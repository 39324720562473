import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddPanelist from "./AddPanelist";
import {
  getPanelists,
  removePanelist,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { doesExamEnded, doesExamStarted } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const Panelist = (props) => {
  const {
    loading,
    showPanelLists,
    setShowPanelLists,
    selectedExam,
    panelists,
    getPanelists,
    removePanelist,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showAddPanelist, setShowPanelist] = useState(false);
  const [selectedPanelist, setSelectedPanelist] = useState(null);
  const [panelistId, setPanelistId] = useState("0");
  const [confirmRemovePanelist, setConfirmRemovePanelist] = useState(false);
  const onClose = () => {
    setShowPanelLists(false);
  };

  useEffect(
    () => {
      getPanelists(selectedExam.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedExam]
  );

  return (
    <>
      <Dialog open={showPanelLists} fullWidth maxWidth="md">
        <DialogTitle >
          <Typography variant="h6" noWrap component="div" maxWidth={550}>
            {showAddPanelist ? (
              <span style={{ color: "rgb(7, 142, 206)" }}>
               Add panel member
              </span>
            ) : (
              <>
                <span style={{ color: "rgb(7, 142, 206)" }}>Panel members</span>
                <span className="ml-2">
                  @
                  {selectedExam.advertisement
                    ? selectedExam.advertisement.requisition.position.name
                    : ""}
                </span>
              </>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            {showAddPanelist ? (
              <>
                <AddPanelist
                  selectedExam={selectedExam}
                  setShowPanelist={setShowPanelist}
                />
              </>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <Button
                  disabled={!!selectedExam.wasExamMarkingPublished}
                    onClick={() => {
                      if(hasPermissions(["IS_CBM","IS_HR"])){
                        
                           setShowPanelist(true);
                           
                      }else {
                        showError("Make sure that you are HR or CBM!");
                      }
                   
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Panelist
                  </Button>
                </div>
                <div className="mt-3">
                  <PreLoader isLoading={loading && !panelists.length} />

                  <NoRecordMessage
                    isEmpty={!loading && !panelists.length}
                    title={"No Panelists found!"}
                  />

                  {!loading && !!panelists.length && (
                    <div className="mt-3">
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col" className="text-left">
                              Names
                            </th>
                            <th scope="col" className="text-left">
                              Email
                            </th>
                            <th scope="col" className="text-left">
                              PhoneNumber
                            </th>
                            <th scope="col" className="text-left">
                              IDNumber
                            </th>
                            <th scope="col" className="text-left">
                              Panel
                            </th>
                            <th scope="col" className="text-left">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {panelists.map((panelist, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {panelist.user.lastName}{" "}
                                  {panelist.user.firstName}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {" "}
                                  {panelist.user.email}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {panelist.user.phoneNumber}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {panelist.user.idNumber}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {panelist?.panel?.panel}
                                </span>
                              </td>
                              <td className="text-left">
                                {" "}
                                <button
                                
                                disabled={!!selectedExam.wasExamMarkingPublished || !hasPermissions(["IS_CBM","IS_HR"]) || panelistId === panelist.id}

                                  onClick={() => {
                                    setSelectedPanelist(panelist);
                                    setConfirmRemovePanelist(true);
                                  }}
                                  className="btn btn-danger"
                                >
                                  {panelistId === panelist.id ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    <RemoveIcon />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {confirmRemovePanelist && (
                  <ConfirmationDialog
                    confirmationDialog={confirmRemovePanelist}
                    message={`Are you sure you want to remove @${selectedPanelist.user.firstName} ${selectedPanelist.user.lastName} from panel members?`}
                    setConfirmationDialog={setConfirmRemovePanelist}
                    onYes={() => {
                      removePanelist(
                        selectedPanelist,
                        setPanelistId,
                        setConfirmRemovePanelist,
                        setSelectedPanelist
                      );
                    }}
                  />
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, panelists }) => {
  return {
    loading,
    panelists,
  };
};
export default connect(mapStateToProps, {
  getPanelists,
  removePanelist,
})(Panelist);
