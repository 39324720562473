import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...structure };

export const getActiveContracts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/contractuals/active");
      dispatch({
        type: types.SET_ACTIVE_CONTRACTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getExpiredContracts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/contractuals/expired");
      dispatch({
        type: types.SET_EXPIRED_CONTRACTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveContract = (formData, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/contractuals/employee-contracts",
        formData
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      if (!!id)
        dispatch({
          type: types.UPDATE_CONTRACT,
          data,
        });
      else
        dispatch({
          type: types.ADD_NEW_CONTRACT,
          data,
        });

      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const endContract = (id, setConfirmRemoveContract) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/contractuals/" + id);

      dispatch({ type: types.END_LOADING });

      showSuccess(data.message);

      dispatch({
        type: types.DELETE_CONTRACT,
        id,
      });

      setConfirmRemoveContract(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getSingleActiveContract = (employeePositionId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data: {},
      });

      const { data } = await axios.get(
        "/api/contractuals/active/" + employeePositionId
      );

      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveContract = (payroad, setConfirmApproveContract) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/contractuals/approve", payroad);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SELECTED_CONTRACT,
        data,
      });

      setConfirmApproveContract(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPositionRequestTypes = (cb) => {
  return async (dispatch) => {
    try {
      cb(true);
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/contractuals/position-request-types"
      );

      dispatch({
        type: types.SET_POSITION_REQUEST_TYPES,
        data,
      });

      cb(false);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCRTRequestFileTypes = (requestId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, []);
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/contractuals/crt-request-file-types/" + requestId
      );

      cb(false, data);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionRequest = (payload, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/contractuals/crt-request",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch({
        type: types.ADD_NEW_CRT_REQUEST,
        data,
      });
      cb(false, true);
      dispatch({ type: types.END_LOADING });
      showSuccess("Request saved successfully");
    } catch (error) {
      cb(false, false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};