import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateSOFunds,
  addNewExitType,
  getAdminExitType,
  updateExitType,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { getInitiators } from "../../../store/exit/actions";

const ExitTypes = (props) => {
  const {
    createExitType,
    setCreateExitType,
    // redux -----------------
    loading,
    adminExitTypes,
    getAdminExitType,
    addNewExitType,
    updateExitType,
    initiators,
    getInitiators,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [typeId, setTypeId] = useState(0);
  const [typeName, setTypeName] = useState("");
  const [initiator, setInitiator] = useState(null);
  const [blackListPeriod, setBlacklistPeriod] = useState(0);
  const [effectiveInDays, setEffectiveInDays] = useState(0);
  const [payTerminalBenefit, setPayTerminalBenefit] = useState(false);
  const [canBeRehired, setCanBeRehired] = useState(false);
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [forcePage, setForcePage] = useState(undefined);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (adminExitTypes.length === 0) {
      getAdminExitType(setLoadingData);
    } else {
      setLoadingData(false);
    }

    getInitiators();

    return () => {
      setLoadingData(true);
      setTypeId(0);
      setTypeName("");
      setFormError(null);
      setSearchInput("");
      setInitiator(null);
      setBlacklistPeriod(0);
      setEffectiveInDays(0);
      setPayTerminalBenefit(false);
      setCanBeRehired(false);
      setForcePage(undefined);
      setItemOffset(0);
    };
  }, []);

  const onCloseForm = () => {
    setCreateExitType(false);
    setTypeId(0);
    setTypeName("");
    setPayTerminalBenefit(false);
    setCanBeRehired(false);
    setBlacklistPeriod(0);
    setEffectiveInDays(0);
    setInitiator(null);
  };

  const onSaveForm = () => {
    if (typeId === 0 && !createExitType) {
      setFormError({
        element: "GENERAL",
        msg: "Please select exit type you want to update.",
      });
      return;
    }
    if (typeName.length === 0) {
      setFormError({ element: "NAME", msg: "Exit type name is required." });
      return;
    }

    if (!initiator) {
      setFormError({ element: "INITIATOR", msg: "Initiator is required." });
      return;
    }

    if (typeId > 0 && !createExitType) {
      updateExitType(
        typeId,
        {
          name: typeName,
          payTerminalBenefit,
          canBeRehired,
          initiatorId: initiator.id,
          initiator,
          blackListPeriod,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewExitType(
        {
          name: typeName,
          payTerminalBenefit,
          canBeRehired,
          initiatorId: initiator.id,
          initiator,
          blackListPeriod,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const IS_EDITING = Boolean(typeId > 0);
  const CURRENT_DATA = adminExitTypes || [];
  const SEARCHED_DATA =
    searchInput.length > 0
      ? CURRENT_DATA.filter(
          (item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase()) ||
            (item.initiator &&
              item.initiator.name
                .toLocaleLowerCase()
                .includes(searchInput.toLocaleLowerCase()))
        )
      : CURRENT_DATA;
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (typeItem) => {
    setTypeId(typeItem.id);
    setTypeName(typeItem.name);
    setPayTerminalBenefit(typeItem.payTerminalBenefit);
    setCanBeRehired(typeItem.canBeRehired);
    setBlacklistPeriod(typeItem.blacklistPeriod);
    setInitiator(typeItem.initiator);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center justify-content-between">
        <div className="col-12">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || adminExitTypes.length === 0}
            placeholder="Search exit type..."
          />
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Exit type</th>
            <th scope="col">Initiator</th>
            <th className="text-center">Blacklist Period</th>
            <th className="text-center">Pay Terminal Benefit</th>
            <th className="text-center">Can Be Rehired</th>
            <th className="text-center">Effective Days</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={8} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={8}>
                <div className="text-center font-weight-bold my-2">
                  No exit type found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((typeItem, index) => (
              <tr key={"exitType-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{typeItem.name}</td>
                <td>{typeItem.initiator?.name}</td>
                <td align="center" style={{ fontSize: "14px" }}>
                  {typeItem.blacklistPeriod}
                </td>
                <td align="center">
                  {typeItem.payTerminalBenefit ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center">
                  {typeItem.canBeRehired ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center" style={{ fontSize: "14px" }}>
                  {typeItem.effectiveInDays}
                </td>
                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit exit type"
                      size="small"
                      onClick={() => setForUpdate(typeItem)}
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createExitType || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Exit type" : "Add New Exit type"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Exit type name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={typeName}
              onChange={(e) => setTypeName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, initiator) => (
                <Box component="li" {...props}>
                  {initiator?.name}
                </Box>
              )}
              options={initiators || []}
              size="small"
              value={initiator || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Exit Initiator"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "initiator",
                  }}
                />
              )}
              onChange={(event, initiator) => setInitiator(initiator || null)}
              className="mt-3"
            />

            <TextField
              id="outlined-basic"
              label="Blacklist period"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              type="number"
              disabled={loading || loadingData}
              value={blackListPeriod}
              onChange={(e) => setBlacklistPeriod(+e.target.value)}
            />

            <div className="d-flex align-items-center mt-1">
              <span>Pay terminal benefit</span>
              <Switch
                checked={payTerminalBenefit}
                onChange={() => setPayTerminalBenefit(!payTerminalBenefit)}
                className="ml-2"
              />
            </div>

            <div className="d-flex align-items-center mt-1">
              <span>Can be rehired</span>
              <Switch
                checked={canBeRehired}
                onChange={() => setCanBeRehired(!canBeRehired)}
                className="ml-2"
              />
            </div>

            <TextField
              id="outlined-basic"
              label="Due days"
              variant="outlined"
              size="small"
              className="w-100 mt-2"
              type="number"
              disabled={loading || loadingData}
              value={effectiveInDays}
              onChange={(e) => setEffectiveInDays(+e.target.value)}
            />
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

ExitTypes.propTypes = {
  createExitType: PropTypes.bool,
  setCreateExitType: PropTypes.func,
};

const mapStateToProps = ({ loading, adminExit, initiators }) => {
  return {
    loading,
    adminExitTypes: adminExit.exitTypes,
    initiators,
  };
};

const mapDispatchToProps = {
  getAdminExitType,
  addNewExitType,
  updateExitType,
  getInitiators,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitTypes);
