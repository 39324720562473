import {
  Tooltip,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
  Chip,
  Badge,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { deleteActivity, selectActivity } from "../../../store/rbm/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import defaultProfile from "../../assets/default-profile.jpg";

import moment from "moment";
import ActivityAssignmentForm from "./ActivityAssignmentForm";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ActivityForm from "./ActivityForm";
import CustomProgressLine from "./CustomProgressLine";
import _ from "lodash";

const ActivityCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    activity,
    index,
    deleteActivity,
    totalQuarterTarget,
    quarter,
    totalWeight,
    user,
    selectActivity,
    selectedOutput,
    selectedIndicator,
    selectedActivity,
    isForContract,
    showReport,
    isForMonitoring,
  } = props;
  const open = Boolean(anchorEl);

  //const location = window.location;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);

  const { hasPermissions } = useRequiredAuth();
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  const canEditOrDelete = (_activity) => {
    const isTechnicalHeadOrPlanner = user?.selectedEntitySector.roles.RBM.find(
      (item) => item.id === "TECHNICAL_HEAD" || item.id === "PLANNER"
    );

    if (isTechnicalHeadOrPlanner) {
      return true;
    } else if (
      user?.employments.find(
        (item) => item.entitySectorId === user?.selectedEntitySector?.id
      ) &&
      _activity.createdBy === user.id
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Card
        className="mb-3 py-3 rounded hoverable-card"
        variant="outlined"
        style={{ backgroundColor: "#fff" }}
      >
        <CardHeader
          className="py-0"
          avatar={
            <Tooltip title="Score">
              <span
                style={{ fontSize: "12px", cursor: "default" }}
                className={`font-weight-bold  text-${
                  activity.score < 50
                    ? "danger"
                    : activity.score < 70
                    ? "warning"
                    : activity.score < 100
                    ? "info"
                    : "success"
                }`}
              >
                {activity.score.toFixed(2)} %
              </span>
            </Tooltip>
          }
          action={
            <>
              {!isForMonitoring && (
                <Box>
                  <IconButton
                    size="small"
                    className="ml-2"
                    onClick={handleOpenMenu}
                    aria-label="settings"
                  >
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={activity.status !== "Reported"}
                    >
                      <span className="material-icons">more_vert</span>
                    </Badge>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem disabled>MENUS</MenuItem>

                    {!isForContract &&
                      hasPermissions(["CAN_ASSIGN_ACTIVITY"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();

                              setShowAssignmentForm(true);
                            }}
                            className="text-dark font-weight-light"
                          >
                            <span className="material-icons mr-2">people</span>
                            Assign activity
                          </MenuItem>
                        </span>
                      )}

                    {isForContract && (
                      <span>
                        <Divider className="my-0" />
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            showReport(activity);
                          }}
                          className="text-dark font-weight-light"
                        >
                          <Badge color="error" variant="dot" invisible>
                            <span className="material-icons mr-2">
                              file_present
                            </span>{" "}
                            Go to reports
                          </Badge>
                        </MenuItem>
                      </span>
                    )}

                    {!isForContract &&
                      hasPermissions(["CAN_ADD_NEW_ACTIVITY"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              setIsEditing(true);
                              setShowActivityForm(true);
                              handleCloseMenu();
                            }}
                            className="text-dark font-weight-light"
                          >
                            <span className="material-icons mr-2">edit</span>{" "}
                            Edit
                          </MenuItem>
                        </span>
                      )}

                    {!isForContract &&
                      hasPermissions(["CAN_ADD_NEW_ACTIVITY"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              setConfirmDelete(true);
                            }}
                            className="text-danger font-weight-light"
                          >
                            <span className="material-icons mr-2">delete</span>{" "}
                            Delete
                          </MenuItem>
                        </span>
                      )}
                  </Menu>
                </Box>
              )}
            </>
          }
          title={
            <>
              <span className="text-uppercase">
                {index + 1}: {activity.name}{" "}
              </span>
            </>
          }
          subheader={
            <span>
              <span className="d-flex align-items-center justify-content-between flex-wrap">
                <span className="d-flex flex-wrap">
                  <Tooltip title="Weight">
                    <span
                      className="align-items-center"
                      style={{
                        cursor: "default",
                      }}
                    >
                      <span className="badge badge-pill px-0">
                        {weights.find(({ id }) => id === activity.weight)
                          ?.name || "n/a"}
                      </span>
                    </span>
                  </Tooltip>
                  <Tooltip title="Activity target share">
                    <span
                      className="  align-items-center px-1 mr-2 text-info"
                      style={{
                        cursor: "default",
                      }}
                    >
                      Target share: {activity.targetShare}
                      {(activity.achievedTarget > 0 ||
                        activity.targetShare > 0) && (
                        <span>
                          {" "}
                          |{" "}
                          <span
                            className={`badge badge-${
                              activity.achievedTarget ===
                                activity.targetShare && activity.targetShare > 0
                                ? "success"
                                : activity.achievedTarget > activity.targetShare
                                ? "success"
                                : "secondary"
                            }`}
                          >
                            {activity.achievedTarget} Achieved{" "}
                          </span>
                        </span>
                      )}
                    </span>
                  </Tooltip>

                  <span className="d-flex align-items-center flex-wrap">
                    {activity?.assignments?.map((assignment) => (
                      <Tooltip
                        key={assignment.id}
                        title={`${assignment.lastName} ${assignment.firstName} assigned`}
                      >
                        <Chip
                          size="small"
                          className="border border-light mr-1"
                          avatar={
                            <Avatar
                              style={{ border: "1px solid #d1cbcb" }}
                              alt={assignment.firstName}
                              src={
                                assignment.profileImage
                                  ? `data:image/jpeg;base64,${assignment.profileImage}`
                                  : defaultProfile
                              }
                            />
                          }
                          label={assignment.firstName}
                          variant="outlined"
                        />
                      </Tooltip>
                    ))}
                    {!isForContract && !activity.assignments[0] && (
                      <button
                        onClick={() => {
                          if (hasPermissions(["CAN_ASSIGN_ACTIVITY"]))
                            setShowAssignmentForm(true);
                        }}
                        className="badge badge-warning ml-1 text-danger border"
                        type="button"
                      >
                        Not assigned
                      </button>
                    )}
                    {!isForMonitoring &&
                      !isForContract &&
                      hasPermissions(["CAN_ASSIGN_ACTIVITY"]) && (
                        <Tooltip title={`Assign activity`}>
                          <IconButton
                            color="primary"
                            size="small"
                            className="p-0 ml-1 border"
                            onClick={() => {
                              setShowAssignmentForm(true);
                            }}
                          >
                            <span className="material-icons">add</span>
                          </IconButton>
                        </Tooltip>
                      )}
                  </span>
                </span>

                <span className="d-flex flex-wrap mt-n4">
                  <span className="text-info ">
                    Deadline: {moment(activity.endOn).format("ll")}
                    {/* <span>
                      {activity.status === "Pending" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-dark mx-4 ">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            pending_actions
                          </span>
                          <span style={{ fontSize: "10px" }}> Pending...</span>
                        </span>
                      )}
                      {activity.status === "Scored" && (
                        <span
                          className={`d-flex align-items-center justify-content-center badge badge-success mx-4 `}
                        >
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            done_all
                          </span>
                          <span style={{ fontSize: "10px" }}> Scored</span>
                        </span>
                      )}
                      {activity.status === "Reported" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-primary mx-4">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            file_present
                          </span>
                          <span style={{ fontSize: "10px" }}> Reported</span>
                        </span>
                      )}

                      {activity.status === "Rejected" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-danger mx-4">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons   "
                          >
                            close
                          </span>
                          <span style={{ fontSize: "10px" }}> Rejected</span>
                        </span>
                      )}
                      {activity.status === "Overdue" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-danger mx-4">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons"
                          >
                            error
                          </span>
                          <span style={{ fontSize: "10px" }}> Overdue</span>
                        </span>
                      )}
                    </span> */}
                  </span>
                </span>
              </span>
            </span>
          }
        />
      </Card>

      <ConfirmationDialog
        confirmationDialog={confirmDelete}
        message="Are you sure you want to delete Activity?"
        setConfirmationDialog={setConfirmDelete}
        onYes={() => {
          deleteActivity(activity, setConfirmDelete);
        }}
      />

      {showAssignmentForm && (
        <ActivityAssignmentForm
          totalWeight={totalWeight}
          totalQuarterTarget={totalQuarterTarget}
          quarter={quarter}
          selectedActivity={activity}
          assignments={activity.assignments || []}
          showAssignmentForm={showAssignmentForm}
          setShowAssignmentForm={setShowAssignmentForm}
        />
      )}

      {showActivityForm && (
        <ActivityForm
          totalWeight={totalWeight}
          totalQuarterTarget={totalQuarterTarget}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          showActivityForm={showActivityForm}
          setShowActivityForm={setShowActivityForm}
          quarter={quarter}
          selectedActivity={activity}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  // expectedResults,
  user,
  // selectedIndicator,
  selectedActivity,
}) => {
  return { user, selectedActivity };
};
export default connect(mapStateToProps, { deleteActivity, selectActivity })(
  ActivityCard
);
