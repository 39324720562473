import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRequisitionQualifications } from "../../../store/recruitment/actions";
import ReactPaginate from "react-paginate";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import ListSkeleton from "../../common/components/ListSkeleton";

const RequisitionQualification = (props) => {
  const {
    loading,
    requisitionId,
    requisitionQualifications,
    getRequisitionQualifications,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      getRequisitionQualifications(requisitionId, setIsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requisitionId]
  );

  const getNo = (idToSearch) =>
    requisitionQualifications.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {!isLoading && !!requisitionQualifications.length && (
        <div className="col-lg-12 mt-3">
          <strong>Qualifications</strong>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {requisitionQualifications.map((row, index) => (
              <React.Fragment key={row.id}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        fontSize: "16px",
                        border: "1px solid #c8c6c6",
                      }}
                      className="bg-white text-dark "
                    >
                      {getNo(row.id) + 1}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <span className={`${"font-weight-light"}`}>
                        {" "}
                        {row.qualification &&
                          row.qualification.degree &&
                          row.qualification.degree.name}{" "}
                        in {row.qualification.name}
                      </span>
                    }
                    secondary={
                      <>
                        <span
                          className={`${"font-weight-bold badge badge-secondary"}`}
                        >
                          {row.yearsOfExperience < 1
                            ? row.yearsOfExperience + " Year"
                            : row.yearsOfExperience + " Years"}{" "}
                          of relevant experience
                        </span>
                      </>
                    }
                  />
                </ListItem>

                {requisitionQualifications.length > index + 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </div>
      )}

      {isLoading && (
        <div className="col-lg-12 mt-3">
          <ListSkeleton />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, requisitionQualifications }) => {
  return {
    loading,
    requisitionQualifications,
  };
};
export default connect(mapStateToProps, {
  getRequisitionQualifications,
})(RequisitionQualification);
