import React, { useEffect, useState } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  calculateReturnDate,
  countLeaveDays,
  createLeavePlan,
  getMyAnnualLeavePlan,
  getPublicHolidays,
} from "../../../store/leave/actions";
import { connect } from "react-redux";
import moment from "moment";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ViewLeaveLetter from "./ViewLeaveLetter";

const PlanAnnualLeaveForm = (props) => {
  const {
    allPlannedDays,
    showCreateAnnualLeave,
    setShowCreateAnnualLeave,
    selectedPlan,
    leaveToPostpone,
    loading,
    user,
    selectedFiscalYear,
    publicHolidays,
    countLeaveDays,
    createLeavePlan,
    getMyAnnualLeavePlan,
    myLeavePlans,
    getPublicHolidays,
    calculateReturnDate,
  } = props;
  const [leaveDateRange, setLeaveDateRange] = useState({
    startDate: null,
    endDate: null,
    effectiveDate: null,
  });
  const [countingDays, setCountingDays] = useState(false);
  const [plannedDays, setPlannedDays] = useState(null);
  const [comment, setComment] = useState(selectedPlan?.employeeComment || "");
  const [errors, setErrors] = useState({
    startDateHasError: false,
    endDateHasError: false,
    effectiveDateHasError: false,
    messageHasError: false,
    messageErrorMessage: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [maxDate, setMaxDate] = useState({
    start: new Date(selectedFiscalYear?.endOn),
    end: new Date(selectedFiscalYear?.endOn),
  });
  const [showLeaveLetter, setShowLeaveLetter] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");

  useEffect(() => {
    if (showCreateAnnualLeave) {
      if (myLeavePlans.length === 0 && !leaveToPostpone) {
        getMyAnnualLeavePlan(setIsLoadingData);
      } else setIsLoadingData(false);
      if (selectedPlan) {
        setLeaveDateRange({
          startDate: new Date(selectedPlan?.startDate),
          endDate: new Date(selectedPlan?.endDate),
          effectiveDate: null,
        });
        setComment(selectedPlan?.employeeComment || "");
      } else if (leaveToPostpone) {
        setLeaveDateRange({
          startDate: new Date(leaveToPostpone.startDate),
          endDate: new Date(leaveToPostpone.endDate),
          effectiveDate: null,
        });
      }
    }

    if (publicHolidays.length === 0) getPublicHolidays(setIsLoadingData);

    return () => {
      setIsLoadingData(false);
    };
  }, [showCreateAnnualLeave]);

  useEffect(() => {
    if (!!leaveToPostpone) {
      const endOfYear = moment(selectedFiscalYear?.endOn).endOf("year");
      setMaxDate((prev) => ({ ...prev, start: endOfYear.toDate() }));
    } else {
      setMaxDate((prev) => ({
        ...prev,
        start: new Date(selectedFiscalYear?.endOn),
      }));
    }

    return () => {
      setMaxDate({
        start: new Date(selectedFiscalYear?.endOn),
        end: new Date(selectedFiscalYear?.endOn),
      });
    };
  }, []);

  useEffect(() => {
    if (!!leaveDateRange.startDate) {
      calculateReturnDate(
        {
          startDate: leaveDateRange.startDate,
          numOfDays: !!leaveToPostpone
            ? leaveToPostpone.numOfDays
            : user.leaveDays - allPlannedDays + (selectedPlan?.numOfDays || 0),
        },
        (loading, data) => {
          setCountingDays(loading);
          if (!loading && data) {
            if (
              !selectedPlan ||
              moment(leaveDateRange.startDate).format("YYYY-MM-DD") !==
                moment(
                  selectedPlan?.startDate || leaveToPostpone?.startDate
                ).format("YYYY-MM-DD")
            ) {
              setMaxDate((prev) => ({ ...prev, end: data }));
            } else {
              setMaxDate((prev) => ({
                ...prev,
                end: new Date(selectedFiscalYear?.endOn),
              }));
            }
          }
        }
      );
    }
  }, [leaveDateRange.startDate]);

  useEffect(() => {
    if (!!leaveDateRange.startDate && !!leaveDateRange.endDate) {
      countLeaveDays(
        {
          ...leaveDateRange,
          leavePlanId: selectedPlan?.id || leaveToPostpone?.id || "",
          employeePositionId:
            selectedPlan?.employeePositionId ||
            leaveToPostpone?.employeePositionId ||
            "",
          isPostponed: !!leaveToPostpone,
        },
        (loading, data) => {
          setCountingDays(loading);
          if (!loading) setPlannedDays(data);
        }
      );
    }
  }, [leaveDateRange.endDate]);

  const onClose = () => {
    // clean all states before close
    setLeaveDateRange({
      startDate: null,
      endDate: null,
      effectiveDate: null,
    });
    setShowApproveRequestForm(false);
    setShowLeaveLetter(false);
    setComment("");
    setErrors({
      startDateHasError: false,
      endDateHasError: false,
      messageHasError: false,
      messageErrorMessage: "",
    });
    setIsSubmitting(false);
    setPkiPassword("");
    setPlannedDays(null);
    setShowCreateAnnualLeave();
  };

  const onSubmitLeavePlan = (e) => {
    if (e) e.preventDefault();

    // handle validation
    let hasError = false;
    if (!leaveDateRange.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        messageErrorMessage: "Start date is required",
      });
      hasError = true;
    }
    if (!leaveDateRange.endDate) {
      setErrors({
        ...errors,
        endDateHasError: true,
        messageErrorMessage: "End date is required",
      });
      hasError = true;
    }
    if (leaveDateRange.startDate > leaveDateRange.endDate) {
      setErrors({
        ...errors,
        endDateHasError: true,
        messageErrorMessage: "End date cannot be less than start date",
      });
      hasError = true;
    }
    // if (!!leaveToPostpone && !leaveDateRange.effectiveDate) {
    //   setErrors({
    //     ...errors,
    //     effectiveDateHasError: true,
    //     messageErrorMessage: "Effective date is required",
    //   });
    //   hasError = true;
    // }
    if (!!leaveToPostpone && !comment) {
      setErrors({
        ...errors,
        messageHasError: true,
        messageErrorMessage: "Message is required",
      });
      hasError = true;
    }
    // if (comment.length < 10) {
    //   setErrors({
    //     ...errors,
    //     messageHasError: true,
    //     messageErrorMessage: "Message must be at least 10 characters",
    //   });
    //   hasError = true;
    // }

    if (hasError) return;

    createLeavePlan(
      {
        startDate: leaveDateRange.startDate,
        endDate: leaveDateRange.endDate,
        numOfDays: plannedDays?.days || 0,
        comment,
        leavePlanId: selectedPlan?.id || leaveToPostpone?.id || "",
        effectiveDate: leaveDateRange.effectiveDate,
        previousPostponedLeaveId: leaveToPostpone?.postponedId || null,
        isForPostponement: !!leaveToPostpone,
        password: pkiPassword,
      },
      (loading, res) => {
        setIsSubmitting(loading);
        if (!loading && res) {
          onClose();
        }
      }
    );
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const isHoliday = (date) => {
    const dates = publicHolidays.map((holiday) =>
      moment(holiday.date).format("YYYY-MM-DD")
    );

    return dates.includes(moment(date).format("YYYY-MM-DD"));
  };

  const handleDatesToDisable = (date) => {
    if (isWeekend(date) && +user.leaveDays === 18) return true;
    if (isHoliday(date) && +user.leaveDays === 18) return true;

    const _myLeavePlans = myLeavePlans.filter(
      (plan) => plan.id !== (selectedPlan?.id || leaveToPostpone?.id)
    );

    const dates = _myLeavePlans.map(({ startDate, endDate }) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      return date >= start && date <= end;
    });
    return dates.includes(true);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCreateAnnualLeave}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-dark pb-2">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            {!!selectedPlan
              ? "UPDATE"
              : !!leaveToPostpone
              ? "POSTPONE"
              : "CREATE"}{" "}
            ANNUAL LEAVE |{" "}
            <strong className="text-primary">{selectedFiscalYear?.name}</strong>{" "}
            {allPlannedDays}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="py-1">
          {!!plannedDays &&
            (plannedDays.days > plannedDays.allowedDays ? (
              <Alert variant="standard" severity="error" className="mb-3">
                You have exceeded the allowed days of leave
              </Alert>
            ) : !!plannedDays.overlapWithOtherLeavePlans ? (
              <Alert
                variant="standard"
                severity="warning"
                color="error"
                className="mb-3"
              >
                This leave plan overlaps with other leave plans you have created
              </Alert>
            ) : !!plannedDays.otherEmployeesWithLeave ? (
              <Alert
                variant="standard"
                severity="success"
                color="warning"
                className="mb-3"
              >
                Other <b>{plannedDays.otherEmployeesWithLeave}</b> staff
                {plannedDays.otherEmployeesWithLeave > 1
                  ? "s have"
                  : " has"}{" "}
                planned leave for the same period
              </Alert>
            ) : null)}
          {!!errors.effectiveDateHasError && (
            <Alert variant="standard" severity="error">
              {errors.messageErrorMessage}
            </Alert>
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="d-flex align-items-center mt-1">
              <DesktopDatePicker
                label="Leave Start Date"
                inputFormat="dd/MM/yyyy"
                disabled={
                  loading || countingDays || isSubmitting || isLoadingData
                }
                value={leaveDateRange.startDate}
                onChange={(date) => {
                  setLeaveDateRange({
                    ...leaveDateRange,
                    startDate: date,
                    endDate: null,
                  });
                  setPlannedDays(null);

                  setErrors({
                    ...errors,
                    startDateHasError: false,
                  });
                }}
                minDate={
                  new Date(
                    selectedFiscalYear
                      ? selectedFiscalYear?.startOn
                      : selectedPlan?.createdOn
                  )
                }
                maxDate={maxDate.start}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
                shouldDisableDate={handleDatesToDisable}
                disablePast={!selectedPlan && !leaveToPostpone}
              />

              <span className="mx-2" style={{ fontSize: "30px" }}>
                -
              </span>

              <DesktopDatePicker
                label="Leave End Date"
                inputFormat="dd/MM/yyyy"
                disabled={
                  !leaveDateRange.startDate ||
                  loading ||
                  countingDays ||
                  isSubmitting ||
                  isLoadingData
                }
                defaultCalendarMonth={leaveDateRange.startDate || new Date()}
                value={leaveDateRange.endDate}
                onChange={(date) => {
                  setLeaveDateRange({
                    ...leaveDateRange,
                    endDate: date,
                  });
                  setPlannedDays(null);

                  setErrors({
                    ...errors,
                    effectiveDateHasError: false,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
                minDate={leaveDateRange.startDate}
                maxDate={maxDate.end}
                shouldDisableDate={handleDatesToDisable}
                disablePast={!selectedPlan && !leaveToPostpone}
              />
            </div>

            {!!plannedDays && (
              <div className="d-flex align-items-center text-uppercase">
                <span
                  className={`badge ${
                    plannedDays.days > 0 &&
                    plannedDays.days <= plannedDays.allowedDays
                      ? "badge-secondary"
                      : "badge-danger"
                  } text-left text-uppercase mr-1`}
                  style={{ minWidth: "132px" }}
                  title="Planned days of leave"
                >
                  <span
                    className={`badge badge-light${
                      plannedDays.days > 0 &&
                      plannedDays.days <= plannedDays.allowedDays
                        ? " bg-secondary"
                        : " bg-danger"
                    } text-light`}
                    style={{ fontSize: "12px" }}
                  >
                    {plannedDays.days}/{plannedDays.allowedDays}
                  </span>{" "}
                  Planned days
                </span>
                <span
                  className="badge badge-warning text-left text-uppercase"
                  style={{ minWidth: "132px" }}
                  title="Other staff with same period of leave"
                >
                  <span
                    className="badge badge-light bg-warning text-light"
                    // style={{ fontSize: "12px" }}
                  >
                    {plannedDays.otherEmployeesWithLeave}
                  </span>{" "}
                  Other staff
                </span>
              </div>
            )}

            {!!leaveToPostpone && (
              <div className="d-flex align-items-center mt-3">
                <DesktopDatePicker
                  label="Effective Date"
                  inputFormat="dd/MM/yyyy"
                  disabled={
                    loading || countingDays || isSubmitting || isLoadingData
                  }
                  value={leaveDateRange.effectiveDate}
                  onChange={(date) => {
                    setLeaveDateRange({
                      ...leaveDateRange,
                      effectiveDate: date,
                    });

                    setErrors({
                      ...errors,
                      effectiveDateHasError: false,
                    });
                  }}
                  minDate={
                    new Date(
                      leaveToPostpone.statusId === 5
                        ? leaveToPostpone.startDate
                        : selectedPlan?.createdOn || selectedFiscalYear?.startOn
                    )
                  }
                  maxDate={
                    leaveToPostpone.statusId !== 5
                      ? leaveDateRange.startDate
                      : leaveToPostpone.endDate
                  }
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      required={true}
                      fullWidth
                      size="small"
                      {...params}
                    />
                  )}
                  shouldDisableDate={isWeekend}
                />
              </div>
            )}

            {!!leaveToPostpone && (
              <Alert
                icon={false}
                variant="outlined"
                severity="info"
                className="mt-2"
              >
                The <b>Effective Date</b> determines how many days have been
                taken if the leave status is '<b>On-Leave</b>.' It will be
                ignored otherwise.
              </Alert>
            )}
          </LocalizationProvider>

          <TextField
            error={errors.messageHasError}
            helperText={errors.messageErrorMessage}
            fullWidth
            size="small"
            multiline
            rows={4}
            // autoFocus={assignment.targetShare === 0}
            name="employeeMessage"
            label="Message"
            variant="outlined"
            placeholder={`Message (Required)`}
            className="mb-3 mt-3"
            value={comment}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => {
              setComment(e.target.value);
              setErrors({
                ...errors,
                messageHasError: false,
                messageErrorMessage: "",
              });
            }}
            disabled={
              loading ||
              countingDays ||
              !plannedDays ||
              plannedDays.days > plannedDays.allowedDays ||
              isLoadingData ||
              isSubmitting
            }
          />

          <div className="d-flex justify-content-center">
            <button
              onClick={onSubmitLeavePlan}
              type="button"
              className="btn btn-sm btn-primary text-uppercase px-5"
              disabled={
                loading ||
                countingDays ||
                !plannedDays ||
                plannedDays.days > plannedDays.allowedDays ||
                isLoadingData ||
                isSubmitting
              }
            >
              {isSubmitting ? "Wait..." : "Submit"}
            </button>
          </div>
        </DialogContent>
        <DialogActions className="py-2"></DialogActions>
      </Dialog>
      {showApproveRequestForm && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>

              <form onSubmit={onSubmitLeavePlan} name="sign-document">
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            onSubmitLeavePlan();
          }}
        />
      )}
      {showLeaveLetter && (
        <ViewLeaveLetter
          setShowLeaveLetter={setShowLeaveLetter}
          showLeaveLetter={showLeaveLetter}
          leaveRequest={{
            ...leaveToPostpone,
            startDate: leaveDateRange.startDate,
            endDate: leaveDateRange.endDate,
            numberOfDays: plannedDays?.days || null,
          }}
          isForPostponement={!!leaveToPostpone}
          signCallBack={
            <>
              <button
                onClick={() => setShowApproveRequestForm(true)}
                type="button"
                className="btn btn-sm btn-primary text-uppercase px-4 mr-2"
                disabled={loading || countingDays || isSubmitting}
              >
                {isSubmitting ? "Wait..." : "Sign"}
              </button>
            </>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  selectedFiscalYear,
  myLeavePlans,
  publicHolidays,
}) => {
  return {
    loading,
    user,
    selectedFiscalYear,
    myLeavePlans,
    publicHolidays,
  };
};
export default connect(mapStateToProps, {
  countLeaveDays,
  createLeavePlan,
  getMyAnnualLeavePlan,
  getPublicHolidays,
  calculateReturnDate,
})(PlanAnnualLeaveForm);
