import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Input,
  Button,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { verifyMinistryTrainingRequest } from "../../../store/training/actions";
import { showError } from "../../toastify";
import { Editor, countWords } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ErrorMessage from "../../common/components/ErrorMessage";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import moment from "moment";
import rwandaLogo from "../../assets/rwanda-logo.png";
import DraftAuthorizationByMinistry from "./DraftAuthorizationByMinistry";
import AuthorizationPermit from "./AuthorizationPermit";
import DraftAuthorizationPermit from "./DraftAuthorizationPermit";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const draftAuthorizationContentByMinistryMxWords = 2000;
const VerifyTrainingRequestByMinistryDialog = (props) => {
  const {
    loading,
    level,
    showVerifyRequest,
    setShowVerifyRequest,
    setShowTrainingRequestDetails,
    request,
    verifyMinistryTrainingRequest,
    isMifotraEntitySector,
    status,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [hasTextEditorChanged, setHasTextEditorChanged] = useState(false);
  const [canSubmitRequest, setCanSubmitRequest] = useState(false);
  const [formData, setFormData] = useState({
    referenceNo: request.referenceNo,
    level: level,
    comments: !!window.localStorage.getItem(`${request.referenceNo}-comments`)
      ? window.localStorage.getItem(`${request.referenceNo}-comments`)
      : ``,
    permitReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-permitReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-permitReferenceNo`)
      : request.permitReferenceNo,
    coveredRelatedCostsDetails: request.coveredRelatedCostsDetails,
    draftAuthorizationContentByMinistry: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationContentByMinistry`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationContentByMinistry`
        )
      : request.draftAuthorizationContentByMinistry,
    draftAuthorizationSubjectByMinistry: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationSubjectByMinistry`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationSubjectByMinistry`
        )
      : request.draftAuthorizationSubjectByMinistry
      ? request.draftAuthorizationSubjectByMinistry
      : "Request the authorization to conduct a training",
    minstryLetterReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-minstryLetterReferenceNo`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-minstryLetterReferenceNo`
        )
      : request.minstryLetterReferenceNo,
    draftAuthorizationPermitContent: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationPermitContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationPermitContent`
        )
      : request.draftAuthorizationPermitContent,
  });
  const [errors, setErrors] = useState({
    commentHasError: false,
    draftAuthorizationContentByMinistryMxWordsHasError: false,
    draftAuthorizationContentByMinistryHasError: false,
    draftAuthorizationSubjectByMinistryHasError: false,
    draftAuthorizationContentByMinistryMaxCharHasError: false,
    minstryLetterReferenceNoHasError: false,
    draftAuthorizationPermitContentHasError: false,
    draftAuthorizationPermitContentMxWordsHasError: false,
    permitReferenceNoHasError: false,
  });

  const formValidator = () => {
    const error = {
      commentHasError: false,
      hasError: false,
      draftAuthorizationContentByMinistryMxWordsHasError: false,
      draftAuthorizationContentByMinistryHasError: false,
      draftAuthorizationSubjectByMinistryHasError: false,
      draftAuthorizationContentByMinistryMaxCharHasError: false,
      minstryLetterReferenceNoHasError: false,
      draftAuthorizationPermitContentHasError: false,
      draftAuthorizationPermitContentMxWordsHasError: false,
      permitReferenceNoHasError: false,
    };

    if (
      !formData.comments ||
      formData.comments === `<p><br></p>` ||
      formData.comments === `<p></p>`
    ) {
      error.commentHasError = true;
      error.hasError = true;
    }
    if (
      !formData.draftAuthorizationContentByMinistry &&
      request.employmentCategory === "Permanent staff"
    ) {
      error.draftAuthorizationContentByMinistryHasError = true;
      error.hasError = true;
    }
    if (
      !formData.draftAuthorizationSubjectByMinistry &&
      request.employmentCategory === "Permanent staff"
    ) {
      error.draftAuthorizationSubjectByMinistryHasError = true;
      error.hasError = true;
    }

    if (
      !formData.minstryLetterReferenceNo &&
      request.employmentCategory === "Permanent staff"
    ) {
      error.minstryLetterReferenceNoHasError = true;
      error.hasError = true;
    }

    if (
      request.employmentCategory === "Permanent staff" &&
      !!formData.draftAuthorizationContentByMinistry &&
      countWords(formData.draftAuthorizationContentByMinistry) >
        draftAuthorizationContentByMinistryMxWords
    ) {
      error.draftAuthorizationContentByMinistryMxWordsHasError = true;
      error.hasError = true;
    }
    if (
      !formData.permitReferenceNo &&
      request.employmentCategory === "Contractual staff"
    ) {
      error.permitReferenceNoHasError = true;
      error.hasError = true;
    }

    if (
      !formData.draftAuthorizationPermitContent &&
      request.employmentCategory === "Contractual staff"
    ) {
      error.draftAuthorizationPermitContentHasError = true;
      error.hasError = true;
    }
    if (
      !formData.draftAuthorizationSubjectByMinistry &&
      request.employmentCategory === "Contractual staff"
    ) {
      error.draftAuthorizationSubjectByMinistryHasError = true;
      error.hasError = true;
    }

    if (
      request.employmentCategory === "Contractual staff" &&
      !!formData.draftAuthorizationContentByMinistry &&
      countWords(formData.draftAuthorizationContentByMinistry) >
        draftAuthorizationContentByMinistryMxWords
    ) {
      error.draftAuthorizationPermitContentMxWordsHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    console.log(formData);
    if (!formValidator()) {
      setCanSubmitRequest(true);
    }
  };

  const onClose = () => {
    setFormData({
      referenceNo: "",
      level: "",
      comments: ``,
    });
    setShowVerifyRequest(false);
  };
  const getEditorContent = (data) => {
    //  console.log(data);
    let draftAuthorizationContentByMinistryMaxCharHasError = false;
    setHasTextEditorChanged(data.isChanged);
    setFormData({
      ...formData,
      draftAuthorizationContentByMinistry: data.contents,
    });

    if (!!data.isMaxChar)
      draftAuthorizationContentByMinistryMaxCharHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationContentByMinistryMaxCharHasError:
        draftAuthorizationContentByMinistryMaxCharHasError,
    });
  };
  const getEditorContent2 = (data) => {
    // console.log(data);
    let draftAuthorizationPermitContentMxWordsHasError = false;
    // if (data && data.isChanged) setHasTextEditorChanged(data.isChanged);
    if (data && data.contents)
      setFormData({
        ...formData,
        draftAuthorizationPermitContent: data.contents,
      });
    if (data && !!data.isMaxChar)
      draftAuthorizationPermitContentMxWordsHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationPermitContentMxWordsHasError:
        draftAuthorizationPermitContentMxWordsHasError,
    });
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showVerifyRequest}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            REQUEST VERIFICATION
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ maxHeight: "100vh" }}>
          {request?.employmentCategory === "Contractual staff" ? (
            <>
              <AuthorizationPermit
                headOfInstitution={{
                  firstName: request?.ministryApproverFirstName,
                  lastName: request?.ministryApproverLastName,
                  title: request?.ministryApproverTitle,
                  institutionName: request?.ministryApproverInstitutionName,
                  positionName: request?.ministryApproverPositionName,
                }}
                permitReferenceNo={formData.permitReferenceNo}
                draftAuthorizationPermitContentInput={
                  <>
                    <TextEditorComponent
                      id="editor-textarea5"
                      options={{
                        autoSave: false,
                        localStorageKey: `${request.referenceNo}-draftAuthorizationPermitContent`,
                        maxCharCount:
                          draftAuthorizationContentByMinistryMxWords,
                        defaultValue: formData.draftAuthorizationPermitContent,
                      }}
                      onEditorChange={getEditorContent2}
                    />

                    <ErrorMessage
                      hasError={errors.draftAuthorizationPermitContentHasError}
                      message="This field is required"
                    />
                  </>
                }
                permitReferenceNoInput={
                  <>
                    <Input
                      autoFocus
                      value={formData.permitReferenceNo}
                      error={!!errors.permitReferenceNoHasError}
                      inputProps={{
                        "aria-label": "permitReferenceNo",
                      }}
                      onChange={(e) => {
                        const permitReferenceNo = e.target.value;
                        setFormData({
                          ...formData,
                          permitReferenceNo,
                        });
                        window.localStorage.setItem(
                          `${request.referenceNo}-permitReferenceNo`,
                          permitReferenceNo
                        );
                        setErrors({
                          ...errors,
                          permitReferenceNoHasError: false,
                        });
                      }}
                    />
                  </>
                }
                request={null}
                draftAuthorizationPermitContent={
                  formData.draftAuthorizationPermitContent
                }
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "15%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        {/* {request?.ministryDelegateApproverPositionName &&
                          hasPermissions([
                            "IS_TRAINING_MINISTRY_DELEGATED_APPROVER",
                          ]) && (
                            <>
                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                For{" "}
                                {request?.ministryDelegateApproverFirstName ||
                                  ""}{" "}
                                {request?.ministryDelegateApproverLastName ||
                                  ""}
                              </p>

                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                {request?.ministryDelegateApproverPositionName}
                              </p>
                            </>
                          )} */}
                      </td>
                    </tr>
                  </table>
                }
              />
            </>
          ) : (
            <>
              <div className="letterMainContent">
                <div className="letter">
                  <div className="letterhead d-flex flex-column justify-content-center w-100">
                    <div
                      className="letterhead-title w-100"
                      style={{ fontWeight: "bold" }}
                    >
                      REPUBLIC OF RWANDA
                    </div>
                    <div className="w-100">
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                        }}
                        src={rwandaLogo}
                        alt="Logo"
                      />
                    </div>
                    <div
                      className="letterhead-subtitle w-100"
                      style={{
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {request?.ministryApproverInstitutionName.toUpperCase()}
                    </div>
                  </div>
                  <div className="subletterhead">
                    {/* <!-- Date and reference number go here --> */}
                    <p className="date" style={{ fontWeight: "bold" }}>
                      Kigali, on {moment(new Date()).format("LL")}
                    </p>
                    <p
                      className="reference float-right"
                      style={{ fontWeight: "bold" }}
                    >
                      No:{" "}
                      <Input
                        autoFocus
                        error={!!errors.minstryLetterReferenceNoHasError}
                        value={formData.minstryLetterReferenceNo}
                        inputProps={{
                          "aria-label": "minstryLetterReferenceNo",
                        }}
                        onChange={(e) => {
                          const minstryLetterReferenceNo = e.target.value;
                          setFormData({
                            ...formData,
                            minstryLetterReferenceNo,
                          });
                          window.localStorage.setItem(
                            `${request.referenceNo}-minstryLetterReferenceNo`,
                            minstryLetterReferenceNo
                          );
                          setErrors({
                            ...errors,
                            minstryLetterReferenceNoHasError: false,
                          });
                        }}
                      />
                    </p>
                  </div>
                  <div className="recipient">
                    {/* <!-- Recipient's name and address go here --> */}

                    <p
                      style={{
                        fontSize: "16px",
                        lineHeight: "1.5em",
                      }}
                    >
                      {isMifotraEntitySector ? (
                        <>
                          Chief Skills Officer <br />
                          <strong>Rwanda Development Board</strong>
                        </>
                      ) : (
                        <>Honorable Minister of Public Service and Labour</>
                      )}
                      <br />
                      <span
                        style={{
                          position: "relative",
                          top: "6px",
                          fontWeight: "bold",
                        }}
                      >
                        KIGALI
                      </span>
                    </p>
                  </div>
                  <div className="content2">
                  {isMifotraEntitySector ? (
                      <>
                        <p>Dear Chief Skills Officer,</p>
                      </>
                    ) : (
                      <>
                        <p>Honorable Minister,</p>
                      </>
                    )}
                    <p style={{ fontWeight: "bold" }}>
                      Re:
                      <Input
                        className="w-100"
                        autoFocus
                        error={
                          !!errors.draftAuthorizationSubjectByMinistryHasError
                        }
                        value={formData.draftAuthorizationSubjectByMinistry}
                        inputProps={{
                          "aria-label": "draftAuthorizationSubjectByMinistry",
                        }}
                        onChange={(e) => {
                          const draftAuthorizationSubjectByMinistry =
                            e.target.value;
                          setFormData({
                            ...formData,
                            draftAuthorizationSubjectByMinistry,
                          });
                          window.localStorage.setItem(
                            `${request.referenceNo}-draftAuthorizationSubjectByMinistry`,
                            draftAuthorizationSubjectByMinistry
                          );
                          setErrors({
                            ...errors,
                            draftAuthorizationSubjectByMinistryHasError: false,
                          });
                        }}
                      />
                    </p>
                    <p>
                      <TextEditorComponent
                        id="editor-textarea3"
                        options={{
                          autoSave: false,
                          hasChanged: hasTextEditorChanged,
                          localStorageKey: `${request.referenceNo}-draftAuthorizationContentByMinistry`,
                          // api: `/api/training/update-ministry-letter-contents/${request.referenceNo}`,
                          maxCharCount:
                            draftAuthorizationContentByMinistryMxWords,
                          defaultValue:
                            formData.draftAuthorizationContentByMinistry,
                        }}
                        onEditorChange={getEditorContent}
                      />

                      <ErrorMessage
                        hasError={
                          errors.draftAuthorizationContentByMinistryHasError
                        }
                        message="This field is required"
                      />
                    </p>
                  </div>
                  <div className="signature2">
                    <p
                      style={{
                        position: "relative",
                        paddingBottom: "15px",
                        fontWeight: 800,
                        color: "red",
                      }}
                    >
                      <table>
                        <tr>
                          <td
                            style={{
                              width: "15%",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            <span className="d-block text-danger">
                              Not signed
                            </span>
                          </td>
                          <td
                            style={{
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            {/* {request?.ministryDelegateApproverPositionName &&
                              hasPermissions([
                                "IS_TRAINING_MINISTRY_DELEGATED_APPROVER",
                              ]) && (
                                <>
                                  <p
                                    style={{
                                      fontWeight: 800,
                                      fontStyle: "italic",
                                      color: "blue",
                                    }}
                                  >
                                    For{" "}
                                    {request?.ministryDelegateApproverFirstName ||
                                      ""}{" "}
                                    {request?.ministryDelegateApproverLastName ||
                                      ""}
                                  </p>

                                  <p
                                    style={{
                                      fontWeight: 800,
                                      fontStyle: "italic",
                                      color: "blue",
                                    }}
                                  >
                                    {
                                      request?.ministryDelegateApproverPositionName
                                    }
                                  </p>
                                </>
                              )} */}
                          </td>
                        </tr>
                      </table>
                    </p>
                    <p
                      className="mt-4"
                      style={{
                        fontSize: "12px",
                        fontWeight: 800,
                      }}
                    >
                      {request?.ministryApproverFirstName || ""}{" "}
                      {request?.ministryApproverLastName || ""}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: 800,
                      }}
                    >
                      {request?.ministryApproverPositionName}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {canSubmitRequest &&
            request.employmentCategory === "Contractual staff" && (
              <DraftAuthorizationPermit
                showAuthorizationPermit={canSubmitRequest}
                setShowAuthorizationPermit={setCanSubmitRequest}
                headOfInstitution={{
                  firstName: request?.ministryApproverFirstName,
                  lastName: request?.ministryApproverLastName,
                  title: request?.ministryApproverTitle,
                  institutionName: request?.ministryApproverInstitutionName,
                  positionName: request?.ministryApproverPositionName,
                  canAddInstitutionName: !!request?.ministryApproverCanAddInstitutionName?true:false,
                }}
                permitReferenceNo={formData.permitReferenceNo}
                draftAuthorizationPermitContent={
                  formData.draftAuthorizationPermitContent
                }
               
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "15%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        {request?.ministryDelegateApproverPositionName &&
                          hasPermissions([
                            "IS_TRAINING_MINISTRY_DELEGATED_APPROVER",
                          ]) && (
                            <>
                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                For{" "}
                                {request?.ministryDelegateApproverFirstName ||
                                  ""}{" "}
                                {request?.ministryDelegateApproverLastName ||
                                  ""}
                              </p>

                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                {request?.ministryDelegateApproverPositionName}
                              </p>
                            </>
                          )}
                      </td>
                    </tr>
                  </table>
                }
                dialogActions={
                  <>
                    <Button
                      disabled={loading}
                      onClick={() =>
                        verifyMinistryTrainingRequest(
                          formData,
                          setShowTrainingRequestDetails,
                          onClose
                        )
                      }
                      style={{
                        ...ActiveButton,
                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                        float: "right",
                        background: "#14a44d",
                        borderColor: "#14a44d",
                      }}
                      size="md"
                    >
                      {loading ? "Submitting..." : "Confirm now"}
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => setCanSubmitRequest(false)}
                      style={{
                        ...ActiveButton,
                        float: "right",
                        background: "#ced4da",
                        borderColor: "#ced4da",
                        color: "#495057",
                      }}
                      size="md"
                    >
                      Cancel
                    </Button>
                  </>
                }
              />
            )}
          {canSubmitRequest &&
            request.employmentCategory !== "Contractual staff" && (
              <DraftAuthorizationByMinistry
                headOfInstitution={{
                  firstName: request?.ministryApproverFirstName,
                  lastName: request?.ministryApproverLastName,
                  title: request?.ministryApproverTitle,
                  institutionName: request?.ministryApproverInstitutionName,
                  positionName: request?.ministryApproverPositionName,
                  canAddInstitutionName: !!request?.ministryApproverCanAddInstitutionName?true:false,
                }}
                minstryLetterReferenceNo={formData.minstryLetterReferenceNo}
                draftAuthorizationSubjectByMinistry={
                  formData.draftAuthorizationSubjectByMinistry
                }
                draftAuthorizationContentByMinistry={
                  formData.draftAuthorizationContentByMinistry
                }
                showAuthorizationPermit={canSubmitRequest}
                setShowAuthorizationPermit={setCanSubmitRequest}
                isMifotraEntitySector={isMifotraEntitySector}
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "15%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        {/* {request?.ministryDelegateApproverPositionName &&
                          hasPermissions([
                            "IS_TRAINING_MINISTRY_DELEGATED_APPROVER",
                          ]) && (
                            <>
                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                For{" "}
                                {request?.ministryDelegateApproverFirstName ||
                                  ""}{" "}
                                {request?.ministryDelegateApproverLastName ||
                                  ""}
                              </p>

                              <p
                                style={{
                                  fontWeight: 800,
                                  fontStyle: "italic",
                                  color: "blue",
                                }}
                              >
                                {request?.ministryDelegateApproverPositionName}
                              </p>
                            </>
                          )} */}
                      </td>
                    </tr>
                  </table>
                }
                dialogActions={
                  <>
                    <Button
                      disabled={loading}
                      onClick={() =>
                        verifyMinistryTrainingRequest(
                          formData,
                          setShowTrainingRequestDetails,
                          onClose
                        )
                      }
                      style={{
                        ...ActiveButton,
                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                        float: "right",
                        background: "#14a44d",
                        borderColor: "#14a44d",
                      }}
                      size="md"
                    >
                      {loading ? "Submitting..." : "Confirm now"}
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => setCanSubmitRequest(false)}
                      style={{
                        ...ActiveButton,
                        float: "right",
                        background: "#ced4da",
                        borderColor: "#ced4da",
                        color: "#495057",
                      }}
                      size="md"
                    >
                      Cancel
                    </Button>
                  </>
                }
              />
            )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center">
          <div
            className="p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12 mt-0">
                <ReactQuill
                  theme="snow"
                  placeholder="Type your comments..."
                  value={formData.comments || ""}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  onChange={(value) => {
                    const comments = value;
                    setFormData({
                      ...formData,
                      comments,
                    });
                    window.localStorage.setItem(
                      `${request.referenceNo}-comments`,
                      comments
                    );
                    const commentHasError = false;
                    setErrors({ ...errors, commentHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.commentHasError}
                  message="A comment is needed."
                />
              </div>
              <div className="col-12 mt-1">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase  px-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Wait..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  verifyMinistryTrainingRequest,
})(VerifyTrainingRequestByMinistryDialog);
