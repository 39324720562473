import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getApplicationPublications } from "../../../../store/profile/actions";

import NoResults from "../../../common/components/NoResults";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import LinkIcon from "@mui/icons-material/Link";
import moment from "moment";
export const Publications = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationPublications,
    getApplicationPublications,
    applicationId,
  } = props;

  useEffect(() => {
    getApplicationPublications(applicationId);
  }, []);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="publications">
        <CardContent>
          {loading && !onWait && !applicationPublications.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Publications
                  </Typography>
                </div>
              </div>

              {applicationPublications.length === 0 ? (
                <NoResults />
              ) : (
                applicationPublications.map((publication, index) => (
                  <PublicationItemCard
                    key={publication.id}
                    index={index}
                    publication={publication}
                    loading={loading}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({
  env,
  loading,
  onWait,
  applicationPublications,
}) => ({
  env,
  loading,
  onWait,
  applicationPublications,
});

export default connect(mapStateToProps, {
  getApplicationPublications,
})(Publications);

const PublicationItemCard = (props) => {
  const { publication } = props;

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card`}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {publication.publicationYear}
            </span>
          }
          title={<Typography>{publication.publicationType.name}</Typography>}
          subheader={
            <>
              {publication.title}
              <small className="d-block text-truncate">
                {!!publication.url && (
                  <a href={publication.url} target="_blank" rel="noreferrer">
                    <LinkIcon /> {publication.url}
                  </a>
                )}
              </small>
              <p
                            className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                           Updated on, {moment(publication.createdOn).format("ll")}
                          </p>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />
    </>
  );
};
