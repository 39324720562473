import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Divider,
  ListItemText,
} from "@mui/material";
import WrittenExam from "./WrittenExam";
import OralExam from "./OralExam";
import { useTheme } from "@mui/material/styles";
import OverallResults from "./OverallResults";
import TeachersPlacement from "./TeachersPlacement";

const menus = [
  { icon: "list", name: "Written" },
  { icon: "list", name: "Oral" },
  { icon: "list", name: "Overall Results" },
  { icon: "list", name: "Teachers Placement" },
];

const ExamSettingDialog = (props) => {
  const {
    user,
    loading,
    showExamSettings,
    setShowExamSettings,
    selectedAdvertisement,
    userType,
  } = props;

  const onClose = () => {
    setShowExamSettings(false);
  };
  const theme = useTheme();

  const [activeMenu, setActiveMenu] = useState(
    selectedAdvertisement.willHaveWrittenExam ? "Written" : "Oral"
  );
  // console.log(selectedAdvertisement);

  const allowToViewOverallResultMenu = (selectedAdvertisement) => {
    let allowed = false;

    if (
      selectedAdvertisement.willHaveOralExam &&
      selectedAdvertisement.willHaveWrittenExam
    ) {
      allowed = true;
    }

    if (
      !selectedAdvertisement.willHaveOralExam &&
      selectedAdvertisement.willHaveWrittenExam
    ) {
      allowed = true;
    }

    if (
      selectedAdvertisement.willHaveOralExam &&
      !selectedAdvertisement.willHaveWrittenExam
    ) {
      allowed = true;
    }

    return allowed;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showExamSettings}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">RECRUITMENT</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "6px" }}
              >
                chevron_right
              </span>
            </span>

            <span style={{ color: "rgb(7, 142, 206)" }}>Exam</span>
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "6px" }}
            >
              chevron_right
            </span>
            <span className="text-success">
              {selectedAdvertisement && selectedAdvertisement.positionName}
            </span>
            {selectedAdvertisement.adStatusId === 7 && (
              <span className="text-danger">(Advertisement Canceled)</span>
            )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "650px" }}>
          <div className="row m-1">
            <div className="col-lg-2">
              <div
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  overflow: "visible",
                  // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  <nav aria-label="main mailbox folders">
                    <List>
                      {menus.map((menu, index) => (
                        <span key={index}>
                          {selectedAdvertisement.willHaveWrittenExam &&
                            menu.name === "Written" && (
                              <>
                                <ListItem
                                  onClick={() => setActiveMenu(menu.name)}
                                  style={
                                    activeMenu === menu.name
                                      ? {
                                          backgroundColor: "rgb(7, 142, 206)",
                                          color: "#ffff",
                                        }
                                      : {}
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemText
                                      primary={menu.name + " Exam"}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                {menus.length - 1 !== index && <Divider />}
                              </>
                            )}

                          {selectedAdvertisement.willHaveOralExam &&
                            menu.name === "Oral" && (
                              <>
                                <ListItem
                                  onClick={() => setActiveMenu(menu.name)}
                                  style={
                                    activeMenu === menu.name
                                      ? {
                                          backgroundColor: "rgb(7, 142, 206)",
                                          color: "#ffff",
                                        }
                                      : {}
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemText
                                      primary={menu.name + " Exam"}
                                    />
                                  </ListItemButton>
                                </ListItem>

                                {menus.length - 1 !== index && <Divider />}
                              </>
                            )}

                          {allowToViewOverallResultMenu(
                            selectedAdvertisement
                          ) &&
                            menu.name === "Overall Results" && (
                              <>
                                <ListItem
                                  onClick={() => setActiveMenu(menu.name)}
                                  style={
                                    activeMenu === menu.name
                                      ? {
                                          backgroundColor: "rgb(7, 142, 206)",
                                          color: "#ffff",
                                        }
                                      : {}
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemText primary={menu.name} />
                                  </ListItemButton>
                                </ListItem>

                                {menus.length - 1 !== index && <Divider />}
                              </>
                            )}

                          {[
                            "1413000000-090000000000000000-10",
                            "1420000000-090000000000000000-10",
                          ].includes(user.selectedEntitySector.id) &&
                            menu.name === "Teachers Placement" && (
                              <>
                                <ListItem
                                  className="mt-3"
                                  onClick={() => setActiveMenu(menu.name)}
                                  style={
                                    activeMenu === menu.name
                                      ? {
                                          backgroundColor: "rgb(7, 142, 206)",
                                          color: "#ffff",
                                        }
                                      : { backgroundColor: "#eee" }
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemText primary={menu.name} />
                                  </ListItemButton>
                                </ListItem>

                                {menus.length - 1 !== index && <Divider />}
                              </>
                            )}
                        </span>
                      ))}
                    </List>
                  </nav>
                </Box>
              </div>
            </div>

            <div className="col-lg-10">
              {activeMenu === "Written" &&
                selectedAdvertisement.willHaveWrittenExam && (
                  <span>
                    <WrittenExam
                      selectedAdvertisement={selectedAdvertisement}
                      userType={userType}
                    />
                  </span>
                )}

              {activeMenu === "Oral" &&
                selectedAdvertisement.willHaveOralExam && (
                  <span>
                    <OralExam
                      selectedAdvertisement={selectedAdvertisement}
                      userType={userType}
                    />
                  </span>
                )}

              {activeMenu === "Overall Results" &&
                allowToViewOverallResultMenu(selectedAdvertisement) && (
                  <span>
                    <OverallResults
                      selectedAdvertisement={selectedAdvertisement}
                      userType={userType}
                    />
                  </span>
                )}

              {activeMenu === "Teachers Placement" && (
                <span>
                  <TeachersPlacement
                    selectedAdvertisement={selectedAdvertisement}
                    userType={userType}
                  />
                </span>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(ExamSettingDialog);
