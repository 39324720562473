import React, { useRef, useState } from "react";
import Decimal from "decimal.js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import readXlsxFile from "read-excel-file";
import { connect } from "react-redux";
import {
  addActionPlanTemp,
  downloadActionPlanTemplate,
} from "../../../store/rbm/actions";
import ActionPlanTableView from "./ActionPlanTableView";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import TableSkeleton from "../../common/components/TableSkeleton";
import AddToDriveRoundedIcon from "@mui/icons-material/AddToDriveRounded";

const excelHeaders = [
  "OutputNo",
  "OutputName",
  "OutputWeight",
  "Budget",
  "IndicatorNo",
  "IndicatorName",
  "MeasurementUnit",
  "IndicatorWeight",
  "Baseline",
  "AnnualTarget",
  "Q1Target",
  "Q2Target",
  "Q3Target",
  "Q4Target",
  "ActivityNo",
  "ActivityName",
  "ActivityWeight",
  "ActivityQuarterNo",
  "TargetShare",
];

const AddActionPlan = ({
  onClose,
  open,
  loading,
  user,
  addActionPlanTemp,
  downloadActionPlanTemplate,
  selectedFiscalYear,
  setTotalRecods,
}) => {
  const [validationError, setValidationError] = useState([]);
  const [validatedData, setValidatedData] = useState([]);
  const [validatedDataAPI, setValidatedDataAPI] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [requestToSubmit, setRequestToSubmit] = useState(false);
  const fileInputRef = useRef(null);

  const handleCloseDialog = () => {
    setValidationError([]);
    setValidatedData([]);
    setValidatedDataAPI([]);
    setIsSubmitting(false);
    setRequestToSubmit(false);
    onClose();
    // handleClearClick()
  };

  const handleClearClick = () => {
    // Clear the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setValidationError([]);
    setValidatedData([]);
    setValidatedDataAPI([]);
  };

  const isNotNumber = (value) => {
    // Check if the value is a number.
    if (typeof value !== "number") {
      return true;
    }

    // Check if the value is NaN.
    if (isNaN(value)) {
      return true;
    }

    // Otherwise, the value is a valid number.
    return false;
  };

  const getSplitValue = (value) => {
    if (typeof value === "string") return value.split(",");
    if (typeof value === "number") return value.toString().split(",");
    return [];
  };

  const FC_HandleUploadedFile = (event) => {
    if (!event.target.files.length) {
      setValidationError([]);
      return;
    }
    let file = event.target.files[0];
    if (
      file.name.substr(-4).toLowerCase() === ".xls" ||
      file.name.substr(-5).toLowerCase() === ".xlsx"
    ) {
      FC_GenerateJSONData(file);
    } else {
      setValidationError([
        {
          type: "invalid-file",
          message: "Only Excel files are allowed!",
        },
      ]);
    }
  };
  const FC_GenerateJSONData = async (data) => {
    let hasError = [];
    try {
      if (data) {
        setLoadingExcel(true);
        const rows = await readXlsxFile(data);
        let tempData = [],
          tempDataAPI = [];
        // validate headers
        if (
          !!rows.length &&
          rows.length >= 2 &&
          rows[0][0] &&
          rows[0][4] &&
          rows[0][14]
        ) {
          if (
            rows[0][0].toLowerCase() !== "outputs" ||
            rows[0][1] !== null ||
            rows[0][2] !== null ||
            rows[0][3] !== null
          )
            hasError.push({
              type: "required-field",
              message:
                "Some Outputs columns can't be found, please check whether you didn't modify the template!",
            });

          if (
            rows[0][4].toLowerCase() !== "indicators" ||
            rows[0][5] !== null ||
            rows[0][6] !== null ||
            rows[0][7] !== null ||
            rows[0][8] !== null ||
            rows[0][9] !== null ||
            rows[0][10] !== null ||
            rows[0][11] !== null ||
            rows[0][12] !== null ||
            rows[0][13] !== null
          )
            hasError.push({
              type: "required-field",
              message:
                "Some Indicators columns can't be found, please check whether you didn't modify the template!",
            });

          if (
            rows[0][14].toLowerCase() !== "activities" ||
            rows[0][15] !== null ||
            rows[0][16] !== null ||
            rows[0][17] !== null ||
            rows[0][18] !== null
          )
            hasError.push({
              type: "required-field",
              message:
                "Some Activities columns can't be found, please check whether you didn't modify the template!",
            });

          const baseHeaders = JSON.stringify(excelHeaders);
          const receivedHeaders = JSON.stringify(rows[1]);
          if (baseHeaders !== receivedHeaders)
            hasError.push({
              type: "sub-headers",
              message:
                "File headers have been modified, please check whether you didn't modify the template!",
            });
        } else
          hasError.push({
            type: "no-data",
            message:
              rows.length >= 2
                ? "Template has been modified!"
                : "No valid data provided or Template has been modified!",
          });
        if (hasError.length) {
          setValidationError([...hasError]);
          setLoadingExcel(false);
          return true;
        }
        //   remove headers
        const rowData = rows.slice(2);

        let outputNo = 1,
          outputName = "",
          outputWeight = "",
          budget = 0;
        let indicatorNo = 1,
          indicatorName = "",
          measurementUnit = "",
          indicatorWeight = "",
          baseline = 0,
          annualTarget = 0,
          q1Target = 0,
          q2Target = 0,
          q3Target = 0,
          q4Target = 0,
          activityWeight = "",
          activityQNo = [],
          targetShares = [],
          previousNumbering = { output: 0, indicator: 0, activity: 0 };

        rowData.forEach((element, index) => {
          // getting output data
          budget = element[3] !== null ? +element[3] : budget;
          outputWeight = element[2] !== null ? element[2] : outputWeight;
          outputName = element[1] !== null ? element[1] : outputName;
          outputNo = element[0]
            ? outputNo !== +element[0]
              ? +element[0]
              : outputNo
            : outputNo;
          // handle output data validation
          // check numbering
          if (index === 0 && outputNo !== 1) {
            hasError.push({
              type: "invalid-value",
              message: "Invalid Output number of First OutputNo",
            });
          } else if (
            index !== 0 &&
            !isNotNumber(element[0]) &&
            outputNo - previousNumbering.output !== 1
          ) {
            hasError.push({
              type: "invalid-value",
              message: `Invalid Output number of OutputNo ${outputNo}`,
            });
          }
          if (
            previousNumbering.output === 0 ||
            (!isNotNumber(outputNo) && previousNumbering.output !== outputNo)
          ) {
            previousNumbering = { output: outputNo, indicator: 0, activity: 0 };
          }

          if (!outputName || !outputName.length)
            hasError.push({
              type: "invalid-value",
              message: "Invalid value of OutputName",
            });
          if (isNotNumber(budget))
            hasError.push({
              type: "invalid-value",
              message: "Invalid value of Budget",
            });

          // getting indicators
          q4Target = element[13] !== null ? parseFloat(element[13]) : q4Target;
          q3Target = element[12] !== null ? parseFloat(element[12]) : q3Target;
          q2Target = element[11] !== null ? parseFloat(element[11]) : q2Target;
          q1Target = element[10] !== null ? parseFloat(element[10]) : q1Target;
          annualTarget = element[9] !== null ? +element[9] : annualTarget;
          baseline = element[8] !== null ? +element[8] : baseline;
          indicatorWeight = element[7] !== null ? element[7] : indicatorWeight;
          measurementUnit = element[6] !== null ? element[6] : measurementUnit;
          indicatorName = element[5] !== null ? element[5] : indicatorName;

          // handle indicators data validation
          if (
            previousNumbering.indicator === 0 ||
            (!isNotNumber(element[4]) &&
              previousNumbering.indicator !== element[4])
          ) {
            indicatorNo = element[4]
              ? indicatorNo !== +element[4]
                ? +element[4]
                : indicatorNo
              : indicatorNo;
            previousNumbering = {
              ...previousNumbering,
              indicator: previousNumbering.indicator + 1,
              activity: 0,
            };
          }

          if (!indicatorName || !indicatorName.length)
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of IndicatorName on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (!measurementUnit || !measurementUnit.length)
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of MeasurementUnit on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(baseline))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of Baseline on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(annualTarget))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of AnnualTarget on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(q1Target))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value(${q1Target}) of Q1Target on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(q2Target))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of(${q2Target}) Q2Target on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(q3Target))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of(${q3Target}) Q3Target on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });
          if (isNotNumber(q4Target))
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of(${q4Target}) Q4Target on OutputNo ${outputNo} indicatorNo ${indicatorNo}`,
            });

          // handle activities data validation
          previousNumbering.activity += 1;
          if (!element[15] || !element[15].length)
            hasError.push({
              type: "invalid-value",
              message: `Invalid value of ActivityName on OutputNo ${outputNo} indicatorNo ${indicatorNo} activityNo ${previousNumbering.activity}`,
            });
          activityQNo = getSplitValue(element[17]);
          targetShares = getSplitValue(element[18]);

          if (
            activityQNo.length > 4 ||
            activityQNo.length < 1 ||
            activityQNo.some((quarterNo) => ![1, 2, 3, 4].includes(+quarterNo))
          )
            hasError.push({
              type: "invalid-value",
              message: `ActivityQuarterNo on OutputNo ${outputNo} indicatorNo ${indicatorNo} activityNo ${previousNumbering.activity} must contain values between 1 & 4`,
            });

          if (
            targetShares.length !== 1 &&
            targetShares.length !== activityQNo.length
          )
            hasError.push({
              type: "invalid-value",
              message: `TargetShare on OutputNo ${outputNo} indicatorNo ${indicatorNo} activityNo ${previousNumbering.activity} must contain 1 or ${activityQNo.length} values`,
            });

          activityQNo.forEach((activityItem, index) => {
            previousNumbering.activity += index;
            tempData.push({
              outputNo,
              outputName,
              outputWeight,
              budget,
              indicatorNo,
              indicatorName,
              measurementUnit,
              indicatorWeight,
              baseline,
              annualTarget,
              q1Target,
              q2Target,
              q3Target,
              q4Target,
              activityNo: previousNumbering.activity,
              activityName: element[15],
              activityWeight: element[16],
              activityQuarterNo: +activityItem,
              targetShare:
                targetShares.length === activityQNo.length
                  ? +targetShares[index]
                  : +targetShares[0],
            });
            tempDataAPI.push({
              outputNo,
              outputName,
              outputWeight:
                outputWeight === "High" ? 3 : outputWeight === "Medium" ? 2 : 1,
              budget,
              indicatorNo,
              indicatorName,
              measurementUnit,
              indicatorWeight:
                indicatorWeight === "High"
                  ? 3
                  : indicatorWeight === "Medium"
                  ? 2
                  : 1,
              baseline,
              annualTarget,
              q1Target,
              q2Target,
              q3Target,
              q4Target,
              activityNo: previousNumbering.activity,
              activityName: element[15],
              activityWeight:
                element[16] === "High" ? 3 : element[16] === "Medium" ? 2 : 1,
              activityQuarterNo: +activityItem,
              targetShare:
                targetShares.length === activityQNo.length
                  ? +targetShares[index]
                  : +targetShares[0],
            });
          });
        });

        if (tempData.length > 0) {
          setValidatedData(tempData);
          setValidatedDataAPI(tempDataAPI);
          if (hasError.length)
            setValidationError([...validatedData, ...hasError]);
          else
            setValidationError([
              ...validatedData,
              ...FC_AreDataValid(tempData),
            ]);
        } else {
          hasError.push({
            type: "no-data",
            message: "File is empty!",
          });
          setValidationError([...validatedData, ...hasError]);
        }

        setLoadingExcel(false);
      }
    } catch (error) {
      console.error(error);
      setValidationError([
        {
          type: "no-data",
          message: "No valid data provided or Template has been modified!",
        },
      ]);
      setLoadingExcel(false);
    }
  };

  const handleSubmit = () => {
    if (validatedDataAPI.length > 0 && !validationError.length) {
      addActionPlanTemp(validatedDataAPI, setTotalRecods, (status, res) => {
        setIsSubmitting(status);
        if (res) handleCloseDialog();
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        // maxWidth="xl"
        fullScreen
      >
        <DialogTitle className=" text-uppercase ">
          <span className="d-flex align-items-center">
            Upload Action Plan{" "}
            <span className="text-primary d-flex align-items-center">
              <span className="material-icons">chevron_right</span> FY:{" "}
              {selectedFiscalYear.name}
            </span>
          </span>

          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div
            className="text-center mb-1 text-uppercase"
            style={{ fontSize: "18px", fontWeight: 500 }}
          >
            Upload Action Plan
          </div>
          <div className="d-flex justify-content-center">
            <div className="custom-file" style={{ maxWidth: "750px" }}>
              <input
                name="selectFile"
                type="file"
                className="custom-file-input"
                accept=".xlsx, .xls, .csv"
                multiple={false}
                onChange={FC_HandleUploadedFile}
                onClick={handleClearClick}
                ref={fileInputRef}
              />
              <label className="custom-file-label">Choose file...</label>
            </div>
          </div>
          <div className="text-center mt-3">
            {!validationError.length && validatedData.length ? (
              <Typography className="d-inline alert alert-success">
                <VerifiedRoundedIcon /> Data verified successful!
              </Typography>
            ) : (
              <button
                className={`btn btn-link btn-sm  ${
                  loading || isSubmitting ? "disabled" : ""
                }`}
                disabled={loading || isSubmitting}
                onClick={() => downloadActionPlanTemplate()}
              >
                <DownloadIcon /> Download action plan template
              </button>
            )}
          </div>
        </DialogTitle>
        {/* {isSubmitting && <LinearProgress />} */}
        <DialogContent dividers>
          {loadingExcel ? (
            <div className="table-responsive">
              <table
                className="table table-sm table-bordered fixTableHead"
                style={{ fontSize: "10px" }}
              >
                <thead>
                  <tr>
                    <th className="text-center bg-output">Output No</th>
                    <th className="text-center bg-output">Output Name</th>
                    <th className="text-center bg-output">Output Weight</th>
                    <th className="text-center bg-output">Budget</th>
                    <th className="text-center bg-indicator">Indicator Name</th>
                    <th className="text-center bg-indicator">
                      Measurement Unit
                    </th>
                    <th className="text-center bg-indicator">
                      Indicator Weight
                    </th>
                    <th className="text-center bg-indicator">Baseline</th>
                    <th className="text-center bg-indicator">Annual Target</th>
                    <th className="text-center bg-indicator">Q1 Target</th>
                    <th className="text-center bg-indicator">Q2 Target</th>
                    <th className="text-center bg-indicator">Q3 Target</th>
                    <th className="text-center bg-indicator">Q4 Target</th>
                    <th className="text-center bg-activity">Activity No</th>
                    <th className="text-center bg-activity">Activity Name</th>
                    <th className="text-center bg-activity">Activity Weight</th>
                    <th className="text-center bg-activity">
                      Activity Quarter No
                    </th>
                    <th className="text-center bg-activity">Target Share</th>
                  </tr>
                </thead>
                <TableSkeleton cols={18} rows={20} />
              </table>
            </div>
          ) : (
            validationError.length > 0 && (
              <div className="my-3 border border-danger px-3 py-2">
                {validationError.map((error, index) => (
                  <div
                    className="d-flex align-items-start my-1"
                    style={{ fontSize: "12px" }}
                    key={index}
                  >
                    <div className="badge badge-danger my-1 mr-1">
                      {error.type}
                    </div>
                    <div>{error.message}</div>
                  </div>
                ))}
              </div>
            )
          )}

          {!loadingExcel && validatedData.length > 0 && (
            <ActionPlanTableView data={validatedData} />
          )}

          {!loadingExcel &&
            !validationError.length &&
            !validatedData.length && (
              <div className="d-flex align-items-center justify-content-center text-success h-100">
                <div className="d-flex flex-column align-items-center">
                  <AddToDriveRoundedIcon style={{ fontSize: "72px" }} />
                  <span className="mt-4" style={{ fontSize: "24px" }}>
                    Upload Action Plan Excel File
                  </span>
                </div>
              </div>
            )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <div className="d-flex justify-content-center">
            <Button
              color="success"
              variant="contained"
              type="button"
              disabled={
                validationError.length ||
                !validatedData.length ||
                loading ||
                isSubmitting
              }
              onClick={() => setRequestToSubmit(true)}
              style={{
                float: "right",
                borderRadius: "8px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              size="md"
            >
              <span className="material-icons mr-1">task_alt</span> Confirm
              Action Plan
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {requestToSubmit && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to confirm this Action Plan?`}
          setConfirmationDialog={() => setRequestToSubmit(false)}
          onYes={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user, selectedFiscalYear }) => {
  return {
    loading,
    user,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, {
  downloadActionPlanTemplate,
  addActionPlanTemp,
})(AddActionPlan);

const FC_AreDataValid = (data) => {
  const errors = [];
  const errorMap = new Map();

  data.forEach((item, index) => {
    const {
      outputWeight,
      budget,
      baseline,
      q1Target,
      q2Target,
      q3Target,
      q4Target,
      annualTarget,
      targetShare,
      indicatorWeight,
      activityWeight,
      indicatorNo,
      outputNo,
      activityQuarterNo,
    } = item;

    /**
     * Check outputWeight if are High, Medium or Low
     */
    if (!FC_ValidateWeight(outputWeight)) {
      const errorMessage = `The value of outputWeight(${outputWeight}) for Output ${outputNo} must be High, Medium or Low.`;
      const key = `${outputNo}-${indicatorNo}`;
      if (!errorMap.has(key)) {
        errors.push({
          type: "invalid-value",
          message: errorMessage,
        });
        errorMap.set(key, index);
      }
    }

    // Check field values if are greater then 0
    [
      baseline,
      q1Target,
      q2Target,
      q3Target,
      q4Target,
      annualTarget,
      targetShare,
    ].forEach((field, i) => {
      if (field !== undefined && field < 0) {
        const errorMessage = `The value of ${
          Object.keys(item)[i]
        } must be 0 or above.`;
        const key = `${outputNo}-${indicatorNo}`;
        if (!errorMap.has(key)) {
          errors.push({
            type: "invalid-value",
            message: errorMessage,
          });
          errorMap.set(key, index);
        }
      }
    });

    // Check budget if are greater or equal to 0
    if (budget !== undefined && budget < 0) {
      const errorMessage = `The value of budget must be 0 or above.`;
      const key = `${outputNo}-${indicatorNo}`;
      if (!errorMap.has(key)) {
        errors.push({
          type: "invalid-value",
          message: errorMessage,
        });
        errorMap.set(key, index);
      }
    }

    /**
     * Check indicatorWeight and activityWeight if are High, Medium or Low
     */
    [indicatorWeight, activityWeight].forEach((field, i) => {
      if (!FC_ValidateWeight(field)) {
        const fieldName = Object.keys(item)[i === 0 ? 7 : 15];
        const key = `${outputNo}-${indicatorNo}`;
        const existingErrorIndex = errorMap.get(key);
        if (
          existingErrorIndex === undefined &&
          (fieldName === "indicatorWeight" || fieldName === "activityWeight")
        ) {
          const errorMessage = `The value of ${fieldName} for indicatorNo ${indicatorNo}, outputNo ${outputNo} must be High, Medium or Low.`;
          errors.push({
            type: "invalid-value",
            message: errorMessage,
          });
          errorMap.set(key, index);
        }
      }
    });

    // Check sum of quarterly targets
    if (
      q1Target !== undefined &&
      q2Target !== undefined &&
      q3Target !== undefined &&
      q4Target !== undefined &&
      annualTarget !== undefined
    ) {
      const sumTargets = new Decimal(q1Target)
        .plus(q2Target)
        .plus(q3Target)
        .plus(q4Target);

      if (!sumTargets.equals(new Decimal(annualTarget))) {
        const errorMessage = `The sum(${sumTargets}/${new Decimal(
          annualTarget
        )}) of q1Target, q2Target, q3Target, q4Target for indicatorNo ${indicatorNo}, and outputNo ${outputNo} must be equal to annualTarget.`;
        const key = `${outputNo}-${indicatorNo}`;
        if (!errorMap.has(key)) {
          errors.push({
            type: "invalid-value",
            message: errorMessage,
          });
          errorMap.set(key, index);
        }
      }
    }

    // Check activityQuarterNo
    if (
      activityQuarterNo !== undefined &&
      (activityQuarterNo < 1 || activityQuarterNo > 4)
    ) {
      const errorMessage = `The value of activityQuarterNo at outputNo ${outputNo} and indicatorNo ${indicatorNo} must be between 1 and 4.`;
      const key = `${outputNo}-${indicatorNo}`;
      if (!errorMap.has(key)) {
        errors.push({
          type: "invalid-value",
          message: errorMessage,
        });
        errorMap.set(key, index);
      }
    }

    // Check quarterly targets
    const quarterlyTargets = [q1Target, q2Target, q3Target, q4Target];
    quarterlyTargets.forEach((target, quarter) => {
      if (target !== undefined) {
        const sumTargetShare = data
          .filter(
            (d) =>
              d.indicatorNo === indicatorNo &&
              d.outputNo === outputNo &&
              d.activityQuarterNo === quarter + 1
          )
          .reduce((sum, d) => sum.plus(d.targetShare || 0), new Decimal(0));

        if (!sumTargetShare.equals(new Decimal(target || 0))) {
          const errorMessage = `The sum(${sumTargetShare}/${new Decimal(
            target || 0
          )}) of targetShare for indicatorNo ${indicatorNo}, outputNo ${outputNo}, activityQuarterNo ${
            quarter + 1
          } must be equal to q${quarter + 1}Target.`;
          const key = `${outputNo}-${indicatorNo}-${activityQuarterNo}`;
          if (!errorMap.has(key)) {
            errors.push({
              type: "invalid-value",
              message: errorMessage,
            });
            errorMap.set(key, index);
          }
        }
      }
    });

    // Check annualTarget
    if (annualTarget !== undefined) {
      const sumTargetShare = data
        .filter((d) => d.indicatorNo === indicatorNo && d.outputNo === outputNo)
        .reduce((sum, d) => sum.plus(d.targetShare || 0), new Decimal(0));

      if (!sumTargetShare.equals(new Decimal(annualTarget))) {
        const errorMessage = `The sum(${sumTargetShare}/${new Decimal(
          annualTarget
        )}) of targetShare for indicatorNo ${indicatorNo}, outputNo ${outputNo} must be equal to annualTarget.`;
        const key = `${outputNo}-${indicatorNo}-${activityQuarterNo}`;
        if (!errorMap.has(key)) {
          errors.push({
            type: "invalid-value",
            message: errorMessage,
          });
          errorMap.set(key, index);
        }
      }
    }
  });

  return errors;
};

// validate weight of a provided field if are High, Medium or Low
const FC_ValidateWeight = (value) => {
  if (value !== undefined && !["High", "Medium", "Low"].includes(value)) {
    return false;
  }
  return true;
};
