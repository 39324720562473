export const defaultState = {
  loading: false,
  onWait: false,
  fetchingUser: true,
  strongPassword: true,
  user: {},
  ippisModules: [],
  selectedModule: {},
  env: null,
  selectedEmployeeProfilePicture: null,
  userProfilePicture: null,
  requestDocumentSignerDetail: null,
  requireAuth: false,
  hrMessage: "",
  userProfile: null,
};
