import { defaultState } from "./state";
import support from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...support };

const userFound = (userFoundState = defaultState.userFound, action) => {
  switch (action.type) {
    case types.USER_FOUND:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userFound;

    default:
      return userFoundState;
  }
};

const entitySectors = (
  entitySectorsState = defaultState.entitySectors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectors;

    default:
      return entitySectorsState;
  }
};

const entitySectorUnits = (
  entitySectorUnitsState = defaultState.entitySectorUnits,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectorUnits;

    default:
      return entitySectorUnitsState;
  }
};

// const costCenters = (costCentersState = defaultState.costCenters, action) => {
//   switch (action.type) {
//     case types.SET_COST_CENTERS:
//       return action.data;
//     case types.CLEAN_STATE:
//       return defaultState.costCenters;
//     default:
//       return costCentersState;
//   }
// };

const entitySectorCostCenters = (
  entitySectorCostCentersState = defaultState.entitySectorCostCenters,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_COST_CENTERS:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorCostCenters;
    default:
      return entitySectorCostCentersState;
  }
};
const entitySectorCreditors = (
  entitySectorCreditorsState = defaultState.entitySectorCreditors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_CREDITORS:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorCreditors;
    default:
      return entitySectorCreditorsState;
  }
};

const entitySectorPayrollTypes = (
  entitySectorPayrollTypesState = defaultState.entitySectorPayrollTypes,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_PAYROLL_TYPES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorPayrollTypes;
    default:
      return entitySectorPayrollTypesState;
  }
};

export default {
  userFound,
  entitySectors,
  entitySectorUnits,
  entitySectorCostCenters,
  entitySectorCreditors,
  entitySectorPayrollTypes,
};
