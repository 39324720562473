import { defaultState } from "./state";
import exit from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...exit };

const exitRequests = (
  exitRequestsState = defaultState.exitRequests,
  action
) => {
  switch (action.type) {
    case types.SET_EXIT_REQUESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.exitRequests;

    case types.UPDATE_REQUEST: {
      const tmpExitRequestState = [...exitRequestsState];
      const index = tmpExitRequestState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpExitRequestState[index] = action.data;

      return tmpExitRequestState;
    }

    default:
      return exitRequestsState;
  }
};

const blacklists = (blacklistsState = defaultState.blacklists, action) => {
  switch (action.type) {
    case types.ADD_BLACKLIST:
      const tmpBlacklistState = [...blacklistsState];
      tmpBlacklistState.unshift(action.data);
      return tmpBlacklistState;
    case types.SET_BLACKLISTS:
      return action.data;
    case types.UPDATE_BLACKLIST: {
      const tmpUpdateBlacklistState = [...blacklistsState];
      const index = tmpUpdateBlacklistState.findIndex(
        ({ blacklistId }) => blacklistId === action.data.blacklistId
      );
      tmpUpdateBlacklistState[index] = action.data;
      return tmpUpdateBlacklistState;
    }
    case types.DELETE_BLACKLIST:
      const tmpUpdateBlacklistState = [...blacklistsState];
      const index = tmpUpdateBlacklistState.findIndex(
        ({ blacklistId }) => blacklistId === action.data.blacklistId
      );

      tmpUpdateBlacklistState.splice(index, 1);

      return tmpUpdateBlacklistState;

    case types.CLEAN_STATE:
      return defaultState.blacklists;
    default:
      return blacklistsState;
  }
};

const searchEmployee = (
  searchEmployeeState = defaultState.searchEmployee,
  action
) => {
  switch (action.type) {
    case types.SET_SEARCH_EMPLOYEE:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.searchEmployee;
    default:
      return searchEmployeeState;
  }
};
const myRequests = (myRequestsState = defaultState.myRequests, action) => {
  switch (action.type) {
    case types.ADD_REQUEST:
      const tmpRequestState = [...myRequestsState];
      tmpRequestState.unshift(action.data);
      return tmpRequestState;

    case types.SET_MY_REQUESTS:
      return action.data;
    case types.DELETE_MY_REQUEST:
      const tmpRemoveMyRequestsState = [...myRequestsState];
      const index = tmpRemoveMyRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveMyRequestsState.splice(index, 1);

      return tmpRemoveMyRequestsState;
    case types.CLEAN_STATE:
      return defaultState.myRequests;

    default:
      return myRequestsState;
  }
};

const approvedRequests = (
  approvedRequestsState = defaultState.approvedRequests,
  action
) => {
  switch (action.type) {
    case types.SET_APPROVED_REQUESTS:
      return action.data;

    case types.UPDATE_REQUEST: {
      const tmpApprovedRequestsState = [...approvedRequestsState];

      const index = tmpApprovedRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index < 0) return tmpApprovedRequestsState;

      tmpApprovedRequestsState[index] = action.data;
      return tmpApprovedRequestsState;
    }
    case types.DELETE_MY_REQUEST:
      const tmpRemoveMyRequestsState = [...approvedRequestsState];
      const index = tmpRemoveMyRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveMyRequestsState.splice(index, 1);

      return tmpRemoveMyRequestsState;

    case types.CLEAN_STATE:
      return defaultState.approvedRequests;
    default:
      return approvedRequestsState;
  }
};

const exitTypes = (exitTypesState = defaultState.exitTypes, action) => {
  switch (action.type) {
    case types.SET_EXIT_TYPES: {
      return action.data;
    }
    case types.SET_HR_EXIT_TYPES: {
      return action.data;
    }
    case types.ADD_EXIT_TYPE: {
      return [...exitTypesState, action.data];
    }

    case types.CLEAN_STATE:
      return defaultState.exitTypes;
    default:
      return exitTypesState;
  }
};

const initiators = (initiatorsState = defaultState.initiators, action) => {
  switch (action.type) {
    case types.SET_INITIATORS: {
      return action.data;
    }
    case types.CLEAN_STATE:
      return defaultState.initiators;
    default:
      return initiatorsState;
  }
};

const blacklistReasons = (
  blacklistReasonsState = defaultState.blacklistReasons,
  action
) => {
  switch (action.type) {
    case types.SET_BLACKLIST_REASON: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.blacklistReasons;
    default:
      return blacklistReasonsState;
  }
};
const rehabilitationRequests = (
  rehabilitationRequestsState = defaultState.rehabilitationRequests,
  action
) => {
  switch (action.type) {
    case types.SET_REHABILITATION_REQUESTS:
      return action.data;
    case types.UPDATE_APPROVE_REHABILITATION: {
      const tmpUpdateRehabilitationState = [...rehabilitationRequestsState];
      const index = tmpUpdateRehabilitationState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpUpdateRehabilitationState[index] = action.data;
      return tmpUpdateRehabilitationState;
    }
    case types.CLEAN_STATE:
      return defaultState.rehabilitationRequests;
    default:
      return rehabilitationRequestsState;
  }
};

const employeeExitRequest = (
  employeeExitRequestState = defaultState.employeeExitRequest,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_EXIT_REQUEST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeExitRequest;

    default:
      return employeeExitRequestState;
  }
};
const exitRequestAttachments = (
  exitRequestAttachmentsState = defaultState.exitRequestAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_EXIT_REQUEST_ATTACHMENTS: {
      return action.data;
    }

    case types.ADD_EXIT_REQUEST_ATTACHMENT: {
      const tmpState = [...exitRequestAttachmentsState];
      tmpState.unshift(action.data);
      return tmpState;
    }
    case types.UPDATE_EXIT_REQUEST_ATTACHMENTS: {
      const tmpState = [...exitRequestAttachmentsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;
      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.exitRequestAttachments;
    default:
      return exitRequestAttachmentsState;
  }
};
export default {
  exitRequests,
  exitTypes,
  myRequests,
  approvedRequests,
  searchEmployee,
  blacklists,
  blacklistReasons,
  initiators,
  rehabilitationRequests,
  employeeExitRequest,
  exitRequestAttachments,
};
