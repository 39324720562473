import React from "react";

import FindUserDetails from "../../employees/components/FindUserDetails";

const UserSettingSupport = (props) => {
  return (
    <>
      <div className="mt-3">
        <FindUserDetails
          canVerifyProfile={true}
          canGiveAccess={true}
          canEditProfile={true}
          canEditEndDate={true}
          canApplyProrata={true}
          canEditIndexTopUp={true}
        />
      </div>
    </>
  );
};

export default UserSettingSupport;
