import React, { useState, useEffect } from "react";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, DialogActions, Button, Link } from "@mui/material";
import ReactPaginate from "react-paginate";
import {
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import {
  getExaminerOralExamResults,
  publishOralExamMarks,
  getApplicantOralSchema,
} from "../../../store/recruitment/actions";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import { connect, useDispatch } from "react-redux";
import AddOralMarkDialog from "./AddOralMarkDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import Groups2Icon from "@mui/icons-material/Groups2";
import recruitment from "../../../store/recruitment/action-types";
import AddOralSchemaDialog from "./AddOralSchemaDialog";
import Attandance from "./Attandance";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import OralTopupScoreDialog from "./OralTopupScoreDialog";
const timeItervals = 300000; //Set interval for every 5 minutes (300,000 milliseconds)

const PanelOralExamMarking = (props) => {
  const {
    loading,
    exam,
    setShowOralExamMarking,
    showOralExamMarking,
    getExaminerOralExamResults,
    examinerOralExamResults,
    publishOralExamMarks,
    showAddOralMarkDialog,
    showAddOralSchemaDialog,
    getApplicantOralSchema,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [showPublishOralMark, setShowPublishOralMark] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAttandanceLists, setShowAttandanceLists] = useState(false);
  const [disableAutoRefresh, setDisableAutoRefresh] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [filteredApplicants, setFilteredApplicants] = useState([
    ...examinerOralExamResults,
  ]);

  useEffect(
    () => {
      getExaminerOralExamResults(exam.examId, exam.panelId, setIsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exam]
  );

  // useEffect(() => {
  //   // Set interval for every 5 minutes (300,000 milliseconds)
  //   if (!disableAutoRefresh) {
  //     const intervalId = setInterval(() => {
  //       getExaminerOralExamResults(exam.examId,setIsLoading1);
  //     }, timeItervals);

  //     // Clear interval when the component is unmounted or when you want to stop it
  //     return () => clearInterval(intervalId);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // // Empty dependency array means it runs once on mount

  const onClose = () => {
    setShowOralExamMarking(false);
  };

  useEffect(() => {
    const filtered = [...examinerOralExamResults].filter(
      ({ lastName, firstName, phoneNumber }) =>
        (lastName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (firstName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (phoneNumber || "").toLowerCase().includes(searchTeam.toLowerCase())
      // unitName.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredApplicants(filtered);
    paginate(filtered);
  }, [searchTeam, examinerOralExamResults]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredApplicants);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, examinerOralExamResults, filteredApplicants]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredApplicants.length;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    if (!term) setDisableAutoRefresh(true);
    else setDisableAutoRefresh(false);
    setSearchTeam(term);
  };

  const doesExaminerAllowToPublish = () => {
    if (
      !loading &&
      !!examinerOralExamResults.length &&
      examinerOralExamResults.filter(({ isMarked }) => !isMarked).length ===
        0 &&
      examinerOralExamResults.filter(({ isPublished }) => !!isPublished)
        .length === 0
    )
      return true;
    else return false;
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showOralExamMarking}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Oral exam</span>
            <span className="mr-0 ml-0" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "7px" }}
              >
                chevron_right
              </span>
            </span>

            <span className="ml-1 text-success">{exam.positionName}</span>
            {exam.panelName && (
              <>
                <span className="mr-0 ml-2" style={{ color: "#2a7790" }}>
                  |
                </span>
                <span className="badge badge-primary ml-2">
                  {exam.panelName} Panel
                </span>
              </>
            )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ display: "flex", width: "100%" }}>
            {/*  */}

            <div
              className="w-100"
              style={{
                height: "520px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    // padding: ".6em",
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-4 mb-2">
                      <Typography variant="h6" noWrap component="div">
                        <span style={{ color: "#2a7790" }}>
                          <Groups2Icon
                            className="mr-2"
                            style={{ color: "rgb(7, 142, 206)" }}
                          />
                        </span>

                        <span>
                          Attended Candidates ({filteredApplicants.length})
                        </span>
                      </Typography>
                    </div>

                    <div className="col-lg-8 mb-2">
                      <div className="d-flex justify-content-end">
                        <Button
                          title="Reload"
                          // disabled={!examinerOralExamResults.length}
                          onClick={() =>
                            getExaminerOralExamResults(
                              exam.examId,
                              exam.panelId,
                              setIsLoading
                            )
                          }
                          variant="contained"
                          size="small"
                          color="info"
                          disableElevation
                          className="mr-2 "
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                          }}
                        >
                          <span className="material-icons">refresh</span>
                        </Button>
                        <Button
                          onClick={() => setShowAttandanceLists(true)}
                          variant="contained"
                          size="small"
                          color="primary"
                          disableElevation
                          className="mr-2 "
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                          }}
                        >
                          <span className="material-icons">add</span>Attendance
                        </Button>

                        <Button
                          onClick={() => setShowPublishOralMark(true)}
                          variant="contained"
                          size="small"
                          color="success"
                          disableElevation
                          className="mr-2"
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                          }}
                        >
                          <span className="material-icons">done_all</span>{" "}
                          Confirm Marking
                        </Button>

                        <div className="ml-2">
                          <SearchBox
                            disabled={!examinerOralExamResults.length}
                            onSearch={onSearch}
                            placeholder="Search…"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div
                className="mt-3"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col" className="text-left">
                          Names
                        </th>

                        <th
                          scope="col"
                          className="text-center"
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                        >
                          Phone Number
                        </th>

                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center"
                        >
                          Behavior
                        </th>
                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center text-truncate"
                        >
                          TechnicalKnowledge
                        </th>
                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center text-truncate"
                        >
                          GeneralKnowledge
                        </th>
                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center text-truncate"
                        >
                          CommunicationSkills
                        </th>
                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center text-truncate"
                        >
                          WrittenSkills
                        </th>

                        <th
                          scope="col"
                          colSpan={2}
                          style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}
                          className="text-center"
                        >
                          TotalOralScore
                        </th>
                        <th scope="col" colSpan={2} className="text-center"  style={{
                            borderRight: "1px solid rgb(150, 171, 181)",
                          }}>
                          Top-upScore
                        </th>
                        {!!exam.willHaveWrittenExam && (
                          <th scope="col" className="text-center">
                            TotalWrittenScore
                          </th>
                        )}

                        <th scope="col" className="text-center">
                          TotalScore
                        </th>

                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <TableSkeleton cols={19} />
                    ) : (
                      <tbody>
                        <BackdropLoader isLoading={isLoading1} />
                        {paginatedItems.map((oralExamMark, index) => (
                          <PanelOralExamMarkingCard
                            oralExamMark={oralExamMark}
                            getApplicantOralSchema={getApplicantOralSchema}
                            oralExam={exam}
                            key={index}
                          />
                        ))}
                      </tbody>
                    )}
                  </table>

                  {showAttandanceLists && (
                    <Attandance
                      showAttandanceLists={showAttandanceLists}
                      selectedExam={exam}
                      examTypeId="2"
                      setShowAttandanceLists={setShowAttandanceLists}
                    />
                  )}
                  <NoRecordMessage
                    isEmpty={!filteredApplicants.length && !isLoading}
                    title="Not attend candidates found"
                  />

                  {showAddOralMarkDialog && <AddOralMarkDialog />}
                  {showAddOralSchemaDialog && <AddOralSchemaDialog />}
                </CardContent>
              </div>

              {showPublishOralMark && (
                <ConfirmationDialog
                  confirmationDialog={showPublishOralMark}
                  message={`Are you sure you want to confirm oral marking @${exam.positionName}?`}
                  setConfirmationDialog={setShowPublishOralMark}
                  onYes={() => {
                    publishOralExamMarks(exam.examId, exam.panelId, onClose);
                  }}
                />
              )}
            </div>
          </Box>
        </DialogContent>

        {!!paginatedItems.length &&
          examinerOralExamResults.length >= itemsPerPage && (
            <DialogActions className="d-flex justify-content-center py-1">
              <div
                className="justify-content-center mt-2"
                style={{ overflow: "hidden" }}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            </DialogActions>
          )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  examinerOralExamResults,
  showAddOralMarkDialog,
  showAddOralSchemaDialog,
}) => {
  return {
    user,
    loading,
    examinerOralExamResults,
    showAddOralMarkDialog,
    showAddOralSchemaDialog,
  };
};

export default connect(mapStateToProps, {
  getExaminerOralExamResults,
  publishOralExamMarks,
  getApplicantOralSchema,
})(PanelOralExamMarking);

const PanelOralExamMarkingCard = (props) => {
  const { oralExamMark, getApplicantOralSchema, oralExam } = props;
  const dispatch = useDispatch();

  const [showTopupScore, setShowTopupScore] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {oralExamMark.userId}
        </th>
        <td className="text-left">
          {" "}
          {oralExamMark.firstName} {oralExamMark.lastName}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.phoneNumber}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.isMarked ? oralExamMark.examinerScoreOnBehaviour : "-"}
          {oralExamMark.isMarked ? "/" + oralExamMark.markOnBehaviour : ""}
        </td>
        <td
          className="text-center"
          scope="row"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.isMarked ? oralExamMark.scoreOnBehaviour : "-"}
          {oralExamMark.isMarked ? "/" + oralExamMark.markOnBehaviour : ""}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.isMarked
            ? oralExamMark.examinerScoreOnTechnicalKnowledge
            : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnTechnicalKnowledge
            : ""}
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.isMarked ? oralExamMark.scoreOnTechnicalKnowledge : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnTechnicalKnowledge
            : ""}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.isMarked
            ? oralExamMark.examinerScoreOnGeneralKnowledge
            : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnGeneralKnowledge
            : ""}
        </td>
        <td
          className="text-center "
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.isMarked ? oralExamMark.scoreOnGeneralKnowledge : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnGeneralKnowledge
            : ""}
        </td>
        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.isMarked
            ? oralExamMark.examinerScoreOnCommunicationSkills
            : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnCommunicationSkills
            : ""}
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.isMarked
            ? oralExamMark.scoreOnCommunicationSkills
            : "-"}
          {oralExamMark.isMarked
            ? "/" + oralExamMark.markOnCommunicationSkills
            : ""}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.isMarked
            ? oralExamMark.examinerScoreOnWrittenSkills
            : "-"}
          {oralExamMark.isMarked ? "/" + oralExamMark.markOnWrittenSkills : ""}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.isMarked ? oralExamMark.scoreOnWrittenSkills : "-"}
          {oralExamMark.isMarked ? "/" + oralExamMark.markOnWrittenSkills : ""}
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          <>
            {oralExamMark.isMarked ? (
              <div className="text-center">
                {oralExamMark.examinerTotalScore}/{oralExamMark.totalMarks}
              </div>
            ) : (
              "-"
            )}
          </>
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          <>
            {oralExamMark.isMarked ? (
              <div className="text-center">
                {oralExamMark.totalOralScore}/{oralExamMark.totalMarks}
              </div>
            ) : (
              "-"
            )}
          </>
        </td>

        <td
          className="text-center"
          style={{ borderRight: "1px solid rgb(150, 171, 181)" }}
        >
          {oralExamMark.wasExamMarkingPublished ? (
            <>
              {oralExamMark.examinerTopupScore} <br />
              <Link
                className=" my-sm-0"
                to="#"
                onClick={() => setShowTopupScore(true)}
              >
                {oralExamMark.examinerTopupScore ? (
                  <> Update top-up</>
                ) : (
                  <> Add top-up</>
                )}
              </Link>
            </>
          ) : (
            <>-</>
          )}
        </td>
        <td
          className="text-center"
          style={{
            backgroundColor: "#f0f2f5",
            borderRight: "1px solid rgb(150, 171, 181)",
          }}
        >
          {oralExamMark.wasExamMarkingPublished ? (
            <>{oralExamMark.topupScore}</>
          ) : (
            <>-</>
          )}
        </td>

        {!!oralExam.willHaveWrittenExam && (
          <td className="text-center">
            <>
              {oralExamMark.isMarked ? (
                <div className="text-center">
                  {oralExamMark.totalWrittenScore}/
                  {oralExamMark.totalWrittenMarks}
                </div>
              ) : (
                "-"
              )}
            </>
          </td>
        )}

        <td className="text-center">
          <>
            {oralExamMark.isMarked ? (
              <div
                className="text-center"
                style={{
                  margin: "0 auto",
                  padding: "2px",
                  width: "80px",
                  height: "auto",
                  border: `solid 3px ${
                    oralExamMark.totalScore >= 25 ? "#28a745" : "#dc3545"
                  }`,
                  borderRadius: "50px",
                  textAlign: "center",
                }}
              >
                {oralExamMark.totalScore}/100
                {/* <PercentIcon style={{ fontSize: "13px" }} /> */}
              </div>
            ) : (
              "-"
            )}
          </>
        </td>

        <td className="text-center">
          <button
            // disabled={oralExamMark.isPublished}
            className={
              oralExamMark.isMarked ? "btn btn-primary" : "btn btn-secondary"
            }
            onClick={() => {
              dispatch({
                type: recruitment.SET_APPLICANT_ORAL_EXAM_RESULT,
                data: oralExamMark,
              });
              getApplicantOralSchema(
                oralExamMark.examId,
                oralExamMark.testForWrittenSkills
              );
            }}
          >
            Marking
          </button>
        </td>
      </tr>
      {showTopupScore && (
        <OralTopupScoreDialog
          showTopupScore={showTopupScore}
          setShowTopupScore={setShowTopupScore}
          oralExamMark={oralExamMark}
        />
      )}
    </>
  );
};
