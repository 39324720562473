import React, { useState } from "react";

import ActiveContracts from "./pages/ActiveContracts";
import ExpiredContracts from "./pages/ExpiredContracts";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import { Box, Tab, Tabs } from "@mui/material";

const Layout = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [searchTeam, setSearchTeam] = useState("");
  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <React.Fragment>
      <div className="mb-3 d-flex justify-content-center pl-5">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="Contracts tabs"
        >
          <Tab label="Active contracts" {...a11yProps(0)} />
          <Tab
            className="text-danger"
            label="Expired contracts"
            {...a11yProps(1)}
          />
        </Tabs>
      </div>
      <div
        style={{
          marginLeft: "1em",
          marginRight: "1em",
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "65vh",
          overflowY: "scroll",
        }}
      >
        <div className="col-sm-12 ">
          <TabPanel value={value} index={0}>
            <React.Fragment>
              <ActiveContracts />
            </React.Fragment>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <React.Fragment>
              <ExpiredContracts />
            </React.Fragment>
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
