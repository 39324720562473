import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { addOrUpdateUserExperience } from "../../../store/profile/actions";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

const EducationFormDialog = (props) => {
  const {
    loading,
    onShow,
    onClose,
    selectedExperience,
    lookup,
    addOrUpdateUserExperience,
    experienceForm,
    setExperienceForm,
    userProfile
  } = props;

  const [errors, setErrors] = useState({
    descriptionHasError: false,
    employerNameHasError: false,
    institutionEmailHasError: false,
    institutionPhoneNumberHasError: false,
    jobTitleHasError: false,
    fromDateHasError: false,
    toDateHasError: false,
    fileHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      descriptionHasError: false,
      employerNameHasError: false,
      institutionEmailHasError: false,
      institutionPhoneNumberHasError: false,
      jobTitleHasError: false,
      fromDateHasError: false,
      toDateHasError: false,
      fileHasError: false,
      hasError: false,
    };

    if (!experienceForm.jobTitle) {
      error.jobTitleHasError = true;
      error.hasError = true;
    }

    if (!experienceForm.employerName) {
      error.employerNameHasError = true;
      error.hasError = true;
    }

    if (!experienceForm.institutionEmail) {
      error.institutionEmailHasError = true;
      error.hasError = true;
    }

    if (!experienceForm.institutionPhoneNumber) {
      error.institutionPhoneNumberHasError = true;
      error.hasError = true;
    }

    if (!experienceForm.fromDate || !(experienceForm.fromDate < new Date())) {
      error.fromDateHasError = true;
      error.hasError = true;
    }

    if (
      (!experienceForm.toDate ||
        !(experienceForm.toDate < new Date()) ||
        !(experienceForm.toDate > experienceForm.fromDate)) &&
      !experienceForm.isCurrentlyActive
    ) {
      error.toDateHasError = true;
      error.hasError = true;
    }

    if (!experienceForm.description) {
      error.descriptionHasError = true;
      error.hasError = true;
    }

    // if (!experienceForm.file) {
    //   error.fileHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    if (!formValidator()) {
      const formData = new FormData();

      formData.append("id", selectedExperience?.id || "");
      formData.append("description", experienceForm.description);
      formData.append("institutionEmail", experienceForm.institutionEmail);
      formData.append("userId", userProfile.id);
      
      formData.append(
        "institutionPhoneNumber",
        experienceForm.institutionPhoneNumber
      );
      formData.append("jobTitle", experienceForm.jobTitle);
      formData.append("employerName", experienceForm.employerName);

      formData.append(
        "fromDate",
        moment(experienceForm.fromDate).format("YYYY-MM-DD")
      );

      formData.append(
        "toDate",
        experienceForm.toDate
          ? moment(experienceForm.toDate).format("YYYY-MM-DD")
          : ""
      );

      formData.append("isCurrentlyActive", +experienceForm.isCurrentlyActive);
      experienceForm.file && formData.append("file", experienceForm.file[0]);

      addOrUpdateUserExperience(formData, onClose);
    }
  };

  useEffect(() => {
    if (!!selectedExperience)
      setExperienceForm({
        description: selectedExperience.responsibilities,
        employerName: selectedExperience.institutionName,
        institutionEmail: selectedExperience.institutionEmail,
        institutionPhoneNumber: selectedExperience.institutionPhone,
        jobTitle: selectedExperience.positionName,
        fromDate: new Date(selectedExperience.fromDate),
        toDate: !!selectedExperience.toDate
          ? new Date(selectedExperience.toDate)
          : null,
        isCurrentlyActive:
          selectedExperience.toDate === null
            ? true
            : selectedExperience.isCurrentlyActive,
        file: null,
      });
  }, [selectedExperience]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedExperience ? "Add " : "Update "} experience
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className=" col-12 mb-3">
                <TextField
                  fullWidth
                  label="Job Title"
                  placeholder="Job Title"
                  id="job-title"
                  value={experienceForm.jobTitle}
                  size="small"
                  onChange={(e) => {
                    setExperienceForm({
                      ...experienceForm,
                      jobTitle: e.target.value,
                    });
                    setErrors({ ...errors, jobTitleHasError: false });
                  }}
                  error={errors.jobTitleHasError}
                />
                {errors.jobTitleHasError && (
                  <small className="text-danger">Job title is required</small>
                )}
              </div>
              <div className="col-12 mb-3">
                <TextField
                  fullWidth
                  label="Employer Name"
                  placeholder="Employer Name"
                  id="employee-names"
                  value={experienceForm.employerName}
                  size="small"
                  onChange={(e) => {
                    setExperienceForm({
                      ...experienceForm,
                      employerName: e.target.value,
                    });
                    setErrors({ ...errors, employerNameHasError: false });
                  }}
                  error={errors.employerNameHasError}
                />
                {errors.employerNameHasError && (
                  <small className="text-danger">
                    Employer name is required
                  </small>
                )}
              </div>

              <div className="form-group col-12 col-md-6">
                <TextField
                  fullWidth
                  label="Employer email"
                  placeholder="Employer email"
                  id="employee-email"
                  value={experienceForm.institutionEmail}
                  size="small"
                  onChange={(e) => {
                    setExperienceForm({
                      ...experienceForm,
                      institutionEmail: e.target.value,
                    });
                    setErrors({ ...errors, institutionEmailHasError: false });
                  }}
                  error={errors.institutionEmailHasError}
                />
                {errors.institutionEmailHasError && (
                  <small className="text-danger">
                    Employer email is required
                  </small>
                )}
              </div>

              <div className="col-12 col-md-6 mb-3">
                <TextField
                  fullWidth
                  label="Employer phone number"
                  placeholder="Employer phone number"
                  id="employee-phone"
                  value={experienceForm.institutionPhoneNumber}
                  size="small"
                  onChange={(e) => {
                    setExperienceForm({
                      ...experienceForm,
                      institutionPhoneNumber: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      institutionPhoneNumberHasError: false,
                    });
                  }}
                  error={errors.institutionPhoneNumberHasError}
                />
                {errors.institutionPhoneNumberHasError && (
                  <small className="text-danger">
                    Employer phone number is required
                  </small>
                )}
              </div>

              <div className="col-12 col-md-6 mb-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    allowSameDateSelection
                    maxDate={new Date()}
                    label="From date [ dd/MM/yyyy ]"
                    value={experienceForm.fromDate}
                    onChange={(fromDate) => {
                      setExperienceForm({
                        ...experienceForm,
                        fromDate,
                        toDate: null,
                      });

                      setErrors({ ...errors, fromDateHasError: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        name="fromDate"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.fromDateHasError && (
                  <small className="text-danger">Provide valid date</small>
                )}
              </div>

              <div className="col-12 col-md-6 mb-3 text-center">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={
                      !experienceForm.fromDate ||
                      experienceForm.isCurrentlyActive
                    }
                    inputFormat="dd/MM/yyyy"
                    allowSameDateSelection
                    minDate={experienceForm.fromDate}
                    maxDate={new Date()}
                    label="To date [ dd/MM/yyyy ]"
                    value={experienceForm.toDate}
                    onChange={(toDate) => {
                      setExperienceForm({
                        ...experienceForm,
                        toDate,
                      });

                      setErrors({ ...errors, toDateHasError: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        name="toDate"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.toDateHasError && (
                  <small className="text-danger">Provide valid date</small>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={experienceForm.isCurrentlyActive}
                      className="p-0 mx-1"
                    />
                  }
                  label="I currently work here"
                  onChange={() => {
                    setExperienceForm({
                      ...experienceForm,
                      isCurrentlyActive: !experienceForm.isCurrentlyActive,
                      toDate: !experienceForm.isCurrentlyActive
                        ? null
                        : experienceForm.toDate,
                    });

                    setErrors({ ...errors, toDateHasError: false });
                  }}
                  className="my-0"
                />
              </div>

              <div className="col-12 mb-3">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="experienceFile"
                    accept="application/pdf"
                    onClick={(e) => {
                      e.currentTarget.value = null;
                      setExperienceForm({ ...experienceForm, file: null });
                    }}
                    onChange={(e) => {
                      setExperienceForm({
                        ...experienceForm,
                        file: e.target.files,
                      });
                      setErrors({ ...errors, fileHasError: false });
                    }}
                  />
                  <label className="custom-file-label" htmlFor="experienceFile">
                    {experienceForm.file
                      ? experienceForm.file[0].name
                      : "Work certificate"}
                  </label>
                </div>

                {errors.fileHasError && (
                  <small className="text-danger">
                    Work certificate is required
                  </small>
                )}
              </div>

              <div className="form-group col-12">
                <TextField
                  fullWidth
                  label="Job responsibilities"
                  placeholder="Job responsibilities"
                  id="jobResponsibilities"
                  value={experienceForm.description || ""}
                  size="small"
                  multiline
                  minRows={4}
                  onChange={(e) => {
                    setExperienceForm({
                      ...experienceForm,
                      description: e.target.value,
                    });
                    setErrors({ ...errors, descriptionHasError: false });
                  }}
                  error={errors.descriptionHasError}
                />

                {errors.descriptionHasError && (
                  <small className="text-danger">
                    Job responsibilities is required
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Wait..." : !!selectedExperience ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ userProfile, loading, onWait, lookup }) => ({
  userProfile,
  loading,
  onWait,
  lookup,
});

export default connect(mapStateToProps, {
  addOrUpdateUserExperience,
})(EducationFormDialog);
