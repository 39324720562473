import Educations from "./components/Educations";
import Identity from "./components/Identity";
import Experiences from "./components/Experiences";
import Certificates from "./components/Certificates";
import Languages from "./components/Languages";
import Disabilities from "./components/Disabilities";
import Referees from "./components/Referees";
import ProfileSummary from "./components/ProfileStatus";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CVUpload from "./components/CVUpload";
import Publications from "./components/Publications";
import { connect, useDispatch } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";
import axios from "axios";
import { useEffect, useState } from "react";

const UserResumeDetail = (props) => {
  const {
    userProfile,
    canEdit = false,
    allowedToEditEmail = false,
    allowedToEditEmploymentDate = false,
  } = props;

  const [userProfilePicture, setUserProfilePicture] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_USER_CV", data: [] });
    dispatch({ type: "SET_USER_REFEREES", data: [] });
    dispatch({ type: "SET_USER_PUBLICATIONS", data: [] });
    dispatch({ type: "SET_USER_LANGUAGES", data: [] });
    dispatch({ type: "SET_USER_DISABILITIES", data: [] });
    dispatch({ type: "SET_USER_EXPERIENCES", data: [] });
    dispatch({ type: "SET_USER_EDUCATIONS", data: [] });
    dispatch({ type: "SET_USER_PROFILE_STATUS", data: {} });
  }, [userProfile]);

  useEffect(() => {
    if (!userProfilePicture) getUserProfilePicture(userProfile.idNumber || "");
  }, [userProfilePicture]);

  const getUserProfilePicture = async (idNumber) => {
    try {
      const { data } = await axios.get(
        "/api/auth/user-profile-picture/" + idNumber
      );

      setUserProfilePicture(data.photo);
    } catch (error) {}
  };

  return (
    <>
      <div className="row mx-0 mt-4">
        <div className="col-12 mb-3 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
          <div className="d-flex align-items-center justify-content-center w-100 rounded mb-4">
            <img
              src={
                userProfilePicture
                  ? `data:image/png;base64,${userProfilePicture}`
                  : defaultProfile
              }
              className="img-fluid rounded img-thumbnail mr-1"
              style={{
                display: "block",
                maxHeight: "192px",
              }}
              alt={``}
            />
          </div>
          <ProfileSummary canEdit={canEdit} />
        </div>
        <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-sm-0 px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary bg-light ">
            <div className="card-header rounded bg-light rounded-top font-weight-bold text-uppercase">
              <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between ">
                <Typography variant="h6">Profile</Typography>
                <Typography variant="h6" className="text-primary">
                  Id:{userProfile?.id}
                </Typography>
              </div>
            </div>
            <div className="card-body px-2 px-sm-3">
              <Identity
                canEdit={canEdit}
                allowedToEditEmail={allowedToEditEmail}
              />
              <Educations canEdit={canEdit} />
              <Experiences
                canEdit={canEdit}
                allowedToEditEmploymentDate={allowedToEditEmploymentDate}
              />
              <Certificates canEdit={canEdit} />
              <Disabilities canEdit={canEdit} />
              <Languages canEdit={canEdit} />
              <Publications canEdit={canEdit} />
              <Referees canEdit={canEdit} />
              <CVUpload showEdit canEdit={canEdit} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ userProfile }) => {
  return { userProfile };
};
export default connect(mapStateToProps, {})(UserResumeDetail);
