import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
  Divider,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { showError } from "../../toastify";
import axios from "axios";
import NoResults from "../../common/components/NoResults";
import SearchBox from "../../common/components/SearchBox";
import UserResume from "../../profile/UserResume";
import ApplicationCv from "../../common/components/ApplicationCv";
import RejectionReasonDialog from "./RejectionReasonDialog";
import BadgeIcon from "@mui/icons-material/Badge";
import { getUserDetails } from "../../../store/profile/actions";

const ShowApplicantCandidates = (props) => {
  const { user, showDialog, setShowDialog, application, getUserDetails } =
    props;

  const [loading, setLoading] = useState(false);
  const [searchCandidate, setSearchCandidate] = useState("");

  const [candidateList, setCandidateList] = useState([]);

  // ### PAGINATION
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedCandidates, setPaginatedCandidates] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getCandidateList();
  }, []);

  useEffect(() => {
    if (filteredData) {
      setPaginatedCandidates(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [filteredData, itemOffset, endOffset]);

  useEffect(() => {
    if (candidateList) {
      if (searchCandidate !== "") {
        const value = searchCandidate?.toLowerCase();
        setFilteredData(
          candidateList.filter(
            (item) =>
              (item.names || "").toLowerCase().includes(value) ||
              item.statusName.toLowerCase().startsWith(value) ||
              (item.isMe && value === "me")
          )
        );
      } else {
        setFilteredData(candidateList);
      }
    }
  }, [candidateList, searchCandidate]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      (filteredData ? filteredData.length : 1);
    setItemOffset(newOffset);
  };

  const getCandidateList = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/recruitment/all-candidates-shortlisted/${application.advertisementId}`
      );

      setCandidateList(data);

      setLoading(false);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch);
  };

  const [showAppealForm, setShowAppealForm] = useState(false);
  const [hideAppealButton, setHideAppealButton] = useState(false);
  const [showAppealPreviewDialog, setShowAppealPreviewDialog] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">All applications</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-2 d-flex flex-column flex-md-row justify-content-md-between ">
            <SearchBox
              placeholder="Search..."
              disabled={!candidateList || candidateList.length === 0}
              onSearch={setSearchCandidate}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="table-responsive">
            <table
              className={`table table-sm ${
                candidateList ? "table-striped" : ""
              }`}
            >
              <thead>
                <tr>
                  <th style={{ width: "14px", verticalAlign: "middle" }}>#</th>
                  <th>
                    <span className="d-flex  flex-wrap align-items-center">
                      <span className="mr-2">
                        Candidates{" "}
                        {!!candidateList.length && (
                          <span className="text-primary">
                            ({candidateList.length})
                          </span>
                        )}
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    Status
                  </th>
                  <th className="text-right" style={{ verticalAlign: "right" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && !candidateList.length ? (
                  Array.from(Array(10).keys()).map((item, index) => (
                    <tr align="left" key={index}>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                    </tr>
                  ))
                ) : paginatedCandidates.length ? (
                  paginatedCandidates.map((candidate, index) => (
                    <CItem
                      candidate={candidate}
                      key={getNo(candidate.id, candidateList, "id")}
                      index1={getNo(candidate.id, candidateList, "id")}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <NoResults />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* display pagination */}
          </div>
        </DialogContent>
        <DialogActions>
          {candidateList.length > itemsPerPage && (
            <div
              className="justify-content-center col-12 mb-3"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
        </DialogActions>
      </Dialog>

      {/* {showAppealForm && (
        <AppealForm
          showDialog={showAppealForm}
          setShowDialog={setShowAppealForm}
          application={application}
          appealLevelId={1}
          appealTypeId={1}
          onClose={onClose}
        />
      )} */}

      {/* {showAppealPreviewDialog && (
        <AppealPreviewDialog
          showDialog={showAppealPreviewDialog}
          setShowDialog={setShowAppealPreviewDialog}
          appealId={application.shortListAppealId}
          application={application}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { getUserDetails })(
  ShowApplicantCandidates
);

const CItem = (props) => {
  const { candidate, getUserDetails, loading, index1 } = props;
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);

  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };

  const [showRejectionReason, setShowRejectionReason] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <tr className={`${candidate.isMe ? "tr-bordered-blue" : ""}`}>
        <td className="text-right">{index1 + 1}</td>
        <td className="text-left">
          <span className="d-flex align-items-center">
            {candidate.names}
            {candidate.isMe && (
              <span className="badge badge-primary ml-1">Me</span>
            )}
          </span>
        </td>
        <td
          className={`text-right text-${
            candidate.statusId === 1
              ? "success"
              : [2, 3].includes(candidate.statusId)
              ? "danger"
              : ""
          }`}
        >
          {/* {!!candidate.isMe &&
                          !!application.shortListAppealId && (
                            <span
                              onClick={() => {
                                setShowAppealPreviewDialog(true);
                              }}
                              className="badge badge-pill text-warning font-weight-bold text-uppercase text-underline cursor-pointer"
                            >
                              Appealed
                            </span>
                          )} */}
          {candidate.statusName}
        </td>
        <td className="text-right">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplicationCv(true);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Application Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getUserDetails(candidate.userId, handleOpenCurrentProfile);
                }}
                className="text-dark font-weight-light"
              >
                <BadgeIcon className="mr-2 text-primary" />
                {loading ? "Wait...." : "Currently Profile"}
              </MenuItem>
            </span>

            {candidate.profileAccepted === false && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowRejectionReason(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  Reason of Rejection.
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
        {showApplicationCv && (
          <ApplicationCv
            showApplicationCv={showApplicationCv}
            setShowApplicationCv={setShowApplicationCv}
            application={candidate}
          />
        )}
        {showCurrentProfile && (
          <UserResume
            showProfileDialog={showCurrentProfile}
            setShowProfileDialog={setShowCurrentProfile}
          />
        )}
        {showRejectionReason && (
          <RejectionReasonDialog
            showRejectionReason={showRejectionReason}
            setShowRejectionReason={setShowRejectionReason}
            selectedApplication={candidate}
          />
        )}
      </tr>
    </>
  );
};
