import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from "@mui/material";
import React from "react";

import { useRequiredAuth } from "../../common/guard/requiredAuth";
import TempExamQuestion from "./TempExamQuestion";
import WrittenExamQuestion from "./WrittenExamQuestion";


const ManageWrittenExamQuestionDialog = (props) => {
  const {
    loading,
    exam,
    showUploadWrittenExamQuestionsForm,
    setShowUploadWrittenExamQuestionsForm,
    selectedAdvertisement
  } = props;
  const { hasPermissions } = useRequiredAuth();

  const onClose = () => {
    setShowUploadWrittenExamQuestionsForm(false);
  };
// console.log(exam);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUploadWrittenExamQuestionsForm}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Written Exam</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "6px" }}
              >
                chevron_right
              </span>
            </span>
            <span className="mr-2">Exam Questions</span>
            <span className="text-dark ">
              @{exam?.positionName}{exam?.advertisement?.requisition?.position?.name}
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            height:"600px",
          }}
          dividers
        >
   

          {!exam.hasExamQuestion && (
           <TempExamQuestion 
           selectedAdvertisement={selectedAdvertisement}
           setShowUploadWrittenExamQuestionsForm={setShowUploadWrittenExamQuestionsForm} exam={exam} />
          )}
           {!!exam.hasExamQuestion && (
           <WrittenExamQuestion setShowUploadWrittenExamQuestionsForm={setShowUploadWrittenExamQuestionsForm} exam={exam} />
          )}

         
        </DialogContent>
      
       
      </Dialog>
    </>
  );
};


export default ManageWrittenExamQuestionDialog;
