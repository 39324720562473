import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { addOrUpdateUserPublication } from "../../../store/profile/actions";

import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";

function isValidURL(string) {
  return string.match(
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
}

const getYears = (currentDate = new Date()) => {
  const d = new Date(currentDate);
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

const PublicationForm = (props) => {
  const {
    userProfile,
    loading,
    selectedPublication,
    lookup,
    addOrUpdateUserPublication,
    onShow,
    onClose,
  } = props;

  const [publicationType, setPublicationType] = useState(null);
  const [year, setYear] = useState(null);
  const [title, setTitle] = useState("");
  const [url, setURL] = useState("");

  useEffect(() => {
    setPublicationType(selectedPublication?.publicationType || null);
    setTitle(selectedPublication?.title || "");
    setURL(selectedPublication?.url || "");
    setYear(
      getYears().find(
        ({ name }) => name == selectedPublication?.publicationYear
      ) || null
    );
  }, []);

  const [errors, setErrors] = useState({
    publicationTypeHasError: false,
    yearHasError: false,
    titleHasError: false,
    urlHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      publicationTypeHasError: false,
      yearHasError: false,
      titleHasError: false,
      urlHasError: false,
      hasError: false,
    };

    if (!publicationType) {
      error.publicationTypeHasError = true;
      error.hasError = true;
    }

    if (!year) {
      error.yearHasError = true;
      error.hasError = true;
    }

    if (!title) {
      error.titleHasError = true;
      error.hasError = true;
    }

    if (!!url && !isValidURL(url)) {
      error.urlHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!formValidator())
      addOrUpdateUserPublication(
        {
          id: selectedPublication?.id || "",
          publicationTypeId: publicationType.id,
          publicationYear: year.name,
          userId:userProfile.id,
          title,
          url,
        },
        onClose
      );
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedPublication ? "Add " : "Update "} publication
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <TextField
                autoFocus
                fullWidth
                autoComplete="off"
                label="Publication title"
                placeholder="Publication title"
                value={title}
                size="small"
                onChange={(e) => {
                  setTitle(e.target.value);
                  setErrors({ ...errors, titleHasError: false });
                }}
                error={errors.titleHasError}
              />
              {errors.titleHasError && (
                <small className="text-danger">Title is required</small>
              )}
            </div>
            <div className="mb-3">
              <Autocomplete
                size="small"
                name="language"
                defaultValue={null}
                value={publicationType}
                options={lookup?.publicationTypes || []}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, publicationType) => {
                  setPublicationType(publicationType || null);
                  setErrors({ ...errors, publicationTypeHasError: false });
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, publicationType) => (
                  <Box component="li" {...props} key={publicationType.id}>
                    {publicationType.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Select publicationType"
                    placeholder="Select publicationType"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "name",
                    }}
                  />
                )}
              />
              {errors.publicationTypeHasError && (
                <small className="text-danger">
                  Publication type is required
                </small>
              )}
            </div>
            <div className="mb-3">
              <Autocomplete
                size="small"
                defaultValue={null}
                value={year}
                options={getYears()}
                disabled={loading}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, year) => {
                  setYear(year || null);
                  setErrors({ ...errors, yearHasError: false });
                }}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, year) => (
                  <Box component="li" {...props} key={year.id}>
                    {year.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "25px !important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px !important",
                    }}
                    {...params}
                    label="Year of publication"
                    placeholder="Year of publication"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "name", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors.yearHasError && (
                <small className="text-danger">
                  Year of publication is required
                </small>
              )}
            </div>

            <div>
              <TextField
                fullWidth
                autoComplete="off"
                label="URL (Optional)"
                placeholder="URL"
                value={url}
                size="small"
                onChange={(e) => {
                  setURL(e.target.value);

                  if (!!e.target.value && !isValidURL(e.target.value))
                    setErrors({
                      ...errors,
                      urlHasError: true,
                    });
                  else
                    setErrors({
                      ...errors,
                      urlHasError: false,
                    });
                }}
                error={errors.urlHasError}
              />

              <small className="d-block">Eg: http://example.com</small>

              {errors.urlHasError && (
                <small className="text-danger">URL is not valid.</small>
              )}
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Wait..." : !!selectedPublication ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ userProfile, loading, lookup }) => ({
  userProfile,
  loading,
  lookup,
});

export default connect(mapStateToProps, { addOrUpdateUserPublication })(
  PublicationForm
);
