import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  DialogActions,
  Autocomplete,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { showError } from "../../toastify";

import {
  getIndividualSupportingDocuments,
  saveIndividualSupportingDocument,
  removeIndividualSupportingDocument,
} from "../../../store/payroll/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const IndividualSupportingDocumentDialog = (props) => {
  const {
    loading,
    onWait,
    showDialog,
    setShowDialog,

    employee,
    changeReasons,
    individualSupportingDocuments,

    getIndividualSupportingDocuments,
    saveIndividualSupportingDocument,
    removeIndividualSupportingDocument,
    type,
  } = props;

  useEffect(() => {
    if (!!showDialog)
      getIndividualSupportingDocuments(type, employee.payrollChangeListId);
  }, [employee]);

  const [selectedReason, setSelectedReason] = useState(null);
  const [file, setFile] = useState(null);

  const [showAddNew, setShowAddNew] = useState(false);

  const onClose = () => {
    setShowDialog(null);
    setSelectedReason(null);
    setFile(null);
  };
  const onSave = () => {
    if (!!selectedReason && !!file) {
      const formData = new FormData();
      formData.append("payrollChangeListId", employee.payrollChangeListId);
      formData.append("changeReasonId", selectedReason?.id);
      formData.append("file", file);
      saveIndividualSupportingDocument(formData, () => {
        setShowAddNew(false);
        setSelectedReason(null);
        setFile(null);
      });
    } else showError("Please, fill all fields");
  };

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
        open={showDialog}
      >
        <DialogTitle className="text-primary pb-0 mb-0">
          Individual supporting documents
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="elevated rounded px-3 py-2 mt-0"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "25vh",
            }}
          >
            {type === "Temp" &&
              hasPermissions(["IS_HR", "CAN_GENERATE_PAYROLL"]) && (
                <Button
                  className="mb-2"
                  onClick={() => {
                    setShowAddNew(true);
                  }}
                  style={{
                    float: "left",
                    borderRadius: "8px",
                    height: "32px",
                    border: `1px solid rgb(7, 142, 206)`,
                    display: "flex",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                    backgroundColor: "rgb(7, 142, 206)",
                    "&:hover": {
                      backgroundColor: "#f0f2f5",
                    },

                    // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="sm"
                >
                  <span className="material-icons">add</span>
                </Button>
              )}

            {!!individualSupportingDocuments.length && (
              <table className="table table-striped  table-sm fixTableHead">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {individualSupportingDocuments.map(
                    (supportingDocument, index) => (
                      <SupportingDocumentItemCard
                        hasPermissions={hasPermissions}
                        index={index}
                        key={index}
                        supportingDocument={supportingDocument}
                        removeIndividualSupportingDocument={
                          removeIndividualSupportingDocument
                        }
                        loading={loading}
                        onWait={onWait}
                        type={type}
                      />
                    )
                  )}
                </tbody>
              </table>
            )}

            {!individualSupportingDocuments.length && (
              <div className=" text-center mt-5">
                <div className="container">
                  <p className="lead">
                    {loading ? "Loading data..." : "No record found"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={() => {
          setShowAddNew(false);
        }}
        open={showAddNew}
      >
        <DialogTitle className={`text-primary`}>
          <small className="">Add individual supporting document</small>{" "}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowAddNew(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="row">
            {" "}
            <div className="col-12">
              <Autocomplete
                fullWidth
                size="small"
                className="mt-3"
                id="changeReason"
                name="changeReason"
                defaultValue={null}
                value={selectedReason || null}
                options={changeReasons}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, reason) => {
                  setSelectedReason(reason || null);
                }}
                renderOption={(props, reason) => (
                  <Box component="li" {...props}>
                    {reason.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    placeholder="Change reason"
                    {...params}
                    label="Change reason"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
            <div className="col-12 mt-2 text-left">
              <small>Attachement (PDF)</small>

              <Button
                fullWidth
                component="label"
                style={{
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  required
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFile(file || null);
                  }}
                />
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <button
            onClick={onSave}
            type="button"
            disabled={loading}
            className="btn btn-primary text-uppercase  mr-3"
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  changeReasons,
  individualSupportingDocuments,
}) => {
  return {
    loading,
    onWait,
    changeReasons,
    individualSupportingDocuments,
  };
};
export default connect(mapStateToProps, {
  getIndividualSupportingDocuments,
  saveIndividualSupportingDocument,
  removeIndividualSupportingDocument,
})(IndividualSupportingDocumentDialog);

const SupportingDocumentItemCard = (props) => {
  const {
    index,
    supportingDocument,
    removeIndividualSupportingDocument,
    loading,
    onWait,
    type,
    hasPermissions,
  } = props;

  const [confirmRemoveDocument, setConfirmRemoveDocument] = useState(false);

  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    link.click();
  }

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{supportingDocument?.changeReason?.name}</td>
        <td className="text-right">
          <IconButton
            className="mr-2"
            size="small"
            color="primary"
            onClick={() => {
              downloadURI(
                "https://documents.ippis.rw/preview/" +
                  supportingDocument.documentReferenceNumber,
                supportingDocument.documentReferenceNumber
              );
            }}
          >
            <span className="material-icons">open_in_new</span>
          </IconButton>
          {type === "Temp" &&
            hasPermissions(["IS_HR", "CAN_GENERATE_PAYROLL"]) && (
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setConfirmRemoveDocument(true);
                }}
              >
                <span className="material-icons">delete_outline</span>
              </IconButton>
            )}
        </td>
      </tr>

      {confirmRemoveDocument && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveDocument}
          message={`Are you sure you want to remove this supporting document?`}
          setConfirmationDialog={setConfirmRemoveDocument}
          onYes={() => {
            removeIndividualSupportingDocument(
              supportingDocument.id,
              setConfirmRemoveDocument
            );
          }}
        />
      )}
    </>
  );
};
