import React from "react";
import Breadcrumb from "./Breadcrumb";
import ModulesMenu from "../../common/components/ModulesMenu";
import { Link, useLocation } from "react-router-dom";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { connect } from "react-redux";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: -10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const MenuBar = (props) => {
  const location = useLocation();
  const { noOfEmployeeWithholdRequests } = props;

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <nav>
        <div
          className="py-1 d-flex flex-wrap justify-content-between mt-0  align-items-center px-4"
          style={{
            backgroundColor: "#078ece",
            borderBottom: "1px solid #078ece",
            borderTop: "1px solid #078ece",
          }}
        >
          <Breadcrumb />

          <div className="d-flex flex-wrap justify-content-end align-items-center pl-2">
            {hasPermissions(["IS_HR"]) && (
              <span>
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  to="/payroll/requested-deductions"
                  className={`px-1  ${
                    location.pathname.includes("/payroll/requested-deductions")
                      ? "active"
                      : " "
                  }`}
                >
                  <StyledBadge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    color="error"
                    badgeContent={noOfEmployeeWithholdRequests}
                  >
                    <span className="text-truncate font-weight-bold menu-item">
                      Deduction requests
                    </span>
                  </StyledBadge>
                </Link>
                <HorizontalRuleIcon className="rotated" />
              </span>
            )}

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/user-guide"
              className={`  px-1 rounded mr-3 d-none d-md-inline  ${
                location.pathname.includes("/payroll/user-guide")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate font-weight-bold  menu-item d-flex">
                <LocalLibraryIcon />
                User-Guide
              </span>
            </Link>
          </div>
        </div>
      </nav>
      {/* <div style={{ marginTop: "0px" }}>
        <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
        <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
      </div> */}
    </>
  );
};

const mapStateToProps = ({ noOfEmployeeWithholdRequests }) => {
  return { noOfEmployeeWithholdRequests };
};

export default connect(mapStateToProps, {})(MenuBar);
