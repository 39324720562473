import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import {
  verifyEmployeeCaseFeedbackLetter,
  approveEmployeeCaseFeedbackLetter,
} from "../../../store/discipline/actions";
import { getHeadOfUnit } from "../../../store/structure/actions";
import {
  removeDuplicates,
  textCapitalized,
} from "../../common/components/Utils";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SignLetter from "../../Documents/LetterFormat/SignLetter";
import DisciplinaryProvideFeedbackForExplanation from "../../Documents/LetterFormat/DisciplinaryProvideFeedbackForExplanation";

const ViewProvidingExplanationFeedbackLetterDialog = (props) => {
  const {
    loading,
    showCaseReportingForm,
    setShowCaseReportingForm,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    verifyEmployeeCaseFeedbackLetter,
    caseRequest,
    enableToFillLetter,
    action = "EDIT",
    actionStatusId = 0,
    closeModal,
    approveEmployeeCaseFeedbackLetter,
    headOfUnit,
    getHeadOfUnit  
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [reason, setReason] = useState("");
  const textareaRef = useRef(null);
  const [showCaseReviewerForm, setShowCaseReviewerForm] = useState(false);
  const [showCaseApproverForm, setShowCaseApproverForm] = useState(false);

  const [showSubmitData, setShowSubmitData] = useState(false);

  const [pkiPassword, setPkiPassword] = useState("");

  const [explanationFeedbackLetterCC, setExplanationFeedbackLetterCC] =
    useState(
      caseRequest && !!caseRequest.explanationFeedbackLetterCC
        ? JSON.parse(caseRequest?.explanationFeedbackLetterCC || [])
        : []
    );


  const [formData, setFormData] = useState({
    caseId: caseRequest?.id || "",
    actionStatusId: actionStatusId,
    explanationFeedbackLetterNo: caseRequest?.explanationFeedbackLetterNo || "",
    explanationFeedbackLetterCC:
      caseRequest && !!caseRequest.explanationFeedbackLetterCC
        ? JSON.parse(caseRequest?.explanationFeedbackLetterCC || [])
        : [],

    explanationFeedbackContentLetter:
      caseRequest && !!caseRequest.explanationFeedbackContentLetter
        ? caseRequest.explanationFeedbackContentLetter
        : `I regret to inform you that after analysis, your explanation was found unsatisfactory and not convincing. Therefore, your case is submitted to the Internal Disciplinary Committee to start carrying out thorough administrative investigations on the administrative faults your alleged to commit;`,
  });

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
    if (!headOfUnit) getHeadOfUnit(caseRequest.unitId);
  }, []);

  useEffect(() => {
    // Initial height adjustment if there's any content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    // Initial height adjustment if there's any content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
    provisionalSuspensionEffectDateError: false,
    provisionalSuspensionReasonError: false,
    explanationFeedbackLetterNoError: false,
    provisionalSuspensionNoError: false,
    explanationFeedbackContentLetterError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      maxCharHasError: false,
      provisionalSuspensionEffectDateError: false,
      provisionalSuspensionReasonError: false,
      explanationFeedbackLetterNoError: false,
      provisionalSuspensionNoError: false,
      explanationFeedbackContentLetterError: false,
    };


    if ( !formData.explanationFeedbackContentLetter ) {
      error.explanationFeedbackContentLetterError = true;
      error.hasError = true;
    }


    setErrors(error);

    if (error.hasError) {
      showError("Please fill the required field");
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowCaseReportingForm(false);
  };
  const onCloseAll = () => {
    setShowCaseReportingForm(false);
    closeModal();
  };
  const onSave = () => {
    if (!formValidator()) {
      formData.explanationFeedbackLetterCC = JSON.stringify(
        explanationFeedbackLetterCC
      );
   
      formData.reason = reason;
      
      verifyEmployeeCaseFeedbackLetter(formData, onCloseAll);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCaseReportingForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Provide feedback on employee's explanation letter</span> /{" "}
            <span className="text-primary">
              {textCapitalized(caseRequest?.lastName)}
              {textCapitalized(caseRequest?.firstName)}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

       
        </DialogTitle>

        <DialogContent className="pb-2">
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
          
              <DisciplinaryProvideFeedbackForExplanation
                recipientName={
                  caseRequest.lastName + " " + caseRequest.firstName
                }
                locationName={caseRequest.approverLocation}
                recipientEmail={caseRequest.email}
                recipientPhone={caseRequest.phoneNumber}
                institutionName={caseRequest.entityName}
                enableToFillLetter={enableToFillLetter || false}
                docLetterNo={caseRequest.explanationFeedbackLetterNo}
                isProvisionSuspensionLetterRequired={
                  caseRequest.isProvisionSuspensionLetterRequired
                }
                provisionalSuspensionNo={formData.provisionalSuspensionNo}
                provisionalSuspensionEffectDate={moment(new Date()).format(
                  "LL"
                )}
                providingExplanationLetterDate={moment(
                  caseRequest.requestExplanationDocLetterDate
                ).format("LL")}
                providingExplanationLetterNo={
                  caseRequest.requestExplanationDocLetterNo
                }
                employeeExplanationLetterDate={moment(
                  caseRequest.employeeExplanationLetterDate
                ).format("LL")}
                subTitle={
                  "Dear " + (caseRequest.lastName + " " + caseRequest.firstName)
                }
                explanationFeedbackContentLetter={formData.explanationFeedbackContentLetter}
                explanationFeedbackContentLetterInput={
                  <>
                    <TextField
                      autoFocus
                      error={!!errors.explanationFeedbackContentLetterError}
                      value={formData.explanationFeedbackContentLetter}
                      placeholder={formData.explanationFeedbackContentLetter}
                      style={{ width: "100%" }}
                      inputProps={{
                        "aria-label": "add doc No",
                      }}
                      inputRef={textareaRef}
                      multiline
                      onChange={(e) => {
                        const explanationFeedbackContentLetter = e.target.value;
                        setFormData({
                          ...formData,
                          explanationFeedbackContentLetter,
                        });

                        setErrors({
                          ...errors,
                          explanationFeedbackContentLetterError: false,
                        });

                        if (textareaRef.current) {
                          textareaRef.current.style.height = "auto";
                          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
                        }
                      }}
                    />
                  </>
                }
                // effectiveDate={moment(formData.effectiveOn).format("LL")}
                competentAuthorityName={
                  caseRequest.decisionLevel === 1
                    ? headOfUnit?.names || ""
                    : !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail.competentAuthorityUser.names
                    : ""
                }
                competentAuthorityPosition={
                  caseRequest.decisionLevel === 1
                    ? headOfUnit?.positionName || ""
                    : !!requestDocumentSignerDetail
                    ? requestDocumentSignerDetail?.competentAuthorityUser
                        ?.positionName
                    : ""
                }
                ccSelectForm={
                  <>
                    <Autocomplete
                      size="small"
                      fullWidth
                      multiple
                      id="ccData"
                      options={entities.map(
                        ({ name, institutionEmail, id,ccName }) => ({
                          name,
                          institutionEmail,
                          id,ccName
                        })
                      )}
                      value={
                        Array.isArray(explanationFeedbackLetterCC)
                          ? explanationFeedbackLetterCC
                          : []
                      }
                      onChange={(e, cc) => {
                        const uniqueCc = removeDuplicates(cc);
                        setExplanationFeedbackLetterCC(uniqueCc);
                        const ccHasError = false;
                        setErrors({ ...errors, ccHasError });
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={
                              selected ||
                              (!!Array.isArray(explanationFeedbackLetterCC)
                                ? explanationFeedbackLetterCC
                                : []
                              ).find(({ id }) => id === option.id)
                            }
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Select the institution to copy"}
                          placeholder={"Select the institution to copy"}
                        />
                      )}
                    />

                    {errors.ccHasError && (
                      <small className="text-danger mb-1">
                        Please, select the institution to copy
                      </small>
                    )}
                  </>
                }
                ccData={
                  Array.isArray(explanationFeedbackLetterCC)
                    ? explanationFeedbackLetterCC
                    : []
                }
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          position: "relative",

                          width: "20%",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {((!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                          caseRequest.decisionLevel === 2) ||
                          (!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                            caseRequest.decisionLevel === 1)) &&
                        ["APPROVE"].includes(action) ? (
                          <>
                            <SignLetter />
                          </>
                        ) : (
                          <>
                            <span className="d-block text-danger">
                              Not signed
                            </span>
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          width: "20%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      ></td>
                    </tr>
                  </table>
                }
              />
           
          </Box>
        </DialogContent>

        {!!hasPermissions([
          "IS_HR",
          "IS_DOCUMENT_VERIFIER",
          "IS_HEAD_OF_UNIT",
          "IS_HEAD_OF_INSTITUTION",
        ]) &&
          enableToFillLetter && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
               
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
              
                      if (!formValidator()) {
                        if (["APPROVE"].includes(action)) {
                          setShowCaseApproverForm(true);
                        } else if (["VERIFY"].includes(action)) {
                          setShowCaseReviewerForm(true);
                        } else {
                          setShowSubmitData(true);
                        }
                      }
                    

                    // if (isProvisionSuspensionLetterRequired === 1) {
                    //   if (!formValidator()) {
                    //     if (activeStep === 0) setActiveStep(1);
                    //     if (activeStep === 1) {
                    //       if (["APPROVE"].includes(action)) {
                    //         setShowCaseApproverForm(true);
                    //       } else if (["VERIFY"].includes(action)) {
                    //         setShowCaseReviewerForm(true);
                    //       } else {
                    //         setShowSubmitData(true);
                    //       }
                    //     }
                    //   }
                    // }
                  }}
                  className="btn btn-xs"
                >
                  {loading
                    ? "Wait.."
                    : "Submit"}
                </Button>
              </DialogActions>
            </>
          )}
        {showCaseReviewerForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseReviewerForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className={`text-success`}>confirm</strong> this case?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReviewerForm}
            onYes={() => {
              onSave();
            }}
          />
        )}

        {showCaseApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showCaseApproverForm}
            message={
              <>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={setShowCaseApproverForm}
            onYes={() => {
              if (!formValidator()) {
                formData.explanationFeedbackLetterCC = JSON.stringify(
                  explanationFeedbackLetterCC
                );
               
                formData.password = pkiPassword;

                approveEmployeeCaseFeedbackLetter(formData, onCloseAll);
              }
            }}
          />
        )}
        {showSubmitData && (
          <ConfirmationDialog
            confirmationDialog={showSubmitData}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className={`text-success`}>report</strong> this case
                  to the Committee for Investigating?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowSubmitData}
            onYes={() => {
              onSave();
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
  headOfUnit,
  faults,
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
    headOfUnit,
    faults,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  verifyEmployeeCaseFeedbackLetter,
  approveEmployeeCaseFeedbackLetter,
  getHeadOfUnit,
})(ViewProvidingExplanationFeedbackLetterDialog);
