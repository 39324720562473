import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { a11yProps, TabPanel } from "../../common/components/TabPanel";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import { Toolbar, Tabs, Tab, Box } from "@mui/material";
import Requisitions from "../components/Requisitions";
import Advertisements from "../components/Advertisements";

const Recruitment = (props) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100 m-0 p-0"
          >
            <Toolbar>
              <div className="row no-gutter justify-content-between align-items-center pb-1 w-100">
                <div className="col-12 col-md-9">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Support recruitment tabs"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Requisitions" {...a11yProps(0)} />
                    <Tab label="Advertisements" {...a11yProps(1)} />
                  </Tabs>
                </div>
                <div className="col-12 col-md-3 text-right">
                  {/* {tabValue === 0 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCostCenter(true)}
                      title="Add New Cost coste"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Cost coste</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )} */}
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "0 1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <Requisitions />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Advertisements />
            </TabPanel>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {})(Recruitment);
