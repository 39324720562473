import {
  getApplicantsAttended,
  removeApplicantAttend,
} from "../../../store/recruitment/actions";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import PreLoader from "../../common/components/PreLoader";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { TextField } from "@mui/material";
const moment = require("moment");
const ApplicantsAttended = (props) => {
  const {
    loading,
    examId,
    examTypeId,
    applicantsAttended,
    getApplicantsAttended,
    removeApplicantAttend,
    roomId
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [applicantId, setApplicantId] = useState("0");
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [confirmRemoveApplicant, setConfirmRemoveApplicant] = useState(false);

  const [filteredApplicantsAttended, setFilteredApplicantsAttended] = useState([
    ...applicantsAttended,
  ]);

  useEffect(
    () => {
      getApplicantsAttended(examId, examTypeId,roomId?roomId:'0');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [examId, examTypeId,roomId]
  );

  useEffect(() => {
    const filtered = [...applicantsAttended].filter(
      ({ firstName, lastName, idNumber, phoneNumber }) =>
        firstName?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        lastName?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        idNumber?.toString().includes(searchTeam.toString()) ||
        phoneNumber?.toString().includes(searchTeam.toString())
    );

    setFilteredApplicantsAttended(filtered);
  }, [searchTeam, applicantsAttended]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const [reason, setReason] = useState("");
  return (
    <>
      <div className="row mt-0">
        <div className="col-12">
          <SearchBox
            onSearch={onSearch}
            disabled={!applicantsAttended.length}
            placeholder="Search for applicants…"
          />
        </div>

        <div className="col-12 mt-3">
          {!!applicantsAttended.length && (
            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    IDNumber
                    <span className="float-right text-right text-primary">attended({applicantsAttended.length})</span>
                  </th>
                  <th scope="col" className="text-left">
                    Names
                  </th>

                  <th scope="col" className="text-left">
                    PhoneNumber
                  </th>
                  {+examTypeId===1 &&  <th scope="col" className="text-left">
                    Center
                  </th>}
                  {+examTypeId===2 &&  <th scope="col" className="text-left">
                    Panel
                  </th>}
                  
                  <th scope="col" className="text-right">
                    Date
                  </th>

                  <th scope="col" className="text-right">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredApplicantsAttended.map((applicant, index) => (
                  <tr key={index}>
                    <td
                      scope="row"
                      style={{ backgroundColor: "#f0f2f5" }}
                      className="text-left"
                    >
                      {applicant.idNumber}
                    </td>

                    <td className="text-left">
                      {applicant.lastName} {applicant.firstName}
                    </td>
                    <td className="text-left"> {applicant.phoneNumber}</td>
                    {+examTypeId===1 && <td className="text-left"> {applicant?.examCenterName}</td>}
                    {+examTypeId===2 && <td className="text-left"> {applicant?.panelName}</td>}
                    <td className="text-right">
                      {moment(applicant.createdOn).format("YYYY-MM-DD HH:mm A")}
                    </td>
                    <td className="text-right">
                      <button
                        onClick={() => {
                          setSelectedApplicant(applicant);
                          setConfirmRemoveApplicant(true);
                        }}
                        disabled={
                          applicantId === applicant.id
                        }
                        className="btn btn-danger"
                      >
                        {applicantId === applicant.id ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <RemoveIcon />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <NoRecordMessage
            isEmpty={!filteredApplicantsAttended.length && !loading}
            title="No records found "
          />

          <PreLoader isLoading={loading && !applicantsAttended.length} />

          {confirmRemoveApplicant && (
            <ConfirmationDialog
            disabled={!reason}
              confirmationDialog={confirmRemoveApplicant}
              setConfirmationDialog={setConfirmRemoveApplicant}
              message={
                <>
                  <p>
                    Are you sure you want  remove{" "}
                    <strong className="text-primary">{selectedApplicant.firstName} {selectedApplicant.lastName}</strong> this
                    on attendance?
                  </p>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="Reason"
                    autoFocus
                    label="Type your reason .."
                    variant="outlined"
                    placeholder="Type your reason  .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </>
              }
              onYes={() => {
              removeApplicantAttend(
                  selectedApplicant,
                  examTypeId,
                  reason,
                  setApplicantId,
                  setConfirmRemoveApplicant,
                  setSelectedApplicant,
                  setReason
                );
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ loading, applicantsAttended }) => {
  return {
    loading,
    applicantsAttended,
  };
};
export default connect(mapStateToProps, {
  getApplicantsAttended,
  removeApplicantAttend,
})(ApplicantsAttended);
