import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditUserSignatureDialog from "./EditUserSignatureDialog";

const UserSignatureDialog = (props) => {
  const {
    userSignature,

    setUserSignature,
    isWaiting,
  } = props;

  const [showEditUserSignatureDialog, setShowEditUserSignatureDialog] =
    useState(false);

  return (
    <>
      <div className="px-2 py-5">
        <div className="row justify-content-center">
          <div className="col-12">
            {!userSignature && !isWaiting && (
              <div className="text-center mb-2">
                <span className="text-danger">Your signature was not set</span>
              </div>
            )}
            {!!userSignature && (
              <img
                style={{ width: "238px", border: "solid 1px #dae1e7" }}
                src={"data:image/jpg;base64," + userSignature.signature}
                className="rounded py-1"
              />
            )}

            <div className="text-center">
   
                <div className="">
                  <button
                    onClick={() => setShowEditUserSignatureDialog(true)}
                    type="button"
                    className="btn btn-sm btn-link"
                  >
                    {!!userSignature && userSignature.signature ? "Edit" : "Set"} your signature
                  </button>
                </div>
           
              {!!userSignature && userSignature.isVerified && (
                <div className="text-success" style={{ fontSize: "10px" }}>
                  <span
                    className="material-icons text-success mt-2"
                    style={{ fontSize: "12px" }}
                  >
                    verified
                  </span>{" "}
                  Confirmed
                </div>
              )}
            </div>
          </div>
        </div>
        <EditUserSignatureDialog
          showEditUserSignatureDialog={showEditUserSignatureDialog}
          setShowEditUserSignatureDialog={setShowEditUserSignatureDialog}
          setUserSignature={setUserSignature}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(UserSignatureDialog);
