import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import NoResults from "../../../common/components/NoResults";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import { getApplicationReferees } from "../../../../store/profile/actions";
import moment from "moment";
export const Referees = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationReferees,
    getApplicationReferees,
    applicationId,
  } = props;

  useEffect(() => {
    getApplicationReferees(applicationId);
  }, []);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="references">
        <CardContent>
          {loading && !onWait && applicationReferees.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                {/* title */}
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Referees
                  </Typography>
                </div>
              </div>

              {applicationReferees.length === 0 ? (
                <NoResults />
              ) : (
                applicationReferees.map((referee, index) => (
                  <RefereeItemCard
                    key={referee.id}
                    index={index}
                    referee={referee}
                    loading={loading}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = ({ env, loading, onWait, applicationReferees }) => ({
  env,
  loading,
  onWait,
  applicationReferees,
});

const mapDispatchToProps = {
  getApplicationReferees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referees);

const RefereeItemCard = (props) => {
  const { env, loading, referee } = props;

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card`}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          title={
            <Typography>
              {(referee.refereeFirstName.split(" ")[0] || "") +
                " " +
                (referee.refereeLastName ||
                  referee.refereeFirstName.split(" ")[1] ||
                  "")}
            </Typography>
          }
          subheader={
            <span>
              {!!referee.refereePositionName && (
                <small className="btn-link">
                  {referee.refereePositionName
                    ? referee.refereePositionName +
                      " at " +
                      referee.refereeInstitutionName
                    : "-"}
                </small>
              )}

              <div
                className="d-flex flex-column flex-md-row align-items-center-center"
                style={{ fontSize: "12px" }}
              >
                <div className="d-flex flex-row mr-3">
                  <PhoneRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <span className="font-weight-bold ml-1">
                    {referee.refereePhoneNumber}
                  </span>
                </div>
                <div className="d-flex flex-row">
                  <MailRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <a
                    href={`mailto:${referee.refereeEmail}`}
                    className="font-weight-bold ml-1 text-dark"
                  >
                    {referee.refereeEmail}
                  </a>
                </div>
              </div>
              <p
                            className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                           Updated on, {moment(referee.createdOn).format("ll")}
                          </p>
            </span>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />
    </>
  );
};
