import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";

import {
  CardContent,
  Button,
  IconButton,
  Typography,
  Box,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import {
  getTeachersPlacements,
  requestJobOffer,
  skipCandidate,
  cancelJobOffer,
  employCandidate,
  downloadTeacherPlacements,
  getRecruitmentRequest,
  executePlacementAlgorithm,
  publishTeacherPlacements,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PercentIcon from "@mui/icons-material/Percent";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ApplicationCv from "../../common/components/ApplicationCv";
import BadgeIcon from "@mui/icons-material/Badge";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import WidgetViewContentDialog from "../../common/components/WidgetViewContentDialog";
import moment from "moment";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import EmployCandidateDialog from "../components/EmployCandidateDialog";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UserResume from "../../profile/UserResume";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import NewRecruitmentRequestDialog from "../components/NewRecruitmentRequestDialog";
import ApproveRecruitmentRequestDialog from "../components/ApproveRecruitmentRequestDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import TeachersVacantPostsDialog from "../components/TeachersVacantPostsDialog";

const TeachersPlacement = (props) => {
  const {
    loading,
    teachersPlacements,
    getTeachersPlacements,
    selectedAdvertisement,
    requestJobOffer,
    skipCandidate,
    cancelJobOffer,
    employCandidate,
    userType,
    downloadTeacherPlacements,
    getUserDetails,
    getRecruitmentRequest,
    executePlacementAlgorithm,
    publishTeacherPlacements,
    env,
  } = props;

  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();

  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [itemPerPage, setItemPerPage] = useState(10);

  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [filterStatus, setFilterStatus] = useState({ id: "", name: "All" });
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    getTMISVacants();

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getTeachersPlacements(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getTeachersPlacements(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getTeachersPlacements(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading1
    );
  };

  const [showVacantPostsDialog, setShowVacantPostsDialog] = useState(false);

  const [confirmPublish, setConfirmPublish] = useState(false);
  const [vacants, setVacants] = useState(0);

  const getTMISVacants = async () => {
    try {
      setVacants(0);
      const { data } = await axios.get(
        "/api/external/count-tmis-vacants/" + selectedAdvertisement.positionId
      );
      setVacants(data);
    } catch (error) {}
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div
          className="w-100"
          style={{
            height: "500px",
          }}
        >
          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
            }}
          >
            <CardContent
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <div className="row">
                <div className="col-lg-5 mb-2">
                  <Typography variant="h6" noWrap component="div">
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      Teachers Placement
                    </span>
                    <Button
                      className="ml-2"
                      onClick={() => setShowVacantPostsDialog(true)}
                      disabled={loading}
                      variant="contained"
                      size="small"
                      disableElevation
                      color="inherit"
                      style={{
                        borderRadius: "8px",
                        height: "30px",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                      }}
                    >
                      Vacant Posts
                    </Button>
                  </Typography>

                  <small className="">
                    <strong>
                      <i className="fas fa-chevron-right"></i> {vacants}
                    </strong>{" "}
                    vacant post
                    {vacants > 1 ? "s" : ""} available in TMIS
                  </small>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex justify-content-end align-items-center">
                    <span className="btn btn-link">
                      PassMark:{selectedAdvertisement.passMark}%
                    </span>
                    <Button
                      className="mr-2"
                      disableElevation
                      // size="small"
                      onClick={() => setConfirmPublish(true)}
                      disabled={
                        loading ||
                        confirmPublish ||
                        !teachersPlacements.length ||
                        !teachersPlacements.find(
                          ({ isApproved }) => !isApproved
                        )
                      }
                      variant="contained"
                      color="success"
                    >
                      <span className="material-icons">publish</span>
                      Publish
                    </Button>
                    <div className="d-flex justify-content-end">
                      <SearchBox
                        disabled={isLoading1 || isLoading}
                        onSearch={(search) => {
                          setSearchTerm(search);

                          if (!search) {
                            const currentPage = 1;
                            setCurrentPage(currentPage);
                            const query = {
                              searchTerm: "",
                              page: currentPage,
                              currentStatus: filterStatus?.id || defaultIds,
                              size: itemPerPage,
                            };
                            getTeachersPlacements(
                              selectedAdvertisement.id,
                              query,
                              setTotalRequests,
                              setIsLoading1
                            );
                          }
                        }}
                        onKeyPress={() => {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: searchTerm || "",
                            page: currentPage,
                            size: itemPerPage,
                          };
                          getTeachersPlacements(
                            selectedAdvertisement.id,
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }}
                        placeholder="Search…"
                      />
                    </div>
                    <span className="ml-2 btn btn-outline-primary btn-sm">
                      <Tooltip title="Download">
                        <IconButton
                          disabled={isLoading || isLoading1}
                          className="p-0"
                          color="inherit"
                          onClick={() => {
                            const query = {
                              searchTerm: searchTerm,
                              currentStatus: filterStatus?.id || defaultIds,
                            };
                            downloadTeacherPlacements(
                              selectedAdvertisement,
                              query,
                              setIsDownloading
                            );
                          }}
                        >
                          {isDownloading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ fontSize: "28px" }}
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            <span
                              className="material-icons"
                              style={{ fontSize: "28px" }}
                            >
                              cloud_download
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
          </div>

          <div
            className="mt-0"
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <table className="table table-striped">
                {!!teachersPlacements.length && (
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      {/* <th scope="col">#</th> */}
                      <th scope="col">Candidate profile</th>
                      <th scope="col" className="text-left">
                        Preferences
                      </th>
                      <th scope="col" className="text-left">
                        Placement
                      </th>
                      <th scope="col" className="text-right">
                        Score
                      </th>

                      <th scope="col" className="text-center">
                        Status
                      </th>
                    </tr>
                  </thead>
                )}
                {isLoading ? (
                  <TableSkeleton cols={5} />
                ) : (
                  <tbody style={{ height: "100%" }}>
                    <BackdropLoader isLoading={isLoading1} />
                    {teachersPlacements.map((result, index) => (
                      <TeacherPlacementCard
                        selectedAdvertisement={selectedAdvertisement}
                        result={result}
                        index={index}
                        key={index}
                        userType={userType}
                        requestJobOffer={requestJobOffer}
                        cancelJobOffer={cancelJobOffer}
                        employCandidate={employCandidate}
                        skipCandidate={skipCandidate}
                        getUserDetails={getUserDetails}
                        loading={loading}
                        getRecruitmentRequest={getRecruitmentRequest}
                        env={env}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <NoRecordMessage
                isEmpty={!teachersPlacements.length && !isLoading}
                title={"No record found"}
              />
              {!isLoading && (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ overflow: "hidden" }}
                >
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      // marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalRequests / itemPerPage)}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                      activeLinkClassName="active"
                    />
                  </div>

                  {!!teachersPlacements.length ||
                    (!!isLoading1 && (
                      <select
                        className="custom-select custom-select-sm ml-2"
                        style={{ width: "100px" }}
                        value={itemPerPage}
                        onChange={(event) => {
                          const itemPerPage = +event.target.value;
                          window.localStorage.setItem(
                            "ITEM_PER_PAGE",
                            itemPerPage
                          );
                          setItemPerPage(itemPerPage);
                          const query = {
                            searchTerm: searchTerm,
                            page: currentPage,
                            size: itemPerPage,
                            currentStatus: filterStatus?.id || defaultIds,
                          };
                          getTeachersPlacements(
                            selectedAdvertisement.id,
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }}
                      >
                        {[10, 50, 100].map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                    ))}
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>

      {showVacantPostsDialog && (
        <TeachersVacantPostsDialog
          showDialog={showVacantPostsDialog}
          setShowDialog={setShowVacantPostsDialog}
          selectedAdvertisement={selectedAdvertisement}
        />
      )}

      {confirmPublish && (
        <ConfirmationDialog
          color="success"
          confirmationDialog={confirmPublish}
          message="Are you sure you want to publish placements?"
          setConfirmationDialog={setConfirmPublish}
          onYes={() => {
            publishTeacherPlacements(selectedAdvertisement.id, () => {
              setCurrentPage(currentPage);
              setConfirmPublish(false);
            });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, teachersPlacements, env }) => {
  return { user, loading, teachersPlacements, env };
};

export default connect(mapStateToProps, {
  requestJobOffer,
  skipCandidate,
  cancelJobOffer,
  employCandidate,
  getTeachersPlacements,
  downloadTeacherPlacements,
  getUserDetails,
  getRecruitmentRequest,
  executePlacementAlgorithm,
  publishTeacherPlacements,
})(TeachersPlacement);

const TeacherPlacementCard = (props) => {
  const {
    result,
    selectedAdvertisement,
    userType,
    requestJobOffer,
    skipCandidate,
    cancelJobOffer,
    getUserDetails,
    loading,
    getRecruitmentRequest,
    env,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [confirmToRequestJobOffer, setConfirmToRequestJobOffer] =
    useState(false);
  const [confirmToSkipCandidate, setConfirmToSkipCandidate] = useState(false);

  const [confirmToEmploy, setConfirmToEmploy] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const [confirmToCancelJobOffer, setConfirmToCancelJobOffer] = useState(false);
  const [reason, setReason] = useState("");
  const [reason1, setReason1] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [approveRequestForm, setApproveRequestForm] = useState(false);
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };
  return (
    <>
      <tr className={``}>
        {/* <th scope="row" className="text-left">
        {result.no}
        </th> */}

        <td className="text-left">
          <span className="d-flex align-items-center">
            <button
              onClick={() => setShowApplicationCv(true)}
              className="btn text-dark font-weight-bold btn-link btn-sm px-0 text-uppercase text-left text-underline "
            >
              {result.no}: {result.lastName + " " + result.firstName}
            </button>{" "}
            {result.hasDisability && (
              <Tooltip title="Has disability">
                <span
                  className="badge badge-pill text-dark text-uppercase cursor-pointer"
                  onClick={() => setShowApplicationCv(true)}
                >
                  <i className="fas fa-wheelchair"></i> Disability
                </span>
              </Tooltip>
            )}
          </span>

          <div>
            <span className="d-block">
              {result.idNumber} <i className="fas fa-chevron-right"></i>{" "}
              <a href={`mailto:${result.email}`}>{result.email}</a> /{" "}
              <a href={`tel:25${result.phone}`}>{result.phone}</a>
            </span>

            {!!result.residanceDistrict && (
              <Tooltip title="Candidate residance">
                <small className="text-dark ">
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  {result.residanceDistrict} / {result.residanceSector}
                </small>
              </Tooltip>
            )}
          </div>
        </td>
        <td className="text-left" style={{ width: "230px" }}>
          <div
            className={`text-left px-2 ${
              result.preferenceOne === result.districtOfPlacement
                ? "d-flex justify-content-between align-items-center border border-success font-weight-bold"
                : ""
            }`}
            style={{
              borderRadius: "5px",
              backgroundColor:
                result.preferenceOne === result.districtOfPlacement
                  ? "#bcf5c3"
                  : "inherit",
            }}
          >
            <Typography
              component={
                result.preferenceOne !== result.districtOfPlacement
                  ? "del"
                  : "span"
              }
              className="small"
              style={{ fontSize: "14px" }}
            >
              <small>
                1:{" "}
                {!!result.preferenceOne ? (
                  <>{result.preferenceOne}</>
                ) : (
                  <>N/A</>
                )}
              </small>
            </Typography>
            {result.preferenceOne === result.districtOfPlacement && (
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            )}
          </div>

          <div
            className={`text-left px-2 ${
              result.preferenceTwo === result.districtOfPlacement
                ? "d-flex justify-content-between align-items-center border border-success font-weight-bold"
                : ""
            }`}
            style={{
              borderRadius: "5px",
              backgroundColor:
                result.preferenceTwo === result.districtOfPlacement
                  ? "#bcf5c3"
                  : "inherit",
            }}
          >
            <Typography
              component={
                result.preferenceTwo !== result.districtOfPlacement
                  ? "del"
                  : "span"
              }
              className="small"
              style={{ fontSize: "14px" }}
            >
              <small>
                2:{" "}
                {!!result.preferenceTwo ? (
                  <>{result.preferenceTwo}</>
                ) : (
                  <>N/A</>
                )}
              </small>
            </Typography>
            {result.preferenceTwo === result.districtOfPlacement && (
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            )}
          </div>

          <div
            className={`text-left px-2 ${
              result.preferenceThree === result.districtOfPlacement
                ? "d-flex justify-content-between align-items-center border border-success font-weight-bold"
                : ""
            }`}
            style={{
              borderRadius: "5px",
              backgroundColor:
                result.preferenceThree === result.districtOfPlacement
                  ? "#bcf5c3"
                  : "inherit",
            }}
          >
            <Typography
              component={
                result.preferenceThree !== result.districtOfPlacement
                  ? "del"
                  : "span"
              }
              className="small"
              style={{ fontSize: "14px" }}
            >
              <small>
                3:{" "}
                {!!result.preferenceThree ? (
                  <>{result.preferenceThree}</>
                ) : (
                  <>N/A</>
                )}
              </small>
            </Typography>
            {result.preferenceThree === result.districtOfPlacement && (
              <span className="text-success">
                <i className="fas fa-check-circle"></i> Approved
              </span>
            )}
          </div>
        </td>

        <td className="text-left">
          <span className="d-block">
            {result.schoolOfPlacement} ({result.teachingLevelOfPlacement}){" "}
            <i className="fas fa-chevron-right"></i>{" "}
            <span className="text-primary">
              {result.districtOfPlacement} / {result.sectorOfPlacement}
            </span>
          </span>
          <Tooltip title="Placement date">
            <small>
              <i className="fas fa-calendar-alt"></i>{" "}
              {moment(result.placementDate).format("yyyy-MM-DD")}
            </small>
          </Tooltip>
        </td>

        <td className="text-right">{(result.totalScore * 1.0).toFixed(2)}</td>
        <td className="text-center">
          <span
            className={`badge px-3 py-1 badge-${
              result.statusId === null
                ? "secondary"
                : result.statusId === 1
                ? "primary"
                : [3, 4].includes(result.statusId)
                ? "danger"
                : [2, 5, 7].includes(result.statusId)
                ? "success"
                : "warning"
            }`}
          >
            {result.status}
          </span>
        </td>
      </tr>

      {showApplicationCv && (
        <ApplicationCv
          showApplicationCv={showApplicationCv}
          setShowApplicationCv={setShowApplicationCv}
          application={{
            id: result.applicationId,
            idNumber: result.idNumber,
            phoneNumber: result.phone,
            userId: result.userId,
            lastName: result.lastName,
            firstName: result.firstName,
            email: result.email,
          }}
        />
      )}
    </>
  );
};
