import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  InputAdornment,
  Badge,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  deleteCasualSalary,
  saveCasualSalary,
} from "../../../store/structure/actions";

import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const CasualSalaries = (props) => {
  const {
    loading,
    onWait,
    selectedEmployee,
    casualSalaries,
    saveCasualSalary,
    deleteCasualSalary,
    periods,
    disabled = false,
  } = props;

  const [period, setPeriod] = useState(periods[0]);
  const [type, setType] = useState({ id: "D", name: "Daily" });

  const theme = useTheme();

  const [formData, setFormData] = useState({
    type: "D",
    multiplier: 0,
    periodId: "",
    basePay: 0,
    housingAllowance: 0,
    transportAllowance: 0,
    taxableAllowance: 0,
    lumpsumAllowance: 0,
  });

  const [add, setAdd] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);

  const [selectedEmployeeArrear, setSelectedItem] = useState(false);

  const [errors, setErrors] = useState({
    periodIdHasError: false,
    typeHasError: false,
    amountHasError: false,
    multiplierHasError: false,
    hasError: false,
  });

  useEffect(() => {
    const add = casualSalaries?.length > 0 ? false : true;
    setAdd(add);
  }, [casualSalaries]);

  const formValidator = () => {
    const error = {
      periodIdHasError: false,
      typeHasError: false,
      amountHasError: false,
      multiplierHasError: false,
      hasError: false,
    };

    if (!period) {
      error.periodIdHasError = true;
      error.hasError = true;
    }

    if (!type) {
      error.typeHasError = true;
      error.hasError = true;
    }

    if (
      +formData.basePay +
        +formData.housingAllowance +
        +formData.transportAllowance +
        +formData.taxableAllowance +
        +formData.lumpsumAllowance <
      10
    ) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!formData.multiplier) {
      error.multiplierHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveCasualSalary(
        {
          ...formData,
          employeePositionId: selectedEmployee.employeePositionId,
          periodId: period?.id,
          type: type?.id,
        },
        setFormData,
        setAdd
      );
    }
  };

  return (
    <div style={{ minHeight: "50vh" }}>
      {confirmRemove && (
        <ConfirmationDialog
          confirmationDialog={confirmRemove}
          message={`Are you sure you want to delete this item?`}
          setConfirmationDialog={setConfirmRemove}
          onYes={() => {
            deleteCasualSalary(
              selectedEmployeeArrear.id,
              setConfirmRemove,
              setSelectedItem
            );
          }}
        />
      )}

      {loading && !onWait && (
        <div className="container mt-5" style={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </div>
      )}

      {(!loading || onWait) && (
        <div>
          {!add && (
            <Button
              disabled={disabled}
              className="mb-2"
              onClick={() => {
                setAdd(true);
              }}
              style={{
                ...ActiveButton,
                padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
              }}
              size="sm"
            >
              <span className="material-icons">add</span>
            </Button>
          )}
          {add && (
            <div className="row justify-content-center">
              <div
                className="elevated rounded p-0 mt-3 p-3 col-8"
                style={{
                  backgroundColor: "#fafafa",
                  border: "1px solid #17a2b8",
                }}
              >
                <div className="row mt-2">
                  {/* <div className="col-6 ">
                  <Autocomplete
                    size="small"
                    id="arrearType"
                    defaultValue={null}
                    value={arrearType}
                    options={getArrearTypes()}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, arrearType) => {
                      setArrearType(arrearType || null);

                      setFormData({
                        ...formData,
                        arrearTypeId: arrearType?.id || "",
                      });

                      setErrors({ ...errors, arrearTypeHasError: false });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, arrearType) => (
                      <Box component="li" {...props}>
                        {arrearType.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Arrear type"
                        placeholder="Arrear type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "arrearType", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {errors.arrearTypeHasError && (
                    <small className="text-danger mb-1 ">
                      Arrear type is required{" "}
                    </small>
                  )}
                </div> */}
                  <div className="col-6">
                    <Autocomplete
                      size="small"
                      id="periodId"
                      name="periodId"
                      defaultValue={null}
                      value={period}
                      options={periods}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, period) => {
                        setPeriod(period || null);

                        setErrors({ ...errors, periodIdHasError: false });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, period) => (
                        <Box component="li" {...props}>
                          {period.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Period"
                          placeholder="Period"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "period", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />

                    {errors.periodIdHasError && (
                      <small className="text-danger mb-3">
                        Period is required
                      </small>
                    )}
                  </div>

                  <div className="col-6">
                    <Autocomplete
                      size="small"
                      id="type"
                      name="type"
                      defaultValue={null}
                      value={type}
                      options={[
                        { id: "D", name: "Daily" },
                        { id: "H", name: "Hourly" },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, type) => {
                        setType(type || null);

                        setErrors({ ...errors, typeHasError: false });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, type) => (
                        <Box component="li" {...props}>
                          {type.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Casual Type"
                          placeholder="Casual Type"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />

                    {errors.typeHasError && (
                      <small className="text-danger mb-3">
                        Casual Type is required
                      </small>
                    )}
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="mb-3 col-3">
                    <div className="">
                      <NumberFormat
                        fullWidth
                        size="small"
                        label="Base Pay"
                        name="basePay"
                        variant="outlined"
                        placeholder="RWF 0"
                        prefix={"RWF "}
                        customInput={TextField}
                        value={formData.basePay}
                        thousandSeparator={true}
                        onBlur={() => {
                          if (formData.basePay === "")
                            setFormData({
                              ...formData,
                              basePay: 0,
                            });
                        }}
                        onKeyDown={() => {
                          if (+formData.basePay === 0)
                            setFormData({
                              ...formData,
                              basePay: "",
                            });
                        }}
                        onChange={(e) => {
                          setErrors({ ...errors, amountHasError: false });
                          const basePay = e.target.value.split("RWF ");

                          setFormData({
                            ...formData,
                            basePay: !!basePay[1]
                              ? basePay[1].replace(/,/g, "")
                              : 0,
                          });
                        }}
                      />
                      {errors.amountHasError && (
                        <small className="text-danger mb-2">Required</small>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 col-3">
                    <div className="">
                      <NumberFormat
                        fullWidth
                        size="small"
                        label="Housing"
                        name="housingAllowance"
                        variant="outlined"
                        placeholder="RWF 0"
                        prefix={"RWF "}
                        customInput={TextField}
                        value={formData.housingAllowance}
                        thousandSeparator={true}
                        onBlur={() => {
                          if (formData.housingAllowance === "")
                            setFormData({
                              ...formData,
                              housingAllowance: 0,
                            });
                        }}
                        onKeyDown={() => {
                          if (+formData.housingAllowance === 0)
                            setFormData({
                              ...formData,
                              housingAllowance: "",
                            });
                        }}
                        onChange={(e) => {
                          setErrors({ ...errors, amountHasError: false });
                          const housingAllowance = e.target.value.split("RWF ");

                          setFormData({
                            ...formData,
                            housingAllowance: !!housingAllowance[1]
                              ? housingAllowance[1].replace(/,/g, "")
                              : 0,
                          });
                        }}
                      />
                      {errors.amountHasError && (
                        <small className="text-danger mb-2">Required </small>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 col-3">
                    <div className="">
                      <NumberFormat
                        fullWidth
                        size="small"
                        label="Transport"
                        name="transportAllowance"
                        variant="outlined"
                        placeholder="RWF 0"
                        prefix={"RWF "}
                        customInput={TextField}
                        value={formData.transportAllowance}
                        thousandSeparator={true}
                        onBlur={() => {
                          if (formData.transportAllowance === "")
                            setFormData({
                              ...formData,
                              transportAllowance: 0,
                            });
                        }}
                        onKeyDown={() => {
                          if (+formData.transportAllowance === 0)
                            setFormData({
                              ...formData,
                              transportAllowance: "",
                            });
                        }}
                        onChange={(e) => {
                          setErrors({ ...errors, amountHasError: false });
                          const transportAllowance =
                            e.target.value.split("RWF ");

                          setFormData({
                            ...formData,
                            transportAllowance: !!transportAllowance[1]
                              ? transportAllowance[1].replace(/,/g, "")
                              : 0,
                          });
                        }}
                      />
                      {errors.amountHasError && (
                        <small className="text-danger mb-2">Required </small>
                      )}
                    </div>
                  </div>

                  <div className="mb-3 col-3">
                    <div className="">
                      <NumberFormat
                        fullWidth
                        size="small"
                        label="Multiplier"
                        name="multiplier"
                        variant="outlined"
                        placeholder="0"
                        customInput={TextField}
                        value={formData.multiplier}
                        onBlur={() => {
                          if (formData.multiplier === "")
                            setFormData({
                              ...formData,
                              multiplier: 0,
                            });
                        }}
                        onKeyDown={() => {
                          if (+formData.multiplier === 0)
                            setFormData({
                              ...formData,
                              multiplier: "",
                            });
                        }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            multiplier: e.target.value || 0,
                          });

                          setErrors({ ...errors, multiplierHasError: false });
                        }}
                      />
                      {errors.multiplierHasError && (
                        <small className="text-danger mb-2">Required </small>
                      )}
                    </div>
                  </div>
                </div>

                <Divider className="my-1" />
                <div className="d-flex justify-content-center py-0 p-2">
                  <button
                    onClick={onSave}
                    type="button"
                    className="btn btn-primary text-uppercase px-5"
                    disabled={disabled || loading || onWait}
                  >
                    {onWait ? "Wait..." : "Save"}
                  </button>{" "}
                  {!!casualSalaries?.length && (
                    <React.Fragment>
                      &nbsp; &nbsp;
                      <button
                        onClick={() => {
                          setAdd(false);
                          setPeriod(null);
                          setFormData({
                            ...formData,
                            periodId: "",
                            basePay: 0,
                            housingAllowance: 0,
                            transportAllowance: 0,
                            taxableAllowance: 0,
                            exemptedAllowance: 0,
                            lumpsumAllowance: 0,
                          });
                        }}
                        type="button"
                        className="btn  btn-default text-uppercase"
                        disabled={disabled || loading}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}

          {!!casualSalaries?.length && (
            <React.Fragment>
              <table className="table table-bordered table-sm mt-3">
                <thead>
                  <tr>
                    <th colSpan={12} style={{ textAlign: "center" }}>
                      Casual Salaries
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#e9ecef" }}>#</td>

                    <td
                      colSpan={2}
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-left"
                    >
                      Period
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      BasePay
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Housing
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Transport
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-center"
                    >
                      Status
                    </td>

                    <td style={{ backgroundColor: "#e9ecef" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {casualSalaries.map((casualSalary, index) => (
                    <CardItem
                      key={casualSalary.id}
                      casualSalary={casualSalary}
                      setPeriod={setPeriod}
                      setFormData={setFormData}
                      setConfirmRemove={setConfirmRemove}
                      setAdd={setAdd}
                      setSelectedItem={setSelectedItem}
                      index={index}
                      disabled={disabled}
                    />
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  casualSalaries,
  periods,
  payrollTypes,
}) => {
  return {
    loading,
    onWait,
    casualSalaries,
    periods,
    payrollTypes,
  };
};
export default connect(mapStateToProps, {
  saveCasualSalary,
  deleteCasualSalary,
})(CasualSalaries);

const CardItem = (props) => {
  const { casualSalary, setConfirmRemove, setSelectedItem, index, disabled } =
    props;

  return (
    <tr
      style={{
        background: index % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{index + 1}</td>

      <td>{casualSalary.periodName}</td>

      <td>
        <strong>
          {casualSalary.multiplier}{" "}
          {casualSalary.type === "H" ? "Hours" : "Days"}
        </strong>
      </td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={casualSalary.basePay}
            displayType={"text"}
            thousandSeparator={true}
          />
        </>
      </td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={casualSalary.housingAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
        </>
      </td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={casualSalary.transportAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
        </>
      </td>

      <td className="text-center">
        <span
          className={`badge badge-${
            casualSalary.status === "In Progress"
              ? "primary"
              : casualSalary.status === "Paid"
              ? "sucess"
              : "secondary"
          }`}
        >
          {casualSalary.status}
        </span>
      </td>

      <td className="text-right">
        {casualSalary.status === "Unpaid" ? (
          <IconButton
            disabled={disabled}
            size="small"
            color="error"
            onClick={() => {
              setSelectedItem(casualSalary);
              setConfirmRemove(true);
            }}
          >
            <span className="material-icons">delete</span>
          </IconButton>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};
