import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateCreditor,
  addNewCreditor,
  getAdminCreditor,
  updateCreditor,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { getBanks } from "../../../store/structure/actions";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  getBasePayrollComponents,
  getCalculationMethods,
} from "../../../store/payroll/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import AutoModeRoundedIcon from "@mui/icons-material/AutoModeRounded";
import AssignCreditorDialog from "../../support/components/AssignCreditorDialog";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";

const Creditor = (props) => {
  const {
    createCreditor,
    setCreateCreditor,
    // redux -----------------
    loading,
    allCreditors,
    getAdminCreditor,
    addNewCreditor,
    updateCreditor,
    banks,
    calculationMethods,
    basePayrollComponents,
    getCalculationMethods,
    getBasePayrollComponents,
    activateCreditor,
    getBanks,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [filterCalculationMethod, setFilterCalculationMethod] = useState(null);
  const [filterBasePayroll, setFilterBasePayroll] = useState(null);
  const [creditorId, setCreditorId] = useState(0);
  const [deleteCreditor, setDeleteCreditor] = useState(null);
  const [creditorName, setCreditorName] = useState("");
  const [rate, setRate] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [formError, setFormError] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedCalculationMethod, setSelectedCalculationMethod] =
    useState(null);
  const [selectedBasePayroll, setSelectedBasePayroll] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [creditorToAssign, setCreditorToAssign] = useState(null);
  const [assignCreditorAction, setAssignCreditorAction] = useState(null);

  //PAGINATION
  const [forcePage, setForcePage] = useState(undefined);
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  useEffect(() => {
    if (allCreditors.length === 0) {
      getAdminCreditor(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (!calculationMethods.length) getCalculationMethods();
    if (!basePayrollComponents.length) getBasePayrollComponents();
    if (!banks.length) getBanks();

    return () => {
      setLoadingData(true);
      setCreditorId(0);
      setCreditorName("");
      setFormError(null);
      setSearchInput("");
      setSelectedBank(null);
    };
  }, []);

  const onCloseForm = () => {
    setCreateCreditor(false);
    setCreditorId(0);
    setCreditorName("");
    setSelectedBasePayroll(null);
    setSelectedCalculationMethod(null);
    setSelectedBank(null);
    setRate("");
    setAccountNumber("");
  };

  const onSaveForm = () => {
    if (creditorId === 0 && !createCreditor) {
      setFormError({
        element: "GENERAL",
        msg: "Please select allowance you want to update.",
      });
      return;
    }
    if (creditorName.length === 0) {
      setFormError({ element: "NAME", msg: "Certificate name is required." });
      return;
    }
    if (!selectedCalculationMethod) {
      setFormError({
        element: "CALCULATION_METHOD",
        msg: "Selected calculation is required.",
      });
      return;
    }
    if (!selectedBank) {
      setFormError({ element: "BANK", msg: "Bank is required." });
      return;
    }
    if (!selectedBasePayroll) {
      setFormError({
        element: "BASE_PAYROLL",
        msg: "Base payroll is required.",
      });
      return;
    }
    if (selectedCalculationMethod.id === 2 && !rate.length) {
      setFormError({ element: "RATE", msg: "Rate is required." });
      return;
    }

    if (creditorId > 0 && !createCreditor) {
      updateCreditor(
        creditorId,
        {
          name: creditorName,
          selectedCalculationMethod,
          selectedBasePayroll,
          selectedBank,
          rate,
          accountNumber,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewCreditor(
        {
          name: creditorName,
          selectedCalculationMethod,
          selectedBasePayroll,
          selectedBank,
          rate,
          accountNumber,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const handleFilter = (data) => {
    let result = [...data];
    result = result.filter((item) => item.isActive === !showInactive);
    if (filterBasePayroll)
      result = result.filter(
        (item) => item.basePayrollComponentId === filterBasePayroll?.id
      );
    if (filterCalculationMethod)
      result = result.filter(
        (item) => item.calculationMethodId === filterCalculationMethod.id
      );

    return result;
  };

  const handleChangeStatus = () => {
    if (deleteCreditor)
      activateCreditor(
        deleteCreditor.id,
        (status) => status && setDeleteCreditor(null)
      );
  };

  const IS_EDITING = Boolean(creditorId > 0);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0
      ? allCreditors
      : allCreditors.filter(
          (item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase()) ||
            item.acountNumber.includes(searchInput) ||
            item.rate.toString().includes(searchInput)
        )
    : [];
  SEARCHED_DATA = handleFilter(SEARCHED_DATA);
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (creditorItem) => {
    setCreditorId(creditorItem.id);
    setCreditorName(creditorItem.name);
    setSelectedBasePayroll(creditorItem.basePayrollComponent);
    setSelectedCalculationMethod(creditorItem.calculationMethod);
    setSelectedBank(creditorItem.bank);
    setRate(creditorItem.rate);
    setAccountNumber(creditorItem.acountNumber);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-5 col-sm-5">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || allCreditors.length === 0}
            placeholder="Search creditor..."
          />
        </div>

        <div className="col-5 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, calculation) => (
              <Box component="li" {...props}>
                {calculation?.name}
              </Box>
            )}
            options={calculationMethods}
            size="small"
            value={filterCalculationMethod || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Calculation method"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "calculation",
                }}
              />
            )}
            onChange={(event, calculation) =>
              setFilterCalculationMethod(calculation || null)
            }
          />
        </div>
        <div className="col-5 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, basePayroll) => (
              <Box component="li" {...props}>
                {basePayroll?.name}
              </Box>
            )}
            options={basePayrollComponents}
            size="small"
            value={filterBasePayroll || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Base payroll"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "type",
                }}
              />
            )}
            onChange={(event, basePayroll) =>
              setFilterBasePayroll(basePayroll || null)
            }
          />
        </div>
        <div className="col-5 col-sm-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
            }}
            variant="outlined"
            size="small"
            disableElevation
            // className="mr-2"
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Creditor</th>
            <th className="text-center">Calculation method</th>
            <th className="text-center">Base payroll</th>
            <th className="text-center">Rate</th>
            <th className="text-center">Bank</th>
            <th className="text-center">Account number</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={8} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={8}>
                <div className="text-center font-weight-bold my-2">
                  No creditor found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((creditorItem, index) => (
              <tr key={"creditor-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{creditorItem && creditorItem.name}</td>
                <td align="center">
                  {creditorItem.calculationMethod
                    ? creditorItem.calculationMethod.name
                    : "-"}
                </td>
                <td align="center">
                  {creditorItem.basePayrollComponent
                    ? creditorItem.basePayrollComponent.name
                    : "-"}
                </td>
                <td align="center">
                  {creditorItem.calculationMethodId === 2
                    ? creditorItem.rate
                    : "-"}
                </td>
                <td align="center">
                  {creditorItem.bank ? creditorItem.bank.name : "-"}
                </td>
                <td align="center">{creditorItem.acountNumber}</td>
                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit allowance"
                      size="small"
                      onClick={() => setForUpdate(creditorItem)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="assign creditor to all employees in a selected entity"
                      title="Assign creditor to all employees in a selected entity"
                      size="small"
                      onClick={() => {
                        setCreditorToAssign(creditorItem);
                        setAssignCreditorAction("assign");
                      }}
                      className="mr-1"
                    >
                      <AutoModeRoundedIcon fontSize="small" color="info" />
                    </IconButton>
                    <IconButton
                      aria-label="stop creditor to all employees in a selected entity"
                      title="Stop creditor to all employees in a selected entity"
                      size="small"
                      onClick={() => {
                        setCreditorToAssign(creditorItem);
                        setAssignCreditorAction("stop");
                      }}
                      className="mr-1"
                    >
                      <DangerousRoundedIcon fontSize="small" color="error" />
                    </IconButton>
                    <IconButton
                      aria-label="remove allowance"
                      size="small"
                      onClick={() => {
                        setDeleteCreditor({
                          id: creditorItem.id,
                          isActive: creditorItem.isActive,
                        });
                      }}
                    >
                      {creditorItem.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createCreditor || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Creditor" : "Add New Creditor"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Creditor name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={creditorName}
              onChange={(e) => setCreditorName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Calculation method */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, type) => (
                <Box component="li" {...props}>
                  {type?.name}
                </Box>
              )}
              options={calculationMethods}
              size="small"
              value={selectedCalculationMethod || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Calculation method"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "calculation",
                  }}
                />
              )}
              onChange={(event, calculation) => {
                setSelectedCalculationMethod(calculation || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "CALCULATION_METHOD" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Base payroll */}
            {/* {selectedCalculationMethod &&
              selectedCalculationMethod.id === 2 && ( */}
                <Autocomplete
                  disabled={loading || loadingData}
                  disablePortal
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, type) => (
                    <Box component="li" {...props}>
                      {type?.name}
                    </Box>
                  )}
                  options={basePayrollComponents}
                  size="small"
                  value={selectedBasePayroll || null}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Base payroll"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "base",
                      }}
                    />
                  )}
                  onChange={(event, base) => {
                    setSelectedBasePayroll(base || null);
                    setFormError(null);
                  }}
                  className="mt-3"
                />
               {/* )} */}
            {formError && formError.element === "BASE_PAYROLL" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Rating */}
            {selectedCalculationMethod &&
              selectedCalculationMethod.id === 2 &&
              selectedBasePayroll && (
                <TextField
                  id="outlined-basic"
                  label="Rating"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  disabled={loading || loadingData}
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              )}

            {formError && formError.element === "RATE" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Bank */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, bank) => (
                <Box component="li" {...props}>
                  {bank?.name}
                </Box>
              )}
              options={banks}
              size="small"
              value={selectedBank || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bank"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "bank",
                  }}
                />
              )}
              onChange={(event, bank) => {
                setSelectedBank(bank || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "BANK" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
            {/* Bank account */}
            <TextField
              id="outlined-basic"
              label="Account number"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              disabled={loading || loadingData}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            {formError && formError.element === "ACCOUNT_NUMBER" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}

      {deleteCreditor && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !deleteCreditor.isActive ? "activate" : "de-activate"
          } this creditor?`}
          setConfirmationDialog={() => setDeleteCreditor(null)}
          onYes={handleChangeStatus}
        />
      )}

      {!!creditorToAssign && (
        <AssignCreditorDialog
          thisCreditor={creditorToAssign}
          setThisCreditor={setCreditorToAssign}
          action={assignCreditorAction}
          setAction={setAssignCreditorAction}
        />
      )}
    </div>
  );
};

Creditor.propTypes = {
  createCreditor: PropTypes.bool,
  setCreateCreditor: PropTypes.func,
};

const mapStateToProps = ({
  loading,
  adminPayroll,
  banks,
  calculationMethods,
  basePayrollComponents,
}) => {
  return {
    loading,
    allCreditors: adminPayroll.adminCreditor,
    banks,
    calculationMethods,
    basePayrollComponents,
  };
};

const mapDispatchToProps = {
  getAdminCreditor,
  addNewCreditor,
  updateCreditor,
  activateCreditor,
  getCalculationMethods,
  getBasePayrollComponents,
  getBanks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Creditor);
