import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Skeleton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  getSalaryStructure,
  deleteSalaryStructure,
} from "../../../store/structure/actions";
import AddSalaryStructureDialog from "./AddSalaryStructureDialog";
import EditSalaryStructureDialog from "./EditSalaryStructureDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "30px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const ManageSalaryStructureDialog = (props) => {
  const {
    loading,
    onWait,
    selectedEntitySector,
    showSalaryStructure,
    setShowSalaryStructure,
    getSalaryStructure,
    salaryStructures,
    deleteSalaryStructure,
    entity,
  } = props;

  const [showAddNewFrom, setShowAddNewForm] = useState(false);
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [filteredSalaryStructures, setFilteredSalaryStructures] = useState([
    ...salaryStructures,
  ]);
  // useEffect(
  //   () => {
  //     if (!salaryStructures.length)
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [selectedEntitySector]
  // );

  const onClose = () => {
    setShowSalaryStructure(false);
  };

  useEffect(() => {
    const filtered = [...salaryStructures].filter(({ name }) =>
      name.toLowerCase().includes(searchTeam.toLowerCase())
    );
    setFilteredSalaryStructures(filtered);
    paginate(filtered);
  }, [searchTeam, salaryStructures]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(
    () => {
      paginate(filteredSalaryStructures);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, salaryStructures, filteredSalaryStructures]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredSalaryStructures.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const getNo = (idToSearch) =>
    salaryStructures.findIndex(({ id }) => id === idToSearch);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSalaryStructure}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">Salary Structure | </span>{" "}
                <span className="ml-0">
                  {entity && entity.name}{" "}
                  <span className="badge badge-secondary">
                    {selectedEntitySector && selectedEntitySector.sector.name}
                  </span>
                </span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "77vh",
            }}
          >
            {loading && !onWait && (
              <div className="container mt-2" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            )}

            {(!loading || onWait) && (
              <React.Fragment>
                {!showAddNewFrom && (
                  <div className="row">
                    <div className="col-lg-8 mb-2"></div>
                    <div className="col-lg-3 mb-2">
                      <SearchBox
                        style={{
                          padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                          float: "right",
                        }}
                        disabled={!salaryStructures.length ? true : false}
                        onSearch={onSearch}
                        placeholder="Search…"
                      />
                    </div>
                    <div className="col-lg-1 mb-2">
                      <Button
                        onClick={() => {
                          setShowAddNewForm(true);
                        }}
                        style={{
                          ...ActiveButton,
                          padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                          float: "right",
                        }}
                        size="small"
                      >
                        <span className="material-icons">add</span>
                      </Button>
                    </div>
                  </div>
                )}
                {showAddNewFrom && (
                  <AddSalaryStructureDialog
                    selectedEntitySector={selectedEntitySector}
                    showAddNewFrom={showAddNewFrom}
                    setShowAddNewForm={setShowAddNewForm}
                  />
                )}

                {!!paginatedItems.length && (
                  <table className="table table-sm  table-striped  table-bordered">
                    <thead>
                      <tr>
                        <th className="py-2">No</th>

                        <th
                          className="py-2"
                          style={{ verticalAlign: "middle" }}
                        >
                          <span className="d-flex align-items-center">
                            <span className="badge badge-secondary mr-1">
                              {salaryStructures.length}
                            </span>{" "}
                            Positions
                          </span>
                        </th>
                        <th className="py-2">No of Posts</th>
                        <th className="py-2">Level</th>
                        <th className="py-2">Scale</th>
                        <th className="py-2">Emp. Group</th>
                        <th className="py-2">Hiring Mode</th>
                        <th className="py-2">Career field</th>
                        <th className="py-2">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedItems.map((salaryStructure, ssIndex) => (
                        <LoadSalaryStructure
                          selectedEntitySector={selectedEntitySector}
                          key={ssIndex}
                          ssIndex={getNo(salaryStructure.id)}
                          salaryStructure={salaryStructure}
                          deleteSalaryStructure={deleteSalaryStructure}
                          entity={entity}
                        />
                      ))}
                    </tbody>
                  </table>
                )}

                {!filteredSalaryStructures.length && (
                  <div className="jumbotron jumbotron-fluid text-center mt-5">
                    <div className="container">
                      <p className="lead">No Salary Structure found</p>
                    </div>
                  </div>
                )}

                <div
                  className="justify-content-center mb-3"
                  style={{ overflow: "hidden" }}
                >
                  {!!paginatedItems.length &&
                    salaryStructures.length >= itemsPerPage && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                          <>
                            <span className="pr-1 d-none d-md-inline">
                              Next
                            </span>
                            <i className="fas fa-angle-double-right"></i>
                          </>
                        }
                        previousLabel={
                          <>
                            <i className="fas fa-angle-double-left"></i>
                            <span className="pl-1  d-none d-md-inline">
                              Previous
                            </span>
                          </>
                        }
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                        nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                        activeLinkClassName="active"
                      />
                    )}
                </div>
              </React.Fragment>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, salaryStructures }) => {
  return {
    loading,
    onWait,
    salaryStructures,
  };
};
export default connect(mapStateToProps, {
  getSalaryStructure,
  deleteSalaryStructure,
})(ManageSalaryStructureDialog);

const LoadSalaryStructure = (props) => {
  const {
    salaryStructure,
    ssIndex,
    deleteSalaryStructure,
    selectedEntitySector,
    entity,
  } = props;

  const [showSalaryStructure, setShowSalaryStructure] = useState(false);

  const [selectedSalaryStructure, setSelectedSalaryStructure] = useState(null);

  const [confirmRemoveSalaryStructure, setConfirmRemoveSalaryStructure] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr
        style={{
          background: showSalaryStructure
            ? "rgb(7, 142, 206)"
            : ssIndex % 2 === 0
            ? ""
            : "#f6f7f7",
          borderBottom: "2px dotted rgb(7, 142, 206)!important",
          color: showSalaryStructure ? "#fff" : "",
          border: showSalaryStructure
            ? "2px solid rgb(7, 142, 206)!important"
            : "",
        }}
      >
        <td>{ssIndex + 1}</td>
        <td>{salaryStructure.name}</td>

        <td>{salaryStructure.numberOfPosts}</td>
        <td>{salaryStructure.level && salaryStructure.level.name}</td>
        <td>{salaryStructure.scale && salaryStructure.scale.name}</td>
        <td>
          {salaryStructure.employeeGroup && salaryStructure.employeeGroup.name}
        </td>
        <td>{salaryStructure.hiringMode && salaryStructure.hiringMode.name}</td>
        <td>{salaryStructure.jobField && salaryStructure.jobField.name}</td>

        <td>
          {!showSalaryStructure && (
            <span
              onClick={handleOpenMenu}
              className="material-icons text-info ml-1"
              style={{ cursor: "pointer" }}
            >
              more_vert
            </span>
          )}
        </td>
      </tr>

      {showSalaryStructure && (
        <tr style={{ background: "#f8f9fa" }}>
          <td colSpan={8}>
            {showSalaryStructure && (
              <EditSalaryStructureDialog
                selectedEntitySector={selectedEntitySector}
                showSalaryStructure={showSalaryStructure}
                setShowSalaryStructure={setShowSalaryStructure}
                setSelectedSalaryStructure={setSelectedSalaryStructure}
                selectedSalaryStructure={selectedSalaryStructure}
              />
            )}
          </td>
        </tr>
      )}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <span>
          <MenuItem
            onClick={() => {
              setShowSalaryStructure(false);
              setSelectedSalaryStructure(salaryStructure);
              setShowSalaryStructure(true);
              handleCloseMenu();
            }}
            className="text-primary font-weight-bolder"
          >
            <span className="material-icons mr-1">edit_note</span> Edit
          </MenuItem>

          <Divider className="my-1" />

          <MenuItem
            onClick={() => {
              setSelectedSalaryStructure(salaryStructure);
              setConfirmRemoveSalaryStructure(true);
              handleCloseMenu();
            }}
            className="text-danger font-weight-light"
          >
            <span className=" material-icons mr-1">highlight_off</span> Delete
          </MenuItem>
        </span>
      </Menu>

      {confirmRemoveSalaryStructure && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveSalaryStructure}
          message={`Are you sure you want to remove this salary structure @${selectedSalaryStructure.name}?`}
          setConfirmationDialog={setConfirmRemoveSalaryStructure}
          onYes={() => {
            deleteSalaryStructure(
              selectedSalaryStructure,
              setSelectedSalaryStructure,
              setConfirmRemoveSalaryStructure
            );
          }}
        />
      )}
    </>
  );
};
