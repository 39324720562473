import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

const AlertDialog = (props) => {
  const { showAlert, setShowAlert, title, children, maxWidth,actionButton } = props;

  const onClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAlert}
        fullWidth
        maxWidth={maxWidth}
      >
        {!!title && (
          <DialogTitle className="text-primary">
            <Typography variant="h6" noWrap component="div">
              <span className="text-primary">{title}</span>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          {actionButton ? (
            actionButton
          ) : (
            <button
              onClick={() => onClose()}
              type="button"
              className="btn btn-primary"
            >
              Close
            </button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
