import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import {
  getSubmissions,
  removeEvaluation,
  evaluateSubmission,
} from "../../../store/rbm/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import loadingGif from "../../assets/loading.gif";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonIcon from "@mui/icons-material/Person";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CustomProgressLine from "./CustomProgressLine";
export const formatSubmission = (submission) => {
  return {
    createdOn: submission.createdOn,
    lastName: submission.evaluation
      ? submission.evaluation.evaluatedBy.lastName
      : null,
    firstName: submission.evaluation
      ? submission.evaluation.evaluatedBy.firstName
      : null,
    reportedMessage: submission.message,
    status: submission.evaluation ? "scored" : "reported",
    submissionId: submission.id,
    targetShare: submission.thisTarget,
    achievedTarget: submission.achievedTarget,
    measurementUnit: submission.measurementUnit,
    completed: submission.completed,
    docReferenceId: submission.docReferenceId,
    fileName: submission.fileName,
    evaluation: submission.evaluation || null,
  };
};

const ReportAssignment = ({
  assignment,
  loading,
  removeEvaluation,
  getSubmissions,

  showDialog,
  setShowDialog,
  index,
  no,
  submissions,
  evaluateSubmission,
  selectedSubordinate,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmRemoveEvaluation, setConfirmRemoveEvaluation] = useState(false);
  const [submissionReport, setSubmissionReport] = useState(null);

  useEffect(() => {
    if (showDialog)
      getSubmissions(
        {
          assignmentId: assignment.assignmentId,
          quarterId: assignment.quarterId,
          itemTypeId: assignment.itemTypeId,
        },
        setIsLoading
      );
  }, [showDialog]);

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <span className="px-4">Assignment reports</span>
          <IconButton
            aria-label="close"
            onClick={() => setShowDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-1 text-black px-4" style={{ fontSize: "11px" }}>
            <span className="text-uppercase d-inline-flex align-items-center">
              <strong className="font-weight-light">
                {(index || no) + 1}:{assignment.itemTypeName}
              </strong>
              <ArrowRightIcon className="text-primary" />
              {assignment.name}{" "}
            </span>
            <div>
              <span className="text-info">
                Target share: {assignment.targetShare}{" "}
                {assignment.measurementUnit}
              </span>{" "}
              | Planned from:{" "}
              <span className="text-info">
                {moment(assignment.startOn).format("ll")}
              </span>{" "}
              To:{" "}
              <span className="text-info">
                {moment(assignment.endOn).format("ll")}
              </span>
              <strong
                style={{
                  position: "absolute",
                  right: 32,
                }}
                className="badge-info text-uppercase px-1"
              >
                quarter {assignment.quarterId}
              </strong>{" "}
            </div>
          </div>
        </DialogTitle>
        {isSubmitting && <LinearProgress />}
        <DialogContent
          className="px-5"
          dividers
          style={{
            minHeight: "52vh",
          }}
        >
          {!!isLoading && (
            <div className="text-center mt-5">
              <img src={loadingGif} height="128" alt="loading" />
            </div>
          )}

          {!isLoading && (
            <>
              {!submissions.length && (
                <div
                  className="alert alert-danger text-center mt-1"
                  role="alert"
                >
                  No report found
                </div>
              )}

              {(!!assignment.employeeTimePenalities ||
                !!assignment.employeeQualityPenalities) && (
                <div className="d-flex align-items-center justify-content-end">
                  {!!assignment.employeeTimePenalities && (
                    <span
                      className={`${
                        !!assignment.employeeQualityPenalities ? "mx-2" : ""
                      } badge badge-warning text-left text-uppercase`}
                      style={{ minWidth: "125px" }}
                    >
                      <span
                        className="badge badge-light bg-warning text-light"
                        style={{ fontSize: "12px" }}
                      >
                        {assignment.employeeTimePenalities}
                      </span>{" "}
                      Time penalty
                    </span>
                  )}
                  {!!assignment.employeeQualityPenalities && (
                    <span
                      className="badge badge-danger text-left text-uppercase"
                      style={{ minWidth: "125px" }}
                    >
                      <span
                        className="badge badge-light bg-danger text-light"
                        style={{ fontSize: "12px" }}
                      >
                        {assignment.employeeQualityPenalities}
                      </span>{" "}
                      Quality penalty
                    </span>
                  )}
                </div>
              )}

              {!!submissions.length &&
                submissions.map((submission, index) => (
                  <SubmissionCard
                    key={submission.id}
                    loading={loading}
                    submission={submission}
                    removeEvaluation={removeEvaluation}
                    assignment={assignment}
                    evaluateSubmission={evaluateSubmission}
                    selectedSubordinate={selectedSubordinate}
                    submissions={submissions}
                    index={index}
                  />
                ))}
            </>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, submissions }) => {
  return { loading, submissions };
};
export default connect(mapStateToProps, {
  removeEvaluation,
  getSubmissions,
  evaluateSubmission,
})(ReportAssignment);

const SubmissionCard = ({
  loading,
  submission,
  removeEvaluation,
  assignment,
  evaluateSubmission,
  selectedSubordinate,
  submissions,
  index,
}) => {
  const [confirmRemoveEvaluation, setConfirmRemoveEvaluation] = useState(false);
  const [viewSupportDoc, setViewSupportDoc] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [evaluationFormData, setEvaluationFormData] = useState({
    achievedTarget: "",
    completed:
      submission.completed === true && !assignment.targetShare
        ? "yes"
        : submission.completed === false && !assignment.targetShare
        ? "no"
        : "",
    accepted: false,
    supervisorComment: "",
    quality: "",
  });

  const [errors, setErrors] = useState({
    hasError: false,
    achievedTargetHasError: false,
    rankHasError: false,
    supervisorCommentHasError: false,
    fileHasError: false,
    fileErrorMessge: "",
    achievedTargetErrorMessage: "",
    rankErrorMessge: "",
    supervisorCommentErrorMessge: "",
  });

  const formValidator = () => {
    const error = {
      hasError: false,
      achievedTargetHasError: false,
      rankHasError: false,
      supervisorCommentHasError: false,
      fileHasError: false,
      fileErrorMessge: "",
      achievedTargetErrorMessage: "",
      rankErrorMessge: "",
      supervisorCommentErrorMessge: "",
    };

    if (
      assignment.targetShare === 0 &&
      evaluationFormData.completed === "" &&
      evaluationFormData.accepted
    ) {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Please, specify if ${
        assignment.itemTypeId === 1
          ? "output"
          : assignment.itemTypeId === 2
          ? "indicator"
          : "activity"
      } was completed or not`;
      error.hasError = true;
    }

    if (
      assignment.targetShare > 0 &&
      evaluationFormData.achievedTarget === "" &&
      evaluationFormData.accepted
    ) {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Please, specify the achieved target`;
      error.hasError = true;
    }

    if (
      +assignment.targetShare > 0 &&
      +evaluationFormData.achievedTarget < 0 &&
      evaluationFormData.accepted
    ) {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Achieved target can't be negative`;
      error.hasError = true;
    }

    if (+submission.achievedTarget < +evaluationFormData.achievedTarget) {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Achieved target can't be greater than the reported target`;
      error.hasError = true;
    }

    if (
      !evaluationFormData.quality &&
      evaluationFormData.accepted &&
      evaluationFormData.completed !== "no"
    ) {
      error.rankHasError = true;
      error.rankErrorMessge = `Please, rank the quality of reported work`;
      error.hasError = true;
    }

    if (!evaluationFormData.supervisorComment) {
      error.supervisorCommentHasError = true;
      error.supervisorCommentErrorMessge = `Please, Motivate your ranking`;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      evaluateSubmission(
        {
          ...evaluationFormData,
          quality: evaluationFormData.quality || 0,
          targetShare: assignment.targetShare,
          submissionId: submission.id,
          assignmentId: assignment.assignmentId,
          contractId: assignment.contractId,
          itemId: assignment.id,
          itemTypeId: assignment.itemTypeId,
          subordinateId: selectedSubordinate.id,
        },
        setIsSubmitting,
        setConfirm
      );
  };
  return (
    <>
      <div className="my-2 border rounded p-4">
        {/* report content */}
        <div className="row justify-content-start mb-3">
          <div className="col-11 text-secondary">
            <small
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <span className="d-none text-capitalize mr-1 d-md-inline-flex text-primary align-items-center">
                <PersonIcon />
                {submission.submitedBy?.lastName.toLowerCase()}{" "}
                {submission.submitedBy?.firstName.toLowerCase()}
              </span>{" "}
              {" /"} {moment(submission.createdOn).format("lll")}
            </small>
            <Card
              variant="outlined"
              className="rounded elevated pb-2"
              style={{ backgroundColor: "#f6f8fa" }}
            >
              <CardContent className=" pt-2 pb-2">
                <div>
                  <span className="d-flex align-items-center justify-content-between">
                    {assignment.targetShare > 0 && (
                      <span
                        className="badge badge-light mb-2 text-primary text-uppercase"
                        style={{ backgroundColor: "transparent" }}
                      >
                        {submission.achievedTarget} {assignment.measurementUnit}{" "}
                        reported
                      </span>
                    )}
                    {assignment.targetShare === 0 && (
                      <>
                        {submission.completed && (
                          <span
                            className="badge badge-light text-primary text-uppercase"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Reported as completed
                          </span>
                        )}
                        {!submission.completed && (
                          <span
                            className="badge badge-light text-danger text-uppercase"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Reported as Not completed
                          </span>
                        )}
                      </>
                    )}

                    {!!submission.docReferenceId && (
                      <Button
                        onClick={() => setViewSupportDoc(true)}
                        size="small"
                        className="float-right"
                      >
                        <AttachFileIcon /> Supporting Document <OpenInNewIcon />
                      </Button>
                    )}
                    {!submission.docReferenceId && (
                      <span className="float-right  badge badge-warning mt-2">
                        No file attached
                      </span>
                    )}

                    <span
                      className={`d-inline-flex align-items-center justify-content-center badge text-${
                        submission.statusId === 1
                          ? "secondary"
                          : submission.statusId === 4
                          ? "success"
                          : "danger"
                      }`}
                      style={{ width: "100px" }}
                    >
                      <span
                        style={{ fontSize: "17px" }}
                        className="material-icons"
                      >
                        {submission.statusId === 1 && <>pending_actions</>}
                        {submission.statusId === 4 && <>done_all</>}
                        {submission.statusId === 5 && <>close</>}
                        {submission.statusId === 2 && <>error</>}
                      </span>
                      <span
                        style={{ fontSize: "10px" }}
                        className="text-uppercase"
                      >
                        {submission.statusId !== 1 && (
                          <>{submission?.status?.name}</>
                        )}

                        {submission.statusId === 1 && <>Pending...</>}
                      </span>
                    </span>
                  </span>
                </div>

                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  className="mt-2 bg-white"
                  size="small"
                  multiline
                  rows={3}
                  name="name"
                  label="Employee message"
                  variant="outlined"
                  value={submission.employeeMessage}
                />
              </CardContent>
            </Card>
            {/* {submission.statusId === 1 && (
            <div
              className="alert alert-warning text-center py-0 mt-n0"
              style={{ borderRadius: "0px" }}
            >
              <small style={{ fontSize: "12px" }}>
                <marquee behavior="scroll" direction="left" scrollamount="4">
                  Penalties will be applied to you after{" "}
                  <strong>
                    {" "}
                    {submission.dueDays} day
                    {submission.dueDays > 1 ? "s" : ""}
                  </strong>{" "}
                  without evaluation.{" "}
                  <span className="px-1 badge-warning">(Today included)</span>
                </marquee>
              </small>
            </div>
          )} */}

            {!submission.evaluation && (
              <div className="text-center mt-3">
                <Button
                  onClick={() => {
                    setEvaluationFormData({
                      ...evaluationFormData,
                      accepted: true,
                    });
                    setConfirm(true);
                  }}
                  size="small"
                  color="success"
                  variant="contained"
                  className="mx-2 px-4"
                >
                  I Accept
                </Button>

                <Button
                  onClick={() => {
                    setEvaluationFormData({
                      ...evaluationFormData,
                      accepted: false,
                    });
                    setConfirm(true);
                  }}
                  size="small"
                  color="error"
                  variant="contained"
                  className="mx-2 px-4"
                >
                  I Reject
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* evaluation content */}
        {!!submission && !!submission.evaluation && (
          <div className="row justify-content-end mt-3 mb-3">
            <div className="col-11 text-right">
              <small
                className="d-flex align-items-center"
                style={{ fontSize: "12px" }}
              >
                <span className="d-none text-capitalize mr-1 d-md-inline-flex text-primary align-items-center">
                  <PersonIcon />
                  {submission.evaluation.evaluatedBy.lastName.toLowerCase()}{" "}
                  {submission.evaluation.evaluatedBy.firstName.toLowerCase()}
                </span>{" "}
                {" /"} {moment(submission.evaluation.createdOn).format("lll")}
              </small>

              <Card
                variant="outlined"
                className="rounded elevated"
                style={{ backgroundColor: "#f6f8fa" }}
              >
                <CardContent>
                  <div>
                    <div className="pb-2">
                      {submission.evaluation.accepted && (
                        <span className="float-left mt-n2 text-uppercase">
                          {assignment.targetShare > 0 && (
                            <span
                              className="badge badge-light mb-2 text-primary"
                              style={{ backgroundColor: "transparent" }}
                            >
                              {" "}
                              {submission.evaluation.achievedTarget}{" "}
                              {assignment.measurementUnit} accepted
                            </span>
                          )}
                          {assignment.targetShare === 0 && (
                            <>
                              {submission.evaluation.completed && (
                                <span
                                  className="badge badge-light text-success"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Completed accepted
                                </span>
                              )}
                              {!submission.evaluation.completed && (
                                <span
                                  className="badge badge-light text-danger"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Not completed
                                </span>
                              )}
                            </>
                          )}

                          <span
                            className={`badge ml-2 badge-${
                              +submission.evaluation.qualityValue === 0.95
                                ? "success"
                                : +submission.evaluation.qualityValue === 0.85
                                ? "primary"
                                : +submission.evaluation.qualityValue === 0.75
                                ? "info"
                                : +submission.evaluation.qualityValue === 0.6
                                ? "warning"
                                : "danger"
                            }`}
                          >
                            {+submission.evaluation.qualityValue === 0.95
                              ? "Excellent"
                              : +submission.evaluation.qualityValue === 0.85
                              ? "Very Good"
                              : +submission.evaluation.qualityValue === 0.75
                              ? "Good"
                              : +submission.evaluation.qualityValue === 0.6
                              ? "Fair"
                              : "Poor"}{" "}
                          </span>
                        </span>
                      )}

                      {!submission.evaluation.accepted && (
                        <div className="pb-2">
                          <span className="float-left text-uppercase">
                            <span
                              className="badge badge-light mb-2 text-danger"
                              style={{ backgroundColor: "transparent" }}
                            >
                              {/* <i className="fas fa-times"></i> */}
                              Rejected
                            </span>
                          </span>
                        </div>
                      )}

                      <span className="float-right mt-n2 text-uppercase d-inline-flex mt-1 align-items-center">
                        {submission.evaluation.accepted && (
                          <>
                            <span
                              className="badge badge-light mr-2 text-primary"
                              style={{ backgroundColor: "transparent" }}
                            >
                              Score:{" "}
                            </span>
                            <span style={{ width: "150px" }} className="mr-3">
                              <CustomProgressLine
                                value={submission.evaluation.score}
                                height={16}
                              />
                            </span>
                          </>
                        )}

                        <IconButton
                          disabled={submissions.length !== index + 1}
                          onClick={() => {
                            setConfirmRemoveEvaluation(true);
                          }}
                          size="small"
                          color="error"
                          className="mr-n3 mt-n2 border bg-light "
                        >
                          <span className="material-icons ">
                            delete_forever
                          </span>
                        </IconButton>
                      </span>
                    </div>
                  </div>
                  <TextField
                    style={{ backgroundColor: "#f6f8fa" }}
                    fullWidth
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    className="mt-2"
                    rows={3}
                    name="name"
                    autoFocus
                    label="Supervisor comment"
                    variant="outlined"
                    value={submission.evaluation.supervisorComment}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>

      {/* <Divider />
      <Divider /> */}

      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={() => {
          setConfirm(false);
        }}
        open={confirm}
      >
        <DialogTitle className="text-dark">
          <span
            style={{ fontSize: "16px" }}
            className={`pr-4 text-${
              evaluationFormData.accepted ? "primary" : "danger"
            }`}
          >
            {evaluationFormData.accepted ? "Evaluate report" : "Reject report"}
          </span>

          <IconButton
            aria-label="close"
            onClick={() => {
              setConfirm(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-1 text-black" style={{ fontSize: "11px" }}>
            <span className="text-uppercase d-inline-flex align-items-center">
              <strong className="font-weight-light">
                {assignment.itemTypeName}
              </strong>
              <ArrowRightIcon className="text-primary" />
              {assignment.name}{" "}
            </span>
            <div>
              Planned in{" "}
              <strong className="badge-info text-uppercase px-1">
                quarter {assignment.quarterId}
              </strong>{" "}
              from:{" "}
              <span className="text-info">
                {moment(assignment.startOn).format("ll")}
              </span>{" "}
              To:{" "}
              <span className="text-info">
                {moment(assignment.endOn).format("ll")}
              </span>
            </div>
          </div>
        </DialogTitle>
        {isSubmitting && (
          <LinearProgress
            color={`${!!evaluationFormData.accepted ? "primary" : "error"}`}
          />
        )}
        <DialogContent className="pb-1" dividers>
          {evaluationFormData.accepted && (
            <>
              {submission.achievedTarget > 0 && assignment.itemTypeId !== 1 ? (
                <div className="mb-2" style={{ fontSize: "13px" }}>
                  From{" "}
                  <span className="font-weight-bold text-primary">
                    {submission.achievedTarget} {assignment.measurementUnit}
                  </span>{" "}
                  reported by{" "}
                  <strong className="text-primary">
                    {selectedSubordinate.firstName}
                  </strong>{" "}
                  , how many do you confirm?
                </div>
              ) : assignment.itemTypeId !== 1 ? (
                <div>
                  Confirm if this{" "}
                  <strong className="text-primary">
                    {assignment.itemTypeName.toLowerCase()}
                  </strong>{" "}
                  was completed or not.
                </div>
              ) : (
                <small className="mb-2">
                  Confirm the level of annual target achievement.
                  <small
                    className="badge text-primary px-1 mt-1 border float-right"
                    style={{ backgroundColor: "#dfe7f6", borderRadius: "4px" }}
                  >
                    {submission.achievedTarget}
                    {assignment.measurementUnit} reported
                  </small>
                </small>
              )}
              {assignment.targetShare > 0 && assignment.itemTypeId !== 1 ? (
                <>
                  <TextField
                    error={errors.achievedTargetHasError}
                    helperText={errors.achievedTargetErrorMessage}
                    fullWidth
                    size="small"
                    type="number"
                    name="achievedTarget"
                    autoFocus={!!assignment.targetShare}
                    label="Achieved Target"
                    variant="outlined"
                    placeholder="0"
                    className="mb-3 mt-1"
                    value={evaluationFormData.achievedTarget}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onBlur={() => {
                      if (evaluationFormData.achievedTarget === "")
                        setEvaluationFormData({
                          ...evaluationFormData,
                          achievedTarget: "",
                        });
                    }}
                    onKeyDown={() => {
                      if (+evaluationFormData.achievedTarget === 0)
                        setEvaluationFormData({
                          ...evaluationFormData,
                          achievedTarget: "",
                        });
                    }}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        achievedTargetHasError: false,
                        achievedTargetErrorMessage: "",
                      });
                      const achievedTarget = e.target.value;
                      setEvaluationFormData({
                        ...evaluationFormData,
                        achievedTarget: achievedTarget || "",
                      });
                    }}
                  />
                  {+evaluationFormData.achievedTarget === 0 &&
                    evaluationFormData.achievedTarget !== "" && (
                      <div
                        className="alert alert-warning py-0 mt-n2 border border-warning"
                        style={{ borderRadius: "4px" }}
                      >
                        <small style={{ fontSize: "12px" }}>
                          <strong>{selectedSubordinate.firstName}</strong> will
                          get zore(0%) on this activity.
                        </small>
                      </div>
                    )}
                </>
              ) : assignment.itemTypeId !== 1 ? (
                <>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={evaluationFormData.completed}
                    onChange={(e, v) => {
                      setErrors({
                        ...errors,
                        achievedTargetHasError: false,
                        achievedTargetErrorMessage: "",
                      });

                      setEvaluationFormData({
                        ...evaluationFormData,
                        completed: v,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          disabled={!submission.completed}
                          sx={{
                            color: errors.achievedTargetHasError ? "red" : "",
                          }}
                        />
                      }
                      label="Completed"
                    />

                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: errors.achievedTargetHasError ? "red" : "",
                          }}
                        />
                      }
                      label="Not completed"
                    />
                  </RadioGroup>
                  {evaluationFormData.completed === "no" && (
                    <div
                      className="alert alert-warning py-0 mb-3 mt-n3 border border-warning"
                      style={{ borderRadius: "4px" }}
                    >
                      <small style={{ fontSize: "12px" }}>
                        <strong>{selectedSubordinate.firstName}</strong> will
                        get zore(0%) on this activity.
                      </small>
                    </div>
                  )}
                  {errors.achievedTargetHasError && (
                    <small className="d-block mt-n3 mb-3 text-danger">
                      <small>{errors.achievedTargetErrorMessage}</small>
                    </small>
                  )}
                </>
              ) : (
                <FormControl fullWidth size="small" className="mb-3 mt-2">
                  <InputLabel
                    id="demo-simple-select-label"
                    className={`${
                      errors.achievedTargetHasError ? "text-danger" : ""
                    }`}
                  >
                    Select the level of achievement
                  </InputLabel>
                  <Select
                    error={errors.achievedTargetHasError}
                    size="small"
                    value={evaluationFormData.achievedTarget}
                    label="Select the level of achievement"
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        achievedTargetHasError: false,
                        achievedTargetErrorMessage: "",
                      });
                      const achievedTarget = e.target.value;
                      setEvaluationFormData({
                        ...evaluationFormData,
                        achievedTarget: achievedTarget,
                      });
                    }}
                  >
                    {+submission.achievedTarget === 100 && (
                      <MenuItem value={100}>
                        1:Beyond expected{" "}
                        <small className="ml-1 badge-success px-1 border rounded">
                          ~100%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 90 && (
                      <MenuItem value={90}>
                        2:As expected{" "}
                        <small className="ml-1 badge-success px-1 border rounded">
                          ~90%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 80 && (
                      <MenuItem value={80}>
                        3:Great{" "}
                        <small className="ml-1 badge-info px-1 border rounded">
                          ~80%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 70 && (
                      <MenuItem value={70}>
                        4: Moderate{" "}
                        <small className="ml-1 badge-info px-1 border rounded">
                          ~70%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 60 && (
                      <MenuItem value={60}>
                        5:Fairly{" "}
                        <small className="ml-1 badge-warning px-1 border rounded">
                          ~60%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 50 && (
                      <MenuItem value={50}>
                        6:Few{" "}
                        <small className="ml-1 badge-warning px-1 border rounded">
                          ~50%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 40 && (
                      <MenuItem value={40}>
                        7:Poor{" "}
                        <small className="ml-1 badge-danger px-1 border rounded">
                          ~40%
                        </small>
                      </MenuItem>
                    )}
                    {+submission.achievedTarget >= 20 && (
                      <MenuItem value={20}>
                        8:Very poor{" "}
                        <small className="ml-1 badge-danger px-1 border rounded">
                          ~20%
                        </small>
                      </MenuItem>
                    )}
                    <MenuItem value={0}>
                      9:Nothing done{" "}
                      <small className="ml-1 badge-danger px-1 border rounded">
                        ~00%
                      </small>
                    </MenuItem>
                  </Select>
                  <small
                    className="text-danger ml-2"
                    style={{ fontSize: "12px" }}
                  >
                    {errors.achievedTargetErrorMessage}
                  </small>
                  {+evaluationFormData.achievedTarget === 0 &&
                    evaluationFormData.achievedTarget !== "" && (
                      <div
                        className="alert alert-warning py-0 "
                        style={{ borderRadius: "4px" }}
                      >
                        <small style={{ fontSize: "12px" }}>
                          <strong>{selectedSubordinate.firstName}</strong> will
                          get zore(0%) on this output.
                        </small>
                      </div>
                    )}
                </FormControl>
              )}
              {evaluationFormData.completed !== "no" && (
                <FormControl fullWidth size="small" className="mb-3">
                  <InputLabel id="demo-simple-select-label">
                    Rank the quality of reported work
                  </InputLabel>
                  <Select
                    error={errors.rankHasError}
                    size="small"
                    value={evaluationFormData.quality}
                    label="Rank the quality of reported work"
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        rankHasError: false,
                        rankErrorMessge: "",
                      });

                      const quality = e.target.value;
                      setEvaluationFormData({
                        ...evaluationFormData,
                        quality,
                      });
                    }}
                  >
                    <MenuItem value={0.95}>Excellent(x0.95)</MenuItem>
                    <MenuItem value={0.85}>Very Good(x0.85)</MenuItem>
                    <MenuItem value={0.75}>Good(x0.75)</MenuItem>
                    <MenuItem value={0.6}>Fair(x0.60)</MenuItem>
                    <MenuItem value={0.4}>Poor(x0.40)</MenuItem>
                  </Select>
                  <small
                    className="text-danger ml-2"
                    style={{ fontSize: "12px" }}
                  >
                    {errors.rankErrorMessge}
                  </small>
                </FormControl>
              )}
            </>
          )}

          <TextField
            error={errors.supervisorCommentHasError}
            helperText={errors.supervisorCommentErrorMessge}
            fullWidth
            size="small"
            multiline
            rows={3}
            name="description"
            autoFocus={!assignment.targetShare}
            label="Motivate the ranking"
            placeholder="Motivate the ranking"
            variant="outlined"
            onChange={(e) => {
              setErrors({
                ...errors,
                supervisorCommentHasError: false,
                supervisorCommentErrorMessge: "",
              });

              setEvaluationFormData({
                ...evaluationFormData,
                supervisorComment: e.target.value || "",
              });
            }}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            onClick={onSave}
            size="small"
            color={`${evaluationFormData.accepted ? "success" : "error"}`}
            variant="contained"
            className="px-5"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? "Wait..."
              : evaluationFormData.accepted
              ? "Confirm"
              : "Reject"}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        confirmationDialog={confirmRemoveEvaluation}
        message={`Are you sure you want to remove this evaluation?`}
        setConfirmationDialog={setConfirmRemoveEvaluation}
        onYes={() =>
          removeEvaluation(
            {
              submissionId: submission.id,
              assignmentId: assignment.assignmentId,
              contractId: assignment.contractId,
              itemId: assignment.id,
              itemTypeId: assignment.itemTypeId,
              subordinateId: selectedSubordinate.id,
            },
            setConfirmRemoveEvaluation
          )
        }
      />

      {!!viewSupportDoc && (
        <PreviewPdfUrl
          showDocument={viewSupportDoc}
          setShowDocument={setViewSupportDoc}
          document={{
            name: `View Supporting Document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              submission.docReferenceId,
          }}
        />
      )}
    </>
  );
};
