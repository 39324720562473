import React, { useState } from "react";
import { Button } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import { currencyFormat } from "../../common/components/Utils";

const RBMScoreTableView = ({
  data,
  isForMissing = false,
  isForAreas = false,
  isFromAPI = false,
}) => {
  const [visibleData, setVisibleData] = useState(30);

  const handlePageClick = (action) => {
    if (action === "less" && visibleData > 30) setVisibleData(visibleData - 30);
    if (action === "more" && visibleData < data.length)
      setVisibleData(visibleData + 30);
  };

  return (
    <div className="table-responsive">
      <table
        className={`table table-sm table-bordered fixTableHead ${
          isForMissing ? "table-danger" : ""
        }`}
        style={{ fontSize: "10px" }}
      >
        <thead>
          <tr className="">
            <th className="text-left" style={{ width: "75px" }}>
              Employee No
            </th>
            {!isForAreas && <th className="text-left">Last Name</th>}
            {!isForAreas && <th className="text-left">First Name</th>}
            {!isForAreas && <th className="text-left">Entity Name</th>}
            {!isForAreas && <th className="text-left">Unit Name</th>}
            {!isForAreas && <th className="text-left">Position Name</th>}
            <th
              className="text-center"
              style={{
                width: "90px",
              }}
            >
              Score
            </th>
            {isForAreas && <th className="text-center">Rate</th>}
            {isForAreas && <th className="text-left">Category</th>}
            {isForAreas && <th className="text-center">Months</th>}
            {isForAreas && !isFromAPI && (
              <th className="text-center">Monthly Basic Salary</th>
            )}
            {isForAreas && <th className="text-center">Annual Basic Salary</th>}
            {isForAreas && <th className="text-center">Basic Bonus</th>}
            {isFromAPI && <th className="text-left">Comment</th>}
          </tr>
        </thead>
        <tbody className="rbm-row">
          {data.slice(0, visibleData).map((item, index) => (
            <tr
              key={index}
              style={{
                background: isForMissing
                  ? "#fff"
                  : (index + 1) % 2 === 0
                  ? "#f6f7f7"
                  : "",
                // borderBottom: "2px dotted rgb(7, 142, 206)!important",
              }}
            >
              {/* cells for output */}
              <td align="center">{item.employeeId}</td>
              {!isForAreas && <td>{item.lastName}</td>}
              {!isForAreas && <td align="left">{item.firstName}</td>}
              {!isForAreas && <td align="left">{item.entityName}</td>}
              {!isForAreas && <td align="left">{item.unitName}</td>}
              {!isForAreas && <td align="left">{item.positionName}</td>}

              <td align="center">{item.score}%</td>
              {isForAreas && <td align="center">{item.rate}</td>}
              {isForAreas && <td align="left">{item.category}</td>}
              {isForAreas && <td align="center">{item.months}</td>}
              {isForAreas && !isFromAPI && (
                <td align="center">
                  {currencyFormat(item.monthlyBasicSalary)}
                </td>
              )}
              {isForAreas && (
                <td align="center">{currencyFormat(item.annualBasicSalary)}</td>
              )}
              {isForAreas && (
                <td align="center">{currencyFormat(item.basicBonus)}</td>
              )}
              {isFromAPI && <td align="left">{item.reason}</td>}
            </tr>
          ))}
        </tbody>
      </table>

      {visibleData < data.length && (
        <div className="text-center mt-4">
          <div className="mx-auto mb-2">
            {visibleData}/{data.length} Activities
          </div>
          <div className="d-flex align-items-center justify-content-center">
            {visibleData < data.length && (
              <Button
                variant="text"
                onClick={() => handlePageClick("more")}
                startIcon={<ExpandMoreRoundedIcon />}
              >
                Show More
              </Button>
            )}
            {visibleData > 30 && (
              <Button
                className="ml-2"
                variant="text"
                color="inherit"
                onClick={() => handlePageClick("less")}
                startIcon={<ExpandLessRoundedIcon />}
              >
                Show Less
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RBMScoreTableView;
