import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import {
  getPerformanceBonusScores,
  loadRBMScores,
  reomeEmployeeFromBonus,
  getEmployeeAnnualSalaries,
  downloadRBMScoreTemplate,
  getPayrollPeriods,
} from "../../../store/payroll/actions";
import { connect } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import GeneratePayrollDialog from "../components/GeneratePayrollDialog";
import { CardContent, Toolbar } from "@mui/material";
import loadingGif from "../../assets/loading.gif";
import SearchBox from "../../common/components/SearchBox";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import FirscalYearSwitcher from "../../rbm/components/FirscalYearSwitcher";
import ReactPaginate from "react-paginate";
import { currencyFormat } from "../../common/components/Utils";
import AddToBonusDialog from "../components/AddToBonusDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import UploadRBMScoreDialog from "../components/UploadRBMScoreDialog";
import AddEmployeeBonusResultDialog from "../components/AddEmployeeBonusResultDialog";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

const PerformanceBonus = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Performance Bonus";
  });

  const {
    user,
    loading,
    onWait,
    performanceBonusScores,
    getPerformanceBonusScores,
    selectedFiscalYear,
    loadRBMScores,
    reomeEmployeeFromBonus,
    getEmployeeAnnualSalaries,
    downloadRBMScoreTemplate,
    getPayrollPeriods,
  } = props;

  const [searchTeam, setSearchTeam] = useState("");

  const [payrollPeriods, setPayrollPeriods] = useState([]);

  useEffect(() => {
    !payrollPeriods[0] && getPayrollPeriods(setPayrollPeriods);
  }, []);

  useEffect(() => {
    // if (!performanceBonusScores.length)
    getPerformanceBonusScores();
  }, [selectedFiscalYear]);

  const [filteredItems, setFilteredItems] = useState([
    ...performanceBonusScores,
  ]);

  useEffect(() => {
    const filtered = [...performanceBonusScores].filter(
      ({ names, position, unit, category }) =>
        ((names || "") + (position || "") + (unit || "") + (category || ""))
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTeam, performanceBonusScores]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  const { hasPermissions } = useRequiredAuth();

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 20;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredItems);
  }, [itemOffset, itemsPerPage, performanceBonusScores, filteredItems]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredItems.length || 0;

    setItemOffset(newOffset);
  };

  const [showGeneratePayrollForm, setShowGeneratePayrollForm] = useState(false);
  const [showAddEmployeeResultForm, setShowAddEmployeeResultForm] =
    useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const isRBMExternalUsers =
    ([5, 6, 14].includes(user.selectedEntitySector.sectorId) ||
      [
        "0500000000-040000000000000000-03",
        "0702000000-180000000000000000-08",
      ].includes(user.selectedEntitySector.id)) &&
    ![
      "1417000000-030000000000000000-05",
      "1601000000-020000000000000000-06",
    ].includes(user.selectedEntitySector.id) &&
    +selectedFiscalYear?.id > 2122;

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span className="text-uppercase">Performance Bonus</span>
              </Typography>

              <>
                <Box
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  className="ml-2 bg-secondary"
                  style={{ borderRadius: "5px" }}
                >
                  <FirscalYearSwitcher disabled={false} />
                </Box>
                {hasPermissions(["IS_HR"]) &&
                  !!performanceBonusScores.length && (
                    <Button
                      onClick={() => {
                        setShowGeneratePayrollForm(true);
                      }}
                      style={{
                        // float: "right",
                        borderRadius: "5px",
                        height: "35px",
                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                        border: `1px solid rgb(7, 142, 206)`,
                        display: "flex",
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                      size="md"
                      startIcon={<AddIcon />}
                      className="ml-2"
                    >
                      Generate Payroll
                    </Button>
                  )}
                <Box sx={{ flexGrow: 1 }} />
              </>

              {(!!performanceBonusScores.length ||
                user.selectedEntitySector.id ===
                  "1700000000-090000000000000000-11") && (
                <div className="d-flex flex-row justify-content-center align-items-center mr-1">
                  {[6].includes(user.selectedEntitySector.sectorId) &&
                    +selectedFiscalYear?.id > 2122 && (
                      <button
                        disabled={loading}
                        onClick={() => downloadRBMScoreTemplate()}
                        type="button"
                        className="btn btn-success"
                      >
                        {loading && (
                          <i className="fas fa-spinner loader2 mr-2"></i>
                        )}
                        {loading ? "Loading..." : "Download template"}
                      </button>
                    )}

                  {([6].includes(user.selectedEntitySector.sectorId) ||
                    user.selectedEntitySector.id ===
                      "1700000000-090000000000000000-11") && (
                    <button
                      disabled={loading}
                      onClick={() => setShowUploadForm((prev) => !prev)}
                      type="button"
                      className="btn btn-secondary ml-2"
                    >
                      Upload scores
                    </button>
                  )}
                </div>
              )}

              {hasPermissions(["IS_HR"]) &&
                // [5, 6, 14].includes(user.selectedEntitySector.sectorId) &&
                !!performanceBonusScores.length && (
                  <Button
                    onClick={() => {
                      setShowAddEmployeeResultForm(true);
                    }}
                    style={{
                      // float: "right",
                      borderRadius: "5px",
                      height: "35px",
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      border: `1px solid rgb(7, 116, 124)`,
                      display: "flex",
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "rgb(7, 116, 124)",
                      "&:hover": {
                        backgroundColor: "#f0f2f5",
                      },
                    }}
                    size="md"
                    startIcon={<AddIcon />}
                    className="mr-1"
                  >
                    Add Employee
                  </Button>
                )}
              {hasPermissions(["IS_HR"]) && !isRBMExternalUsers && (
                <Button
                  disabled={
                    loading ||
                    [5, 6, 14].includes(user.selectedEntitySector.sectorId)
                  }
                  onClick={() =>
                    loadRBMScores(
                      user?.selectedEntitySector?.entityId,
                      selectedFiscalYear.id
                    )
                  }
                  style={{
                    // float: "right",
                    borderRadius: "5px",
                    height: "35px",
                    // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    border: `1px solid rgb(7, 142, 206)`,
                    display: "flex",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                    backgroundColor: "rgb(7, 142, 206)",
                    "&:hover": {
                      backgroundColor: "#f0f2f5",
                    },
                  }}
                  size="md"
                  startIcon={<CachedRoundedIcon />}
                  className="mr-1"
                >
                  Reload Scores
                </Button>
              )}
              <button
                disabled
                type="button"
                className="btn btn-outline-primary mr-1"
              >
                <span className="badge badge-primary">
                  {
                    filteredItems.filter(({ wasAdded }) => wasAdded === true)
                      .length
                  }
                </span>{" "}
                Added
              </button>
              <button
                disabled
                type="button"
                className="btn btn-outline-secondary mr-1"
              >
                <span className="badge badge-secondary">
                  {
                    filteredItems.filter(({ wasAdded }) => wasAdded === false)
                      .length
                  }
                </span>{" "}
                Not added
              </button>
              <Box
                sx={{ display: { xs: "flex", md: "flex" } }}
                className="ml-2"
              >
                <SearchBox
                  disabled={!performanceBonusScores.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>

          {/* {showGeneratePayrollForm && (
            <GeneratePayrollDialog
              showGeneratePayrollForm={showGeneratePayrollForm}
              setShowGeneratePayrollForm={setShowGeneratePayrollForm}
            />
          )} */}

          <div
            style={{
              // padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              // margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              className="py-0 "
              style={{
                height: "auto",
                maxHeight: "55vh",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              {/* {!!filteredItems && !!filteredItems.length && ( */}
              <table className=" table-striped table-hover table-sm ">
                <thead>
                  <tr>
                    <th>#</th>
                    {[6].includes(user.selectedEntitySector.sectorId) && (
                      <th>Parent Unit</th>
                    )}
                    <th>EmployeeId </th>
                    <th className="px-1">Names</th>
                    <th className="px-1">Position</th>
                    <th className="px-1">EmployeeGroup</th>
                    <th className="px-1">Level</th>
                    {/* <th className="px-1">Unit</th> */}
                    <th className="px-1 text-right">Score</th>
                    <th className="px-1">Category</th>
                    <th className="px-1">Rate</th>
                    <th className="px-1">Months</th>
                    <th className="text-center">AnnualBasicSalary</th>
                    <th className="text-center">BasicBonus</th>
                    <th className="text-center">NetBonus</th>
                    <th className="pl-1" style={{ width: "84px" }}></th>
                    <th className="px-1"></th>
                  </tr>
                </thead>

                {!!paginatedItems && (
                  <tbody>
                    {paginatedItems.map((item, index) => (
                      <BonusCard
                        key={item.id + index}
                        itemIndex={+getNo(item.id, performanceBonusScores)}
                        item={item}
                        selectedFiscalYear={selectedFiscalYear}
                        user={user}
                        reomeEmployeeFromBonus={reomeEmployeeFromBonus}
                        getEmployeeAnnualSalaries={getEmployeeAnnualSalaries}
                        getPerformanceBonusScores={getPerformanceBonusScores}
                        loading={loading}
                        hasPermissions={hasPermissions}
                        showUnit={[6].includes(
                          user.selectedEntitySector.sectorId
                        )}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              {/* )} */}

              {!!performanceBonusScores.length &&
                (!paginatedItems ||
                  (!!paginatedItems && !paginatedItems.length && !loading)) && (
                  <div className="text-center ">
                    <p className="lead">No record found!</p>
                  </div>
                )}

              {((!performanceBonusScores.length && !loading) ||
                (loading && onWait && !performanceBonusScores.length)) && (
                <div className="jumbotron jumbotron-fluid text-center">
                  <div className="container">
                    <p className="lead">
                      {loading
                        ? "Loading RBM scores..."
                        : "RBM scores not loaded!"}
                    </p>
                    {/**/}
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      {isRBMExternalUsers ? (
                        <button
                          disabled={loading}
                          onClick={() => downloadRBMScoreTemplate()}
                          type="button"
                          className="btn btn-success"
                        >
                          {loading && (
                            <i className="fas fa-spinner loader2 mr-2"></i>
                          )}
                          {loading
                            ? "Loading..."
                            : "Download RBM score template"}
                        </button>
                      ) : (
                        <button
                          disabled={
                            loading ||
                            ([5, 6, 14].includes(
                              user.selectedEntitySector.sectorId
                            ) &&
                              ![
                                "1417000000-030000000000000000-05",
                                "1601000000-020000000000000000-06",
                              ].includes(user.selectedEntitySector.id))
                          }
                          onClick={() =>
                            loadRBMScores(
                              user?.selectedEntitySector?.entityId,
                              selectedFiscalYear.id
                            )
                          }
                          type="button"
                          className="btn btn-primary"
                        >
                          {loading && (
                            <i className="fas fa-spinner loader2 mr-2"></i>
                          )}
                          {loading
                            ? "Loading RBM scores..."
                            : "Click here to load scores"}
                        </button>
                      )}
                      {isRBMExternalUsers && (
                        <button
                          disabled={loading}
                          onClick={() => setShowUploadForm((prev) => !prev)}
                          type="button"
                          className="btn btn-secondary ml-3"
                        >
                          Click here to upload scores
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {loading && !onWait && (
                <div className="text-center">
                  <img src={loadingGif} height="128" alt="loading" />
                </div>
              )}
            </CardContent>
            <br />

            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className="pr-1 d-none d-md-inline">Next</span>
                  <i className="fas fa-angle-double-right"></i>
                </>
              }
              previousLabel={
                <>
                  <i className="fas fa-angle-double-left"></i>
                  <span className="pl-1  d-none d-md-inline">Previous</span>
                </>
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
              previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
              nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
              activeLinkClassName="active"
            />
          </div>
        </div>
      </Box>

      {showGeneratePayrollForm && (
        <GeneratePayrollDialog
          isBonus={true}
          showGeneratePayrollForm={showGeneratePayrollForm}
          setShowGeneratePayrollForm={setShowGeneratePayrollForm}
          payrollPeriods={payrollPeriods}
        />
      )}

      {showAddEmployeeResultForm && (
        <AddEmployeeBonusResultDialog
          showAddEmployeeResultForm={showAddEmployeeResultForm}
          setShowAddEmployeeResultForm={setShowAddEmployeeResultForm}
        />
      )}

      {showUploadForm && (
        <UploadRBMScoreDialog
          onClose={() => setShowUploadForm(false)}
          open={showUploadForm}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  performanceBonusScores,
  selectedFiscalYear,
}) => {
  return {
    user,
    loading,
    onWait,
    performanceBonusScores,
    selectedFiscalYear,
  };
};

export default connect(mapStateToProps, {
  getPerformanceBonusScores,
  reomeEmployeeFromBonus,
  loadRBMScores,
  getEmployeeAnnualSalaries,
  downloadRBMScoreTemplate,
  getPayrollPeriods,
})(PerformanceBonus);

const BonusCard = (props) => {
  const {
    loading,
    item,
    itemIndex,
    selectedFiscalYear,
    user,
    reomeEmployeeFromBonus,
    getEmployeeAnnualSalaries,
    hasPermissions,
    showUnit = false,
  } = props;

  const [showRemoveFromBonusDialog, setShowRemoveFromBonusDialog] =
    useState(false);

  const [showAddToBonusDialog, setShowAddToBonusDialog] = useState(false);

  return (
    <>
      <tr className={`${!item.id || +item.score < 70 ? "red" : ""}`}>
        <td>{itemIndex}</td>
        {showUnit && (
          <td>
            <span>{item.unitName || "N/A"}</span>
          </td>
        )}
        <td>
          <span>{item.id}</span>
        </td>
        <td>
          <span>{item.names}</span>
        </td>

        <td>{item.position}</td>

        <td>{item.employeeGroupName}</td>
        <td className="text-right">{item.level}</td>
        {/* <td>{item.unit}</td> */}
        <td className="text-right">{item.score}</td>
        <td>{item.category}</td>
        <td className="text-center">{item.rate}</td>
        <td className="text-center">{item.months || "-"}</td>
        <td className="text-center">
          {!!item.annualBasicSalary
            ? currencyFormat(item.annualBasicSalary)
            : "-"}
        </td>
        <td className="text-center">
          {!!item.basicBonus ? currencyFormat(item.basicBonus) : "-"}
        </td>
        <td className="text-center">
          {!!item.netBonus ? currencyFormat(item.netBonus) : "-"}
        </td>
        <td className="text-center">
          {!!item.wasAdded && (
            <span className="text-success">
              <i className="fas fa-check-double"></i> Added
            </span>
          )}
          {!item.wasAdded && <span>Not added</span>}
        </td>

        <td
          style={{
            padding: "2px",
          }}
        >
          {!item.wasAdded && hasPermissions(["IS_HR"]) && (
            <Button
              disabled={loading || +item.score < 70}
              onClick={() => {
                getEmployeeAnnualSalaries(
                  item.id,
                  item.oldEmployeeId,
                  setShowAddToBonusDialog
                );
              }}
              variant="contained"
              size="small"
              disableElevation
              color="primary"
              style={{
                borderRadius: "3px",
                height: "20px",
                width: "80px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons mr-2">add</span>
              Add
            </Button>
          )}
          {!!item.wasAdded && hasPermissions(["IS_HR"]) && (
            <Button
              disabled={loading}
              onClick={() => {
                setShowRemoveFromBonusDialog(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="error"
              style={{
                borderRadius: "3px",
                height: "20px",
                width: "80px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span
                className="material-icons mr-1"
                style={{ fontSize: "18px" }}
              >
                delete_forever
              </span>
              Remove
            </Button>
          )}
        </td>
      </tr>

      {showAddToBonusDialog && (
        <AddToBonusDialog
          showDialog={showAddToBonusDialog}
          setShowDialog={setShowAddToBonusDialog}
          employee={item}
        />
      )}

      {showRemoveFromBonusDialog && (
        <ConfirmationDialog
          confirmationDialog={showRemoveFromBonusDialog}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong>{" "}
              <strong className="text-info">{item.names}</strong> from bonus?
            </>
          }
          setConfirmationDialog={setShowRemoveFromBonusDialog}
          onYes={() => {
            reomeEmployeeFromBonus(
              {
                employeeId: item.id,
                oldEmployeeId: item.oldEmployeeId,
                selectedEntitySectorId: user.selectedEntitySector.id,
                entityId: user.selectedEntitySector.entityId,
                fiscalYearId: selectedFiscalYear.id,
                unitId: item.unitId,
              },
              setShowRemoveFromBonusDialog
            );
          }}
        />
      )}
    </>
  );
};
