import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CardContent, IconButton, Menu, MenuItem } from "@mui/material";
import { getExitTypes } from "../../../store/exit/actions";
import { Button } from "bootstrap";
import AddExitTypeDialog from "../components/AddExitTypeDialog";
import UpdateExitDialog from "../components/UpdateExitDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ExitType = (props) => {
  const { exitTypes, getExitTypes, index, loading, deleteExitType } = props;
  const [selectedExitType, setSelectedExitType] = useState(null);
  const [editExitType, setEditExitType] = useState(null);
  const [value, setValue] = useState(0);
  const [confirmRemoveExitType, setconfirmRemoveExitType] = useState(false);
  const [addExitTypeForm, setAddExitTypeForm] = useState(false);
  const [approveExitTypeForm, setApproveExitTypeForm] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getExitTypes();
  }, []);

  return (
    <>
      <div
        style={{
          marginLeft: "1em",
          marginRight: "1em",
          backgroundColor: "#fff",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "65vh",
          overflowY: "scroll",
        }}
      >
        {confirmRemoveExitType && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveExitType}
            message={`Are you sure you want to remove this Exit Type 
            @ ${selectedExitType.exitType} ?`}
            setConfirmationDialog={setconfirmRemoveExitType}
            onYes={() => {
              deleteExitType(
                selectedExitType,
                setconfirmRemoveExitType,
                setSelectedExitType
              );
            }}
          />
        )}

        <AddExitTypeDialog
          addExitTypeForm={addExitTypeForm}
          setAddExitTypeForm={setAddExitTypeForm}
        />
        <UpdateExitDialog
          editExitType={editExitType}
          setEditExitType={setEditExitType}
          selectedExitType={selectedExitType}
          setSelectedExitType={setSelectedExitType}
        />
        {
          // /* <DetailsExitDialog
          //   detailRequestForm={detailRequestForm}
          //   setDetailRequestForm={setDetailRequestForm}
          //   setSelectedRequest={setSelectedRequest}
          //   selectedRequest={selectedRequest}
          // /> */
        }

        <CardContent className="pl-0 pt-0">
          <button
            onClick={() => {
              setAddExitTypeForm(true);
            }}
            type="button"
            className="btn btn-primary text-uppercase  px-4 btn-sm float-right m-2 "
          >
            {loading ? "Wait..." : "+ Add New"}
          </button>
          <table className="table table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col">#</th>
                <th scope="col">Exit Type:</th>
                <th scope="col">Initiator:</th>
                <th scope="col" className="text-center">
                  # BlackList Period:
                </th>
                <th scope="col" className="text-center">
                  Pay Terminal Benefit :
                </th>
                <th scope="col" className="text-center">
                  #Can Be Rehired :
                </th>
                <th scope="col">Actions :</th>
              </tr>
            </thead>

            <tbody>
              {exitTypes.map((exitType, index) => (
                <ExitTypeItem
                  key={index}
                  exitType={exitType}
                  index={index}
                  setconfirmRemoveExitType={setconfirmRemoveExitType}
                  setSelectedExitType={setSelectedExitType}
                  setEditExitType={setEditExitType}
                  //   setUpdateRequestForm={setUpdateRequestForm}
                  //   setUpdateMyRequestForm={setUpdateMyRequestForm}
                  //   setDetailRequestForm={setDetailRequestForm}
                />
              ))}
            </tbody>
          </table>
        </CardContent>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, exitTypes }) => {
  return { user, loading, exitTypes };
};
export default connect(mapStateToProps, { getExitTypes })(ExitType);

const ExitTypeItem = (props) => {
  const {
    index,
    exitType,
    setconfirmRemoveExitType,
    setSelectedExitType,
    setEditExitType,
    // setUpdateRequestForm,
    // setUpdateMyRequestForm,
    // setDetailRequestForm,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <tr>
      <td>{index + 1}</td>

      <td>{exitType.name}</td>
      <td>
        {exitType.initiatorId === 1 && " Employee"}
        {exitType.initiatorId === 2 && " HR"}
        {exitType.initiatorId === 3 && " Appointing authority or MIFOTRA"}
        {exitType.initiatorId === 4 && " MIFOTRA"}
        {exitType.initiatorId === 5 && " MINALOC"}
      </td>
      <td align="center">{exitType.blacklistPeriod}</td>
      <td align="center">{exitType.payTerminalBenefit ? "YES" : "NO"}</td>
      <td align="center">{exitType.canBeRehired ? "YES" : "NO"}</td>

      <td>
        <IconButton
          onClick={handleClick}
          color="primary"
          aria-label="add to shopping cart"
        >
          <span className="material-icons-outlined">more_vert</span>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setSelectedExitType(exitType);
              setEditExitType(true);
              handleCloseMenu();
            }}
          >
            Edit
          </MenuItem>

          <MenuItem
            onClick={() => {
              setSelectedExitType(exitType);
              setconfirmRemoveExitType(true);
              handleCloseMenu();
            }}
            className="text-danger font-weight-light"
          >
            Delete
          </MenuItem>
        </Menu>
      </td>
    </tr>
  );
};
