const types = {
  ADD_REQUEST: "ADD_REQUEST",
  SET_EXIT_REQUESTS: "SET_EXIT_REQUESTS",
  SET_MY_REQUESTS: "SET_MY_REQUESTS",
  SET_APPROVED_REQUESTS: "SET_APPROVED_REQUESTS",
  SET_EXIT_TYPES: "SET_EXIT_TYPES",
  SET_INITIATORS: "SET_INITIATORS",
  SET_BLACKLIST_REASON: "SET_BLACKLIST_REASON",
  SET_SEARCH_EMPLOYEE: "SET_SEARCH_EMPLOYEE",
  DELETE_MY_REQUEST: "DELETE_MY_REQUEST",
  UPDATE_REJECT_REQUEST: "UPDATE_REJECT_REQUEST",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  ADD_BLACKLIST: "ADD_BLACKLIST",
  UPDATE_BLACKLIST: "UPDATE_BLACKLIST",
  DELETE_BLACKLIST: "DELETE_BLACKLIST",
  SET_BLACKLISTS: "SET_BLACKLISTS",
  SET_REHABILITATION_REQUESTS: "SET_REBABILITATION_REQUESTS",
  UPDATE_APPROVE_REHABILITATION: "UPDATE_APPROVE_REHABILITATION",
  SET_HR_EXIT_TYPES: "SET_HR_EXIT_TYPES",
  ADD_EXIT_TYPE: "ADD_EXIT_TYPE",
  SET_EMPLOYEE_EXIT_REQUEST: "SET_EMPLOYEE_EXIT_REQUEST",
  UPDATE_EMPLOYEE:"UPDATE_EMPLOYEE",
  ADD_EXIT_REQUEST_ATTACHMENT:"ADD_EXIT_REQUEST_ATTACHMENT",
  SET_EXIT_REQUEST_ATTACHMENTS:"SET_EXIT_REQUEST_ATTACHMENTS",
  UPDATE_EXIT_REQUEST_ATTACHMENTS:"UPDATE_EXIT_REQUEST_ATTACHMENTS"
};

export default types;
