import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getExitTypes, saveRequest } from "../../../store/exit/actions";

const UpdateExitDialog = (props) => {
  const {
    loading,
    updateMyRequestForm,
    setUpdateMyRequestForm,
    exitTypes,
    setSelectedRequest,
    selectedRequest,
  } = props;

  const [exitType, setExitType] = useState(null);

  const [updateMyExitForm, setUpdateMyExitForm] = useState({
    employeePositionId: "",
    exitTypeId: "",
    effectiveDate: null,
    comment: "",
  });
  const [errors, setErrors] = useState({
    employeeIdHasError: false,
    exitTypeHasError: false,
    effectiveDateHasError: false,
    commentHasError: false,
  });
  useEffect(() => {
    getExitTypes();
  }, []);
  useEffect(() => {
    if (updateMyRequestForm) {
      setUpdateMyExitForm({
        employeePositionId: selectedRequest.employeePositionId,
      });
    }
  }, [updateMyRequestForm, selectedRequest]);

  const onClose = () => {
    setUpdateMyRequestForm(false);
    setSelectedRequest(null);
  };

  const onSave = () => {};
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={updateMyRequestForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Update My Request on @
            {selectedRequest && selectedRequest.employeeNames}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: "90%" }}>
            <div
              className="elevated rounded p-5 mt-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              {!!exitTypes.length && (
                <div className="row mt-3 ">
                  <div className="col-12">
                    <Autocomplete
                      size="small"
                      id="ExitType"
                      className="mb-3"
                      defaultValue={null}
                      value={exitType}
                      options={exitTypes}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, exitType) => {
                        setExitType(exitType || null);
                        const exitTypeId = exitType ? exitType.id : "";
                        setUpdateMyRequestForm({
                          ...updateMyRequestForm,
                          exitTypeId,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, exitType) => (
                        <Box component="li" {...props}>
                          {exitType.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="exitTypes"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "ExitType", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Effective Date"
                  inputFormat="MM/dd/yyyy"
                  value={updateMyExitForm.effectiveDate}
                  onChange={(date) => {
                    const effectiveDate = date;
                    setUpdateMyExitForm({ ...updateMyExitForm, effectiveDate });
                    const effectiveDateHasError = false;
                    setErrors({ ...errors, effectiveDateHasError });
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth size="small" {...params} />
                  )}
                />

                {errors.effectiveDateHasError && (
                  <div className="text-danger mt-1">
                    Effective Date is required{" "}
                  </div>
                )}
              </LocalizationProvider>
              <TextField
                className="mt-4"
                fullWidth
                size="small"
                name="comment"
                required={true}
                multiline
                rows={3}
                label="Comments..."
                inputProps={{ maxLength: 459 }}
                onChange={(e) => {
                  const comment = e.target.value;
                  setUpdateMyExitForm({ ...updateMyExitForm, comment });
                }}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, exitTypes }) => {
  return {
    loading,
    exitTypes,
  };
};
export default connect(mapStateToProps, {})(UpdateExitDialog);
