import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const DisciplinaryProvideFeedbackForExplanation = (props) => {
  const {
    recipientName,
    recipientEmail,
    recipientPhone,
    faults,
    institutionName,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
    subTitle,
    providingExplanationLetterNo,
    providingExplanationLetterDate,
    isProvisionSuspensionLetterRequired = false,
    employeeExplanationLetterDate,
    provisionalSuspensionNo,
    provisionalSuspensionEffectDate,
    explanationFeedbackContentLetterInput = "",
    explanationFeedbackContentLetter = "",
    locationName=""
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    {locationName || 'Kigali'}, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    <span style={{ fontWeight: "bold" }}>{docLetterNo}</span>
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              C/O: {institutionName.toUpperCase()}”
            </p>
          </div>
          <div className="content2 mb-2">
            {/* <!-- Letter content goes here --> */}
            <p>{subTitle},</p>
            <p style={{ fontWeight: "bold" }}>
              Re: Feedback on your explanation
            </p>
            <p>
              Reference is made to Letter N°{" "}
              <span style={{ fontWeight: "bold" }}>
                {providingExplanationLetterNo}
              </span>{" "}
              of{" "}
              <span style={{ fontWeight: "bold" }}>
                {providingExplanationLetterDate}{" "}
              </span>{" "}
              requesting you to provide a written explanations on the
              disciplinary faults;
            </p>
            <p>
              Reference is made to the Letter dated on{" "}
              <span style={{ fontWeight: "bold" }}>
                {employeeExplanationLetterDate}
              </span>{" "}
              providing written explanation on the suspected disciplinary
              faults;
            </p>
            {isProvisionSuspensionLetterRequired && (
              <p>
                Reference is made to Letter N°.{provisionalSuspensionNo} of{" "}
                {provisionalSuspensionEffectDate} suspending you provisionally
                for administrative investigation (if any);
              </p>
            )}
            <p>
              Pursuant to the Presidential Order N° 021/01 of 24/02/2021
              determining professional ethics for public servants in its article
              36 paragraph 4;
            </p>
            <p>
              {enableToFillLetter ? (
                explanationFeedbackContentLetterInput
              ) : (
                <>{explanationFeedbackContentLetter}</>
              )}
            </p>
            <p>Sincerely,</p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData?.length && (
                <>
                  {ccData?.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.ccName || cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData?.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisciplinaryProvideFeedbackForExplanation;
