import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import SearchBox from "../../common/components/SearchBox";
import NoResults from "../../common/components/NoResults";
import axios from "axios";
import { showError } from "../../toastify";
const ShowApplicantCandidates = (props) => {
  const {
    user,
    showDialog,
    setShowDialog,
    application,
    setShowWrittenExamDetail,
  } = props;

  const [loading, setLoading] = useState(false);
  const [searchCandidate, setSearchCandidate] = useState("");

  const [candidateList, setCandidateList] = useState([]);

  // ### PAGINATION
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedCandidates, setPaginatedCandidates] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getAllCandidatesOnWritten();
  }, []);

  useEffect(() => {
    if (filteredData) {
      setPaginatedCandidates(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [filteredData, itemOffset, endOffset]);

  useEffect(() => {
    if (candidateList) {
      if (searchCandidate !== "") {
        const value = searchCandidate?.toLowerCase();
        setFilteredData(
          candidateList.filter(
            (item) =>
              (item.names || "").toLowerCase().includes(value) ||
              (item.isMe && value === "me")
          )
        );
      } else {
        setFilteredData(candidateList);
      }
    }
  }, [candidateList, searchCandidate]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      (filteredData ? filteredData.length : 1);
    setItemOffset(newOffset);
  };

  const getAllCandidatesOnWritten = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/recruitment/all-candidates-on-written/${application.advertisementId}`
      );

      setCandidateList(data);

      setLoading(false);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch);
  };


  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">
              <span
                onClick={() => setShowWrittenExamDetail(true)}
                className="btn-link text-underline cursor-pointer"
              >
                {/* <OpenInNewIcon /> */}
                Written Exam
              </span>{" "}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-2 d-flex flex-column flex-md-row justify-content-md-between ">
            <SearchBox
              placeholder="Search..."
              disabled={!candidateList || candidateList.length === 0}
              onSearch={setSearchCandidate}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="table-responsive">
            <table
              className={`table table-sm ${
                candidateList ? "table-striped" : ""
              }`}
            >
              <thead>
                <tr>
                  <th style={{ width: "14px", verticalAlign: "middle" }}>#</th>
                  <th>
                    {" "}
                    <span className="d-flex  flex-wrap align-items-center">
                      <span className="mr-2">
                        Candidates{" "}
                        {!!candidateList.length && (
                          <span className="text-primary">
                            ({candidateList.length})
                          </span>
                        )}
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-right"
                    style={{ verticalAlign: "middle" }}
                  >
                    Score
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && !candidateList.length ? (
                  Array.from(Array(10).keys()).map((item, index) => (
                    <tr align="left" key={index}>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                      <td>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={15}
                        />
                      </td>
                    </tr>
                  ))
                ) : paginatedCandidates.length ? (
                  paginatedCandidates.map((candidate, index) => (
                    <tr
                      key={getNo(candidate.id, candidateList, "id")}
                      className={`${
                        (candidate.score || 0) >= 50
                          ? "tr-bg-green"
                          : "tr-bg-red"
                      } ${candidate.isMe ? "tr-bordered-blue" : ""}`}
                    >
                      <td className="text-right">
                        {getNo(candidate.id, candidateList, "id") + 1}
                      </td>
                      <td className="text-left">
                        <span className="d-flex align-items-center">
                          {candidate.names}
                          {candidate.isMe && (
                            <span className="badge badge-primary ml-1">Me</span>
                          )}
                        </span>
                      </td>
                      <td className={`text-right`}>
                        <span className="d-flex flex-nowrap align-items-center justify-content-end">
                          {!application.oralExamId && (
                            <>
                              {candidate.offerStatusId && (
                                <span
                                  style={{ width: "68px" }}
                                  className={` mr-1 badge badge-${
                                    [2, 5].includes(candidate.offerStatusId)
                                      ? "success"
                                      : [3, 4].includes(candidate.offerStatusId)
                                      ? "danger"
                                      : candidate.offerStatusName ===
                                        "Offer expired"
                                      ? "warning"
                                      : "primary"
                                  }`}
                                >
                                  {candidate.offerStatusName}
                                </span>
                              )}
                              {!!candidate.waitingStatusId && (
                                <span
                                  style={{ width: "68px" }}
                                  className={`mr-1 badge badge-pill text-${
                                    candidate.waitingStatusId === 2
                                      ? "danger"
                                      : "primary"
                                  }`}
                                >
                                  {candidate.waitingStatusId === 1
                                    ? "On waiting"
                                    : candidate.waitingStatusId === 2
                                    ? "Waiting expired"
                                    : ""}
                                </span>
                              )}
                            </>
                          )}
                          {(
                            (candidate.score || 0) /
                            (!!application.oralExamId ? 2 : 1)
                          ).toFixed(2)}
                          /{!!application.oralExamId ? "50" : "%"}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <NoResults />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* display pagination */}
          </div>
        </DialogContent>
        <DialogActions>
          {candidateList.length > itemsPerPage && (
            <div
              className="justify-content-center col-12 mb-3"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, {})(ShowApplicantCandidates);
