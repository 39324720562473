import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputAdornment,
  Skeleton,
  Tabs,
  Tab,
  Divider,
  Button,
  Tooltip,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  // getPosition,
  updatePosition1,
  updatePosition2,
  // getSalaryStructure,
  getPositionQualifications,
  getPositionCertificates,
  getPositionCompetencies,
} from "../../../store/structure/actions";
// import {
//   getSourceOfFunds,
//   getPayrollGroups,
//   getCostCenters,
// } from "../../../store/payroll/actions";
import { showError } from "../../toastify";

import PositionQualifications from "./PositionQualifications";
import PositionCompetency from "./PositionCompetencies";
import PositionCertificates from "./PositionCertificates";
import AddPositionQualificationDialog from "./AddPositionQualificationDialog";
import { Editor, currencyFormat } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AddPositionCertificateDialog from "./AddPositionCertificateDialog";
import AddPositionCompetencyDialog from "./AddPositionCompetencyDialog";

import {
  getEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  getSupervisors,
  getDegrees,
} from "../../../store/structure/actions";

const PositionProfileDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    position,
    showUpdatePositionModal,
    setShowUpdatePositionModal,
    getPosition,
    sourceOfFunds,
    updatePosition1,
    updatePosition2,
    isEntitySectorLocked,

    payrollGroups,
    costCenters,
    supervisors,
    getPositionQualifications,
    getPositionCertificates,
    getPositionCompetencies,

    employeeGroups,
    hiringModes,
    levels,
    echelons,
    rraGrades,
    rraDepartments,

    getEmployeeGroups,
    getHiringModes,
    getLevels,
    getEchelons,
    degrees,
    getDegrees,
  } = props;

  const [value, setValue] = useState(0);
  const [edited, setEdited] = useState(false);

  const [employeeGroup, setEmployeeGroup] = useState(null);
  const [hiringMode, setHiringMode] = useState(null);
  const [level, setLevel] = useState(null);
  const [echelon, setEchelon] = useState(null);

  const [rraDepartment, setRRADepartment] = useState(null);
  const [rraGrade, setRRAGrade] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!position.isShared) {
      getPositionQualifications(position.id);
      getPositionCertificates(position.id);
      getPositionCompetencies(position.id);
    }

    if (!employeeGroups.length) getEmployeeGroups();
    if (!hiringModes.length) getHiringModes();
    if (!levels.length) getLevels();
    if (!echelons.length) getEchelons();

    if (!degrees.length) getDegrees();
  }, [position.id]);

  useEffect(() => {
    setRRAGrade(rraGrades.find(({ id }) => id === (position.rraGradeId || 16)));
    setRRADepartment(
      rraDepartments.find(({ id }) => id === position.rraDepartmentId) || null
    );
  }, [position.rraGradeId]);

  const [formData, setFormData] = useState({
    description: position.description,
    reportsTo: position.reportsTo,
  });

  const [supervisor, setSupervisor] = useState({
    id: null,
    name: "None",
    unitName: "",
  });

  const [errors, setErrors] = useState({
    supervisorHasError: false,
    descriptionHasError: false,
  });

  const onClose = () => {
    setShowUpdatePositionModal(false);
    setFormData({
      description: "",
      supervisor: "",
    });
  };

  useEffect(() => {
    if (!!position.reportsTo) {
      setSupervisor(
        supervisors.find(({ id }) => id === position.reportsTo) || null
      );
    }
  }, [position]);

  const formValidator = () => {
    const error = {
      supervisorHasError: false,
      descriptionHasError: false,
      hasError: false,
    };

    // if (!formData.description) {
    //   error.descriptionHasError = true;
    //   error.hasError = true;
    // }

    if (!supervisor) {
      error.supervisorHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const [showPositionForm, setShowPositionForm] = useState(false);
  const [showCertificateForm, setShowCertificateForm] = useState(false);
  const [showCompetencyForm, setShowCompetencyForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedQualification, setSelectedQualification] = useState(null);

  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const [updateData, setUpdateData] = useState({
    id: position?.id,
    indexValue: position?.indexValue || 0,
    positionName: position?.name || "",
    name: position?.name || "",
    numberOfPosts: position?.numberOfPosts || 0,
    isOnStructure: position?.isOnStructure || false,
  });

  useEffect(() => {
    if (showUpdateDialog) {
      setEmployeeGroup(
        employeeGroups.find(({ id }) => id === position?.employeeGroupId)
      );
      setHiringMode(
        hiringModes.find(({ id }) => id === position?.hiringModeId)
      );
      setLevel(levels.find(({ id }) => id === position?.levelId));
      setEchelon(echelons.find(({ id }) => id === position?.scaleId));

      setUpdateData({
        id: position?.id,
        indexValue: position?.indexValue || 0,
        isOnStructure: position?.isOnStructure || false,
        name: position?.name || "",
        numberOfPosts: position?.numberOfPosts || 0,
      });
    }
  }, [showUpdateDialog]);

  const onSave1 = () => {
    if (!formValidator()) {
      updatePosition1(
        position,
        {
          description: formData.description,
          reportsTo: formData.reportsTo,
          changeSupervisor: true,
          rraGradeId: rraGrade?.id || null,
          rraDepartmentId: rraDepartment?.id || null,
        },
        setEdited
      );
    }
  };

  const onSave2 = () => {
    updatePosition2(
      position.id,
      {
        employeeGroupId: employeeGroup.id,
        hiringModeId: hiringMode.id,
        levelId: level.id,
        scaleId: echelon.id,
        indexValue: updateData.indexValue,
        isOnStructure: updateData.isOnStructure,
        name: updateData.name,
        numberOfPosts: updateData.numberOfPosts,
      },
      setShowUpdateDialog
    );
  };

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Dialog
        onClose={onClose}
        open={showUpdatePositionModal}
        fullWidth
        maxWidth={"lg"}
      >
        {position && (
          <DialogTitle className="text-primary">
            <Typography
              variant="overline"
              className="text-truncate"
              display="block"
              style={{ maxWidth: "70%" }}
            >
              <div className="headerorg mb-0">
                {position && (
                  <div className="header_cont">
                    <span className="ml-0 text-dark">Job profiles</span> |{" "}
                    <span className="ml-0">{position.name}</span>{" "}
                    {(position.isShared || position.isSharing) && (
                      <span className="badge badge-pill badge-light">
                        <span
                          style={{ fontSize: "20px" }}
                          className={`ml-1 ${
                            position.isShared ? "shared" : "sharing"
                          }`}
                        >
                          <i className="fas fa-share"></i>
                        </span>
                        <small>
                          {position.isShared ? "Shared" : "Sharing"}
                          {!!position.sharedByName && (
                            <span className="text-primary">
                              {" "}
                              by {position.sharedByName}
                            </span>
                          )}
                        </small>
                      </span>
                    )}
                    {!position.isOnStructure && !position.isShared && (
                      <span
                        className="badge badge-pill badge-light ml-0"
                        style={{
                          fontweight: 100,
                          color: "red",
                          border: "1px solid red",
                        }}
                      >
                        Not on structure
                      </span>
                    )}
                  </div>
                )}
              </div>
            </Typography>

            {!position.isShared && (
              <Tooltip title="Download">
                <span>
                  <IconButton
                    disabled
                    className="p-0"
                    color="primary"
                    sx={{
                      position: "absolute",
                      right: 54,
                      top: 12,
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ fontSize: "28px" }}
                    >
                      cloud_download
                    </span>
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}

        <DialogContent className="py-0">
          <div className="px-3">
            {!position && (
              <div className="container mt-2" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            )}
            {position && (
              <React.Fragment>
                <div className="row">
                  {!position.isShared && (
                    <div className="col-sm-12 mb-3 d-flex justify-content-center">
                      <Tabs
                        // orientation="vertical"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={value}
                        onChange={handleChange}
                        // sx={{
                        //   borderRight: 1,
                        //   borderColor: "divider",
                        //   textAlign: "right",
                        // }}
                      >
                        <Tab label="Basic profile" {...a11yProps(0)} />

                        <Tab label="Qualifications" {...a11yProps(1)} />

                        {/* <Tab label="Experiences" {...a11yProps(2)} /> */}
                        <Tab label="Certificates" {...a11yProps(2)} />

                        <Tab label="Competencies" {...a11yProps(3)} />

                        {/* <Tab label="Allowances" {...a11yProps(5)} />

                      <Tab label="Deductions" {...a11yProps(6)} /> */}
                      </Tabs>
                    </div>
                  )}

                  <div
                    className="col-sm-12 elevated rounded"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #17a2b8",
                      minHeight: "67vh",
                    }}
                  >
                    <TabPanel value={value} index={0}>
                      <React.Fragment>
                        <Dialog
                          maxWidth="sm"
                          onClose={() => {
                            setShowUpdateDialog(false);
                          }}
                          open={showUpdateDialog}
                        >
                          <DialogTitle className={`text-primary`}>
                            <Typography
                              variant="overline"
                              className="text-truncate"
                              display="block"
                              style={{
                                maxWidth: "80%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span className="ml-0 text-dark">
                                Update Position |{" "}
                              </span>{" "}
                              <span className="ml-0"> {position.name}</span>{" "}
                            </Typography>

                            <IconButton
                              aria-label="close"
                              onClick={() => {
                                setShowUpdateDialog(false);
                              }}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <span className="material-icons">close</span>
                            </IconButton>
                          </DialogTitle>
                          <DialogContent className="pb-0 text-center">
                            <div
                              className=" rounded p-0 my-3 p-3"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #17a2b8",
                              }}
                            >
                              <div className="row mt-3 ">
                                <div className="mb-3 col-9">
                                  <TextField
                                    // style={{ backgroundColor: "#eee" }}
                                    size="small"
                                    fullWidth
                                    name="positionName"
                                    label="Position Name"
                                    variant="outlined"
                                    placeholder="Position Name"
                                    value={updateData.name}
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}

                                    onChange={(e) => {
                                      setUpdateData({
                                        ...updateData,
                                        name: e.target.value || "",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="mb-3 col-3">
                                  <TextField
                                    // style={{ backgroundColor: "#eee" }}
                                    size="small"
                                    fullWidth
                                    name="numberOfPosts"
                                    label="Posts"
                                    variant="outlined"
                                    placeholder="Posts"
                                    value={updateData.numberOfPosts}
                                    // InputProps={{
                                    //   readOnly: true,
                                    // }}

                                    onBlur={() => {
                                      if (updateData.numberOfPosts === "")
                                        setUpdateData({
                                          ...updateData,
                                          numberOfPosts: 0,
                                        });
                                    }}
                                    onKeyDown={() => {
                                      if (+updateData.numberOfPosts === 0)
                                        setUpdateData({
                                          ...updateData,
                                          numberOfPosts: "",
                                        });
                                    }}
                                    onChange={(e) => {
                                      setUpdateData({
                                        ...updateData,
                                        numberOfPosts: +e.target.value || 0,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="mb-3 col-6">
                                  <Autocomplete
                                    size="small"
                                    id="employeeGroup"
                                    defaultValue={null}
                                    value={employeeGroup}
                                    options={employeeGroups}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, employeeGroup) => {
                                      setEmployeeGroup(employeeGroup || null);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, employeeGroup) => (
                                      <Box component="li" {...props}>
                                        {employeeGroup.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Employment Group"
                                        placeholder="Employment Group"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "employeeGroup", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="mb-3 col-6">
                                  <Autocomplete
                                    size="small"
                                    id="hiringMode"
                                    defaultValue={null}
                                    value={hiringMode}
                                    options={hiringModes}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, hiringMode) => {
                                      setHiringMode(hiringMode || null);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, hiringMode) => (
                                      <Box component="li" {...props}>
                                        {hiringMode.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Hiring Mode"
                                        placeholder="Hiring Mode"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "hiringMode", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="mb-3 col-4">
                                  <Autocomplete
                                    size="small"
                                    id="level"
                                    defaultValue={null}
                                    value={level}
                                    options={levels}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, level) => {
                                      setLevel(level || null);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, level) => (
                                      <Box component="li" {...props}>
                                        {level.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Level"
                                        placeholder="Level"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "level", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div className="mb-3 col-4">
                                  <Autocomplete
                                    size="small"
                                    id="echelon"
                                    defaultValue={null}
                                    value={echelon}
                                    options={echelons}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, echelon) => {
                                      setEchelon(echelon || null);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, echelon) => (
                                      <Box component="li" {...props}>
                                        {echelon.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="echelon"
                                        placeholder="echelon"
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "echelon", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                </div>

                                <div className="mb-3 col-4">
                                  <TextField
                                    size="small"
                                    fullWidth
                                    name="indexValue"
                                    label="Index Value"
                                    variant="outlined"
                                    placeholder="Index Value"
                                    value={updateData.indexValue}
                                    onBlur={() => {
                                      if (updateData.indexValue === "")
                                        setUpdateData({
                                          ...updateData,
                                          indexValue: 0,
                                        });
                                    }}
                                    onKeyDown={() => {
                                      if (+updateData.indexValue === 0)
                                        setUpdateData({
                                          ...updateData,
                                          indexValue: "",
                                        });
                                    }}
                                    onChange={(e) => {
                                      setUpdateData({
                                        ...updateData,
                                        indexValue: +e.target.value || 0,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-12  d-flex justify-content-end">
                                  <FormControl className="  d-block">
                                    <FormLabel className="text-primary text-uppercase">
                                      Is it on structure?
                                    </FormLabel>
                                    <RadioGroup
                                      className="mt-0 d-flex justify-content-end"
                                      row
                                      name="isOnStructure"
                                      value={updateData.isOnStructure}
                                      onChange={(event) => {
                                        setUpdateData({
                                          ...updateData,
                                          isOnStructure:
                                            event.target.value === "true"
                                              ? true
                                              : false,
                                        });
                                      }}
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={
                                          <Radio disableRipple size="small" />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={
                                          <Radio disableRipple size="small" />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </DialogContent>
                          <DialogActions className="d-flex justify-content-center pb-3">
                            <button
                              onClick={onSave2}
                              type="button"
                              disabled={
                                loading ||
                                !employeeGroup ||
                                !hiringMode ||
                                !level ||
                                !echelon ||
                                (!+updateData.indexValue &&
                                  (employeeGroup?.id <= 2 ||
                                    employeeGroup?.id === 4))
                              }
                              className="btn btn-primary text-uppercase  mr-3"
                            >
                              {loading ? "Wait..." : "Update"}
                            </button>
                          </DialogActions>
                        </Dialog>

                        {(position.employeeGroupId === 1 ||
                          position.employeeGroupId === 2 ||
                          position.employeeGroupId === 4) && (
                          <div
                            // style={{ backgroundColor: "#cdeef8" }}
                            className="d-flex justify-content-center  align-items-center mt-3"
                          >
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              className="bg-secondary text-light"
                              size="small"
                              label={
                                <>
                                  Level:{" "}
                                  {position.levelName +
                                    "." +
                                    position.scaleName}
                                </>
                              }
                            />
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>

                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={<>Index: {position.index || 0}</>}
                            />
                            <span className="px-2">{"X"}</span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={
                                <>IndexValue: {position.indexValue || 0}</>
                              }
                            />
                            {/* <span className="px-2">{"="}</span> */}
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              color="primary"
                              size="small"
                              label={
                                <>
                                  BasePay:{" "}
                                  {currencyFormat(
                                    +position.indexValue * +position.index
                                  )}
                                  RWF
                                </>
                              }
                            />
                          </div>
                        )}

                        <div className="row">
                          {position && (
                            <div className="col-12 mt-3">
                              <table className="table tablejc table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th>Job Classification</th>
                                    <th>Career field</th>
                                    <th>Employee Group</th>
                                    <th>Hiring Mode</th>
                                    <th>Index Value</th>
                                    <th>Posts</th>
                                    <th>Level</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {position.jobClassificationName || "N/A"}
                                    </td>

                                    <td>{position.jobFieldName || "N/A"}</td>

                                    <td>
                                      {position.employeeGroupName || "N/A"}
                                    </td>
                                    <td>{position.hiringModeName || "N/A"}</td>

                                    <td>{position.indexValue || "N/A"}</td>

                                    <td>{position.numberOfPosts || "N/A"}</td>
                                    <td>
                                      {position.levelName}.{position.scaleName}
                                    </td>
                                    <td className="text-center">
                                      <span className="d-inline-flex align-items-center">
                                        <span
                                          style={{
                                            backgroundColor: "#fff",
                                            border: "1px solid #c8c6c6",
                                          }}
                                          className="p-1 rounded"
                                        >
                                          {hasPermissions([
                                            "IS_STRUCTURE_OVERSIGHT",
                                            "IS_HR",
                                          ]) && (
                                            <IconButton
                                              onClick={() =>
                                                setShowUpdateDialog(true)
                                              }
                                              size="small"
                                              edge="end"
                                              color="primary"
                                            >
                                              <span className="material-icons">
                                                edit
                                              </span>
                                            </IconButton>
                                          )}
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div
                            className={`col-${
                              user?.selectedEntitySector?.id ===
                              "1203000001-090000000000000001-11"
                                ? "6"
                                : "12"
                            } mt-3`}
                          >
                            <Autocomplete
                              readOnly={
                                !hasPermissions([
                                  "IS_HR",
                                  "IS_STRUCTURE_OVERSIGHT",
                                ])
                              }
                              size="small"
                              id="supervisor"
                              defaultValue={null}
                              value={supervisor}
                              options={[
                                ...supervisors,
                                {
                                  id: null,
                                  name: "None",
                                  unitName: "",
                                },
                              ]}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, supervisor) => {
                                setSupervisor(supervisor || null);

                                if (!!supervisor)
                                  setFormData({
                                    ...formData,
                                    reportsTo: supervisor.id,
                                  });

                                setEdited(true);

                                setErrors({
                                  ...errors,
                                  supervisorHasError: false,
                                });
                              }}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, supervisor) => (
                                <>
                                  <Box component="li" {...props}>
                                    {supervisor.name}
                                    <small
                                      style={{ backgroundColor: "#eee" }}
                                      className="px-1 ml-1 rounded"
                                    >
                                      <em>
                                        <span className="badge badge-light text-info">
                                          {supervisor.unitName}
                                        </span>
                                      </em>
                                    </small>
                                  </Box>
                                  <Divider />
                                </>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Supervisor"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "supervisor", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            {errors.supervisorHasError && (
                              <small className="text-danger mt-2">
                                Supervisor is required{" "}
                              </small>
                            )}
                          </div>

                          {user?.selectedEntitySector?.id ===
                            "1203000001-090000000000000001-11" && (
                            <div className="col-6 mt-3 row">
                              <div className="col-8">
                                <Autocomplete
                                  readOnly={!hasPermissions(["IS_HR"])}
                                  size="small"
                                  id="rraDepartment"
                                  defaultValue={null}
                                  value={rraDepartment}
                                  options={rraDepartments}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  onChange={(event, rraDepartment) => {
                                    setRRADepartment(rraDepartment || null);

                                    setEdited(true);
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, rraDepartment) => (
                                    <>
                                      <Box component="li" {...props}>
                                        {rraDepartment.name}
                                      </Box>
                                      <Divider />
                                    </>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Department"
                                      placeholder="Department"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "rraDepartment", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </div>

                              <span className="col-4">
                                <Autocomplete
                                  fullWidth
                                  size="small"
                                  id="rraGrade"
                                  defaultValue={null}
                                  value={rraGrade}
                                  options={rraGrades}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  onChange={(event, rraGrade) => {
                                    setRRAGrade(rraGrade || null);
                                    setEdited(true);
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderOption={(props, rraGrade) => (
                                    <Box component="li" {...props}>
                                      {rraGrade.name}
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Grade"
                                      placeholder="Grade"
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "rraGrade", // disable autocomplete and autofill
                                      }}
                                    />
                                  )}
                                />
                              </span>
                            </div>
                          )}

                          <div className="col-12 mt-3">
                            <TextField
                              fullWidth
                              size="small"
                              rows={8}
                              name="reason"
                              autoFocus
                              multiline
                              label="Job Responsibilities"
                              variant="outlined"
                              placeholder="Job Responsibilities"
                              value={formData.description || ""}
                              InputProps={{
                                readOnly: !hasPermissions(["IS_HR"]),
                              }}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  description: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  descriptionHasError: false,
                                });

                                setEdited(true);
                              }}
                            />

                            {errors.descriptionHasError && (
                              <small className="text-danger mt-2">
                                Job responsibilities is required{" "}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-2 pb-2">
                          {hasPermissions(["IS_HR", "IS_TEST_USER"]) && (
                            <Button
                              variant="contained"
                              type="button"
                              className="px-5"
                              disabled={loading || !edited}
                              onClick={onSave1}
                            >
                              {loading && !showUpdateDialog
                                ? "Wait..."
                                : "Save"}
                            </Button>
                          )}
                        </div>
                      </React.Fragment>
                    </TabPanel>
                    {(!position.isShared ||
                      hasPermissions(["IS_STRUCTURE_OVERSIGHT"])) && (
                      <TabPanel value={value} index={1}>
                        <React.Fragment>
                          <div className="px-3 text-right">
                            {hasPermissions([
                              "IS_HR",
                              "IS_STRUCTURE_OVERSIGHT",
                              "IS_TEST_USER",
                            ]) && (
                              <Button
                                disabled={loading}
                                onClick={() => {
                                  setShowPositionForm(true);
                                }}
                                className="mt-1"
                                style={{
                                  // float: "right",
                                  borderRadius: "8px",
                                  height: "35px",
                                  border: `1px solid rgb(7, 142, 206)`,
                                  color: "#fff",
                                  alignItems: "center",
                                  fontSize: ".75rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textTransform: "initial",
                                  backgroundColor: "rgb(7, 142, 206)",
                                  "&:hover": {
                                    backgroundColor: "#f0f2f5",
                                  },
                                }}
                                size="md"
                              >
                                <AddIcon />
                              </Button>
                            )}
                            <PositionQualifications positionId={position.id} />
                            {showPositionForm && (
                              <AddPositionQualificationDialog
                                positionId={position.id}
                                selectedQualification={selectedQualification}
                                setSelectedQualification={
                                  setSelectedQualification
                                }
                                showPositionForm={showPositionForm}
                                setShowPositionForm={setShowPositionForm}
                                isEditing={isEditing}
                                setIsEditing={setIsEditing}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      </TabPanel>
                    )}
                    {(!position.isShared ||
                      hasPermissions(["IS_STRUCTURE_OVERSIGHT"])) && (
                      <TabPanel value={value} index={2}>
                        <React.Fragment>
                          <div className="px-3 text-right">
                            {hasPermissions([
                              "IS_HR",
                              "IS_STRUCTURE_OVERSIGHT",
                              "IS_TEST_USER",
                            ]) && (
                              <Button
                                disabled={loading}
                                onClick={() => {
                                  setShowCertificateForm(true);
                                }}
                                className="mt-1"
                                style={{
                                  // float: "right",
                                  borderRadius: "8px",
                                  height: "35px",
                                  border: `1px solid rgb(7, 142, 206)`,
                                  color: "#fff",
                                  alignItems: "center",
                                  fontSize: ".75rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textTransform: "initial",
                                  backgroundColor: "rgb(7, 142, 206)",
                                  "&:hover": {
                                    backgroundColor: "#f0f2f5",
                                  },
                                }}
                                size="md"
                              >
                                <AddIcon />
                              </Button>
                            )}
                            <PositionCertificates positionId={position.id} />
                            {showCertificateForm && (
                              <AddPositionCertificateDialog
                                positionId={position.id}
                                showCertificateForm={showCertificateForm}
                                setShowCertificateForm={setShowCertificateForm}
                              />
                            )}
                          </div>
                          <div className="pt-3"></div>
                        </React.Fragment>
                      </TabPanel>
                    )}
                    {(!position.isShared ||
                      hasPermissions(["IS_STRUCTURE_OVERSIGHT"])) && (
                      <TabPanel value={value} index={3}>
                        <React.Fragment>
                          <div className="px-3 text-right">
                            {hasPermissions([
                              "IS_HR",
                              "IS_STRUCTURE_OVERSIGHT",
                              "IS_TEST_USER",
                            ]) && (
                              <Button
                                disabled={loading}
                                onClick={() => {
                                  setShowCompetencyForm(true);
                                }}
                                className="mt-1"
                                style={{
                                  // float: "right",
                                  borderRadius: "8px",
                                  height: "35px",
                                  border: `1px solid rgb(7, 142, 206)`,
                                  color: "#fff",
                                  alignItems: "center",
                                  fontSize: ".75rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textTransform: "initial",
                                  backgroundColor: "rgb(7, 142, 206)",
                                  "&:hover": {
                                    backgroundColor: "#f0f2f5",
                                  },
                                }}
                                size="md"
                              >
                                <AddIcon />
                              </Button>
                            )}
                            <PositionCompetency positionId={position.id} />
                            {showCompetencyForm && (
                              <AddPositionCompetencyDialog
                                positionId={position.id}
                                showCompetencyForm={showCompetencyForm}
                                setShowCompetencyForm={setShowCompetencyForm}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      </TabPanel>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  supervisors,
  employeeGroups,
  hiringModes,
  levels,
  echelons,
  rraGrades,
  rraDepartments,
  degrees,
}) => {
  return {
    loading,
    onWait,
    user,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    supervisors,
    employeeGroups,
    hiringModes,
    levels,
    echelons,
    rraGrades,
    rraDepartments,
    degrees,
  };
};
export default connect(mapStateToProps, {
  updatePosition1,
  updatePosition2,
  getPositionQualifications,
  getPositionCertificates,
  getPositionCompetencies,
  getEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  getSupervisors,
  getDegrees,
})(PositionProfileDialog);
