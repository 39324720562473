import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { showError, showSuccess } from "../../toastify";

import { doSignTraining } from "../../../store/training/actions";
import axios from "axios";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { TextField } from "@mui/material";
import EditUserSignatureDialog from "./EditUserSignatureDialog";

const SignTrainingByMinistryDialog = (props) => {
  const {
    user,
    loading,
    request,
    level,
    setShowTrainingRequestDetails,
    // setSelectedTraining,
    doSignTraining,

    userSignature,
    setUserSignature,
    onClose,
  } = props;

 
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    referenceNo: request.referenceNo,
    password: "",
    level: level,
    userId:user.id
  });

  const [errors, setErrors] = useState({
    passwordHasError: false,
  });

  const [showConfirmSignatureMessage, setShowConfirmSignatureMessage] =
    useState(false);
  const [showSignatureBox, setShowSignatureBox] = useState(true);
  const formValidator = () => {
    const error = {
      passwordHasError: false,
    };

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSignTraining = () => {
    if (!formValidator()) {
      doSignTraining(formData, setShowTrainingRequestDetails, onClose);
    }
  };

  const doConfirmSignature = async () => {
    try {
      dispatch({
        type: "START_WAIT",
      });
      dispatch({
        type: "START_LOADING",
      });

      const { data } = await axios.post(
        "/api/auth/confirm-user-signatures",
        {}
      );

      showSuccess("Signature confirmed successfully");

      setUserSignature(data);
      setShowConfirmSignatureMessage(false);

      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    } catch (error) {
      showError(error.message);
      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    }
  };


  const [showEditUserSignatureDialog, setShowEditUserSignatureDialog] =
    useState(false);

  return (
    <>
      <form>
        <div
         
        >
          <div className="row justify-content-center">
            <div className="col-12 px-0 text-center">
              <div className="text-center mb-2">
                <span className="text-uppercase text-primary">Your Signature</span>
              </div>
              {!userSignature && (
                <div className="text-center mb-2">
                  <span className="text-danger">
                    Your signature was not set
                  </span>
                </div>
              )}
              {!!userSignature && (
                <img
                  style={{ width: "192px", border: "solid 1px #dae1e7" }}
                  src={"data:image/jpg;base64," + userSignature.signature}
                  className="rounded py-1"
                />
              )}

              <div className="text-center">
                {(!userSignature || (!!userSignature && !userSignature.isVerified)) && (
                  <div className="">
                    <button
                      onClick={() => setShowEditUserSignatureDialog(true)}
                      type="button"
                      className="btn btn-sm btn-link"
                    >
                      {!!userSignature && !!userSignature.signature ? "Edit" : "Set"} your
                      signature
                    </button>
                  </div>
                )}
                {!!userSignature && userSignature.isVerified && (
                  <div
                    className="d-inline-flex align-items-center text-success"
                    style={{ fontSize: "10px" }}
                  >
                    <span
                      className="material-icons text-success"
                      style={{ fontSize: "16px" }}
                    >
                      verified
                    </span>{" "}
                    Confirmed
                  </div>
                )}
              </div>
            </div>
            {!!userSignature && !!userSignature.signature && userSignature.isVerified && (
              <div className="col-12 px-5 mt-2">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  autoFocus
                  name="password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value || "",
                    });
                    setErrors({ ...errors, passwordHasError: false });
                  }}
                />

                {errors.passwordHasError && (
                  <small className="text-danger">RISA Digital signature password is required </small>
                )}
              </div>
            )}

            {!!userSignature && !!userSignature.signature && userSignature.isVerified && (
              <div className="col-12 d-flex justify-content-center pt-3">
                <button
                  onClick={onSignTraining}
                  type="button"
                  className="btn btn-primary text-uppercase"
                  disabled={loading}
                >
                  {loading ? "Signing..." : "Sign"}
                </button>
              </div>
            )}

            {!!userSignature && !!userSignature.signature && !userSignature.isVerified && (
              <div className="col-12 pb-3 text-center">
                <span className="d-block mt-2 mt-n2">or</span>
                <span className="d-block">
                  <button
                    onClick={() => setShowConfirmSignatureMessage(true)}
                    type="button"
                    className="btn btn-success btn-sm text-uppercase"
                    disabled={loading}
                  >
                    Confirm your Signature
                  </button>
                </span>
              </div>
            )}
          </div>
        </div>
      </form>

      <EditUserSignatureDialog
        showEditUserSignatureDialog={showEditUserSignatureDialog}
        setShowEditUserSignatureDialog={setShowEditUserSignatureDialog}
        setUserSignature={setUserSignature}
      />

      {showConfirmSignatureMessage && (
        <ConfirmationDialog
          confirmationDialog={showConfirmSignatureMessage}
          message={`Are you sure you want to confirm your signature?`}
          setConfirmationDialog={setShowConfirmSignatureMessage}
          onYes={() => {
            doConfirmSignature();
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  doSignTraining,
})(SignTrainingByMinistryDialog);
