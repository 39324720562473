import Appeals from "./Appeals";

const InProgressAppeals = (props) => {
const {}=props;
  return (
    <>
     <Appeals appealCategory="InProgressAppeals" />
    </>
  );
};

export default InProgressAppeals;
