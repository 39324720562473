import Appeals from "./Appeals";

const OverdueAppeals = (props) => {
  const { selectedAdvertisement,onClose,hrCanViewAppealedOnSecondLevel } = props;
  return (
    <>
      <Appeals
      onClose={onClose}
        appealCategory="OverdueAppeals"
        selectedAdvertisement={selectedAdvertisement}
        hrCanViewAppealedOnSecondLevel={hrCanViewAppealedOnSecondLevel}
      />
    </>
  );
};

export default OverdueAppeals;
