import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { connect } from "react-redux";
import { Toolbar, Tabs, Tab, Box } from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import UnitLeavePlan from "./UnitLeavePlan";
import AnnualLeaveRequest from "./AnnualLeaveRequest";
import InstitutionLPlan from "./InstitutionLPlan";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const AnnualLeave = ({ user }) => {
  const { hasPermissions } = useRequiredAuth();
  const isCBMOrTechnicalHead =
    (user.selectedEntitySector.id === "1700000000-090000000000000000-11"
      ? hasPermissions(["IS_HEAD_OF_INSTITUTION"])
      : hasPermissions(["IS_CBM", "IS_TECHNICAL_HEAD"])) &&
    !hasPermissions(["IS_ADMIN"]);
  const isHeadOfUnit = hasPermissions(["IS_HEAD_OF_UNIT"]);
  const isHR =
    hasPermissions(["IS_HR", "IS_CBM"]) && !hasPermissions(["IS_ADMIN"]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100 mb-0 pb-0"
          >
            <Toolbar className="mb-0 pb-0">
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                className="mb-0 pb-0"
                value={value}
                onChange={handleChange}
              >
                {isCBMOrTechnicalHead && (
                  <Tab label="Institution Leave Plan" {...a11yProps(0)} />
                )}
                {isHR && (
                  <Tab
                    label="Monitor Leave Plan"
                    {...a11yProps(isCBMOrTechnicalHead ? 1 : 0)}
                  />
                )}

                {isHeadOfUnit && (
                  <Tab
                    label="Unit Leave Plan"
                    {...a11yProps(
                      isCBMOrTechnicalHead && isHR
                        ? 2
                        : isCBMOrTechnicalHead || isHR
                        ? 1
                        : 0
                    )}
                  />
                )}
                {isHeadOfUnit && (
                  <Tab
                    label="Unit Leave Request"
                    {...a11yProps(
                      isCBMOrTechnicalHead && isHR
                        ? 3
                        : isCBMOrTechnicalHead || isHR
                        ? 2
                        : 1
                    )}
                  />
                )}
                {/* {isCBMOrTechnicalHead && (
                  <Tab label="Postpone Leave Plan" {...a11yProps(4)} />
                )} */}
              </Tabs>

              {/* <Box sx={{ flexGrow: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span className="lead text-uppercase">Unit Requests</span>
              </Typography> */}
            </Toolbar>
          </AppBar>

          <div>
            {isCBMOrTechnicalHead && (
              <TabPanel value={value} index={0}>
                <React.Fragment>
                  <InstitutionLPlan />
                </React.Fragment>
              </TabPanel>
            )}
            {isHR && (
              <TabPanel value={value} index={isCBMOrTechnicalHead ? 1 : 0}>
                <React.Fragment>
                  <UnitLeavePlan isForMonitoring={true} />
                </React.Fragment>
              </TabPanel>
            )}
            {isHeadOfUnit && (
              <TabPanel
                value={value}
                index={
                  isCBMOrTechnicalHead && isHR
                    ? 2
                    : isCBMOrTechnicalHead || isHR
                    ? 1
                    : 0
                }
              >
                <React.Fragment>
                  <UnitLeavePlan />
                </React.Fragment>
              </TabPanel>
            )}
            {isHeadOfUnit && (
              <TabPanel
                value={value}
                index={
                  isCBMOrTechnicalHead && isHR
                    ? 3
                    : isCBMOrTechnicalHead || isHR
                    ? 2
                    : 1
                }
              >
                <React.Fragment>
                  <AnnualLeaveRequest />
                </React.Fragment>
              </TabPanel>
            )}

            {/* {isCBMOrTechnicalHead && (
              <TabPanel value={value} index={4}>
                <React.Fragment>
                  <InstitutionLPlan />
                </React.Fragment>
              </TabPanel>
            )} */}
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(AnnualLeave);
