import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { approveTrainingRequest } from "../../../store/training/actions";
import { showError } from "../../../app/toastify";
import { Editor, textCapitalizing } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import AuthorizationByMinistry from "./AuthorizationByMinistry";
import AuthorizationPermit from "./AuthorizationPermit";
import ErrorMessage from "../../common/components/ErrorMessage";
const ApproveTrainingRequestDialog = (props) => {
  const {
    loading,
    level,
    showApprovalModal,
    setShowApprovalModal,
    setShowTrainingRequestDetails,
    request,
    approveTrainingRequest,
    status,
  } = props;

  const [formData, setFormData] = useState({
    referenceNo: request.referenceNo,
    level: level,
    comments: ``,
  });
  const [errors, setErrors] = useState({
    commentHasError: false,
  });

  const formValidator = () => {
    const error = {
      commentHasError: false,
      hasError: false,
    };

    if (!formData.comments) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      approveTrainingRequest(formData, setShowTrainingRequestDetails, onClose);
    }
  };

  const onClose = () => {
    setFormData({
      referenceNo: "",
      level: "",
      comments: ``,
    });
    setShowApprovalModal(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApprovalModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            REQUEST {level.toUpperCase()} APPROVAL
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "100vh" }}>
          <div className="col-12">
            
              <AuthorizationPermit
                headOfInstitution={{
                  firstName: request?.mifotraApproverFirstName,
                  lastName: request?.mifotraApproverLastName,
                  title: request?.mifotraApproverTitle,

                  institutionName:
                  request?.mifotraApproverInstitutionName || "",
                  positionName:request?.mifotraApproverPositionName || "",
                  canAddInstitutionName:  request?. mifotraApproverCanAddInstitutionName || "",
                }}
                request={request}
                coveredRelatedCostsDetails={request?.coveredRelatedCostsDetails}
                signContent={
                  <table>
                    <tr>
                      <td
                        style={{
                          width: "20%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >
                        <span className="d-block text-danger">Not signed</span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      ></td>
                    </tr>
                  </table>
                }
              />
            
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center">
          <div
            className="p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12 mt-0">
                <ReactQuill
                  theme="snow"
                  placeholder="Type your comments..."
                  value={formData.comments || ""}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  onChange={(value) => {
                    const comments = value;
                    setFormData({
                      ...formData,
                      comments,
                    });
                    const commentHasError = false;
                    setErrors({ ...errors, commentHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.commentHasError}
                  message="A comment is needed."
                />
              </div>
              <div className="col-12 mt-1">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase  px-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Wait..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </DialogActions>


      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  approveTrainingRequest,
})(ApproveTrainingRequestDialog);
