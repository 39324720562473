import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";

import {
  CardContent,
  Button,
  IconButton,
  Typography,
  Box,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Autocomplete,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  getTMISVacants,
  downloadTeacherVacants,
  executePlacementAlgorithm,
  loadTMISVacants,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PercentIcon from "@mui/icons-material/Percent";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ApplicationCv from "../../common/components/ApplicationCv";
import BadgeIcon from "@mui/icons-material/Badge";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import WidgetViewContentDialog from "../../common/components/WidgetViewContentDialog";
import moment from "moment";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import EmployCandidateDialog from "../components/EmployCandidateDialog";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UserResume from "../../profile/UserResume";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import NewRecruitmentRequestDialog from "../components/NewRecruitmentRequestDialog";
import ApproveRecruitmentRequestDialog from "../components/ApproveRecruitmentRequestDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";

const TeachersVacantPostsDialog = (props) => {
  const {
    loading,
    tmisVacants,
    getTMISVacants,
    selectedAdvertisement,
    requestJobOffer,
    skipCandidate,
    cancelJobOffer,
    employCandidate,
    userType,
    downloadTeacherVacants,
    getUserDetails,
    getRecruitmentRequest,
    executePlacementAlgorithm,
    publishTeacherPlacements,
    env,
    showDialog,
    setShowDialog,
    loadTMISVacants,
  } = props;

  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(15);
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    getTMISVacants(
      selectedAdvertisement.positionId,
      query,
      setTotalRequests,
      setIsLoading
    );
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getTMISVacants(
      selectedAdvertisement.positionId,
      query,
      setTotalRequests,
      setIsLoading
    );
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getTMISVacants(
      selectedAdvertisement.positionId,
      query,
      setTotalRequests,
      setIsLoading1
    );
  };

  const [confirmExecute, setConfirmExecute] = useState(false);

  const [confirmLoadVacants, setConfirmLoadVacants] = useState(false);
  const [vacants, setVacants] = useState(0);

  const onClose = () => setShowDialog(false);

  return (
    <>
      <Dialog onClose={onClose} open={showDialog} fullWidth maxWidth="md">
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span className="text-dark mr-1">
              Vacant Posts | {selectedAdvertisement.positionName}
            </span>
          </Typography>
          <small style={{ fontSize: "12px" }} className="d-block mt-n2">
            <strong>CODE:</strong> {selectedAdvertisement.positionId}
          </small>

          <Tooltip title="Download">
            <IconButton
              disabled={isLoading || isLoading1}
              className="p-0"
              color="inherit"
              sx={{
                position: "absolute",
                right: 54,
                top: 12,
              }}
              onClick={() => {
                const query = {
                  searchTerm: searchTerm,
                };
                downloadTeacherVacants(
                  selectedAdvertisement,
                  query,
                  setIsDownloading
                );
              }}
            >
              {isDownloading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ fontSize: "28px" }}
                  ></span>
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              )}
            </IconButton>
          </Tooltip>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="bg-light p-3 rounded"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "55vh",
            }}
          >
            <table className="table table-striped  table-sm mt-2">
              {(!!tmisVacants.length || !!searchTerm || !!isLoading1) && (
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" colSpan={6}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="mx-2 py-1">
                          <SearchBox
                            disabled={isLoading1 || isLoading}
                            onSearch={(search) => {
                              setSearchTerm(search);

                              if (!search) {
                                const currentPage = 1;
                                setCurrentPage(currentPage);
                                const query = {
                                  searchTerm: "",
                                  page: currentPage,
                                  size: itemPerPage,
                                };
                                getTMISVacants(
                                  selectedAdvertisement.positionId,
                                  query,
                                  setTotalRequests,
                                  setIsLoading1
                                );
                              }
                            }}
                            onKeyPress={() => {
                              const currentPage = 1;
                              setCurrentPage(currentPage);
                              const query = {
                                searchTerm: searchTerm || "",
                                page: currentPage,
                                size: itemPerPage,
                              };
                              getTMISVacants(
                                selectedAdvertisement.positionId,
                                query,
                                setTotalRequests,
                                setIsLoading1
                              );
                            }}
                            placeholder="Search…"
                          />
                        </div>
                        <div>
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => setConfirmLoadVacants(true)}
                          >
                            <i className="fas fa-cloud-download-alt mr-1"></i>{" "}
                            Reload vacants
                          </Button>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>

                    <th scope="col" className="text-left">
                      School
                    </th>

                    <th scope="col" className="text-left">
                      TeachingLevel
                    </th>

                    <th scope="col" className="text-left">
                      Sector
                    </th>

                    <th scope="col" className="text-left">
                      District
                    </th>

                    <th scope="col" className="text-right">
                      Vacant Posts
                    </th>
                  </tr>
                </thead>
              )}
              {!isLoading && (
                <tbody style={{ height: "100%" }}>
                  <BackdropLoader isLoading={isLoading1} />
                  {tmisVacants.map((vacancy, index) => (
                    <VacancyItemCard
                      key={vacancy.id}
                      loading={loading}
                      vacancy={vacancy}
                      selectedAdvertisement={selectedAdvertisement}
                    />
                  ))}
                </tbody>
              )}
            </table>

            {!tmisVacants.length && (
              <div className="jumbotron jumbotron-fluid text-center bg-white">
                <div className="container">
                  <p className="lead">
                    {isLoading
                      ? "Loading data..."
                      : !searchTerm
                      ? "The vacant posts have not yet been loaded."
                      : "No record found"}
                  </p>

                  {!searchTerm && !isLoading1 && !isLoading && (
                    <Button
                      disabled={loading}
                      color="primary"
                      variant="contained"
                      onClick={() => setConfirmLoadVacants(true)}
                    >
                      <i className="fas fa-cloud-download-alt mr-1"></i> Load
                      vacants from TMIS
                    </Button>
                  )}
                </div>
              </div>
            )}
            {totalRequests > 10 && !isLoading && (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRequests / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setCurrentPage(1);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: 1,
                      size: itemPerPage,
                    };
                    getTMISVacants(
                      selectedAdvertisement.positionId,
                      query,
                      setTotalRequests,
                      setIsLoading
                    );
                  }}
                >
                  {[15, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pt-0 pb-3">
          <Button
            onClick={() => setConfirmExecute(true)}
            disabled={loading || !tmisVacants.length}
            variant="contained"
            size="small"
            disableElevation
            color="primary"
          >
            <span className="material-icons mr-1">terminal</span>
            Execute Placement Algorithm
          </Button>
        </DialogActions>
      </Dialog>

      {confirmExecute && (
        <ConfirmationDialog
          confirmationDialog={confirmExecute}
          message="Are you sure you want to execute placement algorithm?"
          setConfirmationDialog={setConfirmExecute}
          onYes={() => {
            executePlacementAlgorithm(selectedAdvertisement.id, () =>
              setShowDialog(false)
            );
          }}
        />
      )}

      {confirmLoadVacants && (
        <ConfirmationDialog
          confirmationDialog={confirmLoadVacants}
          message="Are you sure you want to load vacant posts from TMIS System?"
          setConfirmationDialog={setConfirmLoadVacants}
          onYes={() => {
            loadTMISVacants(
              selectedAdvertisement.positionId,
              setTotalRequests,
              () => setConfirmLoadVacants(false)
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, tmisVacants, env }) => {
  return { user, loading, tmisVacants, env };
};

export default connect(mapStateToProps, {
  getTMISVacants,
  downloadTeacherVacants,
  executePlacementAlgorithm,
  loadTMISVacants,
})(TeachersVacantPostsDialog);

const VacancyItemCard = (props) => {
  const { vacancy, selectedAdvertisement } = props;

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {vacancy.no}
        </th>
        <td className="text-left">{vacancy.schoolName}</td>

        <td className="text-left">{vacancy.teachingLevel}</td>

        <td className="text-left">{vacancy.sectorName}</td>

        <td className="text-left">{vacancy.districtName}</td>

        <td className="text-right">
          <span className="badge badge-secondary px-2">
            {vacancy.numOfVacantPosts}
          </span>
        </td>
      </tr>
    </>
  );
};
