import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import LeaveLetter from "../../Documents/LetterFormat/LeaveLetter";
import SignLetter from "../../Documents/LetterFormat/SignLetter";

const ViewLeaveLetter = (props) => {
  const {
    setShowLeaveLetter,
    showLeaveLetter,
    leaveRequest,
    selectedFiscalYear,
    signCallBack,
    isForPostponement,
  } = props;
  const { hasPermissions } = useRequiredAuth();

  const onClose = () => {
    setShowLeaveLetter(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showLeaveLetter}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-dark pb-2 ">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            {leaveRequest.leaveType}
            <strong className="text-primary ml-2">
              {selectedFiscalYear?.name}
            </strong>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="py-1">
          <LeaveLetter
            signerNames={leaveRequest.supervisorNames}
            signerPosition={leaveRequest.supervisorPositionName}
            signerEntity={leaveRequest.supervisorEntityName}
            employeeNames={leaveRequest.employeeNames}
            employeeUnit={leaveRequest.unitName}
            employeePosition={leaveRequest.positionName}
            locationName={leaveRequest.locationName}
            reason={leaveRequest.leaveReason || null}
            days={leaveRequest.numberOfDays}
            fiscalYear={selectedFiscalYear?.name || null}
            startDate={moment(leaveRequest.startDate).format("LL")}
            endDate={moment(leaveRequest.endDate).format("LL")}
            title={leaveRequest.leaveType}
            signContent={
              <table>
                <tr>
                  <td
                    style={{
                      position: "relative",

                      width: "20%",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    {/* {!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    !!leaveRequest.letterReferenceId ? (
                      <>
                        <SignLetter />
                      </>
                    ) : ( */}
                    <>
                      <span className="d-block text-danger">Not signed</span>
                    </>
                    {/* )} */}
                  </td>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  ></td>
                </tr>
              </table>
            }
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          {signCallBack}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear }) => {
  return {
    loading,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, {})(ViewLeaveLetter);
