export const defaultState = {
  faults: [],
  sanctions: [],
  cases: [],
  entitySectorCases: [],
  myCases: [],
  unitCases: [],
  caseRequest: {},
  caseAttachments:[],
 disciplineLetterNo:null
};
