import React, { useState, useEffect } from "react";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BackdropLoader from "../../common/components/BackdropLoader";
import { requestStatusColor } from "../../common/components/Utils";
import ModeIcon from "@mui/icons-material/Mode";
import RuleIcon from "@mui/icons-material/Rule";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link, Typography } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { HtmlTooltip } from "../../common/components/CustomizedTooltips";
import RequestTimelineDialog from "./RequestTimelineDialog";

const RequestCard = (props) => {
  const {
    user,
    request,
    setShowTrainingRequestDetails,
    getSingleTrainingRequest,
    setLoading1,
    loading1,
    env,
  } = props;

  const [hoveredRequest, setHoveredRequest] = useState(null);
  const [showRequestTimeline, setShowRequestTimeline] = useState(false);

  const overdueDays = (departureDate) => {
    var departureDate = moment(departureDate);
    var currentDate = moment();

    // Calculate the difference in days
    var overdueDays = currentDate.diff(departureDate, "days");

    // Make sure overdueDays is a positive number (if the current date is after the departure date)
    overdueDays = Math.max(0, overdueDays);
    return overdueDays;
  };
  return (
    <>
      <BackdropLoader isLoading={loading1} />
      <tr
        disabled={loading1}
        onMouseEnter={() => setHoveredRequest(request)}
        onFocus={() => setHoveredRequest(request)}
        onBlur={() => setHoveredRequest(null)}
        onMouseLeave={() => setHoveredRequest(null)}
        style={{
          backgroundColor:
            hoveredRequest === request ? `#cdeef8!important` : "",
          border: hoveredRequest === request ? "2px solid #376fc8" : "none",
          cursor: hoveredRequest === request ? "pointer" : "",
          boxShadow:
            hoveredRequest === request
              ? "inset 0px 0px 0px 1px transparent"
              : "",
        }}
      >
        <td
          className="text-left"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
        >
          <strong> {request.referenceNo} </strong>
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-left text-truncate"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {request.lastName} {request.firstName}
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-left text-truncate"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {request.entityName}
        </td>
        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-left text-truncate"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {request.positionName} ({request.levelName}.{request.scaleName}){" "}
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-left text-truncate"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {request.requestType}
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-right"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {moment(request.createdOn).format("ll")}
        </td>
        <td
          className="text-right"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          <span
            className="ml-2"
            onClick={() => {
              setHoveredRequest(request);
              if (!loading1)
                getSingleTrainingRequest(
                  request.referenceNo,
                  setLoading1,
                  setShowTrainingRequestDetails
                );
            }}
          >
            {request.departureDate ? (
              <>{moment(request.departureDate).format("ll")}</>
            ) : (
              "Local Training"
            )}
          </span>{" "}
          <br />
          <span onClick={() => setShowRequestTimeline(true)}>
            {!request.isOverdue &&
              ![17, 4, 8, 12, 16,21].includes(request.statusId) && (
                <>
                  <span
                    className={`text-${
                      !!request?.isTimeTrackingExpired ||
                      !!request?.isLevelDayOverdue
                        ? "danger"
                        : "success"
                    }`}
                  >
                    <CircleNotificationsIcon
                      style={{ fontSize: "15px", color: "#0099e8" }}
                    />
                    {request.levelDayLeft} / {request.levelDayLeftDesc}
                  </span>{" "}
                  <Link onClick={() => setShowRequestTimeline(true)}>
                    View summary
                  </Link>
                </>
              )}
            {!!request.isOverdue &&
              ![17, 4, 8, 12, 16,21].includes(request.statusId) && (
                <>
                  {" "}
                  <CircleNotificationsIcon
                    style={{ fontSize: "15px", color: "#0099e8" }}
                  />{" "}
                  <span className="badge badge-danger">
                    {overdueDays(request.departureDate)} days overdue
                  </span>
                </>
              )}
          </span>
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );

            // setSelectedAppeal(appeal);
            // setViewAppeal(true);
          }}
          className="text-right"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          <span
            className={`badge py-2 px-2 badge-${requestStatusColor(
              [
                {
                  className: "success",
                  statusIds: [17],
                },
                {
                  className: "danger",
                  statusIds: [4, 8, 12, 16,21],
                },

                {
                  className: "primary",
                  statusIds: [2, 3, 6, 7, 10, 11, 14, 15, 20],
                },

                {
                  className: "warning",
                  statusIds: [18, 5, 9, 13, 19],
                },
              ],
              request.statusId
            )}`}
          >
            {request.trainingStatusName}
          </span>
        </td>

        <td
          onClick={() => {
            setHoveredRequest(request);
            if (!loading1)
              getSingleTrainingRequest(
                request.referenceNo,
                setLoading1,
                setShowTrainingRequestDetails
              );
          }}
          className="text-right"
          style={{
            backgroundColor: hoveredRequest === request ? `#cdeef8` : "",
          }}
        >
          {request.roleId === "HR" &&
          (request.statusId === 18 ||
            request.statusId === 2 ||
            request.statusId === 3 ||
            (request.statusId === 13 &&
              request.ministryEntitySectorId ===
                user.selectedEntitySector.id)) ? (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-primary m-2"
              >
                <ModeIcon /> Modify
              </button>
            </>
          ) : (request.roleId === "TRAINING_RDB_REVIEWER" &&
              request.statusId === 5) ||
            (request.roleId === "TRAINING_MINEDUC_REVIEWER" &&
              request.statusId === 9) ||
            (request.roleId === "TRAINING_MINISTRY_REVIEWER" &&
              request.statusId === 18) ||
            (request.roleId === "TRAINING_MIFOTRA_REVIEWER" &&
              request.statusId === 13) ||
            (request.roleId === "TRAINING_MIFOTRA_REVIEWER2" &&
              request.statusId === 19) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-primary m-2"
            >
              <PreviewIcon /> Review
            </button>
          ) : (request.roleId === "TRAINING_MINISTRY_VERIFIER" &&
              request.statusId === 2) ||
            (request.roleId === "TRAINING_RDB_VERIFIER" &&
              request.statusId === 6) ||
            (request.roleId === "TRAINING_MINEDUC_VERIFIER" &&
              request.statusId === 10) ||
            (request.roleId === "TRAINING_MIFOTRA_VERIFIER" &&
              request.statusId === 14) ||
            (request.roleId === "TRAINING_MIFOTRA_VIEWER" &&
              [20].includes(request.statusId)) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-primary m-2"
            >
              {" "}
              <RuleIcon /> Verify
            </button>
          ) : (request.roleId === "HEAD_OF_INSTITUTION" &&
              request.statusId === 3) ||
            (request.roleId === "TRAINING_MINISTRY_DELEGATED_APPROVER" &&
              request.statusId === 3) ||
            (request.roleId === "TRAINING_RDB_APPROVER" &&
              request.statusId === 7) ||
            (request.roleId === "HEAD_OF_INSTITUTION" &&
              request.statusId === 11) ||
            (request.statusId === 15 &&
              [
                "TRAINING_MIFOTRA_DELEGATED_APPROVER",
                "HEAD_OF_INSTITUTION",
              ].includes(request.roleId)) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-success m-2"
            >
              <TaskAltIcon /> Approve
            </button>
          ) : (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-secondary m-2"
            >
              <OpenInNewIcon /> Open
            </button>
          )}
        </td>
      </tr>
      {showRequestTimeline && (
        <RequestTimelineDialog
          request={request}
          showRequestTimeline={showRequestTimeline}
          setShowRequestTimeline={setShowRequestTimeline}
        />
      )}
    </>
  );
};
export default RequestCard;
