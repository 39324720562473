import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) 
        && ( */}

        {/* <>
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/discipline/sanction"
            className={`  px-1 rounded  ${
              location.pathname.includes("/discipline/sanction")
                ? "active"
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">
              Disciplinary Sanctions
            </span>
          </Link>
        </> */}
        {/* )} */}
        {/* <>
          <HorizontalRuleIcon className="rotated" />
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/discipline/fault"
            className={` px-1 rounded   ${
              location.pathname.includes("/discipline/fault")
                ? " active  "
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">
              Disciplinary Faults
            </span>
          </Link>
        </> */}
        {/* )} */}

        {/* {hasPermissions([
          "IS_COMMITTEE_MEMBER",
          "IS_HR",
          "IS_DAF",
          "IS_CBM",
        ]) && ( */}

        <>
          {/* <HorizontalRuleIcon className="rotated" /> */}
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to={{
              pathname: "/discipline/in-progress-cases",
              state: { statusCode: "IN_PROGRESS_CASES" },
            }}
            className={`  px-1 rounded  ${
              location.pathname.includes("/discipline/in-progress-cases")
                ? "active"
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">Ongoing cases</span>
          </Link>
        </>
        {/* <>
          <HorizontalRuleIcon className="rotated" />
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to={{
              pathname: "/discipline/in-sanction-cases",
              state: { statusCode: "IN_SANCTION_CASES" },
            }}
            className={`  px-1 rounded  ${
              location.pathname.includes("/discipline/in-sanction-cases")
                ? "active"
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">In sanction cases</span>
          </Link>
        </> */}
        <>
          <HorizontalRuleIcon className="rotated" />
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to={{
              pathname: "/discipline/other-cases",
              state: { statusCode: "OTHER_CASES" },
            }}
            className={`  px-1 rounded  ${
              location.pathname.includes("/discipline/other-cases")
                ? "active"
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">Completed cases</span>
          </Link>
        </>
        <>
          <HorizontalRuleIcon className="rotated" />
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to={{
              pathname: "/discipline/dashboard",
              state: { statusCode: "DASHBOARD" },
            }}
            className={`  px-1 rounded  ${
              location.pathname.includes("/discipline/dashboard")
                ? "active"
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">Dashboard</span>
          </Link>
        </>
      </div>
    </>
  );
};

export default Breadcrumb;
