import React, { useState } from "react";
import { connect } from "react-redux";
import {
  IconButton,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Menu,
  Badge,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import { findEmployeePosition } from "../../../store/support/actions";

const EmployeePositionSupport = (props) => {
  const { loading, findEmployeePosition } = props;
  const [employeeSearch, setEmployeeSearch] = useState(null);
  const [employeePositions, setEmployeePositions] = useState([]);

  const onFind = (e) => {
    e.preventDefault();
    if (employeeSearch && employeeSearch.length > 4) {
      setEmployeePositions([]);
      findEmployeePosition(employeeSearch, setEmployeePositions);
    }
  };

  return (
    <div
      className="elevated rounded p-3 my-3"
      style={{
        backgroundColor: "#fafafa",
        border: "1px solid #17a2b8",
        minHeight: "75vh",
      }}
    >
      <form onSubmit={onFind}>
        <div className="row  mb-3">
          <div className="col-10">
            <TextField
              fullWidth
              size="small"
              rows={4}
              name="searchTerm"
              label={`Search by (Employee ID)`}
              placeholder={`Search by (Employee ID)`}
              variant="outlined"
              className="font-weight-bold mb-2"
              value={employeeSearch || ""}
              onChange={(e) => {
                setEmployeeSearch(e.target.value || "");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span
                      style={{
                        borderRadius: "0px",
                      }}
                      className={`badge badge-primary`}
                    >
                      Employee ID
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="col-2">
            <button
              disabled={loading || !employeeSearch}
              type="submit"
              className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
            >
              <span className="material-icons">search</span>
              Search
            </button>
          </div>
        </div>
      </form>

      <table className="table table-striped table-hover table-sm fixTableHead">
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">Employee</th>
            <th scope="col">Unit</th>
            <th scope="col">Position</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Status</th>
            <th scope="col">Scale</th>
            <th scope="col">Level</th>
            <th scope="col">Index To Up</th>
            <th scope="col">Position Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        {loading ? (
          <TableSkeleton cols={11} />
        ) : (
          <tbody>
            {employeePositions.map((item, index) => (
              <tr key={index}>
                <th>{item.firstName + " " + item.lastName.toUpperCase()}</th>
                <td> {item.unityName}</td>
                <td>{item.positionName}</td>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td>{item.statusId}</td>
                <td>{item.scaleId}</td>
                <td>{item.levelId}</td>
                <td>{item.indexTopUp}</td>
                <td>{item.isActing}</td>
                <td>Action</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps, { findEmployeePosition })(
  EmployeePositionSupport
);
