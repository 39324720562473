import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  createOtherLeaveRequest,
  geLeaveTypes,
  geGetLeaveReasons,
  calculateReturnDate,
} from "../../../store/leave/actions";
import { connect } from "react-redux";
import { set } from "lodash";

const OtherLeaveRequestForm = (props) => {
  const {
    loading,
    selectedFiscalYear,
    leaveTypes,
    selectedRequest,
    setShowRequestForm,
    createOtherLeaveRequest,
    geLeaveTypes,
    geGetLeaveReasons,
    calculateReturnDate,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [formData, setFormData] = useState({
    leaveTypeId: "",
    leaveReasonId: "",
    startDate: null,
    numOfDays: 0,
    comment: "",
    leaveRequestId: "",
  });
  const [errors, setErrors] = useState({
    leaveTypeHasError: false,
    leaveTypeErrorMessage: "",
    leaveReasonHasError: false,
    leaveReasonErrorMessage: "",
    startDateHasError: false,
    startDateErrorMessage: "",
    messageHasError: false,
    messageErrorMessage: "",
    numOfDaysHasError: false,
    numOfDaysErrorMessage: "",
  });
  const [leaveReasons, setLeaveReasons] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [returnDate, setReturnDate] = useState(null);

  useEffect(() => {
    if (!leaveTypes.length) {
      geLeaveTypes(setLoadingData);
    } else {
      setLoadingData(false);
    }
    if (selectedRequest) {
      setFormData({
        leaveTypeId: selectedRequest.leaveTypeId,
        leaveReasonId: selectedRequest.leaveReasonId,
        startDate: new Date(selectedRequest.startDate),
        numOfDays: selectedRequest.numOfDays,
        comment: selectedRequest.employeeComment || "",
        leaveRequestId: selectedRequest.id,
      });
      setSelectedType({
        id: selectedRequest.leaveTypeId,
        name: selectedRequest.leaveType,
      });
      setSelectedReason({
        id: selectedRequest.leaveReasonId,
        name: selectedRequest.leaveReason,
      });
    }
  }, []);

  useEffect(() => {
    if (formData.leaveTypeId) {
      geGetLeaveReasons(formData.leaveTypeId, (loading, data) => {
        setLoadingData(loading);
        if (!loading) {
          setLeaveReasons(data);
        }
      });
    }
  }, [formData.leaveTypeId]);

  useEffect(() => {
    if (leaveReasons.length === 1) {
      setFormData({ ...formData, leaveReasonId: leaveReasons[0].id });
    }
  }, [leaveReasons]);

  useEffect(() => {
    if (
      !!selectedReason &&
      (!!selectedReason.allowedDays || !!formData.numOfDays) &&
      formData.startDate
    ) {
      calculateReturnDate(
        {
          startDate: formData.startDate,
          numOfDays: selectedReason.allowedDays || formData.numOfDays,
        },
        (loading, data) => {
          setLoadingData(loading);
          if (!loading && data) setReturnDate(data);
        }
      );
    }
  }, [formData]);

  const onClose = () => {
    setFormData({
      leaveTypeId: "",
      leaveReasonId: "",
      startDate: null,
      numOfDays: 0,
      comment: "",
      leaveRequestId: "",
    });
    setSelectedType(null);
    setSelectedReason(null);
    setErrors({
      leaveTypeHasError: false,
      leaveTypeErrorMessage: "",
      leaveReasonHasError: false,
      leaveReasonErrorMessage: "",
      startDateHasError: false,
      startDateErrorMessage: "",
      messageHasError: false,
      messageErrorMessage: "",
      numOfDaysHasError: false,
      numOfDaysErrorMessage: "",
    });
    setShowRequestForm(false);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const onSubmitLeaveRequest = () => {
    let hasError = false;
    if (!formData.leaveTypeId) {
      setErrors({
        ...errors,
        leaveTypeHasError: true,
        leaveTypeErrorMessage: "Leave type is required",
      });
      hasError = true;
    }
    if (!formData.leaveReasonId) {
      setErrors({
        ...errors,
        leaveReasonHasError: true,
        leaveReasonErrorMessage: "Leave reason is required",
      });
      hasError = true;
    }
    if (!formData.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        startDateErrorMessage: "Start date is required",
      });
      hasError = true;
    }
    if (formData.numOfDays === 0 && selectedReason?.allowedDays === 0) {
      setErrors({
        ...errors,
        numOfDaysHasError: true,
        numOfDaysErrorMessage: "Number of days is required",
      });
      hasError = true;
    }
    if (!formData.comment.length) {
      setErrors({
        ...errors,
        messageHasError: true,
        messageErrorMessage: "Message is required",
      });
      hasError = true;
    }
    if (hasError) return;

    setIsSubmitting(true);
    createOtherLeaveRequest(formData, (loading, res) => {
      setIsSubmitting(loading);
      if (!loading && res) {
        onClose();
      }
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          {selectedRequest ? "UPDATE" : "REQUEST"} LEAVE |{" "}
          <strong className="text-primary">{selectedFiscalYear?.name}</strong>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      {(loadingData || isSubmitting) && <LinearProgress />}
      <DialogContent className="py-1">
        <Autocomplete
          disabled={!leaveTypes.length || isSubmitting || loadingData}
          size="small"
          id="leaveTypes"
          defaultValue={null}
          value={selectedType}
          options={leaveTypes.filter(
            (type) =>
              type.allowedDays !== 0 || type.allowedLeaves > type.takenLeaves
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, leaveType) => {
            if (leaveType) {
              setFormData({
                ...formData,
                leaveTypeId: leaveType.id,
                numOfDays: 0,
                startDate: null,
                leaveReasonId: "",
              });
              setSelectedType(leaveType);
              setSelectedReason(null);
              setErrors({
                ...errors,
                leaveTypeHasError: false,
                leaveTypeErrorMessage: "",
              });
              setReturnDate(null);
            }
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, leaveType) => (
            <Box component="li" {...props}>
              {leaveType.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Leave type"
              inputProps={{
                ...params.inputProps,
                autoComplete: "leaveTypes", // disable autocomplete and autofill
              }}
            />
          )}
          className="mb-3"
        />

        <Autocomplete
          disabled={!leaveReasons.length || isSubmitting || loadingData}
          size="small"
          id="leaveReasons"
          defaultValue={null}
          value={selectedReason}
          options={leaveReasons}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, leaveReason) => {
            if (leaveReason) {
              setFormData({
                ...formData,
                leaveReasonId: leaveReason.id,
                startDate: null,
                numOfDays: 0,
              });
              setSelectedReason(leaveReason);
              setErrors({
                ...errors,
                leaveReasonHasError: false,
                leaveReasonErrorMessage: "",
              });
              setReturnDate(null);
            }
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, leaveReason) => (
            <Box component="li" {...props}>
              <div>
                <span className="d-block text-primary">
                  {leaveReason.name}{" "}
                  <span
                    className="ml-2 badge badge-secondary text-left  text-uppercase"
                    style={{ minWidth: "126px" }}
                  >
                    <span
                      className="badge badge-light bg-white text-secondary"
                      style={{ fontSize: "11px" }}
                    >
                      {leaveReason.allowedDays}
                    </span>{" "}
                    {leaveReason.isWorkingDays ? "Working Days" : "Days"}
                  </span>
                </span>
                <Divider />
                <Divider />
              </div>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Leave Reason"
              inputProps={{
                ...params.inputProps,
                autoComplete: "leaveReasons", // disable autocomplete and autofill
              }}
            />
          )}
          className="mb-3"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="d-flex align-items-center mb-3">
            <DesktopDatePicker
              label="Start date"
              inputFormat="dd/MM/yyyy"
              disabled={loading}
              value={formData.startDate}
              onChange={(date) => {
                setFormData({ ...formData, startDate: date });
                setErrors({
                  ...errors,
                  startDateHasError: false,
                  startDateErrorMessage: "",
                });
                setReturnDate(null);
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => e.preventDefault()}
                  required={true}
                  fullWidth
                  size="small"
                  {...params}
                />
              )}
              shouldDisableDate={isWeekend}
              //   disablePast={!selectedPlan}
            />
            <TextField
              error={errors.numOfDaysHasError}
              helperText={errors.numOfDaysErrorMessage}
              fullWidth
              size="small"
              label="Leave Days"
              type="number"
              name="leaveDays"
              variant="outlined"
              defaultChecked
              placeholder="Leave Days"
              className="ml-3"
              disabled={selectedReason?.allowedDays !== 0}
              value={selectedReason?.allowedDays || formData.numOfDays}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onBlur={() => {
                if (formData.numOfDays === "")
                  setFormData({
                    ...formData,
                    numOfDays: 0,
                  });
                setReturnDate(null);
              }}
              onKeyDown={() => {
                if (+formData.numOfDays === 0)
                  setFormData({
                    ...formData,
                    numOfDays: "",
                  });
              }}
              onChange={(e) => {
                const numOfDays = e.target.value;
                setFormData({
                  ...formData,
                  numOfDays: +numOfDays || 0,
                });
                setErrors({
                  ...errors,
                  numOfDaysHasError: false,
                  numOfDaysErrorMessage: "",
                });
              }}
            />
          </div>
        </LocalizationProvider>

        {returnDate && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="d-flex align-items-center mb-3">
              <DesktopDatePicker
                label="End date"
                inputFormat="dd/MM/yyyy"
                disabled
                value={returnDate}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
              />
            </div>
          </LocalizationProvider>
        )}

        <TextField
          error={errors.messageHasError}
          helperText={errors.messageErrorMessage}
          fullWidth
          size="small"
          multiline
          rows={4}
          name="employeeMessage"
          label="Message (Optional)"
          variant="outlined"
          placeholder="Message"
          className="mb-3"
          value={formData.comment}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          onChange={(e) => {
            setFormData({ ...formData, comment: e.target.value });
            setErrors({
              ...errors,
              messageHasError: false,
              messageErrorMessage: "",
            });
          }}
          disabled={loading}
        />

        <div className="d-flex justify-content-center">
          <button
            onClick={onSubmitLeaveRequest}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={
              loading ||
              isSubmitting ||
              loadingData ||
              errors.numOfDaysHasError ||
              errors.startDateHasError ||
              errors.leaveTypeHasError ||
              errors.leaveReasonHasError
            }
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear, leaveTypes }) => {
  return {
    loading,
    selectedFiscalYear,
    leaveTypes,
  };
};
export default connect(mapStateToProps, {
  createOtherLeaveRequest,
  geLeaveTypes,
  geGetLeaveReasons,
  calculateReturnDate,
})(OtherLeaveRequestForm);
