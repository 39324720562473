import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import PdfViewer from "../../common/components/PdfViewer";
import { getCaseAttachments } from "../../../store/discipline/actions";
import { showError } from "../../toastify";
import TableSkeleton from "../../common/components/TableSkeleton";

const ViewCaseAttachments = (props) => {
  const { caseAttachments, loading, onWait, caseId, getCaseAttachments } =
    props;

  const [showDocument, setShowDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [editFile, setEditFile] = useState(false);
  const [addFile, setAddFile] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getCaseAttachments(caseId, setIsLoading);
  }, [caseId]);
  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            {showDocument && selectedDocument && (
              <PreviewPdfUrl
                showDocument={showDocument}
                setShowDocument={setShowDocument}
                document={{
                  name: `View ${selectedDocument.attachmentName}`,
                  url:
                    process.env.REACT_APP_FILES_URL +
                    "/preview/" +
                    selectedDocument.docReferenceId,
                }}
              />
            )}
            {isLoading && (
              <>
                <table className="table-striped table-hover table-sm  w-100  fixTableHead mt-2"
                style={{ width:"100%" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5", width:"100%" }}>
                      <th scope="col" className="text-left w-50" style={{ width:"50%" }}>
                        
                      </th>

                      <th scope="col" className="text-right w-50" style={{ width:"50%" }}> 
                        <span className="mr-3"></span>
                      </th>
                    </tr>
                  </thead>
             
                    <TableSkeleton cols={2} />
              
                </table>
              </>
            )}
            {!isLoading &&
              !![...caseAttachments].filter(
                ({ isForEmployee }) => !isForEmployee
              ).length && (
                <>
                  <table className="table-striped table-hover table-sm  fixTableHead mt-2">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Institution document(s)
                        </th>

                        <th scope="col" className="text-right">
                          <span className="mr-3"></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {[...caseAttachments]
                        .filter(({ isForEmployee }) => !isForEmployee)
                        .map((attachment, index) => (
                          <tr
                            key={index}
                            className="appeal-hovered"
                            onClick={() => {
                              setSelectedDocument(attachment);
                              setShowDocument(true);
                            }}
                          >
                            <td className="text-left">
                              <strong>
                                <AttachFileIcon className="text-primary" />
                                View {attachment.attachmentName}{" "}
                              </strong>
                            </td>

                            <td className="text-right">
                              <button
                                type="button"
                                className={`btn btn-success m-2`}
                              >
                                <OpenInNewIcon /> Open
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}

            {!isLoading &&
              !![...caseAttachments].filter(
                ({ isForEmployee }) => !!isForEmployee
              ).length && (
                <>
                  <table className="table-striped table-hover table-sm  fixTableHead mt-3">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Employee document(s)
                        </th>

                        <th scope="col" className="text-right">
                          <span className="mr-3"></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {[...caseAttachments]
                        .filter(({ isForEmployee }) => !!isForEmployee)
                        .map((attachment, index) => (
                          <tr
                            key={index}
                            className="appeal-hovered"
                            onClick={() => {
                              setSelectedDocument(attachment);
                              setShowDocument(true);
                            }}
                          >
                            <td className="text-left">
                              <strong>
                                <AttachFileIcon className="text-primary" />
                                View {attachment.attachmentName}{" "}
                              </strong>
                            </td>

                            <td className="text-right">
                              <button
                                type="button"
                                className={`btn btn-primary m-2`}
                              >
                                <OpenInNewIcon /> Open
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}

            {!caseAttachments.length && !isLoading && (
              <>
                <div className="text-center">
                  <p className="lead">No Attachment found</p>
                </div>
              </>
            )}
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};
const mapStateToProps = ({ loading, onWait, caseAttachments }) => {
  return {
    loading,
    onWait,
    caseAttachments,
  };
};
export default connect(mapStateToProps, {
  getCaseAttachments,
})(ViewCaseAttachments);
