import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../toastify";

import { doRejectPayroll } from "../../../store/payroll/actions";
import axios from "axios";
import EditUserSignatureDialog from "./EditUserSignatureDialog";

const SignPayrollDialog = (props) => {
  const {
    user,
    loading,
    showRejectPayrollDialog,
    setShowRejectPayrollDialog,
    doRejectPayroll,
    selectedPayroll,
    signingUser,
    userSignature,
    setUserSignature,
    rejectionLevel,
  } = props;

  const [formData, setFormData] = useState({
    password: "",
    payrollNumber: "",
    reason: "",
  });

  const [errors, setErrors] = useState({
    passwordHasError: false,
    reasonHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      reasonHasError: false,
      passwordHasError: false,
      hasError: false,
    };

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowRejectPayrollDialog(false);
  };

  const [showEditUserSignatureDialog, setShowEditUserSignatureDialog] =
    useState(false);

  //const [reason,setReason]=useState("")

  const onRejectPayroll = (e) => {
    e.preventDefault();
    if (!formValidator()) {
      formData.payrollNumber = selectedPayroll.id;
      doRejectPayroll(
        {
          payrollNumber: selectedPayroll.id,
          password: formData.password,
          reason: formData.reason,
          approvalLevel: rejectionLevel,
          costCenterId: selectedPayroll.costCenterId,
          positionId: user.employments[0].positionId,
        },
        setShowRejectPayrollDialog
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRejectPayrollDialog}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle className="text-danger">
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">error</span> Confirm
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          <form onSubmit={onRejectPayroll}>
            <div
              className="elevated rounded p-3 mt-2"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="text-center">
                Are you sure you want to reject this payroll?
              </div>
              <div className="row">
                <div className="col-12 px-5">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    name="reason"
                    autoFocus
                    label="Reason for rejection"
                    placeholder="Reason for rejection"
                    className="mt-3"
                    value={formData.reason}
                    variant="outlined"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        reason: e.target.value || "",
                      });
                      setErrors({ ...errors, reasonHasError: false });
                    }}
                  />
                  {errors.reasonHasError && (
                    <small className="text-danger">
                      Reason for rejection is required
                    </small>
                  )}
                </div>
                <div className="col-12 px-5 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="password"
                    type="password"
                    label="Enter your password to confirm"
                    variant="outlined"
                    placeholder="Enter your password to confirm"
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        password: e.target.value || "",
                      });
                      setErrors({ ...errors, passwordHasError: false });
                    }}
                  />

                  {errors.passwordHasError && (
                    <small className="text-danger">Password is required </small>
                  )}
                </div>
                <div className="col-12 px-5 d-flex justify-content-center py-3">
                  <button
                    type="submit"
                    className="btn btn-danger btn-block text-uppercase  px-4"
                    disabled={loading}
                  >
                    {loading ? "Rejecting..." : "Confirm, Preject payroll"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <EditUserSignatureDialog
        showEditUserSignatureDialog={showEditUserSignatureDialog}
        setShowEditUserSignatureDialog={setShowEditUserSignatureDialog}
        setUserSignature={setUserSignature}
      />
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, { doRejectPayroll })(SignPayrollDialog);
