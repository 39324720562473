import {
  Tooltip,
  Card,
  Typography,
  CardHeader,
  CircularProgress,
  Box,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import {
  downloadEmployeeContractReport,
  downloadEmployeePerformanceReport,
} from "../../../store/rbm/actions";

const MonitorEmployeeCard = (props) => {
  const {
    employee,
    selectedFiscalYear,
    downloadEmployeePerformanceReport,
    loading,
    setSelectedEmployee,
    downloadEmployeeContractReport,
  } = props;

  const [score, setScore] = useState(0);

  useEffect(() => {
    // setScore(employee.totalScore);
    setTimeout(() => {
      setScore(employee.totalScore);
    }, 500);
  }, [employee]);

  const handleSelectEmployee = (employee) => {
    if (!employee.contractId) return;
    setSelectedEmployee(employee);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <Tooltip title="Progress">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    score < 50
                      ? "danger"
                      : score < 70
                      ? "warning"
                      : score < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={score}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      score < 50
                        ? "danger"
                        : score < 70
                        ? "warning"
                        : score < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${score.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          }
          action={
            <div className="d-flex align-items-center">
              <span
                className={`badge badge-pill ${
                  employee.contractTypeName ? "" : "text-danger"
                } text-uppercase px-0`}
              >
                <small>{employee.contractTypeName || "No"} Contract</small>
              </span>
              {employee.contractTypeName && (
                <Button
                  variant="outlined"
                  size="small"
                  className="ml-3"
                  onClick={() => handleSelectEmployee(employee)}
                >
                  Open
                </Button>
              )}
              <DownloadEmployeeRBMReport
                title={
                  !employee.entitySectorId
                    ? "Employee doesn't have RBM Contract"
                    : "Download Employee Performance Report"
                }
                downloadEmployeePerformanceReport={
                  downloadEmployeePerformanceReport
                }
                downloadEmployeeContractReport={downloadEmployeeContractReport}
                disabled={!employee.entitySectorId || loading}
                employee={employee}
                selectedFiscalYear={selectedFiscalYear}
              />
              {/* <IconButton
                color={!employee.entitySectorId ? "error" : "primary"}
                size="small"
                title={
                  !employee.entitySectorId
                    ? "Employee doesn't have RBM Contract"
                    : "Download Employee Performance Report"
                }
                onClick={() => downloadEmployeePerformanceReport(employee.id)}
                disabled={!employee.entitySectorId || loading}
              >
                <i className="fas fa-download"></i>
              </IconButton> */}
            </div>
          }
          title={
            <span className="d-flex flex-column">
              <span className="text-uppercase font-weight-bold text-primary">
                <strong>{employee.no}:</strong> {employee.lastName}{" "}
                {employee.firstName}
              </span>
              <span className="d-block text-primary ml-1 text-sm">
                {employee.positionName}{" "}
                <strong className="text-dark ">
                  @{employee.unitName.toUpperCase()}
                </strong>
              </span>
            </span>
          }
          subheader={
            <span className="d-flex align-items-center flex-wrap">
              <Tooltip title="All activities">
                <span
                  className={`align-items-center px-1 mx-1 text-dark`}
                  style={{
                    borderRight: "2px solid #ccd0d6",
                    borderLeft: "2px solid #ccd0d6",
                    borderRadius: "8px",
                  }}
                  onClick={() => handleSelectEmployee(employee)}
                >
                  {employee.numOfActivities} Activities
                </span>
              </Tooltip>
              <Tooltip title="Due activities">
                <span
                  className={`align-items-center px-1 mx-1 text-dark`}
                  style={{
                    borderRight: "2px solid #ccd0d6",
                    borderLeft: "2px solid #ccd0d6",
                    borderRadius: "8px",
                  }}
                >
                  {employee.ontrackActivities} OnTrack
                </span>
              </Tooltip>
              <Tooltip title="Due activities">
                <span
                  className={`align-items-center px-1 mx-1 text-dark`}
                  style={{
                    borderRight: "2px solid #ccd0d6",
                    borderLeft: "2px solid #ccd0d6",
                    borderRadius: "8px",
                  }}
                >
                  {employee.overdueActivities} Overdue
                </span>
              </Tooltip>
              <Tooltip title="Reported activities">
                <span
                  className={`align-items-center px-1 mx-1 text-dark`}
                  style={{
                    borderRight: "2px solid #ccd0d6",
                    borderLeft: "2px solid #ccd0d6",
                    borderRadius: "8px",
                  }}
                >
                  {employee.reportedActivities} Reported
                </span>
              </Tooltip>
              <Tooltip title="Scored activities">
                <span
                  className={`align-items-center px-1 mx-1 text-dark`}
                  style={{
                    borderRight: "2px solid #ccd0d6",
                    borderLeft: "2px solid #ccd0d6",
                    borderRadius: "8px",
                  }}
                >
                  {employee.scoredActivities || 0} Scored
                </span>
              </Tooltip>

              <span
                className={`d-flex align-items-center text-${
                  employee.achievedTarget < 50
                    ? "danger"
                    : employee.achievedTarget < 70
                    ? "warning"
                    : employee.achievedTarget < 90
                    ? "info"
                    : "success"
                } px-2 py-0 ml-2`}
                style={{
                  border: "none",
                  cursor: "default",
                  fontWeight: "lighter",
                  fontSize: "13px",
                }}
              >
                <span
                  className={`badge badge-${
                    employee.achievedTarget < 50
                      ? "danger"
                      : employee.achievedTarget < 70
                      ? "warning"
                      : employee.achievedTarget < 90
                      ? "info"
                      : "success"
                  } mr-1`}
                >
                  {employee.achievedTarget.toFixed(2)}%
                </span>
                Target achieved
              </span>
            </span>
          }
        />
        <Divider />
        <Divider />
      </Card>
    </>
  );
};

const mapStateToProps = ({ loading, user, selectedFiscalYear }) => {
  return {
    loading,
    user,
    selectedFiscalYear,
  };
};

export default connect(mapStateToProps, {
  downloadEmployeePerformanceReport,
  downloadEmployeeContractReport,
})(MonitorEmployeeCard);

export const DownloadEmployeeRBMReport = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    title,
    employee,
    disabled,
    downloadEmployeePerformanceReport,
    downloadEmployeeContractReport,
    selectedFiscalYear,
  } = props;
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        size="small"
        className="ml-2"
        color={disabled ? "error" : "primary"}
        onClick={!disabled ? handleOpenMenu : () => {}}
        aria-label="settings"
        disabled={disabled}
        title={title}
      >
        {/* <Badge color="error" variant="dot" invisible={disabled}> */}
        <i className="fas fa-download"></i>
        {/* </Badge> */}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <span>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              downloadEmployeeContractReport(employee.contractId);
            }}
            className="text-dark font-weight-light"
          >
            <i className="fas fa-download text-primary mr-2"></i>
            {employee.contractTypeName} Contract
          </MenuItem>
          <Divider className="my-0" />
        </span>
        <MenuItem
          onClick={() => {
            downloadEmployeePerformanceReport(
              employee.id,
              selectedFiscalYear.id
            );
            handleCloseMenu();
          }}
          className="text-dark font-weight-light"
        >
          <i className="fas fa-download text-primary mr-2"></i> Full report
        </MenuItem>
      </Menu>
    </Box>
  );
};
