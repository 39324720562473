import {
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
  Divider,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditUserDigitalCertificateDialog from "./EditUserDigitalCertificateDialog";
import { connect } from "react-redux";
import UserSignatureDialog from "./UserSignatureDialog";
import BackdropLoader from "./BackdropLoader";
import axios from "axios";
// const menus = [
//   { icon: "list", name: "Certificate" },
//   { icon: "list", name: "Signature" },
// ];
const DigitalCertificateDialog = (props) => {
  const { user, loading, showDialog, setShowDialog } = props;
  // const [activeMenu, setActiveMenu] = useState("Certificate");
  const onClose = () => {
    setShowDialog(false);
  };
  const [userSignature, setUserSignature] = useState(null);
  const [
    showEditUserDigitalCertificateDialog,
    setShowEditUserDigitalCertificateDialog,
  ] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    getUserSignature(setIsWaiting);
  }, []);

  const getUserSignature = async (setIsWaiting) => {
    try {
      setIsWaiting(true);
      const { data } = await axios.get("/api/auth/user-signatures");
      setIsWaiting(false);
      setUserSignature(data);
    } catch (error) {
      setIsWaiting(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth={`md`}
    >
      <DialogTitle className="text-primary font-weight-bold">
        <span className="d-flex align-items-center">
          My Digital Signature Setting
        </span>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="pb-1"
        style={{
          backgroundColor: "#f8f9fa",
        }}
      >
        <div className="row mt-3 justify-content-center">
          <div className="col-12 text-center">
            <div className="px-2">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    {!user.dcertDocReferenceId && (
                      <div className="text-center mb-2">
                        <span className="text-danger">
                          Your digital certificate was not set
                        </span>
                      </div>
                    )}

                    {!!user.dcertDocReferenceId && (
                      <>
                        <div className="certificate-card px-2 py-2 rounded">
                          <div className="certificate-card-body">
                            <span className="material-icons mr-1 certificate-icon">
                              card_membership
                            </span>

                            <p className="certificate-text">
                              Digital Certificate
                            </p>
                          </div>

                          <div
                            className="text-success text-center mt-1"
                            style={{ fontSize: "10px" }}
                          >
                            <span
                              className="material-icons text-success"
                              style={{ fontSize: "12px" }}
                            >
                              verified
                            </span>{" "}
                            <span className="mb-4">Certified by RISA PKI</span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="text-center">
                      <div className="mt-2">
                        <button
                          onClick={() =>
                            setShowEditUserDigitalCertificateDialog(true)
                          }
                          type="button"
                          className="btn btn-sm btn-link"
                        >
                          {!!user.dcertDocReferenceId ? "Edit" : "Set"} your
                          certificate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showEditUserDigitalCertificateDialog && (
                <EditUserDigitalCertificateDialog
                  user={user}
                  showEditUserDigitalCertificateDialog={
                    showEditUserDigitalCertificateDialog
                  }
                  setShowEditUserDigitalCertificateDialog={
                    setShowEditUserDigitalCertificateDialog
                  }
                />
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div>
              <BackdropLoader isLoading={isWaiting} />
              <UserSignatureDialog
                isWaiting={isWaiting}
                userSignature={userSignature}
                setUserSignature={setUserSignature}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(DigitalCertificateDialog);
