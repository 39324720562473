import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";
import { Input, TextField } from "@mui/material";
const LeaveOfAbsenceForSpecificPeriod = (props) => {
  const {
    recipientName,
    effectiveDate,
    recipientPositionName = "",
    institutionName = "",
    effectiveInDays = 0,
    enableToFillLetter = false,
    docReferenceIdInput = "",
    docReferenceDateInput = "",
    referenceLetterDate = "",
    referenceLetterId = "",
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
  } = props;

  function getMonthsFromDays() {
    const daysInMonth = 30; // Assuming an average of 30 days in a month
    const months = Math.floor(effectiveInDays / daysInMonth);
    return months;
  }
  function getEffectedTo() {
    return moment(effectiveDate).add(effectiveInDays, "days").format("LL");
  }

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                “{recipientPositionName}“
              </span>
              <br />
              <span style={{ position: "relative", top: "6px" }}>
                C/O: {institutionName.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="content2">
            {/* <!-- Letter content goes here --> */}

            <p style={{ fontWeight: "bold" }}>
              Re: Response to your request for a leave of absence for a specific
              period
            </p>
            <p>Dear Sir/Madam,</p>
            <p>
              Reference is made to your letter of{" "}
              {moment(new Date()).format("LL")} addressed to the Ministry of
              Public Service and Labour (MIFOTRA), requesting for a leave of
              absence for a specific period of {getMonthsFromDays()} months from
              the job position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recipientPositionName}{" "}
              </span>{" "}
              in the{" "}
              <span style={{ fontWeight: "bold" }}>
                {institutionName.toUpperCase()}{" "}
              </span>
              ;
            </p>
            <p>
              “Reference to the Letter No{" "}
              {enableToFillLetter ? (
                docReferenceIdInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterId}
                  </span>
                </>
              )}{" "}
              of{" "}
              {enableToFillLetter ? (
                docReferenceDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterDate}
                  </span>
                </>
              )}{" "}
              which appointed you on the position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recipientPositionName}{" "}
              </span>{" "}
              in{" "}
              <span style={{ fontWeight: "bold" }}>
                {institutionName.toUpperCase()}{" "}
              </span>
              ;
            </p>
            <p>
              Pursuant to Law n° 017/2020 of 07/10/2020 Law establishing the
              general statute governing public servants, especially in articles
              44 and 45;
            </p>
            <p>
              I would like to inform you that your request for a leave of
              absence for a specific period from the above-mentioned position is
              authorized effective from{" "}
              <span style={{ fontWeight: "bold" }}>{effectiveDate}</span> to{" "}
              <span style={{ fontWeight: "bold" }}>{getEffectedTo()}</span>
            </p>
            <p>Sincerely,</p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveOfAbsenceForSpecificPeriod;
