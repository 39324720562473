import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const WorkCertificateLetter = (props) => {
  const {
    signerNames,
    signerPosition,
    signerEntity,
    employeeNames,
    employmentDate,
    employmentEndDate,
    employeePosition,
    signContent = "",
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {signerEntity.toUpperCase()}
            </div>
          </div>

          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                lineHeight: "1.5",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  top: "25px",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                WORK CERTIFICATE
              </span>
            </p>
          </div>
          <div
            className="content2"
            style={{ position: "relative", top: "20px" }}
          >
            <p>
              I, <span style={{ fontWeight: "bold" }}>{signerNames}</span>, {signerPosition} in the {signerEntity}, certify
              that <span style={{ fontWeight: "bold" }}>{employeeNames}</span> has
              served the {signerEntity} as{" "}
              <span style={{ fontWeight: "bold" }}>{employeePosition}</span> from{" "}
              <span style={{ fontWeight: "bold" }}>{employmentDate}</span> to{" "}
              <span style={{ fontWeight: "bold" }}>{employmentEndDate}</span>.
            </p>
            <p>
              This certificate is issued to serve the purpose it might be
              required for.
            </p>

            <p>Done at Kigali, on {moment(new Date()).format("Do MMMM YYYY")}</p>
          </div>
          <p  style={{ position: "relative", top: "20px" }}>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {signerNames}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {signerPosition} <br />
                {signerEntity}
              </span>
            </p>
          </div>
          </p>
         
        </div>
      </div>
    </>
  );
};

export default WorkCertificateLetter;
