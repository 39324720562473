import React from "react";
import moment from "moment";
import rwandaLogo from "../assets/rwanda-logo.png";

const LetterTemplate = (props) => {
  const {
    request,
    signContent,
    author,
    draftLetterContent,
    draftLetterContentInput,
    docReferenceNoInput,
    docReferenceNo,
    title = "Letter Title Goes Here",
    location = "Kigali",
  } = props;

  const {
    firstName,
    lastName,
    institutionName,
    positionName,
    canAddInstitutionName,
  } = author;
  return (
    <div className="letterMainContent">
      <div className="letter">
        <div className="letterhead d-flex flex-column justify-content-center w-100">
          <div
            className="letterhead-title w-100"
            style={{ fontWeight: "bold" }}
          >
            REPUBLIC OF RWANDA
          </div>
          <div className="w-100">
            <img
              style={{ width: "100px", height: "100px" }}
              src={rwandaLogo}
              alt="Logo"
            />
          </div>
          <div
            className="letterhead-subtitle w-100"
            style={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            {institutionName?.toUpperCase() || ""}
          </div>
        </div>

        <div className="subletterhead">
          <table>
            <tr>
              <td style={{ width: "70%" }}></td>
              <td style={{ width: "30%" }}>
                <p
                  className="date"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  {location}, on {moment(new Date()).format("LL")}
                  <br />N<sup>o</sup>:{" "}
                  {docReferenceNoInput
                    ? docReferenceNoInput
                    : docReferenceNo
                    ? docReferenceNo
                    : "........."}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div className={draftLetterContentInput ? "" : "auth-content"}>
          <div className="header">
            <h1 style={{ fontWeight: "bold" }}>{title}</h1>
          </div>

          <div
            style={{ marginBottom: "30px" }}
            className={draftLetterContentInput ? "" : "content2"}
          >
            {draftLetterContentInput ? (
              draftLetterContentInput
            ) : draftLetterContent ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: draftLetterContent,
                }}
              ></div>
            ) : (
              <>
                <div>
                  I, {firstName || ""} {lastName || ""},{" "}
                  <span className="m_title">
                    {" "}
                    {positionName || "Minister of Public Service and Labour"}
                  </span>
                  , do hereby certify that <br /> {request.employeeTitle}.{" "}
                  {request.lastName.toUpperCase()} {request.firstName},{" "}
                  {request.positionName} in the {request.entityName} is
                  authorised to attend a training on {request.trainingTitle} in{" "}
                  {request.countryForTraining}{" "}
                  <>
                    from {moment(request.trainingDurationFrom).format("ll")} to{" "}
                    {moment(request.trainingDurationTo).format("ll")}.{" "}
                    {!request.isTrainingInsideCountry && (
                      <>
                        The date of departure is{" "}
                        {moment(request.departureDate).format("ll")} and the
                        returning date is{" "}
                        {moment(request.returningDate).format("ll")}.
                      </>
                    )}
                  </>{" "}
                  {request.coveredRelatedCostsDetails}.
                </div>
                <p>
                  I highly appreciate any assistance accorded to{" "}
                  {request.genderId === "M" ? "him" : "her"} in this regard.
                </p>
              </>
            )}
          </div>

          <div className="signature">
            {!draftLetterContentInput && (
              <p
                style={{
                  position: "relative",
                  fontWeight: 800,
                  textAlign: "left",
                }}
              >
                {signContent}
              </p>
            )}

            <p style={{ fontWeight: 800 }}>
              {firstName || ""} {lastName || ""}
            </p>

            <p style={{ fontWeight: 800 }}>
              {positionName || "Minister of Public Service and Labour"}
            </p>
            {!!canAddInstitutionName ? (
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: 800,
                }}
              >
                {institutionName}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetterTemplate;
