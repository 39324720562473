import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { rejectRequest } from "../../../store/exit/actions";

const RejectExitDialog = (props) => {
  const {
    loading,
    rejectRequest,
    rejectRequestForm,
    setRejectRequestForm,
    setSelectedRequest,
    selectedRequest,
  } = props;

  const [rejectExitForm, setRejectExitForm] = useState({
    id: null,
    comment: null,
  });
  const onClose = () => {
    setRejectRequestForm(false);
  };
  const onSave = () => {
    rejectRequest(rejectExitForm, setRejectExitForm, onClose);
  };

  useEffect(() => {
    if (selectedRequest) {
      setRejectExitForm({
        exitRequestId: selectedRequest.id,
        statusId: selectedRequest.statusId,
        requestStatus: selectedRequest.requestStatus,
      });
    }
  }, [selectedRequest]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={rejectRequestForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            CONFIRM Exit Rejection For @
            {selectedRequest && selectedRequest.employeeNames}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            required
            id="rejectExit"
            label="Comments..."
            fullWidth
            onChange={(e) => {
              const comment = e.target.value;
              setRejectExitForm({ ...rejectExitForm, comment });
            }}
            // defaultValue="Type "
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-danger text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "REJECT"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, { rejectRequest })(RejectExitDialog);
