import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";

import MenuBar from "./components/MenuBar";
// import RolePermisson from "./pages/RolePermisson";
import UserGuide from "./pages/UserGuide";
import Structure from "./pages/Structure";
import Users from "./pages/Users";
import Recruitment from "./pages/Recruitment";
import Payroll from "./pages/Payroll";
import RBM from "./pages/RBM";
import Exit from "./pages/Exit";
import { connect } from "react-redux";

import { selectModule } from "./../../store/common/actions";
import { useEffect } from "react";
import Common from "./pages/Common";
import PrivateRoute from "../common/guard/PrivateRoute";
import { useRequiredAuth } from "../common/guard/requiredAuth";
const defaultRouters = [
  {
    to: "/admin/structure",
    permissions: ["IS_ADMIN","IS_STRUCTURE_OVERSIGHT"],
  },

  {
    to: "/admin/recruitment",
    permissions: ["IS_ADMIN", "IS_NPSC", "IS_RECRUITMENT_ADMIN"],
  },

  // {
  //   to: "/admin/users",
  //   permissions: ["IS_ADMIN"],
  // },

  // {
  //   to: "/admin/payroll",
  //   permissions: ["IS_ADMIN"],
  // },

  // {
  //   to: "/admin/payroll",
  //   permissions: ["IS_ADMIN"],
  // },

];
const Layout = (props) => {
  const { hasPermissions } = useRequiredAuth();

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions)) {
        return router.to;
      }
    }

    // If no route is found with the necessary permissions, return the default route
    return "/unauthorized";
  };

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/admin/dashboard" component={Dashboard} />
        <PrivateRoute
          children={<Recruitment />}
          permissions={[
            "IS_ADMIN",
            "IS_NPSC",
            "IS_RECRUITMENT_ADMIN"
          ]}
          path="/admin/recruitment"
        />

        <PrivateRoute
          children={<Structure />}
          permissions={["IS_ADMIN","IS_STRUCTURE_OVERSIGHT"]}
          path="/admin/structure"
        />
        <PrivateRoute
          children={<Users />}
          permissions={["IS_ADMIN"]}
          path="/admin/users"
        />
        <PrivateRoute
          children={<Payroll />}
          permissions={["IS_ADMIN","IS_PAYROLL_OVERSIGHT"]}
          path="/admin/payroll"
        />
        <PrivateRoute
          children={<RBM />}
          permissions={["IS_ADMIN"]}
          path="/admin/rbm"
        />
        <PrivateRoute
          children={<Exit />}
          permissions={["IS_ADMIN"]}
          path="/admin/exit"
        />
        <PrivateRoute
          children={<UserGuide />}
          permissions={["IS_ADMIN"]}
          path="/admin/user-guide"
        />
        <PrivateRoute
          children={<Common />}
          permissions={["IS_ADMIN"]}
          path="/admin/common"
        />

        <Route
          path="/admin"
          component={() => <Redirect to={renderDefaultRoute()} />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ selectedModule }) => {
  return {
    selectedModule,
  };
};
export default connect(mapStateToProps, { selectModule })(Layout);
