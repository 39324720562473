import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { editNPSCReportRespondedLetter } from "../../../store/recruitment/actions";
import { removeDuplicates } from "../../common/components/Utils";
import RespondRecruitmentReportLetter from "../../Documents/LetterFormat/RespondRecruitmentReportLetter";

const ModifyNPSCReportRespondingLetterDialog = (props) => {
  const {
    loading,
    showSubmissionReport,
    setShowSubmissionReport,
    advertisement,
    entities,
    getEntities,
    editNPSCReportRespondedLetter,
    npscReport,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  // console.log('npscReport',npscReport);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);

  // console.log(requestDocumentSignerDetail);
  const [formData, setFormData] = useState({
    requestId: npscReport && npscReport.details ? npscReport.details.id : "",
    advertisementId: npscReport.details.advertisementId,
    npscDocLetterNo:
      npscReport && npscReport.details
        ? npscReport.details.npscDocLetterNo
        : "",
    npscDocContent:
      npscReport && npscReport.details && npscReport.details.npscDocContent
        ? npscReport.details.npscDocContent
        : "",
    npscCCAddress: null,
  });
  const [ccData, setCCData] = useState(
    npscReport && npscReport.details && npscReport.details.npscCCAddress
      ? JSON.parse(npscReport.details.npscCCAddress)
      : []
  );
  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      docContentHasError: false,
      maxCharHasError: false,
    };
    if (!formData.npscDocLetterNo) {
      error.docLetterNoError = true;
      error.hasError = true;
    }
    if (!formData.npscDocContent) {
      error.docContentHasError = true;
      error.hasError = true;
    }

    // if (!ccData.length) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [hasTextEditorChanged, setHasTextEditorChanged] = useState(false);
  const [maxWidth, setMaxWidth] = useState("lg");

  const onClose = () => {
    setShowSubmissionReport(false);
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.npscCCAddress = JSON.stringify(ccData);
      editNPSCReportRespondedLetter(formData, onClose);
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSubmissionReport}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Response of Recruitment Report</span> /{" "}
            <span className="text-primary">
              {advertisement?.positionName || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <RespondRecruitmentReportLetter
              enableToFillLetter={true}
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        position: "relative",

                        width: "20%",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      <span className="d-block text-danger">Not signed</span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
              institutionName={
                npscReport.details.signerEntity.toUpperCase() || ""
              }
              competentAuthorityName={npscReport.details.signerNames}
              competentAuthorityPosition={npscReport.details.signerPosition}
              ccData={ccData}
              headOfInstitutionPosition={
                npscReport.details.headOfInstitutionPosition2 === "Minister"
                  ? npscReport.details.headOfInstitutionPosition
                  : npscReport.details.headOfInstitutionPosition2
              }
              headOfInstitutionEntity={
                npscReport.details.headOfInstitutionPosition2 === "Minister"
                  ? ""
                  : npscReport.details.headOfInstitutionEntity
              }
              dear={npscReport.details.headOfInstitutionPosition2}
              referenceLetterId={npscReport.details.docLetterNo}
              employeePositionName={npscReport.details.positionName}
              docLetterNoInput={
                <>
                  <Input
                    autoFocus
                    error={!!errors.docLetterNoError}
                    value={formData.npscDocLetterNo}
                    style={{ width: "150px" }}
                    inputProps={{
                      "aria-label": "add doc No",
                    }}
                    onChange={(e) => {
                      const npscDocLetterNo = e.target.value;
                      setFormData({
                        ...formData,
                        npscDocLetterNo,
                      });

                      setErrors({
                        ...errors,
                        docLetterNoError: false,
                      });
                    }}
                  />
                </>
              }
              docContentTextEditor={
                <>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    error={!!errors.docContentHasError}
                    rows={4}
                    name="npscDocContent"
                    maxLength={500}
                    label="Doc. Content"
                    variant="outlined"
                    placeholder="Doc. Content"
                    value={formData.npscDocContent}
                    onChange={(e) => {
                      const npscDocContent = e.target.value;
                      setFormData({
                        ...formData,
                        npscDocContent,
                      });

                      setErrors({
                        ...errors,
                        docContentHasError: false,
                      });
                    }}
                  />
                  {errors.docContentHasError && (
                    <small className="text-danger mb-1">
                      This field is required
                    </small>
                  )}
                </>
              }
              ccSelectForm={
                <>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    id="ccData"
                    options={entities.map(({ name, institutionEmail, id }) => ({
                      name,
                      institutionEmail,
                      id,
                    }))}
                    value={ccData}
                    onChange={(e, cc) => {
                      const uniqueCc = removeDuplicates(cc);
                      setCCData(uniqueCc);
                      const ccHasError = false;
                      setErrors({ ...errors, ccHasError });
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            selected ||
                            !!ccData.find(({ id }) => id === option.id)
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Select the institution to copy"}
                        placeholder={"Select the institution to copy"}
                      />
                    )}
                  />

                  {errors.ccHasError && (
                    <small className="text-danger mb-1">
                      Please, select the institution to copy
                    </small>
                  )}
                </>
              }
            />
          </Box>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-2">
          <Button
            variant="contained"
            color="success"
            type="button"
            disabled={loading}
            onClick={onSave}
            className="btn btn-xs"
          >
            {loading ? "Wait.." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, entities, npscReport }) => {
  return {
    loading,
    entities,
    npscReport,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  editNPSCReportRespondedLetter,
})(ModifyNPSCReportRespondingLetterDialog);
