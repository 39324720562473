import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  Tooltip,
  Menu,
  Badge,
  MenuItem,
} from "@mui/material";

import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import {
  saveEmployeeAllowance,
  getEmployeeAllowances,
  deleteEmployeeAllowance,
  removeEmployeeAllowanceProRata,
} from "../../../store/structure/actions";
import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import NumberFormat from "react-number-format";
import CurrencyFormat from "react-currency-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import EmployeeAllowanceProRata from "./EmployeeAllowanceProRata";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const EmployeeAllowances = (props) => {
  const {
    loading,
    onWait,
    selectedEmployee,
    saveEmployeeAllowance,
    deleteEmployeeAllowance,
    employeeAllowances,
    allowances,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
    removeEmployeeAllowanceProRata,
    disabled = false,
  } = props;

  const [allowance, setAllowance] = useState(null);
  const [allowanceType, setAllowanceType] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);

  const [selectedEmployeeAllowance, setSelectedEmployeeAllowance] =
    useState(null);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    employeePositionId: selectedEmployee.employeePositionId,
    allowanceId: "",
    startDate: new Date(),
  });

  const [add, setAdd] = useState(false);

  const [errors, setErrors] = useState({
    allowanceHasError: false,
    startDateError: "",
    startDateHasError: false,
  });

  useEffect(
    () => {
      // getAllowances();
      // getEmployeeAllowances(selectedEmployee.employeePositionId);

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEmployee]
  );

  useEffect(
    () => {
      const add = employeeAllowances.length > 0 ? false : true;

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeAllowances]
  );

  const formValidator = () => {
    const error = {
      allowanceHasError: false,
      hasError: false,
      startDateHasError: false,
      startDateError: "",
    };

    if (!formData.allowanceId) {
      error.allowanceHasError = true;
      error.hasError = true;
    }

    if (!formData.startDate) {
      error.startDateHasError = true;
      error.hasError = true;
    }

    // if (
    //   (!!formData.startDate &&
    //     new Date(formData.startDate) <
    //       new Date(new Date().getFullYear(), new Date().getMonth(), 1)) ||
    //   new Date(formData.startDate) > new Date()
    // ) {
    //   error.startDateHasError = true;
    //   error.startDateError = "Invalid Date";
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveEmployeeAllowance(formData, setFormData, setAdd, setAllowance);
    }
  };

  useEffect(() => {
    if (!!allowance) {
      setAllowanceType(
        allowanceTypes.find(({ id }) => id === allowance.allowanceTypeId)
      );

      setCalculationMethod(
        calculationMethods.find(
          ({ id }) => id === allowance.calculationMethodId
        )
      );

      if (
        allowance.calculationMethodId === 2 &&
        !!allowance.basePayrollComponentName
      )
        setBasePayrollComponent(
          basePayrollComponents.find(
            ({ id }) => id === allowance.basePayrollComponentId
          )
        );
    } else {
      setAllowanceType(null);
      setCalculationMethod(null);
      setBasePayrollComponent(null);
    }
  }, [allowance]);

  const getEmployeeLevelAllowances = () =>
    allowances.filter(({ isForEmployee }) => isForEmployee === true);

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  const [confirmRemoveEmployeeAllowance, setConfirmRemoveEmployeeAllowance] =
    useState(false);

  const [showEmployeeAllowanceProRata, setShowEmployeeAllowanceProRata] =
    useState(false);

  return (
    <>
      <div style={{ minHeight: "45vh" }}>
        {!add && (
          <Button
            className="mb-2"
            disabled={disabled}
            onClick={() => {
              setAdd(true);
            }}
            style={{
              ...ActiveButton,
              padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
              float: "right",
            }}
            size="sm"
          >
            <span className="material-icons">add</span>
          </Button>
        )}

        {loading && !onWait && (
          <div className="container mt-2" style={{ width: "100%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </div>
        )}

        {(!loading || onWait) && (
          <div>
            {add && (
              <div
                className="elevated rounded p-0 mb-3"
                style={{
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #17a2b8",
                }}
              >
                <div className="row d-flex align-items-start p-3">
                  <div className="col-9">
                    <Autocomplete
                      className="mt-3"
                      size="small"
                      id="allowance"
                      defaultValue={null}
                      disabled={disabled}
                      value={allowance}
                      options={getEmployeeLevelAllowances()}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, allowance) => {
                        setAllowance(allowance || null);
                        const allowanceId = allowance ? allowance.id : "";

                        setFormData({
                          ...formData,
                          allowanceId,
                        });

                        setErrors({ ...errors, allowanceHasError: false });
                      }}
                      getOptionLabel={(option) =>
                        getNo(option.id, getEmployeeLevelAllowances()) +
                        "-" +
                        option.name +
                        (option.calculationMethodId === 1
                          ? ` (${option.currencyId}:`
                          : " (RATE:") +
                        option.amount +
                        (option.calculationMethodId === 2 &&
                        !!option.basePayrollComponentName
                          ? "/ " + option.basePayrollComponentName
                          : "") +
                        ")"
                      }
                      renderOption={(props, allowance) => (
                        <Fragment key={allowance.id}>
                          <Box component="li" {...props}>
                            {getNo(allowance.id, getEmployeeLevelAllowances())}{" "}
                            - {allowance.name}{" "}
                            {allowance.calculationMethodId === 1
                              ? ` (${allowance.currencyId}:`
                              : " (RATE:"}
                            {allowance.amount}
                            {allowance.calculationMethodId === 2 &&
                            !!allowance.basePayrollComponentName
                              ? "/ " + allowance.basePayrollComponentName
                              : ""}
                            {")"}
                          </Box>
                          <Divider />
                        </Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="---Select allowance---"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "allowance", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />

                    {errors.allowanceHasError && (
                      <small className="text-danger ">
                        Allowance is required{" "}
                      </small>
                    )}
                  </div>

                  <div className="col-3 mt-3">
                    <Autocomplete
                      style={{ backgroundColor: "#eee" }}
                      size="small"
                      disabled
                      id="type"
                      defaultValue={null}
                      value={allowanceType}
                      options={allowanceTypes}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, allowanceType) => (
                        <Box component="li" {...props}>
                          {allowanceType.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "type", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>

                  <div
                    className={`${
                      !!allowance &&
                      allowance.calculationMethodId === 2 &&
                      !!allowance.basePayrollComponentName
                        ? "col-3"
                        : "col-4"
                    }`}
                  >
                    <NumberFormat
                      disabled
                      style={{ backgroundColor: "#eee" }}
                      fullWidth
                      size="small"
                      className="mt-3 py-0 small"
                      label={
                        !allowance || allowance.calculationMethodId === 1
                          ? "Amount"
                          : "Rate"
                      }
                      name="amount"
                      variant="outlined"
                      placeholder={`${allowance?.currencyId || ""} 0`}
                      prefix={`${allowance?.currencyId || ""} `}
                      customInput={TextField}
                      value={!!allowance ? allowance.amount : ""}
                      thousandSeparator={true}
                    />
                  </div>
                  <div
                    className={`mt-3 ${
                      !!allowance &&
                      allowance.calculationMethodId === 2 &&
                      !!allowance.basePayrollComponentName
                        ? "col-3"
                        : "col-4"
                    }`}
                  >
                    <div>
                      <Autocomplete
                        style={{ backgroundColor: "#eee" }}
                        size="small"
                        disabled
                        id="Method"
                        defaultValue={null}
                        value={calculationMethod}
                        options={calculationMethods}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        // onChange={(event, calculationMethod) => {
                        //   setCalculationMethod(calculationMethod || null);
                        //   const calculationMethodId = calculationMethod
                        //     ? calculationMethod.id
                        //     : "";
                        //   setFormData({ ...formData, calculationMethodId });
                        // }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, calculationMethod) => (
                          <Box component="li" {...props}>
                            {calculationMethod.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Method"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "calculationMethod", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={`mt-3 ${
                      !!allowance &&
                      allowance.calculationMethodId === 2 &&
                      !!allowance.basePayrollComponentName
                        ? "col-3"
                        : "col-4"
                    }`}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Effective Date"
                        inputFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        // minDate={
                        //   new Date(
                        //     new Date().getFullYear(),
                        //     new Date().getMonth(),
                        //     1
                        //   )
                        // }
                        value={formData.startDate}
                        onChange={(date) => {
                          setErrors({
                            ...errors,
                            startDateHasError: false,
                          });
                          const startDate = date;
                          setFormData({ ...formData, startDate });

                          setErrors({ ...errors, startDateHasError: false });
                        }}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />

                      {errors.startDateHasError && (
                        <small className="text-danger mt-1">
                          {errors.startDateError ||
                            "Effective Date is required"}
                        </small>
                      )}
                    </LocalizationProvider>
                  </div>
                  {!!allowance &&
                    allowance.calculationMethodId === 2 &&
                    !!allowance.basePayrollComponentName && (
                      <div className=" col-3">
                        <div className="mt-3">
                          <Autocomplete
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            id="baseComponent"
                            defaultValue={null}
                            value={basePayrollComponent}
                            options={basePayrollComponents}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, basePayrollComponent) => (
                              <Box component="li" {...props}>
                                {basePayrollComponent.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Base Component"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "baseComponent", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                  {/* <div className="col-12">
                    <FormGroup
                      row
                      className=" mt-3 d-flex justify-content-center border border-info bg-light"
                    >
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payTPR || false}
                          />
                        }
                        label="Pay TPR"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payCSR || false}
                          />
                        }
                        label="Pay CSR"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payMedical || false}
                          />
                        }
                        label="Pay Medical"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payMaternity || false}
                          />
                        }
                        label="Pay Maternity"
                      />
                    </FormGroup>
                  </div> */}
                </div>

                <Divider className="my-1" />
                <div className="d-flex justify-content-center py-0 p-2">
                  <button
                    onClick={onSave}
                    type="button"
                    className="btn btn-primary text-uppercase"
                    disabled={disabled || loading}
                  >
                    {loading ? "Wait..." : "Save"}
                  </button>{" "}
                  {!!employeeAllowances.length && (
                    <React.Fragment>
                      &nbsp; &nbsp;
                      <button
                        onClick={() => {
                          setAdd(false);
                          setAllowance(null);
                        }}
                        type="button"
                        className="btn btn-default text-uppercase"
                        disabled={disabled || loading}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}

            {!!employeeAllowances.length && (
              <React.Fragment>
                <table className="table table-bordered table-sm mt-1">
                  <thead>
                    <tr>
                      <th
                        colSpan={5}
                        style={{ textAlign: "center" }}
                        className="py-1"
                      >
                        ACTIVE ALLOWANCES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeAllowances.map((employeeAllowance, paIndex) => (
                      <EmployeeAllowanceItem
                        key={paIndex}
                        disabled={disabled}
                        employeeAllowance={employeeAllowance}
                        paIndex={paIndex}
                        selectedEmployeeAllowance={selectedEmployeeAllowance}
                        selectedEmployee={selectedEmployee}
                        loading={loading}
                        deleteEmployeeAllowance={deleteEmployeeAllowance}
                        setSelectedEmployeeAllowance={
                          setSelectedEmployeeAllowance
                        }
                        setConfirmRemoveEmployeeAllowance={
                          setConfirmRemoveEmployeeAllowance
                        }
                        setShowEmployeeAllowanceProRata={
                          setShowEmployeeAllowanceProRata
                        }
                        removeEmployeeAllowanceProRata={
                          removeEmployeeAllowanceProRata
                        }
                      />
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        )}
      </div>

      {confirmRemoveEmployeeAllowance && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveEmployeeAllowance}
          message={`Are you sure you want to remove this allowance?`}
          setConfirmationDialog={setConfirmRemoveEmployeeAllowance}
          onYes={() => {
            deleteEmployeeAllowance(
              selectedEmployeeAllowance,
              setConfirmRemoveEmployeeAllowance,
              setSelectedEmployeeAllowance
            );
          }}
        />
      )}

      {showEmployeeAllowanceProRata && (
        <EmployeeAllowanceProRata
          showEmployeeAllowanceProRata={showEmployeeAllowanceProRata}
          setShowEmployeeAllowanceProRata={setShowEmployeeAllowanceProRata}
          selectedEmployeeAllowance={selectedEmployeeAllowance}
          selectedEmployee={selectedEmployee}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  allowances,
  employeeAllowances,
  calculationMethods,
  basePayrollComponents,
  allowanceTypes,
}) => {
  return {
    loading,
    onWait,
    allowances,
    employeeAllowances,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
  };
};
export default connect(mapStateToProps, {
  saveEmployeeAllowance,
  getEmployeeAllowances,
  deleteEmployeeAllowance,
  getAllowances,
  removeEmployeeAllowanceProRata,
})(EmployeeAllowances);

const EmployeeAllowanceItem = (props) => {
  const {
    employeeAllowance,
    paIndex,
    setSelectedEmployeeAllowance,
    loading,
    setConfirmRemoveEmployeeAllowance,
    setShowEmployeeAllowanceProRata,
    removeEmployeeAllowanceProRata,
    selectedEmployee,
    disabled,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr
        key={paIndex}
        style={{
          background: paIndex % 2 === 0 ? "" : "#f6f7f7",
          borderBottom: "2px dotted rgb(7, 142, 206)!important",
        }}
      >
        <td>{paIndex + 1}</td>
        <td style={{ width: "55%" }}>
          {employeeAllowance.allowanceName}

          <span className="badge badge-fill   float-right text-info ">
            {" "}
            <small>
              Effective From:{" "}
              {moment(employeeAllowance.startDate).format("yyyy-MM-DD")}
            </small>
          </span>
        </td>
        <td className="text-left">
          <span>
            <Chip
              style={{ borderRadius: "0px" }}
              variant="outlined"
              className="small-chip"
              size="small"
              label={employeeAllowance.allowanceTypeName}
            />
          </span>
        </td>

        <td className="text-right">
          {employeeAllowance.calculationMethodId === 1 ? (
            <>
              {!employeeAllowance.proRataAmount && (
                <CurrencyFormat
                  value={employeeAllowance.amount}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              )}

              {!!employeeAllowance.proRataAmount && (
                <del className="text-danger">
                  <CurrencyFormat
                    value={employeeAllowance.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </del>
              )}

              {!!employeeAllowance.proRataAmount && (
                <span>
                  {/* <i
                    className="fas fa-play mx-1"
                    style={{ fontSize: "12px" }}
                  ></i> */}
                  <strong className="px-1">{"=>"}</strong>
                  <CurrencyFormat
                    value={employeeAllowance.proRataAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              )}
            </>
          ) : (
            <>
              {employeeAllowance.amount}
              {!!employeeAllowance.basePayrollComponentName && (
                <span
                  className="badge badge-light text-uppercase text-info"
                  style={{ fontSize: "14px" }}
                >
                  {"/ " + employeeAllowance.basePayrollComponentName}
                </span>
              )}
            </>
          )}

          <span
            className={`badge mr-1 badge-${
              employeeAllowance.calculationMethodId === 1
                ? "light text-info"
                : "secondary"
            }`}
          >
            {" "}
            {employeeAllowance.calculationMethodId === 1
              ? employeeAllowance.currencyId
              : "RATE"}
            {employeeAllowance.proRataAmount && (
              <small className="ml-1 badge badge-secondary">Pro-rata</small>
            )}
          </span>
        </td>
        <td className="text-right border-none">
          <span>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons text-primary">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "auto",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              {!employeeAllowance.proRataAmount && (
                <span>
                  <MenuItem
                    disabled={disabled || loading}
                    onClick={() => {
                      handleCloseMenu();

                      setSelectedEmployeeAllowance(employeeAllowance);
                      setShowEmployeeAllowanceProRata(true);
                    }}
                    className="text-primary font-weight-bolder"
                  >
                    <span
                      style={{ transform: "rotateY(180deg)" }}
                      className="material-icons mr-1"
                    >
                      add
                    </span>
                    Add pro-rata
                  </MenuItem>
                </span>
              )}
              {!!employeeAllowance.proRataAmount && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    disabled={disabled || loading}
                    onClick={() => {
                      removeEmployeeAllowanceProRata(
                        {
                          employeePositionId:
                            selectedEmployee.employeePositionId,
                          allowanceId: employeeAllowance.allowanceId,
                        },
                        handleCloseMenu
                      );
                    }}
                    className="text-danger font-weight-bolder"
                  >
                    <span className="material-icons mr-1">delete</span>
                    Remove pro-rata
                  </MenuItem>
                </span>
              )}

              {employeeAllowance.isEditable && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    disabled={disabled || loading}
                    onClick={() => {
                      handleCloseMenu();
                      setSelectedEmployeeAllowance(employeeAllowance);
                      setConfirmRemoveEmployeeAllowance(true);
                    }}
                    className="text-danger font-weight-bolder"
                  >
                    <span className="material-icons mr-1">delete</span> Remove
                  </MenuItem>
                </span>
              )}
            </Menu>
          </span>
        </td>
      </tr>
    </>
  );
};
