import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { rejectEmployeeWithhold } from "../../../store/structure/actions";
import { showError } from "../../../app/toastify";

const RejectEmployeeDeductionDialog = (props) => {
  const {
    loading,
    approveEmployeeWithhold,
    setApproveEmployeeWithhold,
    selectedEmployeeWithhold,
    rejectEmployeeWithhold,
  } = props;

  const [employeeWithholdForm, setEmployeeWithholdForm] = useState({
    creditorId: selectedEmployeeWithhold.creditorId,
    employeePositionId: selectedEmployeeWithhold.employeePositionId,
    rejectReason: "",
  });
  const [errors, setErrors] = useState({
    rejectReasonHasError: false,
  });

  const [askToReject, setAskToReject] = useState(false);

  const formValidator = () => {
    const error = {
      rejectReasonHasError: false,
      hasError: false,
    };

    if (!employeeWithholdForm.rejectReason) {
      error.rejectReasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      rejectEmployeeWithhold(
        employeeWithholdForm,
        setEmployeeWithholdForm,
        setApproveEmployeeWithhold,
        setAskToReject
      );
    }
  };

  const onClose = () => {
    setAskToReject(false);
    setApproveEmployeeWithhold(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveEmployeeWithhold}
        fullWidth
        maxWidth="sm"
      >
        {askToReject && (
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              Reject deduction @{selectedEmployeeWithhold.creditorName}?
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            {!askToReject && (
              <div className="row">
                <div className="col-12 mt-3">
                  <div className="alert alert-info" role="alert">
                    <p>
                      Are you sure you want to reject this deduction @
                      {selectedEmployeeWithhold.creditorName}?
                    </p>
                  </div>
                </div>
              </div>
            )}
            {askToReject && (
              <div className="row">
                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="rejectReason"
                    autoFocus
                    label="Type your reason .."
                    variant="outlined"
                    placeholder="Type your reasons  .."
                    value={employeeWithholdForm.rejectReason}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const rejectReason = e.target.value;
                      setEmployeeWithholdForm({
                        ...employeeWithholdForm,
                        rejectReason,
                      });
                      const rejectReasonHasError = false;
                      setErrors({ ...errors, rejectReasonHasError });
                    }}
                  />

                  {errors.rejectReasonHasError && (
                    <div className="text-danger mt-2">
                      rejection reason is required{" "}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        {askToReject && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Reject"}
            </button>
          </DialogActions>
        )}

        {!askToReject && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={() => {
                setAskToReject(true);
              }}
              type="button"
              className="btn btn-success text-uppercase  px-4 btn-sm"
            >
              Yes
            </button>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-secondary text-uppercase  px-4 btn-sm"
            >
              No
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  rejectEmployeeWithhold,
})(RejectEmployeeDeductionDialog);
