import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Autocomplete,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Badge,
} from "@mui/material";
import {
  getEmployeesPullRequests,
  pullEmployee,
  getUnitPositions,
  getEmployeeProfile,
} from "../../../store/structure/actions";

import React, { useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../toastify";
import { useEffect } from "react";
import axios from "axios";
// import EmployeeCard from "./EmployeeCard";
import { useDispatch } from "react-redux";
import EmployeePersonalInfoDialog from "./EmployeePersonalInfoDialog";

const EmployeesPullDialog = (props) => {
  const {
    loading,
    onWait,
    user,
    showEmployeePullDialog,
    setShowEmployeePullDialog,
    employeesPullRequests,
    getEmployeesPullRequests,
    pullEmployee,
    selectedUnit,
    getUnitPositions,
    positions,
    getEmployeeProfile,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    getEmployeesPullRequests();
    getUnitPositions(selectedUnit.unitId);
  }, []);

  const onClose = () => {
    setShowEmployeePullDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeePullDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <span className="ml-0 text-dark"> employees pull request | </span>{" "}
            <span className="ml-0">{user.selectedEntitySector.entityName}</span>{" "}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              height: "75vh",
              display: "table-cell",
              width: "100vw",
              padding: "10px",
              // verticalAlign: "middle",
            }}
          >
            {loading && !onWait && (
              <Box className="justify-content-center " sx={{ display: "flex" }}>
                <CircularProgress />
                <h5 className="mt-2 ml-2">Please wait ...</h5>
              </Box>
            )}

            {(!loading || onWait) && (
              <>
                {!employeesPullRequests.length && (
                  <div className="jumbotron jumbotron-fluid text-center">
                    <div className="container py-5">
                      <p className="lead py-5">
                        No employee pull requests available
                      </p>
                    </div>
                  </div>
                )}
                {!!employeesPullRequests.length && (
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">EmployeeId</th>
                        <th scope="col">Names</th>
                        <th scope="col">National ID</th>
                        <th scope="col">RSSBNo</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Personal Email</th>
                        <th scope="col">Professional Email</th>

                        <th scope="col">Position</th>
                        <th scope="col">Source Institution</th>

                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {employeesPullRequests.map((employee, index) => (
                        <EmployeeCard
                          loading={loading}
                          employee={employee}
                          key={employee.id}
                          positions={positions}
                          pullEmployee={pullEmployee}
                          getEmployeeProfile={getEmployeeProfile}
                        />
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-2"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  employeesPullRequests,
  positions,
}) => {
  return {
    loading,
    onWait,
    user,
    employeesPullRequests,
    positions,
  };
};
export default connect(mapStateToProps, {
  getEmployeesPullRequests,
  pullEmployee,
  getUnitPositions,
  getEmployeeProfile,
})(EmployeesPullDialog);

const EmployeeCard = (props) => {
  const { loading, employee, positions, pullEmployee, getEmployeeProfile } =
    props;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  return (
    <>
      <tr className={`text-danger`}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {employee.employeeId}
        </th>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{employee.idNumber}</td>
        <td>{employee.rssbNumber}</td>
        <td>{employee.phoneNumber}</td>
        <td>{employee.email}</td>
        <td>{employee.professionalEmail}</td>
        <td>{employee.positionName}</td>
        <td>{employee.entityName}</td>

        <td className="py-2">
          <Button
            disabled={loading}
            size="small"
            color="primary"
            variant="contained"
            className="ml-2"
            onClick={() => {
              getEmployeeProfile(
                { employeeId: employee.id, idNumber: employee.idNumber },

                setShowConfirmDialog,
                () => {}
              );
            }}
            aria-label="settings"
          >
            <span className="material-icons">get_app</span> Pull
          </Button>
        </td>
      </tr>

      <ConfirmDialod
        positions={positions}
        employee={employee}
        loading={loading}
        setShowConfirmDialog={setShowConfirmDialog}
        showConfirmDialog={showConfirmDialog}
        pullEmployee={pullEmployee}
      />
    </>
  );
};

const ConfirmDialod = (props) => {
  const {
    setShowConfirmDialog,
    loading,
    showConfirmDialog,
    employee,
    pullEmployee,
    positions,
  } = props;

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={() => {
        setShowConfirmDialog(false);
      }}
      open={showConfirmDialog}
    >
      <DialogTitle>
        <span className="d-flex align-items-center">
          employees pull request
        </span>{" "}
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowConfirmDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-0 text-center">
        <div
          className="elevated rounded px-3 py-4 mt-0"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #17a2b8",
            minHeight: "65vh",
          }}
        >
          <EmployeePersonalInfoDialog
            employee={{ ...employee, id: employee.employeeId }}
            isPullRequest={true}
            setShowConfirmDialog={setShowConfirmDialog}
          />
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
    </Dialog>
  );
};
