import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  Divider,
  Typography,
  Checkbox,
  Button,
  DialogActions,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveActivityAssignment,
  removeActivityAssignment,
  getSubordinates,
} from "../../../store/rbm/actions";
import { isEmpty } from "lodash";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import defaultProfile from "../../assets/default-profile.jpg";

import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ActivityAssignmentForm = (props) => {
  const {
    selectedActivity,
    showAssignmentForm,
    setShowAssignmentForm,
    loading,
    subordinates,
    saveActivityAssignment,
    removeActivityAssignment,
    totalQuarterTarget,
    quarter,
    totalWeight,
    user,
    getSubordinates,
  } = props;

  console.log(quarter);

  const [formData, setFormData] = useState(null);
  const [confirmRemoveAssignment, setConfirmRemoveAssignment] = useState(false);
  const [assignmentToBeRemoved, setAssignmentToBeRemoved] = useState(null);
  const [assignmentDeadline, setAssignmentDeadline] = useState(
    new Date(selectedActivity.endOn)
  );

  const { hasPermissions } = useRequiredAuth();

  const [contractType, setContractType] = useState({
    id: 1,
    name: "Normal",
  });

  useEffect(() => {
    if (!!showAssignmentForm && !subordinates.length) getSubordinates();
  }, [showAssignmentForm]);

  const filteredSubordinates = () =>
    subordinates.filter(
      (subordinate) =>
        selectedActivity.assignments.findIndex(
          (assignment) => subordinate.employeeId === assignment.id
        ) < 0 && !subordinate.isNotActive
    );

  const onSave = () => {
    if (+quarter.target - +totalQuarterTarget !== 0)
      return showError(
        `${+quarter.target - +totalQuarterTarget} Target${
          +quarter.target - +totalQuarterTarget > 1 ? "s" : ""
        } remaining`
      );

    if (!assignmentDeadline) return showError("Please select deadline date");

    const payroad = [];

    // formData.forEach(({ id, employeeId }) =>
    //   payroad.push({
    //     activityId: selectedActivity.id,
    //     employeePositionId: id,
    //     employeeId: employeeId,
    //     contractTypeId: contractType.id,
    //     deadline: assignmentDeadline,
    //   })
    // );

    // formData.forEach(({ id, employeeId }) =>
    payroad.push({
      activityId: selectedActivity.id,
      employeePositionId: formData.id,
      employeeId: formData.employeeId,
      contractTypeId: contractType.id,
      deadline: assignmentDeadline,
    });
    // );

    if (!payroad.length) return;

    saveActivityAssignment(payroad, () => setFormData(null));
  };

  const onClose = () => {
    setFormData(null);
    setShowAssignmentForm(false);
  };

  const handleAssignmentDeadline = (selectedEmployees) => {
    if (
      selectedEmployees &&
      [3, 4].includes(selectedEmployees.contractTypeId)
    ) {
      setAssignmentDeadline(new Date(selectedEmployees.deadline));
    } else {
      setAssignmentDeadline(new Date(selectedActivity.endOn));
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAssignmentForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary pb-0 mb-0 mx-2">
          <Typography className="text-uppercase">Assign Activity</Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          {hasPermissions(["CAN_ASSIGN_ACTIVITY"]) && (
            <div className="row mt-2 no-gutters align-items-center">
              <div className="col-12">
                <Typography className="text-dark">
                  {selectedActivity.name}
                </Typography>
                <Divider />
                <Divider />
              </div>

              <div className="col-12 no-gutters row mx-auto my-2 pt-1">
                <div className="col-12 mb-3">
                  <Autocomplete
                    // multiple
                    // disableCloseOnSelect
                    disablePortal
                    size="small"
                    value={formData || null}
                    options={filteredSubordinates()}
                    getOptionLabel={(option) => option.names}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setFormData(newValue);
                      handleAssignmentDeadline(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select responsible employees"
                        placeholder="Select responsible employees"
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <Box component="li" {...props}>
                        {/* <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        /> */}
                        <Avatar
                          style={{ border: "1px solid #d1cbcb" }}
                          alt={option.firstName}
                          className="mr-2"
                          src={
                            option.profileImage
                              ? `data:image/jpeg;base64,${option.profileImage}`
                              : defaultProfile
                          }
                        />
                        <div>
                          <span className="d-block text-primary">
                            {option.names}{" "}
                            <span
                              className={`badge ${
                                option.contractType
                                  ? "badge-pill"
                                  : "badge-pill text-danger"
                              } badge-light`}
                            >
                              {option.contractType || "No"} contract
                            </span>
                          </span>
                          <small className="d-block">
                            {" "}
                            {!!option.isActing && (
                              <span className="text-primary font-weight-bold">
                                Ag.
                              </span>
                            )}{" "}
                            {option.positionName}
                          </small>
                          <small className="d-block text-uppercase mb-1">
                            {option.unitName}
                          </small>
                          <Divider />
                          <Divider />
                        </div>
                      </Box>
                    )}
                  />
                </div>
                <div className="col-6">
                  <Autocomplete
                    disablePortal
                    size="small"
                    value={contractType}
                    options={[
                      {
                        id: 1,
                        name: "Normal",
                      },
                      {
                        id: 2,
                        name: "Acting",
                      },
                      {
                        id: 3,
                        name: "Probation",
                      },
                      {
                        id: 4,
                        name: "Second probation",
                      },
                      {
                        id: 5,
                        name: "Second employment",
                      },
                    ]}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setContractType(newValue);
                      handleAssignmentDeadline(formData);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contract Type"
                        placeholder="Contract Type"
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                  />
                </div>
                <div className="col-6 pl-2">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Deadline Date"
                      inputFormat="dd/MM/yyyy"
                      defaultCalendarMonth={assignmentDeadline}
                      value={assignmentDeadline}
                      onChange={(date) => {
                        setAssignmentDeadline(date);
                      }}
                      maxDate={
                        ![3, 4].includes(contractType.id)
                          ? new Date(quarter.endOn)
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => e.preventDefault()}
                          required={true}
                          fullWidth
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent className="mx-4 px-2">
          <div className="px-4 rbm-shadow rounded">
            {!!selectedActivity.assignments[0] && (
              <Typography className="text-dark text-center text-uppercase bg-light mt-3 border px-2 py-1">
                <small className="text-uppercase">Responsible Employees</small>
              </Typography>
            )}

            <div style={{ minHeight: "35vh" }}>
              <Card elevation={0}>
                {!!selectedActivity.assignments[0] && (
                  <List component="nav" dense>
                    {selectedActivity.assignments.map((assignment, index) => (
                      <React.Fragment key={assignment.id}>
                        <>
                          <ListItem
                            className=" pl-0"
                            secondaryAction={
                              <span>
                                <span className="badge badge-pill px-0">
                                  <small>{assignment.contractTypeName}</small>
                                </span>

                                {hasPermissions(["CAN_ASSIGN_ACTIVITY"]) &&
                                  subordinates.findIndex(
                                    (subordinate) =>
                                      subordinate.employeeId === assignment.id
                                  ) >= 0 && (
                                    <IconButton
                                      edge="end"
                                      color="error"
                                      aria-label="delete"
                                      onClick={() => {
                                        setAssignmentToBeRemoved(
                                          assignment.assignmentId
                                        );
                                        setConfirmRemoveAssignment(true);
                                      }}
                                    >
                                      <span className="material-icons">
                                        delete_forever
                                      </span>
                                    </IconButton>
                                  )}
                              </span>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{ border: "1px solid #d1cbcb" }}
                                alt={assignment.firstName}
                                src={
                                  assignment.profileImage
                                    ? `data:image/jpeg;base64,${assignment.profileImage}`
                                    : defaultProfile
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <span className="font-weight-bold text-primary">
                                  {assignment.lastName} {assignment.firstName}
                                </span>
                              }
                              secondary={
                                <>
                                  <small className="d-block">
                                    {" "}
                                    {!!assignment.isActing && (
                                      <span className="text-primary font-weight-bold">
                                        Ag.
                                      </span>
                                    )}{" "}
                                    {assignment.positionName}
                                  </small>
                                  <small className="d-block text-uppercase mb-1">
                                    {assignment.unitName}
                                  </small>
                                </>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      </React.Fragment>
                    ))}
                  </List>
                )}

                {!selectedActivity.assignments[0] && (
                  <div className="jumbotron mt-4 jumbotron-fluid text-center">
                    <div className="container">
                      <p className="lead">No one assigned</p>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            type="button"
            disabled={
              isEmpty(formData) || (loading && !confirmRemoveAssignment)
            }
            color="primary"
            variant="contained"
            onClick={onSave}
            className="px-5"
          >
            {loading && !confirmRemoveAssignment ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      {confirmRemoveAssignment && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAssignment}
          message={`Are you sure you want to remove this assignment?`}
          setConfirmationDialog={setConfirmRemoveAssignment}
          onYes={() => {
            removeActivityAssignment(
              {
                id: assignmentToBeRemoved,
              },
              setConfirmRemoveAssignment
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, subordinates, user }) => {
  return { loading, subordinates, user };
};
export default connect(mapStateToProps, {
  saveActivityAssignment,
  removeActivityAssignment,
  getSubordinates,
})(ActivityAssignmentForm);
