import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Button,
  Tabs,
  Tab,
  AlertTitle,
  Checkbox,
  TextField,
  Card,
  CardHeader,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import defaultPofile from "../../assets/default-profile.jpg";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  employmentCategories,
  expectedTrainingAwards,
  highestAcademicLevels,
  textCapitalized,
  trainingAreas,
  trainingCategories,
  trainingInitiators,
  requestStatusColor,
} from "../../common/components/Utils";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import ViewAttachmentTrainingRequestDialog from "./ViewAttachmentTrainingRequestDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  deleteTrainingRequest,
  sendToVerifierTrainingRequest,
} from "../../../store/training/actions";
import RejectTrainingRequestDialog from "./RejectTrainingRequestDialog";
import { a11yProps } from "../../common/components/TabPanel";
import { getHeadOfInstitution } from "../../../store/structure/actions";
import { getUserDetails } from "../../../store/profile/actions";

import PdfViewer from "../../common/components/PdfViewer";
import ViewTrainingRequestLogs from "./ViewTrainingRequestLogs";
import VerifyTrainingRequestDialog from "./VerifyTrainingRequestDialog";
import ApproveTrainingRequestDialog from "./ApproveTrainingRequestDialog";
import ReviewTrainingRequestDialog from "./ReviewTrainingRequestDialog";
import MifotraVerifyTrainingRequestDialog from "./MifotraVerifyTrainingRequestDialog";
import MifotraReviewTrainingRequestDialog from "./MifotraReviewTrainingRequestDialog";
import MifotraApproveTrainingDialog from "./MifotraApproveTrainingDialog";
import UpdateTrainingRequestDialog from "./UpdateTrainingRequestDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AuthorizationPermit from "./AuthorizationPermit";
import AuthorizationByMinistry from "./AuthorizationByMinistry";
import ApproveTrainingRequestByMinistryDialog from "./ApproveTrainingRequestByMinistryDialog";
import MifotraVerify2TrainingRequestDialog from "./MifotraVerify2TrainingRequestDialog";
import VerifyTrainingRequestByMinistryDialog from "./VerifyTrainingRequestByMinistryDialog";
import ReviewerTrainingRequestByMinistryDialog from "./ReviewerTrainingRequestByMinistryDialog";
import ReAttachTrainingRequestDialog from "./ReAttachTrainingRequestDialog";
import MifotraReview2TrainingRequestDialog from "./MifotraReview2TrainingRequestDialog";
import UserResume from "../../profile/UserResume";
import NotificationDayLeft from "./NotificationDayLeft";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const ViewTrainingRequestDialog = (props) => {
  const {
    user,
    showTrainingRequestDetails,
    setShowTrainingRequestDetails,
    trainingRequest,
    deleteTrainingRequest,
    sendToVerifierTrainingRequest,
    getUserDetails,
    loading,
  } = props;

  const [viewAuthLetter, setViewAuthLetter] = useState(false);
  const [viewAuthPermit, setViewAuthPermit] = useState(false);
  const [viewAuthPermit2, setViewAuthPermit2] = useState(false);
  const [showAuthorizationPermit, setShowAuthorizationPermit] = useState(false);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showAddAttachment, setShowAddAttachment] = useState(false);

  const [showUpdateTrainingRequest, setShowUpdateTrainingRequest] =
    useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowTrainingRequestDetails(false);
  };
  const { hasPermissions } = useRequiredAuth();
  const handleOpenCurrentProfile = () => {
    setShowApplicationCv(true);
  };
  return (
    <>
      {showUpdateTrainingRequest && (
        <UpdateTrainingRequestDialog
          showUpdateTrainingRequest={showUpdateTrainingRequest}
          setShowUpdateTrainingRequest={setShowUpdateTrainingRequest}
        />
      )}
      {viewAuthLetter && trainingRequest?.request && (
        <PreviewPdfUrl
          showDocument={viewAuthLetter}
          setShowDocument={setViewAuthLetter}
          document={{
            name: `View Training Authorization Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              trainingRequest?.request.docAuthLetterReferenceId,
          }}
        />
      )}

      {viewAuthPermit && trainingRequest?.request && (
        <PreviewPdfUrl
          showDocument={viewAuthPermit}
          setShowDocument={setViewAuthPermit}
          document={{
            name: `View Training Authorization Permit`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              trainingRequest?.request.docAuthPermitReferenceId,
          }}
        />
      )}
         {viewAuthPermit2 && trainingRequest?.request.lstDocAuthPermitReferenceId && (
        <PreviewPdfUrl
          showDocument={viewAuthPermit2}
          setShowDocument={setViewAuthPermit2}
          document={{
            name: `View Training Authorization Permit`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              trainingRequest?.request.lstDocAuthPermitReferenceId,
          }}
        />
      )}
      
      {showAddAttachment && trainingRequest?.request && (
        <ReAttachTrainingRequestDialog
          request={trainingRequest?.request}
          showAddAttachment={showAddAttachment}
          setShowAddAttachment={setShowAddAttachment}
          trainingFunds={trainingRequest.trainingFundProviders}
        />
      )}
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showTrainingRequestDetails}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>
                {trainingRequest?.request?.ministryEntityName && (
                  <>
                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="d-flex justify-content-start">
                          <small
                            className="text-dark fw-bold text-truncate "
                            style={{ fontSize: "14px" }}
                          >
                            Employee
                          </small>{" "}
                          <small
                            style={{
                              fontSize: "16px",
                              position: "relative",
                              bottom: 3,
                            }}
                            className="ml-2 mt-0"
                          >
                            @
                          </small>
                          <small
                            className="ml-1 fw-bold text-truncate text-info"
                            style={{ fontSize: "12px" }}
                          >
                            {textCapitalized(
                              trainingRequest?.request?.ministryEntityName
                            )}
                          </small>{" "}
                          {trainingRequest?.request && (
                            <span
                              style={{ fontSize: "12px" }}
                              className={`text ml-2 text-${requestStatusColor(
                                [
                                  {
                                    className: "success",
                                    statusIds: [
                                      trainingRequest?.status?.APPROVED,
                                    ],
                                  },
                                  {
                                    className: "danger",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MINISTRY,
                                      trainingRequest?.status?.REJECTED_BY_RDB,
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MINEDUC,
                                      trainingRequest?.status
                                        ?.REJECTED_BY_MIFOTRA,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.VERIFIED_SENT_TO_MINISTRY_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_RDB_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_MINEDUC_APPROVAL,
                                      trainingRequest?.status
                                        ?.PENDING_MIFOTRA_APPROVAL,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.PENDING_RDB_VERIFICATION,
                                      trainingRequest?.status
                                        ?.PENDING_MINEDUC_VERIFICATION,
                                      trainingRequest?.status
                                        ?.APPROVED_SENT_TO_MIFOTRA,
                                    ],
                                  },
                                  {
                                    className: "warning",
                                    statusIds: [
                                      trainingRequest?.status
                                        ?.PENDING_MINISTRY_VERIFIER,
                                      trainingRequest?.status
                                        ?.PENDING_RDB_REVIEW,
                                      trainingRequest?.status
                                        ?.PENDING_MINEDUC_REVIEW,
                                      trainingRequest?.status
                                        ?.PENDING_MIFOTRA_REVIEW,
                                      trainingRequest?.status
                                        ?.PENDING_MIFOTRA_REVIEW2,
                                    ],
                                  },
                                  {
                                    className: "primary",
                                    statusIds: [
                                      trainingRequest?.status?.NEW,
                                      trainingRequest?.status
                                        ?.SENT_TO_MINISTRY_VERIFIER,
                                    ],
                                  },
                                ],
                                trainingRequest?.request?.statusId
                              )}`}
                            >
                              ({trainingRequest?.request?.trainingStatusName})
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </h5>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col">
                  {!!trainingRequest?.request?.isTrainingDatesUnlocked && (
                    <>
                      {" "}
                      <strong className="badge badge-success mt-3 float-right">
                        Unlocked For 30 days
                      </strong>
                      {((!!trainingRequest?.request?.trainingUnlockedByNames &&
                        hasPermissions([
                          "IS_TRAINING_MIFOTRA_VIEWER",
                          "IS_TRAINING_MIFOTRA_VERIFIER",
                        ])) ||
                        (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                          !!trainingRequest?.request?.trainingUnlockedByNames &&
                          user?.selectedEntitySector?.id ===
                            "2000000000-010200000000000000-02")) && (
                        <p>
                          <small style={{ fontSize: "10px" }}>
                            By:
                            {
                              trainingRequest.request.trainingUnlockedByNames
                            }{" "}
                          </small>{" "}
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9">
              <Tabs
                className="mt-2 justify-content-end"
                value={tabValue}
                onChange={handleChange}
                aria-label="tabs"
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  className="text-capitalize"
                  label="Details"
                  {...a11yProps(0)}
                />
                <Tab
                  className="text-capitalize"
                  label="Attachments
             "
                  {...a11yProps(1)}
                />

                <Tab
                  className="text-capitalize"
                  label="Comments"
                  {...a11yProps(2)}
                />

                <Tab
                  className="text-capitalize"
                  label="CD Plan"
                  {...a11yProps(3)}
                />
              </Tabs>
            </div>
            <div className="col-lg-3">
              {trainingRequest?.request?.statusId ===
                trainingRequest?.status?.VERIFIED_SENT_TO_MINISTRY_APPROVAL ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.REJECTED_BY_MINISTRY ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.SENT_TO_MINISTRY_VERIFIER ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.NEW ? (
                <Button
                  style={{
                    ...InactiveButton,
                    float: "right",
                    background: "rgb(229, 246, 253)",
                    //  borderColor: "rgb(229, 246, 253)",
                  }}
                  size="md"
                  onClick={() => setShowAuthorizationPermit(true)}
                >
                  Draft of Authorization for training
                </Button>
              ) : (
                ""
              )}
              {trainingRequest?.request?.statusId ===
                trainingRequest?.status?.PENDING_MIFOTRA_REVIEW2 ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.PENDING_MIFOTRA_VERIFICATION ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.PENDING_MIFOTRA_APPROVAL ? (
                <Button
                  style={{
                    ...InactiveButton,
                    float: "right",
                    background: "rgb(229, 246, 253)",
                    //  borderColor: "rgb(229, 246, 253)",
                  }}
                  size="md"
                  onClick={() => setShowAuthorizationPermit(true)}
                >
                  Draft of Authorization for training
                </Button>
              ) : (
                ""
              )}

              {showAuthorizationPermit && (
                <Dialog
                  onClose={() => setShowAuthorizationPermit(false)}
                  aria-labelledby="customized-dialog-title"
                  open={showAuthorizationPermit}
                  fullWidth
                  maxWidth="md"
                >
                  <DialogTitle className="text-primary">
                    <div className="row">
                      <div className="col-10">
                        {trainingRequest?.request?.statusId ===
                          trainingRequest?.status
                            ?.VERIFIED_SENT_TO_MINISTRY_APPROVAL ||
                        trainingRequest?.request?.statusId ===
                          trainingRequest?.status?.REJECTED_BY_MINISTRY ||
                        trainingRequest?.request?.statusId ===
                          trainingRequest?.status?.SENT_TO_MINISTRY_VERIFIER ||
                        trainingRequest?.request?.statusId ===
                          trainingRequest?.status?.NEW ? (
                          <>Request the authorization to conduct a training</>
                        ) : (
                          <>Authorization for training</>
                        )}
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col">
                            <IconButton
                              aria-label="close"
                              onClick={() => setShowAuthorizationPermit(false)}
                              sx={{
                                position: "absolute",
                                right: 8,
                                top: 2,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <span className="material-icons">close</span>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </DialogTitle>
                  <DialogContent className="mt-0 mx-4" dividers>
                 

                    {trainingRequest?.request?.statusId ===
                      trainingRequest?.status
                        ?.VERIFIED_SENT_TO_MINISTRY_APPROVAL ||
                    trainingRequest?.request?.statusId ===
                      trainingRequest?.status?.REJECTED_BY_MINISTRY ||
                    trainingRequest?.request?.statusId ===
                      trainingRequest?.status?.PENDING_MINISTRY_VERIFIER ||
                    trainingRequest?.request?.statusId ===
                      trainingRequest?.status?.SENT_TO_MINISTRY_VERIFIER ||
                    trainingRequest?.request?.statusId ===
                      trainingRequest?.status?.NEW ? (
                      <>
                        {trainingRequest?.request?.employmentCategory ===
                        "Contractual staff" ? (
                          <>
                            <AuthorizationPermit
                              headOfInstitution={{
                                firstName:
                                  trainingRequest?.request
                                    ?.ministryApproverFirstName || "",
                                lastName:
                                  trainingRequest?.request
                                    ?.ministryApproverLastName || "",
                                title:
                                  trainingRequest?.request
                                    ?.ministryApproverTitle || "",
                                institutionName:
                                  trainingRequest?.request
                                    ?.ministryApproverInstitutionName || "",
                                positionName:
                                  trainingRequest?.request
                                    ?.ministryApproverPositionName || "",
                              }}
                              draftAuthorizationPermitContent={
                                trainingRequest?.request
                                  ?.draftAuthorizationPermitContent
                              }
                              draftAuthorizationLetterContent={
                                trainingRequest?.request
                                  ?.draftAuthorizationLetterContent
                              }
                              permitReferenceNo={
                                trainingRequest?.request?.permitReferenceNo
                              }
                              letterReferenceNo={
                                trainingRequest?.request?.letterReferenceNo
                              }
                              request={trainingRequest?.request}
                              coveredRelatedCostsDetails={
                                trainingRequest?.request
                                  ?.coveredRelatedCostsDetails
                              }
                              signContent={
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "15%",
                                        fontSize: "12px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span className="d-block text-danger">
                                        Not signed
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              }
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <AuthorizationByMinistry
                              headOfInstitution={{
                                firstName:
                                  trainingRequest?.request
                                    ?.ministryApproverFirstName,
                                lastName:
                                  trainingRequest?.request
                                    ?.ministryApproverLastName,
                                title:
                                  trainingRequest?.request
                                    ?.ministryApproverTitle,
                                institutionName:
                                  trainingRequest?.request
                                    ?.ministryApproverInstitutionName,
                                positionName:
                                  trainingRequest?.request
                                    ?.ministryApproverPositionName,
                                canAddInstitutionName:
                                  trainingRequest?.request
                                    ?.ministryApproverCanAddInstitutionName,
                              }}
                              minstryLetterReferenceNo={
                                trainingRequest?.request
                                  ?.minstryLetterReferenceNo
                              }
                              draftAuthorizationSubjectByMinistry={
                                trainingRequest?.request
                                  ?.draftAuthorizationSubjectByMinistry
                              }
                              draftAuthorizationContentByMinistry={
                                trainingRequest?.request
                                  ?.draftAuthorizationContentByMinistry
                              }
                              isMifotraEntitySector={
                                trainingRequest?.request
                                  ?.ministryEntitySectorId ===
                                "2000000000-010200000000000000-02"
                                  ? true
                                  : false
                              }
                              signContent={
                                <table>
                                  <tr>
                                    <td
                                      style={{
                                        width: "15%",
                                        fontSize: "12px",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span className="d-block text-danger">
                                        Not signed
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {/* {trainingRequest?.request
                                        ?.ministryDelegateApproverPositionName &&
                                        hasPermissions([
                                          "IS_TRAINING_MINISTRY_DELEGATED_APPROVER",
                                        ]) && (
                                          <>
                                            <p
                                              style={{
                                                fontWeight: 800,
                                                fontStyle: "italic",
                                                color: "blue",
                                              }}
                                            >
                                              For{" "}
                                              {trainingRequest?.request
                                                ?.ministryDelegateApproverFirstName ||
                                                ""}{" "}
                                              {trainingRequest?.request
                                                ?.ministryDelegateApproverLastName ||
                                                ""}
                                            </p>

                                            <p
                                              style={{
                                                fontWeight: 800,
                                                fontStyle: "italic",
                                                color: "blue",
                                              }}
                                            >
                                              {
                                                trainingRequest?.request
                                                  ?.ministryDelegateApproverPositionName
                                              }
                                            </p>
                                          </>
                                        )} */}
                                    </td>
                                  </tr>
                                </table>
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <AuthorizationPermit
                        headOfInstitution={{
                          firstName:
                            trainingRequest?.request?.mifotraApproverFirstName,
                          lastName:
                            trainingRequest?.request?.mifotraApproverLastName,
                          title: trainingRequest?.request?.mifotraApproverTitle,
                          institutionName:
                            trainingRequest?.request
                              ?.mifotraApproverInstitutionName,
                          positionName:
                            trainingRequest?.request
                              ?.mifotraApproverPositionName,

                          canAddInstitutionName:
                            trainingRequest?.request
                              ?.mifotraApproverCanAddInstitutionName,
                        }}
                        draftAuthorizationPermitContent={
                          trainingRequest?.request
                            ?.draftAuthorizationPermitContent
                        }
                        draftAuthorizationLetterContent={
                          trainingRequest?.request
                            ?.draftAuthorizationLetterContent
                        }
                        permitReferenceNo={
                          trainingRequest?.request?.permitReferenceNo
                        }
                        letterReferenceNo={
                          trainingRequest?.request?.letterReferenceNo
                        }
                        request={trainingRequest?.request}
                        coveredRelatedCostsDetails={
                          trainingRequest?.request?.coveredRelatedCostsDetails
                        }
                        signContent={
                          <table>
                            <tr>
                              <td
                                style={{
                                  width: "15%",
                                  fontSize: "12px",
                                  textAlign: "left",
                                }}
                              >
                                <span className="d-block text-danger">
                                  Not signed
                                </span>
                              </td>
                            </tr>
                          </table>
                        }
                      />
                    )}
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </div>
          { ![17,4,8,12,16,21].includes(trainingRequest?.request?.statusId) &&   <NotificationDayLeft request={trainingRequest?.request || null} className="mt-2" />}
        
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
        
          {tabValue === 0 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <div className="card service-card valid mb-2 mt-1">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      I. Identification
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                          <div className="row mt-0">
                            <div className="col-3 justify-content-center text-uppercase text-center">
                              <div
                                className="card mb-4 pb-2"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-center">
                                  <div
                                    style={{
                                      height: "auto",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <>
                                      {trainingRequest?.profilePicture && (
                                        <img
                                          className="mt-0 rounded  text-center"
                                          src={
                                            "data:image/jpg;base64," +
                                            trainingRequest?.profilePicture
                                          }
                                          style={{
                                            border: "1px solid #a6d3df",
                                            height: "128px",
                                            // width: "130px",
                                            overflow: "hidden",
                                            // borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                      {!trainingRequest?.profilePicture && (
                                        <img
                                          className="mt-0  rounded text-center"
                                          src={defaultPofile}
                                          style={{
                                            // border: "1px solid #a6d3df",
                                            height: "128px",
                                            width: "100px",
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </>
                                  </div>

                                  {trainingRequest?.request?.isAvailable && (
                                    <span
                                      className="text-success font-weight-bolder"
                                      style={{
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    >
                                      <span
                                        className="material-icons d-block"
                                        style={{ fontSize: "48px" }}
                                      >
                                        check_circle_outline
                                      </span>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="mt-n1 d-block"
                                      >
                                        Available
                                      </span>
                                    </span>
                                  )}
                                  {!trainingRequest?.request?.isAvailable && (
                                    <>
                                      <span
                                        className="text-danger font-weight-bolder"
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                        }}
                                      >
                                        <span
                                          className="material-icons d-block"
                                          style={{ fontSize: "48px" }}
                                        >
                                          highlight_off
                                        </span>
                                        <span
                                          style={{ fontSize: "12px" }}
                                          className="mt-n1 d-block"
                                        >
                                          {trainingRequest?.request
                                            ?.employeeStatusId === 3 ? (
                                            <>Suspended</>
                                          ) : (
                                            <>Exited</>
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <div
                                className="card"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-left">
                                  <div className="row">
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              Employee Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.employeeId
                                                }{" "}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Names:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {
                                                  trainingRequest?.request
                                                    ?.employeeTitle
                                                }
                                                .
                                                <span className="ml-1">
                                                  {textCapitalized(
                                                    trainingRequest?.request
                                                      ?.lastName
                                                  )}{" "}
                                                  {textCapitalized(
                                                    trainingRequest?.request
                                                      ?.firstName
                                                  )}
                                                </span>
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              DOB:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {moment(
                                                  trainingRequest?.request
                                                    ?.dateOfBirth
                                                ).format("ll")}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              National Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.idNumber
                                                }
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Gender:{" "}
                                              <span className="contact_span">
                                                {trainingRequest?.request
                                                  ?.gender === "M"
                                                  ? "Male"
                                                  : "Female"}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Civil Status:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.civilStatus
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              PhoneNumber:{" "}
                                              <span className="contact_span">
                                                {
                                                  trainingRequest?.request
                                                    ?.phoneNumber
                                                }{" "}
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              Personal Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  trainingRequest?.request
                                                    ?.email
                                                }
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Other Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  trainingRequest?.request
                                                    ?.professionalEmail
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Card
                                className={`rounded hoverable-card mb-2 mt-2 py-2`}
                                variant="outlined"
                                sx={{
                                  backgroundColor: `#cdeef8`,
                                  border: "none",
                                }}
                              >
                                <CardHeader
                                  className="pb-0 pt-0"
                                  avatar={
                                    <Tooltip
                                      title={
                                        <>
                                          {trainingRequest?.request
                                            ?.employeeTitle
                                            ? " ." +
                                              trainingRequest?.request
                                                ?.employeeTitle
                                            : ""}
                                          <span className="ml-1">
                                            {textCapitalized(
                                              trainingRequest?.request?.lastName
                                            )}
                                            {textCapitalized(
                                              trainingRequest?.request
                                                ?.firstName
                                            )}
                                          </span>
                                        </>
                                      }
                                    >
                                      <Box
                                        sx={{
                                          position: "relative",
                                          display: "inline-flex",
                                          cursor: "default",
                                        }}
                                      >
                                        <span className="material-icons text-primary mr-1">
                                          info
                                        </span>
                                      </Box>
                                    </Tooltip>
                                  }
                                  title={
                                    <span className="d-flex align-items-center flex-wrap justify-content-between ">
                                      <span className="d-flex align-items-center flex-wrap">
                                        <span className="">
                                          <span className="d-flex align-items-center  flex-wrap">
                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-primary mr-1`}
                                              >
                                                {trainingRequest?.request
                                                  ?.totalRequests || 0}
                                              </span>
                                              All requests
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-warning mr-1`}
                                              >
                                                {trainingRequest?.request
                                                  ?.totalOngoing || 0}
                                              </span>
                                              Ongoing
                                            </span>
                                            <span
                                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-success mr-1`}
                                              >
                                                {trainingRequest?.request
                                                  ?.totalApproved || 0}
                                              </span>
                                              Approved
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                // borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-danger mr-1`}
                                              >
                                                {trainingRequest?.request
                                                  ?.totalRejected || 0}
                                              </span>{" "}
                                              Rejected
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  }
                                />
                              </Card>
                            </div>
                          </div>
                          {trainingRequest?.request?.hasPendingExitRequest && (
                            <ErrorMessage
                              hasError={true}
                              message={<>Employee has pending exist request!</>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {trainingRequest?.request?.lstDocAuthPermitReferenceId && (
                    <div className="card service-card valid mb-2 mt-3">
                      <div
                        className="card-header"
                        style={{
                          padding: "0.25rem 1.2rem",

                          backgroundColor: "#28a745",
                          color: "#fff",
                          borderBottom: "1px solid #28a745",
                        }}
                      >
                        Last Training Attended
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-8">
                            {trainingRequest?.request?.lstTrainingTitle || ""}
                          </div>
                          <div className="col-lg-4">
                            <button
                              type="button"
                              onClick={() => setViewAuthPermit2(true)}
                              className="btn btn-sm my-2 btn-success float-right"
                            >
                              <PreviewIcon className="text-white mr-1" />
                              View Authorization Permit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      II. Employment Information
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employer (Institution)
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.entityName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Job position
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.positionName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employment category
                            </legend>
                            <div className="form-group1 mb-0">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="employmentCategory-label"
                                  name="employmentCategory"
                                  value={
                                    trainingRequest?.request
                                      ?.employmentCategory || ""
                                  }
                                >
                                  {employmentCategories.map(
                                    (employmentCategory, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          disableTypography={true}
                                          readOnly={true}
                                          value={employmentCategory}
                                          name="employmentCategory"
                                          label={employmentCategory}
                                          control={<Radio size="small" />}
                                          checked={
                                            employmentCategory ===
                                            trainingRequest?.request
                                              ?.employmentCategory
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      III. Educational Background
                    </div>
                    <div className="card-body">
                      <fieldset className="form-group border p-2">
                        <legend
                          className="w-auto px-2"
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontFamily:
                              '"Roboto","Helvetica","Arial",sans-serif',
                            fontWeight: 400,
                            fontSize: "0.82rem",
                            lineHeight: "1em",
                            letterSpacing: "0.00938em",
                          }}
                        >
                          Highest academic level of the public servant
                        </legend>
                        <div className="form-group1 mb-0">
                          <div className="row">
                            <div className="col-9">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="highestAcademicLevel-label"
                                  name="highestAcademicLevel"
                                  value={
                                    trainingRequest?.request
                                      ?.highestAcademicLevel || ""
                                  }
                                >
                                  {highestAcademicLevels.map(
                                    (highestAcademicLevel, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          style={{ cursor: "none" }}
                                          value={highestAcademicLevel}
                                          readOnly={true}
                                          name="highestAcademicLevel"
                                          control={<Radio size="small" />}
                                          checked={
                                            highestAcademicLevel ===
                                            trainingRequest?.request
                                              ?.highestAcademicLevel
                                              ? true
                                              : false
                                          }
                                          label={highestAcademicLevel}
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div className="col-3">
                              {showApplicationCv && (
                                <UserResume
                                  showProfileDialog={showApplicationCv}
                                  setShowProfileDialog={setShowApplicationCv}
                                />
                              )}

                              <button
                                type="button"
                                disabled={loading}
                                onClick={() =>
                                  getUserDetails(
                                    trainingRequest?.request?.employeeId,
                                    handleOpenCurrentProfile
                                  )
                                }
                                className="btn btn-sm my-0 btn-secondary float-right"
                              >
                                <PreviewIcon className="text-white mr-1" />
                                {loading ? "Wait...." : "Currently Profile"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      IV. Training details
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Title of the training
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {trainingRequest?.request?.trainingTitle}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Areas
                            </legend>
                            <div className="form-group1 mb-0 overflow-hidden">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="trainingArea-label"
                                      name="trainingArea"
                                      value={
                                        trainingRequest?.request
                                          ?.trainingAreas || ""
                                      }
                                    >
                                      {trainingAreas.map(
                                        (trainingArea, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={trainingArea}
                                              name="trainingArea"
                                              label={trainingArea}
                                              readOnly={true}
                                              control={<Radio size="small" />}
                                              checked={
                                                trainingRequest?.request
                                                  ?.trainingAreas ===
                                                trainingArea
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                              {trainingRequest?.request?.trainingAreas &&
                                trainingRequest?.request?.trainingAreas ===
                                  "Other" && (
                                  <fieldset className="form-group border p-2 mt-2">
                                    <legend
                                      className="w-auto px-2"
                                      style={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        fontFamily:
                                          '"Roboto","Helvetica","Arial",sans-serif',
                                        fontWeight: 400,
                                        fontSize: "0.82rem",
                                        // lineHeight: "1em",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      If other, specify
                                    </legend>
                                    <div className="form-group1 mb-0">
                                      {
                                        trainingRequest?.request
                                          ?.otherTrainingAreas
                                      }
                                    </div>
                                  </fieldset>
                                )}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training category
                            </legend>
                            <div className="form-group1">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="trainingCategory-label"
                                  name="trainingCategory"
                                  value={
                                    trainingRequest?.request
                                      ?.trainingCategory || ""
                                  }
                                >
                                  {trainingCategories.map(
                                    (trainingCategory, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          value={trainingCategory.name}
                                          name="trainingCategory"
                                          label={trainingCategory.name}
                                          readOnly={true}
                                          control={<Radio size="small" />}
                                          checked={
                                            trainingCategory.name ===
                                            trainingRequest?.request
                                              ?.trainingCategory
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              The training location
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-12 mt-1">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingInsideCountry-label"
                                      name="isTrainingInsideCountry"
                                    >
                                      <FormControlLabel
                                        value="In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          trainingRequest?.request
                                            ?.isTrainingInsideCountry
                                        }
                                      />
                                      <FormControlLabel
                                        value="Not In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="Not In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          !trainingRequest?.request
                                            ?.isTrainingInsideCountry
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training duration
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    From:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.trainingDurationFrom
                                        ? moment(
                                            trainingRequest?.request
                                              ?.trainingDurationFrom
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    To:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.trainingDurationTo
                                        ? moment(
                                            trainingRequest?.request
                                              ?.trainingDurationTo
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Departure date:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request?.departureDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.departureDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Returning date:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request?.returningDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.returningDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Booking Flight Date
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Departure:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.bookingFlightDepartureDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.bookingFlightDepartureDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Arrival:{" "}
                                    <span className="contact_span float-right">
                                      {trainingRequest?.request
                                        ?.bookingFlightArrivalDate
                                        ? moment(
                                            trainingRequest?.request
                                              ?.bookingFlightArrivalDate
                                          ).format("ll")
                                        : "(Local)"}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training place
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Country:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.countryForTraining
                                      }
                                    </span>
                                  </p>
                                </div>
                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Town:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.townForTraining
                                      }
                                    </span>
                                  </p>
                                </div>

                                <div className="col-lg-12 mt-1">
                                  <p className="contact_p">
                                    {" "}
                                    Institute/University:{" "}
                                    <span className="contact_span float-right">
                                      {
                                        trainingRequest?.request
                                          ?.universityForTraining
                                      }
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Funds Provider
                            </legend>
                            {!!trainingRequest?.trainingFundProviders
                              .length && (
                              <div className="form-group1 mb-0 overflow-hidden">
                                <div className="row">
                                  <div className="col-12">
                                    <FormControl className="w-100">
                                      <RadioGroup
                                        aria-labelledby="trainingFundsProvider-label"
                                        name="trainingFundsProvider"
                                      >
                                        {trainingRequest?.trainingFundProviders.map(
                                          (trainingFund, index) => (
                                            <div key={index}>
                                              <FormControlLabel
                                                className="w-100"
                                                value={trainingFund.provider}
                                                name="trainingFund"
                                                label={trainingFund.provider}
                                                readOnly={true}
                                                control={
                                                  <Checkbox size="small" />
                                                }
                                                checked={true}
                                              />
                                              {trainingFund.provider ===
                                                "Government" && (
                                                <div className="w-100">
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If Government, specify the
                                                      institution
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}

                                              {trainingFund.provider ===
                                                "Development Partner" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If development partner,
                                                      specify
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}
                                              {trainingFund.provider ===
                                                "Other" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If other, specify
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.name}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {trainingFund.name} cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}

                                              {trainingFund.provider ===
                                                "Self-Sponsored" && (
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will Self-Sponsored
                                                      cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2 ml-3">
                                                      {trainingFund.description}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            )}
                          </fieldset>
                          {trainingRequest?.request
                            ?.coveredRelatedCostsDetails && (
                            <fieldset className="form-group border p-2">
                              <legend
                                className="w-auto px-2"
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                What will training funds Provider cover?
                              </legend>
                              <div className="form-group1 mb-0">
                                {
                                  trainingRequest?.request
                                    ?.coveredRelatedCostsDetails
                                }
                              </div>
                            </fieldset>
                          )}
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Expected training award
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="expectedTrainingAward-label"
                                      name="expectedTrainingAward"
                                      value={
                                        trainingRequest?.request
                                          ?.expectedTrainingAward || ""
                                      }
                                    >
                                      {expectedTrainingAwards.map(
                                        (expectedTrainingAward, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={expectedTrainingAward}
                                              name="expectedTrainingAward"
                                              label={expectedTrainingAward}
                                              readOnly={true}
                                              control={<Radio size="small" />}
                                              checked={
                                                expectedTrainingAward ===
                                                trainingRequest?.request
                                                  ?.expectedTrainingAward
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Is the training captured in the capacity
                              development plan of the institution?
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingHasCdPlan-label"
                                      name="isTrainingHasCdPlan"
                                      value={
                                        trainingRequest?.request
                                          ?.isTrainingHasCdPlan || ""
                                      }
                                    >
                                      {["Yes", "No"].map(
                                        (isTrainingHasCdPlan, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={isTrainingHasCdPlan}
                                              name="isTrainingHasCdPlan"
                                              label={isTrainingHasCdPlan}
                                              control={<Radio size="small" />}
                                              checked={
                                                isTrainingHasCdPlan ===
                                                trainingRequest?.request
                                                  ?.isTrainingHasCdPlan
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          {trainingRequest?.request?.isTrainingHasCdPlan &&
                            trainingRequest?.request?.isTrainingHasCdPlan ===
                              "No" && (
                              <fieldset className="form-group border p-2">
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  If no, who initiated the training?
                                </legend>
                                <div className="form-group1 mb-2 overflow-hidden">
                                  <div className="row">
                                    <div className="col-12">
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="trainingInitiator-label"
                                          name="trainingInitiator"
                                          value={
                                            trainingRequest?.request
                                              ?.trainingInitiator || ""
                                          }
                                        >
                                          {trainingInitiators.map(
                                            (trainingInitiator, index) => (
                                              <div key={index}>
                                                <FormControlLabel
                                                  value={trainingInitiator}
                                                  name="trainingInitiator"
                                                  label={trainingInitiator}
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  checked={
                                                    trainingInitiator ===
                                                    trainingRequest?.request
                                                      ?.trainingInitiator
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>
                                  {trainingRequest?.request
                                    ?.trainingInitiator &&
                                    trainingRequest?.request
                                      ?.trainingInitiator === "Other" && (
                                      <fieldset className="form-group border p-2 mt-2">
                                        <legend
                                          className="w-auto px-2"
                                          style={{
                                            color: "rgba(0, 0, 0, 0.6)",
                                            fontFamily:
                                              '"Roboto","Helvetica","Arial",sans-serif',
                                            fontWeight: 400,
                                            fontSize: "0.82rem",
                                            lineHeight: "1em",
                                            letterSpacing: "0.00938em",
                                          }}
                                        >
                                          If other, specify
                                        </legend>
                                        <div className="form-group1 mb-0">
                                          {
                                            trainingRequest?.request
                                              ?.otherTrainingInitiator
                                          }
                                        </div>
                                      </fieldset>
                                    )}
                                </div>
                              </fieldset>
                            )}
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              What gaps were identified to be addressed by the
                              training
                            </legend>
                            <div className="form-group1 mb-0">
                              {trainingRequest?.request?.trainingGapsIdentified}
                            </div>
                          </fieldset>

                          {/* {!!trainingRequest?.approvals.length && (
                            <ViewTrainingRequestApprovals
                              approvals={trainingRequest.approvals}
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </React.Fragment>
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  {!trainingRequest.trainingRequestAttachments.length && (
                    <>
                      <Alert severity="error">
                        <AlertTitle>
                          No Attached documents, please click on button below to
                          add required files.
                        </AlertTitle>
                      </Alert>
                      <Button
                        className="mt-2"
                        onClick={() => setShowAddAttachment(true)}
                        style={{
                          ...ActiveButton,
                          float: "right",
                          background: "rgb(7, 142, 206)",
                          borderColor: "rgb(7, 142, 206)",
                        }}
                        size="md"
                      >
                        Attach document
                      </Button>
                    </>
                  )}
                  {!!trainingRequest.trainingRequestAttachments.length && (
                    <>
                      <ViewAttachmentTrainingRequestDialog
                        canEdit={false}
                        attachments={trainingRequest.trainingRequestAttachments}
                      />
                    </>
                  )}
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {tabValue === 2 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewTrainingRequestLogs logs={trainingRequest.logs} />
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {tabValue === 3 && (
            <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  {trainingRequest?.cdPlan ? (
                    <div
                      className="card service-card mb-3 mt-0"
                      style={{ borderColor: "#e0edf9!important" }}
                    >
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem", width: "100%" }}
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <AttachFileIcon />
                            View cd plan for fiscal year of
                            {trainingRequest.request.fiscalYearId}
                          </div>
                        </div>
                      </div>
                      <div>
                        <PdfViewer
                          url={
                            process.env.REACT_APP_FILES_URL +
                            "/preview/" +
                            trainingRequest.request.documentReferenceId
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <Alert severity="info">
                      <AlertTitle>
                        This training is not in the CD Plan of the institution.
                      </AlertTitle>
                    </Alert>
                  )}
                </Typography>
              </React.Fragment>
            </Box>
          )}
        </DialogContent>
        <DialogActions className="py-3 mx-4 d-flex justify-content-center">
          {hasPermissions(["IS_HR", "IS_TRAINING_INITIATOR"]) &&
            trainingRequest?.request &&
            (trainingRequest?.request?.statusId ===
              trainingRequest?.status?.SENT_TO_MINISTRY_VERIFIER ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.PENDING_MINISTRY_VERIFIER ||
              trainingRequest?.request?.statusId ===
                trainingRequest?.status?.VERIFIED_SENT_TO_MINISTRY_APPROVAL ||
              (trainingRequest?.request?.statusId ===
                trainingRequest?.status?.PENDING_MIFOTRA_REVIEW &&
                trainingRequest?.request?.ministryEntitySectorId ===
                  user.selectedEntitySector.id)) && (
              <GeneratorButton
                setShowUpdateTrainingRequest={setShowUpdateTrainingRequest}
                request={trainingRequest?.request}
                deleteTrainingRequest={deleteTrainingRequest}
                sendToVerifierTrainingRequest={sendToVerifierTrainingRequest}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions([
            "IS_TRAINING_MIFOTRA_EDITOR",
            "IS_TRAINING_MIFOTRA_REVIEWER",
          ]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId !==
              trainingRequest?.status?.APPROVED && (
              <GeneratorButton
                setShowUpdateTrainingRequest={setShowUpdateTrainingRequest}
                request={trainingRequest?.request}
                deleteTrainingRequest={deleteTrainingRequest}
                sendToVerifierTrainingRequest={sendToVerifierTrainingRequest}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}

          {hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.VERIFIED_SENT_TO_MINISTRY_APPROVAL &&
            trainingRequest?.request?.ministryEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MinistryLevelApprovalButton
                status={trainingRequest?.status}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MINISTRY_VERIFIER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.SENT_TO_MINISTRY_VERIFIER &&
            trainingRequest?.request?.ministryEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MinistryLevelVerifierButton
                status={trainingRequest?.status}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MINISTRY_REVIEWER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MINISTRY_VERIFIER &&
            trainingRequest?.request?.ministryEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MinistryLevelReviewButton
                status={trainingRequest?.status}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}

          {hasPermissions(["IS_RDB_TRAINING_APPROVER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_RDB_APPROVAL &&
            trainingRequest?.request?.rdbEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <RDBLevelApprovalButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_RDB_VERIFIER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_RDB_VERIFICATION &&
            trainingRequest?.request?.rdbEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <RDBLevelVerifierButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_RDB_REVIEWER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_RDB_REVIEW &&
            trainingRequest?.request?.rdbEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <RDBLevelReviewerButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions([
            "IS_HEAD_OF_INSTITUTION",
            "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
          ]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MIFOTRA_APPROVAL &&
            trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MIFOTRALevelApprovalButton
                headOfInstitution={{
                  firstName: trainingRequest?.request?.mifotraApproverFirstName,
                  lastName: trainingRequest?.request?.mifotraApproverLastName,
                  institutionName:
                    trainingRequest?.request?.mifotraApproverInstitutionName,
                  positionName:
                    trainingRequest?.request?.mifotraApproverPositionName,
                }}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MIFOTRA_VERIFIER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MIFOTRA_VERIFICATION &&
            trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MIFOTRALevelVerifierButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MIFOTRA_VIEWER"]) &&
            trainingRequest?.request &&
            (trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MIFOTRA_VERIFICATION ||
              trainingRequest?.request?.statusId ===20) &&
            trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MIFOTRALevelVerifier2Button
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}

          {hasPermissions(["IS_TRAINING_MIFOTRA_REVIEWER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MIFOTRA_REVIEW &&
            trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MIFOTRALevelReviewerButton
                trainingFundProviders={trainingRequest?.trainingFundProviders}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}

          {hasPermissions(["IS_TRAINING_MIFOTRA_REVIEWER2"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MIFOTRA_REVIEW2 &&
            trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MIFOTRALevelReviewerButton2
                trainingFundProviders={trainingRequest?.trainingFundProviders}
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}

          {hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MINEDUC_APPROVAL &&
            trainingRequest?.request?.mineducEntitySectorId ===
              user?.selectedEntitySector?.id && (
              <MINEDUCLevelApprovalButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MINEDUC_VERIFIER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MINEDUC_VERIFICATION &&
            (trainingRequest?.request?.mineducEntitySectorId ===
              user?.selectedEntitySector?.id ||
              trainingRequest?.request?.hecEntitySectorId ===
                user?.selectedEntitySector?.id) && (
              <MINEDUCLevelVerifierButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions(["IS_TRAINING_MINEDUC_REVIEWER"]) &&
            trainingRequest?.request &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.PENDING_MINEDUC_REVIEW &&
            (trainingRequest?.request?.mineducEntitySectorId ===
              user?.selectedEntitySector?.id ||
              trainingRequest?.request?.hecEntitySectorId ===
                user?.selectedEntitySector?.id) && (
              <MINEDUCLevelReviewerButton
                request={trainingRequest?.request}
                setShowTrainingRequestDetails={setShowTrainingRequestDetails}
              />
            )}
          {hasPermissions([
            "IS_TRAINING_MINEDUC_VERIFIER",
            "IS_HEAD_OF_INSTITUTION",
            "IS_TRAINING_MIFOTRA_VERIFIER",
            "IS_TRAINING_RDB_VERIFIER",
            "IS_RDB_TRAINING_APPROVER",
            "IS_TRAINING_MINISTRY_VERIFIER",
            "IS_TRAINING_MINEDUC_REVIEWER",
            "IS_TRAINING_MIFOTRA_REVIEWER",
            "IS_TRAINING_MIFOTRA_REVIEWER2",
            "IS_TRAINING_RDB_REVIEWER",
            "IS_HR",
            "IS_TRAINING_INITIATOR",
            "IS_TRAINING_PM_OVERSIGHT",
            "IS_TRAINING_MIFOTRA_VIEWER",
            "IS_TRAINING_MIFOTRA_OVERSIGHT",
          ]) &&
            trainingRequest?.request &&
            trainingRequest?.request.docAuthPermitReferenceId &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.APPROVED && (
              <button
                type="button"
                onClick={() => setViewAuthPermit(true)}
                className="btn btn-sm my-2 btn-primary"
              >
                <PreviewIcon className="text-white mr-1" />
                View Training Authorization Permit
              </button>
            )}

          {hasPermissions([
            "IS_HEAD_OF_INSTITUTION",
            "IS_TRAINING_MIFOTRA_VERIFIER",
            "IS_TRAINING_MIFOTRA_REVIEWER",
            "IS_TRAINING_PM_OVERSIGHT",
            "IS_TRAINING_MIFOTRA_VIEWER",
            "IS_TRAINING_MIFOTRA_OVERSIGHT",
            "IS_TRAINING_MIFOTRA_REVIEWER2",
          ]) &&
            trainingRequest?.request &&
            trainingRequest?.request.docAuthLetterReferenceId &&
            (trainingRequest?.request?.mifotraEntitySectorId ===
              user?.selectedEntitySector?.id ||
              user?.selectedEntitySector?.id ===
                "0400000000-010000000000000000-01" ||
              user?.selectedEntitySector?.id ===
                "2000000000-010200000000000000-02") &&
            trainingRequest?.request?.statusId ===
              trainingRequest?.status?.APPROVED && (
              <button
                type="button"
                onClick={() => setViewAuthLetter(true)}
                className="btn btn-sm my-2 btn-success"
              >
                <PreviewIcon className="text-white mr-1" />
                View Training Authorization Letter
              </button>
            )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  trainingRequest,
  headOfInstitution,
}) => {
  return {
    user,
    loading,
    trainingRequest,
    headOfInstitution,
  };
};
export default connect(mapStateToProps, {
  deleteTrainingRequest,
  sendToVerifierTrainingRequest,
  getHeadOfInstitution,
  getUserDetails,
})(ViewTrainingRequestDialog);

const GeneratorButton = (props) => {
  const {
    user,
    loading,
    request,
    deleteTrainingRequest,
    setShowTrainingRequestDetails,
    sendToVerifierTrainingRequest,
    setShowUpdateTrainingRequest,
  } = props;
  const [confirmDeleteRequest, setConfirmDeleteRequest] = useState(false);
  const [sendToVerifierRequest, setSendToVerifierRequest] = useState(false);
  const [reason, setReason] = useState("");
  return (
    <>
      {confirmDeleteRequest && (
        <ConfirmationDialog
          confirmationDialog={confirmDeleteRequest}
          message={`Are you sure you want to delete this request?`}
          setConfirmationDialog={setConfirmDeleteRequest}
          onYes={() => {
            deleteTrainingRequest(
              request,
              setConfirmDeleteRequest,
              setShowTrainingRequestDetails
            );
          }}
        />
      )}

      {sendToVerifierRequest && (
        <ConfirmationDialog
          confirmationDialog={sendToVerifierRequest}
          disabled={!reason}
          message={
            <>
              Are sure you want to send this request to{" "}
              <span className="text-primary"> MIFOTRA Verifier </span>? <br />
              <form>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="Reason"
                  label="Type your reason .."
                  variant="outlined"
                  placeholder="Type your reason  .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setSendToVerifierRequest}
          onYes={() => {
            sendToVerifierTrainingRequest(
              request,
              reason,
              setSendToVerifierRequest,
              setShowTrainingRequestDetails
            );
          }}
        />
      )}

      {/* <Button
        disabled={loading}
        onClick={() => setSendToVerifierRequest(true)}
        style={{
          ...ActiveButton,
          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
          // float: "right",
          background: "#14a44d",
          borderColor: "#14a44d",
        }}
        size="md"
      >
        Confirm
      </Button> */}
      {request.statusId === 19 && (
        <Button
          onClick={() => setSendToVerifierRequest(true)}
          style={{
            ...ActiveButton,
            background: "#14a44d",
            borderColor: "#14a44d",
          }}
          size="md"
        >
          Send to Verifier
        </Button>
      )}

      <Button
        onClick={() => setShowUpdateTrainingRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        Edit
      </Button>

      <Button
        disabled={loading}
        onClick={() => setConfirmDeleteRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Delete
      </Button>
    </>
  );
};

const MinistryLevelApprovalButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showSignTrainingDialog, setShowSignTrainingDialog] = useState(false);
  const [userSignature, setUserSignature] = useState(null);
  const [isGettingSignature, setIsGettingSignature] = useState(false);
  const [signingUser, setSigningUser] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);

  const getUserSignature = async (setShowSignTrainingDialog, setIsWaiting) => {
    try {
      setIsGettingSignature(true);
      setIsWaiting(true);
      const { data } = await axios.get("/api/auth/user-signatures");
      setIsWaiting(false);
      setUserSignature(data);
      setShowSignTrainingDialog(true);
      setIsGettingSignature(false);
    } catch (error) {
      setIsWaiting(false);
      setShowSignTrainingDialog(true);
      setIsGettingSignature(false);
    }
  };
  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"ministry"}
          rejectedBy={request.ministryEntityName}
        />
      )}
      {showApprovalModal && (
        <ApproveTrainingRequestByMinistryDialog
          showApprovalModal={showApprovalModal}
          setShowApprovalModal={setShowApprovalModal}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          signingUser={signingUser}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
        />
      )}
      <Button
        disabled={loading || isGettingSignature || isWaiting}
        onClick={() => getUserSignature(setShowApprovalModal, setIsWaiting)}
        style={{
          ...ActiveButton,
          background: "#14a44d",
          borderColor: "#14a44d",
        }}
        size="md"
      >
        {isWaiting ? "Wait.." : "Approve"}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const RDBLevelApprovalButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}
      {showApprovalModal && (
        <ApproveTrainingRequestDialog
          showApprovalModal={showApprovalModal}
          setShowApprovalModal={setShowApprovalModal}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowApprovalModal(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        Approve
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const MinistryLevelReviewButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails, status } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"ministry"}
        />
      )}
      {showVerifyRequest && (
        <ReviewerTrainingRequestByMinistryDialog
          status={status}
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          isMifotraEntitySector={
            request?.ministryEntitySectorId ===
            "2000000000-010200000000000000-02"
              ? true
              : false
          }
          level={"ministry"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Confirm"}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const MinistryLevelVerifierButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails, status } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"ministry"}
          rejectedBy={request.rdbEntityName}
        />
      )}
      {showVerifyRequest && (
        <VerifyTrainingRequestByMinistryDialog
          status={status}
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"ministry"}
          isMifotraEntitySector={
            request?.ministryEntitySectorId ===
            "2000000000-010200000000000000-02"
              ? true
              : false
          }
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,

          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Confirm"}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};
const RDBLevelVerifierButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}
      {showVerifyRequest && (
        <VerifyTrainingRequestDialog
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,

          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Confirm"}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const RDBLevelReviewerButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showReviewRequest, setShowReviewRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}
      {showReviewRequest && (
        <ReviewTrainingRequestDialog
          showReviewRequest={showReviewRequest}
          setShowReviewRequest={setShowReviewRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"rdb"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowReviewRequest(true)}
        style={{
          ...ActiveButton,

          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        Confirm
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const MINEDUCLevelReviewerButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showReviewRequest, setShowReviewRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
        />
      )}
      {showReviewRequest && (
        <ReviewTrainingRequestDialog
          showReviewRequest={showReviewRequest}
          setShowReviewRequest={setShowReviewRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowReviewRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        Confirm
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};
const MIFOTRALevelReviewerButton = (props) => {
  const {
    loading,
    request,
    setShowTrainingRequestDetails,
    trainingFundProviders,
  } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showReviewRequest, setShowReviewRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}
      {showReviewRequest && (
        <MifotraReviewTrainingRequestDialog
          showReviewRequest={showReviewRequest}
          setShowReviewRequest={setShowReviewRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          trainingFundProviders={trainingFundProviders}
          request={request}
          level={
            request.requestType === "LongTerm"
              ? !!request.rdbEntitySectorId
                ? "mifotra-review2"
                : "mineduc-review"
              : "mifotra-review2"
          }
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowReviewRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Review Now .."}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};
const MIFOTRALevelReviewerButton2 = (props) => {
  const {
    loading,
    request,
    setShowTrainingRequestDetails,
    trainingFundProviders,
  } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showReviewRequest, setShowReviewRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}
      {showReviewRequest && (
        <MifotraReview2TrainingRequestDialog
          showReviewRequest={showReviewRequest}
          setShowReviewRequest={setShowReviewRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          trainingFundProviders={trainingFundProviders}
          request={request}
          level={"mifotra-verifer"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowReviewRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Review Now .."}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const MINEDUCLevelVerifierButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
        />
      )}
      {showVerifyRequest && (
        <VerifyTrainingRequestDialog
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
        />
      )}
      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Confirm"}
      </Button>

      {/* <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
          // float: "right",
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button> */}
    </>
  );
};

const MIFOTRALevelApprovalButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails, headOfInstitution } =
    props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showSignTrainingDialog, setShowSignTrainingDialog] = useState(false);
  const [userSignature, setUserSignature] = useState(null);
  const [isGettingSignature, setIsGettingSignature] = useState(false);
  const [signingUser, setSigningUser] = useState({});
  const [isWaiting, setIsWaiting] = useState(false);
  const getUserSignature = async (setShowSignTrainingDialog, setIsWaiting) => {
    try {
      setIsGettingSignature(true);
      setIsWaiting(true);
      const { data } = await axios.get("/api/auth/user-signatures");
      setIsWaiting(false);
      setUserSignature(data);
      setShowSignTrainingDialog(true);
      setIsGettingSignature(false);
    } catch (error) {
      setIsWaiting(false);
      setShowSignTrainingDialog(true);
      setIsGettingSignature(false);
    }
  };
  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
          rejectedBy={request.mifotraEntityName}
        />
      )}
      {showSignTrainingDialog && (
        <MifotraApproveTrainingDialog
          headOfInstitution={headOfInstitution}
          request={request}
          showSignTrainingDialog={showSignTrainingDialog}
          setShowSignTrainingDialog={setShowSignTrainingDialog}
          signingUser={signingUser}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          level={"mifotra"}
        />
      )}

      <Button
        disabled={loading || isGettingSignature || isWaiting}
        onClick={() =>
          getUserSignature(setShowSignTrainingDialog, setIsWaiting)
        }
        style={{
          ...ActiveButton,
          background: "#14a44d",
          borderColor: "#14a44d",
        }}
        size="md"
      >
        {isWaiting ? "Wait.." : "Approve"}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};
const MIFOTRALevelVerifierButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}
      {showVerifyRequest && (
        <MifotraVerifyTrainingRequestDialog
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,

          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Verify Now .."}
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
          // float: "right",
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};

const MIFOTRALevelVerifier2Button = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;
  const [showRejectRequest, setShowRejectRequest] = useState(false);

  const [showVerifyRequest, setShowVerifyRequest] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}
      {showVerifyRequest && (
        <MifotraVerify2TrainingRequestDialog
          showVerifyRequest={showVerifyRequest}
          setShowVerifyRequest={setShowVerifyRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mifotra"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowVerifyRequest(true)}
        style={{
          ...ActiveButton,
          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        {loading ? "Wait.." : " Verify Now .."}
      </Button>

      {/* <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
          // float: "right",
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button> */}
    </>
  );
};
const MINEDUCLevelApprovalButton = (props) => {
  const { loading, request, setShowTrainingRequestDetails } = props;

  const [showRejectRequest, setShowRejectRequest] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  return (
    <>
      {showRejectRequest && (
        <RejectTrainingRequestDialog
          showRejectRequest={showRejectRequest}
          setShowRejectRequest={setShowRejectRequest}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
          rejectedBy={request.rdbEntityName}
        />
      )}

      {showApprovalModal && (
        <ApproveTrainingRequestDialog
          showApprovalModal={showApprovalModal}
          setShowApprovalModal={setShowApprovalModal}
          setShowTrainingRequestDetails={setShowTrainingRequestDetails}
          request={request}
          level={"mineduc"}
        />
      )}

      <Button
        disabled={loading}
        onClick={() => setShowApprovalModal(true)}
        style={{
          ...ActiveButton,

          background: "rgb(7, 142, 206)",
          borderColor: "rgb(7, 142, 206)",
        }}
        size="md"
      >
        Approve
      </Button>

      <Button
        disabled={loading}
        onClick={() => setShowRejectRequest(true)}
        style={{
          ...ActiveButton,
          background: "#dc3545",
          borderColor: "#dc3545",
        }}
        size="md"
      >
        Reject?
      </Button>
    </>
  );
};
