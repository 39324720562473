import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateSOFunds,
  addNewSOFunds,
  getAdminSOFunds,
  updateSOFunds,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const SourceOfFounds = (props) => {
  const {
    createFund,
    setCreateFund,
    // redux -----------------
    loading,
    adminSOFunds,
    getAdminSOFunds,
    addNewSOFunds,
    updateSOFunds,
    activateSOFunds,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [fundId, setFundId] = useState(0);
  const [deleteFund, setDeleteFund] = useState(null);
  const [fundName, setFundName] = useState("");
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [forcePage, setForcePage] = useState(undefined);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (adminSOFunds.length === 0) {
      getAdminSOFunds(setLoadingData);
    } else {
      setLoadingData(false);
    }

    return () => {
      setLoadingData(true);
      setFundId(0);
      setFundName("");
      setFormError(null);
      setSearchInput("");
    };
  }, []);

  const onCloseForm = () => {
    setCreateFund(false);
    setFundId(0);
    setFundName("");
  };

  const onSaveForm = () => {
    if (fundId === 0 && !createFund) {
      setFormError({
        element: "GENERAL",
        msg: "Please select allowance you want to update.",
      });
      return;
    }
    if (fundName.length === 0) {
      setFormError({ element: "NAME", msg: "Certificate name is required." });
      return;
    }

    if (fundId > 0 && !createFund) {
      updateSOFunds(
        fundId,
        {
          name: fundName,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewSOFunds(
        {
          name: fundName,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const handleChangeStatus = () => {
    if (deleteFund)
      activateSOFunds(deleteFund.id, (status) => status && setDeleteFund(null));
  };

  const IS_EDITING = Boolean(fundId > 0);
  const CURRENT_DATA = adminSOFunds
    ? adminSOFunds.filter((item) => item.isActive === !showInactive)
    : [];
  const SEARCHED_DATA =
    searchInput.length > 0
      ? CURRENT_DATA.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
      : CURRENT_DATA;
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (fundItem) => {
    setFundId(fundItem.id);
    setFundName(fundItem.name);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center justify-content-between">
        <div className="col-9">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || adminSOFunds.length === 0}
            placeholder="Search source of fund..."
          />
        </div>
        <div className="col-2 col-sm-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
            }}
            variant="outlined"
            size="small"
            disableElevation
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Source of fund</th>
            <th className="text-center" style={{ maxWidth: "12px" }}>
              Action
            </th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={3} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={3}>
                <div className="text-center font-weight-bold my-2">
                  No source of fund found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((fundItem, index) => (
              <tr key={"soFund-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{fundItem && fundItem.name}</td>
                <td align="center" style={{ maxWidth: "12px" }}>
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit source of fund"
                      size="small"
                      onClick={() => setForUpdate(fundItem)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="remove source of fund"
                      size="small"
                      onClick={() => {
                        setDeleteFund({
                          id: fundItem.id,
                          isActive: fundItem.isActive,
                        });
                      }}
                    >
                      {fundItem.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createFund || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Source of fund" : "Add New Source of fund"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Source of fund name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={fundName}
              onChange={(e) => setFundName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}

      {deleteFund && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !deleteFund.isActive ? "activate" : "de-activate"
          } this source of fund?`}
          setConfirmationDialog={() => setDeleteFund(null)}
          onYes={handleChangeStatus}
        />
      )}
    </div>
  );
};

SourceOfFounds.propTypes = {
  createFund: PropTypes.bool,
  setCreateFund: PropTypes.func,
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    adminSOFunds: adminPayroll.adminPayrollSOFunds,
  };
};

const mapDispatchToProps = {
  getAdminSOFunds,
  addNewSOFunds,
  updateSOFunds,
  activateSOFunds,
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfFounds);
