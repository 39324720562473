import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import DownloadIcon from "@mui/icons-material/Download";
import TableSkeleton from "../../common/components/TableSkeleton";
import AddToDriveRoundedIcon from "@mui/icons-material/AddToDriveRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { connect } from "react-redux";
import {
  addRBMTemplateScores,
  downloadRBMScoreTemplate,
  getPerformanceBonusScores,
  getRBMScoreTemplate,
} from "../../../store/payroll/actions";
import readXlsxFile from "read-excel-file";
import RBMScoreTableView from "../../rbm/components/RBMScoreTableView";
import { useEffect } from "react";

const excelHeaders = [
  "Employee ID",
  "Last Name",
  "First Name",
  "Position",
  "Entity",
  "Sector",
  "Unit",
  "Score",
];

const UploadRBMScoreDialog = ({
  onClose,
  open,
  loading,
  user,
  downloadRBMScoreTemplate,
  selectedFiscalYear,
  addRBMTemplateScores,
  getRBMScoreTemplate,
  getPerformanceBonusScores,
}) => {
  const [validationError, setValidationError] = useState([]);
  const [validatedData, setValidatedData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [missingEmployees, setMissingEmployees] = useState([]);
  const [RBMScoreTemplate, setRBMScoreTemplate] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [requestToSubmit, setRequestToSubmit] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!RBMScoreTemplate.length && +selectedFiscalYear?.id > 2122) {
      getRBMScoreTemplate((status, data) => {
        if (!status) {
          setRBMScoreTemplate(data);
        }
      });
    }
    return () => {
      setValidationError([]);
      setValidatedData([]);
      setMissingEmployees([]);
      setRBMScoreTemplate([]);
      setIsSubmitting(false);
      setRequestToSubmit(false);
    };
  }, []);

  const handleCloseDialog = () => {
    setValidationError([]);
    setRBMScoreTemplate([]);
    setIsSubmitting(false);
    setRequestToSubmit(false);
    onClose();
    handleClearClick();
  };

  const handleClearClick = () => {
    // Clear the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setValidationError([]);
    setValidatedData([]);
    setMissingEmployees([]);
  };

  const FC_HandleUploadedFile = (event) => {
    if (!event.target.files.length) {
      setValidationError([]);
      return;
    }
    let file = event.target.files[0];
    if (
      file.name.substr(-4).toLowerCase() === ".xls" ||
      file.name.substr(-5).toLowerCase() === ".xlsx"
    ) {
      if (+selectedFiscalYear?.id > 2122) {
        FC_GenerateJSONData(file);
      } else {
        FC_GenerateAreasJSONData(file);
      }
    } else {
      setValidationError([
        {
          type: "invalid-file",
          message: "Only Excel files are allowed!",
        },
      ]);
    }
  };

  const isNotNumber = (value) => {
    // Check if the value is a number.
    if (typeof value !== "number") {
      return true;
    }

    // Check if the value is NaN.
    if (isNaN(value)) {
      return true;
    }

    // Otherwise, the value is a valid number.
    return false;
  };

  const findDuplicates = (arr) => {
    let duplicates = [];

    for (let index = 0; index < arr.length; index++) {
      const item = arr[index];
      const duplicate = arr.slice(index + 1).find((item2) => {
        return (
          item.employeeId === item2.employeeId &&
          item.entitySectorId === item2.entitySectorId &&
          item.employeePositionId === item2.employeePositionId &&
          item.fiscalYearId === item2.fiscalYearId
        );
      });

      if (duplicate) {
        duplicates.push({
          type: "duplicate",
          message: `Row: ${index + 2} :: Duplicate data for ${
            item.employeeId
          }!`,
        });
      }
    }

    return duplicates;
  };

  const findMissingEmployees = (original, newData) => {
    // const newDataEmployeeIds = new Set(
    //   newData.map((item) => item[0].toString())
    // );
    // const employeesNotInNewData = original.filter(
    //   (employee) => !newDataEmployeeIds.has(employee.employeeId)
    // );

    // return employeesNotInNewData;
    return [];
  };

  const FC_GenerateJSONData = async (data) => {
    let hasError = [];
    try {
      setLoadingExcel(true);
      const rows = await readXlsxFile(data);
      let tempData = [],
        tempDataAPI = [];
      if (!!!rows.length || rows.length < 2) {
        setValidationError([
          {
            type: "no-data",
            message: "No valid data provided or Template has been modified!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      if (rows[0].length !== excelHeaders.length) {
        setValidationError([
          {
            type: "invalid-header",
            message: "Invalid header format!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      /**
       * Check if there employees whom are not in the excel file
       */
      if (user.selectedEntitySector.sectorId !== 6) {
        const missingEmployees = findMissingEmployees(
          RBMScoreTemplate,
          rows.slice(1)
        );

        if (missingEmployees.length) {
          setMissingEmployees(missingEmployees);
          setValidationError([
            {
              type: "missing-employees",
              message: `${missingEmployees.length} Missing Employees`,
            },
          ]);
          setLoadingExcel(false);
          return;
        }
      }

      /**
       * Check if Employee No is 10 characters and is number
       * Check if Score is a number and is between 70 and 100
       * Check if Months is a number and is between 0 and 12
       * Check if Annual Basic Salary is a number
       */

      for (let i = 1; i < rows.length; i++) {
        let row = rows[i];
        let tempRow = {};
        const employeeInfo = RBMScoreTemplate.find(
          (item) => +item.employeeId === +row[0]
        );

        if ((row[0] + "").length !== 10) {
          hasError.push({
            type: "required-field",
            message: `Row: ${i + 1} :: Invalid Employee ID! Employee number (${
              row[0]
            }) of ${
              row[1] + " " + row[2]
            } should be 10 characters and should be a number!`,
          });
        } else {
          tempRow["employeeId"] = row[0];
        }

        if (isNotNumber(row[7]) || row[7] < 70 || row[7] > 100) {
          hasError.push({
            type: "required-field",
            message: `Row: ${
              i + 1
            } :: Invalid Score! Score should be between 70 and 100!`,
          });
        } else {
          tempRow["score"] = row[7];
          tempRow["rate"] = row[7] >= 80 ? 5 : row[7] >= 60 ? 3 : 0;
          tempRow["category"] =
            row[7] >= 80
              ? "Indashyikirwa"
              : row[7] >= 70
              ? "Inkomezamihigo"
              : row[7] >= 60
              ? "Inkangurirwamihigo"
              : "Unspecified";
        }

        if (!hasError.length) {
          tempData.push({
            ...tempRow,
            entitySectorId: employeeInfo
              ? employeeInfo.entitySectorId
              : user.selectedEntitySector.id,
            employeePositionId: employeeInfo
              ? employeeInfo.employeePositionId
              : null,
            firstName: employeeInfo ? employeeInfo.firstName : row[2] || null,
            lastName: employeeInfo ? employeeInfo.lastName : row[1] || null,
            entityName: employeeInfo ? employeeInfo.entityName : row[4] || null,
            unitName: employeeInfo ? employeeInfo.unitName : row[6] || null,
            positionName: employeeInfo
              ? employeeInfo.positionName
              : row[3] || null,
            fiscalYearId: selectedFiscalYear.id,
          });
          tempDataAPI.push({
            ...tempRow,
            entitySectorId: employeeInfo
              ? employeeInfo.entitySectorId
              : user.selectedEntitySector.id,
            employeePositionId: employeeInfo
              ? employeeInfo.employeePositionId
              : null,
            firstName: employeeInfo ? employeeInfo.firstName : row[2] || null,
            lastName: employeeInfo ? employeeInfo.lastName : row[1] || null,
            fiscalYearId: selectedFiscalYear.id,
            employeeId: employeeInfo ? employeeInfo.employeeId : row[0],
            unitId: user?.selectedEntitySector?.unitId || null,
          });
        } else {
          setValidationError(hasError);
          setLoadingExcel(false);
          return;
        }
      }

      if (!tempData.length) {
        setValidationError([
          {
            type: "no-data",
            message: "No valid data provided or Template has been modified!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      //   check if there duplicates in the array basing on entitySectorId, employeePositionId and fiscalYearId
      // if there are duplicates, show them in error message
      const duplicates = findDuplicates(tempData);
      if (duplicates.length) {
        setValidationError(duplicates);
        setLoadingExcel(false);
        return;
      }

      // check if there is at least one employee with score of above 0
      const hasScore = tempData.find((item) => item.score > 0);
      if (!hasScore) {
        setValidationError([
          {
            type: "missing-score",
            message: "No employee with score above 0!",
          },
        ]);
        setValidatedData(tempData);
        setApiData(tempDataAPI);
        setLoadingExcel(false);
        return;
      }

      setValidationError([]);
      setValidatedData(tempData);
      setApiData(tempDataAPI);
      setLoadingExcel(false);
    } catch (error) {
      console.error(error);
      setValidationError([
        {
          type: "no-data",
          message: "No valid data provided or Template has been modified!",
        },
      ]);
      setLoadingExcel(false);
    }
  };
  const FC_GenerateAreasJSONData = async (data) => {
    let hasError = [];
    try {
      setLoadingExcel(true);
      const rows = await readXlsxFile(data);
      let tempData = [],
        tempDataAPI = [];
      if (!!!rows.length || rows.length < 2) {
        setValidationError([
          {
            type: "no-data",
            message: "No valid data provided or Template has been modified!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      if (rows[0].length !== 5) {
        setValidationError([
          {
            type: "invalid-header",
            message: "Invalid header format!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      /**
       * Check if Employee No is 10 characters and is number
       * Check if Score is a number and is between 70 and 100
       * Check if Months is a number and is between 6 and 12
       * Check if Annual Basic Salary is a number
       */

      for (let i = 1; i < rows.length; i++) {
        let row = rows[i];
        let tempRow = {};

        if ((row[0] + "").length !== 10) {
          hasError.push({
            type: "required-field",
            message: `Row: ${i + 1} :: Invalid Employee ID! Employee number (${
              row[0]
            }) of ${
              row[1] + " " + row[2]
            } should be 10 characters and should be a number!`,
          });
        } else {
          tempRow["employeeId"] = row[0];
        }

        // validate months and if months is greater or equal to 6 and less than or equal to 12
        if (isNotNumber(row[2]) || +row[2] < 6 || +row[2] > 12) {
          hasError.push({
            type: "required-field",
            message: `Row: ${
              i + 1
            } :: Invalid Months! Months should be between 6 and 12!`,
          });
        } else {
          tempRow["months"] = row[2];
        }

        // validate score and if score is greater or equal to 70 and less than or equal to 100
        if (isNotNumber(row[1]) || +row[1] < 70 || +row[1] > 100) {
          hasError.push({
            type: "required-field",
            message: `Row: ${
              i + 1
            } :: Invalid Score! Score should be between 70 and 100!`,
          });
        } else {
          tempRow["score"] = row[1];
          tempRow["rate"] = row[1] >= 80 ? 5 : row[1] >= 60 ? 3 : 0;
          tempRow["category"] =
            row[1] >= 80
              ? "Indashyikirwa"
              : row[1] >= 60
              ? "Inkomezamihigo"
              : "Unspecified";
        }
        // validate annual basic salary
        if (isNotNumber(row[4]) || +row[4] < 0) {
          hasError.push({
            type: "required-field",
            message: `Row: ${
              i + 1
            } :: Invalid Annual Basic Salary! Annual Basic Salary should be a number!`,
          });
        } else {
          tempRow["annualBasicSalary"] = row[4];
          tempRow["basicBonus"] = (+row[4] * +tempRow["rate"]) / 100;
        }

        if (!hasError.length) {
          tempData.push({
            ...tempRow,
            monthlyBasicSalary: +row[3],
            fiscalYearId: selectedFiscalYear.id,
          });
          tempDataAPI.push({
            ...tempRow,
            entitySectorId: user.selectedEntitySector.id,
            fiscalYearId: selectedFiscalYear.id,
            wasAdded: true,
            unitId: user?.selectedEntitySector?.unitId || null,
          });
        } else {
          setValidationError(hasError);
          setLoadingExcel(false);
          return;
        }
      }

      if (!tempData.length) {
        setValidationError([
          {
            type: "no-data",
            message: "No valid data provided or Template has been modified!",
          },
        ]);
        setLoadingExcel(false);
        return;
      }

      //   check if there duplicates in the array basing on entitySectorId, employeePositionId and fiscalYearId
      // if there are duplicates, show them in error message
      const duplicates = findDuplicates(tempData);
      if (duplicates.length) {
        setValidationError(duplicates);
        setLoadingExcel(false);
        return;
      }

      setValidationError([]);
      setValidatedData(tempData);
      setApiData(tempDataAPI);
      setLoadingExcel(false);
    } catch (error) {
      console.error(error);
      setValidationError([
        {
          type: "no-data",
          message: "No valid data provided or Template has been modified!",
        },
      ]);
      setLoadingExcel(false);
    }
  };

  const handleSubmit = () => {
    if (apiData.length > 0 && !validationError.length) {
      addRBMTemplateScores(apiData, (status, res, error) => {
        setIsSubmitting(status);
        if (res && !error) {
          handleCloseDialog();
          getPerformanceBonusScores();
        } else if (error) {
          setValidationError([
            {
              type: "error",
              message:
                error?.message ||
                "Something went wrong, please try again later!",
            },
          ]);
          setMissingEmployees(error?.failedInsertions || []);
          setRequestToSubmit(false);
        }
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        // maxWidth="xl"
        fullScreen
      >
        <DialogTitle className=" text-uppercase ">
          <span className="d-flex align-items-center">
            Upload Action Plan{" "}
            <span className="text-primary d-flex align-items-center">
              <span className="material-icons">chevron_right</span> FY:{" "}
              {selectedFiscalYear?.name}
            </span>
          </span>

          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div
            className="text-center mb-1 text-uppercase"
            style={{ fontSize: "18px", fontWeight: 500 }}
          >
            Upload RBM Scores
          </div>
          <div className="d-flex justify-content-center">
            <div className="custom-file" style={{ maxWidth: "750px" }}>
              <input
                name="selectFile"
                type="file"
                className="custom-file-input"
                accept=".xlsx, .xls, .csv"
                multiple={false}
                onChange={FC_HandleUploadedFile}
                onClick={handleClearClick}
                disabled={loading || isSubmitting}
                ref={fileInputRef}
              />
              <label className="custom-file-label">Choose file...</label>
            </div>
          </div>
          <div className="text-center mt-3">
            {!validationError.length && validatedData.length ? (
              <Typography className="d-inline alert alert-success">
                <VerifiedRoundedIcon /> Data verified successful!
              </Typography>
            ) : (
              +selectedFiscalYear?.id > 2122 && (
                <button
                  className={`btn btn-link btn-sm `}
                  disabled={loadingExcel || loading || isSubmitting}
                  onClick={() => downloadRBMScoreTemplate()}
                >
                  <DownloadIcon /> Download RBM scores template
                </button>
              )
            )}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {loadingExcel || (loading && !isSubmitting) ? (
            <div className="table-responsive">
              <table
                className="table table-sm table-bordered fixTableHead"
                style={{ fontSize: "10px" }}
              >
                <thead>
                  <tr>
                    <th className="text-left">Employee No</th>
                    {+selectedFiscalYear?.id > 2122 && (
                      <th className="text-left">Last Name</th>
                    )}
                    {+selectedFiscalYear?.id > 2122 && (
                      <th className="text-left">First Name</th>
                    )}
                    {+selectedFiscalYear?.id > 2122 && (
                      <th className="text-left">Entity Name</th>
                    )}
                    {+selectedFiscalYear?.id > 2122 && (
                      <th className="text-left">Unit Name</th>
                    )}
                    {+selectedFiscalYear?.id > 2122 && (
                      <th className="text-left">Position Name</th>
                    )}
                    <th className="text-center">Score</th>
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-center">Rate</th>
                    )}
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-left">Category</th>
                    )}
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-center">Months</th>
                    )}
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-center">Monthly Basic Salary</th>
                    )}
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-center">Annual Basic Salary</th>
                    )}
                    {+selectedFiscalYear?.id === 2122 && (
                      <th className="text-center">Basic Bonus</th>
                    )}
                  </tr>
                </thead>
                <TableSkeleton
                  cols={+selectedFiscalYear?.id > 2122 ? 7 : 8}
                  rows={20}
                />
              </table>
            </div>
          ) : (
            validationError.length > 0 && (
              <div className="my-3 border border-danger px-3 py-2">
                {validationError.map((error, index) => (
                  <div
                    className="d-flex align-items-start my-1"
                    style={{ fontSize: "12px" }}
                    key={index}
                  >
                    <div className="badge badge-danger my-1 mr-1">
                      {error.type}
                    </div>
                    <div>{error.message}</div>
                  </div>
                ))}
              </div>
            )
          )}

          {!loadingExcel &&
            (!loading || isSubmitting) &&
            (validatedData.length > 0 || missingEmployees.length > 0) && (
              <RBMScoreTableView
                data={
                  missingEmployees.length > 0 ? missingEmployees : validatedData
                }
                isForMissing={missingEmployees.length > 0 ? true : false}
                isForAreas={+selectedFiscalYear?.id === 2122}
                isFromAPI={!!missingEmployees[0]?.reason}
              />
            )}
          {!loadingExcel &&
            !validationError.length &&
            !validatedData.length && (
              <div className="d-flex align-items-center justify-content-center text-success h-100">
                <div className="d-flex flex-column align-items-center">
                  <AddToDriveRoundedIcon style={{ fontSize: "72px" }} />
                  <span className="mt-4" style={{ fontSize: "24px" }}>
                    Upload RBM Scores Excel File
                  </span>
                </div>
              </div>
            )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <div className="d-flex justify-content-center">
            <Button
              color="success"
              variant="contained"
              type="button"
              disabled={
                validationError.length ||
                !validatedData.length ||
                loading ||
                isSubmitting
              }
              onClick={() => setRequestToSubmit(true)}
              style={{
                float: "right",
                borderRadius: "8px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              size="md"
            >
              <span className="material-icons mr-1">task_alt</span> Confirm RBM
              Scores
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {requestToSubmit && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to confirm this RBM Scores?`}
          setConfirmationDialog={() => setRequestToSubmit(false)}
          onYes={handleSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user, selectedFiscalYear }) => {
  return {
    loading,
    user,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, {
  downloadRBMScoreTemplate,
  addRBMTemplateScores,
  getPerformanceBonusScores,
  getRBMScoreTemplate,
})(UploadRBMScoreDialog);
