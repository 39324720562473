import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { approveOrRejectWaitingListRequisition } from "../../../store/recruitment/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const WaitingListRequestDetails = (props) => {
  const {
    loading,
    setShowWaitingListRequisitionDetail,
    showWaitingListRequisitionDetail,
    waitingListRequisition,
    approveOrRejectWaitingListRequisition,
  } = props;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [statusId, setStatusId] = useState("");

  const onClose = () => {
    setStatusId("");
    setShowConfirmationDialog(false);
    setShowWaitingListRequisitionDetail(false);
  };
  // console.log(waitingListRequisition);

  const { hasPermissions } = useRequiredAuth();
  const [comment, setComment] = useState("");

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showWaitingListRequisitionDetail}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            Requisition{" "}
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "6px" }}
            >
              chevron_right
            </span>
            <span className="ml-2 text-success">
              {waitingListRequisition && waitingListRequisition.positionName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <table>
            <tbody>
              <tr>
                <th scope="row">Position</th>
                <td>{waitingListRequisition.positionName}</td>
              </tr>
              <tr>
                <th scope="row">Unit</th>
                <td>{waitingListRequisition.unitName}</td>
              </tr>
              <tr>
                <th scope="row">Number</th>
                <td>{waitingListRequisition.number}</td>
              </tr>
              <tr>
                <th scope="row">RequestedOn</th>
                <td>
                  {moment(waitingListRequisition.requestedOn).format(
                    "YYYY-MM-DD"
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">RequestedBy</th>
                <td>{waitingListRequisition.requestedBy}</td>
              </tr>
              <tr>
                <th scope="row">Reason</th>
                <td>
                  <div className="p-4 card border-primary  rounded">
                    {" "}
                    {waitingListRequisition.requestComment}{" "}
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Status</th>
                <td>
                  <span
                    className={`font-weight-bold text-uppercase text-${
                      waitingListRequisition.statusId === 3
                        ? "danger"
                        : waitingListRequisition.statusId === 1
                        ? "primary"
                        : "success"
                    }`}
                  >
                    {waitingListRequisition.statusName}
                  </span>
                </td>
              </tr>
              {waitingListRequisition.statusDate && (
                <tr>
                  <th scope="row">StatusOn</th>
                  <td>
                    {moment(waitingListRequisition.statusDate).format(
                      "YYYY-MM-DD"
                    )}
                  </td>
                </tr>
              )}

              {waitingListRequisition.statusBy && (
                <tr>
                  <th scope="row">StatusBy</th>
                  <td>{waitingListRequisition.statusBy}</td>
                </tr>
              )}
              {waitingListRequisition.statusComment && (
                <tr>
                  <th scope="row">StatusComment</th>
                  <td>
                    <div className="p-4 card border-danger rounded">
                      {" "}
                      {waitingListRequisition.statusComment}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions className="py-3">
          {
            // waitingListRequisition.statusId === 1 &&
            hasPermissions(["IS_CBM"]) && (
              <Stack direction="row" spacing={2} className="mx-auto">
                <Button
                  disabled={
                    showConfirmationDialog || showRejectDialog || loading
                  }
                  variant="contained"
                  color="success"
                  startIcon={
                    <span className="material-icons mr-1">done_all</span>
                  }
                  onClick={() => {
                    setStatusId(2);
                    setShowConfirmationDialog(true);
                  }}
                >
                  Approve
                </Button>
                <Button
                  disabled={
                    showConfirmationDialog || showRejectDialog || loading
                  }
                  variant="contained"
                  color="warning"
                  endIcon={<span className="material-icons mr-1">cancel</span>}
                  onClick={() => {
                    setStatusId(3);
                    setShowConfirmationDialog(true);
                  }}
                >
                  Reject
                </Button>
              </Stack>
            )
          }
        </DialogActions>
      </Dialog>

      {showConfirmationDialog && (
        <ConfirmationDialog
          disabled={statusId === 3 && !comment}
          btnMessage={`${statusId === 2 ? "Approve" : "Reject"}`}
          noPreFormat={true}
          confirmationDialog={showConfirmationDialog}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${statusId === 2 ? "success" : "danger"}`}
              >
                {statusId === 2 ? "approve" : "reject"}
              </strong>{" "}
              this request?
              {statusId === 3 && (
                <TextField
                  className="mt-3"
                  required
                  id="comment"
                  label="Add your comments..."
                  fullWidth
                  multiline
                  rows={3}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              )}
            </>
          }
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            approveOrRejectWaitingListRequisition(
              {
                requisitionId: waitingListRequisition.id,
                statusId: statusId,
                statusComment: "",
              },
              onClose
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  approveOrRejectWaitingListRequisition,
})(WaitingListRequestDetails);
