import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  removePayrollApprover,
  addNewPayrollApprover,
  getAdminCostCenter,
  getAdminPayrollApprover,
  updatePayrollApprover,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { findUser } from "../../../store/support/actions";
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";

const PayrollApprover = (props) => {
  const {
    createPayrollApprover,
    setCreatePayrollApprover,
    // redux -----------------
    loading,
    adminPayrollApprover,
    getAdminPayrollApprover,
    addNewPayrollApprover,
    updatePayrollApprover,
    adminCostCenter,
    getAdminCostCenter,
    removePayrollApprover,
    findUser,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);

  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [formError, setFormError] = useState(null);
  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [formDocument, setFormDocument] = useState(null);

  const [filterCostCenter, setFilterCostCenter] = useState(null);
  const [deleteApprover, setDeleteApprover] = useState(null);
  const [payrollApproverId, setPayrollApproverId] = useState(0);
  const [userFound, setUserFound] = useState(null);

  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!adminPayrollApprover.length) {
      getAdminPayrollApprover(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (!adminCostCenter.length) getAdminCostCenter();

    return () => {
      setLoadingData(true);
      setPayrollApproverId(0);
      setFormError(null);
      setSearchInput("");
      setSelectedCostCenter(null);
      setUserFound(null);
      setDeleteApprover(null);
      setFilterCostCenter(null);
      setSelectedLevel(null);
      setSearchUserTerm("");
      setLoadingUser(false);
      setLoadingData(true);
    };
  }, []);

  const onCloseForm = () => {
    setCreatePayrollApprover(false);
    setPayrollApproverId(0);
    setSelectedCostCenter(null);
    setUserFound(null);
    setSelectedLevel(null);
    setFormError(null);
    setFormDocument(null);
  };

  const onSaveForm = () => {
    if (payrollApproverId <= 0 && !createPayrollApprover) {
      setFormError({
        element: "GENERAL",
        msg: "Please select cost center you want to update.",
      });
      return;
    }
    if (!userFound) {
      setFormError({ element: "USER", msg: "Employee is required." });
      return;
    }
    // if (!formDocument) {
    //   setFormError({ element: "DOCUMENT", msg: "Attachment is required." });
    //   return;
    // }

    const formData = new FormData();
    formData.append("file", formDocument);
    formData.append("approvalLevel", selectedLevel);

    if (payrollApproverId > 0 && !createPayrollApprover) {
      updatePayrollApprover(
        {
          payrollApproverId,
          formData,
        },
        (status) => status && onCloseForm()
      );
    } else {
      formData.append("costCenterId", selectedCostCenter?.id);
      formData.append("userId", userFound?.id);
      addNewPayrollApprover(formData, (status) => status && onCloseForm());
    }
  };

  const handleFilter = (data) => {
    let result = [...data];
    if (result.length) {
      if (filterCostCenter)
        result = result.filter(
          (item) => item.costCenterId === filterCostCenter?.id
        );
      if (searchInput.length)
        result = result.filter(
          (item) =>
            (item.userId || "")
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            (item.approvalLevel || "")
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            (item.costCenter &&
              ((item.costCenter.id || "")
                .toLowerCase()
                .includes(searchInput.toLowerCase()) ||
                (item.costCenter.name || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()))) ||
            (item.user &&
              ((item.user.id || "")
                .toLowerCase()
                .includes(searchInput.toLowerCase()) ||
                (item.user.lastName || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                (item.user.firstName || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                (item.user.email || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                (item.user.phoneNumber || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                (item.user.idNumber || "")
                  .toLowerCase()
                  .includes(searchInput.toLowerCase())))
        );
    }
    return result;
  };

  const handleChangeStatus = () => {
    if (deleteApprover)
      removePayrollApprover(
        deleteApprover.id,
        (status) => status && setDeleteApprover(null)
      );
  };

  const onFindUser = () => {
    if (searchUserTerm.length)
      findUser(searchUserTerm, (status, data) => {
        setLoadingData(status);
        setUserFound(data);
      });
  };

  const IS_EDITING = Boolean(payrollApproverId > 0);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0
      ? adminPayrollApprover
      : adminPayrollApprover.filter(
          (item) =>
            (item.costCenter ? item.costCenter.name : "")
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase()) ||
            (item.user ? item.user.lastName : "") ||
            (item.user ? item.user.firstName : "")
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase())
        )
    : [];
  SEARCHED_DATA = handleFilter(SEARCHED_DATA);
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (approverItem) => {
    setPayrollApproverId(approverItem.id);
    setSelectedCostCenter(approverItem.costCenter);
    setUserFound(approverItem.user);
    setSelectedLevel(approverItem.approvalLevel);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["application/pdf"];

    if (file && allowedTypes.includes(file.type)) setFormDocument(file);
    else {
      setFormDocument(null);
      alert("Please select a PDF file.");
    }
    setFormError(null);
  };

  const USER_APPROVALS = userFound
    ? adminPayrollApprover.filter((item) => item.userId === userFound.id)
    : null;

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-12 col-sm-8">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || adminCostCenter.length === 0}
            placeholder="Search cost center..."
          />
        </div>
        <div className="col-6 col-sm-4 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, costCenter) => (
              <Box component="li" {...props}>
                {costCenter?.name}
              </Box>
            )}
            options={adminCostCenter}
            size="small"
            value={filterCostCenter || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by cost center"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "cost center",
                }}
              />
            )}
            onChange={(event, costCenter) =>
              setFilterCostCenter(costCenter || null)
            }
          />
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Cost center</th>
            <th scope="col">Employee Id</th>
            <th scope="col">Employee</th>
            <th scope="col">Email</th>
            <th className="text-center">Level</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={7} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={7}>
                <div className="text-center font-weight-bold my-2">
                  No cost center found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((approver, index) => (
              <tr key={"payrollApprover-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1}
                </th>
                <td>{approver.costCenter ? approver.costCenter.name : "-"}</td>
                <td>{approver.user.id}</td>
                <td>
                  {approver.user
                    ? `${approver.user.lastName} ${approver.user.firstName}`
                    : "-"}
                </td>
                <td>{approver.user.email}</td>
                <td align="center" className="font-weight-bold">
                  {displayApprovalLevel(approver.approvalLevel)}
                </td>

                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit bank"
                      size="small"
                      onClick={() => setForUpdate(approver)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="change bank status"
                      size="small"
                      onClick={() => {
                        setDeleteApprover({
                          id: approver.id,
                        });
                      }}
                    >
                      {approver.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
        />
      )}

      <Dialog
        onClose={onCloseForm}
        aria-labelledby="customized-dialog-title"
        open={Boolean(createPayrollApprover || IS_EDITING)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {IS_EDITING ? "Edit Payroll Approver" : "Add New Payroll Approver"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {formError && formError.element === "GENERAL" && (
            <Alert severity="error" className="mb-1">
              {formError.msg}
            </Alert>
          )}

          {createPayrollApprover && (
            <div className="d-flex flex-row align-items-center">
              <SearchBox
                disabled={loadingUser || loading || loadingData}
                onSearch={setSearchUserTerm}
                placeholder="Enter email or phone"
                isFull
              />
              <Button
                className="ml-2"
                disabled={
                  loadingUser ||
                  loading ||
                  loadingData ||
                  !searchUserTerm.length
                }
                onClick={() => onFindUser()}
                size="md"
                variant="contained"
              >
                Search{loadingUser && "ing..."}
              </Button>
            </div>
          )}

          {userFound && (
            <span className="text-primary font-weight-bold">
              {userFound.lastName + " " + userFound.firstName}
            </span>
          )}

          {USER_APPROVALS && USER_APPROVALS.length > 0 && !IS_EDITING && (
            <table className={`table table-sm fixTableHead mt-2`}>
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">Cost center</th>
                  <th className="text-center">Level</th>
                </tr>
              </thead>
              <tbody>
                {USER_APPROVALS.map((approver, index) => (
                  <tr key={"payrollApprover-" + index}>
                    <td>
                      {approver.costCenter ? approver.costCenter.name : "-"}
                    </td>
                    <td align="center">
                      {displayApprovalLevel(approver.approvalLevel)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {formError && formError.element === "USER" && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError.msg}
            </span>
          )}

          {/* cost center list */}
          <Autocomplete
            disabled={loading || loadingData || IS_EDITING}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, costCenter) => (
              <Box component="li" {...props}>
                {costCenter?.name}
              </Box>
            )}
            options={adminCostCenter}
            size="small"
            value={selectedCostCenter || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select cost center"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "country",
                }}
              />
            )}
            onChange={(event, costCenter) => {
              setSelectedCostCenter(costCenter || null);
              setFormError(null);
            }}
            className="mt-3"
          />
          {formError && formError.element === "COST_CENTER" && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError.msg}
            </span>
          )}

          <div className="d-flex flex-row align-items-center justify-content-between mt-3">
            <div
              className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer ${
                selectedLevel === "GENERATED"
                  ? "border-primary text-primary"
                  : "border-secondary"
              }`}
              onClick={() =>
                !loadingUser &&
                !loading &&
                !loadingData &&
                setSelectedLevel("GENERATED")
              }
            >
              {selectedLevel === "GENERATED" ? (
                <RadioButtonCheckedRounded fontSize="small" />
              ) : (
                <RadioButtonUncheckedRounded fontSize="small" />
              )}{" "}
              GENERATE
            </div>
            <div
              className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer mx-1 ${
                selectedLevel === "VERIFIED"
                  ? "border-primary text-primary"
                  : "border-secondary"
              }`}
              onClick={() =>
                !loadingUser &&
                !loading &&
                !loadingData &&
                setSelectedLevel("VERIFIED")
              }
            >
              {selectedLevel === "VERIFIED" ? (
                <RadioButtonCheckedRounded fontSize="small" />
              ) : (
                <RadioButtonUncheckedRounded fontSize="small" />
              )}{" "}
              VERIFY
            </div>
            <div
              className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer ${
                selectedLevel === "APPROVED"
                  ? "border-primary text-primary"
                  : "border-secondary"
              }`}
              onClick={() =>
                !loadingUser &&
                !loading &&
                !loadingData &&
                setSelectedLevel("APPROVED")
              }
            >
              {selectedLevel === "APPROVED" ? (
                <RadioButtonCheckedRounded fontSize="small" />
              ) : (
                <RadioButtonUncheckedRounded fontSize="small" />
              )}{" "}
              APPROVE
            </div>
          </div>

          <div className="custom-file mt-3">
            <input
              type="file"
              className="custom-file-input"
              accept="application/pdf"
              onChange={handleFileSelect}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {formDocument ? formDocument.name : "Attach request letter"}
            </label>
          </div>
          {formError && formError.element === "DOCUMENT" && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError.msg}
            </span>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={onSaveForm}
            type="button"
            className="btn btn-primary text-uppercase px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>

      {deleteApprover && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to remove this payroll approver?`}
          setConfirmationDialog={() => setDeleteApprover(null)}
          onYes={handleChangeStatus}
        />
      )}
    </div>
  );
};

PayrollApprover.propTypes = {
  createPayrollApprover: PropTypes.bool,
  setCreatePayrollApprover: PropTypes.func,
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    adminCostCenter: adminPayroll.adminCostCenter,
    adminPayrollApprover: adminPayroll.adminPayrollApprover,
  };
};

const mapDispatchToProps = {
  getAdminPayrollApprover,
  addNewPayrollApprover,
  updatePayrollApprover,
  removePayrollApprover,
  getAdminCostCenter,
  findUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollApprover);

const displayApprovalLevel = (level) => {
  if (level === "GENERATED") return "Generate";
  if (level === "VERIFIED") return "Verify";
  if (level === "APPROVED") return "Approve";
};
