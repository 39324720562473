import { Card, CardHeader, IconButton, Typography } from "@mui/material";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useState } from "react";

const SanctionLetter = (props) => {
    const { sanctionDocReferenceNo, caseSanctionName } = props;
  
    const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  
    return (
      <>
        <div className="d-flex justify-content-start mt-2">
          <Card
            className={`mb-2  rounded hoverable-card card border-${
              !!sanctionDocReferenceNo ? "success" : "primary"
            }`}
            elevation={1}
            variant="elevation"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <CardHeader
            className="p-1"
              avatar={
                <span className={"font-weight-bold text-primary"}>
                  <TextSnippetIcon />
                </span>
              }
              title={
                <Typography>
                  <strong>View {caseSanctionName || ""} Letter</strong>
                </Typography>
              }
              action={
                <>
                  <div
                    className="d-flex flex-row align-items-center pull-right"
                    style={{ borderRadius: "4px" }}
                  >
                    {sanctionDocReferenceNo && (
                      <IconButton
                        // disableRipple
                        // className="ml-n2"
                        size="small"
                        onClick={() => {
                          setOnViewProvDocument(true);
                        }}
                      >
                        <FilePresentIcon
                          // fontSize="large"
                          style={{
                            color: !!sanctionDocReferenceNo
                              ? "#fe4066"
                              : "#c57c7cb8",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </>
              }
            />
          </Card>
          {onViewProvDocument && (
            <PreviewPdfUrl
              showDocument={onViewProvDocument}
              setShowDocument={() => setOnViewProvDocument(false)}
              document={{
                name: `View ${caseSanctionName || ""} letter`,
                url:
                  process.env.REACT_APP_FILES_URL +
                  "/preview/" +
                  sanctionDocReferenceNo,
              }}
            />
          )}
        </div>
      </>
    );
  };
  export default SanctionLetter;