import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { employCandidate } from "../../../store/recruitment/actions";
import { showError } from "../../toastify";
import MaskedInput from "react-text-mask";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import defaultPofile from "../../assets/default-profile.jpg";
import axios from "axios";
import moment from "moment";
import joi from "joi";
import ErrorMessage from "../../common/components/ErrorMessage";
// import { idNumberPattern } from "../../common/components/Utils";

export const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const professionalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["rw"] },
  }),
});

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { deny: ["rw"] },
  }),
});

const EmployCandidateDialog = (props) => {
  const {
    loading,

    showEmployeeForm,
    setShowEmployeeForm,
    employCandidate,
    application,
    isFromWaitingList,
  } = props;

  const [employeeForm, setEmployeeForm] = useState({
    applicationId: application.applicantId,
    employeeId: application.userId,
    idNumber: application.idNumber2,
    personalEmail: application.email,
    phoneNumber: application.phoneNumber,
    gender: application.genderId,
    countryOfBirth: application.countryOfBirth,
    firstName: application.firstName,
    lastName: application.lastName,
    civilStatus: application.civilStatusId,
    fatherNames: application.fatherNames,
    motherNames: application.motherNames,
    dateOfBirth: application.dateOfBirth,
    placeOfBirth: application.placeOfBirth,
    professionalEmail: "",
    employmentDate: null,
    profilePicture: null,
  });

  const [employee, setEmployee] = useState(null);

  const [file, setFile] = useState(false);
  const [employmentErrors, setEmploymentErrors] = useState([]);

  const [errors, setErrors] = useState({
    idNumberHasError: false,
    idNumberError: "",
    phoneNumberHasError: false,
    phoneNumberError: "",
    personalEmailHasError: false,
    personalEmailError: "",
    professionalEmailHasError: false,
    professionalEmailError: "",
    employmentDateError: false,
    employmentError: "",
    employmentDateHasError: false,
    fileHasError: false,
    fileError: false,
    hasError: false,
  });

  useEffect(() => {
    getNidDetails(application.idNumber2);
  }, [application]);

  const formValidator = () => {
    const error = {
      idNumberHasError: false,
      idNumberError: "",
      phoneNumberHasError: false,
      phoneNumberError: "",
      personalEmailHasError: false,
      personalEmailError: "",
      professionalEmailHasError: false,
      professionalEmailError: "",
      employmentDateHasError: false,
      employmentDateError: "",
      fileHasError: false,
      fileError: false,
      hasError: false,
    };

    if (!employeeForm.idNumber) {
      error.idNumberHasError = true;
      error.hasError = true;
    }

    if (!!employeeForm.idNumber && !wasVerified) {
      error.idNumberHasError = true;
      error.idNumberError = "NID not verified";
      error.hasError = true;
    }

    if (!employeeForm.phoneNumber) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (!!employeeForm.phoneNumber && employeeForm.phoneNumber.length !== 10) {
      error.phoneNumberHasError = true;
      error.phoneNumberError = "Invalid phone number";
      error.hasError = true;
    }

    if (!employeeForm.personalEmail) {
      error.personalEmailHasError = true;
      error.hasError = true;
    }

    if (
      !!employeeForm.personalEmail &&
      personalEmailValidator.validate({ email: employeeForm.personalEmail })
        .error
    ) {
      error.personalEmailHasError = true;
      error.personalEmailError = "Invalid personal email";
      error.hasError = true;
    }

    if (
      !!employeeForm.professionalEmail &&
      professionalEmailValidator.validate({
        email: employeeForm.professionalEmail,
      }).error
    ) {
      error.professionalEmailHasError = true;
      error.professionalEmailError = "Invalid professional email";
      error.hasError = true;
    }

    if (!employeeForm.employmentDate) {
      error.employmentDateHasError = true;
      error.hasError = true;
    }

    if (
      !!employeeForm.employmentDate &&
      new Date(employeeForm.employmentDate) > new Date()
    ) {
      error.employmentDateHasError = true;
      error.employmentDateError = "Future date is not allowed";
      error.hasError = true;
    }

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      if (wasVerified && !!employmentErrors.length) {
        return showError(employmentErrors[0].errorMessage);
      }

      const formData = new FormData();
      formData.append(
        "idNumber",
        (employeeForm.idNumber || "").replace(/\s/g, "")
      );
      const positionId = !!isFromWaitingList
        ? application.requestedPositionId
        : application.positionId;
      formData.append("positionId", positionId);
      formData.append("employmentDate", employeeForm.employmentDate);
      formData.append("phoneNumber", employeeForm.phoneNumber);
      formData.append("personalEmail", employeeForm.personalEmail);
      formData.append("professionalEmail", employeeForm.professionalEmail);
      formData.append("applicationId", employeeForm.applicationId);
      formData.append("employeeId", employeeForm.employeeId);
      formData.append("file", file);
      formData.append("isFromWaitingList", isFromWaitingList);

      employCandidate(formData, isFromWaitingList, setShowEmployeeForm);
    }
  };
  const onClose = () => {
    setShowEmployeeForm(false);
  };

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const [isAvailable, setIsAvailable] = useState(false);

  const getNidDetails = async (idNumber) => {
    try {
      setWasVerified(false);
      setIsVerifing(true);

      const { data } = await axios.get(
        "/api/structure/new-employee-checking/" +
          (idNumber || "").replace(/\s/g, "")
      );

      const dateOfBirthArray = data.dateOfBirth.split("/");

      setEmployeeForm({
        ...employeeForm,
        idNumber: idNumber,
        gender: data.sex,
        countryOfBirth: data.birthCountry,
        firstName: data.foreName,
        lastName: data.surnames,
        civilStatus: data.maritalStatus,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth:
          dateOfBirthArray[2] +
          "-" +
          dateOfBirthArray[1] +
          "-" +
          dateOfBirthArray[0],
        placeOfBirth: data.placeOfBirth,
        profilePicture: data.photo,
        personalEmail: data.employee?.email || data.email || "",
        phoneNumber: data.employee?.phoneNumber || data.phoneNumber || "",
      });
      setEmploymentErrors([...data.employmentErrors]);
      setEmployee(data.employee);

      setIsAvailable(data.isAvailable);

      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
      showError(error);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeForm}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              {application.positionName && (
                <div className="header_cont">
                  <span className="ml-0">
                    {!!isFromWaitingList
                      ? application.requestedPositionName
                      : application.positionName}
                  </span>{" "}
                </div>
              )}
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ minHeight: "400px" }}>
            <div
              className=" mt-3"
              // style={{
              //   backgroundColor: "#fafafa",
              //   border: "1px solid #17a2b8",
              // }}
            >
              {/* <div className="row">
                <div className="col-12 text-left">
                  <h6 className="mb-2">Manual placement</h6>
                </div>
              </div> */}
              <div className="row mt-3">
                <div className="col-2 justify-content-center text-uppercase text-center">
                  <h6 className="mb-2">Profile</h6>
                  <div
                    style={{
                      height: "128px",
                      overflow: "hidden",
                    }}
                  >
                    <>
                      {employeeForm.profilePicture && (
                        <img
                          className="mt-0 rounded  text-center"
                          src={
                            "data:image/jpg;base64," +
                            employeeForm.profilePicture
                          }
                          style={{
                            border: "1px solid #a6d3df",
                            height: "128px",
                            // width: "130px",
                            overflow: "hidden",
                            // borderRadius: "50%",
                          }}
                        />
                      )}
                      {!employeeForm.profilePicture && (
                        <img
                          className="mt-0  rounded text-center"
                          src={defaultPofile}
                          style={{
                            // border: "1px solid #a6d3df",
                            height: "128px",
                            width: "100px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </>
                  </div>

                  <label
                    className="text-center text-uppercase mt-2"
                    style={{ fontSize: "12px" }}
                  >
                    <span style={{ fontSize: "10px" }}>Professional email</span>{" "}
                    <br />{" "}
                    <input
                      className="mt-1"
                      name="professionalEmail"
                      style={{ borderColor: "blue" }}
                      type="email"
                      placeholder="Professional email"
                      onChange={(e) => {
                        setEmployeeForm({
                          ...employeeForm,
                          professionalEmail: e.target.value || "",
                        });

                        setErrors({
                          ...errors,
                          professionalEmailHasError: false,
                          professionalEmailError: "",
                        });
                      }}
                    />
                    {errors.professionalEmailHasError && (
                      <small className="text-danger">
                        {errors.professionalEmailError ||
                          "Professional email required"}
                      </small>
                    )}
                  </label>
                  {/* <span
                    className="material-icons  text-info d-block"
                    style={{ fontSize: "64px" }}
                  >
                    help
                  </span> */}

                  {wasVerified && isAvailable && (
                    <span className="text-success font-weight-bolder">
                      <span
                        className="material-icons d-block"
                        style={{ fontSize: "48px" }}
                      >
                        check_circle_outline
                      </span>
                      <span
                        style={{ fontSize: "12px" }}
                        className="mt-n1 d-block"
                      >
                        Available
                      </span>
                    </span>
                  )}
                  {wasVerified &&
                    (!isAvailable || !!employmentErrors.length) && (
                      <span className="text-danger font-weight-bolder">
                        <span
                          className="material-icons d-block"
                          style={{ fontSize: "48px" }}
                        >
                          highlight_off
                        </span>
                        <span
                          style={{ fontSize: "12px" }}
                          className="mt-n1 d-block"
                        >
                          Not available
                        </span>
                      </span>
                    )}
                  {wasVerified && !isAvailable && (
                    <div
                      style={{ fontSize: "10px" }}
                      className="alert alert-danger mt-1 p-1 font-weight-bold"
                    >
                      in service at{" "}
                      <span className=" text-info">{employee.entityName}</span>{" "}
                      as <span className="">{employee.positionName}</span>
                    </div>
                  )}
                </div>
                <div className="col-10 row">
                  {!!wasVerified && (
                    <div className="col-12">
                      {employmentErrors.map((employmentError, index) => (
                        <ErrorMessage
                          hasError={true}
                          message={employmentError.errorMessage}
                          key={index}
                        />
                      ))}
                    </div>
                  )}

                  <div className="col-12">
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="position"
                      label="Position of placement"
                      variant="outlined"
                      className="font-weight-bold mb-2"
                      value={
                        !!isFromWaitingList
                          ? application.requestedPositionName || "N/A"
                          : application.positionName || "N/A"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              style={{
                                borderRadius: "0px",
                              }}
                              className={`badge badge-primary`}
                            >
                              Recruitment
                            </span>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div className="col-4 justify-content-center">
                    {!idHasError && !isVerifing && !wasVerified && (
                      <span className="material-icons loaderIcon text-danger">
                        help
                      </span>
                    )}

                    {!!isVerifing && (
                      <i
                        className="fas fa-spinner spinner loaderIcon text-primary"
                        style={{ fontSize: "22px" }}
                      ></i>
                    )}

                    {!isVerifing && !!idHasError && (
                      <span className="loaderIcon text-danger d-flex align-items-center">
                        <span className="material-icons text-danger">
                          report_problem
                        </span>
                        <span style={{ fontSize: "10px" }}>Error</span>
                      </span>
                    )}

                    {!idHasError && !isVerifing && !!wasVerified && (
                      <span className="loaderIcon text-success d-flex align-items-center">
                        <span className="material-icons text-success ">
                          verified
                        </span>
                        <span style={{ fontSize: "10px" }}>Verified</span>
                      </span>
                    )}

                    <MaskedInput
                      mask={idNumberPattern}
                      className="form-control"
                      placeholder="Employee NID Number"
                      guide={false}
                      value={employeeForm.idNumber}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !!e.target.value.length >= 1) {
                          getNidDetails(e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        setEmployeeForm({
                          ...employeeForm,
                          idNumber: e.target.value,
                        });

                        if (e.target.value.length === 21) {
                          getNidDetails(e.target.value);
                        } else {
                          setIdHasError(false);
                          setWasVerified(false);
                          setIsVerifing(false);
                        }

                        setErrors({
                          ...errors,
                          idNumberHasError: false,
                          idNumberError: "",
                        });
                      }}
                    />

                    {errors.idNumberHasError && (
                      <small className="text-danger mb-1">
                        {errors.idNumberError || "ID Number is required"}
                      </small>
                    )}

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="lastName"
                      disabled={true}
                      label="Last Name"
                      variant="outlined"
                      className="my-3"
                      value={employeeForm.lastName}
                    />

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="firstName"
                      disabled={true}
                      label="First Name"
                      variant="outlined"
                      className="mb-3"
                      value={employeeForm.firstName}
                    />

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="civilStatus"
                      disabled={true}
                      label="Civil Status"
                      variant="outlined"
                      value={employeeForm.civilStatus}
                    />
                  </div>

                  <div className="col-4 justify-content-center">
                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="gender"
                      disabled={true}
                      label="Gender"
                      variant="outlined"
                      className="mb-3 font-weight-bold"
                      value={employeeForm.gender}
                    />

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="fatherNames"
                      value={employeeForm.fatherNames}
                      disabled={true}
                      label="Father Names"
                      variant="outlined"
                      className="mb-3"
                    />

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="motherNames"
                      value={employeeForm.motherNames}
                      disabled={true}
                      label="Mother Names"
                      variant="outlined"
                      className="mb-3"
                    />

                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      autoComplete="false"
                      name="phone"
                      label="Phone number"
                      variant="outlined"
                      placeholder="07xxxxxxxx"
                      value={employeeForm.phoneNumber}
                      onChange={(e) => {
                        setEmployeeForm({
                          ...employeeForm,
                          phoneNumber: e.target.value,
                        });

                        setErrors({
                          ...errors,
                          phoneNumberHasError: false,
                          phoneNumberError: "",
                        });
                      }}
                    />
                    {errors.phoneNumberHasError && (
                      <small className="text-danger">
                        {errors.phoneNumberError || "Phone number is required"}
                      </small>
                    )}
                  </div>

                  <div className="col-4 justify-content-center">
                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="countryOfBirth"
                      value={employeeForm.countryOfBirth || ""}
                      disabled={true}
                      label="Country of Birth"
                      variant="outlined"
                      className="mb-3"
                    />

                    <TextField
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="dateOfBirth"
                      value={
                        employeeForm.dateOfBirth
                          ? moment(employeeForm.dateOfBirth).format(
                              "DD/MM/yyyy"
                            )
                          : ""
                      }
                      disabled={true}
                      label="Date Of Birth"
                      variant="outlined"
                      className="mb-3"
                    />

                    <TextField
                      disabled
                      style={{ backgroundColor: "#e9ecef" }}
                      fullWidth
                      size="small"
                      rows={4}
                      name="placeOfBirth"
                      value={employeeForm.placeOfBirth || ""}
                      label="Place of Birth"
                      variant="outlined"
                      className="mb-3"
                    />

                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      autoComplete="false"
                      name="email"
                      label="Personal email"
                      placeholder="Personal email"
                      variant="outlined"
                      value={employeeForm.personalEmail}
                      onChange={(e) => {
                        setEmployeeForm({
                          ...employeeForm,
                          personalEmail: e.target.value,
                        });

                        setErrors({
                          ...errors,
                          personalEmailHasError: false,
                          personalEmailError: "",
                        });
                      }}
                    />

                    {errors.personalEmailHasError && (
                      <small className="text-danger">
                        {errors.personalEmailError ||
                          "Personal email is required"}
                      </small>
                    )}
                  </div>

                  <div className="col-12 mt-3 row no-gutters">
                    <div className={`col-6 pr-2 mt-n3`}>
                      <small>Upload the appointment letter </small>
                      <Button
                        fullWidth
                        className="d-flex justify-content-start"
                        component="label"
                        style={{
                          //float: "right",
                          borderRadius: "0px",
                          height: "35px",
                          border: `1px solid #6c757d`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "#6c757d",
                          "&:hover": {
                            backgroundColor: "#6c757d",
                          },
                        }}
                        size="md"
                      >
                        <input
                          accept="application/pdf"
                          type="file"
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              fileHasError: false,
                              fileError: "",
                            });
                            const file = e.target.files[0];
                            setFile(file || null);
                          }}
                        />
                      </Button>
                      {errors.fileHasError && (
                        <small className="text-danger">
                          {errors.fileError || "Attachment is required"}
                        </small>
                      )}
                    </div>
                    <div className="col-6  pl-2 mt-1">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          maxDate={new Date()}
                          label="Employment Date"
                          inputFormat="MM/dd/yyyy"
                          value={employeeForm.employmentDate}
                          onChange={(date) => {
                            setEmployeeForm({
                              ...employeeForm,
                              employmentDate: date,
                            });

                            setErrors({
                              ...errors,
                              employmentDateHasError: false,
                              employmentDateError: "",
                            });
                          }}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                      {errors.employmentDateHasError && (
                        <small className="text-danger mt-1">
                          {errors.employmentDateError ||
                            "Employment is required"}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  disabled={
                    loading ||
                    !(
                      wasVerified
                      // && isAvailable
                    )
                  }
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase  px-4 mt-4"
                >
                  {loading ? "Wait..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className=" py-1"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  employCandidate,
})(EmployCandidateDialog);
