import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Box,
  Button,
} from "@mui/material";

import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { saveContract } from "../../../store/rbm/actions";
import NumberFormat from "react-number-format";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const ContractForm = (props) => {
  const {
    user,
    selectedFiscalYear,
    saveContract,
    setShowDialog,
    showDialog,
    isEditing,
    setIsEditing,
    loading,
    totalWeight,
    subordinate,
  } = props;

  const [contractType, setContractType] = useState({
    id: 1,
    name: "Normal",
  });

  const [formData, setFormData] = useState({
    contractTypeId: 1,
    startOn: new Date(selectedFiscalYear.startOn),
    endOn: new Date(selectedFiscalYear.endOn),
  });

  const onClose = () => {
    setShowDialog(false);
    setFormData({
      contractTypeId: 1,

      startOn: new Date(selectedFiscalYear.startOn),
      endOn: new Date(selectedFiscalYear.endOn),
    });
  };

  const onSave = () => {
    saveContract(
      {
        employeeId: subordinate.id,
        employeePositionId: subordinate.employeePositionId,
        contractTypeId: contractType.id,
        startOn: formData.startOn,
        endOn: formData.endOn,
      },
      onClose
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography className="text-primary text-uppercase d-flex px-4">
            <span className="material-icons">post_add</span> Create Contract
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ minHeight: "25vh" }} className="px-5">
          <div className="mt-3">
            <Autocomplete
              className="mt-3"
              disablePortal
              size="small"
              value={contractType}
              options={[
                {
                  id: 1,
                  name: "Normal",
                },
                {
                  id: 2,
                  name: "Acting",
                },
                {
                  id: 3,
                  name: "Probation",
                },
                {
                  id: 4,
                  name: "Second probation",
                },
              ]}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                setContractType(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contract Type"
                  placeholder="Contract Type"
                />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              )}
            />
          </div>

          <div className="mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                allowSameDateSelection
                minDate={new Date(selectedFiscalYear.startOn)}
                maxDate={new Date(selectedFiscalYear.endOn)}
                label="Start date [ dd/MM/yyyy ]"
                value={formData.startOn}
                onChange={(startOn) => {
                  setFormData({
                    ...formData,
                    startOn,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    name="startOn"
                    variant="outlined"
                    className="mb-3"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

          <div className="mt-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                allowSameDateSelection
                minDate={new Date(selectedFiscalYear?.startOn)}
                maxDate={new Date(selectedFiscalYear?.endOn)}
                label="End date [ dd/MM/yyyy ]"
                value={formData.endOn}
                onChange={(endOn) => {
                  setFormData({
                    ...formData,
                    endOn,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    size="small"
                    name="endOn"
                    variant="outlined"
                    className="mb-3"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-4"
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, selectedFiscalYear }) => {
  return { user, loading, selectedFiscalYear };
};
export default connect(mapStateToProps, {
  saveContract,
})(ContractForm);
