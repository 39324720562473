import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { saveOutput } from "../../../store/rbm/actions";
import NumberFormat from "react-number-format";

const OutputForm = (props) => {
  const {
    selectedOutput,
    saveOutput,
    closeOutputForm,
    outputFormOpen,
    isEditing,
    setIsEditing,
    loading,
    totalWeight,
    totalRecods,
    setTotalRecods,
  } = props;

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    weight: 3,
    budget: 0,
  });

  useLayoutEffect(() => {
    if (isEditing)
      setFormData({
        id: selectedOutput.id,
        name: selectedOutput.name,
        weight: selectedOutput.weight,
        budget: selectedOutput.budget,
      });
  }, [selectedOutput, isEditing]);

  const onSave = () => {
    saveOutput(formData, setFormData, setIsEditing, closeOutputForm, () =>
      setTotalRecods(totalRecods + 1)
    );
  };

  const onClose = () => {
    closeOutputForm();
    setIsEditing(false);
    setError("");
    setFormData({
      id: null,
      name: "",
      weight: 3,
      budget: 0,
    });
  };

  const [error, setError] = useState("");

  // const checkWeight = (value) => {
  //   if (!isEditing && (+value < 0 || +value + +totalWeight > 100))
  //     setError(`Invalid weight. ${(100 - +totalWeight).toFixed(2)}% allowed`);
  //   else if (
  //     isEditing &&
  //     (+value < 0 || +value + +totalWeight > 100 + +selectedOutput.weight)
  //   )
  //     setError(
  //       `Invalid weight. ${(
  //         100 -
  //         +totalWeight +
  //         +selectedOutput.weight
  //       ).toFixed(2)}% allowed`
  //     );
  //   else setError("");
  // };

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={outputFormOpen}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography className="text-primary text-uppercase">
            {isEditing ? "Edit" : "Add New"} Output
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            size="small"
            multiline
            autoFocus
            rows={4}
            name="name"
            label="Name"
            variant="outlined"
            placeholder="Output description"
            className="mb-3 mt-3"
            value={formData.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => {
              const name = e.target.value;
              setFormData({ ...formData, name });
            }}
          />

          {/* <TextField
            error={!!error}
            fullWidth
            size="small"
            type="number"
            label="Weight"
            min="0"
            max="100"
            name="weight"
            variant="outlined"
            placeholder="0"
            className="mb-3"
            helperText={error}
            value={formData.weight}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
            onBlur={() => {
              if (formData.weight === "")
                setFormData({
                  ...formData,
                  weight: 0,
                });
            }}
            onKeyDown={() => {
              if (+formData.weight === 0)
                setFormData({
                  ...formData,
                  weight: "",
                });
            }}
            onChange={(e) => {
              const weight = e.target.value;
              checkWeight(+weight);
              setFormData({ ...formData, weight: weight || 0 });
            }}
          /> */}

          <Autocomplete
            fullWidth
            size="small"
            className="mb-3"
            value={weights.find(({ id }) => id === formData.weight)}
            options={weights}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, weight) => {
              setFormData({
                ...formData,
                weight: weight?.id,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Weight" placeholder="Weight" />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
          />

          <NumberFormat
            fullWidth
            size="small"
            label="Budget"
            name="budget"
            variant="outlined"
            placeholder="RWF 0"
            className="mb-3"
            prefix={"RWF "}
            customInput={TextField}
            value={formData.budget}
            onBlur={() => {
              if (formData.budget === "")
                setFormData({
                  ...formData,
                  budget: 0,
                });
            }}
            onKeyDown={() => {
              if (+formData.budget === 0)
                setFormData({
                  ...formData,
                  budget: "",
                });
            }}
            onChange={(e) => {
              const budget = e.target.value.split("RWF ");
              setFormData({
                ...formData,
                budget: budget[1] ? budget[1].replace(/,/g, "") : 0,
              });
            }}
            thousandSeparator={true}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-5"
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps, {
  saveOutput,
})(OutputForm);
