import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Radio,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import { updateExamQuestion } from "../../../store/recruitment/actions";

import { showError } from "../../../app/toastify";
import ActionDialog from "../../common/components/ActionDialog";
import ErrorMessage from "../../common/components/ErrorMessage";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

const Input = (props) => {
  const { option, index, setQuestionForm, questionForm } = props;
  const [value, setValue] = useState(
    option.description ? option.description : ""
  );
  return (
    <>
      <TextField
        fullWidth
        size="small"
        name={"assertion" + option.optionNumber}
        autoFocus
        label={"Option " + option.optionNumber}
        variant="outlined"
        placeholder={"Option " + option.optionNumber}
        value={value}
        InputProps={{
          startAdornment: <InputAdornment position="start"></InputAdornment>,
        }}
        onChange={(e) => {
          const value = e.target.value || "";
          const { multipleChoiceOptions } = questionForm;
          multipleChoiceOptions[index].description = value;
          setValue(value);
          setQuestionForm({
            ...questionForm,
            multipleChoiceOptions,
          });
        }}
      />
    </>
  );
};

const Checkboxx = (props) => {
  const { option, index, setQuestionForm, questionForm } = props;

  return (
    <>
      <div className="check-box">
        <input
          checked={option.isCorrect}
          onChange={(e) => {
            const { multipleChoiceOptions } = questionForm;
            const isCorrectIndex = multipleChoiceOptions.findIndex(
              ({ isCorrect }) => !!isCorrect
            );
            multipleChoiceOptions[isCorrectIndex].isCorrect = false;
            multipleChoiceOptions[index].isCorrect = true;
            setQuestionForm({
              ...questionForm,
              multipleChoiceOptions,
            });
          }}
          className="inputchekerbx"
          type="checkbox"
        />

        <label className="checked">
          <span>
            {option.isCorrect && (
              <DoneRoundedIcon
                style={{
                  marginTop: "-50px",
                  color: "#fff",
                }}
              />
            )}
          </span>
        </label>
      </div>
    </>
  );
};
const EditWrittenExamQuestionDialog = (props) => {
  const {
    loading,
    updateExamQuestion,
    question,
    showEditQuestion,
    setShowEditQuestion,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const [questionForm, setQuestionForm] = useState({
    examQuestionId: question ? question.id : 0,
    description: question ? question.question.description : "",
    questionTypeId:
      question && question.question.questionTypeId
        ? question.question.questionTypeId
        : 0,
    examId: question.examId,
    questionId: question.questionId,
    multipleChoiceOptions:
      question &&
      question.question.questionTypeId === 1 &&
      !!question.question.multipleChoiceOptions.length
        ? question.question.multipleChoiceOptions
        : [],
    marks: question && question.marks ? question.marks : 0,
  });

  const [errors, setErrors] = useState({
    descriptionHasError: false,
    marksHasError: false,
  });

  const formValidator = () => {
    const error = {
      descriptionHasError: false,
      marksHasError: false,
    };

    if (!questionForm.description) {
      error.descriptionHasError = true;
      error.hasError = true;
    }
    if (!questionForm.marks || questionForm.marks <= 0) {
      error.marksHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = async () => {

    if (!formValidator()) {
  
      let hasCorrectAnswer = false;
      let hasEmptyAnswer = false;

      questionForm.multipleChoiceOptions.forEach((option) => {
        if (option.isCorrect) hasCorrectAnswer = true;
        if (!option.description) hasEmptyAnswer = true;
      });

      if (questionForm.questionTypeId === 1 && !hasCorrectAnswer) {
        showError("Please make sure you have a correct answer!");
        return false;
      }

      if (hasEmptyAnswer) {
        showError("Please check a required fields!");
        return false;
      }
      updateExamQuestion(questionForm, onClose, setIsUpdating);
    }
  };

  const onClose = () => {
    setQuestionForm({
      examQuestionId: 0,
      description: "",
      questionTypeId: 0,
      examId: "",
      questionId: 0,
      multipleChoiceOptions: [],
      marks: 0,
    });
    setShowEditQuestion(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditQuestion}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Edit Question
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div className="row">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="description"
                  autoFocus
                  label="Type your question .."
                  variant="outlined"
                  placeholder="Type your question  .."
                  value={questionForm.description}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const description = e.target.value;
                    setQuestionForm({
                      ...questionForm,
                      description,
                    });
                    const descriptionHasError = false;
                    setErrors({ ...errors, descriptionHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.descriptionHasError}
                  message="Question is required"
                />
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  name="marks"
                  autoFocus
                  label="Marks"
                  variant="outlined"
                  placeholder="Marks"
                  value={questionForm.marks}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const marks = e.target.value;
                    setQuestionForm({
                      ...questionForm,
                      marks,
                    });
                    const marksHasError = false;
                    setErrors({ ...errors, marksHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.marksHasError}
                  message="Marks is required or equal to zero."
                />
              </div>
            </div>
            {questionForm.questionTypeId === 1 && (
              <>
                {questionForm.multipleChoiceOptions.map((option, index) => (
                  <div className="mt-3 answers__options" key={index}>
                    <div className="opt-one answer__option">
                      <h4>{option.optionNumber}</h4>

                      <Checkboxx
                        option={option}
                        index={index}
                        setQuestionForm={setQuestionForm}
                        questionForm={questionForm}
                      />
                    </div>
                    <div className="opt-two answer__desc">
                      <Input
                        option={option}
                        index={index}
                        setQuestionForm={setQuestionForm}
                        questionForm={questionForm}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}

            {isUpdating && (
              <ActionDialog showAction={isUpdating} action="Updating ..." />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {isUpdating ? "Updating..." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  updateExamQuestion,
})(EditWrittenExamQuestionDialog);
