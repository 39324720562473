import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";

import React from "react";
import UserResume from "../../profile/UserResume";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const EmployeeProfileDialog = (props) => {
  const { employee, showEmployeeProfileDialog, setShowEmployeeProfileDialog } =
    props;

  const onClose = () => {
    setShowEmployeeProfileDialog(false);
  };
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeProfileDialog}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="text-dark">Current Profile | </span>
            {employee && employee.lastName} {employee && employee.firstName}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <UserResume
            canEdit={hasPermissions(["IS_HR"])}
            allowedToEditEmploymentDate={hasPermissions(["IS_HR"])}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeProfileDialog;
