import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

const RejectionReasonDialog = (props) => {
  const { showRejectionReason, setShowRejectionReason, selectedApplication } =
    props;

  const onClose = () => {
    setShowRejectionReason(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRejectionReason}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Reason of rejection
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
        <div className="w-100  float-start">
            <div className="d-flex flex-row justify-content-start mt-0 mr-3 w-100 float-start">
              <div
                className="card  w-100"
                style={{
                  backgroundColor: "#fff",
                  borderLeft: "2px solid #3f51b5",
                  borderLeft: "2px solid #3f51b5",
                  borderRadius: "4px",
                }}
              >
             
                <div className="card-body w-100">
                  <p
                    className="small p-0 ms-2 mb-0 rounded-3"
                    dangerouslySetInnerHTML={{
                      __html: selectedApplication.reason ||
                      selectedApplication.applicantRejectionReason,
                    }}
                  ></p>

                 
                </div>
              </div>
            </div>
          </div>
         
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RejectionReasonDialog;
