import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import Proflile from "../profile";
import MyAcount from "./pages/MyAcount";

const Layout = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { user } = props;

  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);

  return (
    <React.Fragment>
      {!isEmpty(user) && (
        <>
          <>
            {/* <nav>
              <div
                className="py-1 d-flex flex-wrap justify-content-between mt-0  align-items-center px-4"
                style={{
                  backgroundColor: "#078ece",
                  borderBottom: "1px solid #078ece",
                  borderTop: "1px solid #078ece",
                }}
              ></div>
            </nav> */}
            <div style={{ marginTop: "0px" }}>
              <div style={{ backgroundColor: "#078ece", height: "6px" }}></div>
              <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
              <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
            </div>
          </>
          <div className={`container-fluid pt-2 px-4 `} id="main-container">
            <Switch>
              <Route path="/user/profile" component={Proflile} />
              <Route path="/user/account" component={MyAcount} />

              <Route
                path="/user"
                component={() => <Redirect to="/user/profile" />}
              />
            </Switch>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, {})(Layout);
