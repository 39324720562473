import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment";
import {
  changeLeavePlanStatus,
  changeOtherLeaveStatus,
  countLeaveDays,
  geEmployeeInLeavePeriod,
} from "../../../store/leave/actions";
import AlertDialog from "../../profile/components/AlertDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import PlanAnnualLeaveForm from "./PlanAnnualLeaveForm";
import ViewLeaveLetter from "./ViewLeaveLetter";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ViewSamePeriodStaff from "./ViewSamePeriodStaff";

const ViewLeaveDetails = (props) => {
  const {
    setShowRequest,
    title,
    leaveRequest,
    loading,
    selectedFiscalYear,
    countLeaveDays,
    changeLeavePlanStatus,
    changeOtherLeaveStatus,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [comment, setComment] = React.useState("");
  const [error, setError] = React.useState("");
  const [countingDays, setCountingDays] = useState(false);
  const [plannedDays, setPlannedDays] = useState(null);
  const [viewOtherEmployees, setViewOtherEmployees] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decision, setDecision] = useState(null);
  const [requestedToReject, setRequestedToReject] = useState(false);
  const [changeDecision, setChangeDecision] = useState(false);
  const [postponeThisLeave, setPostponeThisLeave] = useState(false);
  const [showLeaveLetter, setShowLeaveLetter] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");
  const [showPDFLetter, setShowPDFLetter] = useState(false);
  const [showPDFSupport, setShowPDFSupport] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    if (
      !!leaveRequest.startDate &&
      !!leaveRequest.endDate &&
      !!leaveRequest.isAnnualLeave
    ) {
      countLeaveDays(
        {
          startDate: leaveRequest.startDate,
          endDate: leaveRequest.endDate,
          leavePlanId: leaveRequest?.id || "",
          employeePositionId: leaveRequest?.employeePositionId || "",
        },
        (loading, data) => {
          setCountingDays(loading);
          if (!loading) setPlannedDays(data);
        }
      );
    }
  }, []);

  const onClose = () => {
    setError("");
    setCountingDays(false);
    setViewOtherEmployees(false);
    setIsSubmitting(false);
    setDecision(null);
    setChangeDecision(false);
    setPostponeThisLeave(false);
    setShowLeaveLetter(false);
    setShowApproveRequestForm(false);
    setPkiPassword("");
    setComment("");
    setShowRequest(null);
  };

  const handleChangeStatus = (statusId) => {
    if (!comment && statusId === 7) {
      if (leaveRequest.statusId === 10) setShowFeedback(true);
      setError("Message is required for rejection");
      setDecision(null);
      return;
    }

    if (!!leaveRequest.isAnnualLeave) {
      const formData = {
        leavePlanId: leaveRequest.id,
        statusId,
        comment,
        isForSupervisor: true,
        password: pkiPassword,
      };
      changeLeavePlanStatus(formData, (loading, res) => {
        setIsSubmitting(loading);
        if (!loading && res) {
          onClose();
        }
      });
    } else {
      const formData = {
        leaveRequestId: leaveRequest.id,
        statusId,
        comment,
        isForSupervisor: true,
        password: pkiPassword,
      };
      changeOtherLeaveStatus(formData, (loading, res) => {
        setIsSubmitting(loading);
        if (!loading && res) {
          onClose();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-dark pb-2 ">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            {title}
            <strong className="text-primary">{selectedFiscalYear?.name}</strong>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="py-1">
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">Leave Type</th>
                <td>{leaveRequest.leaveType}</td>
              </tr>
              {!leaveRequest.isAnnualLeave && (
                <tr>
                  <th scope="row">Leave Reason</th>
                  <td>{leaveRequest.leaveReason}</td>
                </tr>
              )}
              <tr>
                <th scope="row">Employee Names</th>
                <td>{leaveRequest.employeeNames}</td>
              </tr>
              <tr>
                <th scope="row">Position</th>
                <td>
                  {leaveRequest.positionName} ({leaveRequest.employeeGroupName})
                </td>
              </tr>
              <tr>
                <th scope="row">Number of Days</th>
                <td>{leaveRequest.numOfDays} Days</td>
              </tr>
              <tr>
                <th scope="row">Date to start leave</th>
                <td>{moment(leaveRequest.startDate).format("LL")}</td>
              </tr>
              <tr>
                <th scope="row">Date to return at work</th>
                <td>{moment(leaveRequest.endDate).format("LL")}</td>
              </tr>
              <tr>
                <th scope="row">Employee comment</th>
                <td>{leaveRequest.employeeComment}</td>
              </tr>
              <tr>
                <th scope="row">Created On</th>
                <td>{moment(leaveRequest.createdOn).format("LL")}</td>
              </tr>
              <tr>
                <th scope="row">Status</th>
                <td>
                  <span
                    className={`badge ${
                      leaveRequest.statusId === 2 || leaveRequest.statusId === 9
                        ? "badge-primary"
                        : leaveRequest.statusId === 4
                        ? "badge-info"
                        : leaveRequest.statusId === 5
                        ? "badge-light"
                        : leaveRequest.statusId === 6
                        ? "badge-success"
                        : leaveRequest.statusId === 7
                        ? "badge-danger"
                        : "badge-secondary"
                    }`}
                  >
                    <span
                      className="material-icons-round"
                      style={{
                        fontSize: "0.8rem",
                        verticalAlign: "middle",
                        marginRight: "0.2rem",
                      }}
                    >
                      {leaveRequest.statusId === 2
                        ? "done"
                        : leaveRequest.statusId === 3
                        ? "done_all"
                        : leaveRequest.statusId === 4
                        ? "done_all"
                        : leaveRequest.statusId === 5
                        ? "fast_forward"
                        : leaveRequest.statusId === 6
                        ? "stop"
                        : leaveRequest.statusId === 7
                        ? "close"
                        : leaveRequest.statusId === 9
                        ? "next_plan"
                        : "alarm_on"}
                    </span>
                    <span>{leaveRequest.statusName}</span>
                  </span>
                </td>
              </tr>
              {(!!leaveRequest.letterReferenceId ||
                !!leaveRequest.fileReferenceId) && (
                <tr>
                  <th scope="row">Document</th>
                  <td>
                    {leaveRequest.fileReferenceId && (
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => setShowPDFSupport(true)}
                        startIcon={
                          <span className="material-icons-round">
                            attach_file
                          </span>
                        }
                      >
                        Support Doc
                      </Button>
                    )}{" "}
                    {leaveRequest.letterReferenceId && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setShowPDFLetter(true)}
                        startIcon={
                          <span className="material-icons-round">
                            attach_file
                          </span>
                        }
                      >
                        Authorization Letter
                      </Button>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {countingDays && (
            <React.Fragment>
              <Skeleton animation="wave" height={10} className="mt-3 mb-1" />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          )}
          {!countingDays &&
          plannedDays &&
          !!plannedDays.otherEmployeesWithLeave ? (
            <Alert
              variant="standard"
              severity="success"
              color="warning"
              className="mt-3 mb-1"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setViewOtherEmployees(true)}
                >
                  VIEW
                </Button>
              }
            >
              Other <b>{plannedDays.otherEmployeesWithLeave}</b> staff
              {plannedDays.otherEmployeesWithLeave > 1 ? "s" : ""} have planned
              leave for the same period.
            </Alert>
          ) : null}
          {((([1, 3].includes(leaveRequest.statusId) ||
            !!changeDecision ||
            !!requestedToReject) &&
            hasPermissions(["IS_HEAD_OF_UNIT", "IS_HEAD_OF_INSTITUTION"])) ||
            !!showFeedback) && (
            <TextField
              error={!!error}
              helperText={error}
              fullWidth
              size="small"
              multiline
              rows={3}
              // autoFocus={assignment.targetShare === 0}
              name="supervisorMessage"
              label="Message"
              placeholder="Message"
              variant="outlined"
              className="mt-3"
              value={comment}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onChange={(e) => {
                setComment(e.target.value);
                setError("");
              }}
              disabled={loading || countingDays || isSubmitting}
            />
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          {/* {![1, 3].includes(leaveRequest.statusId) && !changeDecision && (
            <button
              onClick={() => setChangeDecision(true)}
              type="button"
              className="btn btn-sm btn-info text-uppercase px-4 mr-2"
              disabled={loading || countingDays || isSubmitting}
            >
              Change Decision
            </button>
          )} */}

          {(leaveRequest.statusId === 1 || !!changeDecision) &&
            hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <button
                onClick={() => {
                  setDecision(10);
                }}
                type="button"
                className="btn btn-sm btn-primary text-uppercase px-4 mr-2"
                disabled={loading || countingDays || isSubmitting}
              >
                {isSubmitting && decision === 2 ? "Wait..." : "Approve"}
              </button>
            )}

          {(leaveRequest.statusId === 3 || !!changeDecision) &&
            hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <button
                onClick={() => {
                  setShowLeaveLetter(true);
                }}
                type="button"
                className="btn btn-sm btn-primary text-uppercase px-4 mr-2"
                disabled={loading || countingDays || isSubmitting}
              >
                {isSubmitting ? "Wait..." : "Approve"}
              </button>
            )}

          {leaveRequest.statusId === 3 &&
            !!leaveRequest.isAnnualLeave &&
            hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <button
                onClick={() => setDecision(9)}
                type="button"
                className="btn btn-sm btn-secondary text-uppercase px-4 mr-2"
                disabled={loading || countingDays || isSubmitting}
              >
                {isSubmitting && decision === 9 ? "Wait..." : "Postpone"}
              </button>
            )}

          {([1].includes(leaveRequest.statusId) ||
            (leaveRequest.statusId === 3 && !leaveRequest.isAnnualLeave) ||
            requestedToReject) &&
            hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <button
                onClick={() => setDecision(7)}
                type="button"
                className="btn btn-sm btn-danger text-uppercase px-4"
                disabled={
                  loading || countingDays || isSubmitting || !comment.length
                }
              >
                {isSubmitting && decision === 7 ? "Wait..." : "Reject"}
              </button>
            )}
          {([10].includes(leaveRequest.statusId) ||
            ([2, 10].includes(leaveRequest.statusId) &&
              !!leaveRequest.isAnnualLeave)) &&
            hasPermissions(["IS_HEAD_OF_UNIT"]) &&
            !requestedToReject && (
              <button
                onClick={() => setRequestedToReject(true)}
                type="button"
                className="btn btn-sm btn-warning text-uppercase px-4"
                disabled={loading || isSubmitting}
              >
                Request to Reject
              </button>
            )}
        </DialogActions>
      </Dialog>

      {viewOtherEmployees && (
        <ViewSamePeriodStaff
          leave={leaveRequest}
          onClose={() => setViewOtherEmployees(false)}
        />
      )}

      {decision && (
        <AlertDialog
          status={true}
          handleClose={() => setDecision(null)}
          handleConfirm={() =>
            decision === 9
              ? setPostponeThisLeave(true)
              : handleChangeStatus(decision)
          }
          title={`${
            [2, 4, 10].includes(decision)
              ? "Approve"
              : decision === 7
              ? "Reject"
              : "Postpone"
          } this leave ${leaveRequest.statusId === 1 ? "plan" : "request"}
            `}
          desc={`Are you sure you want to ${
            [2, 4, 10].includes(decision)
              ? "approve"
              : decision === 7
              ? "reject"
              : "postpone"
          } this leave ${leaveRequest.statusId === 1 ? "plan" : "request"}?`}
          disabled={loading || isSubmitting}
        />
      )}

      {postponeThisLeave && (
        <PlanAnnualLeaveForm
          showCreateAnnualLeave={true}
          setShowCreateAnnualLeave={() => {
            setPostponeThisLeave(false);
            setDecision(null);
          }}
          leaveToPostpone={leaveRequest}
        />
      )}

      {showApproveRequestForm && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>

              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            handleChangeStatus(4);
          }}
        />
      )}
      {showLeaveLetter && (
        <ViewLeaveLetter
          setShowLeaveLetter={setShowLeaveLetter}
          showLeaveLetter={showLeaveLetter}
          leaveRequest={leaveRequest}
          signCallBack={
            <>
              <button
                onClick={() => {
                  // setDecision([1, 7].includes(leaveRequest.statusId) ? 2 : 4)
                  setShowApproveRequestForm(true);
                }}
                type="button"
                className="btn btn-sm btn-primary text-uppercase px-4 mr-2"
                disabled={loading || countingDays || isSubmitting}
              >
                {isSubmitting && [2, 4].includes(decision) ? "Wait..." : "Sign"}
              </button>
            </>
          }
        />
      )}

      {(!!showPDFLetter || !!showPDFSupport) && (
        <PreviewPdfUrl
          showDocument={true}
          setShowDocument={() => {
            setShowPDFLetter(false);
            setShowPDFSupport(false);
          }}
          document={{
            name: `View ${
              showPDFLetter ? "Leave letter" : "Supporting Document"
            }`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              (showPDFLetter
                ? leaveRequest?.letterReferenceId
                : leaveRequest?.fileReferenceId),
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user, selectedFiscalYear }) => {
  return {
    loading,
    user,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, {
  countLeaveDays,
  geEmployeeInLeavePeriod,
  changeLeavePlanStatus,
  changeOtherLeaveStatus,
})(ViewLeaveDetails);
