import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { doRejectDeductionRequest } from "../../../store/payroll/actions";
import { showError } from "../../../app/toastify";
import { Editor } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NoRecordMessage from "../../common/components/NoRecordMessage";
const RejectDeductionRequestDialog = (props) => {
  const {
    loading,
    level,
    doRejectDeductionRequest,
    confirmRejectedEmployeeWithhold,
              setConfirmRejectedEmployeeWithhold,
              selectedEmployeeWithhold
  } = props;

  const [formData, setFormData] = useState({
    requestId: selectedEmployeeWithhold.id,
    comments: ``,
  });
  const [errors, setErrors] = useState({
    commentHasError: false,
  });

  const [askToReject, setAskToReject] = useState(false);

  const formValidator = () => {
    const error = {
      commentHasError: false,
      hasError: false,
    };

    if (!formData.comments) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
     doRejectDeductionRequest(formData, onClose);
    }
  };

  const onClose = () => {
    setFormData({
      requestId: "",
      comments: ``,
    });

    setConfirmRejectedEmployeeWithhold(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={confirmRejectedEmployeeWithhold}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <span className="d-flex align-items-center">
            Reject Request
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12 mt-2">
                <NoRecordMessage
                  isEmpty={true}
                  title={`Are you sure you want to reject this deduction request (${selectedEmployeeWithhold.creditorName}) for
                      ${selectedEmployeeWithhold.firstName} ${selectedEmployeeWithhold.lastName}
                    ?`}
                />
              </div>

              <div className="col-12 mt-2">
                <ReactQuill
                  theme="snow"
                  placeholder="Type your reason..."
                  value={formData.comments || ""}
                  modules={Editor.modules}
                  formats={Editor.formats}
                  onChange={(value) => {
                    const comments = value;
                    setFormData({
                      ...formData,
                      comments,
                    });
                    const commentHasError = false;
                    setErrors({ ...errors, commentHasError });
                  }}
                />

                {errors.commentHasError && (
                  <div className="text-danger mt-2">Reason is required </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-danger text-uppercase  btn-sm px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Confirm"}
          </button>

          {/* <button
            onClick={onClose}
            type="button"
            className="btn btn-secondary text-uppercase  px-4 btn-sm"
          >
            Cancel
          </button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  doRejectDeductionRequest,
})(RejectDeductionRequestDialog);
