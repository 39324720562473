import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import {
  assignUserRole,
  editEmploymentData,
  getEmployeeByEntitySectorId,
} from "../../../store/support/actions";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  getEntitySectors,
  getTreeUnits,
} from "../../../store/structure/actions";
import { getAdminCostCenter, getRoles } from "../../../store/admin/actions";
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";

const AssignEmployeeRole = (props) => {
  const {
    actionData,
    setActionData,
    editEmploymentData,
    getEmployeeByEntitySectorId,
    entitySectors,
    getEntitySectors,
    units,
    getTreeUnits,
    roles,
    getRoles,
    adminCostCenter,
    getAdminCostCenter,
    assignUserRole,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeePositions, setEmployeePositions] = useState([]);
  const [formData, setFormData] = useState({
    startDate: actionData.employee.startDate,
    endDate: actionData.employee.endDate,
    employeePositionId: actionData.employee.employeePositionId,
    indexTopUp: actionData.employee.indexTopUp,
    action: actionData.action,
    employeePositionId1: null,
    employeePositionId2: null,
    entity: null,
    unit: null,
    role: null,
    constCenter: null,
    payrollLevel: null,
    applyPayrollLevel: false,
  });
  const [errors, setErrors] = useState({
    startDateHasError: false,
    startDateErrorMessage: "",
    endDateHasError: false,
    endDateErrorMessage: "",
    indexTopUpHasError: false,
    indexTopUpErrorMessage: "",
    employeePositionId1HasError: false,
    employeePositionId1ErrorMessage: "",
    employeePositionId2HasError: false,
    employeePositionId2ErrorMessage: "",
    hasError: false,
    errorMessage: "",
  });

  const onClose = () => {
    setFormData({
      startDate: null,
      endDate: null,
      employeePositionId: null,
      indexTopUp: null,
      action: null,
      employeePositionId1: null,
      employeePositionId2: null,
      entity: null,
      unit: null,
      role: null,
      constCenter: null,
      payrollLevel: null,
      applyPayrollLevel: false,
    });
    setErrors({
      startDateHasError: false,
      startDateErrorMessage: "",
      endDateHasError: false,
      endDateErrorMessage: "",
      indexTopUpHasError: false,
      indexTopUpErrorMessage: "",
      employeePositionId1HasError: false,
      employeePositionId1ErrorMessage: "",
      employeePositionId2HasError: false,
      employeePositionId2ErrorMessage: "",
      hasError: false,
      errorMessage: "",
    });
    setActionData(null);
  };

  useEffect(() => {
    if (actionData?.action === "apply-prorata") {
      getEmployeeByEntitySectorId(
        {
          entitySectorId: actionData.employee.entitySectorId,
          employeeId: actionData.employee.id,
        },
        (status, data) => {
          setLoading(status);
          if (!status && !!data) {
            setEmployeePositions(data);
            const activePosition = data.filter((item) =>
              [29, 0].includes(item.statusId)
            );
            const prevPosition = data.filter((item) => item.statusId === 30);
            if (activePosition.length === 1) {
              setFormData({
                ...formData,
                employeePositionId2: activePosition[0].employeePositionId,
              });
            }
            if (prevPosition.length === 1) {
              setFormData({
                ...formData,
                employeePositionId1: prevPosition[0].employeePositionId,
              });
            }
          }
        }
      );
    }
    if (actionData.action === "assign-role") {
      if (entitySectors.length === 0) {
        getEntitySectors();
      }

      if (roles.length === 0) {
        getRoles();
      }
    }
  }, [actionData]);

  useEffect(() => {
    if (!!formData.employeePositionId1 && !!formData.employeePositionId2) {
      const prevIndex = employeePositions.find(
        (item) => item.employeePositionId === formData.employeePositionId1
      );
      const activeIndex = employeePositions.find(
        (item) => item.employeePositionId === formData.employeePositionId2
      );

      if (!!prevIndex && !!activeIndex) {
        setFormData({
          ...formData,
          indexTopUp: prevIndex.indexBaseProrata - activeIndex.indexBasePay,
        });
      }
    }
  }, [formData.employeePositionId1, formData.employeePositionId2]);

  const onSubmit = () => {
    let hasError = false;
    if (formData.action === "change-end-date" && !formData.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        startDateErrorMessage: "Start date is required",
      });
      hasError = true;
    }
    if (formData.action === "change-end-date" && !formData.endDate) {
      setErrors({
        ...errors,
        endDateHasError: true,
        endDateErrorMessage: "End date is required",
      });
      hasError = true;
    }

    if (formData.action === "apply-prorata" && !formData.employeePositionId1) {
      setErrors({
        ...errors,
        employeePositionId1HasError: true,
        employeePositionId1ErrorMessage: "Previous position is required",
      });
      hasError = true;
    }

    if (formData.action === "apply-prorata" && !formData.employeePositionId2) {
      setErrors({
        ...errors,
        employeePositionId2HasError: true,
        employeePositionId2ErrorMessage: "Active position is required",
      });
      hasError = true;
    }

    if (
      (formData.action === "apply-index-topup" ||
        actionData.action === "apply-prorata") &&
      !formData.indexTopUp &&
      +formData.indexTopUp !== 0
    ) {
      setErrors({
        ...errors,
        indexTopUpHasError: true,
        indexTopUpErrorMessage: "Index TopUp is required",
      });
      hasError = true;
    }

    if (formData.action === "assign-role") {
      if (!formData.entity) {
        setErrors({
          ...errors,
          hasError: true,
          errorMessage: "Entity sector is required",
        });
        hasError = true;
      }
      if (!formData.unit) {
        setErrors({
          ...errors,
          hasError: true,
          errorMessage: "Unit is required",
        });
        hasError = true;
      }
      if (!formData.role) {
        setErrors({
          ...errors,
          hasError: true,
          errorMessage: "Role is required",
        });
        hasError = true;
      }
      if (
        ["CBM", "DAF", "HR"].includes(formData.role?.id) &&
        formData.applyPayrollLevel &&
        !formData.constCenter
      ) {
        setErrors({
          ...errors,
          hasError: true,
          errorMessage: "Cost Center is required",
        });
        hasError = true;
      }

      if (
        ["CBM", "DAF", "HR"].includes(formData.role?.id) &&
        formData.applyPayrollLevel &&
        !formData.payrollLevel
      ) {
        setErrors({
          ...errors,
          hasError: true,
          errorMessage: "Payroll Level is required",
        });
        hasError = true;
      }
    }

    if (hasError) return;

    if (formData.action === "assign-role") {
      assignUserRole({
        entitySectorId: formData.entity.id,
      unitId: formData.unit.unitId,
      employeeId: actionData.employee.id,
      roleId: formData.role.id,
      costCenterId: formData.constCenter?.id||null,
      approvalLevel: formData.payrollLevel||null,
      applyPayrollLevel: formData.applyPayrollLevel||false,
      }, (status, res) => {
        setIsSubmitting(status);
        if (!status && res) {
          onClose();
        }
      });
    } else {
      editEmploymentData(formData, (status, res) => {
        setIsSubmitting(status);
        if (!status && res) {
          onClose();
        }
      });
    }
  };

  useEffect(() => {
    if (actionData.action === "assign-role" && !!formData.entity) {
      getTreeUnits(null, null, formData.entity.id);
    }
  }, [formData.entity]);

  useEffect(() => {
    if (
      actionData.action === "assign-role" &&
      ["CBM", "DAF", "HR"].includes(formData.role?.id) &&
      !!formData.applyPayrollLevel
    ) {
      if (!adminCostCenter.length) getAdminCostCenter();
    }
  }, [formData.role, formData.applyPayrollLevel]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "70%" }}
        >
          {actionData.action === "change-end-date"
            ? "Change Employment End Date"
            : actionData.action === "apply-index-topup"
            ? "Apply Index TopUp"
            : actionData.action === "apply-prorata"
            ? "Apply Prorata"
            : actionData.action === "assign-role"
            ? "Assign Role"
            : ""}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      {(isSubmitting || loading) && <LinearProgress />}

      {errors.hasError && (
        <Alert severity="error" className="m-3">
          {errors.errorMessage}
        </Alert>
      )}

      <DialogContent className="py-2">
        {actionData.action === "change-end-date" && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="d-flex align-items-center mb-3">
              <DesktopDatePicker
                label="Start date"
                inputFormat="dd/MM/yyyy"
                disabled={loading || isSubmitting || true}
                value={formData.startDate}
                onChange={(date) => {
                  setFormData({ ...formData, startDate: date });
                  setErrors({
                    ...errors,
                    startDateHasError: false,
                    startDateErrorMessage: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
              />
              <span className="mx-2">to</span>
              <DesktopDatePicker
                label="End date"
                inputFormat="dd/MM/yyyy"
                disabled={loading || isSubmitting}
                value={formData.endDate}
                onChange={(date) => {
                  setFormData({ ...formData, endDate: date });
                  setErrors({
                    ...errors,
                    endDateHasError: false,
                    endDateErrorMessage: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
              />
            </div>
          </LocalizationProvider>
        )}

        {actionData.action === "apply-prorata" && (
          <>
            <Autocomplete
              disabled={!employeePositions.length || isSubmitting || loading}
              size="small"
              id="prevEmployeePosition"
              defaultValue={null}
              value={
                employeePositions.find(
                  (type) =>
                    type.employeePositionId === formData.employeePositionId1
                ) || null
              }
              options={employeePositions.filter(
                (item) => ![29, 0].includes(item.statusId)
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, option) => {
                if (option) {
                  setFormData({
                    ...formData,
                    employeePositionId1: option.employeePositionId,
                  });
                  setErrors({
                    ...errors,
                    employeePositionId1HasError: false,
                    employeePositionId1ErrorMessage: "",
                  });
                }
              }}
              getOptionLabel={(option) => option.positionName}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <span>
                    <span className="d-block text-primary">{option.names}</span>
                    <small className="d-block">
                      {!!option.isActing && (
                        <span className="text-primary font-weight-bold">
                          Ag.
                        </span>
                      )}{" "}
                      {option.positionName}
                    </small>
                    <small className="d-block text-uppercase mb-1">
                      {option.unitName}
                    </small>
                    <Divider />
                    <Divider />
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Previous Position"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "prevEmployeePosition", // disable autocomplete and autofill
                  }}
                />
              )}
              className="mb-3"
            />

            <Autocomplete
              disabled={!employeePositions.length || isSubmitting || loading}
              size="small"
              id="activeEmployeePosition"
              defaultValue={null}
              value={
                employeePositions.find(
                  (type) =>
                    type.employeePositionId === formData.employeePositionId2
                ) || null
              }
              options={employeePositions.filter((item) =>
                [29, 0].includes(item.statusId)
              )}
              isOptionEqualToValue={(option, value) =>
                option.employeePositionId === value.employeePositionId
              }
              onChange={(event, option) => {
                if (option) {
                  setFormData({
                    ...formData,
                    employeePositionId2: option.employeePositionId,
                  });
                  setErrors({
                    ...errors,
                    employeePositionId2HasError: false,
                    employeePositionId2ErrorMessage: "",
                  });
                }
              }}
              getOptionLabel={(option) => option.positionName}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <span>
                    <span className="d-block text-primary">{option.names}</span>
                    <small className="d-block">
                      {!!option.isActing && (
                        <span className="text-primary font-weight-bold">
                          Ag.
                        </span>
                      )}{" "}
                      {option.positionName}
                    </small>
                    <small className="d-block text-uppercase mb-1">
                      {option.unitName}
                    </small>
                    <Divider />
                    <Divider />
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Active Position"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "activeEmployeePosition", // disable autocomplete and autofill
                  }}
                />
              )}
              className="mb-3"
            />
          </>
        )}

        {(actionData.action === "apply-index-topup" ||
          actionData.action === "apply-prorata") && (
          <TextField
            error={errors.indexTopUpHasError}
            helperText={errors.indexTopUpErrorMessage}
            fullWidth
            size="small"
            label="Index TopUp"
            type="number"
            name="indexTopUp"
            variant="outlined"
            defaultChecked
            placeholder="Index TopUp"
            className="mb-3"
            disabled={loading || isSubmitting}
            value={formData.indexTopUp || ""}
            onBlur={() => {
              if (formData.indexTopUp === "")
                setFormData({
                  ...formData,
                  indexTopUp: 0,
                });
            }}
            // onKeyDown={() => {
            //   if (+formData.indexTopUp < 0)
            //     setFormData({
            //       ...formData,
            //       indexTopUp: 0,
            //     });
            // }}
            onChange={(e) => {
              const indexTopUp = e.target.value;
              setFormData({
                ...formData,
                indexTopUp: +indexTopUp || 0,
              });
              setErrors({
                ...errors,
                indexTopUpHasError: false,
                indexTopUpErrorMessage: "",
              });
            }}
          />
        )}

        {actionData.action === "assign-role" && (
          <>
            <Autocomplete
              loading={loading}
              size="small"
              id="entity"
              defaultValue={null}
              value={formData.entity}
              options={entitySectors}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, entity) => {
                setFormData({
                  ...formData,
                  entity: entity || null,
                  unit: null,
                });
              }}
              getOptionLabel={(option) =>
                option?.entity.name +
                (formData.entity?.entity.acronym
                  ? "(" + formData.entity?.entity.acronym + ")"
                  : "") +
                " / " +
                option.sector?.name
              }
              renderOption={(props, entity) => (
                <Box component="li" {...props}>
                  {entity?.entity.name}
                  {entity?.entity.acronym && (
                    <>({entity?.entity.acronym})</>
                  )} / {entity?.sector.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  // required={true}
                  {...params}
                  label="Select the institution"
                  placeholder="Select the institution"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: "entity", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              size="small"
              id="selectedUnit"
              defaultValue={null}
              value={formData.unit || null}
              options={units || []}
              isOptionEqualToValue={(option, value) =>
                option.unitId === value.unitId
              }
              onChange={(event, selectedUnit) => {
                setFormData({
                  ...formData,
                  unit: selectedUnit || null,
                });
              }}
              getOptionLabel={(option) => option.unitName}
              renderOption={(props, selectedUnit) => (
                <>
                  <Box component="li" {...props}>
                    {selectedUnit.unitName}
                  </Box>
                  <Divider />
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Unit"
                  placeholder="Unit"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: "selectedUnit",
                  }}
                />
              )}
              className="mt-3"
            />

            <Autocomplete
              size="small"
              id="role"
              defaultValue={null}
              value={formData.role || null}
              options={roles || []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, role) => {
                setFormData({
                  ...formData,
                  role: role || null,
                  constCenter: null,
                  payrollLevel:
                    role?.id === "CBM"
                      ? "APPROVED"
                      : role?.id === "DAF"
                      ? "VERIFIED"
                      : role?.id === "HR"
                      ? "GENERATED"
                      : null,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, role) => (
                <>
                  <Box component="li" {...props}>
                    {role.name}
                  </Box>
                  <Divider />
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  placeholder="Role"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: "role",
                  }}
                />
              )}
              className="mt-3"
            />

            {["CBM", "DAF", "HR"].includes(formData.role?.id) && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.applyPayrollLevel}
                    onChange={() =>
                      setFormData({
                        ...formData,
                        applyPayrollLevel: !formData.applyPayrollLevel,
                      })
                    }
                  />
                }
                label="Apply Payroll Approval Level?"
                className="mt-2"
              />
            )}

            {["CBM", "DAF", "HR"].includes(formData.role?.id) &&
              formData.applyPayrollLevel && (
                <Autocomplete
                  size="small"
                  id="constCenter"
                  defaultValue={null}
                  value={formData.constCenter || null}
                  options={adminCostCenter || []}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, constCenter) => {
                    setFormData({
                      ...formData,
                      constCenter: constCenter || null,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, constCenter) => (
                    <>
                      <Box component="li" {...props}>
                        {constCenter.name}
                      </Box>
                      <Divider />
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cost Center"
                      placeholder="Cost Center"
                      inputProps={{
                        ...params.inputProps,
                        // autoComplete: "constCenter",
                      }}
                    />
                  )}
                  className="mt-1"
                />
              )}

            {["CBM", "DAF", "HR"].includes(formData.role?.id) &&
              formData.applyPayrollLevel && (
                <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                  <div
                    className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer ${
                      formData.payrollLevel === "GENERATED"
                        ? "border-primary text-primary"
                        : "border-secondary"
                    }`}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        payrollLevel: "GENERATED",
                      })
                    }
                  >
                    {formData.payrollLevel === "GENERATED" ? (
                      <RadioButtonCheckedRounded fontSize="small" />
                    ) : (
                      <RadioButtonUncheckedRounded fontSize="small" />
                    )}{" "}
                    GENERATE
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer mx-1 ${
                      formData.payrollLevel === "VERIFIED"
                        ? "border-primary text-primary"
                        : "border-secondary"
                    }`}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        payrollLevel: "VERIFIED",
                      })
                    }
                  >
                    {formData.payrollLevel === "VERIFIED" ? (
                      <RadioButtonCheckedRounded fontSize="small" />
                    ) : (
                      <RadioButtonUncheckedRounded fontSize="small" />
                    )}{" "}
                    VERIFY
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center w-content rounded-sm border px-2 py-1 cursor-pointer ${
                      formData.payrollLevel === "APPROVED"
                        ? "border-primary text-primary"
                        : "border-secondary"
                    }`}
                    onClick={() =>
                      setFormData({
                        ...formData,
                        payrollLevel: "APPROVED",
                      })
                    }
                  >
                    {formData.payrollLevel === "APPROVED" ? (
                      <RadioButtonCheckedRounded fontSize="small" />
                    ) : (
                      <RadioButtonUncheckedRounded fontSize="small" />
                    )}{" "}
                    APPROVE
                  </div>
                </div>
              )}

            {["CBM", "DAF"].includes(formData.role?.id) &&
              formData.applyPayrollLevel && (
                <Alert severity="warning" className="mt-3">
                  This is action will disable other staff from same payroll
                  approval level
                </Alert>
              )}
          </>
        )}

        <div className="d-flex justify-content-center mt-3">
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({
  loading,
  user,
  entitySectors,
  units,
  roles,
  adminPayroll,
}) => {
  return {
    loading,
    user,
    entitySectors,
    units,
    roles,
    adminCostCenter: adminPayroll.adminCostCenter,
  };
};

export default connect(mapStateToProps, {
  editEmploymentData,
  getEmployeeByEntitySectorId,
  getEntitySectors,
  getTreeUnits,
  getRoles,
assignUserRole,
  getAdminCostCenter,
})(AssignEmployeeRole);
