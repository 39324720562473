import { defaultState } from "./state";
import leaveActionTypes from "./action-types";
import commonTypes from "../common/action-types";
import { el } from "date-fns/locale";
const types = { ...commonTypes, ...leaveActionTypes };

// public holidays reducer
const publicHolidays = (
  publicHolidaysState = defaultState.publicHolidays,
  action
) => {
  switch (action.type) {
    case types.SET_PUBLIC_HOLIDAYS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.publicHolidays;

    default:
      return publicHolidaysState;
  }
};

// Annual Leave Plans reducer
const myLeavePlans = (
  myLeavePlansState = defaultState.myLeavePlans,
  action
) => {
  switch (action.type) {
    case types.SET_MY_LEAVE_PLANS:
      return action.data;

    case types.CANCEL_LEAVE_PLAN:
      return myLeavePlansState.filter(
        (leavePlan) => leavePlan.id !== action.data
      );

    case types.CHANGE_LEAVE_PLAN_STATUS: {
      if (!action.data) {
        return myLeavePlansState;
      }
      let index = myLeavePlansState.findIndex(
        (leavePlan) => leavePlan.id === action.data.id
      );
      myLeavePlansState[index] = action.data;
      return myLeavePlansState;
    }

    case types.CLEAN_STATE:
      return defaultState.myLeavePlans;

    default:
      return myLeavePlansState;
  }
};

// My Unit Leave Plans reducer
const myUnitLeavePlans = (
  myUnitLeavePlansState = defaultState.myUnitLeavePlans,
  action
) => {
  switch (action.type) {
    case types.SET_MY_UNIT_LEAVE_PLANS:
      return action.data;
    case types.CHANGE_LEAVE_PLAN_STATUS: {
      if (!action.data) {
        return myUnitLeavePlansState;
      }
      let leaves = [...myUnitLeavePlansState];
      let index = leaves.findIndex(
        (leavePlan) => leavePlan.id === action.data.id
      );
      leaves[index] = action.data;
      return leaves;
    }
    case types.ACCEPT_LEAVE_PLANS: {
      if (!action.data[0]) {
        return myUnitLeavePlansState;
      }
      let leaves = [...myUnitLeavePlansState];
      action.data.forEach((plan) => {
        let index = leaves.findIndex((leavePlan) => leavePlan.id === plan.id);
        leaves[index] = plan;
      });
      return leaves;
    }
    case types.CLEAN_STATE:
      return defaultState.myUnitLeavePlans;
    default:
      return myUnitLeavePlansState;
  }
};

// Leave Types reducer
const leaveTypes = (leaveTypesState = defaultState.leaveTypes, action) => {
  switch (action.type) {
    case types.SET_LEAVE_TYPES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.leaveTypes;
    default:
      return leaveTypesState;
  }
};

// My Other Leave Requests reducer
const myOtherLeaveRequests = (
  myOtherLeaveRequestsState = defaultState.myOtherLeaveRequests,
  action
) => {
  switch (action.type) {
    case types.SET_MY_OTHER_LEAVE_REQUESTS:
      return action.data;

    case types.CANCEL_OTHER_LEAVE_REQUEST:
      return myOtherLeaveRequestsState.filter(
        (request) => request.id !== action.data
      );

    case types.CHANGE_OTHER_LEAVE_REQUEST_STATUS: {
      if (!action.data) {
        return myOtherLeaveRequestsState;
      }
      let index = myOtherLeaveRequestsState.findIndex(
        (request) => request.id === action.data.id
      );
      myOtherLeaveRequestsState[index] = action.data;
      return myOtherLeaveRequestsState;
    }

    case types.CLEAN_STATE:
      return defaultState.myOtherLeaveRequests;

    default:
      return myOtherLeaveRequestsState;
  }
};

// Other Leave Requests reducer
const otherLeaveRequests = (
  otherLeaveRequestsState = defaultState.otherLeaveRequests,
  action
) => {
  switch (action.type) {
    case types.SET_OTHER_LEAVE_REQUESTS:
      return action.data;
    case types.CHANGE_OTHER_LEAVE_REQUEST_STATUS: {
      if (!action.data) {
        return otherLeaveRequestsState;
      }
      let index = otherLeaveRequestsState.findIndex(
        (leavePlan) => leavePlan.id === action.data.id
      );
      otherLeaveRequestsState[index] = action.data;
      return otherLeaveRequestsState;
    }
    case types.CLEAN_STATE:
      return defaultState.otherLeaveRequests;
    default:
      return otherLeaveRequestsState;
  }
};

const annualEmployeeLeaves = (
  annualLEmployeeLeavesState = defaultState.annualEmployeeLeaves,
  action
) => {
  switch (action.type) {
    case types.SET_ANNUAL_LEAVE_EMPLOYEES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.annualEmployeeLeaves;
    default:
      return annualLEmployeeLeavesState;
  }
};

const weeklyLeaveSummary = (
  weeklyLeaveSummaryState = defaultState.weeklyLeaveSummary,
  action
) => {
  switch (action.type) {
    case types.SET_WEEKLY_LEAVE_SUMMARY:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.weeklyLeaveSummary;
    default:
      return weeklyLeaveSummaryState;
  }
};

const unitWithAnnualLeave = (
  unitWithAnnualLeaveState = defaultState.unitWithAnnualLeave,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_WITH_ANNUAL_LEAVE:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.unitWithAnnualLeave;
    default:
      return unitWithAnnualLeaveState;
  }
};

const unitWithOtherLeave = (
  unitWithOtherLeaveState = defaultState.unitWithOtherLeave,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_WITH_OTHER_LEAVE:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.unitWithOtherLeave;
    default:
      return unitWithOtherLeaveState;
  }
};

export default {
  publicHolidays,
  myLeavePlans,
  myUnitLeavePlans,
  leaveTypes,
  myOtherLeaveRequests,
  otherLeaveRequests,
  annualEmployeeLeaves,
  weeklyLeaveSummary,
  unitWithAnnualLeave,
  unitWithOtherLeave,
};
