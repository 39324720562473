import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";

import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { connect } from "react-redux";
import { logout } from "../../../store/common/actions";
import { isEmpty } from "lodash";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Drawer = (props) => {
  const {
    drawerState,
    setDrawerState,
    user,
    logout,
    selectedModule,
    userProfilePicture,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const { hasPermissions } = useRequiredAuth();

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed  ${drawerState ? "active" : ""}`}
      >
        <div>
          <div
            className="drawer-user "
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
            {userProfilePicture && (
              <img
                src={`data:image/png;base64,${userProfilePicture}`}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            {!userProfilePicture && (
              <img
                src={defaultProfile}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            <div>
              <Tooltip title="Go to your profile">
                <Link
                  to="/rbm/profile"
                  style={{ color: "#b8ff07" }}
                  className="font-weight-bold text-uppercase"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  {user.firstName} {user.lastName}
                </Link>
              </Tooltip>
            </div>
            {user.employments.map((employment, index) => (
              <small className=" " style={{ color: "#ff0" }} key={index}>
                {employment.positionName}
              </small>
            ))}
            <br />
            <small style={{ color: "#fff" }}>
              <>
                {!isEmpty(user) &&
                  !isEmpty(user.selectedEntitySector) &&
                  !isEmpty(selectedModule) && (
                    <>
                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length > 0 && (
                        <>
                          {user.selectedEntitySector.roles[
                            selectedModule.id
                          ]?.map((role, index) => (
                            <span key={index}>
                              {" "}
                              {role.name}
                              {user.selectedEntitySector.roles[
                                selectedModule.id
                              ].length !==
                                index + 1 && <>,</>}
                            </span>
                          ))}
                        </>
                      )}

                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length === 0 && <>Employee</>}
                    </>
                  )}
              </>
            </small>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>
          <List>
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/rbm/my-institution")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                history.push("/rbm/my-institution");
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">calendar_view_month</span>
                </ListItemIcon>
                <Link to="/rbm/my-institution" className="text-dark">
                  <ListItemText
                    primary="My Institution"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/rbm/my-unit") ? "active" : ""
              }`}
              onClick={() => {
                history.push("/rbm/my-unit");
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span
                    className="material-icons"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    account_tree{" "}
                  </span>
                </ListItemIcon>
                <Link to="/rbm/my-unit" className="d-block text-dark">
                  <ListItemText
                    primary="My Unit"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/rbm/my-subordinates")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                history.push("/rbm/my-subordinates");
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">groups</span>
                </ListItemIcon>
                <Link to="/rbm/my-subordinates" className="text-dark">
                  <ListItemText
                    primary="My Subordinates"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            {hasPermissions([
              "CAN_ACCESS_RBM_MONITOR_PROGRESS",
              "IS_TECHNICAL_HEAD",
            ]) && (
              <ListItem
                disablePadding
                className={`nav-item  ${
                  location.pathname.includes("/rbm/monitor") ? "active" : ""
                }`}
                onClick={() => {
                  history.push("/rbm/monitor");
                  setDrawerState(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon className="item-icon text-dark">
                    <span className="material-icons">preview</span>
                  </ListItemIcon>
                  <Link to="/rbm/monitor" className="text-dark">
                    <ListItemText
                      primary="Monitor progress"
                      className="pl-0"
                    ></ListItemText>
                  </Link>
                </ListItemButton>
              </ListItem>
            )}

            {hasPermissions(["CAN_ACCESS_RBM_OVERSIGHT"]) && (
              <ListItem
                disablePadding
                className={`nav-item  ${
                  location.pathname.includes("/rbm/oversight") ? "active" : ""
                }`}
                onClick={() => {
                  history.push("/rbm/oversight");
                  setDrawerState(false);
                }}
              >
                <ListItemButton>
                  <ListItemIcon className="item-icon text-dark">
                    <span className="material-icons"> remove_red_eye</span>
                  </ListItemIcon>
                  <Link to="/rbm/oversight" className="text-dark">
                    <ListItemText
                      primary="RBM Oversight"
                      className="pl-0"
                    ></ListItemText>
                  </Link>
                </ListItemButton>
              </ListItem>
            )}

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/rbm/user-guide") ? "active" : ""
              }`}
              onClick={() => {
                history.push("/rbm/user-guide");
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">local_library</span>
                </ListItemIcon>
                <Link to="/rbm/user-guide" className="text-dark">
                  <ListItemText
                    primary="User-Guide"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Button
          className="btn btn-block mb-3 "
          color="error"
          style={{ position: "absolute", bottom: "0" }}
          onClick={() => logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${drawerState ? "d-block" : "d-none"} `}
        onClick={() => {
          setDrawerState(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule, userProfilePicture }) => {
  return { user, selectedModule, userProfilePicture };
};
export default connect(mapStateToProps, { logout })(Drawer);
