import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const SubmissionRecruitmentReportLetter = (props) => {
  const {
    institutionName,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
    docContentTextEditor = "",
    docContent = "",
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className={docContentTextEditor ? "" : "auth-content"}>
            <div className="recipient">
              {/* <!-- Recipient's name and address go here --> */}

              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "13.6px",
                  lineHeight: "1.5",
                }}
              >
                Executive Secretary, <br />
                National Public Service Commission, <br />
                <strong
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  KIGALI
                </strong>
              </p>
            </div>
            <div className="content2 mb-2">
              {/* <!-- Letter content goes here --> */}
              <p>Dear Executive Secretary,</p>
              <p style={{ fontWeight: "bold" }}>
                Re: Submission of Recruitment Report.
              </p>

              {enableToFillLetter ? (
                docContentTextEditor
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: docContent,
                    }}
                  ></div>
                </>
              )}
              <p>Sincerely,</p>
            </div>
       
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmissionRecruitmentReportLetter;
