import { defaultState } from "./state";
import eappeal from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...eappeal };

const applealTypes = (
  applealTypesState = defaultState.applealTypes,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_TYPES: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.applealTypes;
    default:
      return applealTypesState;
  }
};

const appeals = (appealsState = defaultState.appeals, action) => {
  switch (action.type) {
    case types.SET_APPEALS: {
      return action.data;
    }

    case types.UPDATE_APPEAL: {
      const tmpAppealsState = [...appealsState];
      const index = tmpAppealsState.findIndex(
        ({ referenceNo, applicationId }) =>
          (referenceNo &&
            action.data.referenceNo &&
            referenceNo === action.data.referenceNo) ||
          (applicationId &&
            action.data.applicationId &&
            applicationId === action.data.applicationId)
      );
      tmpAppealsState[index] = action.data;

      return tmpAppealsState;
    }

    case types.CLEAN_STATE:
      return defaultState.appeals;
    default:
      return appealsState;
  }
};

const appeal = (appealState = defaultState.appeal, action) => {
  switch (action.type) {
    case types.SET_APPEAL: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appeal;
    default:
      return appealState;
  }
};
const appealResolved = (
  appealResolvedState = defaultState.appealResolved,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_RESOLVED: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appealResolved;
    default:
      return appealResolvedState;
  }
};

const appealDetails = (
  appealDetailsState = defaultState.appealDetails,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_DETAILS: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appealDetails;
    default:
      return appealDetailsState;
  }
};

export default {
  applealTypes,
  appeals,
  appeal,
  appealDetails,
  appealResolved,
};
