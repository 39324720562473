import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getOtherLeaveRequest,
  getUnitWithLeaves,
} from "../../../store/leave/actions";
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import ViewLeaveDetails from "../components/ViewLeaveDetails";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { otherLeaveStatus } from "../../common/components/Utils";

const OtherLeaveRequests = (props) => {
  const {
    loading,
    otherLeaveRequests,
    getOtherLeaveRequest,
    unitWithOtherLeave,
    getUnitWithLeaves,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [loadingLeaveData, setLoadingLeaveData] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loadingUnitLeaves, setLoadingUnitLeaves] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState({
    id: 3,
    name: "New Request",
  });
  const [selectedLeaveReason, setSelectedLeaveReason] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleLoadLeaves = (query) => {
    getOtherLeaveRequest(query, (status, data) => {
      setLoadingLeaveData(status);
      if (!status && !!data) {
        setTotalRecords(data.totalRecords);
      }
    });
  };

  useEffect(() => {
    if (otherLeaveRequests.length === 0) {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        unitId: selectedUnit?.id || "",
        statusId: selectedLeaveStatus?.id || 3,
        leaveTypeId: selectedLeaveType?.id || "",
        leaveReasonId: selectedLeaveReason?.id || "",
        page: _currentPage,
        size: itemPerPage,
      };
      handleLoadLeaves(query);
    } else setLoadingLeaveData(false);
    if (!hasPermissions(["IS_HEAD_OF_UNIT"]) && !unitWithOtherLeave.length) {
      getUnitWithLeaves(
        { leaveType: "other", status: selectedLeaveStatus.id || "" },
        setLoadingUnitLeaves
      );
    } else setLoadingUnitLeaves(false);

    return () => {
      setLoadingLeaveData(true);
      setLoadingUnitLeaves(true);
    };
  }, []);

  useEffect(() => {
    if (!hasPermissions(["IS_HEAD_OF_UNIT"]) && !unitWithOtherLeave.length) {
      getUnitWithLeaves(
        { leaveType: "other", status: selectedLeaveStatus.id || "" },
        setLoadingUnitLeaves
      );
    }
  }, [selectedLeaveStatus]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        unitId: selectedUnit?.id || "",
        statusId: selectedLeaveStatus?.id || 3,
        leaveTypeId: selectedLeaveType?.id || "",
        leaveReasonId: selectedLeaveReason?.id || "",
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadLeaves(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      unitId: selectedUnit?.id || "",
      statusId: selectedLeaveStatus?.id || 3,
      leaveTypeId: selectedLeaveType?.id || "",
      leaveReasonId: selectedLeaveReason?.id || "",
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadLeaves(query);
  };

  return (
    <>
      <div className="row w-100 my-3 px-4">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <span className="text-uppercase text-primary font-weight-bold">
              Other Leave Requests
            </span>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2 mt-3">
            <div className="form-group w-100 mt-3">
              <input
                name="search"
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      isForPlan: true,
                      searchTerm: searchTerm,
                      unitId: selectedUnit?.id || "",
                      statusId: selectedLeaveStatus?.id || 3,
                      leaveTypeId: selectedLeaveType?.id || "",
                      leaveReasonId: selectedLeaveReason?.id || "",
                      page: currentPage,
                      size: itemPerPage,
                    };

                    handleLoadLeaves(query);
                  }
                }}
              />
            </div>

            <div className="ml-2">
              <Autocomplete
                id="leaveStatus"
                options={otherLeaveStatus}
                getOptionLabel={(option) => option.name}
                style={{ width: 150 }}
                onChange={(event, newValue) => {
                  setSelectedLeaveStatus(newValue);
                  const query = {
                    isForPlan: true,
                    searchTerm: searchTerm,
                    unitId: selectedUnit?.id || "",
                    statusId: newValue?.id || 3,
                    leaveTypeId: selectedLeaveType?.id || "",
                    leaveReasonId: selectedLeaveReason?.id || "",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  handleLoadLeaves(query);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Leave Status"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>

            {!hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <div className="ml-2">
                <Autocomplete
                  id="unit"
                  options={unitWithOtherLeave}
                  getOptionLabel={(option) => (
                    <span>
                      {option.name} ({option.leaves})
                    </span>
                  )}
                  style={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setSelectedUnit(newValue);
                    const query = {
                      isForPlan: true,
                      searchTerm: searchTerm,
                      unitId: newValue ? newValue.id : "",
                      statusId: selectedLeaveStatus?.id || 3,
                      leaveTypeId: selectedLeaveType?.id || "",
                      leaveReasonId: selectedLeaveReason?.id || "",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadLeaves(query);
                  }}
                  disabled={loadingLeaveData || loadingUnitLeaves}
                  loading={loadingUnitLeaves}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Unit"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
          </div>

          <TableContainer>
            <Table size="small" aria-label="Your annual leave balance">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Leave Type</TableCell>
                  <TableCell>Leave Reason</TableCell>
                  <TableCell>Starting On</TableCell>
                  <TableCell>Ending On</TableCell>
                  <TableCell>Leave Days</TableCell>
                  {/* <TableCell>Planned On</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell className="text-right">Action</TableCell>
                </TableRow>
              </TableHead>
              {loadingLeaveData ? (
                <TableSkeleton cols={11} isMUI />
              ) : (
                <TableBody>
                  {otherLeaveRequests.map((request, index) => (
                    <OtherLeaveRequestsRow
                      item={request}
                      index={index}
                      loading={loadingLeaveData || loading}
                      setSelectedRequest={setSelectedRequest}
                      key={request.id}
                    />
                  ))}
                  {!otherLeaveRequests.length && (
                    <TableRow>
                      <TableCell colSpan={11} className="text-center">
                        No leave request found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>

      {!!selectedRequest && (
        <ViewLeaveDetails
          leaveRequest={selectedRequest}
          setShowRequest={setSelectedRequest}
          title={"Leave Request Details for " + selectedRequest.employeeNames}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  otherLeaveRequests,
  unitWithOtherLeave,
}) => {
  return {
    loading,
    user,
    otherLeaveRequests,
    unitWithOtherLeave,
  };
};
export default connect(mapStateToProps, {
  getOtherLeaveRequest,
  getUnitWithLeaves,
})(OtherLeaveRequests);

export const OtherLeaveRequestsRow = (props) => {
  const { index, item, loading, setSelectedRequest, isMe } = props;

  return (
    <TableRow
      key={item.id}
      hover
      sx={{
        cursor: !!setSelectedRequest && "pointer",
      }}
      className="tr-hovered"
      onClick={() => setSelectedRequest(item)}
    >
      <TableCell>{index + 1}</TableCell>
      <TableCell>{item.unitName}</TableCell>
      <TableCell>
        {item.employeeNames}{" "}
        {isMe && (
          <span
            className="badge badge-primary px-1"
            style={{
              fontSize: "9px",
            }}
          >
            ME
          </span>
        )}
      </TableCell>
      <TableCell>
        {item.positionName}({item.employeeGroupName})
      </TableCell>
      <TableCell>{item.leaveType}</TableCell>
      <TableCell>{item.leaveReason}</TableCell>
      <TableCell>{moment(item.startDate).format("LL")}</TableCell>
      <TableCell>{moment(item.endDate).format("LL")}</TableCell>
      <TableCell>{item.numOfDays} Days</TableCell>
      {/* <TableCell>{moment(item.itemnedOn).format("LL")}</TableCell> */}
      <TableCell>
        <span
          className={`badge ${
            item.statusId === 2 || item.statusId === 9
              ? "badge-primary"
              : item.statusId === 4
              ? "badge-info"
              : item.statusId === 5
              ? "badge-light"
              : item.statusId === 6
              ? "badge-success"
              : item.statusId === 7
              ? "badge-danger"
              : "badge-secondary"
          }`}
        >
          <span
            className="material-icons-round"
            style={{
              fontSize: "0.8rem",
              verticalAlign: "middle",
              marginRight: "0.2rem",
            }}
          >
            {item.statusId === 2
              ? "done"
              : item.statusId === 3
              ? "done_all"
              : item.statusId === 4
              ? "done_all"
              : item.statusId === 5
              ? "fast_forward"
              : item.statusId === 6
              ? "stop"
              : item.statusId === 7
              ? "close"
              : item.statusId === 9
              ? "next_item"
              : "alarm_on"}
          </span>
          <span>{item.statusName}</span>
        </span>
      </TableCell>
      {!!setSelectedRequest && (
        <TableCell className="text-right">
          <Button
            onClick={() => setSelectedRequest(item)}
            variant="text"
            size="small"
            disabled={loading}
          >
            View
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};
