import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import moment from "moment";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { getFiscalYears } from "../../../store/rbm/actions";
import AddTrainingRequestDialog from "../components/AddTrainingRequestDialog";
import { getCountries } from "../../../store/structure/actions";
import {
  getTrainingRequests,
  getCdPlans,
  getSingleTrainingRequest,
} from "../../../store/training/actions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../common/components/TableSkeleton";
import ViewTrainingRequestDialog from "../components/ViewTrainingRequestDialog";
import BackdropLoader from "../../common/components/BackdropLoader";
import { useRef } from "react";
import { requestStatusColor } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import RequestCard from "../components/RequestCard";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const InprogressRequests = (props) => {
  const {
    user,
    countries,
    getCountries,
    trainingRequests,
    fiscalYears,
    cdPlans,
    getTrainingRequests,
    getFiscalYears,
    getCdPlans,
    getSingleTrainingRequest,
    env,
  } = props;
  useEffect(() => {
    document.title = `IPPIS - Training(${trainingRequests.length}) | InProgress Requests`;
  });
  const { hasPermissions } = useRequiredAuth();
  const [addTrainingRequestForm, setAddTrainingRequestForm] = useState(false);
  const [showTrainingRequestDetails, setShowTrainingRequestDetails] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const containerRef = useRef(null);
  const [filteredRequests, setFilteredRequests] = useState([
    ...trainingRequests,
  ]);
  const theme = useTheme();
  useEffect(() => {
    if (!fiscalYears.length) getFiscalYears();
    if (!cdPlans.length) getCdPlans(user?.selectedEntitySector?.entityId);
  }, []);
  useEffect(() => {
    if (!countries.length) getCountries();
    if (!trainingRequests.length) getTrainingRequests(setLoading);
  }, []);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 15;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(trainingRequests);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, trainingRequests]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % trainingRequests.length || 0;

    setItemOffset(newOffset);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-3">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>TRAINING</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      InProgress Requests
                    </span>
                  </span>
                </Typography>
              </div>

              <div className="col-lg-7 text-center">
               
              </div>

              <div className="col-lg-2">
                {hasPermissions(["IS_HR", "IS_TRAINING_INITIATOR"]) && !!user.selectedEntitySector .canUseTrainingModule && (
                  <Button
                    onClick={() => {
                      setAddTrainingRequestForm(true);
                    }}
                    style={{
                      ...ActiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span>Create Request
                  </Button>
                )}
              </div>
            </Toolbar>
          </AppBar>
          {addTrainingRequestForm && (
            <AddTrainingRequestDialog
              addTrainingRequestForm={addTrainingRequestForm}
              setAddTrainingRequestForm={setAddTrainingRequestForm}
            />
          )}
          {showTrainingRequestDetails && (
            <ViewTrainingRequestDialog
              showTrainingRequestDetails={showTrainingRequestDetails}
              setShowTrainingRequestDetails={setShowTrainingRequestDetails}
            />
          )}

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              
              <table className="table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      No
                    </th>
                    <th scope="col" className="text-left">
                      EmployeeName
                    </th>
                    {/* <th scope="col" className="text-left">
                      TrainingTitle
                    </th> */}

                    <th scope="col" className="text-left">
                      Institution
                    </th>

                    <th scope="col" className="text-left">
                      Position
                    </th>

                    <th scope="col" className="text-left">
                      RequestType
                    </th>
                    <th scope="col" className="text-right">
                      RequestedAt
                    </th>
                    <th scope="col" className="text-right">
                      DepartureDate
                    </th>

                    <th scope="col" className="text-right">
                      Status
                    </th>

                    <th scope="col" className="text-right">
                      <span className="mr-3">Actions</span>
                    </th>
                  </tr>
                </thead>

                {loading ? (
                  <TableSkeleton cols={9} />
                ) : (
                  <tbody>
                    {paginatedItems.map((request, index) => (
                      <RequestCard
                        user={user}
                        request={request}
                        index={index}
                        key={index}
                        setLoading1={setLoading1}
                        loading1={loading1}
                        setShowTrainingRequestDetails={
                          setShowTrainingRequestDetails
                        }
                        env={env}
                        getSingleTrainingRequest={getSingleTrainingRequest}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              {!!paginatedItems.length &&
                trainingRequests.length >= itemsPerPage && (
                  <div
                    className="justify-content-center mt-2"
                    style={{ overflow: "hidden" }}
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  </div>
                )}

              <NoRecordMessage
                isEmpty={!trainingRequests.length && !loading}
                title="No Results found"
              />
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  countries,
  trainingRequests,
  cdPlans,
  fiscalYears,
  env,
}) => {
  return {
    user,
    loading,
    countries,
    trainingRequests,
    cdPlans,
    fiscalYears,
    env,
  };
};
export default connect(mapStateToProps, {
  getCountries,
  getTrainingRequests,
  getFiscalYears,
  getCdPlans,
  getSingleTrainingRequest,
})(InprogressRequests);
