import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import EmployeeExitRequestDialog from "./EmployeeExitRequestDialog";
import { connect, useDispatch } from "react-redux";

import {
  stopActing,
  getEmployeeBankAccounts,
  getEmployeeProfile,
  getEmployeePromotions,
  doVerifyRSSBNumber,
  doVerifyNIDNumber,
} from "../../../store/structure/actions";
import types from "../../../store/common/action-types";
import { downloadEmployeeDeductionsReport } from "../../../store/payroll/actions";

import { getSingleActiveContract } from "../../../store/contractuals/actions";
import { getEmployeeExitRequest } from "../../../store/exit/actions";
import { getCase } from "../../../store/discipline/actions";
import { getSelectedEmployeeProfilePicture } from "../../../store/common/actions";
import PreviewEmployeePaySlipDialog from "./PreviewEmployeePaySlipDialog";
import EmployeePayHistoryDialog from "./EmployeePayHistoryDialog";
import EmployeeSalarySettingsDialog from "./EmployeeSalarySettingsDialog";
import EmployeePromotionDialog from "./EmployeePromotionDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { currencyFormat } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import EmployeePBFSettingsDialog from "./EmployeePBFSettingsDialog";
import UserResume from "../../profile/UserResume";
import EmployeeSubordinatesDialog from "../../rbm/components/EmployeeSubordinatesDialog";
import ApproveExitDialog from "../../exit/components/ApproveExitDialog";
import NewDisplinarlyCaseRequestDialog from "../../discipline/components/NewDisplinarlyCaseRequestDialog";
import ViewCaseDialog from "../../discipline/components/ViewCaseDialog";

const EmployeeCard = (props) => {
  const {
    loading,
    selectedEntitySector,
    showInstitutionName,
    employee,
    employeeIndex,
    showActionMenus,
    showUnitName,
    stopActing,
    getEmployeeBankAccounts,
    getEmployeeProfile,
    getEmployeePromotions,
    getSelectedEmployeeProfilePicture,
    getSingleActiveContract,
    selectedUnit,
    getEmployeeExitRequest,
    downloadEmployeeDeductionsReport,
    doVerifyRSSBNumber,
    doVerifyNIDNumber,
    getCase,
    env,
    showBRDTotalRecovery = true,
  } = props;

  const [showEmployeeProfileDialog, setShowEmployeeProfileDialog] =
    useState(false);
  const [showEmployeeExitForm, setShowEmployeeExitForm] = useState(false);
  const [showPendingEmployeeExit, setShowPendingEmployeeExit] = useState(false);
  const [showEmployeePaySlip, setShowEmployeePaySlip] = useState(false);
  const [showEmployeePayHistory, setShowEmployeePayHistory] = useState(false);
  const [showEmployeeSubordinates, setShowEmployeeSubordinates] =
    useState(false);
  const [
    showNewDisplinarlyCaseRequestDialog,
    setShowNewDisplinarlyCaseRequestDialog,
  ] = useState(false);
  const [showCaseDetail, setShowCaseDetail] = useState(false);
  const [isLoading, setShowIsLoading] = useState(false);
  // const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showPBFSettingsDialog, setShowPBFSettingsDialog] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const checkDisabled = (employee) => {
    if (employee.isShared === true) return true;
    else return false;
  };

  const borderColor = (employee) => {
    let borderColor = "";
    if (employee.statusId !== 0 && !employee.isActing) {
      borderColor = "danger";
    }
    if (employee.isShared || employee.isSharing) {
      borderColor = "primary";
    }
    if (
      !employee.positionId ||
      !employee.idNumber ||
      (!!employee.idNumber && employee.idNumber.length !== 21) ||
      !employee.rssbNumber ||
      !employee.phoneNumber ||
      !employee.email
    ) {
      borderColor = "danger";
    }

    return borderColor;
  };

  const [showSalarySettingsDialog, setShowSalarySettingsDialog] =
    useState(false);

  const [showEmployeePromotionsDialog, setShowEmployeePromotionsDialog] =
    useState(false);

  const [confirmStopActing, setConfirmStopActing] = useState(false);
  const { hasPermissions } = useRequiredAuth();

  const [isRSSBVerifying, setIsRSSBVerifying] = useState(false);
  const [isNIDVerifying, setIsNIDVerifying] = useState(false);

  return (
    <>
      <tr className={`text-${borderColor(employee)}`}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {employeeIndex + 1}
        </th>
        <td> {employee.id}</td>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>
          <span className="d-flex align-items-center justify-content-between">
            {(employee.idNumber || "").replace(/ /g, "")}
            <Tooltip
              title={`${employee.nidVerified ? "Verified" : "Not Verified"} `}
            >
              <span style={{ cursor: "pointer" }}>
                <IconButton
                  disabled={isNIDVerifying}
                  className="p-1"
                  size="small"
                  onClick={() => doVerifyNIDNumber(employee, setIsNIDVerifying)}
                >
                  {employee.nidVerified && !isNIDVerifying && (
                    <span
                      className="material-icons text-success"
                      style={{ fontSize: "12px" }}
                    >
                      verified
                    </span>
                  )}
                  {!employee.nidVerified && !isNIDVerifying && (
                    <span
                      className="material-icons text-danger"
                      style={{ fontSize: "12px" }}
                    >
                      warning
                    </span>
                  )}

                  {isNIDVerifying && (
                    <i
                      className="fas fa-spinner loader2"
                      style={{ fontSize: "12px" }}
                    ></i>
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </span>
        </td>

        <td style={{ width: "84px" }}>
          <span className="d-flex align-items-center justify-content-between">
            {employee.rssbNumber}
            <Tooltip
              title={`${employee.rssbVerified ? "Verified" : "Not Verified"} `}
            >
              <span style={{ cursor: "pointer" }}>
                <IconButton
                  disabled={isRSSBVerifying}
                  className="p-1"
                  size="small"
                  onClick={() =>
                    doVerifyRSSBNumber(employee, setIsRSSBVerifying)
                  }
                >
                  {employee.rssbVerified && !isRSSBVerifying && (
                    <span
                      className="material-icons text-success"
                      style={{ fontSize: "12px" }}
                    >
                      verified
                    </span>
                  )}
                  {!employee.rssbVerified && !isRSSBVerifying && (
                    <span
                      className="material-icons text-danger"
                      style={{ fontSize: "12px" }}
                    >
                      warning
                    </span>
                  )}

                  {isRSSBVerifying && (
                    <i
                      className="fas fa-spinner loader2"
                      style={{ fontSize: "12px" }}
                    ></i>
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </span>
        </td>
        <td>
          {" "}
          {employee.isActing && (
            <strong
              className="text-primary"
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              Ag.
            </strong>
          )}
          {employee.positionName}{" "}
          {employee.isRISASharedStaff &&
            (selectedUnit.unitId === "1903000000-150200000000000000" ||
              selectedUnit.unitId === "1903000000-150201000000000000") && (
              <span>({employee.entityName})</span>
            )}
        </td>
        {showUnitName && <td>{employee.unitName}</td>}
        {showInstitutionName && <td>{employee.institutionName}</td>}
        <td className="text-right">
          {employee.levelName}.{employee.scaleName}
        </td>
        <td className="text-right">{employee.index || 0}</td>
        <td className="text-right">{employee.indexValue || 0}</td>

        <td className="text-right" style={{ backgroundColor: "#e9ecef" }}>
          {currencyFormat((employee.indexValue || 0) * (employee.index || 0))}
        </td>
        {!!showBRDTotalRecovery && (
          <>
            <td className="text-right" style={{ backgroundColor: "#e9ecef" }}>
              {!!employee.totalLoanAmount ? (
                <span
                  className={`d-flex align-items-center justify-content-end text-right text-${
                    +employee.remainingLoanAmount <= 0 ? "success" : "danger"
                  }`}
                >
                  {" "}
                  {(+(
                    ((employee?.totalPaidAmount || 0) * 100) /
                    (employee?.totalLoanAmount || 1)
                  ) > 100.0
                    ? 100
                    : (((employee?.totalPaidAmount || 0) * 100) /
                        (employee?.totalLoanAmount || 1)) *
                      1.0
                  ).toFixed(0)}
                  %
                  {+employee.remainingLoanAmount <= 0 && (
                    <span
                      className="material-icons text-success"
                      style={{ fontSize: "12px" }}
                    >
                      verified
                    </span>
                  )}
                </span>
              ) : (
                <>N/A</>
              )}
            </td>
          </>
        )}

        {!!employee.employeeGroupName && (
          <td
            className="text-center"
            style={{
              maxWidth: "96px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {employee.employeeGroupName}
          </td>
        )}

        <td className="text-right">
          {employee.isActing && (
            <strong
              className="badge badge-primary"
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              Acting
            </strong>
          )}

          {!employee.isActing && !employee.hasPendingExitRequest && (
            <strong
              className={`ml-1 badge badge-${
                employee.statusId === 0 && !employee.isSecondary
                  ? "success"
                  : employee.isSecondary
                  ? "secondary"
                  : "warning"
              }`}
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              {employee.isSecondary ? "Second" : employee.statusName}
            </strong>
          )}

          {employee.hasPendingExitRequest && (
            <span className="text-danger">Pending Exit Request</span>
          )}
          {employee.hasPendingDisciplinaryCase && (
            <span className="text-danger">Pending Disciplinary Case</span>
          )}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  // setSelectedEmployee(employee);
                  setShowEmployeeProfileDialog(true);
                  dispatch({
                    type: types.SET_USER_PROFILE,
                    data: {
                      ...employee,
                      employments: [
                        {
                          positionName: employee.positionName,
                          employeeGroupId: employee.employeeGroupId,
                          levelName: employee.levelName,
                          scaleName: employee.scaleName,
                          entityName: employee.entityName,
                          unitName: employee.unitName,
                          isActing: employee.isActing,
                          startDate: employee.startDate,
                          id: employee.employeePositionId,
                        },
                      ],
                    },
                  });
                }}
                className="text-dark font-weight-bold text-primary"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {((!checkDisabled(employee) && !employee.isRISASharedStaff) ||
              (employee.isRISASharedStaff &&
                (selectedUnit.unitId === "1903000000-150200000000000000" ||
                  selectedUnit.unitId ===
                    "1903000000-150201000000000000"))) && (
              <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    getSelectedEmployeeProfilePicture(employee.idNumber);

                    getSingleActiveContract(employee.employeePositionId);

                    getEmployeeBankAccounts(
                      employee.id,
                      setShowSalarySettingsDialog,
                      handleCloseMenu
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">tune</span>
                  Salary settings
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {!checkDisabled(employee) && (
              <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    setShowPBFSettingsDialog(employee);
                    handleCloseMenu();
                  }}
                  className="text-dark font-weight-light"
                >
                  {/* <span className="material-icons mr-1 ">logout</span> */}
                  <span className="material-icons-outlined mr-1">
                    settings_suggest
                  </span>
                  PBF Settings
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {((!checkDisabled(employee) && !employee.isRISASharedStaff) ||
              (employee.isRISASharedStaff &&
                (selectedUnit.unitId === "1903000000-150200000000000000" ||
                  selectedUnit.unitId ===
                    "1903000000-150201000000000000"))) && (
              <span>
                <MenuItem
                  disabled={
                    loading ||
                    employee.statusId === 30 ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    getEmployeePromotions(
                      employee.employeePositionId,
                      setShowEmployeePromotionsDialog,
                      handleCloseMenu
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">moving</span>
                  Promotions
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {!checkDisabled(employee) && (
              <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    handleCloseMenu();
                    //setSelectedEmployee(employee);
                    setShowEmployeePayHistory(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">payments</span>
                  Pay history
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            <span>
              <MenuItem
                disabled={loading || !hasPermissions(["IS_HR"])}
                onClick={() => {
                  downloadEmployeeDeductionsReport(
                    employee.id,
                    handleCloseMenu
                  );
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">download</span>
                Deductions report
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {!!employee.isHeadOfUnit && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowEmployeeSubordinates(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">group</span>
                  Subordinates
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {!checkDisabled(employee) && employee.isActing && (
              <span>
                <MenuItem
                  disabled={
                    loading ||
                    employee.statusId === 30 ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    handleCloseMenu();

                    setConfirmStopActing(true);
                  }}
                  style={{ color: "#ba931e" }}
                  className="font-weight-light"
                >
                  <span className="material-icons mr-1">
                    cancel_presentation
                  </span>
                  Stop acting
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {env !== "prod" && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  disabled={
                    loading ||
                    employee.statusId === 30 ||
                    isLoading ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    if (!!employee.hasPendingDisciplinaryCase) {
                      getCase(
                        employee.disciplinaryCaseId,
                        setShowIsLoading,
                        setShowCaseDetail
                      );
                    } else {
                      setShowNewDisplinarlyCaseRequestDialog(true);
                    }
                    handleCloseMenu();
                  }}
                  // style={{ color: "rgb(105 0 0)" }}
                  className={
                    employee.hasPendingDisciplinaryCase
                      ? "text-warning font-weight-light"
                      : "font-weight-light"
                  }
                >
                  <span className="material-icons mr-1 ">
                    {" "}
                    {employee.hasPendingDisciplinaryCase ? "visibility" : "add"}
                  </span>
                  {isLoading
                    ? "Wait..."
                    : employee.hasPendingDisciplinaryCase
                    ? "View discipline case"
                    : "Initiate discipline Case"}
                </MenuItem>
              </span>
            )}

            {((!checkDisabled(employee) && !employee.isRISASharedStaff) ||
              (employee.isRISASharedStaff &&
                (selectedUnit.unitId === "1903000000-150200000000000000" ||
                  selectedUnit.unitId ===
                    "1903000000-150201000000000000"))) && (
              <span>
                <MenuItem
                  disabled={
                    loading ||
                    employee.statusId === 30 ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    // setSelectedEmployee(employee);

                    getEmployeeExitRequest(
                      employee.employeePositionId,
                      !employee.hasPendingExitRequest &&
                        ![30].includes(employee.statusId)
                        ? setShowEmployeeExitForm
                        : setShowPendingEmployeeExit,
                      handleCloseMenu
                    );
                  }}
                  style={{ color: "rgb(105 0 0)" }}
                  className="font-weight-light"
                >
                  <span className="material-icons mr-1 ">logout</span>
                  Exit request
                </MenuItem>
              </span>
            )}

            {/* 
              <span>
                <Divider className="my-1" />
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    // setSelectedEmployee(employee);

                    getEmployeeExitRequest(
                      employee.employeePositionId,
                      !employee.hasPendingExitRequest && ![30].includes(employee.statusId)
                        ? setShowEmployeeExitForm
                        : setShowPendingEmployeeExit,
                      handleCloseMenu
                    );
                  }}
                  className="font-weight-light"
                >
                  <span className="material-icons mr-1 ">logout</span>
                  Appointment Letter
                </MenuItem>
              </span> */}
          </Menu>
        </td>
      </tr>

      {showEmployeeProfileDialog && (
        <UserResume
          showProfileDialog={showEmployeeProfileDialog}
          setShowProfileDialog={setShowEmployeeProfileDialog}
          canEdit={hasPermissions(["IS_HR"])}
          allowedToEditEmail={hasPermissions(["IS_HR"])}
          allowedToEditEmploymentDate={hasPermissions(["IS_HR"])}
        />
      )}

      {showSalarySettingsDialog && (
        <EmployeeSalarySettingsDialog
          showSalarySettingsDialog={showSalarySettingsDialog}
          setShowSalarySettingsDialog={setShowSalarySettingsDialog}
          employee={employee}
        />
      )}

      {showPBFSettingsDialog && (
        <EmployeePBFSettingsDialog
          showPBFSettingsDialog={showPBFSettingsDialog}
          setShowPBFSettingsDialog={setShowPBFSettingsDialog}
          employee={employee}
        />
      )}

      {showEmployeePromotionsDialog && (
        <EmployeePromotionDialog
          showEmployeePromotionsDialog={showEmployeePromotionsDialog}
          setShowEmployeePromotionsDialog={setShowEmployeePromotionsDialog}
          employee={employee}
        />
      )}

      {showEmployeePaySlip && (
        <PreviewEmployeePaySlipDialog
          showEmployeePaySlip={showEmployeePaySlip}
          setShowEmployeePaySlip={setShowEmployeePaySlip}
          selectedEmployee={employee}
          // setSelectedEmployee={setSelectedEmployee}
        />
      )}

      {!!showEmployeeExitForm && (
        <EmployeeExitRequestDialog
          showEmployeeExitForm={showEmployeeExitForm}
          setShowEmployeeExitForm={setShowEmployeeExitForm}
          selectedEmployee={employee}
          // setSelectedEmployee={setSelectedEmployee}
        />
      )}
      {!!showPendingEmployeeExit && (
        <ApproveExitDialog
          approveRequestForm={showPendingEmployeeExit}
          setApproveRequestForm={setShowPendingEmployeeExit}
        />
      )}

      {showEmployeePayHistory && (
        <EmployeePayHistoryDialog
          selectedEmployee={employee}
          showEmployeePayHistory={showEmployeePayHistory}
          setShowEmployeePayHistory={setShowEmployeePayHistory}
        />
      )}

      {confirmStopActing && (
        <ConfirmationDialog
          confirmationDialog={confirmStopActing}
          message={`Are you sure you want to stop acting of  \n ${employee.lastName}  ${employee.firstName}?`}
          setConfirmationDialog={setConfirmStopActing}
          onYes={() => {
            stopActing(
              {
                employeePositionId: employee.employeePositionId,
              },
              setConfirmStopActing
            );
          }}
        />
      )}

      {showEmployeeSubordinates && (
        <EmployeeSubordinatesDialog
          supervisor={employee}
          onClose={() => setShowEmployeeSubordinates(false)}
        />
      )}
      {showNewDisplinarlyCaseRequestDialog && (
        <NewDisplinarlyCaseRequestDialog
          showNewDisplinarlyCaseRequestDialog={
            showNewDisplinarlyCaseRequestDialog
          }
          setShowNewDisplinarlyCaseRequestDialog={
            setShowNewDisplinarlyCaseRequestDialog
          }
          employee={employee}
          isFirstCategory={false}
          setShowCaseDetail={setShowCaseDetail}
        />
      )}
      {showCaseDetail && (
        <ViewCaseDialog
          showCaseDetail={showCaseDetail}
          setShowCaseDetail={setShowCaseDetail}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, env, user }) => {
  return {
    loading,
    env,
    selectedEntitySector: user.selectedEntitySector,
  };
};

export default connect(mapStateToProps, {
  stopActing,
  getEmployeeBankAccounts,
  getEmployeeProfile,
  getEmployeePromotions,
  getSelectedEmployeeProfilePicture,
  getSingleActiveContract,
  getEmployeeExitRequest,
  downloadEmployeeDeductionsReport,
  doVerifyRSSBNumber,
  doVerifyNIDNumber,
  getCase,
})(EmployeeCard);
