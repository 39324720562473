import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  addNewDisability,
  editDisability,
  loadAllDisabilities,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import ListSkeleton from "../../common/components/ListSkeleton";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";

const Disabilities = (props) => {
  const {
    createDisability,
    setCreateDisability,
    // redux -----------------
    loading,
    disabilities,
    loadAllDisabilities,
    addNewDisability,
    editDisability,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [disabilityId, setDisabilityId] = useState(0);
  const [disabilityName, setDisabilityName] = useState("");
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (disabilities === null || disabilities.length === 0) {
      loadAllDisabilities(setLoadingData);
    } else {
      setLoadingData(false);
    }

    return () => setLoadingData(true);
  }, []);

  const onCloseForm = () => {
    setCreateDisability(false);
    setDisabilityId(0);
    setDisabilityName("");
  };

  const onSaveForm = () => {
    if (disabilityId === 0 && !createDisability) {
      setFormError({
        element: "GENERAL",
        msg: "Please select disability you want to update.",
      });
      return;
    }
    if (disabilityName.length === 0) {
      setFormError({ element: "NAME", msg: "Disability name is required." });
      return;
    }

    if (disabilityId > 0 && !createDisability) {
      editDisability(
        { id: disabilityId, name: disabilityName },
        (status) => status && onCloseForm()
      );
    } else {
      addNewDisability(disabilityName, (status) => status && onCloseForm());
    }
  };

  const IS_EDITING = Boolean(disabilityId > 0);
  const SEARCHED_DATA =
    !loadingData && disabilities !== null
      ? searchInput.length === 0
        ? disabilities
        : disabilities.filter((item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase())
          )
      : [];

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-2">
        <SearchBox
          className="mb-1"
          onSearch={setSearchInput}
          disabled={
            loadingData || disabilities === null || disabilities.length === 0
          }
          placeholder="Search disability..."
        />
      </div>
      {loadingData || disabilities === null ? (
        <ListSkeleton />
      ) : disabilities.length === 0 ? (
        <div className="text-center font-weight-bold">No disability found!</div>
      ) : (
        <List
          sx={{
            borderRadius: "8px",
            border: `1px solid #c8c6c6`,
            backgroundColor: "#f9f9f9",
          }}
          dense
        >
          {SEARCHED_DATA.map((disabilityItem, index) => (
            <React.Fragment key={index + disabilityItem.id}>
              <ListItem
                dense
                secondaryAction={
                  <IconButton
                    aria-label="edit disability"
                    size="small"
                    onClick={() => {
                      setDisabilityId(disabilityItem.id);
                      setDisabilityName(disabilityItem.name);
                    }}
                  >
                    <ModeRoundedIcon fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      fontSize: "16px",
                      border: "1px solid #c8c6c6",
                    }}
                    className="bg-white text-dark "
                  >
                    {index + 1}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <span className="font-weight-light">
                      {disabilityItem.name}
                    </span>
                  }
                />
              </ListItem>
              {SEARCHED_DATA.length > index + 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createDisability || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Disability" : "Add New Disability"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Disability name"
              variant="outlined"
              size="small"
              className="w-100"
              value={disabilityName}
              onChange={(e) => setDisabilityName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

Disabilities.propTypes = {
  createDisability: PropTypes.bool,
  setCreateDisability: PropTypes.func,
};

const mapStateToProps = ({ loading, disability }) => {
  return { loading, disabilities: disability.disabilities };
};

const mapDispatchToProps = {
  loadAllDisabilities,
  addNewDisability,
  editDisability,
};

export default connect(mapStateToProps, mapDispatchToProps)(Disabilities);
