import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { cancelOralExam } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";

const CancelOralExamDialog = (props) => {
  const {
    loading,
    showCancelOralExamForm,
    setShowCancelOralExamForm,
    selectedOralExam,
    cancelOralExam
  } = props;

  const [examForm, setOralExamForm] = useState({
    examId: selectedOralExam.id,
    cancelationReason: ""
  });
  const [errors, setErrors] = useState({
    cancelationReasonHasError: false,
  });

  const [askToCancel, setAskToCancel] = useState(false);

  const formValidator = () => {
    const error = {
      cancelationReasonHasError: false,
      hasError: false,
    };

    if (!examForm.cancelationReason) {
      error.cancelationReasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {

    if (!formValidator()) {

          cancelOralExam(
            examForm,
            setOralExamForm,
            setShowCancelOralExamForm,
            setAskToCancel
          );

    }
    
  };

  const onClose = () => {
    setAskToCancel(false);
    setShowCancelOralExamForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCancelOralExamForm}
        fullWidth
        maxWidth="sm"
      >
        {askToCancel && (
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              Cancel Oral Exam @{selectedOralExam.advertisement
                  && selectedOralExam.advertisement.requisition.position.name
                  }?
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            {!askToCancel && (
              <div className="row">
                <div className="col-12 mt-3">
                  <div className="alert alert-info" role="alert">
                    <p>
                      Are you sure you want to cancel this oral exam @{selectedOralExam.advertisement
                  && selectedOralExam.advertisement.requisition.position.name
                  }?
                    </p>
                  </div>
                </div>
              </div>
            )}
            {askToCancel && (
              <div className="row">
                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="cancelationReason"
                    autoFocus
                    label="Type your reason .."
                    variant="outlined"
                    placeholder="Type your reason  .."
                    value={examForm.cancelationReason}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const cancelationReason = e.target.value;
                      setOralExamForm({
                        ...examForm,
                        cancelationReason,
                      });
                      const cancelationReasonHasError = false;
                      setErrors({ ...errors, cancelationReasonHasError });
                    }}
                  />

                  {errors.cancelationReasonHasError && (
                    <div className="text-danger mt-2">
                      Reason is required{" "}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        {askToCancel && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Cancel"}
            </button>
          </DialogActions>
        )}

        {!askToCancel && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={()=>{
                setAskToCancel(true);
              }}
              type="button"
              className="btn btn-success text-uppercase  px-4 btn-sm"
            >
              Yes
            </button>
            <button
              onClick={onClose}
              type="button"
              className="btn btn-secondary text-uppercase  px-4 btn-sm"
            >No
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  cancelOralExam,
})(CancelOralExamDialog);
