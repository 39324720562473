import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  getExamKey,
  getExamCenterKey,
  reGenerateExamKey,
} from "../../../store/recruitment/actions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

const ExamKey = (props) => {
  const {
    examId,
    getExamKey,
    getExamCenterKey,
    reGenerateExamKey,
    examCenterId,
    disableCentersOption,
  } = props;

  const [examKey, setExamKey] = useState(null);
  const [examKeys, setExamKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (!disableCentersOption) getExamKey(examId, setExamKeys);
    if (disableCentersOption) getExamCenterKey(examCenterId, setExamKey);
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getExamKey(examId, setExamKey);
  //   }, timeItervals);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <>
      Exam Key:
      {disableCentersOption && <span className="text-success">{examKey}</span>}
      {!disableCentersOption && (
        <>
          {" "}
          <button
            className="btn btn-link text-success"
            onClick={() => setShowDialog(true)}
          >
            {examKeys.length === 1
              ? examKeys[0].examKey
              : "View key by centers"}{" "}
          </button>
          (
          <button
            className="btn btn-link"
            disabled={isLoading}
            onClick={() => {
              reGenerateExamKey(examId, setIsLoading, setExamKeys);
            }}
          >
            {" "}
            {isLoading ? "Wait.." : "re-generate"}
          </button>
          ){" "}
          {showDialog && (
            <ExamCenterKeyDialog
              showDialog={showDialog}
              setShowDialog={setShowDialog}
              examKeys={examKeys}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  getExamKey,
  getExamCenterKey,
  reGenerateExamKey,
})(ExamKey);
const ExamCenterKeyDialog = (props) => {
  const { showDialog, setShowDialog, examKeys } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Exam Center's Key
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="mt-3">
            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    Center
                  </th>
                  <th scope="col" className="text-right">
                    Key
                  </th>
                </tr>
              </thead>

              <tbody>
                {examKeys.map((row, index) => (
                  <tr key={index}>
                    <td className="text-left">
                      <span style={{ fontSize: "10px" }}>
                        {row?.center?.name || ""}
                      </span>
                    </td>
                    <td className="text-right">
                      <span
                        className="text-success"
                        style={{ fontSize: "16px" }}
                      >
                        {row.examKey}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
