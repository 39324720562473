import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { connect } from "react-redux";

const Breadcrumb = (props) => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  const { user } = props;
  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {hasPermissions([
          "IS_HR",
          "IS_TRAINING_MIFOTRA_REVIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
          "IS_TRAINING_MINEDUC_REVIEWER",
          "IS_TRAINING_MINEDUC_VERIFIER",
          "IS_TRAINING_MINISTRY_VERIFIER",
          "IS_RDB_TRAINING_APPROVER",
          "IS_TRAINING_RDB_REVIEWER",
          "IS_TRAINING_RDB_VERIFIER",
          "IS_HEAD_OF_INSTITUTION",
          "IS_TRAINING_MIFOTRA_VIEWER",
          "IS_TRAINING_INITIATOR",
          "IS_TRAINING_MIFOTRA_REVIEWER2",
          "IS_TRAINING_MINISTRY_REVIEWER",
          "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
        ]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/in-progress-requests"
              className={` px-1 rounded   ${
                location.pathname.includes("/training/in-progress-requests")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">InProgress</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
          </>
        )}
        {hasPermissions([
          "IS_HR",
          "IS_TRAINING_MIFOTRA_REVIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
          "IS_TRAINING_MINEDUC_REVIEWER",
          "IS_TRAINING_MINEDUC_VERIFIER",
          "IS_TRAINING_MINISTRY_VERIFIER",
          "IS_RDB_TRAINING_APPROVER",
          "IS_TRAINING_RDB_REVIEWER",
          "IS_TRAINING_RDB_VERIFIER",
          "IS_HEAD_OF_INSTITUTION",
          "IS_TRAINING_PM_OVERSIGHT",
          "IS_TRAINING_MIFOTRA_VIEWER",
          "IS_TRAINING_INITIATOR",
          "IS_TRAINING_MIFOTRA_REVIEWER2",
          "IS_TRAINING_MINISTRY_REVIEWER",
          "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
        ]) && (
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/training/approved-requests"
            className={` px-1 rounded   ${
              location.pathname.includes("/training/approved-requests")
                ? " active  "
                : " "
            }`}
          >
            <span className="text-truncate  menu-item">Approved</span>
          </Link>
        )}
        {hasPermissions([
          "IS_HR",
          "IS_TRAINING_MIFOTRA_REVIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
          "IS_TRAINING_MINEDUC_REVIEWER",
          "IS_TRAINING_MINEDUC_VERIFIER",
          "IS_TRAINING_MINISTRY_VERIFIER",
          "IS_RDB_TRAINING_APPROVER",
          "IS_TRAINING_RDB_REVIEWER",
          "IS_TRAINING_RDB_VERIFIER",
          "IS_HEAD_OF_INSTITUTION",
          "IS_TRAINING_MIFOTRA_VIEWER",
          "IS_TRAINING_INITIATOR",
          "IS_TRAINING_MIFOTRA_REVIEWER2",
          "IS_TRAINING_MINISTRY_REVIEWER",
          "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/rejected-requests"
              className={` px-1 rounded   ${
                location.pathname.includes("/training/rejected-requests")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Rejected</span>
            </Link>
          </>
        )}
        {hasPermissions(["IS_HR", "IS_TRAINING_INITIATOR"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/cd-plans"
              className={` px-1 rounded   ${
                location.pathname.includes("/training/cd-plans")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">CD Plans</span>
            </Link>
          </>
        )}
        {(hasPermissions([
          "IS_TRAINING_MIFOTRA_VIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
          "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
        ]) ||
          (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
            user?.selectedEntitySector?.id ===
              "2000000000-010200000000000000-02")) && (
          <>
            <HorizontalRuleIcon className="rotated" />

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/unlock-employee-for-thirty-days"
              className={` px-1 rounded   ${
                location.pathname.includes(
                  "/training/unlock-employee-for-thirty-days"
                )
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">
                Unlock employee for 30 days
              </span>
            </Link>
          </>
        )}
        {/* {hasPermissions([
          "IS_HR",
          "IS_TRAINING_MIFOTRA_REVIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
          "IS_TRAINING_MINEDUC_REVIEWER",
          "IS_TRAINING_MINEDUC_VERIFIER",
          "IS_TRAINING_MINISTRY_VERIFIER",
          "IS_RDB_TRAINING_APPROVER",
          "IS_TRAINING_RDB_REVIEWER",
          "IS_TRAINING_RDB_VERIFIER",
          "IS_HEAD_OF_INSTITUTION",
          "IS_TRAINING_PM_OVERSIGHT",
          "IS_TRAINING_MIFOTRA_VIEWER",
        ]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/dashboard"
              className={` px-1 rounded   ${
                location.pathname.includes("/training/dashboard")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Dashboard</span>
            </Link>
          </>
        )} */}
        {hasPermissions(["IS_TRAINING_MIFOTRA_OVERSIGHT"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/training/oversights"
              className={` px-1 rounded   ${
                location.pathname.includes("/training/oversights")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Oversights</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Breadcrumb);
