import React, { useState } from "react";
import { Button } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

const ActionPlanTableView = ({ data }) => {
  const [visibleData, setVisibleData] = useState(30);

  const handlePageClick = (action) => {
    if (action === "less" && visibleData > 30) setVisibleData(visibleData - 30);
    if (action === "more" && visibleData < data.length)
      setVisibleData(visibleData + 30);
  };

  const getRowSpan = (outputNo, indicatorNo) => {
    if (indicatorNo)
      return data.filter(
        (item) => item.outputNo === outputNo && item.indicatorNo === indicatorNo
      ).length;
    return data.filter((item) => item.outputNo === outputNo).length;
  };
  const getDistinctIndexes = (array, property) => {
    const distinctValues = [];
    const distinctIndexes = [];
    let i = 0;
    while (i < array.length) {
      const value = array[i][property];
      if (!distinctValues.includes(value)) {
        distinctValues.push(value);
        distinctIndexes.push(i);
      }
      i++;
    }

    return distinctIndexes;
  };
  const distinctOutput = getDistinctIndexes(data, "outputNo");

  const getDistinctIndicator = (outputNo) => ({
    indexes: getDistinctIndexes(
      data.filter((item) => item.outputNo === outputNo),
      "indicatorNo"
    ),
    outputIndex: data.findIndex((item) => item.outputNo === outputNo),
  });

  return (
    <div className="table-responsive">
      <table
        className="table table-sm table-bordered fixTableHead"
        style={{ fontSize: "10px" }}
      >
        <thead>
          <tr>
            <th className="text-center bg-output">Output No</th>
            <th className="text-center bg-output">Output Name</th>
            <th className="text-center bg-output">Output Weight</th>
            <th className="text-center bg-output">Budget</th>
            <th className="text-center bg-indicator">Indicator Name</th>
            <th className="text-center bg-indicator">Measurement Unit</th>
            <th className="text-center bg-indicator">Indicator Weight</th>
            <th className="text-center bg-indicator">Baseline</th>
            <th className="text-center bg-indicator">Annual Target</th>
            <th className="text-center bg-indicator">Q1 Target</th>
            <th className="text-center bg-indicator">Q2 Target</th>
            <th className="text-center bg-indicator">Q3 Target</th>
            <th className="text-center bg-indicator">Q4 Target</th>
            <th className="text-center bg-activity">Activity No</th>
            <th className="text-center bg-activity">Activity Name</th>
            <th className="text-center bg-activity">Activity Weight</th>
            <th className="text-center bg-activity">Activity Quarter No</th>
            <th className="text-center bg-activity">Target Share</th>
          </tr>
        </thead>
        <tbody className="rbm-row">
          {data.slice(0, visibleData).map((item, index) => {
            const distinctIndicators = getDistinctIndicator(item.outputNo);
            const rowSpanOutput = getRowSpan(item.outputNo);
            const rowSpanIndicator = getRowSpan(
              item.outputNo,
              item.indicatorNo
            );
            const isDistinctOutput = distinctOutput.includes(index);
            const isDistinctIndicator = distinctIndicators.indexes.includes(
              index - distinctIndicators.outputIndex
            );

            return (
              <tr
                key={index}
                style={{
                  background: item.outputNo % 2 === 0 ? "#f6f7f7" : "",
                  // borderBottom: "2px dotted rgb(7, 142, 206)!important",
                }}
              >
                {/* cells for output */}
                {isDistinctOutput && (
                  <td align="center" rowSpan={rowSpanOutput}>
                    {item.outputNo}
                  </td>
                )}
                {isDistinctOutput && (
                  <td rowSpan={rowSpanOutput}>{item.outputName}</td>
                )}
                {isDistinctOutput && (
                  <td align="center" rowSpan={rowSpanOutput}>
                    {item.outputWeight}
                  </td>
                )}
                {isDistinctOutput && (
                  <td align="center" rowSpan={rowSpanOutput}>
                    {item.budget}
                  </td>
                )}
                {/* cells for indicator */}
                {isDistinctIndicator && (
                  <td rowSpan={rowSpanIndicator}>{item.indicatorName}</td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.measurementUnit}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.indicatorWeight}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.baseline}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.annualTarget}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.q1Target}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.q2Target}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.q3Target}
                  </td>
                )}
                {isDistinctIndicator && (
                  <td align="center" rowSpan={rowSpanIndicator}>
                    {item.q4Target}
                  </td>
                )}
                {/* cells for activity */}
                <td align="center">{item.activityNo}</td>
                <td>{item.activityName}</td>
                <td align="center">{item.activityWeight}</td>
                <td align="center">{item.activityQuarterNo}</td>
                <td align="center">{item.targetShare}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {visibleData < data.length && (
        <div className="text-center mt-4">
          <div className="mx-auto mb-2">
            {visibleData}/{data.length} Activities
          </div>
          {visibleData < data.length && (
            <Button
              variant="text"
              onClick={() => handlePageClick("more")}
              startIcon={<ExpandMoreRoundedIcon />}
            >
              Show More
            </Button>
          )}
          {visibleData > 30 && (
            <Button
              className="ml-2"
              variant="text"
              color="inherit"
              onClick={() => handlePageClick("less")}
              startIcon={<ExpandLessRoundedIcon />}
            >
              Show Less
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionPlanTableView;
