import React, { useState } from "react";
import { Button } from "@mui/material";
import { connect } from "react-redux";
import { getSelectedEmployeeProfilePicture } from "../../../../store/common/actions";

import moment from "moment";
import { getSingleActiveContract } from "../../../../store/contractuals/actions";
import EmployeeContractDialog from "./EmployeeContractDialog";
import { useDispatch } from "react-redux";

const ContractCard = (props) => {
  const { loading, contract, getSelectedEmployeeProfilePicture } = props;

  const dispatch = useDispatch();

  const [showContractDialog, setShowContractDialog] = useState(false);

  return (
    <>
      <tr>
        <td className="align-items-center">{contract.employeeId}</td>
        <td>
          {contract.lastName} {contract.firstName}
        </td>
        {/* <td>{contract.lastName}</td> */}
        <td className="text-truncate">{contract.positionName}</td>
        <td className="text-truncate">{contract.unitName}</td>
        {/* <td>{contract.sourceOfFundName}</td> */}
        {/* <td>{contract.payrollGroupName}</td> */}
        {/* <td>{contract.basePay}</td> */}
        {/* <td>{contract.housingAllowance}</td> */}
        {/* <td>{contract.transportAllowance}</td> */}
        {/* <td>{contract.otherAllowance}</td> */}
        {/* <td>{contract.lumpsum}</td> */}
        {/* <td>{contract.currencyId}</td> */}
        <td>{moment(contract.startOn).format("yyyy-MM-DD")}</td>
        <td>{moment(contract.endOn).format("yyyy-MM-DD")}</td>
        <td>{contract.costCenterName}</td>

        <td>{contract.sourceOfFundName}</td>

        {/* <td>
          <span
            className={`badge p-1 badge-${
              contract.status === "Unapproved"
                ? "secondary"
                : contract.status === "Expired"
                ? "danger"
                : "success"
            }`}
          >
            {contract.status}
          </span>
        </td> */}
        <td className="text-right py-1">
          <Button
            disabled={loading}
            onClick={() => {
              dispatch({
                type: "SET_SELECTED_CONTRACT",
                data: contract,
              });

              getSelectedEmployeeProfilePicture(contract.idNumber, () =>
                setShowContractDialog(true)
              );
            }}
            variant="contained"
            size="small"
            disableElevation
            className=""
            style={{
              borderRadius: "8px",
              height: "32px",
              border: `${
                contract.status === "Active"
                  ? "#28a745"
                  : contract.status === "Unapproved"
                  ? "#6c757d"
                  : "1px solid #dc3545"
              }`,
              color: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              backgroundColor: `${
                contract.status === "Active"
                  ? "#28a745"
                  : contract.status === "Unapproved"
                  ? "#6c757d"
                  : "#dc3545"
              }`,
            }}
          >
            <span className="material-icons">open_in_new</span>
            Open contract
          </Button>
        </td>
      </tr>
      {showContractDialog && (
        <EmployeeContractDialog
          showContractDialog={showContractDialog}
          setShowContractDialog={setShowContractDialog}
          selectedContract={contract}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  getSingleActiveContract,
  getSelectedEmployeeProfilePicture,
})(ContractCard);
