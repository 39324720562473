import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  // backgroundColor: "#f0f2f5",
  // boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
  // height: "35px",
  border: `1px solid rgb(192, 192, 192)`,
  "&:hover": {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.6, 1.2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "3px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    marginTop: "5px",
    // padding: theme.spacing(0.5, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const SearchBox = (props) => {
  const { placeholder, disabled, onSearch, onKeyPress, isFull = true } = props;

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={`${isFull ? "w-100" : ""} `}
    >
      <TextField
        fullWidth
        id="search-in-ippis"
        defaultValue=""
        size="small"
        className="mr-2"
        onChange={(e) => onSearch(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && onKeyPress()}
        placeholder={placeholder}
        label={placeholder}
        disabled={disabled}
        autoComplete="off" // This disables autofill
        type="text"
        name="search-in-ippis"
      />
    </form>
  );
};

export default SearchBox;
