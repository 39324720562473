import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  loadExitDashboard,
  loadExitDashboardByExitType,
  loadExitDashboardBySectorId,
} from "../../../store/admin/actions";
import {
  getDisciplineCaseYearAndMonths,
  getCaseSanctionNumbers,
  getCaseChartStatistics,
  getCaseSummaryReports,
  getHighestFrequencyEmployees,
} from "../../../store/discipline/actions";

import {
  DashboardDoughnutChart,
  DashboardLabel,
  DashboardLineChart,
  DashboardPieChart,
} from "../../admin/components/StructureDashboard";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getEntitySectors } from "../../../store/structure/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const DEFAULT_MONTH = {
  cMonthKey: 0,
  cMonthName: "ALL",
};
const DEFAULT_YEAR = {
  cYear: "ALL",
  name: "All Fiscal Year",
  id: 0,
  startOn: "",
  endOn: "",
};
const DEFAULT_ENTITY = {
  id: "",
  entity: {
    name: "ALL",
    acronym: null,
  },
  tinNumber: null,
  locationSectorId: null,
  lineMinistryId: null,
  unitTypeId: null,
  createdOn: 0,
  createdBy: null,
  endedOn: null,
  endedBy: null,
  institutionEmail: null,
  institutionPhone: null,
  canRBMPlan: true,
  websiteUrl: null,
  poBox: null,
  twitterPage: null,
  instagramPage: null,
  facebookPage: null,
  tmisDistrictCode: null,
  isRSSBIntegrated: true,
};

const Dashboard = (props) => {
  const {
    loading,
    exitMonthYears,
    loadExitDashboard,
    loadExitDashboardByExitType,
    loadExitDashboardBySectorId,
    getDisciplineCaseYearAndMonths,
    entitySectors,
    getEntitySectors,
    user,
    getCaseSanctionNumbers,
    getCaseChartStatistics,
    getCaseSummaryReports,
    getHighestFrequencyEmployees,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingYears, setLoadingYears] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [viewExitByType, setViewExitByType] = useState(null);
  const [viewExitBySector, setViewExitBySector] = useState(null);
  const [selectedYear, setSelectedYear] = useState(DEFAULT_YEAR);
  const [filterYearStatus, setFilterYearStatus] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(DEFAULT_MONTH);
  const [filterMonthStatus, setFilterMonthStatus] = useState(false);
  const [filterEntitySectorStatus, setFilterEntitySectorStatus] =
    useState(false);

  const [allYears, setAllYears] = useState([]);
  const [allMonths, setAllMonths] = useState([]);
  const [caseSanctionNumbers, setCaseSanctionNumbers] = useState([]);
  const [isLoadCaseSanctionNumbers, setIsLoadCaseSanctionNumbers] =
    useState(false);

  const [caseChartStatistics, setCaseChartStatistics] = useState(null);
  const [isLoadCaseChartStatistics, setIsLoadCaseChartStatistics] =
    useState(false);

  const [caseSummaryReports, setCaseSummaryReports] = useState(null);
  const [isLoadCaseSummaryReports, setIsLoadCaseSummaryReports] =
    useState(false);

  const [highestFrequencyEmployees, setHighestFrequencyEmployees] = useState(
    []
  );
  const [isLoadHighestFrequencyEmployees, setIsLoadHighestFrequencyEmployees] =
    useState(false);

  const [limitFrequencyEmployees, setLimitFrequencyEmployees] = useState(10);

  const [selectedEntitySector, setSelectedEntitySector] = useState(
    hasPermissions(["IS_DISCIPLINE_OVERSIGHT"])
      ? DEFAULT_ENTITY
      : {
          ...DEFAULT_ENTITY,
          id: user?.selectedEntitySector?.id || DEFAULT_ENTITY.id,
          entity: {
            name:
              user?.selectedEntitySector?.entityName ||
              DEFAULT_ENTITY.entity.name,
            acronym:
              user?.selectedEntitySector?.acronym ||
              DEFAULT_ENTITY.entity.acronym,
          },
        }
  );

  useEffect(() => {
    if (!entitySectors.length) getEntitySectors();
  }, []);

  useEffect(() => {
    const entitySectorId = selectedEntitySector?.id || "";
    const startDate = selectedYear?.startOn || "";
    const endDate = selectedYear?.endOn || "";

    handleLoadCaseSanctionNumbers(startDate, endDate, entitySectorId);
    handleLoadCaseChartStatistics(startDate, endDate, entitySectorId);
    handleLoadCaseSummaryReports(startDate, endDate, entitySectorId);
    handleLoadHighestFrequencyEmployees(
      startDate,
      endDate,
      entitySectorId,
      limitFrequencyEmployees
    );
  }, [selectedEntitySector, selectedYear, limitFrequencyEmployees]);

  useEffect(() => {
    if (allYears?.length === 0) {
      getDisciplineCaseYearAndMonths((status, data) => {
        setLoadingYears(status);
        setAllYears(data?.years || []);
        setAllMonths(data?.months || []);
      });
    }
    return () => {
      setLoadingYears(false);
      setSelectedYear(DEFAULT_YEAR);
      setFilterYearStatus(false);
      setSelectedMonth(DEFAULT_MONTH);
      setFilterMonthStatus(false);
    };
  }, []);

  const handleLoadHighestFrequencyEmployees = (
    startDate,
    endDate,
    entitySectorId,
    top = 10
  ) => {
    return getHighestFrequencyEmployees(
      startDate,
      endDate,
      entitySectorId,
      top,
      (status, data) => {
        setIsLoadHighestFrequencyEmployees(status);
        setHighestFrequencyEmployees(data);
      }
    );
  };

  const handleLoadCaseSanctionNumbers = (
    startDate,
    endDate,
    entitySectorId
  ) => {
    return getCaseSanctionNumbers(
      startDate,
      endDate,
      entitySectorId,
      (status, data) => {
        setIsLoadCaseSanctionNumbers(status);
        setCaseSanctionNumbers(data);
      }
    );
  };
  const handleLoadCaseChartStatistics = (
    startDate,
    endDate,
    entitySectorId
  ) => {
    return getCaseChartStatistics(
      startDate,
      endDate,
      entitySectorId,
      (status, data) => {
        setIsLoadCaseChartStatistics(status);
        if (!status && !!data) {
          setCaseChartStatistics(data);
        }
      }
    );
  };
  const handleLoadCaseSummaryReports = (startDate, endDate, entitySectorId) => {
    return getCaseSummaryReports(
      startDate,
      endDate,
      entitySectorId,
      (status, data) => {
        setIsLoadCaseSummaryReports(status);
        if (!status && !!data) {
          console.log({ data });
          setCaseSummaryReports(data);
        }
      }
    );
  };

  const getPercentage = (num, total) => {
    return ((num / total) * 100).toFixed(0);
  };

  const handleViewExitByType = (entityType) => {
    loadExitDashboardByExitType("ALL", "ALL", entityType.id, (status, data) => {
      data && setViewExitByType({ entityType, data });
    });
  };
  const handleViewExitBySector = (sector) => {
    loadExitDashboardBySectorId("ALL", "ALL", sector.id, (status, data) => {
      data && setViewExitBySector({ sector, data });
    });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center my-2">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { sm: "none", md: "block" } }}
        >
          <span className="lead text-uppercase font-weight-semibold">
            DISCIPLINE DASHBOARD
          </span>
        </Typography>
        <div className="d-flex align-items-center">
          {filterEntitySectorStatus && (
            <Autocomplete
              className="ml-2"
              disabled={
                !entitySectors.length ||
                loading ||
                !hasPermissions(["IS_DISCIPLINE_OVERSIGHT"])
              }
              disablePortal
              getOptionLabel={(option) => option?.entity?.name || ""}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.entity?.name || ""}
                </Box>
              )}
              options={entitySectors}
              sx={{ width: 400 }}
              size="small"
              value={selectedEntitySector || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose institution"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "institution",
                  }}
                />
              )}
              onChange={(event, institution) => {
                setSelectedEntitySector(institution || null);
              }}
            />
          )}
          {!filterEntitySectorStatus && (
            <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
              {selectedEntitySector && (
                <span className="font-weight-normal mx-1">
                  {selectedEntitySector.entity.name}
                </span>
              )}
              <IconButton
                color="primary"
                aria-label="filter by case year"
                component="label"
                size="small"
                disabled={
                  loadingYears ||
                  loadingData ||
                  loading ||
                  !hasPermissions(["IS_DISCIPLINE_OVERSIGHT"])
                }
                onClick={() => {
                  setFilterEntitySectorStatus(true);
                  setFilterYearStatus(false);
                  setFilterMonthStatus(false);
                }}
              >
                <span className="material-icons-outlined">filter_list</span>
              </IconButton>
            </div>
          )}

          {filterYearStatus && (
            <Autocomplete
              className="ml-2"
              disabled={loadingYears || loading}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.name}
                </Box>
              )}
              options={allYears}
              sx={{ width: 200 }}
              size="small"
              value={selectedYear || null}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose fiscal year"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "year",
                  }}
                />
              )}
              onChange={(event, year) => {
                setSelectedYear(year || null);
                // year && year.name === "ALL" && setSelectedMonth(DEFAULT_MONTH);
                setFilterYearStatus(false);
              }}
            />
          )}
          {!filterYearStatus && (
            <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
              {selectedYear && (
                <span className="font-weight-normal mr-1">
                  {selectedYear.name}
                </span>
              )}
              <IconButton
                color="primary"
                aria-label="filter by case year"
                component="label"
                size="small"
                disabled={loadingYears || loadingData || loading}
                onClick={() => {
                  setFilterYearStatus(true);
                  setFilterMonthStatus(false);
                }}
              >
                <span className="material-icons-outlined">filter_list</span>
              </IconButton>
            </div>
          )}

          {/* {filterMonthStatus && (
            <Autocomplete
              className="ml-2"
              disabled={loadingYears || loading}
              disablePortal
              getOptionLabel={(option) => option.cMonthName}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.cMonthName}
                </Box>
              )}
              options={allMonths}
              sx={{ width: 150 }}
              size="small"
              value={selectedMonth || null}
              isOptionEqualToValue={(option, value) =>
                option.cMonthKey === value.cMonthKey
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose month"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "month",
                  }}
                />
              )}
              onChange={(event, month) => {
                setSelectedMonth(month || null);
                setFilterMonthStatus(false);
              }}
            />
          )}
          {!filterMonthStatus && (
            <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
              {selectedMonth && (
                <span className="font-weight-normal mr-1">
                  {selectedMonth.cMonthName}
                </span>
              )}
              <IconButton
                color="primary"
                aria-label="filter by exit year and month"
                component="label"
                size="small"
                disabled={
                  loadingYears ||
                  loading ||
                  (selectedYear && selectedYear.cYear === "ALL")
                }
                onClick={() => {
                  setFilterMonthStatus(true);
                  setFilterYearStatus(false);
                }}
              >
                <span className="material-icons-outlined">filter_list</span>
              </IconButton>
            </div>
          )} */}
        </div>
      </div>

      {/* labels */}
      <div className="d-flex flex-wrap justify-content-center w-100">
        {!!isLoadCaseSanctionNumbers ? (
          <>
            {[1, 2, 3, 5].map((request, index) => (
              <DashboardLabel
                key={index + 1}
                labelTitle={"Loading..."}
                labelValue={0}
                loading={true}
              />
            ))}
          </>
        ) : (
          <>
            {caseSanctionNumbers.map((request, index) => (
              <DashboardLabel
                key={index + 1}
                labelTitle={request.name}
                labelValue={request.number}
                loading={false}
              />
            ))}
          </>
        )}
      </div>

      {/* pie and doughnut charts */}
      <div className="row justify-content-center w-100 my-sm-3 px-4">
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardPieChart
            pieTitle="Case Statuses"
            pieData={caseChartStatistics ? caseChartStatistics.categories : {}}
            loading={isLoadCaseChartStatistics}
          />
        </div>

        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardPieChart
            pieTitle="Appealed"
            pieData={caseChartStatistics ? caseChartStatistics.appealed : {}}
            loading={isLoadCaseChartStatistics}
          />
        </div>
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardPieChart
            pieTitle="Gender"
            pieData={caseChartStatistics ? caseChartStatistics.genders : {}}
            loading={isLoadCaseChartStatistics}
          />
        </div>
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardDoughnutChart
            chartTitle="Employee Groups"
            chartData={
              caseChartStatistics ? caseChartStatistics.employeeGroups : {}
            }
            loading={isLoadCaseChartStatistics}
          />
        </div>
      </div>

      {/* <div className="my-5">
        <DashboardLineChart
          chartTitle="Exit variations"
          chartData={dashboardData ? dashboardData.EXIT_LINE_CHART : {}}
          loading={loadingData || !dashboardData}
        />
      </div> */}

      <div className="row justify-content-between mb-5">

      <div className="col-12">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Top {limitFrequencyEmployees} employees with the highest
                frequency of case(s)
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Names
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Phone
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    TotalCases
                  </th>
                </tr>
              </thead>
              {highestFrequencyEmployees && !isLoadHighestFrequencyEmployees ? (
                <tbody style={{ fontSize: "13px" }}>
                  {highestFrequencyEmployees.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.firstName} {item.lastName}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.phoneNumber}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                       <span className="text-secondary"> {item.caseCounts} Case(s)</span>, <span className="text-primary"> {item.totalOngoingNumbers} Ongoing</span> , <span className="text-success"> {item.totalCompletedNumbers} Completed</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <TableSkeleton cols={3} />
              )}
            </table>
          </div>
        </div>


        <div className="col-12">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Sectors
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    #
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Sector
                  </th>

                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Cases
                  </th>

                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Sanctioned
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Closed
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case InProgress
                  </th>

                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Rejected
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  {/* All Cases */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                      backgroundColor: "rgb(233, 236, 239)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Sanctioned */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Closed */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case InProgress */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Rejected */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>
                </tr>
              </thead>
              {caseSummaryReports && !isLoadCaseSummaryReports ? (
                <tbody style={{ fontSize: "13px" }}>
                  {caseSummaryReports.numbersBySectors.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.name}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalFemaleMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalAllNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalSanctionedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalSanctionedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalSanctionedNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseClosedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseClosedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseClosedNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseInprogressMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseInprogressFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseInprogressNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseRejectedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseRejectedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseRejectedNumbers || 0}
                      </td>
                    </tr>
                  ))}

                  {/* {dashboardData.sectorExists.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                        colSpan={2}
                      >
                        Total
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {dashboardData.TOTAL_EXIT} (100%)
                      </th>
                    </tr>
                  )} */}
                </tbody>
              ) : (
                <TableSkeleton cols={3} />
              )}
            </table>
          </div>
        </div>

       
        <div className="col-12">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                View List Faults
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    #
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Fault
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Cases
                  </th>

                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Sanctioned
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Closed
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case InProgress
                  </th>

                  <th
                    colSpan={3}
                    style={{
                      borderRight: "1px solid rgb(150, 171, 181)",
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                    className="text-center"
                  >
                    All Case Rejected
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  {/* All Cases */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                      backgroundColor: "rgb(233, 236, 239)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Sanctioned */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Closed */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case InProgress */}

                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>

                  {/* All Case Rejected */}
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Male
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Female
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "1px solid rgb(150, 171, 181)",
                      borderRight: "1px solid rgb(150, 171, 181)",
                    }}
                  >
                    Total
                  </th>
                </tr>
              </thead>
              {caseSummaryReports && !isLoadCaseSummaryReports ? (
                <tbody style={{ fontSize: "13px" }}>
                  {caseSummaryReports.faultsSanctioned.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.name}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalFemaleMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalAllNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalSanctionedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalSanctionedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalSanctionedNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseClosedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseClosedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseClosedNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseInprogressMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseInprogressFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseInprogressNumbers || 0}
                      </td>

                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseRejectedMaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                        }}
                      >
                        {item.totalCaseRejectedFemaleNumbers || 0}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "1px solid rgb(150, 171, 181)",
                          borderRight: "1px solid rgb(150, 171, 181)",
                          backgroundColor: "rgb(233, 236, 239)",
                        }}
                      >
                        {item.totalCaseRejectedNumbers || 0}
                      </td>
                      {/* <td
                        className={`text-center ${
                          item.totalAllNumbers > 0 && !loading && !loadingData
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                        }}
                        // onClick={() =>
                        //   item.totalAllNumbers > 0 &&
                        //   !loading &&
                        //   !loadingData &&
                        //   handleViewExitByType({
                        //     id: item.id,
                        //     name: item.exitTypeName,
                        //   })
                        // }
                      >
                        <span className="font-weight-bold">
                          {item.totalAllNumbers}
                        </span>{" "}
                        
                        {item.totalAllNumbers > 0 && (
                          <div className={`float-right td-clickable-icon`}>
                            <IconButton
                              aria-label="view positions"
                              size="small"
                            >
                              <RemoveRedEyeRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        )}
                      </td> */}
                    </tr>
                  ))}

                  {/* {dashboardData.exitData.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                        colSpan={2}
                      >
                        Total
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {dashboardData.TOTAL_EXIT} (100%)
                      </th>
                    </tr>
                  )} */}
                </tbody>
              ) : (
                <TableSkeleton cols={3} />
              )}
            </table>
          </div>
        </div>
      </div>

      {/* models */}
      {/* {viewExitByType && (
        <Dialog
          onClose={() => setViewExitByType(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of exit by exit type
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewExitByType(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div
              className="w-100 text-center font-weight-bold text-primary mb-1"
              style={{ fontSize: "22px" }}
            >
              {viewExitByType.entityType.name}
            </div>

            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-center">Employee ID</th>
                  <th className="text-left">Employee Name</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">National Id</th>
                  <th className="text-left">Gender</th>
                  <th className="text-left">Position</th>
                </tr>
              </thead>
              <tbody>
                {viewExitByType.data.map((item, index) => (
                  <tr key={index}>
                    <th className="text-center">{item.employeeId}</th>
                    <td align="left">{item.employeeNames}</td>
                    <td align="left">{item.email}</td>
                    <td align="left">{item.idNumber}</td>
                    <td align="left">
                      {item.genderId === "M"
                        ? "Male"
                        : item.genderId === "F"
                        ? "Female"
                        : "Other"}
                    </td>
                    <td align="left">{item.positionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )} */}

      {/* {viewExitBySector && (
        <Dialog
          onClose={() => setViewExitBySector(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of exit by sector
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewExitBySector(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div
              className="w-100 text-center font-weight-bold text-primary mb-1"
              style={{ fontSize: "22px" }}
            >
              {viewExitBySector.sector.name}
            </div>

            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-center">Employee ID</th>
                  <th className="text-left">Employee Name</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">National Id</th>
                  <th className="text-left">Gender</th>
                  <th className="text-left">Exit type</th>
                  <th className="text-left">Position</th>
                </tr>
              </thead>
              <tbody>
                {viewExitBySector.data.map((item, index) => (
                  <tr key={index}>
                    <th className="text-center">{item.employeeId}</th>
                    <td align="left">{item.employeeNames}</td>
                    <td align="left">{item.email}</td>
                    <td align="left">{item.idNumber}</td>
                    <td align="left">
                      {item.genderId === "M"
                        ? "Male"
                        : item.genderId === "F"
                        ? "Female"
                        : "Other"}
                    </td>
                    <td align="left">{item.exitName}</td>
                    <td align="left">{item.positionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )} */}
    </div>
  );
};

const mapStateToProps = ({ adminExit, loading, entitySectors, user }) => {
  return {
    exitMonthYears: adminExit.exitMonthYears,
    loading,
    entitySectors,
    user,
  };
};
const mapDispatchToProps = {
  loadExitDashboard,
  loadExitDashboardByExitType,
  loadExitDashboardBySectorId,
  getDisciplineCaseYearAndMonths,
  getEntitySectors,
  getCaseSanctionNumbers,
  getCaseChartStatistics,
  getCaseSummaryReports,
  getHighestFrequencyEmployees,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
