import Appeals from "./Appeals";

const InProgressAppeals = (props) => {
  const { selectedAdvertisement,onClose ,hrCanViewAppealedOnSecondLevel} = props;
  return (
    <>
      <Appeals
      onClose={onClose}
        appealCategory="InProgressAppeals"
        selectedAdvertisement={selectedAdvertisement}
        hrCanViewAppealedOnSecondLevel={hrCanViewAppealedOnSecondLevel}
      />
    </>
  );
};

export default InProgressAppeals;
