import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";

import {
  getEmployeeAnnualSalaries,
  addEmployeeToBonus,
} from "../../../store/payroll/actions";
import { useState } from "react";
import { currencyFormat } from "../../common/components/Utils";

const AddToBonusDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    employee,
    employeeAnnualSalaries,
    addEmployeeToBonus,
    selectedFiscalYear,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  // useEffect(() => {
  //   if (!!showDialog)
  //     getEmployeeAnnualSalaries(employee.id, employee.oldEmployeeId);
  // }, [employee]);

  const [selectedItems, setSelectedItems] = useState([]);

  const addAll = () => {
    const temp = [];
    employeeAnnualSalaries.forEach((item, index) => temp.push(index));

    setSelectedItems(temp);
  };

  const getTotalAdded = () =>
    employeeAnnualSalaries
      .filter((item, index) => selectedItems.find((i) => index === i) >= 0)
      .reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.basePay;
      }, 0);

  // useEffect(() => {
  //   getTotalAdded();
  // }, [selectedItems]);

  const onSave = () => {
    const temp = employeeAnnualSalaries.filter(
      (item, index) => selectedItems.find((i) => index === i) >= 0
    );
    const payload = [];

    temp.forEach(({ periodId, basePay, payrollTypeId }) =>
      payload.push({
        employeeId: employee.id,
        entityId: user.selectedEntitySector.entityId,
        fiscalYearId: selectedFiscalYear.id,
        periodId,
        payrollTypeId,
        basePay,
        createdBy: user.id,
      })
    );

    addEmployeeToBonus(
      payload,
      employee.oldEmployeeId,
      employee.ignoreSixMonths,
      setShowDialog
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
        open={showDialog}
      >
        <DialogTitle className=" pb-0 mb-0 text-uppercase">
          <Typography className="text-dark">
            Annual Salaries |{" "}
            <span className="text-primary">{employee.names}</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="mt-2"
            style={{
              minHeight: "40vh",
            }}
          >
            <div className="row mx-0">
              <table className=" table-striped table-hover table-sm ">
                <thead>
                  <tr>
                    <th className="text-right">#</th>
                    <th className="text-right">Month</th>
                    <th>Payroll Type</th>
                    <th className="text-right">Basic Salary</th>
                    <th className="text-right">Status</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>

                <tbody>
                  {employeeAnnualSalaries.map((item, index) => (
                    <BonusCard
                      key={index}
                      item={item}
                      index={index}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}

                  <tr>
                    <td
                      style={{
                        backgroundColor: "#e9ecef",
                      }}
                    ></td>
                    <td
                      className="text-primary text-right text-uppercase font-weight-bold"
                      style={{
                        fontSize: "12px",
                        width: "100px",
                        backgroundColor: "#e9ecef",
                      }}
                    >
                      Annual Basic Salary:
                    </td>
                    <td colSpan={3} className="text-right">
                      <span className="text-primary">
                        {" "}
                        {selectedItems.length} Month
                        {selectedItems.length > 1 ? "s" : ""} added
                      </span>

                      <span
                        className="px-2 py-1 d-block text-right"
                        style={{
                          fontSize: "16px",
                          backgroundColor: "#9ce5ad",
                          borderRadius: "5px",
                        }}
                      >
                        {currencyFormat(getTotalAdded())} FRW
                      </span>
                    </td>
                    <td
                      className="text-right pt-3"
                      style={{
                        padding: "2px",
                        width: "96px",
                      }}
                    >
                      {employeeAnnualSalaries.length > selectedItems.length && (
                        <Button
                          onClick={() => {
                            addAll();
                          }}
                          variant="contained"
                          size="small"
                          disableElevation
                          color="success"
                          style={{
                            borderRadius: "3px",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                          }}
                        >
                          <span className="material-icons mr-1">
                            add_circle
                          </span>
                          Add All
                        </Button>
                      )}
                      {employeeAnnualSalaries.length ===
                        selectedItems.length && (
                        <Button
                          onClick={() => {
                            setSelectedItems([]);
                          }}
                          variant="contained"
                          size="small"
                          disableElevation
                          color="error"
                          style={{
                            borderRadius: "3px",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                          }}
                        >
                          <span className="material-icons mr-1">
                            remove_circle
                          </span>
                          Remove All
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading || !selectedItems.length}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-5"
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  employeeAnnualSalaries,
  selectedFiscalYear,
}) => {
  return { user, loading, employeeAnnualSalaries, selectedFiscalYear };
};
export default connect(mapStateToProps, {
  getEmployeeAnnualSalaries,
  addEmployeeToBonus,
})(AddToBonusDialog);

const BonusCard = (props) => {
  const { item, index, selectedItems, setSelectedItems } = props;

  return (
    <>
      <tr>
        <th
          className="text-right"
          style={{
            backgroundColor: "#e9ecef",
          }}
        >
          <span>{index + 1}</span>
        </th>
        <th
          className="text-right"
          style={{
            backgroundColor: "#e9ecef",
          }}
        >
          <span>{item.periodId}</span>
        </th>
        <td>
          <span>{item.payrollTypeName}</span>
        </td>
        <td className="text-right">
          <span>{currencyFormat(item.basePay)}</span>
        </td>

        <td className="text-right">
          {selectedItems.find((item) => item === index) >= 0 && (
            <span className="text-success font-weight-bold">
              <i className="fas fa-check-double"></i> Added
            </span>
          )}
          {!(selectedItems.find((item) => item === index) >= 0) && (
            <span>Not added</span>
          )}
        </td>

        <td
          className="text-right"
          style={{
            padding: "2px",
          }}
        >
          {!(selectedItems.find((item) => item === index) >= 0) && (
            <Button
              onClick={() => {
                const temp = [...selectedItems];
                temp.push(index);
                setSelectedItems(temp);
              }}
              variant="outlined"
              size="small"
              disableElevation
              color="primary"
              style={{
                borderRadius: "3px",
                height: "20px",
                // width: "96px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span className="material-icons mr-1">add</span>
              Add
            </Button>
          )}

          {selectedItems.find((item) => item === index) >= 0 && (
            <Button
              onClick={() => {
                const temp = [...selectedItems];
                temp.splice(
                  selectedItems.findIndex((item) => item === index),
                  1
                );
                setSelectedItems(temp);
              }}
              variant="text"
              size="small"
              disableElevation
              color="error"
              style={{
                borderRadius: "3px",
                height: "20px",
                // width: "96px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              <span
                className="material-icons mr-1"
                style={{ fontSize: "18px" }}
              >
                delete_forever
              </span>
              Remove
            </Button>
          )}

          {/* <button
            type="button"
            className="btn d-inline-flex btn-outline-primary btn-sm py-0"
          >
            <span className="material-icons mr-1">add</span> Add
          </button> */}
        </td>
      </tr>

      {/* {showAddToBonusDialog && (
        <AddToBonusDialog
          showDialog={showAddToBonusDialog}
          setShowDialog={setShowAddToBonusDialog}
          employee={item}
        />
      )} */}
    </>
  );
};
