import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserResidence,
  updateUserResidence,
  getDistricts,
  getUserProfileStatus,
} from "../../../store/profile/actions";
import axios from "axios";
import { showError } from "../../toastify";
import MaskedInput from "react-text-mask";
import moment from "moment";

export const ID_NUMBER_INPUT_PATTERN = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

export const Profile = (props) => {
  const {
    userProfile,
    loading,
    lookup,
    getDistricts,
    getUserResidence,
    updateUserResidence,
    getUserProfileStatus,
    userProfileStatus,
    canEdit,
    allowedToEditEmail,
  } = props;

  const [wait, setWait] = useState(false);

  // const [changePassword, setChangePassword] = useState(false);

  const [userResidence, setUserResidence] = useState({});

  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [villages, setVillages] = useState([]);

  const [district, setDistrict] = useState(null);
  const [sector, setSector] = useState(null);
  const [cell, setCell] = useState(null);
  const [village, setVillage] = useState(null);

  const [nidLoading, setNidLoading] = useState(false);
  const [nidError, setNidError] = useState(false);

  const [formData, setFormData] = useState({
    idNumber: "",
    lastName: "",
    firstName: "",
    fatherNames: "",
    motherNames: "",
    placeOfBirth: "",
    phoneNumber: "",
    email: "",
    genderId: "",
    dateOfBirth: "",
    verified: false,
    profileImage: null,
  });

  const onNIDChange = (e) => {
    const newValue = e.target.value;

    setNidError(false);
    setFormData({ ...formData, idNumber: newValue, verified: false });

    if (newValue.length === 21) verifyNationalID(newValue);
  };

  const verifyNationalID = async (idNumber) => {
    try {
      setNidLoading(true);

      const { data } = await axios.get(
        `/api/profile/user-identity/${(idNumber || "").replace(/\s/g, "")}`
      );

      setFormData({
        ...formData,
        idNumber: idNumber,
        lastName: data.lastName,
        firstName: data.firstName,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        placeOfBirth: data.placeOfBirth,
        genderId: data.genderId,
        dateOfBirth: data.dateOfBirth,
        phoneNumber: userProfile.phoneNumber,
        email: userProfile.email,
        verified: true,
      });

      getSectors(data.residence?.cell?.sector?.districtId);
      getCells(data.residence?.cell?.sectorId);
      getVillages(data.residence?.cellId);

      setVillage(data.residence || null);
      setCell(data.residence?.cell || null);
      setSector(data.residence?.cell?.sector || null);
      setDistrict(data.residence?.cell?.sector?.district || null);

      setNidError(false);
      setNidLoading(false);
    } catch (error) {
      setNidError(true);
      setNidLoading(false);
      showError(error);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      idNumber: userProfile.idNumber,
      lastName: userProfile.lastName,
      firstName: userProfile.firstName,
      fatherNames: userProfile.fatherNames,
      motherNames: userProfile.motherNames,
      placeOfBirth: userProfile.placeOfBirth,
      phoneNumber: userProfile.phoneNumber,
      email: userProfile.email,
      genderId: userProfile.genderId,
      dateOfBirth: userProfile.dateOfBirth,
      verified: userProfile.nidVerified,
      profileImage: userProfile.profileImage,
    });

    if (!userProfile.nidVerified) setNidError(true);

    // if ((userProfile.idNumber || "").replace(/\s/g, "").length === 16)
    //   verifyNationalID(userProfile.idNumber);

    if (!lookup.districts) getDistricts();
    getUserResidence(setUserResidence, userProfile.id);
  }, []);

  useEffect(() => {
    if (!!userResidence) {
      if (!!userResidence?.cell?.sector?.districtId)
        getSectors(userResidence?.cell?.sector?.districtId);
      if (!!userResidence?.cell?.sectorId)
        getCells(userResidence?.cell?.sectorId);
      if (!!userResidence?.cellId) getVillages(userResidence?.cellId);

      setVillage(userResidence || null);
      setCell(userResidence?.cell || null);
      setSector(userResidence?.cell?.sector || null);
      setDistrict(userResidence?.cell?.sector?.district || null);
    }
  }, [userResidence]);

  useEffect(() => {
    if (!!district?.id) getSectors(district.id);
  }, [district]);

  useEffect(() => {
    if (!!sector?.id) getCells(sector.id);
  }, [sector]);

  useEffect(() => {
    if (!!cell?.id) getVillages(cell.id);
  }, [cell]);

  const handleSubmitChanges = () => {
    updateUserResidence(
      {
        residenceId: village.id,
        idNumber: (formData.idNumber || "").replace(/\s/g, ""),
        phoneNumber: formData.phoneNumber || "",
        email: formData.email || "",
        userId: userProfile.id,
        // examDistrict: undefined,
        // workDistricts: undefined,
        // didEducation: undefined,
      },
      setUserResidence,
      getUserProfileStatus
    );
  };

  const residentChanged = () => {
    return (
      (!!village && userResidence?.id !== village?.id) ||
      userProfile.idNumber !== formData.idNumber ||
      userProfile.phoneNumber !== formData.phoneNumber ||
      userProfileStatus.verified !== formData.verified
    );
  };

  const getSectors = async (districtId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/sectors/` + districtId);
      setSectors(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  const getCells = async (sectorId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/cells/` + sectorId);
      setCells(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  const getVillages = async (cellId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/villages/` + cellId);
      setVillages(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1}>
        {/* <Alert icon={false} severity="info"> */}

        {/* </Alert> */}

        <CardContent>
          <div className="container-fluid">
            <Typography
              variant="h6"
              className="text-blue mt-1 ml-1 text-uppercase"
            >
              Identity
            </Typography>

            <div className="row justify-content-center ">
              {/* <div className="col-12 col-md-6 col-lg-2 mb-3 text-center">
                <img
                  src={`${
                    !!formData.profileImage
                      ? "data:image/png;base64," + formData.profileImage
                      : defaultProfileImage
                  } `}
                  alt={formData.lastName + " " + formData.firstName}
                  className="border border-primary rounded mx-auto"
                  width="100%"
                />

                <div className="font-weight-bold text-primary">{userProfile.id}</div>

                <Button
                  size="small"
                  variant="text"
                  className="text-underline mt-2"
                  onClick={() => setChangePassword(true)}
                >
                  Change password
                </Button>
              </div> */}

              <div className="col-12">
                {/* <div className="card border-primary mb-3"> */}
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    {!formData.verified && !nidLoading && !nidError && (
                      <span className="material-icons loaderIcon text-info">
                        help
                      </span>
                    )}

                    {nidLoading && (
                      <i
                        className="fas fa-spinner loaderIcon text-primary loader2"
                        style={{ fontSize: "22px" }}
                      ></i>
                    )}

                    {!nidLoading && !!nidError && (
                      <span className="loaderIcon text-danger d-flex align-items-center">
                        <span className="material-icons text-danger">
                          report_problem
                        </span>
                      </span>
                    )}

                    {formData.verified && !nidLoading && !nidError && (
                      <span className="loaderIcon text-success d-flex align-items-center">
                        <span className="material-icons text-success ">
                          verified
                        </span>
                      </span>
                    )}
                    <MaskedInput
                      mask={ID_NUMBER_INPUT_PATTERN}
                      className="form-control"
                      placeholder="Enter your NID Number"
                      guide={false}
                      value={formData.idNumber}
                      onChange={onNIDChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && e.target.value.length === 21) {
                          verifyNationalID(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Last Name"
                      value={formData.lastName}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="First Name"
                      value={formData.firstName}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Father Name"
                      value={formData.fatherNames || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Mother Name"
                      value={formData.motherNames || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Place of Birth"
                      value={formData.placeOfBirth || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Gender"
                      value={formData.genderId === "M" ? "Male" : "Female"}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Date of Birth"
                      value={
                        !!formData.dateOfBirth
                          ? moment(formData.dateOfBirth).format("ll")
                          : ""
                      }
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      label="Email"
                      value={formData.email || ""}
                      style={{
                        backgroundColor: !allowedToEditEmail ? "#eee" : "",
                      }}
                      size="small"
                      InputProps={{
                        readOnly: !allowedToEditEmail,
                      }}
                      className="w-100"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      label="Phone Number"
                      value={formData.phoneNumber || ""}
                      size="small"
                      className={`w-100`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          phoneNumber: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={district}
                      options={lookup.districts || []}
                      disabled={loading || !canEdit}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, district) => {
                        setDistrict(district || null);
                        setSector(null);
                        setCell(null);
                        setVillage(null);

                        setSectors([]);
                        setCells([]);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, district) => (
                        <Box component="li" {...props} key={district.id}>
                          {district.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence district"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "name", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={sector}
                      options={sectors}
                      disabled={loading || !sectors.length || !canEdit}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, sector) => {
                        setSector(sector || null);
                        setCell(null);
                        setVillage(null);

                        setCells([]);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, sector) => (
                        <Box component="li" {...props} key={sector.id}>
                          {sector.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence sector"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "name", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={cell || null}
                      options={cells}
                      disabled={loading || !cells.length || !canEdit}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, cell) => {
                        setCell(cell || null);
                        setVillage(null);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, cell) => (
                        <Box component="li" {...props} key={cell.id}>
                          {cell.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence cell"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "name", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={village || null}
                      options={villages}
                      disabled={loading || !villages.length || !canEdit}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, village) => setVillage(village || null)}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, village) => (
                        <Box component="li" {...props} key={village.id}>
                          {village.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence village"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "name", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 border-right-sm">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="button"
                      className={`btn btn-primary btn-sm w-100`}
                      disabled={loading || !formData.verified || !canEdit}
                      onClick={handleSubmitChanges}
                    >
                      Update
                    </Button>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* {changePassword && (
        <ChangePassword
          onShow={changePassword}
          onClose={() => setChangePassword(false)}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  userProfile,
  loading,
  lookup,
  userProfileStatus,
}) => ({
  userProfile,
  loading,
  lookup,
  userProfileStatus,
});

export default connect(mapStateToProps, {
  getUserResidence,
  updateUserResidence,
  getDistricts,
  getUserProfileStatus,
})(Profile);
