import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSubordinateLeavePlan,
  getUnitAnnualLeavePlan,
  getUnitWithLeaves,
} from "../../../store/leave/actions";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import ViewLeaveDetails from "../components/ViewLeaveDetails";
import LeaveLogsDialog from "../components/LeaveLogsDialog";
import PlanAnnualLeaveForm from "../components/PlanAnnualLeaveForm";
import ViewPostponedLeaveDetails from "../components/ViewPostponedLeaveDetails";
import ReactPaginate from "react-paginate";
import { AnnualLeaveRow } from "./UnitLeavePlan";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const AnnualLeaveRequest = (props) => {
  const {
    loading,
    user,
    selectedFiscalYear,
    myUnitLeavePlans,
    getSubordinateLeavePlan,
    getUnitAnnualLeavePlan,
    unitWithAnnualLeave,
    getUnitWithLeaves,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [loadingLeavePlans, setLoadingLeavePlans] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPostponeDetails, setShowPostponeDetails] = useState(null);
  const [showLogs, setShowLogs] = useState(null);
  const [postponeThisLeave, setPostponeThisLeave] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeLeavePlans, setEmployeeLeavePlans] = useState([]);
  const [selectedLeavePlan, setSelectedLeavePlan] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [showPDFLetter, setShowPDFLetter] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [loadingUnitLeaves, setLoadingUnitLeaves] = useState(true);

  useEffect(() => {
    document.title = "IPPIS - Leave & Absence | " + selectedFiscalYear?.name;
  });

  const handleLoadLeaves = (query) => {
    getSubordinateLeavePlan({ ...query, isForPlan: false }, (status, data) => {
      setLoadingLeavePlans(status);
      if (!status) {
        setEmployeeLeavePlans(data.records);
        setTotalRecords(data.totalRecords);
      }
    });
  };

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      isForPlan: false,
      searchTerm: _searchTerm,
      unitId: selectedUnit?.id || "",
      page: _currentPage,
      size: itemPerPage,
    };

    handleLoadLeaves(query);
    if (!hasPermissions(["IS_HEAD_OF_UNIT"]) && !unitWithAnnualLeave.length) {
      getUnitWithLeaves(
        { leaveType: "annual", status: "" },
        setLoadingUnitLeaves
      );
    } else setLoadingUnitLeaves(false);
    return () => {
      setLoadingLeavePlans(true);
      setLoadingUnitLeaves(true);
    };
  }, []);

  useEffect(() => {
    if (selectedLeavePlan) {
      getUnitAnnualLeavePlan(
        setLoadingPlans,
        selectedLeavePlan.employeePositionId,
        "true"
      );
    } else {
      setLoadingPlans(true);
    }
  }, [selectedLeavePlan]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        unitId: selectedUnit?.id || "",
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadLeaves(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      unitId: selectedUnit?.id || "",
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadLeaves(query);
  };

  return (
    <>
      <div className="row w-100 my-3 px-4">
        <div className="col-12">
          <div className="d-flex align-items-center mb-2 mt-2">
            <div className="form-group mt-3 w-100">
              <input
                name="search"
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      searchTerm: searchTerm,
                      unitId: selectedUnit?.id || "",
                      page: currentPage,
                      size: itemPerPage,
                    };

                    handleLoadLeaves(query);
                  }
                }}
              />
            </div>

            {!hasPermissions(["IS_HEAD_OF_UNIT"]) && (
              <div className="ml-2">
                <Autocomplete
                  id="unit"
                  options={unitWithAnnualLeave}
                  getOptionLabel={(option) => (
                    <span>
                      {option.name} ({option.leaves})
                    </span>
                  )}
                  style={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setSelectedUnit(newValue);
                    const query = {
                      isForPlan: true,
                      searchTerm: searchTerm,
                      unitId: newValue ? newValue.id : "",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadLeaves(query);
                  }}
                  disabled={loadingLeavePlans || loadingUnitLeaves}
                  loading={loadingUnitLeaves}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Unit"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
          </div>

          <TableContainer>
            <Table size="small" aria-label="IPPIS RBM Employee plans">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell align="center">Overview</TableCell>
                  <TableCell className="text-center">Action</TableCell>
                </TableRow>
              </TableHead>
              {loadingLeavePlans ? (
                <TableSkeleton cols={6} isMUI />
              ) : (
                <TableBody>
                  {employeeLeavePlans.map((plan, index) => (
                    <TableRow
                      key={plan.id}
                      hover
                      sx={{
                        cursor: "pointer",
                      }}
                      className="tr-hovered"
                      onClick={() => setSelectedLeavePlan(plan)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{plan.unitName}</TableCell>
                      <TableCell>
                        {plan.employeeNames}{" "}
                        {plan.id === user.id && (
                          <span
                            className="badge badge-primary px-1"
                            style={{
                              fontSize: "9px",
                            }}
                          >
                            ME
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {plan.positionName}({plan.employeeGroupName})
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ display: "inline-block" }}>
                          <div className="d-flex mb-2 align-items-center">
                            <span
                              style={{ color: "#088dce", minWidth: "100px" }}
                              className="text-uppercase text-right btn-link"
                            >
                              {plan.plannedDays}/{plan.allowedDays} Planned day
                              {plan.plannedDays > 1 ? "s" : ""}
                            </span>

                            <span
                              className="ml-2 badge badge-light  text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-secondary text-light"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.pendingLeaves}
                              </span>{" "}
                              Pending Request{plan.pendingLeaves > 1 ? "s" : ""}
                            </span>
                            <span
                              className="ml-2 badge badge-primary text-left text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-light text-primary"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.approvedLeaves}
                              </span>{" "}
                              Approved Request
                              {plan.approvedLeaves > 1 ? "s" : ""}
                            </span>
                            <span
                              className="ml-2 badge badge-success text-left text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-light text-secondary"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.takenLeaves}
                              </span>{" "}
                              Taken Leave{plan.takenLeaves > 1 ? "s" : ""}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          onClick={() => setSelectedLeavePlan(plan)}
                          variant="text"
                          size="small"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {!employeeLeavePlans.length && (
                    <TableRow>
                      <TableCell colSpan={6} className="text-center">
                        No leave plan found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <div className="mt-3">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalRecords / itemPerPage)}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                activeLinkClassName="active"
              />
            </div>

            {!!myUnitLeavePlans.length && (
              <select
                className="custom-select custom-select-sm mr-3 ml-2"
                style={{ width: "100px" }}
                disabled={loadingLeavePlans}
                value={itemPerPage}
                onChange={(event) => {
                  const itemPerPage = +event.target.value;
                  setItemPerPage(itemPerPage);
                  const query = {
                    searchTerm: searchTerm,
                    unitId: selectedUnit?.id || "",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  handleLoadLeaves(query);
                }}
              >
                {[10, 50, 100].map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>

      {selectedLeavePlan && (
        <Dialog
          onClose={() => setSelectedLeavePlan(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle className="text-dark pb-2 ">
            <Typography
              variant="overline"
              className="text-truncate"
              display="block"
              style={{ maxWidth: "90%" }}
            >
              Leave Plan Details for {selectedLeavePlan.employeeNames}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setSelectedLeavePlan(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent className="py-1">
            <TableContainer>
              <Table size="small" aria-label="Your annual leave balance">
                <TableHead>
                  <TableRow
                    style={{
                      borderLeft: "2px solid #e9ecef",
                      borderRight: "2px solid #e9ecef",
                    }}
                  >
                    <TableCell>#</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Starting On</TableCell>
                    <TableCell>Ending On</TableCell>
                    <TableCell>Planned Days</TableCell>
                    <TableCell className="text-center">Status</TableCell>
                    <TableCell className="text-center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loadingPlans ? (
                  <TableSkeleton cols={9} isMUI />
                ) : (
                  <TableBody>
                    {myUnitLeavePlans.map((plan, index) => (
                      <AnnualLeaveRow
                        plan={plan}
                        index={index}
                        loading={loadingLeavePlans || loading}
                        setSelectedPlan={setSelectedPlan}
                        key={plan.id}
                        setShowPostponeDetails={setShowPostponeDetails}
                        setShowLogs={setShowLogs}
                        setPostponeThisLeave={setPostponeThisLeave}
                        setShowPDFLetter={setShowPDFLetter}
                      />
                    ))}
                    {!myUnitLeavePlans.length && (
                      <TableRow>
                        <TableCell colSpan={9} className="text-center">
                          No leave plan found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
        </Dialog>
      )}

      {!!selectedPlan && (
        <ViewLeaveDetails
          leaveRequest={selectedPlan}
          setShowRequest={setSelectedPlan}
          title={"Leave Plan Details for " + selectedPlan.employeeNames}
        />
      )}

      {!!showLogs && (
        <LeaveLogsDialog setShowLogs={setShowLogs} selectLeave={showLogs} />
      )}

      {postponeThisLeave && (
        <PlanAnnualLeaveForm
          showCreateAnnualLeave={true}
          setShowCreateAnnualLeave={() => {
            setPostponeThisLeave(null);
          }}
          leaveToPostpone={postponeThisLeave}
        />
      )}

      {!!showPostponeDetails && (
        <ViewPostponedLeaveDetails
          leavePlan={showPostponeDetails}
          setLeavePlan={setShowPostponeDetails}
        />
      )}

      {!!showPDFLetter && !!showPDFLetter?.letterReferenceId && (
        <PreviewPdfUrl
          showDocument={true}
          setShowDocument={() => setShowPDFLetter(null)}
          document={{
            name: `View ${showPDFLetter.leaveType} Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              showPDFLetter?.letterReferenceId,
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingLeavePlans && !!employeeLeavePlans[0] ? "d-block" : "d-none"
        }`}
      >
        {loadingLeavePlans && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  selectedFiscalYear,
  myUnitLeavePlans,
  unitWithAnnualLeave,
}) => {
  return {
    loading,
    user,
    selectedFiscalYear,
    myUnitLeavePlans,
    unitWithAnnualLeave,
  };
};
export default connect(mapStateToProps, {
  getSubordinateLeavePlan,
  getUnitAnnualLeavePlan,
  getUnitWithLeaves,
})(AnnualLeaveRequest);
