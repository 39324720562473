import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import {
  editEmploymentData,
  getEmployeeByEntitySectorId,
} from "../../../store/support/actions";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ChangeEmploymentData = (props) => {
  const {
    actionData,
    setActionData,
    editEmploymentData,
    getEmployeeByEntitySectorId,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeePositions, setEmployeePositions] = useState([]);
  const [formData, setFormData] = useState({
    startDate: actionData.employee.startDate,
    endDate: actionData.employee.endDate,
    isPresent: false,
    employeePositionId: actionData.employee.employeePositionId,
    indexTopUp: actionData.employee.indexTopUp,
    action: actionData.action,
    employeePositionId1: null,
    employeePositionId2: null,
  });
  const [errors, setErrors] = useState({
    startDateHasError: false,
    startDateErrorMessage: "",
    endDateHasError: false,
    endDateErrorMessage: "",
    indexTopUpHasError: false,
    indexTopUpErrorMessage: "",
    employeePositionId1HasError: false,
    employeePositionId1ErrorMessage: "",
    employeePositionId2HasError: false,
    employeePositionId2ErrorMessage: "",
  });
  const onClose = () => {
    setFormData({
      startDate: null,
      endDate: null,
      isPresent: false,
      employeePositionId: null,
      indexTopUp: null,
      action: null,
    });
    setErrors({
      startDateHasError: false,
      startDateErrorMessage: "",
      endDateHasError: false,
      endDateErrorMessage: "",
      indexTopUpHasError: false,
      indexTopUpErrorMessage: "",
    });
    setActionData(null);
  };

  useEffect(() => {
    if (actionData?.action === "apply-prorata") {
      getEmployeeByEntitySectorId(
        {
          entitySectorId: actionData.employee.entitySectorId,
          employeeId: actionData.employee.id,
        },
        (status, data) => {
          setLoading(status);
          if (!status && !!data) {
            setEmployeePositions(data);
            const activePosition = data.filter((item) =>
              [29, 0].includes(item.statusId)
            );
            const prevPosition = data.filter((item) => item.statusId === 30);
            if (activePosition.length === 1) {
              setFormData({
                ...formData,
                employeePositionId2: activePosition[0].employeePositionId,
              });
            }
            if (prevPosition.length === 1) {
              setFormData({
                ...formData,
                employeePositionId1: prevPosition[0].employeePositionId,
              });
            }
          }
        }
      );
    }
  }, [actionData]);

  useEffect(() => {
    if (!!formData.employeePositionId1 && !!formData.employeePositionId2) {
      const prevIndex = employeePositions.find(
        (item) => item.employeePositionId === formData.employeePositionId1
      );
      const activeIndex = employeePositions.find(
        (item) => item.employeePositionId === formData.employeePositionId2
      );

      if (!!prevIndex && !!activeIndex) {
        setFormData({
          ...formData,
          indexTopUp: prevIndex.indexBaseProrata - activeIndex.indexBasePay,
        });
      }
    }
  }, [formData.employeePositionId1, formData.employeePositionId2]);

  const onSubmit = () => {
    let hasError = false;
    if (formData.action === "change-end-date" && !formData.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        startDateErrorMessage: "Start date is required",
      });
      hasError = true;
    }
    if (
      formData.action === "change-end-date" &&
      !formData.endDate &&
      !formData.isPresent
    ) {
      setErrors({
        ...errors,
        endDateHasError: true,
        endDateErrorMessage: "End date is required",
      });
      hasError = true;
    }

    if (formData.action === "apply-prorata" && !formData.employeePositionId1) {
      setErrors({
        ...errors,
        employeePositionId1HasError: true,
        employeePositionId1ErrorMessage: "Previous position is required",
      });
      hasError = true;
    }

    if (formData.action === "apply-prorata" && !formData.employeePositionId2) {
      setErrors({
        ...errors,
        employeePositionId2HasError: true,
        employeePositionId2ErrorMessage: "Active position is required",
      });
      hasError = true;
    }

    if (
      (formData.action === "apply-index-topup" ||
        actionData.action === "apply-prorata") &&
      !formData.indexTopUp &&
      +formData.indexTopUp !== 0
    ) {
      setErrors({
        ...errors,
        indexTopUpHasError: true,
        indexTopUpErrorMessage: "Index TopUp is required",
      });
      hasError = true;
    }

    if (hasError) return;
    editEmploymentData(formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        onClose();
      }
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "70%" }}
        >
          {actionData.action === "change-end-date"
            ? "Change Employment End Date"
            : actionData.action === "apply-index-topup"
            ? "Apply Index TopUp"
            : actionData.action === "apply-prorata"
            ? "Apply Prorata"
            : ""}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      {(isSubmitting || loading) && <LinearProgress />}
      <DialogContent className="py-2">
        {actionData.action === "change-end-date" && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="d-flex align-items-center mb-3">
              <DesktopDatePicker
                label="Start date"
                inputFormat="dd/MM/yyyy"
                disabled={loading || isSubmitting}
                value={formData.startDate}
                onChange={(date) => {
                  setFormData({ ...formData, startDate: date });
                  setErrors({
                    ...errors,
                    startDateHasError: false,
                    startDateErrorMessage: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
              />
              <span className="mx-2">to</span>
              <DesktopDatePicker
                label="End date"
                inputFormat="dd/MM/yyyy"
                disabled={loading || isSubmitting || formData.isPresent}
                value={formData.endDate}
                onChange={(date) => {
                  setFormData({ ...formData, endDate: date });
                  setErrors({
                    ...errors,
                    endDateHasError: false,
                    endDateErrorMessage: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => e.preventDefault()}
                    required={true}
                    fullWidth
                    size="small"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mb-3">
              <FormControlLabel
                control={<Switch checked={formData.isPresent} />}
                label="Is present"
                onChange={() =>
                  setFormData({
                    ...formData,
                    isPresent: !formData.isPresent,
                  })
                }
                disabled={loading}
              />
            </div>
          </LocalizationProvider>
        )}

        {actionData.action === "apply-prorata" && (
          <>
            <Autocomplete
              disabled={!employeePositions.length || isSubmitting || loading}
              size="small"
              id="prevEmployeePosition"
              defaultValue={null}
              value={
                employeePositions.find(
                  (type) =>
                    type.employeePositionId === formData.employeePositionId1
                ) || null
              }
              options={employeePositions.filter(
                (item) => ![29, 0].includes(item.statusId)
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, option) => {
                if (option) {
                  setFormData({
                    ...formData,
                    employeePositionId1: option.employeePositionId,
                  });
                  setErrors({
                    ...errors,
                    employeePositionId1HasError: false,
                    employeePositionId1ErrorMessage: "",
                  });
                }
              }}
              getOptionLabel={(option) => option.positionName}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <span>
                    <span className="d-block text-primary">{option.names}</span>
                    <small className="d-block">
                      {!!option.isActing && (
                        <span className="text-primary font-weight-bold">
                          Ag.
                        </span>
                      )}{" "}
                      {option.positionName}
                    </small>
                    <small className="d-block text-uppercase mb-1">
                      {option.unitName}
                    </small>
                    <Divider />
                    <Divider />
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Previous Position"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "prevEmployeePosition", // disable autocomplete and autofill
                  }}
                />
              )}
              className="mb-3"
            />

            <Autocomplete
              disabled={!employeePositions.length || isSubmitting || loading}
              size="small"
              id="activeEmployeePosition"
              defaultValue={null}
              value={
                employeePositions.find(
                  (type) =>
                    type.employeePositionId === formData.employeePositionId2
                ) || null
              }
              options={employeePositions.filter((item) =>
                [29, 0].includes(item.statusId)
              )}
              isOptionEqualToValue={(option, value) =>
                option.employeePositionId === value.employeePositionId
              }
              onChange={(event, option) => {
                if (option) {
                  setFormData({
                    ...formData,
                    employeePositionId2: option.employeePositionId,
                  });
                  setErrors({
                    ...errors,
                    employeePositionId2HasError: false,
                    employeePositionId2ErrorMessage: "",
                  });
                }
              }}
              getOptionLabel={(option) => option.positionName}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <span>
                    <span className="d-block text-primary">{option.names}</span>
                    <small className="d-block">
                      {!!option.isActing && (
                        <span className="text-primary font-weight-bold">
                          Ag.
                        </span>
                      )}{" "}
                      {option.positionName}
                    </small>
                    <small className="d-block text-uppercase mb-1">
                      {option.unitName}
                    </small>
                    <Divider />
                    <Divider />
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Active Position"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "activeEmployeePosition", // disable autocomplete and autofill
                  }}
                />
              )}
              className="mb-3"
            />
          </>
        )}

        {(actionData.action === "apply-index-topup" ||
          actionData.action === "apply-prorata") && (
          <TextField
            error={errors.indexTopUpHasError}
            helperText={errors.indexTopUpErrorMessage}
            fullWidth
            size="small"
            label="Index TopUp"
            type="number"
            name="indexTopUp"
            variant="outlined"
            defaultChecked
            placeholder="Index TopUp"
            className="mb-3"
            disabled={loading || isSubmitting}
            value={formData.indexTopUp || ""}
            onBlur={() => {
              if (formData.indexTopUp === "")
                setFormData({
                  ...formData,
                  indexTopUp: 0,
                });
            }}
            // onKeyDown={() => {
            //   if (+formData.indexTopUp < 0)
            //     setFormData({
            //       ...formData,
            //       indexTopUp: 0,
            //     });
            // }}
            onChange={(e) => {
              const indexTopUp = e.target.value;
              setFormData({
                ...formData,
                indexTopUp: +indexTopUp || 0,
              });
              setErrors({
                ...errors,
                indexTopUpHasError: false,
                indexTopUpErrorMessage: "",
              });
            }}
          />
        )}

        <div className="d-flex justify-content-center">
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

export default connect(null, {
  editEmploymentData,
  getEmployeeByEntitySectorId,
})(ChangeEmploymentData);
