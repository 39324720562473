import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
// import { deleteIndicator, approveIndicator } from "../../../store/rbm/actions";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
// import CustomProgressLine from "./CustomProgressLine";
import ViewListIcon from "@mui/icons-material/ViewList";
import ContractItemCard from "./ContractItemCard";
import { useLocation } from "react-router-dom";
import MyContractItemCard from "./MyContractItemCard";

const ContractQuarterCard = (props) => {
  const {
    quarter,
    totalWeight,
    showReportingForm,
    selectedSubordinate,
    isForMonitoring,
    contractTypeId,
    contractTypeName,
  } = props;

  const location = useLocation();

  const [totalQuarterTarget, setTotalQuarterTarget] = useState(0);
  const [showActivities, setShowActivities] = useState(true);
  // const [totalProgress, setTotalProgress] = useState(0);
  const [achievedTarget, setAchievedTarget] = useState(0);

  useEffect(() => {
    let weight = 0,
      score = 0,
      achievedTarget = 0,
      quarterTarget = 0;
    quarter.activities.forEach((activity) => {
      weight = weight + activity.weight;
      quarterTarget = quarterTarget + activity.targetShare;
      achievedTarget = achievedTarget + activity.achievedTarget;
      score = score + (activity.score * activity.weight) / 100;
    });

    // setTotalProgress((score * 100) / (weight || 1));
    setAchievedTarget(achievedTarget);
    setTotalQuarterTarget(quarterTarget.toFixed(2));
    paginate(quarter.activities);
  }, [quarter, quarter.activities]);

  //Filter
  const [searchTeam, setSearchTeam] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [filteredActivities, setFilteredActivities] = useState([
    ...quarter.activities,
  ]);

  useEffect(() => {
    const filtered = [...quarter.activities].filter(
      ({ name, statusName, itemTypeName }) =>
        ((name || "") + (statusName || "") + (itemTypeName || ""))
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) &&
        (statusName || "").toLowerCase().includes(statusFilter)
    );

    setFilteredActivities(filtered);
    paginate(filtered);
  }, [searchTeam, statusFilter, quarter.activities]);

  // useEffect(() => {
  //   const filtered = [...quarter.activities].filter(
  //     ({ name, status, itemTypeName }) =>
  //       ((name || "") + (itemTypeName || ""))
  //         .toLowerCase()
  //         .includes(searchTeam.toLowerCase()) && (status || "").toLowerCase().includes(statusFilter)
  //   );

  //   setFilteredActivities(filtered);
  //   paginate(filtered);
  // }, [statusFilter, quarter.activities]);

  //PAGINATING
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const getNo = (idToSearch) => {
    return quarter.activities.findIndex(({ id }) => id === idToSearch);
  };

  useEffect(() => {
    paginate(filteredActivities);
  }, [
    itemOffset,
    itemsPerPage,
    quarter,
    quarter.activities,
    filteredActivities,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredActivities.length;

    setItemOffset(newOffset);
  };

  const [quarterSummary, setQuarterSummary] = useState({
    dueActivities: 0,
    rejectedActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,
    ontrackActivities: 0,
    score: 0,
  });

  useEffect(() => {
    const summary = {
      dueActivities: 0,
      rejectedActivities: 0,
      reportedActivities: 0,
      scoredActivities: 0,
      ontrackActivities: 0,
      score: 0,
    };

    let tmpScore = 0,
      totalWeight = 0;
    quarter.activities.forEach((activity) => {
      tmpScore += activity.score * activity.weight;
      totalWeight += activity.weight;

      if (activity.statusId === 1) summary.ontrackActivities++;
      if (activity.statusId === 2) summary.dueActivities++;
      if (activity.statusId === 3) summary.reportedActivities++;

      if (activity.statusId === 4) summary.scoredActivities++;
      if (activity.statusId === 5) summary.rejectedActivities++;
    });

    setQuarterSummary({ ...summary, score: tmpScore / totalWeight });
  }, [quarter]);

  return (
    <Card variant="outlined" className="mt-2 mb-4 expected-result-card">
      <CardHeader
        className="py-2"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "#f4f4f4",
        }}
        avatar={
          <Tooltip
            title={`${
              [3, 4].includes(contractTypeId) ? contractTypeName : "Quarter"
            } Progress`}
          >
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                cursor: "default",
              }}
            >
              <CircularProgress
                className={`text-${
                  quarterSummary.score < 50
                    ? "danger"
                    : quarterSummary.score < 70
                    ? "warning"
                    : quarterSummary.score < 100
                    ? "info"
                    : "success"
                }`}
                variant="determinate"
                value={quarterSummary.score}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  style={{ fontSize: "7px" }}
                  color="text.secondary"
                  className={`text-${
                    quarterSummary.score < 50
                      ? "danger"
                      : quarterSummary.score < 70
                      ? "warning"
                      : quarterSummary.score < 100
                      ? "info"
                      : "success"
                  }`}
                >
                  {`${quarterSummary.score.toFixed(2)}%`}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        }
        action={
          <span className="d-flex align-items-center">
            <span className="text-right ">
              <span
                style={{ color: "#088dce" }}
                className="text-uppercase mr-2 d-inline-flex align-items-center"
              >
                <ViewListIcon />
                {(filteredActivities || []).length}{" "}
                {!!statusFilter && <>{statusFilter}</>}
                {!statusFilter && (
                  <>
                    Item
                    {(filteredActivities || []).length > 1 ? "s" : ""}
                  </>
                )}
              </span>
              <span className="d-block">
                <span
                  className={`badge badge-pill text-uppercase text-left text-${
                    statusFilter === "ontrack" ? "blue" : "secondary"
                  }`}
                >
                  <span
                    className={`badge badge-${
                      quarterSummary.ontrackActivities > 0 ? "info" : "light"
                    } `}
                    style={{ fontSize: "12px" }}
                  >
                    {quarterSummary.ontrackActivities}
                  </span>{" "}
                  Ontrack{" "}
                  <span
                    className={`btn-link text-${
                      statusFilter === "ontrack" ? "blue" : "secondary"
                    }`}
                    onClick={() =>
                      setStatusFilter(
                        statusFilter === "ontrack" ? "" : "ontrack"
                      )
                    }
                  >
                    <i className="fas fa-thumbtack"></i>
                  </span>
                </span>
                <span
                  className={`badge badge-pill text-uppercase text-left text-${
                    statusFilter === "overdue" ? "blue" : "secondary"
                  }`}
                >
                  <span
                    className={`badge badge-${
                      quarterSummary.dueActivities > 0 ? "warning" : "light"
                    } `}
                    style={{ fontSize: "12px" }}
                  >
                    {quarterSummary.dueActivities}
                  </span>{" "}
                  Overdue{" "}
                  <span
                    className={`btn-link text-${
                      statusFilter === "overdue" ? "blue" : "secondary"
                    }`}
                    onClick={() =>
                      setStatusFilter(
                        statusFilter === "overdue" ? "" : "overdue"
                      )
                    }
                  >
                    <i className="fas fa-thumbtack"></i>
                  </span>
                </span>
                <span
                  className={`badge badge-pill text-uppercase text-${
                    statusFilter === "reported" ? "blue" : "secondary"
                  } `}
                >
                  <span
                    className={`badge badge-${
                      quarterSummary.reportedActivities > 0
                        ? "primary"
                        : "light"
                    } `}
                    style={{ fontSize: "12px" }}
                  >
                    {quarterSummary.reportedActivities}
                  </span>{" "}
                  Reported{" "}
                  <span
                    className={`btn-link text-${
                      statusFilter === "reported" ? "blue" : "secondary"
                    }`}
                    onClick={() =>
                      setStatusFilter(
                        statusFilter === "reported" ? "" : "reported"
                      )
                    }
                  >
                    <i className="fas fa-thumbtack"></i>
                  </span>
                </span>
                <span
                  className={`badge badge-pill text-uppercase text-left text-${
                    statusFilter === "scored" ? "blue" : "secondary"
                  }`}
                >
                  <span
                    className={`badge badge-${
                      quarterSummary.scoredActivities > 0 ? "success" : "light"
                    } `}
                    style={{ fontSize: "12px" }}
                  >
                    {quarterSummary.scoredActivities}
                  </span>{" "}
                  Scored{" "}
                  <span
                    className={`btn-link text-${
                      statusFilter === "scored" ? "blue" : "secondary"
                    }`}
                    onClick={() =>
                      setStatusFilter(statusFilter === "scored" ? "" : "scored")
                    }
                  >
                    <i className="fas fa-thumbtack"></i>
                  </span>
                </span>
                <span
                  className={`badge badge-pill text-uppercase text-left text-${
                    statusFilter === "rejected" ? "blue" : "secondary"
                  }`}
                >
                  <span
                    className={`badge badge-${
                      quarterSummary.rejectedActivities > 0 ? "danger" : "light"
                    } `}
                    style={{ fontSize: "12px" }}
                  >
                    {quarterSummary.rejectedActivities}
                  </span>{" "}
                  Rejected{" "}
                  <span
                    className={`btn-link text-${
                      statusFilter === "rejected" ? "blue" : "secondary"
                    }`}
                    onClick={() =>
                      setStatusFilter(
                        statusFilter === "rejected" ? "" : "rejected"
                      )
                    }
                  >
                    <i className="fas fa-thumbtack"></i>
                  </span>
                </span>

                <IconButton
                  aria-label={`view activities of quarter ${quarter.quarterId}`}
                  title={`view activities of quarter ${quarter.quarterId}`}
                  size="small"
                  color="primary"
                  onClick={() => setShowActivities(!showActivities)}
                >
                  {showActivities ? (
                    <ExpandLessRoundedIcon fontSize="inherit" />
                  ) : (
                    <ExpandMoreRoundedIcon fontSize="inherit" />
                  )}
                </IconButton>
              </span>
            </span>
          </span>
        }
        title={
          <div className="d-flex align-items-center">
            <span className="font-weight-bold">
              {[3, 4].includes(contractTypeId) && contractTypeName
                ? `${contractTypeName} period`
                : `QUARTER ${quarter.quarterId}`}
            </span>
          </div>
        }
        subheader={
          <span>
            <strong>Target: </strong>
            <>
              <>
                <span className={`badge badge-secondary `}>
                  {totalQuarterTarget}
                </span>{" "}
                <span className="d-none d-md-inline">
                  /{" "}
                  <span
                    className={`badge badge-${
                      +achievedTarget < +totalQuarterTarget
                        ? "info"
                        : +achievedTarget === +totalQuarterTarget
                        ? "success"
                        : "danger"
                    } `}
                  >
                    {achievedTarget.toFixed(2)} Achieved
                  </span>
                </span>
              </>
            </>
          </span>
        }
      />
      <CardContent className="p-0 bg-white">
        {showActivities && (
          <div className="p-1 px-md-3">
            <div className="form-group mt-1 mb-2 mx-1">
              <input
                type="text"
                value={searchTeam}
                className="form-control"
                id="filter"
                placeholder="Search..."
                onChange={(e) => setSearchTeam(e.target.value)}
              />
            </div>

            {filteredActivities.length === 0 && (
              <div className="alert alert-secondary text-center" role="alert">
                No <strong>{statusFilter}</strong> item found
              </div>
            )}

            {paginatedItems &&
              paginatedItems.map((activity, index) =>
                location.pathname.startsWith("/rbm/my-contracts") ? (
                  <MyContractItemCard
                    totalWeight={totalWeight}
                    totalQuarterTarget={totalQuarterTarget}
                    quarter={quarter}
                    showReportingForm={showReportingForm}
                    activity={activity}
                    key={index}
                    no={getNo(activity.id)}
                    selectedSubordinate={selectedSubordinate}
                  />
                ) : (
                  <ContractItemCard
                    totalWeight={totalWeight}
                    totalQuarterTarget={totalQuarterTarget}
                    quarter={quarter}
                    showReportingForm={showReportingForm}
                    activity={activity}
                    key={index}
                    no={getNo(activity.id)}
                    selectedSubordinate={selectedSubordinate}
                    isForMonitoring={isForMonitoring}
                  />
                )
              )}

            {quarter.activities.length > itemsPerPage && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(ContractQuarterCard);
