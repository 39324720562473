import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const DraftAuthorizationByMinistry = (props) => {
  const {
    showAuthorizationPermit,
    setShowAuthorizationPermit,
    dialogActions,
    loading,
    minstryLetterReferenceNo,
    draftAuthorizationSubjectByMinistry,
    draftAuthorizationContentByMinistry,
    headOfInstitution,
    signContent,
    isMifotraEntitySector,
  } = props;

  const onClose = () => {
    setShowAuthorizationPermit(false);
  };

  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAuthorizationPermit}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-10">
              Request the authorization to conduct a training
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  {!dialogActions && (
                    <IconButton
                      aria-label="close"
                      onClick={onClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <span className="material-icons">close</span>
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          {dialogActions && loading && (
            <div className="col-12">
              <LinearProgress />
            </div>
          )}
          <div className="letterMainContent">
            <div className="letter">
              <div className="letterhead d-flex flex-column justify-content-center w-100">
                <div
                  className="letterhead-title w-100"
                  style={{ fontWeight: "bold", fontFamily: "Times New Roman" }}
                >
                  REPUBLIC OF RWANDA
                </div>
                <div className="w-100">
                  <img
                    style={{ width: "100px", height: "100px" }}
                    src={rwandaLogo}
                    alt="Logo"
                  />
                </div>
                <div
                  className="letterhead-subtitle w-100"
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontFamily: "Times New Roman",
                  }}
                >
                  {headOfInstitution.institutionName.toUpperCase()}
                </div>
              </div>
              <div className="subletterhead">
                <table>
                  <tr>
                    <td style={{ width: "70%" }}></td>
                    <td style={{ width: "30%" }}>
                      <p
                        className="date"
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        Kigali, on {moment(new Date()).format("LL")}
                      </p>
                      <p
                        className="reference"
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                          position: "relative",
                          top: "-22px",
                        }}
                      >
                        N<sup>o</sup>:{" "}
                        {minstryLetterReferenceNo
                          ? minstryLetterReferenceNo
                          : "........."}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="recipient">
                {/* <!-- Recipient's name and address go here --> */}

                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.5em",
                  }}
                >
                  {isMifotraEntitySector ? (
                    <>
                      Chief Skills Officer <br />
                      <strong>Rwanda Development Board</strong>
                    </>
                  ) : (
                    <>Honorable Minister of Public Service and Labour</>
                  )}
                  <br />
                  <span
                    style={{
                      position: "relative",
                      top: "6px",
                      fontWeight: "bold",
                    }}
                  >
                    KIGALI
                  </span>
                </p>
              </div>
              <div className="content2">
                {/* <!-- Letter content goes here --> */}
                {isMifotraEntitySector ? (
                  <>
                    <p>Dear Chief Skills Officer,</p>
                  </>
                ) : (
                  <>
                    <p>Honorable Minister,</p>
                  </>
                )}
                <p style={{ fontWeight: "bold" }}>
                  Re:
                  {draftAuthorizationSubjectByMinistry
                    ? draftAuthorizationSubjectByMinistry
                    : "Request the authorization to conduct a training"}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftAuthorizationContentByMinistry
                      ? draftAuthorizationContentByMinistry
                      : "",
                  }}
                ></div>
                {/* 0864297531 */}
              </div>
              <div className="signature2">
                <p
                  style={{
                    position: "relative",
                    paddingBottom: "15px",
                    fontWeight: 800,
                    color: "red",
                  }}
                >
                  {signContent ? (
                    signContent
                  ) : (
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                            paddingLeft: "76px",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  )}
                </p>
                <p
                  className="mt-4"
                  style={{ fontSize: "13px", fontWeight: 800 }}
                >
                  {headOfInstitution.title
                    ? headOfInstitution.title + ". "
                    : ""}
                  {headOfInstitution?.firstName || ""}{" "}
                  {headOfInstitution?.lastName || ""}
                </p>

                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 800,
                  }}
                >
                  {headOfInstitution?.positionName || ""}
                </p>
                {!!headOfInstitution?.canAddInstitutionName ? (
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 800,
                    }}
                  >
                    {headOfInstitution?.institutionName}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        {dialogActions && (
          <DialogActions className="d-flex justify-content-center py-4">
            {dialogActions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default DraftAuthorizationByMinistry;
