import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InProgressAppeals from "./pages/InProgressAppeals";
import ArchivedAppeals from "./pages/ArchivedAppeals";
import OverdueAppeals from "./pages/OverdueAppeals";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/e-appeal/user-guide" component={UserGuide} />
        <Route
          path="/e-appeal/in-progress-appeals"
          component={InProgressAppeals}
        />
        <Route path="/e-appeal/archived-appeals" component={ArchivedAppeals} />
        {/* <Route path="/e-appeal/overdue-appeals" component={OverdueAppeals} /> */}
        <Route
          path="/e-appeal"
          component={() => <Redirect to="/e-appeal/in-progress-appeals" />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
