import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  Typography,
  Toolbar,
  Button,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { getWaitingListOffers } from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";

import BackdropLoader from "../../common/components/BackdropLoader";
import WaitingListLeftSide from "../components/WaitingListLeftSide";
import moment from "moment";
import ApplicationCv from "../../common/components/ApplicationCv";
import UserResume from "../../profile/UserResume";

const WaitingListOffers = (props) => {
  const { waitingListOffers, getWaitingListOffers, getUserDetails, loading } =
    props;
  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [filterStatus, setFilterStatus] = useState({ id: "", name: "All" });

  useEffect(() => {
    dispatch({
      type: "SET_WAITINGLIST_OFFERS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getWaitingListOffers(query, setTotalRequests, setIsLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getWaitingListOffers(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getWaitingListOffers(query, setTotalRequests, setIsLoading1);
  };
  return (
    <>
      <div className="row mx-0">
        <div className="col-2">
          <WaitingListLeftSide />
        </div>
        <div className="col-10 mt-2">
          <div className="pr-3">
            <AppBar
              position="static"
              elevation={0}
              style={{ overflow: "hidden" }}
              className="app-bar w-100"
            >
              <Toolbar className="row">
                <div className="col-lg-8">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="text-uppercase"
                  >
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      {searchTerm ? "Filtered Waiting List Offers" : "Waiting List Offers"}(
                      {totalRequests})
                    </span>
                  </Typography>
                </div>

                <div className="col-lg-4">
                  <div className="d-flex justify-content-right  align-items-right">
                    {/* <Autocomplete
                      sx={{ width: 300 }}
                      className="mr-2"
                      size="small"
                      id="filterStatus"
                      defaultValue={null}
                      value={filterStatus || null}
                      options={[
                        { id: "1", name: "Active" },
                        { id: "2", name: "Expired" },
                        { id: "3", name: "Offer pending" },
                        { id: "4", name: "Offer accepted" },
                        { id: "5", name: "Offer rejected" },
                        { id: "6", name: "Employed" },
                        { id: "", name: "All" },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, currentStatus) => {
                        const query = {
                          searchTerm: "",
                          currentStatus: currentStatus?.id || defaultIds,
                          page: currentPage,
                          size: itemPerPage,
                        };
                        setFilterStatus(currentStatus);
                        getWaitingListOffers(
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterStatus) => (
                        <Box component="li" {...props} key={filterStatus.id}>
                          {filterStatus.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            width: "100%",
                            height: "35px!important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    /> */}
                    <SearchBox
                      onSearch={(search) => {
                        setSearchTerm(search);

                        if (!search) {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: "",
                            page: currentPage,
                            size: itemPerPage,
                            currentStatus: filterStatus?.id || defaultIds,
                          };
                          getWaitingListOffers(
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                    <Button
                      disabled={!searchTerm}
                      onClick={() => onSearch()}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2 "
                      style={{
                        borderRadius: "8px",
                        height: "38px",
                        border: `1px solid rgb(7, 142, 206)`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                    >
                      <span className="material-icons">search</span>GO
                    </Button>
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>
                  <th scope="col" className="text-left">
                    LastName
                  </th>
                  <th scope="col" className="text-left">
                    FirstName
                  </th>

                  {/* <th scope="col" className="text-left">
                    IdNumber
                  </th> */}
                  {/* <th scope="col" className="text-left">
                    Email
                  </th>

                  <th scope="col" className="text-left">
                    PhoneNumber
                  </th> */}
                  <th scope="col" className="text-left">
                    PositionName
                  </th>

                  <th scope="col" className="text-left">
                    Institution
                  </th>
                  <th scope="col" className="text-left">
                    StartDate
                  </th>

                  <th scope="col" className="text-left">
                    EndDate
                  </th>

                  <th scope="col" className="text-left">
                    Score
                  </th>
                  <th scope="col" className="text-left">
                    PositionOfPlacement
                  </th>
                  <th scope="col" className="text-left">
                    InstitutionOfPlacement
                  </th>
                  <th scope="col" className="text-left">
                    OfferDate
                  </th>
                  <th scope="col" className="text-left">
                    Status
                  </th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>
              {isLoading ? (
                <TableSkeleton cols={13} />
              ) : (
                <tbody>
                  <BackdropLoader isLoading={isLoading1} />
                  {waitingListOffers.map((waitingListOffer, index) => (
                    <WaitingListOfferCard
                      waitingListOffer={waitingListOffer}
                      setShowApplicationCv={setShowApplicationCv}
                      setSelectedApplication={setSelectedApplication}
                      index={index}
                      key={index}
                      getUserDetails={getUserDetails}
                      loading={loading}
                    />
                  ))}
                </tbody>
              )}
            </table>
            <NoRecordMessage
              isEmpty={!waitingListOffers.length && !isLoading}
              title={"No record found"}
            />
            {showApplicationCv && (
              <ApplicationCv
                showApplicationCv={showApplicationCv}
                setShowApplicationCv={setShowApplicationCv}
                application={{
                  id: selectedApplication.applicationId,
                  idNumber: selectedApplication.idNumber,
                  phoneNumber: selectedApplication.phoneNumber,
                  userId: selectedApplication.userId,
                  lastName: selectedApplication.lastName,
                  firstName: selectedApplication.firstName,
                  email: selectedApplication.email,
                }}
              />
            )}
            {!isLoading && (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    // marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRequests / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                {!!waitingListOffers.length && (
                  <select
                    className="custom-select custom-select-sm ml-2"
                    style={{ width: "100px" }}
                    value={itemPerPage}
                    onChange={(event) => {
                      const itemPerPage = +event.target.value;
                      window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                      setItemPerPage(itemPerPage);
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                        currentStatus: filterStatus?.id || defaultIds,
                      };
                      getWaitingListOffers(
                        query,
                        setTotalRequests,
                        setIsLoading
                      );
                    }}
                  >
                    {[10, 15, 20, 50, 100].map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, waitingListOffers }) => {
  return { user, loading, waitingListOffers };
};
export default connect(mapStateToProps, {
  getWaitingListOffers,
  getUserDetails,
})(WaitingListOffers);

const WaitingListOfferCard = (props) => {
  const {
    waitingListOffer,
    index,
    setShowApplicationCv,
    setSelectedApplication,
    getUserDetails,
    loading,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };
  return (
    <tr>
      <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
        {index + 1}
      </th>
      {/* <th className="text-left">{waitingListOffer.userId}</th> */}
      <td className="text-left"> {waitingListOffer.lastName}</td>
      <td className="text-left"> {waitingListOffer.firstName}</td>
      {/* <td className="text-left"> {waitingListOffer.idNumber}</td> */}
      {/* <td className="text-left">{waitingListOffer.email}</td>
      <td className="text-left">{waitingListOffer.phoneNumber}</td> */}
      <td className="text-left">{waitingListOffer.positionName}</td>
      <td className="text-left">{waitingListOffer.entityName}</td>
      <td className="text-left">
        {moment(waitingListOffer.startDate).format("DD/MM/YYYY")}
      </td>
      <td className="text-left">
        {moment(waitingListOffer.endDate).format("DD/MM/YYYY")}
      </td>

      <td className="text-left">{waitingListOffer.totalScore}</td>
      <td className="text-left">{waitingListOffer.placementPositionName}</td>
      <td className="text-left">{waitingListOffer.placementEntityName}</td>
      <td className="text-left">
        {moment(waitingListOffer.offerDate).format("DD/MM/YYYY")}
      </td>
      <td className="text-left">
        <span
          className={`badge badge-${
            waitingListOffer.statusId === 1
              ? "warning"
              : waitingListOffer.statusId === 2
              ? "primary"
              : waitingListOffer.statusId === 5
              ? "success"
              : "danger"
          } `}
        >
          {waitingListOffer.statusName}
        </span>
      </td>

      <td className="text-center">
        <IconButton
          size="small"
          onClick={handleOpenMenu}
          // onMouseMove={handleOpenMenu}
          aria-label="settings"
        >
          <Badge color="info">
            <span className="material-icons">more_vert</span>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              maxHeight: 400 * 4.5,
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <span>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setSelectedApplication(waitingListOffer);
                setShowApplicationCv(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-1">open_in_new</span>
              Application Profile
            </MenuItem>
          </span>
          <Divider className="my-1" />
          <MenuItem
            disabled={loading}
            onClick={() => {
              getUserDetails(
                waitingListOffer.userId,
                handleOpenCurrentProfile
              );
            }}
            className="text-dark font-weight-light"
          >
            <BadgeIcon className="mr-2 text-primary" />
            {loading ? "Wait...." : "Currently Profile"}
          </MenuItem>
        </Menu>
        {showCurrentProfile && (
          <UserResume
            showProfileDialog={showCurrentProfile}
            setShowProfileDialog={setShowCurrentProfile}
          />
        )}
      </td>
    </tr>
  );
};
