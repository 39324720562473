import { defaultState } from "./state";
import disciplineTypes from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...disciplineTypes };

// faults reducer
const faults = (faultsState = defaultState.faults, action) => {
  switch (action.type) {
    case types.SET_FAULTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.faults;

    default:
      return faultsState;
  }
};

//sanctions reducer
const sanctions = (sanctionsState = defaultState.sanctions, action) => {
  switch (action.type) {
    case types.SET_SANCTIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sanctions;

    default:
      return sanctionsState;
  }
};

//sanctions reducer
const disciplineLetterNo = (
  disciplineLetterNoState = defaultState.disciplineLetterNo,
  action
) => {
  switch (action.type) {
    case types.SET_DISCIPLINE_LETTER_NO:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.disciplineLetterNo;

    default:
      return disciplineLetterNoState;
  }
};
//cases reduser
const entitySectorCases = (
  casesState = defaultState.entitySectorCases,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_CASES:
      return action.data;

    case types.ADD_ENTITY_SECTOR_CASE:
      const tmpState = [...casesState];
      tmpState.unshift(action.data);
      return tmpState;

    case types.DELETE_CASE:
      const tmpRemoveMyRequestsState = [...casesState];
      const index = tmpRemoveMyRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveMyRequestsState.splice(index, 1);

      return tmpRemoveMyRequestsState;

    case types.UPDATE_DISCIPLINARY_CASE: {
      const tmpState = [...casesState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;
      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.entitySectorCases;

    default:
      return casesState;
  }
};

const unitCases = (casesState = defaultState.unitCases, action) => {
  switch (action.type) {
    case types.SET_UNIT_CASES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.unitCases;

    default:
      return casesState;
  }
};

const myCases = (casesState = defaultState.myCases, action) => {
  switch (action.type) {
    case types.SET_MY_CASES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.myCases;

    default:
      return casesState;
  }
};

const caseRequest = (caseRequestState = defaultState.caseRequest, action) => {
  switch (action.type) {
    case types.SET_CASE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.caseRequest;

    default:
      return caseRequestState;
  }
};

const caseAttachments = (
  caseAttachmentsState = defaultState.caseAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_CASE_ATTACHMENTS: {
      return action.data;
    }

    case types.ADD_CASE_ATTACHMENT: {
      const tmpState = [...caseAttachmentsState];
      tmpState.unshift(action.data);
      return tmpState;
    }
    case types.UPDATE_CASE_ATTACHMENT: {
      const tmpState = [...caseAttachmentsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;
      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.caseAttachments;
    default:
      return caseAttachmentsState;
  }
};
export default {
  faults,
  sanctions,
  entitySectorCases,
  unitCases,
  myCases,
  caseRequest,
  caseAttachments,
  disciplineLetterNo,
};
