import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

const EventCalendar = ({ events }) => {
  const handleEventClick = ({ event }) => {
    // Extracting event details and displaying them
    const { title, start, end } = event;
    const startDate = start.toLocaleDateString();
    const endDate = end ? ` to ${end.toLocaleDateString()}` : "";
    alert(`Event: ${title}\nStart: ${startDate}${endDate}`);
    // You can replace this alert with a modal or custom display logic
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next",
        center: "title",
        right: "dayGridDay,dayGridWeek,dayGridMonth", // user can switch between the two
      }}
      events={events}
      eventClick={handleEventClick}
    />
  );
};

export default EventCalendar;
