import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import { connect } from "react-redux";
import { CardContent, Toolbar, Box, Typography } from "@mui/material";
import { textCapitalized } from "../../common/components/Utils";

const Dashboard = (props) => {
  const { user } = props;
  const [currentEmployment, setcurrentEmployment] = useState(null);
  const [period, setPeriod] = useState("Good Morning");
  useEffect(() => {
    const currentEmployment =
      user && !!user.employments.length
        ? user.employments.find(
            ({ entitySectorId, endDate }) =>
              entitySectorId === user.selectedEntitySector.id && !endDate
          )
        : null;

    setcurrentEmployment(currentEmployment);
    let now = new Date();

    let isMorning = now.getHours() > 5 && now.getHours() <= 12;
    let isAfternoon = now.getHours() > 12 && now.getHours() <= 18;
    let isEvening = now.getHours() > 18 && now.getHours() <= 22;
    let isNight = now.getHours() > 22 || now.getHours() <= 5;

    if (isMorning) setPeriod("Good Morning");
    if (isAfternoon) setPeriod("Good Afternoon");
    if (isEvening) setPeriod("Good Evening");
    if (isNight) setPeriod("Good Night");
  }, [user]);
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                className="text-uppercase"
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>RECRUITMENT</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>
                <span style={{ color: "rgb(7, 142, 206)" }}>Welcome</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                {/* <SearchBox onSearch={onSearch} placeholder="Search…" /> */}
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              maxHeight: "auto",
              margin: ".5em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
              className="bg-light"
            >
              <div className="row px-5 py-3 d-flex justify-content-center">
                <div className="col-lg-6 col-sm-12 col-xs-12">
                  <div
                    className="card rounded  bg-light-success  mb-5 elevated"
                    style={{
                      border: "1px solid #078ece",
                    }}
                  >
                    <div className="card-body d-flex justify-content-center flex-wrap  pe-0">
                      <div className="flex-grow-1 mt-0 me-9 me-md-0">
                        <div className="position-relative text-gray-800 fs-5 z-index-2 fw-bold">
                          <span>
                            {period},
                            <strong className="ml-2 employee-name text-capitalize">
                              {user && textCapitalized(user.firstName)}
                            </strong>
                          </span>
                        </div>

                        <span className="text-gray-600 fw-semibold fs-6 mb-2 d-block">
                          {currentEmployment && (
                            <>
                              <span className="text-capitalize">
                                {textCapitalized(
                                  currentEmployment.positionName
                                )}
                                ({" "}
                                <strong className="text-primary">
                                  {textCapitalized(currentEmployment.unitName)}
                                </strong>{" "}
                                )
                              </span>
                              <span className="ml-1">in</span>
                            </>
                          )}
                          <span className="ml-1">
                            <span className="text-gray-800 text-capitalize">
                              {user &&
                                user.selectedEntitySector &&
                                textCapitalized(
                                  user.selectedEntitySector.entityName
                                )}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(Dashboard);
