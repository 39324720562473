import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Input,
  Checkbox,
  Card,
  CardHeader,
  Tooltip,
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { getEntities } from "../../../store/structure/actions";
import {
  getFaultWithSanction,
  initiateCase,
  getDisciplineLetterNo,
} from "../../../store/discipline/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import { getHeadOfUnit } from "../../../store/structure/actions";
import moment from "moment";
import { showError } from "../../toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getFaultDictionaryFormat,
  removeDuplicates,
} from "../../common/components/Utils";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import NoResults from "../../common/components/NoResults";
import BackdropLoader from "../../common/components/BackdropLoader";
import DisciplinaryRequestExplanation from "../../Documents/LetterFormat/DisciplinaryRequestExplanation";
import defaultProfile from "../../assets/default-profile.jpg";
import SearchBox from "../../common/components/SearchBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const steps = ["Fill Request Form", "Fill Request Letter", "Attachments"];

const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const NewDisplinarlyCaseRequestDialog = (props) => {
  const {
    loading,
    onWait,
    showNewDisplinarlyCaseRequestDialog,
    setShowNewDisplinarlyCaseRequestDialog,
    employee,
    initiateCase,
    faults,
    getFaultWithSanction,
    getEntities,
    entities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    getHeadOfUnit,
    headOfUnit,
    handleCloseInitiateCase,
    disciplineLetterNo,
    getDisciplineLetterNo,
    setShowCaseDetail,
    isFirstCategory = false,
  } = props;
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [searchTeam, setSearchTeam] = useState("");
  const [filteredFaults, setFilteredFaults] = useState([...faults]);
  const [reason, setReason] = useState("");
  const [showCaseInitiateForm, setShowCaseInitiateForm] = useState("");
  
  // console.log(faults);
  const history = useHistory();
  const containerRef = useRef(null);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fileInputRef = useRef(null);
  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!faults.length) getFaultWithSanction();
  }, []);
  useEffect(() => {
    getDisciplineLetterNo();
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
    if (!headOfUnit) getHeadOfUnit(employee.unitId);
  }, []);

  useEffect(() => {
    const filtered = [...faults].filter(
      ({ faultName, sanction, faultCategoryName }) =>
        (faultName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (faultCategoryName || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (sanction || "").toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredFaults(filtered);
  }, [searchTeam, faults]);

  const [activeStep, setActiveStep] = useState(0);
  const [ccData, setCCData] = useState([]);
  const [tmpFaults, setTmpFaults] = useState([]);
  const [pdfAttachment, setPdfAttachment] = useState(null);

  const [fileUrl, setFileUrl] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const [formData, setFormData] = useState({
    employeePositionId: employee.employeePositionId,
    employeeId: employee.id,
    docLetterNo: disciplineLetterNo,
    cc: null,
    attachments: [],
    faults: null,
    caseDescription: "",
    reason:""
  });

  const [errors, setErrors] = useState({
    reasonHasError: false,
    effectiveOnHasError: false,
    faultHasError: false,
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    attachmentHasErrors: [],
    pdfAttachmentHasError: false,
  });

  const formValidator = () => {
    const error = {
      reasonHasError: false,
      effectiveOnHasError: false,
      faultHasError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      attachmentHasErrors: [],
      pdfAttachmentHasError: false,
    };

    if (!tmpFaults.length) {
      error.faultHasError = true;
      error.hasError = true;
    }

    if (!formData.caseDescription) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      scrollToBottom();
      return true;
    }
    return false;
  };

  const formValidator1 = () => {
    const error = {
      reasonHasError: false,
      effectiveOnHasError: false,
      faultHasError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      attachmentHasErrors: [],
      pdfAttachmentHasError: false,
    };

    if (!tmpFaults.length) {
      error.faultHasError = true;
      error.hasError = true;
    }

    if (!formData.caseDescription) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    // if (!ccData.length) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }

    // if (!formData.docLetterNo) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      scrollToBottom();
      return true;
    }
    return false;
  };

  const formValidator2 = () => {
    const error = {
      attachmentHasErrors: [],
      hasError: false,
      pdfAttachmentHasError: false,
    };

    // if ((attachments || [])?.length === 0) {
    //   error.attachmentHasErrors.push({
    //     id: 0,
    //     name: "Attachment is required",
    //   });
    //   error.hasError = true;
    // }

    // if ((attachments || [])?.length < 3) {
    //   error.attachmentHasErrors.push({
    //     id: 0,
    //     name: "You should upload At least 3 documents",
    //   });
    //   error.hasError = true;
    // }
    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onClose = () => {
    setFormData({
      employeePositionId: "",
      employeeId: "",
      docLetterNo: "",
      cc: null,
      attachments: [],
      faults: null,
      caseDescription: "",
    });
    setAttachments([]);
    setPdfAttachment(null);
    setFileUrl(null);
    setShowNewDisplinarlyCaseRequestDialog(false);
    setActiveStep(0);
    if (isFirstCategory) handleCloseInitiateCase();
  };

  const onSave = () => {
    const payload = new FormData();
    formData.attachments = attachments;
    formData.cc = JSON.stringify(ccData);
    formData.faults = JSON.stringify(tmpFaults);
    formData.reason = reason;
    
    const keys = Object.keys(formData);
    for (const key of keys) {
      if (formData.hasOwnProperty(key)) {
        if (key === "attachments" && formData[key] && !!formData[key].length)
          formData[key].forEach((row, i) => {
            payload.append(key, row.file);
          });
        else payload.append(`${key}`, `${formData[key]}`);
      }
    }
    initiateCase(
      payload,
      onClose,
      setShowCaseDetail,
      setActiveStep,
      showCaseInitiateForm,
      history,
      isFirstCategory
    );
  };

  const handleNext = () => {
    let allowNextStep = false;
    setFormData({
      ...formData,
      docLetterNo: disciplineLetterNo,
    });

    if (activeStep === 0) {
      if (!formValidator()) {
        allowNextStep = true;
      }
    }

    if (activeStep === 1) {
      if (!formValidator1()) allowNextStep = true;
    }
    if (activeStep === 2) {
      if (!formValidator2()) allowNextStep = true;
    }

    if (allowNextStep && activeStep === 2) setShowCaseInitiateForm(true);

    if (
      allowNextStep &&
      (activeStep === 0 || activeStep === 1 || activeStep === 2)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    setErrors({ ...errors, pdfAttachmentHasError: false });
    setPdfAttachment(null);
    let pdfAttachment = event.target.files[0];

    if (
      convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf" &&
      pdfAttachment?.type !== "application/pdf"
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
      fileInputRef.current.value = "";
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
      fileInputRef.current.value = "";
    } else {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFileUrl(e.target.result);
      };

      reader.readAsDataURL(pdfAttachment);

      setPdfAttachment(pdfAttachment);
    }
  };

  const addPDFFile = () => {
    const error = {
      pdfAttachmentHasError: false,
      hasError: false,
    };
    if (!pdfAttachment) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    const tmpDocuments = [...attachments];

    const document = { id: 0, name: "", file: null, url: "" };
    document.name = pdfAttachment?.name;
    document.url = fileUrl;
    document.id = !tmpDocuments.length
      ? 1
      : tmpDocuments[tmpDocuments.length - 1].id + 1;
    document.file = pdfAttachment;

    tmpDocuments.push(document);
    setAttachments(tmpDocuments);
    setPdfAttachment(null);
    setFileUrl(null);
    fileInputRef.current.value = "";
  };

  const [checked, setChecked] = useState([0]);

  const handleFaultToggle = (fault) => () => {
    const currentIndex = checked.indexOf(fault.id);

    const currentIndex2 = tmpFaults.findIndex(({ id }) => id === fault.id);

    const newChecked = [...checked];
    const uniqueFaults = [...tmpFaults];

    if (currentIndex === -1) {
      newChecked.push(fault.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (currentIndex2 === -1) {
      uniqueFaults.push(fault);
    } else {
      uniqueFaults.splice(currentIndex2, 1);
    }

    let faultHasError = false;

    setErrors({ ...errors, faultHasError });

    setChecked(newChecked);
    setTmpFaults(uniqueFaults);
  };

  const onSearchFault = (term) => {
    setSearchTeam(term);
  };
  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showNewDisplinarlyCaseRequestDialog}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">New Displinarly Case</span> /
                <span className="ml-2">
                  {employee?.lastName} {employee?.firstName}
                </span>
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mt-4">
            <Box sx={{ width: "100%", mt: 0 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  // stepProps.completed = isCompleted;

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <div className="py-3">
              <Card
                className={`rounded hoverable-card mb-2 mt-2 pb-3`}
                variant="outlined"
                sx={{
                  backgroundColor: `#cdeef8`,
                  border: "none",
                }}
              >
                <CardHeader
                  className="pb-0 pt-2"
                  avatar={
                    <Tooltip title={employee.names}>
                      <Box
                        sx={{
                          position: "relative",
                          display: "inline-flex",
                          cursor: "default",
                        }}
                      >
                        <Avatar
                          sizes="large"
                          style={{
                            border: "1px solid #d1cbcb",
                          }}
                          alt={employee.names}
                          className=""
                          src={defaultProfile}
                        />
                      </Box>
                    </Tooltip>
                  }
                  title={
                    <span
                      className="d-flex flex-column"
                      style={{ maxWidth: "400px" }}
                    >
                      <span className="d-flex align-items-center text-uppercase font-weight-bold text-primary">
                        {employee.names}
                      </span>
                      <span className="d-block text-primary ml-1 text-sm">
                        {employee.positionName}{" "}
                        {/* <strong className="text-dark">
                                            @{employee.unitName.toUpperCase()}
                                          </strong> */}
                      </span>
                    </span>
                  }
                  subheader={
                    <span className="d-flex align-items-center flex-wrap justify-content-between ">
                      <span className="d-flex align-items-center flex-wrap">
                        <span className="">
                          <span className="d-flex align-items-center  flex-wrap">
                            <span
                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                              style={{
                                cursor: "default",
                                borderRadius: "8px",
                              }}
                            >
                              <span className={`badge badge-primary mr-1`}>
                                {employee?.totalCases || 0}
                              </span>
                              All cases
                            </span>

                            <span
                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                              style={{
                                cursor: "default",
                                borderRadius: "8px",
                              }}
                            >
                              <span className={`badge badge-warning mr-1`}>
                                {employee?.totalOngoing || 0}
                              </span>
                              Ongoing
                            </span>
                            <span
                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                              style={{
                                cursor: "default",
                                // borderRight: "2px solid #ccd0d6",
                                // borderLeft: "2px solid #ccd0d6",
                                borderRadius: "8px",
                              }}
                            >
                              <span className={`badge badge-success mr-1`}>
                                {employee?.totalSanctioned || 0}
                              </span>
                              Sanctioned Cases
                            </span>

                            <span
                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                              style={{
                                cursor: "default",
                                // borderRight: "2px solid #ccd0d6",
                                // borderLeft: "2px solid #ccd0d6",
                                borderRadius: "8px",
                              }}
                            >
                              <span className={`badge badge-secondary mr-1`}>
                                {employee?.totalClosed || 0}
                              </span>
                              Closed Cases
                            </span>

                            <span
                              className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                              style={{
                                cursor: "default",
                                // borderRight: "2px solid #ccd0d6",
                                // borderLeft: "2px solid #ccd0d6",
                                // borderRadius: "8px",
                              }}
                            >
                              <span className={`badge badge-danger mr-1`}>
                                {employee?.totalRejected || 0}
                              </span>{" "}
                              Rejected
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  }
                />
              </Card>
              {!faults.length && <AdvertSkeleton />}
              {!!faults.length && (
                <>
                  {activeStep === 0 && (
                    <>
                      <div className="row w-100 mt-2 ml-1" ref={containerRef}>
                        <div className="col-12">
                          <div className="card service-card valid mb-2 mt-1 w-100">
                            <div
                              className="card-header"
                              style={{ padding: "0.25rem 1.2rem" }}
                            >
                              Choose employee faults
                            </div>
                            <div className="card-body">
                              <div className="px-2 pt-2">
                                <SearchBox
                                  disabled={!faults.length}
                                  onSearch={onSearchFault}
                                  placeholder="Search fault(s) ..."
                                />
                              </div>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                  maxHeight: "500px",
                                  overflowY: "auto",
                                }}
                              >
                                {filteredFaults.map(
                                  ({
                                    faultName,
                                    sanction,
                                    id,
                                    faultCategoryName,
                                    faultCategoryId,
                                  }) => {
                                    const labelId = `checkbox-list-label-${id}`;

                                    return (
                                      <>
                                        <ListItem
                                          key={id}
                                          disablePadding
                                          style={{
                                            backgroundColor: `${
                                              checked.indexOf(id) !== -1
                                                ? "#e9ecef"
                                                : ""
                                            }`,
                                          }}
                                        >
                                          <ListItemButton
                                            role={undefined}
                                            onClick={handleFaultToggle({
                                              faultName: faultName,
                                              sanction: sanction,
                                              id: id,
                                              faultCategoryName:
                                                faultCategoryName,
                                              faultCategoryId: faultCategoryId,
                                            })}
                                            dense
                                          >
                                            <ListItemIcon>
                                              <Checkbox
                                                edge="start"
                                                checked={
                                                  checked.indexOf(id) !== -1
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                  "aria-labelledby": labelId,
                                                }}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              id={labelId}
                                              primary={faultName}
                                              secondary={
                                                <strong
                                                  className={`d-flex align-items-center text-dark text-uppercase `}
                                                  style={{
                                                    cursor: "default",
                                                    borderRadius: "8px",
                                                    fontSize: "10px",
                                                  }}
                                                >
                                                  Sanction:
                                                  <span
                                                    className={`badge badge-${
                                                      (
                                                        sanction || ""
                                                      ).toLowerCase() ===
                                                      "warning"
                                                        ? "warning"
                                                        : (
                                                            sanction || ""
                                                          ).toLowerCase() ===
                                                          "dismissal"
                                                        ? "danger"
                                                        : (
                                                            sanction || ""
                                                          ).toLowerCase() ===
                                                          "reprimand"
                                                        ? "primary"
                                                        : "secondary"
                                                    } ml-1`}
                                                  >
                                                    {sanction}
                                                  </span>
                                                </strong>
                                              }
                                            />
                                          </ListItemButton>
                                        </ListItem>
                                        <Divider />
                                      </>
                                    );
                                  }
                                )}
                              </List>
                              {/* 
                              <Autocomplete
                                size="small"
                                fullWidth
                                multiple
                                id="tmpFaults"
                                options={faults.map(
                                  ({
                                    faultName,
                                    sanction,
                                    id,
                                    faultCategoryName,
                                    faultCategoryId,
                                  }) => ({
                                    faultName,
                                    sanction,
                                    id,
                                    faultCategoryName,
                                    faultCategoryId,
                                  })
                                )}
                                value={tmpFaults}
                                onChange={(e, fault) => {
                                  console.log(fault);
                                  const uniqueFaults = removeDuplicates(fault);
                                  setTmpFaults(uniqueFaults);
                                  const faultHasError = false;
                                  setErrors({ ...errors, faultHasError });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.faultName}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props} className="w-100">
                                    <div className="row w-100">
                                      <div className="col-10">
                                        <Checkbox
                                          icon={
                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                          }
                                          checkedIcon={
                                            <CheckBoxIcon fontSize="small" />
                                          }
                                          checked={
                                            selected ||
                                            !!tmpFaults.find(
                                              ({ id }) => id === option.id
                                            )
                                          }
                                        />
                                        {option.faultName}
                                      </div>
                                      <div className="col-2">
                                        <span className="badge badge-primary text-right float-right">
                                          {option.sanction}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select the employee faults"}
                                    placeholder={"Select the employee faults"}
                                  />
                                )}
                              /> */}

                              {errors.faultHasError && (
                                <small className="text-danger mb-1">
                                  Please, select employee faults
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-3 ">
                          <TextField
                            fullWidth
                            size="small"
                            name="caseDescription"
                            required={true}
                            multiline
                            rows={6}
                            value={formData.caseDescription || ""}
                            label={`Type case description`}
                            placeholder={`Type case description`}
                            inputProps={{ maxLength: 459 }}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                caseDescription: e.target.value,
                              });

                              setErrors({ ...errors, reasonHasError: false });
                            }}
                          />
                          {errors.reasonHasError && (
                            <small className="text-danger mb-1">
                              Please,type case description.
                            </small>
                          )}
                        </div>
                        <div ref={bottomRef}></div>
                      </div>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <div className="mx-2 w-full">
                        <DisciplinaryRequestExplanation
                          recipientName={
                            employee.lastName + " " + employee.firstName
                          }
                          recipientEmail={employee.email}
                          recipientPhone={employee.phoneNumber}
                          institutionName={employee.entityName}
                          recruitmentInstitutionName={employee.entityName}
                          recruitmentPositionName={employee.positionName}
                          enableToFillLetter={true}
                          faults={tmpFaults}
                          subTitle={
                            "Dear " +
                            (employee.lastName + " " + employee.firstName)
                          }
                          docLetterNo={formData.docLetterNo}
                          effectiveDate={moment(formData.effectiveOn).format(
                            "LL"
                          )}
                          competentAuthorityName={
                            !tmpFaults.find(
                              ({ faultCategoryId }) => faultCategoryId === 2
                            )
                              ? headOfUnit.names
                              : !!requestDocumentSignerDetail
                              ? requestDocumentSignerDetail
                                  .competentAuthorityUser.names
                              : ""
                          }
                          competentAuthorityPosition={
                            !tmpFaults.find(
                              ({ faultCategoryId }) => faultCategoryId === 2
                            )
                              ? headOfUnit.positionName
                              : !!requestDocumentSignerDetail
                              ? requestDocumentSignerDetail
                                  .competentAuthorityUser.positionName
                              : ""
                          }
                          ccSelectForm={
                            <>
                              <Autocomplete
                                size="small"
                                fullWidth
                                multiple
                                id="ccData"
                                options={entities.map(
                                  ({ name, institutionEmail, id,ccName }) => ({
                                    name,
                                    institutionEmail,
                                    id,
                                    ccName
                                  })
                                )}
                                value={ccData}
                                onChange={(e, cc) => {
                                  const uniqueCc = removeDuplicates(cc);
                                  setCCData(uniqueCc);
                                  const ccHasError = false;
                                  setErrors({ ...errors, ccHasError });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      checked={
                                        selected ||
                                        !!ccData.find(
                                          ({ id }) => id === option.id
                                        )
                                      }
                                    />
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select the institution to copy"}
                                    placeholder={
                                      "Select the institution to copy"
                                    }
                                  />
                                )}
                              />

                              {errors.ccHasError && (
                                <small className="text-danger mb-1">
                                  Please, select the institution to copy
                                </small>
                              )}
                            </>
                          }
                          ccData={ccData}
                          signContent={
                            <table>
                              <tr>
                                <td
                                  style={{
                                    width: "2%",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    position: "relative",
                                    left: "-66px",

                                    width: "20%",
                                    fontSize: "14px",
                                    textAlign: "left",
                                  }}
                                >
                                  <span className="d-block text-danger">
                                    Not signed
                                  </span>
                                </td>
                                <td
                                  style={{
                                    width: "20%",
                                    fontSize: "12px",
                                    textAlign: "left",
                                  }}
                                ></td>
                              </tr>
                            </table>
                          }
                        />
                      </div>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <div className="card service-card valid mb-2 mt-1">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Add Attachment
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-10 mt-1">
                              <input
                                type="file"
                                name="file"
                                className="form-control"
                                accept="application/pdf"
                                placeholder="Select pdf file"
                                onChange={(e) => handleUploadedPDFFile(e)}
                                ref={fileInputRef}
                              />
                              {errors.pdfAttachmentHasError && (
                                <small className="text-danger mb-1">
                                  Please, Type attachment name
                                </small>
                              )}
                            </div>
                            <div className="col-lg-2 mt-1">
                              <Button
                                color="primary"
                                variant="contained"
                                disabled={!!errors.pdfAttachmentHasError}
                                onClick={() => addPDFFile()}
                                size="md"
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card service-card valid mb-2 mt-1">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Attachments
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {Array.isArray(attachments) &&
                              (attachments || [])?.length > 0 && (
                                <div className="col-lg-12">
                                  {(attachments || [])?.map(
                                    (document, index) => (
                                      <div key={index}>
                                        <div className="form-group1 mb-2">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text">
                                                <i className="fas fa-paperclip"></i>
                                              </span>
                                            </div>

                                            <div
                                              className="form-control"
                                              style={{
                                                backgroundColor:
                                                  "rgb(229, 246, 253)",

                                                color: "#007bff",
                                              }}
                                            >
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: document.url,
                                                  });
                                                }}
                                              >
                                                {document.name}
                                              </span>

                                              <IconButton
                                                onClick={() => {
                                                  const tmpDocuments = [
                                                    ...attachments,
                                                  ];
                                                  const index =
                                                    tmpDocuments.findIndex(
                                                      ({ id }) =>
                                                        id === document.id
                                                    );

                                                  tmpDocuments.splice(index, 1);
                                                  setAttachments(tmpDocuments);
                                                }}
                                                style={{ marginTop: "-6px" }}
                                                color="error"
                                                className="float-right"
                                                aria-label="delete"
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: document.url,
                                                  });
                                                }}
                                                style={{ marginTop: "-6px" }}
                                                color="info"
                                                className="float-right"
                                                aria-label="view"
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}

                            {Array.isArray(attachments) &&
                              (attachments || [])?.length === 0 && (
                                <div className="col-lg-12">
                                  <NoResults />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {!!errors?.attachmentHasErrors?.length && (
                        <>
                          {errors.attachmentHasErrors.map((document, index) => (
                            <div key={index}>
                              <div
                                className={`form-group border border-danger p-2`}
                              >
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                    color: `red `,
                                  }}
                                >
                                  {index + 1}.{" "}
                                  <span className="ml-1">{document.name}</span>
                                </legend>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {activeStep === 3 && !!onWait && (
                    <>
                      <BackdropLoader isLoading={onWait} />
                    </>
                  )}
                </>
              )}
              {showUploadedDocument && uploadedDocument && (
                <PreviewPdfUrl
                  isBase64={true}
                  showDocument={showUploadedDocument}
                  setShowDocument={setShowUploadedDocument}
                  document={{
                    name: `View ${uploadedDocument?.name || ""}`,
                    url: uploadedDocument?.url || "",
                  }}
                />
              )}
            </div>
          </Box>
        </DialogContent>
        {!!faults.length && !onWait && activeStep !== 3 && (
          <DialogActions className="d-flex justify-content-center pb-3">
            {activeStep > 0 && (
              <Button
                style={{
                  ...InactiveButton,
                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="md"
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
              >
                Back
              </Button>
            )}

            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              disabled={loading || onWait}
              onClick={handleNext}
              color={activeStep === 2 ? "success" : "primary"}
              variant="contained"
              size="md"
            >
              {onWait ? "Wait.." : <>{activeStep === 2 ? "Submit" : "Next"}</>}
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {showCaseInitiateForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseInitiateForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Please provide the reason why you initiate the case?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseInitiateForm}
            onYes={() => {
              onSave();
            }}
          />
        )}

      {/* {showConfirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            employee?.lastName + " " + employee?.firstName
          }?`}
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(
              employeeExitRequest.id,
              setShowConfirmationDialog
            );
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  faults,
  requestDocumentSignerDetail,
  entities,
  headOfUnit,
  disciplineLetterNo,
}) => {
  return {
    loading,
    onWait,
    faults,
    requestDocumentSignerDetail,
    entities,
    headOfUnit,
    disciplineLetterNo,
  };
};
export default connect(mapStateToProps, {
  initiateCase,
  getFaultWithSanction,
  getEntities,
  getRequestDocumentSignerDetails,
  getHeadOfUnit,
  getDisciplineLetterNo,
})(NewDisplinarlyCaseRequestDialog);
