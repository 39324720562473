import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Link } from "react-router-dom";
import {
  getUserProfileStatus,
  updateBRDStatus,
} from "../../../store/profile/actions";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import CVUpload from "./CVUpload";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { Button } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const ProfileStatus = (props) => {
  const {
    onWait,
    userProfileStatus,
    getUserProfileStatus,
    userEducations,
    userExperiences,
    userLanguages,
    userDisabilities,
    userCertificates,
    userReferees,
    userPublications,
    userCV,
    userProfile,
    canEdit,
    updateBRDStatus,
  } = props;

  useEffect(() => {
    getUserProfileStatus(userProfile.id);
  }, [
    userEducations,
    userExperiences,
    userLanguages,
    userDisabilities,
    userCertificates,
    userReferees,
    userPublications,
    userCV,
  ]);

  const profilePercentage = Math.round(
    (((userProfileStatus.verified ? 1 : 0) +
      (userProfileStatus.education ? 1 : 0) +
      (userProfileStatus.language ? 1 : 0) +
      (userProfileStatus.referees ? 1 : 0) +
      (userProfileStatus.experience ? 1 : 0) +
      (userProfileStatus.disability ? 1 : 0) +
      (userProfileStatus.certificate ? 1 : 0) +
      (userProfileStatus.publication ? 1 : 0) +
      (userProfileStatus.cv ? 1 : 0)) *
      100.0) /
      9.0
  );

  const brdStatus = {
    progress:
      +(
        ((userProfileStatus?.totalPaidAmount || 0) * 100) /
        (userProfileStatus?.totalLoanAmount || 1)
      ) > 100.0
        ? 100
        : ((userProfileStatus?.totalPaidAmount || 0) * 100) /
          (userProfileStatus?.totalLoanAmount || 1),
    labels: ["BRD loan status"],
    datasets: [
      {
        label: `TotalLoan`,

        data: [userProfileStatus?.totalLoanAmount || 0],
        backgroundColor: ["rgba(54, 162, 235, 0.5)"],
        borderColor: ["rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
      {
        label: `TotalPaid`,
        data: [userProfileStatus?.totalPaidAmount || 0],
        backgroundColor: ["rgba(80, 181, 88,0.6)"],
        borderColor: ["rgba(80, 181, 88, 1)"],
        borderWidth: 1,
      },
      {
        label: "Remaining",
        data: [userProfileStatus?.remainingLoanAmount || 0],
        backgroundColor: ["rgba(255, 206, 86, 0.5)"],
        borderColor: ["rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="elevated rounded border px-3 py-1">
        <span className="text-primary font-weight-bold">
          Profile status
          <small className="badge-pill text-danger">* is required</small>
        </span>

        <div className="d-flex flex-row align-items-center mt-1 mb-2">
          <div>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.verified ? "text-success" : "text-danger"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.verified ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Identity*</span>
            </Link>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.education ? "text-success" : "text-danger"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.education ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Education*</span>
            </Link>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.language ? "text-success" : "text-danger"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.language ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Languages*</span>
            </Link>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.disability ? "text-success" : "text-danger"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.disability ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Disability*</span>
            </Link>

            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.cv ? "text-success" : "text-danger"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.cv ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Upload CV*</span>
            </Link>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.experience ? "text-success" : "text-secondary"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.experience ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Experience</span>
            </Link>

            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.certificate
                  ? "text-success"
                  : "text-secondary"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.certificate ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Certificates</span>
            </Link>

            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.publication
                  ? "text-success"
                  : "text-secondary"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.publication ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">Publications</span>
            </Link>
            <Link
              to="/applicant/profile"
              className={`d-flex flex-row align-items-center ${
                userProfileStatus.referees ? "text-success" : "text-secondary"
              }`}
              style={{ textDecoration: "none" }}
            >
              {userProfileStatus.referees ? (
                <CheckCircleOutlineRoundedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              ) : (
                <CircleOutlinedIcon
                  style={{ fontSize: "16px", marginTop: "3px" }}
                />
              )}
              <span className="ml-1">3 Referees</span>
            </Link>
          </div>
          <div className="w-100 d-block d-sm-none d-md-none d-lg-block">
            <div
              className="mx-auto"
              style={{
                width: "60px",
                height: "60px",
                lineHeight: "60px",
                borderRadius: "50%",
                fontSize: "20px",
                color: +profilePercentage >= 100 ? "#28a745" : "#545454",
                textAlign: "center",
                border: `solid 1px ${
                  +profilePercentage >= 100 ? "#28a745" : "#545454"
                }`,
              }}
            >
              {profilePercentage}%
            </div>
          </div>
        </div>
        <CVUpload canEdit={canEdit} />
      </div>

      {!!canEdit && (
        <div className="card-body d-flex  flex-column elevated rounded border px-3 py-1 mt-3">
          <small className="text-primary text-center text-uppercase">
            BRD Education loan status
          </small>
          <div
            className="progress bg-light "
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              cursor: "default",
              height: "20px",
            }}
          >
            <div
              className={`progress-bar  text-${
                brdStatus.progress < 5 ? "dark" : "light"
              } bg-${
                brdStatus.progress < 50
                  ? "danger"
                  : brdStatus.progress < 70
                  ? "warning"
                  : brdStatus.progress < 100
                  ? "info"
                  : "success"
              }`}
              role="progressbar"
              style={{ width: brdStatus.progress + "%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span
                className={`${brdStatus.progress < 5 ? "ml-5 text-dark" : ""}`}
              >
                {brdStatus.progress.toFixed(0)}%
              </span>
            </div>
          </div>
          <div className="d-flex  flex-wrap mt-2">
            <Bar
              redraw={true}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                  title: {
                    display: true,
                    text: "BRD loan status",
                  },
                },

                interaction: {
                  mode: "index",
                  intersect: false,
                },
              }}
              data={brdStatus}
            />

            <Button
              disabled={onWait}
              size="small"
              className="btn-block my-3"
              variant="outlined"
              onClick={() => updateBRDStatus(userProfile.id)}
            >
              {onWait ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  userProfile,
  onWait,
  userProfileStatus,
  userEducations,
  userExperiences,
  userLanguages,
  userDisabilities,
  userCertificates,
  userReferees,
  userPublications,
  userCV,
}) => ({
  userProfile,
  onWait,
  userProfileStatus,
  userEducations,
  userExperiences,
  userLanguages,
  userDisabilities,
  userCertificates,
  userReferees,
  userPublications,
  userCV,
});

export default connect(mapStateToProps, {
  getUserProfileStatus,
  updateBRDStatus,
})(ProfileStatus);
