import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { showError } from "../../toastify";
import SearchBox from "../../common/components/SearchBox";
import NoResults from "../../common/components/NoResults";
import axios from "axios";

const ShowApplicantCandidates = (props) => {
  const { user, showDialog, setShowDialog, application } = props;

  const [loading, setLoading] = useState(false);
  const [searchCandidate, setSearchCandidate] = useState("");

  const [candidateList, setCandidateList] = useState([]);

  // ### PAGINATION
  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedCandidates, setPaginatedCandidates] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getAllCandidatesResults();
  }, []);

  useEffect(() => {
    if (filteredData) {
      setPaginatedCandidates(filteredData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredData.length / itemsPerPage));
    }
  }, [filteredData, itemOffset, endOffset]);

  useEffect(() => {
    if (candidateList) {
      if (searchCandidate !== "") {
        const value = searchCandidate?.toLowerCase();
        setFilteredData(
          candidateList.filter(
            (item) =>
              ((item.userId || "") + (item.names || ""))
                .toLowerCase()
                .includes(value) ||
              (item.isMe && value === "me")
          )
        );
      } else {
        setFilteredData(candidateList);
      }
    }
  }, [candidateList, searchCandidate]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      (filteredData ? filteredData.length : 1);
    setItemOffset(newOffset);
  };

  const getAllCandidatesResults = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/recruitment/all-candidates-on-waiting?positionName=${application.positionName}&levelId=${application.levelId}`
      );

      setCandidateList(data);

      setLoading(false);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-primary font-weight-bold ">
              Candidates on waiting
            </span>{" "}
            | {application.positionName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-2 d-flex flex-column flex-md-row justify-content-md-between ">
            <SearchBox
              placeholder="Search..."
              disabled={!candidateList || candidateList.length === 0}
              onSearch={setSearchCandidate}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="table-responsive">
            <table
              className={`table table-sm ${
                candidateList ? "table-striped" : ""
              }`}
            >
              <thead>
                <tr>
                  <th style={{ width: "14px" }}>#</th>
                  <th>
                    Candidates{" "}
                    {!!candidateList.length && (
                      <span className="text-primary">
                        ({candidateList.length})
                      </span>
                    )}
                  </th>
                  <th>institution</th>
                  <th>startDate</th>
                  <th>endDate</th>
                  <th className="text-right">Score</th>
                </tr>
              </thead>
              <tbody>
                {loading && !candidateList.length ? (
                  Array.from(Array(10).keys()).map((item, index) => (
                    <tr align="left" key={index}>
                      {Array.from(Array(6).keys()).map((item, index) => (
                        <td key={index}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </td>
                      ))}
                    </tr>
                  ))
                ) : paginatedCandidates.length ? (
                  paginatedCandidates.map((candidate, index) => (
                    <tr
                      key={candidate.id}
                      className={`${candidate.isMe ? "tr-bordered-blue" : ""}`}
                    >
                      <td className="text-right">
                        {getNo(candidate.id, candidateList, "id") + 1}
                      </td>

                      <td className="text-center">
                        {candidate.isMe ? (
                          <span className="d-flex align-items-center justify-content-center">
                            {candidate.userId}
                            <span className="badge badge-primary ml-1">Me</span>
                          </span>
                        ) : (
                          <>-</>
                        )}
                      </td>

                      <td>{candidate.institutionName}</td>

                      <td>{moment(candidate.startDate).format("ll")}</td>

                      <td>{moment(candidate.endDate).format("ll")}</td>
                      <td className={`text-right`}>
                        {(candidate.score || 0).toFixed(2)}%
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <NoResults />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* display pagination */}
          </div>
        </DialogContent>
        <DialogActions>
          {candidateList.length > itemsPerPage && (
            <div
              className="justify-content-center col-12 mb-3"
              style={{ overflow: "hidden" }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, {})(ShowApplicantCandidates);
