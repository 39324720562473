import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import {
  getTempPayrolls,
  getActiveCostCenters,
  getEntitySectorPayrollTypes,
  getSupportingDocumentTypes,
  getPayrollPeriods,
} from "../../../store/payroll/actions";
import { connect } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import GeneratePayrollDialog from "../components/GeneratePayrollDialog";

import { CardContent, Toolbar } from "@mui/material";
import loadingGif from "../../assets/loading.gif";
import SearchBox from "../../common/components/SearchBox";
import PayrollCard from "../components/PayrollCard";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import {
  getCountBlacklistedActiveEmployees,
  downloadCSVBlacklistActiveEmployees,
} from "../../../store/exit/actions";

const TempPayrolls = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | In Progress Payrolls";
  });

  const {
    user,
    loading,
    tempPayrolls,
    getTempPayrolls,
    getActiveCostCenters,
    getEntitySectorPayrollTypes,
    supportingDocumentTypes,
    getSupportingDocumentTypes,
    getCountBlacklistedActiveEmployees,
    downloadCSVBlacklistActiveEmployees,
    getPayrollPeriods,
  } = props;

  const [searchTeam, setSearchTeam] = useState("");
  const [blacklistedActiveEmployeesCount, setBlacklistedActiveEmployeesCount] =
    useState(0);
  const [downloading, setDownloading] = useState(false);

  const [showGeneratePayrollForm, setShowGeneratePayrollForm] = useState(false);

  const [payrollPeriods, setPayrollPeriods] = useState([]);

  const theme = useTheme();

  useEffect(
    () => {
      getPayrollPeriods(setPayrollPeriods);
      getTempPayrolls();
      getCountBlacklistedActiveEmployees(setBlacklistedActiveEmployeesCount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [filteredArchives, setFilteredArchives] = useState([...tempPayrolls]);

  useEffect(() => {
    const filtered = [...tempPayrolls].filter(
      ({
        payrollTypeName,
        periodId,
        employeeGroupName,
        sourceOfFundName,
        payrollGroupName,
      }) =>
        (
          (payrollTypeName || "") +
          (periodId || "") +
          (employeeGroupName || "") +
          (payrollGroupName || "") +
          (sourceOfFundName || "")
        )
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredArchives(filtered);
  }, [searchTeam, tempPayrolls]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar className="d-flex flex-wrap justify-content-between mt-2">
              <Typography
                className="d-flex align-items-center flex-wrap"
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span className="text-uppercase">In Progress Payrolls</span>

                {hasPermissions(["IS_HR", "CAN_GENERATE_PAYROLL"]) && (
                  <>
                    <>
                      <Button
                        onClick={() => {
                          setShowGeneratePayrollForm(true);
                        }}
                        style={{
                          float: "right",
                          borderRadius: "8px",
                          height: "28px",
                          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                          border: `1px solid rgb(7, 142, 206)`,
                          display: "flex",
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                        size="md"
                        startIcon={<AddIcon />}
                        className="ml-2"
                      >
                        Generate Payroll
                      </Button>

                      {blacklistedActiveEmployeesCount > 0 && (
                        <Button
                          onClick={() => {
                            downloadCSVBlacklistActiveEmployees(setDownloading);
                          }}
                          style={{
                            borderRadius: "8px",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                          }}
                          variant="outlined"
                          size="small"
                          color="error"
                          // startIcon={
                          //   <span className="material-icons-round">download</span>
                          // }
                          className="ml-2 text-truncate"
                          disabled={downloading}
                        >
                          <span className="badge badge-danger mr-1">
                            {" "}
                            {blacklistedActiveEmployeesCount}
                          </span>{" "}
                          Blacklisted
                        </Button>
                      )}
                    </>
                    {/* <Box sx={{ flexGrow: 1 }} /> */}
                  </>
                )}
              </Typography>

              <div>
                <SearchBox
                  disabled={!tempPayrolls.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
            </Toolbar>
          </AppBar>

          {showGeneratePayrollForm && (
            <GeneratePayrollDialog
              payrollPeriods={payrollPeriods}
              showGeneratePayrollForm={showGeneratePayrollForm}
              setShowGeneratePayrollForm={setShowGeneratePayrollForm}
            />
          )}

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              // margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              className="py-0"
              style={{
                height: "auto",
                maxHeight: "63vh",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              {!!filteredArchives && !!filteredArchives.length && (
                <table className=" table-striped table-sm ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: "174px" }}>Payroll Number </th>
                      <th
                        className="px-1 text-center"
                        colSpan={2}
                        style={{
                          backgroundColor: "#e5f6fd",
                          borderBottom: "none",
                          borderTop: "none",
                          borderLeft: "1px solid #dee2e6",
                          borderRight: "1px solid #dee2e6",
                        }}
                      >
                        RSSB DECLARATIONS
                      </th>
                      <th className="px-1">TinNumber</th>
                      <th className="px-1">Period</th>

                      <th className="px-1">Payroll Type</th>

                      <th className="px-1">Cost Center</th>
                      <th className="px-1">Payroll Group</th>
                      <th className="px-1">Source Of Funds</th>
                      <th className="px-1 text-right">Total Salary</th>
                      <th className="px-1 text-right">Total Lumpsum</th>
                      <th className="px-1 text-right">Total Paid</th>
                      <th className="px-1">OP Number</th>
                      <th className="px-1">Last Processing Time</th>
                      <th className="px-1 text-center">Employees</th>

                      <th className="pl-1 text-center">Status</th>

                      <th className="px-1">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredArchives.map((payroll, index) => (
                      <PayrollCard
                        key={index}
                        payrollIndex={index + 1}
                        payroll={payroll}
                        type="Temp"
                      />
                    ))}
                  </tbody>
                </table>
              )}

              {!filteredArchives.length && !loading && (
                <div className="text-center ">
                  <p className="lead">No payroll found</p>
                </div>
              )}

              {loading && !tempPayrolls.length && (
                <div className="text-center">
                  <img src={loadingGif} height="128" alt="loading" />
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  tempPayrolls,
  supportingDocumentTypes,
}) => {
  return { user, loading, tempPayrolls, supportingDocumentTypes };
};

export default connect(mapStateToProps, {
  getTempPayrolls,
  getActiveCostCenters,
  getEntitySectorPayrollTypes,
  getSupportingDocumentTypes,
  getCountBlacklistedActiveEmployees,
  downloadCSVBlacklistActiveEmployees,
  getPayrollPeriods,
})(TempPayrolls);
