import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Autocomplete,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { savePositionActing } from "../../../store/structure/actions";
import { showError, showSuccess } from "../../../app/toastify";
import { useEffect } from "react";
import axios from "axios";
import defaultPofile from "../../assets/default-profile.jpg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const PositionSetActingDialog = (props) => {
  const {
    loading,
    showPositionSetActingDialog,
    setShowPositionSetActingDialog,
    savePositionActing,
    position,
  } = props;

  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [file, setFile] = useState(null);

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState(null);
  const [searchType, setSearchType] = useState("firstName");

  const [actingForm, setActingForm] = useState({
    employeeId: "",
    fromDate: null,
    toDate: null,
    hasEndDate: false,
    isPayableActing: true,
  });

  const [errors, setErrors] = useState({
    employeeIdHasError: false,
    employeeIdError: "",
    employeeHasError: false,
    fromDateHasError: false,
    fromDateError: "",
    toDateHasError: false,
    toDateError: "",
    fileHasError: false,
    fileError: "",
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      employeeIdHasError: false,
      employeeIdError: "",
      employeeHasError: false,
      fromDateHasError: false,
      fromDateError: "",
      toDateHasError: false,
      toDateError: "",
      fileHasError: false,
      fileError: "",
      hasError: false,
    };

    if (!employee) {
      error.employeeHasError = true;
      error.hasError = true;
    }

    if (!actingForm.fromDate) {
      error.fromDateHasError = true;
      error.hasError = true;
    }

    if (!!actingForm.fromDate && new Date(actingForm.fromDate) > new Date()) {
      error.fromDateHasError = true;
      error.fromDateError = "Future date is not allowed";
      error.hasError = true;
    }

    if (!actingForm.toDate && actingForm.hasEndDate) {
      error.toDateHasError = true;
      error.hasError = true;
    }

    if (
      !!actingForm.toDate &&
      actingForm.hasEndDate &&
      new Date(actingForm.toDate) <
        new Date(actingForm.fromDate).setDate(
          new Date(actingForm.fromDate).getDate() + 1
        )
    ) {
      error.toDateHasError = true;
      error.toDateError = `"ToDate" can't be less than "FromDate"`;
      error.hasError = true;
    }

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const [selectedEmployee, setSelectedEmployee] = useState("");

  // const validateEmployeeId = () => {
  //   const error = {
  //     employeeIdHasError: false,
  //     employeeIdError: "",
  //     employeeHasError: false,
  //     fromDateHasError: false,
  //     fromDateError: "",
  //     toDateHasError: false,
  //     toDateError: "",
  //     fileHasError: false,
  //     fileError: "",
  //     hasError: false,
  //   };

  //   if (!actingForm.employeeId) {
  //     error.employeeIdHasError = true;
  //     error.hasError = true;
  //   }
  //   if (!!actingForm.employeeId && actingForm.employeeId.length !== 10) {
  //     error.employeeIdHasError = true;
  //     error.employeeIdError = "Invalid Employee Id";
  //     error.hasError = true;
  //   }

  //   setErrors(error);

  //   if (error.hasError) {
  //     return true;
  //   }
  //   return false;
  // };

  const onSave = () => {
    if (!formValidator()) {
      const formData = new FormData();
      formData.append("employeeId", employee.id);
      formData.append("positionId", position.id);
      formData.append("fromDate", actingForm.fromDate);
      formData.append("toDate", actingForm.hasEndDate ? actingForm.toDate : "");
      formData.append("isPayableActing", actingForm.isPayableActing);
      formData.append("file", file);

      savePositionActing(formData, setShowPositionSetActingDialog);
    }
  };

  const onClose = () => {
    setShowPositionSetActingDialog(false);

    setActingForm({
      employeeId: "",
      fromDate: null,
      toDate: null,
      hasEndDate: false,
    });

    setFile(null);
    setEmployee(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  const findEmployee = async () => {
    try {
      setIsLoading(true);

      setEmployees([]);

      const { data } = await axios.post("/api/structure/find-employees", {
        employeeSearchTerm,
        searchType,
      });
      setEmployees(data);

      showSuccess("Employees was found");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };

  const getSingleEmployee = async (employeeId) => {
    try {
      setIsLoading(true);

      setEmployee(null);

      const { data } = await axios.get(
        "/api/structure/find-single-employee/" + employeeId
      );
      setEmployee(data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPositionSetActingDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              {position && (
                <div className="header_cont">
                  <span className="ml-0 text-dark"> Set acting | </span>{" "}
                  <span className="ml-0">{position.name}</span>{" "}
                </div>
              )}
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {isLoading && <LinearProgress />}
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row mt-3">
              <div className="col-2 pr-0 justify-content-center">
                <FormControl size="small" fullWidth className="mb-2">
                  <InputLabel id="searchTypeLabel">Search By</InputLabel>
                  <Select
                    labelId="searchTypeLabel"
                    id="searchType"
                    value={searchType}
                    label="Search By"
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                  >
                    <MenuItem value="firstName">First Name</MenuItem>
                    <MenuItem value="lastName">Last Name</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phoneNumber">Phone Number</MenuItem>
                    <MenuItem value="idNumber">NID Number</MenuItem>
                    <MenuItem value="employeeId">Employee ID</MenuItem>
                  </Select>
                </FormControl>
                <div
                  style={{
                    minHeight: "128px",
                    overflow: "hidden",
                  }}
                  className="text-center"
                >
                  {employee?.profilePicture && (
                    <img
                      className="mt-0 rounded"
                      src={"data:image/jpg;base64," + employee?.profilePicture}
                      style={{
                        border: "1px solid #a6d3df",
                        height: "128px",
                        // width: "130px",
                        overflow: "hidden",
                        // borderRadius: "50%",
                      }}
                    />
                  )}
                  {!employee?.profilePicture && (
                    <img
                      className="mt-0  rounded text-center"
                      src={defaultPofile}
                      style={{
                        // border: "1px solid #a6d3df",
                        height: "128px",
                        width: "100px",
                        overflow: "hidden",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>

                <div className="text-primary text-center">
                  <strong>ID:{employee?.id || "N/A"}</strong>{" "}
                </div>
              </div>

              <div className="col-10 row no-gutters d-flex align-items-start">
                <div className="col-12 row no-gutters mb-3">
                  <div className="col-10 pl-0">
                    <Autocomplete
                      autoHighlight
                      size="small"
                      value={selectedEmployee || ""}
                      freeSolo
                      selectOnFocus
                      id="employeeSearch"
                      onKeyUp={(e) => {
                        setEmployeeSearchTerm(e.target.value || "");
                      }}
                      onChange={(e, value) => {
                        const employeeId = value.split("-")[0];
                        if (!!employeeId && employeeId.length === 10)
                          getSingleEmployee(value.substring(0, 10));
                        else findEmployee(value);

                        setErrors({ ...errors, employeeHasError: false });
                      }}
                      disableClearable
                      options={employees.map(
                        (option) =>
                          option.id +
                          "-" +
                          option.lastName +
                          " " +
                          option.firstName +
                          " (" +
                          option.positionName +
                          ")"
                      )}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Search employee"
                          {...params}
                          label="Search employee"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-2 pr-1">
                    <button
                      disabled={isLoading || loading || !employeeSearchTerm}
                      onClick={() => {
                        findEmployee();
                      }}
                      type="button"
                      className="btn btn-primary btn-block text-uppercase ml-1 "
                    >
                      {isLoading ? "Wait..." : "Search"}
                    </button>
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    rows={4}
                    name="Employee names"
                    disabled={true}
                    label="Employee names"
                    variant="outlined"
                    className="font-weight-bold"
                    value={
                      !!employee
                        ? (employee?.lastName || "") +
                          " " +
                          (employee?.firstName || "")
                        : "Not selected"
                    }
                  />
                  {errors.employeeHasError && (
                    <small className="text-danger">
                      Please, Select employee
                    </small>
                  )}
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="position"
                    label="Position of acting"
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={position.name || "N/A"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              borderRadius: "0px",
                            }}
                            className={`badge badge-primary`}
                          >
                            Acting
                          </span>
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="col-6 pr-2">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      maxDate={new Date()}
                      label="Acting From Date"
                      inputFormat="MM/dd/yyyy"
                      value={actingForm.fromDate}
                      onChange={(date) => {
                        setActingForm({ ...actingForm, fromDate: date });

                        setErrors({ ...errors, fromDateHasError: false });
                      }}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.fromDateHasError && (
                    <small className="text-danger mt-1">
                      {errors.fromDateError || "From Date is required"}
                    </small>
                  )}
                </div>
                <div className="col-6">
                  <div className="input-group input-group-sm ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Do you know the end date?
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form-check form-check-inline ">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="no"
                          name="isInternal"
                          value={false}
                          checked={actingForm.hasEndDate === false}
                          onChange={(event) => {
                            setActingForm({
                              ...actingForm,
                              hasEndDate: false,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="no">
                          No
                        </label>
                      </div>

                      <div className="form-check form-check-inline mr-0">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="yes"
                          name="isInternal"
                          value={true}
                          checked={actingForm.hasEndDate === true}
                          onChange={(event) => {
                            setActingForm({
                              ...actingForm,
                              hasEndDate: true,
                            });
                          }}
                        />
                        <label className="form-check-label" htmlFor="yes">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {actingForm.hasEndDate && (
                  <div className="col-6 mt-3 pr-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={!actingForm.fromDate}
                        minDate={new Date(actingForm.fromDate).setDate(
                          new Date(actingForm.fromDate).getDate() + 1
                        )}
                        label="Acting To Date"
                        inputFormat="MM/dd/yyyy"
                        value={actingForm.toDate}
                        onChange={(date) => {
                          setActingForm({ ...actingForm, toDate: date });
                          setErrors({ ...errors, toDateHasError: false });
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={() => {
                              return;
                            }}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors.toDateHasError && (
                      <small className="text-danger mt-1">
                        {errors.toDateError || "To Date is required"}
                      </small>
                    )}
                  </div>
                )}
                <div
                  className={`${
                    actingForm.hasEndDate ? "col-6 mt-n2" : "col-12 mt-1"
                  }  `}
                >
                  <small>Upload acting letter: (PDF)</small>

                  <Button
                    className="d-flex justify-content-start"
                    fullWidth
                    component="label"
                    style={{
                      borderRadius: "0px",
                      height: "35px",
                      border: `1px solid #6c757d`,
                      color: "#fff",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "#6c757d",
                      "&:hover": {
                        backgroundColor: "#6c757d",
                      },
                    }}
                    size="md"
                  >
                    <input
                      accept="application/pdf"
                      type="file"
                      onChange={(e) => {
                        setErrors({
                          ...errors,
                          fileHasError: false,
                          fileError: "",
                        });
                        const file = e.target.files[0];
                        setFile(file || null);
                      }}
                    />
                  </Button>
                  {errors.fileHasError && (
                    <small className="text-danger">
                      {errors.fileError || "Attachment is required"}
                    </small>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <div className="input-group input-group-sm ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Is it payable acting?
                      </span>
                    </div>
                    <div className="form-control">
                      <div className="form-check form-check-inline mr-0">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="payableActing"
                          name="isPayableActing"
                          value={true}
                          checked={actingForm.isPayableActing === true}
                          onChange={(event) => {
                            setActingForm({
                              ...actingForm,
                              isPayableActing: true,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="payableActing"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="form-check form-check-inline ml-3">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="no"
                          name="notPayableActing"
                          value={false}
                          checked={actingForm.isPayableActing === false}
                          onChange={(event) => {
                            setActingForm({
                              ...actingForm,
                              isPayableActing: false,
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="notPayableActing"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  savePositionActing,
})(PositionSetActingDialog);
