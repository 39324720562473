import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Box,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getPayrollTypes } from "../../../store/payroll/actions";
import {
  getEmployeeGroups,
  getEntitySectors,
} from "../../../store/structure/actions";
import { assignCreditToAllEmployees } from "../../../store/support/actions";
import { showError } from "../../toastify";

const AssignCreditorDialog = (props) => {
  const {
    thisCreditor,
    setThisCreditor,
    loading,
    allCreditors,
    payrollTypes,
    entitySectors,
    employeeGroups,
    getEmployeeGroups,
    getPayrollTypes,
    getEntitySectors,
    assignCreditToAllEmployees,
    action,
    setAction,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [creditor, setCreditor] = useState(thisCreditor);
  const [entity, setEntity] = useState(null);
  const [employeeGroup, setEmployeeGroup] = useState(null);
  const [payrollType, setPayrollType] = useState(null);

  const [formData, setFormData] = useState({
    creditorId: thisCreditor?.id || "",
    entitySectorId: "",
    employeeGroupId: "",
    payrollTypeId: "",
    amount: 0,
    totalAmount: 0,
    startDate: null,
    endDate: null,
    updateExisting: false,
    stopAll: action === "stop",
    action,
  });
  const [errors, setErrors] = useState({
    creditorHasError: false,
    amountHasError: false,
    endDateHasError: false,
    payrollTypeHasError: false,
    employeeGroupHasError: false,
    entityHasError: false,
  });

  useEffect(() => {
    if (payrollTypes.length === 0) getPayrollTypes();
    if (entitySectors.length === 0) getEntitySectors();
    if (!employeeGroups.length) getEmployeeGroups();
  }, []);

  const getNo = (idToSearch, data) => {
    return (data || []).findIndex(({ id }) => id === idToSearch) + 1;
  };

  const handleCancel = () => {
    setCreditor(thisCreditor || null);
    setEntity(null);
    setEmployeeGroup(null);
    setPayrollType(null);
    setErrors({
      creditorHasError: false,
      amountHasError: false,
      endDateHasError: false,
      payrollTypeHasError: false,
      employeeGroupHasError: false,
      entityHasError: false,
    });
    setFormData({
      creditorId: thisCreditor?.id || "",
      entitySectorId: "",
      employeeGroupId: "",
      payrollTypeId: "",
      amount: 0,
      totalAmount: 0,
      startDate: null,
      endDate: null,
      updateExisting: false,
      stopAll: action === "stop",
    });
  };

  const handleSubmit = () => {
    const error = {
      hasError: false,
      creditorHasError: false,
      amountHasError: false,
      endDateHasError: false,
      payrollTypeHasError: false,
      employeeGroupHasError: false,
      entityHasError: false,
    };
    if (!formData.creditorId) {
      error.creditorHasError = true;
      error.hasError = true;
    }
    if (action === "assign") {
      if (!formData.amount && creditor?.calculationMethodId !== 2) {
        error.amountHasError = true;
        error.hasError = true;
      }
      if (!formData.endDate) {
        error.endDateHasError = true;
        error.hasError = true;
      }
    } else if (action === "stop" && !formData.stopAll) {
      if (
        (!formData.amount && creditor?.calculationMethodId !== 2) ||
        !formData.totalAmount ||
        !formData.endDate ||
        !formData.startDate
      ) {
        showError(
          "Please fill start and end date or monthly deduction or total amount or check 'Stop all' checkbox."
        );
        error.hasError = true;
      }
    }
    if (!formData.payrollTypeId) {
      error.payrollTypeHasError = true;
      error.hasError = true;
    }
    if (!formData.employeeGroupId) {
      error.employeeGroupHasError = true;
      error.hasError = true;
    }
    if (!formData.entitySectorId) {
      error.entityHasError = true;
      error.hasError = true;
    }

    if (error.hasError) {
      setErrors(error);
      return;
    }

    assignCreditToAllEmployees(formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        handleCancel();
      }
    });
  };

  const onClose = () => {
    handleCancel();
    setThisCreditor(null);
    setAction(null);
  };

  return (
    <>
      <Dialog
        aria-labelledby="assign-creditor-dialog"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {action === "assign" ? "Assign" : "Stop assigned"} Creditor
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className={`elevated rounded p-0 mt-3 ${
              loading || !isSubmitting ? "px-3 pb-3" : "p-3"
            }`}
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="mb-2">
              {(loading || isSubmitting) && <LinearProgress />}
            </div>

            <div className="row mt-3 justify-content-center">
              <div className="mb-3 col-12 col-lg-6">
                <Autocomplete
                  size="small"
                  id="creditor"
                  defaultValue={null}
                  value={creditor}
                  options={allCreditors}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, creditor) => {
                    setErrors({ ...errors, creditorHasError: false });

                    setFormData({
                      ...formData,
                      creditorId: creditor?.id || thisCreditor?.id || "",
                    });
                    setCreditor(creditor);
                  }}
                  getOptionLabel={(option) =>
                    getNo(option.id, allCreditors) +
                    "-" +
                    option.name +
                    (option.calculationMethodId === 2
                      ? " (" + option.calculationMethodName + ")"
                      : "")
                  }
                  renderOption={(props, creditor) => (
                    <Box component="li" {...props}>
                      {getNo(creditor.id, allCreditors)} - {creditor.name}
                      {creditor?.calculationMethodId === 2
                        ? " (" + creditor.calculationMethodName + ")"
                        : ""}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select creditors"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "creditor", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  disabled={loading || isSubmitting}
                />

                {errors.creditorHasError && (
                  <small className="text-danger mb-3">
                    Please, select creditor
                  </small>
                )}
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <Autocomplete
                  loading={loading}
                  size="small"
                  id="entitySector"
                  defaultValue={null}
                  value={entity}
                  options={entitySectors}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, entity) => {
                    setEntity(entity || null);
                    setFormData({
                      ...formData,
                      entitySectorId: entity ? entity.id : "",
                    });
                    setErrors({ ...errors, entityHasError: false });
                  }}
                  getOptionLabel={(option) =>
                    option?.entity.name +
                    (entity?.sector ? " / " + entity?.sector.name : "")
                  }
                  renderOption={(props, entity) => (
                    <Box component="li" {...props}>
                      {entity?.entity.name}
                      {entity?.sector && <> / {entity?.sector.name}</>}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // required={true}
                      {...params}
                      label="Select the institution"
                      placeholder="Select the institution"
                      onChange={(e) => {
                        // handleKeywordChange(e);
                      }}
                      inputProps={{
                        ...params.inputProps,
                        // autoComplete: "entity", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  disabled={loading || isSubmitting}
                />

                {errors.entityHasError && (
                  <small className="text-danger mb-3">
                    Please, select the institution
                  </small>
                )}
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <Autocomplete
                  size="small"
                  id="payrollType"
                  defaultValue={null}
                  value={payrollType}
                  options={payrollTypes.filter(({ id }) => id !== 2)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, payrollType) => {
                    setErrors({ ...errors, payrollTypeHasError: false });

                    setPayrollType(payrollType);

                    setFormData({
                      ...formData,
                      payrollTypeId: payrollType?.id || 0,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, payrollType) => (
                    <Box component="li" {...props}>
                      {payrollType.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payroll Type"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "PayrollType", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {errors.payrollTypeHasError && (
                  <small className="text-danger mb-3">
                    Payroll type is required
                  </small>
                )}
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <Autocomplete
                  size="small"
                  disablePortal
                  options={employeeGroups || []}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={employeeGroup}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      label="Employee Groups"
                      placeholder="Employee Groups"
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}
                    </Box>
                  )}
                  onChange={(e, item) => {
                    setErrors({ ...errors, employeeGroupHasError: false });
                    setEmployeeGroup(item);
                    setFormData({
                      ...formData,
                      employeeGroupId: item?.id || "",
                    });
                  }}
                  disabled={loading}
                />
                {errors.employeeGroupHasError && (
                  <div className="text-danger mb-2">
                    Employee group is required
                  </div>
                )}
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <TextField
                  style={{ backgroundColor: "#eee" }}
                  disabled
                  size="small"
                  fullWidth
                  name="accountNumber"
                  type="text"
                  label="Account Number"
                  variant="outlined"
                  placeholder="Account Number"
                  value={!!creditor ? creditor.acountNumber : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="mb-3 col-12 col-lg-6">
                <TextField
                  style={{ backgroundColor: "#eee" }}
                  disabled
                  size="small"
                  fullWidth
                  name="bank"
                  type="text"
                  label="Bank"
                  variant="outlined"
                  placeholder="Bank"
                  value={!!creditor ? creditor.bankName : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                />
              </div>

              {action === "stop" && (
                <div className="mb3 mt-n3 col-12">
                  <FormControlLabel
                    control={
                      <Checkbox color="warning" checked={formData.stopAll} />
                    }
                    label={<small>Stop all</small>}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        stopAll: e.target.checked,
                      });
                    }}
                    disabled={loading || isSubmitting}
                  />
                </div>
              )}

              <div className="mb-3 col-12 col-lg-3">
                <NumberFormat
                  fullWidth
                  size="small"
                  label="Monthly deduction"
                  name="amount"
                  variant="outlined"
                  placeholder="RWF 0"
                  prefix={"RWF "}
                  customInput={TextField}
                  value={formData.amount}
                  thousandSeparator={true}
                  disabled={
                    creditor?.calculationMethodId === 2 ||
                    isSubmitting ||
                    loading ||
                    (action === "stop" && formData.stopAll)
                  }
                  onBlur={() => {
                    if (formData.amount === "")
                      setFormData({
                        ...formData,
                        amount: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.amount === 0)
                      setFormData({
                        ...formData,
                        amount: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, amountHasError: false });
                    const amount = e.target.value.split("RWF ");

                    setFormData({
                      ...formData,
                      amount: !!amount[1] ? amount[1].replace(/,/g, "") : 0,
                    });
                  }}
                />
                {errors.amountHasError && (
                  <small className="text-danger mb-2">
                    Amount is required{" "}
                  </small>
                )}
              </div>

              <div className="mb-3 col-12 col-lg-3">
                <NumberFormat
                  fullWidth
                  size="small"
                  label="Total to be paid"
                  name="totalAmount"
                  variant="outlined"
                  autoFocus={true}
                  placeholder="RWF 0"
                  prefix={"RWF "}
                  disabled={
                    creditor?.calculationMethodId === 2 ||
                    isSubmitting ||
                    loading ||
                    (action === "stop" && formData.stopAll)
                  }
                  customInput={TextField}
                  value={formData.totalAmount}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (formData.totalAmount === "")
                      setFormData({
                        ...formData,
                        totalAmount: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+formData.totalAmount === 0)
                      setFormData({
                        ...formData,
                        totalAmount: "",
                      });
                  }}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      totalAmountHasError: false,
                    });
                    const totalAmount = e.target.value.split("RWF ");

                    setFormData({
                      ...formData,
                      totalAmount: !!totalAmount[1]
                        ? totalAmount[1].replace(/,/g, "")
                        : 0,
                    });
                  }}
                />
              </div>

              <div className="mb-3 col-12 col-lg-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="MM/dd/yyyy"
                    value={formData.startDate || null}
                    onChange={(date) => {
                      setErrors({
                        ...errors,
                        startDateHasError: false,
                      });
                      const startDate = date;
                      setFormData({ ...formData, startDate });

                      const startDateHasError = false;
                      setErrors({ ...errors, startDateHasError });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" fullWidth {...params} />
                    )}
                    disabled={
                      loading ||
                      isSubmitting ||
                      (action === "stop" && formData.stopAll)
                    }
                  />

                  {errors.startDateHasError && (
                    <small className="text-danger mt-1">
                      Start Date is required{" "}
                    </small>
                  )}
                </LocalizationProvider>
              </div>

              <div className="mb-3 col-12 col-lg-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="MM/dd/yyyy"
                    value={formData.endDate || null}
                    onChange={(date) => {
                      setErrors({
                        ...errors,
                        endDateHasError: false,
                      });
                      const endDate = date;
                      setFormData({ ...formData, endDate });

                      const endDateHasError = false;
                      setErrors({ ...errors, endDateHasError });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" fullWidth {...params} />
                    )}
                    disabled={
                      loading ||
                      isSubmitting ||
                      (action === "stop" && formData.stopAll)
                    }
                  />

                  {errors.endDateHasError && (
                    <small className="text-danger mt-1">
                      End Date is required{" "}
                    </small>
                  )}
                </LocalizationProvider>
              </div>

              {action === "assign" && (
                <div className="mb3 col-12 col-lg-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.updateExisting}
                      />
                    }
                    label={<small>Update Existing</small>}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        updateExisting: e.target.checked,
                      });
                    }}
                    disabled={loading || isSubmitting}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={handleCancel}
            type="button"
            className="btn  btn-default text-uppercase"
            disabled={loading || isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  payrollTypes,
  entitySectors,
  adminPayroll,
  employeeGroups,
}) => {
  return {
    loading,
    payrollTypes,
    entitySectors,
    allCreditors: adminPayroll.adminCreditor,
    employeeGroups,
  };
};
export default connect(mapStateToProps, {
  getEntitySectors,
  getPayrollTypes,
  getEmployeeGroups,
  assignCreditToAllEmployees,
})(AssignCreditorDialog);
