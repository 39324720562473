import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import MenuBar from "./components/MenuBar";
import Dashboard from "./pages/Dashboard";
import UserGuide from "./pages/UserGuide";
import PrivateRoute from "../common/guard/PrivateRoute";
import { getFiscalYears, getSelectedFiscalYear } from "../../store/rbm/actions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import MyLeaves from "./pages/MyLeaves";
import AnnualLeave from "./pages/AnnualLeave";
import OtherLeaveRequests from "./pages/OtherLeaveRequests";

const Layout = (props) => {
  const {
    getFiscalYears,
    getSelectedFiscalYear,
    selectedFiscalYear,
    fiscalYears,
  } = props;

  useEffect(() => {
    if (!fiscalYears.length) getFiscalYears();
    if (isEmpty(selectedFiscalYear)) getSelectedFiscalYear();
  }, []);

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <PrivateRoute
          children={<AnnualLeave />}
          permissions={["IS_HEAD_OF_UNIT", "IS_HR"]}
          path="/leave/annual-leave"
        />
        <PrivateRoute
          children={<OtherLeaveRequests />}
          permissions={["IS_HEAD_OF_UNIT", "IS_HR"]}
          path="/leave/other-leave"
        />
        <Route path="/leave/my-leave" component={MyLeaves} />
        <Route path="/leave/dashboard" component={Dashboard} />

        <Route
          path="/leave/user-guide"
          permissions={["IS_HEAD_OF_UNIT", "IS_HR"]}
          component={UserGuide}
        />

        <PrivateRoute
          children={() => <Redirect to={"/leave/dashboard"} />}
          permissions={["IS_HEAD_OF_UNIT", "IS_HR"]}
          path="/leave"
        />

        <PrivateRoute
          children={<OtherLeaveRequests />}
          permissions={["IS_HEAD_OF_UNIT", "IS_HR"]}
          path="/leave/other-leave"
        />

        <Route
          children={() => <Redirect to={"/leave/dashboard"} />}
          path="/leave"
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ selectedFiscalYear, fiscalYears }) => {
  return {
    selectedFiscalYear,
    fiscalYears,
  };
};
export default connect(mapStateToProps, {
  getFiscalYears,
  getSelectedFiscalYear,
})(Layout);
