import React, { useState, useEffect } from "react";

import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  InputAdornment,
  Badge,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
} from "@mui/material";

import { connect } from "react-redux";

import {
  deleteEmployeeArrear,
  // saveEmployeeWithhold,
  // getemployeeArrears,
  // deleteEmployeeWithHold,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  saveEmployeeArrear,
} from "../../../store/structure/actions";

import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const EmployeePBFScores = (props) => {
  const {
    loading,
    onWait,
    selectedEmployee,
    // saveEmployeeWithhold,
    // deleteEmployeeWithHold,
    // employeeArrears,
    // creditors,
    // calculationMethods,
    // basePayrollComponents,
    employeeArrears,
    saveEmployeeArrear,
    deleteEmployeeArrear,
    periods,
    payrollTypes,
    sourceOfFunds,
    payrollGroups,
    costCenters,
  } = props;

  const [period, setPeriod] = useState(periods[0] || null);
  const [arrearType, setArrearType] = useState(null);

  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);
  const [costCenter, setCostCenter] = useState(null);

  // const [calculationMethod, setCalculationMethod] = useState(null);
  // const [basePayrollComponent, setBasePayrollComponent] = useState(null);

  // const [selectedEmployeeWithhold, setSelectedEmployeeWithhold] =
  //   useState(null);

  // const [confirmRemoveEmployeeWithhold, setConfirmRemoveEmployeeWithhold] =
  //   useState(false);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    periodId: "",
    arrearTypeId: "",
    noOfDays: 0,
    basePay: 0,
    housingAllowance: 0,
    transportAllowance: 0,
    taxableAllowance: 0,
    exemptedAllowance: 0,
    lumpsumAllowance: 0,
    reason: "",
  });

  const [add, setAdd] = useState(false);
  const [confirmRemoveEmployeeArrear, setConfirmRemoveEmployeeArrear] =
    useState(false);

  const [selectedEmployeeArrear, setSelectedEmployeeArrear] = useState(false);

  const [errors, setErrors] = useState({
    periodIdHasError: false,
    amountHasError: false,
    arrearTypeHasError: false,
    // noOfDaysHasError: false,
    reasonHasError: false,
    hasError: false,
  });

  useEffect(
    () => {
      const add = employeeArrears?.length > 0 ? false : true;

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeArrears]
  );

  const formValidator = () => {
    const error = {
      periodIdHasError: false,
      arrearTypeHasError: false,
      // noOfDaysHasError: false,
      amountHasError: false,
      reasonHasError: false,
      hasError: false,
    };

    if (!formData.periodId) {
      error.periodIdHasError = true;
      error.hasError = true;
    }

    if (!formData.arrearTypeId) {
      error.arrearTypeHasError = true;
      error.hasError = true;
    }

    // if (!formData.noOfDays) {
    //   error.noOfDaysHasError = true;
    //   error.hasError = true;
    // }

    if (!formData.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (
      +formData.basePay +
        +formData.housingAllowance +
        +formData.transportAllowance +
        +formData.taxableAllowance +
        +formData.exemptedAllowance +
        +formData.lumpsumAllowance <
      10
    ) {
      error.amountHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      // showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  // useEffect(()=>{

  // },[])

  const onSave = () => {
    if (!formValidator()) {
      saveEmployeeArrear(
        {
          ...formData,
          employeePositionId: selectedEmployee.employeePositionId,
        },
        setFormData,
        setAdd,
        setPeriod,
        setArrearType
      );
    }
  };

  const getArrearTypes = () => payrollTypes.filter(({ id }) => id !== 2);

  const payrollGroupDropdown = () => {
    return payrollGroups.filter(({ id }) => id === 9);
  };
  return (
    <div style={{ minHeight: "50vh" }}>
      {confirmRemoveEmployeeArrear && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveEmployeeArrear}
          message={`Are you sure you want to "${selectedEmployeeArrear.periodId}" arrear?`}
          setConfirmationDialog={setConfirmRemoveEmployeeArrear}
          onYes={() => {
            deleteEmployeeArrear(
              selectedEmployeeArrear.id,
              setConfirmRemoveEmployeeArrear,
              setSelectedEmployeeArrear
            );
          }}
        />
      )}

      {loading && !onWait && (
        <div className="container mt-5" style={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </div>
      )}

      {(!loading || onWait) && (
        <div>
          {!add && (
            <Button
              className="mb-2"
              onClick={() => {
                setAdd(true);
              }}
              style={{
                ...ActiveButton,
                padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
              }}
              size="sm"
            >
              <span className="material-icons">add</span>
            </Button>
          )}
          {add && (
            <div
              className="elevated rounded p-0 mt-3 p-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row mt-2">
                <div className="col-6">
                  <Autocomplete
                    size="small"
                    id="periodId"
                    name="periodId"
                    defaultValue={null}
                    value={period}
                    options={periods}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, period) => {
                      setPeriod(period || null);

                      setFormData({
                        ...formData,
                        periodId: period?.id,
                      });

                      setErrors({ ...errors, periodIdHasError: false });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, period) => (
                      <Box component="li" {...props}>
                        {period.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Period"
                        placeholder="Period"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "period", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  {errors.periodIdHasError && (
                    <small className="text-danger mb-3">
                      Period is required
                    </small>
                  )}
                </div>
                <div className="mb-3 col-6">
                  <TextField
                    fullWidth
                    size="small"
                    label="PBF Score"
                    name="Score"
                    variant="outlined"
                    placeholder="0"
                    // prefix={"RWF "}
                    // customInput={TextField}
                    value={formData.basePay}
                    // thousandSeparator={true}
                    onBlur={() => {
                      if (formData.basePay === "")
                        setFormData({
                          ...formData,
                          basePay: 0,
                        });
                    }}
                    onKeyDown={() => {
                      if (+formData.basePay === 0)
                        setFormData({
                          ...formData,
                          basePay: "",
                        });
                    }}
                    onChange={(e) => {
                      setErrors({ ...errors, amountHasError: false });

                      setFormData({
                        ...formData,
                        basePay: e.target.value || 0,
                      });
                    }}
                  />
                  {errors.amountHasError && (
                    <small className="text-danger mb-2">Required</small>
                  )}
                </div>

                <div className="mt-3 col-3">
                  <Autocomplete
                    size="small"
                    id="source-of-fund"
                    defaultValue={null}
                    value={sourceOfFund}
                    options={sourceOfFunds}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, sourceOfFund) => {
                      setSourceOfFund(sourceOfFund || null);
                      setFormData({
                        ...formData,
                        sourceOfFundId: sourceOfFund?.id || null,
                      });

                      setErrors({
                        ...errors,
                        sourceOfFundHasError: false,
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, sourceOfFund) => (
                      <Box component="li" {...props}>
                        {sourceOfFund.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Source Of Fund"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "sourceOfFund", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {errors.sourceOfFundHasError && (
                    <div className="text-danger mt-2">
                      Source of fund is required{" "}
                    </div>
                  )}
                </div>

                <div className="mt-3 col-3">
                  <Autocomplete
                    size="small"
                    id="costCenter"
                    defaultValue={null}
                    value={costCenter}
                    options={costCenters}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, costCenter) => {
                      setCostCenter(costCenter || null);
                      setFormData({
                        ...formData,
                        costCenterId: costCenter.id,
                      });

                      setErrors({
                        ...errors,
                        costCenterHasError: false,
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, costCenter) => (
                      <Box component="li" {...props}>
                        {costCenter.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cost Center"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "costCenter", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {errors.costCenterHasError && (
                    <div className="text-danger mt-2">
                      Cost Center is required{" "}
                    </div>
                  )}
                </div>

                <div className="mt-3 col-5">
                  <Autocomplete
                    size="small"
                    id="payrollGroup"
                    defaultValue={null}
                    value={payrollGroup}
                    options={payrollGroups}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, payrollGroup) => {
                      setPayrollGroup(payrollGroup || null);
                      setFormData({
                        ...formData,
                        payrollGroupId: payrollGroup.id,
                      });

                      setErrors({
                        ...errors,
                        payrollGroupHasError: false,
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, payrollGroup) => (
                      <Box component="li" {...props}>
                        {payrollGroup.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payroll Group"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "payrollGroup", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {errors.payrollGroupHasError && (
                    <div className="text-danger mt-2">
                      Payroll Group is required{" "}
                    </div>
                  )}
                </div>

                <div className="mt-3 col-1">
                  <button
                    onClick={onSave}
                    type="button"
                    className="btn btn-primary text-uppercase"
                    disabled={loading || onWait}
                  >
                    {onWait ? "Wait..." : "Save"}
                  </button>{" "}
                  {!!employeeArrears?.length && (
                    <React.Fragment>
                      &nbsp; &nbsp;
                      <button
                        onClick={() => {
                          setAdd(false);
                          setPeriod(null);
                          setFormData({
                            periodId: "",
                            basePay: 0,
                            housingAllowance: 0,
                            transportAllowance: 0,
                            taxableAllowance: 0,
                            exemptedAllowance: 0,
                            lumpsumAllowance: 0,
                          });
                        }}
                        type="button"
                        className="btn  btn-default text-uppercase"
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}

          {!!employeeArrears?.length && (
            <React.Fragment>
              <table className="table table-bordered table-sm mt-3">
                <thead>
                  <tr>
                    <th colSpan={12} style={{ textAlign: "center" }}>
                      ACTIVE ARREARS
                    </th>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "#e9ecef" }}>#</td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-left"
                    >
                      ArrearType
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-left"
                    >
                      Period
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-left"
                    >
                      Days
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      BasePay
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Housing All.
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Transport
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Other All.
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      Lumpsum
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-center"
                    >
                      Reason for Arrear
                    </td>

                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-center"
                    >
                      Status
                    </td>

                    <td style={{ backgroundColor: "#e9ecef" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {employeeArrears.map((employeeArrear, index) => (
                    <EmployeeArrearItem
                      key={employeeArrear.id}
                      employeeArrear={employeeArrear}
                      setPeriod={setPeriod}
                      setFormData={setFormData}
                      setConfirmRemoveEmployeeArrear={
                        setConfirmRemoveEmployeeArrear
                      }
                      setAdd={setAdd}
                      setSelectedEmployeeArrear={setSelectedEmployeeArrear}
                      index={index}
                    />
                  ))}
                </tbody>
              </table>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  // employeeArrears,
  // creditors,
  // calculationMethods,
  // basePayrollComponents,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  employeeArrears,
  periods,
  payrollTypes,
}) => {
  return {
    loading,
    onWait,
    // employeeArrears,
    // creditors,
    // calculationMethods,
    // basePayrollComponents,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    employeeArrears,
    periods,
    payrollTypes,
  };
};
export default connect(mapStateToProps, {
  // saveEmployeeWithhold,
  // getemployeeArrears,
  // deleteEmployeeWithHold,

  saveEmployeeArrear,
  deleteEmployeeArrear,
})(EmployeePBFScores);

const EmployeeArrearItem = (props) => {
  const {
    employeeArrear,
    setPeriod,
    setFormData,
    setConfirmRemoveEmployeeArrear,
    setAdd,
    setSelectedEmployeeArrear,
    index,
  } = props;

  return (
    <tr
      style={{
        background: index % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{index + 1}</td>

      <td>
        <strong> {employeeArrear.arrearTypeName || "N/A"}</strong>
      </td>

      <td>{employeeArrear.periodName}</td>
      <td>{employeeArrear.noOfDays || "-"}</td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={employeeArrear.basePay}
            displayType={"text"}
            thousandSeparator={true}
          />
          {/* {!!employeeArrear.basePay && (
            <span className={`badge text-info`}>RWF</span>
          )} */}
        </>
      </td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={employeeArrear.housingAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
          {/* {!!employeeArrear.housingAllowance && (
            <span className={`badge text-info`}>RWF</span>
          )} */}
        </>
      </td>

      <td className="text-right">
        <>
          <CurrencyFormat
            value={employeeArrear.transportAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
          {/* {!!employeeArrear.transportAllowance && (
            <span className={`badge text-info`}>RWF</span>
          )} */}
        </>
      </td>
      <td className="text-right">
        <>
          <CurrencyFormat
            value={employeeArrear.taxableAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
          {/* {!!employeeArrear.taxableAllowance && (
            <span className={`badge text-info`}>RWF</span>
          )} */}
        </>
      </td>
      <td className="text-right">
        <>
          <CurrencyFormat
            value={employeeArrear.lumpsumAllowance}
            displayType={"text"}
            thousandSeparator={true}
          />
          {/* {!!employeeArrear.lumpsumAllowance && (
            <span className={`badge text-info`}>RWF</span>
          )} */}
        </>
      </td>

      <td className="text-right">
        <TextField
          fullWidth
          multiline
          rows={1}
          size="small"
          label=""
          name="reason"
          variant="outlined"
          placeholder=""
          value={employeeArrear.reason}
          InputProps={{
            readOnly: true,
          }}
        />
      </td>

      <td className="text-center">
        <span
          className={`badge badge-${
            employeeArrear.status === "In Progress"
              ? "primary"
              : employeeArrear.status === "Paid"
              ? "sucess"
              : "secondary"
          }`}
        >
          {employeeArrear.status}
        </span>
      </td>

      <td className="text-right">
        {employeeArrear.status === "Unpaid" ? (
          <IconButton
            size="small"
            color="error"
            onClick={() => {
              setSelectedEmployeeArrear(employeeArrear);
              setConfirmRemoveEmployeeArrear(true);
            }}
          >
            <span className="material-icons">delete</span>
          </IconButton>
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};
