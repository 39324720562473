import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { Line, Doughnut, Pie } from "react-chartjs-2";
import { currencyFormat } from "../../common/components/Utils";
import { getOversightTrainingSummary } from "../../../store/training/actions";
import BackdropLoader from "../../common/components/BackdropLoader";
import { isEmpty } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const Dashboard = (props) => {
  const { user, loading, getOversightTrainingSummary, oversightSummary } =
    props;
    
  const [totalSummary, setTotalSummary] = useState({
    inProgress: 0,
    rejected: 0,
    approved: 0,
  });

  const [loading2, setLoading2] = useState(false);

  const [showInstitutionOverall, setShowInstitutionOverall] = useState(false);
  useEffect(() => {
    getOversightTrainingSummary(setLoading2);
  }, []);

  useEffect(() => {
    if (!isEmpty(oversightSummary)) {
      let totalSummary = {
        inProgress: oversightSummary.numbers.find(
          ({ keyword }) => keyword === "InProgress"
        ).total,
        rejected: oversightSummary.numbers.find(
          ({ keyword }) => keyword === "Rejected"
        ).total,
        approved: oversightSummary.numbers.find(
          ({ keyword }) => keyword === "Approved"
        ).total,
      };
      setTotalSummary(totalSummary);
    }
  }, [oversightSummary]);


  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-12">
            <div className="d-flex flex-column mb-3 w-100">
              <div className="d-flex  flex-wrap justify-content-center w-100">
                <div
                  style={{ color: "#ffe6aa" }}
                  className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                   In Progress
                  </div>
                  <div
                    style={{ color: "#9cff71" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {!!loading2 ? (
                      <>
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                      </>
                    ) : (
                      <>{totalSummary.inProgress}</>
                    )}
                  </div>
                </div>
                <div
                  style={{ color: "#ffe6aa" }}
                  className="bg-danger border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                   Rejected
                  </div>
                  <div
                    style={{ color: "#ffff" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {!!loading2 ? (
                      <>
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                      </>
                    ) : (
                      <>{totalSummary.rejected}</>
                    )}
                  </div>
                </div>
                <div
                  style={{ color: "#ffe6aa" }}
                  className="bg-success border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                    Approved
                  </div>
                  <div
                    style={{ color: "#ffff" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {!!loading2 ? (
                      <>
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                      </>
                    ) : (
                      <>{totalSummary.approved}</>
                    )}
                  </div>
                </div>
                <div
                  style={{ color: " " }}
                  className="bg-warning border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                    Total
                  </div>
                  <div
                    style={{ color: "#ffff" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {!!loading2 ? (
                      <>
                        {" "}
                        <CircularProgress size={20} thickness={4} />
                      </>
                    ) : (
                      <>{totalSummary.rejected + totalSummary.inProgress + totalSummary.approved}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center m-0">
              <div className="col-12 col-md-3 row">
                <div className="col-12">
                  <div className="border rounded p-2 dashboard-card">
                    {!isEmpty(oversightSummary) && !!oversightSummary.institutionSummaryData && (
                     <>
                      <Pie
                        redraw={true}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "INSTITUTION LEVEL",
                            },
                          },
                        }}
                        data={oversightSummary.institutionSummaryData}
                      />
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="col-12 col-md-3 row">
                <div className="col-12">
                  <div className="border rounded p-2 dashboard-card">
                    {!isEmpty(oversightSummary) && !!oversightSummary.rdbSummaryData && (
                      <Pie
                        redraw={true}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "RDB LEVEL",
                            },
                          },
                        }}
                        data={oversightSummary.rdbSummaryData}
                      />
                    )}
                  </div>
                </div>
              </div> */}

              <div className="col-12 col-md-3 row">
                <div className="col-12">
                  <div className="border rounded p-2 dashboard-card">
                    {!isEmpty(oversightSummary) && !!oversightSummary.mineducSummaryData && (
                      <Pie
                        redraw={true}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "MINEDUC LEVEL",
                            },
                          },
                        }}
                        data={oversightSummary.mineducSummaryData}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 row">
                <div className="col-12">
                  <div className="border rounded p-2 dashboard-card">
                    {!isEmpty(oversightSummary) && !!oversightSummary.mifotraSummaryData && (
                      <Pie
                        redraw={true}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                            },
                            title: {
                              display: true,
                              text: "MIFOTRA LEVEL",
                            },
                          },
                        }}
                        data={oversightSummary.mifotraSummaryData}
                      />
                    )}
                  </div>
                </div>
              </div>
 <div className="col-12 col-sm-12 col-lg-11 mt-3">
 <table className="table table-sm">
                    <caption
                      style={{ backgroundColor: "#e9ecef" }}
                      className="mb-2"
                    >
                      INSTITUTION LEVEL SUMMARY
                    </caption>

                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Institution
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Review
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Verification
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Approval
                        </th>
                       
                      </tr>
                    </thead>
                    {!isEmpty(oversightSummary) && !!oversightSummary?.institutionSummary.length && (
                      <tbody style={{ fontSize: "13px" }}>
                        {oversightSummary?.institutionSummary.map(
                          (item, index) => (
                            <tr key={index}>
                              <th
                                className="text-left"
                                style={{ borderLeft: "3px solid #dee2e6" }}
                              >
                                {item.entityName}
                              </th>
                              <td className="text-center">
                                {item.pendingInReview}
                              </td>
                              <td className="text-center">
                                {item.pendingInVerifier}
                              </td>

                              <td className="text-center">
                                {item.pendingInApprover}
                              </td>
                             
                            </tr>
                          )
                        )}
                      </tbody>
                    )}
                  </table>
              </div>
             

              {/* <div className="col-12 col-sm-12 col-lg-12 mt-3">
                <div
                  className="rounded p-2 dashboard-card"
                  style={{ border: "1px solid rgb(198 205 209)" }}
                >
                  <table className="table table-sm">
                    <caption
                      style={{ backgroundColor: "#e9ecef" }}
                      className="mb-2"
                    >
                      View Other Levels Summary
                    </caption>

                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Institution
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Review
                        </th>
                        <th scope="col" className="text-center">
                          Pending for 2nd Review
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Verification
                        </th>

                        <th scope="col" className="text-center">
                          Pending for Approval
                        </th>
                        <th scope="col" className="text-center">
                          Rejected
                        </th>
                      </tr>
                    </thead>
                    {!isEmpty(oversightSummary) && !!oversightSummary?.otherLevels.length && (
                      <tbody style={{ fontSize: "13px" }}>
                        {oversightSummary?.otherLevels.map((item, index) => (
                          <tr key={index}>
                            <th
                              className="text-left"
                              style={{ borderLeft: "3px solid #dee2e6" }}
                            >
                              {item.entityName}
                            </th>
                            <td className="text-center">
                              {item.pendingInReview}
                            </td>
                            <td className="text-center">
                              {item.pendingInReview2}
                            </td>
                            <td className="text-center">
                              {item.pendingInVerifier}
                            </td>

                            <td className="text-center">
                              {item.pendingInApprover}
                            </td>
                            <td className="text-center">{item.rejected}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, oversightSummary }) => {
  return { user, loading, oversightSummary };
};
export default connect(mapStateToProps, { getOversightTrainingSummary })(
  Dashboard
);
