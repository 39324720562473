import {
  Fab,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  getIndicators,
  approveAllIndicators,
} from "../../../store/rbm/actions";
import { getUnits } from "../../../store/structure/actions";
import IndicatorCard from "./IndicatorCard";
import types from "../../../store/rbm/action-types";
import IndicatorForm from "./IndicatorForm";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AddIcon from "@mui/icons-material/Add";
import RBMPageHeader from "./RBMPageHeader";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import IndicatorAssignmentForm from "./IndicatorAssignmentForm";

const IndicatorDialog = (props) => {
  const history = useHistory();
  const {
    indicators,
    selectedOutput,
    loading,
    onWait,
    getUnits,
    units,
    getIndicators,
    user,
    showDialog,
    setShowDialog,
    approveAllIndicators,
    isForMonitoring,
  } = props;

  // useEffect(() => {
  //   document.title = "IPPIS - RBM Indicators | " + selectedOutput?.name;
  // });

  const [totalProgress, setTotalProgress] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [showIndicatorForm, setShowIndicatorForm] = useState(false);

  const [filteredIndicators, setFilteredIndicators] = useState([...indicators]);

  const [searchTeam, setSearchTeam] = useState("");
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (showDialog) getIndicators(selectedOutput.id);

      //console.log(selectedOutput);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showDialog]
  );

  useEffect(() => {
    let weight = 0,
      score = 0,
      unApprovedIndicatorFound = false;
    filteredIndicators.forEach((indicator) => {
      score = score + (indicator.score * indicator.weight) / 100;
      weight = weight + indicator.weight;
      if (!indicator.isApproved) unApprovedIndicatorFound = true;
    });

    setTotalWeight(weight);
    setTotalProgress(score);
  }, [indicators, filteredIndicators]);

  // useEffect(
  //   () => {
  //     if (!units.length) getUnits(user.selectedEntitySector.unitId);
  //   },

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  useEffect(() => {
    const filtered = [...indicators].filter(
      ({ name, unitName }) =>
        name.toLowerCase().includes(searchTeam.toLowerCase()) ||
        (!!unitName &&
          unitName.toLowerCase().includes(searchTeam.toLowerCase()))
    );
    setFilteredIndicators(filtered);

    paginate(filtered);
  }, [searchTeam, indicators]);

  const getNo = (idToSearch) => {
    return indicators.findIndex(({ id }) => id === idToSearch);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredIndicators);
  }, [itemOffset, itemsPerPage, indicators, filteredIndicators]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredIndicators.length || 0;

    setItemOffset(newOffset);
  };
  const { hasPermissions } = useRequiredAuth();

  const onClose = () => {
    setShowDialog(false);
    dispatch({
      type: types.SET_INDICATORS,
      data: [],
    });
  };

  const [confirmApproval, setConfirmApproval] = useState(false);
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth={`xl`}
      >
        <DialogTitle>
          <Typography className="text-primary text-uppercase">
            <span className="d-flex align-items-center">
              <span>
                Indicators
                {/* <Tooltip title="Total Indicators Weight">
                  <span
                    style={{ cursor: "pointer" }}
                    className={`badge badge-${
                      +totalWeight === 100 ? "success" : "danger"
                    } ml-1 py-1`}
                  >
                    <i className="fas fa-weight-hanging mr-1"></i>{" "}
                    {totalWeight.toFixed(2)}
                  </span>
                </Tooltip> */}
              </span>
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mt-3 px-2">
            <span className="font-weight-bold  text-primary">
              <span className="d-flex justify-content-between align-items-center">
                <Tooltip title="Output">
                  <Typography className="d-flex align-items-center">
                    <span className="text-uppercase">
                      <i className="fas fa-folder-open mr-1 "></i>
                      <small>{selectedOutput.name}</small>
                    </span>
                  </Typography>
                </Tooltip>

                {!isForMonitoring && (
                  <span className="d-inline-flex align-items-center mb-2">
                    {hasPermissions(["IS_TECHNICAL_HEAD"]) && (
                      <Tooltip title="Assign All Indicators">
                        <span>
                          <Button
                            disabled={loading}
                            onClick={() => setShowAssignmentForm(true)}
                            color="primary"
                            variant="outlined"
                            disableElevation
                            style={{
                              borderRadius: "4px",
                              height: "24px",
                              alignItems: "center",
                              fontSize: ".75rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textTransform: "initial",
                            }}
                            size="md"
                            className="ml-2 px-1"
                          >
                            <span className="material-icons">person_pin</span>
                            Assign All Indicators
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                    {hasPermissions(["CAN_ADD_NEW_INDICATOR"]) && (
                      <Tooltip title="Add New Indicator">
                        <span>
                          <Button
                            disabled={loading}
                            color="primary"
                            variant="contained"
                            onClick={() => setShowIndicatorForm(true)}
                            style={{
                              borderRadius: "8px",
                              height: "35px",

                              display: "flex",
                              alignItems: "center",
                              fontSize: ".75rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textTransform: "initial",
                            }}
                            size="md"
                            className="ml-2 "
                          >
                            <AddIcon />{" "}
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </span>
                )}
              </span>
              <RBMPageHeader
                labels={[]}
                progress={{
                  title: "PROGRES",
                  value: selectedOutput.score,
                }}
                disabled={loading}
                loading={loading}
                noShadow={true}
              />
            </span>
          </div>
        </DialogTitle>

        <DialogContent className="mx-4 px-2">
          <div className="row no-gutter justify-content-center mx-0">
            <div className="col-12 px-0">
              <Card
                className="mb-3  px-0"
                variant="outlined"
                style={{ border: "none" }}
              >
                <CardContent className="py-0 px-0 mx-0">
                  <div
                    className="rbm-shadow rounded px-4 py-3 mt-2"
                    style={{
                      minHeight: "52vh",
                    }}
                  >
                    <div className="row">
                      <div className={`col-12`}>
                        <div className="form-group  mb-2">
                          <input
                            name="search"
                            type="text"
                            className="form-control"
                            value={searchTeam}
                            id="filter"
                            placeholder="Search..."
                            onChange={(e) => setSearchTeam(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {paginatedItems &&
                      paginatedItems.map((indicator, index) => (
                        <IndicatorCard
                          key={indicator.id}
                          index={getNo(indicator.id)}
                          indicator={indicator}
                          setIsEditing={setIsEditing}
                          setShowIndicatorForm={setShowIndicatorForm}
                          selectedOutput={selectedOutput}
                          isForMonitoring={isForMonitoring}
                        />
                      ))}

                    {!indicators.length && loading && !onWait && (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          className="mb-3 mt-2"
                          height={118}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                      </div>
                    )}

                    {indicators &&
                      !filteredIndicators[0] &&
                      (!loading || onWait) && (
                        <div className="jumbotron jumbotron-fluid text-center">
                          <div className="container">
                            <p className="lead">No indicator found</p>
                          </div>
                        </div>
                      )}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          <IndicatorForm
            totalWeight={totalWeight}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showIndicatorForm={showIndicatorForm}
            setShowIndicatorForm={setShowIndicatorForm}
            outputId={selectedOutput.id}
          />

          {/* {hasPermissions(["IS_PLANNER", "IS_TECHNICAL_HEAD"]) && (
        <div className="fab-container pr-4 pb-4">
          <Tooltip title="Add New Indicator" placement="top">
            <span className="fab-btn">
              <Fab color="primary" onClick={() => setShowIndicatorForm(true)}>
                <span className="material-icons">add</span>
              </Fab>
            </span>
          </Tooltip>
        </div>
      )} */}
          {/* <br />
      <br />
      <br />
      <br /> */}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        </DialogActions>
      </Dialog>
      {showAssignmentForm && (
        <IndicatorAssignmentForm
          indicators={indicators}
          showAssignmentForm={showAssignmentForm}
          setShowAssignmentForm={setShowAssignmentForm}
        />
      )}
      {confirmApproval && (
        <ConfirmationDialog
          color="danger"
          confirmationDialog={confirmApproval}
          message={`Are you sure you want to approve all the indicators of this output?`}
          setConfirmationDialog={setConfirmApproval}
          onYes={() => {
            approveAllIndicators(selectedOutput.id, setConfirmApproval);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  indicators,
  loading,
  onWait,
  units,
  user,
  strongPassword,
}) => {
  return {
    indicators,
    loading,
    onWait,
    units,
    user,
    strongPassword,
  };
};
export default connect(mapStateToProps, {
  getIndicators,
  getUnits,
  approveAllIndicators,
})(IndicatorDialog);
