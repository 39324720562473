import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../toastify";

import { doSignPayroll } from "../../../store/payroll/actions";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";

const EditUserSignatureDialog = (props) => {
  const {
    user,
    loading,
    showEditUserSignatureDialog,
    setShowEditUserSignatureDialog,
    setSelectedPayroll,
    doSignPayroll,
    selectedPayroll,
    signingUser,
    setSigningUser,
    userSignature,
    setUserSignature,
  } = props;

  const [formData, setFormData] = useState({
    password: "",
    payrollNumber: "",
  });

  const [errors, setErrors] = useState({
    passwordHasError: false,
  });

  const formValidator = () => {
    const error = {
      passwordHasError: false,
    };

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowEditUserSignatureDialog(false);
  };

  const [editor, setEditor] = useState(null);

  const setEditorRef = (editor) => {
    setEditor(editor);
  };

  const [avatar, setAvatar] = useState({
    selectedImage: "",
    scaleValue: 0.5,
  });

  const onScaleChane = (e) => {
    const scaleValue = +e.target.value;
    setAvatar({ ...avatar, scaleValue });
  };

  const [isSaving, setIsSaving] = useState(false);

  const onSave = () => {
    if (!!avatar.selectedImage) {
      const formData = new FormData();

      formData.append(
        "signature",
        editor.getImageScaledToCanvas().toDataURL().split(",")[1]
      );

      saveUserSignature(formData);
    }
  };

  const saveUserSignature = async (formData) => {
    try {
      setIsSaving(true);
      const { data } = await axios.post("/api/auth/user-signatures", formData);

      // const { data } = await axios.get("/api/auth/user-signatures");

      setUserSignature(data);

      setShowEditUserSignatureDialog(false);

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };

  const onFileChange = (e) => {
    const selectedImage = e.target.files[0];
    if (
      selectedImage.type.endsWith("png") ||
      selectedImage.type.endsWith("jpg") ||
      selectedImage.type.endsWith("jpeg")
    ) {
      setAvatar({ ...avatar, selectedImage });
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditUserSignatureDialog}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">Edit Your Signature</span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          <div className="row">
            <div className={`col-12`}>
              <small>Select image of your signature (PNG, JPG)</small>
              <Button
                fullWidth
                className="d-flex justify-content-start"
                component="label"
                style={{
                  //float: "right",
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={onFileChange}
                  className="form-control-file"
                />
              </Button>
            </div>
            <div className="col-12 mt-2">
              <AvatarEditor
                image={avatar.selectedImage}
                width={500}
                height={132}
                border={25}
                scale={avatar.scaleValue}
                ref={setEditorRef}
                rotate={0}
                borderRadius={100}
              />
            </div>
            <div className="col-12 ">
              <Tooltip title="Adjust">
                <input
                  type="range"
                  style={{ width: "100%" }}
                  min="0"
                  max="2"
                  step="0.01"
                  name="scaleValue"
                  value={avatar.scaleValue}
                  onChange={onScaleChane}
                />
              </Tooltip>

              <small className="mt-n2 d-block ">
                Adjust your signature to fit into the frame
              </small>
            </div>
            <div className="col-12 d-flex justify-content-center py-3">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase  px-4"
                disabled={isSaving}
              >
                {isSaving ? "Wait..." : "Save"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, { doSignPayroll })(
  EditUserSignatureDialog
);
