import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  Skeleton,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Divider,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Alert,
  LinearProgress,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  saveSharedPosition,
  getEntitySectors,
  getEntitySectorPositions,
  // getSharedScopes,
} from "../../../store/structure/actions";
import {
  getCostCenters,
  getSourceOfFunds,
  getPayrollGroups,
} from "../../../store/payroll/actions";

import { showError } from "../../../app/toastify";
import { isEmpty } from "lodash";
import types from "../../../store/structure/action-types";
import { Fragment } from "react";

const AddSharedPostionDialog = (props) => {
  const {
    loading,
    onWait,
    user,
    selectedUnit,
    showSharedPostionForm,
    setShowSharedPositionForm,
    saveSharedPosition,
    costCenters,
    entitySectorPositions,
    getCostCenters,
    getEntitySectors,
    entitySectors,
    getEntitySectorPositions,
    // getSharedScopes,
    sharedScopes,
    getSourceOfFunds,
    getPayrollGroups,
    sourceOfFunds,
    payrollGroups,
  } = props;

  const defaultPositionForm = {
    sharedPositionId: "",
    sharedToUnitId: selectedUnit.unitId,
    sharedToEntitySectorId: "",
    sharedToCostCenterId: "",
    sharedBy: "",
  };

  const [positionForm, setPositionForm] = useState(defaultPositionForm);

  const [entitySector, setEntitySector] = useState(null);
  const [position, setPosition] = useState(null);

  const [costCenter, setCostCenter] = useState(null);

  const [sourceOfFund, setSourceOfFund] = useState(null);
  const [payrollGroup, setPayrollGroup] = useState(null);

  const [tempPositions, setTempPositions] = useState([]);

  const [errors, setErrors] = useState({
    sharedPositionHasError: false,
    sharedToEntitySectorHasError: false,
    sharedByHasError: false,
    // sourceOfFundHasError: false,
    // payrollGroupHasError: false,
  });
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!isEmpty(entitySectorPositions)) {
  //     dispatch({
  //       type: types.SET_ENTITY_SECTOR_POSITIONS,
  //       data: [],
  //     });
  //     dispatch({
  //       type: types.SET_ENTITY_SECTORS,
  //       data: [],
  //     });
  //   }
  // }, []);

  useEffect(
    () => {
      if (!!showSharedPostionForm) {
        // if (!sharedScopes.length) getSharedScopes();

        if (!entitySectors.length) getEntitySectors();
      }

      // getCostCenters(user.selectedEntitySector.id);
      // getSourceOfFunds();
      // getPayrollGroups();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSharedPostionForm]
  );

  useEffect(
    () => {
      setTempPositions([...entitySectorPositions]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entitySectorPositions]
  );

  const formValidator = () => {
    const error = {
      sharedPositionHasError: false,
      sharedToEntitySectorHasError: false,
      sharedByHasError: false,
      // sourceOfFundHasError: false,
      // payrollGroupHasError: false,
      hasError: false,
    };

    if (!entitySector) {
      error.sharedToEntitySectorHasError = true;
      error.hasError = true;
    }
    if (!position) {
      error.sharedPositionHasError = true;
      error.hasError = true;
    }

    if (!positionForm.sharedBy) {
      error.sharedByHasError = true;
      error.hasError = true;
    }
    // if (!sourceOfFund) {
    //   error.sourceOfFundHasError = true;
    //   error.hasError = true;
    // }

    // if (!payrollGroup) {
    //   error.payrollGroupHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveSharedPosition(positionForm, setShowSharedPositionForm);
    }
  };

  const onClose = () => {
    setShowSharedPositionForm(false);

    setPositionForm(defaultPositionForm);
  };

  const filterEntitySectors = () => {
    return entitySectors.filter(
      ({ id }) => id !== user.selectedEntitySector.id
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSharedPostionForm}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">
                  {" "}
                  Pulling shared position |{" "}
                </span>{" "}
                <span className="ml-0">{selectedUnit.unitName}</span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {loading && !onWait && <LinearProgress />}
        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#f9f9f9",
              border: "1px solid #17a2b8",
              minHeight: "60vh",
            }}
          >
            <div className="row">
              {!entitySectors.length && loading && (
                <div className="container mt-2" style={{ width: "100%" }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </div>
              )}

              <div className="col-12 mt-1">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="entitySector"
                  defaultValue={null}
                  value={entitySector || null}
                  options={filterEntitySectors()}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, entitySector) => {
                    setErrors({
                      ...errors,
                      sharedToEntitySectorHasError: false,
                    });

                    setEntitySector(entitySector || null);
                    setPosition(null);
                    setCostCenter(null);
                    const sharedToEntitySectorId = entitySector
                      ? entitySector.id
                      : "";
                    setPositionForm({
                      ...positionForm,
                      sharedToEntitySectorId,
                    });
                    setTempPositions([]);
                    if (entitySector)
                      getEntitySectorPositions(entitySector.id, true);
                  }}
                  getOptionLabel={(option) =>
                    option.entity.name + " (" + option.sector.name + ")"
                  }
                  renderOption={(props, entitySector) => (
                    <Box component="li" {...props}>
                      {entitySector.entity.name +
                        " (" +
                        entitySector.sector.name +
                        ")"}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Pull shared position from--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "entitySector", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                {errors.sharedToEntitySectorHasError && (
                  <small className="text-danger">
                    {" "}
                    EntitySector is required{" "}
                  </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <Autocomplete
                  disabled={!entitySector}
                  size="small"
                  id="position"
                  defaultValue={null}
                  value={position || null}
                  options={tempPositions}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, position) => {
                    setPosition(position || null);
                    const sharedPositionId = position ? position.id : "";
                    setPositionForm({ ...positionForm, sharedPositionId });
                    setErrors({ ...errors, sharedPositionHasError: false });
                  }}
                  getOptionLabel={(option) =>
                    option.name +
                    " (" +
                    option.levelName +
                    "." +
                    option.scaleName +
                    ") @" +
                    option.unitName
                  }
                  renderOption={(props, position) => (
                    <Fragment key={position.id}>
                      <Box component="li" {...props}>
                        <span
                          className="d-block text-truncate"
                          style={{ maxWidth: "100%" }}
                        >
                          {position.name} ({position.levelName}.
                          {position.scaleName}){" "}
                          <small
                            style={{ backgroundColor: "#eee" }}
                            className="px-2 rounded"
                          >
                            <em>@{position.unitName}</em>{" "}
                          </small>
                        </span>
                      </Box>

                      <Divider />
                    </Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="--Select position--"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "position", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.sharedPositionHasError && (
                  <small className="text-danger mt-2">
                    {" "}
                    Position is required{" "}
                  </small>
                )}
              </div>

              {/* <div className="col-12 mt-3">
                <Autocomplete
                  size="small"
                  id="costCenter"
                  defaultValue={null}
                  value={costCenter}
                  options={costCenters}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, costCenter) => {
                    setCostCenter(costCenter || null);
                    const sharedToCostCenterId = costCenter
                      ? costCenter.id
                      : "";
                    setPositionForm({
                      ...positionForm,
                      sharedToCostCenterId,
                    });
                    const sharedToCostCenterHasError = false;
                    setErrors({ ...errors, sharedToCostCenterHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, costCenter) => (
                    <Box component="li" {...props}>
                      {costCenter.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cost Center"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "costCenter", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.sharedToCostCenterHasError && (
                  <div className="text-danger mt-2">
                    Cost Center is required{" "}
                  </div>
                )}
              </div> */}

              {/* <div className="col-6 mt-2">
                <Autocomplete
                  size="small"
                  id="source-of-fund"
                  defaultValue={null}
                  value={sourceOfFund}
                  options={sourceOfFunds}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, sourceOfFund) => {
                    setSourceOfFund(sourceOfFund || null);
                    const sourceOfFundId = sourceOfFund ? sourceOfFund.id : "";
                    setPositionForm({ ...positionForm, sourceOfFundId });
                    const sourceOfFundHasError = false;
                    setErrors({ ...errors, sourceOfFundHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, sourceOfFund) => (
                    <Box component="li" {...props}>
                      {sourceOfFund.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Source Of Fund"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "sourceOfFund", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.sourceOfFundHasError && (
                  <div className="text-danger mt-2">
                    Source of fund is required{" "}
                  </div>
                )}
              </div> */}

              {/* <div className="col-6 mt-2">
                <Autocomplete
                  size="small"
                  id="payrollGroup"
                  defaultValue={null}
                  value={payrollGroup}
                  options={payrollGroups}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, payrollGroup) => {
                    setPayrollGroup(payrollGroup || null);
                    const payrollGroupId = payrollGroup ? payrollGroup.id : "";
                    setPositionForm({ ...positionForm, payrollGroupId });
                    const payrollGroupHasError = false;
                    setErrors({ ...errors, payrollGroupHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, payrollGroup) => (
                    <Box component="li" {...props}>
                      {payrollGroup.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Payroll Group"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "payrollGroup", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.payrollGroupHasError && (
                  <div className="text-danger mt-2">
                    Payroll Group is required{" "}
                  </div>
                )}
              </div> */}

              {/* <div className="col-12 mt-2">
                <div className="input-group input-group-sm mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Shared By
                    </span>
                  </div>
                  <div className="form-control">
                    {sharedScopes.map((sharedScope, index) => (
                      <div key={index} className="form-check form-check-inline">
                        <input
                          type="radio"
                          className="form-check-input"
                          id={"shared-by-" + sharedScope.id}
                          name="sharedBy"
                          value={sharedScope.id}
                          checked={sharedScope.id === positionForm.sharedBy}
                          onChange={(event) => {
                            const sharedBy = parseInt(event.target.value);

                            setPositionForm({ ...positionForm, sharedBy });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"shared-by-" + sharedScope.id}
                        >
                          {" "}
                          {sharedScope.name}{" "}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}

              <div className="col-12 mt-2">
                <FormControl className="mt-3   ">
                  <FormLabel className="text-primary text-uppercase">
                    Shared By:
                  </FormLabel>
                  <RadioGroup
                    className=""
                    name="isOnStructure"
                    value={positionForm.sharedBy}
                    onChange={(e) => {
                      setErrors({ ...errors, sharedByHasError: false });
                      const sharedBy = e.target.value;

                      setPositionForm({
                        ...positionForm,
                        sharedBy,
                      });
                    }}
                  >
                    {sharedScopes.map((scope) => (
                      <Fragment>
                        {(scope.id !== 4 ||
                          selectedUnit.unitId ===
                            "1903000000-150200000000000000" ||
                          selectedUnit.unitId ===
                            "1903000000-150201000000000000") && (
                          <FormControlLabel
                            key={scope.id}
                            value={scope.id}
                            control={<Radio size="small" />}
                            label={scope.name}
                          />
                        )}
                      </Fragment>
                    ))}
                  </RadioGroup>
                  {errors.sharedByHasError && (
                    <small className="text-danger mt-2">
                      {" "}
                      Sharing scope is required{" "}
                    </small>
                  )}
                </FormControl>
              </div>

              {/* <div className="col-12">
                <div className="input-group input-group-sm mt-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Is RISA IT Position?
                    </span>
                  </div>
                  <div className="form-control">
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="no"
                        name="isRISAItPosition"
                        value={false}
                        checked={positionForm.isRISAItPosition === false}
                        onChange={(event) => {
                          const isRISAItPosition = false;
                          setPositionForm({
                            ...positionForm,
                            isRISAItPosition,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="no">
                        No
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="yes"
                        name="isRISAItPosition"
                        value={true}
                        checked={positionForm.isRISAItPosition === true}
                        onChange={(event) => {
                          const isRISAItPosition = true;
                          setPositionForm({
                            ...positionForm,
                            isRISAItPosition,
                          });
                        }}
                      />
                      <label className="form-check-label" htmlFor="yes">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <Alert className="mt-4" severity="info">
              This is an info alert — check it out!
            </Alert> */}
          </div>
          {/* {costCenter && (
            <div
              className="elevated rounded p-3 mt-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row">
                <div className="col-12 text-left">
                  <h6 className="mb-2">Roles</h6>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <FormGroup className="d-flex py-0 p-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isOnStructure}
                          onChange={(e) => {
                            const isOnStructure = !positionForm.isOnStructure;
                            setPositionForm({
                              ...positionForm,
                              isOnStructure,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is it on structure?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.payMaternity}
                          onChange={(e) => {
                            const payMaternity = !positionForm.payMaternity;
                            setPositionForm({
                              ...positionForm,
                              payMaternity,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Pay Maternity?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.payCBHI}
                          onChange={(e) => {
                            const payCBHI = !positionForm.payCBHI;
                            setPositionForm({ ...positionForm, payCBHI });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Pay CBHI?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.payTPR}
                          onChange={(e) => {
                            const payTPR = !positionForm.payTPR;
                            setPositionForm({ ...positionForm, payTPR });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Pay TPR?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.payCSR}
                          onChange={(e) => {
                            const payCSR = !positionForm.payCSR;
                            setPositionForm({ ...positionForm, payCSR });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Pay CSR?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.payMedical}
                          onChange={(e) => {
                            const payMedical = !positionForm.payMedical;
                            setPositionForm({
                              ...positionForm,
                              payMedical,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Pay Medical?"
                    />
                  </FormGroup>
                </div>
                <div className="col-lg-6">
                  <FormGroup className="d-flex py-0 p-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isHeadOfUnit}
                          onChange={(e) => {
                            const isHeadOfUnit = !positionForm.isHeadOfUnit;
                            setPositionForm({
                              ...positionForm,
                              isHeadOfUnit,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is Head Of Unit?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isPlanner}
                          onChange={(e) => {
                            const isPlanner = !positionForm.isPlanner;
                            setPositionForm({ ...positionForm, isPlanner });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is Planner?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isRBMOversight}
                          onChange={(e) => {
                            const isRBMOversight = !positionForm.isRBMOversight;
                            setPositionForm({
                              ...positionForm,
                              isRBMOversight,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is RBM Oversight?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isHR}
                          onChange={(e) => {
                            const isHR = !positionForm.isHR;
                            setPositionForm({ ...positionForm, isHR });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is HR?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.isDAF}
                          onChange={(e) => {
                            const isDAF = !positionForm.isDAF;
                            setPositionForm({ ...positionForm, isDAF });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Is DAF?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.hasTransportAllowance}
                          onChange={(e) => {
                            const hasTransportAllowance =
                              !positionForm.hasTransportAllowance;
                            setPositionForm({
                              ...positionForm,
                              hasTransportAllowance,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Has Transport Allowance?"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={positionForm.hasHousingAllowance}
                          onChange={(e) => {
                            const hasHousingAllowance =
                              !positionForm.hasHousingAllowance;
                            setPositionForm({
                              ...positionForm,
                              hasHousingAllowance,
                            });
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Has Housing Allowance?"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          )} */}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            onClick={onSave}
            type="button"
            variant="contained"
            color="primary"
            className=" px-5"
            disabled={loading || onWait}
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  costCenters,
  entitySectors,
  entitySectorPositions,
  sharedScopes,
  sourceOfFunds,
  payrollGroups,
}) => {
  return {
    loading,
    onWait,
    user,
    costCenters,
    entitySectors,
    entitySectorPositions,
    sharedScopes,
    sourceOfFunds,
    payrollGroups,
  };
};
export default connect(mapStateToProps, {
  saveSharedPosition,
  getCostCenters,
  getEntitySectors,
  getEntitySectorPositions,
  // getSharedScopes,
  getSourceOfFunds,
  getPayrollGroups,
})(AddSharedPostionDialog);
