import { combineReducers } from "redux";

import commonReducers from "./common/reducers";

import rbmReducers from "./rbm/reducers";

import structureReducers from "./structure/reducers";

import payrollReducers from "./payroll/reducers";

import recruitmentReducers from "./recruitment/reducers";
import exitReducers from "./exit/reducers";

import adminReducers from "./admin/reducers";

import supportReducers from "./support/reducers";

import eapealReducers from "./e-appeal/reducers";

import contractualsReducers from "./contractuals/reducers";
import trainingReducers from "./training/reducers";

import leaveReducers from "./leave/reducers";
import disciplineReducers from "./discipline/reducers";
import profileReducers from "./profile/reducers";

export default combineReducers({
  //---- COMMON ----//
  ...commonReducers,

  //---- RBM ----//
  ...rbmReducers,

  //----STRUCTURE ----//
  ...structureReducers,

  //----PAYROLL ----//
  ...payrollReducers,

  //----RECRUITMENT ----//
  ...recruitmentReducers,

  //----EXIT ----//
  ...exitReducers,

  //----ADMIN----//
  ...adminReducers,

  //----SUPPORT----//
  ...supportReducers,

  //----E-APEAL----//
  ...eapealReducers,

  //----CONTRACT----//
  ...contractualsReducers,

  //----TRAINING----//
  ...trainingReducers,

  //----LEAVE----//
  ...leaveReducers,

  //---- DISCIPLINE----//
  ...disciplineReducers,

  //----PROFILE----//
  ...profileReducers,
});
