import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import {
  getRRADepartments,
  deleteRRADepartment,
  getSupervisors,
} from "../../../store/structure/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import TableSkeleton from "../../common/components/TableSkeleton";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import AddOrUpdateRRADepartmentDialog from "../components/AddOrUpdateRRADepartmentDialog";

const RRADepartments = (props) => {
  const {
    user,
    loading,
    rraDepartments,
    getRRADepartments,
    deleteRRADepartment,

    supervisors,
    getSupervisors,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const [filteredDepartments, setFilteredDepartments] = useState([
    ...rraDepartments,
  ]);

  useEffect(() => {
    const filtered = [...rraDepartments].filter(
      ({ name, supervisingPositionName, grade, supervisorNames }) =>
        (
          (name || "") +
          (supervisingPositionName || "") +
          (grade || "") +
          (supervisorNames || "")
        )
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredDepartments(filtered);
  }, [searchTerm, rraDepartments]);

  useEffect(() => {
    document.title = "IPPIS - Org. Structure | RRA Departments";
  });

  useEffect(() => {
    if (!rraDepartments.length) getRRADepartments();

    if (
      !supervisors.length ||
      (!!supervisors.length && !!supervisors[0].employeePositionId)
    )
      getSupervisors();
  }, []);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    supervisingPositionId: "",
  });

  const [showAddOrUpdateDepartment, setShowAddOrUpdateDepartment] =
    useState(false);

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{ minHeight: "0px", borderBottom: "3px solid #e9ecef" }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex ilign-items-center"
              >
                <span className="text-uppercase text-info">
                  {user.selectedEntitySector.entityName}
                </span>
                {/* <span
                  className="mr-1 ml-1 py-0 my-0"
                  style={{ color: "#2a7790" }}
                >
                  <HorizontalRuleIcon className="rotated text-dark" />
                </span>
                <span className="text-uppercase">
                  {user.selectedEntitySector.sectorName}
                </span> */}
              </Typography>
              {hasPermissions(["IS_HR"]) && (
                <Button
                  onClick={() => setShowAddOrUpdateDepartment(true)}
                  variant="contained"
                  size="small"
                  disableElevation
                  className="ml-2"
                  style={{
                    borderRadius: "8px",
                    height: "32px",
                    border: `1px solid rgb(7, 142, 206)`,
                    color: "#fff",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                    backgroundColor: "rgb(7, 142, 206)",
                  }}
                >
                  <span className="material-icons">add</span>
                  New Department
                </Button>
              )}

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={(term) => setSearchTerm(term)}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          {/* <div className="container-fluid text-center"> */}
          <div
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "65vh",
              overflow: "auto",
            }}
          >
            {/* <CardContent className="pl-0 pt-0 pb-0"> */}
            {filteredDepartments.length ||
            (loading && !rraDepartments.length) ? (
              <table className="table table-striped">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">DEPARTMENT</th>
                    <th scope="col" className="text-left">
                      HEAD
                    </th>

                    <th scope="col" className="text-left">
                      GRADE
                    </th>

                    {hasPermissions(["IS_HR"]) && <th scope="col">Actions</th>}
                  </tr>
                </thead>

                {loading && !rraDepartments.length ? (
                  <TableSkeleton
                    cols={hasPermissions(["IS_HR"]) ? 5 : 4}
                    rows={10}
                  />
                ) : (
                  <tbody>
                    {filteredDepartments.map((department, index) => (
                      <DepartmentCard
                        key={department.id}
                        index={index}
                        department={department}
                        loading={loading}
                        user={user}
                        deleteRRADepartment={deleteRRADepartment}
                        setShowAddOrUpdateDepartment={
                          setShowAddOrUpdateDepartment
                        }
                        setFormData={setFormData}
                        hasPermissions={hasPermissions}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            ) : (
              <div className="jumbotron jumbotron-fluid text-center mt-3">
                <div className="container py-5 ">
                  <p className="lead">No record found</p>
                </div>
              </div>
            )}
            {/* </CardContent> */}
          </div>
          {/* </div> */}
        </div>
      </Box>

      {showAddOrUpdateDepartment && (
        <AddOrUpdateRRADepartmentDialog
          formData={formData}
          setFormData={setFormData}
          showDialog={showAddOrUpdateDepartment}
          setShowDialog={setShowAddOrUpdateDepartment}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, rraDepartments, supervisors }) => {
  return { user, loading, rraDepartments, supervisors };
};
export default connect(mapStateToProps, {
  getRRADepartments,
  deleteRRADepartment,
  getSupervisors,
})(RRADepartments);

const DepartmentCard = (props) => {
  const {
    user,
    index,
    department,
    loading,
    deleteRRADepartment,
    setShowAddOrUpdateDepartment,
    setFormData,
    hasPermissions,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmDeleteDepartment, setConfirmDeleteDepartment] = useState(false);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td className="text-left">{department.name}</td>
        <td className="text-left">
          <div className="text-primary font-weight-bold">
            {department.supervisorNames}
          </div>
          {department.supervisingPositionName}
        </td>

        <td className="text-left">{department.grade || "-"}</td>

        {hasPermissions(["IS_HR"]) && (
          <td>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setFormData({
                      id: department.id,
                      name: department.name,
                      supervisingPositionId: department.supervisingPositionId,
                    });

                    setShowAddOrUpdateDepartment(true);
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons mr-1">edit</span>
                  Edit
                </MenuItem>
                <Divider className="my-1" />
              </span>

              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setConfirmDeleteDepartment(true);
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-1">highlight_off</span>
                  Remove
                </MenuItem>
              </span>
            </Menu>
          </td>
        )}
      </tr>

      {confirmDeleteDepartment && (
        <ConfirmationDialog
          confirmationDialog={confirmDeleteDepartment}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this department?
            </>
          }
          setConfirmationDialog={setConfirmDeleteDepartment}
          onYes={() => {
            deleteRRADepartment(department.id, () =>
              setConfirmDeleteDepartment(false)
            );
          }}
        />
      )}
    </>
  );
};
