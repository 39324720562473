import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { Line, Doughnut, Pie } from "react-chartjs-2";
import { currencyFormat } from "../../common/components/Utils";
import { getPayrollDashboardData } from "../../../store/payroll/actions";

import { isEmpty } from "lodash";
import TableSkeleton from "../../common/components/TableSkeleton";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Dashboard";
  });

  const {
    user,
    loading,
    getPayrollDashboardData,
    payrollDashboardData,
    isForOversight = false,
  } = props;
  const [selectedYear, setSelectedYear] = useState(null);
  const [showAll, setShowAll] = useState({
    payrollType: false,
    payrollGroup: false,
    sectors: false,
    sourceOfFund: false,
  });

  useEffect(() => {
    // if (isEmpty(payrollDashboardData))
    getPayrollDashboardData(selectedYear?.name || "0", isForOversight);
  }, []);

  const getTotal = (data = []) => {
    let total = 0;
    data.forEach((item) => (total += +item.totalPaid));

    return total;
  };

  useEffect(() => {
    if (!!payrollDashboardData?.years)
      setSelectedYear(
        payrollDashboardData.years.find(
          (year) => year.id === payrollDashboardData.year
        )
      );
  }, [payrollDashboardData]);

  const getSmallData = () => {
    let result = 6;
    if (
      payrollDashboardData?.payrollTypesData &&
      payrollDashboardData?.payrollTypesData.length < result
    )
      result = payrollDashboardData?.payrollTypesData.length;
    if (
      payrollDashboardData?.payrollGroupsData &&
      payrollDashboardData?.payrollGroupsData.length < result
    )
      result = payrollDashboardData?.payrollGroupsData.length;
    if (
      payrollDashboardData?.sectorsData &&
      payrollDashboardData?.sectorsData.length < result
    )
      result = payrollDashboardData?.sectorsData.length;
    if (
      payrollDashboardData?.sourceOfFundsData &&
      payrollDashboardData?.sourceOfFundsData.length < result
    )
      result = payrollDashboardData?.sourceOfFundsData.length;
    return result;
  };
  const DEFAULT_LIMIT = getSmallData();

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar className="bg-light border">
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>PAYROLL DASHBOARD</span>
              </Typography>

              <Autocomplete
                sx={{ width: 120 }}
                className="ml-2"
                size="small"
                disablePortal
                value={selectedYear}
                options={payrollDashboardData?.years || []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(e, year) => {
                  setSelectedYear(year);
                  if (!!year) getPayrollDashboardData(year.name);
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Year"
                    placeholder="Year"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />

              {/* <Autocomplete
                sx={{ width: 150 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "2023-02" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Periods"
                    placeholder="Periods"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />

              <Autocomplete
                sx={{ width: 180 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "High Institutions" },
                  { id: 2, name: "Ministry" },
                  { id: 3, name: "Local Goverment" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Sectors"
                    placeholder="Sectors"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />

              <Autocomplete
                sx={{ width: 180 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "Salary" },
                  { id: 2, name: "Terminal Benefits" },
                  { id: 3, name: "Performance Bonus" },
                  { id: 4, name: "Arrears" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Payroll Types"
                    placeholder="Payroll Types"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />

              <Autocomplete
                sx={{ width: 180 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "Political Appointee" },
                  { id: 2, name: "Under Statute" },
                  { id: 3, name: "Under Contract" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Employee Groups"
                    placeholder="Employee Groups"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />

              <Autocomplete
                sx={{ width: 180 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "Political Appointee" },
                  { id: 2, name: "Under Statute" },
                  { id: 3, name: "Under Contract/SPIU" },
                  { id: 4, name: "Under Contract/Expert" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Payroll Groups"
                    placeholder="Payroll Groups"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />
              <Autocomplete
                sx={{ width: 180 }}
                className="ml-2"
                size="small"
                disablePortal
                options={[
                  { id: 0, name: "All" },
                  { id: 1, name: "Ordinary Budget" },
                  { id: 2, name: "Own revenue" },
                  { id: 3, name: "World Bank" },
                  { id: 4, name: "Donor's funds" },
                ]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Source Of Funds"
                    placeholder="Source Of Funds"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              /> */}
            </Toolbar>
          </AppBar>
          <div className="d-flex flex-column mb-3">
            <div className="d-flex  flex-wrap justify-content-center">
              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Gross Salary
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.grossSalary || 0)}
                </div>
              </div>
              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Pension
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.pension || 0)}
                </div>
              </div>
              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Medical
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.medical || 0)}
                </div>
              </div>
              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Maternity Leave
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.maternity || 0)}
                </div>
              </div>

              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Lumpsum
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.lumpsum || 0)}
                </div>
              </div>

              <div
                style={{ color: "#ffe6aa" }}
                className="bg-info border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Total Amount Paid
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(payrollDashboardData.totalPaid || 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center m-0">
            <div className="col-12 col-md-6">
              <div className="border rounded p-2 dashboard-card">
                {!!payrollDashboardData?.lineChartData && (
                  <Line
                    redraw={true}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: "bottom",
                        },
                        title: {
                          display: true,
                          text: "Payroll variations",
                        },
                      },

                      interaction: {
                        mode: "index",
                        intersect: false,
                      },
                    }}
                    data={payrollDashboardData.lineChartData}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 row">
              <div className="col-6">
                <div className="border rounded p-2 dashboard-card">
                  {!!payrollDashboardData?.doughnutData && (
                    <Doughnut
                      options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Payroll by employee group",
                          },
                        },
                      }}
                      data={payrollDashboardData.doughnutData}
                    />
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="border rounded p-2 dashboard-card">
                  {!!payrollDashboardData?.pieData && (
                    <Pie
                      redraw={true}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            position: "bottom",
                          },
                          title: {
                            display: true,
                            text: "Payroll by gender",
                          },
                        },
                      }}
                      data={payrollDashboardData.pieData}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="border rounded p-4 dashboard-card text-center bg-light">
                  <h4 className="lead font-weight-bold text-info">
                    Total Amount Paid:{" "}
                    {currencyFormat(payrollDashboardData.totalPaid || 0)} RWF
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 mx-0 mb-4">
            <div className="row mt-3 mx-0 mb-4">
              <div className="col-12 col-sm-6 col-lg-6 overflow-x-auto">
                <div
                  className="rounded p-2 dashboard-card"
                  style={{ border: "1px solid rgb(198 205 209)" }}
                >
                  <table className="table table-sm">
                    <caption
                      style={{ backgroundColor: "#e9ecef" }}
                      className="mb-2"
                    >
                      Payroll Types
                    </caption>
                    {!!payrollDashboardData?.payrollTypesData ? (
                      <tbody style={{ fontSize: "13px" }}>
                        {payrollDashboardData.payrollTypesData
                          .slice(
                            0,
                            showAll.payrollType
                              ? payrollDashboardData.payrollTypesData.length
                              : DEFAULT_LIMIT
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <th
                                className="text-right"
                                style={{ borderLeft: "3px solid #dee2e6" }}
                              >
                                {item.payrollTypeName}
                              </th>
                              <td className="text-right">
                                {currencyFormat(item.totalPaid || 0)}
                              </td>
                              <td>
                                <span className="badge badge-secondary">
                                  {(
                                    ((item.totalPaid || 0) * 100) /
                                    (getTotal(
                                      payrollDashboardData.payrollTypesData
                                    ) || 1)
                                  ).toFixed(2)}
                                  %
                                </span>
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <th
                            style={{ border: "3px solid #dee2e6" }}
                            colSpan={3}
                            className="text-center bg-light "
                          >
                            {currencyFormat(
                              getTotal(payrollDashboardData.payrollTypesData)
                            )}{" "}
                            FRW
                          </th>
                        </tr>

                        <tr>
                          <th colSpan={3} className="text-center bg-white pt-1">
                            <Button
                              variant="text"
                              onClick={() =>
                                setShowAll({
                                  ...showAll,
                                  payrollType: !showAll.payrollType,
                                })
                              }
                              disabled={
                                payrollDashboardData.payrollTypesData.length <=
                                DEFAULT_LIMIT
                              }
                            >
                              {showAll.payrollType ? "Show Less" : "Show More"}
                            </Button>
                          </th>
                        </tr>
                      </tbody>
                    ) : (
                      <TableSkeleton cols={2} rows={7} />
                    )}
                  </table>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-6 overflow-x-auto">
                <div
                  className="rounded p-2 dashboard-card"
                  style={{ border: "1px solid rgb(198 205 209)" }}
                >
                  <table className="table table-sm">
                    <caption
                      style={{ backgroundColor: "#e9ecef" }}
                      className="mb-2"
                    >
                      Payroll Groups
                    </caption>
                    {!!payrollDashboardData?.payrollGroupsData ? (
                      <tbody style={{ fontSize: "13px" }}>
                        {payrollDashboardData.payrollGroupsData
                          .slice(
                            0,
                            showAll.payrollGroup
                              ? payrollDashboardData.payrollGroupsData.length
                              : DEFAULT_LIMIT
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <th
                                className="text-right"
                                style={{ borderLeft: "3px solid #dee2e6" }}
                              >
                                {item.payrollGroupName}
                              </th>
                              <td className="text-right">
                                {currencyFormat(item.totalPaid || 0)}
                              </td>
                              <td>
                                <span className="badge badge-secondary">
                                  {(
                                    ((item.totalPaid || 0) * 100) /
                                    (getTotal(
                                      payrollDashboardData.payrollGroupsData
                                    ) || 1)
                                  ).toFixed(2)}
                                  %
                                </span>
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <th
                            style={{ border: "3px solid #dee2e6" }}
                            colSpan={3}
                            className="text-center bg-light "
                          >
                            {currencyFormat(
                              getTotal(payrollDashboardData.payrollGroupsData)
                            )}{" "}
                            FRW
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={3} className="text-center bg-white pt-1">
                            <Button
                              variant="text"
                              onClick={() =>
                                setShowAll({
                                  ...showAll,
                                  payrollGroup: !showAll.payrollGroup,
                                })
                              }
                              disabled={
                                payrollDashboardData.payrollGroupsData.length <=
                                DEFAULT_LIMIT
                              }
                            >
                              {showAll.payrollGroup ? "Show Less" : "Show More"}
                            </Button>
                          </th>
                        </tr>
                      </tbody>
                    ) : (
                      <TableSkeleton cols={2} rows={6} />
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-3 mx-0 mb-4">
              <div className="col-12 col-sm-6 col-lg-6 overflow-x-auto">
                <div
                  className="rounded p-2 dashboard-card"
                  style={{ border: "1px solid rgb(198 205 209)" }}
                >
                  <table className="table table-sm">
                    <caption
                      style={{ backgroundColor: "#e9ecef" }}
                      className="mb-2"
                    >
                      Sectors
                    </caption>
                    {!!payrollDashboardData?.sectorsData ? (
                      <tbody style={{ fontSize: "13px" }}>
                        {payrollDashboardData.sectorsData
                          .slice(
                            0,
                            showAll.sectors
                              ? payrollDashboardData.sectorsData.length
                              : DEFAULT_LIMIT
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <th
                                className="text-right"
                                style={{ borderLeft: "3px solid #dee2e6" }}
                              >
                                {item.sectorName}
                              </th>
                              <td className="text-right">
                                {currencyFormat(item.totalPaid || 0)}
                              </td>
                              <td>
                                <span className="badge badge-secondary">
                                  {(
                                    ((item.totalPaid || 0) * 100) /
                                    (getTotal(
                                      payrollDashboardData.sectorsData
                                    ) || 1)
                                  ).toFixed(2)}
                                  %
                                </span>
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <th
                            style={{ border: "3px solid #dee2e6" }}
                            colSpan={3}
                            className="text-center bg-light "
                          >
                            {currencyFormat(
                              getTotal(payrollDashboardData.sectorsData)
                            )}{" "}
                            FRW
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={3} className="text-center bg-white pt-1">
                            <Button
                              variant="text"
                              onClick={() =>
                                setShowAll({
                                  ...showAll,
                                  sectors: !showAll.sectors,
                                })
                              }
                              disabled={
                                payrollDashboardData.sectorsData.length <=
                                DEFAULT_LIMIT
                              }
                            >
                              {showAll.sectors ? "Show Less" : "Show More"}
                            </Button>
                          </th>
                        </tr>
                      </tbody>
                    ) : (
                      <TableSkeleton cols={2} rows={6} />
                    )}
                  </table>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-6 overflow-x-auto">
                <div
                  className="rounded p-2 dashboard-card"
                  style={{ border: "1px solid rgb(198 205 209)" }}
                >
                  <table className="table table-sm">
                    <caption
                      style={{
                        backgroundColor: "#e9ecef",
                      }}
                      className="mb-2"
                    >
                      Source Of Funds
                    </caption>
                    {!!payrollDashboardData?.sourceOfFundsData ? (
                      <tbody style={{ fontSize: "13px" }}>
                        {payrollDashboardData.sourceOfFundsData
                          .slice(
                            0,
                            showAll.sourceOfFund
                              ? payrollDashboardData.sourceOfFundsData.length
                              : DEFAULT_LIMIT
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <th
                                className="text-right"
                                style={{ borderLeft: "3px solid #dee2e6" }}
                              >
                                {item.sourceOfFundName}
                              </th>
                              <td className="text-right">
                                {currencyFormat(item.totalPaid || 0)}
                              </td>
                              <td>
                                <span className="badge badge-secondary">
                                  {(
                                    ((item.totalPaid || 0) * 100) /
                                    (getTotal(
                                      payrollDashboardData.sourceOfFundsData
                                    ) || 1)
                                  ).toFixed(2)}
                                  %
                                </span>
                              </td>
                            </tr>
                          ))}

                        <tr>
                          <th
                            style={{ border: "3px solid #dee2e6" }}
                            colSpan={3}
                            className="text-center bg-light "
                          >
                            {currencyFormat(
                              getTotal(payrollDashboardData.sourceOfFundsData)
                            )}{" "}
                            FRW
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={3} className="text-center bg-white pt-1">
                            <Button
                              variant="text"
                              onClick={() =>
                                setShowAll({
                                  ...showAll,
                                  sourceOfFund: !showAll.sourceOfFund,
                                })
                              }
                              disabled={
                                payrollDashboardData.sourceOfFundsData.length <=
                                DEFAULT_LIMIT
                              }
                            >
                              {showAll.sourceOfFund ? "Show Less" : "Show More"}
                            </Button>
                          </th>
                        </tr>
                      </tbody>
                    ) : (
                      <TableSkeleton cols={2} rows={6} />
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, payrollDashboardData }) => {
  return { user, loading, payrollDashboardData };
};
export default connect(mapStateToProps, { getPayrollDashboardData })(Dashboard);
