import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";

const WidgetViewContentDialog = (props) => {
  const { title, content, setOpenDialog, openDialog, maxWidth = "sm" } = props;

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        onClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
      >
        <DialogTitle>
        {title}
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
      </Dialog>
    </>
  );
};

export default WidgetViewContentDialog;
