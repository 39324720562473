import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
// import AddExamCenter from "./AddExamCenter";
import {
  getExamCenters,
  removeExamCenter,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { doesExamEnded } from "../../common/components/Utils";
import { showError } from "../../toastify";
import AddExamCenter from "./AddExamCenter";
import SearchBox from "../../common/components/SearchBox";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const ExamCenters = (props) => {
  const {
    loading,
    showExamCenters,
    setShowExamCenters,
    selectedExam,
    examCenters,
    getExamCenters,
    removeExamCenter,
    examCenterNoneSelected
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showAddExamCenter, setShowExamCenter] = useState(false);
  const [selectedExamCenter, setSelectedExamCenter] = useState(null);
  const [examCenterId, setExamCenterId] = useState("0");
  const [confirmRemoveExamCenter, setConfirmRemoveExamCenter] = useState(false);
  const [searchTeam, setSearchTeam] = useState("");

  const [filteredExamCenters, setFilteredExamCenters] = useState([
    ...examCenters,
  ]);
  const onClose = () => {
    setShowExamCenters(false);
  };

  useEffect(
    () => {
      getExamCenters(selectedExam.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedExam]
  );
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  useEffect(() => {
    const filtered = [...examCenters].filter(({ center }) =>
      center?.name.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredExamCenters(filtered);
  }, [searchTeam, examCenters]);
  return (
    <>
      <Dialog open={showExamCenters} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6" noWrap component="div" maxWidth={550}>
            {showAddExamCenter ? (
              <span style={{ color: "rgb(7, 142, 206)" }}>
                All Centers({examCenterNoneSelected.length})
              </span>
            ) : (
              <>
                <span style={{ color: "rgb(7, 142, 206)" }}>Exam Centers({examCenters.length})</span>
                
              </>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            {showAddExamCenter ? (
              <>
                <AddExamCenter
                  selectedExam={selectedExam}
                  setShowExamCenter={setShowExamCenter}
                />
              </>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <SearchBox
                    disabled={loading || !examCenters.length}
                    onSearch={onSearch}
                    placeholder="Enter center name…"
                  />
                  <Button className="ml-2"
                    onClick={() => {
                      if (hasPermissions(["IS_CBM", "IS_HR"])) {
                        // if (doesExamEnded(selectedExam)) {
                        //   showError("Exam Ended");
                        //   return false;
                        // }

                        if (selectedExam.wasExamMarkingPublished) {
                          showError("Exam Published");
                          return false;
                        }
                        setShowExamCenter(true);
                      } else {
                        showError("Make sure that you are HR or CBM!");
                      }
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Add
                  </Button>
                </div>
                <div className="mt-3">
                  <PreLoader isLoading={loading && !examCenters.length} />

                  <NoRecordMessage
                    isEmpty={!loading && !filteredExamCenters.length}
                    title={"No Exam Centers found!"}
                  />

                  {!loading && !!filteredExamCenters.length && (
                    <div className="mt-3">
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col" className="text-left">
                              Center({examCenters.length})
                            </th>
                            <th scope="col" className="text-right">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredExamCenters.map((examCenter, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {examCenter.center.name}
                                </span>
                              </td>

                              <td className="text-right">
                                {" "}
                                <button
                                  disabled={
                                    examCenterId === examCenter.id ||
                                    !hasPermissions(["IS_CBM", "IS_HR"]) ||
                                    doesExamEnded(selectedExam) ||
                                    selectedExam.wasExamMarkingPublished
                                  }
                                  onClick={() => {
                                    setSelectedExamCenter(examCenter);
                                    setConfirmRemoveExamCenter(true);
                                  }}
                                  className="btn btn-danger"
                                >
                                  {examCenterId === examCenter.id ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    <RemoveIcon />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {confirmRemoveExamCenter && (
                  <ConfirmationDialog
                    confirmationDialog={confirmRemoveExamCenter}
                    message={`Are you sure you want to remove this center @${selectedExamCenter.center.name} from Exam Centers?`}
                    setConfirmationDialog={setConfirmRemoveExamCenter}
                    onYes={() => {
                      removeExamCenter(
                        selectedExamCenter,
                        setExamCenterId,
                        setConfirmRemoveExamCenter,
                        setSelectedExamCenter
                      );
                    }}
                  />
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, examCenters,examCenterNoneSelected }) => {
  return {
    loading,
    examCenters,examCenterNoneSelected
  };
};
export default connect(mapStateToProps, {
  getExamCenters,
  removeExamCenter,
})(ExamCenters);
