import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  Button,
  TextField,
  Autocomplete,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  getWaitingListRequisitions,
  deleteWaitingListRequisition,
} from "../../../store/recruitment/actions";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";

import BackdropLoader from "../../common/components/BackdropLoader";
import WaitingListLeftSide from "../components/WaitingListLeftSide";
import moment from "moment";
import WaitingListJobOfferDialog from "./WaitingListJobOfferDialog";
import WaitingListRequestDetails from "../components/WaitingListRequestDetails";
import RequestPositionOnWaitingListDialog from "../components/RequestPositionOnWaitingListDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const WaitingListRequisitions = (props) => {
  const {
    waitingListRequisitions,
    getWaitingListRequisitions,
    deleteWaitingListRequisition,
  } = props;
  const defaultIds = "";
  const dispatch = useDispatch();
  const { hasPermissions } = useRequiredAuth();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 15
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [showAddRequisitionForm, setShowAddRequisitionForm] = useState(false);

  const [filterStatus, setFilterStatus] = useState({
    id: "",
    name: "All",
  });

  useEffect(() => {
    dispatch({
      type: "SET_WAITINGLIST_REQUISITIONS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getWaitingListRequisitions(query, setTotalRequests, setIsLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getWaitingListRequisitions(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getWaitingListRequisitions(query, setTotalRequests, setIsLoading1);
  };

  return (
    <>
      <div className="row mx-0">
        <div className="col-2">
          <WaitingListLeftSide />
        </div>
        <div className="col-10 mt-2">
          <div className="pr-3">
            <AppBar
              position="static"
              elevation={0}
              style={{ overflow: "hidden" }}
              className="app-bar w-100"
            >
              <Toolbar className="row">
                <div className="col-lg-5">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="text-uppercase"
                  >
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      {searchTerm ? "Filtered Requisitions" : "Requisitions"}(
                      {totalRequests})
                    </span>
                    {hasPermissions(["IS_HR"]) && (
                      <Button
                        onClick={() => setShowAddRequisitionForm(true)}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2 "
                        style={{
                          borderRadius: "8px",
                          height: "38px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          marginLeft: "5px",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                      >
                        <span className="material-icons">add</span>Request
                      </Button>
                    )}
                  </Typography>
                </div>

                <div className="col-lg-7">
                  <div className="d-flex justify-content-right  align-items-right">
                    <Autocomplete
                      sx={{ width: 400 }}
                      className="mr-2"
                      size="small"
                      id="filterStatus"
                      defaultValue={null}
                      value={filterStatus || null}
                      options={[
                        { id: "", name: "All" },
                        { id: "1", name: "Pending" },
                        { id: "2", name: "Approved" },
                        { id: "3", name: "Rejected" },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, currentStatus) => {
                        const query = {
                          searchTerm: "",
                          currentStatus: currentStatus?.id || defaultIds,
                          page: currentPage,
                          size: itemPerPage,
                        };
                        setFilterStatus(currentStatus);
                        getWaitingListRequisitions(
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterStatus) => (
                        <Box component="li" {...props} key={filterStatus.id}>
                          {filterStatus.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            width: "100%",
                            height: "35px!important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <SearchBox
                      onSearch={(search) => {
                        setSearchTerm(search);

                        if (!search) {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: "",
                            page: currentPage,
                            size: itemPerPage,
                            currentStatus: filterStatus?.id || defaultIds,
                          };
                          getWaitingListRequisitions(
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                    <Button
                      disabled={!searchTerm}
                      onClick={() => onSearch()}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2 "
                      style={{
                        borderRadius: "8px",
                        height: "38px",
                        border: `1px solid rgb(7, 142, 206)`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                    >
                      <span className="material-icons">search</span>GO
                    </Button>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            {showAddRequisitionForm && (
              <RequestPositionOnWaitingListDialog
                showAddRequisitionForm={showAddRequisitionForm}
                setShowAddRequisitionForm={setShowAddRequisitionForm}
              />
            )}

            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left py-2">
                    Position
                  </th>
                  <th scope="col" className="text-left">
                    Unit
                  </th>

                  <th scope="col" className="text-left">
                    Number
                  </th>
                  <th scope="col" className="text-left">
                    RequestedOn
                  </th>
                  <th scope="col" className="text-left">
                    RequestedBy
                  </th>
                  <th scope="col" className="text-left">
                    Status
                  </th>
                  <th scope="col" className="text-left">
                    StatusOn
                  </th>
                  <th scope="col" className="text-left">
                    StatusBy
                  </th>
                  <th scope="col" className="text-right"></th>
                </tr>
              </thead>
              {isLoading ? (
                <TableSkeleton cols={10} />
              ) : (
                <tbody>
                  <BackdropLoader isLoading={isLoading1} />
                  {waitingListRequisitions.map(
                    (waitingListRequisition, index) => (
                      <WaitingListRequisitionCard
                        hasPermissions={hasPermissions}
                        waitingListRequisition={waitingListRequisition}
                        index={index}
                        key={index}
                        deleteWaitingListRequisition={
                          deleteWaitingListRequisition
                        }
                      />
                    )
                  )}
                </tbody>
              )}
            </table>
            <NoRecordMessage
              isEmpty={!waitingListRequisitions.length && !isLoading}
              title={"No record found"}
            />
            {!isLoading && (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    // marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRequests / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                {!!waitingListRequisitions.length && (
                  <select
                    className="custom-select custom-select-sm ml-2"
                    style={{ width: "100px" }}
                    value={itemPerPage}
                    onChange={(event) => {
                      const itemPerPage = +event.target.value;
                      window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                      setItemPerPage(itemPerPage);
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                        currentStatus: filterStatus?.id || defaultIds,
                      };
                      getWaitingListRequisitions(
                        query,
                        setTotalRequests,
                        setIsLoading
                      );
                    }}
                  >
                    {[10, 15, 20, 50, 100].map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, waitingListRequisitions }) => {
  return { user, loading, waitingListRequisitions };
};
export default connect(mapStateToProps, {
  getWaitingListRequisitions,
  deleteWaitingListRequisition,
})(WaitingListRequisitions);

const WaitingListRequisitionCard = (props) => {
  const {
    waitingListRequisition,
    index,
    hasPermissions,
    deleteWaitingListRequisition,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [
    showWaitingListRequisitionDetail,
    setShowWaitingListRequisitionDetail,
  ] = useState(false);

  const [showWaitingListJobOffer, setShowWaitingListJobOffer] = useState(false);

  const [confirmRemoveRequisition, setConfirmRemoveRequisition] =
    useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left"> {waitingListRequisition.positionName}</td>
        <td className="text-left"> {waitingListRequisition.unitName}</td>
        <td className="text-left"> {waitingListRequisition.number}</td>
        <td className="text-left">
          {" "}
          {moment(waitingListRequisition.requestedOn).format("YYYY-MM-DD")}
        </td>
        <td className="text-left"> {waitingListRequisition.requestedBy}</td>
        <td className="text-left">
          <span
            className={`font-weight-bold text-uppercase text-${
              waitingListRequisition.statusId === 3
                ? "danger"
                : waitingListRequisition.statusId === 1
                ? "primary"
                : "success"
            }`}
          >
            {waitingListRequisition.statusName}
          </span>
        </td>
        <td className="text-left">
          {" "}
          {waitingListRequisition.statusDate &&
            moment(waitingListRequisition.statusDate).format("YYYY-MM-DD")}
        </td>
        <td className="text-left">
          {" "}
          {waitingListRequisition.statusBy && waitingListRequisition.statusBy}
        </td>
        <td className="text-right">
          <span className="d-flex flex-nowrap align-items-center justify-content-end">
            {waitingListRequisition.statusId === 2 && (
              <span
                style={{ width: "48px" }}
                className={`badge mr-2 p-1 badge-${
                  waitingListRequisition.allOffers === 0 ? "warning" : "success"
                }`}
              >
                {waitingListRequisition.allOffers} Offer
                {waitingListRequisition.allOffers > 1 && "s"}
              </span>
            )}
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {waitingListRequisition.statusId === 2 &&
                waitingListRequisition.allOffers > 0 && (
                  <span>
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        setShowWaitingListJobOffer(true);
                      }}
                      className="font-weight-bold text-primary"
                    >
                      <HowToRegIcon className="mr-2 text-primary" />
                      Candidates
                    </MenuItem>
                    <Divider className="my-1" />
                  </span>
                )}

              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowWaitingListRequisitionDetail(true);
                  }}
                  className="text-dark "
                >
                  <OpenInNewIcon className="mr-2" /> Preview
                </MenuItem>
                <Divider className="my-1" />
              </span>

              {hasPermissions(["IS_HR", "IS_CBM"]) &&
                waitingListRequisition.statusId === 1 && (
                  <span>
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        setConfirmRemoveRequisition(true);
                      }}
                      className="text-danger font-weight-light"
                    >
                      <DeleteForeverIcon className="mr-2 text-danger" /> Delete
                    </MenuItem>
                  </span>
                )}
            </Menu>
          </span>
        </td>
      </tr>

      {showWaitingListRequisitionDetail && (
        <WaitingListRequestDetails
          showWaitingListRequisitionDetail={showWaitingListRequisitionDetail}
          waitingListRequisition={waitingListRequisition}
          // selectedWaitingListRequisition={selectedWaitingListRequisition}
          setShowWaitingListRequisitionDetail={
            setShowWaitingListRequisitionDetail
          }
        />
      )}

      {showWaitingListJobOffer && (
        <WaitingListJobOfferDialog
          showWaitingListJobOffer={showWaitingListJobOffer}
          setShowWaitingListJobOffer={setShowWaitingListJobOffer}
          waitingListRequisition={waitingListRequisition}
        />
      )}

      {confirmRemoveRequisition && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveRequisition}
          message={
            <>
              {" "}
              Are you sure you want to{" "}
              <strong className="text-danger">delete</strong> this requisition?
            </>
          }
          setConfirmationDialog={setConfirmRemoveRequisition}
          onYes={() => {
            deleteWaitingListRequisition(waitingListRequisition.id, () =>
              setConfirmRemoveRequisition(false)
            );
          }}
        />
      )}
    </>
  );
};
