import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { showError, showSuccess } from "../../toastify";

import { doSignPayroll } from "../../../store/payroll/actions";
import axios from "axios";
import EditUserSignatureDialog from "./EditUserSignatureDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useDispatch } from "react-redux";

const SignPayrollDialog = (props) => {
  const {
    user,
    loading,
    showSignPayrollDialog,
    setShowSignPayrollDialog,
    setSelectedPayroll,
    doSignPayroll,
    selectedPayroll,
    signingUser,
    setSigningUser,
    userSignature,
    setUserSignature,
  } = props;

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    password: "",
    payrollNumber: "",
  });

  const [errors, setErrors] = useState({
    passwordHasError: false,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [showConfirmSignatureMessage, setShowConfirmSignatureMessage] =
    useState(false);

  const formValidator = () => {
    const error = {
      passwordHasError: false,
    };

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSignPayroll = () => {
    if (!formValidator()) {
      formData.payrollNumber = selectedPayroll.id;
      doSignPayroll(
        {
          payrollNumber: selectedPayroll.id,
          password: formData.password,
          approvalLevel: signingUser.approvalLevel,
          costCenterId: selectedPayroll.costCenterId,
          positionId: user.employments[0].positionId,
        },
        setShowSignPayrollDialog
      );
    }
  };

  const doConfirmSignature = async () => {
    try {
      dispatch({
        type: "START_WAIT",
      });
      dispatch({
        type: "START_LOADING",
      });

      const { data } = await axios.post(
        "/api/auth/confirm-user-signatures",
        {}
      );

      showSuccess("Signature confirmed successfully");

      setUserSignature(data);
      setShowConfirmSignatureMessage(false);

      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    } catch (error) {
      showError(error);
      dispatch({
        type: "END_LOADING",
      });
      dispatch({
        type: "END_WAIT",
      });
    }
  };

  const onClose = () => {
    setShowSignPayrollDialog(false);
  };

  const [showEditUserSignatureDialog, setShowEditUserSignatureDialog] =
    useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSignPayrollDialog}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">draw</span> Sign Payroll
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          {/* <span className="d-block text-center text-uppercase">
            {selectedPayroll.payrollGroupName} {" - "}
            {selectedPayroll.payrollTypeName}
            {" - "}
            {selectedPayroll.periodName}
          </span> */}

          <form>
            <div
              className="elevated rounded p-3 mt-2"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-12 px-0 text-center">
                  <div className="text-center mb-2">
                    <span className="text-uppercase">Your Signature</span>
                  </div>
                  {!userSignature && (
                    <div className="text-center mb-2">
                      <span className="text-danger">
                        Your signature was not set
                      </span>
                    </div>
                  )}
                  {!!userSignature && (
                    <img
                      style={{ width: "192px", border: "solid 1px #dae1e7" }}
                      src={"data:image/jpg;base64," + userSignature?.signature}
                      className="rounded py-1"
                    />
                  )}

                  <div className="text-center">
                    {!userSignature?.isVerified && (
                      <div className="">
                        <button
                          onClick={() => setShowEditUserSignatureDialog(true)}
                          type="button"
                          className="btn btn-sm btn-link"
                        >
                          {!!userSignature?.signature ? "Edit" : "Set"} your
                          signature
                        </button>
                      </div>
                    )}
                    {userSignature?.isVerified && (
                      <div
                        className="d-inline-flex align-items-center text-success"
                        style={{ fontSize: "10px" }}
                      >
                        <span
                          className="material-icons text-success"
                          style={{ fontSize: "16px" }}
                        >
                          verified
                        </span>{" "}
                        Confirmed
                      </div>
                    )}
                  </div>
                </div>
                {!!userSignature?.signature && userSignature?.isVerified && (
                  <div className="col-8 px-5 mt-2">
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      autoFocus
                      name="password"
                      type="password"
                      label="Enter your password to sign"
                      variant="outlined"
                      placeholder="Enter your password to sign"
                      value={formData.password}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          password: e.target.value || "",
                        });
                        setErrors({ ...errors, passwordHasError: false });
                      }}
                    />

                    {errors.passwordHasError && (
                      <small className="text-danger">
                        Password is required{" "}
                      </small>
                    )}
                  </div>
                )}

                {!!userSignature?.signature && userSignature?.isVerified && (
                  <div className="col-12 d-flex justify-content-center pt-3">
                    <button
                      onClick={onSignPayroll}
                      type="button"
                      className="btn btn-primary text-uppercase"
                      disabled={loading}
                    >
                      {loading ? "Signing..." : "Sign payroll"}
                    </button>
                  </div>
                )}

                {!!userSignature?.signature && !userSignature?.isVerified && (
                  <div className="col-12 pb-3 text-center">
                    <span className="d-block mt-2 mt-n2">or</span>
                    <span className="d-block">
                      <button
                        onClick={() => setShowConfirmSignatureMessage(true)}
                        type="button"
                        className="btn btn-success btn-sm text-uppercase"
                        disabled={loading}
                      >
                        Confirm your Signature
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <EditUserSignatureDialog
        showEditUserSignatureDialog={showEditUserSignatureDialog}
        setShowEditUserSignatureDialog={setShowEditUserSignatureDialog}
        setUserSignature={setUserSignature}
      />

      {showConfirmSignatureMessage && (
        <ConfirmationDialog
          confirmationDialog={showConfirmSignatureMessage}
          message={`Are you sure you want to confirm your signature?`}
          setConfirmationDialog={setShowConfirmSignatureMessage}
          onYes={() => {
            doConfirmSignature();
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, { doSignPayroll })(SignPayrollDialog);
