import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";

import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { connect } from "react-redux";
import { logout } from "../../../store/common/actions";
import { isEmpty } from "lodash";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Drawer = (props) => {
  const {
    drawerState,
    setDrawerState,
    user,
    logout,
    selectedModule,
    userProfilePicture,
  } = props;

  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  const history = useHistory();

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed  ${drawerState ? "active" : ""}`}
      >
        <div>
          <div
            className="drawer-user "
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
            {userProfilePicture && (
              <img
                src={`data:image/png;base64,${userProfilePicture}`}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            {!userProfilePicture && (
              <img
                src={defaultProfile}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            <div>
              <Tooltip title="Go to your profile">
                <Link
                  to="/structure/profile"
                  style={{ color: "#b8ff07" }}
                  className="font-weight-bold text-uppercase"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  {user.firstName} {user.lastName}
                </Link>
              </Tooltip>
            </div>
            {user.employments.map((employment, index) => (
              <small className=" " style={{ color: "#ff0" }} key={index}>
                {employment.positionName}
              </small>
            ))}
            <br />
            <small style={{ color: "#fff" }}>
              <>
                {!isEmpty(user) &&
                  !isEmpty(user.selectedEntitySector) &&
                  !isEmpty(selectedModule) && (
                    <>
                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length > 0 && (
                        <>
                          {user.selectedEntitySector.roles[
                            selectedModule.id
                          ]?.map((role, index) => (
                            <span key={index}>
                              {" "}
                              {role.name}
                              {user.selectedEntitySector.roles[
                                selectedModule.id
                              ].length !==
                                index + 1 && <>,</>}
                            </span>
                          ))}
                        </>
                      )}

                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length === 0 && <>Employee</>}
                    </>
                  )}
              </>
            </small>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>

          <List>
            {hasPermissions([
              "IS_HR",
              "IS_TRAINING_MIFOTRA_REVIEWER",
              "IS_TRAINING_MIFOTRA_VERIFIER",
              "IS_TRAINING_MINEDUC_REVIEWER",
              "IS_TRAINING_MINEDUC_VERIFIER",
              "IS_TRAINING_MINISTRY_VERIFIER",
              "IS_RDB_TRAINING_APPROVER",
              "IS_TRAINING_RDB_REVIEWER",
              "IS_TRAINING_RDB_VERIFIER",
              "IS_HEAD_OF_INSTITUTION",
              "IS_TRAINING_MIFOTRA_VIEWER",
              "IS_TRAINING_INITIATOR",
              "IS_TRAINING_MIFOTRA_REVIEWER2",
              "IS_TRAINING_MINISTRY_REVIEWER",
              "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"
            ]) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/in-progress-requests")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons">pending</span>
                    </ListItemIcon>
                    <Link
                      to="/training/in-progress-requests"
                      className="text-dark"
                    >
                      <ListItemText
                        primary="InProgress"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {hasPermissions([
              "IS_HR",
              "IS_TRAINING_MIFOTRA_REVIEWER",
              "IS_TRAINING_MIFOTRA_VERIFIER",
              "IS_TRAINING_MINEDUC_REVIEWER",
              "IS_TRAINING_MINEDUC_VERIFIER",
              "IS_TRAINING_MINISTRY_VERIFIER",
              "IS_RDB_TRAINING_APPROVER",
              "IS_TRAINING_RDB_REVIEWER",
              "IS_TRAINING_RDB_VERIFIER",
              "IS_HEAD_OF_INSTITUTION",
              "IS_TRAINING_PM_OVERSIGHT",
              "IS_TRAINING_MIFOTRA_VIEWER",
              "IS_TRAINING_INITIATOR",
              "IS_TRAINING_MIFOTRA_REVIEWER2",
              "IS_TRAINING_MINISTRY_REVIEWER",
              "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"
            ]) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/approved-requests")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons-round">check_circle</span>
                    </ListItemIcon>
                    <Link
                      to="/training/approved-requests"
                      className="text-dark"
                    >
                      <ListItemText
                        primary="Approved"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {hasPermissions([
              "IS_HR",
              "IS_TRAINING_MIFOTRA_REVIEWER",
              "IS_TRAINING_MIFOTRA_VERIFIER",
              "IS_TRAINING_MINEDUC_REVIEWER",
              "IS_TRAINING_MINEDUC_VERIFIER",
              "IS_TRAINING_MINISTRY_VERIFIER",
              "IS_RDB_TRAINING_APPROVER",
              "IS_TRAINING_RDB_REVIEWER",
              "IS_TRAINING_RDB_VERIFIER",
              "IS_HEAD_OF_INSTITUTION",
              "IS_TRAINING_MIFOTRA_VIEWER",
              "IS_TRAINING_INITIATOR",
              "IS_TRAINING_MIFOTRA_OVERSIGHT",
              "IS_TRAINING_MINISTRY_REVIEWER",
              "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"
            ]) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/rejected-requests")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons-round">cancel</span>
                    </ListItemIcon>
                    <Link
                      to="/training/rejected-requests"
                      className="text-dark"
                    >
                      <ListItemText
                        primary="Rejected"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}

            {hasPermissions(["IS_HR"]) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/cd-plans")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons-round">attachment</span>
                    </ListItemIcon>
                    <Link to="/training/cd-plans" className="text-dark">
                      <ListItemText
                        primary="CD Plan"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}
            {(hasPermissions([
              "IS_TRAINING_MIFOTRA_VIEWER",
              "IS_TRAINING_MIFOTRA_VERIFIER",
              "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"
            ]) ||
              (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                user?.selectedEntitySector?.id ===
                  "2000000000-010200000000000000-02")) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/unlock-employee-for-thirty-days")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons-round">lock</span>
                    </ListItemIcon>
                    <Link to="/training/unlock-employee-for-thirty-days" className="text-dark">
                      <ListItemText
                        primary="Unlock employee for 30 days"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}

            {hasPermissions(["IS_TRAINING_MIFOTRA_OVERSIGHT"]) && (
              <>
                <ListItem
                  disablePadding
                  className={`nav-item  ${
                    location.pathname.includes("/training/oversights")
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon className="item-icon text-dark">
                      <span className="material-icons-round">dashboard</span>
                    </ListItemIcon>
                    <Link to="/training/oversights" className="text-dark">
                      <ListItemText
                        primary="Oversights"
                        className="pl-0"
                      ></ListItemText>
                    </Link>
                  </ListItemButton>
                </ListItem>
              </>
            )}

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/training/user-guide")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">local_library</span>
                </ListItemIcon>
                <Link to="/training/user-guide" className="text-dark">
                  <ListItemText
                    primary="User-Guide"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Button
          className="btn btn-block mb-3 "
          color="error"
          style={{ position: "absolute", bottom: "0" }}
          onClick={() => logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${drawerState ? "d-block" : "d-none"} `}
        onClick={() => {
          setDrawerState(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule, userProfilePicture }) => {
  return { user, selectedModule, userProfilePicture };
};
export default connect(mapStateToProps, { logout })(Drawer);
