import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TableSkeleton from "../../common/components/TableSkeleton";
import { AnnualLeaveRow } from "../pages/UnitLeavePlan";
import { geEmployeeInLeavePeriod } from "../../../store/leave/actions";

const ViewSamePeriodStaff = (props) => {
  const { leave, onClose, loading, user, geEmployeeInLeavePeriod } = props;
  const [otherEmployees, setOtherEmployees] = useState([]);
  const [loadingOtherEmployees, setLoadingOtherEmployees] = useState(false);

  useEffect(() => {
    if (leave) {
      geEmployeeInLeavePeriod(
        {
          startDate: leave.startDate,
          endDate: leave.endDate,
          leavePlanId: leave.id,
        },
        (status, data) => {
          setLoadingOtherEmployees(status);
          setOtherEmployees(data);
        }
      );
    } else setOtherEmployees([]);
  }, []);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          Other staff with planned leave for the same period
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent className="py-1">
        <TableContainer>
          <Table size="small" aria-label="Your annual leave balance">
            <TableHead>
              <TableRow
                style={{
                  borderLeft: "2px solid #e9ecef",
                  borderRight: "2px solid #e9ecef",
                }}
              >
                <TableCell>#</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Starting On</TableCell>
                <TableCell>Ending On</TableCell>
                <TableCell>Planned Days</TableCell>
                <TableCell>Planned On</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            {loadingOtherEmployees ? (
              <TableSkeleton cols={9} isMUI />
            ) : (
              <TableBody>
                {otherEmployees.map((plan, index) => (
                  <AnnualLeaveRow
                    plan={plan}
                    index={index}
                    loading={loadingOtherEmployees || loading}
                    key={plan.id}
                    isMe={plan.employeeId === user?.id}
                    showPlannedOn={true}
                  />
                ))}
                {!otherEmployees.length && (
                  <TableRow>
                    <TableCell colSpan={9} className="text-center">
                      No leave plan found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  geEmployeeInLeavePeriod,
})(ViewSamePeriodStaff);
