import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Slide from "@mui/material/Slide";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { currencyFormat } from "../../common/components/Utils";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import {
  downloadPDFPayroll,
  getPayrollDetails,
} from "../../../store/payroll/actions";
import { isEmpty } from "lodash";
import SignPayrollDialog from "./SignPayrollDialog";
import axios from "axios";
import RejectPayrollDialog from "./RejectPayrollDialog";

const PayrollPreviewArrearDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    showPayroll,
    setShowPayroll,
    selectedPayroll,
    payrollPreviewed,
    downloadPDFPayroll,
    getPayrollDetails,
    payrollDetails: inputPayrollDetails,
    type,
    viewType,
  } = props;

  // console.log(payrollPreviewed);

  const [payrollDetails, setPayrollDetails] = useState([]);

  useEffect(() => {
    setPayrollDetails(inputPayrollDetails[selectedPayroll.id]);
  }, [inputPayrollDetails]);

  const getTotalAllowances = () => +selectedPayroll.grossSalary;

  const getTotalWithhelds = () =>
    +selectedPayroll.pensionEmployee +
    +selectedPayroll.maternityEmployee +
    +selectedPayroll.medicalEmployee +
    +selectedPayroll.mmiEmployee +
    +selectedPayroll.cbhi +
    +selectedPayroll.tpr +
    +selectedPayroll.withHolds;

  const getTotalPatronalContributions = () =>
    +selectedPayroll.pensionEmployer +
    +selectedPayroll.professionalRisk +
    +selectedPayroll.maternityEmployer +
    +selectedPayroll.medicalEmployer +
    +selectedPayroll.mmiEmployer;

  const [formatedPayrollDetails, setFormatPayrollDetails] = useState([]);
  const [payrollChangeList, setPayrollChangeList] = useState({});

  const formatPayrollDetails = () => {
    const units = [];

    payrollDetails.payList.forEach((payrollDetail) => {
      const unitIndex = units.findIndex(
        (unit) => unit.unitId === payrollDetail.unitId
      );

      if (unitIndex < 0)
        units.push({
          unitId: payrollDetail.unitId,
          unitName: payrollDetail.unitName,
          basePay: payrollDetail.basePay,
          housingAllowance: payrollDetail.housingAllowance,
          transportAllowance: payrollDetail.transportAllowance,
          taxableAllowance: payrollDetail.taxableAllowance,
          exemptedAllowance: payrollDetail.exemptedAllowance,
          lumpsum: payrollDetail.lumpsum,
          grossSalary: payrollDetail.grossSalary,
          tpr: payrollDetail.tpr,
          tprLumpsum: payrollDetail.tprLumpsum,
          pensionEmployee: payrollDetail.pensionEmployee,
          pensionEmployer: payrollDetail.pensionEmployer,
          professionalRisk: payrollDetail.professionalRisk,
          medicalEmployee: payrollDetail.medicalEmployee,
          medicalEmployer: payrollDetail.medicalEmployer,
          maternityEmployee: payrollDetail.maternityEmployee,
          maternityEmployer: payrollDetail.maternityEmployer,
          cbhi: payrollDetail.cbhi,
          partialNet: payrollDetail.partialNet,
          withHolds: payrollDetail.withHolds,
          netLumpsum: payrollDetail.netLumpsum,
          netSalary: payrollDetail.netSalary,
          employees: [payrollDetail],
        });
      else {
        units[unitIndex].basePay += +payrollDetail.basePay;
        units[unitIndex].housingAllowance += +payrollDetail.housingAllowance;
        units[unitIndex].transportAllowance +=
          +payrollDetail.transportAllowance;
        units[unitIndex].taxableAllowance += +payrollDetail.taxableAllowance;
        units[unitIndex].exemptedAllowance += +payrollDetail.exemptedAllowance;
        units[unitIndex].lumpsum += +payrollDetail.lumpsum;
        units[unitIndex].grossSalary += +payrollDetail.grossSalary;
        units[unitIndex].tpr += +payrollDetail.tpr;
        units[unitIndex].tprLumpsum += +payrollDetail.tprLumpsum;
        units[unitIndex].pensionEmployee += +payrollDetail.pensionEmployee;
        units[unitIndex].pensionEmployer += +payrollDetail.pensionEmployer;
        units[unitIndex].professionalRisk += +payrollDetail.professionalRisk;
        units[unitIndex].medicalEmployee += +payrollDetail.medicalEmployee;
        units[unitIndex].medicalEmployer += +payrollDetail.medicalEmployer;
        units[unitIndex].maternityEmployee += +payrollDetail.maternityEmployee;
        units[unitIndex].maternityEmployer += +payrollDetail.maternityEmployer;
        units[unitIndex].cbhi += +payrollDetail.cbhi;
        units[unitIndex].partialNet += +payrollDetail.partialNet;
        units[unitIndex].withHolds += +payrollDetail.withHolds;
        units[unitIndex].netLumpsum += +payrollDetail.netLumpsum;
        units[unitIndex].netSalary += +payrollDetail.netSalary;
        units[unitIndex].employees.push(payrollDetail);
      }
    });

    setFormatPayrollDetails(units);
  };

  const formatChangeList = (listOfChanges) => {
    let formatList = {
      basePay: 0,
      housingAllowance: 0,
      transportAllowance: 0,
      taxableAllowance: 0,
      lumpsum: 0,
      grossSalary: 0,
      pensionEmployer: 0,
      professionalRisk: 0,
      medicalEmployer: 0,
      maternityEmployer: 0,
      prevBasePay: 0,
      prevHousingAllowance: 0,
      prevTransportAllowance: 0,
      prevTaxableAllowance: 0,
      prevLumpsum: 0,
      prevGrossSalary: 0,
      prevPensionEmployer: 0,
      prevProfessionalRisk: 0,
      prevMedicalEmployer: 0,
      prevMaternityEmployer: 0,
      differance: 0,
      changeList: [],
    };

    listOfChanges.forEach((employee) => {
      formatList.basePay += +employee.basePay;
      formatList.housingAllowance += +employee.housingAllowance;
      formatList.transportAllowance += +employee.transportAllowance;
      formatList.taxableAllowance += +employee.taxableAllowance;
      formatList.lumpsum += +employee.lumpsum;
      formatList.grossSalary += +employee.grossSalary;
      formatList.pensionEmployer += +employee.pensionEmployer;
      formatList.professionalRisk += +employee.professionalRisk;
      formatList.medicalEmployer += +employee.medicalEmployer;
      formatList.maternityEmployer += +employee.maternityEmployer;
      formatList.prevBasePay += employee.prevBasePay;
      formatList.prevHousingAllowance += employee.prevHousingAllowance;
      formatList.prevTransportAllowance += employee.prevTransportAllowance;
      formatList.prevTaxableAllowance += employee.prevTaxableAllowance;
      formatList.prevLumpsum += employee.prevLumpsum;
      formatList.prevGrossSalary += employee.prevGrossSalary;
      formatList.prevPensionEmployer += employee.prevPensionEmployer;
      formatList.prevProfessionalRisk += employee.prevProfessionalRisk;
      formatList.prevMedicalEmployer += employee.prevMedicalEmployer;
      formatList.prevMaternityEmployer += employee.prevMaternityEmployer;
      formatList.differance +=
        +employee.grossSalary +
        +employee.lumpsum +
        +employee.pensionEmployer +
        +employee.professionalRisk +
        +employee.medicalEmployer +
        +employee.maternityEmployer -
        (+employee.prevGrossSalary +
          +employee.prevLumpsum +
          +employee.prevPensionEmployer +
          +employee.prevProfessionalRisk +
          +employee.prevMedicalEmployer +
          +employee.prevMaternityEmployer);
      formatList.changeList.push(employee);
    });

    return formatList;
  };

  useEffect(() => {
    if (
      !!payrollDetails &&
      !!payrollDetails.payList &&
      payrollDetails.payList.length
    )
      formatPayrollDetails();
  }, [payrollDetails]);

  const getPayrollChangeList = () => {
    if (
      !!payrollDetails &&
      !!payrollDetails.changeList &&
      payrollDetails.changeList.length
    )
      return {
        news: formatChangeList(
          payrollDetails.changeList.filter((item) => item.status === "New")
        ),
        promoDemos: formatChangeList(
          payrollDetails.changeList.filter(
            (item) => item.status === "Promotion/Demotion"
          )
        ),
        removeds: formatChangeList(
          payrollDetails.changeList.filter((item) => item.status === "Removed")
        ),
      };
    else
      return {
        news: [],
        promoDemos: [],
        removeds: [],
      };
  };

  const [showSignPayrollDialog, setShowSignPayrollDialog] = useState(false);
  const [signingUser, setSigningUser] = useState({});
  const [rejectionLevel, setRejectionLevel] = useState("");
  const [showRejectPayrollDialog, setShowRejectPayrollDialog] = useState(false);

  const firstSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "GENERATED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const secondSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "VERIFIED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const thirdSignatory = () => {
    const approval = payrollDetails?.approvals?.find(
      ({ approvalLevel }) => approvalLevel === "APPROVED"
    );

    if (!!approval) approval.canSign = approval.userId === user.id;

    return approval;
  };

  const [userSignature, setUserSignature] = useState(null);
  const [isGettingSignature, setIsGettingSignature] = useState(false);

  const getUserSignature = async (setShowSignPayrollDialog) => {
    try {
      setIsGettingSignature(true);
      const { data } = await axios.get("/api/auth/user-signatures");
      setUserSignature(data);
      setShowSignPayrollDialog(true);
      setIsGettingSignature(false);
    } catch (error) {
      setShowSignPayrollDialog(true);
      setIsGettingSignature(false);
    }
  };

  const countUnique = (iterable) => {
    let employeeIds = [];

    iterable.forEach(({ employeeId }) => employeeIds.push(employeeId));

    return new Set(employeeIds).size;
  };

  return (
    <>
      <div id="main-content" style={{ marginTop: 12 }}>
        <table className="table table-sm table-borderless">
          <tbody>
            <tr>
              <td colSpan={2}></td>
              <td colSpan={2}>
                <table
                  style={{
                    border: "solid 1px #4f81bc",
                    textAlign: "center",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          border: "solid 1px #4f81bc",
                          backgroundColor: "#4f81bc",
                          color: "#fff",
                        }}
                      >
                        PAYROLL
                      </th>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {selectedPayroll.payrollTypeName}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {!!selectedPayroll.minPeriodName &&
                          !!selectedPayroll.maxPeriodName && (
                            <>
                              {selectedPayroll.minPeriodName}

                              {selectedPayroll.minPeriodName !==
                                selectedPayroll.maxPeriodName && (
                                <> - {selectedPayroll.maxPeriodName}</>
                              )}
                            </>
                          )}
                        {!(
                          selectedPayroll.minPeriodName &&
                          selectedPayroll.maxPeriodName
                        ) && <>{selectedPayroll.periodName}</>}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {selectedPayroll.payrollGroupName} /{" "}
                        {selectedPayroll.sourceOfFundName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <th
                colSpan={6}
                style={{
                  width: "50%",
                  borderLeft: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                Payroll Summary
              </th>
            </tr>
            <tr>
              <td colSpan={6} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Allowances
              </th>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Withheld
              </th>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Salary Dispatch
              </th>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Base Pay:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.basePay)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.pensionEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployee +
                    +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Housing:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.housingAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity Leave:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.maternityEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity Leave:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.maternityEmployee +
                    +selectedPayroll.maternityEmployer
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Transport:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.transportAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.medicalEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployee +
                    +selectedPayroll.medicalEmployer
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Taxable:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.taxableAllowance)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.mmiEmployee)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.mmiEmployee + +selectedPayroll.mmiEmployer
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Exempted:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                0
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Mutuelle:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.cbhi)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Mutuelle:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.cbhi)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalAllowances())}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                TPR:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.tpr)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                TPR:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.tpr)}
              </td>
            </tr>
            <tr>
              <th
                colSpan={2}
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Patronal Contribution
              </th>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Non Statutory:
              </td>
              <td
                rowSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.withHolds)}
              </td>

              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Other Withheld:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.withHolds)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Net Salary:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.partialNet - +selectedPayroll.withHolds
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                C S R:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.toTalPensionEmployer)}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalWithhelds())}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(
                  +getTotalAllowances() + +getTotalPatronalContributions()
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Maternity:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.maternityEmployer)}
              </td>
              <th
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Salary Take Home
              </th>
              <th
                colSpan={2}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                Lumpsum
              </th>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                RAMA:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.medicalEmployer)}
              </td>
              <td
                rowSpan={3}
                colSpan={2}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "center",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(
                  (
                    (getTotalAllowances() - getTotalWithhelds()) /
                    selectedPayroll.exchangeRate
                  ).toFixed(0)
                )}{" "}
                {selectedPayroll.currencyId}
                {selectedPayroll.currencyId !== "RWF" && (
                  <small className="d-block">
                    Exchange rate: {selectedPayroll.exchangeRate * 1.0}
                  </small>
                )}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                T P R
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.tprLumpsum)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                MMI:
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.mmiEmployer)}
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                }}
              >
                Net
              </td>
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {currencyFormat(+selectedPayroll.netLumpsum)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                Total:
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+getTotalPatronalContributions())}
              </td>
              <td
                colSpan={2}
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  textAlign: "right",
                  padding: "0px 8px",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #dcd9d9",
                }}
              >
                {currencyFormat(+selectedPayroll.lumpsum)}
              </td>
            </tr>
            <tr>
              <td colSpan={6} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={6}
                style={{
                  width: "50%",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                General Totals
              </th>
            </tr>
            <tr>
              <td colSpan={6} style={{ backgroundColor: "#fff" }} />
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +getTotalAllowances() + +getTotalPatronalContributions()
                )}
              </th>
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  backgroundColor: "#fff",
                }}
              />
              <th
                colSpan={2}
                style={{
                  width: "17%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#f3f3f3",
                }}
              >
                {currencyFormat(
                  +getTotalAllowances() +
                    +getTotalPatronalContributions() +
                    +selectedPayroll.lumpsum
                )}
              </th>
            </tr>
          </tbody>
        </table>

        <table
          className="table table-sm table-borderless table-hover"
          style={{ marginTop: 24, marginBottom: 8 }}
        >
          <tbody>
            <tr>
              <th
                colSpan={24}
                style={{
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "white",
                  textAlign: "center",
                  fontSize: "18px",
                  padding: 3,
                }}
              >
                Payroll List ({countUnique(payrollDetails?.payList || [])})
              </th>
            </tr>
            <tr>
              <td
                colSpan={24}
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  backgroundColor: "#fff",
                }}
              />
            </tr>
            {formatedPayrollDetails.map((unit) => (
              <Fragment key={unit.unitId}>
                <tr>
                  <td
                    colSpan={24}
                    style={{
                      fontSize: "7px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      color: "#fff",
                    }}
                  >
                    {unit.unitName} ({countUnique(unit?.employees || [])})
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={24}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <th
                    colSpan={7}
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                  <th
                    colSpan={5}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Allowances
                  </th>
                  <th
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Social Sec.
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #fff",
                      color: "#fff",
                    }}
                  >
                    Maternity
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      fontSize: "9px",
                      textAlign: "center",
                      backgroundColor: "#4f81bc",
                      borderLeft: "1px solid #fff",
                      color: "#fff",
                    }}
                  >
                    Medical Insu.
                  </th>
                  <th
                    colSpan={5}
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    EmployeeId
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Names
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Position
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Level
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    AccountNo
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "left",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Period
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Days
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    BasePay
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Housing
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Transport
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Other
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Lumpsum
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    TPR
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employee
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Employer
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Mutuelle
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Net Lumpsum
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Partial Net
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Withheld
                  </th>
                  <th
                    style={{
                      fontSize: "7px",
                      textAlign: "right",
                      backgroundColor: "#f3f3f3",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                    }}
                  >
                    Net
                  </th>
                </tr>

                {unit.employees.map((employee) => (
                  <Fragment key={employee.id}>
                    <tr>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.employeeId}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.lastName} {employee.firstName}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                          maxWidth: "192px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {employee.positionName.startsWith("Ag.") && (
                          <>
                            <strong className="text-primary">Ag.</strong>
                            {employee.positionName.replace("Ag.", "")}
                          </>
                        )}
                        {!employee.positionName.startsWith("Ag.") && (
                          <>{employee.positionName}</>
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.level}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.accountNumber}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "left",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.arrearPeriodId}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {employee.noOfDays || "-"}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.basePay)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.housingAllowance)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.transportAllowance)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.taxableAllowance)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.lumpsum)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.tpr)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.pensionEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(
                          +employee.pensionEmployer + +employee.professionalRisk
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.maternityEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.maternityEmployer)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.medicalEmployee)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.medicalEmployer)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.cbhi)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.netLumpsum)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.partialNet)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.withHolds)}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          textAlign: "right",
                          borderLeft: "1px solid #4f81bc",
                          borderRight: "1px solid #4f81bc",
                          borderBottom: "1px solid #4f81bc",
                        }}
                      >
                        {currencyFormat(employee.netSalary)}
                      </td>
                    </tr>
                  </Fragment>
                ))}
                <tr>
                  <td
                    colSpan={25}
                    style={{
                      fontSize: "7px",
                      backgroundColor: "#fff",
                    }}
                  />
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: "7px",
                      backgroundColor: "#fff",
                    }}
                  />
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      fontWeight: "bold",
                      backgroundColor: "#4f81bc",
                      color: "#fff",
                    }}
                  >
                    Total
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      fontWeight: "bold",
                      backgroundColor: "#f3f3f3",
                    }}
                  >
                    Number: {unit.employees.length}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.basePay)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.housingAllowance)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.transportAllowance)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.taxableAllowance)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.lumpsum)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.tpr)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.pensionEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(
                      +unit.pensionEmployer + +unit.professionalRisk
                    )}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.maternityEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.maternityEmployer)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.medicalEmployee)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.medicalEmployer)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.cbhi)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.netLumpsum)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.partialNet)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.withHolds)}
                  </td>
                  <td
                    style={{
                      fontSize: "9px",
                      textAlign: "right",
                      borderLeft: "1px solid #4f81bc",
                      borderRight: "1px solid #4f81bc",
                      borderBottom: "1px solid #4f81bc",
                      borderTop: "1px solid #4f81bc",
                      backgroundColor: "#f3f3f3",
                      fontWeight: "bold",
                    }}
                  >
                    {currencyFormat(unit.netSalary)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={24}
                    style={{
                      padding: "6px 0px",
                      textAlign: "center",
                      backgroundColor: "#fff",
                      color: "#fff",
                    }}
                  ></td>
                </tr>
              </Fragment>
            ))}

            <tr>
              <td
                colSpan={2}
                style={{
                  fontSize: "7px",
                  backgroundColor: "#fff",
                }}
              />
              <td
                colSpan={2}
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  fontWeight: "bold",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                  padding: "6px 8px",
                }}
              >
                Grand Totals
              </td>
              <td
                colSpan={3}
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  fontWeight: "bold",
                  backgroundColor: "#dcdbe6",
                }}
              >
                Number: {selectedPayroll.numberOfEmployees}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.basePay)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.housingAllowance)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.transportAllowance)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.taxableAllowance)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.lumpsum)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.tpr + +selectedPayroll.tprLumpsum
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.pensionEmployee)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.pensionEmployer +
                    +selectedPayroll.professionalRisk
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.maternityEmployee)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.maternityEmployer)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployee +
                    +selectedPayroll.mmiEmployee
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(
                  +selectedPayroll.medicalEmployer +
                    +selectedPayroll.mmiEmployer
                )}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.cbhi)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.netLumpsum)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.partialNet)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.withHolds)}
              </td>
              <td
                style={{
                  fontSize: "9px",
                  textAlign: "right",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                  borderTop: "1px solid #4f81bc",
                  backgroundColor: "#dcdbe6",
                  fontWeight: "bold",
                }}
              >
                {currencyFormat(selectedPayroll.netSalary)}
              </td>
            </tr>
          </tbody>
        </table>

        <table style={{ marginTop: 24, marginBottom: 8 }}>
          <tbody>
            <tr>
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Generated By
              </th>
              <th style={{ width: "15%", backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Verified by
              </th>
              <th style={{ width: "15%", backgroundColor: "#fff" }} />
              <th
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  border: "1px solid #4f81bc",
                  backgroundColor: "#4f81bc",
                  color: "#fff",
                }}
              >
                Approved by
              </th>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {firstSignatory()?.names} <br />
                {firstSignatory()?.position}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {secondSignatory()?.names}
                <br />
                {secondSignatory()?.position}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {thirdSignatory()?.names}
                <br />
                {thirdSignatory()?.position}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed && (
                  <img
                    style={{ width: "192px" }}
                    src={"data:image/jpg;base64," + firstSignatory()?.signature}
                  />
                )}
                {!firstSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!firstSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {firstSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed && !!secondSignatory()?.signed && (
                  <img
                    style={{ width: "192px" }}
                    src={
                      "data:image/jpg;base64," + secondSignatory()?.signature
                    }
                  />
                )}
                {!secondSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!secondSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {secondSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  padding: 8,
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                }}
              >
                {!!firstSignatory()?.signed &&
                  !!secondSignatory()?.signed &&
                  !!thirdSignatory()?.signed && (
                    <img
                      style={{ width: "192px" }}
                      src={
                        "data:image/jpg;base64," + thirdSignatory()?.signature
                      }
                    />
                  )}
                {!thirdSignatory()?.signed && (
                  <span className="d-block text-danger">Not signed</span>
                )}
                {!!thirdSignatory()?.signed && (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Signed
                    </span>
                    <span className="d-block text-center">
                      {thirdSignatory()?.statusDate}
                    </span>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !firstSignatory()?.signed &&
                  !secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!firstSignatory() &&
                  !!firstSignatory()?.canSign &&
                  viewType !== "admin" && (
                    <Button
                      disabled={isGettingSignature}
                      color="primary"
                      variant="contained"
                      style={{
                        borderRadius: "8px",
                        height: "32px",
                        alignItems: "center",
                      }}
                      size="sm"
                      onClick={() => {
                        setSigningUser(firstSignatory());
                        getUserSignature(setShowSignPayrollDialog);
                      }}
                    >
                      <span className="material-icons mr-1">draw</span> Sign
                    </Button>
                  )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !!firstSignatory()?.signed &&
                  !secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!secondSignatory() &&
                  !!secondSignatory().canSign &&
                  selectedPayroll.statusId === "GENERATED" &&
                  viewType !== "admin" && (
                    <>
                      <Button
                        disabled={isGettingSignature}
                        color="primary"
                        variant="contained"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setSigningUser(secondSignatory());
                          getUserSignature(setShowSignPayrollDialog);
                        }}
                      >
                        <span className="material-icons mr-1">draw</span> Sign
                      </Button>

                      <Button
                        disabled={isGettingSignature}
                        color="error"
                        variant="contained"
                        className="ml-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setRejectionLevel("REJECTED_BY_VERIFICATOR");
                          setShowRejectPayrollDialog(true);
                        }}
                      >
                        <span className="material-icons mr-1">close</span>{" "}
                        Reject
                      </Button>
                    </>
                  )}

                {selectedPayroll.statusId === "REJECTED_BY_VERIFICATOR" && (
                  <span className="badge badge-danger">
                    {selectedPayroll.statusName}
                  </span>
                )}
              </td>
              <td />
              <td
                style={{
                  width: "20%",
                  fontSize: "12px",
                  textAlign: "center",
                  borderLeft: "1px solid #4f81bc",
                  borderRight: "1px solid #4f81bc",
                  borderBottom: "1px solid #4f81bc",
                }}
              >
                {type === "Temp" &&
                  !!firstSignatory()?.signed &&
                  !!secondSignatory()?.signed &&
                  !thirdSignatory()?.signed &&
                  !!thirdSignatory() &&
                  !!thirdSignatory()?.canSign &&
                  selectedPayroll.statusId === "VERIFIED" &&
                  viewType !== "admin" && (
                    <>
                      <Button
                        disabled={isGettingSignature}
                        color="primary"
                        variant="contained"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setSigningUser(thirdSignatory());
                          getUserSignature(setShowSignPayrollDialog);
                        }}
                      >
                        <span className="material-icons mr-1">draw</span> Sign
                      </Button>

                      <Button
                        disabled={isGettingSignature}
                        color="error"
                        variant="contained"
                        className="ml-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          alignItems: "center",
                        }}
                        size="sm"
                        onClick={() => {
                          setRejectionLevel("REJECTED_BY_CBM");
                          setShowRejectPayrollDialog(true);
                        }}
                      >
                        <span className="material-icons mr-1">close</span>{" "}
                        Reject
                      </Button>
                    </>
                  )}

                {selectedPayroll.statusId === "REJECTED_BY_CBM" && (
                  <span className="badge badge-danger">
                    {selectedPayroll.statusName}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showSignPayrollDialog && (
        <SignPayrollDialog
          selectedPayroll={selectedPayroll}
          showSignPayrollDialog={showSignPayrollDialog}
          setShowSignPayrollDialog={setShowSignPayrollDialog}
          signingUser={signingUser}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
        />
      )}

      {showRejectPayrollDialog && (
        <RejectPayrollDialog
          selectedPayroll={selectedPayroll}
          showRejectPayrollDialog={showRejectPayrollDialog}
          setShowRejectPayrollDialog={setShowRejectPayrollDialog}
          // signingUser={signingUser}
          rejectionLevel={rejectionLevel}
          // setUserSignature={setUserSignature}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  payrollPreviewed,
  payrollDetails,
}) => {
  return { user, loading, onWait, payrollPreviewed, payrollDetails };
};
export default connect(mapStateToProps, {
  downloadPDFPayroll,
  getPayrollDetails,
})(PayrollPreviewArrearDialog);
