import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { respondRehabilitationRequest } from "../../../store/exit/actions";

const ApproveRehabilitationDialog = (props) => {
  const {
    loading,
    selectedRehabilitationRequest,
    setApproveRehabilitationForm,
    approveRehabilitationForm,
    responseStatus,
    respondRehabilitationRequest,
    closeRequestDetails,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);
  const [comment, setComment] = useState("");
  const onClose = () => {
    if (!submitting) setApproveRehabilitationForm(false);
  };

  const submitResponse = () => {
    if (!comment.length) return setFormError(true);
    respondRehabilitationRequest(
      selectedRehabilitationRequest.rehabilitationId,
      responseStatus + 1,
      comment,
      (status, res) => {
        setSubmitting(status);
        if (!status && res) {
          setComment("");
          onClose();
          closeRequestDetails();
        }
      }
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRehabilitationForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Approve Rehabilitation Of @{" "}
            {selectedRehabilitationRequest &&
              selectedRehabilitationRequest.firstName}{" "}
            {selectedRehabilitationRequest &&
              selectedRehabilitationRequest.lastName}{" "}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <p>
            <span className="font-weight-light">
              <b>Decision:</b>
            </span>{" "}
            {responseStatus === 1 ? (
              <span className="badge badge-success">Approved</span>
            ) : (
              <span className="badge badge-danger">Rejected</span>
            )}
          </p>
          <TextField
            required
            id="rejectExit"
            label="Comments..."
            fullWidth
            multiline
            rows={3}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              setFormError(false);
            }}
            error={formError}
            helperText={formError && "Comment is required"}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={submitResponse}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, { respondRehabilitationRequest })(
  ApproveRehabilitationDialog
);
