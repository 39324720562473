import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getUnitsEvaluations,
  downloadUnitPerformanceReport,
  getUnitEvaluationSummary,
  downloadInstitutionEmployeesScores,
} from "../../../store/rbm/actions";
import MonitorUnityCard from "../components/MonitorUnityCard";
import RBMPageHeader from "../components/RBMPageHeader";
import UnitIndicators from "./UnitIndicators";

const MonitorUnities = (props) => {
  const {
    unitsEvaluations,
    loading,
    selectedFiscalYear,
    selectedUserEntity,
    getUnitsEvaluations,
    getUnitEvaluationSummary,
    downloadInstitutionEmployeesScores,
    user,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [evaluationSummary, setEvaluationSummary] = useState({
    allUnit: 0,
    targetShare: 0,
    achievedTarget: 0,
    score: 0,
    numOfItems: 0,
    unassigned: 0,
    due: 0,
    onTrack: 0,
    overdue: 0,
    reported: 0,
    scored: 0,
  });
  const [showSelectedUnity, setShowSelectedUnity] = useState(null);
  const [loadingUnits, setLoadingUnits] = useState(true);

  const handleLoadUnits = (query, setLoading) => {
    if (setLoading) {
      getUnitsEvaluations(query, setTotalRecords, setLoading);
    } else {
      getUnitsEvaluations(query, setTotalRecords, setLoader);
    }
  };

  useEffect(
    () => {
      // if (!loading && !unitsEvaluations.length)
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      handleLoadUnits(query, setLoadingUnits);
      getUnitEvaluationSummary((res) => {
        if (res) setEvaluationSummary(res);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps

    [selectedFiscalYear, selectedUserEntity]
  );

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadUnits(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadUnits(query);
  };

  useEffect(() => {
    document.title = "IPPIS - RBM Monitor Unities";
  }, []);

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <div>
            <RBMPageHeader
              labels={[
                {
                  title: "Units",
                  value: evaluationSummary.allUnit,
                  type: "number",
                },
                {
                  title: "Items",
                  value: evaluationSummary.numOfItems || 0,
                  type: "number",
                },
                {
                  title: "Unassigned",
                  value: evaluationSummary.unassigned || 0,
                  type: "number",
                },
                {
                  title: "Ontrack",
                  value: evaluationSummary.onTrack || 0,
                  type: "number",
                  onClick: () => {
                    const query = {
                      searchTerm: "ontrack",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    setSearchTerm("ontrack");
                    handleLoadUnits(query);
                  },
                },
                {
                  title: "Overdue",
                  value: evaluationSummary.overdue || 0,
                  type: "number",
                  onClick: () => {
                    const query = {
                      searchTerm: "overdue",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    setSearchTerm("overdue");
                    handleLoadUnits(query);
                  },
                },
                {
                  title: "Reported",
                  value: evaluationSummary.reported || 0,
                  type: "number",
                  onClick: () => {
                    const query = {
                      searchTerm: "reported",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    setSearchTerm("reported");
                    handleLoadUnits(query);
                  },
                },
                {
                  title: "Scored",
                  value: evaluationSummary.scored || 0,
                  type: "number",
                  onClick: () => {
                    const query = {
                      searchTerm: "scored",
                      page: currentPage,
                      size: itemPerPage,
                    };
                    setSearchTerm("scored");
                    handleLoadUnits(query);
                  },
                },
              ]}
              progress={{
                title: "PROGRESS",
                value: evaluationSummary.score,
              }}
              buttons={[
                {
                  fun: () =>
                    downloadInstitutionEmployeesScores(
                      user.selectedEntitySector.id
                    ),
                  title: "Report",
                  placeholder: "Employees performance report",
                },
              ]}
              disabled={loading || loader || loadingUnits}
              loading={loading || loader || loadingUnits}
            />

            <div className="rbm-shadow rounded px-4 py-3 ">
              <div className="form-group mt-2 mb-2">
                <input
                  type="text"
                  className="form-control"
                  value={searchTerm}
                  id="filter"
                  placeholder="Search..."
                  onChange={(e) => onSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };
                      handleLoadUnits(query);
                    }
                  }}
                  disabled={loading || loader || loadingUnits}
                />
              </div>
              {unitsEvaluations &&
                unitsEvaluations.map((unit) => (
                  <MonitorUnityCard
                    key={unit.id}
                    unit={unit}
                    // index={getNo(unit.id)}
                    setShowSelectedUnity={setShowSelectedUnity}
                  />
                ))}
            </div>

            {!unitsEvaluations.length && (loading || loadingUnits) && (
              <div>
                <Skeleton
                  variant="rectangular"
                  className="mb-3 mt-2"
                  height={118}
                />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
              </div>
            )}

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecords / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!unitsEvaluations.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadUnits(query);
                  }}
                >
                  {[5, 10, 50].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {unitsEvaluations &&
            !unitsEvaluations[0] &&
            !loading &&
            !loadingUnits &&
            !loader && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container">
                  <p className="lead">No units found.</p>
                </div>
              </div>
            )}
        </div>
      </div>

      {showSelectedUnity && (
        <Dialog
          onClose={() => setShowSelectedUnity(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle className="text-primary">
            <div className="d-flex align-items-center">
              <span className="badge badge-info mr-1">
                {showSelectedUnity.action === "all"
                  ? "All Items"
                  : showSelectedUnity.action === "overdue"
                  ? "Overdue Items"
                  : showSelectedUnity.action === "ontrack"
                  ? "Ontrack Items"
                  : showSelectedUnity.action === "reported"
                  ? "Reported Items"
                  : showSelectedUnity.action === "scored"
                  ? "Scored Items"
                  : "All Items"}
              </span>
              {showSelectedUnity.name}
            </div>
            <IconButton
              aria-label="close"
              onClick={() => setShowSelectedUnity(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <UnitIndicators selectedUnit={showSelectedUnity} />
          </DialogContent>
        </Dialog>
      )}

      <div
        className={`page-loading-void position-fixed ${
          loader && !!unitsEvaluations[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  unitsEvaluations,
  loading,
  selectedFiscalYear,
  selectedUserEntity,
}) => {
  return {
    user,
    unitsEvaluations,
    loading,
    selectedFiscalYear,
    selectedUserEntity,
  };
};
export default connect(mapStateToProps, {
  getUnitsEvaluations,
  downloadUnitPerformanceReport,
  getUnitEvaluationSummary,
  downloadInstitutionEmployeesScores,
})(MonitorUnities);
