import { defaultState } from "./state";
import rbmTypes from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...rbmTypes };

const outputs = (outputsState = defaultState.outputs, action) => {
  switch (action.type) {
    case types.SET_OUTPUTS:
      return action.data;

    case types.ADD_NEW_OUTPUT: {
      const tmpoutputsState = [...outputsState];
      tmpoutputsState.push(action.data);
      return tmpoutputsState;
    }

    case types.UPDATE_OUTPUT: {
      const tmpoutputsState = [...outputsState];
      const index = tmpoutputsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpoutputsState[index] = action.data;

      return tmpoutputsState;
    }

    case types.DELETE_OUTPUT: {
      const tmpoutputsState = [...outputsState];
      const index = tmpoutputsState.findIndex(({ id }) => id === action.id);
      tmpoutputsState.splice(index, 1);

      return tmpoutputsState;
    }

    case types.CLEAN_STATE:
    case types.REMOVE_ACTION_PLAN:
      return defaultState.outputs;

    default:
      return outputsState;
  }
};

// const selectedExpectedResult = (
//   selectedExpectedResultState = defaultState.selectedExpectedResult,
//   action
// ) => {
//   switch (action.type) {
//     case types.SET_SELECTED_OUTPUT:
//       return action.data;

//     case types.CLEAN_STATE:
//       return defaultState.selectedExpectedResult;

//     default:
//       return selectedExpectedResultState;
//   }
// };

const indicators = (indicatorsState = defaultState.indicators, action) => {
  switch (action.type) {
    case types.SET_INDICATORS:
      return action.data;

    case types.ADD_NEW_INDICATOR: {
      const tmpIndicatorsState = [...indicatorsState];
      tmpIndicatorsState.push(action.data);
      return tmpIndicatorsState;
    }

    case types.UPDATE_INDICATOR: {
      const tmpIndicatorsState = [...indicatorsState];
      const index = tmpIndicatorsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpIndicatorsState[index] = action.data;

      return tmpIndicatorsState;
    }

    case types.UPDATE_INDICATOR_TARGET_SHARE: {
      const tmpIndicatorsState = [...indicatorsState];
      const index = tmpIndicatorsState.findIndex(
        ({ id }) => id === action.data.indicatorId
      );
      if (index !== -1) {
        action.data.assignmentData.forEach((targetShare) => {
          const assignmentIndex = tmpIndicatorsState[
            index
          ].assignments.findIndex(
            ({ assignmentId }) => assignmentId === targetShare.assignmentId
          );
          if (assignmentIndex !== -1) {
            const { quarterId, value } = targetShare;
            tmpIndicatorsState[index].assignments[assignmentIndex][
              `q${quarterId}TargetShare`
            ] = value;
          }
        });
      }

      return tmpIndicatorsState;
    }

    case types.DELETE_INDICATOR: {
      const tmpIndicatorsState = [...indicatorsState];
      const index = tmpIndicatorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpIndicatorsState.splice(index, 1);

      return tmpIndicatorsState;
    }

    case types.CLEAN_STATE:
    case types.REMOVE_ACTION_PLAN:
      return defaultState.indicators;

    default:
      return indicatorsState;
  }
};

const unitIndicators = (
  unitIndicatorsState = defaultState.unitIndicators,
  action
) => {
  switch (action.type) {
    case types.SET_UNITY_INDICATORS:
      return action.data;

    case types.UPDATE_INDICATOR: {
      const tmpUnitIndicatorsState = [...unitIndicatorsState];
      const index = tmpUnitIndicatorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpUnitIndicatorsState[index] = action.data;

      return tmpUnitIndicatorsState;
    }

    case types.UPDATE_OUTPUT: {
      const tmpUnitIndicatorsState = [...unitIndicatorsState];
      const index = tmpUnitIndicatorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpUnitIndicatorsState[index] = action.data;

      return tmpUnitIndicatorsState;
    }

    case types.DELETE_INDICATOR: {
      const tmpUnitIndicatorsState = [...unitIndicatorsState];
      const index = tmpUnitIndicatorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpUnitIndicatorsState.splice(index, 1);

      return tmpUnitIndicatorsState;
    }

    case types.CLEAN_STATE:
      return defaultState.unitIndicators;

    default:
      return unitIndicatorsState;
  }
};

const selectedIndicator = (
  selectedIndicatorState = defaultState.selectedIndicator,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_INDICATOR:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedIndicator;

    default:
      return selectedIndicatorState;
  }
};

const indicatorActivities = (
  indicatorActivitiesState = defaultState.indicatorActivities,
  action
) => {
  switch (action.type) {
    case types.SET_INDICATOR_ACTIVITIES:
      return action.data;

    case types.ADD_NEW_ACTIVITY: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.quarterTargetId
      );

      if (!tmpIndicatorActivitiesState[quarterIndex].activities)
        tmpIndicatorActivitiesState[quarterIndex].activities = [action.data];
      else
        tmpIndicatorActivitiesState[quarterIndex].activities.unshift(
          action.data
        );

      tmpIndicatorActivitiesState[quarterIndex].activities = [
        ...tmpIndicatorActivitiesState[quarterIndex].activities,
      ];

      tmpIndicatorActivitiesState[quarterIndex] = {
        ...tmpIndicatorActivitiesState[quarterIndex],
      };
      return tmpIndicatorActivitiesState;
    }

    case types.UPDATE_ACTIVITY: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.quarterTargetId
      );

      if (quarterIndex >= 0) {
        const activities = [
          ...(tmpIndicatorActivitiesState[quarterIndex].activities || []),
        ];

        const activityIndex = activities.findIndex(
          ({ id }) => id === action.data.id
        );

        if (activityIndex >= 0) {
          activities[activityIndex] = action.data;

          tmpIndicatorActivitiesState[quarterIndex] = {
            ...tmpIndicatorActivitiesState[quarterIndex],
            activities: [...activities],
          };
        }
      }

      return tmpIndicatorActivitiesState;
    }

    case types.DELETE_ACTIVITY: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.quarterTargetId
      );

      const activityIndex = tmpIndicatorActivitiesState[
        quarterIndex
      ].activities.findIndex(({ id }) => id === action.data.id);

      tmpIndicatorActivitiesState[quarterIndex].activities.splice(
        activityIndex,
        1
      );

      tmpIndicatorActivitiesState[quarterIndex].activities = [
        ...tmpIndicatorActivitiesState[quarterIndex].activities,
      ];

      tmpIndicatorActivitiesState[quarterIndex] = {
        ...tmpIndicatorActivitiesState[quarterIndex],
      };

      return tmpIndicatorActivitiesState;
    }

    case types.ADD_ACTIVITY_ASSIGNMENT: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.activity.quarterTargetId
      );

      const activities = [
        ...(tmpIndicatorActivitiesState[quarterIndex].activities || []),
      ];

      const activityIndex = activities.findIndex(
        ({ id }) => id === action.data.activity.id
      );

      const assignments = activities[activityIndex].assignments || [];

      assignments.push(action.data.assignment);

      activities[activityIndex] = {
        ...activities[activityIndex],
        assignments: [...assignments],
      };

      tmpIndicatorActivitiesState[quarterIndex] = {
        ...tmpIndicatorActivitiesState[quarterIndex],
        activities: [...activities],
      };

      return tmpIndicatorActivitiesState;
    }

    // case types.REMOVE_ACTIVITY_ASSIGNMENT: {
    //   const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

    //   const quarterIndex = tmpIndicatorActivitiesState.findIndex(
    //     ({ id }) => id === action.data.activity.quarterTargetId
    //   );

    //   const activities = [
    //     ...(tmpIndicatorActivitiesState[quarterIndex].activities || []),
    //   ];

    //   const activityIndex = activities.findIndex(
    //     ({ id }) => id === action.data.activity.id
    //   );

    //   const assignments = activities[activityIndex].assignments || [];

    //   const assignmentIndex = assignments.findIndex(
    //     ({ assignmentId }) => assignmentId === action.data.id
    //   );

    //   assignments.splice(assignmentIndex, 1);

    //   activities[activityIndex] = {
    //     ...activities[activityIndex],
    //     assignments: [...assignments],
    //   };

    //   tmpIndicatorActivitiesState[quarterIndex] = {
    //     ...tmpIndicatorActivitiesState[quarterIndex],
    //     activities: [...activities],
    //   };

    //   return tmpIndicatorActivitiesState;
    // }

    case types.ADD_ACTIVITY_EVALUATION: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.activity.quarterTargetId
      );

      if (quarterIndex < 0) return tmpIndicatorActivitiesState;

      const activities = [
        ...(tmpIndicatorActivitiesState[quarterIndex].activities || []),
      ];

      const activityIndex = activities.findIndex(
        ({ id }) => id === action.data.activity.id
      );

      // const submissions = activities[activityIndex].submissions || [];
      const assignments = activities[activityIndex].assignments || [];

      // if (submissions.length > 0)
      //   submissions[submissions.length - 1].evaluation = action.data.evaluation;

      activities[activityIndex] = {
        ...action.data.activity,
        // submissions: [...submissions],
        assignments: [...assignments],
      };

      tmpIndicatorActivitiesState[quarterIndex] = {
        ...tmpIndicatorActivitiesState[quarterIndex],
        activities: [...activities],
      };

      return tmpIndicatorActivitiesState;
    }

    case types.REMOVE_ACTIVITY_EVALUATION: {
      const tmpIndicatorActivitiesState = [...indicatorActivitiesState];

      const quarterIndex = tmpIndicatorActivitiesState.findIndex(
        ({ id }) => id === action.data.activity.quarterTargetId
      );

      if (quarterIndex < 0) return tmpIndicatorActivitiesState;

      const activities = [
        ...(tmpIndicatorActivitiesState[quarterIndex].activities || []),
      ];

      const activityIndex = activities.findIndex(
        ({ id }) => id === action.data.activity.id
      );

      // const submissions = activities[activityIndex].submissions || [];
      const assignments = activities[activityIndex].assignments || [];

      // if (submissions.length > 0)
      //   delete submissions[submissions.length - 1].evaluation;

      activities[activityIndex] = {
        ...action.data.activity,
        // submissions: [...submissions],
        assignments: [...assignments],
      };

      tmpIndicatorActivitiesState[quarterIndex] = {
        ...tmpIndicatorActivitiesState[quarterIndex],
        activities: [...activities],
      };

      return tmpIndicatorActivitiesState;
    }

    case types.CLEAN_STATE:
      return defaultState.indicatorActivities;

    default:
      return indicatorActivitiesState;
  }
};

const selectedActivity = (
  selectedActivityState = defaultState.selectedActivity,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_ACTIVITY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedActivity;

    default:
      return selectedActivityState;
  }
};

const myContracts = (myContractsState = defaultState.myContracts, action) => {
  switch (action.type) {
    case types.SET_MY_CONTRACTS:
      return action.data;

    case types.UPDATE_CONTRACT: {
      const tmpMyContractsState = [...myContractsState];
      const index = tmpMyContractsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpMyContractsState[index] = action.data;

      return tmpMyContractsState;
    }

    case types.CLEAN_STATE:
      return defaultState.myContracts;

    default:
      return myContractsState;
  }
};

const subordinates = (
  subordinatesState = defaultState.subordinates,
  action
) => {
  switch (action.type) {
    case types.SET_SUBORDINATES:
      return action.data;

    case types.UPDATE_SUBORDINATE: {
      const tmpSubordinatesState = [...subordinatesState];

      const index = tmpSubordinatesState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpSubordinatesState[index] = action.data;

      return tmpSubordinatesState;
    }

    case types.LOGOUT:
      return defaultState.subordinates;

    default:
      return subordinatesState;
  }
};

const subordinateContracts = (
  subordinateContractsState = defaultState.subordinateContracts,
  action
) => {
  switch (action.type) {
    case types.SET_SUBORDINATE_CONTRACTS:
      return action.data;

    case types.UPDATE_SUBORDINATE_CONTRACT: {
      const tmpsubordinateContractsState = [...subordinateContractsState];

      const index = tmpsubordinateContractsState.findIndex(
        ({ employeePositionId }) =>
          employeePositionId === action.data.employeePositionId
      );

      if (index >= 0)
        tmpsubordinateContractsState[index] = {
          no: tmpsubordinateContractsState[index].no,
          ...action.data,
        };

      return tmpsubordinateContractsState;
    }

    case types.REMOVE_CONTRACT_ASSIGNMENT_ITEM: {
      const tmpsubordinateContractsState = [...subordinateContractsState];

      const index = tmpsubordinateContractsState.findIndex(
        ({ contractId }) => contractId === action.data.contractId
      );

      let removeContract = null;

      if (index >= 0) {
        if (tmpsubordinateContractsState[index].numOfActivities === 1) {
          removeContract = tmpsubordinateContractsState[index]?.id;
        }
        tmpsubordinateContractsState[index] = {
          ...tmpsubordinateContractsState[index],
          numOfActivities:
            tmpsubordinateContractsState[index].numOfActivities - 1,
          dueActivities:
            action.data.statusId === 2
              ? tmpsubordinateContractsState[index].dueActivities - 1
              : tmpsubordinateContractsState[index].dueActivities,
          ontrackActivities:
            action.data.statusId === 1
              ? tmpsubordinateContractsState[index].ontrackActivities - 1
              : tmpsubordinateContractsState[index].ontrackActivities,
        };
      }

      if (removeContract) {
        if (
          tmpsubordinateContractsState.filter(({ id }) => id === removeContract)
            .length > 1
        ) {
          return tmpsubordinateContractsState.filter(
            ({ contractId }) => contractId !== action.data.contractId
          );
        } else {
          tmpsubordinateContractsState[index] = {
            ...tmpsubordinateContractsState[index],
            contractId: null,
            contractTypeId: null,
            contractTypeName: null,
          };
        }
      }

      return tmpsubordinateContractsState;
    }

    case types.SET_SUBORDINATE_CONTRACT_COMPETENCY_SCORES:
    case types.REMOVE_SUBORDINATE_CONTRACT_PENDING_ASSIGNMENTS: {
      const tmpsubordinateContractsState = [...subordinateContractsState];
      const index = tmpsubordinateContractsState.findIndex(
        ({ contractId }) => contractId === action.data.contractId
      );

      if (index >= 0) {
        tmpsubordinateContractsState[index] = {
          no: tmpsubordinateContractsState[index].no,
          ...action.data,
        };
      }

      return tmpsubordinateContractsState;
    }

    case types.SET_SUBORDINATE_CONTRACT_COMPETENCY_RE_EVALUATION: {
      const tmpsubordinateContractsState = [...subordinateContractsState];
      const index = tmpsubordinateContractsState.findIndex(
        ({ contractId }) => contractId === action.data.contractId
      );

      if (index >= 0) {
        tmpsubordinateContractsState[index] = {
          ...tmpsubordinateContractsState[index],
          competencyScore: 0,
        };
      }

      return tmpsubordinateContractsState;
    }

    case types.LOGOUT:
      return defaultState.subordinateContracts;

    default:
      return subordinateContractsState;
  }
};

const supervisors = (supervisorsState = defaultState.supervisors, action) => {
  switch (action.type) {
    case types.SET_SUPERVISORS:
      return action.data;

    case types.LOGOUT:
      return defaultState.supervisors;

    default:
      return supervisorsState;
  }
};

const technicalHeads = (
  technicalHeadsState = defaultState.technicalHeads,
  action
) => {
  switch (action.type) {
    case types.SET_TECHNICAL_HEADS:
      return action.data;

    case types.LOGOUT:
      return defaultState.technicalHeads;

    default:
      return technicalHeadsState;
  }
};

const fiscalYears = (fiscalYearsState = defaultState.fiscalYears, action) => {
  switch (action.type) {
    case types.SET_FISCAL_YEARS:
      return action.data;

    case types.LOGOUT:
      return defaultState.fiscalYears;

    default:
      return fiscalYearsState;
  }
};

const selectedFiscalYear = (
  selectedFiscalYearState = defaultState.selectedFiscalYear,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_FISCAL_YEAR:
      return action.data;

    case types.LOGOUT:
      return defaultState.selectedFiscalYear;

    default:
      return selectedFiscalYearState;
  }
};

const userEntities = (
  userEntitiesState = defaultState.userEntities,
  action
) => {
  switch (action.type) {
    case types.SET_USER_ENTITIES:
      return action.data;

    case types.LOGOUT:
      return defaultState.userEntities;

    default:
      return userEntitiesState;
  }
};

const selectedUserEntity = (
  selectedUserEntityState = defaultState.selectedUserEntity,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_USER_ENTITY:
      return action.data;

    case types.LOGOUT:
      return defaultState.selectedUserEntity;

    default:
      return selectedUserEntityState;
  }
};

// const units = (unitsState = defaultState.units, action) => {
//   switch (action.type) {
//     case types.SET_UNITS:
//       return action.data;

//     case types.CLEAN_STATE:
//       return defaultState.units;

//     default:
//       return unitsState;
//   }
// };

const institutionsEvaluations = (
  institutionsEvaluationsState = defaultState.institutionsEvaluations,
  action
) => {
  switch (action.type) {
    case types.SET_OVERSIGHT_EVALUATIONS:
      return action.data;

    case types.CLEAN_STATE:
    case types.REMOVE_ACTION_PLAN:
      return defaultState.institutionsEvaluations;

    default:
      return institutionsEvaluationsState;
  }
};

const unitsEvaluations = (
  unitsEvaluationsState = defaultState.unitsEvaluations,
  action
) => {
  switch (action.type) {
    case types.SET_UNITS_EVALUATIONS:
      return action.data;

    case types.CLEAN_STATE:
    case types.REMOVE_ACTION_PLAN:
      return defaultState.unitsEvaluations;

    default:
      return unitsEvaluationsState;
  }
};

const employeesEvaluations = (
  employeesEvaluationsState = defaultState.employeesEvaluations,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEES_EVALUATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeesEvaluations;

    default:
      return employeesEvaluationsState;
  }
};

const indicatorAssignments = (
  indicatorAssignmentsState = defaultState.indicatorAssignments,
  action
) => {
  switch (action.type) {
    case types.SET_INDICATOR_ASSIGNMENTS:
      return action.data;

    case types.REMOVE_INDICATOR_ASSIGNMENT: {
      const tmpState = [...indicatorAssignmentsState];

      const index = tmpState.findIndex(
        ({ unitId }) => unitId === action.unitId
      );

      if (index < 0) return tmpState;

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.indicatorAssignments;

    default:
      return indicatorAssignmentsState;
  }
};

const myActivities = (
  myActivitiesState = defaultState.myActivities,
  action
) => {
  switch (action.type) {
    case types.SET_MY_ACTIVITIES:
      return action.data;

    case types.UPDATE_ACTIVITY: {
      const tmpMyActivitiesState = [...myActivitiesState];

      const quarterIndex = tmpMyActivitiesState.findIndex(
        ({ quarterId }) => quarterId === action.data.quarterId
      );

      if (quarterIndex >= 0) {
        const activities = [
          ...(tmpMyActivitiesState[quarterIndex].activities || []),
        ];

        const activityIndex = activities.findIndex(
          ({ id }) => id === action.data.id
        );

        if (activityIndex >= 0) {
          const assignments = !!activities[activityIndex]?.assignments
            ? [...activities[activityIndex]?.assignments]
            : undefined;

          activities[activityIndex] = {
            ...action.data,
            assignments: assignments,
          };

          tmpMyActivitiesState[quarterIndex] = {
            ...tmpMyActivitiesState[quarterIndex],
            activities: [...activities],
          };
        }
      }

      return tmpMyActivitiesState;
    }

    case types.CLEAN_STATE:
      return defaultState.myActivities;

    default:
      return myActivitiesState;
  }
};

const contractItems = (
  contractItemsState = defaultState.contractItems,
  action
) => {
  switch (action.type) {
    case types.SET_CONTRACT_ITEMS:
      return action.data;

    case types.UPDATE_CONTRACT_ITEM: {
      const tmpcontractItemsState = [...contractItemsState];

      const quarterIndex = tmpcontractItemsState.findIndex(
        ({ quarterId }) => quarterId === action.data.quarterId
      );

      if (quarterIndex >= 0) {
        const activities = [
          ...(tmpcontractItemsState[quarterIndex].activities || []),
        ];

        const activityIndex = activities.findIndex(
          ({ id }) => id === action.data.id
        );

        if (activityIndex >= 0) {
          const assignments = !!activities[activityIndex]?.assignments
            ? [...activities[activityIndex]?.assignments]
            : undefined;

          activities[activityIndex] = {
            ...action.data,
            assignments: assignments,
          };

          tmpcontractItemsState[quarterIndex] = {
            ...tmpcontractItemsState[quarterIndex],
            activities: [...activities],
          };
        }
      }

      return tmpcontractItemsState;
    }

    case types.REMOVE_SUBORDINATE_CONTRACT_PENDING_ASSIGNMENTS: {
      const tmpcontractItemsState = [...contractItemsState];
      const q1Activities = (tmpcontractItemsState[0].activities || []).filter(
        ({ statusId }) => statusId !== 1 && statusId !== 2 && statusId !== 5
      );
      const q2Activities = (tmpcontractItemsState[1].activities || []).filter(
        ({ statusId }) => statusId !== 1 && statusId !== 2 && statusId !== 5
      );
      const q3Activities = (tmpcontractItemsState[2].activities || []).filter(
        ({ statusId }) => statusId !== 1 && statusId !== 2 && statusId !== 5
      );
      const q4Activities = (tmpcontractItemsState[3].activities || []).filter(
        ({ statusId }) => statusId !== 1 && statusId !== 2 && statusId !== 5
      );

      tmpcontractItemsState[0] = {
        ...tmpcontractItemsState[0],
        activities: [...q1Activities],
      };

      tmpcontractItemsState[1] = {
        ...tmpcontractItemsState[1],
        activities: [...q2Activities],
      };

      tmpcontractItemsState[2] = {
        ...tmpcontractItemsState[2],
        activities: [...q3Activities],
      };

      tmpcontractItemsState[3] = {
        ...tmpcontractItemsState[3],
        activities: [...q4Activities],
      };

      return tmpcontractItemsState;
    }

    case types.REMOVE_CONTRACT_ASSIGNMENT_ITEM: {
      const tmpcontractItemsState = [...contractItemsState];

      const quarterIndex = tmpcontractItemsState.findIndex(
        ({ quarterId }) => quarterId === action.data.quarterId
      );

      if (quarterIndex >= 0) {
        const activities = [
          ...(tmpcontractItemsState[quarterIndex].activities || []).filter(
            ({ assignmentId }) => assignmentId !== action.data.assignmentId
          ),
        ];

        tmpcontractItemsState[quarterIndex] = {
          ...tmpcontractItemsState[quarterIndex],
          activities: [...activities],
        };
      }

      return tmpcontractItemsState;
    }

    case types.CLEAN_STATE:
      return defaultState.contractItems;

    default:
      return contractItemsState;
  }
};

const myContractActivities = (
  myContractActivitiesState = defaultState.myContractActivities,
  action
) => {
  switch (action.type) {
    case types.SET_MY_CONTRACT_ACTIVITIES:
      return [...myContractActivitiesState, action.data];
    case types.ADD_ITEM_SUBMISSION: {
      const tmpMyContractActivitiesState = [...myContractActivitiesState];

      const contractIndex = tmpMyContractActivitiesState.findIndex(
        ({ contractId }) => contractId === action.data.contractId
      );

      tmpMyContractActivitiesState[contractIndex].data.summary.reported += 1;
      tmpMyContractActivitiesState[contractIndex].data.summary.pending -= 1;

      if (action.data.itemTypeId === 1) {
        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.outputs.findIndex(({ id }) => id === action.data.id);
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ] = {
          ...tmpMyContractActivitiesState[contractIndex].data.outputs[
            assignmentIndex
          ],
          status: "reported",
          submissionId: action.data.result.id,
          achievedTarget: action.data.result.achievedTarget,
          completed: action.data.result.completed,
          reportedMessage: action.data.result.message,
          reportedBy: action.data.result.createdBy,
          reportedOn: action.data.result.createdOn,
          fileName: action.data.result.fileName,
          fileFullPath: action.data.result.fileFullPath,
          firstName: action.data.result.firstName,
          lastName: action.data.result.lastName,
          submissions: [
            ...tmpMyContractActivitiesState[contractIndex].data.outputs[
              assignmentIndex
            ].submissions,
            { ...action.data.result, evaluation: null },
          ],
        };
        // tmpMyContractActivitiesState[contractIndex].data.outputs[
        //   assignmentIndex
        // ].status = "reported";
        tmpMyContractActivitiesState[
          contractIndex
        ].data.outputSummary.reported += 1;
        tmpMyContractActivitiesState[
          contractIndex
        ].data.outputSummary.pending -= 1;
      } else if (action.data.itemTypeId === 2) {
        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.indicators.findIndex(({ id }) => id === action.data.id);
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ] = {
          ...tmpMyContractActivitiesState[contractIndex].data.indicators[
            assignmentIndex
          ],
          status: "reported",
          submissionId: action.data.result.id,
          achievedTarget: action.data.result.achievedTarget,
          completed: action.data.result.completed,
          reportedMessage: action.data.result.message,
          reportedBy: action.data.result.createdBy,
          reportedOn: action.data.result.createdOn,
          fileName: action.data.result.fileName,
          fileFullPath: action.data.result.fileFullPath,
          firstName: action.data.result.firstName,
          lastName: action.data.result.lastName,
          submissions: [
            ...tmpMyContractActivitiesState[contractIndex].data.indicators[
              assignmentIndex
            ].submissions,
            { ...action.data.result, evaluation: null },
          ],
        };
        tmpMyContractActivitiesState[
          contractIndex
        ].data.indicatorSummary.reported += 1;
        tmpMyContractActivitiesState[
          contractIndex
        ].data.indicatorSummary.pending -= 1;
      } else {
        const quarterIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.quarters.findIndex(
          ({ quarterId }) => quarterId === action.data.assignment.quarterId
        );

        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.quarters[quarterIndex].activities.findIndex(
          ({ id }) => id === action.data.assignment.id
        );

        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].status = "reported";
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex] = {
          ...tmpMyContractActivitiesState[contractIndex].data.quarters[
            quarterIndex
          ].activities[assignmentIndex],
          status: "reported",
          submissionId: action.data.result.id,
          achievedTarget: action.data.result.achievedTarget,
          completed: action.data.result.completed,
          reportedMessage: action.data.result.message,
          reportedBy: action.data.result.createdBy,
          reportedOn: action.data.result.createdOn,
          fileName: action.data.result.fileName,
          fileFullPath: action.data.result.fileFullPath,
          firstName: action.data.result.firstName,
          lastName: action.data.result.lastName,
          submissions: [
            ...tmpMyContractActivitiesState[contractIndex].data.quarters[
              quarterIndex
            ].activities[assignmentIndex].submissions,
            { ...action.data.result, evaluation: null },
          ],
        };
        tmpMyContractActivitiesState[
          contractIndex
        ].data.quarterSummary.reported += 1;
        tmpMyContractActivitiesState[
          contractIndex
        ].data.quarterSummary.pending -= 1;
      }

      // const submissions = activities[activityIndex].submissions || [];

      // submissions.push(action.data.reported);

      return tmpMyContractActivitiesState;
    }
    case types.REMOVE_ITEM_SUBMISSION: {
      const tmpMyContractActivitiesState = [...myContractActivitiesState];
      const contractIndex = tmpMyContractActivitiesState.findIndex(
        ({ contractId }) => contractId === action.data.assignment.contractId
      );
      tmpMyContractActivitiesState[contractIndex].data.summary.reported -= 1;
      // for output
      if (action.data.assignment.itemTypeId === 1) {
        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.outputs.findIndex(({ id }) => id === action.data.assignment.id);
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].status = "pending";
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].evalReportAccepted = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].qualityName = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].qualityValue = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].evaluatedOn = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].evalDescription = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].evalFirstName = null;
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].evalLastName = null;
        // remove evaluation to submission
        const newSubmissions = tmpMyContractActivitiesState[
          contractIndex
        ].data.outputs[assignmentIndex].submissions.filter(
          ({ id }) => id !== action.data.assignment.submissionId
        );
        tmpMyContractActivitiesState[contractIndex].data.outputs[
          assignmentIndex
        ].submissions = newSubmissions;

        tmpMyContractActivitiesState[
          contractIndex
        ].data.outputSummary.reported -= 1;
      } else if (action.data.assignment.itemTypeId === 2) {
        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.indicators.findIndex(
          ({ id }) => id === action.data.assignment.id
        );
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].status = "pending";
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].evalReportAccepted = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].qualityName = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].qualityValue = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].evaluatedOn = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].evalDescription = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].evalFirstName = null;
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].evalLastName = null;
        tmpMyContractActivitiesState[
          contractIndex
        ].data.indicatorSummary.reported -= 1;
        // remove evaluation to submission
        const newSubmissions = tmpMyContractActivitiesState[
          contractIndex
        ].data.indicators[assignmentIndex].submissions.filter(
          ({ id }) => id !== action.data.assignment.submissionId
        );
        tmpMyContractActivitiesState[contractIndex].data.indicators[
          assignmentIndex
        ].submissions.submissions = newSubmissions.length
          ? newSubmissions
          : null;
      } else {
        const quarterIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.quarters.findIndex(
          ({ quarterId }) => quarterId === action.data.assignment.quarterId
        );

        const assignmentIndex = tmpMyContractActivitiesState[
          contractIndex
        ].data.quarters[quarterIndex].activities.findIndex(
          ({ id }) => id === action.data.assignment.id
        );

        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].status = "pending";

        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].evalReportAccepted = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].qualityName = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].qualityValue = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].evaluatedOn = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].evalDescription = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].evalFirstName = null;
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].evalLastName = null;

        tmpMyContractActivitiesState[
          contractIndex
        ].data.quarterSummary.reported -= 1;

        // remove evaluation to submission
        const newSubmissions = tmpMyContractActivitiesState[
          contractIndex
        ].data.quarters[quarterIndex].activities[
          assignmentIndex
        ].submissions.filter(
          ({ id }) => id !== action.data.assignment.submissionId
        );
        tmpMyContractActivitiesState[contractIndex].data.quarters[
          quarterIndex
        ].activities[assignmentIndex].submissions.submissions =
          newSubmissions.length ? newSubmissions : null;
      }
      return tmpMyContractActivitiesState;
    }
    case types.CLEAN_STATE:
    case types.REMOVE_ACTION_PLAN:
      return defaultState.myContractActivities;
    default:
      return myContractActivitiesState;
  }
};

const submissions = (submissionsState = defaultState.submissions, action) => {
  switch (action.type) {
    case types.SET_SUBMISSIONS:
      return action.data;

    case types.DELETE_SUBMISSION: {
      const tmpSubmissionsState = [...submissionsState];
      const index = tmpSubmissionsState.findIndex(({ id }) => id === action.id);
      tmpSubmissionsState.splice(index, 1);

      return tmpSubmissionsState;
    }

    case types.CLEAN_STATE:
      return defaultState.submissions;

    default:
      return submissionsState;
  }
};

const rbmCompetencies = (
  competenceState = defaultState.rbmCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_COMPETENCIES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.rbmCompetencies;
    default:
      return competenceState;
  }
};

const contractCompetencyEvaluations = (
  competencyEvaluationState = defaultState.contractCompetencyEvaluations,
  action
) => {
  switch (action.type) {
    case types.SET_CONTRACT_COMPETENCY_EVALUATIONS:
      return [...competencyEvaluationState, ...action.data];
    case types.ADD_CONTRACT_COMPETENCY_EVALUATION: {
      const tmpCompetencyEvaluationState = [...competencyEvaluationState];
      const index = tmpCompetencyEvaluationState.findIndex(
        ({ id }) => id === action.data.id
      );
      if (index < 0) {
        tmpCompetencyEvaluationState.push(action.data);
      } else {
        tmpCompetencyEvaluationState[index] = action.data;
      }
      return tmpCompetencyEvaluationState;
    }
    case types.CLEAN_STATE:
      return defaultState.contractCompetencyEvaluations;
    default:
      return competencyEvaluationState;
  }
};

const subordinatePendingReports = (
  subordinatePendingReportsState = defaultState.subordinatePendingReports,
  action
) => {
  switch (action.type) {
    case types.SET_SUBORDINATE_PENDING_REPORTS: {
      if (!!action.data) {
        return action.data;
      }
      return defaultState.subordinatePendingReports;
    }
    case types.CLEAN_STATE:
      return defaultState.subordinatePendingReports;
    default:
      return subordinatePendingReportsState;
  }
};

export default {
  outputs,
  indicators,
  unitIndicators,
  selectedIndicator,
  indicatorActivities,
  selectedActivity,
  myActivities,
  subordinates,
  supervisors,
  technicalHeads,
  fiscalYears,
  selectedFiscalYear,
  userEntities,
  selectedUserEntity,
  institutionsEvaluations,
  unitsEvaluations,
  employeesEvaluations,
  indicatorAssignments,
  contractItems,
  subordinateContracts,
  myContractActivities,
  myContracts,
  submissions,
  rbmCompetencies,
  contractCompetencyEvaluations,
  subordinatePendingReports,
};
