import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {hasPermissions([
          "IS_HR",
          "IS_CBM",
          "IS_HEAD_OF_INSTITUTION",
          "IS_DOCUMENT_VERIFIER",
        ]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/exit/exit-requests"
              className={` px-1 rounded   ${
                location.pathname.includes("/exit/exit-requests")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">All Requests</span>
            </Link>
          </>
        )}

        {hasPermissions(["IS_BLACKLIST_MANAGER"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/exit/blacklist"
              className={`  px-1 rounded  ${
                location.pathname.includes("/exit/blacklist") ? "active" : " "
              }`}
            >
              <span className="text-truncate  menu-item">Black Lists</span>
            </Link>
          </>
        )}

        {hasPermissions(["IS_BLACKLIST_MANAGER"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/exit/rehabilitations"
              className={`  px-1 rounded  ${
                location.pathname.includes("/exit/rehabilitations")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Rehabilitations</span>
            </Link>
          </>
        )}
        {hasPermissions(["IS_HR", "IS_CBM"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/exit/dashboard"
              className={` px-1 rounded   ${
                location.pathname.includes("/exit/dashboard")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Dashboard</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Breadcrumb;
