import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getEntityAnnualLeavePlans,
  getUnitAnnualLeavePlan,
  confirmAllLeavePlans,
} from "../../../store/leave/actions";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { GanttOriginal, ViewMode } from "react-gantt-chart";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const InstitutionLPlan = (props) => {
  const { loading, getEntityAnnualLeavePlans, confirmAllLeavePlans } = props;
  const [loadingLeavePlans, setLoadingLeaves] = useState(true);
  const [employeeLeavePlans, setEmployeeLeavePlans] = useState([]);
  const [view, setView] = useState(ViewMode.Month);
  const [acceptAll, setAcceptAll] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    if (!employeeLeavePlans.length) {
      getEntityAnnualLeavePlans((status, data) => {
        setLoadingLeaves(status);
        if (!status && !!data[0]) {
          setEmployeeLeavePlans(data);
        }
      });
    }
  }, []);

  const handleAcceptAll = () => {
    confirmAllLeavePlans((status, res) => {
      setIsAccepting(status);
      if (!status && !!res) {
        setAcceptAll(false);
        getEntityAnnualLeavePlans((status, data) => {
          setLoadingLeaves(status);
          if (!status && !!data[0]) {
            setEmployeeLeavePlans(data);
          }
        });
      }
    });
  };

  return (
    <div>
      <div className="row w-100 my-3 pl-4">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <ViewSwitcher
              onViewModeChange={(viewMode) => setView(viewMode)}
              selectedView={view}
            />

            <div className="d-flex align-items-center justify-content-between">
              <IconButton
                aria-label="refresh list of annual level"
                onClick={() => {
                  getEntityAnnualLeavePlans((status, data) => {
                    setLoadingLeaves(status);
                    if (!status && !!data[0]) {
                      setEmployeeLeavePlans(data);
                    }
                  });
                }}
                disabled={loadingLeavePlans || loading}
              >
                <CachedRoundedIcon />
              </IconButton>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="ml-2"
                onClick={() => setAcceptAll(true)}
                disabled={
                  loadingLeavePlans ||
                  loading ||
                  isAccepting ||
                  !employeeLeavePlans.some((plan) => plan.statusId === 10)
                }
              >
                Approve All
              </Button>
            </div>
          </div>

          {!!employeeLeavePlans.length && (
            <GanttOriginal
              tasks={employeeLeavePlans.map((plan) => ({
                type: "project",
                id: plan.id,
                name: plan.employeeNames,
                start: new Date(plan.startDate),
                end: new Date(plan.endDate),
                progress: plan.progress,
                // hideChildren: false,
              }))}
              viewMode={view}
              columnWidth={200}
              // ganttHeight={500}
              loading={loadingLeavePlans}
            />
          )}

          {!loadingLeavePlans && !employeeLeavePlans.length && (
            <div className="text-center my-4 py-4">No data found</div>
          )}
        </div>
      </div>

      <ConfirmationDialog
        noPreFormat={true}
        confirmationDialog={acceptAll}
        message={
          <>
            Are you sure you want to approve all accepted plans!
            <br />
            <div
              className="alert alert-warning mt-2 px-2 py-1"
              role="alert"
              style={{ fontSize: "13px" }}
            >
              <strong>This action cannot be undone.</strong> Once approved, all
              accepted plans will be finalized and cannot be reversed.
            </div>
          </>
        }
        setConfirmationDialog={setAcceptAll}
        onYes={handleAcceptAll}
        isLoading={isAccepting}
        disabled={isAccepting || loadingLeavePlans || loading}
      />

      <div
        className={`page-loading-void position-fixed ${
          loadingLeavePlans && !!employeeLeavePlans[0] ? "d-block" : "d-none"
        }`}
      >
        {loadingLeavePlans && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  loading,
  user,
  selectedFiscalYear,
  myUnitLeavePlans,
}) => {
  return {
    loading,
    user,
    selectedFiscalYear,
    myUnitLeavePlans,
  };
};
export default connect(mapStateToProps, {
  getUnitAnnualLeavePlan,
  getEntityAnnualLeavePlans,
  confirmAllLeavePlans,
})(InstitutionLPlan);

export const ViewSwitcher = ({ onViewModeChange, selectedView }) => {
  return (
    <ButtonGroup size="small" aria-label="Gantt chart view controller">
      <Button
        onClick={() => onViewModeChange(ViewMode.Day)}
        variant={selectedView === ViewMode.Day ? "contained" : "outlined"}
      >
        Day
      </Button>
      <Button
        onClick={() => onViewModeChange(ViewMode.Week)}
        variant={selectedView === ViewMode.Week ? "contained" : "outlined"}
      >
        Week
      </Button>
      <Button
        onClick={() => onViewModeChange(ViewMode.Month)}
        variant={selectedView === ViewMode.Month ? "contained" : "outlined"}
      >
        Month
      </Button>
    </ButtonGroup>
  );
};
