import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const DisciplinaryRequestExplanation = (props) => {
  const {
    recipientName,
    recipientEmail,
    recipientPhone,
    faults,
    institutionName,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
    subTitle,
    locationName=""
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    {locationName || 'Kigali'}, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                  
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                  
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              C/O: {institutionName.toUpperCase()}”
            </p>
          </div>
          <div className="content2 mb-2">
            {/* <!-- Letter content goes here --> */}
            <p>{subTitle},</p>
            <p style={{ fontWeight: "bold" }}>Re: Request for explanation</p>
            <p>
              Pursuant to Presidential Order N°. 65/01 of 04/03/2014 determining
              modalities of imposing disciplinary sanctions to Public Servants
              in its article 10;
            </p>
            <p>
              Having noted that you alleged to commit the following
              administrative fault(s): <br />
              <ul>
                {faults.map((fault, index) => (
                  <li key={index}>{fault.faultName}</li>
                ))}
              </ul>
            </p>
            <p>
              I request you to provide explanation in writing for the above
              mentioned faults that you alleged to commit. <br /> The requested
              explanation must be given not exceeding 5 working days from the
              date of receiving this letter.
            </p>
            <p>Sincerely,</p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData?.length && (
                <>
                  {ccData?.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc?.ccName || cc.ccName.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData?.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisciplinaryRequestExplanation;
