export const defaultState = {
  tempPayrolls: [],
  archivedPayrolls: [],
  costCenters: [],
  // employeeGroups: [],
  payrollGroups: [],
  payrollTypes: [],
  payrollDeclarations: {},

  approvalPayrolls: [],
  sourceOfFunds: [],
  // arrearTypes: [],
  payrollPreviewed: null,
  allowances: [],
  systemAllowances: [],
  creditors: [],
  basePayrollComponents: [],
  calculationMethods: [],
  payHistories: [],
  currencies: [],
  payrollDetails: {},
  payrollDashboardData: {},
  rssbDeclarationError: "",
  payrollSupportingDocuments: [],
  supportingDocumentTypes: [],
  employeeWithholdRequests: [],
  noOfEmployeeWithholdRequests: 0,
  totalArchivedPayrolls: 0,
  currentArchivedPayrollPage: 1,
  performanceBonusScores: [],
  employeeAnnualSalaries: [],
  pbfIndexGroup: [],
  employeePbfSettings: [],

  individualSupportingDocuments: [],
  changeReasons: [],
};
