import React from "react";
import { IconButton } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import CustomProgressLine from "./CustomProgressLine";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const ContractReportingCard = ({
  title,
  items = 0,
  overdue,
  reported,
  scored,
  pending,
  rejected,
  children,
  progress,
  onDownload,
  onDelete,
  onCancel,
  isActive,
  canCancel,
  isForMonitoring,
}) => {
  const { hasPermissions } = useRequiredAuth();

  return (
    <div className={`card elevated service-card valid mb-2 mt-1`}>
      <div
        className={`py-4 rbm-card-header d-flex justify-content-between`}
        style={{ padding: "1.5rem 1.2rem" }}
      >
        <div className="d-flex align-items-center text-uppercase flex-wrap">
          <ViewListIcon />
          <div style={{ minWidth: "70px" }}>{title}</div>
          <span
            className="ml-2 badge badge-secondary text-dark text-left text-uppercase"
            style={{ minWidth: "90px", backgroundColor: "#ebedef" }}
          >
            <span
              className="badge badge-light bg-info text-light"
              style={{
                fontSize: "12px",
              }}
            >
              {pending}
            </span>{" "}
            Ontrack
          </span>
          <span
            className="ml-2 badge badge-warning text-left text-uppercase"
            style={{ minWidth: "90px", backgroundColor: "#fbf0da" }}
          >
            <span
              className="badge badge-light bg-warning text-light"
              style={{ fontSize: "12px" }}
            >
              {overdue}
            </span>{" "}
            Overdue
          </span>

          <span
            className="ml-2 badge badge-info text-dark text-uppercase"
            style={{ minWidth: "90px", backgroundColor: "#def1f7" }}
          >
            <span
              className="badge badge-light bg-info text-light"
              style={{ fontSize: "12px" }}
            >
              {reported}
            </span>{" "}
            Reported
          </span>
          <span
            className="ml-2 badge badge-success text-left text-dark text-uppercase"
            style={{ minWidth: "90px", backgroundColor: "#d6f0e0" }}
          >
            <span
              className="badge badge-light bg-success text-light"
              style={{ fontSize: "12px" }}
            >
              {scored}
            </span>{" "}
            Scored
          </span>
          <span
            className="mx-2 badge badge-danger text-left text-dark text-uppercase"
            style={{ minWidth: "90px", backgroundColor: "#f9e1e5" }}
          >
            <span
              className="badge badge-light bg-danger text-light"
              style={{ fontSize: "12px" }}
            >
              {rejected}
            </span>{" "}
            Rejected
          </span>
        </div>
        <div className="d-flex align-items-center text-uppercase">
          <span
            style={{
              width:
                onDelete &&
                hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                !isForMonitoring &&
                items > scored
                  ? "200px"
                  : "250px",
            }}
          >
            <CustomProgressLine value={progress} />
          </span>
          {onDelete &&
            hasPermissions(["IS_HEAD_OF_UNIT", "CAN_ADD_NEW_OUTPUT"]) &&
            // !isForMonitoring &&
            items > scored &&
            isActive && (
              <span
                className="mx-2 btn btn-sm btn-danger py-0 text-uppercase d-flex align-items-center"
                onClick={onDelete}
              >
                <span className="material-icons-round">delete_forever</span>
                Items
              </span>
            )}
          {onDelete &&
            hasPermissions([
              "IS_HEAD_OF_UNIT",
              "CAN_ADD_NEW_OUTPUT",
              "IS_HR",
            ]) &&
            canCancel && (
              <span
                className="mx-2 btn btn-sm btn-outline-danger py-0 text-uppercase d-flex align-items-center"
                onClick={onCancel}
              >
                <span className="material-icons-round">error</span>
                Cancel
              </span>
            )}
          <IconButton
            disabled={!onDownload}
            color="primary"
            size="small"
            onClick={onDownload}
          >
            <i className="fas fa-download"></i>
          </IconButton>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default ContractReportingCard;
