const types = {
  SET_TRAINING_REQUESTS: "SET_TRAINING_REQUESTS",
  SET_REJECTED_TRAINING_REQUESTS: "SET_REJECTED_TRAINING_REQUESTS",
  SET_APPROVED_TRAINING_REQUESTS: "SET_APPROVED_TRAINING_REQUESTS",
  ADD_TRAINING_REQUEST: "ADD_TRAINING_REQUEST",
  SET_CD_PLANS: "SET_CD_PLANS",
  ADD_CD_PLAN: "ADD_CD_PLAN",
  DELETE_CD_PLAN: "DELETE_CD_PLAN",
  SET_TRAINING_REQUEST: "SET_TRAINING_REQUEST",
  DELETE_TRAINING_REQUEST: "DELETE_TRAINING_REQUEST",
  UPDATE_TRAINING_REQUEST: "UPDATE_TRAINING_REQUEST",
  UPDATE_TRAINING_ATTACHMENTS:"UPDATE_TRAINING_ATTACHMENTS",
  SET_OVERSIGHT_TRAINING_REQUESTS:"SET_OVERSIGHT_TRAINING_REQUESTS",
  SET_OVERSIGHT_TRAINING_SUMMARY:"SET_OVERSIGHT_TRAINING_SUMMARY",
  SET_REQUEST_TIMELINE:"SET_REQUEST_TIMELINE"
};

export default types;
