import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import {
  CardContent,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Certificates from "../components/Certificates";
import CertificateFields from "../components/CertificateFields";
import Competencies from "../components/Competencies";
import Degrees from "../components/Degrees";
import Qualifications from "../components/Qualifications";
import { useHistory, useLocation } from "react-router-dom";

const Common = (props) => {
  const [createCertificate, setCreateCertificate] = useState(false);
  const [createCertificateField, setCreateCertificateField] = useState(false);
  const [createCompetency, setCreateCompetency] = useState(false);
  const [createDegree, setCreateDegree] = useState(false);
  const [createQualification, setCreateQualification] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const location = useLocation();

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span className="text-uppercase">
                  {location.pathname.startsWith("/support")
                    ? "Support"
                    : "Admin"}{" "}
                </span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>Common</span>
              </Typography>

              {/* <Box sx={{ flexGrow: 1 }} /> */}
            </Toolbar>
          </AppBar>

          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                // padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <div className="row justify-content-between align-items-center pb-1">
                <div className="col-9 col-md-10">
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Common tabs"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Certificates" {...a11yProps(0)} />
                    <Tab label="Certificate fields" {...a11yProps(1)} />
                    <Tab label="Competencies" {...a11yProps(2)} />
                    <Tab label="Degrees" {...a11yProps(3)} />
                    <Tab label="Qualifications" {...a11yProps(4)} />
                  </Tabs>
                </div>
                <div className="col-3 col-md-2 text-right">
                  {tabValue === 0 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCertificate(true)}
                      title="Add Certificate"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Certificate</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 1 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCertificateField(true)}
                      className=" mx-md-0"
                      title="Add Certificate field"
                    >
                      <span className="d-none d-md-block">
                        Add Certificate field
                      </span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 2 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCompetency(true)}
                      className=" mx-md-0"
                      title="Add Competency"
                    >
                      <span className="d-none d-md-block">Add Competency</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 3 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateDegree(true)}
                      className=" mx-md-0"
                      title="Add Degree"
                    >
                      <span className="d-none d-md-block">Add Degree</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 4 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateQualification(true)}
                      className=" mx-md-0"
                      title="Add Qualification"
                    >
                      <span className="d-none d-md-block">
                        Add Qualification
                      </span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                </div>
              </div>

              <TabPanel value={tabValue} index={0}>
                <Certificates
                  createCertificate={createCertificate}
                  setCreateCertificate={setCreateCertificate}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CertificateFields
                  createCertificateField={createCertificateField}
                  setCreateCertificateField={setCreateCertificateField}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Competencies
                  createCompetency={createCompetency}
                  setCreateCompetency={setCreateCompetency}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Degrees
                  createDegree={createDegree}
                  setCreateDegree={setCreateDegree}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <Qualifications
                  createQualification={createQualification}
                  setCreateQualification={setCreateQualification}
                />
              </TabPanel>
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Common;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
