const types = {
  SET_PUBLIC_HOLIDAYS: "SET_PUBLIC_HOLIDAYS",
  SET_MY_LEAVE_PLANS: "SET_MY_LEAVE_PLANS",
  CANCEL_LEAVE_PLAN: "CANCEL_LEAVE_PLAN",
  SET_MY_UNIT_LEAVE_PLANS: "SET_MY_UNIT_LEAVE_PLANS",
  CHANGE_LEAVE_PLAN_STATUS: "CHANGE_LEAVE_PLAN_STATUS",
  SET_LEAVE_TYPES: "SET_LEAVE_TYPES",
  SET_OTHER_LEAVE_REQUESTS: "SET_OTHER_LEAVE_REQUESTS",
  CANCEL_OTHER_LEAVE_REQUEST: "CANCEL_OTHER_LEAVE_REQUEST",
  SET_MY_OTHER_LEAVE_REQUESTS: "SET_MY_OTHER_LEAVE_REQUESTS",
  CHANGE_OTHER_LEAVE_REQUEST_STATUS: "CHANGE_OTHER_LEAVE_REQUEST_STATUS",
  SET_EMPLOYEE_IN_LEAVE: "SET_EMPLOYEE_IN_LEAVE",
  SET_ANNUAL_LEAVE_EMPLOYEES: "SET_ANNUAL_LEAVE_EMPLOYEES",
  SET_WEEKLY_LEAVE_SUMMARY: "SET_WEEKLY_LEAVE_SUMMARY",
  ACCEPT_LEAVE_PLANS: "ACCEPT_LEAVE_PLANS",
  SET_UNIT_WITH_ANNUAL_LEAVE: "SET_UNIT_WITH_ANNUAL_LEAVE",
  SET_UNIT_WITH_OTHER_LEAVE: "SET_UNIT_WITH_OTHER_LEAVE",
};

export default types;
