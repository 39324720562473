import React, { useState, useEffect } from "react";
import moment from "moment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BackdropLoader from "../../common/components/BackdropLoader";
import { requestStatusColor } from "../../common/components/Utils";
import ModeIcon from "@mui/icons-material/Mode";
import RuleIcon from "@mui/icons-material/Rule";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import InfoIcon from "@mui/icons-material/Info";
import ViewCaseDialog from "./ViewCaseDialog";
const CaseCard = (props) => {
  const { user, request, setLoading1, loading1, getCase } = props;
  const { hasPermissions } = useRequiredAuth();

  const [showCaseDetail, setShowCaseDetail] = useState(false);
  // const overdueDays = (departureDate) => {
  //   var departureDate = moment(departureDate);
  //   var currentDate = moment();

  //   // Calculate the difference in days
  //   var overdueDays = currentDate.diff(departureDate, "days");

  //   // Make sure overdueDays is a positive number (if the current date is after the departure date)
  //   overdueDays = Math.max(0, overdueDays);
  //   return overdueDays;
  // };
  return (
    <>
      <BackdropLoader isLoading={loading1} />
      <tr
        disabled={loading1}
        className="appeal-hovered"
        onClick={() => {
          if (!loading1) getCase(request.id, setLoading1, setShowCaseDetail);
        }}
      >
        <td className="text-left">
          <strong> {request.id} </strong>
        </td>

        <td className="text-left text-truncate">
          {request.firstName} {request.lastName}
        </td>

        <td className="text-left text-truncate">
          {request.positionName} ({request.levelName}.{request.scaleName}){" "}
        </td>

        {/* <td className="text-left text-truncate">{request.unitName}</td> */}
        <td className="text-left text-truncate">
          {request.decisionLevel === 2 ? "Second Level" : "First Level"}
        </td>
        <td className="text-left text-truncate">
          {request?.caseSanctionName || "N/A"}
        </td>
        <td className="text-right">{moment(request.createdOn).format("ll")}</td>
        <td className="text-right">{moment(request.updatedOn).format("ll")}</td>

        <td className="text-right">
          <span
            className={`badge py-2 px-2 badge-${requestStatusColor(
              [
                {
                  className: "success",
                  statusIds: [11,13],
                },
                {
                  className: "danger",
                  statusIds: [4],
                },

                {
                  className: "primary",
                  statusIds: [3, 5, 8],
                },
                {
                  className: "warning",
                  statusIds: [2, 6, 9, 10,12],
                },

                {
                  className: "secondary",
                  statusIds: [1, 6, 7],
                },
              ],
              request.caseStatusId
            )}`}
          >
            {request.caseStatusName}
          </span>
        </td>

        <td className="text-right">
          {hasPermissions(["IS_HR"]) &&
          [1].includes(request.caseStatusId) &&
          request.decisionLevel === 2 ? (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-primary m-2"
              >
                <ModeIcon /> Modify
              </button>
            </>
          ) : hasPermissions(["IS_HR"]) &&
            [5, 8].includes(request.caseStatusId) ? (
            <>
              <button
                type="button"
                disabled={loading1}
                className="btn btn-primary m-2"
              >
                <InfoIcon /> Decision
              </button>
            </>
          ) : //  : hasPermissions(["IS_HEAD_OF_UNIT"]) &&
          //   [1].includes(request.caseStatusId) &&
          //   request.decisionLevel === 2 ? (
          //   <button
          //     type="button"
          //     disabled={loading1}
          //     className="btn btn-primary m-2"
          //   >
          //     <PreviewIcon /> Review
          //   </button>
          // )

          hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
            ([1,9].includes(request.caseStatusId) ||
              ([6].includes(request.caseStatusId) &&
                request.decisionLevel === 2)) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-primary m-2"
            >
              <RuleIcon /> Verify
            </button>
          ) : (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
              [1, 2, 10, 7].includes(request.caseStatusId)) ||
            (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
              [1, 6].includes(request.caseStatusId) &&
              user.selectedEntitySector.unitId === request.unitId) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-success m-2"
            >
              <TaskAltIcon /> Approve
            </button>
          ) : (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
              [12].includes(request.caseStatusId) &&
              request.decisionLevel === 2) ||
            (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
              [12].includes(request.caseStatusId) &&
              request.decisionLevel === 1 &&
              user.selectedEntitySector.unitId === request.unitId) ? (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-primary m-2"
            >
              <TaskAltIcon /> Confirm
            </button>
          ) : (
            <button
              type="button"
              disabled={loading1}
              className="btn btn-secondary m-2"
            >
              <OpenInNewIcon /> Open
            </button>
          )}
        </td>
      </tr>
      {showCaseDetail && (
        <ViewCaseDialog
          showCaseDetail={showCaseDetail}
          setShowCaseDetail={setShowCaseDetail}
        />
      )}
    </>
  );
};
export default CaseCard;
