export const defaultState = {
  publicHolidays: [],
  myLeavePlans: [],
  myUnitLeavePlans: [],
  leaveTypes: [],
  myOtherLeaveRequests: [],
  otherLeaveRequests: [],
  annualEmployeeLeaves: [],
  weeklyLeaveSummary: [],
  unitWithAnnualLeave: [],
  unitWithOtherLeave: [],
};
