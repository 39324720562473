import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  Card,
  CardContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { attachDocIds } from "../../../store/payroll/actions";

const PayrollDocIdsDialog = (props) => {
  const {
    loading,
    showDocIds,
    setShowDocIds,
    payroll,
    docIds,
    attachDocIds,
    viewType,
    type,
    getPayrollDocIds,
  } = props;

  const onClose = () => {
    setShowDocIds(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="md"
        open={showDocIds}
      >
        <DialogTitle className="text-primary pb-0 mb-0  text-center text-uppercase">
          RSSB Doc Ids
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {!!docIds.length && (
            <div>
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Period</th>
                    <th>Tin</th>
                    <th>Paye</th>
                    <th>Pension</th>
                    <th>Maternity</th>
                    <th>RAMA</th>
                    <th>CBHI</th>
                  </tr>
                </thead>

                <tbody>
                  {docIds.map((doc, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td
                        className={`${
                          doc.declarationPeriod ? "" : "text-danger"
                        }`}
                      >
                        {doc.declarationPeriod || "NULL"}
                        {doc.isMain && (
                          <span className="badge badge-primary ml-1">main</span>
                        )}
                      </td>
                      <td className={`${doc.tinNumber ? "" : "text-danger"}`}>
                        {doc.tinNumber || "NULL"}
                      </td>
                      <td className={`${doc.payeDocId ? "" : "text-danger"}`}>
                        {doc.payeDocId || "NULL"}
                      </td>
                      <td
                        className={`${doc.pensionDocId ? "" : "text-danger"}`}
                      >
                        {doc.pensionDocId || "NULL"}
                      </td>
                      <td
                        className={`${doc.maternityDocId ? "" : "text-danger"}`}
                      >
                        {doc.maternityDocId || "NULL"}
                      </td>
                      <td className={`${doc.ramaDocId ? "" : "text-danger"}`}>
                        {doc.ramaDocId || "NULL"}
                      </td>
                      <td className={`${doc.cbhiDocId ? "" : "text-danger"}`}>
                        {doc.cbhiDocId || "NULL"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <>
            {!docIds.length && (
              <div className="text-center">DocIds not attached</div>
            )}
          </>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          {viewType !== "admin" && (
            /*type !== "Archived" &&*/ <div className="text-center">
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => {
                  attachDocIds(payroll.id, getPayrollDocIds);
                }}
                startIcon={
                  <>
                    {!docIds.length && (
                      <span className="material-icons">attachment</span>
                    )}
                    {!!docIds.length && (
                      <span
                        className={`material-icons ${
                          loading ? "loader2" : ""
                        } `}
                      >
                        update
                      </span>
                    )}
                  </>
                }
              >
                {loading && !docIds.length
                  ? "WAIT..."
                  : !!docIds.length
                  ? "UPDATE DOC IDS"
                  : "ATTACH DOC IDS"}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  attachDocIds,
})(PayrollDocIdsDialog);
