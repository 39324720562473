export const defaultState = {
  loading: false,
  strongPassword: true,
  user: {},
  outputs: [],
  selectedExpectedResult: {},
  indicators: [],
  unitIndicators: [],
  selectedIndicator: {},
  indicatorActivities: [],
  selectedActivity: {},
  myActivities: [],
  subordinates: [],
  subordinateContracts: [],
  fiscalYears: [],
  selectedFiscalYear: {},
  userEntities: [],
  selectedUserEntity: {},
  units: [],
  institutionsEvaluations: [],
  unitsEvaluations: [],
  employeesEvaluations: [],
  indicatorAssignments: [],
  contractItems: [],
  supervisors: [],
  technicalHeads: [],
  myContracts: [],
  myContractActivities: [],

  submissions: [],
  rbmCompetencies: [],
  contractCompetencyEvaluations: [],
  subordinatePendingReports: {
    allReports: 0,
    forActiveReports: 0,
    forExitedReports: 0,
  },
};
