import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getFaultWithSanction } from "../../../store/discipline/actions";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../common/components/TableSkeleton";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CardContent,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const Fault = (props) => {
  const { getFaultWithSanction, faults } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [filteredFaults, setFilteredFaults] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSanction, setFilterSanction] = useState("");

  useEffect(() => {
    getFaultWithSanction();
  }, []);

  useEffect(() => {
    if (faults.length > 0) {
      setIsLoading(false);
      setFilteredFaults(faults);
      paginate(faults);
    }
  }, [faults]);

  useEffect(() => {
    paginate(filteredFaults);
  }, [itemOffset, itemsPerPage, filteredFaults]);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredFaults.length || 0;
    setItemOffset(newOffset);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterFaults(event.target.value, filterSanction);
  };

  const handleFilterChange = (event, value) => {
    setFilterSanction(value);
    filterFaults(searchTerm, value);
  };

  const filterFaults = (searchTerm, filterSanction) => {
    let filtered = faults.filter((fault) =>
      fault.faultName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filterSanction) {
      filtered = filtered.filter((fault) => fault.sanction === filterSanction);
    }
    setFilteredFaults(filtered);
    paginate(filtered);
  };

  // Get unique sanctions
  const sanctions = Array.from(new Set(faults.map((fault) => fault.sanction)));

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <Toolbar className="row">
            <div className="col-lg-3">
              <Typography variant="h6" noWrap component="div">
                <span>DISCIPLINE</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>
                <span style={{ color: "rgb(7, 142, 206)" }}>Faults</span>
              </Typography>
            </div>

            <div className="col-lg-5 d-flex align-items-end">
              <TextField
                label="Search by Fault Name"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                id="outlined-size-small"
                defaultValue="Small"
                size="small"
              />
            </div>

            <div className="col-lg-2">
              <Autocomplete
                id="size-small-standard"
                size="small"
                fullWidth
                options={sanctions}
                value={filterSanction}
                onChange={handleFilterChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Sanction"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="col-lg-2">
              <IconButton
                onClick={handleOpenDialog}
                style={{
                  ...ActiveButton,
                  float: "right",
                }}
                size="medium"
              >
                <AddIcon />
              </IconButton>
            </div>
          </Toolbar>
          <CardContent>
            <table className="table-striped table-hover table-sm fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>
                  <th scope="col">Fault Name</th>
                  <th scope="col">Sanction</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableSkeleton cols={3} />
                ) : (
                  <>
                    {paginatedItems.map((fault, index) => (
                      <tr key={index}>
                        <td>{fault.id}</td>
                        <td>{fault.faultName}</td>
                        <td>{fault.sanction}</td>
                        <td>
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>

            {!filteredFaults.length && !isLoading && (
              <NoRecordMessage
                isEmpty={!faults.length}
                title="No Sanctions found"
              />
            )}

            {!isLoading && (
              <div className="justify-content-center mt-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<span className="pr-1">Next</span>}
                  previousLabel={<span className="pl-1">Previous</span>}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            )}
          </CardContent>
        </div>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add Fault</DialogTitle>
        <DialogContent>
          <TextField label="Sanction Name" variant="outlined" fullWidth />
          <TextField label="Fault Name" variant="outlined" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCloseDialog} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  faults: state.faults,
});

const mapDispatchToProps = {
  getFaultWithSanction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Fault);
