import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import OverallResults from "./OverallResults";

const NpscOverallResultsDialog = (props) => {
  const { showOverallResult, setShowOverallResult, selectedAdvertisement } =
    props;

  const onClose = () => {
    setShowOverallResult(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showOverallResult}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">RECRUITMENT</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "6px" }}
              >
                chevron_right
              </span>
            </span>

            <span style={{ color: "rgb(7, 142, 206)" }}>Overall Results</span>
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "6px" }}
            >
              chevron_right
            </span>
            <span className="text-success">
              {selectedAdvertisement && selectedAdvertisement.positionName}
            </span>
            {selectedAdvertisement.adStatusId === 7 && (
              <span className="text-danger">(Advertisement Canceled)</span>
            )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "650px" }}>
          <OverallResults
            selectedAdvertisement={selectedAdvertisement}
            userType={""}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(NpscOverallResultsDialog);
