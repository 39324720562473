import {
  Tooltip,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
  Chip,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  CardContent,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import defaultProfile from "../../assets/default-profile.jpg";

import moment from "moment";

import {
  getSubmissions,
  getSubordinatePendingReports,
  removeSubmission,
  submitAssignmentReport,
} from "../../../store/rbm/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CustomProgressLine from "./CustomProgressLine";

import PersonIcon from "@mui/icons-material/Person";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import loadingGif from "../../assets/loading.gif";

const MyContractItemCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    activity,
    index,
    no,
    loading,
    submissions,
    submitAssignmentReport,
    removeSubmission,
    subordinatePendingReports,
    getSubmissions,
    getSubordinatePendingReports,
  } = props;
  const open = Boolean(anchorEl);

  useEffect(() => {
    if ((subordinatePendingReports?.allReports || 0) === 0) {
      getSubordinatePendingReports();
    }
  }, []);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showReportingDialog, setShowReportingDialog] = useState(false);

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  return (
    <>
      <Card
        className="mb-3 py-3 rounded hoverable-card"
        variant="outlined"
        style={{ backgroundColor: "#fff" }}
      >
        <CardHeader
          className="py-0"
          avatar={
            <Tooltip title="Score">
              <span
                style={{ fontSize: "12px", cursor: "default" }}
                className={`font-weight-bold  text-${
                  activity.score < 50
                    ? "danger"
                    : activity.score < 70
                    ? "warning"
                    : activity.score < 100
                    ? "info"
                    : "success"
                }`}
              >
                {activity.score.toFixed(2)} %
              </span>
            </Tooltip>
          }
          action={
            <>
              <Box>
                <IconButton
                  size="small"
                  className="ml-2"
                  onClick={handleOpenMenu}
                  aria-label="settings"
                >
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={activity.statusId !== 3}
                  >
                    <span className="material-icons">more_vert</span>
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>MENUS</MenuItem>

                  <span>
                    <Divider className="my-0" />
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        setShowReportingDialog(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <span className="material-icons mr-2">file_present</span>{" "}
                      Go to reports
                    </MenuItem>
                  </span>
                </Menu>
              </Box>
            </>
          }
          title={
            <>
              <span className="text-uppercase d-inline-flex align-items-center">
                <strong className="font-weight-light">
                  {" "}
                  {(index || no) + 1}:{activity.itemTypeName}
                </strong>
                <ArrowRightIcon className="text-primary" />
                {activity.name}{" "}
              </span>
            </>
          }
          subheader={
            <span>
              <span className="d-flex align-items-center justify-content-between flex-wrap">
                <span className="d-flex flex-wrap">
                  <Tooltip title="Weight">
                    <span
                      className="align-items-center"
                      style={{
                        cursor: "default",
                      }}
                    >
                      <span className="badge badge-pill text-dark px-0">
                        {weights.find(({ id }) => id === activity.weight)
                          ?.name || "n/a"}
                      </span>
                    </span>
                  </Tooltip>
                  <Tooltip title="Your target">
                    <span
                      className="align-items-center px-1 mr-2 text-info"
                      style={{
                        cursor: "default",
                      }}
                    >
                      Target: {activity.targetShare || "N/A"}
                      {activity.measurementUnit}
                      {(activity.achievedTarget > 0 ||
                        activity.targetShare > 0) && (
                        <span>
                          {" "}
                          |{" "}
                          <span
                            className={`badge badge-${
                              activity.achievedTarget ===
                                activity.targetShare && activity.targetShare > 0
                                ? "success"
                                : activity.achievedTarget > activity.targetShare
                                ? "success"
                                : "secondary"
                            }`}
                          >
                            {activity.achievedTarget} Achieved{" "}
                          </span>
                        </span>
                      )}
                    </span>
                  </Tooltip>
                  {!!activity.isRRAKey && (
                    <span
                      style={{ cursor: "default" }}
                      className="badge text-primary"
                    >
                      <i className="fas fa-key"></i> Key departmental priority
                    </span>
                  )}
                  <span className="d-flex align-items-center flex-wrap">
                    {activity?.assignments?.map((assignment) => (
                      <Tooltip
                        key={assignment.assignmentId}
                        title={`${assignment.lastName} ${assignment.firstName} assigned`}
                      >
                        <Chip
                          size="small"
                          className="border border-light mr-1"
                          avatar={
                            <Avatar
                              style={{ border: "1px solid #d1cbcb" }}
                              alt={assignment.firstName}
                              src={
                                assignment.profileImage
                                  ? `data:image/jpeg;base64,${assignment.profileImage}`
                                  : defaultProfile
                              }
                            />
                          }
                          label={assignment.firstName}
                          variant="outlined"
                        />
                      </Tooltip>
                    ))}
                  </span>
                </span>

                <span className="d-flex flex-wrap ">
                  <span className="text-info ">
                    Deadline: {moment(activity.endOn).format("ll")}
                    <span>
                      <span
                        className={`d-flex align-items-center justify-content-center badge mx-4 badge-${
                          activity.statusId === 1
                            ? "secondary"
                            : activity.statusId === 3
                            ? "primary"
                            : activity.statusId === 4
                            ? "success"
                            : "danger"
                        }`}
                      >
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons "
                        >
                          {activity.statusId === 1 && <>pending_actions</>}
                          {activity.statusId === 3 && <>file_present</>}
                          {activity.statusId === 4 && <>done_all</>}
                          {activity.statusId === 5 && <>close</>}
                          {activity.statusId === 2 && <>error</>}
                        </span>
                        <span style={{ fontSize: "10px" }}>
                          {" "}
                          {activity.statusName}
                          {activity.statusId === 1 && <>...</>}
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          }
        />
      </Card>

      {showReportingDialog && (
        <ReportAssignment
          assignment={activity}
          showDialog={showReportingDialog}
          setShowDialog={setShowReportingDialog}
          index={index}
          no={no}
          loading={loading}
          submissions={submissions}
          submitAssignmentReport={submitAssignmentReport}
          removeSubmission={removeSubmission}
          getSubmissions={getSubmissions}
          subordinatePendingReports={subordinatePendingReports}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  submissions,
  subordinatePendingReports,
}) => {
  return { user, loading, submissions, subordinatePendingReports };
};
export default connect(mapStateToProps, {
  submitAssignmentReport,
  removeSubmission,
  getSubmissions,
  getSubordinatePendingReports,
})(MyContractItemCard);

const ReportAssignment = (props) => {
  const {
    assignment,

    loading,
    submitAssignmentReport,
    removeSubmission,
    getSubmissions,
    subordinatePendingReports,

    showDialog,
    setShowDialog,
    index,
    no,
    submissions,
  } = props;

  const [reportFormData, setReportFormData] = useState({
    achievedTarget: "",
    completed: "",
    employeeMessage: "",
    file: null,
  });

  const [errors, setErrors] = useState({
    hasError: false,
    achievedTargetHasError: false,
    messageHasError: false,
    fileHasError: false,
    fileErrorMessge: "",
    achievedTargetErrorMessage: "",
    messageErrorMessge: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formValidator = () => {
    const error = {
      hasError: false,
      achievedTargetHasError: false,
      messageHasError: false,
      fileHasError: false,
      fileErrorMessge: "",
      achievedTargetErrorMessage: "",
      messageErrorMessge: "",
    };

    if (assignment.targetShare === 0 && reportFormData.completed === "") {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage =
        assignment.itemTypeId === 1
          ? "Please, select the level of output completion."
          : `Please, specify if ${
              assignment.itemTypeId === 2 ? "indicator" : "activity"
            } was completed or not`;
      error.hasError = true;
    }

    if (assignment.targetShare > 0 && reportFormData.achievedTarget === "") {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Achieved target is required`;
      error.hasError = true;
    }

    if (assignment.targetShare > 0 && +reportFormData.achievedTarget < 0) {
      error.achievedTargetHasError = true;
      error.achievedTargetErrorMessage = `Achieved target can't be negative`;
      error.hasError = true;
    }

    if (!reportFormData.employeeMessage) {
      error.messageHasError = true;
      error.messageErrorMessge = `Please, fill the message field`;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (showDialog)
      getSubmissions(
        {
          assignmentId: assignment.assignmentId,
          quarterId: assignment.quarterId,
          itemTypeId: assignment.itemTypeId,
        },
        setIsLoading
      );
  }, [showDialog]);

  const onSubmitReport = () => {
    if (!formValidator()) {
      const reportData = new FormData();
      reportData.append("itemId", assignment.id);
      reportData.append("itemTypeId", assignment.itemTypeId);
      reportData.append("contractId", assignment.contractId);
      reportData.append("assignmentId", assignment.assignmentId);
      reportData.append("achievedTarget", reportFormData.achievedTarget);
      reportData.append("employeeMessage", reportFormData.employeeMessage);
      reportData.append("completed", reportFormData.completed);
      reportData.append("file", reportFormData.file);

      submitAssignmentReport(reportData, setIsSubmitting, () =>
        setReportFormData({
          achievedTarget: "",
          completed: "",
          employeeMessage: "",
          file: null,
        })
      );
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <span>Assignment reports</span>
          <IconButton
            aria-label="close"
            onClick={() => setShowDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="mb-1 text-black" style={{ fontSize: "11px" }}>
            <span className="text-uppercase d-inline-flex align-items-center">
              <strong className="font-weight-light">
                {(index || no) + 1}:{assignment.itemTypeName}
              </strong>
              <ArrowRightIcon className="text-primary" />
              {assignment.name}{" "}
            </span>
            <div>
              <span className="text-info">
                Target share: {assignment.targetShare}{" "}
                {assignment.measurementUnit}
              </span>{" "}
              | Planned from:{" "}
              <span className="text-info">
                {moment(assignment.startOn).format("ll")}
              </span>{" "}
              To:{" "}
              <span className="text-info">
                {moment(assignment.endOn).format("ll")}
              </span>
              <strong
                style={{
                  position: "absolute",
                  right: 20,
                }}
                className="badge-info text-uppercase px-1"
              >
                quarter {assignment.quarterId}
              </strong>{" "}
            </div>
          </div>
        </DialogTitle>
        {isSubmitting && <LinearProgress />}
        <DialogContent
          dividers
          style={{
            minHeight: "52vh",
          }}
        >
          {!!subordinatePendingReports?.allReports &&
            assignment.itemTypeId !== 3 && (
              <Alert severity="warning" className="mb-2" icon={false}>
                {`Please note that you cannot submit your report if there are pending evaluations for reports submitted by your subordinates. Ensure that all ${
                  subordinatePendingReports?.forActiveReports > 0
                    ? `${subordinatePendingReports?.forActiveReports} report${
                        subordinatePendingReports?.forActiveReports > 1
                          ? "s"
                          : ""
                      } for active employee(s)`
                    : ""
                }${
                  subordinatePendingReports?.forActiveReports > 0 &&
                  subordinatePendingReports?.forExitedReports > 0
                    ? " and "
                    : ""
                }${
                  subordinatePendingReports?.forExitedReports > 0
                    ? `${subordinatePendingReports?.forExitedReports} report${
                        subordinatePendingReports?.forExitedReports > 1
                          ? "s"
                          : ""
                      } for exited employee(s)`
                    : ""
                } ${
                  subordinatePendingReports?.allReports > 1 ? "are" : "is"
                } assessed before proceeding with your submission.`}
              </Alert>
            )}
          {!!isLoading && (
            <div className="text-center mt-5">
              <img src={loadingGif} height="128" alt="loading" />
            </div>
          )}

          {!isLoading && (
            <>
              {(!!assignment.employeeTimePenalities ||
                !!assignment.employeeQualityPenalities) && (
                <div className="d-flex align-items-center justify-content-end">
                  {!!assignment.employeeTimePenalities && (
                    <span
                      className={`${
                        !!assignment.employeeQualityPenalities ? "mx-2" : ""
                      } badge badge-warning text-left text-uppercase`}
                      style={{ minWidth: "125px" }}
                    >
                      <span
                        className="badge badge-light bg-warning text-light"
                        style={{ fontSize: "12px" }}
                      >
                        {assignment.employeeTimePenalities}
                      </span>{" "}
                      Time penalty
                    </span>
                  )}
                  {!!assignment.employeeQualityPenalities && (
                    <span
                      className="badge badge-danger text-left text-uppercase"
                      style={{ minWidth: "125px" }}
                    >
                      <span
                        className="badge badge-light bg-danger text-light"
                        style={{ fontSize: "12px" }}
                      >
                        {assignment.employeeQualityPenalities}
                      </span>{" "}
                      Quality penalty
                    </span>
                  )}
                </div>
              )}

              {!!submissions.length &&
                submissions.map((submission) => (
                  <SubmissionCard
                    key={submission.id}
                    submission={submission}
                    removeSubmission={removeSubmission}
                    assignment={assignment}
                  />
                ))}

              {(!submissions.length ||
                (!!submissions &&
                  !!submissions[submissions.length - 1]?.evaluation &&
                  !submissions[submissions.length - 1]?.evaluation
                    ?.accepted)) && (
                <div className="border p-4 rounded">
                  {assignment.targetShare > 0 && assignment.itemTypeId !== 1 ? (
                    <div className="mb-2" style={{ fontSize: "13px" }}>
                      From{" "}
                      <span className="font-weight-bold text-info">
                        {assignment.targetShare} {assignment.measurementUnit}
                      </span>{" "}
                      target, how many did you achieve?
                    </div>
                  ) : assignment.itemTypeId !== 1 ? (
                    <div>
                      Is this{" "}
                      <span className="badge-info px-1">
                        {assignment.itemTypeName.toLowerCase()}
                      </span>{" "}
                      completed?
                    </div>
                  ) : (
                    <small className="mb-2">
                      At which level the annual target of this
                      <strong className="text-primary px-1">
                        {assignment.itemTypeName.toLowerCase()}
                      </strong>
                      was achievement?
                    </small>
                  )}

                  {assignment.targetShare > 0 && assignment.itemTypeId !== 1 ? (
                    <>
                      <TextField
                        error={errors.achievedTargetHasError}
                        helperText={errors.achievedTargetErrorMessage}
                        fullWidth
                        size="small"
                        type="number"
                        name="achievedTarget"
                        autoFocus={assignment.targetShare > 0}
                        label="Achieved Target"
                        variant="outlined"
                        placeholder="0"
                        className="mb-3 mt-1"
                        disabled={
                          loading ||
                          isLoading ||
                          isSubmitting ||
                          ((subordinatePendingReports?.allReports || 0) > 0 &&
                            assignment.itemTypeId !== 3)
                        }
                        value={reportFormData.achievedTarget}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        onBlur={() => {
                          if (reportFormData.achievedTarget === "")
                            setReportFormData({
                              ...reportFormData,
                              achievedTarget: "",
                            });
                        }}
                        onKeyDown={() => {
                          if (+reportFormData.achievedTarget === 0)
                            setReportFormData({
                              ...reportFormData,
                              achievedTarget: "",
                            });
                        }}
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            achievedTargetHasError: false,
                            achievedTargetErrorMessage: "",
                          });
                          const achievedTarget = e.target.value;
                          setReportFormData({
                            ...reportFormData,
                            achievedTarget: achievedTarget || "",
                          });
                        }}
                      />
                      {+reportFormData.achievedTarget === 0 &&
                        reportFormData.achievedTarget !== "" && (
                          <div
                            className="alert alert-warning py-0 mt-n3"
                            style={{ borderRadius: "4px" }}
                          >
                            <small style={{ fontSize: "12px" }}>
                              You will get zore(0%) once scored. Please, submit
                              at your own risk.
                            </small>
                          </div>
                        )}
                    </>
                  ) : assignment.itemTypeId !== 1 ? (
                    <>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={reportFormData.completed}
                        onChange={(e, v) => {
                          setErrors({
                            ...errors,
                            achievedTargetHasError: false,
                            achievedTargetErrorMessage: "",
                          });

                          setReportFormData({
                            ...reportFormData,
                            completed: v,
                          });
                        }}
                        disabled={
                          loading ||
                          isLoading ||
                          isSubmitting ||
                          ((subordinatePendingReports?.allReports || 0) > 0 &&
                            assignment.itemTypeId !== 3)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              sx={{
                                color: errors.achievedTargetHasError
                                  ? "red"
                                  : "",
                              }}
                            />
                          }
                          label="Yes"
                        />

                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              sx={{
                                color: errors.achievedTargetHasError
                                  ? "red"
                                  : "",
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                      {reportFormData.completed === "no" && (
                        <div
                          className="alert alert-warning py-0 mb-3 mt-n3"
                          style={{ borderRadius: "4px" }}
                        >
                          <small style={{ fontSize: "12px" }}>
                            You will get zore(0%) once scored. Please, submit at
                            your own risk.
                          </small>
                        </div>
                      )}
                      {errors.achievedTargetHasError && (
                        <small className="d-block mt-n3 mb-3 text-danger">
                          <small>{errors.achievedTargetErrorMessage}</small>
                        </small>
                      )}
                    </>
                  ) : (
                    <FormControl fullWidth size="small" className="mb-3 mt-2">
                      <InputLabel
                        id="demo-simple-select-label"
                        className={`${
                          errors.achievedTargetHasError ? "text-danger" : ""
                        }`}
                      >
                        Select the level of achievement
                      </InputLabel>
                      <Select
                        error={errors.achievedTargetHasError}
                        size="small"
                        value={reportFormData.achievedTarget}
                        label="Select the level of achievement"
                        onChange={(e) => {
                          setErrors({
                            ...errors,
                            achievedTargetHasError: false,
                            achievedTargetErrorMessage: "",
                          });
                          const achievedTarget = e.target.value;
                          setReportFormData({
                            ...reportFormData,
                            achievedTarget: achievedTarget,
                          });
                        }}
                        disabled={
                          loading ||
                          isLoading ||
                          isSubmitting ||
                          ((subordinatePendingReports?.allReports || 0) > 0 &&
                            assignment.itemTypeId !== 3)
                        }
                      >
                        <MenuItem value={100}>
                          1:Beyond expected{" "}
                          <small className="ml-1 badge-success px-1 border rounded">
                            ~100%
                          </small>
                        </MenuItem>
                        <MenuItem value={90}>
                          2:As expected{" "}
                          <small className="ml-1 badge-success px-1 border rounded">
                            ~90%
                          </small>
                        </MenuItem>
                        <MenuItem value={80}>
                          3:Great{" "}
                          <small className="ml-1 badge-info px-1 border rounded">
                            ~80%
                          </small>
                        </MenuItem>
                        <MenuItem value={70}>
                          4:Moderate{" "}
                          <small className="ml-1 badge-info px-1 border rounded">
                            ~70%
                          </small>
                        </MenuItem>
                        <MenuItem value={60}>
                          5:Fairly{" "}
                          <small className="ml-1 badge-warning px-1 border rounded">
                            ~60%
                          </small>
                        </MenuItem>
                        <MenuItem value={50}>
                          6:Few{" "}
                          <small className="ml-1 badge-warning px-1 border rounded">
                            ~50%
                          </small>
                        </MenuItem>
                        <MenuItem value={40}>
                          7:Poor{" "}
                          <small className="ml-1 badge-danger px-1 border rounded">
                            ~40%
                          </small>
                        </MenuItem>
                        <MenuItem value={20}>
                          8:Very poor{" "}
                          <small className="ml-1 badge-danger px-1 border rounded">
                            ~20%
                          </small>
                        </MenuItem>
                        <MenuItem value={0}>
                          9:Nothing done{" "}
                          <small className="ml-1 badge-danger px-1 border rounded">
                            ~00%
                          </small>
                        </MenuItem>
                      </Select>
                      <small
                        className="text-danger ml-2"
                        style={{ fontSize: "12px" }}
                      >
                        {errors.achievedTargetErrorMessage}
                      </small>
                      {+reportFormData.achievedTarget === 0 &&
                        reportFormData.achievedTarget !== "" && (
                          <div
                            className="alert alert-warning py-0 "
                            style={{ borderRadius: "4px" }}
                          >
                            <small style={{ fontSize: "12px" }}>
                              You will get zore(0%) once scored. Please, submit
                              at your own risk.
                            </small>
                          </div>
                        )}
                    </FormControl>
                  )}

                  <TextField
                    error={errors.messageHasError}
                    helperText={errors.messageErrorMessge}
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    autoFocus={assignment.targetShare === 0}
                    name="employeeMessage"
                    label="Message"
                    variant="outlined"
                    placeholder="Message"
                    className="mb-3"
                    value={reportFormData.employeeMessage}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const employeeMessage = e.target.value;

                      setErrors({
                        ...errors,
                        messageHasError: false,
                        messageErrorMessge: "",
                      });
                      setReportFormData({ ...reportFormData, employeeMessage });
                    }}
                    disabled={
                      loading ||
                      isLoading ||
                      isSubmitting ||
                      ((subordinatePendingReports?.allReports || 0) > 0 &&
                        assignment.itemTypeId !== 3)
                    }
                  />

                  <div>
                    <AttachFileIcon /> Supporting document{" "}
                    <small className="badge-secondary px-1 rounded">
                      (Optional)
                    </small>{" "}
                    <small
                      style={{ fontSize: "12px" }}
                      className="font-weight-bold text-danger"
                    >
                      (Only PDF is allowed)
                    </small>{" "}
                  </div>
                  <div className="custom-file ">
                    <input
                      type="file"
                      className={`custom-file-input ${
                        errors.fileHasError ? "is-invalid" : ""
                      }`}
                      id="supportingDocument"
                      accept="application/pdf"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        setErrors({
                          ...errors,
                          fileHasError: false,
                          fileErrorMessge: "",
                        });
                        setReportFormData({
                          ...reportFormData,
                          file: file || null,
                        });
                      }}
                      disabled={
                        loading ||
                        isLoading ||
                        isSubmitting ||
                        ((subordinatePendingReports?.allReports || 0) > 0 &&
                          assignment.itemTypeId !== 3)
                      }
                    />

                    <label
                      className="custom-file-label"
                      htmlFor="supportingDocument"
                    >
                      {reportFormData.file
                        ? reportFormData.file.name
                        : "Select file..."}
                    </label>
                    {errors.fileHasError && (
                      <small className="invalid-feedback mt-0">
                        <small>{errors.fileErrorMessge}</small>
                      </small>
                    )}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      onClick={onSubmitReport}
                      type="button"
                      className="btn btn-primary text-uppercase  px-5"
                      disabled={
                        loading ||
                        isLoading ||
                        isSubmitting ||
                        ((subordinatePendingReports?.allReports || 0) > 0 &&
                          assignment.itemTypeId !== 3)
                      }
                    >
                      {isSubmitting ? "Wait..." : "Submit"}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const SubmissionCard = ({ submission, removeSubmission, assignment }) => {
  const [confirmRemoveSubmission, setConfirmRemoveSubmission] = useState(false);
  const [viewSupportDoc, setViewSupportDoc] = useState(false);

  return (
    <>
      <div className="my-2 border rounded p-4">
        {/* report content */}
        <div className="row justify-content-start mb-3">
          <div className="col-11 text-secondary">
            <small
              className="d-flex align-items-center"
              style={{ fontSize: "12px" }}
            >
              <span className="d-none text-capitalize mr-1 d-md-inline-flex text-primary align-items-center">
                <PersonIcon />
                {submission.submitedBy?.lastName.toLowerCase()}{" "}
                {submission.submitedBy?.firstName.toLowerCase()}
              </span>{" "}
              {" /"} {moment(submission.createdOn).format("lll")}
            </small>
            <Card
              variant="outlined"
              className="rounded elevated pb-2"
              style={{ backgroundColor: "#f6f8fa" }}
            >
              <CardContent className=" pt-2 pb-2">
                <div>
                  <span className="d-flex align-items-center justify-content-between">
                    {assignment.targetShare > 0 && (
                      <span
                        className="badge badge-light mb-2 text-primary text-uppercase"
                        style={{ backgroundColor: "transparent" }}
                      >
                        {submission.achievedTarget} {assignment.measurementUnit}{" "}
                        reported
                      </span>
                    )}
                    {assignment.targetShare === 0 && (
                      <>
                        {submission.completed && (
                          <span
                            className="badge badge-light text-primary text-uppercase"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Reported as completed
                          </span>
                        )}
                        {!submission.completed && (
                          <span
                            className="badge badge-light text-danger text-uppercase"
                            style={{ backgroundColor: "transparent" }}
                          >
                            Reported as Not completed
                          </span>
                        )}
                      </>
                    )}

                    {!!submission.docReferenceId && (
                      <Button
                        onClick={() => setViewSupportDoc(true)}
                        size="small"
                        className="float-right"
                      >
                        <AttachFileIcon /> Supporting Document <OpenInNewIcon />
                      </Button>
                    )}
                    {!submission.docReferenceId && (
                      <span className="float-right  badge badge-warning mt-2">
                        No file attached
                      </span>
                    )}

                    <span>
                      <span
                        className={`d-inline-flex align-items-center justify-content-center badge text-${
                          submission.statusId === 1
                            ? "secondary"
                            : submission.statusId === 4
                            ? "success"
                            : "danger"
                        }`}
                        style={{ width: "100px" }}
                      >
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons"
                        >
                          {submission.statusId === 1 && <>pending_actions</>}
                          {submission.statusId === 4 && <>done_all</>}
                          {submission.statusId === 5 && <>close</>}
                          {submission.statusId === 2 && <>error</>}
                        </span>
                        <span
                          style={{ fontSize: "10px" }}
                          className="text-uppercase"
                        >
                          {submission.statusId !== 1 && (
                            <>{submission?.status?.name}</>
                          )}

                          {submission.statusId === 1 && <>Pending...</>}
                        </span>
                      </span>

                      <IconButton
                        disabled={!!submission.evaluation}
                        onClick={() => {
                          setConfirmRemoveSubmission(true);
                        }}
                        size="small"
                        color="error"
                        className="mr-n2 mt-n2 border bg-light "
                      >
                        <span className="material-icons ">delete_forever</span>
                      </IconButton>
                    </span>
                  </span>
                </div>

                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  className="mt-2 bg-white"
                  size="small"
                  multiline
                  rows={3}
                  name="name"
                  label="Employee message"
                  variant="outlined"
                  value={submission.employeeMessage}
                />
              </CardContent>
            </Card>
          </div>
          {/* {submission.statusId === 1 && (
          <div className="col-11">
            <div
              className="alert alert-light text-center py-0 mt-n0"
              style={{ borderRadius: "0px" }}
            >
              <small style={{ fontSize: "12px" }}>
                <marquee behavior="scroll" direction="left" scrollamount="4">
                  Penalties will be applied to the supervisor after{" "}
                  <strong>
                    {" "}
                    {submission.dueDays} day{submission.dueDays > 1 ? "s" : ""}
                  </strong>{" "}
                  without evaluation.{" "}
                  <span className="px-1 badge-warning">(Today included)</span>
                </marquee>
              </small>
            </div>
          </div>
        )} */}
        </div>

        {/* evaluation content */}
        {!!submission && !!submission.evaluation && (
          <div className="row justify-content-end mt-2 mb-3">
            <div className="col-11 text-right">
              <small
                className="d-flex align-items-center"
                style={{ fontSize: "12px" }}
              >
                <span className="d-none text-capitalize mr-1 d-md-inline-flex text-primary align-items-center">
                  <PersonIcon />
                  {submission.evaluation.evaluatedBy.lastName.toLowerCase()}{" "}
                  {submission.evaluation.evaluatedBy.firstName.toLowerCase()}
                </span>{" "}
                {" /"} {moment(submission.evaluation.createdOn).format("lll")}
              </small>

              <Card
                variant="outlined"
                className="rounded elevated"
                style={{ backgroundColor: "#f6f8fa" }}
              >
                <CardContent>
                  <div>
                    <div className="pb-2">
                      {submission.evaluation.accepted && (
                        <span className="float-left mt-n2 text-uppercase">
                          {assignment.targetShare > 0 && (
                            <span
                              className="badge badge-light mb-2 text-primary"
                              style={{ backgroundColor: "transparent" }}
                            >
                              {" "}
                              {submission.evaluation.achievedTarget}{" "}
                              {assignment.measurementUnit} accepted
                            </span>
                          )}
                          {assignment.targetShare === 0 && (
                            <>
                              {submission.evaluation.completed && (
                                <span
                                  className="badge badge-light text-success"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Completed accepted
                                </span>
                              )}
                              {!submission.evaluation.completed && (
                                <span
                                  className="badge badge-light text-danger"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  Not completed
                                </span>
                              )}
                            </>
                          )}

                          <span
                            className={`badge ml-2 badge-${
                              +submission.evaluation.qualityValue === 0.95
                                ? "success"
                                : +submission.evaluation.qualityValue === 0.85
                                ? "primary"
                                : +submission.evaluation.qualityValue === 0.75
                                ? "info"
                                : +submission.evaluation.qualityValue === 0.6
                                ? "warning"
                                : "danger"
                            }`}
                          >
                            {+submission.evaluation.qualityValue === 0.95
                              ? "Excellent"
                              : +submission.evaluation.qualityValue === 0.85
                              ? "Very Good"
                              : +submission.evaluation.qualityValue === 0.75
                              ? "Good"
                              : +submission.evaluation.qualityValue === 0.6
                              ? "Fair"
                              : "Poor"}{" "}
                          </span>
                        </span>
                      )}

                      {!submission.evaluation.accepted && (
                        <div className="pb-2">
                          <span className="float-left text-uppercase">
                            <span
                              className="badge badge-light mb-2 text-danger"
                              style={{ backgroundColor: "transparent" }}
                            >
                              {/* <i className="fas fa-times text-danger"></i>{" "} */}
                              Rejected
                            </span>
                          </span>
                        </div>
                      )}

                      <span className="float-right mt-n2 text-uppercase d-inline-flex mt-1 align-items-center">
                        {submission.evaluation.accepted && (
                          <>
                            <span
                              className="badge badge-light mr-2 text-primary"
                              style={{ backgroundColor: "transparent" }}
                            >
                              Score:{" "}
                            </span>
                            <span style={{ width: "150px" }} className="mr-1">
                              <CustomProgressLine
                                value={submission.evaluation.score}
                                height={16}
                              />
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <TextField
                    style={{ backgroundColor: "#f6f8fa" }}
                    fullWidth
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    className="mt-2"
                    rows={3}
                    name="name"
                    autoFocus
                    label="Supervisor comment"
                    variant="outlined"
                    value={submission.evaluation.supervisorComment}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>

      <ConfirmationDialog
        confirmationDialog={confirmRemoveSubmission}
        message={`Are you sure you want to remove this submission?`}
        setConfirmationDialog={setConfirmRemoveSubmission}
        onYes={() =>
          removeSubmission(
            {
              submissionId: submission.id,
              assignmentId: assignment.assignmentId,
              contractId: assignment.contractId,
              itemId: assignment.id,
              itemTypeId: assignment.itemTypeId,
            },
            setConfirmRemoveSubmission
          )
        }
      />

      {!!viewSupportDoc && (
        <PreviewPdfUrl
          showDocument={viewSupportDoc}
          setShowDocument={setViewSupportDoc}
          document={{
            name: `View Supporting Document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              submission.docReferenceId,
          }}
        />
      )}
    </>
  );
};
