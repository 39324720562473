import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  LinearProgress,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { requestWaitingListRequisition } from "../../../store/recruitment/actions";
import { getWaitingListPositionByEntitySector } from "../../../store/recruitment/actions";

import { showError } from "../../toastify";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RequestPositionOnWaitingListDialog = (props) => {
  const {
    loading,
    showAddRequisitionForm,
    setShowAddRequisitionForm,
    waitingListPositionByEntitySector,
    getWaitingListPositionByEntitySector,
    requestWaitingListRequisition,
  } = props;
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState(null);

  const [waitingListRequestForm, setWaitingListRequestForm] = useState({
    positionId: "",
    positionName: "",
    levelId: "",
    scaleId: "",
    numberOfPosts: "",
    reason: "",
  });

  useEffect(() => {
    getWaitingListPositionByEntitySector(setIsLoading);
    setWaitingListRequestForm({
      ...waitingListRequestForm,
      positionId: position?.positionId,
      positionName: position?.positionName,
      levelId: position?.levelId,
      scaleId: position?.scaleId,
      numberOfPosts:
        +position?.numberOnWaitingList <= +position?.numberOfVacantPosts
          ? position?.numberOnWaitingList
          : position?.numberOfVacantPosts,
    });
  }, [position]);

  const [errors, setErrors] = useState({
    positionHasError: false,
    reasonHasError: false,
    numberOfPostsHasError: false,
    isNumberOfPostsEqualToZero: false,
    numberOfVacantPostsIsLessThanEntered: false,
    examOptionHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      positionHasError: false,
      reasonHasError: false,
      numberOfPostsHasError: false,
      hasError: false,
      numberOfVacantPostsIsLessThanEntered: false,
      examOptionHasError: false,
    };

    if (!waitingListRequestForm.numberOfPosts) {
      error.numberOfPostsHasError = true;
      error.hasError = true;
    }
    if (waitingListRequestForm.numberOfPosts <= 0) {
      error.isNumberOfPostsEqualToZero = true;
      error.hasError = true;
    }
    if (waitingListRequestForm.numberOfPosts > position.numberOfVacantPosts) {
      error.numberOfVacantPostsIsLessThanEntered = true;
      error.hasError = true;
    }

    if (!waitingListRequestForm.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      // showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      requestWaitingListRequisition(waitingListRequestForm, onClose, history);
    }
  };

  const onClose = () => {
    setShowAddRequisitionForm(false);

    setWaitingListRequestForm({
      positionId: "",
      positionName: "",
      levelId: "",
      scaleId: "",
      numberOfPosts: "",
      reason: "",
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddRequisitionForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span className="text-dark">
              Request employee from waiting list{" "}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="px-3">
            {!!position?.numberOnWaitingList && (
              <div className="alert alert-info text-center" role="alert">
                <span className="badge badge-primary mr-1">
                  {position?.numberOnWaitingList}
                </span>
                Applicant{position?.numberOnWaitingList > 1 ? "s" : ""} for{" "}
                <strong>{position?.positionName}</strong>
                {position?.numberOnWaitingList > 1 ? " are " : " is "} available
                on the waiting list.
              </div>
            )}
            {isLoading && <LinearProgress />}
            <div className="row mt-3">
              <div className="col-12">
                <Autocomplete
                  disabled={isLoading}
                  size="small"
                  id="position"
                  defaultValue={null}
                  value={position || null}
                  options={waitingListPositionByEntitySector}
                  isOptionEqualToValue={(option, value) =>
                    option.positionId === value.positionId
                  }
                  onChange={(event, p) => {
                    setPosition(p);
                    // const competencyId = c.id;
                    // setFormData({ ...formData, competencyId });
                  }}
                  getOptionLabel={(option) => option.positionName}
                  renderOption={(props, el) => (
                    <Box component="li" {...props}>
                      {el.positionName}
                      <span className="ml-2 badge badge-secondary text-capitalize">
                        {el.unitName.toLowerCase()}
                      </span>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Position"
                      {...params}
                      label="Select Position"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {/* {errors.competencyHasError && (
                  <div className="text-danger mb-2">
                    Competency is required{" "}
                  </div>
                )} */}
              </div>

              <div className="col-12 mt-3">
                <TextField
                  disabled={!position}
                  fullWidth
                  size="small"
                  name="numberOfPosts"
                  label="Number of staff needed"
                  variant="outlined"
                  placeholder="Number of staff needed"
                  value={waitingListRequestForm.numberOfPosts}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      numberOfPostsHasError: false,
                      isNumberOfPostsEqualToZero: false,
                      numberOfVacantPostsIsLessThanEntered: false,
                    });

                    setWaitingListRequestForm({
                      ...waitingListRequestForm,
                      numberOfPosts: e.target.value,
                    });
                  }}
                />

                {errors.numberOfPostsHasError && (
                  <small className="text-danger">
                    Number of posts is required{" "}
                  </small>
                )}
                {!errors.numberOfPostsHasError &&
                  errors.isNumberOfPostsEqualToZero && (
                    <small className="text-danger">
                      Number of posts must greater than zero
                    </small>
                  )}
                {errors.numberOfVacantPostsIsLessThanEntered && (
                  <small className="text-danger">
                    Only {position.numberOfVacantPosts} vacant post
                    {position.numberOfVacantPosts > 1 ? "s are " : " is "}
                    available
                  </small>
                )}
              </div>

              <div className="col-12 mt-3">
                <TextField
                  disabled={!position}
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="reason"
                  autoFocus
                  label="Motivate your request"
                  variant="outlined"
                  placeholder="Motivate your request"
                  value={waitingListRequestForm.reason}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setErrors({ ...errors, reasonHasError: false });

                    setWaitingListRequestForm({
                      ...waitingListRequestForm,
                      reason: e.target.value,
                    });
                  }}
                />

                {errors.reasonHasError && (
                  <small className="text-danger">
                    Please, motivate your request
                  </small>
                )}
              </div>
            </div>

            <div className="mt-4 text-center">
              <Button
                className="btn-block"
                variant="contained"
                onClick={onSave}
                type="button"
                disabled={loading || isLoading}
              >
                {loading ? "Wait..." : "Submit"}
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  waitingListPositionByEntitySector,
}) => {
  return {
    loading,
    user,
    waitingListPositionByEntitySector,
  };
};
export default connect(mapStateToProps, {
  requestWaitingListRequisition,
  getWaitingListPositionByEntitySector,
})(RequestPositionOnWaitingListDialog);
