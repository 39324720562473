import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Badge,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import {
  getNPSCReports,
  getNPSCReport,
} from "../../../store/recruitment/actions";

// import ApproveExitDialog from "../components/ApproveExitDialog";
// import RejectExitDialog from "../components/RejectExitDialog";
import ReactPaginate from "react-paginate";
import moment from "moment";
import TableSkeleton from "../../common/components/TableSkeleton";
import ApproveNPSCReportDialog from "../components/ApproveNPSCReportDialog";

const NPSCRecruitmentReport = (props) => {
  const { user, npscReports, getNPSCReports, getNPSCReport, loading } =
    props;
  const [value, setValue] = useState(0);
  const [searchTeam, setSearchTeam] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [rejectRequestForm, setRejectRequestForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getNPSCReports(setIsLoader, user?.selectedEntitySector?.id);
  }, [user]);

  const [filteredRequests, setFilteredRequests] = useState([...npscReports]);

  useEffect(() => {
    const filtered = [...npscReports].filter(
      ({ statusName, positionName, entityName, createdOn }) =>
        (statusName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (positionName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (entityName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (createdOn || "").toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredRequests(filtered);
    paginate(filtered);
  }, [searchTeam, npscReports]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredRequests);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, npscReports, filteredRequests]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredRequests.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>NPSC REPORTS</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!npscReports.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>

          <CardContent
            style={{
              padding: ".1em",
              height: "auto",
              minHeight: "auto",
              overflow: "auto",
            }}
          >
            <div
              style={{
                marginLeft: "1em",
                marginRight: "1em",
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                height: "65vh",
                overflowY: "auto",
              }}
            >
              {/* <RejectExitDialog
                rejectRequestForm={rejectRequestForm}
                setRejectRequestForm={setRejectRequestForm}
                selectedRequest={selectedRequest}
                setSelectedRequest={setSelectedRequest}
              /> */}

              <CardContent className="pl-0 pt-0">
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">AdvertisementId</th>
                      <th scope="col">EntityName</th>
                      <th scope="col">Position</th>
                      <th scope="col">NumberOfPosts</th>
                      <th scope="col">RequestedOn</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {!!isLoader ? (
                    <TableSkeleton cols={6} />
                  ) : (
                    <tbody>
                      {paginatedItems.map((request, index) => (
                        <RequestItem
                          key={index}
                          request={request}
                          index={index}
                          setRejectRequestForm={setRejectRequestForm}
                          setSelectedRequest={setSelectedRequest}
                          getNPSCReport={getNPSCReport}
                          loading={loading}
                        />
                      ))}
                    </tbody>
                  )}
                </table>

                <div
                  className="justify-content-center"
                  style={{ overflow: "hidden" }}
                >
                  {!isLoader &&
                    !!paginatedItems.length &&
                    npscReports.length >= itemsPerPage && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                          <>
                            <span className="pr-1 d-none d-md-inline">
                              Next
                            </span>
                            <i className="fas fa-angle-double-right"></i>
                          </>
                        }
                        previousLabel={
                          <>
                            <i className="fas fa-angle-double-left"></i>
                            <span className="pl-1  d-none d-md-inline">
                              Previous
                            </span>
                          </>
                        }
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        pageCount={pageCount}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                        nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                        activeLinkClassName="active"
                      />
                    )}
                </div>

                {!filteredRequests.length && !isLoader && (
                  <div className="text-center">
                    <p className="lead">No Request found</p>
                  </div>
                )}
              </CardContent>
            </div>
          </CardContent>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, npscReports }) => {
  return { user, loading, loading, npscReports };
};
export default connect(mapStateToProps, {
  getNPSCReports,
  getNPSCReport,
})(NPSCRecruitmentReport);

const RequestItem = (props) => {
  const {
    index,
    loading,
    request,
    setRejectRequestForm,
    setSelectedRequest,
    getNPSCReport,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [approveRequestForm, setApproveRequestForm] = useState(false);
  const [showRequestAction, setShowRequestAction] = useState("View");
  const [showLetter, setsShowLetter] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <tr key={request.id}>
        <td>{request.advertisementId}</td>

        <td>{request.entityName}</td>
        <td>{request.positionName}</td>
        <td>{request.numberOfPosts}</td>

        <td>{moment(request.createdOn).format("LLL")}</td>
        <td>
          <span
            style={{ fontSize: "12px" }}
            className={`${
              +request.statusId === 7
                ? "badge badge-success w-100"
                
                :
                [2,4].includes(+request.statusId)
                ? "badge badge-primary w-100"
                
                :
                [5,6].includes(+request.statusId)
                ? "badge badge-danger w-100"
                :  [1,3].includes(+request.statusId)
                ? "badge badge-secondary w-100"
                : "badge badge-info w-100"
            }`}
          >
            {request.statusName}
          </span>
        </td>
        <td className="text-right">
          <>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              // onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "auto",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    getNPSCReport(
                      request.id,
                      setApproveRequestForm,
                      handleCloseMenu
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">info</span>
                  {loading ? "Wait.." : " View details"}
                </MenuItem>
                {/* {request.statusId === 2 && <Divider className="my-1" />} */}
              </span>
              {/* {request.statusId === 2 && (
                <span>
                  <MenuItem
                    disabled={loading || !request?.docReferenceId}
                    onClick={() => {
                      setsShowLetter(true);
                    }}
                    className="text-primary font-weight-light"
                  >
                    <span className="material-icons mr-1">open_in_new</span>
                    View approved letter
                  </MenuItem>
                </span>
              )} */}
            </Menu>
          </>
        </td>
      </tr>
      {/* {showLetter && request.statusId === 2 && !!request?.docReferenceId && (
        <PreviewPdfUrl
          showDocument={showLetter}
          setShowDocument={setsShowLetter}
          document={{
            name: `View ${request.title} Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              request?.docReferenceId,
          }}
        />
      )} */}

      {!!approveRequestForm && (
        <ApproveNPSCReportDialog
          approveRequestForm={approveRequestForm}
          setApproveRequestForm={setApproveRequestForm}
          selectedRequest={request}
        />
      )}
    </>
  );
};
