import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddRecruitmentMember from "./AddRecruitmentMember";
import {
  getRecruitmentMembers,
  removeRecruitmentMember,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const RecruitmentMember = (props) => {
  const {
    loading,
    showRecruitmentMembers,
    setShowRecruitmentMembers,
    selectedAdvertisement,
    recruitmentMembers,
    getRecruitmentMembers,
    removeRecruitmentMember,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showAddRecruitmentMember, setShowRecruitmentMember] = useState(false);
  const [selectedRecruitmentMember, setSelectedRecruitmentMember] =
    useState(null);
  const [recruitmentMemberId, setRecruitmentMemberId] = useState("0");
  const [confirmRemoveRecruitmentMember, setConfirmRemoveRecruitmentMember] =
    useState(false);
  const onClose = () => {
    setShowRecruitmentMembers(false);
  };

  useEffect(
    () => {
     getRecruitmentMembers(selectedAdvertisement.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAdvertisement]
  );

  return (
    <>
      <Dialog open={showRecruitmentMembers} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6" noWrap component="div" maxWidth={550}>
            {showAddRecruitmentMember ? (
              <span style={{ color: "rgb(7, 142, 206)" }}>
                {" "}
                <AddIcon />
                Add New Shortlister.{" "}
              </span>
            ) : (
              <>
                <span style={{ color: "rgb(7, 142, 206)" }}>
                  Shortlisters |{" "}
                </span>
                <span className="ml-2">
                  {selectedAdvertisement.positionName}
                </span>
              </>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            {showAddRecruitmentMember ? (
              <>
                <AddRecruitmentMember
                  selectedAdvertisement={selectedAdvertisement}
                  setShowRecruitmentMember={setShowRecruitmentMember}
                />
              </>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <Button

                    onClick={() => {
                      if(hasPermissions(["IS_CBM","IS_HR"])){
                        if(selectedAdvertisement.adStatusId===0){
                          setShowRecruitmentMember(true);
                        }else{
                          showError("Make sure that the advertisement process is currently in shortlisting");
                        }
                        
                      }else {
                        showError("Make sure that you are HR or CBM!");
                      }
                     
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Shortlister
                  </Button>
                </div>
                <div className="mt-3">
                  <PreLoader
                    isLoading={loading && !recruitmentMembers.length}
                  />

                  <NoRecordMessage
                    isEmpty={!loading && !recruitmentMembers.length}
                    title={"No Shortlister found!"}
                  />

                  {!loading && !!recruitmentMembers.length && (
                    <div className="mt-3">
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col" className="text-left">
                              Names
                            </th>
                            <th scope="col" className="text-left">
                              Email
                            </th>
                            <th scope="col" className="text-left">
                              PhoneNumber
                            </th>
                            <th scope="col" className="text-left">
                              IDNumber
                            </th>

                            <th scope="col" className="text-left">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {recruitmentMembers.map(
                            (recruitmentMember, index) => (
                              <tr key={index}>
                                <td className="text-left">
                                  <span style={{ fontSize: "10px" }}>
                                    {recruitmentMember.user.lastName}{" "}
                                    {recruitmentMember.user.firstName}
                                  </span>
                                </td>
                                <td className="text-left">
                                  <span style={{ fontSize: "10px" }}>
                                    {" "}
                                    {recruitmentMember.user.email}
                                  </span>
                                </td>
                                <td className="text-left">
                                  <span style={{ fontSize: "10px" }}>
                                    {recruitmentMember.user.phoneNumber}
                                  </span>
                                </td>
                                <td className="text-left">
                                  <span style={{ fontSize: "10px" }}>
                                    {recruitmentMember.user.idNumber}
                                  </span>
                                </td>

                                <td className="text-left">
                                  {" "}
                                  <button
                                    disabled={
                                     recruitmentMemberId ===  recruitmentMember.id || !hasPermissions(["IS_CBM","IS_HR"])
                                    }
                                    onClick={() => {
                                      if(hasPermissions(["IS_CBM","IS_HR"])){
                                        if(selectedAdvertisement.adStatusId===0){
                                          setSelectedRecruitmentMember(
                                            recruitmentMember
                                          );
                                          setConfirmRemoveRecruitmentMember(true);
                                        }else{
                                          showError("Make sure that the advertisement process is currently in shortlisting");
                                        }
                                        
                                      }else {
                                        showError("Make sure that you are  HR or CBM!");
                                      }

                                   
                                     
                                    }}
                                    className="btn btn-danger"
                                  >
                                    {recruitmentMemberId ===
                                    recruitmentMember.id ? (
                                      <>
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </>
                                    ) : (
                                      <RemoveIcon />
                                    )}
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {confirmRemoveRecruitmentMember && (
                  <ConfirmationDialog
                    confirmationDialog={confirmRemoveRecruitmentMember}
                    message={`Are you sure you want to remove ${selectedRecruitmentMember.user.lastName} from recruitment members?`}
                    setConfirmationDialog={setConfirmRemoveRecruitmentMember}
                    onYes={() => {
                      removeRecruitmentMember(
                        selectedRecruitmentMember,
                        setRecruitmentMemberId,
                        setConfirmRemoveRecruitmentMember,
                        setSelectedRecruitmentMember
                      );
                    }}
                  />
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, recruitmentMembers }) => {
  return {
    loading,
    recruitmentMembers,
  };
};
export default connect(mapStateToProps, {
  getRecruitmentMembers,
  removeRecruitmentMember,
})(RecruitmentMember);
