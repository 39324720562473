import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { Typography, Box, CircularProgress, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  getActiveCurrencies,
  updateCurrencyRate,
} from "../../../store/payroll/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const CurrencyConversion = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Current Conversion";
  });

  const { loading, currencies, getActiveCurrencies, updateCurrencyRate } =
    props;
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    field: null,
    value: null,
    prevValue: null,
  });

  useEffect(() => {
    if (!currencies.length) getActiveCurrencies();
  }, []);

  const handleRequest = () => {
    updateCurrencyRate(formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        if (!status && res) {
          setFormData({
            id: null,
            field: null,
            value: null,
            prevValue: null,
          });
          setShowAlert(false);
        }
      }
    });
  };

  return (
    <>
      <div
        className="elevated rounded p-3 m-4"
        style={{
          backgroundColor: "#fafafa",
          border: "1px solid #17a2b8",
          minHeight: "75vh",
        }}
      >
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar className="bg-light border">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>Currency Conversion</span>
            </Typography>
          </Toolbar>
        </AppBar>

        <div className="pt-2 mt-2">
          <table className="table table-striped table-hover table-sm fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th>#</th>
                <th>Currency</th>
                <th className="text-right">Rate</th>
                <th className="text-right">Diplomat Rate</th>
                <th className="text-right">Last Update</th>
              </tr>
            </thead>
            {loading && !currencies.length ? (
              <TableSkeleton cols={5} />
            ) : (
              <tbody>
                {currencies.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td className={`text-right`}>
                      <div className="d-flex justify-content-end align-items-center">
                        {formData.field === "rate" &&
                        formData.id === item.id ? (
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            style={{ width: "150px" }}
                            value={formData.value}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") setShowAlert(true);
                              if (e.key === "Escape")
                                setFormData({
                                  id: null,
                                  field: null,
                                  value: null,
                                  prevValue: null,
                                });
                            }}
                            autoFocus
                          />
                        ) : (
                          <div className="w-100 mr-1">{item.rate || "N/A"}</div>
                        )}
                        {formData.id === item.id &&
                        formData.field === "rate" &&
                        isSubmitting ? (
                          <div className={`float-right`}>
                            <CircularProgress color="info" size={15} />
                          </div>
                        ) : formData.id === item.id &&
                          formData.field === "rate" ? (
                          <div className={`d-flex align-items-center`}>
                            <IconButton
                              aria-label="currency rate"
                              size="small"
                              className="mr-2"
                              color="primary"
                              onClick={() => setShowAlert(true)}
                              disabled={
                                isSubmitting ||
                                item.id === "RWF" ||
                                formData.value === item.rate
                              }
                            >
                              <CheckRoundedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="cancel changes"
                              size="small"
                              onClick={() =>
                                setFormData({
                                  id: null,
                                  field: null,
                                  value: null,
                                  prevValue: null,
                                })
                              }
                              color="error"
                            >
                              <ClearRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        ) : (
                          <div className={`float-right`}>
                            <IconButton
                              aria-label="change currency rate"
                              size="small"
                              onClick={() =>
                                setFormData({
                                  id: item.id,
                                  field: "rate",
                                  value: item.rate,
                                  prevValue: item.rate,
                                })
                              }
                              disabled={
                                isSubmitting ||
                                item.id === "RWF" ||
                                !!formData.id
                              }
                            >
                              <EditRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className={`text-right`}
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        {formData.field === "rateDiplomat" &&
                        formData.id === item.id ? (
                          <input
                            type="text"
                            className="form-control form-control-sm text-center"
                            style={{ width: "150px" }}
                            value={formData.value}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") setShowAlert(true);
                              if (e.key === "Escape")
                                setFormData({
                                  id: null,
                                  field: null,
                                  value: null,
                                  prevValue: null,
                                });
                            }}
                            autoFocus
                          />
                        ) : (
                          <div className="w-100 mr-1">
                            {item.rateDiplomat || "N/A"}
                          </div>
                        )}
                        {formData.id === item.id &&
                        formData.field === "rateDiplomat" &&
                        isSubmitting ? (
                          <div className={`float-right`}>
                            <CircularProgress color="info" size={15} />
                          </div>
                        ) : formData.id === item.id &&
                          formData.field === "rateDiplomat" ? (
                          <div className={`d-flex align-items-center`}>
                            <IconButton
                              aria-label="currency rate diplomat"
                              size="small"
                              className="mr-2"
                              color="primary"
                              onClick={() => setShowAlert(true)}
                              disabled={
                                isSubmitting ||
                                item.id === "RWF" ||
                                formData.value === item.rateDiplomat
                              }
                            >
                              <CheckRoundedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              aria-label="cancel number of posts"
                              size="small"
                              onClick={() =>
                                setFormData({
                                  id: null,
                                  field: null,
                                  value: null,
                                  prevValue: null,
                                })
                              }
                              color="error"
                            >
                              <ClearRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        ) : (
                          <div className={`float-right`}>
                            <IconButton
                              aria-label="change number of posts"
                              size="small"
                              onClick={() =>
                                setFormData({
                                  id: item.id,
                                  field: "rateDiplomat",
                                  value: item.rateDiplomat,
                                  prevValue: item.rateDiplomat,
                                })
                              }
                              disabled={
                                isSubmitting ||
                                item.id === "RWF" ||
                                !!formData.id
                              }
                            >
                              <EditRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-right">
                      {moment(item.lastUpdate).format("lll")}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {!currencies.length && !loading && (
            <div className="jumbotron jumbotron-fluid text-center  mt-0">
              <div className="container py-5">
                <p className="lead">No record found</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {showAlert && !!formData.id && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={
            <p>
              Are you sure you want to update the currency exchange rate from{" "}
              <strong>{formData.prevValue}</strong> to{" "}
              <strong>{formData.value}</strong>?
            </p>
          }
          setConfirmationDialog={() => setShowAlert(false)}
          onYes={handleRequest}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, currencies }) => {
  return { user, loading, currencies };
};
export default connect(mapStateToProps, {
  getActiveCurrencies,
  updateCurrencyRate,
})(CurrencyConversion);
