import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { textCapitalized } from "../../common/components/Utils";
import moment from "moment";

const BlaclistDetailsDialog = (props) => {
  const {
    loading,
    blacklistDetailsForm,
    setBlacklistDetailsForm,
    selectedBlacklist,
  } = props;

  const onClose = () => {
    setBlacklistDetailsForm(false);
  };
  const onSave = () => {
    //approveRequest(blacklistDetailsForm, setBlacklistDetailsForm, onClose);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        //
        open={blacklistDetailsForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Blacklist Details Of @{" "}
            {selectedBlacklist && selectedBlacklist.lastName}{" "}
            {selectedBlacklist && selectedBlacklist.firstName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* employee info */}
          <div className="card service-card valid mb-2 mt-1">
            <div className="card-header" style={{ padding: "0.25rem 1.2rem" }}>
              # Employee
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                  <div className="row mt-0">
                    <div className="col-12">
                      <div
                        className="card"
                        style={{
                          backgroundColor: "#fff",
                          backgroundClip: "border-box",
                          border: "1px solid rgba(0,0,0,0.175)",
                          borderRadius: "0.5rem",
                          boxShadow:
                            " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                        }}
                      >
                        <div className="card-body text-left">
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="user-contact"
                                style={{ padding: "0px!important" }}
                              >
                                <div className="">
                                  <div>
                                    <p className="contact_p">
                                      Employee Id:{" "}
                                      <span className="contact_span">
                                        {selectedBlacklist &&
                                          selectedBlacklist.userId}
                                      </span>
                                    </p>
                                    <p className="contact_p">
                                      Names:{" "}
                                      <span className="contact_span">
                                        {selectedBlacklist && (
                                          <span className="ml-1">
                                            {textCapitalized(
                                              selectedBlacklist.lastName
                                            )}{" "}
                                            {textCapitalized(
                                              selectedBlacklist.firstName
                                            )}
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                    <p className="contact_p">
                                      DOB:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {moment(
                                            selectedBlacklist.dateOfBirth
                                          ).format("MMMM d, YYYY")}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      National Id:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.idNumber}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      Gender:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.genderId === "M"
                                            ? "Male"
                                            : "Female"}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      Civil Status:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.civilStatus}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div
                                className="user-contact"
                                style={{ padding: "0px!important" }}
                              >
                                <div className="">
                                  <div>
                                    <p className="contact_p">
                                      Father Names:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.fatherNames}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      Mother Names:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.motherNames}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      Institution:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.institutionName}
                                        </span>
                                      )}
                                    </p>
                                    <p className="contact_p">
                                      Position:{" "}
                                      {selectedBlacklist && (
                                        <span className="contact_span">
                                          {selectedBlacklist.positionName}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* blacklist details */}
          <div className="card service-card valid mb-2 mt-1">
            <div className="card-header" style={{ padding: "0.25rem 1.2rem" }}>
              # Blacklist
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                  <div className="row mt-0">
                    <div className="col-12">
                      <div
                        className="card"
                        style={{
                          backgroundColor: "#fff",
                          backgroundClip: "border-box",
                          border: "1px solid rgba(0,0,0,0.175)",
                          borderRadius: "0.5rem",
                          boxShadow:
                            " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                        }}
                      >
                        <div className="card-body text-left">
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="user-contact"
                                style={{ padding: "0px!important" }}
                              >
                                <div className="">
                                  <div>
                                    <p className="contact_p">
                                      Reason
                                      <br />
                                      <span className="contact_span">
                                        {selectedBlacklist &&
                                          selectedBlacklist.reason}
                                      </span>
                                    </p>
                                    <p className="contact_p">
                                      End date:{" "}
                                      <span className="contact_span">
                                        {selectedBlacklist &&
                                          moment(
                                            selectedBlacklist.willEndOn
                                          ).format("LL")}
                                      </span>
                                    </p>
                                    <p className="contact_p">
                                      Requested by:{" "}
                                      <span className="contact_span">
                                        {selectedBlacklist &&
                                          selectedBlacklist.requestedBy}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          {/* <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {})(BlaclistDetailsDialog);
