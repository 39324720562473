import {
  Tooltip,
  Card,
  Typography,
  CardHeader,
  IconButton,
  CircularProgress,
  Box,
  Menu,
  MenuItem,
  Divider,
  Chip,
  Avatar,
  Badge,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { deleteOutput, markOutputAsKey } from "../../../store/rbm/actions";
import types from "../../../store/rbm/action-types";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CurrencyFormat from "react-currency-format";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import IndicatorDialog from "./IndicatorDialog";
import defaultProfile from "../../assets/default-profile.jpg";
import OutputAssignmentForm from "./OutputAssignmentForm";
import OutputForm from "./OutputForm";
import CustomProgressLine from "./CustomProgressLine";
import KeyIcon from "@mui/icons-material/Key";
import KeyOffIcon from "@mui/icons-material/KeyOff";

const OutputCard = (props) => {
  const {
    user,
    output,
    index,
    deleteOutput,
    markOutputAsKey,
    loading,
    totalWeight,
    isForContract,
    setShowReport,
    isForMonitoring,
    keySet,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [score, setScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScore(output.score);
    }, 500);
  }, [output]);

  const { hasPermissions } = useRequiredAuth();

  const [showIndicatorDialog, setShowIndicatorDialog] = useState(false);

  const [showAssignmentForm, setShowAssignmentForm] = useState(false);

  const [outputFormOpen, setOutputFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [confirmMarkAsKey, setConfirmMarkAsKey] = useState(false);

  const openOutputForm = () => {
    setOutputFormOpen(true);
  };
  const closeOutputForm = () => {
    setOutputFormOpen(false);
    setIsEditing(false);
  };

  const location = useLocation();

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <Tooltip title="Progress">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    score < 50
                      ? "danger"
                      : score < 70
                      ? "warning"
                      : score < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={score}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      score < 50
                        ? "danger"
                        : score < 70
                        ? "warning"
                        : score < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${score.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          }
          action={
            <>
              <Box>
                <IconButton
                  size="small"
                  className="ml-2"
                  onClick={handleOpenMenu}
                  aria-label="settings"
                  disabled={isForMonitoring}
                >
                  <span className="material-icons">more_vert</span>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>MENUS</MenuItem>
                  <Divider className="my-1" />

                  {!isForContract &&
                    hasPermissions(["CAN_ADD_NEW_OUTPUT"]) &&
                    user?.selectedEntitySector?.id ===
                      "1203000001-090000000000000001-11" &&
                    !keySet /*|| !!output.isRRAKey*/ && (
                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setConfirmMarkAsKey(true);
                          }}
                          className={`text-${
                            !!output.isRRAKey ? "danger" : "primary"
                          } font-weight-bold`}
                        >
                          <span className="material-icons mr-2">
                            {!output.isRRAKey && <KeyIcon />}
                            {!!output.isRRAKey && <KeyOffIcon />}
                          </span>{" "}
                          Mark as {!!output.isRRAKey ? " not " : ""} key
                        </MenuItem>

                        <Divider className="my-1" />
                      </span>
                    )}
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      setShowIndicatorDialog(true);
                      handleCloseMenu();
                    }}
                    className="text-dark font-weight-light"
                  >
                    <span className="material-icons mr-2">launch</span>
                    Indicators
                  </MenuItem>

                  {!isForContract && hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                    <span>
                      <Divider className="my-1" />
                      <MenuItem
                        disabled={loading}
                        onClick={() => {
                          handleCloseMenu();
                          dispatch({
                            type: types.SET_SELECTED_OUTPUT,
                            data: output,
                          });
                          setIsEditing(true);
                          openOutputForm();
                        }}
                        className="text-dark font-weight-light"
                      >
                        <span className="material-icons mr-2">edit</span> Edit
                      </MenuItem>
                    </span>
                  )}

                  {isForContract && (
                    <span>
                      <Divider className="my-0" />
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          setShowReport(output);
                        }}
                        className="text-dark font-weight-light"
                      >
                        <Badge color="error" variant="dot" invisible>
                          <span className="material-icons mr-2">
                            file_present
                          </span>{" "}
                          Go to reports
                        </Badge>
                      </MenuItem>
                    </span>
                  )}

                  {!isForContract && hasPermissions(["IS_TECHNICAL_HEAD"]) && (
                    <span>
                      <Divider className="my-1" />
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          setShowAssignmentForm(true);
                        }}
                        className="text-dark font-weight-light"
                      >
                        <span className="material-icons mr-2">people</span>
                        Assign Output
                      </MenuItem>
                    </span>
                  )}

                  {!isForContract && hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                    <span>
                      <Divider className="my-1" />
                      <MenuItem
                        disabled={loading}
                        onClick={() => {
                          handleCloseMenu();
                          setConfirmationDialog(true);
                        }}
                        className="text-danger font-weight-light"
                      >
                        <span className="material-icons mr-2">delete</span>{" "}
                        Delete
                      </MenuItem>
                    </span>
                  )}
                </Menu>
              </Box>
            </>
          }
          title={
            <span className="text-uppercase">
              <strong>OUTPUT {output.no} : </strong> {output.name}{" "}
              {!!output.isSpecial && (
                <Chip
                  icon={
                    <span
                      className="material-icons-round text-info"
                      // style={{
                      //   fontSize: "14px",
                      // }}
                    >
                      stars
                    </span>
                  }
                  label="Special"
                  title="Special Output"
                  size="small"
                  variant="outlined"
                  color="info"
                />
              )}
            </span>
          }
          subheader={
            <span className="">
              <Tooltip title="Weight">
                <span
                  className="align-items-center mr-1"
                  style={{
                    cursor: "default",
                  }}
                >
                  <span className="badge badge-pill px-0">
                    {weights.find(({ id }) => id === output.weight)?.name ||
                      "N/A"}
                  </span>
                </span>
              </Tooltip>
              <Tooltip title="Budget">
                <span className="mr-2 text-info" style={{ cursor: "default" }}>
                  {" | "}
                  <i className="fas fa-money-check-alt ml-1 mr-1"></i>{" "}
                  <CurrencyFormat
                    value={output.budget}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"RWF "}
                  />
                </span>
              </Tooltip>

              {!isForContract && (
                <span className="d-inline-flex align-items-center flex-wrap ml-1">
                  {!output.assignmentId && (
                    <Tooltip title="Assign Output">
                      <button
                        onClick={() => {
                          if (hasPermissions(["CAN_ADD_NEW_OUTPUT"]))
                            setShowAssignmentForm(true);
                        }}
                        className="badge badge-warning mx-1 text-danger border "
                        type="button"
                      >
                        Not Assigned
                      </button>
                    </Tooltip>
                  )}

                  <span className="">
                    {!!output.assignmentId && (
                      <Tooltip
                        title={`${output.lastName} ${output.firstName} assigned`}
                      >
                        <Chip
                          size="small"
                          className="border border-light mr-1"
                          avatar={
                            <Avatar
                              style={{ border: "1px solid #d1cbcb" }}
                              alt={output.firstName}
                              src={
                                output.profileImage
                                  ? `data:image/jpeg;base64,${output.profileImage}`
                                  : defaultProfile
                              }
                            />
                          }
                          label={output.firstName}
                          variant="outlined"
                        />
                      </Tooltip>
                    )}
                    {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) &&
                      location.pathname !== "/rbm/my-unit" &&
                      !isForMonitoring && (
                        <Tooltip title={`Assign output`}>
                          <IconButton
                            color="primary"
                            size="small"
                            className="p-0 border "
                            onClick={() => {
                              setShowAssignmentForm(true);
                            }}
                          >
                            <span className="material-icons">add</span>
                          </IconButton>
                        </Tooltip>
                      )}

                    {!!output.isRRAKey && (
                      <span
                        style={{ cursor: "default" }}
                        className="badge text-primary ml-2"
                      >
                        <i className="fas fa-key"></i> Key departmental priority
                      </span>
                    )}
                  </span>
                </span>
              )}
              <span className="float-right text-center d-block mt-n4">
                <button
                  disabled={loading}
                  type="button"
                  className={`btn btn-link text-uppercase btn-sm d-flex align-items-center ${
                    output.numOfIndicators === 0 ? " text-danger" : "text-info"
                  }`}
                  onClick={() => {
                    setShowIndicatorDialog(true);
                  }}
                >
                  <span>
                    <Tooltip title="Go To Indicators">
                      <span>
                        {output.numOfIndicators} Indicator
                        {output.numOfIndicators > 1 ? "s" : ""}{" "}
                      </span>
                    </Tooltip>
                  </span>
                </button>

                {!!output.unassignedIndicators && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowIndicatorDialog(true);
                    }}
                    className="badge text-danger"
                  >
                    {output.unassignedIndicators} Unassigned
                  </span>
                )}
                {!output.unassignedIndicators && output.numOfIndicators > 0 && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowIndicatorDialog(true);
                    }}
                    className="badge text-success"
                  >
                    <i className="fas fa-check"></i> All assigned
                  </span>
                )}
                {isForContract && (
                  <span>
                    {output.status === "Pending" && (
                      <span className="d-flex align-items-center justify-content-center badge badge-dark mx-3 ">
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons "
                        >
                          pending_actions
                        </span>
                        <span style={{ fontSize: "10px" }}> Pending...</span>
                      </span>
                    )}
                    {output.status === "Scored" && (
                      <span
                        className={`d-flex align-items-center justify-content-center badge badge-success mx-3 `}
                      >
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons "
                        >
                          done_all
                        </span>
                        <span style={{ fontSize: "10px" }}> Scored</span>
                      </span>
                    )}
                    {output.status === "Reported" && (
                      <span className="d-flex align-items-center justify-content-center badge badge-primary mx-3">
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons "
                        >
                          file_present
                        </span>
                        <span style={{ fontSize: "10px" }}> Reported</span>
                      </span>
                    )}

                    {output.status === "Rejected" && (
                      <span className="d-flex align-items-center justify-content-center badge badge-danger mx-3">
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons   "
                        >
                          close
                        </span>
                        <span style={{ fontSize: "10px" }}> Rejected</span>
                      </span>
                    )}
                    {output.status === "Overdue" && (
                      <span className="d-flex align-items-center justify-content-center badge badge-danger mx-3">
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons"
                        >
                          error
                        </span>
                        <span style={{ fontSize: "10px" }}> Overdue</span>
                      </span>
                    )}
                  </span>
                )}
              </span>
            </span>
          }
        />
      </Card>
      <Divider />
      <Divider />
      {confirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={confirmationDialog}
          message="Are you sure you want to delete output?"
          setConfirmationDialog={setConfirmationDialog}
          onYes={() => {
            deleteOutput(output.id, setConfirmationDialog);
          }}
        />
      )}

      {showIndicatorDialog && (
        <IndicatorDialog
          showDialog={showIndicatorDialog}
          setShowDialog={setShowIndicatorDialog}
          selectedOutput={output}
          isForMonitoring={isForMonitoring}
        />
      )}

      {showAssignmentForm && (
        <OutputAssignmentForm
          selectedOutput={output}
          showAssignmentForm={showAssignmentForm}
          setShowAssignmentForm={setShowAssignmentForm}
        />
      )}

      <OutputForm
        totalWeight={totalWeight}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        closeOutputForm={closeOutputForm}
        outputFormOpen={outputFormOpen}
        selectedOutput={output}
      />

      {confirmMarkAsKey && (
        <ConfirmationDialog
          confirmationDialog={confirmMarkAsKey}
          message="Are you sure you want to mark this output as departmental key priority?"
          setConfirmationDialog={setConfirmMarkAsKey}
          onYes={() => {
            markOutputAsKey(
              { id: output.id, isRRAKey: !output.isRRAKey },
              setConfirmMarkAsKey
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  deleteOutput,
  markOutputAsKey,
})(OutputCard);
