import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Autocomplete,
  TextField,
  IconButton,
  Slide,
} from "@mui/material";
import Allowance from "../components/Allowance";
import { a11yProps, TabPanel } from "../../common/components/TabPanel";
import Creditor from "../components/Creditor";
import Banks from "../components/Banks";
import CostCenter from "../components/CostCenter";
import PayrollApprover from "../components/PayrollApprover";
import Dashboard from "../../payroll/pages/Dashboard";
import PayrollOversight from "../components/PayrollOversight";
import { loadPayrollPeriods } from "../../../store/admin/actions";
import SourceOfFounds from "../components/SourceOfFounds";
import SearchBox from "../../common/components/SearchBox";
import SalaryIndexGrid from "../../structure/pages/SalaryIndexGrid";

const Payroll = (props) => {
  const { loading, adminPayrollPeriods, payrollOversight, loadPayrollPeriods } =
    props;
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [overSightTabValue, setOverSightTabValue] = useState(0);
  const [createAllowance, setCreateAllowance] = useState(false);
  const [createCreditor, setCreateCreditor] = useState(false);
  const [createBank, setCreateBank] = useState(false);
  const [createCostCenter, setCreateCostCenter] = useState(false);
  const [createPayrollApprover, setCreatePayrollApprover] = useState(false);
  const [createFund, setCreateFund] = useState(false);
  const [loadingPeriods, setLoadingPeriods] = useState(false);
  const [filterPeriodStatus, setFilterPeriodStatus] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const handleChangeOversightTab = (event, newValue) => {
    setOverSightTabValue(newValue);
    cleanFilters();
  };

  const cleanFilters = () => {
    setSelectedPeriod(null);
    setFilterPeriodStatus(false);

    setSearchInput("");
  };

  useEffect(() => {
    if (adminPayrollPeriods === null || adminPayrollPeriods.length === 0) {
      loadPayrollPeriods((status, data) => {
        setLoadingPeriods(status);
        setSelectedPeriod(data);
      });
    } else {
      setSelectedPeriod(adminPayrollPeriods[0] || null);
      setLoadingPeriods(false);
    }
    return () => {
      setLoadingPeriods(true);
      setTabValue(0);
      cleanFilters();
    };
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    cleanFilters();
  };

  const FN_HandleFilter = (_data) => {
    let data = [..._data];
    if (searchInput.length === 0) return data;
    return data.filter(
      (item) =>
        (item.sectorName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.costCenterName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.employeeGroupName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.payrollGroupName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.sourceOfFundName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.periodName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.totalPaid || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.totalSalary || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.statusName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.arrearTypeName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase()) ||
        (item.entityName || "")
          .toLocaleLowerCase()
          .includes(searchInput.toLocaleLowerCase())
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            className="app-bar w-100 m-0 p-0"
          >
            <Toolbar>
              <div className="row no-gutter justify-content-between align-items-center pb-1 w-100">
                <div className="col-12 col-md-9">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="Admin payroll tabs"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {/* <Tab label="Cost centers" {...a11yProps(0)} />
                    <Tab label="Allowances" {...a11yProps(1)} />
                    <Tab label="Creditors" {...a11yProps(2)} />
                    <Tab label="Banks" {...a11yProps(3)} />
                    <Tab label="Payroll Approver" {...a11yProps(4)} />
                    <Tab label="Payroll Source of fund" {...a11yProps(5)} /> */}
                    <Tab label="Oversight" {...a11yProps(0)} />
                    <Tab label="Salary Index Grid" {...a11yProps(1)} />

                    <Tab label="Dashboard" {...a11yProps(2)} />
                  </Tabs>
                </div>
                <div className="col-12 col-md-3 text-right">
                  {tabValue === 0 && overSightTabValue === 1 && (
                    <div className="d-flex flex-row align-items-center justify-content-end">
                      {filterPeriodStatus && (
                        <Autocomplete
                          className="ml-2"
                          disabled={loadingPeriods || loading}
                          disablePortal
                          getOptionLabel={(option) => option.periodId}
                          renderOption={(props, field) => (
                            <Box component="li" {...props}>
                              {field?.periodId}
                            </Box>
                          )}
                          options={adminPayrollPeriods}
                          sx={{ width: 145 }}
                          size="small"
                          value={selectedPeriod || null}
                          isOptionEqualToValue={(option, value) =>
                            option.periodId === value.periodId
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose period"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "period",
                              }}
                            />
                          )}
                          onChange={(event, period) => {
                            setSelectedPeriod(period || null);
                            setFilterPeriodStatus(false);
                          }}
                        />
                      )}
                      {!filterPeriodStatus && (
                        <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
                          {selectedPeriod && (
                            <span className="font-weight-normal mr-1">
                              {selectedPeriod.periodId}
                            </span>
                          )}
                          <IconButton
                            color="primary"
                            aria-label="activate filter by payroll period"
                            component="label"
                            size="small"
                            onClick={() => setFilterPeriodStatus(true)}
                          >
                            <span className="material-icons-outlined">
                              filter_list
                            </span>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {tabValue === 0 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCostCenter(true)}
                      title="Add New Cost coste"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Cost coste</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 1 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateAllowance(true)}
                      title="Add New Allowance"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Allowance</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 2 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateCreditor(true)}
                      title="Add New Creditor"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Creditor</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 3 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateBank(true)}
                      title="Add New Bank"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Bank</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 4 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreatePayrollApprover(true)}
                      title="Add payroll approver"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">Add Approver</span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )}
                  {tabValue === 5 && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateFund(true)}
                      title="Add payroll source of fund"
                      className=" mx-md-0"
                    >
                      <span className="d-none d-md-block">
                        Add Source of fund
                      </span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                  )} */}
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "0 1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            {/* <TabPanel value={tabValue} index={0}>
              <CostCenter
                createCostCenter={createCostCenter}
                setCreateCostCenter={setCreateCostCenter}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Allowance
                createAllowance={createAllowance}
                setCreateAllowance={setCreateAllowance}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Creditor
                createCreditor={createCreditor}
                setCreateCreditor={setCreateCreditor}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Banks createBank={createBank} setCreateBank={setCreateBank} />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <PayrollApprover
                createPayrollApprover={createPayrollApprover}
                setCreatePayrollApprover={setCreatePayrollApprover}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <SourceOfFounds
                createFund={createFund}
                setCreateFund={setCreateFund}
              />
            </TabPanel> */}

            <TabPanel value={tabValue} index={0}>
              <div className="pb-1 w-100">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <Tabs
                      orientation="horizontal"
                      variant="scrollable"
                      scrollButtons="auto"
                      value={overSightTabValue}
                      onChange={handleChangeOversightTab}
                      aria-label="Payroll types tabs"
                      // sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                      <Tab
                        label={
                          <span style={{ fontSize: "11px" }}>In_Progress</span>
                        }
                        {...a11yProps(0)}
                      />
                      <Tab
                        label={
                          <span style={{ fontSize: "11px" }}>Archives</span>
                        }
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </div>
                  <div className="col-auto">
                    <div className="mr-md-2 w-100 mt-2 mt-md-0">
                      <SearchBox
                        // className="mt-2 mb-1"
                        onSearch={setSearchInput}
                        disabled={loading || payrollOversight.length <= 3}
                        placeholder="Search ..."
                      />
                    </div>
                  </div>
                </div>

                <TabPanel value={overSightTabValue} index={0}>
                  <div className="mt-1">
                    <PayrollOversight
                      selectedPeriod={selectedPeriod}
                      payrollType="Temp"
                      searchInput={searchInput}
                      filteredData={FN_HandleFilter(payrollOversight)}
                    />
                  </div>
                </TabPanel>

                <TabPanel value={overSightTabValue} index={1}>
                  <div className="mt-1">
                    <PayrollOversight
                      selectedPeriod={selectedPeriod}
                      payrollType="Archived"
                      searchInput={searchInput}
                      filteredData={FN_HandleFilter(payrollOversight)}
                    />
                  </div>
                </TabPanel>
              </div>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <React.Fragment>
                <SalaryIndexGrid />
              </React.Fragment>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Dashboard isForOversight />
            </TabPanel>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    adminPayrollPeriods: adminPayroll.adminPayrollPeriods,
    payrollOversight: adminPayroll.adminPayrollOversight,
  };
};

export default connect(mapStateToProps, { loadPayrollPeriods })(Payroll);
