import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import { connect } from "react-redux";
import { Toolbar, Box, Typography, CardContent } from "@mui/material";

const Dashboard = (props) => {
  const { user, loading } = props;
  const [searchTeam, setSearchTeam] = useState("");

  useEffect(() => {});

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>Support</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>
                <span style={{ color: "rgb(7, 142, 206)" }}>Dashboard</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              Coming soon..
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(Dashboard);
