import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Alert,
  AlertTitle,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  approveRequisition,
  rejectRequisition,
} from "../../../store/recruitment/actions";
import RequisitionCertificate from "./RequisitionCertificate";
import RequisitionCompetency from "./RequisitionCompetency";
import RequisitionQualification from "./RequisitionQualification";
import RejectRequisitionDialog from "./RejectRequisitionDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CurrencyFormat from "react-currency-format";
import moment from "moment";

const RequisitionDetailsDialog = (props) => {
  const {
    loading,
    onWait,
    showRequisitionDetails,
    setShowRequisitionDetails,
    selectedRequisition,
    approveRequisition,
    rejectRequisition,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [content, setContent] = useState("");
  const textFieldRef = useRef(null);
  const [rows, setRows] = useState(1);

  const history = useHistory();

  useEffect(() => {
    if (textFieldRef.current) {
      const lineHeight = parseInt(
        getComputedStyle(textFieldRef.current).lineHeight,
        10
      );
      const currentRows = Math.floor(
        textFieldRef.current.scrollHeight / lineHeight
      );
      setRows(Math.min(currentRows, 10));
    }
  }, [content]);

  const handleContentChange = (event) => {
    setContent(event.target.innerText);
  };

  const [requisitionForm, setRequisitionForm] = useState({
    id: selectedRequisition.id,
    positionId: selectedRequisition.positionId,
    numberOfPosts: selectedRequisition.numberOfPosts,
    isInternal: selectedRequisition.isInternal,
    reason: selectedRequisition.reason,
    willHaveWrittenExam: selectedRequisition.willHaveWrittenExam,
    willHaveOralExam: selectedRequisition.willHaveOralExam,
    statusComments: selectedRequisition?.statusComments || "",
    statusId: selectedRequisition?.statusId || 0,
  });
  const [showRejectRequisitionForm, setShowRejectRequisitionForm] =
    useState(false);

  const [showApproveRequisitionForm, setShowApproveRequisitionForm] =
    useState(false);

  const [isRejection, setIsRejection] = useState(false);

  const onClose = () => {
    setShowRequisitionDetails(false);
    setRequisitionForm(null);
  };

  // console.log(selectedRequisition);

  const [reason, setReason] = useState("");

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRequisitionDetails}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Requisition Details
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="card service-card valid mb-2 mt-1">
            <div
              className="card-header text-uppercase"
              style={{ padding: "0.25rem 1.2rem" }}
            >
              {selectedRequisition.positionName +
                " (" +
                selectedRequisition.levelName +
                "." +
                selectedRequisition.scaleName +
                ")"}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mt-0">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Employment group
                        </th>
                        <th scope="col" className="text-left">
                          Recruitment mode
                        </th>
                        <th scope="col" className="text-left">
                          Exams
                        </th>

                        <th scope="col" className="text-left">
                          Reports to
                        </th>
                        <th scope="col" className="text-center">
                          Number of posts
                        </th>
                        <th scope="col" className="text-center">
                          PublishedOn
                        </th>
                        <th scope="col" className="text-center">
                          Deadline
                        </th>
                        {hasPermissions(["IS_CBM", "IS_HR", "IS_DAF"]) && (
                          <th scope="col" className="text-center">
                            Annual basic salary
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left">
                          {selectedRequisition.employeeGroupName}
                        </td>

                        <td className="text-left">
                          {selectedRequisition.isInternal
                            ? "Internal recruitment"
                            : "Open competition"}
                        </td>
                        <td className="text-left">
                          {selectedRequisition.willHavePsychometricTest && (
                            <>
                              <span>1:PsychometricTest</span>
                              <br />
                            </>
                          )}

                          {selectedRequisition.willHaveWrittenExam && (
                            <>
                              <span>
                                {selectedRequisition.willHavePsychometricTest
                                  ? "2"
                                  : "1"}
                                :Written
                              </span>
                              <br />
                            </>
                          )}

                          {selectedRequisition.willHaveOralExam && (
                            <span>
                              {selectedRequisition.willHavePsychometricTest &&
                              selectedRequisition.willHaveWrittenExam
                                ? "3"
                                : selectedRequisition.willHaveWrittenExam
                                ? "2"
                                : "1"}
                              :Oral
                            </span>
                          )}
                        </td>

                        <td className="text-left">
                          {selectedRequisition.reportsTo}
                        </td>
                        <td className="text-center">
                          {selectedRequisition.numberOfPosts}
                        </td>
                        <td className="text-center">
                          {moment(selectedRequisition.openingDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td className="text-center">
                          {moment(selectedRequisition.closingDate).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                      
                        {hasPermissions(["IS_CBM", "IS_HR", "IS_DAF"]) && (
                          <td className="text-center">
                            {!!selectedRequisition.annualBasicSalary ? (
                              <CurrencyFormat
                                value={selectedRequisition.annualBasicSalary}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"RWF "}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
                {selectedRequisition.reason && (
                  <div className="col-lg-12 mt-2">
                    <strong>Reason for requisition</strong>
                    <div
                      className="mt-1 p-4 rounded"
                      ref={textFieldRef}
                      contentEditable={false} // Set to true if you want it editable
                      style={{
                        whiteSpace: "pre-line",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        border: `1px solid #c8c6c6`,
                      }}
                      onInput={handleContentChange}
                    >
                      {selectedRequisition.reason}
                    </div>
                  </div>
                )}

                <div className="col-lg-12 mt-3">
                  <strong>Job Responsibilities</strong>
                  <div
                    className="mt-1 p-4 rounded"
                    ref={textFieldRef}
                    contentEditable={false} // Set to true if you want it editable
                    style={{
                      whiteSpace: "pre-line",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      border: `1px solid #c8c6c6`,
                    }}
                    onInput={handleContentChange}
                  >
                    {selectedRequisition.jobDescriptions}
                  </div>
                </div>

                <RequisitionQualification
                  requisitionId={selectedRequisition.id}
                />

                <RequisitionCompetency requisitionId={selectedRequisition.id} />

                <RequisitionCertificate
                  requisitionId={selectedRequisition.id}
                />
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          {((selectedRequisition.statusId !== 0 &&
            hasPermissions(["IS_CBM"])) ||
            !hasPermissions(["IS_CBM"])) && (
            <span
              className={`font-weight-bold text-uppercase badge badge-${
                selectedRequisition.statusId === 2
                  ? "danger"
                  : selectedRequisition.statusId === 0
                  ? "dark"
                  : "success"
              }`}
            >
              {selectedRequisition.statusName}
            </span>
          )}

          {hasPermissions(["IS_CBM"]) && requisitionForm.statusId === 0 && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => setShowApproveRequisitionForm(true)}
                type="button"
                className="  px-4"
                disabled={
                  onWait ||
                  loading ||
                  showRejectRequisitionForm ||
                  showApproveRequisitionForm
                }
              >
                {loading ? "Wait..." : "Approve?"}
              </Button>
              <span className="m-2">or</span>

              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setShowRejectRequisitionForm(true);
                }}
                type="button"
                className="px-4 ml-2"
                disabled={
                  loading ||
                  loading ||
                  showRejectRequisitionForm ||
                  showApproveRequisitionForm
                }
              >
                {isRejection ? "Wait..." : "Reject?"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {showApproveRequisitionForm && (
        <ConfirmationDialog
          confirmationDialog={showApproveRequisitionForm}
          message={
            <>
              <p>Are you sure you want to approve this requisition?</p>
            </>
          }
          setConfirmationDialog={setShowApproveRequisitionForm}
          onYes={() => {
            approveRequisition(
              requisitionForm.id,
              setShowRequisitionDetails,
              history
            );
          }}
        />
      )}

      {showRejectRequisitionForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequisitionForm}
          message={
            <>
              <p>Are you sure you want to reject this requisition?</p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="rejectionReason"
                autoFocus
                label="Rejection reason"
                variant="outlined"
                placeholder="Rejection reason"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequisitionForm}
          onYes={() => {
            rejectRequisition(
              {
                requisitionId: selectedRequisition.id,
                reason: reason,
              },
              setShowRequisitionDetails
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, user, onWait }) => {
  return {
    loading,
    user,
    onWait,
  };
};
export default connect(mapStateToProps, {
  approveRequisition,
  rejectRequisition,
})(RequisitionDetailsDialog);
