const types = {
  SET_APPEAL_TYPES: "SET_APPEAL_TYPES",
  SET_APPEALS: "SET_APPEALS",
  SET_APPEAL: "SET_APPEAL",
  UPDATE_APPEAL: "UPDATE_APPEAL",
  SET_APPEAL_DETAILS: "SET_APPEAL_DETAILS",
  SET_APPEAL_RESOLVED: "SET_APPEAL_RESOLVED",
};

export default types;
