import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPositionQualifications,
  deletePositionQualification,
} from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ListSkeleton from "../../common/components/ListSkeleton";

const PositionQualifications = (props) => {
  const {
    loading,
    onWait,
    positionQualifications,
    deletePositionQualification,
  } = props;

  // useEffect(() => {
  //   getPositionQualifications(position.id);
  // }, [position]);

  //PAGINATION
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [confirmRemoveQualification, setConfirmRemoveQualification] =
    useState(false);

  const { hasPermissions } = useRequiredAuth();
  const getNo = (idToSearch) =>
    positionQualifications.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {confirmRemoveQualification && (
        <ConfirmationDialog
          isLoading={onWait}
          confirmationDialog={confirmRemoveQualification}
          message={`Are you sure you want to remove this qualification(${selectedQualification.qualificationName})?`}
          setConfirmationDialog={setConfirmRemoveQualification}
          onYes={() => {
            deletePositionQualification(
              selectedQualification,
              setConfirmRemoveQualification,
              setSelectedQualification
            );
          }}
        />
      )}
      {(!loading || onWait) && !!positionQualifications.length && (
        <div className="card service-card valid mb-2 mt-2">
          <div
            className="card-header float-left text-left"
            style={{ padding: "0.25rem 1.2rem" }}
          >
            Qualifications
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <List
                  className="mt-2"
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid #c8c6c6`,
                    backgroundColor: "#f9f9f9",
                  }}
                  dense
                >
                  {positionQualifications.map((qualification, index) => (
                    <React.Fragment key={qualification.id}>
                      <ListItem
                        dense
                        secondaryAction={
                          <>
                            {!!qualification.positionId &&
                              hasPermissions([
                                "IS_HR",
                                "IS_STRUCTURE_OVERSIGHT",
                                "IS_TEST_USER",
                              ]) && (
                                <span>
                                  <IconButton
                                    style={{ float: "right" }}
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      setSelectedQualification(qualification);
                                      setConfirmRemoveQualification(true);
                                    }}
                                    aria-label="settings"
                                  >
                                    <span className="material-icons">
                                      delete_outline
                                    </span>
                                  </IconButton>
                                </span>
                              )}
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              fontSize: "16px",
                              border: "1px solid #c8c6c6",
                            }}
                            className="bg-white text-dark "
                          >
                            {getNo(qualification.id) + 1}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <span className={`${"font-weight-light"}`}>
                              {" "}
                              {qualification.degreeName} in{" "}
                              {qualification.qualificationName}
                            </span>
                          }
                          secondary={
                            <>
                              <span
                                className={`${"font-weight-bold badge badge-secondary"}`}
                              >
                                {!qualification.yearsOfExperience && (
                                  <span>N/A</span>
                                )}
                                {!!qualification.yearsOfExperience && (
                                  <span>
                                    {qualification.yearsOfExperience} Year
                                    {qualification.yearsOfExperience > 1 && (
                                      <>s of relevant experience</>
                                    )}
                                  </span>
                                )}
                              </span>
                            </>
                          }
                          // deletePositionQualification={deletePositionQualification}
                        />
                      </ListItem>

                      {positionQualifications.length > index + 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <ListSkeleton />}
      {(!loading || onWait) && !positionQualifications.length && (
        <div
          className="jumbotron jumbotron-fluid text-center mt-2"
          style={{ width: "100%" }}
        >
          <div className="container">
            <p className="lead">No qualifications found</p>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  positionQualifications,
  position,
}) => {
  return {
    loading,
    onWait,
    positionQualifications,
    position,
  };
};
export default connect(mapStateToProps, {
  getPositionQualifications,
  deletePositionQualification,
})(PositionQualifications);
