import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getSessionAdditionalMinutes,
  saveAdditionalMinutes,
} from "../../../store/recruitment/actions";
import { showError } from "../../toastify";
import { useEffect } from "react";
import PaperDraggableComponent from "../../common/components/PaperDraggableComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
const moment = require("moment");

const SessionAdditionalMinutesDialog = (props) => {
  const {
    loading,
    showSessionAdditionalMinutes,
    setSessionAdditionalMinutes,
    selectedSession,
    getSessionAdditionalMinutes,
    sessionAdditionalMinutes,
    saveAdditionalMinutes,
  } = props;

  const [formData, setFormData] = useState({
    sessionId: selectedSession.id,
    additionalMinutes: 0,
    comment: "",
  });
  const [errors, setErrors] = useState({
    additionalMinutesHasError: false,
    commentHasError: false,
  });

  useEffect(
    () => {
      getSessionAdditionalMinutes(selectedSession.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSession]
  );

  const formValidator = () => {
    const error = {
      additionalMinutesHasError: false,
      commentHasError: false,
      hasError: false,
    };

    if (!formData.additionalMinutes || formData.additionalMinutes <= 0) {
      error.additionalMinutesHasError = true;
      error.hasError = true;
    }

    if (!formData.comment) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveAdditionalMinutes(formData, onClose);
    }
  };

  const onClose = () => {
    setSessionAdditionalMinutes(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperDraggableComponent}
        onClose={onClose}
        open={showSessionAdditionalMinutes}
        // fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="text-primary"
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Typography variant="overline" display="block">
            Additional Minutes
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="row">
            <div className="col-lg-12 mt-2">
              <TextField
              disabled={selectedSession.submittedOn || !!selectedSession.isMarkedAsCheated}
                fullWidth
                size="small"
                name="additionalMinutes"
                autoFocus
                label="Minutes"
                variant="outlined"
                placeholder="Minutes"
                value={formData.additionalMinutes}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const additionalMinutes = e.target.value;
                  setFormData({
                    ...formData,
                    additionalMinutes,
                  });
                  const additionalMinutesHasError = false;
                  setErrors({ ...errors, additionalMinutesHasError });
                }}
              />
              <ErrorMessage
                hasError={errors.additionalMinutesHasError}
                message="Additional minutes is required"
              />
            </div>
            <div className="col-lg-12 mt-2">
              <TextField
               disabled={selectedSession.submittedOn || !!selectedSession.isMarkedAsCheated}
                fullWidth
                multiline
                rows={2}
                size="small"
                name="comment"
                autoFocus
                label="Comments"
                variant="outlined"
                placeholder="Comments"
                value={formData.comment}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const comment = e.target.value;
                  setFormData({
                    ...formData,
                    comment,
                  });
                  const commentHasError = false;
                  setErrors({ ...errors, commentHasError });
                }}
              />

              <ErrorMessage
                hasError={errors.commentHasError}
                message="Comment is required"
              />
            </div>
            <div className="col-lg-12 mt-2">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary float-right"
                disabled={loading || selectedSession.submittedOn || !!selectedSession.isMarkedAsCheated}
              >
                {loading ? "Wait..." : "Add"}
              </button>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              {!!sessionAdditionalMinutes.length && (
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col" className="text-left">
                        Minutes
                      </th>
                      <th scope="col" className="text-left">
                        Comment
                      </th>

                      <th scope="col" className="text-right">
                        Date
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {sessionAdditionalMinutes.map((row, index) => (
                      <tr key={index}>
                        <td className="text-left">
                          + {row.additionalMinutes} minutes
                        </td>
                        <td className="text-left">{row.comment}</td>
                        <td className="text-right">
                          {moment(row.createdOn).format("YYYY-MM-DD HH:mm A")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, sessionAdditionalMinutes }) => {
  return {
    loading,
    sessionAdditionalMinutes,
  };
};
export default connect(mapStateToProps, {
  getSessionAdditionalMinutes,
  saveAdditionalMinutes,
})(SessionAdditionalMinutesDialog);
