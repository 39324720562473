import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../../common/components/ErrorMessage";
import ActionDialog from "../../common/components/ActionDialog";
import { showError } from "../../toastify";

import { submitFirstLevelAppealDecision } from "../../../store/e-appeal/actions";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
const FirstLevelAppealDecisionDialog = (props) => {
  const {
    user,
    loading,
    makeAppealFLDecision,
    setMakeAppealFLDecision,
    appeal,
    submitFirstLevelAppealDecision,
  } = props;

  const onClose = () => {
    setMakeAppealFLDecision(false);
  };
  const maxLength = 250;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    appealId: appeal ? appeal.id : 0,
    message: appeal?appeal.decision:"",
    isFirstLevelValid: appeal?appeal.isValid:false,
    firstLevelValid: !!appeal?appeal.isValid?'yes':"no":"",
    attachedFiles: [],
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    messageHasError: false,
    firstLevelValid: false,
    hasError: false,
  });

  //console.log(entitySectors);
  const formValidator = () => {
    const error = {
      messageHasError: false,
      firstLevelValid: false,
      hasError: false,
    };

    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }
    if (!formData.firstLevelValid) {
      error.firstLevelValid = true;
      error.hasError = true;
    }
    // if (!formData.attachedFiles.length) {
    //   error.fileHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!errors.fileHasError) {
      if (!formValidator()) {
        formData.isValid = !!formData.isFirstLevelValid?1:0;
        formData.appealId = formData.appealId;

        const payload = new FormData();
        const keys = Object.keys(formData);
        for (const key of keys) {
          if (formData.hasOwnProperty(key)) {
            if (
              key === "attachedFiles" &&
              formData[key] &&
              !!formData[key].length
            )
              formData[key].forEach((row, i) => {
                payload.append(key, row.file);
              });
            else payload.append(`${key}`, `${formData[key]}`);
          }
        }

        submitFirstLevelAppealDecision(
          payload,
          setFormData,
          setIsSubmitting,
          onClose
        );
      }
    }
  };

  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={makeAppealFLDecision}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-11">
              <h5
                style={{
                  // background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {" "}
                {appeal?<>Edit</>:<>Make</>} Decision.
              </h5>
            </div>
            <div className="col-1">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 mt-0">
              <div className="input-group input-group-sm mt-2">
                <div
                  className="input-group-prepend "
                  style={{
                    borderColor:
                      formData.firstLevelValid === "yes"
                        ? "#139647"
                        : formData.firstLevelValid === "no"
                        ? "#d62e4a"
                        : "",
                  }}
                >
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{
                      background:
                        formData.firstLevelValid === "yes"
                          ? "#139647"
                          : formData.firstLevelValid === "no"
                          ? "#d62e4a"
                          : "",
                      color:
                        formData.firstLevelValid === "yes"
                          ? "#fff"
                          : formData.firstLevelValid === "no"
                          ? "#fff"
                          : "",
                    }}
                  >
                    Is Appeal Valid?
                  </span>
                </div>
                <div
                  className="form-control"
                  style={{
                    borderColor:
                      formData.firstLevelValid === "yes"
                        ? "#139647"
                        : formData.firstLevelValid === "no"
                        ? "#d62e4a"
                        : "",
                  }}
                >
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="firstLevelValid"
                      name="firstLevelValid"
                      checked={formData.firstLevelValid === "yes"}
                      onChange={(event) => {
                        const isFirstLevelValid = true;
                        const firstLevelValid = "yes";
                        setFormData({
                          ...formData,
                          isFirstLevelValid,
                          firstLevelValid,
                        });
                        setErrors({
                          ...errors,
                          firstLevelValid: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="Yes">
                      Yes
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="firstLevelValid"
                      name="firstLevelValid"
                      checked={formData.firstLevelValid === "no"}
                      onChange={(event) => {
                        const isFirstLevelValid = false;
                        const firstLevelValid = "no";
                        setFormData({
                          ...formData,
                          isFirstLevelValid,
                          firstLevelValid,
                        });
                        setErrors({
                          ...errors,
                          firstLevelValid: false,
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor="No">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                hasError={errors.firstLevelValid}
                message="Please confirm if the appeal is valid or not!"
              />
            </div>
            <div className="col-lg-12 mt-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                size="small"
                name="message"
                label={`Write your decision not great than ${maxLength} characters`}
                variant="outlined"
                placeholder={`Write your decision not great than ${maxLength} character`}
                value={formData.message}
                inputProps={{
                  maxLength: maxLength,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const message = e.target.value;
                  setFormData({
                    ...formData,
                    message,
                  });

                  setErrors({
                    ...errors,
                    messageHasError: false,
                  });
                }}
              />
              {!!formData?.message?.length && (
                <div className="float-right text-primary">
                  {formData?.message?.length}/{maxLength}
                </div>
              )}
              <ErrorMessage
                hasError={errors.messageHasError}
                message="Appeal decision is required"
              />
            </div>
            {!appeal?.decision && <>
              <div className="col-lg-12 mt-3">
              <WidgetUploadComponent
                onSuccess={onUploadSuccess}
                onErrors={onUploadErrors}
                multiple={true}
                acceptedFileSize="1074176"
                acceptedFileTypes="pdf"
                label="Upload supporting document (pdf)"
              />
            </div>{" "}
            </>}
           
          </div>

          {isSubmitting && (
            <ActionDialog showAction={isSubmitting} action="Submitting" />
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  submitFirstLevelAppealDecision,
})(FirstLevelAppealDecisionDialog);
