import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import TempPayrolls from "./pages/TempPayrolls";
import ArchivedPayrolls from "./pages/ArchivedPayrolls";
import UserGuide from "./pages/UserGuide";
import MenuBar from "./components/MenuBar";
import SalaryIndexGrid from "../structure/pages/SalaryIndexGrid";
import RequestedDeductions from "./pages/RequestedDeductions";
import {
  countEmployeeDeductions,
  getActiveCostCenters,
  getEntitySectorPayrollTypes,
  getSupportingDocumentTypes,
  getChangeReasons,
} from "../../store/payroll/actions";
import { connect } from "react-redux";
import PerformanceBonus from "./pages/PerformanceBonus";
import { getFiscalYears, getSelectedFiscalYear } from "../../store/rbm/actions";
import { isEmpty } from "lodash";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import Barometer from "./pages/Barometer";
import PayrollOversight from "../admin/pages/Payroll";
import CurrencyConversion from "./pages/CurrencyConversion";

const defaultRouters = [
  {
    to: "/payroll/oversight",
    permissions: ["IS_PAYROLL_OVERSIGHT"],
  },

  {
    to: "/payroll/barometer-data",
    permissions: ["CAN_ACCESS_BAROMETER_DATA"],
  },
];

const Layout = (props) => {
  const {
    countEmployeeDeductions,
    fiscalYears,
    getFiscalYears,
    selectedFiscalYear,

    getActiveCostCenters,
    getEntitySectorPayrollTypes,

    supportingDocumentTypes,
    getSupportingDocumentTypes,
    changeReasons,
    getChangeReasons,
  } = props;
  const { hasPermissions } = useRequiredAuth();

  useEffect(
    () => {
      if (!changeReasons.length) getChangeReasons();
      if (!fiscalYears.length) getFiscalYears();

      if (isEmpty(selectedFiscalYear)) getSelectedFiscalYear();

      countEmployeeDeductions();

      if (!supportingDocumentTypes.length) getSupportingDocumentTypes();

      getActiveCostCenters();
      getEntitySectorPayrollTypes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderDefaultRoute = () => {
    for (const router of defaultRouters) {
      if (hasPermissions(router.permissions) && !hasPermissions(["IS_ADMIN"])) {
        return router.to;
      }
    }

    return "/payroll/temp-payrolls";
  };

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/payroll/dashboard" component={Dashboard} />
        <Route path="/payroll/temp-payrolls" component={TempPayrolls} />
        <Route path="/payroll/archived-payrolls" component={ArchivedPayrolls} />
        <Route
          path="/payroll/requested-deductions"
          component={RequestedDeductions}
        />
        <Route path="/payroll/performance-bonus" component={PerformanceBonus} />
        <Route path="/payroll/index-grid" component={SalaryIndexGrid} />
        <Route path="/payroll/barometer-data" component={Barometer} />
        <Route path="/payroll/oversight" component={PayrollOversight} />
        <Route
          path="/payroll/currency-conversion"
          component={CurrencyConversion}
        />

        <Route path="/payroll/user-guide" component={UserGuide} />

        <Route
          path="/payroll"
          component={() => <Redirect to={renderDefaultRoute()} />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  loading,
  fiscalYears,
  selectedFiscalYear,
  supportingDocumentTypes,
  changeReasons,
}) => {
  return {
    user,
    loading,
    fiscalYears,
    selectedFiscalYear,
    supportingDocumentTypes,
    changeReasons,
  };
};

export default connect(mapStateToProps, {
  countEmployeeDeductions,
  getFiscalYears,
  getSelectedFiscalYear,

  getActiveCostCenters,
  getEntitySectorPayrollTypes,
  getSupportingDocumentTypes,
  getChangeReasons,
})(Layout);
