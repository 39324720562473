import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Slide,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  trainingAttachments
} from "../../common/components/Utils";
import { showError } from "../../toastify";

import { reAttachTrainingRequestDocs } from "../../../store/training/actions";

import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};


const ReAttachTrainingRequestDialog = (props) => {
  const {
    loading,
    showAddAttachment,
    setShowAddAttachment,
    reAttachTrainingRequestDocs,
    request,
    trainingFunds
  } = props;

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const [formData, setFormData] = useState({
    referenceNo:request.referenceNo,
    trainingRequestId:request.id,
    requestType: request.requestType,
    employeeId:request.employeeId,
    _requestType: request.requestType==="ShortTerm"?"SHORT_TERM":"LONG_TERM",
    attachments: [],
  });

// console.log(request);

  const [errors, setErrors] = useState({
    attachmentHasErrors: [],
    hasError: false,
  });
  useEffect(() => {
  const contractualName = "an employment contract for contractual staff";
  const documents = trainingAttachments.find(
    ({ key }) => key === "SHORT_TERM"
  ).documents;

  if (request.employmentCategory === "Contractual staff") {
   
    if (!!documents.length) {
      const docLength = documents.length;
      const lastIndex = documents[docLength - 1];
      const doc = documents.find((el) => el && el.name === contractualName);
      if (!doc) {
        trainingAttachments
          .find(({ key }) => key === "SHORT_TERM")
          .documents.push({
            id: lastIndex.id + 1,
            name: contractualName,
          });
      }
    }
  }

  const _trainingAttachments = trainingAttachments.find(({ key }) => key === formData._requestType);

const trainingAttachmentGvt ="government commitment/Request for non-objection to fund the training";
const trainingAttachmentSelfSpsd = "financial statement";

if (!!trainingFunds.find(({provider})=>provider==='Government')) {
  const _trainingAttachment =
  _trainingAttachments.documents.find(
      (el) =>
        el &&
        el.name ===
          trainingAttachmentGvt
    );
  const attLength = _trainingAttachments ?.documents.length;

  if (
    !!attLength &&
    !_trainingAttachment
  ) {
    _trainingAttachments.documents.push(
      {
        id:
        _trainingAttachments
            ?.documents[
            attLength - 1
          ].id + 1,
        name: trainingAttachmentGvt,
      }
    );
  }

}

if (!!trainingFunds.find(({provider})=>provider==='Self-Sponsored')) {
  const __rainingAttachment =
    _trainingAttachments.documents.find(
      (el) =>
        el &&
        el.name ===
          trainingAttachmentSelfSpsd
    );
  const attLength =_trainingAttachments?.documents.length;
  if (
    !!attLength &&
    !__rainingAttachment
  ) {
    _trainingAttachments.documents.push(
      {
        id:
        _trainingAttachments
            ?.documents[
            attLength - 1
          ].id + 1,
        name: trainingAttachmentSelfSpsd,
      }
    );
  }
 
}

}, []);


  const containerRef = useRef(null);


  const formValidator = (showErrorToolTips = true) => {
    const error = {
      attachmentHasErrors: [],
      hasError: false,
    };

    trainingAttachments
      .find(({ key }) => key === formData?._requestType || "")
      .documents.forEach((doc) => {
        const document = formData.attachments.find(
          ({ name }) => name === doc.name
        );
        if (!document) {
          error.attachmentHasErrors.push(doc);
          error.hasError = true;
        }
      });

    setErrors(error);

    if (error.hasError) {
      if (showErrorToolTips) {
        showError(
          `Please attach the required files(${
            error?.attachmentHasErrors?.length || 0
          })`
        );
      }

      return true;
    }
    return false;
  };

  const onSave = () => {
    const payload = new FormData();
    if (!formValidator()) {
      const keys = Object.keys(formData);
      for (const key of keys) {
        if (formData.hasOwnProperty(key)) {
          if (key === "attachments" && formData[key] && !!formData[key].length)
            formData[key].forEach((row, i) => {
              payload.append(key, row.file);
              payload.append(`fileTitles`, row.name);
            });
            else payload.append(`${key}`, `${formData[key]}`);
        }
      }
  
      reAttachTrainingRequestDocs(payload, onClose);
    }
  
  };


  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf") {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ name }) => name === document.name
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ name }) => document && name && name === document.name
      );
      if (index2 >= 0) errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };


  const onClose = () => {
    setFormData({
      referenceNo: "",
      requestType:"",
      _requestType:"",
      attachments: [],
    });

    setShowAddAttachment(false);
  };


  return (
    <>
    

      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddAttachment}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-10">
              <h5>

                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="d-flex justify-content-start">
                          <small
                            className="text-primary fw-bold text-truncate "
                            style={{ fontSize: "14px", marginTop: "-2px" }}
                          >
                            Employee Training Request
                          </small>{" "}
                        
                        </div>
                      </div>
                    </div>
                
              </h5>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          
       
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          <Box sx={{ width: "100%", minHeight: "55vh" }}>
            <React.Fragment>

            <Slide
                    direction="down"
                    in={true}
                    container={containerRef.current}
                  >
                    <div className="card service-card valid mb-2 mt-1">
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem" }}
                      >
                        {formData && formData?._requestType === "LONG_TERM"
                          ? "Long Term"
                          : "Short Term"}{" "}
                        Training Attachments
                      </div>
                      <div className="card-body">
                        <div className="row">
                          {trainingAttachments.find(
                            ({ key }) => key && formData._requestType && key === formData?._requestType
                          ) && (
                            <div className="col-lg-12 mt-1">
                              {trainingAttachments
                                .find( ({ key }) => key && formData._requestType && key === formData?._requestType
                                )
                                ?.documents.map((document, index) => (
                                  <div key={index}>
                                    <fieldset
                                      className={`form-group ${
                                        !!(
                                          errors && errors.attachmentHasErrors && !!errors.attachmentHasErrors.length &&
                                          errors.attachmentHasErrors.find(
                                            (doc) => doc && document === doc
                                          )
                                        )
                                          ? "border border-danger"
                                          : "border"
                                      } p-2`}
                                    >
                                      <legend
                                        className="w-auto px-2"
                                        style={{
                                          fontFamily:
                                            '"Roboto","Helvetica","Arial",sans-serif',
                                          fontWeight: 400,
                                          fontSize: "0.82rem",
                                          lineHeight: "1em",
                                          letterSpacing: "0.00938em",
                                          color: `${
                                            !!(
                                              errors && errors.attachmentHasErrors && !!errors.attachmentHasErrors.length &&
                                              errors.attachmentHasErrors.find(
                                                (doc) => doc && document === doc
                                              )
                                            )
                                              ? "red"
                                              : "rgba(0, 0, 0, 0.6)"
                                          }`,
                                        }}
                                      >
                                        {index + 1}.{" "}
                                        <span className="ml-1">
                                          Attach {document.name}
                                        </span>
                                      </legend>
                                      <div className="form-group1 mb-2">
                                        <div className="input-group">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text">
                                              <i className="fas fa-paperclip"></i>
                                            </span>
                                          </div>
                                          {formData.attachments.find(
                                            (doc) =>
                                              document === doc && !!doc.filename
                                          ) ? (
                                            <div
                                              className="form-control"
                                              style={{
                                                backgroundColor:
                                                  "rgb(229, 246, 253)",

                                                color: "#007bff",
                                              }}
                                            >
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: formData.attachments.find(
                                                      (doc) =>
                                                        document === doc &&
                                                        !!doc.filename
                                                    ).url,
                                                  });
                                                }}
                                              >
                                                {
                                                  formData.attachments.find(
                                                    (doc) =>
                                                      document === doc &&
                                                      !!doc.filename
                                                  ).filename
                                                }
                                              </span>

                                              <IconButton
                                                onClick={() => {
                                                  const tmpDocuments = [
                                                    ...formData.attachments,
                                                  ];
                                                  const index =
                                                    tmpDocuments.findIndex(
                                                      ({ id }) =>
                                                        id === document.id
                                                    );

                                                  tmpDocuments.splice(index, 1);
                                                  setFormData({
                                                    ...formData,
                                                    attachments: tmpDocuments,
                                                  });
                                                }}
                                                style={{ marginTop: "-6px" }}
                                                color="error"
                                                className="float-right"
                                                aria-label="delete"
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                              <IconButton
                                                onClick={() => {
                                                  setShowUploadedDocument(true);
                                                  setUploadedDocument({
                                                    name: document.name,
                                                    url: formData.attachments.find(
                                                      (doc) =>
                                                        document === doc &&
                                                        !!doc.filename
                                                    ).url,
                                                  });
                                                }}
                                                style={{ marginTop: "-6px" }}
                                                color="info"
                                                className="float-right"
                                                aria-label="view"
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            </div>
                                          ) : (
                                            <>
                                              <input
                                                // disabled={!fiscalYear}
                                                type="file"
                                                name="file"
                                                className="form-control"
                                                accept="application/pdf"
                                                placeholder="Select pdf file"
                                                onChange={(e) =>
                                                  handleUploadedPDFFile(
                                                    e,
                                                    document
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div>
                                ))}

                              {showUploadedDocument && uploadedDocument && (
                                <PreviewPdfUrl
                                  isBase64={true}
                                  showDocument={showUploadedDocument}
                                  setShowDocument={setShowUploadedDocument}
                                  document={{
                                    name: `View ${
                                      uploadedDocument?.name || ""
                                    }`,
                                    url: uploadedDocument?.url || "",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Slide>
            </React.Fragment>
          </Box>
        </DialogContent>

        <DialogActions className="py-3 mx-4">
        <Button
                    disabled={loading}
                    onClick={() => onSave()}
                    style={{
                      ...ActiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                      background:"rgb(7, 142, 206)",
                      borderColor:"rgb(7, 142, 206)",
                    }}
                    size="md"
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user
}) => {
  return {
    user
  };
};
export default connect(mapStateToProps, {
  reAttachTrainingRequestDocs,
})(ReAttachTrainingRequestDialog);
