import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../../app/toastify";
import ActionDialog from "../../common/components/ActionDialog";

import ErrorMessage from "../../common/components/ErrorMessage";
import { saveCdPlan } from "../../../store/training/actions";

const AddCDPlan = (props) => {
  const {
    user,
    loading,
    fiscalYears,
    addCdPlanForm,
    setAddCdPlanForm,
    saveCdPlan,
  } = props;
  const [fiscalYear, setFiscalYear] = useState(
    !!fiscalYears.length ? fiscalYears[0] : null
  );
  const [isUploading, setIsUploading] = useState(false);

  const [formData, setFormData] = useState({
    fiscalYearId: !!fiscalYears.length ? fiscalYears[0].id : null,
    entityId: user.selectedEntitySector.entityId,
    pdfAttachment: "",
  });

  const [errors, setErrors] = useState({
    pdfAttachmentHasError: false,
    fiscalYearHasError: false,
  });

  const formValidator = () => {
    const error = {
      pdfAttachmentHasError: false,
      fiscalYearHasError: false,
      hasError: false,
    };
    //fiscalYearHasError
    if (!fiscalYear) {
      error.fiscalYearHasError = true;
      error.hasError = true;
    }
    if (!formData.pdfAttachment) {
      error.pdfAttachmentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const handleUploadedPDFFile = (event) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    setFormData({
      ...formData,
      pdfAttachment,
    });
  };

  const onSave = async () => {
    if (!formValidator()) {
      const formData2 = new FormData();

      formData2.append("fiscalYearId", formData.fiscalYearId);
      formData2.append("entityId", formData.entityId);
      formData2.append("pdfAttachment", formData.pdfAttachment);

      saveCdPlan(formData2, onClose);
    }
  };

  const onClose = () => {
    setFormData({
      fiscalYearId: "",
      entityId: "",
      pdfAttachment: "",
    });
    setAddCdPlanForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addCdPlanForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Add CD Plan</span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div className="row">
              <div className="col-lg-12 mt-2">
                <Autocomplete
                  size="small"
                  id="fiscalYear"
                  defaultValue={null}
                  value={fiscalYear}
                  options={fiscalYears}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, fiscalYear) => {
                    const fiscalYearId = fiscalYear ? fiscalYear.id : "";

                    setFormData({
                      ...formData,
                      fiscalYearId,
                    });
                    setFiscalYear(fiscalYear || null);
                    setErrors({ ...errors, fiscalYearHasError: false });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, fiscalYear) => (
                    <Box component="li" {...props} key={fiscalYear.id}>
                      {fiscalYear.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select fiscal Year"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "fiscalYearId", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  hasError={errors.fiscalYearHasError}
                  message="Fiscal Year is required"
                />
              </div>

              <div className="col-lg-12 mt-3">
                CD Plan PDF(* only pdf file)
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-paperclip"></i>
                    </span>
                  </div>

                  <input
                    disabled={!fiscalYear}
                    type="file"
                    name="file"
                    className="form-control"
                    accept="application/pdf"
                    placeholder="Select pdf file"
                    onChange={(e) => handleUploadedPDFFile(e)}
                  />
                </div>
                <ErrorMessage
                  hasError={errors.pdfAttachmentHasError}
                  message={
                    <>
                      <div>
                        Please check following issues:
                        <br />
                        File should be pdf format.
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            {isUploading && (
              <ActionDialog showAction={isUploading} action="Uploading ..." />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-2">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase"
            disabled={loading}
          >
            {isUploading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, fiscalYears }) => {
  return {
    user,
    loading,
    fiscalYears,
  };
};
export default connect(mapStateToProps, { saveCdPlan })(AddCDPlan);
