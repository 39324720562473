import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Button,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveActivity } from "../../../store/rbm/actions";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

const ActivityForm = (props) => {
  const {
    loading,
    totalWeight,
    isEditing,
    setIsEditing,
    showActivityForm,
    setShowActivityForm,
    quarter,
    selectedActivity,
    totalQuarterTarget,
    saveActivity,
    personalRemainTargets,
  } = props;

  console.log({ personalRemainTargets });

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    weight: 3,
    targetShare: 0,
    startOn: quarter.startOn,
    endOn: quarter.endOn,
  });

  useEffect(
    () => {
      if (isEditing)
        setFormData({
          id: selectedActivity.id,
          name: selectedActivity.name,
          weight: selectedActivity.weight,
          targetShare: selectedActivity.targetShare,
          startOn: selectedActivity.startOn,
          endOn: selectedActivity.endOn,
        });
      else
        setFormData({
          id: null,
          name: "",
          weight: 3,
          targetShare: 0,
          startOn: quarter.startOn,
          endOn: quarter.endOn,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedActivity, isEditing]
  );

  const onSave = () => {
    if (!weighError && !targetShareError)
      saveActivity(
        {
          ...formData,
          quarterTargetId: quarter.id,
        },
        setFormData,
        setIsEditing,
        setShowActivityForm,
        quarter
      );
  };

  const [weighError, setWeighError] = useState("");
  const [targetShareError, setTargetShareError] = useState("");

  const onClose = () => {
    setShowActivityForm(false);
    setIsEditing(false);
    setWeighError("");
    setTargetShareError("");

    setFormData({
      id: null,
      name: "",
      weight: 3,
      targetShare: 0,
      startOn: quarter.startOn,
      endOn: quarter.endOn,
    });
  };

  // const checkWeight = (value) => {
  //   if (!isEditing && (+value < 0 || +value + +totalWeight > 100))
  //     setWeighError(
  //       `Invalid weight. ${(100 - +totalWeight).toFixed(2)}% allowed`
  //     );
  //   else if (
  //     isEditing &&
  //     (+value < 0 || +value + +totalWeight > 100 + +selectedActivity.weight)
  //   )
  //     setWeighError(
  //       `Invalid weight. ${(
  //         100 -
  //         +totalWeight +
  //         +selectedActivity.weight
  //       ).toFixed(2)}% allowed`
  //     );
  //   else setWeighError("");
  // };

  const checkTargetShare = (value) => {
    if (!isEditing && (+value < 0 || +value > +personalRemainTargets))
      setTargetShareError(
        `Invalid Target Share. ${(+personalRemainTargets).toFixed(1)}% allowed`
      );
    else if (
      isEditing &&
      (+value < 0 ||
        +value + +personalRemainTargets >
          +personalRemainTargets + +selectedActivity.targetShare)
    )
      setTargetShareError(
        `Invalid Target Share. ${(
          personalRemainTargets + +selectedActivity.targetShare
        ).toFixed(2)}% allowed`
      );
    else setTargetShareError("");
  };

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  // const [weight, setWeight]=useState(null)

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showActivityForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          {isEditing ? "Edit" : "Add New"} Activity
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-12 mb-3">
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="name"
                autoFocus
                label="Name"
                variant="outlined"
                placeholder="Activity description"
                value={formData.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const name = e.target.value;
                  setFormData({ ...formData, name });
                }}
              />
            </div>
            <div className="col-6 mb-3">
              <Autocomplete
                size="small"
                value={weights.find(({ id }) => id === formData.weight)}
                options={weights}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, weight) => {
                  setFormData({
                    ...formData,
                    weight: weight?.id,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Weight" placeholder="Weight" />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
              />
            </div>
            <div className="col-6 mb-3">
              <TextField
                error={!!targetShareError}
                helperText={targetShareError}
                fullWidth
                size="small"
                type="number"
                label="Target share"
                name="targetShare"
                variant="outlined"
                defaultChecked
                placeholder="Target share"
                value={formData.targetShare}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onBlur={() => {
                  if (formData.targetShare === "")
                    setFormData({
                      ...formData,
                      targetShare: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.targetShare === 0)
                    setFormData({
                      ...formData,
                      targetShare: "",
                    });
                }}
                onChange={(e) => {
                  const targetShare = e.target.value;
                  checkTargetShare(+targetShare);
                  setFormData({
                    ...formData,
                    targetShare: +targetShare || 0,
                  });
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection
                  minDate={new Date(quarter.startOn)}
                  maxDate={new Date(quarter.endOn)}
                  label="Start date [ dd/MM/yyyy ]"
                  value={formData.startOn}
                  onChange={(startOn) => {
                    setFormData({
                      ...formData,
                      startOn,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      name="startOn"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  allowSameDateSelection
                  minDate={new Date(quarter.startOn)}
                  maxDate={new Date(quarter.endOn)}
                  label="End date [ dd/MM/yyyy ]"
                  value={formData.endOn}
                  onChange={(endOn) => {
                    setFormData({
                      ...formData,
                      endOn,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="small"
                      name="endOn"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading || !!weighError || !!targetShareError}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-5"
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  saveActivity,
})(ActivityForm);
