import React from "react";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import {
  Redirect,
  Route,
  Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import UserGuide from "../training/pages/UserGuide";
import ContractsInprogress from "./pages/ContractsInprogress";
import MenuBar from "./components/MenuBar";

const Layout = () => {
  const { hasPermissions } = useRequiredAuth();
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route
          path="/contractuals/in-progress"
          component={ContractsInprogress}
        />
        <Route path="/contractuals/user-guide" component={UserGuide} />

        <Route
          path="/contractuals"
          component={() => <Redirect to={"/contractuals/in-progress"} />}
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
