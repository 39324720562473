import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { connect } from "react-redux";
import { getSubordinatePendingReports } from "../../../store/rbm/actions";
import { Badge } from "@mui/material";

const Breadcrumb = (props) => {
  const location = useLocation();
  const {
    subordinatePendingReports,
    subordinateContracts,
    getSubordinatePendingReports,
  } = props;

  useEffect(() => {
    if ((subordinatePendingReports?.allReports || 0) === 0) {
      getSubordinatePendingReports();
    }
  }, []);

  useEffect(() => {
    getSubordinatePendingReports();
  }, [subordinateContracts]);

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/rbm/my-institution"
          className={`px-1 rounded   ${
            location.pathname.includes("/rbm/my-institution")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate font-weight-bold menu-item">
            My Institution
          </span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/rbm/my-unit"
          className={`px-1 rounded ${
            location.pathname.includes("/rbm/my-unit") ? " active  " : " "
          }`}
        >
          <span className="text-truncate font-weight-bold menu-item">
            My Unit
          </span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/rbm/my-subordinates"
          className={`px-1 rounded  ${
            location.pathname.includes("/rbm/my-subordinates") ? "active" : " "
          }`}
        >
          <Badge
            color={subordinatePendingReports?.allReports ? "error" : "warning"}
            badgeContent={
              subordinatePendingReports?.allReports ||
              subordinatePendingReports?.forActiveCompetencyNotEvaluated +
                subordinatePendingReports?.forExitedCompetencyNotEvaluated ||
              0
            }
            max={99}
          >
            <span className="text-truncate font-weight-bold menu-item">
              My Subordinates
            </span>
          </Badge>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/rbm/my-contracts"
          className={`  px-1 rounded  ${
            location.pathname.includes("/rbm/my-contracts") ? "active" : " "
          }`}
        >
          <span className="text-truncate font-weight-bold menu-item">
            My Contracts
          </span>
        </Link>

        {/* <a
          style={{ textDecoration: "none", color: "#fff" }}
          href="https://selfservice.ippis.rw/"
          className={`px-1 rounded `}
          title="Self Service Portal"
          target="_blank"
        >
          <span className="text-truncate font-weight-bold menu-item d-inline-flex">
            My Contracts <span className="material-icons">open_in_new</span>
          </span>
        </a> */}

        {/* {!!user.institution &&
          user.institution.id === "1203000001-090000000000000001" && (
            <>
              <ChevronRightIcon className="text-light" />

              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/rbm/rra-evaluation"
                className={`  px-1 rounded  ${
                  location.pathname.includes("/rbm/rra-evaluation")
                    ? "active"
                    : " "
                }`}
              >
                <span className="text-truncate  menu-item">RRA EVALUATION</span>
              </Link>
            </>
          )} */}
      </div>
    </>
  );
};

const mapStateToProps = ({
  subordinatePendingReports,
  subordinateContracts,
}) => {
  return { subordinatePendingReports, subordinateContracts };
};
export default connect(mapStateToProps, { getSubordinatePendingReports })(
  Breadcrumb
);
