import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getApplicationExperiences,
  requestWorkCertificate,
  updateApplicationFile,
} from "../../../../store/profile/actions";

import NoResults from "../../../common/components/NoResults";

import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import moment from "moment";
import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import { showError } from "../../../toastify";

export const Experiences = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationExperiences,
    getApplicationExperiences,
    applicationId,
    requestWorkCertificate,
    updateApplicationFile,
  } = props;

  useEffect(() => {
    getApplicationExperiences(applicationId);
  }, []);

  const [loadingDocument, setLoadingDocument] = useState(false);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="experiences">
        <CardContent>
          {loading && !onWait && !applicationExperiences.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <Typography variant="h6" style={{ color: "#078ece" }}>
                  Work experience
                </Typography>
              </div>

              {applicationExperiences.length === 0 ? (
                <NoResults />
              ) : (
                applicationExperiences.map((experience, index) => (
                  <ExperienceItemCard
                    key={experience.id}
                    experience={experience}
                    index={index}
                    loading={loading}
                    setLoadingDocument={setLoadingDocument}
                    isLastRow={index + 1 === applicationExperiences.length}
                    requestWorkCertificate={requestWorkCertificate}
                    updateApplicationFile={updateApplicationFile}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ env, loading, onWait, applicationExperiences }) => ({
  env,
  loading,
  onWait,
  applicationExperiences,
});

const mapDispatchToProps = {
  getApplicationExperiences,
  requestWorkCertificate,
  updateApplicationFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiences);

const ExperienceItemCard = (props) => {
  const {
    loading,
    env,
    experience,
    setLoadingDocument,
    updateApplicationFile,

    requestWorkCertificate,

    isLastRow,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  const isCurrentInPosition = (experience) => {
    return !!experience?.isFromPublicSector && experience.toDate === null
      ? true
      : false;
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card mt-2`}
        elevation={0}
        variant="outlined"
        sx={{
          backgroundColor: !!isCurrentInPosition(experience)
            ? `#cdeef8`
            : "#fff",
          border: "none",
        }}
      >
        <CardHeader
          // avatar={<span className="font-weight-bold text-primary"></span>}
          title={
            <Typography>
              <strong>{experience.positionName} </strong> at{" "}
              <span>{experience.institutionName}</span>
            </Typography>
          }
          subheader={
            <span>
              <small className="btn-link">
                {experience.fromDate ? (
                  <>
                    {moment(experience.fromDate).format("MMM-YYYY")} -{" "}
                    {experience.toDate !== null && experience.toDate.length > 0
                      ? moment(experience.toDate).format("MMM-YYYY")
                      : "Present"}
                  </>
                ) : (
                  experience.oldPeriod ?? "None"
                )}
                {!!isCurrentInPosition(experience) &&
                  experience.isActing === 1 && (
                    <strong
                      className="badge badge-primary text- ml-2"
                      style={{ fontSize: ".75rem", fontweight: "bold" }}
                    >
                      Currently Acting
                    </strong>
                  )}

                {!!isCurrentInPosition(experience) && !experience.isActing && (
                  <strong
                    className="badge badge-success text-uppercase ml-2"
                    style={{ fontSize: ".75rem", fontweight: "bold" }}
                  >
                    Currently {experience.statusName}
                  </strong>
                )}
              </small>

              <div
                className="d-flex flex-column flex-md-row align-items-center-center"
                style={{ fontSize: "12px" }}
              >
                {!!experience.employeeGroupId && (
                  <>
                    <div className="d-flex flex-row mt-1">
                      <span
                        className={`mr-2 badge badge-${
                          experience.employeeGroupId < 3
                            ? "primary"
                            : "secondary"
                        }  text-uppercase`}
                        style={{ fontSize: ".75rem", fontweight: "bold" }}
                      >
                        {experience.employeeGroupName}
                      </span>
                      <Chip
                        style={{ borderRadius: "0px" }}
                        variant="outlined"
                        className="small-chip "
                        size="small"
                        label={
                          <>
                            Level: {experience.levelName}.{experience.scaleName}
                          </>
                        }
                      />
                    </div>
                  </>
                )}
                <div className="d-flex flex-row mr-3">
                  <PhoneRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <span className="font-weight-bold ml-1">
                    {experience.institutionPhone}
                  </span>

                  <MailRoundedIcon
                    className="text- ml-2"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <a
                    href={`mailto:${experience.institutionEmail}`}
                    className="font-weight-bold ml-1 text-dark"
                  >
                    {experience.institutionEmail}
                  </a>
                </div>
                <div className="d-flex flex-row mr-3">
                  {!!experience.updatedOn && (
                    <p
                      className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      Updated on, {moment(experience.updatedOn).format("ll")}{" "}
                      {!!experience?.isFromPublicSector && (
                        <>
                          /{" "}
                          <span className="text-primary font-weight-bold">
                            From IPPIS System
                          </span>
                        </>
                      )}
                    </p>
                  )}
                  {!experience.updatedOn && (
                    <p
                      className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      Created on, {moment(experience.createdOn).format("ll")}{" "}
                      {!!experience?.isFromPublicSector && (
                        <>
                          /{" "}
                          <span className="text-primary font-weight-bold">
                            From IPPIS System
                          </span>
                        </>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center pull-right"
                style={{ borderRadius: "4px" }}
              >
                <p>
                  <IconButton
                    // disableRipple
                    // className="ml-n2"
                    size="small"
                    onClick={() => {
                      if (experience.docReferenceId) {
                        setOnViewDocument(true);
                      } else {
                        if (!!experience.isFromPublicSector) {
                          requestWorkCertificate(
                            {
                              employeeId: experience.userId,
                              employeePositionId: experience.id,
                            },
                            setOnViewDocument,
                            experience,
                            setLoadingDocument
                          );
                        } else {
                          updateApplicationFile(
                            experience,
                            "CHANGE_EXPERIENCE_FILE",
                            experience.userId +
                              "-" +
                              experience.institutionName,
                            setOnViewDocument,
                            setLoadingDocument
                          );
                        }
                      }
                    }}
                  >
                    <FilePresentIcon
                      // fontSize="large"
                      style={{
                        color: !!experience.docReferenceId
                          ? "#fe4066"
                          : "#c57c7cb8",
                      }}
                    />
                  </IconButton>
                </p>
                {/* 
                {!experience.docReferenceId &&
                  !!experience.isFromPublicSector && (
                    <>
                      <Button
                        variant="contained"
                        startIcon={<AddRoundedIcon />}
                        disabled={loading}
                        onClick={() => {
                          requestWorkCertificate(
                            {
                              employeeId: experience.userId,
                              employeePositionId: experience.employeePositionId,
                            },
                            setOnViewDocument,
                            experience,
                            setLoadingDocument
                          );
                        }}
                        size="small"
                        color="info"
                      >
                        <span className="d-none d-md-inline mr-1">Get</span>{" "}
                        Work Certificate
                      </Button>
                    </>
                  )} */}
              </div>
            </>
          }
        />
      </Card>

      {!isCurrentInPosition(experience) && !isLastRow && (
        <>
          {" "}
          <Divider variant="fullWidth" />
          <Divider variant="fullWidth" />
        </>
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Experience document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              experience.docReferenceId,
          }}
        />
      )}
      {/* {confirmToRequestWorkCertificate && (
        <ConfirmationDialog
          confirmationDialog={confirmToRequestWorkCertificate}
          message={`Are you sure you want to request for work certificate for @${experience.positionName}?`}
          setConfirmationDialog={setConfirmToRequestWorkCertificate}
          onYes={() => {
            requestWorkCertificate(
              {
                employeeId: experience.userId,
                employeePositionId: experience.employeePositionId,
              },
              setConfirmToRequestWorkCertificate
            );
          }}
        />
      )} */}
    </>
  );
};
