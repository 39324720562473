import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUserExperiences,
  deleteUserExperience,
  updateUserFile,
  requestWorkCertificate,
} from "../../../store/profile/actions";

import { updateEmployeeEmploymentDate } from "../../../store/structure/actions";

import NoResults from "../../common/components/NoResults";

import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ExperienceFormDialog from "./ExperienceFormDialog";
import LockIcon from "@mui/icons-material/Lock";

import AlertDialog from "../components/AlertDialog";
import moment from "moment";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AdvertSkeleton from "../../common/components/AdvertSkeleton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
export const Experiences = (props) => {
  const {
    userProfile,
    env,
    loading,
    onWait,
    userExperiences,
    getUserExperiences,
    deleteUserExperience,
    updateUserFile,
    updateEmployeeEmploymentDate,
    canEdit,
    employee,
    allowedToEditEmploymentDate,
    requestWorkCertificate,
    user,
  } = props;

  useEffect(() => {
    getUserExperiences(userProfile.id);
  }, []);

  // const handleCancel = () => {
  //   setNames("");
  //   setPhone("");
  //   setEmail("");
  //   setDescription("");
  //   setFormError(null);
  //   setJob("");
  //   setFromDate("");
  //   setEndDate("");
  //   setExperience(null);
  //   setUploadedFile(null);
  //   setIsPresent(false);
  //   setJobField(null);
  // };

  // const handleLoads = (action) => {
  //   if (jobFields === null || jobFields.length === 0) {
  //     loadJobFields(setLoadingData);
  //   }
  //   setFormAction(action);
  // };

  // const [viewDocument, setViewDocument] = useState(null);

  const [experienceForm, setExperienceForm] = useState({
    description: "",
    employerName: "",
    institutionEmail: "",
    institutionPhoneNumber: "",
    jobTitle: "",
    fromDate: null,
    toDate: null,
    isCurrentlyActive: false,
    // jobField: "",
    file: null,
  });

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showExperienceForm, setShowExperienceForm] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const getCurrentEmployment = () => {
    return !!userProfile?.employments ? userProfile?.employments[0] : null;
  };

  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="experiences">
        <CardContent>
          {loading && !onWait && !userExperiences.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <Typography variant="h6" style={{ color: "#078ece" }}>
                  Work experience
                </Typography>
                {canEdit && (
                  <Button
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    disabled={loading}
                    onClick={() => {
                      setShowExperienceForm(true);
                    }}
                    size="small"
                  >
                    <span className="d-none d-md-inline mr-1">Add</span> new
                  </Button>
                )}
              </div>
              {/* {!!getCurrentEmployment() && (
                <List
                  className="mt-0 mb-2"
                  sx={{
                    border: `1px solid #c8c6c6`,
                    backgroundColor: "#cdeef8",
                    borderRadius: "0px",
                  }}
                  dense
                >
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          fontSize: "16px",
                          border: "1px solid #c8c6c6",
                        }}
                        className="bg-white text-dark "
                      >
                        <span className="material-icons">pin_drop</span>
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <>
                          <span className={`font-weight-bold text-uppercase`}>
                            {getCurrentEmployment().positionName}
                          </span>
                          <br />
                          <span
                            className={`mr-2 badge badge-${
                              getCurrentEmployment().employeeGroupId < 3
                                ? "primary"
                                : "secondary"
                            }  text-uppercase`}
                          >
                            {getCurrentEmployment().employeeGroupName}
                          </span>
                          <Chip
                            style={{ borderRadius: "0px" }}
                            variant="outlined"
                            className="small-chip "
                            size="small"
                            label={
                              <>
                                Level: {getCurrentEmployment().levelName}.
                                {getCurrentEmployment().scaleName}
                              </>
                            }
                          />
                        </>
                      }
                      secondary={
                        <>
                          <span className={`font-weight-bold `}>
                            @{getCurrentEmployment().entityName} /{" "}
                            {getCurrentEmployment().unitName}
                          </span>
                        </>
                      }
                    />
                    <span>
                      <span>
                        <div className="text-right">
                          {getCurrentEmployment().isActing === 1 && (
                            <strong
                              className="badge badge-primary text-uppercase"
                              style={{ fontSize: ".75rem", fontweight: "bold" }}
                            >
                              Currently Acting
                            </strong>
                          )}

                          {!getCurrentEmployment().isActing && (
                            <strong
                              className="badge badge-success text-uppercase"
                              style={{ fontSize: ".75rem", fontweight: "bold" }}
                            >
                              Currently {getCurrentEmployment().statusName}
                            </strong>
                          )}
                        </div>
                        <div className="text-right">
                          <small>
                            From{" "}
                            {moment(getCurrentEmployment()?.startDate).format(
                              "ll"
                            )}
                          </small>
                        </div>
                      </span>
                    </span>
                    {!!hasPermissions(["IS_HR"]) &&
                      !!allowedToEditEmploymentDate && (
                        <span
                          style={{
                            backgroundColor: "#fff",
                            border: "1px solid #c8c6c6",
                          }}
                          className="p-1 rounded ml-2"
                        >
                          <IconButton
                            onClick={() => setShowEmploymentDateDialog(true)}
                            size="small"
                            color="primary"
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        </span>
                      )}
                  </ListItem>
                </List>
              )} */}

              {userExperiences.length === 0 ? (
                <NoResults />
              ) : (
                userExperiences.map((experience, index) => (
                  <ExperienceItemCard
                    key={experience.id}
                    experience={experience}
                    index={index}
                    loading={loading}
                    deleteUserExperience={deleteUserExperience}
                    setSelectedExperience={setSelectedExperience}
                    setShowExperienceForm={setShowExperienceForm}
                    setLoadingDocument={setLoadingDocument}
                    updateUserFile={updateUserFile}
                    updateEmployeeEmploymentDate={updateEmployeeEmploymentDate}
                    env={env}
                    canEdit={canEdit}
                    requestWorkCertificate={requestWorkCertificate}
                    hasPermissions={hasPermissions}
                    allowedToEditEmploymentDate={allowedToEditEmploymentDate}
                    user={user}
                    isLastRow={index + 1 === userExperiences.length}
                    onWait={onWait}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showExperienceForm && (
        <ExperienceFormDialog
          onShow={showExperienceForm}
          selectedExperience={selectedExperience}
          experienceForm={experienceForm}
          setExperienceForm={setExperienceForm}
          onClose={() => {
            setExperienceForm({
              description: "",
              employerName: "",
              institutionEmail: "",
              institutionPhoneNumber: "",
              jobTitle: "",
              fromDate: null,
              toDate: null,
              isCurrentlyActive: false,
              file: null,
            });
            setShowExperienceForm(false);
            setSelectedExperience(null);
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  userProfile,
  env,
  loading,
  onWait,
  userExperiences,
  user,
}) => ({
  userProfile,
  env,
  loading,
  onWait,
  userExperiences,
  user,
});

const mapDispatchToProps = {
  getUserExperiences,
  deleteUserExperience,
  updateUserFile,
  updateEmployeeEmploymentDate,
  requestWorkCertificate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Experiences);

const ExperienceItemCard = (props) => {
  const {
    loading,
    env,
    experience,
    deleteUserExperience,
    setSelectedExperience,
    setShowExperienceForm,
    setLoadingDocument,
    updateUserFile,
    canEdit,
    requestWorkCertificate,
    hasPermissions,
    allowedToEditEmploymentDate,
    updateEmployeeEmploymentDate,
    user,
    isLastRow,
    onWait,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  const [showEmploymentDateDialog, setShowEmploymentDateDialog] =
    useState(false);

  const [confirmToRequestWorkCertificate, setConfirmToRequestWorkCertificate] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const isCurrentInPosition = (experience) => {
    return !!experience?.isFromPublicSector && experience.toDate === null
      ? true
      : false;
  };
  const [employmentDate, setEmploymentDate] = useState(
    experience?.fromDate || null
  );
  const [exitedDate, setExitedDate] = useState(experience?.toDate || null);
  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card mt-2`}
        elevation={0}
        variant="outlined"
        sx={{
          backgroundColor: !!isCurrentInPosition(experience)
            ? `#cdeef8`
            : "#fff",
          border: "none",
        }}
      >
        <CardHeader
          // avatar={<span className="font-weight-bold text-primary"></span>}
          title={
            <Typography>
              <strong>{experience.positionName} </strong> at{" "}
              <span>{experience.institutionName}</span>
            </Typography>
          }
          subheader={
            <span>
              <small className="btn-link">
                {experience.fromDate ? (
                  <>
                    {moment(experience.fromDate).format("DD-MMM-YYYY")} -{" "}
                    {experience.toDate !== null && experience.toDate.length > 0
                      ? moment(experience.toDate).format("DD-MMM-YYYY")
                      : "Present"}
                  </>
                ) : (
                  experience.oldPeriod ?? "None"
                )}
                {!!isCurrentInPosition(experience) &&
                  experience.isActing === 1 && (
                    <strong
                      className="badge badge-primary text- ml-2"
                      style={{ fontSize: ".75rem", fontweight: "bold" }}
                    >
                      Currently Acting
                    </strong>
                  )}

                {!!isCurrentInPosition(experience) && !experience.isActing && (
                  <strong
                    className="badge badge-success text-uppercase ml-2"
                    style={{ fontSize: ".75rem", fontweight: "bold" }}
                  >
                    Currently {experience.statusName}
                  </strong>
                )}
              </small>

              <div
                className="d-flex flex-column flex-md-row align-items-center-center"
                style={{ fontSize: "12px" }}
              >
                {!!experience.employeeGroupId && (
                  <>
                    <div className="d-flex flex-row mt-1">
                      <span
                        className={`mr-2 badge badge-${
                          experience.employeeGroupId < 3
                            ? "primary"
                            : "secondary"
                        }  text-uppercase`}
                        style={{ fontSize: ".75rem", fontweight: "bold" }}
                      >
                        {experience.employeeGroupName}
                      </span>
                      <Chip
                        style={{ borderRadius: "0px" }}
                        variant="outlined"
                        className="small-chip "
                        size="small"
                        label={
                          <>
                            Level: {experience.levelName}.{experience.scaleName}
                          </>
                        }
                      />
                    </div>
                  </>
                )}
                <div className="d-flex flex-row mr-3">
                  <PhoneRoundedIcon
                    className="text-secondary"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <span className="font-weight-bold ml-1">
                    {experience.institutionPhone}
                  </span>

                  <MailRoundedIcon
                    className="text- ml-2"
                    style={{ fontSize: "14px", marginTop: "3px" }}
                  />
                  <a
                    href={`mailto:${experience.institutionEmail}`}
                    className="font-weight-bold ml-1 text-dark"
                  >
                    {experience.institutionEmail}
                  </a>
                </div>
                <div className="d-flex flex-row mr-3">
                  {!!experience.updatedOn && (
                    <p
                      className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      Updated on, {moment(experience.updatedOn).format("ll")}{" "}
                      {!!experience?.isFromPublicSector && (
                        <>
                          /{" "}
                          <span className="text-primary font-weight-bold">
                            From IPPIS System
                          </span>
                        </>
                      )}
                    </p>
                  )}
                  {!experience.updatedOn && (
                    <p
                      className="small text-muted mb-0 rounded-3 mt-2 mr-2 float-end"
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      Created on, {moment(experience.createdOn).format("ll")}{" "}
                      {!!experience?.isFromPublicSector && (
                        <>
                          /{" "}
                          <span className="text-primary font-weight-bold">
                            From IPPIS System
                          </span>
                        </>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center pull-right"
                style={{ borderRadius: "4px" }}
              >
                {!!hasPermissions(["IS_HR"]) &&
                  !!allowedToEditEmploymentDate &&
                  !!experience?.isFromPublicSector &&
                  ((!!experience.isRISASharedStaff &&
                    user?.selectedEntitySector?.id ===
                      "1903000000-150000000000000000-11") ||
                    (experience.entitySectorId &&
                      experience.entitySectorId ===
                        (user?.selectedEntitySector?.id || null))) && (
                    <span
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #c8c6c6",
                        marginTop: "-2px",
                      }}
                      className="p-1 rounded ml-2 mr-2"
                    >
                      <IconButton
                        style={{ height: "20px" }}
                        onClick={() => {
                          setSelectedExperience(experience);
                          setShowEmploymentDateDialog(true);
                        }}
                        size="small"
                        color="primary"
                      >
                        <span className="material-icons">edit</span>
                      </IconButton>
                    </span>
                  )}
                <p>
                  <IconButton
                    // disableRipple
                    // className="ml-n2"
                    size="small"
                    onClick={() => {
                      if (experience.docReferenceId) {
                        setOnViewDocument(true);
                      } else {
                        if (!!experience.isFromPublicSector) {
                          requestWorkCertificate(
                            {
                              employeeId: experience.userId,
                              employeePositionId: experience.employeePositionId,
                            },
                            setOnViewDocument,
                            experience,
                            setLoadingDocument
                          );
                        } else {
                          updateUserFile(
                            experience,
                            "CHANGE_EXPERIENCE_FILE",
                            experience.userId +
                              "-" +
                              experience.institutionName,
                            setOnViewDocument,
                            setLoadingDocument
                          );
                        }
                      }
                    }}
                  >
                    <FilePresentIcon
                      // fontSize="large"
                      style={{
                        color: !!experience.docReferenceId
                          ? "#fe4066"
                          : "#c57c7cb8",
                      }}
                    />
                  </IconButton>
                </p>

                <IconButton
                  size="small"
                  // className="ml-n2"
                  onClick={handleOpenMenu}
                  aria-label="settings"
                  disabled={!!experience.isFromPublicSector || !canEdit}
                >
                  <Badge color="info">
                    <span className="material-icons">more_vert</span>
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 2,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <span>
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        handleCloseMenu();
                        setSelectedExperience(experience);
                        setShowExperienceForm(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <span className="material-icons mr-1">edit_note</span>
                      Edit
                    </MenuItem>
                  </span>

                  <span>
                    <MenuItem
                      disabled={loading}
                      onClick={() => {
                        handleCloseMenu();
                        setOnDelete(true);
                      }}
                      className="text-danger font-weight-light"
                    >
                      <span className="material-icons mr-1">remove</span> Remove
                    </MenuItem>
                  </span>
                </Menu>
              </div>
            </>
          }
        />
      </Card>

      {!isCurrentInPosition(experience) && !isLastRow && (
        <>
          {" "}
          <Divider variant="fullWidth" />
          <Divider variant="fullWidth" />
        </>
      )}

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserExperience(experience.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this experience?"
          disabled={loading}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Experience document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              experience.docReferenceId,
          }}
        />
      )}
      {/* {confirmToRequestWorkCertificate && (
        <ConfirmationDialog
          confirmationDialog={confirmToRequestWorkCertificate}
          message={`Are you sure you want to request for work certificate for @${experience.positionName}?`}
          setConfirmationDialog={setConfirmToRequestWorkCertificate}
          onYes={() => {
            requestWorkCertificate(
              {
                employeeId: experience.userId,
                employeePositionId: experience.employeePositionId,
              },
              setConfirmToRequestWorkCertificate
            );
          }}
        />
      )} */}

      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={() => {
          setShowEmploymentDateDialog(false);
        }}
        open={showEmploymentDateDialog}
      >
        <DialogTitle>
          <small className="text-primary">Edit Employment Date</small>
          <br />
          <small style={{ fontSize: "13px" }}>
            @{experience.positionName}
          </small>{" "}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowEmploymentDateDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="d-flex justify-content-center py-4">
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Employment Date"
                inputFormat="dd/MM/yyyy"
                maxDate={new Date()}
                value={employmentDate}
                onChange={(date) => {
                  setEmploymentDate(date);
                }}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
            <div className="mt-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={experience.statusId !== 30}
                  label="Exited Date"
                  inputFormat="dd/MM/yyyy"
                  minDate={employmentDate}
                  value={exitedDate}
                  onChange={(date) => {
                    setExitedDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <button
            onClick={() => {
              updateEmployeeEmploymentDate(
                {
                  employeePositionId: experience?.id || null,
                  employmentDate: moment(employmentDate).format("YYYY-MM-DD"),
                  exitedDate: experience.statusId === 30?moment(exitedDate).format("YYYY-MM-DD"):experience.toDate,
                },
                setShowEmploymentDateDialog,
                (data) => {
                  if (!!data) {
                    experience.fromDate = data.startDate;
                    experience.toDate = data.endDate;
                  }
                }
              );
            }}
            type="button"
            disabled={onWait || !employmentDate}
            className="btn btn-primary text-uppercase  mr-3"
          >
            {onWait ? "Wait..." : "Confirm"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
