import { defaultState } from "./state";
import payroll from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...payroll };

const activeContracts = (
  activeContractsState = defaultState.activeContracts,
  action
) => {
  switch (action.type) {
    case types.SET_ACTIVE_CONTRACTS:
      return action.data;

    case types.ADD_NEW_CONTRACT: {
      const tmpState = [...activeContractsState];
      tmpState.unshift(action.data);
      return tmpState;
    }

    case types.UPDATE_CONTRACT: {
      const tmpState = [...activeContractsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return tmpState;

      if (tmpState[index].status === "Expired") {
        tmpState.splice(index, 1);
        return tmpState;
      }

      tmpState[index] = action.data;

      return tmpState;
    }

    case types.SET_SELECTED_CONTRACT: {
      const tmpState = [...activeContractsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return tmpState;

      tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_CONTRACT: {
      const tmpState = [...activeContractsState];
      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index < 0) return tmpState;

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.activeContracts;

    default:
      return activeContractsState;
  }
};

const expiredContracts = (
  expiredContractsState = defaultState.expiredContracts,
  action
) => {
  switch (action.type) {
    case types.SET_EXPIRED_CONTRACTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.expiredContracts;

    default:
      return expiredContractsState;
  }
};

const selectedContract = (
  selectedContractState = defaultState.selectedContract,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_CONTRACT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedContract;

    default:
      return selectedContractState;
  }
};

const positionRequestTypes = (
  positionRequestTypesState = defaultState.positionRequestTypes,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_REQUEST_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionRequestTypes;

    default:
      return positionRequestTypesState;
  }
};

export default {
  activeContracts,
  expiredContracts,
  selectedContract,
  positionRequestTypes,
};
