import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import { Toolbar, Typography, Box, Button } from "@mui/material";
import { getFiscalYears } from "../../../store/rbm/actions";
import { getCountries } from "../../../store/structure/actions";
import {
  getTrainingRequests,
  getCdPlans,
  getSingleTrainingRequest,
} from "../../../store/training/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AddContractRequestForm from "../components/AddContractRequestForm";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const ContractsInprogress = (props) => {
  const { hasPermissions } = useRequiredAuth();
  const [showRequestForm, setShowRequestForm] = useState(true);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>CONTRACTUAL</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    InProgress Requests
                  </span>
                </span>
              </Typography>

              {hasPermissions(["IS_HR"]) && (
                <Button
                  onClick={() => {
                    setShowRequestForm(true);
                  }}
                  style={{
                    ...ActiveButton,
                    // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="md"
                >
                  <span className="material-icons">add</span>Create Request
                </Button>
              )}
            </Toolbar>
          </AppBar>
        </div>
      </Box>

      {showRequestForm && (
        <AddContractRequestForm setShowRequestForm={setShowRequestForm} />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  countries,
  trainingRequests,
  cdPlans,
  fiscalYears,
}) => {
  return { user, loading, countries, trainingRequests, cdPlans, fiscalYears };
};
export default connect(mapStateToProps, {
  getCountries,
  getTrainingRequests,
  getFiscalYears,
  getCdPlans,
  getSingleTrainingRequest,
})(ContractsInprogress);
