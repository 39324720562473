import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveOrUpdatePermission
} from "../../../store/admin/actions";
import { showError } from "../../../app/toastify";

const AddOrUpdatePermissionDialog = (props) => {
  const {
    loading,
    showPermissionForm,
    setShowPermissionForm,
    selectedPermission,
    saveOrUpdatePermission
  } = props;

  const [formData, setFormData] = useState({
    name: ""
  });

  const [errors, setErrors] = useState({
    nmeHasError: false
  });


  const formValidator = () => {
  
    const error = {
      nameHasError: false,
      hasError:false
    };

    if (!formData.name) {
      error.nameHasError = true;
      error.hasError=true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {

    if (!formValidator()) {

          saveOrUpdatePermission(
            formData,
            setFormData,
            setShowPermissionForm
          );
    }

 
  };

  const onClose = () => {
    setShowPermissionForm(false);

    setFormData({
      name: ""
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPermissionForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Add New Permission
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            size="small"
            rows={4}
            name="name"
            autoFocus
            label="Permission"
            variant="outlined"
            placeholder="Permission"
            className="mb-3"
            value={formData.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => {
              const name = e.target.value.toUpperCase().replace(/ /g,"_");
             
              const nameHasError =false;
              setErrors({...errors, nameHasError});
              setFormData({ ...formData, name });
            }}

          />
          {errors.nameHasError && (
              <div className="text-danger mb-2">
                 Permission is required{" "}
              </div>
            )}

     
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading}) => {
  return {
    loading
  };
};
export default connect(mapStateToProps, {
  saveOrUpdatePermission
})(AddOrUpdatePermissionDialog);
