import { defaultState } from "./state";
import training from "./action-types";
import commonTypes from "../common/action-types";

const types = { ...commonTypes, ...training };

const trainingRequests = (
  trainingRequestsState = defaultState.trainingRequests,
  action
) => {
  switch (action.type) {
    case types.SET_TRAINING_REQUESTS:
      return action.data;
    case types.ADD_TRAINING_REQUEST: {
      const tmpTrainingRequestsState = [...trainingRequestsState];
      tmpTrainingRequestsState.unshift(action.data);
      return tmpTrainingRequestsState;
    }

    case types.UPDATE_TRAINING_REQUEST: {
      const tmpTrainingRequestsState = [...trainingRequestsState];
      const index = tmpTrainingRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpTrainingRequestsState[index] = action.data;

      return tmpTrainingRequestsState;
    }

    case types.DELETE_TRAINING_REQUEST:
      const tmpTrainingRequestsState = [...trainingRequestsState];
      const index = tmpTrainingRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpTrainingRequestsState.splice(index, 1);

      return tmpTrainingRequestsState;
    case types.CLEAN_STATE:
      return defaultState.trainingRequests;
    default:
      return trainingRequestsState;
  }
};

const cdPlans = (cdPlansState = defaultState.cdPlans, action) => {
  switch (action.type) {
    case types.SET_CD_PLANS:
      return action.data;
    case types.ADD_CD_PLAN: {
      const tmpCdPlans = [...cdPlansState];
      tmpCdPlans.push(action.data);
      return tmpCdPlans;
    }

    case types.DELETE_CD_PLAN:
      const tmpCdPlansState = [...cdPlansState];
      const index = tmpCdPlansState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpCdPlansState.splice(index, 1);

      return tmpCdPlansState;
    case types.CLEAN_STATE:
      return defaultState.cdPlans;
    default:
      return cdPlansState;
  }
};

const trainingRequest = (
  trainingRequestState = defaultState.trainingRequest,
  action
) => {
  switch (action.type) {
    case types.SET_TRAINING_REQUEST:
      return action.data;
    case types.UPDATE_TRAINING_ATTACHMENTS:
        const tmpTrainingRequestState = trainingRequestState;
          tmpTrainingRequestState.trainingRequestAttachments=action.data;
        return tmpTrainingRequestState;

    case types.CLEAN_STATE:
      return defaultState.trainingRequest;
    default:
      return trainingRequestState;
  }
};

const rejectedTrainingRequests = (
  rejectedTrainingRequestsState = defaultState.rejectedTrainingRequests,
  action
) => {
  switch (action.type) {
    case types.SET_REJECTED_TRAINING_REQUESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.rejectedTrainingRequests;
    default:
      return rejectedTrainingRequestsState;
  }
};

const approvedTrainingRequests = (
  approvedTrainingRequestsState = defaultState.approvedTrainingRequests,
  action
) => {
  switch (action.type) {
    case types.SET_APPROVED_TRAINING_REQUESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.approvedTrainingRequests;
    default:
      return approvedTrainingRequestsState;
  }
};
const oversightTrainingRequests = (
  oversightTrainingRequestsState = defaultState.oversightTrainingRequests,
  action
) => {
  switch (action.type) {
    case types.SET_OVERSIGHT_TRAINING_REQUESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.oversightTrainingRequests;
    default:
      return oversightTrainingRequestsState;
  }
};

const oversightSummary = (
  oversightSummaryState = defaultState.oversightSummary,
  action
) => {
  switch (action.type) {
    case types.SET_OVERSIGHT_TRAINING_SUMMARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.oversightSummary;
    default:
      return oversightSummaryState;
  }
};

//
const requestTimelines = (
  requestTimelinesState = defaultState.requestTimelines,
  action
) => {
  switch (action.type) {
    case types.SET_REQUEST_TIMELINE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requestTimelines;
    default:
      return requestTimelinesState;
  }
};

export default {
  trainingRequests,
  trainingRequest,
  rejectedTrainingRequests,
  approvedTrainingRequests,
  oversightTrainingRequests,
  cdPlans,
  oversightSummary,
  requestTimelines
};
