import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const BackdropLoader = (props) => {
  const { isLoading } = props;
  return (
    <>
      {isLoading && (
        <CircularProgress
          // variant="determinate"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "45%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
          {...props}
          // value={100}
        />
      )}
    </>
  );
};

export default BackdropLoader;
