import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import ErrorMessage from "./ErrorMessage";
import { useDispatch } from "react-redux";
import { showError } from "../../toastify";

const EditUserDigitalCertificateDialog = (props) => {
  const {
    showEditUserDigitalCertificateDialog,
    setShowEditUserDigitalCertificateDialog,
    user,
  } = props;

  const dispatch = useDispatch();
  const onClose = () => {
    setShowEditUserDigitalCertificateDialog(false);
  };
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState(null);
  const [errors, setErrors] = useState({
    certificateAttachmentHasError: false,
  });

  const [isSaving, setIsSaving] = useState(false);

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    // let fileSize = 0;
    // if (size < 1000 * 1000 * 1000) {
    //   fileSize = size / 1000 / 1000;
    // }
    return size > 1074176 ? true : false;
  };

  const handleUploadFile = (event) => {
    let certificateAttachment = event.target.files[0];

    setErrors({ ...errors, certificateAttachmentHasError: false });
    setFile(null);
    if (
      !(
        certificateAttachment.type === "application/x-pkcs12" &&
        convertToLower(certificateAttachment.name.substr(-4)) === ".pfx"
      )
    ) {
      setErrors({ ...errors, certificateAttachmentHasError: true });
      showError("File should be Pfx format");
    } else {
      setFile(certificateAttachment || null);
    }
  };

  const onSave = () => {
    if (!password) {
      showError("Password is required");
      return;
    }
    if (!file || !!errors.certificateAttachmentHasError) {
      showError("File is required");
      return;
    }
    const formData = new FormData();

    formData.append("userId", user.id);
    formData.append("file", file);
    formData.append("password", password);
    saveUserDigitalCert(formData, user);
  };

  const saveUserDigitalCert = async (formData, user) => {
    try {
      setIsSaving(true);
      const { data } = await axios.post(
        "/api/upload/digital-certificate",
        formData
      );

      user.dcertDocReferenceId = data;

      dispatch({
        type: "SET_USER",
        data: user,
      });

      setShowEditUserDigitalCertificateDialog(false);

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
      showError(error);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditUserDigitalCertificateDialog}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">
            Set Digital Certificate
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
        <form>
          <div className="row">
            <div className={`col-12`}>
              <div className="col-lg-12 mt-3">
                Upload Digital Certificate(* .pfx)
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-paperclip"></i>
                    </span>
                  </div>

                  <input
                    // disabled={!questionType}
                    type="file"
                    name="file"
                    className="form-control"
                    accept="pfx"
                    placeholder="Select digital certificate file"
                    onChange={(e) => handleUploadFile(e)}
                  />
                </div>
                <ErrorMessage
                  hasError={errors.certificateAttachmentHasError}
                  message={
                    <>
                      <div>
                        Please check following issues:
                        <br />* File should be pfx format.
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            <div className={`col-12 mt-2`}>
              <div className="col-lg-12 mt-3">
                Your Digital Certificate Password
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>

                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 d-flex justify-content-center py-3">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase  px-4"
                disabled={isSaving}
              >
                {isSaving ? "Wait..." : "Submit"}
              </button>
            </div>
          </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditUserDigitalCertificateDialog;
