import {
  Tooltip,
  Card,
  Typography,
  CardHeader,
  IconButton,
  CircularProgress,
  Box,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Chip,
  Avatar,
} from "@mui/material";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  deleteIndicator,
  approveIndicator,
  getIndicatorAssignments,
} from "../../../store/rbm/actions";
import types from "../../../store/rbm/action-types";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import IndicatorAssignmentForm from "./IndicatorAssignmentForm";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ActivitiesDialog from "./ActivitiesDialog";
import defaultProfile from "../../assets/default-profile.jpg";
import { removeDuplicateIndicatorAssignments } from "../../common/components/Utils";

const IndicatorCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    selectedOutput,
    indicator,
    index,
    setIsEditing,
    setShowIndicatorForm,
    deleteIndicator,
    approveIndicator,
    user,
    isChildIndicator,
    getIndicatorAssignments,
    loading,
    isForContract,
    setShowReport,
    isForMonitoring,
  } = props;
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmApproval, setConfirmApproval] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(true);
  const [showAssignmentForm, setShowAssignmentForm] = useState(false);
  const history = useHistory();

  const [score, setScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScore(indicator.score);
    }, 500);
  }, [indicator]);

  const { hasPermissions } = useRequiredAuth();
  const [showActivitiesDialog, setShowActivitiesDialog] = useState(false);

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  return (
    <>
      <Card
        className={`my-2 rounded hoverable-card`}
        variant="outlined"
        style={{
          // backgroundColor: isChildIndicator ? "#f6f8fa" : "#fff",
          // borderTop: isChildIndicator ? "none" : "",
          // borderLeft: isChildIndicator ? "none" : "",
          // borderRight: isChildIndicator ? "none" : "",
          border: "none",
        }}
      >
        <CardHeader
          className="pb-0 pt-2"
          avatar={
            <Tooltip title="Progress">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    score < 50
                      ? "danger"
                      : score < 70
                      ? "warning"
                      : score < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={score}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      score < 50
                        ? "danger"
                        : score < 70
                        ? "warning"
                        : score < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${score.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          }
          action={
            <Box className="d-flex align-items-center">
              <span className="d-none d-md-inline">
                <span className="text-center d-block">
                  <span
                    className={`d-flex align-items-center text-center flex-wrap ${
                      hasPermissions(["CAN_ADD_NEW_INDICATOR"]) ? "mr-4" : ""
                    }`}
                  >
                    <Tooltip
                      title="Activities"
                      className={`${
                        hasPermissions(["CAN_ADD_NEW_INDICATOR"]) ? "mr-3" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="mt-2 mr-n4 "
                        style={{ textDecoration: "none" }}
                      >
                        {/* <CustomProgressLine value={indicator.score} /> */}

                        <button
                          type="button"
                          className={`btn text-center text-uppercase btn-link btn-sm d-flex align-items-center ${
                            indicator.numOfActivities === 0
                              ? " text-danger"
                              : "text-info"
                          }`}
                          onClick={() => {
                            setShowActivitiesDialog(true);
                          }}
                        >
                          <Badge
                            color="error"
                            variant="dot"
                            invisible={
                              !indicator.hasReportedActivity ||
                              !hasPermissions([
                                "CAN_VIEW_RBM_INDICATOR_MORE_ICON",
                              ])
                            }
                          >
                            {indicator.numOfActivities} Activit
                            {indicator.numOfActivities > 1 ? "ies" : "y"}{" "}
                          </Badge>
                        </button>
                        {!!indicator.unassignedActivities && (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowActivitiesDialog(true);
                            }}
                            className="badge text-danger"
                          >
                            {indicator.unassignedActivities} Unassigned
                          </span>
                        )}
                        {!indicator.unassignedActivities &&
                          indicator.numOfActivities > 0 && (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowActivitiesDialog(true);
                              }}
                              className="badge text-success"
                            >
                              <i className="fas fa-check"></i> All assigned
                            </span>
                          )}
                      </Link>
                    </Tooltip>
                  </span>
                  {isForContract && (
                    <span>
                      {indicator.status === "Pending" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-dark mx-3 ">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            pending_actions
                          </span>
                          <span style={{ fontSize: "10px" }}> Pending...</span>
                        </span>
                      )}
                      {indicator.status === "Scored" && (
                        <span
                          className={`d-flex align-items-center justify-content-center badge badge-success mx-3 `}
                        >
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            done_all
                          </span>
                          <span style={{ fontSize: "10px" }}> Scored</span>
                        </span>
                      )}
                      {indicator.status === "Reported" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-primary mx-3">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons "
                          >
                            file_present
                          </span>
                          <span style={{ fontSize: "10px" }}> Reported</span>
                        </span>
                      )}

                      {indicator.status === "Rejected" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-danger mx-3">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons   "
                          >
                            close
                          </span>
                          <span style={{ fontSize: "10px" }}> Rejected</span>
                        </span>
                      )}
                      {indicator.status === "Overdue" && (
                        <span className="d-flex align-items-center justify-content-center badge badge-danger mx-3">
                          <span
                            style={{ fontSize: "17px" }}
                            className="material-icons"
                          >
                            error
                          </span>
                          <span style={{ fontSize: "10px" }}> Overdue</span>
                        </span>
                      )}
                    </span>
                  )}
                </span>
              </span>

              {!isForMonitoring && (
                <span>
                  <IconButton
                    size="small"
                    className="ml-2"
                    onClick={handleOpenMenu}
                    aria-label="settings"
                  >
                    <span className="material-icons">more_vert</span>
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem disabled>MENUS</MenuItem>
                    <Divider className="my-0" />
                    <MenuItem
                      onClick={() => {
                        setShowActivitiesDialog(true);
                        handleCloseMenu();
                      }}
                      className="text-dark font-weight-light"
                    >
                      <span className="material-icons mr-2">launch</span>
                      Activities
                    </MenuItem>

                    {!isForContract &&
                      hasPermissions(["CAN_ADD_NEW_INDICATOR"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              dispatch({
                                type: types.SET_SELECTED_INDICATOR,
                                data: indicator,
                              });
                              setIsEditing(true);
                              setShowIndicatorForm(true);
                            }}
                            className="text-dark font-weight-light"
                          >
                            <span className="material-icons mr-2">edit</span>{" "}
                            Edit
                          </MenuItem>
                        </span>
                      )}

                    {isForContract && (
                      <span>
                        <Divider className="my-0" />
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            setShowReport(indicator);
                          }}
                          className="text-dark font-weight-light"
                        >
                          <Badge color="error" variant="dot" invisible>
                            <span className="material-icons mr-2">
                              file_present
                            </span>{" "}
                            Go to reports
                          </Badge>
                        </MenuItem>
                      </span>
                    )}

                    {!isForContract &&
                      hasPermissions(["CAN_ASSIGN_INDICATOR"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              setShowAssignmentForm(true);
                            }}
                            className="text-dark font-weight-light"
                          >
                            <span className="material-icons mr-2">people</span>
                            Assign Indicator
                          </MenuItem>
                        </span>
                      )}

                    {!isForContract &&
                      hasPermissions(["CAN_ADD_NEW_INDICATOR"]) && (
                        <span>
                          <Divider className="my-1" />
                          <MenuItem
                            onClick={() => {
                              handleCloseMenu();
                              setConfirmDelete(true);
                            }}
                            className="text-danger font-weight-light"
                          >
                            <span className="material-icons mr-2">delete</span>{" "}
                            Delete
                          </MenuItem>
                        </span>
                      )}
                  </Menu>
                </span>
              )}
            </Box>
          }
          title={
            <span className=" text-uppercase">
              <strong> INDICATOR {indicator.no || index + 1}: </strong>
              {indicator.name}
            </span>
          }
          subheader={
            <span className="d-flex align-items-center flex-wrap">
              <Tooltip title="Weight">
                <span
                  className="align-items-center mr-1"
                  style={{
                    cursor: "default",
                  }}
                >
                  <span className="badge badge-pill px-0">
                    {weights.find(({ id }) => id === indicator.weight)?.name ||
                      "N/A"}
                  </span>
                </span>
              </Tooltip>

              <span className="d-flex align-items-center flex-wrap">
                {indicator.itemTypeId === 2 && (
                  <>
                    <Tooltip title="Measurement Unit">
                      <span
                        className=" d-flex align-items-center px-1 text-info"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",
                        }}
                      >
                        <strong>Unit:</strong>
                        {indicator.measurementUnit}
                      </span>
                    </Tooltip>

                    <Tooltip title="Baseline">
                      <span
                        className="d-flex align-items-center px-1 text-info "
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",
                        }}
                      >
                        <strong>Baseline:</strong> {indicator.baseline}
                      </span>
                    </Tooltip>
                  </>
                )}

                <Tooltip title="Annual Target">
                  <span
                    className=" d-flex align-items-center px-1 text-info mr-2 "
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      cursor: "default",
                    }}
                  >
                    <strong>Target:</strong> {indicator.annualTarget}{" "}
                    {indicator.measurementUnit
                      .toLowerCase()
                      .charAt(0)
                      .toUpperCase() +
                      indicator.measurementUnit.slice(1).toLowerCase()}
                  </span>
                </Tooltip>

                {!!indicator.isRRAKey && (
                  <span
                    style={{ cursor: "default" }}
                    className="badge text-primary ml-2"
                  >
                    <i className="fas fa-key"></i> Key departmental priority
                  </span>
                )}
              </span>
            </span>
          }
        />
        <CardHeader
          className="ml-5 pt-2 py-2 pl-4"
          subheader={
            <span className="d-flex align-items-center  flex-wrap justify-content-between ">
              <span className="d-flex align-items-center  flex-wrap">
                {indicator.itemTypeId === 2 && (
                  <>
                    <Tooltip title="Quarter-1 Target">
                      <span
                        className={`d-flex align-items-center  px-2 ${
                          indicator.quarter1Target > 0
                            ? "text-light"
                            : "bg-light"
                        }`}
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",

                          backgroundColor:
                            indicator.quarter1Target > 0 ? "#697981" : "",
                          fontWeight: "lighter",
                          fontSize: "13px",
                        }}
                      >
                        Q<sub>1</sub>: {indicator.quarter1Target}{" "}
                        {indicator.quarter1Target > 0 && (
                          <>
                            {indicator.measurementUnit
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                              indicator.measurementUnit.slice(1).toLowerCase()}
                          </>
                        )}
                      </span>
                    </Tooltip>
                    <Tooltip title="Quarter-2 Target">
                      <span
                        className={`d-flex align-items-center  px-2 ${
                          indicator.quarter2Target > 0
                            ? " text-light"
                            : "bg-light"
                        }`}
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",
                          backgroundColor:
                            indicator.quarter2Target > 0 ? "#697981" : "",
                          fontWeight: "lighter",
                          fontSize: "13px",
                        }}
                      >
                        Q<sub>2</sub>: {indicator.quarter2Target}{" "}
                        {indicator.quarter2Target > 0 && (
                          <>
                            {indicator.measurementUnit
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                              indicator.measurementUnit.slice(1).toLowerCase()}
                          </>
                        )}
                      </span>
                    </Tooltip>
                    <Tooltip title="Quarter-3 Target">
                      <span
                        className={`d-flex align-items-center  px-2 ${
                          indicator.quarter3Target > 0
                            ? " text-light"
                            : "bg-light"
                        }`}
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",
                          backgroundColor:
                            indicator.quarter3Target > 0 ? "#697981" : "",
                          fontWeight: "lighter",
                          fontSize: "13px",
                        }}
                      >
                        Q<sub>3</sub>: {indicator.quarter3Target}{" "}
                        {indicator.quarter3Target > 0 && (
                          <>
                            {indicator.measurementUnit
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                              indicator.measurementUnit.slice(1).toLowerCase()}
                          </>
                        )}
                      </span>
                    </Tooltip>
                    <Tooltip title="Quarter-4 Target">
                      <span
                        className={`d-flex align-items-center mr-2  px-2 ${
                          indicator.quarter4Target > 0
                            ? " text-light"
                            : "bg-light"
                        }`}
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          cursor: "default",
                          backgroundColor:
                            indicator.quarter4Target > 0 ? "#697981" : "",
                          fontWeight: "lighter",
                          fontSize: "13px",
                        }}
                      >
                        Q<sub>4</sub>: {indicator.quarter4Target}{" "}
                        {indicator.quarter4Target > 0 && (
                          <>
                            {indicator.measurementUnit
                              .toLowerCase()
                              .charAt(0)
                              .toUpperCase() +
                              indicator.measurementUnit.slice(1).toLowerCase()}
                          </>
                        )}
                      </span>
                    </Tooltip>
                  </>
                )}

                {!isForContract && (
                  <span className="d-flex align-items-center flex-wrap ">
                    {!indicator.assignments[0] && (
                      <Tooltip title="Assign Indicator">
                        <button
                          onClick={() => {
                            if (
                              hasPermissions(["CAN_ASSIGN_INDICATOR"]) &&
                              indicator.itemTypeId === 2
                            )
                              setShowAssignmentForm(true);
                          }}
                          className="badge badge-warning ml-1 text-danger border"
                          type="button"
                        >
                          {hasPermissions(["CAN_ASSIGN_INDICATOR"])
                            ? "Assign Indicator"
                            : "Not Assigned"}
                        </button>
                      </Tooltip>
                    )}

                    <span className="border rounded">
                      {removeDuplicateIndicatorAssignments(
                        indicator.assignments
                      ).map((assignment) => (
                        <Tooltip
                          key={assignment.employeePositionId}
                          title={`${assignment.lastName} ${assignment.firstName} assigned`}
                        >
                          <Chip
                            size="small"
                            className="border border-light mr-1"
                            avatar={
                              <Avatar
                                style={{ border: "1px solid #d1cbcb" }}
                                alt={assignment.firstName}
                                src={
                                  assignment.profileImage
                                    ? `data:image/jpeg;base64,${assignment.profileImage}`
                                    : defaultProfile
                                }
                              />
                            }
                            label={assignment.firstName}
                            variant="outlined"
                          />
                        </Tooltip>
                      ))}
                      {hasPermissions(["CAN_ASSIGN_INDICATOR"]) &&
                        !isForMonitoring &&
                        indicator.itemTypeId === 2 && (
                          <Tooltip title={`Assign Indicator`}>
                            <IconButton
                              color="primary"
                              size="small"
                              className="p-0"
                              onClick={() => {
                                setShowAssignmentForm(true);
                                handleCloseMenu();
                              }}
                            >
                              <span className="material-icons">add</span>
                            </IconButton>
                          </Tooltip>
                        )}
                    </span>
                  </span>
                )}
              </span>
            </span>
          }
        />
      </Card>
      <Divider />
      <Divider />
      {confirmDelete && (
        <ConfirmationDialog
          confirmationDialog={confirmDelete}
          message="Are you sure you want to delete Indicator?"
          setConfirmationDialog={setConfirmDelete}
          onYes={() => {
            deleteIndicator(indicator, setConfirmDelete);
          }}
        />
      )}

      {showAssignmentForm && (
        <IndicatorAssignmentForm
          selectedIndicator={indicator}
          // assignment={
          //   !!indicator.unitId
          //     ? {
          //         unitId: indicator.unitId,
          //         unitName: indicator.unitName,
          //         parentUnitId: indicator.parentUnitId,
          //       }
          //     : null
          // }
          showAssignmentForm={showAssignmentForm}
          setShowAssignmentForm={setShowAssignmentForm}
        />
      )}
      {confirmApproval && (
        <ConfirmationDialog
          color="danger"
          confirmationDialog={confirmApproval}
          message={`Are you sure you want to ${
            approvalStatus ? "approve" : "disapprove"
          } this indicator?`}
          setConfirmationDialog={setConfirmApproval}
          onYes={() => {
            approveIndicator(indicator.id, approvalStatus, setConfirmApproval);
          }}
        />
      )}

      {showActivitiesDialog && (
        <ActivitiesDialog
          showDialog={showActivitiesDialog}
          setShowDialog={setShowActivitiesDialog}
          selectedIndicator={indicator}
          selectedOutput={selectedOutput}
          isForMonitoring={isForMonitoring}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  deleteIndicator,
  approveIndicator,
  getIndicatorAssignments,
})(IndicatorCard);
