import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  getExitYearAndMonths,
  loadExitDashboard,
  loadExitDashboardByExitType,
  loadExitDashboardBySectorId,
} from "../../../store/admin/actions";
import {
  DashboardDoughnutChart,
  DashboardLabel,
  DashboardLineChart,
  DashboardPieChart,
} from "../../admin/components/StructureDashboard";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import TableSkeleton from "../../common/components/TableSkeleton";

const DEFAULT_MONTH = {
  exMonthKey: "ALL",
  exMonthName: "ALL",
};

const Dashboard = (props) => {
  const {
    loading,
    exitMonthYears,
    loadExitDashboard,
    loadExitDashboardByExitType,
    loadExitDashboardBySectorId,
    getExitYearAndMonths,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [loadingYears, setLoadingYears] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [viewExitByType, setViewExitByType] = useState(null);
  const [viewExitBySector, setViewExitBySector] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filterYearStatus, setFilterYearStatus] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(DEFAULT_MONTH);
  const [filterMonthStatus, setFilterMonthStatus] = useState(false);

  useEffect(() => {
    if (exitMonthYears === null || exitMonthYears.years.length === 0) {
      getExitYearAndMonths((status, data) => {
        setLoadingYears(status);
        setSelectedYear(data);
      });
    } else {
      setSelectedYear(exitMonthYears.years[0] || null);
      setLoadingYears(false);
    }
    return () => {
      setLoadingYears(true);
      setSelectedYear(null);
      setFilterYearStatus(false);
      setSelectedMonth(DEFAULT_MONTH);
      setFilterMonthStatus(false);
    };
  }, []);

  useEffect(() => {
    if (!loadingYears && selectedYear) {
      loadExitDashboard(
        selectedMonth.exMonthKey,
        selectedYear.exYear,
        "NULL",
        (status, data) => {
          setLoadingData(status);
          setDashboardData(data);
        }
      );
    }
  }, [selectedYear, selectedMonth]);

  const getPercentage = (num, total) => {
    return ((num / total) * 100).toFixed(0);
  };

  const handleViewExitByType = (entityType) => {
    loadExitDashboardByExitType("ALL", "ALL", entityType.id, (status, data) => {
      data && setViewExitByType({ entityType, data });
    });
  };
  const handleViewExitBySector = (sector) => {
    loadExitDashboardBySectorId("ALL", "ALL", sector.id, (status, data) => {
      data && setViewExitBySector({ sector, data });
    });
  };

  const DATA_YearOption = exitMonthYears
    ? [{ exYear: "ALL" }, ...exitMonthYears.years]
    : [];
  const DATA_MonthOption = exitMonthYears
    ? [DEFAULT_MONTH, ...exitMonthYears.months]
    : [];

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center my-2">
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { sm: "none", md: "block" } }}
        >
          <span className="lead text-uppercase font-weight-semibold">
            EXIT & TERMINATION DASHBOARD
          </span>
        </Typography>
        <div className="d-flex align-items-center">
          {filterYearStatus && (
            <Autocomplete
              className="ml-2"
              disabled={loadingYears || loading}
              disablePortal
              getOptionLabel={(option) => option.exYear}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.exYear}
                </Box>
              )}
              options={DATA_YearOption}
              sx={{ width: 120 }}
              size="small"
              value={selectedYear || null}
              isOptionEqualToValue={(option, value) =>
                option.exYear === value.exYear
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose year"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "year",
                  }}
                />
              )}
              onChange={(event, year) => {
                setSelectedYear(year || null);
                year &&
                  year.exYear === "ALL" &&
                  setSelectedMonth(DEFAULT_MONTH);
                setFilterYearStatus(false);
              }}
            />
          )}
          {!filterYearStatus && (
            <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
              {selectedYear && (
                <span className="font-weight-normal mr-1">
                  {selectedYear.exYear}
                </span>
              )}
              <IconButton
                color="primary"
                aria-label="filter by exit year"
                component="label"
                size="small"
                disabled={loadingYears || loadingData || loading}
                onClick={() => {
                  setFilterYearStatus(true);
                  setFilterMonthStatus(false);
                }}
              >
                <span className="material-icons-outlined">filter_list</span>
              </IconButton>
            </div>
          )}
          {filterMonthStatus && (
            <Autocomplete
              className="ml-2"
              disabled={loadingYears || loading}
              disablePortal
              getOptionLabel={(option) => option.exMonthKey}
              renderOption={(props, field) => (
                <Box component="li" {...props}>
                  {field?.exMonthName}
                </Box>
              )}
              options={DATA_MonthOption}
              sx={{ width: 120 }}
              size="small"
              value={selectedMonth || null}
              isOptionEqualToValue={(option, value) =>
                option.exMonthKey === value.exMonthKey
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose month"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "month",
                  }}
                />
              )}
              onChange={(event, month) => {
                setSelectedMonth(month || null);
                setFilterMonthStatus(false);
              }}
            />
          )}
          {!filterMonthStatus && (
            <div className="d-flex flex-row align-items-center rounded border px-1 ml-2">
              {selectedMonth && (
                <span className="font-weight-normal mr-1">
                  {selectedMonth.exMonthName}
                </span>
              )}
              <IconButton
                color="primary"
                aria-label="filter by exit year and month"
                component="label"
                size="small"
                disabled={
                  (selectedYear && selectedYear.exYear === "ALL") ||
                  loadingYears ||
                  loadingData ||
                  loading
                }
                onClick={() => {
                  setFilterMonthStatus(true);
                  setFilterYearStatus(false);
                }}
              >
                <span className="material-icons-outlined">filter_list</span>
              </IconButton>
            </div>
          )}
        </div>
      </div>

      {/* labels */}
      <div className="d-flex flex-wrap justify-content-center w-100">
        <DashboardLabel
          labelTitle="Dismissal"
          labelValue={dashboardData ? dashboardData.LABELS.dismissal : 0}
          loading={loadingData}
        />
        <DashboardLabel
          labelTitle="Retirement"
          labelValue={dashboardData ? dashboardData.LABELS.retirement : 0}
          loading={loadingData}
        />
        <DashboardLabel
          labelTitle="Death"
          labelValue={dashboardData ? dashboardData.LABELS.dead : 0}
          loading={loadingData}
        />
        <DashboardLabel
          labelTitle="Blacklisted"
          labelValue={dashboardData ? dashboardData.LABELS.blacklisted : 0}
          loading={loadingData}
        />
        <DashboardLabel
          labelTitle="Rehabilitated"
          labelValue={dashboardData ? dashboardData.LABELS.rehabilitated : 0}
          loading={loadingData}
        />
      </div>

      {/* pie and doughnut charts */}
      <div className="row justify-content-center w-100 my-sm-3 px-4">
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardPieChart
            pieTitle="Exit Category"
            pieData={dashboardData ? dashboardData.SOURCE_TYPE_PIE_CHART : {}}
            loading={loadingData || !dashboardData}
          />
        </div>
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardPieChart
            pieTitle="Gender"
            pieData={dashboardData ? dashboardData.GENDER_PIE_CHART : {}}
            loading={loadingData || !dashboardData}
          />
        </div>
        <div className="col-6 col-md-3 mb-2 mb-sm-0">
          <DashboardDoughnutChart
            chartTitle="Employee Group"
            chartData={
              dashboardData ? dashboardData.EMP_GROUP_DOUGHNUT_CHART : {}
            }
            loading={loadingData || !dashboardData}
          />
        </div>
      </div>

      <div className="my-5">
        <DashboardLineChart
          chartTitle="Exit variations"
          chartData={dashboardData ? dashboardData.EXIT_LINE_CHART : {}}
          loading={loadingData || !dashboardData}
        />
      </div>

      <div className="row justify-content-between mb-5">
        {/* table exit types */}
        <div className="col-12 col-md-7 mb-5 mb-md-0">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Exit types
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    #
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Exit type
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Number of exits(%)
                  </th>
                </tr>
              </thead>
              {dashboardData && !loadingData ? (
                <tbody style={{ fontSize: "13px" }}>
                  {dashboardData.exitData.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.exitTypeName}
                      </td>
                      <td
                        className={`text-center ${
                          item.totalExit > 0 && !loading && !loadingData
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                        }}
                        onClick={() =>
                          item.totalExit > 0 &&
                          !loading &&
                          !loadingData &&
                          handleViewExitByType({
                            id: item.exitTypeId,
                            name: item.exitTypeName,
                          })
                        }
                      >
                        <span className="font-weight-bold">
                          {item.totalExit}
                        </span>{" "}
                        (
                        {getPercentage(
                          item.totalExit,
                          dashboardData.TOTAL_EXIT
                        )}
                        %)
                        {item.totalExit > 0 && (
                          <div className={`float-right td-clickable-icon`}>
                            <IconButton
                              aria-label="view positions"
                              size="small"
                            >
                              <RemoveRedEyeRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}

                  {dashboardData.exitData.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                        colSpan={2}
                      >
                        Total
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {dashboardData.TOTAL_EXIT} (100%)
                      </th>
                    </tr>
                  )}
                </tbody>
              ) : (
                <TableSkeleton cols={3} />
              )}
            </table>
          </div>
        </div>

        {/* table exit sectors */}
        <div className="col-12 col-md-5">
          <div
            className="rounded py-2 px-2 dashboard-card"
            style={{ border: "1px solid rgb(198 205 209)" }}
          >
            <table className="table table-sm">
              <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
                Sectors
              </caption>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    #
                  </th>
                  <th
                    className="text-left"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Sector
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Number of exits(%)
                  </th>
                </tr>
              </thead>
              {dashboardData && !loadingData ? (
                <tbody style={{ fontSize: "13px" }}>
                  {dashboardData.sectorExists.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{ borderLeft: "3px solid #dee2e6" }}
                      >
                        {item.sectorName}
                      </td>
                      <td
                        className={`text-center ${
                          item.totalExit > 0 && !loading && !loadingData
                            ? "td-clickable"
                            : ""
                        }`}
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                        }}
                        onClick={() =>
                          item.totalExit > 0 &&
                          !loading &&
                          !loadingData &&
                          handleViewExitBySector({
                            id: item.sectorId,
                            name: item.sectorName,
                          })
                        }
                      >
                        <span className="font-weight-bold">
                          {item.totalExit}
                        </span>{" "}
                        (
                        {getPercentage(
                          item.totalExit,
                          dashboardData.TOTAL_EXIT
                        )}
                        %)
                        {item.totalExit > 0 && (
                          <div className={`float-right td-clickable-icon`}>
                            <IconButton
                              aria-label="view positions"
                              size="small"
                            >
                              <RemoveRedEyeRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}

                  {dashboardData.sectorExists.length && (
                    <tr>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                        colSpan={2}
                      >
                        Total
                      </th>
                      <th
                        style={{ border: "3px solid #dee2e6" }}
                        className="text-center bg-light "
                      >
                        {dashboardData.TOTAL_EXIT} (100%)
                      </th>
                    </tr>
                  )}
                </tbody>
              ) : (
                <TableSkeleton cols={3} />
              )}
            </table>
          </div>
        </div>
      </div>

      {/* models */}
      {viewExitByType && (
        <Dialog
          onClose={() => setViewExitByType(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of exit by exit type
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewExitByType(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div
              className="w-100 text-center font-weight-bold text-primary mb-1"
              style={{ fontSize: "22px" }}
            >
              {viewExitByType.entityType.name}
            </div>

            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-center">Employee ID</th>
                  <th className="text-left">Employee Name</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">National Id</th>
                  <th className="text-left">Gender</th>
                  <th className="text-left">Position</th>
                </tr>
              </thead>
              <tbody>
                {viewExitByType.data.map((item, index) => (
                  <tr key={index}>
                    <th className="text-center">{item.employeeId}</th>
                    <td align="left">{item.employeeNames}</td>
                    <td align="left">{item.email}</td>
                    <td align="left">{item.idNumber}</td>
                    <td align="left">
                      {item.genderId === "M"
                        ? "Male"
                        : item.genderId === "F"
                        ? "Female"
                        : "Other"}
                    </td>
                    <td align="left">{item.positionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {viewExitBySector && (
        <Dialog
          onClose={() => setViewExitBySector(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              List of exit by sector
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setViewExitBySector(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div
              className="w-100 text-center font-weight-bold text-primary mb-1"
              style={{ fontSize: "22px" }}
            >
              {viewExitBySector.sector.name}
            </div>

            <table
              className={`table table-sm ${
                loadingData ? "" : "table-striped"
              } fixTableHead`}
            >
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th className="text-center">Employee ID</th>
                  <th className="text-left">Employee Name</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">National Id</th>
                  <th className="text-left">Gender</th>
                  <th className="text-left">Exit type</th>
                  <th className="text-left">Position</th>
                </tr>
              </thead>
              <tbody>
                {viewExitBySector.data.map((item, index) => (
                  <tr key={index}>
                    <th className="text-center">{item.employeeId}</th>
                    <td align="left">{item.employeeNames}</td>
                    <td align="left">{item.email}</td>
                    <td align="left">{item.idNumber}</td>
                    <td align="left">
                      {item.genderId === "M"
                        ? "Male"
                        : item.genderId === "F"
                        ? "Female"
                        : "Other"}
                    </td>
                    <td align="left">{item.exitName}</td>
                    <td align="left">{item.positionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = ({ adminExit, loading }) => {
  return { exitMonthYears: adminExit.exitMonthYears, loading };
};
const mapDispatchToProps = {
  loadExitDashboard,
  loadExitDashboardByExitType,
  loadExitDashboardBySectorId,
  getExitYearAndMonths,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
