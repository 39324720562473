import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const LeaveLetter = (props) => {
  const {
    signerNames,
    signerPosition,
    signerEntity,
    employeeNames,
    employeeUnit,
    employeePosition,
    locationName,
    reason,
    days,
    fiscalYear,
    startDate,
    endDate,
    title,
    signContent = "",
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {signerEntity?.toUpperCase() || ""}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    {locationName || "Unknown"}, on{" "}
                    {moment(new Date()).format("LL")}
                    {/* <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "} */}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                lineHeight: "1.5",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  position: "relative",
                  top: "25px",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                {title}
              </span>
            </p>
          </div>
          <div
            className="content2"
            style={{ position: "relative", top: "20px" }}
          >
            <p>
              Pursuant to the Law No.017/2020 of 07/10/2020 establishing the
              general statute governing public servants, especially in its
              article 18, 19 and 20 relating to annual leave ;
            </p>
            <p>Basing on the request of Ms/Mr.:</p>
            <p>Names: {employeeNames}</p>
            <p>Department/ Unit :{employeeUnit}</p>
            <p>Position:{employeePosition}</p>
            {reason && <p>Reason:{reason}</p>}
            <p>
              {title} of {days} days from {startDate} to {endDate} in the fiscal
              year of {fiscalYear} is granted to Ms/Mr. {employeeNames}.
            </p>
          </div>
          <p style={{ position: "relative", top: "20px" }}>
            {signContent}
            <div className="signature2">
              {/* <!-- Signature goes here --> */}
              <p style={{ fontWeight: "bold" }}>
                {signerNames}
                <br />{" "}
                <span style={{ position: "relative", top: "3px" }}>
                  {signerPosition} <br />
                  {signerEntity}
                </span>
              </p>
            </div>
          </p>
        </div>
      </div>
    </>
  );
};

export default LeaveLetter;
