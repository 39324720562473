import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MenuBar from "./components/MenuBar";
import Structure from "./pages/Structure";
import Contract from "./pages/Contract";
import Eappeal from "./pages/Eappeal";
import Users from "./pages/Users";
import Recruitment from "./pages/Recruitment";
import Payroll from "../admin/pages/PayrollSupport";
import RBM from "./pages/RBM";
import Exit from "./pages/Exit";
import Employees from "./pages/Employees";
import Common from "../admin/pages/Common";
import UserGuide from "./pages/UserGuide";
import { connect } from "react-redux";

import { selectModule } from "./../../store/common/actions";
import { useEffect } from "react";

const Layout = (props) => {
  const { selectModule, selectedModule } = props;
  const history = useHistory();

  useEffect(() => {
    selectModule(
      {
        ...selectedModule,
        shortName: "Support",
        fullName: "System Support",
        to: "/support",
      },
      history
    );
  }, []);

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/support/dashboard" component={Dashboard} />
        <Route path="/support/structure" component={Structure} />
        <Route path="/support/contractuals" component={Contract} />
        <Route path="/support/users" component={Users} />
        <Route path="/support/recruitment" component={Recruitment} />
        <Route path="/support/payroll" component={Payroll} />
        <Route path="/support/rbm" component={RBM} />
        <Route path="/support/e-appeal" component={Eappeal} />
        <Route path="/support/exit" component={Exit} />
        <Route path="/support/common" component={Common} />
        <Route path="/support/employees" component={Employees} />
        <Route path="/support/user-guide" component={UserGuide} />

        <Route
          path="/support"
          component={() => <Redirect to="/support/users" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ selectedModule }) => {
  return {
    selectedModule,
  };
};
export default connect(mapStateToProps, { selectModule })(Layout);
