import {
  IconButton,
  TextField,
  Button,
  Badge,
  MenuItem,
  Divider,
  Menu,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getRequisition,
  getWrittenExams,
  getOralExams,
  getAllCandidatesEmployed,
  doNPSCConfirmEmployedCandidate,
  getRecruitmentRequest,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import RequisitionDetailsDialog from "./RequisitionDetailsDialog";
import AllCandidatesShortlistedDialog from "./AllCandidatesShortlistedDialog";
import AllCandidatesResultsDialog from "./AllCandidatesResultsDialog";
import AllCandidatesOnWaitingDialog from "./AllCandidatesOnWaitingDialog";
import AllCandidatesOnWrittenDialog from "./AllCandidatesOnWrittenDialog";
import AllCandidatesOnOralDialog from "./AllCandidatesOnOralDialog";
import WrittenExamDetailDialog from "./WrittenExamDetailDialog";
import OralExamDetailDialog from "./OralExamDetailDialog";
import { showError } from "../../toastify";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import axios from "axios";
import NpscOverallResultsDialog from "../pages/NpscOverallResultsDialog";
import WrittenExamMarking from "./WrittenExamMarking";
import OralExamMarking from "./OralExamMarking";
import ManageWrittenExamQuestionDialog from "./ManageWrittenExamQuestionDialog";
import Panelist from "./Panelist";
import Invigilator from "./Invigilator";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import UserResume from "../../profile/UserResume";
import ApplicationCv from "../../common/components/ApplicationCv";
import ApproveRecruitmentRequestDialog from "./ApproveRecruitmentRequestDialog";

const AdvertisementReport = (props) => {
  const {
    loading,
    details,
    getRequisition,
    getWrittenExams,
    getOralExams,
    oralExams,
    writtenExams,
    getAllCandidatesEmployed,
    candidatesEmployed,
    canApproveCandidates,
    doNPSCConfirmEmployedCandidate,
    getUserDetails,
    getRecruitmentRequest,
  } = props;

  const [
    showAllCandidatesShortlistedDialog,
    setShowAllCandidatesShortlistedDialog,
  ] = useState(false);
  const [showOverallResult, setShowOverallResult] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  const [
    showAllCandidatesOnWrittenDialog,
    setShowAllCandidatesOnWrittenDialog,
  ] = useState(false);
  const [showAllCandidatesOnOralDialog, setShowAllCandidatesOnOralDialog] =
    useState(false);
  const [showAllCandidatesResultsDialog, setShowAllCandidatesResultsDialog] =
    useState(false);

  const [
    showAllCandidatesOnWaitingDialog,
    setShowAllCandidatesOnWaitingDialog,
  ] = useState(false);

  const [showRequisitionDetails, setShowRequisitionDetails] = useState(false);
  const [selectedRequisition, setSelectedRequisition] = useState(null);
  const [showWrittenExamDetail, setShowWrittenExamDetail] = useState(false);
  const [showOralExamDetail, setShowOralExamDetail] = useState(false);

  const [showDocument, setShowDocument] = useState(false);
  const [documentQuestions, setDocumentQuestions] = useState({});
  const [reportSummary, setReportSummary] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [
    showUploadWrittenExamQuestionsForm,
    setShowUploadWrittenExamQuestionsForm,
  ] = useState(false);
  const [showInvigilators, setShowInvigilators] = useState(false);
  const [showPanelLists, setShowPanelLists] = useState(false);
  const [showOralExamMarking, setShowOralExamMarking] = useState(false);
  const [showWrittenExamMarking, setShowWrittenExamMarking] = useState(false);
  const [currentWrittenExam, setCurrentWrittenExam] = useState(
    writtenExams.find(({ isCanceled }) => !isCanceled)
  );
  const [currentOralExam, setCurrentOralExam] = useState(
    oralExams.find(({ isCanceled }) => !isCanceled)
  );

  useEffect(() => {
    if (!!details?.willHaveWrittenExam) {
      getWrittenExams(details.advertisementId, setIsLoading);
    }
    if (!!details?.willHaveOralExam) {
      getOralExams(details.advertisementId, setIsLoading);
    }
    getNpscReportSummary();
    getAllCandidatesEmployed(details.advertisementId, details.npscReportId);
  }, []);
  useEffect(() => {
    setCurrentWrittenExam(writtenExams.find(({ isCanceled }) => !isCanceled));
    setCurrentOralExam(oralExams.find(({ isCanceled }) => !isCanceled));
  }, [writtenExams, oralExams]);

  const onViewOralExamQuestions = async () => {
    try {
      const { data } = await axios.get(
        `/api/recruitment/advertised-oral-exam-questions/${details.advertisementId}`
      );

      setDocumentQuestions({
        url: data,
        name: "Oral exam prepared questions",
      });
      setShowDocument(true);
    } catch (error) {
      showError(error);
    }
  };
  const getNpscReportSummary = async () => {
    try {
      const { data } = await axios.get(
        `/api/recruitment/npsc-report-summary/${details.advertisementId}`
      );

      setReportSummary(data);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <table className="table table-striped table-hover table-sm  fixTableHead">
        <caption
          style={{ backgroundColor: "#1976d2", color: "#fff" }}
          className="mb-0"
        >
          {details?.positionName || ""}
        </caption>
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">List</th>
            <th scope="col" className="text-right">
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="py-2 appeal-hovered">
            <td>1. Job profile. </td>

            <td className="float-right">
              {" "}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={loading}
                onClick={() => {
                  getRequisition(
                    details?.requisitionId,
                    setShowRequisitionDetails,
                    setSelectedRequisition
                  );
                }}
                className="text-right "
              >
                Open
              </Button>
            </td>
          </tr>

          <tr className="py-2 appeal-hovered">
            <td>2. All applications. </td>

            <td className="float-right">
              {" "}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={loading}
                onClick={() => {
                  setShowAllCandidatesShortlistedDialog(true);
                }}
                className="text-right "
              >
                Open
              </Button>
            </td>
          </tr>
          <tr className="py-2 appeal-hovered">
            <td>6. Exam schedule. </td>

            <td className="float-right">
              {" "}
              {!!details?.willHaveWrittenExam && currentWrittenExam && (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowWrittenExamDetail(true);
                  }}
                  className="mr-3"
                >
                  Written
                </Button>
              )}
              {!!details?.willHaveOralExam && currentOralExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowOralExamDetail(true);
                  }}
                >
                  Oral
                </Button>
              )}
            </td>
          </tr>
          <tr className="py-2 appeal-hovered">
            <td>3. Exam attendance. </td>

            <td className="float-right">
              {" "}
              {!!details?.willHaveWrittenExam && currentWrittenExam && (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowAllCandidatesOnWrittenDialog(true);
                  }}
                  className="mr-3"
                >
                  Written
                </Button>
              )}
              {!!details?.willHaveOralExam && currentOralExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowAllCandidatesOnOralDialog(true);
                  }}
                >
                  Oral
                </Button>
              )}
            </td>
          </tr>
          <tr className="py-2 appeal-hovered">
            <td>4.Exam results. </td>

            <td className="float-right">
              {" "}
              {!!details?.willHaveWrittenExam && !!currentWrittenExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowWrittenExamMarking(true);
                  }}
                  className="text-right mr-2"
                >
                  Written
                </Button>
              )}
              {!!details?.willHaveOralExam && currentOralExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowOralExamMarking(true);
                  }}
                  className="text-right mr-2"
                >
                  Oral
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={loading}
                onClick={() => {
                  setShowOverallResult(true);
                }}
                className="text-right "
              >
                Overall
              </Button>
            </td>
          </tr>
          <tr className="py-2 appeal-hovered">
            <td>4.Waiting list. </td>

            <td className="float-right">
              {" "}
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={loading}
                onClick={() => {
                  setShowAllCandidatesOnWaitingDialog(true);
                }}
                className="text-right "
              >
                Open
              </Button>
            </td>
          </tr>

          <tr className="py-2 appeal-hovered">
            <td>7.Exam questionnaire and marking scheme </td>

            <td className="float-right">
              {" "}
              {!!details?.willHaveWrittenExam && currentWrittenExam && (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowUploadWrittenExamQuestionsForm(true);
                  }}
                  className="mr-3"
                >
                  Written
                </Button>
              )}
              {!!details?.willHaveOralExam && currentOralExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    onViewOralExamQuestions();
                  }}
                >
                  Oral
                </Button>
              )}
            </td>
          </tr>

          <tr className="py-2 appeal-hovered">
            <td>8. Examiners. </td>

            <td className="float-right py-2 px-2">
              {!!details?.willHaveWrittenExam && currentWrittenExam && (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowInvigilators(true);
                  }}
                  className="mr-3"
                >
                  Written
                </Button>
              )}
              {!!details?.willHaveOralExam && currentOralExam && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={loading}
                  onClick={() => {
                    setShowPanelLists(true);
                  }}
                >
                  Oral
                </Button>
              )}
            </td>
          </tr>
          <tr className="appeal-hovered">
            <td>9. Recorded video and audio for the oral interview. </td>

            <td className="float-right">To Be Submitted Manually</td>
          </tr>
        </tbody>
      </table>

      <div
        className="card service-card mt-5 mt-0"
        style={{ borderColor: "#e0edf9!important" }}
      >
        <div
          className="card-header"
          style={{ padding: "0.25rem 1.2rem", width: "100%" }}
        >
          <div className="row">
            <div className="col-lg-12">10. Summary by gender. </div>
          </div>
        </div>
        <div>
          <table className="table table-striped table-hover table-sm  fixTableHead">
            <tbody>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  Applications
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  Shortlisted
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  NotShortlisted
                </td>

                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  WrittenAttended
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  OralAttended
                </td>
                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  SucceedInAllExams.
                </td>

                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  Hired.
                </td>

                <td
                  colSpan={3}
                  style={{
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                  className="text-center"
                >
                  OnWaitingList.
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Male
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Female
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  Total
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalApplicantMale || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalApplicantFemale || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalNumberOfApplicant || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalMaleShortlisted || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalFemaleShortlisted || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalShortlisted || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalMaleNotShortlisted || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalFemaleNotShortlisted || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalNotShortlisted || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalWrittenMaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalWrittenFemaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalWrittenAttended || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalOralMaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalOralFemaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalOralAttended || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalSucceedMaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalSucceedFemaleAttended || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalSucceedAttended || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalMaleHired || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalFemaleHired || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalHired || 0}
                </td>

                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalMaleOnWaitingList || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalFemaleOnWaitingList || 0}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid rgb(150, 171, 181)",
                    borderRight: "1px solid rgb(150, 171, 181)",
                  }}
                >
                  {reportSummary?.totalOnWaitingList || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {!!candidatesEmployed.length && (
        <div
          className="card service-card mt-5 mt-0"
          style={{ borderColor: "#e0edf9!important" }}
        >
          <div
            className="card-header"
            style={{ padding: "0.25rem 1.2rem", width: "100%" }}
          >
            <div className="row">
              <div className="col-lg-12">View Hired Candidates</div>
            </div>
          </div>
          <div>
            <div className="mt-3">
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      Names
                    </th>
                    <th scope="col" className="text-left">
                      Email
                    </th>
                    <th scope="col" className="text-left">
                      PhoneNumber
                    </th>
                    <th scope="col" className="text-left">
                      IDNumber
                    </th>
                    <th scope="col" className="text-left">
                      Gender
                    </th>
                    <th scope="col" className="text-left">
                      EmploymentDate
                    </th>
                    <th scope="col" className="text-left">
                      Status
                    </th>

                    <th scope="col" colSpan={2} className="text-left">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {candidatesEmployed.map((employee, index) => (
                    <EmployeeList
                      key={index}
                      employee={employee}
                      canApproveCandidates={canApproveCandidates}
                      hasPermissions={hasPermissions}
                      doNPSCConfirmEmployedCandidate={
                        doNPSCConfirmEmployedCandidate
                      }
                      details={details}
                      loading={loading}
                      getUserDetails={getUserDetails}
                      getRecruitmentRequest={getRecruitmentRequest}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {showRequisitionDetails && (
        <RequisitionDetailsDialog
          showRequisitionDetails={showRequisitionDetails}
          setShowRequisitionDetails={setShowRequisitionDetails}
          selectedRequisition={selectedRequisition}
        />
      )}
      {showAllCandidatesShortlistedDialog && (
        <AllCandidatesShortlistedDialog
          showDialog={showAllCandidatesShortlistedDialog}
          setShowDialog={setShowAllCandidatesShortlistedDialog}
          application={details}
        />
      )}
      {showAllCandidatesResultsDialog && (
        <AllCandidatesResultsDialog
          showDialog={showAllCandidatesResultsDialog}
          setShowDialog={setShowAllCandidatesResultsDialog}
          application={details}
        />
      )}
      {showAllCandidatesOnWaitingDialog && (
        <AllCandidatesOnWaitingDialog
          showDialog={showAllCandidatesOnWaitingDialog}
          setShowDialog={setShowAllCandidatesOnWaitingDialog}
          application={details}
        />
      )}
      {showAllCandidatesOnWrittenDialog && (
        <AllCandidatesOnWrittenDialog
          showDialog={showAllCandidatesOnWrittenDialog}
          setShowDialog={setShowAllCandidatesOnWrittenDialog}
          application={details}
          setShowWrittenExamDetail={setShowWrittenExamDetail}
        />
      )}

      {showAllCandidatesOnOralDialog && (
        <AllCandidatesOnOralDialog
          showDialog={showAllCandidatesOnOralDialog}
          setShowDialog={setShowAllCandidatesOnOralDialog}
          application={details}
          setShowOralExamDetail={setShowOralExamDetail}
        />
      )}
      {showWrittenExamDetail && (
        <WrittenExamDetailDialog
          showDialog={showWrittenExamDetail}
          setShowDialog={setShowWrittenExamDetail}
          application={details}
        />
      )}

      {showOralExamDetail && (
        <OralExamDetailDialog
          showDialog={showOralExamDetail}
          setShowDialog={setShowOralExamDetail}
          application={details}
        />
      )}
      {showDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showDocument}
          document={documentQuestions}
          setShowDocument={setShowDocument}
        />
      )}
      {showOverallResult && (
        <NpscOverallResultsDialog
          showOverallResult={showOverallResult}
          setShowOverallResult={setShowOverallResult}
          selectedAdvertisement={details}
        />
      )}
      {showWrittenExamMarking && (
        <WrittenExamMarking
          exam={currentWrittenExam}
          showWrittenExamMarking={showWrittenExamMarking}
          setShowWrittenExamMarking={setShowWrittenExamMarking}
          userType={""}
        />
      )}
      {showOralExamMarking && (
        <OralExamMarking
          exam={currentOralExam}
          showOralExamMarking={showOralExamMarking}
          setShowOralExamMarking={setShowOralExamMarking}
        />
      )}
      {showUploadWrittenExamQuestionsForm && (
        <ManageWrittenExamQuestionDialog
          exam={currentWrittenExam}
          selectedAdvertisement={details}
          showUploadWrittenExamQuestionsForm={
            showUploadWrittenExamQuestionsForm
          }
          setShowUploadWrittenExamQuestionsForm={
            setShowUploadWrittenExamQuestionsForm
          }
        />
      )}
      {showPanelLists && (
        <Panelist
          showPanelLists={showPanelLists}
          selectedExam={currentOralExam}
          setShowPanelLists={setShowPanelLists}
        />
      )}
      {showInvigilators && (
        <Invigilator
          showInvigilators={showInvigilators}
          selectedExam={currentOralExam}
          setShowInvigilators={setShowInvigilators}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  oralExams,
  writtenExams,
  candidatesEmployed,
}) => {
  return {
    loading,
    oralExams,
    writtenExams,
    candidatesEmployed,
  };
};
export default connect(mapStateToProps, {
  getRequisition,
  getWrittenExams,
  getOralExams,
  getAllCandidatesEmployed,
  doNPSCConfirmEmployedCandidate,
  getUserDetails,
  getRecruitmentRequest,
})(AdvertisementReport);

const EmployeeList = (props) => {
  const {
    employee,
    canApproveCandidates,
    hasPermissions,
    doNPSCConfirmEmployedCandidate,
    details,
    loading,
    getUserDetails,
    getRecruitmentRequest,
  } = props;
  const [showApproveEmployeeDialog, setShowApproveEmployeeDialog] =
    useState(false);
  const [showRejectEmployeeDialog, setShowRejectEmployeeDialog] =
    useState(false);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [reason, setReason] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [approveRequestForm, setApproveRequestForm] = useState(false);
  const [showRejectReasonDialog, setShowRejectReasonDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };
  return (
    <tr>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>
          {employee.lastName} {employee.firstName}
        </span>
      </td>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>{employee.email}</span>
      </td>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>{employee.phoneNumber}</span>
      </td>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>{employee.idNumber}</span>
      </td>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>{employee.genderId}</span>
      </td>
      <td className="text-left">
        <span style={{ fontSize: "10px" }}>
          {moment(employee.startDate).format("LL")}
        </span>
      </td>
      <td className="text-center">
        <span style={{ fontSize: "10px" }}>
          <span
            style={{ fontSize: "12px" }}
            className={`${
              +employee.npscStatusId === 1
                ? "badge badge-success w-100"
                : +employee.npscStatusId === 2
                ? "badge badge-danger w-100"
                : "badge badge-primary w-100"
            }`}
          >
            {employee.npscStatus}
          </span>{" "}
          <br />
          {+employee.npscStatusId === 2 && (
            <>
              {" "}
              <button
                className="btn btn-link btn-sm text-center"
                onClick={() => setShowRejectReasonDialog(true)}
              >
                {" "}
                See the reason{" "}
              </button>{" "}
            </>
          )}
        </span>
      </td>
      <td className="text-right">
        {!!canApproveCandidates && !!hasPermissions(["IS_NPSC"]) && (
          <>
            {!!loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <>
                {[0, 2].includes(employee.npscStatusId) && (
                  <button
                    onClick={() => {
                      setShowApproveEmployeeDialog(true);
                    }}
                    className="btn btn-success btn-sm mr-1"
                  >
                    Approve
                  </button>
                )}
                {[0, 1].includes(employee.npscStatusId) && (
                  <button
                    onClick={() => {
                      setShowRejectEmployeeDialog(true);
                    }}
                    className="btn btn-danger btn-sm mr-1"
                  >
                    Reject
                  </button>
                )}

                {showApproveEmployeeDialog && (
                  <ConfirmationDialog
                    confirmationDialog={showApproveEmployeeDialog}
                    message={`Are you sure you want to approve this employee @${employee.lastName}
${employee.firstName}?`}
                    setConfirmationDialog={setShowApproveEmployeeDialog}
                    onYes={() => {
                      doNPSCConfirmEmployedCandidate(
                        {
                          isApproved: 1,
                          reason: "",
                          recruitmentReportId: details.npscReportId,
                          employeeId: employee.userId,
                          advertisementId: details.advertisementId,
                        },
                        setShowApproveEmployeeDialog
                      );
                    }}
                  />
                )}
                {showRejectEmployeeDialog && (
                  <ConfirmationDialog
                    disabled={!reason}
                    confirmationDialog={showRejectEmployeeDialog}
                    message={
                      <>
                        <p>
                          Are you sure you want to{" "}
                          <strong className="text-danger">reject</strong> this
                          employee @{employee.lastName} {employee.firstName}?
                        </p>
                        <TextField
                          fullWidth
                          size="small"
                          multiline
                          rows={4}
                          name="Reason"
                          label="Type your reason .."
                          variant="outlined"
                          placeholder="Type your reason  .."
                          value={reason}
                          onChange={(e) => {
                            setReason(e.target.value || "");
                          }}
                        />
                      </>
                    }
                    setConfirmationDialog={setShowRejectEmployeeDialog}
                    onYes={() => {
                      doNPSCConfirmEmployedCandidate(
                        {
                          isApproved: 0,
                          reason: reason,
                          recruitmentReportId: details.npscReportId,
                          employeeId: employee.userId,
                          advertisementId: details.advertisementId,
                        },
                        setShowRejectEmployeeDialog
                      );
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {showRejectReasonDialog && (
          <ConfirmationDialog
            disabled={true}
            confirmationDialog={showRejectReasonDialog}
            message={
              <>
                <div style={{minWidth:'200px'}} className="w-100  float-start">
                  <div className="d-flex flex-row justify-content-start mt-0 mr-3 w-100 float-start">
                    <div
                      className="card  w-100"
                      style={{
                        backgroundColor: "#fff",
                        borderLeft: "2px solid #3f51b5",
                        borderLeft: "2px solid #3f51b5",
                        borderRadius: "4px",
                      }}
                    >
                      <div className="card-body w-100">
                        <p className="small p-0 ms-2 mb-0 rounded-3">
                          {employee.reason}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            setConfirmationDialog={setShowRejectReasonDialog}
            onYes={() => {
              setShowRejectReasonDialog(false);
            }}
          />
        )}
      </td>
      <td className="text-right">
        <IconButton
          size="small"
          className="ml-2"
          onClick={handleOpenMenu}
          // onMouseMove={handleOpenMenu}
          aria-label="settings"
        >
          <Badge color="info">
            <span className="material-icons">more_vert</span>
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "auto",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 2,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 15,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                handleCloseMenu();
                setShowApplicationCv(true);
              }}
              className="text-dark font-weight-light"
            >
              <span className="material-icons mr-1">open_in_new</span>
              Application Profile
            </MenuItem>
            <Divider className="my-1" />
          </span>
          <MenuItem
            disabled={loading}
            onClick={() => {
              getUserDetails(employee.userId, handleOpenCurrentProfile);
            }}
            className="text-dark font-weight-light"
          >
            {" "}
            <span className="material-icons mr-1 text-primary">
              open_in_new
            </span>
            {loading ? "Wait...." : "Currently Profile"}
          </MenuItem>
          {!!employee.recruitmentRequestId && (
            <>
              <Divider className="my-1" />
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getRecruitmentRequest(
                    employee.recruitmentRequestId,
                    setApproveRequestForm,
                    handleCloseMenu
                  );
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1 text-primary">
                  newspaper
                </span>
                {loading ? "Wait...." : "Provisional Appointment Letter"}
              </MenuItem>
            </>
          )}
        </Menu>
      </td>

      {showCurrentProfile && (
        <UserResume
          showProfileDialog={showCurrentProfile}
          setShowProfileDialog={setShowCurrentProfile}
        />
      )}
      {showApplicationCv && (
        <ApplicationCv
          showApplicationCv={showApplicationCv}
          setShowApplicationCv={setShowApplicationCv}
          application={{
            id: employee.applicationId,
            idNumber: employee.idNumber,
            phoneNumber: employee.phoneNumber,
            userId: employee.userId,
            lastName: employee.lastName,
            firstName: employee.firstName,
            email: employee.email,
          }}
        />
      )}
      {!!approveRequestForm && (
        <ApproveRecruitmentRequestDialog
          approveRequestForm={approveRequestForm}
          setApproveRequestForm={setApproveRequestForm}
          selectedRequest={employee}
        />
      )}
    </tr>
  );
};
