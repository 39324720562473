import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  acceptLeavePlans,
  getSubordinateLeavePlan,
  getMonitorLeavePlan,
  getUnitAnnualLeavePlan,
  getUnitWithLeaves,
} from "../../../store/leave/actions";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import ViewLeaveDetails from "../components/ViewLeaveDetails";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import LeaveLogsDialog from "../components/LeaveLogsDialog";
import PlanAnnualLeaveForm from "../components/PlanAnnualLeaveForm";
import ViewPostponedLeaveDetails from "../components/ViewPostponedLeaveDetails";
import ReactPaginate from "react-paginate";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ViewSamePeriodStaff from "../components/ViewSamePeriodStaff";
import AlertDialog from "../../profile/components/AlertDialog";
import { showError } from "../../toastify";

const UnitLeavePlan = (props) => {
  const {
    isForMonitoring = false,
    loading,
    user,
    selectedFiscalYear,
    myUnitLeavePlans,
    getSubordinateLeavePlan,
    getUnitAnnualLeavePlan,
    acceptLeavePlans,
    unitWithAnnualLeave,
    getUnitWithLeaves,
    getMonitorLeavePlan,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [loadingLeavePlans, setLoadingLeavePlans] = useState(true);
  const [loadingUnitLeaves, setLoadingUnitLeaves] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showPostponeDetails, setShowPostponeDetails] = useState(null);
  const [showLogs, setShowLogs] = useState(null);
  const [postponeThisLeave, setPostponeThisLeave] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeLeavePlans, setEmployeeLeavePlans] = useState([]);
  const [selectedLeavePlan, setSelectedLeavePlan] = useState(null);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [showPDFLetter, setShowPDFLetter] = useState(null);
  const [formData, setFormData] = useState([]);
  const [showOverlaps, setShowOverlaps] = useState(null);
  const [requestToConfirm, setRequestToConfirm] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showEmployeeWithNoPlan, setShowEmployeeWithNoPlan] = useState(false);

  useEffect(() => {
    document.title = "IPPIS - Leave & Absence | " + selectedFiscalYear?.name;
  });

  const handleLoadLeaves = (query) => {
    if (!!isForMonitoring) {
      getMonitorLeavePlan(query, (status, data) => {
        setLoadingLeavePlans(status);
        if (!status) {
          setEmployeeLeavePlans(data.records);
          setTotalRecords(data.totalRecords);
        }
      });
    } else {
      getSubordinateLeavePlan(query, (status, data) => {
        setLoadingLeavePlans(status);
        if (!status) {
          setEmployeeLeavePlans(data.records);
          setTotalRecords(data.totalRecords);
        }
      });
    }
  };

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      isForPlan: true,
      searchTerm: _searchTerm,
      unitId: selectedUnit?.id || "",
      page: _currentPage,
      size: itemPerPage,
      withNoSchedule: showEmployeeWithNoPlan,
    };

    handleLoadLeaves(query);
    if (isForMonitoring && !unitWithAnnualLeave.length) {
      getUnitWithLeaves(
        {
          leaveType: "annual",
          status: "",
          withNoSchedule: showEmployeeWithNoPlan,
        },
        setLoadingUnitLeaves
      );
    } else setLoadingUnitLeaves(false);
    return () => {
      setLoadingLeavePlans(true);
      setLoadingUnitLeaves(true);
      setFormData([]);
    };
  }, []);

  useEffect(() => {
    if (selectedLeavePlan) {
      getUnitAnnualLeavePlan(
        setLoadingPlans,
        selectedLeavePlan.employeePositionId
      );
    } else {
      setLoadingPlans(true);
    }
  }, [selectedLeavePlan]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        isForPlan: true,
        searchTerm: _searchTerm,
        unitId: selectedUnit?.id || "",
        page: _currentPage,
        size: itemPerPage,
        withNoSchedule: showEmployeeWithNoPlan,
      };

      handleLoadLeaves(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      isForPlan: true,
      searchTerm: searchTerm,
      unitId: selectedUnit?.id || "",
      page: currentPage,
      size: itemPerPage,
      withNoSchedule: showEmployeeWithNoPlan,
    };

    handleLoadLeaves(query);
  };

  const handleSelectLeaves = (leaveId, postponedId) => {
    if (leaveId === "all" && formData.length === myUnitLeavePlans.length) {
      setFormData([]);
    } else if (leaveId === "all") {
      setFormData(
        myUnitLeavePlans.map((plan) => ({
          leavePlanId: plan.id,
          postponedId: plan.postponedId,
          comment: "",
          statusId: 10,
        }))
      );
    } else {
      const index = formData.findIndex((item) => item.leavePlanId === leaveId);
      if (index > -1) {
        const _formData = formData.filter(
          (item) => item.leavePlanId !== leaveId
        );
        setFormData(_formData);
      } else {
        const _formData = [...formData];
        _formData.push({
          leavePlanId: leaveId,
          postponedId: postponedId,
          comment: formData[0]?.comment || "",
          statusId: formData[0]?.statusId || 10,
        });
        setFormData(_formData);
      }
    }
  };

  const handleAcceptLeavePlans = () => {
    if (!formData.length) {
      showError("Please select at least one leave plan to accept");
      return;
    }
    setIsAccepting(true);
    acceptLeavePlans(formData, (status, res) => {
      setIsAccepting(status);
      if (!status && res) {
        setFormData([]);
        setRequestToConfirm(false);
        setIsAccepting(false);
        setSelectedLeavePlan(null);
        const query = {
          isForPlan: true,
          searchTerm: searchTerm,
          unitId: selectedUnit?.id || "",
          page: currentPage,
          size: itemPerPage,
          withNoSchedule: showEmployeeWithNoPlan,
        };
        handleLoadLeaves(query);
      }
    });
  };

  return (
    <div>
      <div className="row w-100 my-3 pl-4">
        <div className="col-12">
          {isForMonitoring && (
            <span
              className={`px-0 badge badge-pill btn-link cursor-pointer text-uppercase text-${
                showEmployeeWithNoPlan ? "blue" : "secondary"
              } `}
              onClick={() => {
                const query = {
                  isForPlan: true,
                  searchTerm: searchTerm,
                  unitId: selectedUnit?.id || "",
                  page: currentPage,
                  size: itemPerPage,
                  withNoSchedule: !showEmployeeWithNoPlan,
                };
                handleLoadLeaves(query);
                setShowEmployeeWithNoPlan(!showEmployeeWithNoPlan);
              }}
            >
              <i className="fas fa-thumbtack"></i> Unschedule Employee
            </span>
          )}
          <div className="d-flex justify-content-between align-items-center m-0 mb-2 p-0">
            <div className="form-group w-100 mt-3">
              <input
                name="search"
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      isForPlan: true,
                      searchTerm: searchTerm,
                      unitId: selectedUnit?.id || "",
                      page: currentPage,
                      size: itemPerPage,
                      withNoSchedule: showEmployeeWithNoPlan,
                    };

                    handleLoadLeaves(query);
                  }
                }}
              />
            </div>

            {isForMonitoring && (
              <div className="ml-2 my-0">
                <Autocomplete
                  id="unit"
                  options={unitWithAnnualLeave}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.leaves})`
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name} ({option.leaves})
                    </Box>
                  )}
                  style={{ width: 300 }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setSelectedUnit(newValue);
                    const query = {
                      isForPlan: true,
                      searchTerm: searchTerm,
                      unitId: newValue ? newValue.id : "",
                      page: currentPage,
                      size: itemPerPage,
                      withNoSchedule: showEmployeeWithNoPlan,
                    };
                    handleLoadLeaves(query);
                  }}
                  disabled={loadingLeavePlans || loadingUnitLeaves}
                  loading={loadingUnitLeaves}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Unit"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </div>
            )}
          </div>

          <TableContainer>
            <Table size="small" aria-label="IPPIS RBM Employee plans">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>#</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell align="center">Overview</TableCell>
                  <TableCell className="text-center">Action</TableCell>
                </TableRow>
              </TableHead>
              {loadingLeavePlans ? (
                <TableSkeleton cols={6} isMUI />
              ) : (
                <TableBody>
                  {employeeLeavePlans.map((plan, index) => (
                    <TableRow
                      key={plan.id}
                      hover
                      sx={{
                        cursor: "pointer",
                      }}
                      className="tr-hovered"
                      onClick={() => setSelectedLeavePlan(plan)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{plan.unitName}</TableCell>
                      <TableCell>
                        {plan.employeeNames}{" "}
                        {plan.id === user.id && (
                          <span
                            className="badge badge-primary px-1"
                            style={{
                              fontSize: "9px",
                            }}
                          >
                            ME
                          </span>
                        )}
                      </TableCell>
                      <TableCell>
                        {plan.positionName}({plan.employeeGroupName})
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ display: "inline-block" }}>
                          <div className="d-flex mb-2 align-items-center">
                            <span
                              style={{ color: "#088dce", minWidth: "160px" }}
                              className="text-uppercase text-right btn-link"
                            >
                              {plan.plannedDays}/{plan.allowedDays} day
                              {plan.plannedDays > 1 ? "s" : ""} Planned
                            </span>

                            <span
                              className="ml-2 badge badge-light text-left  text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-secondary text-light"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.newPlans}
                              </span>{" "}
                              New Plan{plan.newPlans > 1 ? "s" : ""}
                            </span>

                            <span
                              className="ml-2 badge badge-info text-left text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-light text-primary"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.approvedPlans}
                              </span>{" "}
                              Approved Plan{plan.approvedPlans > 1 ? "s" : ""}
                            </span>
                            <span
                              className="ml-2 badge badge-success text-left text-uppercase"
                              style={{ minWidth: "100px" }}
                            >
                              <span
                                className="badge badge-light bg-light text-secondary"
                                style={{ fontSize: "11px" }}
                              >
                                {plan.takenLeaves}
                              </span>{" "}
                              Taken Leave{plan.takenLeaves > 1 ? "s" : ""}
                            </span>
                          </div>
                          {/* <CustomProgressLine
                            height={10}
                            hidePercentage
                            value={(plan.plannedDays * 100) / plan.allowedDays}
                          /> */}
                        </div>
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          onClick={() =>
                            setSelectedLeavePlan({ ...plan, isForPlan: true })
                          }
                          variant="text"
                          size="small"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {!employeeLeavePlans.length && (
                    <TableRow>
                      <TableCell colSpan={6} className="text-center">
                        No leave plan found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <div className="mt-3">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalRecords / itemPerPage)}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                activeLinkClassName="active"
              />
            </div>

            {!!myUnitLeavePlans.length && (
              <select
                className="custom-select custom-select-sm mr-3 ml-2"
                style={{ width: "100px" }}
                disabled={loadingLeavePlans}
                value={itemPerPage}
                onChange={(event) => {
                  const itemPerPage = +event.target.value;
                  setItemPerPage(itemPerPage);
                  const query = {
                    isForPlan: true,
                    searchTerm: searchTerm,
                    unitId: selectedUnit?.id || "",
                    page: currentPage,
                    size: itemPerPage,
                    withNoSchedule: showEmployeeWithNoPlan,
                  };
                  handleLoadLeaves(query);
                }}
              >
                {[10, 50, 100].map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>

      {selectedLeavePlan && (
        <Dialog
          // onClose={() => setSelectedLeavePlan(null)}
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle className="text-dark pb-2 ">
            <Typography
              variant="overline"
              className="text-truncate"
              display="block"
              style={{ maxWidth: "90%" }}
            >
              Leave Plan Details for {selectedLeavePlan.employeeNames}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setSelectedLeavePlan(null)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent className="py-1">
            <TableContainer>
              <Table size="small" aria-label="Your annual leave balance">
                <TableHead>
                  <TableRow
                    style={{
                      borderLeft: "2px solid #e9ecef",
                      borderRight: "2px solid #e9ecef",
                    }}
                  >
                    {hasPermissions(["IS_HEAD_OF_UNIT"]) && (
                      <TableCell>
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          disabled={
                            myUnitLeavePlans.filter(
                              (item) => item.statusId === 1
                            ).length <= 1 ||
                            loadingPlans ||
                            loading
                          }
                          onChange={() => handleSelectLeaves("all")}
                          checked={formData.length === myUnitLeavePlans.length}
                        />
                      </TableCell>
                    )}
                    <TableCell>#</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Starting On</TableCell>
                    <TableCell>Ending On</TableCell>
                    <TableCell>Planned Days</TableCell>
                    <TableCell className="text-center">Status</TableCell>
                    <TableCell className="text-center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {loadingPlans ? (
                  <TableSkeleton
                    cols={hasPermissions(["IS_HEAD_OF_UNIT"]) ? 10 : 9}
                    isMUI
                  />
                ) : (
                  <TableBody>
                    {myUnitLeavePlans.map((plan, index) => (
                      <AnnualLeaveRow
                        plan={plan}
                        index={index}
                        loading={loadingLeavePlans || loading}
                        setSelectedPlan={setSelectedPlan}
                        key={plan.id}
                        setShowPostponeDetails={setShowPostponeDetails}
                        setShowLogs={setShowLogs}
                        setPostponeThisLeave={setPostponeThisLeave}
                        setShowPDFLetter={setShowPDFLetter}
                        handleSelectLeaves={handleSelectLeaves}
                        selectedPlans={formData.map((item) => item.leavePlanId)}
                        isSupervisor={hasPermissions(["IS_HEAD_OF_UNIT"])}
                        setShowOverlaps={setShowOverlaps}
                      />
                    ))}
                    {!myUnitLeavePlans.length && (
                      <TableRow>
                        <TableCell
                          colSpan={hasPermissions(["IS_HEAD_OF_UNIT"]) ? 10 : 9}
                          className="text-center"
                        >
                          No leave plan found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            {!!formData.length && (
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setRequestToConfirm(true)}
                >
                  Accept
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      )}

      {!!selectedPlan && (
        <ViewLeaveDetails
          leaveRequest={selectedPlan}
          setShowRequest={setSelectedPlan}
          title={"Leave Plan Details for " + selectedPlan.employeeNames}
        />
      )}

      {!!showLogs && (
        <LeaveLogsDialog setShowLogs={setShowLogs} selectLeave={showLogs} />
      )}

      {postponeThisLeave && (
        <PlanAnnualLeaveForm
          showCreateAnnualLeave={true}
          setShowCreateAnnualLeave={() => {
            setPostponeThisLeave(null);
          }}
          leaveToPostpone={postponeThisLeave}
        />
      )}

      {!!showPostponeDetails && (
        <ViewPostponedLeaveDetails
          leavePlan={showPostponeDetails}
          setLeavePlan={setShowPostponeDetails}
        />
      )}

      {!!showPDFLetter && !!showPDFLetter?.letterReferenceId && (
        <PreviewPdfUrl
          showDocument={true}
          setShowDocument={() => setShowPDFLetter(null)}
          document={{
            name: `View ${showPDFLetter.leaveType} - Authorization Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              showPDFLetter?.letterReferenceId,
          }}
        />
      )}

      {!!showOverlaps && (
        <ViewSamePeriodStaff
          leave={showOverlaps}
          onClose={() => setShowOverlaps(null)}
        />
      )}

      {requestToConfirm && (
        <AlertDialog
          status={true}
          handleClose={() => setRequestToConfirm(false)}
          handleConfirm={() => handleAcceptLeavePlans()}
          title={"Are you sure you want to accept the selected leave plans?"}
          disabled={loading || isAccepting}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingLeavePlans && !!employeeLeavePlans[0] ? "d-block" : "d-none"
        }`}
      >
        {loadingLeavePlans && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  loading,
  user,
  selectedFiscalYear,
  myUnitLeavePlans,
  unitWithAnnualLeave,
}) => {
  return {
    loading,
    user,
    selectedFiscalYear,
    myUnitLeavePlans,
    unitWithAnnualLeave,
  };
};
export default connect(mapStateToProps, {
  getSubordinateLeavePlan,
  getMonitorLeavePlan,
  getUnitAnnualLeavePlan,
  acceptLeavePlans,
  getUnitWithLeaves,
})(UnitLeavePlan);

export const AnnualLeaveRow = (props) => {
  const {
    index,
    plan,
    loading,
    setSelectedPlan,
    setShowPostponeDetails,
    isMe,
    showPlannedOn,
    setShowLogs,
    setPostponeThisLeave,
    setShowPDFLetter,
    handleSelectLeaves,
    selectedPlans,
    isSupervisor,
    setShowOverlaps,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { hasPermissions } = useRequiredAuth();

  return (
    <TableRow
      key={plan.id}
      hover
      // sx={{
      //   cursor: !!setSelectedPlan && "pointer",
      // }}
      // className="tr-hovered"
      // onClick={() => setSelectedPlan(plan)}
    >
      {isSupervisor && !!handleSelectLeaves && (
        <TableCell>
          {![1, 7, 9].includes(plan.statusId) ? (
            <span
              className={`material-icons-round ${
                plan.statusId === 10 ? "text-primary" : "text-success"
              }`}
            >
              verified
            </span>
          ) : plan.statusId === 7 ? (
            <span className="material-icons-round text-danger">
              highlight_off
            </span>
          ) : plan.statusId === 9 ? (
            <span className="material-icons-round text-danger">
              new_releases
            </span>
          ) : plan.statusId === 1 ? (
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              checked={selectedPlans.includes(plan.id)}
              onChange={() => handleSelectLeaves(plan.id, plan.postponedId)}
            />
          ) : (
            <span className="material-icons-round">minimize</span>
          )}
        </TableCell>
      )}
      <TableCell>{index + 1}</TableCell>
      <TableCell>{plan.unitName}</TableCell>
      <TableCell>
        {plan.employeeNames}{" "}
        {isMe && (
          <span
            className="badge badge-primary px-1"
            style={{
              fontSize: "9px",
            }}
          >
            ME
          </span>
        )}
      </TableCell>
      <TableCell>
        {plan.positionName}({plan.employeeGroupName})
      </TableCell>
      <TableCell>{moment(plan.startDate).format("LL")}</TableCell>
      <TableCell>{moment(plan.endDate).format("LL")}</TableCell>
      <TableCell>
        {plan.numOfDays || plan.numOfDaysPlanned || "N/A"} Days
      </TableCell>
      {showPlannedOn && (
        <TableCell>{moment(plan.createdOn).format("LL")}</TableCell>
      )}
      <TableCell className="text-center">
        <div className="d-flex mb-2 align-items-center">
          <span
            className={`badge ${
              plan.statusId === 2 || plan.statusId === 9
                ? "badge-primary"
                : plan.statusId === 4
                ? "badge-info"
                : plan.statusId === 5
                ? "badge-light"
                : plan.statusId === 6
                ? "badge-success"
                : plan.statusId === 7
                ? "badge-danger"
                : "badge-secondary"
            }`}
          >
            <span
              className="material-icons-round"
              style={{
                fontSize: "0.8rem",
                verticalAlign: "middle",
                marginRight: "0.2rem",
              }}
            >
              {plan.statusId === 2
                ? "done"
                : plan.statusId === 3
                ? "done_all"
                : plan.statusId === 4
                ? "done_all"
                : plan.statusId === 5
                ? "fast_forward"
                : plan.statusId === 6
                ? "stop"
                : plan.statusId === 7
                ? "close"
                : plan.statusId === 9
                ? "next_plan"
                : "alarm_on"}
            </span>
            <span>{plan.statusName}</span>
          </span>

          {!!plan.overlapLeaves && (
            <span
              className="ml-2 badge badge-warning text-left cursor-pointer"
              style={{ minWidth: "70px" }}
              onClick={() => setShowOverlaps(plan)}
            >
              <span
                className="badge badge-warning bg-light text-warning"
                style={{ fontSize: "11px" }}
              >
                {plan.overlapLeaves}
              </span>{" "}
              Overlap{plan.overlapLeaves > 1 ? "s" : ""}
            </span>
          )}
        </div>
      </TableCell>
      {!!setSelectedPlan && (
        <TableCell className="text-right">
          <IconButton
            color="primary"
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPlan(plan);
                }}
                className="text-primary font-weight-bolder"
              >
                {/* <span className="material-icons-round mr-1">edit</span> */}
                View
              </MenuItem>
              {/* <Divider className="my-1" /> */}
            </span>

            {!!plan.postponedId && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowPostponeDetails(plan);
                  }}
                  className="text-primary font-weight-light"
                >
                  {/* <span className="material-icons-round mr-1">info_outline</span> */}
                  Postpone Details
                </MenuItem>
              </span>
            )}
            {!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
              [2, 3, 4, 5, 10].includes(plan.statusId) && (
                <span>
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      handleCloseMenu();
                      setPostponeThisLeave(plan);
                    }}
                    className="text-warning font-weight-light"
                  >
                    Postpone This Leave
                  </MenuItem>
                </span>
              )}
            {!!plan.letterReferenceId && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowPDFLetter(plan);
                  }}
                  className="text-dark font-weight-light"
                >
                  Authorization Letter
                </MenuItem>
              </span>
            )}
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowLogs(plan);
                }}
                className="text-dark font-weight-light"
              >
                {/* <span className="material-icons-round mr-1">info_outline</span> */}
                Logs
              </MenuItem>
            </span>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};
