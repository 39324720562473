import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import defaultProfile from "../../assets/default-profile.jpg";

import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { connect } from "react-redux";
import { logout } from "../../../store/common/actions";
import { isEmpty } from "lodash";

const Drawer = (props) => {
  const {
    drawerState,
    setDrawerState,
    user,
    logout,
    selectedModule,
    userProfilePicture,
  } = props;

  const location = useLocation();
  const history = useHistory();

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed  ${drawerState ? "active" : ""}`}
      >
        <div>
          <div
            className="drawer-user "
            style={{ borderBottom: "1px solid #d8d9da" }}
          >
            {userProfilePicture && (
              <img
                src={`data:image/png;base64,${userProfilePicture}`}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            {!userProfilePicture && (
              <img
                src={defaultProfile}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
            )}
            <div>
              <Tooltip title="Go to your profile">
                <Link
                  to="/structure/profile"
                  style={{ color: "#b8ff07" }}
                  className="font-weight-bold text-uppercase"
                  onClick={() => {
                    setDrawerState(false);
                  }}
                >
                  {user.firstName} {user.lastName}
                </Link>
              </Tooltip>
            </div>
            {user.employments.map((employment, index) => (
              <small className=" " style={{ color: "#ff0" }} key={index}>
                {employment.positionName}
              </small>
            ))}
            <br />
            <small style={{ color: "#fff" }}>
              <>
                {!isEmpty(user) &&
                  !isEmpty(user.selectedEntitySector) &&
                  !isEmpty(selectedModule) && (
                    <>
                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length > 0 && (
                        <>
                          {user.selectedEntitySector.roles[
                            selectedModule.id
                          ]?.map((role, index) => (
                            <span key={index}>
                              {" "}
                              {role.name}
                              {user.selectedEntitySector.roles[
                                selectedModule.id
                              ].length !==
                                index + 1 && <>,</>}
                            </span>
                          ))}
                        </>
                      )}

                      {user.selectedEntitySector.roles[selectedModule.id]
                        ?.length === 0 && <>Employee</>}
                    </>
                  )}
              </>
            </small>
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>
          <List>
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/units") ? "active" : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">account_tree</span>
                </ListItemIcon>
                <Link to="/structure/units" className="text-dark">
                  <ListItemText primary="Units" className="pl-0"></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/positions")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">event_seat</span>
                </ListItemIcon>
                <Link to="/structure/positions" className="text-dark">
                  <ListItemText
                    primary="Positions"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/employees")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">group</span>
                </ListItemIcon>
                <Link to="/structure/employees" className="text-dark">
                  <ListItemText
                    primary="Employees"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/job-classifications")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">work</span>
                </ListItemIcon>
                <Link to="/structure/job-classifications" className="text-dark">
                  <ListItemText
                    primary="Job Classifications"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/oversight")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">preview</span>
                </ListItemIcon>
                <Link to="/structure/oversight" className="text-dark">
                  <ListItemText
                    primary="Oversight"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className={`nav-item  ${
                location.pathname.includes("/structure/user-guide")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setDrawerState(false);
              }}
            >
              <ListItemButton>
                <ListItemIcon className="item-icon text-dark">
                  <span className="material-icons">local_library</span>
                </ListItemIcon>
                <Link to="/structure/user-guide" className="text-dark">
                  <ListItemText
                    primary="User-Guide"
                    className="pl-0"
                  ></ListItemText>
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </div>
        <Button
          className="btn btn-block mb-3 "
          color="error"
          style={{ position: "absolute", bottom: "0" }}
          onClick={() => logout(history)}
        >
          <i
            className="fas fa-sign-out-alt mr-2 "
            style={{ transform: "rotate(180deg)" }}
          ></i>
          Logout
        </Button>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${drawerState ? "d-block" : "d-none"} `}
        onClick={() => {
          setDrawerState(false);
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule, userProfilePicture }) => {
  return { user, selectedModule, userProfilePicture };
};

export default connect(mapStateToProps, { logout })(Drawer);
