import {
  Tooltip,
  Card,
  Typography,
  CardHeader,
  CircularProgress,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { downloadUnitPerformanceReport } from "../../../store/rbm/actions";
import CustomProgressLine from "./CustomProgressLine";

const MonitorUnityCard = (props) => {
  const { unit, downloadUnitPerformanceReport, loading, setShowSelectedUnity } =
    props;

  const [score, setScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScore(unit.score);
    }, 500);
  }, [unit]);

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <Tooltip arrow title="Progress">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    score < 50
                      ? "danger"
                      : score < 70
                      ? "warning"
                      : score < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={score}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      score < 50
                        ? "danger"
                        : score < 70
                        ? "warning"
                        : score < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${score.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          }
          action={
            <>
              <IconButton
                color="primary"
                size="small"
                disabled={loading}
                title="Download Unit Performance Report"
                onClick={() => {
                  downloadUnitPerformanceReport(unit.id);
                }}
              >
                <i className="fas fa-download"></i>
              </IconButton>
            </>
          }
          title={
            <span className="text-uppercase">
              <span className="text-uppercase font-weight-bold text-primary">
                <strong>{unit.no}:</strong> {unit.name}
              </span>
            </span>
          }
          subheader={
            <span className="d-flex align-items-center flex-wrap justify-content-between">
              <span className="d-flex align-items-center flex-wrap">
                <span className="">
                  <CustomProgressLine value={unit.score} />
                  <span className="d-flex align-items-center flex-wrap">
                    <Tooltip arrow title="All activities">
                      <span
                        className={`align-items-center px-1 mx-1 text-dark cursor-pointer`}
                        style={{
                          borderRight: "2px solid #ccd0d6",
                          borderLeft: "2px solid #ccd0d6",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          setShowSelectedUnity({ ...unit, action: "all" })
                        }
                      >
                        {unit.numOfActivities} Items
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Due activities">
                      <span
                        className={`align-items-center px-1 mx-1 text-dark cursor-pointer`}
                        style={{
                          borderRight: "2px solid #ccd0d6",
                          borderLeft: "2px solid #ccd0d6",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          setShowSelectedUnity({ ...unit, action: "ontrack" })
                        }
                      >
                        {unit.onTrackActivities} Ontrack
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Overdue activities">
                      <span
                        className={`align-items-center px-1 mx-1 text-dark cursor-pointer`}
                        style={{
                          borderRight: "2px solid #ccd0d6",
                          borderLeft: "2px solid #ccd0d6",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          setShowSelectedUnity({ ...unit, action: "overdue" })
                        }
                      >
                        {unit.overdueActivities} Overdue
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Reported activities">
                      <span
                        className={`align-items-center px-1 mx-1 text-dark cursor-pointer`}
                        style={{
                          borderRight: "2px solid #ccd0d6",
                          borderLeft: "2px solid #ccd0d6",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          setShowSelectedUnity({ ...unit, action: "reported" })
                        }
                      >
                        {unit.reportedActivities} Reported
                      </span>
                    </Tooltip>
                    <Tooltip arrow title="Scored activities">
                      <span
                        className={`align-items-center px-1 mx-1 text-dark cursor-pointer`}
                        style={{
                          borderRight: "2px solid #ccd0d6",
                          borderLeft: "2px solid #ccd0d6",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          setShowSelectedUnity({ ...unit, action: "scored" })
                        }
                      >
                        {unit.scoredActivities || 0} Scored
                      </span>
                    </Tooltip>

                    {/* <span
                      className={`d-flex align-items-center text-${
                        (unit.achievedTarget * 100) / (unit.targetShare || 1) <
                        50
                          ? "danger"
                          : (unit.achievedTarget * 100) /
                              (unit.targetShare || 1) <
                            70
                          ? "warning"
                          : (unit.achievedTarget * 100) /
                              (unit.targetShare || 1) <
                            90
                          ? "info"
                          : "success"
                      } px-2 py-0 ml-2`}
                      style={{
                        border: "none",
                        cursor: "default",
                        fontWeight: "lighter",
                        fontSize: "13px",
                      }}
                    >
                      <span
                        className={`badge badge-${
                          (unit.achievedTarget * 100) /
                            (unit.targetShare || 1) <
                          50
                            ? "danger"
                            : (unit.achievedTarget * 100) /
                                (unit.targetShare || 1) <
                              70
                            ? "warning"
                            : (unit.achievedTarget * 100) /
                                (unit.targetShare || 1) <
                              90
                            ? "info"
                            : "success"
                        } mr-1`}
                      >
                        {(
                          (unit.achievedTarget * 100) /
                          (unit.targetShare || 1)
                        ).toFixed(2)}
                        %
                      </span>
                      Target achieved
                    </span> */}
                  </span>
                </span>
              </span>
            </span>
          }
        />
        <Divider />
        <Divider />
      </Card>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  downloadUnitPerformanceReport,
})(MonitorUnityCard);
