const types = {
  SET_FAULTS: "SET_FAULTS",
  SET_SANCTIONS: "SET_SANCTIONS",
  SET_ENTITY_SECTOR_CASES: "SET_ENTITY_SECTOR_CASES",
  DELETE_DISCIPLINARY_CASE: "DELETE_DISCIPLINARY_CASE",
  SET_MY_CASES: "SET_MY_CASES",
  SET_UNIT_CASES: "SET_UNIT_CASES",
  ADD_REQUEST: "ADD_REQUEST",
  SET_CASE: "SET_CASE",
  DELETE_CASE:"DELETE_CASE",
  UPDATE_DISCIPLINARY_CASE:"UPDATE_DISCIPLINARY_CASE",
  SET_CASE_ATTACHMENTS: "SET_CASE_ATTACHMENTS",
  ADD_CASE_ATTACHMENT: "ADD_CASE_ATTACHMENT",
  UPDATE_CASE_ATTACHMENT: "UPDATE_CASE_ATTACHMENT",
  SET_DISCIPLINE_LETTER_NO:"SET_DISCIPLINE_LETTER_NO",
  ADD_ENTITY_SECTOR_CASE:"ADD_ENTITY_SECTOR_CASE"
};

export default types;
