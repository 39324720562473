import React from "react";
import { Button, Card, Divider, IconButton, Tooltip } from "@mui/material";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import CustomProgressLine from "./CustomProgressLine";

export const formatPercentage = (value, optional) => {
  const decimal =
    optional && optional.decimal !== undefined ? optional.decimal : 2;
  const showPercentage =
    optional && optional.showPercentage !== undefined
      ? optional.showPercentage
      : true;
  const formattedValue =
    value !== 100 ? (parseFloat(value) || 0).toFixed(decimal) : value;
  return formattedValue + (showPercentage ? "%" : "");
};

// loading type boolean optional
// disabled type boolean optional
// labels type
// [{ value: number, title: string, type: "number"|"percentage", color?:"default"|"warning"|"danger", onClick?:function }]
// buttons type optional
// [{ fun: function, title: string,placeholder?:string }]
// progress type
// { title: string, value: number(0-100), weight?:number(0,100),hasUnApprovedIndicators?:boolean }

const RBMPageHeader = ({
  labels,
  buttons,
  progress,
  loading,
  disabled,
  noShadow,
  csvDownloadBtn,
  isDownloading,
}) => {
  return (
    <>
      {/* <Card
      variant="outlined"
      elevation={0}
      className="  "
    > */}
      {/* progress */}
      <div
        className={`w-100 rounded mb-2 py-3 px-3 ${
          noShadow ? "" : "rbm-shadow"
        } `}
      >
        <CustomProgressLine
          value={progress.value}
          hasUnApprovedIndicators={progress.hasUnApprovedIndicators}
          title={progress.title}
          weight={progress.weight}
        />

        {/* label counts */}
        <div className="d-flex mt-2 flex-column flex-md-row justify-content-between">
          <div className="row w-100 justify-content-md-between justify-content-center align-items-center">
            {labels.map((item, index) => (
              <div className="col text-center" key={index}>
                <span className="circle-label">{item.title}</span>

                <div
                  className={`circle mx-auto my-0 ${
                    item.onClick && +item.value > 0 ? "cursor-pointer" : ""
                  }`}
                  style={{
                    backgroundColor:
                      item.color === "danger"
                        ? "#ce0808e3"
                        : item.color === "warning"
                        ? "#ce5808f5"
                        : "#088dce",
                    fontWeight: "bold",
                  }}
                  onClick={item.onClick}
                >
                  {item.type === "percentage"
                    ? formatPercentage(item.value)
                    : item.value}
                </div>
              </div>
            ))}

            {!!csvDownloadBtn && (
              <div className="col-1 mt-3 text-left">
                <Button
                  disabled={loading || disabled || isDownloading}
                  className=""
                  size="small"
                  color="primary"
                  onClick={csvDownloadBtn.fun}
                  variant="outlined"
                >
                  {isDownloading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        // style={{ fontSize: "28px" }}
                      ></span>
                    </>
                  ) : (
                    <span className="material-icons">list_alt</span>
                  )}
                  csv
                </Button>
              </div>
            )}

            {!!buttons && !!buttons[0] && (
              <div className="col-1 text-right">
                {buttons.map((item, index) => (
                  <div className="w-100 mt-3 text-right" key={index}>
                    <Tooltip title={item.title} placement="bottom">
                      <span
                        style={{
                          backgroundColor: "#fff",
                          width: "48px",
                        }}
                        className="p-0 text-center"
                      >
                        <IconButton
                          title={item.placeholder}
                          disabled={disabled || loading}
                          color="primary"
                          // size="small"
                          onClick={item.fun}
                        >
                          <i className="fas fa-download"></i>
                          {/* <i className="fas fa-file-download"></i> */}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* </Card> */}
    </>
  );
};

export default RBMPageHeader;
