import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  LinearProgress,
  Button,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slide,
  Alert,
  AlertTitle,
  Checkbox,
  Tab,
  Tabs,
  Input,
} from "@mui/material";
import defaultPofile from "../../assets/default-profile.jpg";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  countWords,
  employmentCategories,
  expectedTrainingAwards,
  highestAcademicLevels,
  idNumberPattern,
  textCapitalized,
  textCapitalizing,
  titles,
  trainingAreas,
  trainingCategories,
  trainingFunds,
  trainingInitiators,
} from "../../common/components/Utils";
import MaskedInput from "react-text-mask";
import axios from "axios";
import moment from "moment";
import { showError } from "../../toastify";
import rwandaLogo from "../../assets/rwanda-logo.png";
import { updateTrainingRequest } from "../../../store/training/actions";
import ErrorMessage from "../../common/components/ErrorMessage";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Link } from "react-router-dom";
import AddCDPlan from "./AddCDPlan";
import { a11yProps } from "../../common/components/TabPanel";
import ViewAttachmentTrainingRequestDialog from "./ViewAttachmentTrainingRequestDialog";
import ApplicationCv from "../../common/components/ApplicationCv";
import { getHeadOfInstitution } from "../../../store/structure/actions";
import DraftAuthorizationByMinistry from "./DraftAuthorizationByMinistry";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import { capitalize } from "lodash";
import DraftAuthorizationPermit from "./DraftAuthorizationPermit";
import AuthorizationPermit from "./AuthorizationPermit";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const trainingGapsIdentifiedMxWords = 65;
const draftAuthorizationContentByMinistryMxWords = 2000;
const coveredRelatedCostsDetailsMxWords = 200;

const UpdateTrainingRequestDialog = (props) => {
  const {
    loading,
    user,
    showUpdateTrainingRequest,
    setShowUpdateTrainingRequest,
    countries,
    updateTrainingRequest,
    cdPlans,
    fiscalYears,
    trainingRequest,
    selectedFiscalYear,
    headOfInstitution,
    getHeadOfInstitution
  } = props;

  const [cdPlan, setCdPlan] = useState(null);
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [newFiscalYear, setNewFiscalYear] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [addCdPlanForm, setAddCdPlanForm] = useState(false);
  const [canSubmitRequest, setCanSubmitRequest] = useState(false);
  const [hasTextEditorChanged, setHasTextEditorChanged] = useState(false);
  const [formData, setFormData] = useState({
    id: trainingRequest.request.id,
    cdPlanId: trainingRequest.request.cdPlanId
      ? trainingRequest.request.cdPlanId
      : cdPlan
      ? cdPlan.id
      : "",
      createdOn:moment(trainingRequest.request.createdOn).format("YYYY-MM-DD"),
    isTrainingInsideCountry: trainingRequest.request.isTrainingInsideCountry,
    employeeId: trainingRequest.request.employeeId,
    employeeTitle: trainingRequest.request.employeeTitle,
    statusId: trainingRequest.request.statusId,
    employmentCategory: trainingRequest.request.employmentCategory,
    highestAcademicLevel: trainingRequest.request.highestAcademicLevel,
    trainingTitle: trainingRequest.request.trainingTitle,
    trainingAreas: trainingRequest.request.trainingAreas,
    otherTrainingAreas: trainingRequest.request.otherTrainingAreas,
    trainingCategory: trainingRequest.request.trainingCategory,
    requestType: trainingRequest.request.requestType,
    trainingDurationFrom: trainingRequest.request.trainingDurationFrom,
    trainingDurationTo: trainingRequest.request.trainingDurationTo,
    departureDate: trainingRequest.request.departureDate,
    returningDate: trainingRequest.request.returningDate,

    bookingFlightDepartureDate: trainingRequest.request.bookingFlightDepartureDate,
    bookingFlightArrivalDate: trainingRequest.request.bookingFlightArrivalDate,

    countryForTraining: trainingRequest.request.countryForTraining,
    townForTraining: trainingRequest.request.townForTraining,
    universityForTraining: trainingRequest.request.universityForTraining,
    trainingFundsProvider: trainingRequest.request.trainingFundsProvider,
    coveredRelatedCostsDetails: trainingRequest.request.coveredRelatedCostsDetails,
    permitReferenceNo:  trainingRequest.request.permitReferenceNo,
    draftAuthorizationPermitContent: trainingRequest.request.draftAuthorizationPermitContent,
    draftAuthorizationContentByMinistry: trainingRequest.request.draftAuthorizationContentByMinistry,
    draftAuthorizationSubjectByMinistry:
    trainingRequest.request.draftAuthorizationSubjectByMinistry?trainingRequest.request.draftAuthorizationSubjectByMinistry:"Request the authorization to conduct a training",
    minstryLetterReferenceNo: trainingRequest.request.minstryLetterReferenceNo,
    otherTrainingFundsProvider: trainingRequest.trainingFundProviders.find(
      (el) => el.provider === "Other"
    )
      ? trainingRequest.trainingFundProviders.find(
          (el) => el.provider === "Other"
        ).name
      : "",
      otherTrainingFundsProviderCover: trainingRequest.trainingFundProviders.find(
        (el) => el.provider === "Other"
      )
        ? trainingRequest.trainingFundProviders.find(
            (el) => el.provider === "Other"
          ).description
        : "",
    dpTrainingFundsProvider: trainingRequest.trainingFundProviders.find(
      (el) => el.provider === "Development Partner"
    )
      ? trainingRequest.trainingFundProviders.find(
          (el) => el.provider === "Development Partner"
        ).name
      : "",
      dpTrainingFundsProviderCover: trainingRequest.trainingFundProviders.find(
        (el) => el.provider === "Development Partner"
      )
        ? trainingRequest.trainingFundProviders.find(
            (el) => el.provider === "Development Partner"
          ).description
        : "",

        govtTrainingFundsProvider: trainingRequest.trainingFundProviders.find(
          (el) => el.provider === "Government"
        )
          ? trainingRequest.trainingFundProviders.find(
              (el) => el.provider === "Government"
            ).name
          : "",
          govtTrainingFundsProviderCover: trainingRequest.trainingFundProviders.find(
            (el) => el.provider === "Government"
          )
            ? trainingRequest.trainingFundProviders.find(
                (el) => el.provider === "Government"
              ).description
            : "",

            selfsponseredTrainingFundsProviderCover: trainingRequest.trainingFundProviders.find(
              (el) => el.provider === "Self-Sponsored"
            )
              ? trainingRequest.trainingFundProviders.find(
                  (el) => el.provider === "Self-Sponsored"
                ).description
              : "",
    isTrainingHasCdPlan: trainingRequest.request.isTrainingHasCdPlan,
    trainingInitiator: trainingRequest.request.trainingInitiator,
    otherTrainingInitiator: trainingRequest.request.otherTrainingInitiator,
    trainingGapsIdentified: trainingRequest.request.trainingGapsIdentified,
    lineMinistryComment: "",
    expectedTrainingAward: trainingRequest.request.expectedTrainingAward,
    trainingFundProviders: trainingRequest.trainingFundProviders.map(
      (el) => el.provider
    ),
    attachments: [],
  });

  const [employeeForm, setEmployeeForm] = useState({
    employeeId: trainingRequest.request.employeeId,
    idNumber: trainingRequest.request.idNumber,
    email: trainingRequest.request.email,
    phoneNumber: trainingRequest.request.phoneNumber,
    gender: trainingRequest.request.gender === "M" ? "Male" : "Female",
    countryOfBirth: trainingRequest.request.countryOfBirth,
    firstName: trainingRequest.request.firstName,
    lastName: trainingRequest.request.lastName,
    civilStatus: trainingRequest.request.civilStatus,
    fatherNames: trainingRequest.request.fatherNames,
    motherNames: trainingRequest.request.motherNames,
    dateOfBirth: trainingRequest.request.dateOfBirth,
    placeOfBirth: trainingRequest.request.placeOfBirth,
    professionalEmail: trainingRequest.request.professionalEmail,
    employmentDate: null,
    profilePicture: trainingRequest.profilePicture,
    isAvailable: trainingRequest.request.isAvailable,
    hasPendingExitRequest: trainingRequest.request.hasPendingExitRequest,
    entityName: trainingRequest.request.entityName,
    positionName: trainingRequest.request.positionName,
    isTrainingDatesUnlocked:trainingRequest.request.isTrainingDatesUnlocked,
  });

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(
    trainingRequest.request.nidVerified
  );
  const [idHasError, setIdHasError] = useState(false);
  const [country, setCountry] = useState(
    countries.find(
      ({ name }) => name === trainingRequest.request.countryForTraining
    )
  );
  const [errors, setErrors] = useState({
    employeeIdHasError: false,
    employeeTitleHasError: false,
    employmentCategoryHasError: false,
    highestAcademicLevelHasError: false,
    trainingTitleHasError: false,
    trainingAreasHasError: false,
    otherTrainingAreasHasError: false,
    trainingCategoryHasError: false,
    trainingDurationFromHasError: false,
    trainingDurationToHasError: false,
    otherTrainingFundsProviderHasError: false,
    trainingFundsProviderHasError: false,
    universityHasError: false,
    expectedTrainingAwardsHasError: false,
    isTrainingHasCdPlanHasError: false,
    trainingInitiatorHasError: false,
    otherTrainingInitiatorHasError: false,
    trainingGapsIdentifiedHasError: false,
    townHasError: false,
    countryHasError: false,
    doesTrainingRequiredCdPlan: false,
    isTrainingInsideCountryHasError: false,
    dpTrainingFundsProviderHasError: false,
    otherTrainingFundsProviderCoverHasError: false,
    govtTrainingFundsProviderHasError: false,
    govtTrainingFundsProviderCoverHasError: false,
    selfsponseredTrainingFundsProviderCoverHasError: false,
    dpTrainingFundsProviderCoverHasError: false,
    draftAuthorizationContentByMinistryMxWordsHasError: false,
    draftAuthorizationContentByMinistryHasError: false,
    draftAuthorizationSubjectByMinistryHasError: false,
    draftAuthorizationContentByMinistryMaxCharHasError: false,
    minstryLetterReferenceNoHasError: false,
    coveredRelatedCostsDetailsMaxWordsHasError: false,
    coveredRelatedCostsDetailsHasError: false,
    draftAuthorizationPermitContentMxWordsHasError:false,
    draftAuthorizationPermitContentHasError: false,
    attachmentHasErrors: [],
    hasError: false,
    bookingFlightDepartureDateHasError2: false,
    bookingFlightArrivalDateHasError2: false,
    bookingFlightDepartureDateHasError: false,
    bookingFlightArrivalDateHasError: false,
  });
  useEffect(() => {
    getHeadOfInstitution(user.selectedEntitySector.id);
  }, []);
  
  useEffect(() => {

    const currentFiscalYear = !!fiscalYears.length ?fiscalYears.find(({active})=>!!active):null;
    setCurrentFiscalYear(currentFiscalYear);
  }, [fiscalYears]);

  useEffect(() => {
    const cdPlan = !!cdPlans.length
      ? cdPlans.find(
          ({ fiscalYearId }) => fiscalYearId === currentFiscalYear?.id || ""
        )
      : null;

    if (cdPlan && formData && formData.isTrainingHasCdPlan === "Yes") {
      setFormData({
        ...formData,
        cdPlanId: cdPlan.id,
      });

      setErrors({
        ...errors,
        doesTrainingRequiredCdPlan: false,
      });
    }
    setCdPlan(cdPlan);
  }, [cdPlans.length, currentFiscalYear]);

  const containerRef = useRef(null);

  const formValidator = (showErrorToolTips = true) => {
    const error = {
      employeeIdHasError: false,
      employeeTitleHasError: false,
      highestAcademicLevelHasError: false,
      trainingTitleHasError: false,
      trainingAreasHasError: false,
      otherTrainingAreasHasError: false,
      trainingCategoryHasError: false,
      trainingDurationFromHasError: false,
      trainingDurationToHasError: false,
      departureDateHasError: false,
      returningDateHasError: false,
      expectedTrainingAwardsHasError: false,
      countryHasError: false,
      townHasError: false,
      universityHasError: false,
      otherTrainingFundsProviderHasError: false,
      trainingFundsProviderHasError: false,
      isTrainingHasCdPlanHasError: false,
      trainingInitiatorHasError: false,
      otherTrainingInitiatorHasError: false,
      trainingGapsIdentifiedHasError: false,
      isTrainingInsideCountryHasError: false,
      dpTrainingFundsProviderHasError: false,
      otherTrainingFundsProviderCoverHasError: false,
      govtTrainingFundsProviderHasError: false,
      govtTrainingFundsProviderCoverHasError: false,
      selfsponseredTrainingFundsProviderCoverHasError: false,
      draftAuthorizationContentByMinistryMxWordsHasError: false,
      draftAuthorizationContentByMinistryHasError: false,
      draftAuthorizationSubjectByMinistryHasError: false,
      draftAuthorizationContentByMinistryMaxCharHasError: false,
      minstryLetterReferenceNoHasError: false,
      coveredRelatedCostsDetailsMaxWordsHasError: false,
      coveredRelatedCostsDetailsHasError: false,
      dpTrainingFundsProviderCoverHasError:false,
      trainingGapsIdentifiedMaxWordsHasError:false,
      hasError: false,
    };

    if (!formData.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }

    if (!formData.employeeTitle) {
      error.employeeTitleHasError = true;
      error.hasError = true;
    }
    if (!formData.employmentCategory) {
      error.employmentCategoryHasError = true;
      error.hasError = true;
    }
    if (!formData.highestAcademicLevel) {
      error.highestAcademicLevelHasError = true;
      error.hasError = true;
    }

    if (!formData.trainingTitle) {
      error.trainingTitleHasError = true;
      error.hasError = true;
    }
    if (!formData.coveredRelatedCostsDetails) {
      error.coveredRelatedCostsDetailsHasError = true;
      error.hasError = true;
    }
    if (
      !!formData.coveredRelatedCostsDetails &&
      countWords(formData.coveredRelatedCostsDetails) >
        coveredRelatedCostsDetailsMxWords
    ) {
      error.coveredRelatedCostsDetailsMaxWordsHasError = true;
      error.hasError = true;
    }

    if (!formData.trainingGapsIdentified) {
      error.trainingGapsIdentifiedHasError = true;
      error.hasError = true;
    }
    if (
      !!formData.trainingGapsIdentified &&
      countWords(formData.trainingGapsIdentified) >
        trainingGapsIdentifiedMxWords
    ) {
      error.trainingGapsIdentifiedMaxWordsHasError = true;
      error.hasError = true;
    }
    // if (
    //   !isChoosenDateGreatThan30Days(formData.trainingDurationFrom) &&
    //   !formData.isTrainingInsideCountry
    // ) {
    //   error.trainingDurationFromHasError = true;
    //   error.hasError = true;
    // }
    // if (!formData.trainingDurationTo && !formData.isTrainingInsideCountry) {
    //   error.trainingDurationToHasError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !isChoosenDateGreatThan30Days(formData.departureDate) &&
    //   !formData.isTrainingInsideCountry
    // ) {
    //   error.departureDateHasError = true;
    //   error.hasError = true;
    // }

  
    // if (!formData.trainingDurationTo && !formData.isTrainingInsideCountry) {
    //   error.trainingDurationToHasError = true;
    //   error.hasError = true;
    // }
   
    // if (!formData.returningDate && !formData.isTrainingInsideCountry) {
    //   error.returningDateHasError = true;
    //   error.hasError = true;
    // }
    if (!formData.countryForTraining) {
      error.countryHasError = true;
      error.hasError = true;
    }
    if (!formData.townForTraining) {
      error.townHasError = true;
      error.hasError = true;
    }
    if (!formData.universityForTraining) {
      error.universityHasError = true;
      error.hasError = true;
    }

    if (!formData.trainingCategory) {
      error.trainingCategoryHasError = true;
      error.hasError = true;
    }

    if (!formData.expectedTrainingAward) {
      error.expectedTrainingAwardsHasError = true;
      error.hasError = true;
    }

    if (!formData.trainingAreas) {
      error.trainingAreasHasError = true;
      error.hasError = true;
    }
    if (!formData.isTrainingHasCdPlan) {
      error.isTrainingHasCdPlanHasError = true;
      error.hasError = true;
    }

    if (
      formData.isTrainingHasCdPlan &&
      formData.isTrainingHasCdPlan === "No" &&
      !formData.trainingInitiator
    ) {
      error.trainingInitiatorHasError = true;
      error.hasError = true;
    }
    //doesTrainingRequiredCdPlan
    if (
      formData.isTrainingHasCdPlan &&
      formData.isTrainingHasCdPlan === "Yes" &&
      !cdPlan
    ) {
      error.doesTrainingRequiredCdPlan = true;
      error.hasError = true;
    }

    if (
      formData.isTrainingHasCdPlan &&
      formData.isTrainingHasCdPlan === "No" &&
      formData.trainingInitiator &&
      formData.trainingInitiator === "Other" &&
      !formData.otherTrainingInitiator
    ) {
      error.otherTrainingInitiatorHasError = true;
      error.hasError = true;
    }
    if (
      formData.trainingAreas &&
      formData.trainingAreas === "Other" &&
      !formData.otherTrainingAreas
    ) {
      error.otherTrainingAreasHasError = true;
      error.hasError = true;
    }
    if (!formData.trainingFundProviders.length) {
      error.trainingFundsProviderHasError = true;
      error.hasError = true;
    }
    //dpTrainingFundsProvider
    if (
      formData.trainingFundProviders.find((el) => el === "Other") &&
      !formData.otherTrainingFundsProvider
    ) {
      error.otherTrainingFundsProviderHasError = true;
      error.hasError = true;
    }
    if (
      formData.trainingFundProviders.find((el) => el === "Other") &&
      !formData.otherTrainingFundsProviderCover
    ) {
      error.otherTrainingFundsProviderCoverHasError = true;
      error.hasError = true;
    }

    if (
      formData.trainingFundProviders.find(
        (el) => el === "Development Partner"
      ) &&
      !formData.dpTrainingFundsProvider
    ) {
      error.dpTrainingFundsProviderHasError = true;
      error.hasError = true;
    }

    if (
      formData.trainingFundProviders.find(
        (el) => el === "Development Partner"
      ) &&
      !formData.dpTrainingFundsProviderCover
    ) {
      error.dpTrainingFundsProviderCoverHasError = true;
      error.hasError = true;
    }

    if (
      formData.trainingFundProviders.find((el) => el === "Government") &&
      !formData.govtTrainingFundsProvider
    ) {
      error.govtTrainingFundsProviderHasError = true;
      error.hasError = true;
    }
    if (
      formData.trainingFundProviders.find((el) => el === "Government") &&
      !formData.govtTrainingFundsProviderCover
    ) {
      error.govtTrainingFundsProviderCoverHasError = true;
      error.hasError = true;
    }

    if (
      formData.trainingFundProviders.find((el) => el === "Self-Sponsored") &&
      !formData.selfsponseredTrainingFundsProviderCover
    ) {
      error.selfsponseredTrainingFundsProviderCoverHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      if (showErrorToolTips) {
        showError("Please fill out all required fields");
      }
      return true;
    }
    return false;
  };

  const formStepper1Validator = (showErrorToolTips = true) => {
    const error = {
      draftAuthorizationContentByMinistryHasError: false,
      draftAuthorizationContentByMinistryMxWordsHasError: false,
      draftAuthorizationSubjectByMinistryHasError: false,
      hasError: false,
    };

    if (!formData.draftAuthorizationContentByMinistry) {
      error.draftAuthorizationContentByMinistryHasError = true;
      error.hasError = true;
    }
    if (!formData.draftAuthorizationSubjectByMinistry) {
      error.draftAuthorizationSubjectByMinistryHasError = true;
      error.hasError = true;
    }

    if (
      !!formData.draftAuthorizationContentByMinistry &&
      countWords(formData.draftAuthorizationContentByMinistry) >
        draftAuthorizationContentByMinistryMxWords
    ) {
      error.draftAuthorizationContentByMinistryMxWordsHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      if (showErrorToolTips) {
        showError("Please fill out all required fields");
      }
      return true;
    }
    return false;
  };

  const formStepper2Validator = (showErrorToolTips = true) => {
    const error = {
      draftAuthorizationPermitContentHasError: false,
      draftAuthorizationPermitContentMxWordsHasError: false,
      hasError: false,
    };

    if (!formData.draftAuthorizationPermitContent ) {
      error.draftAuthorizationPermitContentHasError = true;
      error.hasError = true;
    }

    if (!formData.draftAuthorizationPermitContent) {
      error.draftAuthorizationPermitContentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      if (showErrorToolTips) {
        showError("Please fill out all required fields");
      }
      return true;
    }
    return false;
  };

 
  const customizeLetterBody = () => {
    return `<div>I am pleased to request the authorization for ${formData.employeeTitle}.${employeeForm.lastName.toUpperCase()} ${employeeForm.firstName} 
  ${capitalize(employeeForm.positionName)}, from ${capitalize(employeeForm.entityName) } to attend a training on ${capitalize(formData.trainingTitle)}. The training will be held at ${formData.universityForTraining} in the ${formData.countryForTraining} from ${moment(formData.trainingDurationFrom).format("MMMM Do, YYYY")} to ${moment(formData.trainingDurationTo).format("MMMM Do, YYYY")}.</div><br />
    <div>${formData.trainingGapsIdentified}. <br /> ${formData.coveredRelatedCostsDetails}</div><br />
    <div>Sincerely,</div>
    `;
  };
  const customizeLetterBody2 = () => {
    return `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">I, ${
      headOfInstitution.title
                            ? headOfInstitution.title + "."
                            : "" + headOfInstitution?.firstName
    } ${
      headOfInstitution?.lastName
    }, ${
      headOfInstitution?.positionName
    }, do hereby certify that ${
      formData.employeeTitle
    }. ${employeeForm.lastName.toUpperCase()} ${employeeForm.firstName}, ${
      employeeForm.positionName
    } in the ${capitalize(
      employeeForm.entityName
    )} is authorised to attend a training on ${capitalize(
      formData.trainingTitle
    )} in ${formData.countryForTraining} from ${moment(
      formData.trainingDurationFrom
    ).format("MMMM Do, YYYY")} to ${moment(formData.trainingDurationTo).format(
      "MMMM Do, YYYY"
    )}. <br />
${
  !formData.isTrainingInsideCountry
    ? `The date of departure is ${moment(formData.departureDate).format(
        "MMMM Do, YYYY"
      )} and the returning date is ${moment(formData.returningDate).format(
        "MMMM Do, YYYY"
      )}.`
    : ""
} <br /> ${formData.coveredRelatedCostsDetails.trim()}.<br />
I highly appreciate any assistance accorded to ${
  employeeForm.genderId === "M" ? "him" : "her"
    } in this regard.</div>
    `;
  };

  const isChoosenDateGreatThan30Days = (date) => {
    const today = moment(trainingRequest.request.createdOn).format("YYYY-MM-DD");
    const chosenDate = moment(date).format("YYYY-MM-DD"); // Replace with your chosen date
    const daysDifference = moment(chosenDate).diff(today, "days");

    if (moment(chosenDate).isAfter(today) && daysDifference >= 30) {
      return false;
    }
    return false;
  };

  const handleSave = () => {
    let allowSave = false;

    if (requestDetailTabValue === 0 && !formValidator()) {
      allowSave = true;
    }

    if (employeeForm.hasPendingExitRequest) {
      allowSave = false;
      showError("Employee has pending exist request.");
    }
    if (!employeeForm.isAvailable) {
      allowSave = false;
      showError("Employee is not available or active");
    }

    
  if(requestDetailTabValue === 1 && formData.employmentCategory === "Permanent staff"  && !formStepper1Validator()) allowSave = true;
    if (allowSave) setCanSubmitRequest(true);

  if(requestDetailTabValue === 1 &&  formData.employmentCategory === "Contractual staff"  && !formStepper2Validator()) allowSave = true;
  if (allowSave) setCanSubmitRequest(true);
};
  const onSave = () => {

    updateTrainingRequest(formData, trainingRequest, onClose);
  };



  const getEmployeeDetails = async (employeeId) => {
    try {
      setWasVerified(false);
      setIsVerifing(true);
      setEmployeeForm(null);

      const { data } = await axios.get(
        "/api/structure/find-single-employee/" + employeeId
      );

      if (data.employeeGroupId === 3) {
        formData.employmentCategory = "Contractual staff";
        setFormData({
          ...formData,
          employmentCategory: "Contractual staff",
        });
      }
      if (data.employeeGroupId === 1 || data.employeeGroupId === 2) {
        formData.employmentCategory = "Permanent staff";
        setFormData({
          ...formData,
          employmentCategory: "Permanent staff",
        });
      }
      if (data.gender === "M") {
        setFormData({
          ...formData,
          employeeId,
          employeeTitle: "Mr",
        });
      } else {
        setFormData({
          ...formData,
          employeeId,
          employeeTitle: data.civilStatus === "Married" ? "Ms" : "Miss",
        });
      }

      setEmployeeForm({
        ...employeeForm,
        employeeId: data.id,
        idNumber: data.idNumber,
        gender: data.gender === "M" ? "Male" : "Female",
        countryOfBirth: data.countryOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        civilStatus: data.civilStatus,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        phoneNumber: data.phoneNumber,
        email: data.email,
        dateOfBirth: data.dateOfBirth,
        placeOfBirth: data.placeOfBirth,
        profilePicture: data.profilePicture,
        isAvailable: data.isAvailable,
        hasPendingExitRequest: data.hasPendingExitRequest,
        entityName: data.entityName,
        positionName: data.positionName,
      });

      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
      showError(error);
    }
  };
  const onClose = () => {
    setFormData({
      cdPlanId: cdPlan ? cdPlan.id : "",
      isTrainingInsideCountry: false,
      employeeId: "",
      employeeTitle: "",
      statusId: 1,
      employmentCategory: "",
      highestAcademicLevel: "",
      trainingTitle: "",
      trainingAreas: "",
      otherTrainingAreas: "",
      trainingCategory: "",
      requestType: "",
      trainingDurationFrom: null,
      trainingDurationTo: null,
      departureDate: null,
      returningDate: null,
      countryForTraining: "",
      townForTraining: "",
      universityForTraining: "",
      trainingFundsProvider: "",
      otherTrainingFundsProvider: "",
      dpTrainingFundsProvider: "",
      isTrainingHasCdPlan: false,
      trainingInitiator: "",
      otherTrainingInitiator: "",
      trainingGapsIdentified: "",
      lineMinistryComment: "",
      expectedTrainingAward: "",
      trainingFundProviders: [],
      attachments: [],
    });
    setEmployeeForm({
      employeeId: "",
      idNumber: "",
      email: "",
      phoneNumber: "",
      gender: "",
      countryOfBirth: "",
      firstName: "",
      lastName: "",
      civilStatus: "",
      fatherNames: "",
      motherNames: "",
      dateOfBirth: "",
      placeOfBirth: "",
      professionalEmail: "",
      employmentDate: null,
      profilePicture: null,
    });
    setCanSubmitRequest(true);
    setShowUpdateTrainingRequest(false);
  };
  // console.log(user?.selectedEntitySector);
  const getNextMonthDate = () => {
    var currentDate = moment(trainingRequest.request.createdOn).format("YYYY-MM-DD"); // June is represented by 5 (zero-based)
    var nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );

    return nextMonthDate;
  };

  const [tabValue, setTabValue] = useState(0);
  const [requestDetailTabValue, setRequestDetailTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getEditorContent = (data) => {
    // console.log(data);
    let draftAuthorizationContentByMinistryMaxCharHasError = false;
    // if (data && data.isChanged) setHasTextEditorChanged(data.isChanged);
    if (data && data.contents)
      setFormData({
        ...formData,
        draftAuthorizationContentByMinistry: data.contents,
      });
    if (data && !!data.isMaxChar)
      draftAuthorizationContentByMinistryMaxCharHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationContentByMinistryMaxCharHasError:
        draftAuthorizationContentByMinistryMaxCharHasError,
    });
  };
  const getEditorContent2 = (data) => {
    // console.log(data);
    let draftAuthorizationPermitContentMxWordsHasError = false;
    // if (data && data.isChanged) setHasTextEditorChanged(data.isChanged);
    if (data && data.contents)
      setFormData({
        ...formData,
        draftAuthorizationPermitContent: data.contents,
      });
    if (data && !!data.isMaxChar)
      draftAuthorizationPermitContentMxWordsHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationPermitContentMxWordsHasError:
      draftAuthorizationPermitContentMxWordsHasError,
    });
  };
  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUpdateTrainingRequest}
        fullWidth
        maxWidth={requestDetailTabValue===1?"md":"lg"}
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>
                {user?.selectedEntitySector && (
                  <>
                    <div className="row">
                      <div className="col-12 mt-4">
                        <div className="d-flex justify-content-start">
                          <small
                            className="text-primary fw-bold text-truncate "
                            style={{ fontSize: "14px", marginTop: "-2px" }}
                          >
                            Employee Training Request
                          </small>{" "}
                          <small
                            style={{
                              fontSize: "12px",
                              position: "relative",
                              top: 1,
                            }}
                            className="fas fa-angle-right ml-1 mt-0"
                          ></small>
                          <small
                            className="ml-1 fw-bold text-truncate text-dark"
                            style={{ fontSize: "12px" }}
                          >
                            {textCapitalized(
                              user?.selectedEntitySector.entityName
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </h5>
            </div>
            <div className="col-3">
              <div className="row">
              <div className="col">
                  {!!employeeForm?.isTrainingDatesUnlocked && (
                    <>
                      {" "}
                      <strong className="badge badge-success mt-4 float-right">
                        Unlocked For 30 days
                      </strong>
                    </>
                  )}
                </div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Tabs
              className="mt-2 justify-content-end"
              value={tabValue}
              onChange={handleChange}
              aria-label="tabs"
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                className="text-capitalize"
                label="Edit Request Details & Letter"
                {...a11yProps(0)}
              />
              <Tab
                className="text-capitalize"
                label="Edit Attachments
             "
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          {cdPlan && (
            <div className="row mt-3">
              <div className="col-lg-12">
                {showDocument && (
                  <PreviewPdfUrl
                    showDocument={showDocument}
                    setShowDocument={setShowDocument}
                    document={{
                      name: `View cd plan for fiscal year of ${trainingRequest.request.fiscalYearId}`,
                      url: process.env.REACT_APP_FILES_URL +'/preview/'+ trainingRequest.request.documentReferenceId
                    }}
                  />
                )}
                <Alert severity="info" icon={<AttachFileIcon />}>
                  <Link to="#" onClick={() => setShowDocument(true)}>
                    {" "}
                    View cd plan for fiscal year of{" "}
                    {trainingRequest.request.fiscalYearId
                      ? trainingRequest.request.fiscalYearId
                      : cdPlan.fiscalYear.name}
                  </Link>
                </Alert>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent className="mt-0 mx-4">
          <Box sx={{ width: "100%" }}>
            <React.Fragment>
              {tabValue === 0 && requestDetailTabValue===0 && (
                <>
                  <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                    <div className="card service-card valid mb-2 mt-1">
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem" }}
                      >
                        Identification{" "}
                      </div>
                      <div className="card-body">
                        <div className="row" ref={containerRef}>
                          <div className="col-lg-5">
                            <TextField
                              fullWidth
                              size="small"
                              rows={4}
                              type="number"
                              name="Enter Employee ID"
                              autoFocus
                              label="Enter Employee ID"
                              variant="outlined"
                              placeholder="Enter Employee ID"
                              disabled
                              value={formData.employeeId || ""}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"></InputAdornment>
                                ),
                              }}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  e.target.value.length === 10
                                ) {
                                  getEmployeeDetails(e.target.value);
                                }
                              }}
                              onChange={(e) => {
                                setEmployeeForm({
                                  ...employeeForm,
                                  employeeId: e.target.value,
                                });
                                setFormData({
                                  ...formData,
                                  employeeId: e.target.value,
                                });

                                if (e.target.value.length === 10) {
                                  setErrors({
                                    ...errors,
                                    employeeIdHasError: false,
                                  });
                                  getEmployeeDetails(e.target.value);
                                }
                              }}
                            />
                            {errors.employeeIdHasError && (
                              <small className="text-danger ">
                                Employee ID is required{" "}
                              </small>
                            )}
                          </div>
                          <div className="col-lg-7">
                            <div className="row">
                              <div className="col-12">
                                <FormControl>
                                  <RadioGroup
                                    row
                                    name="employeeTitle"
                                    value={formData?.employeeTitle || ""}
                                  >
                                    {titles.map((title, index) => (
                                      <div key={index}>
                                        <FormControlLabel
                                          value={title}
                                          disabled={
                                            title === "Mr" &&
                                            employeeForm?.gender &&
                                            employeeForm?.gender === "Male"
                                              ? false
                                              : (title === "Ms" ||
                                                  title === "Miss") &&
                                                employeeForm?.gender &&
                                                employeeForm?.gender ===
                                                  "Female"
                                              ? false
                                              : true
                                          }
                                          control={<Radio size="small" />}
                                          checked={
                                            title === formData?.employeeTitle
                                              ? true
                                              : false
                                          }
                                          name="employeeTitle"
                                          label={title}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              employeeTitle: e.target.value,
                                            });

                                            setErrors({
                                              ...errors,
                                              employeeTitleHasError: false,
                                            });
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </RadioGroup>
                                </FormControl>{" "}
                              </div>
                              <div
                                className="col-12"
                                style={{ marginTop: "-15px" }}
                              >
                                {errors.employeeTitleHasError && (
                                  <small className="text-danger ">
                                    Title is required{" "}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          {isVerifing && (
                            <div className="col-12">
                              <LinearProgress />
                            </div>
                          )}

                          {wasVerified && (
                            <Slide
                              direction="down"
                              in={true}
                              container={containerRef.current}
                            >
                              <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                                <fieldset className="form-group border p-2">
                                  <legend
                                    className="w-auto px-2"
                                    style={{
                                      color: "rgba(0, 0, 0, 0.6)",
                                      fontFamily:
                                        '"Roboto","Helvetica","Arial",sans-serif',
                                      fontWeight: 400,
                                      fontSize: "0.82rem",
                                      lineHeight: "1.4375em",
                                      letterSpacing: "0.00938em",
                                    }}
                                  >
                                    Employee profile
                                  </legend>
                                  <div className="form-group1">
                                    <div className="row mt-0">
                                      <div className="col-2 justify-content-center text-uppercase text-center">
                                        <h6 className="mb-2">Profile</h6>
                                        <div
                                          style={{
                                            height: "auto",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <>
                                            {employeeForm.profilePicture && (
                                              <img
                                                className="mt-0 rounded  text-center"
                                                src={
                                                  "data:image/jpg;base64," +
                                                  employeeForm.profilePicture
                                                }
                                                style={{
                                                  border: "1px solid #a6d3df",
                                                  height: "128px",
                                                  // width: "130px",
                                                  overflow: "hidden",
                                                  // borderRadius: "50%",
                                                }}
                                              />
                                            )}
                                            {!employeeForm.profilePicture && (
                                              <img
                                                className="mt-0  rounded text-center"
                                                src={defaultPofile}
                                                style={{
                                                  // border: "1px solid #a6d3df",
                                                  height: "128px",
                                                  width: "100px",
                                                  overflow: "hidden",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                            )}
                                          </>
                                        </div>

                                        {employeeForm.isAvailable && (
                                          <span className="text-success font-weight-bolder">
                                            <span
                                              className="material-icons d-block"
                                              style={{ fontSize: "48px" }}
                                            >
                                              check_circle_outline
                                            </span>
                                            <span
                                              style={{ fontSize: "12px" }}
                                              className="mt-n1 d-block"
                                            >
                                              Available
                                            </span>
                                          </span>
                                        )}
                                        {!employeeForm.isAvailable && (
                                          <>
                                            <span className="text-danger font-weight-bolder">
                                              <span
                                                className="material-icons d-block"
                                                style={{ fontSize: "48px" }}
                                              >
                                                highlight_off
                                              </span>
                                              <span
                                                style={{ fontSize: "12px" }}
                                                className="mt-n1 d-block"
                                              >
                                                Not available
                                              </span>
                                            </span>
                                          </>
                                        )}
                                      </div>
                                      <div className="col-10 row">
                                        <div className="col-4 justify-content-center">
                                          {!idHasError &&
                                            !isVerifing &&
                                            !wasVerified && (
                                              <span className="material-icons loaderIcon text-danger">
                                                help
                                              </span>
                                            )}

                                          {!!isVerifing && (
                                            <i
                                              className="fas fa-spinner spinner loaderIcon text-primary"
                                              style={{ fontSize: "22px" }}
                                            ></i>
                                          )}

                                          {!isVerifing && !!idHasError && (
                                            <span className="loaderIcon text-danger d-flex align-items-center">
                                              <span
                                                className="material-icons text-danger"
                                                style={{
                                                  position: "relative",
                                                  fontSize: "16px",
                                                  top: "3px",
                                                  left: "0px",
                                                }}
                                              >
                                                report_problem
                                              </span>
                                            </span>
                                          )}

                                          {!idHasError &&
                                            !isVerifing &&
                                            !!wasVerified && (
                                              <span className="loaderIcon text-success d-flex align-items-center">
                                                <span
                                                  className="material-icons text-success "
                                                  style={{
                                                    position: "relative",
                                                    fontSize: "16px",
                                                    top: "3px",
                                                    left: "0px",
                                                  }}
                                                >
                                                  verified
                                                </span>
                                              </span>
                                            )}

                                          <MaskedInput
                                            mask={idNumberPattern}
                                            className="form-control"
                                            placeholder="Employee NID Number"
                                            guide={false}
                                            readOnly={true}
                                            value={employeeForm.idNumber}
                                            style={{
                                              background: "#fff",
                                              fontSize: "13px",
                                              padding: "18px",
                                            }}
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="lastName"
                                            readOnly={true}
                                            label="Last Name"
                                            // variant="standard"
                                            className="my-3"
                                            value={employeeForm.lastName}
                                          />

                                          <TextField
                                            readOnly={true}
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            className="mb-3"
                                            value={employeeForm.firstName}
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="civilStatus"
                                            readOnly={true}
                                            label="Civil Status"
                                            variant="outlined"
                                            value={employeeForm.civilStatus}
                                          />
                                        </div>

                                        <div className="col-4 justify-content-center">
                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="gender"
                                            readOnly={true}
                                            label="Gender"
                                            variant="outlined"
                                            className="mb-3 font-weight-bold"
                                            value={employeeForm.gender}
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="fatherNames"
                                            value={employeeForm.fatherNames}
                                            readOnly={true}
                                            label="Father Names"
                                            variant="outlined"
                                            className="mb-3"
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="motherNames"
                                            value={employeeForm.motherNames}
                                            readOnly={true}
                                            label="Mother Names"
                                            variant="outlined"
                                            className="mb-3"
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            autoComplete="false"
                                            name="phone"
                                            label="Phone number"
                                            variant="outlined"
                                            placeholder="07xxxxxxxx"
                                            readOnly={true}
                                            value={employeeForm.phoneNumber}
                                          />
                                        </div>

                                        <div className="col-4 justify-content-center">
                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="countryOfBirth"
                                            value={
                                              employeeForm.countryOfBirth || ""
                                            }
                                            readOnly={true}
                                            label="Country of Birth"
                                            variant="outlined"
                                            className="mb-3"
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            name="dateOfBirth"
                                            value={
                                              employeeForm.dateOfBirth
                                                ? moment(
                                                    employeeForm.dateOfBirth
                                                  ).format("ll")
                                                : ""
                                            }
                                            readOnly={true}
                                            label="Date Of Birth"
                                            variant="outlined"
                                            className="mb-3"
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            readOnly={true}
                                            name="placeOfBirth"
                                            value={
                                              employeeForm.placeOfBirth || ""
                                            }
                                            label="Place of Birth"
                                            variant="outlined"
                                            className="mb-3"
                                          />

                                          <TextField
                                            fullWidth
                                            size="small"
                                            rows={4}
                                            autoComplete="false"
                                            name="email"
                                            label="Personal email"
                                            placeholder="Personal email"
                                            variant="outlined"
                                            value={employeeForm.email}
                                            readOnly={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                                {employeeForm.hasPendingExitRequest && (
                                  <ErrorMessage
                                    hasError={true}
                                    message={
                                      <>Employee has pending exist request!</>
                                    }
                                  />
                                )}
                              </div>
                            </Slide>
                          )}
                        </div>
                      </div>
                    </div>

                    <Slide
                      direction="down"
                      in={true}
                      container={containerRef.current}
                    >
                      <div className="card service-card valid mb-3 mt-4">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Employment Information
                        </div>
                        <div className="card-body">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employer (Institution)
                            </legend>
                            <div className="form-group1 mb-2">
                              {employeeForm.entityName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Job position
                            </legend>
                            <div className="form-group1 mb-2">
                              {employeeForm.positionName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Employment category
                            </legend>
                            <div className="form-group1 mb-0">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="employmentCategory-label"
                                      name="employmentCategory"
                                      value={formData?.employmentCategory || ""}
                                    >
                                      {employmentCategories.map(
                                        (employmentCategory, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={employmentCategory}
                                              name="employmentCategory"
                                              label={employmentCategory}
                                              control={<Radio size="small" />}
                                              disabled={true}
                                              checked={
                                                employmentCategory ===
                                                formData?.employmentCategory
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  employmentCategory:
                                                    e.target.value,
                                                });

                                                setErrors({
                                                  ...errors,
                                                  employmentCategoryHasError: false,
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.employmentCategoryHasError && (
                                    <small className="text-danger ">
                                      Employment category is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </Slide>

                    <Slide
                      direction="down"
                      in={true}
                      container={containerRef.current}
                    >
                      <div className="card service-card valid mb-3 mt-4">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          Educational Background
                        </div>
                        <div className="card-body row">
                          <div className="col-lg-12">
                            <fieldset className="form-group border p-2">
                              <legend
                                className="w-auto px-2"
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontFamily:
                                    '"Roboto","Helvetica","Arial",sans-serif',
                                  fontWeight: 400,
                                  fontSize: "0.82rem",
                                  lineHeight: "1em",
                                  letterSpacing: "0.00938em",
                                }}
                              >
                                Highest academic level of the public servant
                              </legend>
                              <div className="form-group1 mb-0">
                                <div className="row">
                                  <div className="col-9">
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="highestAcademicLevel-label"
                                        name="highestAcademicLevel"
                                        value={
                                          formData?.highestAcademicLevel || ""
                                        }
                                      >
                                        {highestAcademicLevels.map(
                                          (highestAcademicLevel, index) => (
                                            <div key={index}>
                                              <FormControlLabel
                                                value={highestAcademicLevel}
                                                name="highestAcademicLevel"
                                                control={<Radio size="small" />}
                                                checked={
                                                  highestAcademicLevel ===
                                                  formData?.highestAcademicLevel
                                                    ? true
                                                    : false
                                                }
                                                label={highestAcademicLevel}
                                                onChange={(e) => {
                                                  setFormData({
                                                    ...formData,
                                                    highestAcademicLevel:
                                                      e.target.value,
                                                  });

                                                  setErrors({
                                                    ...errors,
                                                    highestAcademicLevelHasError: false,
                                                  });
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                  <div className="col-3">
                                  {/* {showApplicationCv && (
                                    <ApplicationCv
                                      showApplicationCv={showApplicationCv}
                                      setShowApplicationCv={
                                        setShowApplicationCv
                                      }
                                      userId={employeeForm.employeeId}
                                    />
                                  )} */}
                                  {/* <button
                                    disabled={
                                      employeeForm && !!employeeForm.entityName
                                        ? false
                                        : true
                                    }
                                    type="button"
                                    onClick={() => setShowApplicationCv(true)}
                                    className="btn btn-sm my-0 btn-secondary float-right"
                                  >
                                    <PreviewIcon className="text-white mr-1" />
                                    View Resume
                                  </button> */}
                                </div>

                                  <div
                                    className="col-12"
                                    style={{ marginTop: "-15px" }}
                                  >
                                    {errors.highestAcademicLevelHasError && (
                                      <small className="text-danger ">
                                        Educational level is required{" "}
                                      </small>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  </Typography>

                  <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                    <Slide
                      direction="down"
                      in={true}
                      container={containerRef.current}
                    >
                      <div className="row">
                        <div className="col-lg-12 mt-1">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Title of the training
                            </legend>
                            <div className="form-group1 mb-2">
                              <TextField
                                fullWidth
                                autoFocus
                                size="small"
                                rows={4}
                                name="trainingTitle"
                                label="Title"
                                variant="outlined"
                                value={formData.trainingTitle}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    trainingTitle: e.target.value,
                                  });

                                  setErrors({
                                    ...errors,
                                    trainingTitleHasError: false,
                                  });
                                }}
                              />
                              {errors.trainingTitleHasError && (
                                <small className="text-danger ">
                                  Training title is required{" "}
                                </small>
                              )}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Areas
                            </legend>
                            <div className="form-group1 mb-2 overflow-hidden">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="trainingArea-label"
                                      name="trainingArea"
                                      value={formData?.trainingAreas || ""}
                                    >
                                      {trainingAreas.map(
                                        (trainingArea, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={trainingArea}
                                              name="trainingArea"
                                              label={trainingArea}
                                              control={<Radio size="small" />}
                                              checked={
                                                trainingArea ===
                                                formData?.trainingAreas
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  trainingAreas: e.target.value,
                                                });

                                                setErrors({
                                                  ...errors,
                                                  trainingAreasHasError: false,
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.trainingAreasHasError && (
                                    <small className="text-danger ">
                                      Training areas is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                              {formData?.trainingAreas &&
                                formData?.trainingAreas === "Other" && (
                                  <Slide
                                    direction="right"
                                    in={true}
                                    container={containerRef.current}
                                  >
                                    <fieldset className="form-group border p-2 mt-2">
                                      <legend
                                        className="w-auto px-2"
                                        style={{
                                          color: "rgba(0, 0, 0, 0.6)",
                                          fontFamily:
                                            '"Roboto","Helvetica","Arial",sans-serif',
                                          fontWeight: 400,
                                          fontSize: "0.82rem",
                                          lineHeight: "1em",
                                          letterSpacing: "0.00938em",
                                        }}
                                      >
                                        If other, specify
                                      </legend>
                                      <div className="form-group1 mb-2">
                                        <TextField
                                          fullWidth
                                          size="small"
                                          rows={4}
                                          name="otherTrainingAreas"
                                          label="specify other training areas"
                                          variant="outlined"
                                          value={formData.otherTrainingAreas}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              otherTrainingAreas:
                                                e.target.value,
                                            });

                                            setErrors({
                                              ...errors,
                                              otherTrainingAreasHasError: false,
                                            });
                                          }}
                                        />
                                        {errors.otherTrainingAreasHasError && (
                                          <small className="text-danger ">
                                            Please specify other training areas{" "}
                                          </small>
                                        )}
                                      </div>
                                    </fieldset>
                                  </Slide>
                                )}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training category
                            </legend>
                            <div className="form-group1">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="trainingCategory-label"
                                      name="trainingCategory"
                                      value={formData?.trainingCategory || ""}
                                    >
                                      {trainingCategories.map(
                                        (trainingCategory, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={trainingCategory.name}
                                              name="trainingCategory"
                                              label={trainingCategory.name}
                                              control={<Radio size="small" />}
                                              disabled={true}
                                              checked={
                                                trainingCategory.name ===
                                                formData?.trainingCategory
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                if (
                                                  trainingCategory.name !==
                                                  formData?.trainingCategory
                                                )
                                                  setFormData({
                                                    ...formData,
                                                    attachments: [],
                                                    requestType:
                                                      trainingCategories.find(
                                                        ({ name }) =>
                                                          name ===
                                                          e.target.value
                                                      ).key,
                                                    trainingCategory:
                                                      e.target.value,
                                                  });

                                                setErrors({
                                                  ...errors,
                                                  attachmentHasErrors: [],
                                                  trainingCategoryHasError: false,
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.trainingCategoryHasError && (
                                    <small className="text-danger ">
                                      Training category is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Specify the training location
                            </legend>
                            <div className="form-group1">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingInsideCountry-label"
                                      name="isTrainingInsideCountry"
                                    >
                                      <FormControlLabel
                                        value="In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          formData.isTrainingInsideCountry
                                        }
                                        onChange={(e) => {
                                          const country = countries.find(
                                            ({ name }) =>
                                              name.toLowerCase() === "rwanda"
                                          );
                                          setCountry(country || null);
                                          setFormData({
                                            ...formData,
                                            isTrainingInsideCountry: true,
                                            countryForTraining: "Rwanda",
                                            trainingDurationFrom: null,
                                            trainingDurationTo: null,
                                            departureDate: null,
                                            returningDate: null,
                                            townForTraining: "",
                                            universityForTraining: "",
                                          });

                                          setErrors({
                                            ...errors,
                                            isTrainingInsideCountryHasError: false,
                                          });
                                        }}
                                      />
                                      <FormControlLabel
                                        value="Not In Rwanda?"
                                        name="isTrainingInsideCountry"
                                        label="Not In Rwanda?"
                                        control={<Radio size="small" />}
                                        checked={
                                          !formData.isTrainingInsideCountry
                                        }
                                        onChange={(e) => {
                                          setCountry(null);
                                          setFormData({
                                            ...formData,
                                            isTrainingInsideCountry: false,
                                            countryForTraining: "",
                                            trainingDurationFrom: null,
                                            trainingDurationTo: null,
                                            departureDate: null,
                                            returningDate: null,
                                            townForTraining: "",
                                            universityForTraining: "",
                                          });

                                          setErrors({
                                            ...errors,
                                            isTrainingInsideCountryHasError: false,
                                          });
                                        }}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.isTrainingInsideCountryHasError && (
                                    <small className="text-danger ">
                                      This field is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training duration
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      disabled={true}
                                      // minDate={getNextMonthDate()}
                                      skipDisabled
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          trainingDurationFrom:
                                            formData.trainingDurationFrom,
                                        });
                                      }}
                                      label="From"
                                      inputFormat="MM/dd/yyyy"
                                      value={formData.trainingDurationFrom}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          trainingDurationFrom: date,
                                          trainingDurationTo: null,
                                          departureDate: null,
                                          returningDate: null,
                                        });

                                        setErrors({
                                          ...errors,
                                          trainingDurationFromHasError: false,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.trainingDurationFromHasError && (
                                    <small className="text-danger mt-1">
                                      Training starting date is required
                                    </small>
                                  )}
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      // minDate={formData.trainingDurationFrom}
                                      disabled={true}
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          trainingDurationTo:
                                            formData.trainingDurationTo ||
                                            formData.trainingDurationFrom,
                                        });
                                      }}
                                      label="To"
                                      inputFormat="MM/dd/yyyy"
                                      value={formData.trainingDurationTo}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          trainingDurationTo: date,
                                          returningDate: null,
                                        });

                                        setErrors({
                                          ...errors,
                                          trainingDurationToHasError: false,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.trainingDurationToHasError && (
                                    <small className="text-danger mt-1">
                                      Training ending date is required
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      // minDate={getNextMonthDate()}
                                      // maxDate={formData.trainingDurationFrom}
                                      disabled={true}
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          departureDate:
                                            formData.departureDate
                                        });
                                      }}
                                      label="Departure date"
                                      inputFormat="MM/dd/yyyy"
                                      value={formData.departureDate}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          departureDate: date,
                                          returningDate: null,
                                        });

                                        setErrors({
                                          ...errors,
                                          departureDateHasError: false,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.departureDateHasError && (
                                    <small className="text-danger mt-1">
                                      Departure date is required
                                    </small>
                                  )}
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      // minDate={formData.trainingDurationTo}
                                      label="Returning date"
                                      inputFormat="MM/dd/yyyy"
                                      disabled={true}
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          returningDate:
                                            formData.returningDate ||
                                            formData.trainingDurationTo,
                                        });
                                      }}
                                      value={formData.returningDate}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          returningDate: date,
                                        });

                                        setErrors({
                                          ...errors,
                                          returningDateHasError: false,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.returningDateHasError && (
                                    <small className="text-danger mt-1">
                                      Returning date is required
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          {!formData.isTrainingInsideCountry && (
                        <fieldset className="form-group border p-2">
                          <legend
                            className="w-auto px-2"
                            style={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              lineHeight: "1em",
                              letterSpacing: "0.00938em",
                            }}
                          >
                           Booking Flight Date
                          </legend>
                          <div className="form-group1 mb-2">
                           
                        
                              <div className="row mt-2">
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      // minDate={getNextMonthDate()}
                                      // maxDate={formData.trainingDurationFrom}
                                      disabled={
                                        true
                                      }
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          bookingFlightDepartureDate:
                                            formData.bookingFlightDepartureDate ||
                                            getNextMonthDate(),
                                        });
                                      }}
                                      label="Departure"
                                      inputFormat="MM/dd/yyyy"
                                      value={formData.bookingFlightDepartureDate}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          bookingFlightDepartureDate: date,
                                          returningDate: null,
                                        });
                                        let bookingFlightDepartureDateHasError = false;
                                      
                                        setErrors({
                                          ...errors,
                                          bookingFlightDepartureDateHasError,
                                          bookingFlightDepartureDateHasError2:false
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.bookingFlightDepartureDateHasError && (
                                    <small className="text-danger mt-1">
                                      The Departure is required
                                    </small>
                                  )}
                                   {errors.bookingFlightDepartureDateHasError2 && (
                                    <small className="text-danger mt-1">
                                      The Booking Flight Departure date should be the same as Training Departure  Date
                                    </small>
                                  )}
                                </div>
                                <div className="col-lg-6 mt-1">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      // minDate={formData.trainingDurationTo}
                                      label="Arrival"
                                      inputFormat="MM/dd/yyyy"
                                      disabled={
                                      true
                                      }
                                      onOpen={() => {
                                        setFormData({
                                          ...formData,
                                          bookingFlightArrivalDate:
                                            formData.bookingFlightArrivalDate
                                        });
                                      }}
                                      value={formData.bookingFlightArrivalDate}
                                      onChange={(date) => {
                                        setFormData({
                                          ...formData,
                                          bookingFlightArrivalDate: date,
                                        });

                                        setErrors({
                                          ...errors,
                                          bookingFlightArrivalDateHasError: false,
                                          bookingFlightArrivalDateHasError2:false
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {errors.bookingFlightArrivalDateHasError && (
                                    <small className="text-danger mt-1">
                                      Arrival is required
                                    </small>
                                  )}
                                  {errors.bookingFlightArrivalDateHasError2 && (
                                    <small className="text-danger mt-1">
                                      The Booking Flight Arrival date should be the same as Training Returning Date
                                    </small>
                                  )}
                                </div>
                              </div>
                         
                          </div>
                        </fieldset>
                           )}

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training place
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-lg-4 mt-1">
                                  <Autocomplete
                                    size="small"
                                    id="country"
                                    disabled={formData.isTrainingInsideCountry}
                                    defaultValue={null}
                                    value={country || null}
                                    options={countries}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                    onChange={(event, country) => {
                                      setCountry(country || null);
                                      //countryForTraining
                                      setFormData({
                                        ...formData,
                                        countryForTraining:
                                          country?.name || null,
                                        townForTraining: "",
                                        universityForTraining: "",
                                      });
                                      setErrors({
                                        ...errors,
                                        countryHasError: false,
                                      });
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, country) => (
                                      <Box component="li" {...props}>
                                        {country.name}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        placeholder="Select country"
                                        {...params}
                                        label="Select country"
                                        inputProps={{
                                          ...params.inputProps,
                                        }}
                                      />
                                    )}
                                  />
                                  {errors.countryHasError && (
                                    <small className="text-danger ">
                                      Country is required{" "}
                                    </small>
                                  )}
                                </div>
                                <div className="col-lg-4 mt-1">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    rows={4}
                                    name="townForTraining"
                                    label="Town"
                                    variant="outlined"
                                    value={formData.townForTraining || ""}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        townForTraining: e.target.value,
                                      });

                                      setErrors({
                                        ...errors,
                                        townHasError: false,
                                      });
                                    }}
                                  />
                                  {errors.townHasError && (
                                    <small className="text-danger ">
                                      Town is required{" "}
                                    </small>
                                  )}
                                </div>

                                <div className="col-lg-4 mt-1">
                                  <TextField
                                    fullWidth
                                    size="small"
                                    rows={4}
                                    name="universityForTraining"
                                    label="Institute/University"
                                    variant="outlined"
                                    value={formData.universityForTraining || ""}
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        universityForTraining: e.target.value,
                                      });

                                      setErrors({
                                        ...errors,
                                        universityHasError: false,
                                      });
                                    }}
                                  />
                                  {errors.universityHasError && (
                                    <small className="text-danger ">
                                      Institute/University is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Training Funds Provider
                            </legend>
                            <div className="form-group1 mb-2 overflow-hidden">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl className="w-100">
                                    <RadioGroup
                                      
                                      aria-labelledby="trainingFundsProvider-label"
                                      name="trainingFundsProvider"
                                      value={
                                        formData?.trainingFundsProvider || ""
                                      }
                                    >
                                      {trainingFunds.map(
                                        (trainingFund, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={trainingFund}
                                              name="trainingFund"
                                              label={trainingFund}
                                              control={
                                                <Checkbox size="small" />
                                              }
                                              checked={
                                                formData.trainingFundProviders.find(
                                                  (el) => el === trainingFund
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                const trainingFundProviders =
                                                  formData.trainingFundProviders;
                                                if (
                                                  trainingFundProviders.find(
                                                    (el) => el === trainingFund
                                                  )
                                                ) {
                                                  const index2 =
                                                    trainingFundProviders.findIndex(
                                                      (el) =>
                                                        el === trainingFund
                                                    );

                                                  trainingFundProviders.splice(
                                                    index2,
                                                    1
                                                  );
                                                } else {
                                                  trainingFundProviders.push(
                                                    trainingFund
                                                  );
                                                }

                                                setFormData({
                                                  ...formData,
                                                  trainingFundProviders,
                                                });

                                                setErrors({
                                                  ...errors,
                                                  trainingFundsProviderHasError:
                                                    !!formData
                                                      .trainingFundProviders
                                                      .length
                                                      ? false
                                                      : true,
                                                });
                                              }}
                                            />

                                            
                                          {formData.trainingFundProviders.find(
                                            (el) => el === "Government"
                                          ) &&
                                            trainingFund === "Government" && (
                                              <Slide
                                                direction="right"
                                                in={true}
                                                container={containerRef.current}
                                              >
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If Government, specify the
                                                      institution
                                                    </legend>
                                                    <div className="form-group1 mb-2">
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        rows={4}
                                                        name="govtTrainingFundsProvider"
                                                        label="Specify the institution"
                                                        variant="outlined"
                                                        value={
                                                          formData.govtTrainingFundsProvider
                                                        }
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            govtTrainingFundsProvider:
                                                              e.target.value,
                                                          });

                                                          setErrors({
                                                            ...errors,
                                                            govtTrainingFundsProviderHasError: false,
                                                          });
                                                        }}
                                                      />
                                                      {errors.dpTrainingFundsProviderHasError && (
                                                        <small className="text-danger ">
                                                          Please specify the
                                                          institution.
                                                        </small>
                                                      )}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {formData.govtTrainingFundsProvider
                                                        ? formData.govtTrainingFundsProvider
                                                        : "government"}{" "}
                                                      cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2">
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        name="govtTrainingFundsProvider"
                                                        label={`What will ${
                                                          formData.govtTrainingFundsProvider
                                                            ? formData.govtTrainingFundsProvider
                                                            : "government"
                                                        } cover?`}
                                                        variant="outlined"
                                                        value={
                                                          formData.govtTrainingFundsProviderCover
                                                        }
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            govtTrainingFundsProviderCover:
                                                              e.target.value,
                                                          });

                                                          setErrors({
                                                            ...errors,
                                                            govtTrainingFundsProviderCoverHasError: false,
                                                          });
                                                        }}
                                                      />
                                                      {errors.govtTrainingFundsProviderCoverHasError && (
                                                        <small className="text-danger ">
                                                          This field is required
                                                        </small>
                                                      )}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              </Slide>
                                            )}

                                          {formData.trainingFundProviders.find(
                                            (el) => el === "Development Partner"
                                          ) &&
                                            trainingFund ===
                                              "Development Partner" && (
                                              <Slide
                                                direction="right"
                                                in={true}
                                                container={containerRef.current}
                                              >
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      If development partner,
                                                      specify
                                                    </legend>
                                                    <div className="form-group1 mb-2">
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        rows={4}
                                                        name="dpTrainingFundsProvider"
                                                        label="Specify the development partner"
                                                        variant="outlined"
                                                        value={
                                                          formData.dpTrainingFundsProvider
                                                        }
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            dpTrainingFundsProvider:
                                                              e.target.value,
                                                          });

                                                          setErrors({
                                                            ...errors,
                                                            dpTrainingFundsProviderHasError: false,
                                                          });
                                                        }}
                                                      />
                                                      {errors.dpTrainingFundsProviderHasError && (
                                                        <small className="text-danger ">
                                                          Please specify
                                                          development partner{" "}
                                                        </small>
                                                      )}
                                                    </div>
                                                  </fieldset>

                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will{" "}
                                                      {formData.dpTrainingFundsProvider
                                                        ? formData.dpTrainingFundsProvider
                                                        : "development partner"}{" "}
                                                      cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2">
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        name="dpTrainingFundsProviderCover"
                                                        label={`What will ${
                                                          formData.dpTrainingFundsProvider
                                                            ? formData.dpTrainingFundsProvider
                                                            : "development partner"
                                                        } cover?`}
                                                        variant="outlined"
                                                        value={
                                                          formData.dpTrainingFundsProviderCover
                                                        }
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            dpTrainingFundsProviderCover:
                                                              e.target.value,
                                                          });

                                                          setErrors({
                                                            ...errors,
                                                            dpTrainingFundsProviderCoverHasError: false,
                                                          });
                                                        }}
                                                      />
                                                      {errors.dpTrainingFundsProviderCoverHasError && (
                                                        <small className="text-danger ">
                                                          This field is required
                                                        </small>
                                                      )}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              </Slide>
                                            )}

                                          {formData.trainingFundProviders.find(
                                            (el) => el === "Self-Sponsored"
                                          ) &&
                                            trainingFund ===
                                              "Self-Sponsored" && (
                                              <Slide
                                                direction="right"
                                                in={true}
                                                container={containerRef.current}
                                              >
                                                <div>
                                                  <fieldset className="form-group border p-2 mt-2">
                                                    <legend
                                                      className="w-auto px-2"
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.6)",
                                                        fontFamily:
                                                          '"Roboto","Helvetica","Arial",sans-serif',
                                                        fontWeight: 400,
                                                        fontSize: "0.82rem",
                                                        lineHeight: "1em",
                                                        letterSpacing:
                                                          "0.00938em",
                                                      }}
                                                    >
                                                      What will Self-Sponsored
                                                      cover?
                                                    </legend>
                                                    <div className="form-group1 mb-2">
                                                      <TextField
                                                        fullWidth
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        name="selfsponseredTrainingFundsProviderCover"
                                                        label="What will Self-Sponsored cover?"
                                                        variant="outlined"
                                                        value={
                                                          formData.selfsponseredTrainingFundsProviderCover
                                                        }
                                                        onChange={(e) => {
                                                          setFormData({
                                                            ...formData,
                                                            selfsponseredTrainingFundsProviderCover:
                                                              e.target.value,
                                                          });

                                                          setErrors({
                                                            ...errors,
                                                            selfsponseredTrainingFundsProviderCoverHasError: false,
                                                          });
                                                        }}
                                                      />
                                                      {errors.selfsponseredTrainingFundsProviderCoverHasError && (
                                                        <small className="text-danger ">
                                                          This field is required
                                                        </small>
                                                      )}
                                                    </div>
                                                  </fieldset>
                                                </div>
                                              </Slide>
                                            )}

                                          {formData.trainingFundProviders.find(
                                            (el) =>
                                              el === "Other" &&
                                              trainingFund === "Other"
                                          ) && (
                                            <Slide
                                              direction="right"
                                              in={true}
                                              container={containerRef.current}
                                            >
                                              <div>
                                                <fieldset className="form-group border p-2 mt-2">
                                                  <legend
                                                    className="w-auto px-2"
                                                    style={{
                                                      color:
                                                        "rgba(0, 0, 0, 0.6)",
                                                      fontFamily:
                                                        '"Roboto","Helvetica","Arial",sans-serif',
                                                      fontWeight: 400,
                                                      fontSize: "0.82rem",
                                                      lineHeight: "1em",
                                                      letterSpacing:
                                                        "0.00938em",
                                                    }}
                                                  >
                                                    If other, specify
                                                  </legend>
                                                  <div className="form-group1 mb-2">
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      rows={4}
                                                      name="otherTrainingFundsProvider"
                                                      label="specify other training funds provider"
                                                      variant="outlined"
                                                      value={
                                                        formData.otherTrainingFundsProvider
                                                      }
                                                      onChange={(e) => {
                                                        setFormData({
                                                          ...formData,
                                                          otherTrainingFundsProvider:
                                                            e.target.value,
                                                        });

                                                        setErrors({
                                                          ...errors,
                                                          otherTrainingFundsProviderHasError: false,
                                                        });
                                                      }}
                                                    />
                                                    {errors.otherTrainingFundsProviderHasError && (
                                                      <small className="text-danger ">
                                                        Please specify other
                                                        training funds provider{" "}
                                                      </small>
                                                    )}
                                                  </div>
                                                </fieldset>

                                                <fieldset className="form-group border p-2 mt-2">
                                                  <legend
                                                    className="w-auto px-2"
                                                    style={{
                                                      color:
                                                        "rgba(0, 0, 0, 0.6)",
                                                      fontFamily:
                                                        '"Roboto","Helvetica","Arial",sans-serif',
                                                      fontWeight: 400,
                                                      fontSize: "0.82rem",
                                                      lineHeight: "1em",
                                                      letterSpacing:
                                                        "0.00938em",
                                                    }}
                                                  >
                                                    What will{" "}
                                                    {formData.otherTrainingFundsProvider
                                                      ? formData.otherTrainingFundsProvider
                                                      : "other"}{" "}
                                                    cover?
                                                  </legend>
                                                  <div className="form-group1 mb-2">
                                                    <TextField
                                                      fullWidth
                                                      size="small"
                                                      multiline
                                                      rows={2}
                                                      name="otherTrainingFundsProviderCover"
                                                      label={`What will ${
                                                        formData.otherTrainingFundsProvider
                                                          ? formData.otherTrainingFundsProvider
                                                          : "other"
                                                      } cover?`}
                                                      variant="outlined"
                                                      value={
                                                        formData.otherTrainingFundsProviderCover
                                                      }
                                                      onChange={(e) => {
                                                        setFormData({
                                                          ...formData,
                                                          otherTrainingFundsProviderCover:
                                                            e.target.value,
                                                        });

                                                        setErrors({
                                                          ...errors,
                                                          otherTrainingFundsProviderCoverHasError: false,
                                                        });
                                                      }}
                                                    />
                                                    {errors.otherTrainingFundsProviderCoverHasError && (
                                                      <small className="text-danger ">
                                                        This field is required
                                                      </small>
                                                    )}
                                                  </div>
                                                </fieldset>
                                              </div>
                                            </Slide>
                                          )}
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.trainingFundsProviderHasError && (
                                    <small className="text-danger ">
                                      Funds Provider is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                      
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                          <legend
                            className="w-auto px-2"
                            style={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily:
                                '"Roboto","Helvetica","Arial",sans-serif',
                              fontWeight: 400,
                              fontSize: "0.82rem",
                              lineHeight: "1em",
                              letterSpacing: "0.00938em",
                            }}
                          >
                          Specify what the training provider(s) will cover in 1 or 2 paragraph?
                          </legend>
                          <div className="form-group1 mb-2">
                            <TextField
                              fullWidth
                              size="small"
                              rows={4}
                              name="coveredRelatedCostsDetails"
                              label="Specify what the training provider(s) will cover in 1 or 2 paragraph?"
                              variant="outlined"
                              multiline
                              value={formData.coveredRelatedCostsDetails}
                              onChange={(e) => {
                                const coveredRelatedCostsDetails =
                                  e.target.value;
                                if (
                                  countWords(coveredRelatedCostsDetails) <=
                                  coveredRelatedCostsDetailsMxWords
                                ) {
                                  setFormData({
                                    ...formData,
                                    coveredRelatedCostsDetails:
                                      coveredRelatedCostsDetails,
                                  });
                                }

                                let coveredRelatedCostsDetailsMaxWordsHasError = false;
                                if (
                                  countWords(coveredRelatedCostsDetails) >
                                  coveredRelatedCostsDetailsMxWords
                                ) {
                                  coveredRelatedCostsDetailsMaxWordsHasError = true;
                                }

                                setErrors({
                                  ...errors,
                                  coveredRelatedCostsDetailsHasError: false,
                                  coveredRelatedCostsDetailsMaxWordsHasError,
                                });
                              }}
                            />
                          </div>
                          {errors.coveredRelatedCostsDetailsHasError && (
                            <small className="text-danger ">
                              This field is required{" "}
                            </small>
                          )}

                          <small
                            className={
                              !errors.coveredRelatedCostsDetailsHasError &&
                              errors.coveredRelatedCostsDetailsMaxWordsHasError
                                ? "float-right text-danger"
                                : "float-right text-primary"
                            }
                          >
                            Words:{" "}
                            {countWords(formData.coveredRelatedCostsDetails)}/
                            {coveredRelatedCostsDetailsMxWords}
                          </small>
                        </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Expected training award
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="expectedTrainingAward-label"
                                      name="expectedTrainingAward"
                                      value={
                                        formData?.expectedTrainingAward || ""
                                      }
                                    >
                                      {expectedTrainingAwards.map(
                                        (expectedTrainingAward, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={expectedTrainingAward}
                                              name="expectedTrainingAward"
                                              label={expectedTrainingAward}
                                              control={<Radio size="small" />}
                                              checked={
                                                expectedTrainingAward ===
                                                formData?.expectedTrainingAward
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  expectedTrainingAward:
                                                    e.target.value,
                                                });

                                                setErrors({
                                                  ...errors,
                                                  expectedTrainingAwardsHasError: false,
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.expectedTrainingAwardsHasError && (
                                    <small className="text-danger ">
                                      Expected training award is required{" "}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Is the training captured in the capacity
                              development plan of the institution?
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="row">
                                <div className="col-12">
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      aria-labelledby="isTrainingHasCdPlan-label"
                                      name="isTrainingHasCdPlan"
                                      value={
                                        formData?.isTrainingHasCdPlan || ""
                                      }
                                    >
                                      {["Yes", "No"].map(
                                        (isTrainingHasCdPlan, index) => (
                                          <div key={index}>
                                            <FormControlLabel
                                              value={isTrainingHasCdPlan}
                                              name="isTrainingHasCdPlan"
                                              label={isTrainingHasCdPlan}
                                              control={<Radio size="small" />}
                                              checked={
                                                isTrainingHasCdPlan ===
                                                formData?.isTrainingHasCdPlan
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  cdPlanId:
                                                    e.target.value === "Yes" &&
                                                    cdPlan
                                                      ? cdPlan.id
                                                      : "",
                                                  isTrainingHasCdPlan:
                                                    e.target.value,
                                                });
                                                let doesTrainingRequiredCdPlan = false;
                                                if (
                                                  e.target.value === "Yes" &&
                                                  !cdPlan
                                                )
                                                  doesTrainingRequiredCdPlan = true;
                                                setErrors({
                                                  ...errors,
                                                  isTrainingHasCdPlanHasError: false,
                                                  doesTrainingRequiredCdPlan,
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                <div
                                  className="col-12"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.isTrainingHasCdPlanHasError && (
                                    <small className="text-danger ">
                                      Please choose Yes or No option{" "}
                                    </small>
                                  )}
                                  {addCdPlanForm && (
                                    <AddCDPlan
                                      addCdPlanForm={addCdPlanForm}
                                      setAddCdPlanForm={setAddCdPlanForm}
                                    />
                                  )}
                                  {errors.doesTrainingRequiredCdPlan && (
                                    <Alert severity="error">
                                      <AlertTitle>
                                        {" "}
                                        You must first add cd plan for fiscal
                                        year of {newFiscalYear}.
                                        <Button
                                          onClick={() => setAddCdPlanForm(true)}
                                          style={{
                                            position: "absolute",
                                            right: 24,
                                          }}
                                          className="float-right text-capitalized"
                                          variant="contained"
                                          size="small"
                                          startIcon={
                                            <span className="material-icons">
                                              add
                                            </span>
                                          }
                                        >
                                          <span className="text-capitalized">
                                            Add plan now!
                                          </span>
                                        </Button>
                                      </AlertTitle>
                                    </Alert>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          {formData?.isTrainingHasCdPlan &&
                            formData?.isTrainingHasCdPlan === "No" && (
                              <fieldset className="form-group border p-2">
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  If no, who initiated the training?
                                </legend>
                                <div className="form-group1 mb-2 overflow-hidden">
                                  <div className="row">
                                    <div className="col-12">
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="trainingInitiator-label"
                                          name="trainingInitiator"
                                          value={
                                            formData?.trainingInitiator || ""
                                          }
                                        >
                                          {trainingInitiators.map(
                                            (trainingInitiator, index) => (
                                              <div key={index}>
                                                <FormControlLabel
                                                  value={trainingInitiator}
                                                  name="trainingInitiator"
                                                  label={trainingInitiator}
                                                  control={
                                                    <Radio size="small" />
                                                  }
                                                  checked={
                                                    trainingInitiator ===
                                                    formData?.trainingInitiator
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setFormData({
                                                      ...formData,
                                                      trainingInitiator:
                                                        e.target.value,
                                                    });

                                                    setErrors({
                                                      ...errors,
                                                      trainingInitiatorHasError: false,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                    <div
                                      className="col-12"
                                      style={{ marginTop: "-15px" }}
                                    >
                                      {errors.trainingInitiatorHasError && (
                                        <small className="text-danger ">
                                          Training initiator is required{" "}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                  {formData?.trainingInitiator &&
                                    formData?.trainingInitiator === "Other" && (
                                      <Slide
                                        direction="right"
                                        in={true}
                                        container={containerRef.current}
                                      >
                                        <fieldset className="form-group border p-2 mt-2">
                                          <legend
                                            className="w-auto px-2"
                                            style={{
                                              color: "rgba(0, 0, 0, 0.6)",
                                              fontFamily:
                                                '"Roboto","Helvetica","Arial",sans-serif',
                                              fontWeight: 400,
                                              fontSize: "0.82rem",
                                              lineHeight: "1em",
                                              letterSpacing: "0.00938em",
                                            }}
                                          >
                                            If other, specify
                                          </legend>
                                          <div className="form-group1 mb-2">
                                            <TextField
                                              fullWidth
                                              size="small"
                                              rows={4}
                                              name="otherTrainingInitiator"
                                              label="specify, who initiated the
                                              training"
                                              variant="outlined"
                                              value={
                                                formData.otherTrainingInitiator
                                              }
                                              onChange={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  otherTrainingInitiator:
                                                    e.target.value,
                                                });

                                                setErrors({
                                                  ...errors,
                                                  otherTrainingInitiatorHasError: false,
                                                });
                                              }}
                                            />
                                            {errors.otherTrainingInitiatorHasError && (
                                              <small className="text-danger ">
                                                Please specify, who initiated
                                                the training{" "}
                                              </small>
                                            )}
                                          </div>
                                        </fieldset>
                                      </Slide>
                                    )}
                                </div>
                              </fieldset>
                            )}
                          
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              What gaps were identified to be addressed by the
                              training
                            </legend>
                            <div className="form-group1 mb-2">
                              <TextField
                                fullWidth
                                size="small"
                                rows={4}
                                name="specify"
                                label="Type here ..."
                                variant="outlined"
                                multiline
                                value={formData.trainingGapsIdentified}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    trainingGapsIdentified: e.target.value,
                                  });

                                  setErrors({
                                    ...errors,
                                    trainingGapsIdentifiedHasError: false,
                                  });
                                }}
                              />
                            </div>
                            {errors.trainingGapsIdentifiedHasError && (
                              <small className="text-danger ">
                                This field is required{" "}
                              </small>
                            )}
                          </fieldset>
                        </div>
                      </div>
                    </Slide>
                  </Typography>
                </>
              )}
              {tabValue === 1 && (
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <Slide
                    direction="down"
                    in={true}
                    container={containerRef.current}
                  >
                    <div className="card service-card valid mb-2 mt-1">
                      <div
                        className="card-header"
                        style={{ padding: "0.25rem 1.2rem" }}
                      >
                        {formData && formData?.requestType === "LONG_TERM"
                          ? "Long Term"
                          : "Short Term"}{" "}
                        Training Attachments
                      </div>
                      <div className="card-body">
                      {tabValue === 1 &&
            !!trainingRequest?.trainingRequestAttachments.length && (
              <Box sx={{ width: "100%", minHeight: "55vh" }} className="mt-1">
                <React.Fragment>
                  <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                    <ViewAttachmentTrainingRequestDialog
                    canEdit={true}
                      attachments={trainingRequest.trainingRequestAttachments}
                    />
                  </Typography>
                </React.Fragment>
              </Box>
            )}
                      </div>
                    </div>
                  </Slide>
                </Typography>
              )}
                {tabValue === 0 && requestDetailTabValue===1 && (
                  <>
                  {formData.employmentCategory === "Contractual staff" ? (
                    <>
                       <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                        <Slide
                          direction="down"
                          in={true}
                          container={containerRef.current}
                        >
                          <div className="card service-card valid mb-2 mt-1">
                            <div
                              className="card-header"
                              style={{ padding: "0.25rem 1.2rem" }}
                            >
                              Training Authorization letter trough line ministry
                            </div>
                            <div className="card-body">
                      <AuthorizationPermit
                        headOfInstitution={{
                          firstName: headOfInstitution.title
                            ? headOfInstitution.title + "."
                            : "" + headOfInstitution?.firstName,
                          lastName: headOfInstitution?.lastName || "",
                          title: headOfInstitution?.title || "",
                          institutionName:
                            headOfInstitution?.institutionName || "",
                          positionName: headOfInstitution?.positionName || "",
                        }}
                        permitReferenceNo={formData.permitReferenceNo}
                        draftAuthorizationPermitContentInput={
                            <>
                              <TextEditorComponent
                                id="editor-textarea5"
                                options={{
                                  autoSave: false,
                                  maxCharCount:
                                    draftAuthorizationContentByMinistryMxWords,
                                  defaultValue:
                                    formData.draftAuthorizationPermitContent,
                                }}
                                onEditorChange={getEditorContent2}
                              />

                              <ErrorMessage
                                hasError={
                                  errors.draftAuthorizationPermitContentHasError
                                }
                                message="This field is required"
                              />
                            </>
                        }
                        permitReferenceNoInput={
                            <>
                              <Input
                                autoFocus
                                value={formData.permitReferenceNo}
                                inputProps={{
                                  "aria-label": "permitReferenceNo",
                                }}
                                onChange={(e) => {
                                  const permitReferenceNo = e.target.value;
                                  setFormData({
                                    ...formData,
                                    permitReferenceNo,
                                  });
                                }}
                              />
                           
                            </>
                         
                        }
                        request={null}
                        draftAuthorizationPermitContent={
                          formData.draftAuthorizationPermitContent
                        }
                        signContent={
                          <table>
                            <tr>
                              <td
                                style={{
                                  width: "20%",
                                  fontSize: "12px",
                                  textAlign: "left",
                                }}
                              >
                                <span className="d-block text-danger">
                                  Not signed
                                </span>
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                  fontSize: "12px",
                                  textAlign: "left",
                                }}
                              ></td>
                            </tr>
                          </table>
                        }
                      />
                    
                       </div>
                            </div>
                            </Slide>
                            </Typography>
                    </>
                  ) : (
                      <>
                        <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                          <Slide
                            direction="down"
                            in={true}
                            container={containerRef.current}
                          >
                            <div className="card service-card valid mb-2 mt-1">
                              <div
                                className="card-header"
                                style={{ padding: "0.25rem 1.2rem" }}
                              >
                                Training Authorization letter trough line ministry
                              </div>
                              <div className="card-body">
                                <div className="">
                                  <div className="letterMainContent">
                                    <div className="letter">
                                      <div className="letterhead d-flex flex-column justify-content-center w-100">
                                        <div
                                          className="letterhead-title w-100"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          REPUBLIC OF RWANDA
                                        </div>
                                        <div className="w-100">
                                          <img
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                            }}
                                            src={rwandaLogo}
                                            alt="Logo"
                                          />
                                        </div>
                                        <div
                                          className="letterhead-subtitle w-100"
                                          style={{
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          {headOfInstitution.institutionName.toUpperCase()}
                                        </div>
                                      </div>
                                      <div className="subletterhead">
                                        {/* <!-- Date and reference number go here --> */}
                                        <p
                                          className="date"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Kigali, on {moment(new Date()).format("LL")}
                                        </p>
                                        <p
                                          className="reference"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          No:{" "}
                                          <Input
                                          className="float-right"
                                            autoFocus
                                            value={formData.minstryLetterReferenceNo}
                                            inputProps={{
                                              "aria-label":
                                                "minstryLetterReferenceNo",
                                            }}
                                            onChange={(e) => {
                                              const minstryLetterReferenceNo =
                                                e.target.value;
                                              setFormData({
                                                ...formData,
                                                minstryLetterReferenceNo,
                                              });
                                            }}
                                          />
                                        </p>
                                      </div>
                                      <div className="recipient">
                                        {/* <!-- Recipient's name and address go here --> */}
      
                                        <p
                                          style={{
                                            fontSize: "13.6px",
                                            lineHeight: "1.5",
                                          }}
                                        >
                                          Honorable Minister of Public Service and
                                          Labour
                                          <br />
                                          <span
                                            style={{
                                              position: "relative",
                                              top: "6px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            KIGALI
                                          </span>
                                        </p>
                                      </div>
                                      <div className="content2">
                                        {/* <!-- Letter content goes here --> */}
                                        <p>Honorable Minister,</p>
                                        <p style={{ fontWeight: "bold" }}>
                                          Re:
                                          <Input
                                            className="w-100"
                                            autoFocus
                                            error={!!errors.draftAuthorizationSubjectByMinistryHasError}
                                            value={
                                              formData.draftAuthorizationSubjectByMinistry
                                            }
                                            inputProps={{
                                              "aria-label":
                                                "draftAuthorizationSubjectByMinistry",
                                            }}
                                            onChange={(e) => {
                                              const draftAuthorizationSubjectByMinistry =
                                                e.target.value;
                                              setFormData({
                                                ...formData,
                                                draftAuthorizationSubjectByMinistry,
                                              });
                                              setErrors({...errors,draftAuthorizationSubjectByMinistryHasError:false})
                                            }}
                                          />
                                        </p>
                                        <p>
                                          <TextEditorComponent
                                            id="editor-textarea1"
                                            options={{
                                              autoSave: false,
                                              // hasChanged:hasTextEditorChanged,
                                              // api: `/api/training/update-ministry-letter-contents/${trainingRequest.request.referenceNo}`,
                                              maxCharCount: draftAuthorizationContentByMinistryMxWords,
                                              defaultValue:
                                                formData.draftAuthorizationContentByMinistry,
                                            }}
                                            onEditorChange={getEditorContent}
                                          />
      
                                          <ErrorMessage
                                            hasError={
                                              errors.draftAuthorizationContentByMinistryHasError
                                            }
                                            message="This field is required"
                                          />
                                        </p>
                                      </div>
                                      <div className="signature2">
                                        <p
                                          style={{
                                            position: "relative",
                                            paddingBottom: "15px",
                                            fontWeight: 800,
                                            color: "red",
                                          }}
                                        >
                                          <table>
                                            <tr>
                                              <td
                                                style={{
                                                  width: "20%",
                                                  fontSize: "12px",
                                                  textAlign: "left",
                                                  paddingLeft: "76px",
                                                }}
                                              >
                                                <span className="d-block text-danger">
                                                  Not signed
                                                </span>
                                              </td>
                                              <td
                                                style={{
                                                  width: "20%",
                                                  fontSize: "12px",
                                                  textAlign: "left",
                                                }}
                                              ></td>
                                            </tr>
                                          </table>
                                        </p>
                                        <p
                                          className="mt-4"
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: 800,
                                          }}
                                        >
                                          {headOfInstitution.title
                                            ? headOfInstitution.title + "."
                                            : ""}{" "}
                                          {headOfInstitution?.firstName || ""}{" "}
                                          {headOfInstitution?.lastName || ""}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 800,
                                          }}
                                        >
                                          {headOfInstitution.institutionName
                                            .toUpperCase()
                                            .replace("MINISTRY", "Minister")
                                            .toUpperCase()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slide>
                        </Typography>
                      </>
                  )}
                 </>
               )}
            </React.Fragment>
          </Box>
        </DialogContent>
        <DialogActions className="py-2 mx-4 d-flex justify-content-center">
          {tabValue === 0 && (
           <>
           {requestDetailTabValue==0 && <>
            <Button
              disabled={loading}
              onClick={handleSave}
              style={{
                ...ActiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
                background: "#14a44d",
                borderColor: "#14a44d",
              }}
              size="md"
            >
              {loading ? "Wait.." : <><span className="material-icons mr-1 ">done_outline</span>Submit All Changes</>}
            </Button>
            <Button
              disabled={loading}
              onClick={()=>{
                setFormData({
                  ...formData,
                  draftAuthorizationContentByMinistry:formData.draftAuthorizationContentByMinistry || customizeLetterBody(),
                  draftAuthorizationPermitContent:formData.draftAuthorizationPermitContent || customizeLetterBody2(),
                });
                setRequestDetailTabValue(1)
              }}
              style={{
                ...ActiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
                background: "rgb(7, 142, 206)",
                borderColor: "rgb(7, 142, 206)",
              }}
              size="md"
            >
              Modify Authorization Letter <span className="material-icons ml-1 ">trending_flat</span>
            </Button>
            
           </>}

           {requestDetailTabValue==1 && <>
            <Button
              disabled={loading}
              onClick={handleSave}
              style={{
                ...ActiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
                background: "#14a44d",
                borderColor: "#14a44d",
              }}
              size="md"
            >

            {loading ? "Wait.." : <><span className="material-icons mr-1 ">done_outline</span>Submit All Changes</>}

            </Button>
            <Button
              disabled={loading}
              onClick={()=>setRequestDetailTabValue(0)}
              style={{
                ...ActiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
                background: "rgb(7, 142, 206)",
                borderColor: "rgb(7, 142, 206)",
              }}
              size="md"
            >
              Modify Request Details <span className="material-icons ml-1 ">trending_flat</span>
            </Button>
            
           </>}
           
           </>
          )}
              {canSubmitRequest && formData.employmentCategory === "Contractual staff" &&  (
              <DraftAuthorizationPermit
              showAuthorizationPermit={canSubmitRequest}
              setShowAuthorizationPermit={setCanSubmitRequest}
              headOfInstitution={{
                firstName: headOfInstitution.title
                  ? headOfInstitution.title + "."
                  : "" + headOfInstitution?.firstName,
                lastName: headOfInstitution?.lastName || "",
                title: headOfInstitution?.title || "",
                institutionName:
                  headOfInstitution?.institutionName || "",
                positionName: headOfInstitution?.positionName || "",
              }}
              permitReferenceNo={formData.permitReferenceNo}
            
              draftAuthorizationPermitContent={
                formData.draftAuthorizationPermitContent
              }
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <span className="d-block text-danger">
                        Not signed
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
              dialogActions={
                <>
                  <Button
                    disabled={loading}
                    onClick={() => onSave()}
                    style={{
                      ...ActiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                      background: "#14a44d" ,
                        borderColor:"#14a44d",
                    }}
                    size="md"
                  >
                    {loading ? "Submitting..." : "Confirm now"}
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => setCanSubmitRequest(false)}
                    style={{
                      ...ActiveButton,
                      float: "right",
                      background: "#ced4da",
                      borderColor: "#ced4da",
                      color: "#495057",
                    }}
                    size="md"
                  >
                    Cancel
                  </Button>
                </>
              }
            />
            )}
             {canSubmitRequest && formData.employmentCategory !== "Contractual staff" && (
               <DraftAuthorizationByMinistry
               headOfInstitution={headOfInstitution}
                       
               minstryLetterReferenceNo={formData.minstryLetterReferenceNo}
               draftAuthorizationSubjectByMinistry={formData.draftAuthorizationSubjectByMinistry}
               draftAuthorizationContentByMinistry={formData.draftAuthorizationContentByMinistry}

               showAuthorizationPermit={canSubmitRequest}
               setShowAuthorizationPermit={setCanSubmitRequest}
               dialogActions={
                 <>
                   <Button
                     disabled={loading}
                     onClick={() => onSave()}
                     style={{
                       ...ActiveButton,
                       // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                       float: "right",
                       background: "#14a44d" ,
                       borderColor:"#14a44d",
                     }}
                     size="md"
                   >
                     {loading ? "Submitting..." : "Confirm now"}
                   </Button>
                   <Button
                     disabled={loading}
                     onClick={() => setCanSubmitRequest(false)}
                     style={{
                       ...ActiveButton,
                       float: "right",
                       background: "#ced4da",
                       borderColor: "#ced4da",
                       color: "#495057",
                     }}
                     size="md"
                   >
                     Cancel
                   </Button>
                 </>
               }
             />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  countries,
  cdPlans,
  fiscalYears,
  trainingRequest,
  selectedFiscalYear,headOfInstitution
}) => {
  return {
    user,
    loading,
    countries,
    cdPlans,
    fiscalYears,
    trainingRequest,
    selectedFiscalYear,headOfInstitution
  };
};
export default connect(mapStateToProps, {
  updateTrainingRequest,getHeadOfInstitution
})(UpdateTrainingRequestDialog);

