import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/structure/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/structure/dashboard")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link> */}

        {/* <HorizontalRuleIcon className="rotated"
 /> */}

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/employees/units"
          className={` px-1 rounded   ${
            location.pathname.includes("/employees/units") ? " active  " : " "
          }`}
        >
          <span className="text-truncate menu-item ">Employees</span>
        </Link>

        {hasPermissions(["IS_HR", "IS_CBM"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/employees/contracts"
              className={`px-1 rounded ${
                location.pathname.includes("/employees/contracts")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Contracts</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Breadcrumb;
