const types = {
  SET_OUTPUTS: "SET_OUTPUTS",
  SET_SELECTED_OUTPUT: "SET_SELECTED_OUTPUT",
  SET_INDICATORS: "SET_INDICATORS",
  SET_UNITY_INDICATORS: "SET_UNITY_INDICATORS",
  SET_SELECTED_INDICATOR: "SET_SELECTED_INDICATOR",
  SET_UNIT_ACTIVITIES: "SET_UNIT_ACTIVITIES",
  SET_SELECTED_ACTIVITY: "SET_SELECTED_ACTIVITY",
  SET_MY_ACTIVITIES: "SET_MY_ACTIVITIES",
  SET_SUBORDINATES: "SET_SUBORDINATES",
  SET_SELECTED_USER_ENTITY: "SET_SELECTED_USER_ENTITY",
  SET_USER_ENTITIES: "SET_USER_ENTITIES",
  SET_SELECTED_FISCAL_YEAR: "SET_SELECTED_FISCAL_YEAR",
  SET_FISCAL_YEARS: "SET_FISCAL_YEARS",
  ADD_NEW_OUTPUT: "ADD_NEW_OUTPUT",
  UPDATE_OUTPUT: "UPDATE_OUTPUT",
  DELETE_OUTPUT: "DELETE_OUTPUT",
  ADD_NEW_INDICATOR: "ADD_NEW_INDICATOR",
  UPDATE_INDICATOR: "UPDATE_INDICATOR",
  UPDATE_INDICATOR_TARGET_SHARE: "UPDATE_INDICATOR_TARGET_SHARE",
  SET_INDICATOR_ACTIVITIES: "SET_INDICATOR_ACTIVITIES",
  DELETE_INDICATOR: "DELETE_INDICATOR",
  SET_UNITS: "SET_UNITS",
  UPDATE_ACTIVITY: "UPDATE_ACTIVITY",
  ADD_NEW_ACTIVITY: "ADD_NEW_ACTIVITY",
  DELETE_ACTIVITY: "DELETE_ACTIVITY",
  SET_OVERSIGHT_EVALUATIONS: "SET_OVERSIGHT_EVALUATIONS",
  SET_UNITS_EVALUATIONS: "SET_UNITS_EVALUATIONS",
  SET_EMPLOYEES_EVALUATIONS: "SET_EMPLOYEES_EVALUATIONS",
  ADD_ACTIVITY_ASSIGNMENT: "ADD_ACTIVITY_ASSIGNMENT",
  REMOVE_ACTIVITY_ASSIGNMENT: "REMOVE_ACTIVITY_ASSIGNMENT",
  ADD_ACTIVITY_SUBMISSION: "ADD_ACTIVITY_SUBMISSION",
  REMOVE_ACTIVITY_SUBMISSION: "REMOVE_ACTIVITY_SUBMISSION",
  ADD_ACTIVITY_EVALUATION: "ADD_ACTIVITY_EVALUATION",
  REMOVE_ACTIVITY_EVALUATION: "REMOVE_ACTIVITY_EVALUATION",
  SET_INDICATOR_ASSIGNMENTS: "SET_INDICATOR_ASSIGNMENTS",
  REMOVE_INDICATOR_ASSIGNMENT: "REMOVE_INDICATOR_ASSIGNMENT",

  SET_MY_SUBORDINATES: "SET_MY_SUBORDINATES",
  UPDATE_SUBORDINATE: "UPDATE_SUBORDINATE",

  SET_CONTRACT_ITEMS: "SET_CONTRACT_ITEMS",
  SET_ITEMS_EVALUATION: "SET_ITEMS_EVALUATION",
  REMOVE_ITEM_EVALUATION: "REMOVE_ITEM_EVALUATION",
  UPDATE_CONTRACT_ITEM: "UPDATE_CONTRACT_ITEM",
  SET_SUPERVISORS: "SET_SUPERVISORS",
  SET_TECHNICAL_HEADS: "SET_TECHNICAL_HEADS",

  SET_RBM_DASHBOARD: "SET_RBM_DASHBOARD",

  UPDATE_SUBORDINATE_CONTRACT: "UPDATE_SUBORDINATE_CONTRACT",
  SET_SUBORDINATE_CONTRACTS: "SET_SUBORDINATE_CONTRACTS",
  SET_MY_CONTRACT_ACTIVITIES: "SET_MY_CONTRACT_ACTIVITIES",
  SET_MY_CONTRACTS: "SET_MY_CONTRACTS",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",

  ADD_ITEM_SUBMISSION: "ADD_ITEM_SUBMISSION",
  REMOVE_ITEM_SUBMISSION: "REMOVE_ITEM_SUBMISSION",
  REMOVE_ACTION_PLAN: "REMOVE_ACTION_PLAN",

  SET_SUBMISSIONS: "SET_SUBMISSIONS",

  SET_COMPETENCIES: "SET_COMPETENCIES",
  SET_CONTRACT_COMPETENCY_EVALUATIONS: "SET_CONTRACT_COMPETENCY_EVALUATIONS",
  ADD_CONTRACT_COMPETENCY_EVALUATION: "ADD_CONTRACT_COMPETENCY_EVALUATION",
  SET_SUBORDINATE_CONTRACT_COMPETENCY_SCORES:
    "SET_SUBORDINATE_CONTRACT_COMPETENCY_SCORES",

  REMOVE_SUBORDINATE_CONTRACT_PENDING_ASSIGNMENTS:
    "REMOVE_SUBORDINATE_CONTRACT_PENDING_ASSIGNMENTS",

  DELETE_SUBMISSION: "DELETE_SUBMISSION",
  REMOVE_CONTRACT_ASSIGNMENT_ITEM: "REMOVE_CONTRACT_ASSIGNMENT_ITEM",
  SET_SUBORDINATE_PENDING_REPORTS: "SET_SUBORDINATE_PENDING_REPORTS",
  SET_SUBORDINATE_CONTRACT_COMPETENCY_RE_EVALUATION:
    "SET_SUBORDINATE_CONTRACT_COMPETENCY_RE_EVALUATION",
  SET_CONTRACT_CANCEL: "SET_CONTRACT_CANCEL",
};

export default types;
