import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = (props) => {
  const { user } = props;
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/temp-payrolls"
              className={` px-1 rounded   ${
                location.pathname.includes("/payroll/temp-payrolls")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">In progress</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/archived-payrolls"
              className={`  px-1 rounded  ${
                location.pathname.includes("/payroll/archived-payrolls")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Archives</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/performance-bonus"
              className={` px-1 rounded   ${
                location.pathname.includes("/payroll/performance-bonus")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Performance bonus</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/dashboard"
              className={` px-1 rounded   ${
                location.pathname.includes("/payroll/dashboard")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Dashboard</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {(hasPermissions(["IS_ADMIN", "IS_SUPPORT"]) ||
          ["2207265671", "2207112638", "2207048454"].includes(user?.id)) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/currency-conversion"
              className={`px-1 rounded ${
                location.pathname.includes("/payroll/currency-conversion")
                  ? "active "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Currencies</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_PAYROLL_OVERSIGHT"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/oversight"
              className={`  px-1 rounded  ${
                location.pathname.includes("/payroll/oversight")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Oversight</span>
            </Link>
          </>
        )}

        {hasPermissions(["CAN_ACCESS_BAROMETER_DATA"]) && (
          <>
            <HorizontalRuleIcon className="rotated" />

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/payroll/barometer-data"
              className={` px-1 rounded   ${
                location.pathname.includes("/payroll/barometer-data")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Barometer data</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {})(Breadcrumb);
