import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  Button,
  Slide,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { getFiscalYears } from "../../../store/rbm/actions";
import { getCdPlans, deleteCdPlan } from "../../../store/training/actions";
import AddCDPlan from "../components/AddCDPlan";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { result } from "lodash";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PreLoader from "../../common/components/PreLoader";
import PdfViewer from "../../common/components/PdfViewer";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const CdPlans = (props) => {
  const {
    user,
    loading,
    cdPlans,
    fiscalYears,
    getFiscalYears,
    getCdPlans,
    deleteCdPlan,
    selectedFiscalYear
  } = props;

  const [addCdPlanForm, setAddCdPlanForm] = useState(false);
  const { hasPermissions } = useRequiredAuth();
  const [confirmRemoveCdPlan, setConfirmRemoveCdPlan] = useState(false);
  const theme = useTheme();
  const [cdPlan, setCdPlan] = useState(null);
  const [currentFiscalYear, setCurrentFiscalYear] = useState(null);
  useEffect(() => {
    if (!fiscalYears.length) getFiscalYears();
    if (!cdPlans.length) getCdPlans(user?.selectedEntitySector?.entityId);
  }, [user]);

  useEffect(() => {

    const currentFiscalYear = !!fiscalYears.length ?fiscalYears.find(({active})=>!!active):null;
    setCurrentFiscalYear(currentFiscalYear);
  }, [fiscalYears,selectedFiscalYear]);

  useEffect(() => {
    const cdPlan = !!cdPlans.length
      ? cdPlans.find(
          ({ fiscalYearId }) => fiscalYearId === currentFiscalYear?.id || ""
        )
      : null;
    setCdPlan(cdPlan);
  }, [cdPlans, currentFiscalYear]);
  
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-4">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>TRAINING</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    <span style={{ color: "rgb(7, 142, 206)" }}>Cd plans</span>
                  </span>
                </Typography>
              </div>

              <div className="col-lg-8">
                <div className="d-flex justify-content-end">
                  <Autocomplete
                    size="small"
                    id="currentFiscalYear"
                    defaultValue={null}
                    value={currentFiscalYear || selectedFiscalYear || null}
                    options={fiscalYears}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, currentFiscalYear) => {
                      setCurrentFiscalYear(currentFiscalYear || null);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, currentFiscalYear) => (
                      <Box component="li" {...props}>
                        {currentFiscalYear.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "180px", maxWidth: "180px" }}
                        placeholder="Select fiscal year"
                        {...params}
                        label="Select fiscal year"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {hasPermissions(["IS_HR","IS_TRAINING_INITIATOR"]) && (
                    <Button
                      className="ml-3"
                      onClick={() => {
                        setAddCdPlanForm(true);
                      }}
                      style={{
                        ...ActiveButton,
                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                        float: "right",
                      }}
                      size="md"
                    >
                      <span className="material-icons">add</span>Add plan
                    </Button>
                  )}
                </div>
              </div>
            </Toolbar>
          </AppBar>
          {addCdPlanForm && (
            <AddCDPlan
              addCdPlanForm={addCdPlanForm}
              setAddCdPlanForm={setAddCdPlanForm}
            />
          )}

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <PreLoader isLoading={loading} />
              {!loading && cdPlan && (
                <div
                  className="card service-card mb-3 mt-0"
                  style={{ borderColor: "#e0edf9!important" }}
                >
                  <div
                    className="card-header"
                    style={{ padding: "0.25rem 1.2rem", width: "100%" }}
                  >
                    <div className="row">
                      <div className="col-lg-8">
                        <AttachFileIcon />
                        Cd plan for fiscal year of {cdPlan.fiscalYear.name}
                      </div>
                      {hasPermissions(["IS_HR","IS_TRAINING_INITIATOR"]) && (
                        <div className="col-lg-4 float-right">
                          <div className="float-right">
                            <button
                              onClick={() => setConfirmRemoveCdPlan(true)}
                              className="btn btn-danger btn-sm ml-2"
                            >
                              <span className="fas fa-trash"></span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <PdfViewer url={process.env.REACT_APP_FILES_URL +'/preview/'+cdPlan.documentReferenceId} />
                  </div>
                </div>
              )}

              {cdPlan && confirmRemoveCdPlan && (
                <ConfirmationDialog
                  confirmationDialog={confirmRemoveCdPlan}
                  message={`Are you sure you want to delete CD Plan for fiscal year of ${cdPlan.fiscalYear.name}`}
                  setConfirmationDialog={setConfirmRemoveCdPlan}
                  onYes={() => deleteCdPlan(cdPlan, setConfirmRemoveCdPlan)}
                />
              )}

              <NoRecordMessage
                isEmpty={!loading && !cdPlan}
                title={`No cd plan ${
                  currentFiscalYear
                    ? "for fiscal year of " + currentFiscalYear.name
                    : ""
                }  found`}
              />
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, fiscalYears, cdPlans,selectedFiscalYear }) => {
  return { user, loading, fiscalYears, cdPlans,selectedFiscalYear };
};
export default connect(mapStateToProps, {
  getFiscalYears,
  getCdPlans,
  deleteCdPlan,
})(CdPlans);
