import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Autocomplete,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../../toastify";
import { useEffect } from "react";
import axios from "axios";
import EmployeeContract from "./EmployeeContract";

const EmployeeContractDialog = (props) => {
  const {
    loading,
    showContractDialog,
    setShowContractDialog,
    position,
    selectedContract,
  } = props;

  const onClose = () => {
    setShowContractDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showContractDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <span className="ml-0 text-dark"> Employee Contract | </span>{" "}
            <span className="ml-0">{selectedContract.firstName}</span>{" "}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3 py-4"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "67vh",
            }}
          >
            <EmployeeContract employeeId={selectedContract.employeeId} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {})(EmployeeContractDialog);
