import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getInitiators, AddExitType } from "../../../store/exit/actions";

const AddExitTypeDialog = (props) => {
  const {
    loading,
    addExitTypeForm,
    setAddExitTypeForm,
    getInitiators,
    initiators,
    AddExitType,
  } = props;
  const [exitTypeName, setExitTypeName] = useState("");
  const [blacklistPeriod, setblacklistPeriod] = useState("");
  const [initiator, setInitiator] = useState(null);
  const [payTerminalBenefit, setpayTerminalBenefit] = useState(true);
  const [effectiveInDays, seteffectiveInDays] = useState(false);
  const [canBeRehired, setcanBeRehired] = useState(false);

  useEffect(() => {
    getInitiators();
  }, []);

  const onClose = () => {
    setAddExitTypeForm(false);
  };

  const handleSubmit = () => {
    //   if(){}
    const data = {
      name: exitTypeName,
      initiatorId: initiator.id,
      blacklistPeriod: blacklistPeriod,
      payTerminalBenefit: payTerminalBenefit,
      effectiveInDays: effectiveInDays,
      canBeRehired: canBeRehired,
    };

    AddExitType(data, (status) => {
      if (status) {
        // clean form
      }
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addExitTypeForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Fill To Add New Exit Type
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: "100%" }}>
            <div
              className="elevated rounded p-5 mt-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="col-sm-12 col-xs-5 col-md-5 col-lg-12 justify-content-center">
                <TextField
                  fullWidth
                  size="small"
                  //   rows={4}
                  name="exitTypeName"
                  disabled={false}
                  autoFocus
                  label="Exit Type Name"
                  variant="outlined"
                  className="mb-3"
                  value={exitTypeName}
                  onChange={(e) => setExitTypeName(e.target.value)}
                />

                <TextField
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 7,
                      min: 0,
                    },
                  }}
                  label="Blacklist Period"
                  variant="outlined"
                  className="mb-3"
                  //   rows={4}
                  value={blacklistPeriod}
                  onChange={(e) => setblacklistPeriod(e.target.value)}
                />

                <div className="col-12">
                  <Autocomplete
                    size="small"
                    id="ExitType"
                    className="mb-3"
                    defaultValue={null}
                    value={initiator}
                    options={initiators}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, initiotor) => {
                      setInitiator(initiotor || null);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, initiotor) => (
                      <Box component="li" {...props}>
                        {initiotor.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Initiotor"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "Initiotor",
                        }}
                      />
                    )}
                  />
                </div>

                <FormLabel id="demo-row-radio-buttons-group-label">
                  Pays Terminal Benefits
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setcanBeRehired(e.target.value === "yes" ? true : false)
                  }
                  value={canBeRehired ? "yes" : "no"}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>

                <TextField
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 31,
                      min: 0,
                    },
                  }}
                  label="Effective Date In Days"
                  variant="outlined"
                  className="mb-3"
                  //   rows={4}
                  value={effectiveInDays}
                  onChange={(e) => seteffectiveInDays(e.target.value)}
                />

                <FormLabel id="demo-row-radio-buttons-group-label">
                  Employee Can Be Hired
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) =>
                    setpayTerminalBenefit(
                      e.target.value === "yes" ? true : false
                    )
                  }
                  value={payTerminalBenefit ? "yes" : "no"}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, initiators }) => {
  return {
    loading,
    initiators,
  };
};
export default connect(mapStateToProps, { getInitiators, AddExitType })(
  AddExitTypeDialog
);
