import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getRoles,
  deleteRole,
  deleteRolePermission,
  deleteRoleModule,
} from "../../../store/admin/actions";
import { IconButton, Button, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddOrUpdateRoleDialog from "./AddOrUpdateRoleDialog";
import AssignPermissionDialog from "./AssignPermissionDialog";
import AssignModuleDialog from "./AssignModuleDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const Role = (props) => {
  const {
    user,
    loading,
    roles,
    getRoles,
    deleteRole,
    deleteRolePermission,
    deleteRoleModule,
  } = props;
  const [showRoleForm, setShowRoleForm] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [confirmRemoveRole, setConfirmRemoveRole] = useState(false);

  const [showAssignPermissionForm, setShowAssignPermissionForm] =
    useState(false);

  const [showAssignModuleForm, setShowAssignModuleForm] = useState(false);

  const [confirmRemovePermission, setConfirmRemovePermission] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState("");

  const [confirmRemoveModule, setConfirmRemoveModule] = useState(false);
  const [selectedModule, setSelectedModule] = useState("");
  const theme = useTheme();

  useEffect(
    () => {
      getRoles();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <div className="col-lg-12 mb-2">
        <Button
          onClick={() => {
            setSelectedRole(null);
            setShowRoleForm(true);
          }}
          style={{
            ...ActiveButton,
            padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
            marginBottom: "10px",
            marginRight: "-12px",
          }}
          size="sm"
        >
          <span className="material-icons">add</span> New
        </Button>

        {showRoleForm && (
          <AddOrUpdateRoleDialog
            selectedRole={selectedRole}
            setIsEditing={setIsEditing}
            showRoleForm={showRoleForm}
            setShowRoleForm={setShowRoleForm}
            isEditing={isEditing}
          />
        )}

        {showAssignPermissionForm && (
          <AssignPermissionDialog
            selectedRole={selectedRole}
            showAssignPermissionForm={showAssignPermissionForm}
            setShowAssignPermissionForm={setShowAssignPermissionForm}
            setShowRoleForm={setShowRoleForm}
          />
        )}

        {showAssignModuleForm && (
          <AssignModuleDialog
            selectedRole={selectedRole}
            showAssignModuleForm={showAssignModuleForm}
            setShowAssignModuleForm={setShowAssignModuleForm}
            setShowRoleForm={setShowRoleForm}
          />
        )}

        {confirmRemoveRole && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveRole}
            message={`Are you sure you want to remove this role @${selectedRole.name}?`}
            setConfirmationDialog={setConfirmRemoveRole}
            onYes={() => {
              deleteRole(selectedRole, setConfirmRemoveRole, setSelectedRole);
            }}
          />
        )}

        {confirmRemovePermission && (
          <ConfirmationDialog
            confirmationDialog={confirmRemovePermission}
            message={`Are you sure you want to remove this @${selectedPermission.permission} permission to @${selectedRole.name}?`}
            setConfirmationDialog={setConfirmRemovePermission}
            onYes={() => {
              deleteRolePermission(
                selectedPermission,
                selectedRole,
                setConfirmRemovePermission,
                setSelectedPermission
              );
            }}
          />
        )}

        {confirmRemoveModule && (
          <ConfirmationDialog
            confirmationDialog={confirmRemoveModule}
            message={`Are you sure you want to remove this @${selectedModule.shortName} module to @${selectedRole.name}?`}
            setConfirmationDialog={setConfirmRemoveModule}
            onYes={() => {
              deleteRoleModule(
                selectedModule,
                selectedRole,
                setConfirmRemoveModule,
                setSelectedModule
              );
            }}
          />
        )}
      </div>
      <table className="table table-bordered mt-2">
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Name</th>
            <th style={{ textAlign: "left" }}>Permissons</th>
            <th style={{ textAlign: "left" }}>Modules</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role, index) => (
            <React.Fragment key={index}>
              {role.id !== "ADMIN" && (
                <tr>
                  <td style={{ textAlign: "left" }}>{role.name}</td>
                  <td style={{ textAlign: "left" }}>
                    {role.permissions &&
                      !!role.permissions.length &&
                      role.permissions.map((permission, index2) => (
                        <React.Fragment key={index2}>
                          <Chip
                            color="primary"
                            label={permission.name}
                            onDelete={() => {
                              setSelectedRole(role);
                              setSelectedPermission({
                                roleId: role.id,
                                permission: permission.name,
                              });
                              setConfirmRemovePermission(true);
                            }}
                            variant="outlined"
                            style={{ margin: "5px" }}
                            size="small"
                          />
                        </React.Fragment>
                      ))}
                  </td>

                  <td style={{ textAlign: "left" }}>
                    {role.modules &&
                      !!role.modules.length &&
                      role.modules.map((module, index3) => (
                        <React.Fragment key={index3}>
                          <Chip
                            label={module.shortName}
                            onDelete={() => {
                              setSelectedRole(role);
                              setSelectedModule({
                                roleId: role.id,
                                moduleId: module.id,
                                shortName: module.shortName,
                              });
                              setConfirmRemoveModule(true);
                            }}
                            size="small"
                            variant="outlined"
                            style={{ margin: "5px" }}
                          />
                        </React.Fragment>
                      ))}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <React.Fragment>
                      <div className="row">
                        <div className="col-lg-6">
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={(e) => {
                              setSelectedRole(role);
                              setIsEditing(true);
                              setShowRoleForm(true);
                            }}
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>{" "}
                        </div>
                        <div className="col-lg-6">
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setSelectedRole(role);
                              setConfirmRemoveRole(true);
                            }}
                            className=" mr-2"
                          >
                            <span className="material-icons">delete</span>
                          </IconButton>
                        </div>

                        <div className="col-lg-12 mt-2">
                          <Button
                            onClick={(e) => {
                              setSelectedRole(role);
                              setShowAssignModuleForm(true);
                            }}
                            style={{
                              ...ActiveButton,
                              padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                              float: "right",
                            }}
                            size="sm"
                          >
                            <span className="material-icons">add</span> Assign
                            Modules
                          </Button>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <Button
                            onClick={(e) => {
                              setSelectedRole(role);
                              setShowAssignPermissionForm(true);
                            }}
                            style={{
                              ...InactiveButton,
                              padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                              float: "right",
                            }}
                            size="sm"
                          >
                            <span className="material-icons">add</span> Assign
                            Permissions
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ user, loading, roles }) => {
  return { user, loading, roles };
};
export default connect(mapStateToProps, {
  getRoles,
  deleteRole,
  deleteRolePermission,
  deleteRoleModule,
})(Role);
