import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {
  Autocomplete,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { connect, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CampaignIcon from "@mui/icons-material/Campaign";
import SearchBox from "../../common/components/SearchBox";
import UnitDrawer from "../../structure/components/UnitDrawer";
import ReactPaginate from "react-paginate";
import { CardContent } from "@mui/material";
import EmployeeCard from "../components/EmployeeCard";

import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Button,
} from "@mui/material";

import {
  getUnitEmployeesByPagination,
  getEntitySectorEmployees,
  setSearchEmployees,
  getAllowanceTypes,
  getBanks,
  getMedicalInsuranceTypes,
  getPeriods,
  getEntitySectors,
  getJobFields,
  getDegrees,
  getCountries,
  getCertificates,
  getEchelons,
  getPaymentStatuses,
  downloadEmployees,
  getMissingEmployeeDataSummary,
} from "../../../store/structure/actions";

import { getHrExitTypes } from "../../../store/exit/actions";

import {
  getCalculationMethods,
  getBasePayrollComponents,
  getAllowances,
  getCreditors,
  getActiveCurrencies,
  getEntitySectorPayrollTypes,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
} from "../../../store/payroll/actions";

import AddEmployeeDialog from "../../structure/components/AddEmployeeDialog";
import types from "../../../store/structure/action-types";
import { isEmpty } from "lodash";

import EmployeeSearchDialog from "../components/EmployeesSearchDialog";
import EmployeesPullDialog from "../components/EmployeesPullDialog";
import BackdropLoader from "../../common/components/BackdropLoader";
import TableSkeleton from "../../common/components/TableSkeleton";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import MissingEmployeeDataDialog from "../components/MissingEmployeeDataDialog";

const actions = [
  { icon: <AddIcon />, name: "Add New Employee", key: 1 },
  { icon: <ArrowCircleDownIcon />, name: "Pull Existing employee", key: 2 },
  { icon: <CampaignIcon />, name: "Promotions", key: 3 },
  { icon: <CloudDownloadIcon />, name: "Export all employees", key: 4 },
];

const Employees = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Employees";
  });
  const {
    // selectedUnit,
    user,
    getUnitEmployeesByPagination,
    getEntitySectorEmployees,
    employees,
    loading,
    onWait,
    setSearchEmployees,
    searchCurrentEmployees,
    getJobFields,
    allowances,
    creditors,
    allowanceTypes,
    calculationMethods,
    basePayrollComponents,
    getAllowances,
    getCreditors,
    getAllowanceTypes,
    getCalculationMethods,
    getBasePayrollComponents,
    banks,
    medicalInsuranceTypes,
    jobFields,
    getBanks,
    getMedicalInsuranceTypes,
    periods,
    getPeriods,
    entitySectors,
    getEntitySectors,
    degrees,
    getDegrees,
    countries,
    getCountries,
    certificates,
    getCertificates,
    getEchelons,
    echelons,
    currencies,
    getActiveCurrencies,
    exitTypes,
    getHrExitTypes,
    getEntitySectorPayrollTypes,
    payrollTypes,
    paymentStatuses,
    getPaymentStatuses,
    getSourceOfFunds,
    getPayrollGroups,
    getCostCenters,
    downloadEmployees,
    missingEmployeeDataSummary,
    getMissingEmployeeDataSummary,
  } = props;

  const [showEmployeeForm, setShowEmployeeForm] = useState(false);
  const [showMissingEmployeeDataDialog, setShowMissingEmployeeDataDialog] =
    useState(false);

  const { hasPermissions } = useRequiredAuth();
  const [selectedUnit, setSelectedUnit] = useState({});
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [employeeLoader, setEmployeeLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [employeeLoaderOnNextPage, setEmployeeLoaderOnNextPage] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (isEmpty(selectedUnit)) {
      dispatch({
        type: types.SET_EMPLOYEES,
        data: [],
      });
    }

    getSourceOfFunds();
    getPayrollGroups();
    getCostCenters(user.selectedEntitySector.id);

    if (!paymentStatuses.length) getPaymentStatuses();

    getEntitySectorPayrollTypes();

    if (!currencies.length) getActiveCurrencies();
    if (!echelons.length) getEchelons();

    if (!exitTypes.length) getHrExitTypes();

    if (!degrees.length) getDegrees();
    if (!countries.length) getCountries();

    if (!jobFields.length) getJobFields();
    if (!periods.length) getPeriods();
    if (!allowanceTypes.length) getAllowanceTypes();
    if (!creditors.length) getCreditors();
    if (!allowances.length) getAllowances();
    if (!calculationMethods.length) getCalculationMethods();
    if (!basePayrollComponents.length) getBasePayrollComponents();
    if (!banks.length) getBanks();
    if (!medicalInsuranceTypes.length) getMedicalInsuranceTypes();
    if (!entitySectors.length) getEntitySectors();
    if (!certificates.length) getCertificates();
    if (isEmpty(missingEmployeeDataSummary)) getMissingEmployeeDataSummary();
  }, []);

  const onSelectUnit = (unit) => {
    setSelectedUnit(unit);
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    getUnitEmployeesByPagination(
      unit.unitId,
      query,
      setTotalEmployees,
      setEmployeeLoader
    );
  };

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getUnitEmployeesByPagination(
      selectedUnit.unitId,
      query,
      setTotalEmployees,
      setEmployeeLoader
    );
  };

  const [allEmployees, setAllEmployees] = useState([]);
  const [download, setDownload] = useState(false);

  const downloadAllEmployees = async () => {
    if (!!employees.length) document.getElementById("downloadBTN").click();
    // try {
    //   dispatch({ type: "START_WAIT" });
    //   dispatch({ type: "START_LOADING" });

    //   setDownload(true);
    //   setAllEmployees([]);
    //   const { data } = await axios.get("/api/structure/employees");

    //   [...data].forEach((item) => {
    //     delete item.employeeGroupId;
    //     delete item.levelId;
    //     delete item.scaleId;
    //   });
    //   setAllEmployees(data);
    //   setDownload(false);

    //   dispatch({ type: "END_LOADING" });
    //   dispatch({ type: "END_WAIT" });
    // } catch (error) {
    //   dispatch({ type: "END_LOADING" });
    //   dispatch({ type: "END_WAIT" });
    // }
  };

  // useEffect(() => {
  //   if (!!allEmployees.length && download)
  //     document.getElementById("downloadBTN").click();
  // }, [allEmployees]);

  const [showEmployeeSearchDialog, setShowEmployeeSearchDialog] =
    useState(false);

  const [showEmployeePullDialog, setShowEmployeePullDialog] = useState(false);

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = employees.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getUnitEmployeesByPagination(
      selectedUnit.unitId,
      query,
      setTotalEmployees,
      setEmployeeLoaderOnNextPage
    );
  };
  return (
    <>
      <div className="d-flex ">
        <div className="structure-unit-drawer">
          <UnitDrawer onSelectUnit={onSelectUnit} />
        </div>
        <div className="structure-main-content w-100">
          <Box>
            <AppBar position="static" elevation={0} className="app-bar">
              <Toolbar className="px-3">
                <Typography
                  className="text-uppercase"
                  variant="h6"
                  noWrap
                  component="div"
                  style={{ maxWidth: "40vw" }}
                >
                  {!isEmpty(selectedUnit) && (
                    <>
                      <span
                        className="px-2 "
                        style={{
                          textDecoration: "initial",
                          backgroundColor: "#e9ecef",
                        }}
                      >
                        ({totalEmployees}) Employee
                        {totalEmployees > 1 && "s"}{" "}
                      </span>
                    </>
                  )}

                  {isEmpty(selectedUnit) && (
                    <span className="lead mr-2">--No unit selected--</span>
                  )}
                </Typography>

                {!isEmpty(selectedUnit) && (
                  <Box
                    sx={{ display: { xs: "flex", md: "flex" } }}
                    className="ml-2"
                  >
                    <SearchBox
                      onSearch={(search) => {
                        setSearchTerm(search);

                        if (!search) {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: "",
                            page: currentPage,
                            size: itemPerPage,
                          };
                          getUnitEmployeesByPagination(
                            selectedUnit.unitId,
                            query,
                            setTotalEmployees,
                            setEmployeeLoaderOnNextPage
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                    <Button
                      disabled={!searchTerm}
                      onClick={() => onSearch()}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2 "
                      style={{
                        borderRadius: "8px",
                        height: "38px",
                        border: `1px solid rgb(7, 142, 206)`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                    >
                      <span className="material-icons">search</span>GO
                    </Button>
                  </Box>
                )}

                {+missingEmployeeDataSummary.No_Gender +
                  +missingEmployeeDataSummary.No_Personal_Email +
                  +missingEmployeeDataSummary.No_PhoneNumber +
                  +missingEmployeeDataSummary.No_Professional_Email +
                  +missingEmployeeDataSummary.No_RSSB_Number +
                  +missingEmployeeDataSummary.No_Id_or_Passport >
                  0 && (
                  <Button
                    onClick={() => {
                      setShowMissingEmployeeDataDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      border: `1px solid #dc3545`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "#dc3545",
                    }}
                  >
                    <span
                      className="material-icons px-2"
                      style={{ fontSize: "13px" }}
                    >
                      warning
                    </span>
                    (
                    {+missingEmployeeDataSummary.No_Gender +
                      +missingEmployeeDataSummary.No_Personal_Email +
                      +missingEmployeeDataSummary.No_PhoneNumber +
                      +missingEmployeeDataSummary.No_Professional_Email +
                      +missingEmployeeDataSummary.No_RSSB_Number +
                      +missingEmployeeDataSummary.No_Id_or_Passport}
                    ) Important data are missing
                  </Button>
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  className="mr-2"
                >
                  {hasPermissions(["IS_HR"]) && (
                    <>
                      <Button
                        onClick={() => setShowEmployeeSearchDialog(true)}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2 "
                        style={{
                          borderRadius: "8px",
                          height: "30px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                      >
                        <span className="material-icons">person_search</span>
                        Find employee
                      </Button>
                      <Button
                        onClick={() => {
                          if (isEmpty(selectedUnit))
                            return alert("No unit selected.");
                          setShowEmployeePullDialog(true);
                        }}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          border: `1px solid #0785c0`,
                          color: "#0785c0",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "#eaeade",
                        }}
                      >
                        <span className="material-icons">get_app</span>
                        Pull employee
                      </Button>
                      <Tooltip title="Download">
                        <span className="ml-2">
                          <IconButton
                            disabled={
                              isDownloading ||
                              !employees.length ||
                              isEmpty(selectedUnit)
                            }
                            className="p-0 mr-1"
                            color="primary"
                            onClick={() =>
                              downloadEmployees(
                                selectedUnit.unitId,
                                selectedUnit.unitName,
                                setIsDownloading
                              )
                            }
                          >
                            {isDownloading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ fontSize: "28px" }}
                                ></span>

                                <span className="sr-only">Loading...</span>
                              </>
                            ) : (
                              <span
                                className="material-icons"
                                style={{ fontSize: "28px" }}
                              >
                                cloud_download
                              </span>
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Toolbar>
            </AppBar>
            <div
              style={{
                marginLeft: "1em",
                marginRight: "1em",
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                height: "55vh",
                overflowY: "scroll",
              }}
            >
              <CardContent className="pl-0 pt-0">
                {!isEmpty(selectedUnit) && (
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col">EmployeeId</th>
                        <th scope="col">Names </th>
                        <th scope="col" style={{ width: "128px" }}>
                          National ID
                        </th>
                        <th scope="col">RSSBNo</th>
                        <th scope="col">Position</th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          Level
                        </th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          Index
                        </th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          IndexValue
                        </th>

                        <th style={{ textAlign: "right" }} scope="col">
                          BasePay
                        </th>
                        <th style={{ textAlign: "right" }} scope="col">
                          BRD Repayment
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Employee Group
                        </th>

                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          Status
                        </th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>

                    {!!employeeLoader ? (
                      <TableSkeleton cols={14} rows={15} />
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            <BackdropLoader
                              isLoading={employeeLoaderOnNextPage}
                            />
                          </td>
                        </tr>
                        {employees
                          .filter(
                            ({ unitId, isRISASharedStaff }) =>
                              unitId === selectedUnit.unitId ||
                              selectedUnit.parentUnitId === null ||
                              !!isRISASharedStaff
                          )
                          .map((employee, index) => (
                            <EmployeeCard
                              showInstitutionName={false}
                              showActionMenus={true}
                              employee={employee}
                              employeeIndex={index}
                              key={index}
                              showUnitName={false}
                              selectedUnit={selectedUnit}
                            />
                          ))}
                      </tbody>
                    )}
                  </table>
                )}

                {!employees.length && !employeeLoader && (
                  <div className="jumbotron jumbotron-fluid text-center">
                    <div className="container py-5">
                      {!isEmpty(selectedUnit) && (
                        <p className="lead">No record found</p>
                      )}
                      {isEmpty(selectedUnit) && (
                        <p className="lead">Select unit to view employees</p>
                      )}
                    </div>
                  </div>
                )}
              </CardContent>

              {showMissingEmployeeDataDialog && (
                <MissingEmployeeDataDialog
                  showMissingEmployeeDataDialog={showMissingEmployeeDataDialog}
                  setShowMissingEmployeeDataDialog={
                    setShowMissingEmployeeDataDialog
                  }
                />
              )}

              {showEmployeeForm && (
                <AddEmployeeDialog
                  showEmployeeForm={showEmployeeForm}
                  setShowEmployeeForm={setShowEmployeeForm}
                  selectedUnit={selectedUnit}
                />
              )}

              {showEmployeeSearchDialog && (
                <EmployeeSearchDialog
                  showEmployeeSearchDialog={showEmployeeSearchDialog}
                  setShowEmployeeSearchDialog={setShowEmployeeSearchDialog}
                />
              )}

              {showEmployeePullDialog && (
                <EmployeesPullDialog
                  selectedUnit={selectedUnit}
                  showEmployeePullDialog={showEmployeePullDialog}
                  setShowEmployeePullDialog={setShowEmployeePullDialog}
                />
              )}
            </div>

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalEmployees / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!employees.length && (
                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getUnitEmployeesByPagination(
                      selectedUnit.unitId,
                      query,
                      setTotalEmployees,
                      setEmployeeLoaderOnNextPage
                    );
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>

            {/* {!user.selectedEntitySector.isLocked && !isEmpty(selectedUnit) && (
              <SpeedDial
                disabled={!isEmpty(selectedUnit) ? false : true}
                ariaLabel="SpeedDial openIcon example"
                sx={{ position: "absolute", bottom: 65, right: 35 }}
                icon={
                  <SpeedDialIcon
                    onClick={() => setShowEmployeeForm(true)}
                    openIcon={<CloseIcon />}
                  />
                }
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => {
                      if (action.key === 1) {
                        setShowEmployeeForm(true);
                      }
                    }}
                  />
                ))}
              </SpeedDial>
            )} */}
          </Box>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  selectedUnit,
  employees,
  loading,
  onWait,
  searchCurrentEmployees,
  allowances,
  creditors,
  allowanceTypes,
  calculationMethods,
  basePayrollComponents,
  banks,
  medicalInsuranceTypes,
  periods,
  entitySectors,
  jobFields,
  degrees,
  countries,
  certificates,
  echelons,
  currencies,
  exitTypes,
  payrollTypes,
  paymentStatuses,
  missingEmployeeDataSummary,
}) => {
  return {
    user,
    selectedUnit,
    employees,
    loading,
    onWait,
    searchCurrentEmployees,
    allowances,
    creditors,
    allowanceTypes,
    calculationMethods,
    basePayrollComponents,
    banks,
    medicalInsuranceTypes,
    periods,
    entitySectors,
    jobFields,
    degrees,
    countries,
    certificates,
    echelons,
    currencies,
    exitTypes,
    payrollTypes,
    paymentStatuses,
    missingEmployeeDataSummary,
  };
};

export default connect(mapStateToProps, {
  getUnitEmployeesByPagination,
  getEntitySectorEmployees,
  setSearchEmployees,

  getAllowances,
  getCreditors,
  getAllowanceTypes,
  getCalculationMethods,
  getBasePayrollComponents,
  getJobFields,
  getBanks,
  getMedicalInsuranceTypes,
  getPeriods,
  getEntitySectors,

  getDegrees,
  getCountries,
  getCertificates,
  getEchelons,
  getActiveCurrencies,
  getHrExitTypes,
  getEntitySectorPayrollTypes,
  getPaymentStatuses,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
  downloadEmployees,
  getMissingEmployeeDataSummary,
})(Employees);
