import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  LinearProgress,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getCRTRequestFileTypes,
  getPositionRequestTypes,
  savePositionRequest,
} from "../../../store/contractuals/actions";
import {
  getEchelons,
  getHeadOfInstitution,
  getHiringModes,
  getLevels,
  getTreeUnits,
} from "../../../store/structure/actions";
import {
  getActiveEmployeeGroups,
  getActivePayrollGroups,
  getActiveSourceOfFunds,
  getCostCenters,
} from "../../../store/payroll/actions";
import { showError } from "../../toastify";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import LetterTemplate from "../../Documents/LetterTemplate";
const steps = ["Request details ", "Attachments", "Authorization Letter"];
const draftLetterContentByMxWords = 2000;
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const AddContractRequestForm = (props) => {
  const {
    setShowRequestForm,
    loading,
    user,
    positionRequestTypes,
    getPositionRequestTypes,
    hiringModes,
    getHiringModes,
    employeeGroups,
    getActiveEmployeeGroups,
    levels,
    echelons,
    getLevels,
    getEchelons,
    costCenters,
    getCostCenters,
    payrollGroups,
    getActivePayrollGroups,
    sourceOfFunds,
    getActiveSourceOfFunds,
    units,
    getTreeUnits,
    getCRTRequestFileTypes,
    headOfInstitution,
    getHeadOfInstitution,
    savePositionRequest,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [loadingController, setLoadingController] = useState({
    requestTypes: true,
    positions: false,
    fileTypes: false,
  });
  const [formData, setFormData] = useState({
    requestTypeId: null,
    positionName: "",
    unitId: null,
    costCenterId: null,
    levelId: null,
    scaleId: null,
    positionId: null,
    indexValue: null,
    employeeGroupId: null,
    sourceOfFundId: null,
    payrollGroupId: null,
    hiringModeId: null,
    endDate: null,
    numberOfPosts: 1,
    attachments: [],
    docReferenceNo: null,
    draftLetterContent: null,
  });
  const [errors, setErrors] = useState({
    requestHasError: false,
    positionNameHasError: false,
    unitHasError: false,
    levelHasError: false,
    scaleHasError: false,
    positionHasError: false,
    indexValueHasError: false,
    employeeGroupHasError: false,
    sourceOfFundHasError: false,
    payrollGroupHasError: false,
    hiringModeHasError: false,
    endDateHasError: false,
    docReferenceNoHasError: false,

    attachmentHasErrors: [],
    hasError: false,
  });
  const [selectedData, setSelectedData] = useState({
    requestType: null,
  });
  const [requestFileTypes, setRequestFileTypes] = useState([]);
  const [fileTypesCategories, setFileTypesCategories] = useState({
    required: [],
    optional: [],
  });
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (positionRequestTypes.length === 0) {
      getPositionRequestTypes((status) => {
        setLoadingController({ ...loadingController, requestTypes: !status });
      });
    }
    getHeadOfInstitution(user.selectedEntitySector.id);
  }, []);

  useEffect(() => {
    if (formData.requestTypeId === 1) {
      if (units.length === 0) {
        getTreeUnits(user.selectedEntitySector.unitId);
      }
      if (hiringModes.length === 0) {
        getHiringModes();
      }
      if (levels.length === 0) {
        getLevels();
      }
      if (echelons.length === 0) {
        getEchelons();
      }
      if (costCenters.length === 0) {
        getCostCenters(user.selectedEntitySector.id);
      }
    }
  }, [formData.requestTypeId]);

  useEffect(() => {
    if (!!formData.costCenterId) {
      getActiveEmployeeGroups(formData.costCenterId);
    }
  }, [formData.costCenterId]);

  useEffect(() => {
    if (!!formData.costCenterId && !!formData.employeeGroupId) {
      getActivePayrollGroups(formData.costCenterId, formData.employeeGroupId);
    }
  }, [formData.employeeGroupId]);

  useEffect(() => {
    if (
      !!formData.costCenterId &&
      !!formData.employeeGroupId &&
      !!formData.payrollGroupId
    ) {
      getActiveSourceOfFunds(
        formData.costCenterId,
        formData.employeeGroupId,
        formData.payrollGroupId
      );
    }
  }, [formData.payrollGroupId]);

  useEffect(() => {
    if (
      activeStep === 1 &&
      !requestFileTypes.find(
        (fileType) => fileType.id === formData.requestTypeId
      )
    ) {
      getCRTRequestFileTypes(formData.requestTypeId, (status, data) => {
        setLoadingController({ ...loadingController, fileTypes: status });
        setRequestFileTypes(data);
        if (!status) {
          setFileTypesCategories({
            required: data.filter((fileType) => fileType.isRequired),
            optional: data.filter((fileType) => !fileType.isRequired),
          });
        }
      });
    }

    if (activeStep === 2 && !headOfInstitution) {
      getHeadOfInstitution(user.selectedEntitySector.id);
    }
  }, [activeStep]);

  const getEditorContent2 = (data) => {
    // console.log(data);
    let draftLetterContentMxWordsHasError = false;
    // if (data && data.isChanged) setHasTextEditorChanged(data.isChanged);
    if (data && data.contents)
      setFormData({
        ...formData,
        draftLetterContent: data.contents,
      });
    if (data && !!data.isMaxChar) draftLetterContentMxWordsHasError = true;

    setErrors({
      ...errors,
      draftLetterContentMxWordsHasError: draftLetterContentMxWordsHasError,
    });
  };

  const onClose = () => {
    setShowRequestForm(false);
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    return size > 2074190 ? true : false;
  };

  const handleUploadedPDFFile = (event, document) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });

    if (
      convertToLower(pdfAttachment?.name?.substr(-4)) !== ".pdf" &&
      pdfAttachment?.type !== "application/pdf"
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else if (isFileTooLarge(pdfAttachment)) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("Allowed file should not exceed 2MB");
    } else {
      const index = formData.attachments.findIndex(
        ({ id }) => id === document.id
      );
      const attachments = [...formData.attachments];
      document.file = pdfAttachment;
      document.filename = pdfAttachment.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        document.url = e.target.result;
      };

      reader.readAsDataURL(pdfAttachment);

      if (index < 0) attachments.push(document);
      else attachments[index] = document;
      setFormData({
        ...formData,
        attachments,
      });
      const index2 = errors.attachmentHasErrors.findIndex(
        ({ id }) => document && id && id === document.id
      );
      if (index2 >= 0) errors.attachmentHasErrors.splice(index2, 1);
      setErrors(errors);
    }
  };

  const stepOneValidation = () => {
    let hasError = false;
    if (!formData.requestTypeId) {
      setErrors({ ...errors, requestHasError: true });
      hasError = true;
    }
    if (formData.requestTypeId === 1) {
      if (!formData.positionName) {
        setErrors({ ...errors, positionNameHasError: true });
        hasError = true;
      }
      if (!formData.unitId) {
        setErrors({ ...errors, unitHasError: true });
        hasError = true;
      }
      if (!formData.costCenterId) {
        setErrors({ ...errors, costCenterHasError: true });
        hasError = true;
      }
      if (!formData.employeeGroupId) {
        setErrors({ ...errors, employeeGroupHasError: true });
        hasError = true;
      }
      if (!formData.payrollGroupId) {
        setErrors({ ...errors, payrollGroupHasError: true });
        hasError = true;
      }
      if (!formData.sourceOfFundId) {
        setErrors({ ...errors, sourceOfFundHasError: true });
        hasError = true;
      }
      if (!formData.levelId) {
        setErrors({ ...errors, levelHasError: true });
        hasError = true;
      }
      if (!formData.scaleId) {
        setErrors({ ...errors, scaleHasError: true });
        hasError = true;
      }
      if (!formData.hiringModeId) {
        setErrors({ ...errors, hiringModeHasError: true });
        hasError = true;
      }
      if (!formData.endDate) {
        setErrors({ ...errors, endDateHasError: true });
        hasError = true;
      }
      if (!formData.numberOfPosts || formData.numberOfPosts < 1) {
        setErrors({ ...errors, numberOfPostsHasError: true });
        hasError = true;
      }
    }
    return hasError;
  };

  const stepTwoValidation = () => {
    let hasError = false;
    if (fileTypesCategories.required.length > 0) {
      const attachmentHasErrors = [];
      fileTypesCategories.required.forEach((fileType) => {
        const index = formData.attachments.findIndex(
          ({ id }) => id === fileType.id
        );
        if (index < 0) {
          attachmentHasErrors.push(fileType);
          hasError = true;
        }
      });
      setErrors({ ...errors, attachmentHasErrors });
    }
    return hasError;
  };

  const stepThreeValidation = () => {
    let hasError = false;
    if (!formData.draftLetterContent) {
      setErrors({ ...errors, draftLetterContentHasError: true });
      hasError = true;
    }

    if (formData.draftLetterContent.length > draftLetterContentByMxWords) {
      setErrors({ ...errors, draftLetterContentMxWordsHasError: true });
      hasError = true;
    }

    if (!formData.docReferenceNo) {
      setErrors({ ...errors, docReferenceNoHasError: true });
      hasError = true;
    }

    return hasError;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    let allowNextStep = false;
    if (activeStep === 0 && !stepOneValidation()) {
      allowNextStep = true;
    }
    if (activeStep === 1 && !stepTwoValidation()) {
      allowNextStep = true;
    } else if (activeStep === 1) {
      showError("Please upload all required documents.");
    }

    if (activeStep === 2 && !stepThreeValidation()) {
      allowNextStep = true;
    } else if (activeStep === 2 && stepThreeValidation()) {
      console.log({ formData });
      showError("Please fill all required fields.");
    }

    if (allowNextStep && (activeStep === 0 || activeStep === 1)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (allowNextStep && activeStep === 2) {
      onSubmit();
    }
  };

  const onSubmit = () => {
    const payload = new FormData();
    const keys = Object.keys(formData);
    for (const key of keys) {
      if (formData.hasOwnProperty(key)) {
        if (key === "attachments" && formData[key] && !!formData[key].length) {
          formData[key].forEach((row, i) => {
            payload.append(key, row.file);
            payload.append(`filetypeIds`, row.id);
            payload.append(`fileTitles`, row.name);
          });
        } else payload.append(`${key}`, `${formData[key]}`);
      }
    }

    savePositionRequest(payload, (status, res) => {
      if (!status && res) {
        onClose();
      }
    });
  };

  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>
                <small
                  className="text-primary fw-bold text-truncate "
                  style={{ fontSize: "14px", marginTop: "-2px" }}
                >
                  Contractual Request Form
                </small>
              </h5>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                // stepProps.completed = isCompleted;

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          <Box
            component="form"
            sx={{ width: "100%", minHeight: "55vh" }}
            autoComplete="off"
          >
            {activeStep === 0 && (
              <>
                <FormCard title="Request Type">
                  <div className="row" ref={containerRef}>
                    <div className="col-lg-12">
                      <Autocomplete
                        loading={loading}
                        size="small"
                        id="entity"
                        defaultValue={null}
                        value={selectedData.requestType}
                        options={positionRequestTypes}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, requestType) => {
                          setFormData({
                            ...formData,
                            requestTypeId: requestType?.id || null,
                          });
                          setSelectedData({
                            ...selectedData,
                            requestType: requestType,
                          });
                          setErrors({ ...errors, requestHasError: false });
                        }}
                        getOptionLabel={(option) => option?.name}
                        renderOption={(props, requestType) => (
                          <Box component="li" {...props}>
                            {requestType?.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            // required={true}
                            {...params}
                            label="Select the request type"
                            placeholder="Select the request type"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />

                      {errors.requestHasError && (
                        <small className="text-danger">
                          Request Type is required
                        </small>
                      )}
                    </div>
                    {/* <div className="col-lg-5">test</div> */}
                    {(loadingController.positions || loading) && (
                      <div className="col-12">
                        <LinearProgress />
                      </div>
                    )}
                  </div>
                </FormCard>
                {formData.requestTypeId === 1 && (
                  <FormCard
                    title="Position Details"
                    reference={containerRef.current}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <TextField
                          size="small"
                          fullWidth
                          id="positionName"
                          label="Position Name"
                          value={formData.positionName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              positionName: e.target.value,
                            });
                            setErrors({
                              ...errors,
                              positionNameHasError: false,
                            });
                          }}
                          error={errors.positionNameHasError}
                          helperText={
                            errors.positionNameHasError
                              ? "Position Name is required"
                              : ""
                          }
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          size="small"
                          fullWidth
                          type="number"
                          id="numberOfPosts"
                          label="Number of Posts"
                          value={formData.numberOfPosts}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              numberOfPosts:
                                +e.target.value < 0 ? 0 : +e.target.value,
                            });
                            setErrors({
                              ...errors,
                              numberOfPostsHasError: false,
                            });
                          }}
                          error={errors.numberOfPostsHasError}
                          helperText={
                            errors.numberOfPostsHasError
                              ? "Number of Posts is required"
                              : ""
                          }
                        />
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="unit"
                          defaultValue={null}
                          value={selectedData.unit}
                          options={units}
                          isOptionEqualToValue={(option, value) =>
                            option.unitId === value.unitId
                          }
                          onChange={(event, unit) => {
                            setFormData({
                              ...formData,
                              unitId: unit?.unitId || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              unit: unit,
                            });
                            setErrors({ ...errors, unitHasError: false });
                          }}
                          getOptionLabel={(option) => option?.unitName}
                          renderOption={(props, unit) => (
                            <Box component="li" {...props}>
                              {unit?.unitName}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the unit"
                              placeholder="Select the unit"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />

                        {errors.unitHasError && (
                          <small className="text-danger">
                            Unit is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="costCenter"
                          defaultValue={null}
                          value={selectedData.costCenter}
                          options={costCenters}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, costCenter) => {
                            setFormData({
                              ...formData,
                              costCenterId: costCenter?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              costCenter: costCenter,
                            });
                            setErrors({ ...errors, costCenterHasError: false });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, costCenter) => (
                            <Box component="li" {...props}>
                              {costCenter?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the cost center"
                              placeholder="Select the cost center"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.costCenterHasError && (
                          <small className="text-danger">
                            Cost center is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="employeeGroup"
                          defaultValue={null}
                          value={selectedData.employeeGroup}
                          options={employeeGroups}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, employeeGroup) => {
                            setFormData({
                              ...formData,
                              employeeGroupId: employeeGroup?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              employeeGroup: employeeGroup,
                            });
                            setErrors({
                              ...errors,
                              employeeGroupHasError: false,
                            });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, employeeGroup) => (
                            <Box component="li" {...props}>
                              {employeeGroup?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the employee group"
                              placeholder="Select the employee group"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.employeeGroupHasError && (
                          <small className="text-danger ">
                            Employee Group is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="payrollGroup"
                          defaultValue={null}
                          value={selectedData.payrollGroup}
                          options={payrollGroups}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, payrollGroup) => {
                            setFormData({
                              ...formData,
                              payrollGroupId: payrollGroup?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              payrollGroup: payrollGroup,
                            });
                            setErrors({
                              ...errors,
                              payrollGroupHasError: false,
                            });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, payrollGroup) => (
                            <Box component="li" {...props}>
                              {payrollGroup?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the payroll group"
                              placeholder="Select the payroll group"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.payrollGroupHasError && (
                          <small className="text-danger">
                            Payroll Group is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="sourceOfFund"
                          defaultValue={null}
                          value={selectedData.sourceOfFund}
                          options={sourceOfFunds}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, sourceOfFund) => {
                            setFormData({
                              ...formData,
                              sourceOfFundId: sourceOfFund?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              sourceOfFund: sourceOfFund,
                            });
                            setErrors({
                              ...errors,
                              sourceOfFundHasError: false,
                            });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, sourceOfFund) => (
                            <Box component="li" {...props}>
                              {sourceOfFund?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the source of fund"
                              placeholder="Select the source of fund"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.sourceOfFundHasError && (
                          <small className="text-danger">
                            Source of Fund is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="level"
                          defaultValue={null}
                          value={selectedData.level}
                          options={levels}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, level) => {
                            setFormData({
                              ...formData,
                              levelId: level?.id || null,
                            });
                            setSelectedData({ ...selectedData, level: level });
                            setErrors({ ...errors, levelHasError: false });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, level) => (
                            <Box component="li" {...props}>
                              {level?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the level"
                              placeholder="Select the level"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.levelHasError && (
                          <small className="text-danger">
                            Level is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="echelon"
                          defaultValue={null}
                          value={selectedData.echelon}
                          options={echelons}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, echelon) => {
                            setFormData({
                              ...formData,
                              scaleId: echelon?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              echelon: echelon,
                            });
                            setErrors({ ...errors, scaleHasError: false });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, echelon) => (
                            <Box component="li" {...props}>
                              {echelon?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the scale"
                              placeholder="Select the scale"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.scaleHasError && (
                          <small className="text-danger">
                            Scale is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <TextField
                          size="small"
                          fullWidth
                          id="indexValue"
                          label="Index Value"
                          value={formData.indexValue}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setFormData({ ...formData, indexValue: null });
                            } else if (isNaN(e.target.value)) {
                              setErrors({
                                ...errors,
                                indexValueHasError: true,
                              });
                            } else if (+e.target.value < 0) {
                              setFormData({ ...formData, indexValue: 0 });
                            } else {
                              setFormData({
                                ...formData,
                                indexValue: e.target.value,
                              });
                              setErrors({
                                ...errors,
                                indexValueHasError: false,
                              });
                            }
                          }}
                          error={errors.indexValueHasError}
                          helperText={
                            errors.indexValueHasError
                              ? "Index Value is required"
                              : ""
                          }
                        />
                      </div>
                      <div className="col-lg-6 mt-3">
                        <Autocomplete
                          loading={loading}
                          size="small"
                          id="hiringMode"
                          defaultValue={null}
                          value={selectedData.hiringMode}
                          options={hiringModes}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, hiringMode) => {
                            setFormData({
                              ...formData,
                              hiringModeId: hiringMode?.id || null,
                            });
                            setSelectedData({
                              ...selectedData,
                              hiringMode: hiringMode,
                            });
                            setErrors({ ...errors, hiringModeHasError: false });
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, hiringMode) => (
                            <Box component="li" {...props}>
                              {hiringMode?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              // required={true}
                              {...params}
                              label="Select the hiring mode"
                              placeholder="Select the hiring mode"
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                        {errors.hiringModeHasError && (
                          <small className="text-danger">
                            Hiring Mode is required
                          </small>
                        )}
                      </div>
                      <div className="col-lg-6 mt-3">
                        <TextField
                          size="small"
                          fullWidth
                          id="endDate"
                          label="End Date"
                          type="date"
                          value={formData.endDate}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              endDate: e.target.value,
                            });
                            setErrors({ ...errors, endDateHasError: false });
                          }}
                          error={errors.endDateHasError}
                          helperText={
                            errors.endDateHasError ? "End Date is required" : ""
                          }
                        />
                      </div>
                    </div>
                  </FormCard>
                )}
              </>
            )}
            {activeStep === 1 && (
              <>
                {fileTypesCategories.required.length > 0 && (
                  <FormCard
                    title="Required files"
                    direction="down"
                    reference={containerRef.current}
                  >
                    <div className="row">
                      {fileTypesCategories.required.map((fileType, index) => (
                        <div key={fileType.id} className="col-12">
                          <fieldset
                            className={`form-group ${
                              !!(
                                errors &&
                                errors.attachmentHasErrors &&
                                !!errors.attachmentHasErrors.length &&
                                errors.attachmentHasErrors.find(
                                  (doc) => doc && fileType === doc
                                )
                              )
                                ? "border border-danger"
                                : "border"
                            } p-2`}
                          >
                            <legend
                              className="w-auto px-2"
                              style={{
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                                color: `${
                                  !!(
                                    errors &&
                                    errors.attachmentHasErrors &&
                                    !!errors.attachmentHasErrors.length &&
                                    errors.attachmentHasErrors.find(
                                      (doc) => doc && fileType === doc
                                    )
                                  )
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.6)"
                                }`,
                              }}
                            >
                              {index + 1}.{" "}
                              <span className="ml-1">
                                Attach {fileType.name}
                              </span>
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                {formData.attachments.find(
                                  (doc) => fileType === doc && !!doc.filename
                                ) ? (
                                  <div
                                    className="form-control"
                                    style={{
                                      backgroundColor: "rgb(229, 246, 253)",

                                      color: "#007bff",
                                    }}
                                  >
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: fileType.name,
                                          url: formData.attachments.find(
                                            (doc) =>
                                              fileType === doc && !!doc.filename
                                          ).url,
                                        });
                                      }}
                                    >
                                      {
                                        formData.attachments.find(
                                          (doc) =>
                                            fileType === doc && !!doc.filename
                                        ).filename
                                      }
                                    </span>

                                    <IconButton
                                      onClick={() => {
                                        const tmpDocuments = [
                                          ...formData.attachments,
                                        ];
                                        const index = tmpDocuments.findIndex(
                                          ({ id }) => id === fileType.id
                                        );

                                        tmpDocuments.splice(index, 1);
                                        setFormData({
                                          ...formData,
                                          attachments: tmpDocuments,
                                        });
                                      }}
                                      style={{ marginTop: "-6px" }}
                                      color="error"
                                      className="float-right"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: fileType.name,
                                          url: formData.attachments.find(
                                            (doc) =>
                                              fileType === doc && !!doc.filename
                                          ).url,
                                        });
                                      }}
                                      style={{ marginTop: "-6px" }}
                                      color="info"
                                      className="float-right"
                                      aria-label="view"
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      // disabled={!fiscalYear}
                                      type="file"
                                      name="file"
                                      className="form-control"
                                      accept="application/pdf"
                                      placeholder="Select pdf file"
                                      onChange={(e) =>
                                        handleUploadedPDFFile(e, fileType)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      ))}
                    </div>
                  </FormCard>
                )}

                {fileTypesCategories.optional.length > 0 && (
                  <FormCard
                    title="Optional files"
                    direction="down"
                    reference={containerRef.current}
                  >
                    <div className="row">
                      {fileTypesCategories.optional.map((fileType, index) => (
                        <div key={fileType.id} className="col-12">
                          <fieldset
                            className={`form-group ${
                              !!(
                                errors &&
                                errors.attachmentHasErrors &&
                                !!errors.attachmentHasErrors.length &&
                                errors.attachmentHasErrors.find(
                                  (doc) => doc && fileType === doc
                                )
                              )
                                ? "border border-danger"
                                : "border"
                            } p-2`}
                          >
                            <legend
                              className="w-auto px-2"
                              style={{
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                                color: `${
                                  !!(
                                    errors &&
                                    errors.attachmentHasErrors &&
                                    !!errors.attachmentHasErrors.length &&
                                    errors.attachmentHasErrors.find(
                                      (doc) => doc && fileType === doc
                                    )
                                  )
                                    ? "red"
                                    : "rgba(0, 0, 0, 0.6)"
                                }`,
                              }}
                            >
                              {index + 1}.{" "}
                              <span className="ml-1">
                                Attach {fileType.name}
                              </span>
                            </legend>
                            <div className="form-group1 mb-2">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-paperclip"></i>
                                  </span>
                                </div>
                                {formData.attachments.find(
                                  (doc) => fileType === doc && !!doc.filename
                                ) ? (
                                  <div
                                    className="form-control"
                                    style={{
                                      backgroundColor: "rgb(229, 246, 253)",

                                      color: "#007bff",
                                    }}
                                  >
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          name: fileType.name,
                                          url: formData.attachments.find(
                                            (doc) =>
                                              fileType === doc && !!doc.filename
                                          ).url,
                                        });
                                      }}
                                    >
                                      {
                                        formData.attachments.find(
                                          (doc) =>
                                            fileType === doc && !!doc.filename
                                        ).filename
                                      }
                                    </span>

                                    <IconButton
                                      onClick={() => {
                                        const tmpDocuments = [
                                          ...formData.attachments,
                                        ];
                                        const index = tmpDocuments.findIndex(
                                          ({ id }) => id === fileType.id
                                        );

                                        tmpDocuments.splice(index, 1);
                                        setFormData({
                                          ...formData,
                                          attachments: tmpDocuments,
                                        });
                                      }}
                                      style={{ marginTop: "-6px" }}
                                      color="error"
                                      className="float-right"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setShowUploadedDocument(true);
                                        setUploadedDocument({
                                          id: fileType.id,
                                          name: fileType.name,
                                          url: formData.attachments.find(
                                            (doc) =>
                                              fileType === doc && !!doc.filename
                                          ).url,
                                        });
                                      }}
                                      style={{ marginTop: "-6px" }}
                                      color="info"
                                      className="float-right"
                                      aria-label="view"
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <>
                                    <input
                                      // disabled={!fiscalYear}
                                      type="file"
                                      name="file"
                                      className="form-control"
                                      accept="application/pdf"
                                      placeholder="Select pdf file"
                                      onChange={(e) =>
                                        handleUploadedPDFFile(e, fileType)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      ))}
                    </div>
                  </FormCard>
                )}
              </>
            )}

            {activeStep === 2 && (
              <FormCard title="Request Letter" reference={containerRef.current}>
                <LetterTemplate
                  title="Request Letter"
                  author={{
                    firstName: headOfInstitution?.title
                      ? headOfInstitution?.title + "."
                      : "" + headOfInstitution?.firstName,
                    lastName: headOfInstitution?.lastName || "",
                    title: headOfInstitution?.title || "",
                    institutionName: headOfInstitution?.institutionName || "",
                    positionName: headOfInstitution?.positionName || "",
                  }}
                  docReferenceNo={formData.docReferenceNo}
                  draftLetterContentInput={
                    <>
                      <TextEditorComponent
                        id="editor-textarea5"
                        options={{
                          autoSave: false,
                          maxCharCount: draftLetterContentByMxWords,
                          defaultValue: formData.draftLetterContent,
                        }}
                        onEditorChange={getEditorContent2}
                      />

                      <ErrorMessage
                        hasError={errors.draftLetterContentHasError}
                        message="This field is required"
                      />
                    </>
                  }
                  docReferenceNoInput={
                    <>
                      <Input
                        autoFocus
                        value={formData.docReferenceNo}
                        inputProps={{
                          "aria-label": "docReferenceNo",
                        }}
                        onChange={(e) => {
                          const docReferenceNo = e.target.value;
                          setFormData({
                            ...formData,
                            docReferenceNo,
                          });
                        }}
                      />
                    </>
                  }
                  draftLetterContent={formData.draftLetterContent}
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  }
                />
              </FormCard>
            )}
          </Box>
        </DialogContent>
        <DialogActions className="py-3 mx-4">
          {activeStep > 0 && (
            <Button
              style={{
                ...InactiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
              }}
              size="md"
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
            >
              Back
            </Button>
          )}

          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            disabled={loading}
            onClick={handleNext}
            style={{
              ...ActiveButton,
              float: "right",
              background: "rgb(7, 142, 206)",
              borderColor: "rgb(7, 142, 206)",
            }}
            size="md"
          >
            {activeStep === 2 ? "Submit" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `View ${uploadedDocument?.name || ""}`,
            url: uploadedDocument?.url || "",
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  positionRequestTypes,
  employeeGroups,
  hiringModes,
  echelons,
  levels,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  units,
  headOfInstitution,
}) => {
  return {
    loading,
    user,
    positionRequestTypes,
    employeeGroups,
    hiringModes,
    echelons,
    levels,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    units,
    headOfInstitution,
  };
};

export default connect(mapStateToProps, {
  getPositionRequestTypes,
  getActiveEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  getActiveSourceOfFunds,
  getActivePayrollGroups,
  getCostCenters,
  getTreeUnits,
  getCRTRequestFileTypes,
  getHeadOfInstitution,
  savePositionRequest,
})(AddContractRequestForm);

const FormCard = ({ title, direction = "left", reference, children }) => {
  return (
    <Slide direction={direction} in={true} container={reference}>
      <div className="card service-card valid mb-3 mt-1">
        <div className="card-header" style={{ padding: "0.25rem 1.2rem" }}>
          {title}
        </div>
        <div className="card-body">{children}</div>
      </div>
    </Slide>
  );
};
