import support from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...support };

export const findUser = (term, cb) => {
  cb && cb(true, null);
  return async (dispatch) => {
    try {
      dispatch({
        type: types.USER_FOUND,
        data: null,
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/support/find-user?term=" + term);

      dispatch({
        type: types.USER_FOUND,
        data,
      });
      dispatch({ type: types.END_LOADING });
      cb && cb(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb && cb(false, null);
    }
  };
};

export const getEntitySectors = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/support/entitySectors");

      dispatch({
        type: types.SET_ENTITY_SECTORS,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//modules
export const getEntitySectorUnits = (entitySectorId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_ENTITY_SECTOR_UNITS,
        data: [],
      });
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/support/units/" + entitySectorId);

      dispatch({
        type: types.SET_ENTITY_SECTOR_UNITS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveUserSetting = (
  formData,
  setSearchTerm,
  setEntitySector,
  setEntitySectorUnit
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/support/user-settings", formData);

      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");

      dispatch({
        type: types.USER_FOUND,
        data: [],
      });

      dispatch({
        type: types.SET_ENTITY_SECTOR_UNITS,
        data: [],
      });

      setSearchTerm("");
      setEntitySector(null);
      setEntitySectorUnit(null);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorCostCenter = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/support/entitySectorCostCenters");
      dispatch({
        type: types.SET_ENTITY_SECTOR_COST_CENTERS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorCreditor = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/support/entititySectorCreditors");
      dispatch({
        type: types.SET_ENTITY_SECTOR_CREDITORS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEntitySectorPayrollType = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/support/entitySectorPayrollTypes");
      dispatch({
        type: types.SET_ENTITY_SECTOR_PAYROLL_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const findEmployeePosition = (employeeId, cb) => {
  cb && cb(null);
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/support/getEmployeePositionWithEmployeeId/" + employeeId
      );
      dispatch({ type: types.END_LOADING });
      cb && cb(data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb && cb(null);
    }
  };
};

export const editEmploymentData = (formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      await axios.put("/api/support/edit-employment-data", formData);
      showSuccess("Saved successfully");
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getEmployeeByEntitySectorId = (
  { entitySectorId, employeeId },
  cb
) => {
  cb && cb(true, []);
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/support/search-employee-entity-sector/" +
          entitySectorId +
          "/" +
          employeeId
      );
      cb && cb(false, data);
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      cb && cb(false, []);
    }
  };
};

export const assignCreditToAllEmployees = (formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      await axios.post("/api/support/employee-withholds", formData);
      showSuccess("Saved successfully");
      cb(false, true);
    } catch (error) {
      cb && cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionForSupport = (requisitionId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, null);
      const { data } = await axios.get(
        "/api/support/requisition/" + requisitionId
      );
      cb(false, data);
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateRequisitionForSupport = (requisitionId, formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      const { data } = await axios.patch(
        "/api/support/requisition/" + requisitionId,
        formData
      );
      showSuccess(data);
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const assignUserRole = (formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      await axios.post("/api/support/assign-role", formData);
      showSuccess("Role assigned successfully");
      cb(false, true);
    } catch (error) {
      cb && cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};


export const getAdvertisementForSupport = (advertisementId, cb) => {
  return async (dispatch) => {
    try {
      cb(true, null);
      const { data } = await axios.get(
        "/api/support/advertisement/" + advertisementId
      );
      cb(false, data);
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateAdvertisementForSupport = (advertisementId, formData, cb) => {
  return async (dispatch) => {
    try {
      cb(true, false);
      const { data } = await axios.patch(
        "/api/support/advertisement/" + advertisementId,
        formData
      );
      showSuccess(data);
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};