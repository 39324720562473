import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";
import { Input, TextField } from "@mui/material";

const Death = (props) => {
  const {
    recipientName,
    effectiveDate,
    recipientPositionName= "",
    institutionName= "",
    docReferenceId= "",
    enableToFillLetter = false,
    docReferenceIdInput = "",
    docReferenceDateInput = "",
    referenceLetterDate = "",
    referenceLetterId = "",
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    provisionalRefLetterId="",
    provisionalRefLetterDate="",
    provisionalRefLetterIdInput="",
    provisionalRefLetterDateInput="",
    reasonSuspension="",
    ccData = [],
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBULIKA Y’U RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            {/* <!-- Date and reference number go here --> */}
            <p className="date" style={{ fontWeight: "bold" }}>
              Kigali, on {moment(new Date()).format("LL")}
            </p>
            <p className="reference" style={{ fontWeight: "bold", float: "right",textAlign: "right" }}>
              No: {docReferenceId}
            </p>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                “{recipientPositionName}“
              </span>
              <br />
              <span style={{ position: "relative", top: "6px" }}>
                C/O: {institutionName.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="content2">
            {/* <!-- Letter content goes here --> */}
            <p>Dear {recipientName},</p>
            <p style={{ fontWeight: "bold" }}>Re: Death</p>
            <p>
            “Reference to the Letter No{" "}
              {enableToFillLetter ? (
                docReferenceIdInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterId}
                  </span>
                </>
              )}{" "}
              of{" "}
              {enableToFillLetter ? (
                docReferenceDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterDate}
                  </span>
                </>
              )}{" "}
              which appointed you on the position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recipientPositionName}{" "}
              </span>{" "}
              in{" "}
              <span style={{ fontWeight: "bold" }}>
                {institutionName.toUpperCase()}{" "}
              </span>
              ;
            </p>
            <p>
              Pursuant to Law No. 86/2013 of 11/09/2013 establishing the general statutes for Public Service in its article 35;
            </p>
            <p>
              I am pleased to inform you that, in the interest of service, you
              are transferred ...., with effect from{" "}
              <span style={{ fontWeight: "bold" }}>{effectiveDate}</span>
            </p>
            <p>
            I wish you success and high performance in your responsibilities.
            </p>
          </div>
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>
              Cc:
            </p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
              {enableToFillLetter && <><br /> {ccSelectForm}</> }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Death;
