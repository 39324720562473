import React from "react";

import { connect } from "react-redux";

const HRMessage = ({ user, hrMessage }) => {
  return (
    <>
      {!!hrMessage && (
        <div
          className="alert alert-warning  py-0 mb-0 weak-password-div  text-center text-uppercase"
          style={{
            borderTopColor: "#ff5722",
            borderBottomColor: "#ff5722",
            borderRadius: "0px",
            height: "28px",
          }}
        >
          <marquee behavior="" direction="">
            <strong style={{ color: "red" }}>{hrMessage}</strong>
          </marquee>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user, hrMessage }) => {
  return {
    user,
    hrMessage,
  };
};

export default connect(mapStateToProps)(HRMessage);
