import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  getMissingEmployeeData,
  getEmployeeBankAccounts,
  doVerifyRSSBNumber,
  doVerifyNIDNumber,
} from "../../../store/structure/actions";

import React, { useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../toastify";
import { useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { a11yProps } from "../../common/components/TabPanel";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getSelectedEmployeeProfilePicture } from "../../../store/common/actions";
import { getSingleActiveContract } from "../../../store/contractuals/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import EmployeeSalarySettingsDialog from "./EmployeeSalarySettingsDialog";
import EmployeeProfileDialog from "./EmployeeProfileDialog";

const MissingEmployeeDataDialog = (props) => {
  const {
    loading,
    user,
    showMissingEmployeeDataDialog,
    setShowMissingEmployeeDataDialog,
    missingEmployeeDataSummary,
    getMissingEmployeeData,
    missingEmployeeData,
    getSelectedEmployeeProfilePicture,
    getSingleActiveContract,
    getEmployeeBankAccounts,
    doVerifyRSSBNumber,
    doVerifyNIDNumber,
  } = props;

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showEmployeeProfileDialog, setShowEmployeeProfileDialog] =
    useState(false);
  const [tabs, setTabs] = useState([]);
  let columns = [];
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showSalarySettingsDialog, setShowSalarySettingsDialog] =
    useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  const [tabKey, setTabTabKey] = useState("0");
  const [noIdCount, setNoIdCount] = useState(0);
  const [noRssbCount, setNoRssbCount] = useState(0);

  const itemsPerPage = 15;
  useEffect(() => {
    for (const key in missingEmployeeDataSummary) {
      if (missingEmployeeDataSummary.hasOwnProperty(key)) {
        if (
          missingEmployeeDataSummary[key] > 0 &&
          key === "No_Id_or_Passport"
        ) {
          columns.push({
            column: "idNumber",
            value: "National ID Number",
          });
        }
        // if (missingEmployeeDataSummary[key] > 0 && key === "No_Gender") {
        //   columns.push({
        //     column: "genderId",
        //     value: "Gender",
        //   });
        // }
        // if (
        //   missingEmployeeDataSummary[key] > 0 &&
        //   key === "No_Personal_Email"
        // ) {
        //   columns.push({
        //     column: "email",
        //     value: "Personal Email",
        //   });
        // }
        if (missingEmployeeDataSummary[key] > 0 && key === "No_RSSB_Number") {
          columns.push({
            column: "rssbnumber",
            value: "RSSB Number",
          });
        }
        // if (missingEmployeeDataSummary[key] > 0 && key === "No_PhoneNumber") {
        //   columns.push({
        //     column: "phoneNumber",
        //     value: "PhoneNumber",
        //   });
        // }
        // if (
        //   missingEmployeeDataSummary[key] > 0 &&
        //   key === "No_Professional_Email"
        // ) {
        //   columns.push({
        //     column: "professionalEmail",
        //     value: "Professional Email",
        //   });
        // }
      }

      if (missingEmployeeData.length <= 0) {
        const tmpState = [...columns];

        const index = tmpState.findIndex(({ column }) => column === tabKey);

        tmpState.splice(index, 1);

        setTabs(tmpState);
      }

      setTabs(columns);

      if (!!columns.length && tabKey === "0") {
        setTabTabKey(columns[0].column);
        dispatch({
          type: "SET_MISSING_EMPLOYEE_DATA",
          data: [],
        });
        setPaginatedItems([]);
        getMissingEmployeeData(columns[0].column, setLoader);
      }
    }
  }, []);
  const onClose = () => {
    setShowMissingEmployeeDataDialog(false);
    setPaginatedItems([]);
    dispatch({
      type: "SET_MISSING_EMPLOYEE_DATA",
      data: [],
    });
  };
  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(
    () => {
      if (!!missingEmployeeData.length) paginate(missingEmployeeData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, missingEmployeeData]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % missingEmployeeData.length || 0;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    let idCount = 0;
    let rssbCount = 0;

    for (const key in missingEmployeeDataSummary) {
      if (key === "No_Id_or_Passport") {
        idCount = missingEmployeeDataSummary[key];
      } else if (key === "No_RSSB_Number") {
        rssbCount = missingEmployeeDataSummary[key];
      }
    }

    setNoIdCount(idCount);
    setNoRssbCount(rssbCount);
  }, [missingEmployeeDataSummary]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showMissingEmployeeDataDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <span className="ml-0 text-danger"> Missing Data </span>{" "}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "75vh",
            }}
          >
            <div className="d-flex justify-content-center align-items-center">
              {!!noIdCount && (
                <span className="mr-2 badge badge-pill">
                  <span className="badge badge-danger">{noIdCount}</span> NID
                  Number
                </span>
              )}
              {!!noRssbCount && (
                <span className="mr-2 badge badge-pill">
                  <span className="badge badge-danger">{noRssbCount}</span> RSSB
                  Number
                </span>
              )}
            </div>
            {showEmployeeProfileDialog && (
              <EmployeeProfileDialog
                selectedUnit={null}
                showEmployeeProfileDialog={showEmployeeProfileDialog}
                setShowEmployeeProfileDialog={setShowEmployeeProfileDialog}
                employee={selectedEmployee}
                enableToEditOnlyUserInfoOrContact={true}
              />
            )}

            {showSalarySettingsDialog && (
              <EmployeeSalarySettingsDialog
                showSalarySettingsDialog={showSalarySettingsDialog}
                setShowSalarySettingsDialog={setShowSalarySettingsDialog}
                employee={selectedEmployee}
                enableToEditOnlyRSSB={true}
              />
            )}
            <Tabs
              className=" justify-content-end"
              value={tabValue}
              onChange={handleChange}
              aria-label="tabs"
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((row, index) => (
                <Tab
                  color="error"
                  key={index + 1}
                  className="text-capitalize text-danger"
                  label={row.value}
                  {...a11yProps(index)}
                  onClick={() => {
                    setPaginatedItems([]);
                    dispatch({
                      type: "SET_MISSING_EMPLOYEE_DATA",
                      data: [],
                    });
                    setTabTabKey(row.column);
                    getMissingEmployeeData(row.column, setLoader);
                  }}
                />
              ))}
            </Tabs>

            <table className="table table-striped table-hover table-sm  fixTableHead mt-4">
              {!loader && (
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">EmployeeId</th>
                    <th scope="col">Names </th>
                    <th scope="col" className="text-right">
                      National ID
                    </th>
                    {/* <th scope="col">PassportNo</th> */}
                    <th scope="col" className="text-right">
                      RSSBNo
                    </th>
                    {/* <th scope="col">PersonalEmail</th>
                  <th scope="col">ProfessionalEmail</th>
                  <th scope="col">DateOfBirth</th>
                  <th scope="col">PhoneNumber</th>
                  <th scope="col">Gender</th> */}
                    <th scope="col" className="text-right">
                      Status
                    </th>
                    <th scope="col" className="text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
              )}

              {!!loader ? (
                <TableSkeleton cols={6} rows={15} />
              ) : (
                <tbody>
                  {paginatedItems.map((employee, index) => (
                    <MissingEmployeeData
                      key={employee.employeePositionId || index}
                      loading={loading}
                      getSelectedEmployeeProfilePicture={
                        getSelectedEmployeeProfilePicture
                      }
                      getSingleActiveContract={getSingleActiveContract}
                      getEmployeeBankAccounts={getEmployeeBankAccounts}
                      employee={employee}
                      setSelectedEmployee={setSelectedEmployee}
                      setShowSalarySettingsDialog={setShowSalarySettingsDialog}
                      setShowEmployeeProfileDialog={
                        setShowEmployeeProfileDialog
                      }
                      tabKey={tabKey}
                      doVerifyRSSBNumber={doVerifyRSSBNumber}
                      doVerifyNIDNumber={doVerifyNIDNumber}
                    />
                  ))}
                </tbody>
              )}
            </table>
            {!loader &&
              !!paginatedItems.length &&
              missingEmployeeData.length >= itemsPerPage && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-2"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  missingEmployeeData,
  missingEmployeeDataSummary,
}) => {
  return {
    loading,
    user,
    missingEmployeeData,
    missingEmployeeDataSummary,
  };
};
export default connect(mapStateToProps, {
  getMissingEmployeeData,
  getSelectedEmployeeProfilePicture,
  getSingleActiveContract,
  getEmployeeBankAccounts,
  doVerifyRSSBNumber,
  doVerifyNIDNumber,
})(MissingEmployeeDataDialog);

const MissingEmployeeData = (props) => {
  const {
    loading,
    getSelectedEmployeeProfilePicture,
    getSingleActiveContract,
    getEmployeeBankAccounts,
    employee,
    setSelectedEmployee,
    setShowSalarySettingsDialog,
    setShowEmployeeProfileDialog,
    tabKey,
    doVerifyRSSBNumber,
    doVerifyNIDNumber,
  } = props;

  const { hasPermissions } = useRequiredAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [isRSSBVerifying, setIsRSSBVerifying] = useState(false);
  const [isNIDVerifying, setIsNIDVerifying] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {employee.id}
        </th>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td className="text-right">
          {!!employee.idNumber ? (
            <span className="d-flex align-items-center justify-content-end">
              {(employee.idNumber || "").replace(/ /g, "")}
              <Tooltip
                title={`${employee.nidVerified ? "Verified" : "Not Verified"} `}
              >
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    disabled={isNIDVerifying}
                    className="p-1"
                    size="small"
                    onClick={() =>
                      doVerifyNIDNumber(employee, setIsNIDVerifying)
                    }
                  >
                    {employee.nidVerified && !isNIDVerifying && (
                      <span
                        className="material-icons text-success"
                        style={{ fontSize: "12px" }}
                      >
                        verified
                      </span>
                    )}
                    {!employee.nidVerified && !isNIDVerifying && (
                      <span
                        className="material-icons text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        warning
                      </span>
                    )}

                    {isNIDVerifying && (
                      <i
                        className="fas fa-spinner loader2"
                        style={{ fontSize: "12px" }}
                      ></i>
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          ) : (
            <>
              <span className="text-danger">
                missing...
                {/* <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "} */}
              </span>
            </>
          )}
        </td>

        <td className="text-right">
          {!!employee.rssbNumber ? (
            <span className="d-flex align-items-center justify-content-end">
              {employee.rssbNumber}
              <Tooltip
                title={`${
                  employee.rssbVerified ? "Verified" : "Not Verified"
                } `}
              >
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    disabled={isRSSBVerifying}
                    className="p-1"
                    size="small"
                    onClick={() =>
                      doVerifyRSSBNumber(employee, setIsRSSBVerifying)
                    }
                  >
                    {employee.rssbVerified && !isRSSBVerifying && (
                      <span
                        className="material-icons text-success"
                        style={{ fontSize: "12px" }}
                      >
                        verified
                      </span>
                    )}
                    {!employee.rssbVerified && !isRSSBVerifying && (
                      <span
                        className="material-icons text-danger"
                        style={{ fontSize: "12px" }}
                      >
                        warning
                      </span>
                    )}

                    {isRSSBVerifying && (
                      <i
                        className="fas fa-spinner loader2"
                        style={{ fontSize: "12px" }}
                      ></i>
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          ) : (
            <>
              <span className="text-danger">
                missing...
                {/* <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "} */}
              </span>
            </>
          )}
        </td>
        {/* <td>
          {!!employee.email ? (
            employee.email
          ) : (
            <>
              <span className="text-danger">
                missing...
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "}
              </span>
            </>
          )}
        </td>
        <td>
          {!!employee.professionalEmail ? (
            employee.professionalEmail
          ) : (
            <>
              <span className="text-danger">
                missing...
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "}
              </span>
            </>
          )}
        </td>
        <td>
          {!!employee.dateOfBirth ? (
            employee.dateOfBirth
          ) : (
            <>
              <span className="text-danger">
                missing...
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "}
              </span>
            </>
          )}
        </td>
        <td>
          {!!employee.phoneNumber ? (
            employee.phoneNumber
          ) : (
            <>
              <span className="text-danger">
                missing...{" "}
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "}
              </span>
            </>
          )}
        </td>
        <td>
          {!!employee.gender ? (
            employee.gender
          ) : (
            <>
              <span className="text-danger">
                missing...
                <span
                  className="material-icons text-danger"
                  style={{ fontSize: "12px" }}
                >
                  warning
                </span>{" "}
              </span>
            </>
          )}
        </td> */}

        <td className="text-right">
          {employee.isActing && (
            <strong
              className="badge badge-primary"
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              (Acting)
            </strong>
          )}

          {!employee.isActing && (
            <strong
              className={`ml-1 badge badge-${
                employee.statusId === 0 ? "success" : "warning"
              }`}
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              {employee.statusName}
            </strong>
          )}
        </td>
        <td className="text-right">
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            {/* <span>
              <MenuItem
                disabled={loading || !hasPermissions(["IS_HR"])}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedEmployee(employee);
                  setShowEmployeeProfileDialog(true);
                }}
                className="text-dark font-weight-bold text-primary"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Profile
              </MenuItem>
              <Divider className="my-1" />
            </span> */}

            <span>
              <MenuItem
                disabled={loading || !hasPermissions(["IS_HR"])}
                onClick={() => {
                  setSelectedEmployee(employee);
                  getSelectedEmployeeProfilePicture(employee.idNumber);

                  getSingleActiveContract(employee.employeePositionId);

                  getEmployeeBankAccounts(
                    employee.id,
                    setShowSalarySettingsDialog,
                    handleCloseMenu
                  );
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">tune</span>
                Salary settings
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>
    </>
  );
};
