import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Tabs,
  Tab,
  Button,
  Input,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { reviewTrainingRequest } from "../../../store/training/actions";
import { showError } from "../../../app/toastify";
import { Editor, textCapitalizing } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import AuthorizationLetter from "./AuthorizationLetter";
import { a11yProps } from "../../common/components/TabPanel";
import AuthorizationPermit from "./AuthorizationPermit";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";
import SignTrainingDialog from "./SignTrainingDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import { capitalize } from "lodash";
import ModeIcon from "@mui/icons-material/Mode";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "30px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const draftAuthorizationContentByMinistryMxWords = 2000;

const MifotraApproveTrainingDialog = (props) => {
  const {
    loading,
    user,
    level,
    showSignTrainingDialog,
    setShowSignTrainingDialog,
    request,
    setShowTrainingRequestDetails,

    userSignature,
    setUserSignature,
    headOfInstitution,
  } = props;
  //trainingFundProviders
  const [tabValue, setTabValue] = useState(2);
  const { hasPermissions } = useRequiredAuth();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    referenceNo: request.referenceNo,
    level: level,
    permitReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-permitReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-permitReferenceNo`)
      : request.permitReferenceNo,
    letterReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-letterReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-letterReferenceNo`)
      : request.letterReferenceNo,
    copiedInstitutionEmail: !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitutionEmail`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-copiedInstitutionEmail`
        )
      : "info@presidency.gov.rw,info@minaffet.gov.rw,info@minecofin.gov.rw,info@npsc.gov.rw",
    copiedInstitution: !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitution`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-copiedInstitution`)
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">
<ul>
<li>H.E. The President of The Republic of Rwanda</li>
<li>Hon. Minister of Foreign Affairs and International Cooperation</li>
<li>Hon. Minister of Finance and Economic Planning</li>
${
  request.requestType == "LongTerm" ? "<li>Hon. Minister of Education</li>" : ""
}
<li>Hon. ${capitalize(
          request.ministryEntityName
            .toUpperCase()
            .replace("MINISTRY", "MINISTER")
        )}</li>
<li>Executive Secretary of NPSC</li>
</ul></div>`,

    draftAuthorizationPermitContent: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationPermitContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationPermitContent`
        )
      : request.draftAuthorizationPermitContent
      ? request.draftAuthorizationPermitContent
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">I, ${
          request?.mifotraApproverFirstName
        } ${
          request?.mifotraApproverLastName
        }, Minister of Public Service and Labour, do hereby certify that ${
          request.employeeTitle
        }. ${request.lastName.toUpperCase()} ${request.firstName}, ${
          request.positionName
        } in the ${capitalize(
          request.entityName
        )} is authorised to attend a training on ${capitalize(
          request.trainingTitle
        )} in ${request.countryForTraining} from ${moment(
          request.trainingDurationFrom
        ).format("MMMM Do, YYYY")} to ${moment(
          request.trainingDurationTo
        ).format("MMMM Do, YYYY")}. <br />
${
  !request.isTrainingInsideCountry
    ? `The date of departure is ${moment(request.departureDate).format(
        "MMMM Do, YYYY"
      )} and the returning date is ${moment(request.returningDate).format(
        "MMMM Do, YYYY"
      )}.`
    : ""
} <br /> ${request.coveredRelatedCostsDetails.trim()}.<br />
I highly appreciate any assistance accorded to ${
          request.genderId === "M" ? "him" : "her"
        } in this regard.</div>
`,
    draftAuthorizationLetterContent: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationLetterContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationLetterContent`
        )
      : request.draftAuthorizationLetterContent
      ? request.draftAuthorizationLetterContent
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">
    Reference is made to letter N<sup>o</sup> ${
      request.minstryLetterReferenceNo
    } of ${moment(request.ministryApprovedDate).format(
          "MMMM Do, YYYY"
        )} from the  Hon. ${capitalize(
          request.ministryEntityName
            .toUpperCase()
            .replace("MINISTRY", "MINISTER")
        )} requesting authorization for training in favour of ${request.lastName.toUpperCase()} ${
          request.firstName
        };
I would like to acknowledge to Your Excellency that ${
          request.employeeTitle
        }. ${request.lastName.toUpperCase()} ${capitalize(
          request.firstName
        )}, ${request.positionName} in the ${capitalize(
          request.entityName
        )} is authorised to attend a training on ${capitalize(
          request.trainingTitle
        )}, ${request.countryForTraining} from ${moment(
          request.trainingDurationFrom
        ).format("MMMM Do, YYYY")} to ${moment(
          request.trainingDurationTo
        ).format("MMMM Do, YYYY")}. ${
          !request.isTrainingInsideCountry
            ? `The date of departure is ${moment(request.departureDate).format(
                "MMMM Do, YYYY"
              )} and the returning date is ${moment(
                request.returningDate
              ).format("MMMM Do, YYYY")}.`
            : ""
        } ${request.coveredRelatedCostsDetails.trim()}.</br>
Attached are the documents related to the training.</br>
Please accept, Right Hon. Prime Minister, the assurances of my usual highest consideration and esteem.</div>`,
    comments: !!window.localStorage.getItem(`${request.referenceNo}-comments`)
      ? window.localStorage.getItem(`${request.referenceNo}-comments`)
      : ``,
  });
  useEffect(() => {
    setFormData({
      ...formData,
      coveredRelatedCostsDetails: request.coveredRelatedCostsDetails,
    });
  }, [request, level]);

  useEffect(() => {
    setFormData({
      ...formData,
      coveredRelatedCostsDetails: request.coveredRelatedCostsDetails,
    });
  }, [request, level]);

  const [errors, setErrors] = useState({
    commentHasError: false,
    coveredRelatedCostsDetailsHasError: false,
    copiedInstitutionHasError: false,
    copiedInstitutionEmailHasError: false,
    draftAuthorizationPermitContentHasError: false,
    draftAuthorizationLetterContentHasError: false,
    permitReferenceNoHasError: false,
    letterReferenceNoHasError: false,
  });

  const formValidator = () => {
    const error = {
      draftAuthorizationPermitContentHasError: false,
      permitReferenceNoHasError: false,
      hasError: false,
    };

    if (!formData.draftAuthorizationPermitContent || formData.draftAuthorizationPermitContent === `<p><br></p>` ||
    formData.draftAuthorizationPermitContent === `<p></p>`) {
      error.draftAuthorizationPermitContentHasError = true;
      error.hasError = true;
    }
    if (!formData.permitReferenceNo) {
      error.permitReferenceNoHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };
  const getEditorContent = (data) => {
    let draftAuthorizationContentMaxCharHasError = false;
    // setHasTextEditorChanged(data.isChanged);
    setFormData({
      ...formData,
      draftAuthorizationPermitContent: data.contents,
    });
    request.draftAuthorizationPermitContent = data.contents;
    if (!!data.isMaxChar) draftAuthorizationContentMaxCharHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationContentMaxCharHasError:
        draftAuthorizationContentMaxCharHasError,
    });
  };
  const getEditorContent2 = (data) => {
    let draftAuthorizationContentMaxCharHasError2 = false;
    // setHasTextEditorChanged2(data.isChanged);
    setFormData({
      ...formData,
      draftAuthorizationLetterContent: data.contents,
    });

    request.draftAuthorizationLetterContent = data.contents;
    if (!!data.isMaxChar) draftAuthorizationContentMaxCharHasError2 = true;

    setErrors({
      ...errors,
      draftAuthorizationContentMaxCharHasError2:
        draftAuthorizationContentMaxCharHasError2,
    });
  };

  const getEditorContent3 = (data) => {
    setFormData({
      ...formData,
      copiedInstitution: data.contents,
    });
    request.copiedInstitution = data.contents;
  };

  const formValidator2 = () => {
    const error = {
      commentHasError: false,
      coveredRelatedCostsDetailsHasError: false,
      copiedInstitutionHasError: false,
      copiedInstitutionEmailHasError: false,
      draftAuthorizationLetterContentHasError: false,
      letterReferenceNoHasError: false,

      hasError: false,
    };

    if (!formData.copiedInstitution || formData.copiedInstitution === `<p><br></p>` ||
    formData.copiedInstitution === `<p></p>`) {
      error.copiedInstitutionHasError = true;
      error.hasError = true;
    }

    if (!formData.copiedInstitutionEmail) {
      error.copiedInstitutionEmailHasError = true;
      error.hasError = true;
    }

    if (!formData.draftAuthorizationLetterContent || formData.draftAuthorizationLetterContent === `<p><br></p>` ||
    formData.draftAuthorizationLetterContent === `<p></p>`) {
      error.draftAuthorizationLetterContentHasError = true;
      error.hasError = true;
    }
    if (!formData.letterReferenceNo) {
      error.letterReferenceNoHasError = true;
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };
  const updateFormData = () => {
    formData.permitReferenceNo = !!window.localStorage.getItem(
      `${request.referenceNo}-permitReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-permitReferenceNo`)
      : formData.permitReferenceNo;
    formData.letterReferenceNo = !!window.localStorage.getItem(
      `${request.referenceNo}-letterReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-letterReferenceNo`)
      : formData.letterReferenceNo;

    formData.copiedInstitutionEmail = !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitutionEmail`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-copiedInstitutionEmail`
        )
      : formData.copiedInstitutionEmail;
    formData.copiedInstitution = !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitution`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-copiedInstitution`)
      : formData.copiedInstitution;

    formData.draftAuthorizationPermitContent = !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationPermitContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationPermitContent`
        )
      : formData.draftAuthorizationPermitContent;
    formData.draftAuthorizationLetterContent = !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationLetterContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationLetterContent`
        )
      : formData.draftAuthorizationLetterContent;
    // formData.comments= !!window.localStorage.getItem(`${request.referenceNo}-comments`)?window.localStorage.getItem(`${request.referenceNo}-comments`):formData.comments;
    setFormData({
      ...formData,
    });
  };

  const onClose = () => {
    setFormData({
      referenceNo: "",
      level: "",
      coveredRelatedCostsDetails: "",
      copiedInstitution: "",
      copiedInstitutionEmail: "",
      comments: ``,
    });
    setShowSignTrainingDialog(false);
  };

  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSignTrainingDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Approve Request
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
         
        </DialogTitle>

        <DialogContent
          dividers
          style={{ height: activeStep === 0 ? "100vh" : "100vh" }}
        >
          <div className="row">
          {(tabValue === 0 || tabValue === 2) && (
            <div className="card service-card valid">
              <div
                className="card-header"
                style={{ padding: "0.25rem 1.2rem" }}
              >
              
                  <div className="row">
                    <div className="col-8 mt-1">
                      <strong>1. Training Authorization</strong>
                    </div>
                    <div className="col-4 float-right mt-1">
                    {tabValue === 2 && (
                      <button
                        type="button"
                        onClick={() => setTabValue(0)}
                        className="btn btn-primary btn-sm float-right"
                      >
                        <ModeIcon /> Edit Authorization
                      </button>
                           )}
                    </div>
                  </div>
       
              </div>
              <div className="card-body">
   
                  <AuthorizationPermit
                    headOfInstitution={{
                      firstName: request?.mifotraApproverFirstName || "",
                      lastName: request?.mifotraApproverLastName || "",
                      title: request?.mifotraApproverTitle || "",
                      institutionName:
                        request?.mifotraApproverInstitutionName || "",
                        positionName:request?.mifotraApproverPositionName || "",
                        canAddInstitutionName:  request?. mifotraApproverCanAddInstitutionName || "",
                    }}
                    permitReferenceNoInput={
                      tabValue === 2 ? (
                        ""
                      ) : (
                        <>
                          <Input
                            autoFocus
                            error={!!errors.permitReferenceNoHasError}
                            value={formData.permitReferenceNo}
                            inputProps={{
                              "aria-label": "permitReferenceNo",
                            }}
                            onChange={(e) => {
                              const permitReferenceNo = e.target.value;
                              setFormData({
                                ...formData,
                                permitReferenceNo,
                              });

                              setErrors({
                                ...errors,
                                permitReferenceNoHasError: false,
                              });
                            }}
                          />
                        </>
                      )
                    }
                    permitReferenceNo={formData.permitReferenceNo}
                    draftAuthorizationPermitContentInput={
                      tabValue === 2 ? (
                        ""
                      ) : (
                        <>
                          <TextEditorComponent
                            id="editor-textarea4"
                            options={{
                              autoSave: false,
                              localStorageKey: `${request.referenceNo}-draftAuthorizationPermitContent`,
                              // hasChanged: hasTextEditorChanged,
                              // api: `/api/training/update-authorization-contents/${request.referenceNo}`,
                              maxCharCount:
                                draftAuthorizationContentByMinistryMxWords,
                              defaultValue: !!window.localStorage.getItem(
                                `${request.referenceNo}-draftAuthorizationPermitContent`
                              )
                                ? window.localStorage.getItem(
                                    `${request.referenceNo}-draftAuthorizationPermitContent`
                                  )
                                : formData.draftAuthorizationPermitContent,
                            }}
                            onEditorChange={getEditorContent}
                          />

                          <ErrorMessage
                            hasError={
                              errors.draftAuthorizationPermitContentHasError
                            }
                            message="This field is required"
                          />
                        </>
                      )
                    }
                    request={request}
                    draftAuthorizationPermitContent={
                      formData.draftAuthorizationPermitContent
                    }
                    signContent={
                      <table>
                        <tr>
                          <td
                            style={{
                              width: "15%",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            <span className="d-block text-danger">
                              Not signed
                            </span>
                          </td>
                          {/* <td
                            style={{
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          >
                            {request?.mifotraDelegateApproverPositionName &&
                              hasPermissions([
                                "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
                              ]) && (
                                <>
                                  <p
                                    style={{
                                      fontWeight: 800,
                                      fontStyle: "italic",
                                      color: "blue",
                                    }}
                                  >
                                    For{" "}
                                    {request?.mifotraDelegateApproverFirstName ||
                                      ""}{" "}
                                    {request?.mifotraDelegateApproverLastName ||
                                      ""}
                                  </p>

                                  <p
                                    style={{
                                      fontWeight: 800,
                                      fontStyle: "italic",
                                      color: "blue",
                                    }}
                                  >
                                    {request?.mifotraDelegateApproverPositionName ||
                                      ""}
                                  </p>
                                </>
                              )}
                          </td> */}
                        </tr>
                      </table>
                    }
                  />
             
              </div>
            </div>
   )}
    {(tabValue === 1 || tabValue === 2) && (
            <div className="card service-card valid mb-2 mt-3">
              <div
                className="card-header"
                style={{ padding: "0.25rem 1.2rem" }}
              >
               
                  <div className="row">
                    <div className="col-8 mt-1">
                      <strong>2. Training Letter To PM</strong>
                    </div>
                    <div className="col-4 float-right mt-1">
                    {tabValue === 2 && (
                      <button
                        type="button"
                        onClick={() => setTabValue(1)}
                        className="btn btn-primary btn-sm float-right"
                      >
                        <ModeIcon />  Edit Letter To PM
                      </button>
                           )}
                    </div>
                  </div>
           
              </div>
              <div className="card-body">
       
          
                <AuthorizationLetter
                  headOfInstitution={{
                    firstName: request?.mifotraApproverFirstName || "",
                    lastName: request?.mifotraApproverLastName || "",
                    title: request?.mifotraApproverTitle || "",
                    institutionName:
                      request?.mifotraApproverInstitutionName || "",
                      positionName:request?.mifotraApproverPositionName || "",
                      canAddInstitutionName:  request?. mifotraApproverCanAddInstitutionName || "",
                  }}
                  request={request}
                  draftAuthorizationLetterContent={
                    formData.draftAuthorizationLetterContent
                  }
                  draftAuthorizationLetterContentInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <TextEditorComponent
                          id="editor-textarea51"
                          options={{
                            autoSave: false,
                            localStorageKey: `${request.referenceNo}-draftAuthorizationLetterContent`,
                            // hasChanged: hasTextEditorChanged2,
                            // api: `/api/training/update-authorization-letter-contents/${request.referenceNo}`,
                            maxCharCount:
                              draftAuthorizationContentByMinistryMxWords,
                            defaultValue: !!window.localStorage.getItem(
                              `${request.referenceNo}-draftAuthorizationLetterContent`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-draftAuthorizationLetterContent`
                                )
                              : formData.draftAuthorizationLetterContent,
                          }}
                          onEditorChange={getEditorContent2}
                        />

                        <ErrorMessage
                          hasError={
                            errors.draftAuthorizationLetterContentHasError
                          }
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitutionEmailInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <br />
                        <strong>
                          Copied Institution Email Ex:
                          info@mifotra.gov.rw,info@rdb.rw
                        </strong>
                        <TextField
                          fullWidth
                          size="small"
                          rows={4}
                          name="copiedInstitutionEmail"
                          placeholder="Ex: info@mifotra.gov.rw,info@rdb.rw"
                          variant="outlined"
                          value={formData.copiedInstitutionEmail}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              copiedInstitutionEmail: e.target.value,
                            });

                            setErrors({
                              ...errors,
                              copiedInstitutionEmailHasError: false,
                            });
                          }}
                        />
                        <ErrorMessage
                          hasError={errors.copiedInstitutionEmailHasError}
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitutionInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <br />
                        <strong>Provide copied institution</strong>
                        <TextEditorComponent
                          id="editor-textarea6"
                          options={{
                            autoSave: false,
                            height: "auto",
                            localStorageKey: `${request.referenceNo}-copiedInstitution`,
                            maxCharCount:
                              draftAuthorizationContentByMinistryMxWords,
                            defaultValue: !!window.localStorage.getItem(
                              `${request.referenceNo}-copiedInstitution`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-copiedInstitution`
                                )
                              : formData.copiedInstitution,
                          }}
                          onEditorChange={getEditorContent3}
                        />

                        <ErrorMessage
                          hasError={errors.copiedInstitutionHasError}
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitution={formData.copiedInstitution}
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "15%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        {/* <td
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          {request?.mifotraDelegateApproverPositionName &&
                            hasPermissions([
                              "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
                            ]) && (
                              <>
                                <p
                                  style={{
                                    fontWeight: 800,
                                    fontStyle: "italic",
                                    color: "blue",
                                  }}
                                >
                                  For{" "}
                                  {request?.mifotraDelegateApproverFirstName ||
                                    ""}{" "}
                                  {request?.mifotraDelegateApproverLastName ||
                                    ""}
                                </p>

                                <p
                                  style={{
                                    fontWeight: 800,
                                    fontStyle: "italic",
                                    color: "blue",
                                  }}
                                >
                                  {request?.mifotraDelegateApproverPositionName ||
                                    ""}
                                </p>
                              </>
                            )}
                        </td> */}
                      </tr>
                    </table>
                  }
                  letterReferenceNo={formData.letterReferenceNo}
                  letterReferenceNoInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <Input
                          autoFocus
                          error={!!errors.letterReferenceNoHasError}
                          value={formData.letterReferenceNo}
                          inputProps={{
                            "aria-label": "letterReferenceNo",
                          }}
                          onChange={(e) => {
                            const letterReferenceNo = e.target.value;
                            setFormData({
                              ...formData,
                              letterReferenceNo,
                            });

                            setErrors({
                              ...errors,
                              letterReferenceNoHasError: false,
                            });
                          }}
                        />
                      </>
                    )
                  }
                />
           
            </div>
            </div>
             )}
            {tabValue === 2 && (
              <>
                <div className="d-flex w-100 justify-content-center">
                  <div
                    className="w-100  py-2"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #17a2b8",
                    }}
                  >
                    <div className="col-lg-12">
                      <table>
                        <tr>
                          <td
                            style={{
                              width: "15%",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          ></td>
                          <td
                            style={{
                              width: "30%",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            <SignTrainingDialog
                              request={request}
                              onClose={onClose}
                              userSignature={userSignature}
                              setUserSignature={setUserSignature}
                              setShowTrainingRequestDetails={
                                setShowTrainingRequestDetails
                              }
                              level={"mifotra"}
                              formData2={formData}
                            />
                          </td>
                          <td
                            style={{
                              width: "15%",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                          ></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </DialogContent>
        {tabValue !== 2 && (
          <>
            <DialogActions className="d-flex justify-content-center py-4">
              {tabValue === 0 && (
                <>
                  <button
                    onClick={() => {
                      if (!formValidator()) {
                        updateFormData();
                        setTabValue(2);
                      }
                    }}
                    type="button"
                    className="btn btn-success  px-4"
                    disabled={loading}
                  >
                    Finish!
                  </button>
                </>
              )}
              {tabValue === 1 && (
                <>
                
                  <button
                    onClick={() => {
                      if (!formValidator2()) {
                        updateFormData();
                        setTabValue(2);
                      }
                    }}
                    type="button"
                    className="btn btn-success  px-4"
                    disabled={loading}
                  >
                    Finish!
                  </button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  reviewTrainingRequest,
})(MifotraApproveTrainingDialog);
