import React, { useState } from "react";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import { IconButton } from "@mui/material";
const SupportButton = ({ content, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSupportButtonBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="support-container">
      <button className="support-button" onClick={toggleSupportButtonBox}>
        <PhoneForwardedIcon />
      </button>

      {isOpen && (
        <SupportButtonBox
          content={content}
          title={title}
          closeChat={toggleSupportButtonBox}
        />
      )}
    </div>
  );
};

export default SupportButton;

const SupportButtonBox = ({ closeChat, content, title }) => {
  return (
    <div className="chatbox-container">
      <div className="chatbox-header">
        <span>{title || "Support contacts"}</span>
        <IconButton aria-label="close" onClick={closeChat}>
          <span className="material-icons">close</span>
        </IconButton>
      </div>
      <div className="chatbox-content">
        {/* Your chat content goes here */}
        <p> {content || "Welcome to support! How can we help you?"}</p>
      </div>
      {/* <div className="chatbox-footer">
          <input type="text" placeholder="Type your message..." />
          <button>Send</button>
        </div> */}
    </div>
  );
};
