import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  CardContent,
  Skeleton,
  AppBar,
  Toolbar,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import {
  getUnitPositionsByPagination,
  getEntitySectorPositions,
  setSearchPositions,
  lockEntitySector,
  downloadPositions,
  getSupervisors,
  getSalaryStructure,
} from "../../../store/structure/actions";

import {
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
} from "../../../store/payroll/actions";

import PositionCard from "../components/PositionCard";
import SearchBox from "../../common/components/SearchBox";
import UnitDrawer from "../components/UnitDrawer";
import types from "../../../store/structure/action-types";
import { connect, useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { isEmpty } from "lodash";
import AddPostionDialog from "./AddPostionDialog";
import AddSharedPostionDialog from "./AddSharedPostionDialog";
import ReactPaginate from "react-paginate";
import BackdropLoader from "../../common/components/BackdropLoader";
import TableSkeleton from "../../common/components/TableSkeleton";

const arrangingPostions = (data) => {
  let tempRes = [...data];
  let temPositions1 = [];
  let temPositions2 = [];
  let temPositions3 = [];

  tempRes.forEach((e) => {
    if (e.isHeadOfInstitution || e.isHeadOfUnit) temPositions1.push({ ...e });
    else if (e.isShared) temPositions3.push({ ...e });
    else temPositions2.push({ ...e });
  });

  return [...temPositions1, ...temPositions2, ...temPositions3];
};

const ViewOrganizationStructure = (props) => {
  const {
    user,
    loading,
    selectedEntitySector,
    showOrganizationStructure,
    setShowOrganizationStructure,
    getUnitPositionsByPagination,
    getEntitySectorPositions,
    positions,
    setSearchPositions,
    searchCurrentPositions,
    lockEntitySector,
    entity,
    downloadPositions,

    supervisors,
    getSupervisors,
    payrollGroups,
    salaryStructures,
    getSalaryStructure,
    getSourceOfFunds,
    getPayrollGroups,
    getCostCenters,
  } = props;

  const [showPostionForm, setShowPositionForm] = useState(false);
  const [showSharedPostionForm, setShowSharedPositionForm] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalPositions, setTotalPositions] = useState(0);
  const [positionLoader, setPositionLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [positionLoaderOnNextPage, setPositionLoaderOnNextPage] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLocked, setIsLocked] = useState(selectedEntitySector.isLocked);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(selectedUnit)) {
      dispatch({
        type: types.SET_POSITIONS,
        data: [],
      });
    }

    // if (
    //   !supervisors.length ||
    //   (!!supervisors.length && !!supervisors[0].employeePositionId)
    // )
    getSupervisors(selectedEntitySector.id);

    // if (!salaryStructures.length)
    getSalaryStructure(selectedEntitySector.id);

    getSourceOfFunds();
   if(!payrollGroups.length) getPayrollGroups(selectedEntitySector.id);
    getCostCenters(selectedEntitySector.id);
  }, []);

  const onClose = () => {
    setShowOrganizationStructure(false);
    dispatch({
      type: types.SET_UNITS,
      data: [],
    });

    dispatch({
      type: types.SET_TREE_UNITS,
      data: {},
    });

    dispatch({
      type: types.SET_SUPERVISORS,
      data: [],
    });
  };

  const onSearch = (term) => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      selectedUnit.unitId,
      query,
      setTotalPositions,
      setPositionLoader
    );
  };

  const onSelectUnit = (unit) => {
    setSelectedUnit(unit);
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      unit.unitId,
      query,
      setTotalPositions,
      setPositionLoader
    );
  };
  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      selectedUnit.unitId,
      query,
      setTotalPositions,
      setPositionLoaderOnNextPage
    );
  };

  const getNo = (idToSearch) =>
    arrangingPostions(positions).findIndex(({ id }) => id === idToSearch);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showOrganizationStructure}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">Organization Stucture | </span>{" "}
                <span className="ml-0">
                  {entity && entity.name}{" "}
                  <span className="badge badge-secondary">
                    {selectedEntitySector && selectedEntitySector.sector.name}
                  </span>
                </span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "75vh",
            }}
          >
            <div className="d-flex">
              <div className="structure-unit-drawer">
                <UnitDrawer
                  onSelectUnit={onSelectUnit}
                  selectedEntitySector={selectedEntitySector}
                />
              </div>

              <div className="structure-main-content w-100">
                <AppBar position="static" elevation={0} className="app-bar">
                  <Toolbar className="px-3 tool-bar">
                    <div className="row">
                      <div className="col-6">
                        <Typography
                          className="text-uppercase "
                          variant="h6"
                          noWrap
                          component="div"
                          style={{ maxWidth: "40vw" }}
                        >
                          {" "}
                          {isLocked && (
                            <Tooltip title="Structure was locked">
                              <i className="fas fa-lock text-danger"></i>
                            </Tooltip>
                          )}
                          <span
                            className="px-2 ml-1"
                            style={{
                              textDecoration: "initial",
                              backgroundColor: "#e9ecef",
                            }}
                          >
                            {isEmpty(selectedUnit)
                              ? "--No unit selected--"
                              : selectedUnit.unitName}
                          </span>
                          {/* <Box sx={{ flexGrow: 1 }} /> */}
                        </Typography>
                      </div>
                      <div className="col-6">
                        {!isEmpty(selectedUnit) && (
                          <Box
                            sx={{ display: { xs: "flex", md: "flex" } }}
                            className="ml-2"
                          >
                            <SearchBox
                              onSearch={(search) => {
                                setSearchTerm(search);
                                if (!search) {
                                  const query = {
                                    searchTerm: "",
                                    page: currentPage,
                                    size: itemPerPage,
                                  };
                                  getUnitPositionsByPagination(
                                    selectedUnit.unitId,
                                    query,
                                    setTotalPositions,
                                    setPositionLoaderOnNextPage
                                  );
                                }
                              }}
                              placeholder="Search…"
                            />
                            <Button
                              disabled={!searchTerm}
                              onClick={() => onSearch()}
                              variant="contained"
                              size="small"
                              disableElevation
                              className="mr-2 "
                              style={{
                                borderRadius: "8px",
                                height: "38px",
                                border: `1px solid rgb(7, 142, 206)`,
                                color: "#fff",
                                alignItems: "center",
                                fontSize: ".75rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textTransform: "initial",
                                backgroundColor: "rgb(7, 142, 206)",
                                marginLeft: "5px",
                                "&:hover": {
                                  backgroundColor: "#f0f2f5",
                                },
                              }}
                            >
                              <span className="material-icons">search</span>GO
                            </Button>
                          </Box>
                        )}
                      </div>
                      <div className="col-12 float-right mt-2">
                        <Box
                          sx={{ display: { xs: "flex", md: "flex" } }}
                          className="mr-2 float-right"
                        >
                          {!(
                            selectedUnit.unitId ===
                              "1903000000-150200000000000000" ||
                            selectedUnit.unitId ===
                              "1903000000-150201000000000000"
                          ) && (
                            <Button
                              onClick={() => {
                                if (isEmpty(selectedUnit))
                                  return alert("No unit selected.");
                                setShowPositionForm(true);
                              }}
                              variant="contained"
                              size="small"
                              disableElevation
                              className="mr-2"
                              style={{
                                borderRadius: "8px",
                                height: "32px",
                                border: `1px solid rgb(7, 142, 206)`,
                                color: "#fff",
                                alignItems: "center",
                                fontSize: ".75rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textTransform: "initial",
                                backgroundColor: "rgb(7, 142, 206)",
                              }}
                            >
                              <span className="material-icons">add</span>
                              Add position
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              if (isEmpty(selectedUnit))
                                return alert("No unit selected.");
                              setShowSharedPositionForm(true);
                            }}
                            variant="contained"
                            size="small"
                            disableElevation
                            className="mr-2"
                            style={{
                              borderRadius: "8px",
                              height: "32px",
                              border: `1px solid #0785c0`,
                              color: "#0785c0",
                              alignItems: "center",
                              fontSize: ".75rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textTransform: "initial",
                              backgroundColor: "#eaeade",
                            }}
                          >
                            <span className="material-icons">get_app</span>
                            Pull shared position
                          </Button>

                          <FormControlLabel
                            sx={{
                              float: "right",
                              display: "block",
                            }}
                            control={
                              <Switch
                                checked={isLocked}
                                onChange={() => {
                                  selectedEntitySector.isLocked =
                                    !selectedEntitySector.isLocked;
                                  lockEntitySector(
                                    selectedEntitySector,
                                    setIsLocked
                                  );
                                }}
                                name="lock"
                                color={isLocked ? "primary" : "info"}
                              />
                            }
                            label={isLocked ? "Locked" : "Lock"}
                          />
                          <Tooltip title="Download">
                            <span className="mr-2">
                              <IconButton
                                disabled={
                                  isDownloading ||
                                  !positions.length ||
                                  isEmpty(selectedUnit)
                                }
                                className="p-0 mr-1"
                                color="primary"
                                onClick={() =>
                                  downloadPositions(
                                    selectedUnit.unitId,
                                    selectedUnit.unitName,
                                    setIsDownloading
                                  )
                                }
                              >
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  cloud_download
                                </span>
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </div>
                    </div>
                  </Toolbar>
                </AppBar>

                <div
                  style={{
                    marginLeft: "1em",
                    marginRight: "1em",
                    backgroundColor: "#fff",
                    borderRadius: "0.5rem",
                    boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                    MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                    height: "66vh",
                    overflow: "auto",
                  }}
                >
                  <CardContent className="pl-0 pt-0 pb-0">
                    {!isEmpty(selectedUnit) && (
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col">#</th>
                            <th scope="col">
                              <Box
                                sx={{ display: { xs: "flex", md: "flex" } }}
                                className="align-items-center"
                              >
                                <span className="badge badge-secondary">
                                  {totalPositions}
                                </span>{" "}
                                <span className="mx-2"> Positions </span>
                              </Box>
                            </th>
                            <th scope="col" className="text-center">
                              HiringMode
                            </th>
                            <th scope="col" className="text-center">
                              Level
                            </th>
                            <th scope="col" style={{ textAlign: "right" }}>
                              Index
                            </th>
                            <th scope="col" style={{ textAlign: "right" }}>
                              IndexValue
                            </th>

                            <th style={{ textAlign: "right" }} scope="col">
                              BasePay
                            </th>
                            <th scope="col" className="text-center">
                              Employee Group
                            </th>

                            {/* <th scope="col" className="text-center">
                        #Vacants
                      </th> */}

                            <th scope="col" className="text-right">
                              #Posts
                            </th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>

                        {positionLoader ? (
                          <TableSkeleton cols={10} rows={15} />
                        ) : (
                          <tbody>
                            <BackdropLoader
                              isLoading={positionLoaderOnNextPage}
                            />
                            {[...arrangingPostions(positions)].map(
                              (position, index) => (
                                <PositionCard
                                  isEntitySectorLocked={false}
                                  key={position.id}
                                  positionIndex={getNo(position.id)}
                                  position={position}
                                  selectedUnit={selectedUnit}
                                />
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    )}

                    {!positions.length && !positionLoader && (
                      <div className="jumbotron jumbotron-fluid text-center">
                        <div className="container py-5 ">
                          {!isEmpty(selectedUnit) && (
                            <p className="lead">No record found</p>
                          )}
                          {isEmpty(selectedUnit) && (
                            <p className="lead">
                              Select unit to view positions
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      className="d-flex justify-content-center mb-3 align-items-center"
                      style={{ overflow: "hidden" }}
                    >
                      {!positionLoader && (
                        <>
                          <label> Item per page:</label>

                          <select
                            className="custom-select custom-select-sm mr-3"
                            style={{ width: "100px" }}
                            value={itemPerPage}
                            onChange={(event) => {
                              const itemPerPage = +event.target.value;
                              window.localStorage.setItem(
                                "ITEM_PER_PAGE",
                                itemPerPage
                              );
                              setItemPerPage(itemPerPage);
                              const query = {
                                searchTerm: searchTerm,
                                page: currentPage,
                                size: itemPerPage,
                              };
                              getUnitPositionsByPagination(
                                selectedUnit.unitId,
                                query,
                                setTotalPositions,
                                setPositionLoaderOnNextPage
                              );
                            }}
                          >
                            {[10, 50, 100].map((item, index) => (
                              <option key={index}>{item}</option>
                            ))}
                          </select>
                          <div className="mt-3">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel={
                                <>
                                  <span className="pr-1 d-none d-md-inline">
                                    Next
                                  </span>
                                  <i className="fas fa-angle-double-right"></i>
                                </>
                              }
                              previousLabel={
                                <>
                                  <i className="fas fa-angle-double-left"></i>
                                  <span className="pl-1  d-none d-md-inline">
                                    Previous
                                  </span>
                                </>
                              }
                              pageRangeDisplayed={3}
                              // marginPagesDisplayed={1}
                              onPageChange={handlePageClick}
                              pageCount={Math.ceil(
                                totalPositions / itemPerPage
                              )}
                              renderOnZeroPageCount={null}
                              containerClassName="pagination"
                              pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                              previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                              nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                              activeLinkClassName="active"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </CardContent>

                  {showPostionForm && (
                    <AddPostionDialog
                      showPostionForm={showPostionForm}
                      setShowPositionForm={setShowPositionForm}
                      selectedUnit={selectedUnit}
                    />
                  )}

                  {showSharedPostionForm && (
                    <AddSharedPostionDialog
                      showSharedPostionForm={showSharedPostionForm}
                      setShowSharedPositionForm={setShowSharedPositionForm}
                      selectedUnit={selectedUnit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  selectedUnit,
  positions,
  searchCurrentPositions,
  supervisors,
  salaryStructures,
  payrollGroups
}) => {
  return {
    user,
    loading,
    selectedUnit,
    positions,
    searchCurrentPositions,
    supervisors,
    salaryStructures,
    payrollGroups
  };
};
export default connect(mapStateToProps, {
  getUnitPositionsByPagination,
  getEntitySectorPositions,
  setSearchPositions,
  lockEntitySector,
  downloadPositions,
  getSupervisors,
  getSalaryStructure,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
})(ViewOrganizationStructure);
