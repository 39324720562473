import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import {
  CardContent,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Disabilities from "../components/Disabilities";
import DisabilityLevels from "../components/DisabilityLevels";

const Users = (props) => {
  const [createDisability, setCreateDisability] = useState(false);
  const [createDisabilityLevel, setCreateDisabilityLevel] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>ADMIN</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>Users</span>
              </Typography>

              {/* <Box sx={{ flexGrow: 1 }} /> */}
            </Toolbar>
          </AppBar>

          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <div className="d-flex flex-row justify-content-between align-items-end pb-1">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Disabilities" {...a11yProps(0)} />
                    <Tab label="Disability levels" {...a11yProps(1)} />
                    {/* <Tab label="Permissions" {...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
                {tabValue === 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCreateDisability(true)}
                  >
                    Add Disability
                  </Button>
                )}
                {tabValue === 1 && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setCreateDisabilityLevel(true)}
                  >
                    Add Disability level
                  </Button>
                )}
              </div>

              <TabPanel value={tabValue} index={0}>
                <Disabilities
                  createDisability={createDisability}
                  setCreateDisability={setCreateDisability}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <DisabilityLevels
                  createDisabilityLevel={createDisabilityLevel}
                  setCreateDisabilityLevel={setCreateDisabilityLevel}
                />
              </TabPanel>
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Users;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
