import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateBank,
  addNewBank,
  getAdminBank,
  getAdminCountry,
  getAdminCreditor,
  updateBank,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const Banks = (props) => {
  const {
    createBank,
    setCreateBank,
    // redux -----------------
    loading,
    addNewBank,
    updateBank,
    adminCountries,
    getAdminCountry,
    adminBanks,
    getAdminBank,
    activateBank,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [filterCountry, setFilterCountry] = useState(null);
  const [deleteBank, setDeleteBank] = useState(null);
  const [bankId, setBankId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAcronym, setBankAcronym] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [newBankId, setNewBankId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState(undefined);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!adminBanks.length) {
      getAdminBank(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (!adminCountries.length) getAdminCountry();

    return () => {
      setLoadingData(true);
      setBankId("");
      setBankName("");
      setFormError(null);
      setSearchInput("");
      setSelectedCountry(null);
    };
  }, []);

  const onCloseForm = () => {
    setCreateBank(false);
    setBankId("");
    setBankName("");
    setSelectedCountry(null);
    setNewBankId("");
    setBankAcronym("");
    setSwiftCode("");
  };

  const onSaveForm = () => {
    if (!bankId.length && !createBank) {
      setFormError({
        element: "GENERAL",
        msg: "Please select bank you want to update.",
      });
      return;
    }
    if (!newBankId.length && createBank) {
      setFormError({
        element: "BANK_ID",
        msg: "Bank id is required!",
      });
      return;
    }
    if (newBankId.length > 3) {
      setFormError({
        element: "BANK_ID",
        msg: "Bank id must be 3 number max!",
      });
      return;
    }
    if (bankName.length === 0) {
      setFormError({ element: "NAME", msg: "Certificate name is required." });
      return;
    }

    if (bankId.length && !createBank) {
      updateBank(
        bankId,
        {
          name: bankName,
          acronym: bankAcronym.length ? bankAcronym : null,
          country: selectedCountry,
          swiftCode: swiftCode.length ? swiftCode : null,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewBank(
        {
          bankId: newBankId,
          name: bankName,
          acronym: bankAcronym.length ? bankAcronym : null,
          country: selectedCountry,
          swiftCode: swiftCode.length ? swiftCode : null,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const handleFilter = (data) => {
    let result = [...data];
    result = result.filter((item) => item.isActive === !showInactive);
    // if (filterBasePayroll)
    //   result = result.filter(
    //     (item) => item.basePayrollComponentId === filterBasePayroll?.id
    //   );

    return result;
  };

  const handleChangeStatus = () => {
    if (deleteBank)
      activateBank(deleteBank.id, (status) => status && setDeleteBank(null));
  };

  const IS_EDITING = Boolean(bankId.length);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0
      ? adminBanks
      : adminBanks.filter(
          (item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase()) ||
            (item.accronym || "").includes(searchInput) ||
            (item.swiftCode || "").includes(searchInput)
        )
    : [];
  SEARCHED_DATA = handleFilter(SEARCHED_DATA);
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (bankItem) => {
    setBankId(bankItem.id);
    setBankName(bankItem.name);
    setBankAcronym(bankItem.accronym);
    setSelectedCountry(bankItem.country);
    setSwiftCode(bankItem.swiftCode || "");
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-12 col-sm-8">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || adminBanks.length === 0}
            placeholder="Search bank..."
          />
        </div>
        <div className="col-6 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, country) => (
              <Box component="li" {...props}>
                {country?.name}
              </Box>
            )}
            options={adminCountries}
            size="small"
            value={filterCountry || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "country",
                }}
              />
            )}
            onChange={(event, country) => setFilterCountry(country || null)}
          />
        </div>
        <div className="col-4 col-sm-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
            }}
            variant="outlined"
            size="small"
            disableElevation
            // className="mr-2"
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Bank</th>
            <th className="text-center">Country</th>
            <th className="text-center">Swift code</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={5} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={5}>
                <div className="text-center font-weight-bold my-2">
                  No bank found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((bankItem, index) => (
              <tr key={"bank-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {bankItem && bankItem.id}
                </th>
                <td>
                  {bankItem.name}
                  {bankItem.accronym ? ` (${bankItem.accronym})` : ""}
                </td>
                <td align="center">
                  {bankItem.country ? bankItem.country.name : "-"}
                </td>
                <td align="center">
                  {bankItem.swiftCode ? bankItem.swiftCode : "-"}
                </td>
                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit bank"
                      size="small"
                      onClick={() => setForUpdate(bankItem)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="change bank status"
                      size="small"
                      onClick={() => {
                        setDeleteBank({
                          id: bankItem.id,
                          isActive: bankItem.isActive,
                        });
                      }}
                    >
                      {bankItem.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createBank || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Bank" : "Add New Bank"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            {createBank && (
              <TextField
                id="outlined-basic"
                label="Bank id"
                variant="outlined"
                size="small"
                className="w-100"
                disabled={loading || loadingData}
                value={newBankId}
                onChange={(e) => {
                  setNewBankId(e.target.value);
                  setFormError(null);
                }}
              />
            )}
            {formError && formError.element === "BANK_ID" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <TextField
              id="outlined-basic"
              label="Bank name"
              variant="outlined"
              size="small"
              className={`w-100 ${createBank ? "mt-3" : ""}`}
              disabled={loading || loadingData}
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
                setFormError(null);
              }}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <TextField
              id="outlined-basic"
              label="Bank acronym"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              disabled={loading || loadingData}
              value={bankAcronym}
              onChange={(e) => {
                setBankAcronym(e.target.value);
                setFormError(null);
              }}
            />
            {formError && formError.element === "ACRONYM" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <TextField
              id="outlined-basic"
              label="Swift code"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              disabled={loading || loadingData}
              value={swiftCode}
              onChange={(e) => {
                setSwiftCode(e.target.value);
                setFormError(null);
              }}
            />
            {formError && formError.element === "SWIFT_CODE" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Country list */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, country) => (
                <Box component="li" {...props}>
                  {country?.name}
                </Box>
              )}
              options={adminCountries}
              size="small"
              value={selectedCountry || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "country",
                  }}
                />
              )}
              onChange={(event, country) => {
                setSelectedCountry(country || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "COUNTRY" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}

      {deleteBank && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !deleteBank.isActive ? "activate" : "de-activate"
          } this bank?`}
          setConfirmationDialog={() => setDeleteBank(null)}
          onYes={handleChangeStatus}
        />
      )}
    </div>
  );
};

Banks.propTypes = {
  createBank: PropTypes.bool,
  setCreateBank: PropTypes.func,
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    adminBanks: adminPayroll.adminBanks,
    adminCountries: adminPayroll.adminCountries,
  };
};

const mapDispatchToProps = {
  getAdminCreditor,
  addNewBank,
  updateBank,
  activateBank,
  getAdminBank,
  getAdminCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banks);
