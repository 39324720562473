import React, { useState, useEffect } from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import ArchivedAppeals from "../components/ArchivedAppeals";
import InProgressAppeals from "../components/InProgressAppeals";
import OverdueAppeals from "../components/OverdueAppeals";

const AppealDialog = (props) => {
  const {
    showAppeal,
    setShowAppeal,
    selectedAdvertisement,
    category,
    hrCanViewAppealedOnSecondLevel,
  } = props;

  const onClose = () => {
    setShowAppeal(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAppeal}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span style={{ color: "rgb(7, 142, 206)" }} className="mr-1">
              Appeals
            </span>
            <span className="text-dark mr-1">@</span>
            <span className="text-success">
              {selectedAdvertisement && selectedAdvertisement.positionName}
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "650px" }}>
          {category === "InProgressAppeals" && (
            <InProgressAppeals
              onClose={onClose}
              hrCanViewAppealedOnSecondLevel={hrCanViewAppealedOnSecondLevel}
              selectedAdvertisement={selectedAdvertisement}
            />
          )}
          {category === "ArchivedAppeals" && (
            <ArchivedAppeals
              onClose={onClose}
              selectedAdvertisement={selectedAdvertisement}
            />
          )}

          {category === "OverdueAppeals" && (
            <OverdueAppeals
              onClose={onClose}
              selectedAdvertisement={selectedAdvertisement}
              hrCanViewAppealedOnSecondLevel={hrCanViewAppealedOnSecondLevel}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppealDialog;
