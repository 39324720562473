import Identity from "./Identity";
import Certificates from "./Certificates";
import ProfileSummary from "./ProfileStatus";
import { Typography } from "@mui/material";
import defaultProfile from "../../../assets/default-profile.jpg";
import axios from "axios";
import { useEffect, useState } from "react";
import Educations from "./Educations";
import Experiences from "./Experiences";
import Disabilities from "./Disabilities";
import Languages from "./Languages";
import Publications from "./Publications";
import Referees from "./Referees";
import CVUpload from "./CVUpload";
import { useDispatch } from "react-redux";
const UserResume = (props) => {
  const { application } = props;

  const [userProfilePicture, setUserProfilePicture] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SET_APPLICATION_CV", data: [] });
    dispatch({ type: "SET_APPLICATION_REFEREES", data: [] });
    dispatch({ type: "SET_APPLICATION_PUBLICATIONS", data: [] });
    dispatch({ type: "SET_APPLICATION_LANGUAGES", data: [] });
    dispatch({ type: "SET_APPLICATION_DISABILITIES", data: [] });
    dispatch({ type: "SET_APPLICATION_EXPERIENCES", data: [] });
    dispatch({ type: "SET_APPLICATION_EDUCATIONS", data: [] });
    dispatch({ type: "SET_APPLICATION_PROFILE_STATUS", data: {} });
  }, [application]);

  useEffect(() => {
    getUserProfilePicture(application.idNumber || "");
  }, []);

  const getUserProfilePicture = async (idNumber) => {
    try {
      const { data } = await axios.get(
        "/api/auth/user-profile-picture/" + idNumber
      );

      setUserProfilePicture(data.photo);
    } catch (error) {}
  };

  return (
    <>
      <div className="row mx-0 mt-4">
        <div className="col-12 mb-3 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
          <div className="d-flex align-items-center justify-content-center w-100 rounded mb-4">
            <img
              src={
                userProfilePicture
                  ? `data:image/png;base64,${userProfilePicture}`
                  : defaultProfile
              }
              className="img-fluid rounded img-thumbnail mr-1"
              style={{
                display: "block",
                maxHeight: "192px",
              }}
              alt={``}
            />
          </div>
          <ProfileSummary application={application} />
        </div>
        <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-sm-0 px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary bg-light ">
            <div className="card-header bg-light rounded rounded-top font-weight-bold text-uppercase">
              <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between ">
                <Typography variant="h6">Profile</Typography>
                <Typography variant="h6" className="text-primary">
                  Id:{application?.userId}
                </Typography>
              </div>
            </div>
            <div className="card-body px-2 px-sm-3">
              <Identity application={application} />
              <Educations applicationId={application?.id || ""} />
              <Experiences applicationId={application?.id || ""} />
              <Certificates applicationId={application?.id || ""} />
              <Disabilities applicationId={application?.id || ""} />
              <Languages applicationId={application?.id || ""} />
              <Publications applicationId={application?.id || ""} />
              <Referees applicationId={application?.id || ""} />
              <CVUpload applicationId={application?.id || ""} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserResume;
