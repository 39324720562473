import React from "react";
import { IconButton } from "@mui/material";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

const ClickableTD = (props) => {
  const { disabled, borderBottom, borderRight = "", onClick, children } = props;

  return (
    <td
      className={`text-center ${
        +children > 0 && !disabled ? "td-clickable" : ""
      }`}
      style={{ borderLeft: "3px solid #dee2e6", borderBottom, borderRight }}
      onClick={() => +children > 0 && !disabled && onClick()}
    >
      {children || "-"}
      {+children > 0 && (
        <div className={`float-right td-clickable-icon`}>
          <IconButton aria-label="view positions" size="small">
            <RemoveRedEyeRoundedIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
    </td>
  );
};

export default ClickableTD;
