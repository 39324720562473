import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { updateUploadedExam } from "../../../store/recruitment/actions";

import { showError } from "../../../app/toastify";
import ActionDialog from "../../common/components/ActionDialog";
import ErrorMessage from "../../common/components/ErrorMessage";

const EditUploadedExamDialog = (props) => {
  const {
    loading,
    updateUploadedExam,
    question,
    showEditQuestion,
    setShowEditQuestion,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);

  const [questionForm, setQuestionForm] = useState({
    id: question ? question.id : 0,
    description: question ? question.description : "",
    assertionA: question && question.assertionA ? question.assertionA : "",
    assertionB: question && question.assertionB ? question.assertionB : "",
    assertionC: question && question.assertionC ? question.assertionC : "",
    assertionD: question && question.assertionD ? question.assertionD : "",
    marks: question && question.marks ? question.marks : 0,
  });

  const [errors, setErrors] = useState({
    descriptionHasError: false,
    assertionAHasError: false,
    assertionBHasError: false,
    assertionCHasError: false,
    assertionDHasError: false,
    marksHasError: false,
  });

  const formValidator = () => {
    const error = {
      descriptionHasError: false,
      assertionAHasError: false,
      assertionBHasError: false,
      assertionCHasError: false,
      assertionDHasError: false,
      marksHasError: false,
    };

    if (!questionForm.description) {
      error.descriptionHasError = true;
      error.hasError = true;
    }
    if (!questionForm.marks || questionForm.marks <= 0) {
      error.marksHasError = true;
      error.hasError = true;
    }

    if (question.questionTypeId === 1 && !questionForm.assertionA) {
      error.assertionAHasError = true;
      error.hasError = true;
    }

    if (question.questionTypeId === 1 && !questionForm.assertionB) {
      error.assertionBHasError = true;
      error.hasError = true;
    }

    if (question.questionTypeId === 1 && !questionForm.assertionC) {
      error.assertionCHasError = true;
      error.hasError = true;
    }

    if (question.questionTypeId === 1 && !questionForm.assertionD) {
      error.assertionDHasError = true;
      error.hasError = true;
    }
    if (
      question.questionTypeId === 1 &&
      !(
        questionForm.assertionA.trim().charAt(0) === "*" ||
        questionForm.assertionB.trim().charAt(0) === "*" ||
        questionForm.assertionC.trim().charAt(0) === "*" ||
        questionForm.assertionD.trim().charAt(0) === "*"
      )
    ) {
      showError("No correct answer could be found!");
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = async () => {
    if (!formValidator()) {
  
      updateUploadedExam(questionForm, onClose, setIsUpdating);
    }
  };

  const onClose = () => {
    setQuestionForm({
      id: 0,
      description: "",
      assertionA: "",
      assertionB: "",
      assertionC: "",
      assertionD: "",
      marks: 0,
    });
    setShowEditQuestion(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditQuestion}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Edit Question
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <div className="row">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="description"
                  autoFocus
                  label="Type your question .."
                  variant="outlined"
                  placeholder="Type your question  .."
                  value={questionForm.description}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const description = e.target.value;
                    setQuestionForm({
                      ...questionForm,
                      description,
                    });
                    const descriptionHasError = false;
                    setErrors({ ...errors, descriptionHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.descriptionHasError}
                  message="Question is required"
                />
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  name="marks"
                  autoFocus
                  label="Marks"
                  variant="outlined"
                  placeholder="Marks"
                  value={questionForm.marks}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const marks = e.target.value;
                    setQuestionForm({
                      ...questionForm,
                      marks,
                    });
                    const marksHasError = false;
                    setErrors({ ...errors, marksHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.marksHasError}
                  message="Marks is required or equal to zero"
                />
              </div>
            </div>
            {question.questionTypeId === 1 && (
              <>
                <div className="row  mt-3">
                  <div
                    className="col-2"
                    style={{
                      paddingRight: "5px!important",
                      paddingLeft: "5px!important",
                    }}
                  >
                    <div className="opt-one answer__opt">
                      <h4>A</h4>
                    </div>
                  </div>
                  <div className="col-10">
                    <TextField
                      fullWidth
                      size="small"
                      name="assertionA"
                      autoFocus
                      label="Option A"
                      variant="outlined"
                      placeholder="Option A"
                      value={questionForm.assertionA}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const assertionA = e.target.value.trim();
                        setQuestionForm({
                          ...questionForm,
                          assertionA,
                        });
                        const assertionAHasError = false;
                        setErrors({ ...errors, assertionAHasError });
                      }}
                    />
                    <ErrorMessage
                      hasError={errors.assertionAHasError}
                      message="Option A is required"
                    />
                  </div>
                </div>

                <div className="row  mt-3">
                  <div
                    className="col-2"
                    style={{
                      paddingRight: "5px!important",
                      paddingLeft: "5px!important",
                    }}
                  >
                    <div className="opt-one answer__opt">
                      <h4>B</h4>
                    </div>
                  </div>
                  <div className="col-10">
                    <TextField
                      fullWidth
                      size="small"
                      name="assertionB"
                      autoFocus
                      label="Option B"
                      variant="outlined"
                      placeholder="Option B"
                      value={questionForm.assertionB}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const assertionB = e.target.value.trim();
                        setQuestionForm({
                          ...questionForm,
                          assertionB,
                        });
                        const assertionBHasError = false;
                        setErrors({ ...errors, assertionBHasError });
                      }}
                    />
                    <ErrorMessage
                      hasError={errors.assertionBHasError}
                      message="Option B is required"
                    />
                  </div>
                </div>

                <div className="row  mt-3">
                  <div
                    className="col-2"
                    style={{
                      paddingRight: "5px!important",
                      paddingLeft: "5px!important",
                    }}
                  >
                    <div className="opt-one answer__opt">
                      <h4>C</h4>
                    </div>
                  </div>
                  <div className="col-10">
                    <TextField
                      fullWidth
                      size="small"
                      name="assertionC"
                      autoFocus
                      label="Option C"
                      variant="outlined"
                      placeholder="Option C"
                      value={questionForm.assertionC}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const assertionC = e.target.value.trim();
                        setQuestionForm({
                          ...questionForm,
                          assertionC,
                        });
                        const assertionCHasError = false;
                        setErrors({ ...errors, assertionCHasError });
                      }}
                    />
                    <ErrorMessage
                      hasError={errors.assertionBHasError}
                      message="Option C is required"
                    />
                  </div>
                </div>

                <div className="row  mt-3">
                  <div
                    className="col-2"
                    style={{
                      paddingRight: "5px!important",
                      paddingLeft: "5px!important",
                    }}
                  >
                    <div className="opt-one answer__opt">
                      <h4>D</h4>
                    </div>
                  </div>
                  <div className="col-10">
                    <TextField
                      fullWidth
                      size="small"
                      name="assertionD"
                      autoFocus
                      label="Option D"
                      variant="outlined"
                      placeholder="Option D"
                      value={questionForm.assertionD}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const assertionD = e.target.value.trim();
                        setQuestionForm({
                          ...questionForm,
                          assertionD,
                        });
                        const assertionDHasError = false;
                        setErrors({ ...errors, assertionDHasError });
                      }}
                    />
                    <ErrorMessage
                      hasError={errors.assertionDHasError}
                      message="Option D is required"
                    />
                  </div>
                </div>
              </>
            )}

            {isUpdating && (
              <ActionDialog showAction={isUpdating} action="Updating ..." />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {isUpdating ? "Updating..." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  updateUploadedExam,
})(EditUploadedExamDialog);
