import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import {
  addNewJob,
  deleteJob,
  downloadAllEmployeesPerJob,
} from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { CSVLink } from "react-csv";

const ActiveButton = {
  borderRadius: "8px",
  border: `1px solid rgb(7, 142, 206)`,
  height: "25px",
  color: "#fff",
  alignItems: "center",
  fontSize: ".70rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AllJobsDialog = (props) => {
  const {
    showAllJobsDialog,
    setShowAllJobsDialog,
    jobs,
    jobFields,
    addNewJob,
    deleteJob,
    loading,
    downloadAllEmployeesPerJob,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([...jobs]);

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  // const getFilteredJobs = () => {
  //   if (jobs.length && !!searchTerm)
  //     return jobs.filter(({ name }) =>
  //       name
  //         .toLowerCase()
  //         .replace(/ /g, "")
  //         .includes(searchTerm.toLowerCase().replace(/ /g, ""))
  //     );

  //   else return jobs;
  // };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredJobs);
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredJobs.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (idToSearch) => jobs.findIndex(({ id }) => id === idToSearch);

  const [showAdd, setShowAdd] = useState(false);
  const [confirmRemoveJob, setConfirmRemoveJob] = useState(false);
  const [confirmRemoveJobField, setConfirmRemoveJobField] = useState(false);

  const [job, setJob] = useState({
    id: null,
    name: "",
    jobFieldId: "",
  });

  useEffect(() => {
    const filtered = jobs.filter(({ name, jobField }) =>
      (name + (jobField?.name || ""))
        .toLowerCase()
        .replace(/ /g, "")
        .includes(searchTerm.toLowerCase().replace(/ /g, ""))
    );

    setFilteredJobs(filtered);

    paginate(filtered);

    // const previousElement = document.getElementsByClassName("previous");

    // console.log(previousElement[0]);

    //handlePageClick({ selected: Math.ceil(jobs.length / itemsPerPage) - 1 });

    // if (!!previousElement) previousElement.click();
  }, [searchTerm, jobs]);

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (!!job.id) document.getElementById("downloadBTN").click();
  }, [employees]);

  return (
    <>
      <Dialog
        onClose={() => setShowAllJobsDialog(false)}
        open={showAllJobsDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="badge badge-secondary p-2 mr-1">
              {filteredJobs.length}
            </span>{" "}
            Jobs available
            <Button
              onClick={() => setShowAdd(true)}
              style={{
                ...ActiveButton,
              }}
              size="small"
              className="ml-2 "
            >
              <span className="material-icons">add</span> New
            </Button>
          </Typography>
          <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={() => setShowAllJobsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="pt-2 pb-0">
          <div
            className=" rounded "
            style={{
              // backgroundColor: "#f9f9f9",
              // border: "1px solid #17a2b8",
              minHeight: "70vh",
            }}
          >
            <SearchBox
              disabled={false}
              onSearch={onSearch}
              placeholder="Search…"
            />
            <CSVLink
              data={employees}
              filename={`${job?.name}.csv`}
              className="hidden"
              id="downloadBTN"
              target="_blank"
            />
            {!!paginatedItems && (
              <List
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  border: `1px solid #c8c6c6`,
                }}
                dense
                className="bg-light mt-2"
              >
                {paginatedItems.map((jobI, index) => (
                  <React.Fragment key={jobI.id}>
                    <Tooltip title={jobI.name} placement="top">
                      <ListItem
                        style={{ cursor: "default" }}
                        secondaryAction={
                          <span className="d-flex align-items-center">
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm py-1 px-1"
                              disabled={loading}
                              onClick={() => {
                                setJob(jobI);
                                downloadAllEmployeesPerJob(
                                  jobI,
                                  setJob,
                                  setEmployees
                                );
                              }}
                            >
                              {(!loading || job?.id !== jobI.id) && (
                                <span className="material-icons">
                                  cloud_download
                                </span>
                              )}

                              {loading && job?.id === jobI.id && (
                                <i
                                  className="fas fa-spinner spinner "
                                  style={{ fontSize: "24px" }}
                                ></i>
                              )}
                            </button>

                            <span
                              style={{
                                backgroundColor: "#fff",
                                border: "1px solid #c8c6c6",
                              }}
                              className="p-1 rounded ml-2"
                            >
                              <IconButton
                                size="small"
                                edge="end"
                                color="primary"
                                onClick={() => {
                                  setJob(jobI);
                                  setShowAdd(true);
                                }}
                              >
                                <span className="material-icons">edit</span>
                              </IconButton>
                              <IconButton
                                size="small"
                                edge="end"
                                color="error"
                                onClick={() => {
                                  setJob(jobI);
                                  setConfirmRemoveJob(true);
                                }}
                              >
                                <span className="material-icons">
                                  delete_outline
                                </span>
                              </IconButton>
                            </span>
                          </span>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              fontSize: "16px",
                              border: "1px solid #c8c6c6",
                              backgroundColor: "#fff",
                            }}
                            className=" text-dark "
                          >
                            {getNo(jobI.id) + 1}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span
                              className="text-truncate d-block"
                              style={{ maxWidth: "85%" }}
                            >
                              {jobI.name}
                            </span>
                          }
                          secondary={
                            <span>
                              {!jobI.jobField && <span> Field: N/A </span>}

                              {!!jobI.jobField && (
                                <span>
                                  Field:
                                  <Chip
                                    component="span"
                                    size="small"
                                    className="ml-1"
                                    label={jobI.jobField.name}
                                    onDelete={() => {
                                      setJob({
                                        id: jobI.id,
                                        name: jobI.name,
                                        jobFieldId: null,
                                      });
                                      setConfirmRemoveJobField(true);
                                    }}
                                  />
                                </span>
                              )}
                            </span>
                          }
                        />
                      </ListItem>
                    </Tooltip>

                    {paginatedItems.length > index + 1 && <Divider />}
                  </React.Fragment>
                ))}
                {!paginatedItems.length && (
                  <div className="text-center">No jobs found</div>
                )}
              </List>
            )}
          </div>

          {showAdd && (
            <AddNewJob
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              addNewJob={addNewJob}
              loading={loading}
              job={job}
              setJob={setJob}
              jobFields={jobFields}
            />
          )}

          {confirmRemoveJob && !!job.id && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveJob}
              message={`REMOVE "${job.name.toUpperCase()}"?`}
              setConfirmationDialog={setConfirmRemoveJob}
              onYes={() => {
                deleteJob(job.id, setConfirmRemoveJob, setJob);
              }}
            />
          )}

          {confirmRemoveJobField && !!job.id && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveJobField}
              message={`Remove Career  field from "${job.name}"?`}
              setConfirmationDialog={setConfirmRemoveJobField}
              onYes={() => {
                addNewJob(job, setConfirmRemoveJobField, setJob);
              }}
            />
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <span>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </span>
            }
            previousLabel={
              <span>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1 d-none d-md-inline">Previous</span>
              </span>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, jobs, jobFields }) => {
  return {
    loading,
    jobs,
    jobFields,
  };
};

export default connect(mapStateToProps, {
  addNewJob,
  deleteJob,
  downloadAllEmployeesPerJob,
})(AllJobsDialog);

const AddNewJob = (props) => {
  const { showAdd, setShowAdd, loading, addNewJob, job, setJob, jobFields } =
    props;

  const onSave = () => {
    addNewJob(job, setShowAdd, setJob);
  };

  const [jobField, setJobField] = useState(null);

  const onClose = () => {
    setShowAdd(false);
    setJob({ id: null, name: "", jobFieldId: "" });
  };

  useEffect(() => {
    if (!!job.jobFieldId)
      setJobField(jobFields.find(({ id }) => id === job.jobFieldId));
    else setJobField(null);
  }, [job]);

  return (
    <Dialog onClose={onClose} open={showAdd} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          {!!job.id ? "Edit" : "Add new"} job
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          size="small"
          name="jobName"
          className="mt-3"
          fullWidth
          label="Type job name"
          placeholder="Type job name"
          variant="outlined"
          value={job.name.toUpperCase()}
          onChange={(e) => {
            setJob({ ...job, name: e.target.value || "" });
          }}
        />
        <Autocomplete
          size="small"
          id="jobField"
          name="jobField"
          defaultValue={null}
          value={jobField}
          options={jobFields}
          className="mt-3"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, jobField) => {
            setJobField(jobField || null);

            const jobFieldId = jobField?.id || null;

            setJob({
              ...job,
              jobFieldId,
            });
          }}
          getOptionLabel={(option) => option.name?.toUpperCase()}
          renderOption={(props, jobField) => (
            <Box component="li" {...props}>
              {jobField.name?.toUpperCase()}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="--Select Career  field--"
              placeholder="--Select Career  field--"
              inputProps={{
                ...params.inputProps,
                autoComplete: "jobField",
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions className="d-flex justify-content-center ">
        <button
          type="button"
          className="btn btn-primary text-uppercase ml-2"
          disabled={loading}
          onClick={onSave}
        >
          {loading ? "Wait..." : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
