import Educations from "./components/Educations";
import Identity from "./components/Identity";
import Experiences from "./components/Experiences";
import Certificates from "./components/Certificates";
import Languages from "./components/Languages";
import Disabilities from "./components/Disabilities";
import Referees from "./components/Referees";
import ProfileSummary from "./components/ProfileStatus";
import { Button, Typography } from "@mui/material";
import CVUpload from "./components/CVUpload";
import Publications from "./components/Publications";
import { connect, useDispatch } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";
import axios from "axios";
import { useEffect, useState } from "react";
import types from "../../store/common/action-types";

const Profile = (props) => {
  const { loading, user, staff, userProfile } = props;

  const [userProfilePicture, setUserProfilePicture] = useState(null);
  const dispatch = useDispatch();
  dispatch({
    type: types.SET_USER_PROFILE,
    data: user,
  });

  useEffect(() => {
    if (!staff) getUserProfilePicture(user?.idNumber || "");
    else getUserProfilePicture(staff.idNumber || "");
  }, []);

  const getUserProfilePicture = async (idNumber) => {
    try {
      const { data } = await axios.get(
        "/api/auth/user-profile-picture/" + idNumber
      );

      setUserProfilePicture(data.photo);
    } catch (error) {}
  };

  return (
    <>
      <div className="row mx-0 mt-4">
        <div className="col-12 mb-3 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
          <div className="d-flex align-items-center justify-content-center w-100 rounded mb-4">
            <img
              src={
                userProfilePicture
                  ? `data:image/png;base64,${userProfilePicture}`
                  : defaultProfile
              }
              className="img-fluid rounded img-thumbnail mr-1"
              style={{
                display: "block",
                maxHeight: "192px",
              }}
              alt={``}
            />
          </div>
          <ProfileSummary canEdit={true} />
        </div>
        <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-sm-0 px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary bg-light ">
            <div className="card-header rounded bg-light rounded-top font-weight-bold text-uppercase">
              <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between ">
                <Typography variant="h6"> My Profile</Typography>
                <Typography variant="h6" className="text-primary">
                  Id:{userProfile?.id}
                </Typography>
              </div>
            </div>
            <div className="card-body px-2 px-sm-3">
              <Identity canEdit={true} allowedToEditEmail={false} />
              <Educations canEdit={true} />
              <Experiences canEdit={true} allowedToEditEmploymentDate={false} />
              <Certificates canEdit={true} />
              <Disabilities canEdit={true} />
              <Languages canEdit={true} />
              <Publications canEdit={true} />
              <Referees canEdit={true} />
              <CVUpload showEdit canEdit={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ loading, user, userProfile }) => {
  return { loading, user, userProfile };
};
export default connect(mapStateToProps, {})(Profile);
