import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Fault from "./pages/Fault";
import Sanction from "./pages/Sanction";
import AllCases from "./pages/AllCases";

import MenuBar from "./components/MenuBar";

const Layout = () => {
  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/discipline/dashboard" component={Dashboard} />
        <Route path="/discipline/fault" component={Fault} />
        <Route path="/discipline/sanction" component={Sanction} />
        <Route
          path="/discipline/in-progress-cases"
          component={AllCases}
        />
          {/* <Route
          path="/discipline/in-sanction-cases"
          component={AllCases}
        /> */}
          <Route
          path="/discipline/other-cases"
          component={AllCases}
        />
        <Route
          children={() => <Redirect    
            to={{
            pathname: "/discipline/in-progress-cases",
            state: { statusCode: 'IN_PROGRESS_CASES' },
          }}/>}
          path="/discipline"
        />
      </Switch>
    </React.Fragment>
  );
};

export default Layout;
