import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
  CardContent,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line, Doughnut, Pie } from "react-chartjs-2";
import { currencyFormat } from "../../common/components/Utils";
import {
  downloadBarometerData,
  getPayrollDashboardData,
  getPayrollPeriods,
} from "../../../store/payroll/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import { getSectors } from "../../../store/admin/actions";
import { getEmployeeGroups } from "../../../store/structure/actions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Barometer = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Dashboard";
  });

  const {
    loading,
    getPayrollPeriods,
    getSectors,
    employeeGroups,
    getEmployeeGroups,
    downloadBarometerData,
  } = props;
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState(null);
  const [sectors, setSectors] = useState([]);
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    if (!sectors[0]) getSectors(setSectors);
    if (!periods[0]) getPayrollPeriods(setPeriods);
    if (!employeeGroups.length) getEmployeeGroups();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar className="bg-light border">
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>BAROMETER DATA</span>
              </Typography>

              <Autocomplete
                sx={{ width: 230 }}
                className="ml-4"
                size="small"
                disablePortal
                options={sectors || []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Sectors"
                    placeholder="Sectors"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(e, sector) => setSelectedSector(sector)}
                disabled={loading}
              />

              <Autocomplete
                sx={{ width: 200 }}
                className="ml-2"
                size="small"
                disablePortal
                options={employeeGroups || []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Employee Groups"
                    placeholder="Employee Groups"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                onChange={(e, item) => setSelectedEmployeeGroup(item)}
                disabled={loading}
              />

              <Autocomplete
                sx={{ width: 120 }}
                className="ml-2"
                size="small"
                disablePortal
                value={selectedPeriod}
                options={(periods || []).map((period) => ({
                  id: period.periodId,
                  name: period.periodId,
                }))}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                onChange={(e, period) => setSelectedPeriod(period)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Year"
                    placeholder="Year"
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                disabled={loading}
              />

              <Button
                variant="contained"
                className="ml-2"
                color="primary"
                onClick={() =>
                  downloadBarometerData({
                    sectorId: selectedSector?.id,
                    periodId: selectedPeriod?.id,
                    employeeGroupId: selectedEmployeeGroup?.id,
                  })
                }
                disabled={
                  loading ||
                  !selectedSector ||
                  !selectedPeriod ||
                  !selectedEmployeeGroup
                }
              >
                <span className="material-icons">download</span>
                Download
              </Button>
            </Toolbar>
          </AppBar>

          <div className="px-4 pt-2">
            <div
              className="d-flex  align-items-center justify-content-center flex-column"
              style={{
                height: "50vh",
                // backgroundColor: "#f3f4f6",
              }}
            >
              <span className="lead">
                <span
                  className="material-icons text-secondary"
                  style={{ fontSize: "64px" }}
                >
                  download_for_offline
                </span>
              </span>

              <small className="text-primary">Payroll balometer</small>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, employeeGroups }) => {
  return { user, loading, employeeGroups };
};
export default connect(mapStateToProps, {
  getSectors,
  getPayrollPeriods,
  getEmployeeGroups,
  downloadBarometerData,
})(Barometer);
