import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getApplicationDisabilities } from "../../../../store/profile/actions";
import NoResults from "../../../common/components/NoResults";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import LockIcon from "@mui/icons-material/Lock";
import { showError } from "../../../toastify";
import moment from "moment";

export const Disabilities = (props) => {
  const {
    env,
    loading,
    onWait,
    applicationDisabilities,
    getApplicationDisabilities,
    applicationId,
  } = props;

  useEffect(() => {
    getApplicationDisabilities(applicationId);
  }, []);
  const [loadingDocument, setLoadingDocument] = useState(false);
  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="disabilities">
        <CardContent>
          {loading && !onWait && !applicationDisabilities.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography variant="h5" style={{ color: "#078ece" }}>
                    Disability
                  </Typography>
                </div>
              </div>

              {applicationDisabilities.length === 0 ? (
                <NoResults />
              ) : (
                applicationDisabilities.map((disability, index) => (
                  <DisabilityItemCard
                    key={disability.id}
                    index={index}
                    disability={disability}
                    loading={loading}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  env,
  loading,
  onWait,
  applicationDisabilities,
  lookup,
}) => ({
  env,
  loading,
  onWait,
  applicationDisabilities,
  lookup,
});

export default connect(mapStateToProps, {
  getApplicationDisabilities,
})(Disabilities);

const DisabilityItemCard = (props) => {
  const { index, env, loading, disability } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          title={<Typography>{disability?.disability?.name}</Typography>}
          subheader={
            <>
              {disability.disabilityId !== 1 && (
                <>
                  Level of disability:{" "}
                  <span className="btn-link">
                    {disability?.disabilityLevel?.name}{" "}
                  </span>
                </>
              )}
              <p
                className="small text-muted mb-0 rounded-3 mt-2 float-end mr-2"
                style={{
                  fontSize: "11px",
                }}
              >
                Updated on, {moment(disability.createdOn).format("ll")}
              </p>
            </>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                {disability.disabilityId !== 1 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (disability.docReferenceId) {
                        setOnViewDocument(true);
                      } else {
                        showError("Document is not available");
                      }
                    }}
                  >
                    <FilePresentIcon
                      // fontSize="large"
                      style={{
                        color: !!disability.docReferenceId
                          ? "#fe4066"
                          : "#c57c7cb8",
                      }}
                    />
                  </IconButton>
                )}
                {disability.locked && (
                  <IconButton
                    disableRipple
                    size="small"
                    className=""
                    aria-label="settings"
                  >
                    <LockIcon />
                  </IconButton>
                )}
              </div>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `disability document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              disability.docReferenceId,
          }}
        />
      )}
    </>
  );
};
