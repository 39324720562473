import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, AlertTitle } from "@mui/material";

const NoRecordMessage = (props) => {
  const { isEmpty, title, message, onClickLink, link, textLink } = props;

  return (
    <>
      {isEmpty && (
        // <Alert severity="info" className="mt-3 mb-3">
        //   <AlertTitle>{title && title}</AlertTitle>
        //   {message && <span>{message}</span>}{" "}
        //   <strong>
        //     {textLink && onClickLink && (
        //       <a onClick={onClickLink} className="text--link">
        //         {textLink}
        //       </a>
        //     )}
        //     {textLink && link && (
        //       <a href={link} target="_blank" className="text--link">
        //         {textLink}
        //       </a>
        //     )}
        //   </strong>
        // </Alert>

        <div className="jumbotron jumbotron-fluid text-center">
          <div className="container">
            <p className="lead">{title}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NoRecordMessage;
