import { Autocomplete, Box, Button, Checkbox, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  getSearchedInvigilator,
  addInvigilator,
  getExamCenters,
} from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import AddIcon from "@mui/icons-material/Add";
import RECRUITMENT_ACTION_TYPE from "../../../store/recruitment/action-types";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { showError } from "../../toastify";
import ErrorMessage from "../../common/components/ErrorMessage";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AddInvigilator = (props) => {
  const {
    setShowInvigilator,
    selectedExam,
    searchedInvigilator,
    getSearchedInvigilator,
    addInvigilator,
    getExamCenters,
    examCenters,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [examCenter, setExamCenter] = useState(
    examCenters.length === 1 ? examCenters[0] : null
  );
  const [isSearching, setIsSearching] = useState(false);
  const [isAddingInvigilator, setIsAddingInvigilator] = useState(false);

  const [errors, setErrors] = useState({
    examCenterError: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_SEARCHED_INVIGILATORS",
      data: null,
    });
    dispatch({
      type: "SET_EXAM_CENTERS",
      data: [],
    });
  }, []);

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  useEffect(
    () => {
      getExamCenters(selectedExam.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedExam]
  );
  const onSubmit = () => {
    if (searchTeam)
      getSearchedInvigilator(
        {
          searchTerm: searchTeam,
          examId: selectedExam.id,
          advertisementId: selectedExam.advertisementId,
        },
        setIsSearching
      );
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <SearchBox
          disabled={isSearching || isAddingInvigilator}
          onSearch={onSearch}
          placeholder="Enter user email or phone number…"
        />
        <Button
          className="ml-2 px-1"
          disabled={isSearching || isAddingInvigilator}
          onClick={() => onSubmit()}
          style={{
            ...ActiveButton,
            float: "right",
          }}
          size="md"
        >
          {isSearching ? (
            <>
              {" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            "Search"
          )}
        </Button>

        <Button
          className="ml-2"
          disabled={isSearching || isAddingInvigilator}
          onClick={() => {
            setSearchTeam("");
            setShowInvigilator(false);
            dispatch({
              type: RECRUITMENT_ACTION_TYPE.SET_SEARCHED_INVIGILATORS,
              data: null,
            });
          }}
          style={{
            ...InactiveButton,
            // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
          }}
          size="md"
        >
          <TrendingFlatIcon />
        </Button>
      </div>

      <PreLoader className="mt-3" isLoading={isSearching} />

      <NoRecordMessage
        isEmpty={!searchedInvigilator && !isSearching}
        title={"Please search by email or phone number for existing user!"}
      />

      {!isSearching && searchedInvigilator && (
        <div className="mt-3">
          <div className="card-body d-flex justify-content-center flex-wrap pe-0">
            <table className="rounded">
              <tbody>
                <tr>
                  <th scope="row">Names</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedInvigilator.lastName}{" "}
                      {searchedInvigilator.firstName}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Email</th>
                  <td>
                    {" "}
                    <span style={{ fontSize: "14px" }}>
                      {searchedInvigilator.email}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">PhoneNumber</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedInvigilator.phoneNumber}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">IDNumber</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedInvigilator.idNumber}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Assign Center</th>
                  <td>
                    <Autocomplete
                      size="small"
                      id="examCenter"
                      defaultValue={null}
                      value={examCenter}
                      options={examCenters}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, examCenter) => {
                        setExamCenter(examCenter || null);
                        setErrors({
                          ...errors,
                          examCenterError: false,
                        });
                      }}
                      getOptionLabel={(option) => option?.center?.name}
                      renderOption={(props, examCenter) => (
                        <Box component="li" {...props}>
                          {examCenter?.center?.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required={true}
                          {...params}
                          label="Select center"
                          placeholder="Select center"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "examCenter", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      hasError={errors.examCenterError}
                      message="center is required"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-center">
                    {" "}
                    <button
                      disabled={isAddingInvigilator}
                      onClick={() => {
                        let examCenterError = false;
                        if (!examCenter) {
                          examCenterError = true;
                        }
                        setErrors({
                          ...errors,
                          examCenterError,
                        });
                        if (!examCenter) return showError("Center is required");

                        addInvigilator(
                          {
                            userId: searchedInvigilator.id,
                            examId: selectedExam.id,
                            examCenterId: examCenter?.id,
                          },
                          setIsAddingInvigilator,
                          setShowInvigilator,
                          setSearchTeam,
                          setExamCenter
                        );
                      }}
                      className="btn btn-primary"
                    >
                      {isAddingInvigilator ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, examCenters, searchedInvigilator }) => {
  return {
    loading,
    examCenters,
    searchedInvigilator,
  };
};
export default connect(mapStateToProps, {
  getSearchedInvigilator,
  addInvigilator,
  getExamCenters,
})(AddInvigilator);

// export default AddInvigilator;
