import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import PdfViewer from "../../common/components/PdfViewer";
import {updateRecruitmentRequestAttachment} from "../../../store/recruitment/actions";
import { showError } from "../../toastify";
const ViewRecruitmentAttachments = (props) => {
  const { attachments, loading,canEdit,updateRecruitmentRequestAttachment,onWait } = props;

  const [showDocument, setShowDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [editFile, setEditFile] = useState(false);
  const [filteredAttachments, setFilteredAttachments] = useState([...attachments]);
  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            {showDocument && selectedDocument && (
              <PreviewPdfUrl
                showDocument={showDocument}
                setShowDocument={setShowDocument}
                document={{
                  name: `View ${selectedDocument.attachmentName}`,
                  url:process.env.REACT_APP_FILES_URL +'/preview/'+ selectedDocument.docReferenceId,
                }}
              />
            )}
               {editFile && (
              <UpdateAttachmentFile
                editFile={editFile}
                setEditFile={setEditFile}
                setSelectedDocument={setSelectedDocument}
                selectedDocument={selectedDocument}
                updateRecruitmentRequestAttachment={updateRecruitmentRequestAttachment}
                onWait={onWait}
                loading={loading}
                setFilteredAttachments={setFilteredAttachments}
              />
            )}
            
            <table className="table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    #
                  </th>
                  <th scope="col" className="text-left">
                    name
                  </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3"></span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredAttachments.map((attachment, index) => (
                  <tr
                    key={index}
                    className="appeal-hovered"
                    onClick={() => {
                      if(canEdit){
                        setSelectedDocument(attachment);
                        setEditFile(true);
                      }
                      else{
                        setSelectedDocument(attachment);
                        setShowDocument(true);
                      }
                    }}
                  >
                    <td className="text-left">
                      <strong> {index + 1} </strong>
                    </td>

                    <td className="text-left">
                      <strong>
                        <AttachFileIcon className="text-primary" />
                        View {attachment.attachmentName}{" "}
                      </strong>
                    </td>

                    <td className="text-right">
                      <button type="button" className={`btn ${canEdit?'btn-primary':'btn-success'} m-2`}>
                        {canEdit?<><EditIcon /> Edit</> :<> <OpenInNewIcon /> Open</>}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};
const mapStateToProps = ({
  loading,
  onWait
}) => {
  return {
    loading,
    onWait
  };
};
export default connect(mapStateToProps, {
  updateRecruitmentRequestAttachment,
})(ViewRecruitmentAttachments);

const UpdateAttachmentFile = (props) => {
  const {
    loading,
    editFile,
    setEditFile,
    updateRecruitmentRequestAttachment,
    selectedDocument,
    setSelectedDocument,
    setFilteredAttachments,
    onWait,
  } = props;
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({
    fileHasError: false,
    fileError: "",
    hasError: false,
  });
  const [documentUrl, setDocumentUrl] = useState(null);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      fileError: "",
      hasError: false,
    };

    if (!file) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all the required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      const tempFormData = new FormData();
      tempFormData.append("id", selectedDocument.id);
      tempFormData.append("recruitmentRequestId", selectedDocument.recruitmentRequestId);
      tempFormData.append("file", file);

      updateRecruitmentRequestAttachment(tempFormData,setFilteredAttachments, onClose);
    }
  };

  const onClose = () => {
    setSelectedDocument(null);
    setFile(null);
    setEditFile(false);
  };

  return (
    <Dialog  open={editFile} fullWidth maxWidth="md">
      <DialogTitle className="text-primary pb-0 text-truncate">
       
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent className="pb-0">
        <div
          style={{
            height:"600px"
          }}
        >
          {selectedDocument &&  <h5
                style={{
                  background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                 {selectedDocument.attachmentName}
              </h5>}
          

          <div className="row ">
            <div className={`col-12 py-2 px-2 mt-0`}>
              <small>Upload new file (allowed:pdf)</small>
              <Button
                fullWidth
                className="d-flex justify-content-start"
                component="label"
                style={{
                  //float: "right",
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      fileHasError: false,
                      fileError: "",
                    });
                    const file = e.target.files[0];
                    setFile(file || null);
                    const reader = new FileReader();

                    reader.onload = (e) => {
                      const documentUrl = e.target.result;

                      setDocumentUrl(documentUrl);
                    };

                    reader.readAsDataURL(file);
                  }}
                />
              </Button>
              {errors.fileHasError && (
                <small className="text-danger">
                  {errors.fileError || "Attachment is required"}
                </small>
              )}
            </div>
            <div className="col-12 mt-3">
              {selectedDocument&&  
              <PdfViewer
                isBase64={!!documentUrl?true:false}
                url={documentUrl?documentUrl:process.env.REACT_APP_FILES_URL +'/preview/'+ selectedDocument.docReferenceId}
              />}
             
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <button
          onClick={onSave}
          type="button"
          className="btn btn-primary text-uppercase"
          disabled={loading || onWait}
        >
          {onWait ? "Wait..." : "Update"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

