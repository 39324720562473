import attachment from "../../assets/icons/attachment.svg";
import pdf from "../../assets/icons/pdf.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { useState } from "react";
const ViewExtraAttachments = (props) => {
  const { files } = props;
  const [onViewDocument, setOnViewDocument] = useState(false);
  const [document, setDocument] = useState(null);

  return (
    <>
      <div className="row">
        {onViewDocument && document && (
          <PreviewPdfUrl
            showDocument={onViewDocument}
            setShowDocument={() => setOnViewDocument(false)}
            document={{
              name: `${document.fileName}`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                document.docReferenceId,
            }}
          />
        )}
        {files.map((file, index) => (
          <div key={index} className={files.length === 1 ? "col-12" : "col-6"}>
            <>
              <Chip
                avatar={<Avatar alt={file.fileName} src={pdf} />}
                style={{ marginTop: "5px" }}
                size="small"
                onClick={() => {
                  setOnViewDocument(true);
                  setDocument(file);
                }}
                label={
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#0d141d",
                        fontWeight: "600",
                        width: "calc(100% - 80px)",
                        overflow: "hidden",
                        textDecoration: "none",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="text-truncate text-lowercase"
                    >
                      {file.fileName}
                    </span>
                  </>
                }
                variant="outlined"
                color="primary"
                deleteIcon={<CloudDownloadIcon />}
                onDelete={() => {
                  setOnViewDocument(true);
                  setDocument(file);
                }}
              />
            </>
          </div>
        ))}
      </div>
    </>
  );
};

export default ViewExtraAttachments;
