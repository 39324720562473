const types = {
  SET_TEMP_PAYROLLS: "SET_TEMP_PAYROLLS",
  ADD_NEW_TEMP_PAYROLL: "ADD_NEW_TEMP_PAYROLL",
  UPDATE_TEMP_PAYROLL: "UPDATE_TEMP_PAYROLL",
  DELETE_TEMP_PAYROLL: "DELETE_TEMP_PAYROLL",
  SET_ARCHIVED_PAYROLLS: "SET_ARCHIVED_PAYROLLS",
  SET_COST_CENTERS: "SET_COST_CENTERS",
  SET_EMPLOYEE_GROUPS: "SET_EMPLOYEE_GROUPS",
  SET_PAYROLL_GROUPS: "SET_PAYROLL_GROUPS",
  SET_SOURCE_FOUNDS: "SET_SOURCE_FOUNDS",
  SET_PAYROLL_TYPES: "SET_PAYROLL_TYPES",
  SET_PAYROLL_DECLARATIONS: "SET_PAYROLL_DECLARATIONS",
  SET_APPROVAL_PAYROLLS: "SET_APPROVAL_PAYROLLS",
  SET_ARREAR_TYPES: "SET_ARREAR_TYPES",
  UPDATE_ARCHIVED_PAYROLL: "UPDATE_ARCHIVED_PAYROLL",
  PREVIEW_PAYROLL: "PREVIEW_PAYROLL",
  SET_ALLOWANCES: "SET_ALLOWANCES",
  SET_SYSTEM_ALLOWANCES: "SET_SYSTEM_ALLOWANCES",
  SET_CREDITORS: "SET_CREDITORS",
  SET_CALCULATION_METHODES: "SET_CALCULATION_METHODES",
  SET_BASE_PAYROLL_COMPONENTS: "SET_BASE_PAYROLL_COMPONENTS",
  SET_PAY_HISTORIES: "SET_PAY_HISTORIES",
  GENERATE_PAYROLL: "GENERATE_PAYROLL",
  SET_CURRENCIES: "SET_CURRENCIES",
  DELETE_ARCHIVED_PAYROLL: "DELETE_ARCHIVED_PAYROLL",
  SET_PAYROLL_DETAILS: "SET_PAYROLL_DETAILS",
  UPDATE_PAYROLL_APPROVERS: "UPDATE_PAYROLL_APPROVERS",
  SET_PAYROLL_DASHBOARD: "SET_PAYROLL_DASHBOARD",
  SET_RSSB_DECLARATION_ERROR: "SET_RSSB_DECLARATION_ERROR",
  SET_PAYROLL_SUPPORTING_DOCUMENTS: "SET_PAYROLL_SUPPORTING_DOCUMENTS",
  ADD_NEW_PAYROLL_SUPPORTING_DOCUMENT: "ADD_NEW_PAYROLL_SUPPORTING_DOCUMENT",
  REMOVE_PAYROLL_SUPPORTING_DOCUMENT: "REMOVE_PAYROLL_SUPPORTING_DOCUMENT",
  SET_SUPPORTING_DOCUMENT_TYPES: "SET_SUPPORTING_DOCUMENT_TYPES",
  SET_EMPLOYEE_WITHHOLD_REQUESTS: "SET_EMPLOYEE_WITHHOLD_REQUESTS",
  UPDATE_EMPLOYEE_WITHHOLD_REQUEST: "UPDATE_EMPLOYEE_WITHHOLD_REQUEST",
  SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS: "SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS",
  SET_TOTAL_ARCHIVED_PAYROLLS: "SET_TOTAL_ARCHIVED_PAYROLLS",
  SET_CURRENT_ARCHIVED_PAYROLL_PAGE: "SET_CURRENT_ARCHIVED_PAYROLL_PAGE",
  SET_PERFORMANCE_BONUS_SCORES: "SET_PERFORMANCE_BONUS_SCORES",
  UPDATE_PERFORMANCE_BONUS_SCORE: "UPDATE_PERFORMANCE_BONUS_SCORE",
  REMOVE_PERFORMANCE_BONUS_SCORE: "REMOVE_PERFORMANCE_BONUS_SCORE",
  SET_EMPLOYEE_ANNUAL_SALARIES: "SET_EMPLOYEE_ANNUAL_SALARIES",
  SET_PBF_INDEX_GROUP: "SET_PBF_INDEX_GROUP",
  ADD_EMPLOYEE_PBF_SETTING: "ADD_EMPLOYEE_PBF_SETTING",
  UPDATE_EMPLOYEE_PBF_SETTING: "UPDATE_EMPLOYEE_PBF_SETTING",
  REMOVE_EMPLOYEE_PBF_SETTING: "REMOVE_EMPLOYEE_PBF_SETTING",
  SET_EMPLOYEE_PBF_SETTINGS: "SET_EMPLOYEE_PBF_SETTINGS",

  SET_CHANGE_REASONS: "SET_CHANGE_REASONS",
  SET_INDIVIDUAL_SUPPORTING_DOCUMENTS: "SET_INDIVIDUAL_SUPPORTING_DOCUMENTS",
  ADD_NEW_INDIVIDUAL_SUPPORTING_DOCUMENT:
    "ADD_NEW_INDIVIDUAL_SUPPORTING_DOCUMENT",
  REMOVE_INDIVIDUAL_SUPPORTING_DOCUMENT:
    "REMOVE_INDIVIDUAL_SUPPORTING_DOCUMENT",

  UPDATE_PAYROLL_CHANGE_LIST: "UPDATE_PAYROLL_CHANGE_LIST",
  UPDATE_CURRENCY: "UPDATE_CURRENCY",
};

export default types;
