import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TableSkeleton from "../../common/components/TableSkeleton";
import RBMPageHeader from "./RBMPageHeader";
import CustomProgressLine from "./CustomProgressLine";
import axios from "axios";
import { getEntityTopOutputs } from "../../../store/rbm/actions";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { connect } from "react-redux";
import InstitutionOutputIndicators from "./InstitutionOutputIndicators";
import ClickableTD from "../../common/components/ClickableTd";

const InstitutionOutputs = (props) => {
  const {
    entity,
    loading,
    selectedFiscalYear,
    onCloseEntity,
    getEntityTopOutputs,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [entityOutput, setEntityOutput] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [selectedOutput, setSelectedOutput] = useState(null);

  const [outputSummary, setOutputSummary] = useState({
    allOutputs: 0,
    totalWeight: 0,

    numOfIndicators: 0,
    numOfActivities: 0,
    dueActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,

    achievedTarget: 0,
    score: 0,
  });

  const getEntityTopOutputsSummary = async () => {
    const { data } = await axios.get(
      `/api/rbm/entity-outputs-summary/${entity.id}`
    );

    setOutputSummary(data);
  };

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      entityId: entity.id,
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    if (!!selectedFiscalYear.id) {
      getEntityTopOutputs(
        query,
        setEntityOutput,
        setTotalRecords,
        setLoadingData
      );
      getEntityTopOutputsSummary();
    }
  }, [selectedFiscalYear?.id]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        entityId: entity.id,
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      getEntityTopOutputs(query, setEntityOutput, setTotalRecords, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      entityId: entity.id,
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getEntityTopOutputs(query, setEntityOutput, setTotalRecords, setLoader);
  };

  if (selectedOutput) {
    return (
      <InstitutionOutputIndicators
        output={selectedOutput}
        onClose={() => setSelectedOutput(null)}
      />
    );
  }

  return (
    <Dialog
      onClose={onCloseEntity}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={`xl`}
    >
      <DialogTitle>
        <Typography className="text-primary text-uppercase">
          <span className="d-flex align-items-center">
            <span>{entity.name}</span>
          </span>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onCloseEntity}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
        <div className="mt-3 px-2">
          <RBMPageHeader
            labels={[
              {
                title: "Outputs",
                value: outputSummary.allOutputs,
                type: "number",
              },
              {
                title: "Indicators",
                value: outputSummary.numOfIndicators || 0,
                type: "number",
              },
              {
                title: "Assignments",
                value: outputSummary.allAssignments || 0,
                type: "number",
              },
              {
                title: "Unassigned",
                value: outputSummary.unassignedIndicators || 0,
                type: "number",
                color: "warning",
                onClick: () => {
                  if (outputSummary.unassignedIndicators > 0) {
                    setSearchTerm("unassigned");
                    getEntityTopOutputs(
                      {
                        entityId: entity.id,
                        searchTerm: "unassigned",
                        page: currentPage,
                        size: itemPerPage,
                      },
                      setEntityOutput,
                      setTotalRecords,
                      setLoader
                    );
                  }
                },
              },
              {
                title: "Ontrack",
                value: outputSummary.ontrackAssignments || 0,
                type: "number",
              },
              {
                title: "Due",
                value: outputSummary.dueAssignments || 0,
                type: "number",
              },
              {
                title: "Reported",
                value: outputSummary.reportedAssignments || 0,
                type: "number",
              },
              {
                title: "Scored",
                value: outputSummary.scoredAssignments || 0,
                type: "number",
              },
              {
                title: "Rejected",
                value: outputSummary.rejectedAssignments || 0,
                type: "number",
                color: "danger",
              },
            ]}
            progress={{
              title: "OVERALL INSTITUTIONAL PROGRESS",
              value: 0,
            }}
            // disabled={loading}
            disabled={true}
            loading={loading}
          />

          <div className="w-100">
            <input
              type="text"
              value={searchTerm}
              className="form-control"
              id="filter-output"
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const query = {
                    entityId: entity.id,
                    searchTerm: searchTerm,
                    page: currentPage,
                    size: itemPerPage,
                  };

                  getEntityTopOutputs(
                    query,
                    setEntityOutput,
                    setTotalRecords,
                    setLoader
                  );
                }
              }}
              disabled={loadingData || loader || loading}
            />
          </div>
        </div>
      </DialogTitle>

      <DialogContent className="mx-4 px-2">
        <div className="table-responsive w-100">
          <table
            className={`table table-sm ${
              !loader && !loading && !loadingData ? "table-hover" : ""
            }`}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  #
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Output
                  {(outputSummary.unassignedOutputs || 0) > 0 && (
                    <span className="badge badge-warning ml-1 text-danger">
                      {outputSummary.unassignedOutputs} Unassigned
                    </span>
                  )}
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Budget
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Reference
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Indicators
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Unassigned
                </th>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "3px solid #dee2e6",
                  }}
                >
                  Reported
                </th>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "3px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  Target Achieved
                </th>
              </tr>
            </thead>
            {!loadingData ? (
              <tbody style={{ fontSize: "13px" }}>
                {entityOutput[0] ? (
                  entityOutput.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            entityOutput.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.no}
                      </td>
                      <td
                        className="text-left"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            entityOutput.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        <div className="d-inline-flex justify-content-between align-items-center w-100">
                          <div>{item.name}</div>

                          {item.score !== null && (
                            <div style={{ minWidth: 80 }}>
                              <CustomProgressLine value={item.score || 0} />
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            entityOutput.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.budget || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            entityOutput.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.reference || "-"}
                      </td>
                      <ClickableTD
                        onClick={() =>
                          setSelectedOutput({
                            name: item.name,
                            id: item.id,
                          })
                        }
                        borderBottom={
                          entityOutput.length === index + 1
                            ? "3px solid #dee2e6"
                            : ""
                        }
                        disabled={loading || loadingData}
                      >
                        {item.numOfIndicators}
                      </ClickableTD>
                      <ClickableTD
                        onClick={() =>
                          setSelectedOutput({
                            searchTerm: "unassigned",
                            name: item.name,
                            id: item.id,
                          })
                        }
                        borderBottom={
                          entityOutput.length === index + 1
                            ? "3px solid #dee2e6"
                            : ""
                        }
                        disabled={loading || loadingData}
                      >
                        {item.unassignedIndicators}
                      </ClickableTD>
                      <ClickableTD
                        borderBottom={
                          entityOutput.length === index + 1
                            ? "3px solid #dee2e6"
                            : ""
                        }
                        disabled={loading || loadingData}
                      >
                        {item.submissions}
                      </ClickableTD>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderBottom:
                            entityOutput.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.achievedTarget || "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      className="text-center py-4"
                      style={{ border: "3px solid #dee2e6" }}
                      colSpan={8}
                    >
                      <span style={{ fontSize: "17px", fontWeight: "600" }}>
                        No Data Found!
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <TableSkeleton cols={8} rows={15} />
            )}
          </table>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-0">
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="mt-3">
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className="pr-1 d-none d-md-inline">Next</span>
                  <i className="fas fa-angle-double-right"></i>
                </>
              }
              previousLabel={
                <>
                  <i className="fas fa-angle-double-left"></i>
                  <span className="pl-1  d-none d-md-inline">Previous</span>
                </>
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              pageCount={Math.ceil(totalRecords / itemPerPage)}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
              previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
              nextLinkClassName="btn btn-outline-info btn-sm ml-1"
              activeLinkClassName="active"
            />
          </div>

          {!!entityOutput.length && (
            <select
              className="custom-select custom-select-sm mr-3 ml-2"
              style={{ width: "100px" }}
              value={itemPerPage}
              onChange={(event) => {
                const itemPerPage = +event.target.value;
                setItemPerPage(itemPerPage);
                const query = {
                  entityId: entity.id,
                  searchTerm: searchTerm,
                  page: currentPage,
                  size: itemPerPage,
                };
                getEntityTopOutputs(
                  query,
                  setEntityOutput,
                  setTotalRecords,
                  setLoader
                );
              }}
            >
              {[10, 50, 100].map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          )}
        </div>
      </DialogActions>
      <div
        className={`page-loading-void position-fixed ${
          loader && !!entityOutput[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear }) => {
  return {
    loading,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, { getEntityTopOutputs })(
  InstitutionOutputs
);
