import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Divider,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveOutputAssignment,
  removeOutputAssignment,
  getTechnicalHeads,
} from "../../../store/rbm/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import defaultProfile from "../../assets/default-profile.jpg";
import { showError } from "../../toastify";

const OutputAssignmentForm = (props) => {
  const {
    selectedOutput,
    showAssignmentForm,
    setShowAssignmentForm,
    loading,
    saveOutputAssignment,
    removeOutputAssignment,
    technicalHeads,
    getTechnicalHeads,
    outputs,
  } = props;

  const [technicalHead, setTechnicalHead] = useState(null);
  const [contractType, setContractType] = useState({
    id: 1,
    name: "Normal",
  });

  const onSave = () => {
    if (!!selectedOutput)
      saveOutputAssignment(
        [
          {
            outputId: selectedOutput?.id,
            employeePositionId: technicalHead.employeePositionId,
            employeeId: technicalHead.id,
            contractTypeId: contractType.id,
          },
        ],
        onClose
      );
    else {
      const unAssignedOutputs = outputs.filter(
        ({ assignmentId }) => !assignmentId
      );

      const assignmentData = [];
      unAssignedOutputs.forEach(({ id }) => {
        assignmentData.push({
          outputId: id,
          employeePositionId: technicalHead.employeePositionId,
          employeeId: technicalHead.id,
          contractTypeId: contractType.id,
        });
      });

      if (!assignmentData.length) return showError("All outputs are assigned");

      saveOutputAssignment(assignmentData, onClose);
    }
  };

  const onClose = () => {
    setShowAssignmentForm(false);
  };

  useEffect(() => {
    if (showAssignmentForm && !technicalHeads.length) getTechnicalHeads();
  }, [showAssignmentForm]);

  const [confirmRemoveAssignment, setConfirmRemoveAssignment] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAssignmentForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-dark">
          <Typography className="d-flex align-items-center">
            <strong>OUTPUT:</strong>{" "}
            <span className="text-uppercase text-primary ml-1">
              {/* <i className="fas fa-folder-open mr-1"></i> */}

              <small> {selectedOutput?.name || "Assign All Outputs"} </small>
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="rbm-shadow rounded px-4 mt-2">
            <div className="" style={{ minHeight: "25vh" }}>
              <Card elevation={0} className="">
                {!!selectedOutput?.assignmentId && (
                  <>
                    <Typography className="text-dark text-center text-uppercase bg-light mt-3 border px-2 py-1">
                      <small className="text-uppercase">
                        Responsible Technical Head
                      </small>
                    </Typography>

                    <List>
                      <Divider />
                      <ListItem
                        className="pl-0"
                        secondaryAction={
                          <span>
                            <span className="badge badge-pill px-0">
                              <small>
                                {selectedOutput.contractTypeName} contract
                              </small>
                            </span>
                            {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                              <Tooltip title="Remove">
                                <span>
                                  <IconButton
                                    edge="end"
                                    color="error"
                                    aria-label="delete"
                                    onClick={() => {
                                      setConfirmRemoveAssignment(true);
                                    }}
                                  >
                                    <span className="material-icons">
                                      delete_forever
                                    </span>
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </span>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{ border: "1px solid #d1cbcb" }}
                            alt={selectedOutput?.firstName}
                            src={
                              selectedOutput?.profileImage
                                ? `data:image/jpeg;base64,${selectedOutput?.profileImage}`
                                : defaultProfile
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <span className={`font-weight-bold text-primary`}>
                              {selectedOutput?.lastName}{" "}
                              {selectedOutput?.firstName}
                            </span>
                          }
                          secondary={
                            <>
                              <small className="d-block">
                                {" "}
                                {!!selectedOutput?.isActing && (
                                  <span className="text-primary font-weight-bold">
                                    Ag.
                                  </span>
                                )}{" "}
                                {selectedOutput?.positionName}
                              </small>
                              <small className="d-block text-uppercase mb-1">
                                {selectedOutput?.unitName}
                              </small>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </List>
                  </>
                )}
              </Card>
              {!selectedOutput?.assignmentId && (
                <div>
                  {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                    <div className="row mt-3">
                      <div className="col-8">
                        <Autocomplete
                          fullWidth
                          disablePortal
                          size="small"
                          options={technicalHeads}
                          value={technicalHead}
                          getOptionLabel={(option) =>
                            option.lastName + " " + option.firstName
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Avatar
                                style={{ border: "1px solid #d1cbcb" }}
                                alt={option.firstName}
                                className="mr-2"
                                src={
                                  option.profileImage
                                    ? `data:image/jpeg;base64,${option.profileImage}`
                                    : defaultProfile
                                }
                              />
                              <div>
                                <span className="d-block text-primary">
                                  {option.lastName} {option.firstName}
                                </span>
                                <small className="d-block">
                                  {!!option.isActing && (
                                    <span className="text-primary font-weight-bold">
                                      Ag.
                                    </span>
                                  )}{" "}
                                  {option.positionName}
                                </small>
                                <small className="d-block text-uppercase mb-1">
                                  {option.unitName}
                                </small>
                                <Divider />
                                <Divider />
                              </div>
                            </li>
                          )}
                          onChange={(event, newValue) => {
                            setTechnicalHead(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="technicalHeads"
                              {...params}
                              label="Select Responsible TechnicalHead"
                              placeholder="Select Responsible TechnicalHead"
                            />
                          )}
                        />
                      </div>
                      <div className="col-4">
                        <Autocomplete
                          disablePortal
                          size="small"
                          value={contractType}
                          options={[
                            {
                              id: 1,
                              name: "Normal",
                            },
                            {
                              id: 2,
                              name: "Acting",
                            },
                          ]}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            setContractType(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              name="contractType"
                              {...params}
                              label="Contract Type"
                              placeholder="Contract Type"
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!selectedOutput?.assignmentId && (
                <div className="jumbotron jumbotron-fluid text-center py-4 mt-3">
                  <div className="container">
                    <p className="lead">Output is not assigned.</p>
                  </div>
                </div>
              )}
            </div>
            {!selectedOutput?.assignmentId &&
              hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                <div className="d-flex justify-content-center pb-3">
                  <Button
                    type="button"
                    disabled={
                      !technicalHead || loading || confirmRemoveAssignment
                    }
                    color="primary"
                    variant="contained"
                    onClick={onSave}
                    className="px-5"
                  >
                    {loading && !confirmRemoveAssignment ? "Wait..." : "Save"}
                  </Button>
                </div>
              )}
          </div>
        </DialogContent>
      </Dialog>

      {confirmRemoveAssignment && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAssignment}
          message={`Are you sure you want to remove the assignment?`}
          setConfirmationDialog={setConfirmRemoveAssignment}
          onYes={() => {
            removeOutputAssignment(
              {
                outputId: selectedOutput?.id,
                assignmentId: selectedOutput?.assignmentId,
              },
              () => {
                setConfirmRemoveAssignment(false);
                onClose();
              }
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, units, user, technicalHeads, outputs }) => {
  return { loading, units, user, technicalHeads, outputs };
};
export default connect(mapStateToProps, {
  saveOutputAssignment,
  removeOutputAssignment,
  getTechnicalHeads,
})(OutputAssignmentForm);
