import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  Tooltip,
  Menu,
  Badge,
  MenuItem,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";

import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { saveEmployeeAllowanceProRata } from "../../../store/structure/actions";
import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import NumberFormat from "react-number-format";
import CurrencyFormat from "react-currency-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const EmployeeAllowanceProRata = (props) => {
  const {
    loading,
    onWait,
    showEmployeeAllowanceProRata,
    setShowEmployeeAllowanceProRata,
    selectedEmployeeAllowance,
    selectedEmployee,
    allowances,
    saveEmployeeAllowanceProRata,
  } = props;

  const onClose = () => {
    setShowEmployeeAllowanceProRata(false);
  };

  const [formData, setFormData] = useState({
    employeePositionId: selectedEmployee.employeePositionId,
    allowanceId: selectedEmployeeAllowance.allowanceId || "",
    amount:
      selectedEmployeeAllowance.proRataAmount ||
      selectedEmployeeAllowance.amount > 1
        ? selectedEmployeeAllowance.amount
        : 0,
  });

  const onSave = () => {
    saveEmployeeAllowanceProRata(
      formData,
      setFormData,
      setShowEmployeeAllowanceProRata
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeAllowanceProRata}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Allowance Pro-Rata amount
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="">
          {" "}
          <div className="row d-flex align-items-start">
            <div className="col-12">
              <div className="alert alert-info text-center" role="alert">
                <strong>Pro-rata amount</strong> will replace the real amount,
                but it will be only valid for the current month.
              </div>
            </div>
            <div className="col-12">
              <TextField
                style={{ backgroundColor: "#eee" }}
                size="small"
                fullWidth
                name="rate"
                label="Allowance"
                variant="outlined"
                placeholder="Allowance"
                value={selectedEmployeeAllowance.allowanceName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div className="col-12 mt-3">
              <NumberFormat
                autoFocus
                fullWidth
                size="small"
                label={`Pro rata amount in ${selectedEmployeeAllowance.currencyId}`}
                name="amount"
                variant="outlined"
                placeholder={`${selectedEmployeeAllowance.currencyId} 0`}
                prefix={`${selectedEmployeeAllowance.currencyId} `}
                customInput={TextField}
                value={formData.amount}
                thousandSeparator={true}
                onBlur={() => {
                  if (formData.amount === "")
                    setFormData({
                      ...formData,
                      amount: 0,
                    });
                }}
                onKeyDown={() => {
                  if (+formData.amount === 0)
                    setFormData({
                      ...formData,
                      amount: "",
                    });
                }}
                onChange={(e) => {
                  // setErrors({ ...errors, amountHasError: false });
                  const amount = e.target.value.split("RWF ");

                  setFormData({
                    ...formData,
                    amount: !!amount[1] ? amount[1].replace(/,/g, "") : 0,
                  });
                }}
              />
              {/* {errors.amountHasError && (
                        <small className="text-danger mb-2">
                          Amount is required{" "}
                        </small>
                      )} */}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={
              loading ||
              !(
                !!formData.amount &&
                +selectedEmployeeAllowance.amount !== +formData.amount
              )
            }
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, allowances }) => {
  return {
    loading,
    onWait,
    allowances,
  };
};
export default connect(mapStateToProps, { saveEmployeeAllowanceProRata })(
  EmployeeAllowanceProRata
);
