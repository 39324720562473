import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Divider,
  Checkbox,
  Button,
  Typography,
  Tooltip,
  Box,
  Input,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveIndicatorAssignment,
  updateIndicatorTargetAssignment,
  removeIndicatorAssignment,
  getSupervisors,
} from "../../../store/rbm/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import defaultProfile from "../../assets/default-profile.jpg";
import { removeDuplicateIndicatorAssignments } from "../../common/components/Utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { showError } from "../../toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const IndicatorAssignmentForm = (props) => {
  const location = useLocation();

  const {
    selectedIndicator,
    showAssignmentForm,
    setShowAssignmentForm,
    loading,
    // units,
    saveIndicatorAssignment,
    // updateIndicatorTargetAssignment,
    removeIndicatorAssignment,
    user,
    supervisors,
    getSupervisors,
    indicators,
  } = props;

  const [formData, setFormData] = useState({
    q1TargetShare: selectedIndicator?.quarter1Target || 0,
    q2TargetShare: selectedIndicator?.quarter2Target || 0,
    q3TargetShare: selectedIndicator?.quarter3Target || 0,
    q4TargetShare: selectedIndicator?.quarter4Target || 0,
  });

  const [selectedSupervisor, setSelectedSupervisor] = useState(null);

  const [contractType, setContractType] = useState({
    id: 1,
    name: "Normal",
  });

  const [assignmentDeadline, setAssignmentDeadline] = useState(null);

  const onClose = () => {
    setShowAssignmentForm(false);
  };

  const onSave = () => {
    const assignmentData = [];

    if (!assignmentDeadline && [3, 4].includes(contractType?.id))
      return showError("Please select deadline date");

    if (!!selectedIndicator)
      assignmentData.push({
        indicatorId: selectedIndicator?.id || null,
        employeePositionId: selectedSupervisor?.id || null,
        employeeId: selectedSupervisor?.employeeId || null,
        contractTypeId: contractType?.id || 1,
        q1TargetShare: formData.q1TargetShare || 0,
        q2TargetShare: formData.q2TargetShare || 0,
        q3TargetShare: formData.q3TargetShare || 0,
        q4TargetShare: formData.q4TargetShare || 0,
        deadline: assignmentDeadline,
      });
    else
      indicators.forEach((indicator) => {
        assignmentData.push({
          indicatorId: indicator.id,
          employeePositionId: selectedSupervisor?.id || null,
          employeeId: selectedSupervisor?.employeeId || null,
          contractTypeId: contractType?.id || 1,
          q1TargetShare: indicator.quarter1Target,
          q2TargetShare: indicator.quarter2Target,
          q3TargetShare: indicator.quarter3Target,
          q4TargetShare: indicator.quarter4Target,
          deadline: assignmentDeadline,
        });
      });

    saveIndicatorAssignment(
      assignmentData,
      () =>
        setFormData({
          q1TargetShare: selectedIndicator?.quarter1Target,
          q2TargetShare: selectedIndicator?.quarter2Target,
          q3TargetShare: selectedIndicator?.quarter3Target,
          q4TargetShare: selectedIndicator?.quarter4Target,
        }),
      () => (!!selectedIndicator ? setSelectedSupervisor(null) : onClose())
    );
  };

  useEffect(() => {
    if (
      showAssignmentForm &&
      (!supervisors.length ||
        (!!supervisors.length && !supervisors[0].employeePositionId))
    )
      getSupervisors();
  }, [showAssignmentForm]);

  const { hasPermissions } = useRequiredAuth();

  const filteredSupervisors = () => {
    if (!!selectedIndicator)
      return supervisors.filter(
        (supervisor) =>
          selectedIndicator.assignments.findIndex(
            (assignment) => supervisor.id === assignment.employeePositionId
          ) < 0
      );
    else return supervisors;
  };

  const handleAssignmentDeadline = (selectedEmployees) => {
    if (
      selectedEmployees &&
      [3, 4].includes(selectedEmployees.contractTypeId)
    ) {
      setAssignmentDeadline(new Date(selectedEmployees.deadline));
    } else {
      setAssignmentDeadline(null);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAssignmentForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-dark mx-4" component="div">
          <Typography className="text-uppercase">
            Indicator Assignments
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div>
            <Typography className="d-flex align-items-center text-primary mt-3">
              <span className="text-uppercase">
                <i className="fas fa-folder-open mr-1"></i>
                <small>
                  {selectedIndicator
                    ? selectedIndicator.name
                    : "All indicators"}
                </small>
              </span>
            </Typography>

            {hasPermissions(["CAN_ASSIGN_INDICATOR"]) && (
              <div className="row mt-2">
                {!!selectedIndicator && (
                  <div className="col-9 d-inline-flex mb-2">
                    {+selectedIndicator.quarter1Target > 0 && (
                      <OutlinedInput
                        error={
                          +formData.q1TargetShare >
                          +selectedIndicator.quarter1Target
                        }
                        size="small"
                        name="indicator-assignment-q1"
                        className="col-3"
                        startAdornment="Q1:"
                        endAdornment={`/${selectedIndicator.quarter1Target}`}
                        style={{ fontSize: "13px" }}
                        value={formData.q1TargetShare}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            q1TargetShare: e.target.value,
                          });
                        }}
                      />
                    )}
                    {+selectedIndicator.quarter2Target > 0 && (
                      <OutlinedInput
                        error={
                          +formData.q2TargetShare >
                          +selectedIndicator.quarter2Target
                        }
                        size="small"
                        name="indicator-assignment-q2"
                        className="col-3"
                        startAdornment="Q2:"
                        style={{ fontSize: "13px" }}
                        endAdornment={`/${selectedIndicator.quarter2Target}`}
                        value={formData.q2TargetShare}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            q2TargetShare: e.target.value,
                          });
                        }}
                      />
                    )}
                    {+selectedIndicator.quarter3Target > 0 && (
                      <OutlinedInput
                        error={
                          +formData.q3TargetShare >
                          +selectedIndicator.quarter3Target
                        }
                        size="small"
                        name="indicator-assignment-q3"
                        className="col-3"
                        style={{ fontSize: "13px" }}
                        startAdornment="Q3:"
                        endAdornment={`/${selectedIndicator.quarter3Target}`}
                        value={formData.q3TargetShare}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            q3TargetShare: e.target.value,
                          });
                        }}
                      />
                    )}
                    {+selectedIndicator.quarter4Target > 0 && (
                      <OutlinedInput
                        error={
                          +formData.q4TargetShare >
                          +selectedIndicator.quarter4Target
                        }
                        size="small"
                        name="indicator-assignment-q4"
                        className="col-3"
                        style={{ fontSize: "13px" }}
                        startAdornment="Q4:"
                        endAdornment={`/${selectedIndicator.quarter4Target}`}
                        value={formData.q4TargetShare}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            q4TargetShare: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                )}

                {!selectedIndicator && (
                  <div className="col-9 mb-2">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      size="small"
                      value={selectedSupervisor}
                      options={filteredSupervisors()}
                      getOptionLabel={(option) => option.names}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option) => (
                        <div {...props}>
                          <Avatar
                            style={{ border: "1px solid #d1cbcb" }}
                            alt={option.firstName}
                            className="mr-2"
                            src={
                              option.profileImage
                                ? `data:image/jpeg;base64,${option.profileImage}`
                                : defaultProfile
                            }
                          />
                          <span>
                            <span className="d-block text-primary">
                              {option.names}
                            </span>
                            <small className="d-block">
                              {!!option.isActing && (
                                <span className="text-primary font-weight-bold">
                                  Ag.
                                </span>
                              )}{" "}
                              {option.positionName}
                            </small>
                            <small className="d-block text-uppercase mb-1">
                              {option.unitName}
                            </small>
                            <Divider />
                            <Divider />
                          </span>
                        </div>
                      )}
                      onChange={(event, newValue) => {
                        setSelectedSupervisor(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Responsible Supervisor"
                          placeholder="Select Responsible Supervisor"
                        />
                      )}
                    />
                  </div>
                )}

                <div className="col-3 mb-2">
                  <Autocomplete
                    disablePortal
                    size="small"
                    value={contractType}
                    options={[
                      {
                        id: 1,
                        name: "Normal",
                      },
                      {
                        id: 2,
                        name: "Acting",
                      },
                    ]}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setContractType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Contract Type"
                        placeholder="Contract Type"
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                  />
                </div>

                {!!selectedIndicator && (
                  <div className="col-9 mt-1">
                    <Autocomplete
                      fullWidth
                      // multiple
                      // disableCloseOnSelect
                      disablePortal
                      size="small"
                      value={selectedSupervisor}
                      options={filteredSupervisors()}
                      getOptionLabel={(option) => option.names}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar
                            style={{ border: "1px solid #d1cbcb" }}
                            alt={option.firstName}
                            className="mr-2"
                            src={
                              option.profileImage
                                ? `data:image/jpeg;base64,${option.profileImage}`
                                : defaultProfile
                            }
                          />
                          <div>
                            <span className="d-block text-primary">
                              {option.names}{" "}
                              <span
                                className={`badge ${
                                  option.contractType
                                    ? "badge-pill"
                                    : "badge-pill text-danger"
                                } badge-light`}
                              >
                                {option.contractType || "No"} contract
                              </span>
                            </span>
                            <small className="d-block">
                              {!!option.isActing && (
                                <span className="text-primary font-weight-bold">
                                  Ag.
                                </span>
                              )}{" "}
                              {option.positionName}
                            </small>
                            <small className="d-block text-uppercase mb-1">
                              {option.unitName}
                            </small>
                            <Divider />
                            <Divider />
                          </div>
                        </li>
                      )}
                      onChange={(event, newValue) => {
                        setSelectedSupervisor(newValue);
                        handleAssignmentDeadline(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Responsible Supervisor"
                          placeholder="Select Responsible Supervisor"
                        />
                      )}
                    />
                  </div>
                )}
                {!!selectedIndicator && (
                  <div className="col-3 mt-1">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Deadline Date"
                        inputFormat="dd/MM/yyyy"
                        disabled={
                          contractType.id !== 3 && contractType.id !== 4
                        }
                        value={assignmentDeadline}
                        onChange={(date) => {
                          setAssignmentDeadline(date);
                        }}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            required={true}
                            fullWidth
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
            )}
          </div>
        </DialogTitle>
        <DialogContent className="mx-4 ">
          <div className="px-4 rbm-shadow rounded">
            {selectedIndicator?.assignments[0] && (
              <Typography className="text-dark text-center text-uppercase bg-light mt-3 border px-2 py-1">
                <small className="text-uppercase">
                  Responsible Supervisors
                </small>
              </Typography>
            )}

            <div style={{ minHeight: "30vh" }}>
              <Card elevation={0} className="">
                <List>
                  {removeDuplicateIndicatorAssignments(
                    selectedIndicator?.assignments || []
                  )?.map((assignment, index) => (
                    <AssignmentItem
                      key={index}
                      loading={loading}
                      assignment={assignment}
                      supervisors={supervisors}
                      selectedIndicator={selectedIndicator}
                      user={user}
                      saveIndicatorAssignment={saveIndicatorAssignment}
                      removeIndicatorAssignment={removeIndicatorAssignment}
                    />
                  ))}
                </List>
              </Card>
              {!selectedIndicator?.assignments[0] && (
                <div className="jumbotron jumbotron-fluid text-center mt-3">
                  <div className="container">
                    <p className="lead">Indicator is not assigned.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          {hasPermissions(["CAN_ASSIGN_INDICATOR"]) && (
            <Button
              type="button"
              disabled={
                loading ||
                (!!selectedIndicator &&
                  (+formData.q1TargetShare >
                    +selectedIndicator.quarter1Target ||
                    +formData.q2TargetShare >
                      +selectedIndicator.quarter2Target ||
                    +formData.q3TargetShare >
                      +selectedIndicator.quarter3Target ||
                    +formData.q4TargetShare >
                      +selectedIndicator.quarter4Target)) ||
                !selectedSupervisor ||
                !contractType
              }
              color="primary"
              variant="contained"
              onClick={onSave}
              className="px-5"
            >
              {loading ? "Wait..." : "Save"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, units, user, supervisors }) => {
  return { loading, units, user, supervisors };
};
export default connect(mapStateToProps, {
  saveIndicatorAssignment,
  updateIndicatorTargetAssignment,
  removeIndicatorAssignment,
  getSupervisors,
})(IndicatorAssignmentForm);

const AssignmentItem = (props) => {
  const {
    loading,
    assignment,
    supervisors,
    selectedIndicator,
    user,
    saveIndicatorAssignment,
    removeIndicatorAssignment,
  } = props;

  const { hasPermissions } = useRequiredAuth();

  const [confirmRemoveAssignment, setConfirmRemoveAssignment] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const [formData, setFormData] = useState({
    q1TargetShare: assignment.q1TargetShare,
    q2TargetShare: assignment.q2TargetShare,
    q3TargetShare: assignment.q3TargetShare,
    q4TargetShare: assignment.q4TargetShare,
  });

  const [prevFormData, setPrevFormData] = useState({
    q1TargetShare: assignment.q1TargetShare,
    q2TargetShare: assignment.q2TargetShare,
    q3TargetShare: assignment.q3TargetShare,
    q4TargetShare: assignment.q4TargetShare,
  });

  useEffect(() => {
    if (!!showEditForm) {
      setFormData({
        q1TargetShare: assignment.q1TargetShare,
        q2TargetShare: assignment.q2TargetShare,
        q3TargetShare: assignment.q3TargetShare,
        q4TargetShare: assignment.q4TargetShare,
      });
      setPrevFormData({
        q1TargetShare: assignment.q1TargetShare,
        q2TargetShare: assignment.q2TargetShare,
        q3TargetShare: assignment.q3TargetShare,
        q4TargetShare: assignment.q4TargetShare,
      });
    }
  }, [showEditForm]);

  const onSave = () => {
    const assignmentData = [];

    assignmentData.push({
      indicatorId: assignment.indicatorId,
      employeePositionId: assignment.employeePositionId,
      employeeId: assignment.employeeId,
      contractTypeId: assignment.contractTypeId,
      q1TargetShare: +formData.q1TargetShare,
      q2TargetShare: +formData.q2TargetShare,
      q3TargetShare: +formData.q3TargetShare,
      q4TargetShare: +formData.q4TargetShare,
    });

    saveIndicatorAssignment(
      assignmentData,
      () =>
        setFormData({
          q1TargetShare: assignment.q1TargetShare,
          q2TargetShare: assignment.q2TargetShare,
          q3TargetShare: assignment.q3TargetShare,
          q4TargetShare: assignment.q4TargetShare,
        }),
      () => setShowEditForm(false)
    );
  };

  return (
    <>
      <div>
        <ListItem
          className="pl-0 mr-0"
          secondaryAction={
            <span>
              <span className="badge badge-pill text-uppercase px-0">
                <small>{assignment.contractTypeName} Contract</small>
              </span>
              {hasPermissions(["CAN_ASSIGN_INDICATOR"]) &&
                supervisors.findIndex(
                  (supervisor) =>
                    supervisor.id === assignment.employeePositionId &&
                    (supervisor.employeeId !== user.id ||
                      assignment.createdBy === user.id)
                ) >= 0 && (
                  <>
                    <Tooltip>
                      <span className="text-right ">
                        <IconButton
                          // size="small"
                          edge="end"
                          color="primary"
                          aria-label="edit"
                          onClick={() => {
                            setShowEditForm(true);
                          }}
                        >
                          <span className="material-icons">edit_note</span>
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip>
                      <span className=" text-right">
                        <IconButton
                          // size="small"
                          edge="end"
                          color="error"
                          aria-label="delete"
                          onClick={() => {
                            setConfirmRemoveAssignment(true);
                          }}
                        >
                          <span className="material-icons">delete_forever</span>
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
            </span>
          }
        >
          <ListItemAvatar>
            <Avatar
              style={{ border: "1px solid #d1cbcb" }}
              alt={assignment.firstName}
              src={defaultProfile}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <span className={`font-weight-bold text-primary`}>
                  {assignment.lastName} {assignment.firstName}
                </span>

                <span className="d-block" style={{ fontSize: "14px" }}>
                  <small className="d-block">
                    {" "}
                    {!!assignment.isActing && (
                      <span className="text-primary font-weight-bold">Ag.</span>
                    )}{" "}
                    {assignment.positionName}
                  </small>
                  <small className="d-block text-uppercase mb-1">
                    {assignment.unitName}
                  </small>

                  <span className="badge badge-pill text-primary text-uppercase px-0">
                    Target Shares:
                  </span>
                  <span className="d-inline-flex mb-1 w-100">
                    {assignment.q1TargetShare > 0 && (
                      <Tooltip title="Quarter-1 Target Share">
                        <span
                          className={`d-flex align-items-center  px-2 bg-light`}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "default",
                            fontSize: "13px",
                          }}
                        >
                          Q<sub>1</sub>: {assignment.q1TargetShare}{" "}
                          {assignment.q1TargetShare > 0 && (
                            <>
                              {selectedIndicator.measurementUnit
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                                selectedIndicator.measurementUnit
                                  .slice(1)
                                  .toLowerCase()}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}

                    {assignment.q2TargetShare > 0 && (
                      <Tooltip title="Quarter-2 Target Share">
                        <span
                          className={`d-flex align-items-center  px-2 bg-light`}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "default",
                            fontSize: "13px",
                          }}
                        >
                          Q<sub>2</sub>: {assignment.q2TargetShare}{" "}
                          {assignment.q2TargetShare > 0 && (
                            <>
                              {selectedIndicator.measurementUnit
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                                selectedIndicator.measurementUnit
                                  .slice(1)
                                  .toLowerCase()}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}

                    {assignment.q3TargetShare > 0 && (
                      <Tooltip title="Quarter-3 Target Share">
                        <span
                          className={`d-flex align-items-center  px-2 bg-light`}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "default",
                            fontSize: "13px",
                          }}
                        >
                          Q<sub>3</sub>: {assignment.q3TargetShare}{" "}
                          {assignment.q3TargetShare > 0 && (
                            <>
                              {selectedIndicator.measurementUnit
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                                selectedIndicator.measurementUnit
                                  .slice(1)
                                  .toLowerCase()}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}

                    {assignment.q4TargetShare > 0 && (
                      <Tooltip title="Quarter-4 Target Share">
                        <span
                          className={`d-flex align-items-center mr-2  px-2 bg-light`}
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            cursor: "default",
                            fontSize: "13px",
                          }}
                        >
                          Q<sub>4</sub>: {assignment.q4TargetShare}{" "}
                          {assignment.q4TargetShare > 0 && (
                            <>
                              {selectedIndicator.measurementUnit
                                .toLowerCase()
                                .charAt(0)
                                .toUpperCase() +
                                selectedIndicator.measurementUnit
                                  .slice(1)
                                  .toLowerCase()}
                            </>
                          )}
                        </span>
                      </Tooltip>
                    )}
                  </span>
                </span>
              </>
            }
          />
        </ListItem>
        <Divider />
        <Divider />
      </div>

      {confirmRemoveAssignment && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAssignment}
          message={`Are you sure you want to remove this assignment?`}
          setConfirmationDialog={setConfirmRemoveAssignment}
          onYes={() => {
            removeIndicatorAssignment(
              {
                employeePositionId: assignment.employeePositionId,
                indicatorId: selectedIndicator.id,
              },
              setConfirmRemoveAssignment
            );
          }}
        />
      )}

      {showEditForm && (
        <Dialog
          onClose={() => setShowEditForm(false)}
          aria-labelledby="customized-dialog-title"
          open={showEditForm}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>
            <Typography className="text-uppercase">
              <small>
                Edit Assignment |{" "}
                <span className="text-primary ">
                  {assignment.lastName} {assignment.firstName}
                </span>{" "}
              </small>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setShowEditForm(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="row px-0 px-3">
              {(selectedIndicator.quarter1Target > 0 ||
                prevFormData.q1TargetShare > 0) && (
                <OutlinedInput
                  error={
                    +formData.q1TargetShare > +selectedIndicator.quarter1Target
                  }
                  name="indicator-assignment-q1"
                  className="col text-center"
                  startAdornment="Q1:"
                  endAdornment={`/${selectedIndicator.quarter1Target}`}
                  value={formData.q1TargetShare}
                  style={{ fontSize: "13px" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, q1TargetShare: e.target.value })
                  }
                />
              )}

              {(selectedIndicator.quarter2Target > 0 ||
                prevFormData.q2TargetShare > 0) && (
                <OutlinedInput
                  error={
                    +formData.q2TargetShare > +selectedIndicator.quarter2Target
                  }
                  name="indicator-assignment-q2"
                  className="col"
                  startAdornment="Q2:"
                  endAdornment={`/${selectedIndicator.quarter2Target}`}
                  value={formData.q2TargetShare}
                  style={{ fontSize: "13px" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, q2TargetShare: e.target.value })
                  }
                />
              )}

              {(selectedIndicator.quarter3Target > 0 ||
                prevFormData.q3TargetShare > 0) && (
                <OutlinedInput
                  error={
                    +formData.q3TargetShare > +selectedIndicator.quarter3Target
                  }
                  name="indicator-assignment-q3"
                  className="col"
                  startAdornment="Q3:"
                  endAdornment={`/${selectedIndicator.quarter3Target}`}
                  value={formData.q3TargetShare}
                  style={{ fontSize: "13px" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, q3TargetShare: e.target.value })
                  }
                />
              )}

              {(selectedIndicator.quarter4Target > 0 ||
                prevFormData.q4TargetShare > 0) && (
                <OutlinedInput
                  error={
                    +formData.q4TargetShare > +selectedIndicator.quarter4Target
                  }
                  name="indicator-assignment-q4"
                  className="col"
                  startAdornment="Q4:"
                  endAdornment={`/${selectedIndicator.quarter4Target}`}
                  value={formData.q4TargetShare}
                  style={{ fontSize: "13px" }}
                  size="small"
                  onChange={(e) =>
                    setFormData({ ...formData, q4TargetShare: e.target.value })
                  }
                />
              )}
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center pb-4">
            <Button
              disabled={
                loading ||
                +formData.q1TargetShare > +selectedIndicator.quarter1Target ||
                +formData.q2TargetShare > +selectedIndicator.quarter2Target ||
                +formData.q3TargetShare > +selectedIndicator.quarter3Target ||
                +formData.q4TargetShare > +selectedIndicator.quarter4Target
              }
              color="primary"
              variant="contained"
              onClick={onSave}
              className="ml-2 px-5"
            >
              {loading ? "Wait..." : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
