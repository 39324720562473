import Appeals from "./Appeals";

const ArchivedAppeals = (props) => {
  const { selectedAdvertisement,onClose } = props;
  return (
    <>
      <Appeals
      onClose={onClose}
        appealCategory="ArchivedAppeals"
        selectedAdvertisement={selectedAdvertisement}
      />
    </>
  );
};

export default ArchivedAppeals;
