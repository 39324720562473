import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Autocomplete,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { findEmployees } from "../../../store/structure/actions";

import React, { useState } from "react";
import { connect } from "react-redux";
import { showError } from "../../toastify";
import { useEffect } from "react";
import axios from "axios";
import EmployeeCard from "./EmployeeCard";
import { useDispatch } from "react-redux";

const EmployeesSearchDialog = (props) => {
  const {
    loading,
    user,
    showEmployeeSearchDialog,
    setShowEmployeeSearchDialog,
    searchedEmployees,
    findEmployees,
  } = props;

  const dispatch = useDispatch();

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState(null);
  const [searchType, setSearchType] = useState("firstName");

  const onClose = () => {
    setShowEmployeeSearchDialog(false);

    dispatch({
      type: "SET_SEARCHED_EMPLOYEES",
      data: [],
    });
  };

  const onFind = async (e) => {
    e.preventDefault();

    findEmployees({
      employeeSearchTerm,
      searchType,
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeSearchDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <span className="ml-0 text-dark"> Find employee | </span>{" "}
            <span className="ml-0">{user.selectedEntitySector.entityName}</span>{" "}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "75vh",
            }}
          >
            <form onSubmit={onFind}>
              <div className="row  mb-3">
                <div className="col-3">
                  <FormControl size="small" fullWidth className="mb-2">
                    <InputLabel id="searchTypeLabel">Search By</InputLabel>
                    <Select
                      labelId="searchTypeLabel"
                      id="searchType"
                      value={searchType}
                      label="Search By"
                      onChange={(e) => {
                        setSearchType(e.target.value);
                      }}
                    >
                      <MenuItem value="firstName">First Name</MenuItem>
                      <MenuItem value="lastName">Last Name</MenuItem>
                      <MenuItem value="email">Email</MenuItem>
                      <MenuItem value="phoneNumber">Phone Number</MenuItem>
                      <MenuItem value="idNumber">NID Number</MenuItem>
                      <MenuItem value="employeeId">Employee ID</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-7">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="searchTerm"
                    label={`Search by (${searchType})`}
                    placeholder={`Search by (${searchType})`}
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={employeeSearchTerm || ""}
                    onChange={(e) => {
                      setEmployeeSearchTerm(e.target.value || "");
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              borderRadius: "0px",
                            }}
                            className={`badge badge-primary`}
                          >
                            {searchType}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-2">
                  <button
                    disabled={loading || !employeeSearchTerm}
                    type="submit"
                    className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
                  >
                    <span className="material-icons">search</span>
                    Search
                  </button>
                </div>
              </div>
            </form>

            {!searchedEmployees.length && (
              <div className="jumbotron jumbotron-fluid text-center  mt-5">
                <div className="container py-5">
                  <p className="lead">
                    {loading ? "Searching..." : "No record found"}{" "}
                  </p>
                </div>
              </div>
            )}

            {!!searchedEmployees.length && (
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">EmployeeId</th>
                    <th scope="col">Names</th>
                    <th scope="col">National ID</th>
                    <th scope="col">RSSBNo</th>
                    <th scope="col">Position</th>
                    <th scope="col">Unit</th>
                    <th scope="col" className="text-right">
                      Level
                    </th>
                    <th scope="col" className="text-right">
                      Index
                    </th>
                    <th scope="col" className="text-right">
                      IndexValue
                    </th>
                    <th scope="col" className="text-right">
                      BaseBay
                    </th>
                    <th scope="col" className="text-right">
                      BRD Repayment
                    </th>
                    <th scope="col" className="text-center">
                      EmployeeGroup
                    </th>

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {searchedEmployees.map((employee, index) => (
                    <EmployeeCard
                      showInstitutionName={false}
                      showActionMenus={true}
                      employee={employee}
                      employeeIndex={index}
                      key={employee.employeePositionId}
                      showUnitName={true}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-2"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, searchedEmployees }) => {
  return {
    loading,
    user,
    searchedEmployees,
  };
};
export default connect(mapStateToProps, { findEmployees })(
  EmployeesSearchDialog
);
