import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { saveOralSchema } from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import ErrorMessage from "../../common/components/ErrorMessage";
import recruitment from "../../../store/recruitment/action-types";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
const AddOralSchemaDialog = (props) => {
  const {
    loading,
    showAddOralSchemaDialog,
    applicantOralExamSchema,
    saveOralSchema,
  } = props;
  const dispatch = useDispatch();
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [base64Data, setBase64Data] = useState(null);
  const [formData, setFormData] = useState({
    examId: applicantOralExamSchema.examId || "",
    markOnBehaviour: applicantOralExamSchema.markOnBehaviour || 0,
    markOnTechnicalKnowledge:
      applicantOralExamSchema.markOnTechnicalKnowledge || 0,
    markOnGeneralKnowledge: applicantOralExamSchema.markOnGeneralKnowledge || 0,
    markOnCommunicationSkills:
      !!applicantOralExamSchema.markOnCommunicationSkills || 0,
    markOnWrittenSkills: applicantOralExamSchema.hasWrittenSkillTest
      ? applicantOralExamSchema.markOnWrittenSkills || 15
      : 0,
    pdfAttachment: "",
  });

  const [errors, setErrors] = useState({
    markOnBehaviour: { hasError: false, message: "" },
    markOnGeneralKnowledge: { hasError: false, message: "" },
    markOnTechnicalKnowledge: { hasError: false, message: "" },
    markOnCommunicationSkills: { hasError: false, message: "" },
    markOnWrittenSkills: { hasError: false, message: "" },
    pdfAttachmentHasError: false,
  });

  const formValidator = () => {
    const errors = {
      markOnBehaviour: { hasError: false, message: "" },
      markOnGeneralKnowledge: { hasError: false, message: "" },
      markOnTechnicalKnowledge: { hasError: false, message: "" },
      markOnCommunicationSkills: { hasError: false, message: "" },
      markOnWrittenSkills: { hasError: false, message: "" },
      hasError: false,
      pdfAttachmentHasError: false,
    };

    if (!formData.markOnBehaviour) {
      errors.markOnBehaviour = {
        hasError: true,
        message: "Mark On Behaviour is required",
      };
      errors.hasError = true;
    } else {
      if (formData.markOnBehaviour <= 0) {
        errors.markOnBehaviour = {
          hasError: true,
          message: "Mark On Behaviour must greater than zero",
        };
        errors.hasError = true;
      }
    }

    if (!formData.markOnGeneralKnowledge) {
      errors.markOnGeneralKnowledge = {
        hasError: true,
        message: "Mark On General Knowledge is required",
      };
      errors.hasError = true;
    } else {
      if (formData.markOnGeneralKnowledge <= 0) {
        errors.markOnBehaviour = {
          hasError: true,
          message: "Mark On General Knowledge must greater than zero",
        };
        errors.hasError = true;
      }
    }

    if (!formData.markOnTechnicalKnowledge) {
      errors.markOnTechnicalKnowledge = {
        hasError: true,
        message: "Mark On Technical Knowledge is required",
      };
      errors.hasError = true;
    } else {
      if (formData.markOnTechnicalKnowledge <= 0) {
        errors.markOnTechnicalKnowledge = {
          hasError: true,
          message: "Mark On Technical Knowledge must greater than zero",
        };
        errors.hasError = true;
      }
    }
    //markOnCommunicationSkills

    if (!formData.markOnCommunicationSkills) {
      errors.markOnCommunicationSkills = {
        hasError: true,
        message: "Mark On Communication Skills is required",
      };
      errors.hasError = true;
    } else {
      if (formData.markOnCommunicationSkills <= 0) {
        errors.markOnCommunicationSkills = {
          hasError: true,
          message: "Mark On Communication Skills must greater than zero",
        };
        errors.hasError = true;
      }
    }

    if (!formData.pdfAttachment) {
      errors.pdfAttachmentHasError = true;
      errors.hasError = true;
    }
    setErrors(errors);

    if (errors.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.pdfAttachment = formData.pdfAttachment.split(",")[1];
      if (totalMarks() === 50) saveOralSchema(formData, onClose);
      else showError("Total Marks must equal to 50");
    }
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };
  const isFileTooLarge = (file) => {
    const size = file.size;
    // let fileSize = 0;
    // if (size < 1000 * 1000 * 1000) {
    //   fileSize = size / 1000 / 1000;
    // }
    return size > 1074176 ? true : false;
  };

  const handleUploadedPDFFile = (event) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });
    setFormData({
      ...formData,
      pdfAttachment: "",
    });
    if (
      !(
        pdfAttachment.type === "application/pdf" &&
        convertToLower(pdfAttachment.name.substr(-4)) === ".pdf"
      )
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Get the Base64 data
        const base64Data = e.target.result;
        setBase64Data(base64Data);
        setFormData({
          ...formData,
          pdfAttachment: base64Data,
        });
      };

      // Read the file as Data URL
      reader.readAsDataURL(pdfAttachment);
    }
  };

  const totalMarks = () => {
    return (
      isNull(formData.markOnBehaviour) +
      isNull(formData.markOnCommunicationSkills) +
      isNull(formData.markOnGeneralKnowledge) +
      isNull(formData.markOnTechnicalKnowledge) +
      isNull(formData.markOnWrittenSkills)
    );
  };

  const onClose = () => {
    dispatch({
      type: recruitment.SET_SHOW_ADD_ORAL_SCHEMA_DIALOG,
      data: false,
    });
  };

  const isNull = (value) => {
    return value ? parseFloat(value) : 0;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={showAddOralSchemaDialog}
        // fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Oral Mark Setting
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          {showUploadedDocument && base64Data && (
            <PreviewPdfUrl
              isBase64={true}
              showDocument={showUploadedDocument}
              setShowDocument={setShowUploadedDocument}
              document={{
                name: `View Document`,
                url: base64Data || "",
              }}
            />
          )}
          <div
            className="row"
            style={{
              height: "450px",
            }}
          >
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Mark On Behaviour
                      </span>
                    </div>
                    <input
                      type="number"
                      onChange={(e) => {
                        const markOnBehaviour = e.target.value;

                        const s = markOnBehaviour
                          ? parseInt(markOnBehaviour)
                          : 0;
                        setFormData({
                          ...formData,
                          markOnBehaviour,
                        });

                        const errormarkOnBehaviour = {
                          hasError: false,
                          message: "",
                        };

                        if (s <= 0) {
                          errormarkOnBehaviour.hasError = true;
                          errormarkOnBehaviour.message =
                            "Mark On Behaviour must greater than zero";
                        }

                        setErrors({
                          ...errors,
                          markOnBehaviour: errormarkOnBehaviour,
                        });
                      }}
                      value={formData.markOnBehaviour}
                      className="form-control col text-center"
                      aria-label="Mark On Behaviour"
                    />
                  </div>
                  <ErrorMessage
                    hasError={errors.markOnBehaviour.hasError}
                    message={errors.markOnBehaviour.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend  col-6">
                      <span className="input-group-text w-100">
                        Mark On General Knowledge
                      </span>
                    </div>
                    <input
                      type="number"
                      onChange={(e) => {
                        const markOnGeneralKnowledge = e.target.value;

                        const s = markOnGeneralKnowledge
                          ? parseInt(markOnGeneralKnowledge)
                          : 0;
                        setFormData({
                          ...formData,
                          markOnGeneralKnowledge,
                        });

                        const errormarkOnGeneralKnowledge = {
                          hasError: false,
                          message: "",
                        };

                        if (s <= 0) {
                          errormarkOnGeneralKnowledge.hasError = true;
                          errormarkOnGeneralKnowledge.message =
                            "Mark On General Knowledge is less than zero";
                        }

                        setErrors({
                          ...errors,
                          markOnGeneralKnowledge: errormarkOnGeneralKnowledge,
                        });
                      }}
                      value={formData.markOnGeneralKnowledge}
                      className="form-control col text-center"
                      aria-label="Mark On General Knowledge"
                    />
                  </div>
                  <ErrorMessage
                    hasError={errors.markOnGeneralKnowledge.hasError}
                    message={errors.markOnGeneralKnowledge.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Mark On Technical Knowledge
                      </span>
                    </div>
                    <input
                      type="number"
                      onChange={(e) => {
                        const markOnTechnicalKnowledge = e.target.value;

                        const s = markOnTechnicalKnowledge
                          ? parseInt(markOnTechnicalKnowledge)
                          : 0;
                        setFormData({
                          ...formData,
                          markOnTechnicalKnowledge,
                        });

                        const errormarkOnTechnicalKnowledge = {
                          hasError: false,
                          message: "",
                        };

                        if (s <= 0) {
                          errormarkOnTechnicalKnowledge.hasError = true;
                          errormarkOnTechnicalKnowledge.message =
                            "Mark On Technical Knowledge must greater than zero";
                        }

                        setErrors({
                          ...errors,
                          markOnTechnicalKnowledge:
                            errormarkOnTechnicalKnowledge,
                        });
                      }}
                      value={formData.markOnTechnicalKnowledge}
                      className="form-control col text-center"
                      aria-label="Mark On Technical Knowledge"
                    />
                  </div>
                  <ErrorMessage
                    hasError={errors.markOnTechnicalKnowledge.hasError}
                    message={errors.markOnTechnicalKnowledge.message}
                  />
                </div>

                <div className="col-lg-12 mt-2">
                  <div className="input-group mb-3 row no-gutters">
                    <div className="input-group-prepend col-6">
                      <span className="input-group-text w-100">
                        Mark On Communication Skills
                      </span>
                    </div>
                    <input
                      type="number"
                      onChange={(e) => {
                        const markOnCommunicationSkills = e.target.value;

                        const s = markOnCommunicationSkills
                          ? parseInt(markOnCommunicationSkills)
                          : 0;
                        setFormData({
                          ...formData,
                          markOnCommunicationSkills,
                        });

                        const errormarkOnCommunicationSkills = {
                          hasError: false,
                          message: "",
                        };

                        if (s <= 0) {
                          errormarkOnCommunicationSkills.hasError = true;
                          errormarkOnCommunicationSkills.message =
                            "Mark On Communication Skills is less than zero";
                        }

                        setErrors({
                          ...errors,
                          markOnCommunicationSkills:
                            errormarkOnCommunicationSkills,
                        });
                      }}
                      value={formData.markOnCommunicationSkills}
                      className="form-control col text-center"
                      aria-label="Mark On Communication Skills"
                    />
                  </div>
                  <ErrorMessage
                    hasError={errors.markOnCommunicationSkills.hasError}
                    message={errors.markOnCommunicationSkills.message}
                  />
                </div>
                {applicantOralExamSchema.hasWrittenSkillTest && (
                  <div className="col-lg-12 mt-2">
                    <div className="input-group mb-3 row no-gutters">
                      <div className="input-group-prepend col-6">
                        <span className="input-group-text w-100">
                          Mark On Written Skills
                        </span>
                      </div>
                      <input
                        type="number"
                        disabled={true}
                        onChange={(e) => {
                          const markOnWrittenSkills = e.target.value;

                          const s = markOnWrittenSkills
                            ? parseInt(markOnWrittenSkills)
                            : 0;
                          setFormData({
                            ...formData,
                            markOnWrittenSkills,
                          });

                          const errorMarkOnWrittenSkills = {
                            hasError: false,
                            message: "",
                          };

                          if (s <= 0) {
                            errorMarkOnWrittenSkills.hasError = true;
                            errorMarkOnWrittenSkills.message =
                              "Mark On Written Skills is less than zero";
                          }

                          setErrors({
                            ...errors,
                            markOnWrittenSkills: errorMarkOnWrittenSkills,
                          });
                        }}
                        value={formData.markOnWrittenSkills}
                        className="form-control col text-center"
                        aria-label="Mark On Written Skills"
                      />
                    </div>
                    <ErrorMessage
                      hasError={errors.markOnWrittenSkills.hasError}
                      message={errors.markOnWrittenSkills.message}
                    />
                  </div>
                )}

                <div className="col-lg-12">
                  Oral Exam Question PDF(* only pdf file)
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-paperclip"></i>
                      </span>
                    </div>

                    <input
                      type="file"
                      name="file"
                      className="form-control"
                      accept="application/pdf"
                      placeholder="Select pdf file"
                      onChange={(e) => handleUploadedPDFFile(e)}
                    />
                    {base64Data && (
                      <>
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setShowUploadedDocument(true);
                            }}
                          >
                            View document
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <ErrorMessage
                    hasError={errors.pdfAttachmentHasError}
                    message={
                      <>
                        <div>
                          Please check following issues:
                          <br />
                          File should be pdf format.
                        </div>
                      </>
                    }
                  />
                </div>

                <div className="col-12 mt-3">
                  <div className="input-group input-group-sm mt-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Total Marks
                      </span>
                    </div>
                    <div className="form-control">
                      <div
                        className="form-check form-check-inline"
                        style={{
                          float: "right",
                        }}
                      >
                        <div
                          style={{
                            padding: "0px",
                            marginTop: "-2px",

                            width: "70px",
                            height: "auto",
                            border: `solid 3px ${
                              totalMarks() > 50 || totalMarks() < 50
                                ? "#dc3545"
                                : ""
                            }`,
                            borderRadius: "50px",
                            textAlign: "center",
                          }}
                        >
                          {totalMarks()} / 50
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-1">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  showAddOralSchemaDialog,
  applicantOralExamSchema,
}) => {
  return {
    loading,
    showAddOralSchemaDialog,
    applicantOralExamSchema,
  };
};
export default connect(mapStateToProps, {
  saveOralSchema,
})(AddOralSchemaDialog);
