import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import PreLoader from "../../common/components/PreLoader";
import { useEffect } from "react";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { geGetLeaveLogs } from "../../../store/leave/actions";

const LeaveLogsDialog = (props) => {
  const { loading, setShowLogs, selectLeave, geGetLeaveLogs } = props;
  const [leaveLogs, setLeaveLogs] = React.useState([]);
  const [loadingLogs, setLoadingLogs] = React.useState(true);

  useEffect(
    () => {
      geGetLeaveLogs(selectLeave.id, (status, data) => {
        setLoadingLogs(status);
        if (!status) {
          setLeaveLogs(data);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectLeave]
  );

  const onClose = () => {
    setShowLogs(null);
  };

  const formattingDate = (dateTimeString) => {
    const [datePart, rawTimePart] = dateTimeString.split("T");
    const timePart = rawTimePart.replace(/\.\d+Z$/, "");
    return datePart + " " + timePart;
  };

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="md">
        {!loading && (
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              Leave logs.
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent dividers>
          <div
            style={{
              height: "550px",
            }}
          >
            <PreLoader isLoading={loadingLogs} />

            {!loadingLogs && !!leaveLogs.length && (
              <>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <p className="contact_p">
                    <>
                      {leaveLogs.map((item, index) => (
                        <Timeline
                          key={index}
                          position="alternate"
                          sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                              flex: 0.2,
                            },
                          }}
                        >
                          <TimelineItem
                            style={{
                              width: "100%",
                            }}
                          >
                            <TimelineOppositeContent
                              sx={{ m: "auto 0" }}
                              align="right"
                              variant="body2"
                            >
                              <h2
                                style={{
                                  fontSize: ".85rem",
                                  fontWeight: 600,
                                  overflowWrap: "break-word",
                                  marginBottom: "8px",
                                  textAlign: "left",
                                  paddingRight: "8px",
                                  width: "200px",
                                }}
                              >
                                <span className="text-primary">
                                  {item?.statusName}
                                </span>
                              </h2>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot
                                color={
                                  item.statusId === 7
                                    ? "error"
                                    : item.statusId === 6
                                    ? "success"
                                    : [2, 4].includes(item.statusId)
                                    ? "primary"
                                    : item.statusId === 5
                                    ? "info"
                                    : item.statusId === 9
                                    ? "warning"
                                    : "grey"
                                }
                              />

                              <TimelineConnector />
                            </TimelineSeparator>
                            {/* <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot variant="outlined" color="secondary" />

                            <TimelineConnector />
                          </TimelineSeparator> */}
                            <TimelineContent>
                              <div className="mt-2">
                                <p>
                                  <div className="row">
                                    <div className="col-6">
                                      <span
                                        className="float-left"
                                        style={{ fontSize: "9px" }}
                                      >
                                        {item.names}
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <span
                                        className="float-right"
                                        style={{ fontSize: "9px" }}
                                      >
                                        {formattingDate(item.createdOn)}
                                      </span>
                                    </div>
                                  </div>
                                </p>
                                <p
                                  style={{
                                    fontSize: ".85rem",
                                    fontWeight: 400,
                                    color: "#344457",
                                    width: "100%",
                                  }}
                                >
                                  {item.comments}
                                </p>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                      ))}
                    </>
                  </p>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  geGetLeaveLogs,
})(LeaveLogsDialog);
