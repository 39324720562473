import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRequestTimelines } from "../../../store/training/actions";
import "react-quill/dist/quill.snow.css";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
const RequestTimelineDialog = (props) => {
  const {
    showRequestTimeline,
    setShowRequestTimeline,
    requestTimelines,
    getRequestTimelines,
    request,
  } = props;

  const [loading, setLoading] = useState(false);
  const [hoveredRequest, setHoveredRequest] = useState(null);

  useEffect(() => {
    getRequestTimelines(setLoading, request.id);
  }, []);
  const onClose = () => {
    setShowRequestTimeline(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRequestTimeline}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-dark">
          <Typography variant="overline" display="block">
            Time Tracking
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "7px" }}
            >
              chevron_right
            </span>
            <span className="text-primary">
              {request.lastName} {request.firstName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "100vh" }}>
          <table className="table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  Level
                </th>
                <th scope="col" className="text-left">
                  Expected Starting On
                </th>

                <th scope="col" className="text-left">
                  Deadline
                </th>

                <th scope="col" className="text-left">
                  Sent By
                </th>
                <th scope="col" className="text-left">
                  Sent On
                </th>

                <th scope="col" className="text-left">
                  Approved By
                </th>
                <th scope="col" className="text-left">
                  Approved On
                </th>
                <th scope="col" className="text-left">
                  Remaining Duration
                </th>

                <th scope="col" className="text-left">
                  Spent Duration
                </th>
                <th scope="col" className="text-left">
                  Expected Duration
                </th>
              </tr>
            </thead>

            {loading ? (
              <TableSkeleton cols={10} />
            ) : (
              <tbody>
                {requestTimelines.map((request, index) => (
                  <tr
                    key={index}
                    onMouseEnter={() => setHoveredRequest(request)}
                    onFocus={() => setHoveredRequest(request)}
                    onBlur={() => setHoveredRequest(null)}
                    onMouseLeave={() => setHoveredRequest(null)}
                    style={{
                      backgroundColor:
                        hoveredRequest === request ? `#cdeef8!important` : "",
                      border:
                        hoveredRequest === request
                          ? "2px solid #376fc8"
                          : "none",
                      cursor: hoveredRequest === request ? "pointer" : "",
                      boxShadow:
                        hoveredRequest === request
                          ? "inset 0px 0px 0px 1px transparent"
                          : "",
                    }}
                  >
                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      <strong> {request.levelName} </strong>
                    </td>
                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {moment(request.expectedStartDate).format("lll")}
                    </td>
                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {moment(request.deadline).format("lll")}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {request.sentBy}
                    </td>

                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {moment(request.sentOn).format("lll")}
                    </td>

                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {request.actedBy ? (
                        request.actedBy
                      ) : (
                        <>
                          <span className="text-warning">Pending ..</span>
                        </>
                      )}
                    </td>

                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {request.actedOn ? (
                        <>{moment(request.actedOn).format("lll")}</>
                      ) : (
                        <>
                          <span className="text-warning">Pending ..</span>
                        </>
                      )}
                    </td>

                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      <span
                        className={`text-${
                          request.isOverdue ? "danger" : "primary"
                        }`}
                      >
                        {" "}
                        {request.remainingDuration}
                      </span>
                    </td>
                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      {request.spentDuration}
                    </td>
                    <td
                      className="text-left"
                      style={{
                        backgroundColor:
                          hoveredRequest === request ? `#cdeef8` : "",
                      }}
                    >
                      <span className={`text-success`}>
                        {request.expectedDuration} Day(s)
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {!requestTimelines.length && !loading && (
            <div className="jumbotron jumbotron-fluid text-center">
              <div className="container py-5">
                <p className="lead">No record found</p>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, requestTimelines }) => {
  return {
    loading,
    user,
    requestTimelines,
  };
};
export default connect(mapStateToProps, {
  getRequestTimelines,
})(RequestTimelineDialog);
