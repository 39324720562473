import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import {
  deletePosition,
  deleteSharedPosition,
  setAsPlanner,
  setAsHeadOfUnity,
  getPosition,
} from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import PositionEmployeesDialog from "./PositionEmployeesDialog";
import PositionProfileDialog from "./PositionProfileDialog";
// import UpdateSharedPositionDialog from "../components/UpdateSharedPositionDialog";
import AddRequisitionDialog from ".././../recruitment/components/AddRequisitionDialog";
import PositionRolesDialog from "./PositionRolesDialog";
import PositionSalarySettingsDialog from "./PositionSalarySettingsDialog";
import PositionSetActingDialog from "./PositionSetActingDialog";
import AddEmployeeDialog from "./AddEmployeeDialog";
import { currencyFormat } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import PendingIcon from "@mui/icons-material/Pending";
const PositionCard = (props) => {
  const {
    loading,
    position,
    positionIndex,
    deletePosition,
    deleteSharedPosition,
    isEntitySectorLocked,
    selectedUnit,
    env,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();
  const open = Boolean(anchorEl);

  const [confirmRemovePosition, setConfirmRemovePosition] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [showPostionEmployeesModal, setShowPostionEmployeesModal] =
    useState(false);
  const [showUpdatePositionModal, setShowUpdatePositionModal] = useState(false);
  const [showUpdateSharedPositionModal, setShowUpdateSharedPositionModal] =
    useState(false);
  const [showAddRequisitionForm, setShowAddRequisitionForm] = useState(false);

  const [showPositionSetActingDialog, setShowPositionSetActingDialog] =
    useState(false);

  const [showPostionWithHoldsModal, setShowPostionWithHoldsModal] =
    useState(false);

  const [showPositionRolesModel, setShowPositionRolesModel] = useState(false);
  const [showPayrollSettingsDialog, setShowPayrollSettingsDialog] =
    useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const textColor = (position) => {
    let borderColor = "dark";

    if (position.numberOfVacantPosts !== 0 || position.numberOfPosts <= 0) {
      borderColor = "danger";
    }

    if (position.isHeadOfUnit && position.numberOfVacantPosts === 0) {
      borderColor = "primary";
    }

    return borderColor;
  };

  // const checkDisabled = (position) => {
  //   if (position.isShared === false) return true;
  //   else return false;
  // };

  const [showEmployeeForm, setShowEmployeeForm] = useState(false);

  return (
    <>
      <tr className={`text-${textColor(position)}`}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {positionIndex + 1}
        </th>
        <td>
          {" "}
          <span className={``}>
            {position.name}
            {position.isRISASharedStaff &&
              (selectedUnit.unitId === "1903000000-150200000000000000" ||
                selectedUnit.unitId === "1903000000-150201000000000000") && (
                <span>({position.entityName})</span>
              )}
          </span>
          {position.isCBM && (
            <span className="badge badge-secondary ml-1">(CBM)</span>
          )}
          {position.isTechnicalHead && (
            <span className="badge badge-secondary ml-1">(TECH._HEAD)</span>
          )}
          {position.isHeadOfInstitution && (
            <span className="badge badge-secondary ml-1">(HEAD_OF_INS.)</span>
          )}
          {position.isHeadOfUnit && (
            <span className="badge badge-secondary ml-1">(HEAD_OF_UNIT)</span>
          )}
          {position.isPlanner && (
            <span className="badge badge-secondary ml-1">(Planner.)</span>
          )}
          {position.isHR && (
            <span className="badge badge-secondary ml-1">(HR)</span>
          )}
          {position.isDAF && (
            <span className="badge badge-secondary ml-1">(DAF)</span>
          )}
          {(position.isShared ||
            position.isSharing ||
            position.isRISASharedStaff) && (
            <span className="badge badge-pill badge-light">
              <span
                style={{ fontSize: "14px" }}
                className={`ml-1 ${
                  position.isShared || position.isRISASharedStaff
                    ? "shared"
                    : "sharing"
                }`}
              >
                <i className="fas fa-share"></i>
              </span>
              <small>
                {position.isShared || position.isRISASharedStaff
                  ? "Shared"
                  : "Sharing"}
                {!!position.sharedByName && (
                  <span className="text-primary">
                    {" "}
                    by {position.sharedByName}
                  </span>
                )}
              </small>
            </span>
          )}
          {position.hasActing && (
            <span className="badge badge-pill badge-light acting">
              <span style={{ fontSize: "20px" }} className="ml-1">
                <i className="fas fa-user"></i>
              </span>
              <small className="ml-1">Acting</small>
            </span>
          )}
          {!position.isOnStructure && !position.isShared && (
            // <span className="badge badge-danger ml-1">Not on structure</span>
            <Chip
              style={{ borderRadius: "0px" }}
              className="ml-1 small-chip"
              label="Not on structure"
              color="error"
              size="small"
              variant="outlined"
            />
          )}
          {!!position.numberOnWaitingList &&
            position.numberOfVacantPosts > 0 &&
            !position.numberOfPendingWaitingListRequests && (
              <span className="badge badge-primary">
                ({position.numberOnWaitingList}) on waiting list
              </span>
            )}
          {!!position.numberOfPendingWaitingListRequests && (
            <span className="badge badge-success">
              ({position.numberOfPendingWaitingListRequests}) requested from
              waiting list
            </span>
          )}
          {!!position.wasPublished && (
            <span className="badge badge-primary">Advertized</span>
          )}
        </td>
        <td className="text-left">{position.hiringModeName}</td>

        <td className="text-center">
          {position.levelName}.{position.scaleName}
        </td>
        <td className="text-right">{position.index}</td>
        <td className="text-right">{position.indexValue}</td>

        <td className="text-right" style={{ backgroundColor: "#e9ecef" }}>
          {currencyFormat((position.indexValue || 0) * (position.index || 0))}
        </td>
        <td className="text-center">{position.employeeGroupName}</td>

        {/* <td className="text-center">{position.numberOfVacantPosts}</td> */}

        <td className="text-right">
          {position.numberOfPosts - position.numberOfVacantPosts}
          {"/"}
          {position.numberOfPosts}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowUpdatePositionModal(true);
                }}
                className="text-dark font-weight-bold text-primary"
              >
                <span className="material-icons mr-1">work</span>
                Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {!position.isShared && position.numberOfVacantPosts > 0 && (
              /*position.hiringModeId !== 2 &&*/ <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    handleCloseMenu();
                    setShowEmployeeForm(true);
                  }}
                  className="text-primary font-weight-bolder"
                >
                  <span className="material-icons mr-1">person_add</span>
                  Add Employee
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {((!position.isShared && !position.isRISASharedStaff) ||
              (position.isRISASharedStaff &&
                (selectedUnit.unitId === "1903000000-150200000000000000" ||
                  selectedUnit.unitId ===
                    "1903000000-150201000000000000"))) && (
              <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    handleCloseMenu();
                    setShowPayrollSettingsDialog(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">tune</span>
                  Salary Settings
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setShowPostionEmployeesModal(true);
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">group</span>
                Employees
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {hasPermissions(["IS_STRUCTURE_OVERSIGHT", "IS_HR"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowPositionRolesModel(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span
                    className="material-icons mr-1"
                    style={{ transform: "rotate(-90deg)" }}
                  >
                    key
                  </span>
                  Roles
                </MenuItem>
              </span>
            )}

            {!position.isShared &&
              !(
                position.isRISASharedStaff &&
                (selectedUnit.unitId === "1903000000-150200000000000000" ||
                  selectedUnit.unitId === "1903000000-150201000000000000")
              ) && (
                <span>
                  <MenuItem
                    disabled={
                      position.numberOfVacantPosts < 1 ||
                      position.wasPublished ||
                      loading ||
                      !hasPermissions(["IS_HR", "IS_TEST_USER"])
                    }
                    onClick={() => {
                      handleCloseMenu();
                      setShowAddRequisitionForm(true);
                    }}
                    className="text-dark font-weight-light"
                  >
                    <span
                      className="material-icons mr-1"
                      style={{ transform: "rotateY(180deg)" }}
                    >
                      reply
                    </span>
                    {position.wasPublished ? "Advertized" : "Advertize"}
                  </MenuItem>
                </span>
              )}

            {!position.isShared && (
              <span>
                <MenuItem
                  disabled={loading || !hasPermissions(["IS_HR"])}
                  onClick={() => {
                    handleCloseMenu();
                    setShowPositionSetActingDialog(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">hourglass_bottom</span>
                  Set Acting
                </MenuItem>
              </span>
            )}

            {/* {env !== "prod" && ( */}
            {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    setSelectedPosition(position);
                    setConfirmRemovePosition(true);
                    handleCloseMenu();
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-1">highlight_off</span>
                  Remove
                </MenuItem>
              </span>
            )}
            {/* )} */}
          </Menu>
        </td>
      </tr>
      {confirmRemovePosition && (
        <ConfirmationDialog
          confirmationDialog={confirmRemovePosition}
          message={`Are you sure you want to remove this ${
            selectedPosition.isShared ? "shared" : ""
          } position "${selectedPosition.name}"?`}
          setConfirmationDialog={setConfirmRemovePosition}
          onYes={() => {
            if (selectedPosition.isShared) {
              deleteSharedPosition(
                selectedPosition,
                setConfirmRemovePosition,
                setSelectedPosition
              );
            } else {
              deletePosition(
                selectedPosition,
                setConfirmRemovePosition,
                setSelectedPosition
              );
            }
          }}
        />
      )}

      {showPostionEmployeesModal && (
        <PositionEmployeesDialog
          showPostionEmployeesModal={showPostionEmployeesModal}
          setShowPostionEmployeesModal={setShowPostionEmployeesModal}
          position={position}
        />
      )}

      {!!showUpdatePositionModal && (
        <PositionProfileDialog
          isEntitySectorLocked={isEntitySectorLocked}
          showUpdatePositionModal={showUpdatePositionModal}
          setShowUpdatePositionModal={setShowUpdatePositionModal}
          position={position}
        />
      )}
      {showPayrollSettingsDialog && (
        <PositionSalarySettingsDialog
          isEntitySectorLocked={isEntitySectorLocked}
          showPayrollSettingsDialog={showPayrollSettingsDialog}
          setShowPayrollSettingsDialog={setShowPayrollSettingsDialog}
          position={position}
        />
      )}
      {/* {showUpdateSharedPositionModal && (
        <UpdateSharedPositionDialog
          isEntitySectorLocked={isEntitySectorLocked}
          showUpdateSharedPositionModal={showUpdateSharedPositionModal}
          setShowUpdateSharedPositionModal={setShowUpdateSharedPositionModal}
          position={position}
        />
      )} */}

      {showPositionRolesModel && (
        <PositionRolesDialog
          isEntitySectorLocked={isEntitySectorLocked}
          showPositionRolesModel={showPositionRolesModel}
          setShowPositionRolesModel={setShowPositionRolesModel}
          selectedPosition={position}
        />
      )}

      <AddRequisitionDialog
        position={position}
        showAddRequisitionForm={showAddRequisitionForm}
        setShowAddRequisitionForm={setShowAddRequisitionForm}
      />

      {showPositionSetActingDialog && (
        <PositionSetActingDialog
          position={position}
          showPositionSetActingDialog={showPositionSetActingDialog}
          setShowPositionSetActingDialog={setShowPositionSetActingDialog}
        />
      )}

      {showEmployeeForm && (
        <AddEmployeeDialog
          showEmployeeForm={showEmployeeForm}
          setShowEmployeeForm={setShowEmployeeForm}
          selectedUnit={selectedUnit}
          position={position}
        />
      )}

      {/* <Card
        className={`mb-3 py-3 indicator-card border border-${borderColor(
          position
        )} `}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="row">
          <div className="col-sm-10 col-xs-10 col-md-10 col-lg-10 justify-content-center">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <span
                  style={{ fontSize: "12px", cursor: "default" }}
                  className={`ml-2 font-weight-bold text-${borderColor(
                    position
                  )}`}
                >
                  {position.name}

                  <Stack direction="row" spacing={1} className="mt-2 ml-2">
                    {position.isHeadOfInstitution && (
                      <Chip
                        label="Head Of Institution"
                        color="success"
                        size="small"
                        variant="outlined"
                      />
                    )}
                    {position.isCBM && (
                      <Chip
                        label="CBM"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {position.isHeadOfUnit && (
                      <Chip
                        label="Head Of Unit"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    ) 
                    }

                    {position.isPlanner && (
                      <Chip
                        label="Planner"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {position.isTechnicalHead && (
                      <Chip
                        label="Technical Head"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {position.shared && (
                      <Chip
                        icon={<Shared />}
                        label="Shared"
                        color="success"
                        size="small"
                      />
                    )}

                    {position.sharing && (
                      <Chip
                        icon={<Shared />}
                        label="Sharing"
                        color="primary"
                        size="small"
                      />
                    )}

                    {position.hasActing && (
                      <Chip
                        icon={<PersonIcon />}
                        label="Acting"
                        color="primary"
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {!position.isOnStructure && (
                      <Chip
                        label="Not on structure"
                        color="error"
                        size="small"
                        variant="outlined"
                      />
                    )}
                  </Stack>
                </span>
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <span className="d-flex align-items-center flex-wrap m-2">
                  <Tooltip title="EmployementGroup">
                    <span
                      className={`align-items-center px-1 border-${borderColor(
                        position
                      )}  text-${borderColor(position)}`}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "default",
                        fontSize: "13px",
                      }}
                    >
                      <strong>EmployementGroup:</strong>
                      {position.employeeGroupName}
                    </span>
                  </Tooltip>

                  <Tooltip title="Level">
                    <span
                      className={`align-items-center px-1 border-${borderColor(
                        position
                      )}  text-${borderColor(position)}`}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "default",
                        fontSize: "13px",
                      }}
                    >
                      <strong>Level:</strong>
                      {position.levelName}.{position.echelonName}
                    </span>
                  </Tooltip>

                  <Tooltip title="IV">
                    <span
                      className={`align-items-center px-1 border-${borderColor(
                        position
                      )}  text-${borderColor(position)}`}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "default",
                        fontSize: "13px",
                      }}
                    >
                      <strong>IV:</strong> {position.indexValueId}
                    </span>
                  </Tooltip>

                  <Tooltip title="#Posts">
                    <span
                      className={`align-items-center px-1 border-${borderColor(
                        position
                      )}  text-${borderColor(position)}`}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "default",
                        fontSize: "13px",
                      }}
                    >
                      <strong>#Posts:</strong> {position.number}
                    </span>
                  </Tooltip>

                  <Tooltip title="HiringMode">
                    <span
                      className={`align-items-center px-1 border-${borderColor(
                        position
                      )}  text-${borderColor(position)}`}
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "default",
                        fontSize: "13px",
                      }}
                    >
                      <strong>HiringMode:</strong> {position.hiringModeName}
                    </span>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-xs-2 col-md-2 col-lg-2 justify-content-center  text-right">
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
            onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowUpdatePositionModal(true);
                  }}
                  className="text-dark font-weight-light"
                >
                Details{!position.isShared && "/Update"}
                </MenuItem>
                <Divider className="my-1" />
              </span>

              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowPostionEmployeesModal(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  Employees
                </MenuItem>
                <Divider className="my-1" />
              </span>

              {!position.isShared && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleSetAsHeadOfUnity(position);
                    }}
                    className="text-dark font-weight-light"
                  >
                    {position.isHeadOfUnit?'Unset':'Set'} as Head Of Unit
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )
              }

              {!position.isShared && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      handleSetPlanner(position);
                    
                    }}
                    className="text-dark font-weight-light"
                  >
                    {position.isPlanner?'Unset':'Set'} as {position.isPlanner ? "not" : ""} Planner
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )
              }

              {!position.isShared && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      console.log(position);
                      if (position.hiringModeId !== 1 && position.hiringModeId !== 4)
                      return showError(
                        "Hiring Mode of this position is not Recruitment"
                      );
              
                    if (position.numberOfVacantPost <= 0)
                      return showError("This position has no vacant post");
              
                    if (!position.description)
                      return showError("This position has no description");
              
                    showSuccess("Comming soon!");
                    }}
                    className="text-dark font-weight-light"
                  >
                    Request to Publish
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )}

              {!position.isShared && (
                <span>
                  <MenuItem
                    onClick={() => {
                        setSelectedPosition(position);
                        setConfirmRemovePosition(true);
                        handleCloseMenu();
                    }}
                    className="text-danger font-weight-light"
                  >
                    Delete
                  </MenuItem>
                </span>
              )
              }

            </Menu>
          </div>
        </div>

        {confirmRemovePosition && (
              <ConfirmationDialog
                confirmationDialog={confirmRemovePosition}
                message={`Are you sure you want to remove this position @${selectedPosition.name}?`}
                setConfirmationDialog={setConfirmRemovePosition}
                onYes={() => {
                    deletePosition(
                      selectedPosition,
                      setConfirmRemovePosition,
                      setSelectedPosition
                    );
                }}
              />
            )
          }

            {showPostionEmployeesModal && <PositionEmployeesDialog 
             showPostionEmployeesModal={showPostionEmployeesModal}
             setShowPostionEmployeesModal={setShowPostionEmployeesModal}
             position={position} />}

            {showUpdatePositionModal && <PositionProfileDialog 
             showUpdatePositionModal={showUpdatePositionModal}
             setShowUpdatePositionModal={setShowUpdatePositionModal}
             position={position} />}




      </Card> */}
    </>
  );
};

const mapStateToProps = ({ env, loading, user }) => {
  return {
    env,
    loading,
    user,
  };
};

export default connect(mapStateToProps, {
  deletePosition,
  deleteSharedPosition,
  setAsPlanner,
  setAsHeadOfUnity,
  getPosition,
})(PositionCard);
