import React from "react";
import AutomaticRemovalOffice from "./AutomaticRemovalOffice";
import Secondment from "./Secondment";
import DismissalLetter from "./DismissalLetter";
import ChangeOfJobPosition from "./ChangeOfJobPosition";
import LeaveOfAbsenceForNonSpecificPeriod from "./LeaveOfAbsenceForNonSpecificPeriod";
import Resignation from "./Resignation";
import EarlyRetirement from "./EarlyRetirement";
import Cessation from "./Cessation";
import Death from "./Death";
import MandatoryRetirement from "./MandatoryRetirement";
import JobRotationForSupervisors from "./JobRotationForSupervisors";
import ContractTermination from "./ContractTermination";
import RCSDisciplinarySuspension from "./RCSDisciplinarySuspension";
import LeaveOfAbsenceForSpecificPeriod from "./LeaveOfAbsenceForSpecificPeriod";
import InternalTransferLetter from "./InternalTransferLetter";
import ExternalTransferLetter from "./ExternalTransferLetter";
import DisciplineWarningLetter from "./DisciplineWarningLetter";
import DisciplineDismissalLetter from "./DisciplineDismissalLetter";
import DisciplineReprimandLetter from "./DisciplineReprimandLetter";
const CommonLetter = (props) => {


  return (
    <>
      {(parseInt(props.exitTypeId) === 6) && <ExternalTransferLetter {...props} />}
      {(parseInt(props.exitTypeId) === 0) && <InternalTransferLetter {...props} />}
      
      {parseInt(props.exitTypeId) === 9 && <AutomaticRemovalOffice {...props} />}
      {parseInt(props.exitTypeId) === 10 && <Secondment {...props} />}
      {parseInt(props.exitTypeId) === 8 && <DismissalLetter {...props} />}
      {parseInt(props.exitTypeId) === 1 && <ChangeOfJobPosition {...props} />}
      {parseInt(props.exitTypeId) === 2 && <LeaveOfAbsenceForNonSpecificPeriod {...props} />}
      {parseInt(props.exitTypeId) === 1003 && <LeaveOfAbsenceForSpecificPeriod {...props} />}
      {parseInt(props.exitTypeId) === 3 && <Resignation {...props} />}
      {parseInt(props.exitTypeId) === 4 && <EarlyRetirement {...props} />}
      {parseInt(props.exitTypeId) === 5 && <Cessation {...props} />}
      {parseInt(props.exitTypeId) === 7 && <Death {...props} />}
      {parseInt(props.exitTypeId) === 11 && <MandatoryRetirement {...props} />}
      {parseInt(props.exitTypeId) === 12 && <JobRotationForSupervisors {...props} />}
      {parseInt(props.exitTypeId) === 13 && <ContractTermination {...props} />}
      {parseInt(props.exitTypeId) === 14 && <RCSDisciplinarySuspension {...props} />}

      {/* //DIS */}
      {parseInt(props.caseSanctionId) === 1 && <DisciplineWarningLetter {...props} />}
      {parseInt(props.caseSanctionId) === 6 && <DisciplineDismissalLetter {...props} />}
      {parseInt(props.caseSanctionId) === 2 && <DisciplineReprimandLetter {...props} />}
      
      
      
    </>
  );
};

export default CommonLetter;
