import {
  Tooltip,
  Skeleton,
  CircularProgress,
  Button,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  downloadUnitPerformanceReport,
  getSubordinateContracts,
} from "../../../store/rbm/actions";
import RBMPageHeader from "../components/RBMPageHeader";
import SubordinateCard from "../components/SubordinateCard";
import axios from "axios";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const MySubordinates = (props) => {
  const {
    loading,
    // getUnits,
    // units,
    // subordinates,
    // getUnityIndicators,
    user,
    getSubordinateContracts,
    subordinateContracts,
    selectedFiscalYear,
    downloadUnitPerformanceReport,
    subordinatePendingReports,
  } = props;

  useEffect(() => {
    document.title = "IPPIS - RBM My Subordinates";
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(5);
  const [isNotActive, setIsNotActive] = useState(false);

  useEffect(
    () => {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        isNotActive,
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      if (!!selectedFiscalYear.id)
        getSubordinateContracts(query, setTotalRecords, setLoader);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFiscalYear?.id, isNotActive]
  );

  const [subordinatesSummary, setSubordinatesSummary] = useState({
    numOfSubordinates: 0,
    numOfActivities: 0,
    ontrackActivities: 0,
    dueActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,
    rejectedActivities: 0,
    targetAchieved: 0,
    score: 0,
    unContracted: 0,
  });

  const getNo = (idToSearch) => {
    return subordinateContracts.findIndex(({ id }) => id === idToSearch);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      isNotActive,
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getSubordinateContracts(query, setTotalRecords, setLoader);
  };

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        isNotActive,
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      getSubordinateContracts(query, setTotalRecords, setLoader);
    }
  };

  const getSubordinateContractsSummary = async () => {
    const { data } = await axios.get("/api/rbm/subordinate-contracts-summary");

    setSubordinatesSummary(data);
  };

  useEffect(() => {
    getSubordinateContractsSummary();
  }, [subordinateContracts]);

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <h6 className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              <span className="text-uppercase">
                My Subordinates{" "}
                <span
                  className="ml-2 badge badge-light text-left text-uppercase border"
                  style={{ minWidth: "80px" }}
                >
                  <span
                    className={`badge badge-light ${
                      isNotActive ? "bg-danger" : "bg-primary"
                    } text-light`}
                    style={{ fontSize: "11px" }}
                  >
                    {isNotActive ? "Exited" : "Active"}
                  </span>{" "}
                  subordinates
                  <ButtonGroup
                    size="small"
                    aria-label="Control subordinates status"
                    className="ml-2"
                  >
                    <Button
                      disabled={!isNotActive}
                      onClick={() => setIsNotActive(false)}
                      startIcon={
                        !!subordinatePendingReports?.forActiveReports ? (
                          <span
                            class="badge badge-pill badge-danger p-1"
                            style={{
                              fontSize: "0.5rem",
                            }}
                          >
                            {subordinatePendingReports.forActiveReports}
                          </span>
                        ) : !!subordinatePendingReports?.forActiveCompetencyNotEvaluated ? (
                          <span
                            class="badge badge-pill badge-warning p-1"
                            style={{
                              fontSize: "0.5rem",
                            }}
                          >
                            {
                              subordinatePendingReports.forActiveCompetencyNotEvaluated
                            }
                          </span>
                        ) : null
                      }
                    >
                      Active Staff
                    </Button>
                    <Button
                      disabled={isNotActive}
                      color="error"
                      onClick={() => setIsNotActive(true)}
                      endIcon={
                        !!subordinatePendingReports?.forExitedReports ? (
                          <span
                            class="badge badge-pill badge-danger p-1"
                            style={{
                              fontSize: "0.5rem",
                            }}
                          >
                            {subordinatePendingReports.forExitedReports}
                          </span>
                        ) : !!subordinatePendingReports?.forExitedCompetencyNotEvaluated ? (
                          <span
                            class="badge badge-pill badge-warning p-1"
                            style={{
                              fontSize: "0.5rem",
                            }}
                          >
                            {
                              subordinatePendingReports.forExitedCompetencyNotEvaluated
                            }
                          </span>
                        ) : null
                      }
                    >
                      Exited Staff
                    </Button>
                  </ButtonGroup>
                </span>
              </span>

              {!!subordinatesSummary.unContracted && (
                <Tooltip title="View">
                  <span
                    onClick={() => {
                      const _currentPage = 1;
                      setCurrentPage(_currentPage);

                      const _searchTerm =
                        searchTerm === "uncontracted" ? "" : "uncontracted";
                      setSearchTerm(_searchTerm);

                      const query = {
                        searchTerm: _searchTerm,
                        page: _currentPage,
                        size: itemPerPage,
                      };

                      getSubordinateContracts(
                        query,
                        setTotalRecords,
                        setLoader
                      );
                    }}
                    className={`badge text-danger text-uppercase ml-2`}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {searchTerm !== "uncontracted" && (
                      <CheckBoxOutlineBlankOutlinedIcon />
                    )}
                    {searchTerm === "uncontracted" && <CheckBoxOutlinedIcon />}
                    {subordinatesSummary.unContracted} Not contracted
                  </span>
                </Tooltip>
              )}
            </span>
          </h6>

          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <div className="mb-3">
            <RBMPageHeader
              labels={[
                {
                  title: "Subordinates",
                  value: subordinatesSummary.numOfSubordinates || 0,
                  type: "number",
                },
                {
                  title: "ITEMS",
                  value: subordinatesSummary.numOfActivities || 0,
                  type: "number",
                },
                {
                  title: "Ontrack",
                  value: subordinatesSummary.ontrackActivities || 0,
                  type: "number",
                },
                {
                  title: "Due",
                  value: subordinatesSummary.dueActivities || 0,
                  type: "number",
                },
                {
                  title: "Reported",
                  value: subordinatesSummary.reportedActivities || 0,
                  type: "number",
                },
                {
                  title: "Scored",
                  value: subordinatesSummary.scoredActivities || 0,
                  type: "number",
                },
                {
                  title: "Rejected",
                  value: subordinatesSummary.rejectedActivities || 0,
                  type: "number",
                },
                {
                  title: "Target achieved",
                  value: (subordinatesSummary.targetAchieved || 0).toFixed(2),
                  type: "percentage",
                },
              ]}
              progress={{
                title: "PROGRESS",
                value: subordinatesSummary.score || 0,
              }}
              buttons={[
                {
                  fun: () =>
                    downloadUnitPerformanceReport(user.employments[0].unitId),
                  title: "DOWNLOAD",
                },
              ]}
              disabled={loading}
              loading={loading}
            />
          </div>

          <div className="rbm-shadow rounded px-4 py-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };

                    getSubordinateContracts(query, setTotalRecords, setLoader);
                  }
                }}
              />
            </div>

            {subordinateContracts &&
              subordinateContracts.map((subordinate) => (
                <SubordinateCard
                  key={subordinate.id + subordinate.contractId || ""}
                  index={getNo(subordinate.id)}
                  subordinate={subordinate}
                />
              ))}

            {!subordinateContracts[0] && loading && (
              <div>
                <Skeleton
                  variant="rectangular"
                  className="mb-3 mt-2"
                  height={118}
                />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
              </div>
            )}

            {!subordinateContracts[0] && !loading && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container">
                  <p className="lead">No subordinate found.</p>
                </div>
              </div>
            )}

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecords / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!subordinateContracts.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getSubordinateContracts(query, setTotalRecords, setLoader);
                  }}
                >
                  {[5, 10, 50].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`page-loading-void position-fixed ${
          loader && !!subordinateContracts[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  env,
  subordinateContracts,
  selectedFiscalYear,
  subordinatePendingReports,
}) => {
  return {
    loading,
    user,
    env,
    subordinateContracts,
    selectedFiscalYear,
    subordinatePendingReports,
  };
};

export default connect(mapStateToProps, {
  getSubordinateContracts,
  downloadUnitPerformanceReport,
})(MySubordinates);
