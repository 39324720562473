import disciplineTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...disciplineTypes };

export const getSanctionsWithFaultCategory = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/sanctions");

      dispatch({
        type: types.SET_SANCTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getFaultWithSanction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/faults");

      dispatch({
        type: types.SET_FAULTS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEntitySectorCases = (setLoading, type) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setLoading(true);
      const { data } = await axios.get(
        "/api/discipline/entity-sector-cases?type=" + type
      );

      dispatch({
        type: types.SET_ENTITY_SECTOR_CASES,
        data,
      });
      dispatch({ type: types.END_LOADING });
      setLoading(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteDisciplinaryCase = (caseItem) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      // Make a request to delete the disciplinary case
      await axios.delete(`/api/discipline/entity-sector-cases/${caseItem.id}`);

      // If the deletion is successful, dispatch an action to update the state
      dispatch({
        type: types.DELETE_DISCIPLINARY_CASE,
        caseId: caseItem.id,
      });

      dispatch({ type: types.END_LOADING });

      // Optionally, dispatch a success toast notification
      showSuccess("Disciplinary case deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getUnitCases = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/unit-cases");

      dispatch({
        type: types.SET_UNIT_CASES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getMyCases = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/discipline/my-cases");

      dispatch({
        type: types.SET_MY_CASES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCase = (caseId, setLoading, setShowCaseDetail) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setLoading(true);
      const { data } = await axios.get("/api/discipline/case/" + caseId);

      dispatch({
        type: types.SET_CASE,
        data,
      });

      setLoading(false);
      setShowCaseDetail(true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      setLoading(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const initiateCase = (
  formData,
  onClose,
  setShowCaseDetail,
  setActiveStep,
  showCaseInitiateForm,
  history,
  isFirstCategory
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.START_WAIT });
      setActiveStep(3);
      const { data } = await axios.post(
        "/api/discipline/initiate-case",
        formData
      );

      onClose();

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });
      dispatch({
        type: types.SET_CASE,
        data: data.request,
      });
      dispatch({
        type: types.ADD_ENTITY_SECTOR_CASE,
        data: data.request.request,
      });

      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showCaseInitiateForm(false)
      if (isFirstCategory) {
        setShowCaseDetail(true);
      }
      showSuccess("New discipline case initiated successfully");
      if (!isFirstCategory) {
        history.push("/discipline");
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showCaseInitiateForm(false)
      setActiveStep(2);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCaseAttachments = (caseId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/discipline/case-attachments/" + caseId
      );

      dispatch({
        type: types.SET_CASE_ATTACHMENTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateCaseFault = (
  payload,
  caseRequest,
  setShowAddFaultForm,
  setTmpFaults
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/update-case-faults",
        payload
      );
      showSuccess("Updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE,
        data: { ...caseRequest, faults: data },
      });
      setTmpFaults([]);
      setShowAddFaultForm(false);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const removeCaseFault = (
  payload,
  caseRequest,
  setShowRemoveFaultForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/remove-case-faults",
        payload
      );
      showSuccess("Removed successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE,
        data: { ...caseRequest, faults: data },
      });
      setShowRemoveFaultForm(false);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateCaseDesc = (payload, caseRequest, setShowCaseDescForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/update-case-description",
        payload
      );
      showSuccess("Updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE,
        data: { ...caseRequest, request: data },
      });
      setShowCaseDescForm(false);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updateCaseAttachment = (caseId, payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/discipline/case-attachments/" + caseId,
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const addCaseAttachment = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/case-attachments",
        payload
      );
      showSuccess("Attachment saved successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const cancelCaseRequest = (caseId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete("/api/discipline/cases/" + caseId);

      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data.employee,
      });

      dispatch({
        type: types.DELETE_CASE,
        data: data.request,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case  canceled successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateRequestExplanationLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/update-request-explanation-letter",
        formData
      );
      dispatch({
        type: types.SET_CASE,
        data: data,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Letter updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateRequestSanctionLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/update-case-sanction-letter",
        formData
      );
      dispatch({
        type: types.SET_CASE,
        data: data,
      });
      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Letter updated successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const reviewCaseRequest = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/review-case-request",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case confirmed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const reportCaseToTheCommittee = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/report-case-to-committee",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const verifyEmployeeCaseFeedbackLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/verify-employee-case-feedback",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const reviewCaseSanctionRequest = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/review-case-sanction",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case sanction letter confirmed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveRequestExplanationLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-request-explanation-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Requesting employee explanation approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveRequestSanctionLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-case-sanction-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Case sanction approved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveEmployeeCaseFeedbackLetter = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-employee-case-feedback-letter",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      // dispatch({
      //   type: types.SET_CASE,
      //   data: {},
      // });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Approved and signed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

// export const closeCase = (formData, action, onClose) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: types.START_LOADING });

//       const { data } = await axios.post("/api/discipline/close-case", formData);

//       dispatch({
//         type: types.UPDATE_DISCIPLINARY_CASE,
//         data: data,
//       });

//       // dispatch({
//       //   type: types.SET_CASE,
//       //   data: {},
//       // });
//       onClose();

//       dispatch({ type: types.END_LOADING });
//       showSuccess(
//         `Case ${
//           action === "CLOSE_CASE" ? "closed" : "submitted for verification"
//         } successfully`
//       );
//     } catch (error) {
//       dispatch({ type: types.END_LOADING });
//       if (isSessionExpired(error)) {
//         dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
//       }
//       showError(error);
//     }
//   };
// };

export const closeCase = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/discipline/close-case", formData);

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess(`IDC report submitted successfully`);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onConfirmToCloseCase = (
  formData,
  setShowConfirmationDialog,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/confirm-close-case",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      onClose();
      setShowConfirmationDialog(false);
      dispatch({ type: types.END_LOADING });
      showSuccess(`Case closed successfully`);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const initiateSanctionAfterCommittee = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/initiate-sanction-after-committee",
        formData
      );

      dispatch({
        type: types.UPDATE_DISCIPLINARY_CASE,
        data: data,
      });

      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess(`Sanction initiated successfully`);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getDisciplineCaseYearAndMonths = (callback) => {
  return async (dispatch) => {
    callback(true, null);
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/discipline/years-months");

      // dispatch({
      //   type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
      //   data: data,
      // });
      dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      callback(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getCaseSanctionNumbers = (
  startDate = "",
  endDate = "",
  entitySectorId = "",
  callback
) => {
  return async (dispatch) => {
    callback(true, []);
    try {
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/discipline/case-sanction-numbers?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&entitySectorId=" +
          entitySectorId
      );

      // dispatch({
      //   type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
      //   data: data,
      // });
      // dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      callback(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//

export const getCaseChartStatistics = (
  startDate = "",
  endDate = "",
  entitySectorId = "",
  callback
) => {
  return async (dispatch) => {
    callback(true, []);
    try {
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/discipline/case-chart-statistics?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&entitySectorId=" +
          entitySectorId
      );

      // dispatch({
      //   type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
      //   data: data,
      // });
      // dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      callback(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCaseSummaryReports = (
  startDate = "",
  endDate = "",
  entitySectorId = "",
  callback
) => {
  return async (dispatch) => {
    callback(true, []);
    try {
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/discipline/case-summary-reports?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&entitySectorId=" +
          entitySectorId
      );

      // dispatch({
      //   type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
      //   data: data,
      // });
      // dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      callback(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getDisciplineLetterNo = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/discipline/letter-no`);

      dispatch({
        type: types.SET_DISCIPLINE_LETTER_NO,
        data,
      });
    } catch (error) {
      // showError(error);
    }
  };
};

export const getHighestFrequencyEmployees = (
  startDate = "",
  endDate = "",
  entitySectorId = "",
  top = 10,
  callback
) => {
  return async (dispatch) => {
    callback(true, []);
    try {
      // dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/discipline/highest-frequency-employees?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&entitySectorId=" +
          entitySectorId +
          "&top=" +
          top
      );

      // dispatch({
      //   type: types.SET_ADMIN_EXIT_YEARS_MONTHS,
      //   data: data,
      // });
      // dispatch({ type: types.END_LOADING });
      callback(false, data);
    } catch (error) {
      // dispatch({ type: types.END_LOADING });
      callback(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestProvisionSuspension = (payload, caseRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/update-provision-suspension-details",
        payload
      );
      showSuccess("Updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE,
        data: { ...caseRequest, request: data },
      });
      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const approveProvisionSuspension = (payload, caseRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/discipline/approve-provision-suspension-letter",
        payload
      );
      showSuccess("Approved successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_CASE,
        data: { ...caseRequest, request: data },
      });
      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};