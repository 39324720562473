import React from "react";
import Breadcrumb from "./Breadcrumb";
import ModulesMenu from "../../common/components/ModulesMenu";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: -10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const MenuBar = (props) => {
  const { user } = props;
  const location = useLocation();
  const { noOfEmployeeRequests } = props;
  // console.log(user);
  return (
    <>
      <nav>
        <div
          className="py-1 d-flex flex-wrap justify-content-between mt-0  align-items-center px-4"
          style={{
            backgroundColor: "#078ece",
            borderBottom: "1px solid #078ece",
            borderTop: "1px solid #078ece",
          }}
        >
          <Breadcrumb />

          <div className="d-flex flex-wrap justify-content-end align-items-center pl-2">
            {user.employments.find(
              ({ positionId }) =>
                positionId === "2000000000-010100000000000000-00041"
            ) && (
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/employees/find-employee-details"
                className={`  px-1 rounded mr-3 d-none d-md-inline  ${
                  location.pathname.includes("/employees/find-employee-details")
                    ? "active"
                    : " "
                }`}
              >
                <span className="text-truncate font-weight-bold  menu-item d-flex">
                  <span className="material-icons mt-1">search</span>
                  Find Employee
                </span>
              </Link>
            )}

            {/* <span>
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to="/employees/requests"
                className={`px-1  ${
                  location.pathname.includes("/employees/requests")
                    ? "active"
                    : " "
                }`}
              >
                <StyledBadge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  color="error"
                  badgeContent={noOfEmployeeRequests}
                >
                  <span className="text-truncate font-weight-bold menu-item">
                    Pending requests
                  </span>
                </StyledBadge>
              </Link>
              <HorizontalRuleIcon className="rotated" />
            </span> */}

            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/employees/user-guide"
              className={`  px-1 rounded mr-3 d-none d-md-inline  ${
                location.pathname.includes("/employees/user-guide")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate font-weight-bold  menu-item d-flex">
                <span className="material-icons">local_library</span>
                User-Guide
              </span>
            </Link>
          </div>
        </div>
      </nav>
      {/* <div style={{ marginTop: "0px" }}>
        <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
        <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
      </div> */}
    </>
  );
};

const mapStateToProps = ({ user, noOfEmployeeRequests }) => {
  return {
    user,
    noOfEmployeeRequests,
  };
};

export default connect(mapStateToProps, {})(MenuBar);
