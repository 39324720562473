import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const ProvisionalAppointmentLetter = (props) => {
  const {
    recipientName,
    recipientEmail,
    recipientPhone,
    effectiveDate,
    institutionName,
    recruitmentInstitutionName,
    recruitmentPositionName,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    effectiveDateInput="",
    ccData = [],
    subTitle
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
               {recipientName} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              C/O: {institutionName.toUpperCase()}”
            </p>
          </div>
          <div className="content2 mb-2">
            {/* <!-- Letter content goes here --> */}
            <p>{subTitle},</p>
            <p style={{ fontWeight: "bold" }}>
              Re: Provisional Appointment Letter
            </p>
            <p>
              Pursuant to Presidential Order No.128/01 of 03/12/2020 relating to
              recruitment of public servants and induction programme;
            </p>
            <p>
              Pursuant to Prime Minister’s Order No. ……. / of …… establishing
              the organization structure, salaries and fringe benefits of the
              employees of the{" "}
              <span style={{ fontWeight: "bold" }}>
                {recruitmentInstitutionName}
              </span>
              ;
            </p>
            <p>
              Considering your results in the recruitment conducted on the
              position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recruitmentPositionName}
              </span>
              .; <br />I hereby provisionally appoint you to the job position of{" "}
              {recruitmentPositionName} in {recruitmentInstitutionName} with
              effect from{" "}
              {enableToFillLetter ? (
                effectiveDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>{effectiveDate}</span>
                </>
              )}, while
              waiting for the approval of recruitment report by the competent
              authority.
            </p>
            <p>
              I wish you success and good performance in your responsibilities.
            </p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvisionalAppointmentLetter;
