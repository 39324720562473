import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import { connect } from "react-redux";
import { respondRehabilitationRequest } from "../../../store/exit/actions";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import { textCapitalized } from "../../common/components/Utils";
import moment from "moment";

const RehabilitationDetailsDialog = (props) => {
  const {
    loading,
    rehabilitationDetailsForm,
    setRehabilitationDetailsForm,
    selectedRehabilitationRequest,
    setApproveRehabilitationForm,
    setRejectRehabilitationForm,
  } = props;

  const [showDocument, setShowDocument] = useState(false);

  const onClose = () => {
    setRehabilitationDetailsForm(false);
  };

  return (
    <>
      {!showDocument && (
        <Dialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          //
          open={rehabilitationDetailsForm}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              Rehabilitation Details Of @{" "}
              {selectedRehabilitationRequest &&
                selectedRehabilitationRequest.firstName}{" "}
              {selectedRehabilitationRequest &&
                selectedRehabilitationRequest.lastName}{" "}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {/* employee info */}
            <div className="card service-card valid mb-2 mt-1">
              <div
                className="card-header"
                style={{ padding: "0.25rem 1.2rem" }}
              >
                # Employee
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                    <div className="row mt-0">
                      <div className="col-12">
                        <div
                          className="card"
                          style={{
                            backgroundColor: "#fff",
                            backgroundClip: "border-box",
                            border: "1px solid rgba(0,0,0,0.175)",
                            borderRadius: "0.5rem",
                            boxShadow:
                              " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                          }}
                        >
                          <div className="card-body text-left">
                            <div className="row">
                              <div className="col-6">
                                <div
                                  className="user-contact"
                                  style={{ padding: "0px!important" }}
                                >
                                  <div className="">
                                    <div>
                                      <p className="contact_p">
                                        EmployeeId:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            selectedRehabilitationRequest.userId || 'N/A'}
                                        </span>
                                      </p>
                                      <p className="contact_p">
                                        Names:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest && (
                                            <span className="ml-1">
                                              {textCapitalized(
                                                selectedRehabilitationRequest.lastName
                                              )}{" "}
                                              {textCapitalized(
                                                selectedRehabilitationRequest.firstName
                                              )}
                                            </span>
                                          )}
                                        </span>
                                      </p>
                                      <p className="contact_p">
                                        DOB:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {moment(
                                              selectedRehabilitationRequest.dateOfBirth
                                            ).format("MMMM d, YYYY")}
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        National Id:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.idNumber
                                            }
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        Gender:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {selectedRehabilitationRequest.genderId ===
                                            "M"
                                              ? "Male"
                                              : "Female"}
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        Civil Status:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.civilStatus
                                            }
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div
                                  className="user-contact"
                                  style={{ padding: "0px!important" }}
                                >
                                  <div className="">
                                    <div>
                                      <p className="contact_p">
                                        Father Names:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.fatherNames
                                            }
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        Mother Names:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.motherNames
                                            }
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        Institution:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.institutionName
                                            }
                                          </span>
                                        )}
                                      </p>
                                      <p className="contact_p">
                                        Position:{" "}
                                        {selectedRehabilitationRequest && (
                                          <span className="contact_span">
                                            {
                                              selectedRehabilitationRequest.positionName
                                            }
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* blacklist details */}
            <div className="card service-card valid mb-2 mt-1">
              <div
                className="card-header"
                style={{ padding: "0.25rem 1.2rem" }}
              >
                # Blacklist
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                    <div className="row mt-0">
                      <div className="col-12">
                        <div
                          className="card"
                          style={{
                            backgroundColor: "#fff",
                            backgroundClip: "border-box",
                            border: "1px solid rgba(0,0,0,0.175)",
                            borderRadius: "0.5rem",
                            boxShadow:
                              " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                          }}
                        >
                          <div className="card-body text-left">
                            <div className="row">
                              <div className="col-12">
                                <div
                                  className="user-contact"
                                  style={{ padding: "0px!important" }}
                                >
                                  <div className="">
                                    <div>
                                      <p className="contact_p">
                                        Reason
                                        <br />
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            selectedRehabilitationRequest.exitTypeName}
                                        </span>
                                      </p>
                                      <p className="contact_p">
                                        End date:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            moment(
                                              selectedRehabilitationRequest.willEndOn
                                            ).format("LL")}
                                        </span>
                                      </p>
                                      <p className="contact_p">
                                        RequestedBy:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            selectedRehabilitationRequest.requestedBy}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* rehabilitation details */}
            <div className="card service-card valid mb-2 mt-1">
              <div
                className="card-header"
                style={{ padding: "0.25rem 1.2rem" }}
              >
                # Rehabilitation
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                    <div className="row mt-0">
                      <div className="col-12">
                        <div
                          className="card"
                          style={{
                            backgroundColor: "#fff",
                            backgroundClip: "border-box",
                            border: "1px solid rgba(0,0,0,0.175)",
                            borderRadius: "0.5rem",
                            boxShadow:
                              " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                          }}
                        >
                          <div className="card-body text-left">
                            <div className="row">
                              <div className="col-12">
                                <div
                                  className="user-contact"
                                  style={{ padding: "0px!important" }}
                                >
                                  <div className="">
                                    <div>
                                      <p className="contact_p">
                                       Reason
                                        <br />
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            selectedRehabilitationRequest.rehabilitatedReason}
                                        </span>
                                      </p>
                                      {/* <p className="contact_p">
                                        <Button
                                          variant="outlined"
                                          startIcon={
                                            <FileDownloadRoundedIcon />
                                          }
                                          onClick={() => setShowDocument(true)}
                                        >
                                          Support Document
                                        </Button>
                                      </p> */}
                                      <p className="contact_p">
                                      RehabilitatedBy:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            selectedRehabilitationRequest.rehabilitatedNames}
                                        </span>
                                      </p>
                                      <p className="contact_p">
                                      RehabilitatedOn:{" "}
                                        <span className="contact_span">
                                          {selectedRehabilitationRequest &&
                                            moment(
                                              selectedRehabilitationRequest.rehabilitatedOn
                                            ).format("LL")}
                                        </span>
                                      </p>
                                      {/* DISPLAY STATUS
                                      {selectedRehabilitationRequest &&
                                        selectedRehabilitationRequest.rehabitationStatusId ===
                                          1 && (
                                          <p className="contact_p">
                                            Status:
                                            <span className="contact_span">
                                              <span className="badge badge-info ml-1">
                                                {selectedRehabilitationRequest &&
                                                  selectedRehabilitationRequest.rehabitationStatus}
                                              </span>
                                            </span>
                                          </p>
                                        )} */}
                                      {/* {selectedRehabilitationRequest &&
                                        selectedRehabilitationRequest.rehabitationStatusId ===
                                          2 && (
                                          <p className="contact_p">
                                            Status:
                                            <span className="contact_span">
                                              <span className="badge badge-success ml-1">
                                                {selectedRehabilitationRequest &&
                                                  selectedRehabilitationRequest.rehabitationStatus}
                                              </span>
                                            </span>
                                          </p>
                                        )} */}
                                      {/* {selectedRehabilitationRequest &&
                                        selectedRehabilitationRequest.rehabitationStatusId ===
                                          3 && (
                                          <p className="contact_p">
                                            Status:
                                            <span className="contact_span">
                                              <span className="badge badge-danger ml-1">
                                                {selectedRehabilitationRequest &&
                                                  selectedRehabilitationRequest.rehabitationStatus}
                                              </span>
                                            </span>
                                          </p>
                                        )} */}
                                      {/* DISPLAY RESPONSE IF EXIST */}
                                      {/* {selectedRehabilitationRequest &&
                                        selectedRehabilitationRequest.rehabitationStatusId !==
                                          1 && (
                                          <p className="contact_p">
                                            Response Message
                                            <br />
                                            <span className="contact_span">
                                              {selectedRehabilitationRequest &&
                                                selectedRehabilitationRequest.rehabilitationApprovalComment}
                                            </span>
                                          </p>
                                        )} */}
                                      {/* {selectedRehabilitationRequest &&
                                        selectedRehabilitationRequest.rehabitationStatusId !==
                                          1 && (
                                          <p className="contact_p">
                                            Responded by
                                            <br />
                                            <span className="contact_span">
                                              {selectedRehabilitationRequest &&
                                                selectedRehabilitationRequest.respondedBy}
                                            </span>
                                          </p>
                                        )} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>

          {/* <DialogActions
            className={`d-flex justify-content-center ${
              selectedRehabilitationRequest &&
              selectedRehabilitationRequest.rehabitationStatusId !== 1 &&
              "py-3"
            }`}
          >
            {selectedRehabilitationRequest &&
              selectedRehabilitationRequest.rehabitationStatusId === 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  className="mr-4"
                  onClick={() => setRejectRehabilitationForm(true)}
                >
                  Reject
                </Button>
              )}
            {selectedRehabilitationRequest &&
              selectedRehabilitationRequest.rehabitationStatusId === 1 && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setApproveRehabilitationForm(true)}
                >
                  Approve
                </Button>
              )}
          </DialogActions> */}
        </Dialog>
      )}
      {/* {showDocument && (
        <PreviewPdfUrl
          showDocument={showDocument}
          setShowDocument={setShowDocument}
          document={{
            name: `View ${selectedRehabilitationRequest.supportingDocument}`,
            url: selectedRehabilitationRequest.supportingDocumentUrl,
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {})(
  RehabilitationDetailsDialog,
  respondRehabilitationRequest
);
