import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  DialogActions,
  FormGroup,
  Checkbox,
  Tooltip,
  Chip,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import {
  saveJobClassificationAllowance,
  getJobClassificationAllowances,
  deleteJobClassificationAllowance,
} from "../../../store/structure/actions";
import { showError } from "../../toastify";
import CurrencyFormat from "react-currency-format";
import { Fragment } from "react";
import NumberFormat from "react-number-format";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const JobClassificationAllowancesDialog = (props) => {
  const {
    loading,
    onWait,
    showJobClassificationSettingForm,
    setShowJobClassificationSettingForm,
    selectedJobClassification,
    systemAllowances,
    // getAllowances,
    saveJobClassificationAllowance,
    getJobClassificationAllowances,
    jobClassificationAllowances,
    deleteJobClassificationAllowance,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
    jobClassification,
  } = props;

  //
  const [allowance, setAllowance] = useState(null);
  const [
    selectedJobClassificationAllowance,
    setSelectedJobClassificationAllowance,
  ] = useState(null);
  const [
    confirmRemoveJobClassificationAllowance,
    setConfirmRemoveJobClassificationAllowance,
  ] = useState(false);

  const [allowanceType, setAllowanceType] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    jobClassificationId: selectedJobClassification.id,
    allowanceId: "",
  });

  const [add, setAdd] = useState(false);

  const [errors, setErrors] = useState({
    allowanceHasError: false,
  });

  useEffect(
    () => {
      // getAllowances();
      getJobClassificationAllowances(selectedJobClassification.id);

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedJobClassification]
  );

  useEffect(
    () => {
      const add = jobClassificationAllowances.length > 0 ? false : true;

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobClassificationAllowances]
  );

  const formValidator = () => {
    const error = {
      allowanceHasError: false,
      hasError: false,
    };

    if (!allowance) {
      error.allowanceHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveJobClassificationAllowance(formData, setFormData, setAdd);
    }
  };

  const onClose = () => {
    setShowJobClassificationSettingForm(false);
    setFormData({
      jobClassificationId: "",
      allowanceId: "",
    });
  };

  useEffect(() => {
    if (!!allowance) {
      setAllowanceType(
        allowanceTypes.find(({ id }) => id === allowance.allowanceTypeId)
      );
    }

    if (!!allowance) {
      setCalculationMethod(
        calculationMethods.find(
          ({ id }) => id === allowance.calculationMethodId
        )
      );

      if (
        allowance.calculationMethodId === 2 &&
        !!allowance.basePayrollComponentName
      )
        setBasePayrollComponent(
          basePayrollComponents.find(
            ({ id }) => id === allowance.basePayrollComponentId
          )
        );
    } else {
      setCalculationMethod(null);
      setBasePayrollComponent(null);
    }
  }, [allowance]);

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showJobClassificationSettingForm}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="text-dark">Job Classification Allowances |</span>{" "}
            {jobClassification.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ minHeight: "45vh" }}>
            {loading && !onWait && (
              <div className="container mt-2" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            )}

            {(!loading || onWait) && (
              <div>
                {!add && (
                  <Button
                    className="mb-2"
                    onClick={() => {
                      setAdd(true);
                    }}
                    style={{
                      ...ActiveButton,
                      padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="sm"
                  >
                    <span className="material-icons">add</span>
                  </Button>
                )}

                {add && (
                  <div
                    className="elevated rounded p-0 mt-3"
                    style={{
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #17a2b8",
                    }}
                  >
                    <div className="row d-flex align-items-center p-3">
                      <div className="col-9">
                        <Autocomplete
                          className="mt-3"
                          size="small"
                          id="allowance"
                          defaultValue={null}
                          value={allowance}
                          options={systemAllowances}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, allowance) => {
                            setAllowance(allowance || null);
                            const allowanceId = allowance ? allowance.id : "";

                            setFormData({
                              ...formData,
                              allowanceId,
                            });
                          }}
                          getOptionLabel={(option) =>
                            getNo(option.id, systemAllowances) +
                            "-" +
                            option.name +
                            (option.calculationMethodId === 1
                              ? " (RWF:"
                              : " (RATE:") +
                            option.amount +
                            (option.calculationMethodId === 2 &&
                            !!option.basePayrollComponentName
                              ? "/ " + option.basePayrollComponentName
                              : "") +
                            ")"
                          }
                          renderOption={(props, allowance) => (
                            <Fragment key={allowance.id}>
                              <Box component="li" {...props}>
                                {getNo(allowance.id, systemAllowances)} -{" "}
                                {allowance.name}{" "}
                                {allowance.calculationMethodId === 1
                                  ? " (RWF:"
                                  : " (RATE:"}
                                {allowance.amount}
                                {allowance.calculationMethodId === 2 &&
                                !!allowance.basePayrollComponentName
                                  ? "/ " + allowance.basePayrollComponentName
                                  : ""}
                                {")"}
                              </Box>
                              <Divider />
                            </Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Allowances"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "allowance", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {errors.allowanceHasError && (
                          <div className="text-danger mb-2">
                            Allowance is required{" "}
                          </div>
                        )}
                      </div>
                      <div className="col-3 mt-3">
                        <Autocomplete
                          style={{ backgroundColor: "#eee" }}
                          size="small"
                          disabled
                          id="type"
                          defaultValue={null}
                          value={allowanceType}
                          options={allowanceTypes}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, allowanceType) => (
                            <Box component="li" {...props}>
                              {allowanceType.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "type", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className={`${
                          !!allowance &&
                          allowance.calculationMethodId === 2 &&
                          !!allowance.basePayrollComponentName
                            ? "col-4"
                            : "col-6"
                        }`}
                      >
                        <NumberFormat
                          disabled
                          style={{ backgroundColor: "#eee" }}
                          fullWidth
                          size="small"
                          className="mt-3 py-0 small"
                          label={
                            !allowance || allowance.calculationMethodId === 1
                              ? "Amount"
                              : "Rate"
                          }
                          name="amount"
                          variant="outlined"
                          placeholder="RWF 0"
                          prefix={"RWF "}
                          customInput={TextField}
                          value={!!allowance ? allowance.amount : ""}
                          thousandSeparator={true}
                        />
                      </div>
                      <div
                        className={`${
                          !!allowance &&
                          allowance.calculationMethodId === 2 &&
                          !!allowance.basePayrollComponentName
                            ? "col-4"
                            : "col-6"
                        }`}
                      >
                        <div className="mt-3">
                          <Autocomplete
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            id="Method"
                            defaultValue={null}
                            value={calculationMethod}
                            options={calculationMethods}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            // onChange={(event, calculationMethod) => {
                            //   setCalculationMethod(calculationMethod || null);
                            //   const calculationMethodId = calculationMethod
                            //     ? calculationMethod.id
                            //     : "";
                            //   setFormData({ ...formData, calculationMethodId });
                            // }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, calculationMethod) => (
                              <Box component="li" {...props}>
                                {calculationMethod.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Method"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "calculationMethod", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>

                      {!!allowance &&
                        allowance.calculationMethodId === 2 &&
                        !!allowance.basePayrollComponentName && (
                          <div className=" col-4">
                            <div className="mt-3">
                              <Autocomplete
                                style={{ backgroundColor: "#eee" }}
                                size="small"
                                disabled
                                id="baseComponent"
                                defaultValue={null}
                                value={basePayrollComponent}
                                options={basePayrollComponents}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, basePayrollComponent) => (
                                  <Box component="li" {...props}>
                                    {basePayrollComponent.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Base Component"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "baseComponent", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        )}

                      {/* <div className="col-12">
                        <FormGroup
                          row
                          className=" mt-3 d-flex justify-content-center border border-info bg-light"
                        >
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <Checkbox checked={allowance?.payTPR || false} />
                            }
                            label="Pay TPR"
                          />
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <Checkbox checked={allowance?.payCSR || false} />
                            }
                            label="Pay CSR"
                          />
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <Checkbox
                                checked={allowance?.payMedical || false}
                              />
                            }
                            label="Pay Medical"
                          />
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <Checkbox
                                checked={allowance?.payMaternity || false}
                              />
                            }
                            label="Pay Maternity"
                          />
                        </FormGroup>
                      </div> */}
                    </div>

                    <Divider className="my-1" />
                    <div className="d-flex justify-content-center py-0 p-2">
                      <button
                        onClick={onSave}
                        type="button"
                        className="btn btn-primary text-uppercase"
                        disabled={loading}
                      >
                        {loading ? "Wait..." : "Save"}
                      </button>{" "}
                      {!!jobClassificationAllowances.length && (
                        <React.Fragment>
                          &nbsp; &nbsp;
                          <button
                            onClick={() => setAdd(false)}
                            type="button"
                            className="btn btn-default text-uppercase"
                            disabled={loading}
                          >
                            Cancel
                          </button>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                )}

                {!!jobClassificationAllowances.length && (
                  <table className="table table-bordered table-sm mt-1">
                    <thead>
                      <tr>
                        <th
                          colSpan={5}
                          style={{ textAlign: "center" }}
                          className="py-1 text-uppercase"
                        >
                          Active Allowances
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobClassificationAllowances.map(
                        (jobClassificationAllowance, JcaIndex) => (
                          <tr
                            key={JcaIndex}
                            style={{
                              background: JcaIndex % 2 === 0 ? "" : "#f6f7f7",
                              borderBottom:
                                "2px dotted rgb(7, 142, 206)!important",
                            }}
                          >
                            <td>{JcaIndex + 1}</td>
                            <td>
                              {" "}
                              {jobClassificationAllowance.allowanceName}{" "}
                            </td>
                            <td className="text-left">
                              <span>
                                <Chip
                                  style={{ borderRadius: "0px" }}
                                  variant="outlined"
                                  className="small-chip"
                                  size="small"
                                  label={
                                    jobClassificationAllowance.allowanceTypeName
                                  }
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge mr-1 badge-${
                                  jobClassificationAllowance.calculationMethodId ===
                                  1
                                    ? "light text-info"
                                    : "secondary"
                                }`}
                              >
                                {" "}
                                {jobClassificationAllowance.calculationMethodId ===
                                1
                                  ? "RWF"
                                  : "RATE"}
                              </span>
                              {jobClassificationAllowance.calculationMethodId ===
                              1 ? (
                                <CurrencyFormat
                                  value={jobClassificationAllowance.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              ) : (
                                <>
                                  {jobClassificationAllowance.amount}
                                  {!!jobClassificationAllowance.basePayrollComponentName && (
                                    <span
                                      className="badge badge-light text-uppercase text-info"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {"/ " +
                                        jobClassificationAllowance.basePayrollComponentName}
                                    </span>
                                  )}
                                </>
                              )}
                            </td>

                            <td className="text-right">
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() => {
                                  setSelectedJobClassificationAllowance(
                                    jobClassificationAllowance
                                  );
                                  setConfirmRemoveJobClassificationAllowance(
                                    true
                                  );
                                }}
                                className=" mr-2"
                              >
                                <span className="material-icons">delete</span>
                              </IconButton>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>

          {confirmRemoveJobClassificationAllowance && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveJobClassificationAllowance}
              message={`Are you sure you want to remove this allowance @${selectedJobClassificationAllowance.allowanceName}?`}
              setConfirmationDialog={setConfirmRemoveJobClassificationAllowance}
              onYes={() => {
                deleteJobClassificationAllowance(
                  selectedJobClassificationAllowance,
                  setConfirmRemoveJobClassificationAllowance,
                  setSelectedJobClassificationAllowance
                );
              }}
            />
          )}
        </DialogContent>
        <DialogActions className="py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  systemAllowances,
  jobClassificationAllowances,
  calculationMethods,
  basePayrollComponents,
  allowanceTypes,
}) => {
  return {
    loading,
    onWait,
    systemAllowances,
    jobClassificationAllowances,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
  };
};
export default connect(mapStateToProps, {
  // getAllowances,
  saveJobClassificationAllowance,
  getJobClassificationAllowances,
  deleteJobClassificationAllowance,
})(JobClassificationAllowancesDialog);
