import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  getActiveContracts,
  endContract,
  getSingleActiveContract,
} from "../../../../store/contractuals/actions";
import {
  getActiveCurrencies,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
} from "../../../../store/payroll/actions";
import { getMedicalInsuranceTypes } from "../../../../store/structure/actions";
import { Typography, Box, Button, Tooltip, IconButton } from "@mui/material";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Skeleton, CardContent, Toolbar, List } from "@mui/material";
import loadingGif from "../../../assets/loading.gif";
import ContractCard from "../components/ContractCard";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ActiveContracts = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Employees | Active Contracts";
  });

  const {
    user,
    loading,
    activeContracts,
    getActiveContracts,
    endContract,
    currencies,
    getActiveCurrencies,
    medicalInsuranceTypes,
    getMedicalInsuranceTypes,
    getSourceOfFunds,
    getPayrollGroups,
    getCostCenters,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const [filteredApprovals, setFilteredApprovals] = useState([
    ...activeContracts,
  ]);

  useEffect(
    () => {
      if (!currencies.length) getActiveCurrencies();
      if (!medicalInsuranceTypes.length) getMedicalInsuranceTypes();

      getSourceOfFunds();
      getPayrollGroups(user.selectedEntitySector.id);
      getCostCenters(user.selectedEntitySector.id);

      getActiveContracts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...activeContracts].filter(
      ({ firstName, lastName, unitName, positionName }) =>
        (
          (firstName || "") +
          (lastName || "") +
          (unitName || "") +
          (positionName || "")
        )
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredApprovals(filtered);
    paginate(filtered);
  }, [searchTeam, activeContracts]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 9;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredApprovals);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, activeContracts, filteredApprovals]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredApprovals.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          {/* <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span> ({activeContracts.length}) Active Contracts</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Tooltip title="Download" className="mr-3">
                <IconButton className="p-0" color="primary">
                  <span className="material-icons" style={{ fontSize: "28px" }}>
                    cloud_download
                  </span>
                </IconButton>
              </Tooltip>

              <Box
                sx={{ display: { xs: "flex", md: "flex" } }}
                className="ml-2"
              >
                <SearchBox
                  disabled={!activeContracts.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar> */}

          <div>
            <CardContent className="py-0">
              <table className="table table-striped table-hover table-sm fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th className="px-1 ">EmployeeId</th>
                    <th className="px-1">
                      <Box
                        sx={{ display: { xs: "flex", md: "flex" } }}
                        className="align-items-center"
                      >
                        <span className="badge badge-secondary">
                          {activeContracts.length}
                        </span>{" "}
                        <span className="mx-2"> ActiveContracts </span>
                        <SearchBox
                          disabled={!activeContracts.length}
                          onSearch={onSearch}
                          placeholder="Search…"
                        />
                      </Box>
                    </th>
                    {/* <th className="px-1">LastName</th> */}
                    <th className="px-1">Position</th>
                    <th className="px-1">Unit</th>
                    {/* <th className="px-1" style={{ width: "20%" }}>
                        CostCenter
                      </th> */}
                    {/* <th className="px-1">PayrollGroup</th> */}
                    {/* <th className="px-1">basePay</th> */}
                    {/* <th className="px-1">Housing</th> */}
                    {/* <th className="px-1">Transport</th> */}
                    {/* <th className="px-1">Other</th> */}
                    {/* <th className="px-1">Lumpsum</th> */}
                    {/* <th className="px-1">Currency</th> */}
                    <th className="px-1">StartOn</th>
                    <th className="px-1">EndOn</th>
                    <th className="px-1">CostCenter</th>
                    <th className="px-1">SourceOfFunds</th>

                    {/* <th className="px-1">Status</th> */}
                    <th className="px-1 text-right">
                      <Tooltip title="Download" className="mr-3">
                        <span>
                          <IconButton disabled className="p-0" color="primary">
                            <span
                              className="material-icons"
                              style={{ fontSize: "28px" }}
                            >
                              cloud_download
                            </span>
                          </IconButton>
                        </span>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>

                {!!paginatedItems.length && (
                  <tbody>
                    {paginatedItems.map((contract, index) => (
                      <ContractCard
                        key={index}
                        contractIndex={index}
                        contract={contract}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              {!filteredApprovals.length && !loading && (
                <div className="text-center ">
                  <p className="lead">No record found</p>
                </div>
              )}

              {loading && !activeContracts.length && (
                <div style={{ padding: theme.spacing(1) }}>
                  <div className="text-center">
                    <img src={loadingGif} height="128" alt="loading" />
                  </div>
                </div>
              )}

              {!!paginatedItems.length &&
                activeContracts.length >= itemsPerPage && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  activeContracts,
  currencies,
  medicalInsuranceTypes,
}) => {
  return { user, loading, activeContracts, currencies, medicalInsuranceTypes };
};
export default connect(mapStateToProps, {
  getActiveContracts,
  endContract,
  getActiveCurrencies,
  getMedicalInsuranceTypes,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
})(ActiveContracts);
