import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const DisciplineDismissalLetter = (props) => {
  const {
    recipientName,
    effectiveDate,
    institutionName,
    enableToFillLetter = false,
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
    recipientPhone = "",
    recipientEmail = "",
    subTitle = "Dear Sir/Madam",
    effectiveDateInput = "",
    isSentToDisciplinaryCommittee = false,
    providingExplanationLetterNo = "",
    providingExplanationLetterDate = "",
    employeeExplanationLetterDate = "",
    reportCommitteeSummary = "",
    reportCommitteeDate = "",
    isProvisionSuspensionLetterRequired = false,
    explanationFeedbackLetterNo = "",
    explanationFeedbackLetterDate = "",
    provisionalSuspensionNo = "",
    provisionalSuspensionDate = "",
    locationName=""
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                   {locationName || 'Kigali'}, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:{docLetterNo}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
              {recipientName} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              C/O: {institutionName.toUpperCase()}”
            </p>
          </div>
          <div className="content2">
            {/* <!-- Letter content goes here --> */}
            <p>{subTitle},</p>
            <p style={{ fontWeight: "bold" }}>Re: Dismissal Letter</p>
            {isSentToDisciplinaryCommittee && (
              <>
                <p>
                  Reference is made to the internal disciplinary committee
                  report dated on {reportCommitteeDate} whereby{" "}
                  {reportCommitteeSummary};
                </p>
                {isProvisionSuspensionLetterRequired && (
                  <p>
                    Reference is made to Letter N°.{provisionalSuspensionNo} of{" "}
                    {provisionalSuspensionDate} suspending you provisionally for
                    administrative investigation.
                  </p>
                )}
              </>
            )}
            Reference is {isSentToDisciplinaryCommittee && <>also</>} made to
            the following:
            <ul>
              {isSentToDisciplinaryCommittee && (
                <>
                  <li>
                    Letter N°.{explanationFeedbackLetterNo} of{" "}
                    {explanationFeedbackLetterDate} providing feedback on your
                    explanation and requesting the Internal Disciplinary
                    Committee to start carrying out thorough administrative
                    investigations on the administrative faults you are alleged
                    to commit;
                  </li>
                </>
              )}

              <li>
                Your Letter dated on {employeeExplanationLetterDate} providing
                written explanation on the suspected disciplinary faults;
              </li>
              <li>
                Letter N°.{providingExplanationLetterNo} of {providingExplanationLetterDate} requesting you to provide
                written explanations on the administrative faults;
              </li>
            </ul>
            <p>
              Pursuant to the Presidential Order N° 021/01 of 24/02/2021
              determining Professional Ethics for Public Servants, especially in
              its article 30 and 36;
            </p>
            <p>
              In line with the above mentioned disciplinary faults and process,
              you are hereby informed that you are sanctioned with dismissal,
              with effect from{" "}
              {enableToFillLetter ? (
                effectiveDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>{effectiveDate}</span>
                </>
              )}
              .
            </p>
            <p>Sincerely,</p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.ccName || cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisciplineDismissalLetter;
