import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getRequisitionForSupport,
  updateRequisitionForSupport,
} from "../../../store/support/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const searchTypes = [
  { id: "employeeId", name: "Employee ID" },
  { id: "entitySectorId", name: "Entity Sector ID" },
  { id: "unitId", name: "Unit ID" },
  { id: "positionId", name: "Position ID" },
];

const EmployeeContracts = (props) => {
  const { getRequisitionForSupport, updateRequisitionForSupport } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [requisitions, setRequisitions] = useState([]);
  const [searchType, setSearchType] = useState({
    id: "employeeId",
    name: "Employee ID",
  });
  const [searchValue, setSearchValue] = useState("");
  const [formData, setFormData] = useState({
    id: null,
    action: null,
    value: null,
    prevValue: null,
  });
  const [showAlert, setShowAlert] = useState(false);

  const onFind = async (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    getRequisitionForSupport(searchValue, (status, data) => {
      setIsSearching(status);
      if (!status && data) {
        setRequisitions(data);
      }
    });
  };

  const handleRequest = () => {
    updateRequisitionForSupport(formData.id, formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        getRequisitionForSupport(formData.id, (status, data) => {
          setIsSearching(status);
          if (!status && data) {
            setRequisitions(data);
            setFormData({
              id: null,
              action: null,
              value: null,
              prevValue: null,
            });
            setShowAlert(false);
          }
        });
      }
    });
  };

  return (
    <>
      <div
        className="elevated rounded p-3"
        style={{
          backgroundColor: "#fafafa",
          border: "1px solid #17a2b8",
          minHeight: "75vh",
        }}
      >
        <form onSubmit={onFind} autoComplete="off">
          <div className="row mb-3">
            <div className="col-3">
              <Autocomplete
                loading={isSearching || isSubmitting}
                size="small"
                id="search-type"
                defaultValue={null}
                value={searchType}
                options={searchTypes}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, item) => {
                  setSearchType(item || null);
                }}
                getOptionLabel={(option) => option?.name}
                renderOption={(props, item) => (
                  <Box component="li" {...props}>
                    {item?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // required={true}
                    {...params}
                    label="Select the search type"
                    placeholder="Select the search type"
                    // onChange={(e) => {
                    //   handleKeywordChange(e);
                    // }}
                    inputProps={{
                      ...params.inputProps,
                      //   autoComplete: "search-type", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <div className="col-12 mb-2">
                {isSearching && <LinearProgress />}
              </div>
            </div>
            <div className="col-7">
              <TextField
                fullWidth
                size="small"
                // rows={4}
                name="searchTerm"
                label={`Search by ${searchType?.name || ""}`}
                placeholder={`Search by ${searchType?.name || ""}`}
                variant="outlined"
                className="font-weight-bold mb-2"
                value={searchValue || ""}
                onChange={(e) => {
                  setSearchValue(e.target.value || "");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          borderRadius: "0px",
                        }}
                        className={`badge badge-primary`}
                      >
                        {searchType?.name || ""}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-2">
              <button
                disabled={isSearching || !searchValue}
                type="submit"
                className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
              >
                <span className="material-icons">search</span>
                Search
              </button>
            </div>
          </div>
        </form>

        <table className="table table-striped table-hover table-sm  fixTableHead">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th>#</th>
              <th>Entity</th>
              <th>Unit</th>
              <th>Position</th>
              <th
                style={{
                  maxWidth: "150px",
                }}
              >
                No of Posts
              </th>
              <th>Written Exam</th>
              <th>Oral Exam</th>
              <th>Psychometric Test</th>
              <th>Status</th>
            </tr>
          </thead>
          {isSearching && !requisitions.length ? (
            <TableSkeleton cols={8} />
          ) : (
            <tbody>
              {requisitions.map((req) => (
                <tr key={req.id}>
                  <td>{req.id}</td>
                  <td>
                    {req.entityName} / <b>{req.sectorName}</b>
                  </td>
                  <td>{req.unitName}</td>
                  <td>{req.positionName}</td>
                  <td
                    className={`text-center`}
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      {formData.action !== "numberOfPosts" ? (
                        <span
                          style={{
                            fontSize: ".9rem",
                            fontWeight: "700",
                          }}
                        >
                          {req.numberOfPosts}
                        </span>
                      ) : (
                        <input
                          type="number"
                          className="form-control form-control-sm text-center"
                          style={{ width: "80px" }}
                          value={formData.value}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              value: e.target.value,
                            })
                          }
                        />
                      )}
                      {formData.id === req.id &&
                      formData.action === "numberOfPosts" &&
                      isSubmitting ? (
                        <div className={`float-right`}>
                          <CircularProgress color="info" size={15} />
                        </div>
                      ) : formData.id === req.id &&
                        formData.action === "numberOfPosts" ? (
                        <div className={`d-flex align-items-center`}>
                          <IconButton
                            aria-label="save number of posts"
                            size="small"
                            className="mr-2"
                            color="primary"
                            onClick={() => setShowAlert(true)}
                            disabled={
                              isSubmitting ||
                              formData.value === req.numberOfPosts ||
                              !formData.value ||
                              formData.value < req.numberOfPosts
                            }
                          >
                            <CheckRoundedIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="cancel number of posts"
                            size="small"
                            onClick={() =>
                              setFormData({
                                id: null,
                                action: null,
                                value: null,
                                prevValue: null,
                              })
                            }
                            color="error"
                          >
                            <ClearRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      ) : (
                        <div className={`float-right`}>
                          <IconButton
                            aria-label="change number of posts"
                            size="small"
                            onClick={() =>
                              setFormData({
                                id: req.id,
                                action: "numberOfPosts",
                                value: req.numberOfPosts,
                                prevValue: req.numberOfPosts,
                              })
                            }
                            disabled={isSubmitting || !!formData.id}
                          >
                            <EditRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <IconButton
                      aria-label="change status of written exam"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "willHaveWrittenExam",
                          value: !req.willHaveWrittenExam,
                          prevValue: req.willHaveWrittenExam,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.willHaveWrittenExam ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>
                  <td>
                    <IconButton
                      aria-label="change status of oral exam"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "willHaveOralExam",
                          value: !req.willHaveOralExam,
                          prevValue: req.willHaveOralExam,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.willHaveOralExam ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>
                  <td>
                    <IconButton
                      aria-label="change status of psychometric test"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "willHavePsychometricTest",
                          value: !req.willHavePsychometricTest,
                          prevValue: req.willHavePsychometricTest,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.willHavePsychometricTest ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>
                  <td>{req.statusName}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {!requisitions.length && !isSearching && (
          <div className="jumbotron jumbotron-fluid text-center  mt-0">
            <div className="container py-5">
              <p className="lead">No record found</p>
            </div>
          </div>
        )}
      </div>

      {showAlert && !!formData.id && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            formData.action === "numberOfPosts"
              ? `change the number of posts from ${formData.prevValue} to ${formData.value}`
              : formData.action === "willHaveWrittenExam" && formData.value
              ? "enable written exam"
              : formData.action === "willHaveWrittenExam" && !formData.value
              ? "disable written exam"
              : formData.action === "willHaveOralExam" && formData.value
              ? "enable oral exam"
              : formData.action === "willHaveOralExam" && !formData.value
              ? "disable oral exam"
              : formData.action === "willHavePsychometricTest" && formData.value
              ? "enable psychometric test"
              : formData.action === "willHavePsychometricTest" &&
                !formData.value
              ? "disable psychometric test"
              : "  do unknown action"
          }?`}
          setConfirmationDialog={() => {
            setFormData({
              id: null,
              action: null,
              value: null,
              prevValue: null,
            });
            setShowAlert(false);
          }}
          onYes={handleRequest}
        />
      )}
    </>
  );
};

export default connect(null, {
  getRequisitionForSupport,
  updateRequisitionForSupport,
})(EmployeeContracts);
