const types = {
  USER_FOUND: "USER_FOUND",
  SET_ENTITY_SECTORS: "SET_ENTITY_SECTORS",
  SET_ENTITY_SECTOR_UNITS: "SET_ENTITY_SECTOR_UNITS",
  SET_COST_CENTERS: "SET_COST_CENTERS",
  SET_ENTITY_SECTOR_COST_CENTERS: "SET_ENTITY_SECTOR_COST_CENTERS",
  SET_ENTITY_SECTOR_CREDITORS: "SET_ENTITY_SECTOR_CREDITORS",
  SET_ENTITY_SECTOR_PAYROLL_TYPES: "SET_ENTITY_SECTOR_PAYROLL_TYPES",
};

export default types;
