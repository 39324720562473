import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getApplicationEducations } from "../../../../store/profile/actions";
import NoResults from "../../../common/components/NoResults";
import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import AdvertSkeleton from "../../../common/components/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { showError } from "../../../toastify";
import moment from "moment";

export const Educations = (props) => {
  const {
    applicationId,
    env,
    loading,
    onWait,
    applicationEducations,
    getApplicationEducations,
  } = props;

  useEffect(() => {
    getApplicationEducations(applicationId);
  }, []);

  // const [viewDocument, setViewDocument] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);

  return (
    <>
      <Card className={`border rounded mb-3`} elevation={1} id="educations">
        <CardContent>
          {loading && !onWait && !applicationEducations.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography
                    variant="h6"
                    style={{ color: "#078ece" }}
                    className="mr-1"
                  >
                    Education background
                  </Typography>
                </div>
              </div>

              {applicationEducations.length === 0 ? (
                <NoResults />
              ) : (
                applicationEducations.map((education, index) => (
                  <EducationItemCard
                    key={education.id}
                    education={education}
                    index={index}
                    loading={loading}
                    setLoadingDocument={setLoadingDocument}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ env, loading, onWait, applicationEducations }) => ({
  env,
  loading,
  onWait,
  applicationEducations,
});

const mapDispatchToProps = {
  getApplicationEducations,
};

export default connect(mapStateToProps, mapDispatchToProps)(Educations);

const EducationItemCard = (props) => {
  const { loading, env, education, setLoadingDocument } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {education?.graduationYear}
            </span>
          }
          title={<Typography>{education?.qualification?.name}</Typography>}
          subheader={
            <>
            <span>
              <span className="btn-link">{education?.school?.name}</span> /{" "}
              <strong>{education.school?.country?.name}</strong>
            </span>
             <p
             className="small text-muted mb-0 rounded-3 mt-2 float-end"
             style={{
               fontSize: "11px",
             }}
           >
             Updated on, {moment(education.createdOn).format("ll")}
           </p>
            </>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                <IconButton
                  // disableRipple
                  className=""
                  size="small"
                  onClick={() => {
                    if (education.docReferenceId) {
                      setOnViewDocument(true);
                    } else {
                      showError("Document is not available");
                    }
                  }}
                >
                  <FilePresentIcon
                    // fontSize="large"
                    style={{
                      color: !!education.docReferenceId
                        ? "#fe4066"
                        : "#c57c7cb8",
                    }}
                  />
                </IconButton>
              </div>
            </>
          }
        />
      </Card>
      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Education document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              education.docReferenceId,
          }}
        />
      )}
    </>
  );
};
