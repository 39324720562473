import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getSessionAdditionalMinutes,
  saveExamAdditionalMinutes,
} from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import PaperDraggableComponent from "../../common/components/PaperDraggableComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import { isNull } from "../../common/components/Utils";

const WrittenExamAdditionalMinutesDialog = (props) => {
  const {
    loading,
    showAdditionalMinutesWrittenExamForm,
    setShowAdditionalMinutesWrittenExamForm,
    selectedWrittenExam,
    saveExamAdditionalMinutes,
  } = props;

  const [formData, setFormData] = useState({
    examId: selectedWrittenExam.id,
    additionalMinutes: 0,
    additionalMinutesComments: selectedWrittenExam.additionalMinutesComments,
  });
  const [errors, setErrors] = useState({
    additionalMinutesHasError: false,
    commentHasError: false,
  });

  const formValidator = () => {
    const error = {
      additionalMinutesHasError: false,
      commentHasError: false,
      hasError: false,
    };

    if (!formData.additionalMinutes || formData.additionalMinutes <= 0) {
      error.additionalMinutesHasError = true;
      error.hasError = true;
    }

    if (!formData.additionalMinutesComments) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.additionalMinutes =
        parseFloat(isNull(selectedWrittenExam.additionalMinutes, 0)) +
        parseFloat(formData.additionalMinutes);
      saveExamAdditionalMinutes(formData, onClose);
    }
  };

  const onClose = () => {
    setShowAdditionalMinutesWrittenExamForm(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperDraggableComponent}
        onClose={onClose}
        open={showAdditionalMinutesWrittenExamForm}
        // fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="text-primary"
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Typography variant="overline" display="block">
            Additional Minutes
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="row">
            <div className="col-lg-12 mt-2">
              <TextField
                disabled={selectedWrittenExam.wasExamMarkingPublished}
                fullWidth
                size="small"
                name="additionalMinutes"
                autoFocus
                label="Minutes"
                variant="outlined"
                placeholder="Minutes"
                value={formData.additionalMinutes}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const additionalMinutes = e.target.value;
                  setFormData({
                    ...formData,
                    additionalMinutes,
                  });
                  const additionalMinutesHasError = false;
                  setErrors({ ...errors, additionalMinutesHasError });
                }}
              />
              <ErrorMessage
                hasError={errors.additionalMinutesHasError}
                message="Additional minutes is required"
              />
            </div>
            <div className="col-lg-12 mt-2">
              <TextField
                disabled={selectedWrittenExam.wasExamMarkingPublished}
                fullWidth
                multiline
                rows={2}
                size="small"
                name="comment"
                autoFocus
                label="Comments"
                variant="outlined"
                placeholder="Comments"
                value={formData.additionalMinutesComments}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const additionalMinutesComments = e.target.value;
                  setFormData({
                    ...formData,
                    additionalMinutesComments,
                  });
                  const commentHasError = false;
                  setErrors({ ...errors, commentHasError });
                }}
              />
              <ErrorMessage
                hasError={errors.commentHasError}
                message="Comment is required"
              />
            </div>

            <div className="col-12 mt-2 mb-2">
              <div className="input-group input-group-sm mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Total Additional
                  </span>
                </div>
                <div className="form-control">
                  <div className="form-check form-check-inline">
                    <div>
                      {parseFloat(
                        isNull(selectedWrittenExam.additionalMinutes, 0)
                      ) + parseFloat(formData.additionalMinutes)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-2">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase float-right"
                disabled={
                  loading || selectedWrittenExam.wasExamMarkingPublished
                }
              >
                {loading ? "Wait..." : "Add"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  getSessionAdditionalMinutes,
  saveExamAdditionalMinutes,
})(WrittenExamAdditionalMinutesDialog);
