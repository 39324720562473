import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ApplicantsAttended from "./ApplicantsAttended";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import WrittenExamApplicantsNotAttended from "./WrittenExamApplicantsNotAttended";
import OralExamApplicantsNotAttended from "./OralExamApplicantsNotAttended";
const Attandance = (props) => {
  const {
    showAttandanceLists,
    setShowAttandanceLists,
    selectedExam,
    examTypeId,
  } = props;

  const onClose = () => {
    setShowAttandanceLists(false);
  };
  const { hasPermissions } = useRequiredAuth();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const doesExamEnded = (exam) => {
    const seatingDate = moment(exam.seatingDate).format("YYYY-MM-DD");

    const endDate = moment(seatingDate + " " + exam.endTime).format(
      "YYYY-MM-DD HH:mm"
    );
    if (endDate < moment().format("YYYY-MM-DD HH:mm")) return true;
    else return false;
  };

  return (
    <>
      <Dialog open={showAttandanceLists} fullWidth maxWidth="lg">
        <DialogTitle>
          <Typography variant="h6" noWrap component="div" maxWidth={510}>
            <span className="text-dark">
              {examTypeId == 1 ? "Written Exam " : "Oral Exam"}
            </span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "5px" }}
              >
                chevron_right
              </span>
            </span>

            <span style={{ color: "rgb(7, 142, 206)" }}>Attendance</span>

            <span className="ml-2">
              {" "}
              |{" "}
              {selectedExam.advertisement
                ? selectedExam.advertisement.requisition.position.name
                : ""}
              {selectedExam && selectedExam.positionName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="card elevated rounded border mt-0 bg-light">
                  <div className="card-header rounded-top bg-primary text-light">
                    <ManageSearchIcon /> Search for Candidate
                  </div>
                  <div className="card-body">
                    {+examTypeId === 1 ? (
                      <>
                        <WrittenExamApplicantsNotAttended
                          examId={selectedExam.id || selectedExam.examId}
                          examTypeId={examTypeId}
                          disableCentersOption={!!selectedExam?.examCenterId}
                          center={{id:selectedExam?.examCenterId || 0, center:{
                            name:selectedExam?.centerName || '',
                            id:selectedExam?.centerId || ''
                          } }}
                        />
                      </>
                    ) : (
                      <>
                        <OralExamApplicantsNotAttended
                          examId={selectedExam.id || selectedExam.examId}
                          examTypeId={examTypeId}
                          disablePanelsOption={!!selectedExam?.panelId}
                          currentPanel={{ panel:selectedExam?.panelName || '', id:selectedExam?.panelId || '' }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <div className="card elevated rounded border mt-0 bg-light h-100">
                  <div className="card-header rounded-top text-dark">
                    List of Attended Candidate(s)
                   {selectedExam?.centerName &&  <span className="float-right badge badge-primary">{selectedExam?.centerName} Center</span> }
                   {selectedExam?.panelName &&  <span className="float-right badge badge-primary">{selectedExam?.panelName} Panel</span> }
                  </div>
                  <div className="card-body h-100">
                    <ApplicantsAttended
                      examId={selectedExam.id || selectedExam.examId}
                      examTypeId={examTypeId}
                      roomId={+examTypeId===1? selectedExam?.examCenterId || '':selectedExam?.panelId || ''}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Attandance;
