import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSanctionsWithFaultCategory } from "../../../store/discipline/actions";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../common/components/TableSkeleton";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CardContent,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const Sanction = (props) => {
  const { getSanctionsWithFaultCategory, sanctions } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [filteredSanctions, setFilteredSanctions] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    getSanctionsWithFaultCategory();
  }, []);

  useEffect(() => {
    if (sanctions.length > 0) {
      setIsLoading(false);
      setFilteredSanctions(sanctions);
      paginate(sanctions);
    }
  }, [sanctions]);

  useEffect(() => {
    paginate(filteredSanctions);
  }, [itemOffset, itemsPerPage, filteredSanctions]);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredSanctions.length || 0;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <Toolbar className="row">
            <div className="col-lg-3">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>DISCIPLINE</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>Sanctions</span>
                </span>
              </Typography>
            </div>

            <div className="col-lg-7 text-center">
              <span
                style={{ fontWeight: 800 }}
                className="text-secondary float-right"
              >
                {/* Support Contact:{" "}
                <span className="badge badge-primary py-1">
                  +(250) 788 444 903
                </span>{" "}
                (Mbabazi Comfort) */}
              </span>
            </div>

            <div className="col-lg-2">
              <IconButton
                onClick={() => {
                  //   setAddSanction(true);
                }}
                style={{
                  ...ActiveButton,
                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="medium"
              >
                <AddIcon />
              </IconButton>
            </div>
          </Toolbar>
          <CardContent>
            <table className="table-striped table-hover table-sm fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">ID</th>
                  <th scope="col">Sanction Name</th>
                  <th scope="col">Fault Category</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableSkeleton cols={3} />
                ) : (
                  <>
                    {paginatedItems.map((sanction, index) => (
                      <tr key={index}>
                        <td>{sanction.id}</td>
                        <td>{sanction.sanctionName}</td>
                        <td>{sanction.faultCategory}</td>
                        <td>
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>

            {!filteredSanctions.length && !isLoading && (
              <NoRecordMessage
                isEmpty={!sanctions.length}
                title="No Sanctions found"
              />
            )}

            {!isLoading && (
              <div className="justify-content-center mt-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<span className="pr-1">Next</span>}
                  previousLabel={<span className="pl-1">Previous</span>}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            )}
          </CardContent>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  sanctions: state.sanctions,
});

const mapDispatchToProps = {
  getSanctionsWithFaultCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sanction);
