import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getExamQuestions,
  deleteAllExamQuestions,
  markCanceledExamQUestion,
  unmarkedCanceledExamQUestion,
  canceledExamQuestion,
} from "../../../store/recruitment/actions";

import { doesExamEnded, totalSum } from "../../common/components/Utils";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import SearchBox from "../../common/components/SearchBox";
import EditWrittenExamQuestionDialog from "./EditWrittenExamQuestionDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const WrittenExamQuestionCard = (props) => {
  const {
    exam,
    index,
    setShowEditQuestion,
    setShowCancelQuestion,
    setShowUnCancelledQuestion,
    setSelectedQuestion,
    writtenExam,
    userType,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <tr>
        <th
          scope="row"
          style={{
            backgroundColor:
              !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                ? "rgb(117, 120, 143)"
                : !!exam?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "#f0f2f5",
          }}
        >
          {exam.questionNo}
        </th>

        <td
          className="text-left"
          style={{
            width: "760px",
            backgroundColor:
              !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                ? "rgb(117, 120, 143)"
                : !!exam?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
          }}
        >
          {" "}
          {!!exam?.isExamQuestionCanceled && (
            <span className="badge badge-danger px-1 py-1 mr-2">
              {" "}
              Question Canceled{" "}
            </span>
          )}
          {exam.question && exam.question.description}
        </td>
        <td
          className="text-left"
          style={{
            backgroundColor:
              !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                ? "rgb(117, 120, 143)"
                : !!exam?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
          }}
        >
          {exam.question && !!exam.question.multipleChoiceOptions.length && (
            <>
              {exam.question.multipleChoiceOptions.map((option, index) => (
                <p key={index}>
                  {option.optionNumber}: {option.description}
                  {option.isCorrect && (
                    <span className="badge badge-success ml-1"> Correct</span>
                  )}
                </p>
              ))}
            </>
          )}
        </td>

        <td
          className="text-left"
          style={{
            backgroundColor:
              !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                ? "rgb(117, 120, 143)"
                : !!exam?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
          }}
        >
          {exam.question &&
            exam.question.questionType &&
            exam.question.questionType.name}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor:
              !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                ? "rgb(117, 120, 143)"
                : !!exam?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
          }}
        >
          {" "}
          {exam.marks}
        </td>
        {!writtenExam.isCanceled && userType !== "ADMIN" && (
          <td
            className="text-center"
            style={{
              backgroundColor:
                !!exam?.markedAsCanceled && !exam?.isExamQuestionCanceled
                  ? "rgb(117, 120, 143)"
                  : !!exam?.isExamQuestionCanceled
                  ? "rgb(245, 144, 144)"
                  : "",
            }}
          >
            {(hasPermissions(["IS_HR"]) || !!writtenExam?.isExaminer) && (
              <button
                disabled={writtenExam.wasExamMarkingPublished || (doesExamEnded(writtenExam) && !!writtenExam.isActive)}
                onClick={() => {
                  setSelectedQuestion(exam);
                  setShowEditQuestion(true);
                }}
                type="button"
                title="Edit"
                className="btn btn-primary btn-sm"
              >
                <i className="fas fa-edit"></i>
              </button>
            )}

            {hasPermissions(["IS_NPSC","IS_CBM"]) && (
              <>
                {!!exam?.markedAsCanceled ? (
                  <button
                    disabled={!!exam?.isExamQuestionCanceled}
                    onClick={() => {
                      setSelectedQuestion(exam);
                      setShowUnCancelledQuestion(true);
                    }}
                    type="button"
                    title="Uncancelled"
                    className="btn btn-success btn-sm ml-2"
                  >
                    Uncancelled
                  </button>
                ) : (
                  <button
                    disabled={!!exam?.isExamQuestionCanceled}
                    onClick={() => {
                      setSelectedQuestion(exam);
                      setShowCancelQuestion(true);
                    }}
                    type="button"
                    title="Cancel"
                    className="btn btn-danger btn-sm ml-2"
                  >
                    Cancel
                  </button>
                )}
              </>
            )}
          </td>
        )}
      </tr>
    </>
  );
};

const WrittenExamQuestion = (props) => {
  const {
    loading,
    exam,
    getExamQuestions,
    examQuestions,
    deleteAllExamQuestions,
    markCanceledExamQUestion,
    unmarkedCanceledExamQUestion,
    canceledExamQuestion,
    userType,
    setShowUploadWrittenExamQuestionsForm,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [showCancelQuestion, setShowCancelQuestion] = useState(false);
  const [showUnCancelledQuestion, setShowUnCancelledQuestion] = useState(false);
  const [searchTeam, setSearchTeam] = useState("");
  const [confirmRemoveAll, setConfirmRemoveAll] = useState(false);
  const [confirmToCancelQuestions, setConfirmToCancelQuestions] =
    useState(false);

  const [filteredExamQuestions, setFilteredExamQuestions] = useState([
    ...examQuestions,
  ]);

  useEffect(
    () => {
      getExamQuestions(exam.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exam]
  );

  useEffect(() => {
    const filtered = [...examQuestions].filter(({ question }) =>
      question?.description.toLowerCase().includes(searchTeam.toLowerCase())
    );
    if (!!filtered.length) setFilteredExamQuestions(filtered);
    else {
      setFilteredExamQuestions(examQuestions);
    }
  }, [searchTeam, examQuestions]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <PreLoader isLoading={loading && !examQuestions.length} />

      <div className="row mt-3">
        <div className={!!examQuestions.length ? "col-lg-6" : "col-lg-10"}>
          {!!examQuestions.length && (
            <span>
              Total Q/S: {examQuestions.length} | Marks:
              {totalSum(examQuestions, "marks")}%
            </span>
          )}
        </div>
        <div className={!!examQuestions.length ? "col-lg-6" : "col-lg-2"}>
          <div className="row justify-content-end">
            <div className="col-9">
              {!!examQuestions.length && (
                <SearchBox
                  onSearch={onSearch}
                  placeholder="Search…"
                  className="float-right w-100"
                />
              )}
            </div>

            {!!examQuestions.length && userType !== "ADMIN" && (
              <div className="col-3">
                {examQuestions.find(
                  ({ markedAsCanceled }) => !!markedAsCanceled
                ) ? (
                  <>
                    {hasPermissions(["IS_CBM"]) && (
                      <button
                        onClick={() => setConfirmToCancelQuestions(true)}
                        type="button"
                        className="btn btn-primary float-right text-truncate"
                      >
                        Confirm to cancel(
                        {
                          filteredExamQuestions.filter(
                            ({ markedAsCanceled }) => !!markedAsCanceled
                          ).length
                        }
                        )
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {(hasPermissions(["IS_HR"]) || !!exam?.isExaminer ) && (
                      <button
                        disabled={
                          loading ||
                          exam.wasExamMarkingPublished ||
                          doesExamEnded(exam)
                        }
                        onClick={() => setConfirmRemoveAll(true)}
                        type="button"
                        className="btn btn-danger float-right"
                      >
                        Delete All
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <NoRecordMessage
            isEmpty={!filteredExamQuestions.length && !loading}
            title="No Questions found. "
          />
        </div>
        <div className="col-lg-12 mt-3">
          {!!filteredExamQuestions.length && (
            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left">
                    Description
                  </th>

                  <th scope="col" className="text-left">
                    Assertion
                  </th>

                  <th scope="col" className="text-left">
                    Question Type
                  </th>
                  <th scope="col" className="text-center">
                    Marks
                  </th>
                  {!exam.isCanceled && userType !== "ADMIN" && (
                    <th
                      scope="col"
                      className="text-center"
                      style={{ width: "140px" }}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {filteredExamQuestions.map((tempQuestion, index) => (
                  <WrittenExamQuestionCard
                    exam={tempQuestion}
                    index={index}
                    key={index}
                    writtenExam={exam}
                    setShowEditQuestion={setShowEditQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                    setShowCancelQuestion={setShowCancelQuestion}
                    setShowUnCancelledQuestion={setShowUnCancelledQuestion}
                    userType={userType}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {showEditQuestion && (
        <EditWrittenExamQuestionDialog
          question={selectedQuestion}
          showEditQuestion={showEditQuestion}
          setShowEditQuestion={setShowEditQuestion}
        />
      )}
      {confirmRemoveAll && exam && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAll}
          message={`Are you sure you want to remove all questions?`}
          setConfirmationDialog={setConfirmRemoveAll}
          onYes={() => {
            deleteAllExamQuestions(
              exam,
              setConfirmRemoveAll,
              setShowUploadWrittenExamQuestionsForm
            );
          }}
        />
      )}
      {confirmToCancelQuestions && exam && (
        <ConfirmationDialog
          confirmationDialog={confirmToCancelQuestions}
          message={`Are you sure you want to cancel all marked exam questions(${
            examQuestions.filter(({ markedAsCanceled }) => !!markedAsCanceled)
              .length
          })?`}
          setConfirmationDialog={setConfirmToCancelQuestions}
          onYes={() => {
            canceledExamQuestion(exam.id, setConfirmToCancelQuestions);
          }}
        />
      )}

      {showCancelQuestion && selectedQuestion && (
        <ConfirmationDialog
          confirmationDialog={showCancelQuestion}
          message={`Are you sure you want to cancel this question?`}
          setConfirmationDialog={setShowCancelQuestion}
          onYes={() => {
            markCanceledExamQUestion(
              selectedQuestion.id,
              setShowCancelQuestion
            );
          }}
        />
      )}
      {showUnCancelledQuestion && selectedQuestion && (
        <ConfirmationDialog
          confirmationDialog={showUnCancelledQuestion}
          message={`Are you sure you want to uncancelled this question?`}
          setConfirmationDialog={setShowUnCancelledQuestion}
          onYes={() => {
            unmarkedCanceledExamQUestion(
              selectedQuestion.id,
              setShowUnCancelledQuestion
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, examQuestions }) => {
  return {
    loading,
    examQuestions,
  };
};
export default connect(mapStateToProps, {
  getExamQuestions,
  deleteAllExamQuestions,
  markCanceledExamQUestion,
  unmarkedCanceledExamQUestion,
  canceledExamQuestion,
})(WrittenExamQuestion);
