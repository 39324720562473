import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress
} from "@mui/material";
import React from "react";
import moment from "moment";
import rwandaLogo from "../../assets/rwanda-logo.png";

const DraftAuthorizationPermit = (props) => {
  const {
    permitReferenceNo,
    draftAuthorizationPermitContent,
    showAuthorizationPermit,
    setShowAuthorizationPermit,
    dialogActions,
    loading,
    headOfInstitution,
    signContent
  } = props;

  const onClose = () => {
    setShowAuthorizationPermit(false);
  };


  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAuthorizationPermit}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-10">Draft of Authorization for training</div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  {!dialogActions && (
                    <IconButton
                      aria-label="close"
                      onClick={onClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <span className="material-icons">close</span>
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          {dialogActions && loading && (
            <div className="col-12">
              <LinearProgress />
            </div>
          )}
       <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {headOfInstitution.institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            {/* <!-- Date and reference number go here --> */}
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                  <br />
                    N<sup>o</sup>:{permitReferenceNo? permitReferenceNo: "........."}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div
            className={"auth-content"}
          >
            <div className="header">
              <h1 style={{ fontWeight: "bold" }}>Authorization for training</h1>
            </div>

            <div
              style={{ marginBottom: "30px" }}
              className={ "content2"}
            >
              {draftAuthorizationPermitContent && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: draftAuthorizationPermitContent,
                  }}
                ></div>
              ) }
            </div>

            <div className="signature">
              {signContent && (
                <p
                  style={{
                    position: "relative",
                    fontWeight: 800,
                    textAlign: "left",
                  }}
                >
                  {signContent}
                </p>
              )}

              <p style={{ fontWeight: 800 }}>
                {headOfInstitution?.firstName || ""}{" "}
                {headOfInstitution?.lastName || ""}
              </p>

              <p style={{ fontWeight: 800 }}>
                {headOfInstitution?.positionName || 'Minister of Public Service and Labour'}
                
              </p>
              {!!headOfInstitution?.canAddInstitutionName ? (
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 800,
                    }}
                  >
                    {headOfInstitution?.institutionName}
                  </p>
                ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </div>
        </DialogContent>
        {dialogActions && (
          <DialogActions className="d-flex justify-content-center py-4">
            {dialogActions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default  DraftAuthorizationPermit;
