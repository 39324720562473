import React, { useState, useEffect } from "react";
import moment from "moment";
import { Alert, Button, Link } from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import RequestTimelineDialog from "./RequestTimelineDialog";

const NotificationDayLeft = (props) => {
  const { request } = props;
  const [showRequestTimeline, setShowRequestTimeline] = useState(false);
  const overdueDays = (departureDate) => {
    var departureDate = moment(departureDate);
    var currentDate = moment();

    // Calculate the difference in days
    var overdueDays = currentDate.diff(departureDate, "days");

    // Make sure overdueDays is a positive number (if the current date is after the departure date)
    overdueDays = Math.max(0, overdueDays);
    return overdueDays;
  };
  return (
    <>
      {showRequestTimeline && (
        <RequestTimelineDialog
          request={request}
          showRequestTimeline={showRequestTimeline}
          setShowRequestTimeline={setShowRequestTimeline}
        />
      )}
      <Alert
        className="mt-2"
        variant="outlined"
        severity={!!request?.isTimeTrackingExpired || !!request?.isLevelDayOverdue ? "warning" : "success"}
        sx={{
          padding: "1px 15px 0px",
          width: "100%",
        }}
        icon={<CircleNotificationsIcon style={{ color: "#0099e8" }} />}
        action={
          <>
            <Button className="mt-0 mr-3" onClick={() => setShowRequestTimeline(true)}>
              View summary
            </Button>
          </>
        }
      >
        <marquee behavior="" direction="" style={{ width: "100%" }}>
          {!request.isOverdue  && ![17, 4, 8, 12, 16,21].includes(request.statusId) && (
            <>
              <strong style={{ width: "100%" }}>
                {request.levelDayLeft} / {request.levelDayLeftDesc}
              </strong>
            </>
          )}
          {!!request.isOverdue  && ![17, 4, 8, 12, 16,21].includes(request.statusId) && (
            <>
              <strong className="badge badge-danger" style={{ width: "100%" }}>
                {overdueDays(request.departureDate)} days overdue
              </strong>
            </>
          )}
        </marquee>
      </Alert>
    </>
  );
};
export default NotificationDayLeft;
