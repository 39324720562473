import eapealTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";
import commonTypes from "../common/action-types";
import download from "downloadjs";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...eapealTypes };

export const getApplealTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/e-appeal/appeal-types");

      dispatch({
        type: types.SET_APPEAL_TYPES,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//my-appeals

export const getInProgressAppeals = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      // dispatch({
      //   type: types.SET_APPEALS,
      //   data: [],
      // });

      const { data } = await axios.get("/api/e-appeal/in-progress-appeals");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getArchivedAppeals = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPEALS,
        data: [],
      });
      const { data } = await axios.get("/api/e-appeal/archived-appeals");

      dispatch({
        type: types.SET_APPEALS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getOverdueAppeals = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPEALS,
        data: [],
      });
      const { data } = await axios.get("/api/e-appeal/overdue-appeals");
     
      dispatch({
        type: types.SET_APPEALS,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitFirstLevelAppealDecision = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/e-appeal/first-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("First Level Appeal Decision Submitted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
        isFirstLevelValid: false,
        firstLevelValid: "",
        attachedFiles: [],
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const confirmFirstLevelAppealDecision = (
  formData,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/e-appeal/confirm-first-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("First Level Appeal Decision Approved successfully");
      dispatch({ type: types.END_LOADING });
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//submitResolveAppeal

export const submitResolveAppeal = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/e-appeal/resolved-appeals",
        formData
      );
      const { appealDetails, appeal, appealResolved } = data;
      showSuccess("Submitted successfully");
      dispatch({
        type: types.SET_APPEAL_RESOLVED,
        data: appealResolved,
      });

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
        attachedFiles: [],
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const closeAppeal = (
  appealId,
  setIsSubmitting,
  setConfirmAppealResolved
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.put(
        "/api/e-appeal/close-appeal/" + appealId
      );
      const { appealDetails, appeal } = data;
      showSuccess("Appeal Closed successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
      setConfirmAppealResolved(false);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const submitSecondLevelAppealDecision = (
  formData,
  setFormData,
  setIsSubmitting,
  onClose
) => {
  return async (dispatch) => {
    try {
      setIsSubmitting(true);

      const { data } = await axios.post(
        "/api/e-appeal/second-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("Second Level Appeal Decision Submitted successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      setFormData({
        appealId: 0,
        message: "",
        isSecondLevelValid: false,
        secondLevelValid: "",
        attachedFiles: [],
      });

      setIsSubmitting(false);
      onClose();
    } catch (error) {
      setIsSubmitting(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const confirmSecondLevelAppealDecision = (
  formData,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/e-appeal/confirm-second-level-appeal-decision",
        formData
      );
      const { appealDetails, appeal } = data;
      showSuccess("Second Level Appeal Decision Approved successfully");

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });

      onClose();
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setAppeal = (appeal) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
    } catch (error) {}
  };
};
//

export const getAppealDetails = (referenceNo) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: [],
      });
      const { data } = await axios.get(
        "/api/e-appeal/appeal-details/" + referenceNo
      );

      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getAppealResolved = (appealId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_APPEAL_RESOLVED,
        data: null,
      });
      const { data } = await axios.get(
        "/api/e-appeal/resolved-appeals/" + appealId
      );

      dispatch({
        type: types.SET_APPEAL_RESOLVED,
        data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const assignSupervisorToAppeal = (params, setSupervisorId, onClose) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "/api/e-appeal/assign-supervisor/",
        params
      );
      const { appealDetails, appeal } = data;
      dispatch({
        type: types.SET_APPEAL_DETAILS,
        data: appealDetails,
      });
      dispatch({
        type: types.UPDATE_APPEAL,
        data: appeal,
      });

      dispatch({
        type: types.SET_APPEAL,
        data: appeal,
      });
      showSuccess("Supervisor assigned successfully!");
      onClose();
      setSupervisorId("0");
    } catch (error) {
      setSupervisorId("0");
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadAppeal = (referenceNo, subjectL1, setIsDownloading) => {
  return async (dispatch) => {
    setIsDownloading(true);
    try {
      const { data } = await axios.get(
        "/api/e-appeal/download-appeal/" + referenceNo,
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `${subjectL1.replace(/\s/g, "_") + "_" + referenceNo}.pdf`,
        ".pdf"
      );

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};
