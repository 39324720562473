import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InprogressRequests from "./pages/InprogressRequests";
import UserGuide from "./pages/UserGuide";

import MenuBar from "./components/MenuBar";
import ApprovedRequests from "./pages/ApprovedRequests";
import RejectedRequests from "./pages/RejectedRequests";
import CdPlans from "./pages/CdPlans";
import { useRequiredAuth } from "../common/guard/requiredAuth";
import OverSightRequests from "./pages/OverSightRequests";
import FindEmployeeToUnlock from "./components/FindEmployeeToUnlock";
import Notifications from "./pages/Notifications";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SupportButton from "../common/components/SupportButton";
import PrivateRoute from "../common/guard/PrivateRoute";

const Layout = () => {
  const location = useLocation();
  // const [toggledExpandedTrainingNotification, setToggledExpandedTrainingNotification] = useState(!!window.localStorage.getItem(`HideTrainingNotification`)?false:true);
  return (
    <React.Fragment>
      <MenuBar />
      <Notifications />
      <Router />{" "}
      <SupportButton
        content={
          <>
            <span className="text-secondary  mt-1">
              <span className="text-center" style={{ fontWeight: 800 }}>
                Training Support Team
              </span>
              <div className="mt-2" style={{ fontSize: "12px" }}>
                Mbabazi Comfort:{" "}
                <span className="text-primary" style={{ fontWeight: 800 }}>
                  +(250) 788 444 903{" "}
                </span>{" "}
                <br />
                Amerika Camarade:
                <span className="text-primary" style={{ fontWeight: 800 }}>
                  +(250) 788 545 669{" "}
                </span>
              </div>
            </span>
          </>
        }
        title={"Support contacts"}
      />
    </React.Fragment>
  );
};

export default Layout;

const Router = () => {
  const { hasPermissions } = useRequiredAuth();
  return (
    <React.Fragment>
      <Switch>
        <Route path="/training/user-guide" component={UserGuide} />
        <Route
          path="/training/in-progress-requests"
          component={InprogressRequests}
        />
        <Route
          path="/training/approved-requests"
          component={ApprovedRequests}
        />
        <Route
          path="/training/rejected-requests"
          component={RejectedRequests}
        />

        <PrivateRoute
          children={<OverSightRequests />}
          permissions={["IS_TRAINING_MIFOTRA_OVERSIGHT"]}
          path="/training/oversights"
        />

        <Route path="/training/cd-plans" component={CdPlans} />

        <Route
          path="/training/unlock-employee-for-thirty-days"
          component={FindEmployeeToUnlock}
        />

        <Route
          path="/training"
          component={() => (
            <Redirect
              to={
                hasPermissions([
                  "IS_HR",
                  "IS_TRAINING_MIFOTRA_REVIEWER",
                  "IS_TRAINING_MIFOTRA_VERIFIER",
                  "IS_TRAINING_MINEDUC_REVIEWER",
                  "IS_TRAINING_MINEDUC_VERIFIER",
                  "IS_TRAINING_MINISTRY_VERIFIER",
                  "IS_TRAINING_MINISTRY_REVIEWER",
                  "IS_RDB_TRAINING_APPROVER",
                  "IS_TRAINING_RDB_REVIEWER",
                  "IS_TRAINING_RDB_VERIFIER",
                  "IS_HEAD_OF_INSTITUTION",
                  "IS_TRAINING_MIFOTRA_VIEWER",
                  "IS_TRAINING_MIFOTRA_REVIEWER2",
                  "IS_TRAINING_MIFOTRA_DELEGATED_APPROVER",
                  "IS_TRAINING_INITIATOR",
                ])
                  ? "/training/in-progress-requests"
                  : hasPermissions(["IS_TRAINING_PM_OVERSIGHT"])
                  ? "/training/approved-requests"
                  : hasPermissions(["IS_TRAINING_MIFOTRA_OVERSIGHT"])
                  ? "/training/approved-requests"
                  : "/training/in-progress-requests"
              }
            />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};
