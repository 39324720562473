export const defaultState = {
  trainingRequests: [],
  rejectedTrainingRequests: [],
  approvedTrainingRequests: [],
  cdPlans: [],
  oversightTrainingRequests:[],
  trainingRequest: null,
  oversightSummary:{},
  requestTimelines:[]

};
