import { Button, Divider, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
const Notifications = ({ expanded }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <div
    className="alert alert-warning  py-0 mb-0 weak-password-div  text-center text-uppercase"
    style={{
      borderTopColor: "#ff5722",
      borderBottomColor: "#ff5722",
      borderRadius: "0px",
      height: "28px",
    }}
  >
    <marquee behavior="" direction="">
      <strong style={{ color: "red" }}>In order to streamline the training approval process, please
                note the following timelines have been added and shall be ready
                for use effective 1st September 2024:{" "}
                <span className="font-weight-bold">First level:</span> 2 days,{" "}
                <span className="font-weight-bold">Second level:</span> 2 days
                and{" "}
                <span className="font-weight-bold">Head of Institution:</span> 2
                days. If the entire process from HR initiation to final submission exceeds a total of 6 days without reaching MIFOTRA, the request will be revoked by the system.</strong>
    </marquee>
  </div>
    // <section
    //   className="card elevated rounded border mt-2 bg-light mb-2"
    //   aria-labelledby="application-guidelines-header"
    // >
    //   <div
    //     style={{
    //       cursor: "pointer",
    //     }}
    //     onClick={() => {
    //       if (isExpanded) {
    //         window.localStorage.setItem("HideTrainingNotification", 1);
    //       }

    //       if (!isExpanded) {
    //         window.localStorage.removeItem("HideTrainingNotification");
    //       }

    //       setIsExpanded(!isExpanded);
    //     }}
    //     className={`card-header ${isExpanded?'rounded-top':'rounded'} bg-training-notification-heading`}
    //     id="application-guidelines"
    //     // role="button"
    //   >
    //     <span className="d-flex justify-content-between align-items-center">
    //       <Typography
    //         variant="h6"
    //         className="font-weight-light"
    //         id="application-guidelines-header"
    //       >
    //         <CircleNotificationsIcon style={{ color: "#0099e8" }} />{" "}
    //         {/* <strong>Notification:</strong>  */}
    //         <small className="truncate">
    //           In order to streamline the training approval process, please note
    //           the following timelines have been added and shall be ready for use
    //           effective 1st September 2024{" "}
    //         </small>{" "}
    //         <Button href="#text-buttons">
    //           {!isExpanded ? "View" : "Hide"} more...
    //         </Button>
    //       </Typography>
    //       <IconButton
    //         size="small"
    //         color="inherit"
    //         aria-expanded={isExpanded}
    //         aria-label={
    //           isExpanded
    //             ? "show less application guidelines"
    //             : "show more application guidelines"
    //         }
    //       >
    //         {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    //       </IconButton>
    //     </span>
    //   </div>

    //   <div className={`card-body ${!isExpanded ? "d-none" : ""}`}>
    //     {/* Registration */}
    //     In order to streamline the training approval process, please note the
    //     following timelines have been added and shall be ready for use effective
    //     1st September 2024:
    //     <ul className="mt-2">
    //       <li>
    //         <span className="font-weight-bold">First level:</span> 2 days
    //       </li>
    //       <li>
    //         <span className="font-weight-bold">Second level:</span> 2 days
    //       </li>
    //       <li>
    //         <span className="font-weight-bold">Head of Institution:</span> 2
    //         days
    //       </li>
    //     </ul>
    //     <p className="text-black font-weight-light text-left">
    //       If the entire process from HR initiation to final submission exceeds a
    //       total of 6 days without reaching MIFOTRA, the request will be revoked
    //       by the system.
    //     </p>
    //     <p>
    //       <span className="text-secondary  mt-1">
    //         <span className="text-center" style={{ fontWeight: 800 }}>
    //           Support contacts
    //         </span>
    //         <div className="mt-2" style={{ fontSize: "12px" }}>
    //           Mbabazi Comfort:{" "}
    //           <span className="text-primary" style={{ fontWeight: 800 }}>
    //             +(250) 788 444 903{" "}
    //           </span>{" "}
    //           <br />
    //           Amerika Camarade:
    //           <span className="text-primary" style={{ fontWeight: 800 }}>
    //             +(250) 788 545 669{" "}
    //           </span>
    //         </div>
    //       </span>
    //     </p>
    //   </div>
    // </section>
  );
};

export default Notifications;
