import { AppBar, Box, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import MonitorEmployees from "./MonitorEmployees";
import MonitorUnities from "./MonitorUnities";

const Monitor = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <div className="row no-gutter justify-content-center">
            <div className="col-12 col-lg-11 px-0 px-md-3">
              <AppBar
                position="static"
                elevation={0}
                className="app-bar w-100 mb-0 pb-0"
              >
                <Toolbar className="mb-0 pb-0">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="RBM Monitor Progress"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Units" {...a11yProps(0)} />
                    <Tab label="Employees" {...a11yProps(1)} />
                  </Tabs>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    <span className="lead text-uppercase">
                      RBM Monitor Progress
                    </span>
                  </Typography>
                </Toolbar>
              </AppBar>
            </div>
          </div>

          <div>
            <TabPanel value={tabValue} index={0}>
              <React.Fragment>
                <MonitorUnities />
              </React.Fragment>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <React.Fragment>
                <MonitorEmployees />
              </React.Fragment>
            </TabPanel>
          </div>
        </div>
      </Box>
    </>
  );
};

export default Monitor;
