import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateAllowance,
  addNewAllowance,
  getAdminAllowance,
  updateAllowance,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { getAllowanceTypes } from "../../../store/structure/actions";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  getBasePayrollComponents,
  getCalculationMethods,
} from "../../../store/payroll/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ToggleOffRoundedIcon from "@mui/icons-material/ToggleOffRounded";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";

const Allowance = (props) => {
  const {
    createAllowance,
    setCreateAllowance,
    // redux -----------------
    loading,
    allowances,
    getAdminAllowance,
    addNewAllowance,
    updateAllowance,
    allowanceTypes,
    getAllowanceTypes,
    calculationMethods,
    basePayrollComponents,
    getCalculationMethods,
    getBasePayrollComponents,
    activateAllowance,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [showForEmployee, setShowForEmployee] = useState(null);
  const [allowanceId, setAllowanceId] = useState(0);
  const [deleteAllowance, setDeleteAllowance] = useState(null);
  const [allowanceName, setAllowanceName] = useState("");
  const [amount, setAmount] = useState("");
  const [payTPR, setPayTPR] = useState(true);
  const [payCSR, setPayCSR] = useState(true);
  const [payMaternity, setPayMaternity] = useState(true);
  const [payMedical, setPayMedical] = useState(true);
  const [isForEmployee, setIsForEmployee] = useState(false);
  const [formError, setFormError] = useState(null);
  const [filterAllowanceType, setFilterAllowanceType] = useState(null);
  const [selectedAllowanceType, setSelectedAllowanceType] = useState(null);
  const [selectedCalculationMethod, setSelectedCalculationMethod] =
    useState(null);
  const [selectedBasePayroll, setSelectedBasePayroll] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [forcePage, setForcePage] = useState(undefined);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (allowances.length === 0) {
      getAdminAllowance(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (!allowanceTypes.length) getAllowanceTypes();
    if (!calculationMethods.length) getCalculationMethods();
    if (!basePayrollComponents.length) getBasePayrollComponents();

    return () => {
      setLoadingData(true);
      setShowForEmployee(null);
      setAllowanceId(0);
      setAllowanceName("");
      setFormError(null);
      setSearchInput("");
      setSelectedAllowanceType(null);
    };
  }, []);

  const onCloseForm = () => {
    setCreateAllowance(false);
    setAllowanceId(0);
    setAllowanceName("");
    setSelectedBasePayroll(null);
    setSelectedCalculationMethod(null);
    setSelectedAllowanceType(null);
    setPayTPR(false);
    setPayCSR(false);
    setPayMaternity(false);
    setPayMedical(false);
    setIsForEmployee(false);
    setAmount("");
  };

  const onSaveForm = () => {
    if (allowanceId === 0 && !createAllowance) {
      setFormError({
        element: "GENERAL",
        msg: "Please select allowance you want to update.",
      });
      return;
    }
    if (allowanceName.length === 0) {
      setFormError({ element: "NAME", msg: "Certificate name is required." });
      return;
    }
    if (!selectedCalculationMethod) {
      setFormError({
        element: "CALCULATION",
        msg: "Calculation method is required.",
      });
      return;
    }
    if (selectedCalculationMethod.id === 2 && !selectedBasePayroll) {
      setFormError({
        element: "BASE_PAYROLL",
        msg: "Base payroll is required.",
      });
      return;
    }
    if (!selectedAllowanceType) {
      setFormError({
        element: "ALLOWANCE_TYPE",
        msg: "Allowance type is required.",
      });
      return;
    }
    if (!+amount) {
      setFormError({ element: "AMOUNT", msg: "Amount is required." });
      return;
    }

    if (allowanceId > 0 && !createAllowance) {
      updateAllowance(
        allowanceId,
        {
          name: allowanceName,
          selectedCalculationMethod,
          selectedBasePayroll,
          selectedAllowanceType,
          payTPR,
          payCSR,
          payMaternity,
          payMedical,
          amount,
          isForEmployee,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewAllowance(
        {
          name: allowanceName,
          selectedCalculationMethod,
          selectedBasePayroll,
          selectedAllowanceType,
          payTPR,
          payCSR,
          payMaternity,
          payMedical,
          amount,
          isForEmployee,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const handleFilter = (data) => {
    let result = [...data];
    result = result.filter((item) => item.isActive === !showInactive);
    if (filterAllowanceType)
      result = result.filter(
        (item) => item.allowanceTypeId === filterAllowanceType?.id
      );
    if (showForEmployee && showForEmployee.id === 2)
      result = result.filter((item) => item.isForEmployee === true);
    if (showForEmployee && showForEmployee.id === 3)
      result = result.filter((item) => item.isForEmployee === false);

    return result;
  };

  const handleChangeStatus = () => {
    if (deleteAllowance)
      activateAllowance(
        deleteAllowance.id,
        (status) => status && setDeleteAllowance(null)
      );
  };

  const IS_EDITING = Boolean(allowanceId > 0);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0
      ? allowances
      : allowances.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
    : [];
  SEARCHED_DATA = handleFilter(SEARCHED_DATA);
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };
  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const setForUpdate = (allowanceItem) => {
    setAllowanceId(allowanceItem.id);
    setAllowanceName(allowanceItem.name);
    setSelectedBasePayroll(allowanceItem.basePayrollComponent);
    setSelectedCalculationMethod(allowanceItem.calculationMethod);
    setSelectedAllowanceType(allowanceItem.allowanceType);
    setPayTPR(allowanceItem.payTPR);
    setPayCSR(allowanceItem.payCSR);
    setPayMaternity(allowanceItem.payMaternity);
    setPayMedical(allowanceItem.payMedical);
    setIsForEmployee(allowanceItem.isForEmployee);
    setAmount(allowanceItem.amount);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-5 col-sm-5">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || allowances.length === 0}
            placeholder="Search allowance..."
          />
        </div>
        <div className="col-5 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, allowanceType) => (
              <Box component="li" {...props}>
                {allowanceType?.name}
              </Box>
            )}
            options={allowanceTypes}
            size="small"
            value={filterAllowanceType || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Allowance type"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "type",
                }}
              />
            )}
            onChange={(event, allowanceType) =>
              setFilterAllowanceType(allowanceType || null)
            }
          />
        </div>
        <div className="col-5 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, category) => (
              <Box component="li" {...props}>
                {category?.name}
              </Box>
            )}
            options={[
              { id: 1, name: "ALL" },
              { id: 2, name: "Employee" },
              { id: 3, name: "Not employee" },
            ]}
            size="small"
            value={showForEmployee || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "category",
                }}
              />
            )}
            onChange={(event, category) => setShowForEmployee(category || null)}
          />
        </div>
        <div className="col-5 col-sm-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
            }}
            variant="outlined"
            size="small"
            disableElevation
            // className="mr-2"
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Allowance</th>
            <th className="text-center">Calculation method</th>
            <th className="text-center">Base payroll</th>
            <th className="text-center">Allowance type</th>
            {/* <th className="text-center">Pay TPR</th>
            <th className="text-center">Pay CSR</th>
            <th className="text-center">Pay Maternity</th>
            <th className="text-center">Pay Medical</th> */}
            <th className="text-center">For employee</th>
            <th className="text-center">Amount</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={8} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={8}>
                <div className="text-center font-weight-bold my-2">
                  No allowance found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((allowanceItem, index) => (
              <tr key={"allowance-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>
                <td>{allowanceItem && allowanceItem.name}</td>
                <td align="center">
                  {allowanceItem.calculationMethod
                    ? allowanceItem.calculationMethod.name
                    : "-"}
                </td>
                <td align="center">
                  {allowanceItem.basePayrollComponent
                    ? allowanceItem.basePayrollComponent.name
                    : "-"}
                </td>
                <td align="center">
                  {allowanceItem.allowanceType
                    ? allowanceItem.allowanceType.name
                    : "-"}
                </td>
                {/* <td align="center">
                  {allowanceItem.payTPR ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center">
                  {allowanceItem.payCSR ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center">
                  {allowanceItem.payMaternity ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center">
                  {allowanceItem.payMedical ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td> */}
                <td align="center">
                  {allowanceItem.isForEmployee ? (
                    <CheckBoxRoundedIcon fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankRoundedIcon fontSize="small" />
                  )}
                </td>
                <td align="center" className="font-weight-bold">
                  {allowanceItem.amount}
                </td>
                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit allowance"
                      size="small"
                      onClick={() => setForUpdate(allowanceItem)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="remove allowance"
                      size="small"
                      onClick={() => {
                        setDeleteAllowance({
                          id: allowanceItem.id,
                          isActive: allowanceItem.isActive,
                        });
                      }}
                    >
                      {allowanceItem.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createAllowance || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Allowance" : "Add New Allowance"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Allowance name"
              variant="outlined"
              size="small"
              className="w-100"
              disabled={loading || loadingData}
              value={allowanceName}
              onChange={(e) => setAllowanceName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* allowance type */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, type) => (
                <Box component="li" {...props}>
                  {type?.name}
                </Box>
              )}
              options={allowanceTypes}
              size="small"
              value={selectedAllowanceType || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Allowance type"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "field",
                  }}
                />
              )}
              onChange={(event, type) => {
                setSelectedAllowanceType(type || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "ALLOWANCE_TYPE" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Calculation method */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, type) => (
                <Box component="li" {...props}>
                  {type?.name}
                </Box>
              )}
              options={calculationMethods}
              size="small"
              value={selectedCalculationMethod || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Calculation method"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "calculation",
                  }}
                />
              )}
              onChange={(event, calculation) => {
                setSelectedCalculationMethod(calculation || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "CALCULATION_METHOD" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Base payroll */}
            {selectedCalculationMethod &&
              selectedCalculationMethod.id === 2 && (
                <Autocomplete
                  disabled={loading || loadingData}
                  disablePortal
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, type) => (
                    <Box component="li" {...props}>
                      {type?.name}
                    </Box>
                  )}
                  options={basePayrollComponents}
                  size="small"
                  value={selectedBasePayroll || null}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Base payroll"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "base",
                      }}
                    />
                  )}
                  onChange={(event, base) => {
                    setSelectedBasePayroll(base || null);
                    setFormError(null);
                  }}
                  className="mt-3"
                />
              )}
            {formError && formError.element === "BASE_PAYROLL" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {false && (
              <table
                className={`table table-sm ${
                  loadingData ? "" : "table-striped"
                } fixTableHead mt-3`}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th className="text-center">Pay TPR</th>
                    <th className="text-center">Pay CSR</th>
                    <th className="text-center">Pay Maternity</th>
                    <th className="text-center">Pay Medical</th>
                    <th className="text-center">For employee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center" onClick={() => setPayTPR(!payTPR)}>
                      {payTPR ? (
                        <CheckBoxRoundedIcon
                          fontSize="small"
                          color="primary"
                          className="cursor-pointer mt-1"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="small"
                          className="cursor-pointer mt-1"
                        />
                      )}
                    </td>
                    <td align="center" onClick={() => setPayCSR(!payCSR)}>
                      {payCSR ? (
                        <CheckBoxRoundedIcon
                          fontSize="small"
                          color="primary"
                          className="cursor-pointer mt-1"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="small"
                          className="cursor-pointer mt-1"
                        />
                      )}
                    </td>
                    <td
                      align="center"
                      onClick={() => setPayMaternity(!payMaternity)}
                    >
                      {payMaternity ? (
                        <CheckBoxRoundedIcon
                          fontSize="small"
                          color="primary"
                          className="cursor-pointer mt-1"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="small"
                          className="cursor-pointer mt-1"
                        />
                      )}
                    </td>
                    <td
                      align="center"
                      onClick={() => setPayMedical(!payMedical)}
                    >
                      {payMedical ? (
                        <CheckBoxRoundedIcon
                          fontSize="small"
                          color="primary"
                          className="cursor-pointer mt-1"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="small"
                          className="cursor-pointer mt-1"
                        />
                      )}
                    </td>
                    <td
                      align="center"
                      onClick={() => setIsForEmployee(!isForEmployee)}
                    >
                      {isForEmployee ? (
                        <CheckBoxRoundedIcon
                          fontSize="small"
                          color="primary"
                          className="cursor-pointer mt-1"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="small"
                          className="cursor-pointer mt-1"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <TextField
              id="outlined-basic"
              label="Allowance amount"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              disabled={loading || loadingData}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            {formError && formError.element === "AMOUNT" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <div
              className="d-flex flex-row align-items-center w-content"
              onClick={() => setIsForEmployee(!isForEmployee)}
            >
              <span>Is for employee: </span>

              {isForEmployee ? (
                <ToggleOnRoundedIcon
                  fontSize="large"
                  color="primary"
                  className="cursor-pointer mt-1"
                />
              ) : (
                <ToggleOffRoundedIcon
                  fontSize="large"
                  className="cursor-pointer mt-1"
                />
              )}
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}

      {deleteAllowance && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !deleteAllowance.isActive ? "activate" : "de-activate"
          } this allowance?`}
          setConfirmationDialog={() => setDeleteAllowance(null)}
          onYes={handleChangeStatus}
        />
      )}
    </div>
  );
};

Allowance.propTypes = {
  createAllowance: PropTypes.bool,
  setCreateAllowance: PropTypes.func,
};

const mapStateToProps = ({
  loading,
  adminPayroll,
  allowanceTypes,
  calculationMethods,
  basePayrollComponents,
}) => {
  return {
    loading,
    allowances: adminPayroll.adminAllowances,
    allowanceTypes,
    calculationMethods,
    basePayrollComponents,
  };
};

const mapDispatchToProps = {
  getAdminAllowance,
  addNewAllowance,
  updateAllowance,
  activateAllowance,
  getAllowanceTypes,
  getCalculationMethods,
  getBasePayrollComponents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allowance);
