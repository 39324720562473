import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import moment from "moment";
import { requestStatusColor } from "../../common/components/Utils";

const ViewCaseLogs = (props) => {
  const { logs } = props;

  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            <div
              style={{
                width: "100%",
              }}
            >
              <p className="contact_p">
                <>
                  {!!logs.length && (
                    <>
                      <table style={{ marginTop: 8, marginBottom: 8 }}>
                        <tbody>
                          {logs.map((log, index) => (
                            <>
                              <div key={index} className="row">
                                <div className="col-12 py-2">
                                <Card sx={{ width: "100%" }}>
                                        <CardHeader
                                          avatar={
                                            <Avatar
                                              sx={{
                                                bgcolor: "#ccc",
                                              }}
                                            >
                                              {log.firstName
                                                .charAt(0)
                                                .toUpperCase()}
                                            </Avatar>
                                          }
                                          title={`${log.firstName}`}
                                          subheader={moment(
                                            log?.statusOn
                                          ).format("lll")}
                                          action={
                                            <span className="text-primary">
                                              {/* xx */}
                                              {/* {approval.reviewerPositionName} */}
                                            </span>
                                          }
                                        />

                                        <CardContent>
                                          <Typography
                                            style={{
                                              marginTop: "-15px",
                                            }}
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: log.comments,
                                              }}
                                            ></span>
                                          </Typography>
                                        </CardContent>
                                        <CardActions>
                                          <Chip

                                          // avatar={<TaskAltIcon />}
                                            variant="outlined"
                                            className={`small-chip py-2 px-2
                                              text-${requestStatusColor(
                                                [
                                                  {
                                                    className: "success",
                                                    statusIds: [11,13],
                                                  },
                                                  {
                                                    className: "danger",
                                                    statusIds: [4],
                                                  },
                                  
                                                  {
                                                    className: "primary",
                                                    statusIds: [3, 5, 8],
                                                  },
                                                  {
                                                    className: "warning",
                                                    statusIds: [2, 6, 9, 10,12],
                                                  },
                                  
                                                  {
                                                    className: "secondary",
                                                    statusIds: [1, 6, 7],
                                                  },
                                                ],
                                        log.statusId
                                      )}
                                              border-${requestStatusColor(
                                                [
                                                  {
                                                    className: "success",
                                                    statusIds: [11,13],
                                                  },
                                                  {
                                                    className: "danger",
                                                    statusIds: [4],
                                                  },
                                  
                                                  {
                                                    className: "primary",
                                                    statusIds: [3, 5, 8],
                                                  },
                                                  {
                                                    className: "warning",
                                                    statusIds: [2, 6, 9, 10,12],
                                                  },
                                  
                                                  {
                                                    className: "secondary",
                                                    statusIds: [1, 6, 7],
                                                  },
                                                ],
                                      log.statusId
                                    )}`}
                                            size="small"
                                            label={log.statusName}
                                          />
                                        </CardActions>
                                      </Card>
                                </div>
                              </div>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              </p>
            </div>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};

export default ViewCaseLogs;
