import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, CardContent, Toolbar, Button } from "@mui/material";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import moment from "moment";
import { getPanelOralExams } from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PanelOralExamMarking from "../components/PanelOralExamMarking";
import TableSkeleton from "../../common/components/TableSkeleton";

const PanelistOralExam = (props) => {
  const { user, loading, panelOralExams, getPanelOralExams } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [showOralExamMarking, setShowOralExamMarking] = useState(false);
  const [selectedPanelOralExam, setSelectedPanelOralExam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredPanelOralExams, setFilteredPanelOralExams] = useState([
    ...panelOralExams,
  ]);

  useEffect(
    () => {
      getPanelOralExams(setIsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...panelOralExams].filter(
      ({ positionName, unitName, venue }) =>
        positionName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        unitName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        venue.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredPanelOralExams(filtered);
    paginate(filtered);
  }, [searchTeam, panelOralExams]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredPanelOralExams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, panelOralExams, filteredPanelOralExams]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPanelOralExams.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-8">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>RECRUITMENT</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    Oral Panels({filteredPanelOralExams.length})
                  </span>
                </Typography>
              </div>

              <div className="col-lg-4">
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      InstitutionName
                    </th>
                    <th scope="col" className="text-left">
                      Test Name
                    </th>

                    <th scope="col" className="text-left">
                      Venue
                    </th>
                    <th scope="col" className="text-left">
                      Seating Date
                    </th>
                    {/* <th scope="col" className="text-center">
                      Duration
                    </th> */}

                    <th scope="col" className="text-center">
                      Start Time
                    </th>

                    <th scope="col" className="text-center">
                      Panel
                    </th>

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <TableSkeleton cols={10} />
                ) : (
                  <tbody>
                    {paginatedItems.map((panelOralExam, index) => (
                      <PanelCard
                        setShowOralExamMarking={setShowOralExamMarking}
                        setSelectedPanelOralExam={setSelectedPanelOralExam}
                        panelOralExam={panelOralExam}
                        index={index}
                        key={index}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <NoRecordMessage
                isEmpty={!filteredPanelOralExams.length && !isLoading}
                title={"No Oral Exam found"}
              />
            </CardContent>
          </div>

          <div
            className="justify-content-center mb-3"
            style={{ overflow: "hidden" }}
          >
            {!!paginatedItems.length &&
              panelOralExams.length >= itemsPerPage && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
          </div>
        </div>

        {showOralExamMarking && (
          <PanelOralExamMarking
            exam={selectedPanelOralExam}
            showOralExamMarking={showOralExamMarking}
            setShowOralExamMarking={setShowOralExamMarking}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, panelOralExams }) => {
  return { user, loading, panelOralExams };
};
export default connect(mapStateToProps, {
  getPanelOralExams,
})(PanelistOralExam);
const PanelCard = (props) => {
  const {
    index,
    setSelectedPanelOralExam,
    panelOralExam,
    setShowOralExamMarking,
  } = props;

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left">
          {" "}
          {panelOralExam.entityName}({" "}
          <strong className="text-primary text-truncate">
            {panelOralExam.unitName}
          </strong>{" "}
          )
        </td>
        <td className="text-left"> {panelOralExam.positionName}</td>
        <td className="text-left">{panelOralExam.venue}</td>
        <td className="text-left">
          {" "}
          {panelOralExam.seatingDate
            ? moment(panelOralExam.seatingDate).format("MMMM Do YYYY")
            : ""}
        </td>
        {/* <td className="text-center"> {panelOralExam.durationMin} min</td> */}
        <td className="text-center">
          {panelOralExam.startTime
            ? moment(
                panelOralExam.seatingDate.split("T")[0] +
                  " " +
                  panelOralExam.startTime
              ).format("HH:mm A")
            : ""}
        </td>
        <td className="text-center text-primary">{panelOralExam?.panelName}</td>

        <td className="text-center">
          {panelOralExam.isCanceled ? (
            <span className="px-1 py-1 badge  badge-danger">Canceled</span>
          ) : panelOralExam.wasExamMarkingPublished ? (
            <span className="px-1 py-1 badge  badge-success">
              Exam Marking Published
            </span>
          ) : panelOralExam.isExaminerPublished ? (
            <span className="px-1 py-1 badge  badge-success">Published</span>
          ) : panelOralExam.isExamCurrentStarted ? (
            <span className="px-1 py-1 badge  badge-primary">Exam Started</span>
          ) : (
            <span className="px-1 py-1 badge  badge-secondary">Scheduled</span>
          )}
        </td>
        <td className="text-center">
          {!panelOralExam.wasExamMarkingPublished &&
          panelOralExam.isExamCurrentStarted ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                setSelectedPanelOralExam(panelOralExam);
                setShowOralExamMarking(true);
              }}
            >
              Started Exam Marking
            </button>
          ) : panelOralExam.wasExamMarkingPublished ? (
            <button
              className="btn btn-success"
              onClick={() => {
                setSelectedPanelOralExam(panelOralExam);
                setShowOralExamMarking(true);
              }}
            >
              Oral Exam Published
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => {
                setSelectedPanelOralExam(panelOralExam);
                setShowOralExamMarking(true);
              }}
            >
              Oral Exam Marking
            </button>
          )}
        </td>
      </tr>
    </>
  );
};
