import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Button,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import { getRehabilitationRequests } from "../../../store/exit/actions";
import ReactPaginate from "react-paginate";
import { CardContent, Toolbar } from "@mui/material";
import RehabilitationDetailsDialog from "../components/RehabilitationDetailsDialog";
import ApproveRehabilitationDialog from "../components/ApproveRehabilitation";
import moment from "moment";
import TableSkeleton from "../../common/components/TableSkeleton";

const Rehabilitation = (props) => {
  const {
    user,
    loading,
    getRehabilitationRequests,
    rehabilitationRequests,
    rehabilitationRequest,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   const [editBlacklistForm, setEditrehabilitationRequestForm] = useState(false);


  useEffect(() => {
    getRehabilitationRequests(setIsLoading);
  }, []);

  const [filteredrehabilitationRequests, setfilteredrehabilitationRequests] =
    useState([...rehabilitationRequests]);

  useEffect(() => {
    const filtered = [...rehabilitationRequests].filter(
      ({ exitTypeName, lastName, firstName, idNumber, institutionName }) =>
        (
          (exitTypeName || "") +
          (lastName || "") +
          (firstName || "") +
          (idNumber || "") +
          (institutionName || "")
        )
          .replace(/ /g, "")
          .toLowerCase()
          .includes((searchTeam || "").replace(/ /g, "").toLowerCase())
    );

    setfilteredrehabilitationRequests(filtered);
    paginate(filtered);
  }, [searchTeam, rehabilitationRequests]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredrehabilitationRequests);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      itemOffset,
      itemsPerPage,
      rehabilitationRequests,
      filteredrehabilitationRequests,
    ]
  );
 

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredrehabilitationRequests.length ||
      0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>EXIT & TERMINATION</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>
                <span style={{ color: "rgb(7, 142, 206)" }}>
                  Rehabilitation({rehabilitationRequests.length})
                </span>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!rehabilitationRequests.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
           

            {/* <ApproveRehabilitationDialog
              approveRehabilitationForm={
                approveRehabilitationForm || rejectRehabilitationForm
              }
              setApproveRehabilitationForm={(e) => {
                setApproveRehabilitationForm(e);
                setRejectRehabilitationForm(e);
              }}
              selectedRehabilitationRequest={selectedRehabilitationRequest}
              responseStatus={approveRehabilitationForm ? 1 : 2}
              closeRequestDetails={() => {
                setselectedRehabilitationRequest(false);
                setRehabilitationDetailsForm(false);
              }}
            /> */}

            <CardContent className="pl-0 pt-0">
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">Exit type</th>
                    <th scope="col">Last name</th>
                    <th scope="col">First name</th>
                    <th scope="col" className="text-center">
                      Gender
                    </th>

                    <th scope="col">National ID</th>
                    <th scope="col">Institution</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <TableSkeleton cols={11} />
                ) : (
                  <tbody>
                    {paginatedItems.map((rehabilitationRequest, index) => (
                      <RehabilitationCard
                        key={index + 1}
                        rehabilitationRequest={rehabilitationRequest}
                        index={index}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <div
                className="justify-content-center"
                style={{ overflow: "hidden" }}
              >
                {!isLoading &&
                  !!paginatedItems.length &&
                  rehabilitationRequests.length >= itemsPerPage && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  )}
              </div>

              {!rehabilitationRequests.length && !isLoading && (
                <div className="text-center">
                  <p className="lead">No Record found</p>
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, rehabilitationRequests }) => {
  return { user, loading, rehabilitationRequests };
};
export default connect(mapStateToProps, { getRehabilitationRequests })(
  Rehabilitation
);
const RehabilitationCard = (props) => {
  const { rehabilitationRequest,index } = props;

  const [approveRehabilitationForm, setApproveRehabilitationForm] =
  useState(false);
const [rejectRehabilitationForm, setRejectRehabilitationForm] =
  useState(false);

  const [rehabilitationDetailsForm, setRehabilitationDetailsForm] =
  useState(false);
const [selectedRehabilitationRequest, setSelectedRehabilitationRequest] =
  useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{rehabilitationRequest.exitTypeName}</td>
        <td>{rehabilitationRequest.lastName}</td>
        <td>{rehabilitationRequest.firstName}</td>
        <td align="center">
          {rehabilitationRequest.genderId === "M" ? "Male" : "Female"}
        </td>
        <td>{rehabilitationRequest.idNumber}</td>
        <td>{rehabilitationRequest.institutionName}</td>
        <td>
          <IconButton
            onClick={handleClick}
            color="primary"
            aria-label="add to shopping cart"
          >
            <span className="material-icons-outlined">more_vert</span>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setSelectedRehabilitationRequest(rehabilitationRequest);
                setRehabilitationDetailsForm(true);

                handleCloseMenu();
              }}
              className="text-success font-weight-light"
            >
              Details
            </MenuItem>
          </Menu>
        </td>
      </tr>
      {rehabilitationDetailsForm && (
              <RehabilitationDetailsDialog
                rehabilitationDetailsForm={rehabilitationDetailsForm}
                setRehabilitationDetailsForm={setRehabilitationDetailsForm}
                selectedRehabilitationRequest={selectedRehabilitationRequest}
                setSelectedRehabilitationRequest={
                  setSelectedRehabilitationRequest
                }
                setApproveRehabilitationForm={setApproveRehabilitationForm}
                // setRejectRehabilitationForm={setRejectRehabilitationForm}
              />
            )}
    </>
  );
};
