import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@mui/material/LinearProgress";
import MaskedInput from "react-text-mask";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  getBlacklistReasons,
  saveBlacklist,
} from "../../../store/exit/actions";
import { getNationalIdDetail } from "../../../store/structure/actions";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const ActiveRedButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid #dc3545`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "#dc3545",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AddOnBlacklistDialog = (props) => {
  const {
    loading,
    showAddOnBlacklistForm,
    setShowAddOnBlacklistForm,
    getNationalIdDetail,
    nationalIdDetail,
    blacklistReasons,
    getBlacklistReasons,
    saveBlacklist,
  } = props;

  const onClose = () => {
    setShowAddOnBlacklistForm(false);
  };

  useEffect(() => {
    getBlacklistReasons();
  }, []);
  const doEmptyPersonInfo = () => {
    setBlacklistForm({
      idNumber: "",
      lastName: "",
      firstName: "",
      civilStatus: "",
      civilStatusId: "",
      countryBirth: "",
      countryOfBirth: "",
      gender: "",
      fatherName: "",
      motherName: "",
      birthDate: "",
      birthPlace: "",
      photo: "",
      profileImage: "",
      spouse: "",
      villageId: "",
      signature: "",
      applicationNumber: "",
      nationalityId: "",
      nin: "",
    });
  };
  const [blacklistForm, setBlacklistForm] = useState({
    reasonId: "",
    idNumber: "",
    nin: "",
    applicationNumber: "",
    lastName: "",
    firstName: "",
    genderId: "",
    civilStatusId: "",
    dateOfBirth: "",
    fatherName: "",
    motherName: "",
    nationalityId: "",
    positionName: "",
    institutionName: "",
    effectiveDate: null,
  });

  const [blacklistReason, setBlaklistReason] = useState(null);
  const onSave = () => {
    saveBlacklist(blacklistForm, setBlacklistForm, onClose);
  };
  const [errors, setErrors] = useState({
    idNumberHasError: false,
    institutionNameHasError: false,
    positionHasError: false,
    blacklistReasonHasError: false,
    effectiveDateHasError: false,
    commentHasError: false,
  });

  useEffect(() => {
    if (nationalIdDetail) {
      const date = nationalIdDetail.dateOfBirth.split("/");

      setBlacklistForm({
        idNumber: nationalIdDetail.documentNumber,
        lastName: nationalIdDetail.surnames,
        firstName: nationalIdDetail.foreName,
        civilStatus: nationalIdDetail.maritalStatus,
        civilStatusId: nationalIdDetail.civilStatus,
        countryBirth: nationalIdDetail.birthCountry,
        countryOfBirth: nationalIdDetail.countryOfBirth,
        gender: nationalIdDetail.sex,
        fatherName: nationalIdDetail.fatherNames,
        motherName: nationalIdDetail.motherNames,
        birthDate: date[2] + "-" + date[1] + "-" + date[0],
        birthPlace: nationalIdDetail.placeOfBirth,
        photo: nationalIdDetail.photo,
        profileImage: nationalIdDetail.photo,
        spouse: nationalIdDetail.spouse,
        villageId: nationalIdDetail.villageId,
        signature: nationalIdDetail.signature,
        applicationNumber: nationalIdDetail.applicationNumber,
        nationalityId: nationalIdDetail.nationality,
        nin: nationalIdDetail.nin,
        phone: nationalIdDetail.phone,
        email: nationalIdDetail.email,
        countryOfDomicile: nationalIdDetail.countryOfDomicile,
        positionName: blacklistForm.positionName,
        institutionName: blacklistForm.institutionName,
        blacklistReason: blacklistForm.blacklistReason,
        effectiveDate: blacklistForm.effectiveDate,
        comment: blacklistForm.comment,
      });
    }
  }, [nationalIdDetail]);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        //
        open={showAddOnBlacklistForm}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            + ADD EMPLOYEE ON BLACKLIST
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: "90%" }}>
            <div style={{ minHeight: "400px" }}>
              <div
                className="elevated rounded p-5 mt-3"
                style={{
                  backgroundColor: "#fafafa",
                  border: "1px solid #17a2b8",
                }}
              >
                <div className="row">
                  <div className="col-12 text-left">
                    <h6 className="mb-2">Personal Info</h6>
                  </div>

                  <div className="col-sm-5 col-xs-12 col-md-5 col-lg-6 justify-content-center">
                    <SearchIcon className="loaderIcon" />
                    <MaskedInput
                      mask={[
                        /[1-3]/,
                        " ",
                        /[1-2]/,
                        /[0|9]/,
                        /\d/,
                        /\d/,
                        " ",
                        /[7-8]/,
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                      ]}
                      className="form-control mb-3"
                      placeholder="Search ID Number"
                      guide={false}
                      id="my-input-id"
                      onBlur={(e) => {}}
                      onChange={(e) => {
                        const idLength = e.target.value.length;
                        if (idLength === 21) {
                          doEmptyPersonInfo();
                          getNationalIdDetail(e.target.value);
                        }
                      }}
                    />
                    {errors.idNumberHasError && (
                      <div className="text-danger mb-1">
                        National Identification Number is required
                      </div>
                    )}
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="lastName"
                      disabled={true}
                      autoFocus
                      label="Last Name"
                      style={{
                        backgroundColor: "#e9ecef",
                        border: "1px solid #e9ecef",
                        borderRadius: "5px",
                      }}
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.lastName || ""}
                      onChange={(e) => {
                        const lastName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, lastName });
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="FirstName"
                      disabled={true}
                      autoFocus
                      label="First Name"
                      style={{
                        backgroundColor: "#e9ecef",
                        border: "1px solid #e9ecef",
                        borderRadius: "5px",
                      }}
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.firstName || ""}
                      onChange={(e) => {
                        const firstName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, firstName });
                      }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="birthday"
                      disabled={true}
                      autoFocus
                      label="BirthDay"
                      style={{
                        backgroundColor: "#e9ecef",
                        border: "1px solid #e9ecef",
                        borderRadius: "5px",
                      }}
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.birthDate || ""}
                      onChange={(e) => {
                        const birthDate = e.target.value;
                        setBlacklistForm({ ...blacklistForm, birthDate });
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="motherName"
                      disabled={true}
                      autoFocus
                      label="Mother Name"
                      style={{
                        backgroundColor: "#e9ecef",
                        border: "1px solid #e9ecef",
                        borderRadius: "5px",
                      }}
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.motherName || ""}
                      onChange={(e) => {
                        const motherName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, motherName });
                      }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="fatherName"
                      disabled={true}
                      autoFocus
                      label="Father Name"
                      style={{
                        backgroundColor: "#e9ecef",
                        border: "1px solid #e9ecef",
                        borderRadius: "5px",
                      }}
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.fatherName || ""}
                      onChange={(e) => {
                        const fatherName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, fatherName });
                      }}
                    />
                  </div>
                  <div className="col-sm-5 col-xs-12 col-md-5 col-lg-6 justify-content-center">
                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="institutionName"
                      disabled={false}
                      autoFocus
                      label="Institution"
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.institutionName || ""}
                      onChange={(e) => {
                        const institutionName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, institutionName });
                      }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      rows={4}
                      name="positionName"
                      disabled={false}
                      autoFocus
                      label="Position"
                      variant="outlined"
                      className="mb-3"
                      value={blacklistForm.positionName || ""}
                      onChange={(e) => {
                        const positionName = e.target.value;
                        setBlacklistForm({ ...blacklistForm, positionName });
                      }}
                    />

                    {!!blacklistReasons.length && (
                      <div className="row mt-3 ">
                        <div className="col-12">
                          <Autocomplete
                            size="small"
                            id="ExitType"
                            className="mb-3"
                            defaultValue={null}
                            value={blacklistReason}
                            options={blacklistReasons}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, blacklistReason) => {
                              setBlaklistReason(blacklistReason || null);
                              const exitTypeId = blacklistReason
                                ? blacklistReason.id
                                : "";
                              setBlacklistForm({
                                ...blacklistForm,
                                exitTypeId,
                              });
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, exitType) => (
                              <Box component="li" {...props}>
                                {exitType.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="BlackList Reason"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "ExitType", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Effective Date"
                        inputFormat="MM/dd/yyyy"
                        value={blacklistForm.effectiveDate}
                        onChange={(date) => {
                          const effectiveDate = date;
                          setBlacklistForm({
                            ...blacklistForm,
                            effectiveDate,
                          });
                          const effectiveDateHasError = false;
                          setErrors({ ...errors, effectiveDateHasError });
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth size="small" {...params} />
                        )}
                      />

                      {errors.effectiveDateHasError && (
                        <div className="text-danger mt-1">
                          Effective Date is required{" "}
                        </div>
                      )}
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, nationalIdDetail, blacklistReasons }) => {
  return {
    loading,
    nationalIdDetail,
    blacklistReasons,
  };
};
export default connect(mapStateToProps, {
  getNationalIdDetail,
  getBlacklistReasons,
  saveBlacklist,
})(AddOnBlacklistDialog);
