import training from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";
import download from "downloadjs";
import commonTypes from "../common/action-types";
import { openCenteredPopup } from "../../app/common/components/Utils";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...training };

export const saveTrainingRequest = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/training/requests", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch({
        type: types.ADD_TRAINING_REQUEST,
        data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Training request submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const updateTrainingRequest = (payload, trainingRequest, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/requests/" + payload.id,
        payload
      );
      //window.localStorage.removeItem("TextEditorUpdates");
      if (trainingRequest && data) {
        trainingRequest.request = data.request;
        trainingRequest.trainingFundProviders = data.trainingFundProviders;

        dispatch({
          type: types.SET_TRAINING_REQUEST,
          data: trainingRequest,
        });
        dispatch({
          type: types.UPDATE_TRAINING_REQUEST,
          data: data.request,
        });
      }

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request updated successfully");
      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const updateTrainingAttachment = (
  payload,
  setFilteredAttachments,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/training/update-attachment",
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_TRAINING_ATTACHMENTS,
        data,
      });

      setFilteredAttachments(data);

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const reAttachTrainingRequestDocs = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/training/reattach-documents",
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.UPDATE_TRAINING_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      console.log(error);
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const saveCdPlan = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/training/cd-plans", payload);

      dispatch({
        type: types.ADD_CD_PLAN,
        data,
      });
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getCdPlans = (entityId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/training/cd-plans/" + entityId);

      dispatch({ type: types.SET_CD_PLANS, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteCdPlan = (selectedCdPlan, setConfirmRemoveCdPlan) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.delete("/api/training/cd-plans/" + selectedCdPlan.id);

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_CD_PLAN,
        data: selectedCdPlan,
      });

      setConfirmRemoveCdPlan(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTrainingRequests = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/in-progress-requests");

      dispatch({ type: types.SET_TRAINING_REQUESTS, data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRejectedTrainingRequests = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/rejected-requests");

      dispatch({ type: types.SET_REJECTED_TRAINING_REQUESTS, data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getApprovedTrainingRequests = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/approved-requests");

      dispatch({ type: types.SET_APPROVED_TRAINING_REQUESTS, data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getOversightTrainingRequests = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/training/oversight-requests" +
          "?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_OVERSIGHT_TRAINING_REQUESTS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_OVERSIGHT_TRAINING_REQUESTS });
      }
      showError(error);
    }
  };
};

export const getOversightTrainingSummary = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/oversight-summary");

      dispatch({
        type: types.SET_OVERSIGHT_TRAINING_SUMMARY,
        data,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_OVERSIGHT_TRAINING_SUMMARY });
      }
      showError(error);
    }
  };
};
export const getSingleTrainingRequest = (
  referenceNo,
  setLoading,
  setShowTrainingRequestDetails
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/training/requests/" + referenceNo);

      dispatch({ type: types.SET_TRAINING_REQUEST, data });
      setLoading(false);
      setShowTrainingRequestDetails(true);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

//request

export const deleteTrainingRequest = (
  request,
  setConfirmDeleteRequest,
  setShowTrainingRequestDetails
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      console.log(request);
      await axios.delete("/api/training/requests/" + request.id);

      dispatch({ type: types.END_LOADING });
      showSuccess("Deleted successfully");

      dispatch({
        type: types.DELETE_TRAINING_REQUEST,
        data: request,
      });

      setConfirmDeleteRequest(false);
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//sendToVerifierTrainingRequest
export const sendToVerifierTrainingRequest = (
  request,
  reason,
  setSendToVerifierRequest,
  setShowTrainingRequestDetails
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/send-to-verifier/" + request.referenceNo,
        { reason: reason }
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Request sent to verifier successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      setSendToVerifierRequest(false);
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//rejectTrainingRequest
export const rejectTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/rejection/" + params.referenceNo,
        params
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request rejected successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const verifyTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/verification/" + params.referenceNo,
        params
      );

      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(`${params.referenceNo}-letterReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-copiedInstitutionEmail`
      );
      window.localStorage.removeItem(`${params.referenceNo}-copiedInstitution`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationLetterContent`
      );
      window.localStorage.removeItem(`${params.referenceNo}-comments`);

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request verified successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const verifyMinistryTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/ministry-verification/" + params.referenceNo,
        params
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request verified successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });
      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationContentByMinistry`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationSubjectByMinistry`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-minstryLetterReferenceNo`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(`${params.referenceNo}-comments`);
      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const reviewMinistryTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/ministry-review/" + params.referenceNo,
        params
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request reviewed successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });
      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationContentByMinistry`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationSubjectByMinistry`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-minstryLetterReferenceNo`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(`${params.referenceNo}-comments`);
      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
//
export const verifyTraining2Request = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/verification2/" + params.referenceNo,
        params
      );
      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(`${params.referenceNo}-letterReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-copiedInstitutionEmail`
      );
      window.localStorage.removeItem(`${params.referenceNo}-copiedInstitution`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationLetterContent`
      );
      window.localStorage.removeItem(`${params.referenceNo}-comments`);

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request verified successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const reviewTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/review/" + params.referenceNo,
        params
      );
      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(`${params.referenceNo}-letterReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-copiedInstitutionEmail`
      );
      window.localStorage.removeItem(`${params.referenceNo}-copiedInstitution`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationLetterContent`
      );
      window.localStorage.removeItem(`${params.referenceNo}-comments`);

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request reviewed successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const approveTrainingRequest = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/" + params.level + "/approval/" + params.referenceNo,
        params
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request approved and signed successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (error.response && error.response.data === "NO_DN") {
        // showError("You are not yet connected to RISA Digital Signature. Please click on get started button to get connected");
        // setTimeout(()=>{
        openCenteredPopup(
          `${process.env.REACT_APP_FILES_URL}/risa-dn/registration/${params.userId}`
        );
        //},2000)
      } else {
        if (isSessionExpired(error)) {
          dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
        }
        showError(error);
      }
    }
  };
};

export const doSignTraining = (
  params,
  setShowTrainingRequestDetails,
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/training/" + params.level + "/approval/" + params.referenceNo,
        params
      );
      window.localStorage.removeItem(`${params.referenceNo}-permitReferenceNo`);
      window.localStorage.removeItem(`${params.referenceNo}-letterReferenceNo`);
      window.localStorage.removeItem(
        `${params.referenceNo}-copiedInstitutionEmail`
      );
      window.localStorage.removeItem(`${params.referenceNo}-copiedInstitution`);
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationPermitContent`
      );
      window.localStorage.removeItem(
        `${params.referenceNo}-draftAuthorizationLetterContent`
      );

      dispatch({ type: types.END_LOADING });
      showSuccess("Training request approved and signed successfully");

      dispatch({
        type: types.UPDATE_TRAINING_REQUEST,
        data,
      });

      onClose();
      setShowTrainingRequestDetails(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      console.log(error);
      if (error.response && error.response.data === "NO_DN") {
        // showError("You are not yet connected to RISA Digital Signature. Please click on get started button to get connected");
        // setTimeout(()=>{
        openCenteredPopup(
          `${process.env.REACT_APP_FILES_URL}/risa-dn/registration/${params.userId}`
        );
        //},2000)
      } else {
        if (isSessionExpired(error)) {
          dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
        }
        showError(error);
      }
    }
  };
};

export const downloadTrainingAuthorization = (
  referenceNo,

  lastName,
  firstName,
  trainingAuthorizationType,
  setIsDownloading
) => {
  return async (dispatch) => {
    setIsDownloading(true);
    try {
      const { data } = await axios.get(
        "/api/training/download-authorization/" +
          referenceNo +
          "/" +
          trainingAuthorizationType,
        { responseType: "blob" }
      );

      download(
        new Blob([data]),
        `Training Authorization ${trainingAuthorizationType} - ${lastName.toUpperCase()} ${firstName}(${referenceNo}).pdf`,
        ".pdf"
      );

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
      const responseObj = await error.response.data.text();
      showError(responseObj);
    }
  };
};
export const getRequestTimelines = (setLoading, requestId) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        "/api/training/request-timelines/" + requestId
      );

      dispatch({
        type: types.SET_REQUEST_TIMELINE,
        data,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUEST_TIMELINE });
      }
      showError(error);
    }
  };
};
