import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import axios from "axios";
import NoResults from "../../common/components/NoResults";

const OralExamDetailDialog = (props) => {
  const { user, showDialog, setShowDialog, application } = props;

  const [loading, setLoading] = useState(false);

  const [exam, setExam] = useState({});
  const [examPanels, setExamPanels] = useState([]);
  useEffect(() => {
    getOralExamDetail();
  }, []);

  const getOralExamDetail = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/recruitment/oral-exam-detail/${application.advertisementId}`
      );

      setExam(data.oralExam);
      setExamPanels(data.examPanels);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">Oral exam details</span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="pb-1">
          <div className="table-responsive rounded border ">
            {loading && isEmpty(exam) ? (
              <Skeleton variant="rectangular" width="100%" height={150} />
            ) : !isEmpty(exam) ? (
              <>
                <table className={`table table-sm table-striped mb-0`}>
                  <tbody>
                    {!!exam.venue && (
                      <tr>
                        <th className="text-right py-1">ExamVenue</th>
                        <td>
                          <strong>{exam.venue || "N/A"}</strong>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th className="text-right py-1">ExamDate</th>
                      <td>{moment(exam.seatingDate).format("ll")}</td>
                    </tr>
                    <tr>
                      <th className="text-right py-1">StartTime</th>
                      <td>{exam.startTime}</td>
                    </tr>
                    <tr>
                      <th className="text-right py-1">EndTime</th>
                      <td>{exam.endTime}</td>
                    </tr>
                    <tr>
                      <th className="text-right py-1">Notice</th>
                      <td>
                        <TextField
                          InputProps={{ readOnly: true }}
                          fullWidth
                          label="Notice"
                          placeholder="Notice"
                          value={exam.notice || " "}
                          size="small"
                          multiline
                          minRows={4}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!!examPanels.length && (
                  <table
                    className={`mt-2 table table-sm ${
                      !!examPanels.length ? "table-striped" : ""
                    }`}
                  >
                    <thead>
                    
                      <tr>
                        <th className="text-center" style={{ width: "14px", verticalAlign: "middle" }}>
                          #
                        </th>
                        <th>
                          {" "}
                          <span className="d-flex  flex-wrap align-items-center">
                            <span className="mr-2">
                              Panels{" "}
                              {!!examPanels.length && (
                                <span className="text-primary">
                                  ({examPanels.length})
                                </span>
                              )}
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {examPanels.map((panel, index) => (
                        <tr key={index + 1}>
                          <td className="text-left">{index + 1}</td>
                          <td className="text-left">{panel?.panel || ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            ) : (
              <NoResults />
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { })(
  OralExamDetailDialog
);
