import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { addOrUpdateUserReferee } from "../../../store/profile/actions";

const RefereeForm = (props) => {
  const {
    userProfile,
    loading,
    selectedReferee,
    addOrUpdateUserReferee,
    onShow,
    onClose,
  } = props;

  const [refereeForm, setRefereeForm] = useState({
    refereeFirstName: "",
    refereeLastName: "",
    refereePhoneNumber: "",
    refereeEmail: "",
    refereePositionName: "",
    refereeInstitutionName: "",
  });

  useEffect(() => {
    setRefereeForm({
      refereeFirstName: selectedReferee?.refereeFirstName || "",
      refereeLastName: selectedReferee?.refereeLastName || "",
      refereePhoneNumber: selectedReferee?.refereePhoneNumber || "",
      refereeEmail: selectedReferee?.refereeEmail || "",
      refereePositionName: selectedReferee?.refereePositionName || "",
      refereeInstitutionName: selectedReferee?.refereeInstitutionName || "",
    });
  }, []);

  const [errors, setErrors] = useState({
    firstNameHasError: false,
    lastNameHasError: false,
    phoneNumberHasError: false,
    emailHasError: false,
    positionNameHasError: false,
    institutionNameHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      firstNameHasError: false,
      lastNameHasError: false,
      phoneNumberHasError: false,
      emailHasError: false,
      positionNameHasError: false,
      institutionNameHasError: false,
      hasError: false,
    };

    if (!refereeForm.refereeFirstName) {
      error.firstNameHasError = true;
      error.hasError = true;
    }

    if (!refereeForm.refereeLastName) {
      error.lastNameHasError = true;
      error.hasError = true;
    }

    if (!refereeForm.refereePhoneNumber) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (!refereeForm.refereeEmail) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!refereeForm.refereePositionName) {
      error.positionNameHasError = true;
      error.hasError = true;
    }

    if (!refereeForm.refereeInstitutionName) {
      error.institutionNameHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!formValidator())
      addOrUpdateUserReferee(
        {
          id: selectedReferee?.id || "",
          userId:userProfile.id,
          ...refereeForm,
        },
        onClose
      );
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={onShow}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {!selectedReferee ? "Add " : "Update "} referee
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="px-md-4 mt-2">
          <form>
            <div className="row mt-3">
              <div className="form-group col-12 col-md-6">
                <TextField
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  label="Referee first name"
                  placeholder="Referee first name"
                  id="referee-first-name"
                  value={refereeForm.refereeFirstName}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereeFirstName: e.target.value,
                    });
                    setErrors({ ...errors, firstNameHasError: false });
                  }}
                  error={errors.firstNameHasError}
                />
                {errors.firstNameHasError && (
                  <small className="text-danger">First name is required</small>
                )}
              </div>

              <div className="form-group col-12 col-md-6">
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Referee last name"
                  placeholder="Referee last name"
                  id="referee-last-name"
                  value={refereeForm.refereeLastName}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereeLastName: e.target.value,
                    });
                    setErrors({ ...errors, lastNameHasError: false });
                  }}
                  error={errors.lastNameHasError}
                />
                {errors.lastNameHasError && (
                  <small className="text-danger">Last name is required</small>
                )}
              </div>

              <div className="form-group col-12 col-md-6">
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Referee phone number"
                  placeholder="Referee phone number"
                  id="referee-phone"
                  value={refereeForm.refereePhoneNumber}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereePhoneNumber: e.target.value,
                    });
                    setErrors({ ...errors, phoneNumberHasError: false });
                  }}
                  error={errors.phoneNumberHasError}
                />
                {errors.phoneNumberHasError && (
                  <small className="text-danger">
                    Phone number is required
                  </small>
                )}
              </div>

              <div className="form-group col-12 col-md-6">
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Referee email"
                  placeholder="Referee email"
                  id="referee-email"
                  value={refereeForm.refereeEmail}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereeEmail: e.target.value,
                    });
                    setErrors({ ...errors, emailHasError: false });
                  }}
                  error={errors.emailHasError}
                />
                {errors.emailHasError && (
                  <small className="text-danger">Email is required</small>
                )}
              </div>

              <div className="form-group col-12">
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Referee position"
                  placeholder="Referee position"
                  id="referee-position"
                  value={refereeForm.refereePositionName}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereePositionName: e.target.value,
                    });
                    setErrors({ ...errors, positionNameHasError: false });
                  }}
                  error={errors.positionNameHasError}
                />
                {errors.positionNameHasError && (
                  <small className="text-danger">Position is required</small>
                )}
              </div>

              <div className="form-group col-12">
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Referee institution"
                  placeholder="Referee institution"
                  id="referee-institution"
                  value={refereeForm.refereeInstitutionName}
                  size="small"
                  onChange={(e) => {
                    setRefereeForm({
                      ...refereeForm,
                      refereeInstitutionName: e.target.value,
                    });
                    setErrors({ ...errors, institutionNameHasError: false });
                  }}
                  error={errors.institutionNameHasError}
                />
                {errors.institutionNameHasError && (
                  <small className="text-danger">Institution is required</small>
                )}
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
        <Button
          variant="contained"
          type="button"
          className={`px-5`}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Wait..." : !!selectedReferee ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ userProfile, loading }) => ({
  userProfile,
  loading,
});

export default connect(mapStateToProps, { addOrUpdateUserReferee })(
  RefereeForm
);
