import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { connect } from "react-redux";
import {
  getBlacklists,
  onRemoveEmployeeOnBlacklist,
} from "../../../store/exit/actions";
import ReactPaginate from "react-paginate";
import { CardContent, Toolbar } from "@mui/material";
import BlaclistDetailsDialog from "../components/BlacklistDetailsDialog";
import EditBlacklistDialog from "../components/EditBlacklistDialog";
import AddOnBlacklistDialog from "../components/AddOnBlacklistDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import TableSkeleton from "../../common/components/TableSkeleton";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const BlackList = (props) => {
  const {
    user,
    loading,
    getBlacklists,
    blacklists,
    onRemoveEmployeeOnBlacklist,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [blacklistDetailsForm, setBlacklistDetailsForm] = useState(false);
  const [selectedBlacklist, setSelectedBlacklist] = useState(false);
  const [editBlacklistForm, setEditblacklistForm] = useState(false);
  const [showAddOnBlacklistForm, setShowAddOnBlacklistForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasPermissions } = useRequiredAuth();

  useEffect(() => {
    getBlacklists(setIsLoading);
  }, []);

  const [filteredBlacklists, setfilteredBlacklists] = useState([...blacklists]);

  useEffect(() => {
    const filtered = [...blacklists].filter(
      ({ exitTypeName, lastName, firstName, idNumber, institutionName }) =>
        (
          (exitTypeName || "") +
          (lastName || "") +
          (firstName || "") +
          (idNumber || "") +
          (institutionName || "")
        )
          .replace(/ /g, "")
          .toLowerCase()
          .includes((searchTeam || "").replace(/ /g, "").toLowerCase())
    );
    setfilteredBlacklists(filtered);
    paginate(filtered);
  }, [searchTeam, blacklists]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredBlacklists);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, blacklists, filteredBlacklists]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredBlacklists.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>EXIT & TERMINATION</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>
                <span style={{ color: "rgb(7, 142, 206)" }}>BlackLists({blacklists.length})</span>
                <span style={{ color: "rgb(7, 142, 206)" }}>
                  {hasPermissions(["IS_BLACKLIST_MANAGER"]) && (
                    <span>
                      <button
                        onClick={() => {
                          setShowAddOnBlacklistForm(true);
                        }}
                        type="button"
                        className="btn btn-primary btn-sm ml-2"
                      >
                        + ADD NEW
                      </button>
                    </span>
                  )}
                </span>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!blacklists.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <BlaclistDetailsDialog
              blacklistDetailsForm={blacklistDetailsForm}
              setBlacklistDetailsForm={setBlacklistDetailsForm}
              selectedBlacklist={selectedBlacklist}
              setSelectedBlacklist={setSelectedBlacklist}
            />

            <EditBlacklistDialog
              selectedBlacklist={selectedBlacklist}
              setSelectedBlacklist={setSelectedBlacklist}
              setEditblacklistForm={setEditblacklistForm}
              editBlacklistForm={editBlacklistForm}
            />
            <AddOnBlacklistDialog
              showAddOnBlacklistForm={showAddOnBlacklistForm}
              setShowAddOnBlacklistForm={setShowAddOnBlacklistForm}
            />
            <CardContent className="pl-0 pt-0">
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">Exit type</th>
                    <th scope="col">Last name</th>
                    <th scope="col">First name</th>
                    <th scope="col" className="text-center">
                      Gender
                    </th>

                    <th scope="col">National ID</th>
                    <th scope="col">Institution</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <TableSkeleton cols={8} />
                ) : (
                  <tbody>
                    {paginatedItems.map((blacklist, index) => (
                      <BlacklistItem
                        key={index}
                        blacklist={blacklist}
                        index={index}
                        setSelectedBlacklist={setSelectedBlacklist}
                        setBlacklistDetailsForm={setBlacklistDetailsForm}
                        editBlacklistForm={editBlacklistForm}
                        setEditblacklistForm={setEditblacklistForm}
                        onRemoveEmployeeOnBlacklist={
                          onRemoveEmployeeOnBlacklist
                        }
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <div
                className="justify-content-center"
                style={{ overflow: "hidden" }}
              >
                {!isLoading &&
                  !!paginatedItems.length &&
                  blacklists.length >= itemsPerPage && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  )}
              </div>

              {!filteredBlacklists.length && !isLoading && (
                <div className="text-center">
                  <p className="lead">No Record on Blacklist found</p>
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, blacklists }) => {
  return { user, loading, blacklists };
};
export default connect(mapStateToProps, {
  getBlacklists,
  onRemoveEmployeeOnBlacklist,
})(BlackList);

const BlacklistItem = (props) => {
  const {
    index,
    blacklist,
    setSelectedBlacklist,
    setBlacklistDetailsForm,
    setEditblacklistForm,
    onRemoveEmployeeOnBlacklist,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showRemoveToBlacklistDialog, setShowRemoveToBlacklistDialog] =
    useState(false);
  const [reason, setReason] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <tr>
      <td>{index + 1}</td>

      <td>{blacklist.exitTypeName}</td>
      <td>{blacklist.lastName}</td>
      <td>{blacklist.firstName}</td>
      <td align="center">{blacklist.genderId === "M" ? "Male" : "Female"}</td>
      <td>{blacklist.idNumber}</td>
      <td>{blacklist.institutionName}</td>

      <td>
        <IconButton
          onClick={handleClick}
          color="primary"
          aria-label="add to shopping cart"
        >
          <span className="material-icons-outlined">more_vert</span>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setSelectedBlacklist(blacklist);
              setBlacklistDetailsForm(true);

              handleCloseMenu();
            }}
            className="text-success font-weight-light"
          >
            Details
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowRemoveToBlacklistDialog(true);
              handleCloseMenu();
            }}
            className="text-danger font-weight-light"
          >
            Remove
          </MenuItem>
        </Menu>
      </td>

      {showRemoveToBlacklistDialog && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={setShowRemoveToBlacklistDialog}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">remove</strong> @
                {blacklist.firstName} {blacklist.lastName} on blacklist?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRemoveToBlacklistDialog}
          onYes={() => {
            onRemoveEmployeeOnBlacklist(
              { backlistId: blacklist.blacklistId, rehabilitatedReason:reason },
              blacklist,
              setShowRemoveToBlacklistDialog
            );
          }}
        />
      )}
    </tr>
  );
};
