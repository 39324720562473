import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { deletePositionCertificate } from "../../../store/structure/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ListSkeleton from "../../common/components/ListSkeleton";

const PositionCertificates = (props) => {
  const { loading, onWait, positionCertificates, deletePositionCertificate } =
    props;

  // useEffect(() => {
  //   getpositionCertificates(position.id);
  // }, [position]);

  //PAGINATION
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [confirmRemoveCertificate, setConfirmRemoveCertificate] =
    useState(false);

  const { hasPermissions } = useRequiredAuth();
  const getNo = (idToSearch) =>
    positionCertificates.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {confirmRemoveCertificate && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveCertificate}
          isLoading={onWait}
          message={`Are you sure you want to remove this Certificate(${selectedCertificate.certificateName})?`}
          setConfirmationDialog={setConfirmRemoveCertificate}
          onYes={() => {
            deletePositionCertificate(
              selectedCertificate,
              setConfirmRemoveCertificate,
              setSelectedCertificate
            );
          }}
        />
      )}
      {(!loading || onWait) && !!positionCertificates.length && (
        <div className="card service-card valid mb-2 mt-2">
          <div
            className="card-header float-left text-left"
            style={{ padding: "0.25rem 1.2rem" }}
          >
            Certificates
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                <List
                  className="mt-2"
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid #c8c6c6`,
                    backgroundColor: "#f9f9f9",
                  }}
                  dense
                >
                  {positionCertificates.map((certificate, index) => (
                    <React.Fragment key={certificate.id}>
                      <ListItem
                        dense
                        secondaryAction={
                          <>
                            {!!certificate.positionId &&
                              hasPermissions([
                                "IS_HR",
                                "IS_STRUCTURE_OVERSIGHT",
                                "IS_TEST_USER",
                              ]) && (
                                <span>
                                  <IconButton
                                    style={{ float: "right" }}
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      setSelectedCertificate(certificate);
                                      setConfirmRemoveCertificate(true);
                                    }}
                                    aria-label="settings"
                                  >
                                    <span className="material-icons">
                                      delete_outline
                                    </span>
                                  </IconButton>
                                </span>
                              )}
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              fontSize: "16px",
                              border: "1px solid #c8c6c6",
                            }}
                            className="bg-white text-dark "
                          >
                            {getNo(certificate.id) + 1}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <span className={`${"font-weight-light"}`}>
                              {certificate.certificateName}
                            </span>
                          }
                          secondary={
                            <>
                              <span
                                className={`${"font-weight-light badge badge-primary"}`}
                              >
                                {certificate.certificateFieldName}
                              </span>
                            </>
                          }
                        />
                      </ListItem>

                      {positionCertificates.length > index + 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <ListSkeleton />}
      {(!loading || onWait) && !positionCertificates.length && (
        <div
          className="jumbotron jumbotron-fluid text-center mt-2"
          style={{ width: "100%" }}
        >
          <div className="container">
            <p className="lead">No certificates found</p>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  positionCertificates,
  position,
}) => {
  return {
    loading,
    onWait,
    positionCertificates,
    position,
  };
};
export default connect(mapStateToProps, {
  deletePositionCertificate,
})(PositionCertificates);
