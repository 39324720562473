import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  addExamCenter,
  getCentersNotAddedOnExam,
} from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import AddIcon from "@mui/icons-material/Add";
import NoRecordMessage from "../../common/components/NoRecordMessage";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};


const AddExamCenter = (props) => {
  const {
    setShowExamCenter,
    selectedExam,
    examCenterNoneSelected,
    addExamCenter,
    getCentersNotAddedOnExam,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [filteredExamCenterNoneSelected, setFilteredExamCenterNoneSelected] = useState([
    ...examCenterNoneSelected,
  ]);
  const [examCenterId, setExamCenterId] = useState("0");
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  useEffect(
    () => {
      getCentersNotAddedOnExam(selectedExam.id,setIsSearching);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedExam]
  );

  useEffect(() => {
    const filtered = [...examCenterNoneSelected].filter(
      ({ name }) =>
        name.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredExamCenterNoneSelected(filtered);
    
  }, [searchTeam, examCenterNoneSelected]);

  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <SearchBox
          disabled={isSearching || !examCenterNoneSelected.length}
          onSearch={onSearch}
          placeholder="Find a center…"
        />
       

        <Button
          className="ml-2"
          disabled={isSearching }
          onClick={() => {
            setSearchTeam("");
            setShowExamCenter(false);
          }}
          style={{
            ...InactiveButton,
            // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
          }}
          size="md"
        >
          <TrendingFlatIcon />
        </Button>
      </div>

      <PreLoader className="mt-3" isLoading={isSearching} />

      <NoRecordMessage
        isEmpty={!filteredExamCenterNoneSelected.length && !isSearching}
        title={"No available centers to add on exam!"}
      />

      {!isSearching && !!filteredExamCenterNoneSelected.length && (
        <div className="mt-3">
          <table className="table table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  Center({examCenterNoneSelected.length})
                </th>
                <th scope="col" className="text-right">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredExamCenterNoneSelected.map((examCenter, index) => (
                <tr key={index}>
                  <td className="text-left">
                    <span style={{ fontSize: "10px" }}>{examCenter.name}</span>
                  </td>

                  <td className="text-right">
                    {" "}
                    <button
                      disabled={examCenterId === examCenter.id}
                      onClick={() => {
                        addExamCenter(
                          {
                            centerId: examCenter.id,
                            examId: selectedExam.id,
                          },
                          setExamCenterId,
                          examCenter
                        );
                      }}
                      className="btn btn-primary"
                    >
                      {examCenterId === examCenter.id  ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : (
                        <AddIcon />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, examCenterNoneSelected }) => {
  return {
    loading,
    examCenterNoneSelected,
  };
};
export default connect(mapStateToProps, {
  addExamCenter,
  getCentersNotAddedOnExam,
})(AddExamCenter);

// export default AddExamCenter;
