import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getBlacklistReasons,
  updateBlacklist,
} from "../../../store/exit/actions";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const EditBlacklistDialog = (props) => {
  const {
    loading,
    editBlacklistForm,
    setEditblacklistForm,
    selectedBlacklist,
    blacklistReasons,
    updateBlacklist,
  } = props;

  const onClose = () => {
    setEditblacklistForm(false);
  };
  useEffect(() => {
    getBlacklistReasons();
  }, []);
  const [updateBlacklistForm, setUpdateBlacklistForm] = useState({
    blacklistId: "",
    institutionName: "",
    positionName: "",
    exitTypeId: "",
    effectiveDate: null,
  });
  const [errors, setErrors] = useState({
    idNumberHasError: false,
    institutionNameHasError: false,
    positionHasError: false,
    blacklistReasonHasError: false,
    effectiveDateHasError: false,
    commentHasError: false,
  });
  useEffect(() => {
    if (selectedBlacklist) {
      setUpdateBlacklistForm({
        blacklistId: selectedBlacklist.blacklistId,
        institutionName: updateBlacklistForm.institutionName,
        positionName: updateBlacklistForm.positionName,
        exitTypeId: updateBlacklistForm.exitTypeId,
        effectiveDate: updateBlacklistForm.effectiveDate,
      });
    }
  }, [selectedBlacklist]);

  const [blacklistReason, setBlaklistReason] = useState(null);
  const onSave = () => {
    updateBlacklist(updateBlacklistForm, setUpdateBlacklistForm, onClose);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        //
        open={editBlacklistForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Edit Blaklist Of @ {selectedBlacklist && selectedBlacklist.lastName}{" "}
            {selectedBlacklist && selectedBlacklist.firstName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: "90%" }}>
            <div
              className="elevated rounded p-5 mt-3"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <TextField
                fullWidth
                size="small"
                rows={4}
                name="institutionName"
                hidden={true}
                autoFocus
                label="balacklistId"
                variant="outlined"
                className="mb-3"
                value={selectedBlacklist.blacklistId}
                onChange={(e) => {
                  const blacklistId = e.target.value;
                  setUpdateBlacklistForm({
                    ...updateBlacklistForm,
                    blacklistId,
                  });
                }}
              />
              <TextField
                fullWidth
                size="small"
                rows={4}
                name="institutionName"
                autoFocus
                label="Institution"
                variant="outlined"
                className="mb-3"
                // value={selectedBlacklist.institutionName}
                onChange={(e) => {
                  const institutionName = e.target.value;
                  setUpdateBlacklistForm({
                    ...updateBlacklistForm,
                    institutionName,
                  });
                }}
              />

              <TextField
                fullWidth
                size="small"
                rows={4}
                name="positionName"
                autoFocus
                label="Position"
                variant="outlined"
                className="mb-3"
                // value={blacklistForm.positionName || ""}
                onChange={(e) => {
                  const positionName = e.target.value;
                  setUpdateBlacklistForm({
                    ...updateBlacklistForm,
                    positionName,
                  });
                }}
              />

              {!!blacklistReasons.length && (
                <div className="row mt-3 ">
                  <div className="col-12">
                    <Autocomplete
                      size="small"
                      id="ExitType"
                      className="mb-3"
                      defaultValue={null}
                      value={blacklistReason}
                      options={blacklistReasons}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, blacklistReason) => {
                        setUpdateBlacklistForm(blacklistReason || null);
                        const exitTypeId = blacklistReason
                          ? blacklistReason.id
                          : "";
                        setUpdateBlacklistForm({
                          ...updateBlacklistForm,
                          exitTypeId,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, exitType) => (
                        <Box component="li" {...props}>
                          {exitType.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="BlackList Reason"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "ExitType", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Effective Date"
                  inputFormat="MM/dd/yyyy"
                  value={updateBlacklistForm.effectiveDate}
                  onChange={(date) => {
                    const effectiveDate = date;
                    setUpdateBlacklistForm({
                      ...updateBlacklistForm,
                      effectiveDate,
                    });
                    const effectiveDateHasError = false;
                    setErrors({ ...errors, effectiveDateHasError });
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth size="small" {...params} />
                  )}
                />

                {errors.effectiveDateHasError && (
                  <div className="text-danger mt-1">
                    Effective Date is required{" "}
                  </div>
                )}
              </LocalizationProvider>
            </div>
          </Box>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, blacklistReasons }) => {
  return {
    loading,
    blacklistReasons,
  };
};
export default connect(mapStateToProps, {
  getBlacklistReasons,
  updateBlacklist,
})(EditBlacklistDialog);
