import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { selectEntitySector } from "../../../store/common/actions";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ITEM_HEIGHT = 100;

const EntitySwitcher = (props) => {
  const { selectEntitySector, user } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {user.entitySectors.length > 1 && (
        // <Box className="mr-2">
        //   <Tooltip
        //     title={
        //       <>
        //         {user.selectedEntitySector.entityName}-(
        //         {user.selectedEntitySector.sectorName})
        //       </>
        //     }
        //     placement="top"
        //   >
        //     <Button
        //       variant="text"
        //       disableElevation
        //       onClick={handleOpenMenu}
        //       sx={{ textTransform: "capitalize" }}
        //       endIcon={
        //         <>
        //           <KeyboardArrowDownIcon className="text-light d-inline d-md-none" />

        //           <KeyboardArrowDownIcon className="text-dark d-none  d-md-inline" />
        //         </>
        //       }
        //     >
        //       {user.selectedEntitySector.entityName && (
        //         <>
        //           <span className="text-truncate text-light d-inline d-md-none text-uppercase">
        //             {user.selectedEntitySector.entityName}-(
        //             {user.selectedEntitySector.sectorName})
        //           </span>
        //           <span className="text-truncate text-dark d-none  d-md-inline text-uppercase">
        //             {user.selectedEntitySector.entityName}-(
        //             {user.selectedEntitySector.sectorName})
        //           </span>
        //         </>
        //       )}
        //     </Button>
        //   </Tooltip>

        //   <Menu
        //     id="basic-menu"
        //     anchorEl={anchorEl}
        //     open={open}
        //     onClose={handleCloseMenu}
        //     PaperProps={{
        //       elevation: 0,
        //       sx: {
        //         overflow: "auto",
        //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //         maxHeight: ITEM_HEIGHT * 4.5,
        //         mt: 1.5,
        //         "& .MuiAvatar-root": {
        //           width: 32,
        //           height: 32,
        //           ml: -0.5,
        //           mr: 1,
        //         },
        //         "&:before": {
        //           content: '""',
        //           display: "block",
        //           position: "absolute",
        //           top: 0,
        //           right: 14,
        //           width: 10,
        //           height: 10,
        //           bgcolor: "background.paper",
        //           transform: "translateY(-50%) rotate(45deg)",
        //           zIndex: 0,
        //         },
        //       },
        //     }}
        //     transformOrigin={{ horizontal: "right", vertical: "top" }}
        //     anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        //   >
        //     {user.entitySectors.map((entitySector) => (
        //       <MenuItem
        //         key={entitySector.id}
        //         onClick={() => {
        //           selectEntitySector(entitySector.id, history);
        //           handleCloseMenu();
        //         }}
        //       >
        //         <Tooltip
        //           className="text-uppercase"
        //           title={
        //             <>
        //               {entitySector.entityName}-(
        //               {entitySector.sectorName})
        //             </>
        //           }
        //           placement="top"
        //         >
        //           <span className="text-truncate">
        //             {entitySector.entityName}-(
        //             {entitySector.sectorName})
        //           </span>
        //         </Tooltip>
        //       </MenuItem>
        //     ))}
        //   </Menu>
        // </Box>
        <Box className="mr-2">
          <Autocomplete
            fullWidth
            style={{
              width: "250px",
            }}
            size="small"
            id="selectedEntitySector"
            defaultValue={user?.selectedEntitySector || null}
            value={user?.selectedEntitySector || null}
            options={user.entitySectors}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, entitySector) => {
              selectEntitySector(entitySector.id, history);
            }}
            getOptionLabel={(option) => option.entityName}
            renderOption={(props, entitySector) => (
              <Box component="li" {...props}>
                {entitySector.entityName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={user?.selectedEntitySector?.sectorName || ""}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "selectedEntitySector", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      )}

      {user.entitySectors.length === 1 && (
        <Box className="mr-2">
          <Tooltip
            title={
              <>
                {user.selectedEntitySector.entityName}-(
                {user.selectedEntitySector.sectorName})
              </>
            }
            placement="top"
          >
            <span>
              <Button
                variant="text"
                disabled
                disableElevation
                sx={{ textTransform: "capitalize" }}
              >
                {user.selectedEntitySector.entityName && (
                  <>
                    <span className="text-truncate text-uppercase text-light d-inline d-md-none badge badge-secondary p-2">
                      {user.selectedEntitySector.entityName}-(
                      {user.selectedEntitySector.sectorName})
                    </span>
                    <span className="text-truncate  text-uppercase  d-none  d-md-inline badge badge-secondary p-2">
                      {user.selectedEntitySector.entityName}-(
                      {user.selectedEntitySector.sectorName})
                    </span>
                  </>
                )}
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, { selectEntitySector })(EntitySwitcher);
