import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";
import { Input, TextField } from "@mui/material";
const LeaveOfAbsenceForNonSpecificPeriod = (props) => {
  const {
    recipientName,
    effectiveDate,
    recipientPositionName = "",
    institutionName = "",
    requestedOn = "",
    effectiveDateInput = "",
    enableToFillLetter = false,
    docReferenceIdInput = "",
    docReferenceDateInput = "",
    referenceLetterDate = "",
    referenceLetterId = "",
    competentAuthorityName = "",
    competentAuthorityPosition = "",
    ccSelectForm = "",
    docLetterNoInput = "",
    docLetterNo = "",
    signContent = "",
    ccData = [],
    recipientPhone = "",
    recipientEmail = "",
    subTitle = "Dear Sir/Madam",
  } = props;

  return (
    <>
      <div className="letterMainContent">
        <div className="letter">
          <div className="letterhead d-flex flex-column justify-content-center w-100">
            <div
              className="letterhead-title w-100"
              style={{ fontWeight: "bold" }}
            >
              REPUBLIC OF RWANDA
            </div>
            <div className="w-100">
              <img
                style={{ width: "100px", height: "100px" }}
                src={rwandaLogo}
                alt="Logo"
              />
            </div>
            <div
              className="letterhead-subtitle w-100"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              {institutionName.toUpperCase()}
            </div>
          </div>
          <div className="subletterhead">
            <table>
              <tr>
                <td style={{ width: "70%" }}></td>
                <td style={{ width: "30%" }}>
                  <p
                    className="date"
                    style={{
                      fontWeight: "bold",
                      float: "left",
                      textAlign: "left",
                    }}
                  >
                    Kigali, on {moment(new Date()).format("LL")}
                    <br />N<sup>o</sup>:
                    {enableToFillLetter ? (
                      docLetterNoInput
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {docLetterNo}
                        </span>
                      </>
                    )}{" "}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="recipient">
            {/* <!-- Recipient's name and address go here --> */}

            <p
              style={{
                fontWeight: "bold",
                fontSize: "13.6px",
                lineHeight: "1.5",
              }}
            >
               {recipientName} <br />
              Tel: {recipientPhone} <br />
              Email: {recipientEmail} <br />
              C/O: {institutionName.toUpperCase()}”
            </p>
          </div>
          <div className="content2">
            <p>{subTitle},</p>

            <p style={{ fontWeight: "bold" }}>
              Re: Response to your request for a leave of absence for a
              non-specific period
            </p>

            <p>
              Reference is made to your request dated on{" "}
              <span style={{ fontWeight: "bold" }}>
                {requestedOn
                  ? moment(requestedOn).format("LL")
                  : moment(new Date()).format("LL")}{" "}
              </span>{" "}
              addressed to the Ministry of Public Service and Labour (MIFOTRA),
              requesting for a leave of absence for a non-specific period from
              the job position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recipientPositionName}{" "}
              </span>{" "}
              in the{" "}
              <span style={{ fontWeight: "bold" }}>
                {institutionName.toUpperCase()}{" "}
              </span>
              ;
            </p>
            <p>
              “Reference to the letter no{" "}
              {enableToFillLetter ? (
                docReferenceIdInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterId}
                  </span>
                </>
              )}{" "}
              of{" "}
              {enableToFillLetter ? (
                docReferenceDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {referenceLetterDate}
                  </span>
                </>
              )}{" "}
              which appointed you to the position of{" "}
              <span style={{ fontWeight: "bold" }}>
                {recipientPositionName}{" "}
              </span>{" "}
              in{" "}
              <span style={{ fontWeight: "bold" }}>
                {institutionName.toUpperCase()}{" "}
              </span>
              ;
            </p>
            <p>
              Pursuant to Law n° 017/2020 of 07/10/2020 Law establishing the
              general statute governing public servants, especially in articles
              74 and 75;
            </p>
            <p>
              I would like to inform you that your request for a leave of
              absence for a non-specific period from the above-mentioned
              position is granted effective from{" "}
              {enableToFillLetter ? (
                effectiveDateInput
              ) : (
                <>
                  <span style={{ fontWeight: "bold" }}>{effectiveDate}</span>
                </>
              )}{" "}
            </p>
            <p>Sincerely,</p>
          </div>
          {signContent}
          <div className="signature2">
            {/* <!-- Signature goes here --> */}
            <p style={{ fontWeight: "bold" }}>
              {competentAuthorityName}
              <br />{" "}
              <span style={{ position: "relative", top: "3px" }}>
                {competentAuthorityPosition.toUpperCase()}
              </span>
            </p>
          </div>
          <div className="cc">
            {/* <!-- CC recipients go here --> */}
            <p style={{ fontWeight: "bold" }}>Cc:</p>
            <ul className="cc-list">
              {!!ccData.length && (
                <>
                  {ccData.map((cc, index) => (
                    <li className="cc-item" key={index}>
                      - {cc.name.toUpperCase()}
                    </li>
                  ))}
                </>
              )}
              {!ccData.length && (
                <>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                  <li className="cc-item">- ................</li>
                </>
              )}
              <li className="cc-item">
                {enableToFillLetter && (
                  <>
                    <br /> {ccSelectForm}
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveOfAbsenceForNonSpecificPeriod;
