import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  Tooltip,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";

import {
  savePositionAllowance,
  getPositionAllowances,
  deletePositionAllowance,
} from "../../../store/structure/actions";
import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { Fragment } from "react";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const PositionAllowances = (props) => {
  const {
    user,
    loading,
    onWait,
    selectedPosition,
    // getAllowances,
    savePositionAllowance,
    getPositionAllowances,
    deletePositionAllowance,
    positionAllowances,
    allowances,
    isEntitySectorLocked,
    showPositionAllowancesModal,
    setShowPositionAllowancesModal,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
  } = props;

  const [allowanceType, setAllowanceType] = useState(null);
  const [allowance, setAllowance] = useState(null);
  const [selectedPositionAllowance, setSelectedPositionAllowance] =
    useState(null);
  const [confirmRemovePositionAllowance, setConfirmRemovePositionAllowance] =
    useState(false);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    positionId: selectedPosition.id,
    allowanceId: "",
  });

  const [add, setAdd] = useState(false);

  const [errors, setErrors] = useState({
    allowanceHasError: false,
  });

  useEffect(
    () => {
      // getAllowances();
      // getPositionAllowances(selectedPosition.id);

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPosition]
  );

  useEffect(
    () => {
      const add = positionAllowances.length > 0 ? false : true;

      setAdd(add);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [positionAllowances]
  );

  const formValidator = () => {
    const error = {
      allowanceHasError: false,
      hasError: false,
    };

    if (!formData.allowanceId) {
      error.allowanceHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      savePositionAllowance(formData, setFormData, setAdd, setAllowance);
    }
  };

  useEffect(() => {
    if (!!allowance) {
      setAllowanceType(
        allowanceTypes.find(({ id }) => id === allowance.allowanceTypeId)
      );

      setCalculationMethod(
        calculationMethods.find(
          ({ id }) => id === allowance.calculationMethodId
        )
      );

      if (
        allowance.calculationMethodId === 2 &&
        !!allowance.basePayrollComponentName
      )
        setBasePayrollComponent(
          basePayrollComponents.find(
            ({ id }) => id === allowance.basePayrollComponentId
          )
        );
    } else {
      setAllowanceType(null);
      setCalculationMethod(null);
      setBasePayrollComponent(null);
    }
  }, [allowance]);

  const getPositionLevelAllowances = () =>
    allowances.filter(({ isForEmployee }) => isForEmployee === false);

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  return (
    <>
      <div style={{ minHeight: "45vh" }}>
        {!add && (
          <Button
            disabled={!!user?.selectedEntitySector?.isLocked}
            className="mb-2"
            variant="contained"
            color="primary"
            onClick={() => {
              setAdd(true);
            }}
            style={{
              ...ActiveButton,
              padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
              float: "right",
            }}
            size="sm"
          >
            <span className="material-icons">add</span>
          </Button>
        )}

        {loading && !onWait && (
          <div className="container mt-2" style={{ width: "100%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </div>
        )}

        {(!loading || onWait) && (
          <div>
            {add && (
              <div
                className="elevated rounded p-0 mb-3"
                style={{
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #17a2b8",
                }}
              >
                <div className="row d-flex align-items-center p-3">
                  <div className="col-9">
                    <Autocomplete
                      className="mt-3"
                      size="small"
                      id="allowance"
                      defaultValue={null}
                      value={allowance}
                      options={getPositionLevelAllowances()}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, allowance) => {
                        setAllowance(allowance || null);
                        const allowanceId = allowance ? allowance.id : "";

                        setFormData({
                          ...formData,
                          allowanceId,
                        });
                      }}
                      getOptionLabel={(option) =>
                        getNo(option.id, getPositionLevelAllowances()) +
                        "-" +
                        option.name +
                        (option.calculationMethodId === 1
                          ? ` (${option.currencyId}:`
                          : " (RATE:") +
                        option.amount +
                        (option.calculationMethodId === 2 &&
                        !!option.basePayrollComponentName
                          ? "/ " + option.basePayrollComponentName
                          : "") +
                        ")"
                      }
                      renderOption={(props, allowance) => (
                        <Fragment key={allowance.id}>
                          <Box component="li" {...props}>
                            {getNo(allowance.id, getPositionLevelAllowances())}{" "}
                            - {allowance.name}{" "}
                            {allowance.calculationMethodId === 1
                              ? ` (${allowance.currencyId}:`
                              : " (RATE:"}
                            {allowance.amount}
                            {allowance.calculationMethodId === 2 &&
                            !!allowance.basePayrollComponentName
                              ? "/ " + allowance.basePayrollComponentName
                              : ""}
                            {")"}
                          </Box>
                          <Divider />
                        </Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="---Select allowance---"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "allowance", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />

                    {errors.allowanceHasError && (
                      <div className="text-danger mb-2">
                        Allowance is required{" "}
                      </div>
                    )}
                  </div>

                  <div className="col-3 mt-3">
                    <Autocomplete
                      style={{ backgroundColor: "#eee" }}
                      size="small"
                      disabled
                      id="type"
                      defaultValue={null}
                      value={allowanceType}
                      options={allowanceTypes}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, allowanceType) => (
                        <Box component="li" {...props}>
                          {allowanceType.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "type", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>

                  <div
                    className={`${
                      !!allowance &&
                      allowance.calculationMethodId === 2 &&
                      !!allowance.basePayrollComponentName
                        ? "col-4"
                        : "col-6"
                    }`}
                  >
                    <NumberFormat
                      disabled
                      style={{ backgroundColor: "#eee" }}
                      fullWidth
                      size="small"
                      className="mt-3 py-0 small"
                      label={
                        !allowance || allowance.calculationMethodId === 1
                          ? "Amount"
                          : "Rate"
                      }
                      name="amount"
                      variant="outlined"
                      placeholder={`${allowance?.currencyId || ""} 0`}
                      prefix={`${allowance?.currencyId || ""} `}
                      customInput={TextField}
                      value={!!allowance ? allowance.amount : ""}
                      thousandSeparator={true}
                    />
                  </div>
                  <div
                    className={`${
                      !!allowance &&
                      allowance.calculationMethodId === 2 &&
                      !!allowance.basePayrollComponentName
                        ? "col-4"
                        : "col-6"
                    }`}
                  >
                    <div className="mt-3">
                      <Autocomplete
                        style={{ backgroundColor: "#eee" }}
                        size="small"
                        disabled
                        id="Method"
                        defaultValue={null}
                        value={calculationMethod}
                        options={calculationMethods}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        // onChange={(event, calculationMethod) => {
                        //   setCalculationMethod(calculationMethod || null);
                        //   const calculationMethodId = calculationMethod
                        //     ? calculationMethod.id
                        //     : "";
                        //   setFormData({ ...formData, calculationMethodId });
                        // }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, calculationMethod) => (
                          <Box component="li" {...props}>
                            {calculationMethod.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Method"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "calculationMethod", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {!!allowance &&
                    allowance.calculationMethodId === 2 &&
                    !!allowance.basePayrollComponentName && (
                      <div className=" col-4">
                        <div className="mt-3">
                          <Autocomplete
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            id="baseComponent"
                            defaultValue={null}
                            value={basePayrollComponent}
                            options={basePayrollComponents}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, basePayrollComponent) => (
                              <Box component="li" {...props}>
                                {basePayrollComponent.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Base Component"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "baseComponent", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                  {/* <div className="col-12">
                    <FormGroup
                      row
                      className=" mt-3 d-flex justify-content-center border border-info bg-light"
                    >
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payTPR || false}
                          />
                        }
                        label="Pay TPR"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payCSR || false}
                          />
                        }
                        label="Pay CSR"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payMedical || false}
                          />
                        }
                        label="Pay Medical"
                      />
                      <FormControlLabel
                        className="mb-0"
                        control={
                          <Checkbox
                            // disabled={loading}
                            checked={allowance?.payMaternity || false}
                          />
                        }
                        label="Pay Maternity"
                      />
                    </FormGroup>
                  </div> */}
                </div>

                <Divider className="my-1" />
                <div className="d-flex justify-content-center py-0 p-2">
                  <button
                    onClick={onSave}
                    type="button"
                    className="btn btn-primary text-uppercase"
                    disabled={loading}
                  >
                    {loading ? "Wait..." : "Save"}
                  </button>{" "}
                  {!!positionAllowances.length && (
                    <React.Fragment>
                      &nbsp; &nbsp;
                      <button
                        onClick={() => {
                          setAdd(false);
                          setAllowance(null);
                        }}
                        type="button"
                        className="btn btn-default text-uppercase"
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}

            {!!positionAllowances.length && (
              <React.Fragment>
                <table className="table table-bordered table-sm mt-1">
                  <thead>
                    <tr>
                      <th
                        colSpan={5}
                        style={{ textAlign: "center" }}
                        className="py-1"
                      >
                        ACTIVE ALLOWANCES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {positionAllowances.map((positionAllowance, paIndex) => (
                      <tr
                        key={paIndex}
                        style={{
                          background: paIndex % 2 === 0 ? "" : "#f6f7f7",
                          borderBottom: "2px dotted rgb(7, 142, 206)!important",
                        }}
                      >
                        <td>{paIndex + 1}</td>
                        <td style={{ width: "40%" }}>
                          {positionAllowance.allowanceName}
                        </td>
                        <td className="text-left">
                          <span>
                            <Chip
                              style={{ borderRadius: "0px" }}
                              variant="outlined"
                              className="small-chip"
                              size="small"
                              label={positionAllowance.allowanceTypeName}
                            />
                          </span>
                        </td>
                        <td className="text-right">
                          {positionAllowance.calculationMethodId === 1 ? (
                            <CurrencyFormat
                              value={positionAllowance.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            <>
                              {positionAllowance.amount}
                              {!!positionAllowance.basePayrollComponentName && (
                                <span
                                  className="badge badge-light text-uppercase text-info"
                                  style={{ fontSize: "14px" }}
                                >
                                  {"/ " +
                                    positionAllowance.basePayrollComponentName}
                                </span>
                              )}
                            </>
                          )}
                          <span
                            className={`badge mr-1 badge-${
                              positionAllowance.calculationMethodId === 1
                                ? "light text-info"
                                : "secondary"
                            }`}
                          >
                            {" "}
                            {positionAllowance.calculationMethodId === 1
                              ? positionAllowance.currencyId
                              : "RATE"}
                          </span>
                        </td>
                        <td className="text-right">
                          {!positionAllowance.isFromJobClassification && (
                            <IconButton
                              disabled={!!user?.selectedEntitySector?.isLocked}
                              color="error"
                              size="small"
                              onClick={() => {
                                setSelectedPositionAllowance(positionAllowance);
                                setConfirmRemovePositionAllowance(true);
                              }}
                              className=" mr-1"
                            >
                              <span className="material-icons">delete</span>
                            </IconButton>
                          )}
                          {positionAllowance.isFromJobClassification && (
                            <span className="mr-3">-</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        )}
      </div>

      {confirmRemovePositionAllowance && (
        <ConfirmationDialog
          confirmationDialog={confirmRemovePositionAllowance}
          message={`Are you sure you want to remove this allowance?`}
          setConfirmationDialog={setConfirmRemovePositionAllowance}
          onYes={() => {
            deletePositionAllowance(
              selectedPositionAllowance,
              setConfirmRemovePositionAllowance,
              setSelectedPositionAllowance
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  allowances,
  positionAllowances,
  calculationMethods,
  basePayrollComponents,
  allowanceTypes,
}) => {
  return {
    user,
    loading,
    onWait,
    allowances,
    positionAllowances,
    calculationMethods,
    basePayrollComponents,
    allowanceTypes,
  };
};
export default connect(mapStateToProps, {
  savePositionAllowance,
  getPositionAllowances,
  deletePositionAllowance,
  // getAllowances,
})(PositionAllowances);
