const types = {
  SET_ACTIVE_CONTRACTS: "SET_ACTIVE_CONTRACTS",
  SET_EXPIRED_CONTRACTS: "SET_EXPIRED_CONTRACTS",
  ADD_NEW_CONTRACT: "ADD_NEW_CONTRACT",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",
  DELETE_CONTRACT: "DELETE_CONTRACT",
  SET_SELECTED_CONTRACT: "SET_SELECTED_CONTRACT",
  SET_POSITION_REQUEST_TYPES: "SET_POSITION_REQUEST_TYPES",
};

export default types;
