import React, { useEffect, useState } from "react";
import { Avatar, Skeleton } from "@mui/material";
import { connect } from "react-redux";
import {
  getAnnualEmployeeLeaves,
  getLeaveTypeBalances,
  getOtherLeaveSummary,
  getPieAnnualLeavePlan,
  getPieInLeaveStatus,
  getPublicHolidays,
} from "../../../store/leave/actions";
import {
  DashboardDoughnutChart,
  DashboardLabel,
} from "../../admin/components/StructureDashboard";
import moment from "moment";
import EventCalendar from "../components/EventCalendar";

const Dashboard = (props) => {
  const {
    annualEmployeeLeaves,
    weeklyLeaveSummary,
    publicHolidays,
    getAnnualEmployeeLeaves,
    getPublicHolidays,
    getPieAnnualLeavePlan,
    getLeaveTypeBalances,
    getPieInLeaveStatus,
    getOtherLeaveSummary,
  } = props;
  const [loadingPublicHolidays, setLoadingPublicHolidays] = useState(true);
  const [loadingData, setLoadingData] = useState({
    annualEmployeeLeaves: true,
    weeklyLeaveSummary: true,
    annualLeavePlanPie: true,
    leaveTypeBalances: true,
    employeeInLeaveStatus: true,
    otherLeaveSummary: true,
  });
  const [annualLeavePlanPie, setAnnualLeavePlanPie] = useState({
    allowedDays: 0,
    plannedDays: 0,
    approvedLeaves: 0,
    pendingLeaves: 0,
    rejectedLeaves: 0,
    takenLeaves: 0,
    postponedLeaves: 0,
  });
  const [leaveTypeBalances, setLeaveTypeBalances] = useState({
    annualLeave: 0,
    maternityLeave: 0,
    sickLeave: 0,
    incidentalLeave: 0,
    authorizedLeave: 0,
  });

  const [employeeInLeaveStatus, setEmployeeInLeaveStatus] = useState({
    inLeave: 0,
    inService: 0,
  });

  const [calendarEvents, setCalendarEvents] = useState([]);
  const [otherLeaveSummary, setOtherLeaveSummary] = useState([]);

  useEffect(() => {
    if (publicHolidays.length === 0) {
      getPublicHolidays(setLoadingPublicHolidays);
    } else setLoadingPublicHolidays(false);

    if (annualEmployeeLeaves.length === 0) {
      getAnnualEmployeeLeaves({ isWeekly: false }, (status) =>
        setLoadingData((prev) => ({ ...prev, annualEmployeeLeaves: status }))
      );
    } else {
      setLoadingData((prev) => ({ ...prev, annualEmployeeLeaves: false }));
    }

    if (weeklyLeaveSummary.length === 0) {
      getAnnualEmployeeLeaves({ isWeekly: true }, (status) =>
        setLoadingData((prev) => ({ ...prev, weeklyLeaveSummary: status }))
      );
    } else {
      setLoadingData((prev) => ({ ...prev, weeklyLeaveSummary: false }));
    }

    if (annualLeavePlanPie.allowedDays === 0) {
      getPieAnnualLeavePlan((status, data) => {
        setLoadingData((prev) => ({ ...prev, annualLeavePlanPie: status }));
        if (data) {
          setAnnualLeavePlanPie(data);
        }
      });
    } else {
      setLoadingData((prev) => ({ ...prev, annualLeavePlanPie: false }));
    }

    if (leaveTypeBalances.maternityLeave === 0) {
      getLeaveTypeBalances((status, data) => {
        setLoadingData((prev) => ({ ...prev, leaveTypeBalances: status }));
        if (data) {
          setLeaveTypeBalances(data);
        }
      });
    } else {
      setLoadingData((prev) => ({ ...prev, leaveTypeBalances: false }));
    }

    if (employeeInLeaveStatus.inLeave === 0) {
      getPieInLeaveStatus((status, data) => {
        setLoadingData((prev) => ({ ...prev, employeeInLeaveStatus: status }));
        if (data) {
          setEmployeeInLeaveStatus(data);
        }
      });
    } else {
      setLoadingData((prev) => ({ ...prev, employeeInLeaveStatus: false }));
    }

    if (!otherLeaveSummary.length) {
      getOtherLeaveSummary((status, data) => {
        setLoadingData((prev) => ({ ...prev, otherLeaveSummary: status }));
        if (data) {
          setOtherLeaveSummary(data);
        }
      });
    } else {
      setLoadingData((prev) => ({ ...prev, otherLeaveSummary: false }));
    }

    return () => {
      setLoadingData({
        annualEmployeeLeaves: true,
        weeklyLeaveSummary: true,
        annualLeavePlanPie: true,
        leaveTypeBalances: true,
        employeeInLeaveStatus: true,
      });
      setLoadingPublicHolidays(true);
    };
  }, []);

  useEffect(() => {
    if (annualEmployeeLeaves.length > 0) {
      setCalendarEvents(
        annualEmployeeLeaves.map((item) => ({
          title: item.employeeNames + " - " + item.leaveType,
          start: moment(item.start).format("YYYY-MM-DD"),
          end: moment(item.end).format("YYYY-MM-DD"),
          allDay: true,
        }))
      );
    } else {
      setCalendarEvents([]);
    }
  }, [annualEmployeeLeaves]);

  const PLANNING_PROGRESS_DOUGHNUT_CHART = {
    labels: ["Planned", "Not planned"],
    datasets: [
      {
        data: [
          annualLeavePlanPie.plannedDays,
          annualLeavePlanPie.allowedDays - annualLeavePlanPie.plannedDays,
        ],
        backgroundColor: ["rgba(80, 181, 88,0.6)", "rgba(255, 193, 7, 0.6)"],
        borderColor: ["rgba(80, 181, 88, 1)", "rgba(255, 193, 7, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const LEAVE_STATUS_DOUGHNUT_CHART = {
    labels: ["In service", "In leave"],
    datasets: [
      {
        data: [employeeInLeaveStatus.inService, employeeInLeaveStatus.inLeave],
        backgroundColor: ["rgba(80, 181, 88,0.6)", "rgba(7, 142, 206, 0.7)"],
        borderColor: ["rgba(80, 181, 88, 1)", "rgba(7, 142, 206, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const POSTPONED_DOUGHNUT_CHART = {
    labels: ["Postponed", "Not postponed"],
    datasets: [
      {
        data: [
          annualLeavePlanPie.postponedLeaves,
          annualLeavePlanPie.takenLeaves,
        ],
        backgroundColor: ["rgba(80, 181, 88,0.6)", "rgba(7, 142, 206, 0.7)"],
        borderColor: ["rgba(80, 181, 88, 1)", "rgba(7, 142, 206, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const LEAVE_TYPE_BALANCE = {
    labels: [
      "Annual Leave",
      "Maternity Leave",
      "Sick Leave",
      "Incidental Leave",
      "Authorized absence",
    ],
    datasets: [
      {
        data: [
          leaveTypeBalances?.annualLeave,
          leaveTypeBalances?.maternityLeave,
          leaveTypeBalances?.sickLeave,
          leaveTypeBalances?.incidentalLeave,
          leaveTypeBalances?.authorizedLeave,
        ],
        backgroundColor: [
          "rgba(80, 181, 88,0.6)",
          "rgba(7, 142, 206, 0.7)",
          "rgba(50, 191, 189, 0.5)",
          "rgba(170, 173, 173,0.6)",
        ],
        borderColor: [
          "rgba(80, 181, 88, 1)",
          "rgba(7, 142, 206, 1)",
          "rgba(50, 191, 189, 1)",
          "rgba(170, 173, 173, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="row w-100 my-3 px-4">
        <div className="col-12 col-md-9 my-md-0 my-2">
          <div className="d-flex flex-wrap justify-content-center w-100">
            <DashboardLabel
              labelTitle="Unschedule"
              labelHint="Staff with unscheduled leave"
              labelValue={
                annualLeavePlanPie.allEmployees -
                annualLeavePlanPie.startedPlaningEmployees
              }
              loading={loadingData.annualLeavePlanPie}
            />

            <DashboardLabel
              labelTitle="Scheduled"
              labelHint="Staff with scheduled leave"
              labelValue={annualLeavePlanPie.startedPlaningEmployees}
              loading={loadingData.annualLeavePlanPie}
            />

            {/* <DashboardLabel
              labelTitle="100% Planned Employees"
              labelValue={1022}
              loading={loadingData.annualLeavePlanPie}
            />

            <DashboardLabel
              labelTitle="Employees on Leave"
              labelValue={9322}
              loading={loadingData.annualLeavePlanPie}
            /> */}
          </div>

          {/* pie and doughnut charts */}
          <div className="row justify-content-center w-100 my-3 px-4">
            <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2">
              <DashboardDoughnutChart
                chartTitle="Planning Progress"
                chartData={PLANNING_PROGRESS_DOUGHNUT_CHART}
                loading={loadingData.annualLeavePlanPie}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2">
              <DashboardDoughnutChart
                chartTitle="Leave Status"
                chartData={LEAVE_STATUS_DOUGHNUT_CHART}
                loading={loadingData.employeeInLeaveStatus}
              />
            </div>

            <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2">
              <DashboardDoughnutChart
                chartTitle="Status by Leave Type"
                chartData={LEAVE_TYPE_BALANCE}
                loading={loadingData.leaveTypeBalances}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3 my-md-0 my-2">
              <DashboardDoughnutChart
                chartTitle="Postponing Status"
                chartData={POSTPONED_DOUGHNUT_CHART}
                loading={loadingData.annualLeavePlanPie}
              />
            </div>
          </div>

          <div className="row px-4">
            <div className="col-12">
              <div className="elevated-0 border rounded my-md-0 my-2 p-2">
                <EventCalendar events={calendarEvents} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 my-md-0 my-2 py-2">
          <div className="elevated-0 border rounded w-100 p-2 mb-3">
            <span className="text-uppercase text-primary font-weight-bold">
              Weekly Leave Summary
            </span>

            <div
              className="mt-3"
              style={{
                minHeight:
                  loadingData.weeklyLeaveSummary ||
                  weeklyLeaveSummary.length <= 6
                    ? "50px"
                    : "400px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              {loadingData.weeklyLeaveSummary ? (
                <div>
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />{" "}
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                </div>
              ) : (
                weeklyLeaveSummary.map((item) => (
                  <div
                    key={item.id}
                    className="w-100 d-flex align-items-center justify-content-between mb-2 p-2 rounded border"
                  >
                    <div className="d-flex align-items-center">
                      <Avatar
                        alt={item.employeeNames}
                        src={item.profileImage}
                      />
                      <div className="d-flex flex-column ml-1">
                        <span className="font-weight-bold">
                          {item.employeeNames}
                        </span>
                        <span
                          className="text-muted"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {item.positionName}
                        </span>
                      </div>
                    </div>

                    <span
                      className="badge badge-pill badge-light"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {moment(item.start).format("MMM DD")} -{" "}
                      {moment(item.end).format("MMM DD")}
                    </span>
                  </div>
                ))
              )}

              {!loadingData.weeklyLeaveSummary &&
                weeklyLeaveSummary.length === 0 && (
                  <div className="text-center my-2">No data found</div>
                )}
            </div>
          </div>

          <div className="elevated-0 border rounded p-2 w-100 mb-3">
            <span className="text-uppercase text-primary font-weight-bold">
              Other Leave Summary
            </span>

            <div
              className="mt-3"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {loadingData.otherLeaveSummary ? (
                <div>
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                </div>
              ) : (
                otherLeaveSummary.map((item) => (
                  <div
                    key={item.id}
                    className="d-flex align-items-center mb-2 p-2 rounded border"
                  >
                    <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
                      <h6
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        className="ml-1 mb-1"
                      >
                        {item.name}
                      </h6>
                      <div>
                        <span
                          className="badge badge-light text-left text-uppercase"
                          style={{ minWidth: "90px" }}
                        >
                          <span
                            className="badge badge-light bg-secondary text-light"
                            style={{ fontSize: "11px" }}
                          >
                            {item.pending}
                          </span>{" "}
                          Pending
                        </span>
                        <span
                          className="ml-1 badge badge-info text-left text-uppercase"
                          style={{ minWidth: "90px" }}
                        >
                          <span
                            className="badge badge-light bg-light text-primary"
                            style={{ fontSize: "11px" }}
                          >
                            {item.approved}
                          </span>{" "}
                          Approved
                        </span>
                        <span
                          className="ml-1 badge badge-success text-left text-uppercase"
                          style={{ minWidth: "90px" }}
                        >
                          <span
                            className="badge badge-light bg-light text-secondary"
                            style={{ fontSize: "11px" }}
                          >
                            {item.onLeave}
                          </span>{" "}
                          OnLeave
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
              {!loadingData.otherLeaveSummary &&
                otherLeaveSummary.length === 0 && (
                  <div className="text-center my-2">No data found</div>
                )}
            </div>
          </div>

          <div className="elevated-0 border rounded p-2 w-100 mb-3">
            <span className="text-uppercase text-primary font-weight-bold">
              Public Holidays
            </span>

            <div
              className="mt-3"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {loadingPublicHolidays ? (
                <div>
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                  <Skeleton
                    variant="rect"
                    height={50}
                    className="mb-1 rounded"
                  />
                </div>
              ) : (
                publicHolidays.map((holiday) => (
                  <div
                    key={holiday.id}
                    className="d-flex align-items-center mb-3"
                  >
                    <div
                      className="d-flex flex-column justify-content-center date-card text-center"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <span className="p-0">{holiday.dayOfMonth}</span>
                      <span className="p-0">{holiday.abbreviatedMonth}</span>
                    </div>
                    <div className="ml-2">
                      <h6
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        className="m-0"
                      >
                        {holiday.name}
                      </h6>
                      <p
                        className="m-0"
                        style={{
                          fontSize: "12px",
                          color: "#929292",
                          fontWeight: "bold",
                        }}
                      >
                        {holiday.fullDayOfWeek}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  publicHolidays,
  annualEmployeeLeaves,
  weeklyLeaveSummary,
}) => {
  return {
    publicHolidays,
    annualEmployeeLeaves,
    weeklyLeaveSummary,
  };
};
export default connect(mapStateToProps, {
  getPublicHolidays,
  getAnnualEmployeeLeaves,
  getPieAnnualLeavePlan,
  getLeaveTypeBalances,
  getPieInLeaveStatus,
  getOtherLeaveSummary,
})(Dashboard);
