import React, { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const Breadcrumb = (props) => {
  const { user, ippisModules } = props;

  const location = useLocation();

  const getActiveModules = () => {
    return ippisModules.filter(({ isActive }) => isActive === true);
  };

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/support/dashboard") ? " active  " : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link> */}

        {/* {getActiveModules().map((mod, index) => (
          <Fragment key={index}>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to={`/support${mod.to}`}
              className={` px-1 rounded   ${
                location.pathname.startsWith(`/support${mod.to}`)
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">{mod.shortName} </span>
            </Link>
            <HorizontalRuleIcon className=" rotated" />
          </Fragment>
        ))} */}

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/users"
          className={` px-1 rounded   ${
            location.pathname.includes("/support/users") ? " active  " : " "
          }`}
        >
          <span className="text-truncate  menu-item">Users</span>
        </Link>
        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/structure"
          className={`  px-1 rounded  ${
            location.pathname.includes("/support/structure") ? "active" : " "
          }`}
        >
          <span className="text-truncate  menu-item">Structure</span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/payroll"
          className={`  px-1 rounded  ${
            location.pathname.includes("/support/payroll") ? "active" : " "
          }`}
        >
          <span className="text-truncate  menu-item">Payroll</span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/recruitment"
          className={`px-1 rounded  ${
            location.pathname.includes("/support/recruitment") ? "active" : " "
          }`}
        >
          <span className="text-truncate  menu-item">Recruitment</span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/rbm"
          className={`px-1 rounded  ${
            location.pathname.includes("/support/rbm") ? "active" : " "
          }`}
        >
          <span className="text-truncate menu-item">RBM</span>
        </Link>

        <HorizontalRuleIcon className="rotated" />

        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/support/common"
          className={`  px-1 rounded  ${
            location.pathname.includes("/support/common") ? "active" : " "
          }`}
        >
          <span className="text-truncate  menu-item">Common</span>
        </Link>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, ippisModules }) => {
  return {
    user,
    ippisModules,
  };
};
export default connect(mapStateToProps)(Breadcrumb);
