import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { connect } from "react-redux";

const Breadcrumb = (props) => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  const { user } = props;
  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/contracts/in-progress"
              className={`px-1 rounded ${
                location.pathname.includes("/contracts/in-progress")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">InProgress</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Breadcrumb);
