import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Box,
  Input,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getEntities } from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import {
  updateRequestSanctionLetter,
  reviewCaseSanctionRequest,
  approveRequestSanctionLetter,
  getDisciplineLetterNo,
} from "../../../store/discipline/actions";
import { getHeadOfUnit } from "../../../store/structure/actions";
import {
  removeDuplicates,
  textCapitalized,
} from "../../common/components/Utils";
import { showError } from "../../toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import SignLetter from "../../Documents/LetterFormat/SignLetter";
import CommonLetter from "../../Documents/LetterFormat/CommonLetter";
const ViewSanctionLetterDialog = (props) => {
  const {
    loading,
    showSanctionLetter,
    setShowSanctionLetter,
    entities,
    getEntities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    updateRequestSanctionLetter,
    caseRequest,
    enableToFillLetter,
    action = "EDIT",
    actionStatusId = 0,
    closeModal,
    reviewCaseSanctionRequest,
    approveRequestSanctionLetter,
    isSentToDisciplinaryCommittee,
    currentSanction,
    headOfUnit,
    getHeadOfUnit,
    getDisciplineLetterNo,
    disciplineLetterNo,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [reason, setReason] = useState("");

  const [showCaseReviewerForm, setShowCaseReviewerForm] = useState(false);
  const [showCaseApproverForm, setShowCaseApproverForm] = useState(false);
  const [pkiPassword, setPkiPassword] = useState("");

  const [ccData, setCCData] = useState(
    caseRequest && !!caseRequest.sanctionLetterCc
      ? JSON.parse(caseRequest?.sanctionLetterCc || [])
      : []
  );
  const [formData, setFormData] = useState({
    caseId: caseRequest?.id || "",
    caseSanctionId: currentSanction?.id || "",
    docLetterNo: caseRequest?.sanctionLetterNo || disciplineLetterNo,

    explanationFeedbackLetterNo:
      caseRequest?.explanationFeedbackLetterNo || "N/A",
    reportCommitteeSummary: caseRequest?.reportCommitteeSummary || "",
    reportCommitteeDate: caseRequest.reportCommitteeDate
      ? moment(caseRequest?.reportCommitteeDate || null).format("YYYY-MM-DD")
      : null,
    explanationFeedbackLetterDate: caseRequest.explanationFeedbackLetterDate
      ? moment(caseRequest?.explanationFeedbackLetterDate || null).format(
          "YYYY-MM-DD"
        )
      : null,
    isProvisionSuspensionLetterRequired:
      caseRequest?.isProvisionSuspensionLetterRequired || false,
    cc:
      caseRequest && !!caseRequest.sanctionLetterCc
        ? JSON.parse(caseRequest?.sanctionLetterCc || [])
        : [],
    effectedOn: caseRequest.sanctionEffectedOn
      ? moment(caseRequest?.sanctionEffectedOn || null).format("YYYY-MM-DD")
      : null,
    isSentToDisciplinaryCommittee:
      caseRequest?.isSentToDisciplinaryCommittee ||
      isSentToDisciplinaryCommittee ||
      false,
  });
  useEffect(() => {
    getDisciplineLetterNo();
  }, []);

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);
  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
    if (!headOfUnit) getHeadOfUnit(caseRequest.unitId);
  }, []);

  useEffect(() => {
    if (!caseRequest?.sanctionLetterNo) {
      setFormData({ ...formData, docLetterNo: disciplineLetterNo });
    }
  }, [disciplineLetterNo]);

  const [errors, setErrors] = useState({
    docLetterNoError: false,
    ccHasError: false,
    hasError: false,
    docContentHasError: false,
    maxCharHasError: false,
    effectiveDateError: false,
  });

  const formValidator = () => {
    const error = {
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      maxCharHasError: false,
      effectiveDateError: false,
    };
    if (!formData.docLetterNo && ["VERIFY", "APPROVE"].includes(action)) {
      error.docLetterNoError = true;
      error.hasError = true;
    }

    if (!formData.effectedOn) {
      error.effectiveDateError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill the required field");
      return true;
    }
    return false;
  };
  const onClose = () => {
    setShowSanctionLetter(false);
  };
  const onCloseAll = () => {
    setShowSanctionLetter(false);
    closeModal();
  };
  const onSave = () => {
    if (!formValidator()) {
      formData.cc = JSON.stringify(ccData);
      updateRequestSanctionLetter(formData, onClose);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSanctionLetter}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Request {currentSanction?.name || ""} Letter</span> /{" "}
            <span className="text-primary">
              {textCapitalized(caseRequest?.lastName)}
              {textCapitalized(caseRequest?.firstName)}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <CommonLetter
              caseSanctionId={formData.caseSanctionId}
              recipientName={caseRequest.lastName + " " + caseRequest.firstName}
              recipientEmail={caseRequest.email}
              recipientPhone={caseRequest.phoneNumber}
              institutionName={caseRequest.entityName}
              docLetterNo={formData.docLetterNo}
              explanationFeedbackLetterNo={formData.explanationFeedbackLetterNo}
              explanationFeedbackLetterDate={moment(
                formData.explanationFeedbackLetterDate
              ).format("LL")}
              reportCommitteeSummary={formData.reportCommitteeSummary}
              reportCommitteeDate={moment(formData.reportCommitteeDate).format(
                "LL"
              )}
              effectiveDate={moment(formData.effectedOn).format("LL")}
              subTitle={
                "Dear " + (caseRequest.lastName + " " + caseRequest.firstName)
              }
              competentAuthorityName={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail.competentAuthorityUser.names
                  : ""
              }
              competentAuthorityPosition={
                !!requestDocumentSignerDetail
                  ? requestDocumentSignerDetail?.competentAuthorityUser
                      ?.positionName
                  : ""
              }
              enableToFillLetter={enableToFillLetter}
              docLetterNoInput={
                <>
                  <Input
                    autoFocus
                    error={!!errors.docLetterNoError}
                    value={formData.docLetterNo}
                    style={{ width: "150px" }}
                    inputProps={{
                      "aria-label": "add doc No",
                    }}
                    onChange={(e) => {
                      const docLetterNo = e.target.value;
                      setFormData({
                        ...formData,
                        docLetterNo,
                      });

                      setErrors({
                        ...errors,
                        docLetterNoError: false,
                      });
                    }}
                  />
                </>
              }
              effectiveDateInput={
                <>
                  <Input
                    type="date"
                    error={!!errors.effectiveDateError}
                    value={
                      formData.effectedOn ||
                      caseRequest?.sanctionEffectedOn ||
                      null
                    }
                    style={{ width: "150px" }}
                    inputProps={{
                      "aria-label": "add reference date",
                    }}
                    onChange={(e) => {
                      const effectedOn = e.target.value;
                      setFormData({
                        ...formData,
                        effectedOn,
                      });

                      setErrors({
                        ...errors,
                        effectiveDateError: false,
                      });
                    }}
                  />
                </>
              }
              ccSelectForm={
                <>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    id="ccData"
                    options={entities.map(({ name, institutionEmail, id }) => ({
                      name,
                      institutionEmail,
                      id,
                    }))}
                    value={ccData}
                    onChange={(e, cc) => {
                      const uniqueCc = removeDuplicates(cc);
                      setCCData(uniqueCc);
                      const ccHasError = false;
                      setErrors({ ...errors, ccHasError });
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={
                            selected ||
                            !!ccData.find(({ id }) => id === option.id)
                          }
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Select the institution to copy"}
                        placeholder={"Select the institution to copy"}
                      />
                    )}
                  />

                  {errors.ccHasError && (
                    <small className="text-danger mb-1">
                      Please, select the institution to copy
                    </small>
                  )}
                </>
              }
              ccData={ccData}
              providingExplanationLetterDate={moment(
                caseRequest.requestExplanationDocLetterDate
              ).format("LL")}
              providingExplanationLetterNo={
                caseRequest.requestExplanationDocLetterNo
              }
              employeeExplanationLetterDate={moment(
                caseRequest.employeeExplanationLetterDate
              ).format("LL")}
              isSentToDisciplinaryCommittee={isSentToDisciplinaryCommittee}
              isProvisionSuspensionLetterRequired={
                caseRequest?.isProvisionSuspensionLetterRequired || false
              }
              provisionalSuspensionNo={caseRequest.provisionalSuspensionNo}
              provisionalSuspensionDate={moment(
                caseRequest.provisionalSuspensionDate
              ).format("LL")}
              signContent={
                <table>
                  <tr>
                    <td
                      style={{
                        position: "relative",

                        width: "20%",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                      [12, 11].includes(caseRequest?.caseStatusId) ? (
                        <>
                          <SignLetter />
                        </>
                      ) : (
                        <>
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </>
                      )}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    ></td>
                  </tr>
                </table>
              }
            />
          </Box>
        </DialogContent>

        {!!hasPermissions([
          "IS_HR",
          "IS_DOCUMENT_VERIFIER",
          "IS_HEAD_OF_UNIT",
          "IS_HEAD_OF_INSTITUTION",
        ]) &&
          enableToFillLetter && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (action === "EDIT") {
                      onSave();
                    }
                    if (["REVIEW", "VERIFY"].includes(action)) {
                      if (!formValidator()) {
                        setShowCaseReviewerForm(true);
                      }
                    }
                    if (["APPROVE"].includes(action)) {
                      if (!formValidator()) {
                        setShowCaseApproverForm(true);
                      }
                    }
                  }}
                  className="btn btn-xs"
                >
                  {loading ? "Wait.." : "Submit"}
                </Button>
              </DialogActions>
            </>
          )}
        {showCaseReviewerForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseReviewerForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className={`text-success`}>confirm</strong> this case?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReviewerForm}
            onYes={() => {
              formData.cc = JSON.stringify(ccData);
              formData.reason = reason;
              formData.caseStatusId = actionStatusId;
              reviewCaseSanctionRequest(formData, onCloseAll);
            }}
          />
        )}

        {showCaseApproverForm && (
          <ConfirmationDialog
            disabled={!pkiPassword}
            confirmationDialog={showCaseApproverForm}
            message={
              <>
                <p>Enter your RISA Digital signature password to sign</p>
                <form>
                  <TextField
                    fullWidth
                    size="small"
                    id="pki-password"
                    rows={4}
                    name="pki-password"
                    type="password"
                    label="Enter your RISA Digital signature password to sign"
                    variant="outlined"
                    placeholder="Enter your RISA Digital signature password to sign"
                    value={pkiPassword}
                    onChange={(e) => {
                      setPkiPassword(e.target.value || "");
                    }}
                  />
                </form>
              </>
            }
            setConfirmationDialog={setShowCaseApproverForm}
            onYes={() => {
              if (!formData.docLetterNo) {
                showError("Document No is required");
                return false;
              }
              formData.cc = JSON.stringify(ccData);
              formData.password = pkiPassword;
              formData.caseStatusId = actionStatusId;
              approveRequestSanctionLetter(formData, onCloseAll);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  entities,
  requestDocumentSignerDetail,
  headOfUnit,
  disciplineLetterNo,
}) => {
  return {
    loading,
    entities,
    requestDocumentSignerDetail,
    headOfUnit,
    disciplineLetterNo,
  };
};
export default connect(mapStateToProps, {
  getEntities,
  getRequestDocumentSignerDetails,
  updateRequestSanctionLetter,
  reviewCaseSanctionRequest,
  approveRequestSanctionLetter,
  getHeadOfUnit,
  getDisciplineLetterNo,
})(ViewSanctionLetterDialog);
