import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        <>
          <Link
            style={{ textDecoration: "none", color: "#fff" }}
            to="/leave/dashboard"
            className={`px-1 rounded ${
              location.pathname.includes("/leave/dashboard") ? " active  " : " "
            }`}
          >
            <span className="text-truncate menu-item">Dashboard</span>
          </Link>
        </>

        {hasPermissions(["IS_HEAD_OF_UNIT", "IS_HR"]) && (
          <>
            {hasPermissions(["IS_HEAD_OF_UNIT", "IS_HR"]) && (
              <HorizontalRuleIcon className="rotated" />
            )}
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/leave/annual-leave"
              className={` px-1 rounded   ${
                location.pathname.includes("/leave/annual-leave")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Annual Leave</span>
            </Link>
          </>
        )}
        {hasPermissions(["IS_HEAD_OF_UNIT", "IS_HR"]) && (
          <>
            {hasPermissions(["IS_HEAD_OF_UNIT", "IS_HR"]) && (
              <HorizontalRuleIcon className="rotated" />
            )}
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/leave/other-leave"
              className={` px-1 rounded   ${
                location.pathname.includes("/leave/other-leave")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Other Leave</span>
            </Link>
          </>
        )}

        {false && (
          <>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/leave/my-leave"
              className={`px-1 rounded  ${
                location.pathname.includes("/leave/my-leave") ? "active" : " "
              }`}
            >
              <span className="text-truncate  menu-item">My Leave</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

export default Breadcrumb;
