import Appeals from "./Appeals";

const ArchivedAppeals = (props) => {
const {}=props;
  return (
    <>
     <Appeals appealCategory="ArchivedAppeals" />
    </>
  );
};

export default ArchivedAppeals;
