import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Tabs,
  Tab,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveNPSCReport,
  rejectNPSCReport,
  verifyNPSCReport,
  getNPSCReport,
} from "../../../store/recruitment/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SignLetter from "../../Documents/LetterFormat/SignLetter";

import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import SubmissionRecruitmentReportLetter from "../../Documents/LetterFormat/SubmissionRecruitmentReportLetter";
import SubmitReportDialog from "./SubmitReportDialog";
import PdfViewer from "../../common/components/PdfViewer";
const ApproveNPSCSubmissionLetterDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    approveNPSCReport,
    rejectNPSCReport,
    verifyNPSCReport,
    npscReport,
    getRequestDocumentSignerDetails,
    requestDocumentSignerDetail,
    getNPSCReport,
    onCloseReport,
  } = props;
  const { hasPermissions } = useRequiredAuth();

  const [showSubmissionReport, setShowSubmissionReport] = useState(false);

  const [npscReportDetail, setNPSCReportDetail] = useState(
    npscReport?.details || null
  );

  useEffect(() => {
    setNPSCReportDetail(npscReport?.details || null);
  }, [npscReport]);

  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  const [maxWidth, setMaxWidth] = useState("lg");

  
  const [showRejectRequestForm, setShowRejectRequestForm] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [showVerifyRequestForm, setShowVerifyRequestForm] = useState(false);
  const [reason, setReason] = useState("");
  const [pkiPassword, setPkiPassword] = useState("");
  const onClose = () => {
    setApproveRequestForm(false);
    setShowRejectRequestForm(false);
    setShowApproveRequestForm(false);
    setReason("");
    setPkiPassword("");
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>Submission of Recruitment Letter</span> /{" "}
            <span className="text-primary mr-2">
              {npscReportDetail?.positionName || ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            {[1, 2].includes(+npscReportDetail?.statusId) && (
              <Button
                variant="outlined"
                color="info"
                size="small"
                disabled={loading}
                onClick={() => {
                  getNPSCReport(
                    npscReportDetail.id,
                    setShowSubmissionReport,
                    () => {}
                  );
                }}
                className="m-2 text-capitalized float-right"
              >
                Edit Letter
              </Button>
            )}
            {!!npscReportDetail?.docReferenceId ? (
              <>
                <PdfViewer
                  url={
                    process.env.REACT_APP_FILES_URL +
                    "/preview/" +
                    npscReportDetail?.docReferenceId
                  }
                />
              </>
            ) : (
              <>
                <SubmissionRecruitmentReportLetter
                  enableToFillLetter={false}
                  docContent={npscReportDetail.docContent}
                  docLetterNo={npscReportDetail.docLetterNo}
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            position: "relative",

                            width: "20%",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                          +npscReportDetail?.statusId === 2 ? (
                            <>
                              <SignLetter />
                            </>
                          ) : (
                            <>
                              <span className="d-block text-danger">
                                Not signed
                              </span>
                            </>
                          )}
                        </td>
                        <td
                          style={{
                            width: "20%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        ></td>
                      </tr>
                    </table>
                  }
                  institutionName={
                    requestDocumentSignerDetail?.competentAuthorityUser?.institutionName.toUpperCase() ||
                    ""
                  }
                  competentAuthorityName={
                    !!requestDocumentSignerDetail
                      ? requestDocumentSignerDetail.competentAuthorityUser.lastName.toUpperCase() +
                        " " +
                        requestDocumentSignerDetail.competentAuthorityUser
                          .firstName
                      : ""
                  }
                  competentAuthorityPosition={
                    !!requestDocumentSignerDetail
                      ? requestDocumentSignerDetail.competentAuthorityUser
                          .positionName
                      : ""
                  }
                  ccData={JSON.parse(npscReportDetail.cc)}
                />
              </>
            )}
          </Box>
        </DialogContent>

        {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
          +npscReportDetail?.statusId === 1 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (!npscReportDetail.docLetterNo) {
                      showError("Document No is required");
                      return false;
                    }
                    if (!npscReportDetail.docContent) {
                      showError("Document Content is required");
                      return false;
                    }
                    setShowVerifyRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Verify
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowRejectRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Reject
                </Button>
              </DialogActions>
            </>
          )}

        {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
          +npscReportDetail?.statusId === 2 && (
            <>
              <DialogActions className="d-flex justify-content-center py-2">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    if (!npscReportDetail.docLetterNo) {
                      showError("Document No is required");
                      return false;
                    }
                    if (!npscReportDetail.docContent) {
                      showError("Document Content is required");
                      return false;
                    }
                    setShowApproveRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Sign
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setShowRejectRequestForm(true);
                  }}
                  className="btn btn-xs"
                >
                  Reject
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
      {showApproveRequestForm && (
        <ConfirmationDialog
          disabled={!pkiPassword}
          confirmationDialog={showApproveRequestForm}
          message={
            <>
              <p>Enter your RISA Digital signature password to sign</p>
              <form>
                <TextField
                  fullWidth
                  size="small"
                  id="pki-password"
                  rows={4}
                  name="pki-password"
                  type="password"
                  label="Enter your RISA Digital signature password to sign"
                  variant="outlined"
                  placeholder="Enter your RISA Digital signature password to sign"
                  value={pkiPassword}
                  onChange={(e) => {
                    setPkiPassword(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowApproveRequestForm}
          onYes={() => {
            if (!npscReportDetail.docLetterNo) {
              showError("Document No is required");
              return false;
            }
            if (!npscReportDetail.docContent) {
              showError("Document Content is required");
              return false;
            }
            approveNPSCReport(
              { requestId: npscReportDetail.id, password: pkiPassword },
              onClose,
              onCloseReport
            );
          }}
        />
      )}

      {showRejectRequestForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this request?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectRequestForm}
          onYes={() => {
            rejectNPSCReport(
              {
                requestId: npscReportDetail.id,
                reason: reason,
                statusId: 5,
              },
              onClose,
              onCloseReport
            );
          }}
        />
      )}
      {showSubmissionReport && (
        <SubmitReportDialog
          showSubmissionReport={showSubmissionReport}
          setShowSubmissionReport={setShowSubmissionReport}
          advertisement={npscReportDetail}
          onCloseSubmitReport={onClose}
        />
      )}

      {showVerifyRequestForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showVerifyRequestForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-primary">confirm</strong> this request?
              </p>
              <form>
                <TextField
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  name="Reason"
                  label="Type your reason .."
                  variant="outlined"
                  placeholder="Type your reason  .."
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value || "");
                  }}
                />
              </form>
            </>
          }
          setConfirmationDialog={setShowVerifyRequestForm}
          onYes={() => {
            verifyNPSCReport(
              {
                requestId: npscReportDetail.id,
                reason: reason,
              },
              onClose,
              onCloseReport
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  npscReport,
  requestDocumentSignerDetail,
}) => {
  return {
    loading,
    npscReport,
    requestDocumentSignerDetail,
  };
};
export default connect(mapStateToProps, {
  approveNPSCReport,
  rejectNPSCReport,
  verifyNPSCReport,
  getRequestDocumentSignerDetails,
  getNPSCReport,
})(ApproveNPSCSubmissionLetterDialog);
