import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { getPositionsOnWaitingList } from "../../../store/recruitment/actions";
import { getLevels } from "../../../store/structure/actions";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";

import BackdropLoader from "../../common/components/BackdropLoader";
import WaitingListLeftSide from "../components/WaitingListLeftSide";
import axios from "axios";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import RequestPositionOnWaitingListDialog from "../components/RequestPositionOnWaitingListDialog";

const WaitingListPositions = (props) => {
  const { waitingListPositions, getPositionsOnWaitingList } = props;
  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const { hasPermissions } = useRequiredAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 15
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [filterLevel, setFilterLevel] = useState({
    id: "",
    name: "All",
  });

  const [levels, setLevels] = useState([
    {
      id: "",
      name: "All",
    },
  ]);

  const getLevelsOnWaitingList = async () => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/levels-on-waiting-list"
      );

      setLevels([
        {
          id: "",
          name: "All",
        },
        ...data,
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    getLevelsOnWaitingList();
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_WAITINGLIST_SUMMARY",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      level: filterLevel?.id || defaultIds,
    };

    getPositionsOnWaitingList(query, setTotalRequests, setIsLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      level: filterLevel?.id || defaultIds,
    };
    getPositionsOnWaitingList(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      level: filterLevel?.id || defaultIds,
    };
    getPositionsOnWaitingList(query, setTotalRequests, setIsLoading1);
  };

  const [showAddRequisitionForm, setShowAddRequisitionForm] = useState(false);

  return (
    <>
      <div className="row mx-0">
        <div className="col-2">
          <WaitingListLeftSide />
        </div>
        <div className="col-10 mt-2">
          <div className="pr-3">
            <AppBar
              position="static"
              elevation={0}
              style={{ overflow: "hidden" }}
              className="app-bar w-100"
            >
              <Toolbar className="row">
                <div className="col-lg-7">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="text-uppercase"
                  >
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      {searchTerm
                        ? "Filtered Positions on waiting list"
                        : "Positions on waiting list"}
                      ({totalRequests})
                    </span>
                    {hasPermissions(["IS_HR"]) && (
                      <Button
                        onClick={() => setShowAddRequisitionForm(true)}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2 "
                        style={{
                          borderRadius: "8px",
                          height: "38px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          marginLeft: "5px",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                      >
                        <span className="material-icons">add</span>Request
                      </Button>
                    )}
                  </Typography>
                </div>

                <div className="col-lg-5">
                  <div className="d-flex justify-content-end  align-items-end">
                    <Autocomplete
                      sx={{ width: 400 }}
                      className="mr-2"
                      size="small"
                      id="filterLevel"
                      defaultValue={null}
                      value={filterLevel || null}
                      options={levels}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, filterLevel) => {
                        const query = {
                          searchTerm: "",
                          page: currentPage,
                          size: itemPerPage,
                          level: filterLevel?.id || defaultIds,
                        };
                        setFilterLevel(filterLevel);
                        getPositionsOnWaitingList(
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterLevel) => (
                        <Box component="li" {...props} key={filterLevel.id}>
                          {filterLevel.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            width: "100%",
                            height: "35px!important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By Level"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <div className="d-flex justify-content-end  align-items-end">
                      <SearchBox
                        onKeyPress={() => onSearch()}
                        onSearch={(search) => {
                          setSearchTerm(search);

                          if (!search) {
                            const currentPage = 1;
                            setCurrentPage(currentPage);
                            const query = {
                              searchTerm: "",
                              page: currentPage,
                              size: itemPerPage,
                              level: filterLevel?.id || defaultIds,
                            };
                            getPositionsOnWaitingList(
                              query,
                              setTotalRequests,
                              setIsLoading1
                            );
                          }
                        }}
                        placeholder="Search…"
                      />
                      <Button
                        disabled={!searchTerm}
                        onClick={() => onSearch()}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2 "
                        style={{
                          borderRadius: "8px",
                          height: "38px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          marginLeft: "5px",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                      >
                        <span className="material-icons">search</span>GO
                      </Button>
                    </div>
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left py-2">
                    Position
                  </th>
                  <th scope="col" className="text-right">
                    Level
                  </th>

                  <th scope="col" className="text-right">
                    Available
                  </th>
                  <th scope="col" className="text-right">
                    Remaining
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <TableSkeleton cols={5} />
              ) : (
                <tbody>
                  <BackdropLoader isLoading={isLoading1} />
                  {waitingListPositions.map((waitingListPosition, index) => (
                    <WaitingListPositionCard
                      key={waitingListPosition.no}
                      index={index}
                      waitingListPosition={waitingListPosition}
                    />
                  ))}
                </tbody>
              )}
            </table>
            <NoRecordMessage
              isEmpty={!waitingListPositions.length && !isLoading}
              title={"No record found"}
            />
            {!isLoading && (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    // marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRequests / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                {!!waitingListPositions.length && (
                  <select
                    className="custom-select custom-select-sm ml-2"
                    style={{ width: "100px" }}
                    value={itemPerPage}
                    onChange={(event) => {
                      const itemPerPage = +event.target.value;
                      window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                      setItemPerPage(itemPerPage);
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                        level: filterLevel?.id || defaultIds,
                      };
                      getPositionsOnWaitingList(
                        query,
                        setTotalRequests,
                        setIsLoading
                      );
                    }}
                  >
                    {[10, 15, 20, 50, 100].map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {showAddRequisitionForm && (
        <RequestPositionOnWaitingListDialog
          showAddRequisitionForm={showAddRequisitionForm}
          setShowAddRequisitionForm={setShowAddRequisitionForm}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, waitingListPositions, levels }) => {
  return { user, loading, waitingListPositions, levels };
};
export default connect(mapStateToProps, {
  getPositionsOnWaitingList,
  getLevels,
})(WaitingListPositions);

const WaitingListPositionCard = (props) => {
  const { waitingListPosition, index } = props;

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {waitingListPosition.no}
        </th>
        <td className="text-left"> {waitingListPosition.positionName}</td>
        <td className="text-right"> {waitingListPosition.level}</td>
        <td className="text-right">
          {waitingListPosition.requested && (
            <span
              className="badge badge-primary p-1 mr-1"
              style={{ width: "64px" }}
            >
              {waitingListPosition.requested} Requested
            </span>
          )}
          {waitingListPosition.number}
        </td>
        <td className="text-right">
          <span
            className={`badge badge-${
              waitingListPosition.number - waitingListPosition.requested > 0
                ? "secondary"
                : "danger"
            } p-1`}
          >
            {waitingListPosition.number - waitingListPosition.requested}
          </span>
        </td>
      </tr>
    </>
  );
};
