import React, { useState, useEffect } from "react";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Badge, Menu, MenuItem, Divider } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
  CardContent,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import { getWaitingListJobOffers } from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";

import GroupIcon from "@mui/icons-material/Group";
import ApplicationCv from "../../common/components/ApplicationCv";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import moment from "moment";
import WidgetViewContentDialog from "../../common/components/WidgetViewContentDialog";
import EmployCandidateDialog from "../components/EmployCandidateDialog";
import UserResume from "../../profile/UserResume";

const WaitingListJobOfferDialog = (props) => {
  const {
    user,
    loading,
    waitingListJobOffers,
    getWaitingListJobOffers,
    showWaitingListJobOffer,
    setShowWaitingListJobOffer,
    waitingListRequisition,
    getUserDetails
  } = props;
  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();
  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);

  const [filterStatus, setFilterStatus] = useState({ id: "", name: "All" });

  useEffect(() => {
    dispatch({
      type: "SET_WAITINGLIST_JOB_OFFERS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      requestId: waitingListRequisition.id,
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getWaitingListJobOffers(query, setTotalRequests, setIsLoading);
  }, []);
  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      requestId: waitingListRequisition.id,
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getWaitingListJobOffers(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      requestId: waitingListRequisition.id,
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getWaitingListJobOffers(query, setTotalRequests, setIsLoading1);
  };

  const onClose = () => {
    setShowWaitingListJobOffer(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showWaitingListJobOffer}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">RECRUITMENT</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "6px" }}
              >
                chevron_right
              </span>
            </span>
            <span style={{ color: "rgb(7, 142, 206)" }}>
              Offer From Waiting List
            </span>
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "6px" }}
            >
              chevron_right
            </span>
            <span className="text-success">
              {waitingListRequisition && waitingListRequisition.positionName}
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "650px" }}>
          <Box sx={{ display: "flex", width: "100%" }}>
            {/*  */}

            <div
              className="w-100"
              style={{
                height: "500px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    // padding: ".6em",
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-5 mb-2">
                      <Typography variant="h6" noWrap component="div">
                        <span style={{ color: "#2a7790" }}>
                          <GroupIcon className="text-dark mr-2" />
                        </span>
                        <span style={{ color: "rgb(7, 142, 206)" }}>
                          List of{" "}
                          {searchTerm
                            ? "Filtered"
                            : filterStatus
                            ? filterStatus.name
                            : ""}{" "}
                          Candidates ({totalRequests})
                        </span>
                      </Typography>
                    </div>
                    <div className="col-lg-7">
                      <div className="d-flex justify-content-right  align-items-right">
                        <Autocomplete
                          className="mr-2"
                          sx={{ width: 400 }}
                          size="small"
                          id="filterStatus"
                          defaultValue={null}
                          value={filterStatus || null}
                          options={[
                            { id: "1", name: "Active" },
                            { id: "2", name: "Expired" },
                            { id: "3", name: "Offer pending" },
                            { id: "4", name: "Offer accepted" },
                            { id: "5", name: "Offer rejected" },
                            { id: "6", name: "Employed" },
                            { id: "", name: "All" },
                          ]}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, currentStatus) => {
                            const query = {
                              requestId: waitingListRequisition.id,
                              searchTerm: "",
                              currentStatus: currentStatus?.id || defaultIds,
                              page: currentPage,
                              size: itemPerPage,
                            };
                            setFilterStatus(currentStatus);
                            getWaitingListJobOffers(
                              query,
                              setTotalRequests,
                              setIsLoading1
                            );
                          }}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, filterStatus) => (
                            <Box
                              component="li"
                              {...props}
                              key={filterStatus.id}
                            >
                              {filterStatus.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                width: "200px",
                                height: "35px!important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px!important",
                              }}
                              {...params}
                              label="Filter By"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "filterStatus", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <SearchBox
                          onSearch={(search) => {
                            setSearchTerm(search);

                            if (!search) {
                              const currentPage = 1;
                              setCurrentPage(currentPage);
                              const query = {
                                requestId: waitingListRequisition.id,
                                searchTerm: "",
                                page: currentPage,
                                currentStatus: filterStatus?.id || defaultIds,
                                size: itemPerPage,
                              };
                              getWaitingListJobOffers(
                                query,
                                setTotalRequests,
                                setIsLoading1
                              );
                            }
                          }}
                          placeholder="Search…"
                        />
                        <Button
                          disabled={!searchTerm}
                          onClick={() => onSearch()}
                          variant="contained"
                          size="small"
                          disableElevation
                          className="mr-2 "
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                            border: `1px solid rgb(7, 142, 206)`,
                            color: "#fff",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                            backgroundColor: "rgb(7, 142, 206)",
                            marginLeft: "5px",
                            "&:hover": {
                              backgroundColor: "#f0f2f5",
                            },
                          }}
                        >
                          <span className="material-icons">search</span>GO
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div
                className="mt-0"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col">UserId</th>
                        <th scope="col" className="text-left">
                          Names
                        </th>

                        <th scope="col" className="text-left">
                          NationalID
                        </th>
                        <th scope="col" className="text-left">
                          Email
                        </th>

                        <th scope="col" className="text-left">
                          PhoneNumber
                        </th>
                        <th scope="col" className="text-left">
                          PositionOfApplication
                        </th>

                        <th scope="col" className="text-left">
                          InstitutionOfApplication
                        </th>
                        <th scope="col" className="text-left">
                          PositionRequested
                        </th>

                        <th scope="col" className="text-left">
                          WrittenExamScore
                        </th>

                        <th scope="col" className="text-left">
                          OralExamScore
                        </th>
                        <th scope="col" className="text-left">
                          TotalScore
                        </th>
                        <th scope="col" className="text-left">
                          WaitingFrom
                        </th>
                        <th scope="col" className="text-left">
                          Status
                        </th>
                        <th scope="col" className="text-center"></th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <TableSkeleton cols={13} />
                    ) : (
                      <tbody>
                        <BackdropLoader isLoading={isLoading1} />
                        {waitingListJobOffers.map(
                          (waitingListJobOffer, index) => (
                            <WaitingListJobOfferCard
                              waitingListJobOffer={waitingListJobOffer}
                              setShowApplicationCv={setShowApplicationCv}
                              setSelectedApplication={setSelectedApplication}
                              index={index}
                              key={waitingListJobOffer.id}
                              getUserDetails={getUserDetails}
                              loading={loading}
                            />
                          )
                        )}
                      </tbody>
                    )}
                  </table>

                  <NoRecordMessage
                    isEmpty={!waitingListJobOffers.length && !isLoading}
                    title={"No record found"}
                  />
                </CardContent>
              </div>

              {showApplicationCv && (
                <ApplicationCv
                  showApplicationCv={showApplicationCv}
                  setShowApplicationCv={setShowApplicationCv}
              
                  application={{
                    id: selectedApplication.applicantId,
                    idNumber: selectedApplication.idNumber2,
                    phoneNumber: selectedApplication.phoneNumber,
                    userId: selectedApplication.userId,
                    lastName: selectedApplication.lastName,
                    firstName: selectedApplication.firstName,
                    email: selectedApplication.email,
                  }}
                />
              )}
            </div>
          </Box>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-2">
          {!isLoading && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRequests / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!waitingListJobOffers.length && (
                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      currentStatus: filterStatus?.id || defaultIds,
                    };
                    getWaitingListJobOffers(
                      waitingListRequisition.id,
                      query,
                      setTotalRequests,
                      setIsLoading
                    );
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, waitingListJobOffers }) => {
  return { user, loading, waitingListJobOffers };
};

export default connect(mapStateToProps, {
  getWaitingListJobOffers,getUserDetails
})(WaitingListJobOfferDialog);

const WaitingListJobOfferCard = (props) => {
  const {
    waitingListJobOffer,
    index,
    setShowApplicationCv,
    setSelectedApplication,
    loading,
    getUserDetails
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [confirmToEmploy, setConfirmToEmploy] = useState(false);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <th className="text-left">{waitingListJobOffer.userId}</th>
        <td className="text-left"> {waitingListJobOffer.names}</td>
        <td className="text-left"> {waitingListJobOffer.idNumber}</td>
        <td className="text-left">{waitingListJobOffer.email}</td>
        <td className="text-left">{waitingListJobOffer.phoneNumber}</td>
        <td className="text-left">{waitingListJobOffer.applicationPosition}</td>
        <td className="text-left">{waitingListJobOffer.applicationEntity}</td>
        <td className="text-left">{waitingListJobOffer.requestedPositionName}</td>
        <td className="text-left">{waitingListJobOffer.writtenScore}</td>
        <td className="text-left">{waitingListJobOffer.oralScore}</td>
        <td className="text-left">{waitingListJobOffer.totalScore}</td>
        <td className="text-left">
          {moment(waitingListJobOffer.waitingFrom).format("lll")}
        </td>

        <td className="text-left">
          <span
            className={`badge badge-${
              waitingListJobOffer.statusId === 1
                ? "warning"
                : waitingListJobOffer.statusId === 2
                ? "primary"
                : waitingListJobOffer.statusId === 5
                ? "success"
                : "danger"
            } `}
          >
            {waitingListJobOffer.status}
          </span>
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: 400 * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedApplication(waitingListJobOffer);
                  setShowApplicationCv(true);
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Application Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <MenuItem
              disabled={loading}
              onClick={() => {
                getUserDetails(
                  waitingListJobOffer.userId,
                  handleOpenCurrentProfile
                );
              }}
              className="text-dark font-weight-light"
            >
              <BadgeIcon className="mr-2 text-primary" />
              {loading ? "Wait...." : "Currently Profile"}
            </MenuItem>
            <Divider className="my-1" />
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setOpenDialog(true);
              }}
              className="text-dark font-weight-light"
            >
              <WorkOutlineIcon className="mr-2 text-primary" />
              View Job Offer
            </MenuItem>
            {waitingListJobOffer.statusId === 2 && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setConfirmToEmploy(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <PersonAddAlt1Icon className="mr-2 text-success" />
                  Employ
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>
      {openDialog && (
        <WidgetViewContentDialog
          openDialog={openDialog}
          content={
            <>
              <table>
                <tbody>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {waitingListJobOffer.isJobOfferExpired ? (
                        <span className="px-1 py-1 badge badge-warning">
                          Offer expired
                        </span>
                      ) : waitingListJobOffer.isJobOfferRequested ? (
                        <span
                          className={
                            waitingListJobOffer.jobOfferStatusId === 6
                              ? "px-1 py-1 badge badge-secondary"
                              : waitingListJobOffer.jobOfferStatusId === 5
                              ? "px-1 py-1 badge badge-success"
                              : waitingListJobOffer.jobOfferStatusId === 1
                              ? "px-1 py-1 badge badge-secondary"
                              : waitingListJobOffer.jobOfferStatusId === 2
                              ? "px-1 py-1 badge badge-primary"
                              : "px-1 py-1 badge badge-danger"
                          }
                        >
                          {waitingListJobOffer.jobOfferStatusName}
                        </span>
                      ) : (
                        <span
                          className={
                            waitingListJobOffer.totalScore >= 70
                              ? "px-1 py-1 text-success"
                              : waitingListJobOffer.totalScore >= 50
                              ? "px-1 py-1 text-primary"
                              : "px-1 py-1 text-danger"
                          }
                        >
                          {waitingListJobOffer.status}
                        </span>
                      )}
                    </td>
                  </tr>
                  {waitingListJobOffer.jobOfferExpiredOn && (
                    <tr>
                      <th scope="row">Expiration Date</th>
                      <td>
                        {moment(waitingListJobOffer.jobOfferExpiredOn).format(
                          "LLL"
                        )}
                      </td>
                    </tr>
                  )}

                  {waitingListJobOffer.offeredOn && (
                    <tr>
                      <th scope="row">Requested On</th>
                      <td>
                        {moment(waitingListJobOffer.offeredOn).format("LLL")}
                      </td>
                    </tr>
                  )}
                  {waitingListJobOffer.offeredBy && (
                    <tr>
                      <th scope="row">Requested By</th>
                      <td>{waitingListJobOffer.offeredBy}</td>
                    </tr>
                  )}

                  {waitingListJobOffer.revokedOn && (
                    <tr>
                      <th scope="row">Revoked On</th>
                      <td>
                        {moment(waitingListJobOffer.revokedOn).format("LLL")}
                      </td>
                    </tr>
                  )}

                  {waitingListJobOffer.revokedEmployee && (
                    <tr>
                      <th scope="row">
                        {waitingListJobOffer.jobOfferStatusId === 6
                          ? "SkippedBy"
                          : "RevokedBy"}{" "}
                      </th>
                      <td>{waitingListJobOffer.revokedEmployee}</td>
                    </tr>
                  )}

                  {waitingListJobOffer.candidateRespondedOn && (
                    <tr>
                      <th scope="row">Candidate Responded On</th>
                      <td>
                        {moment(
                          waitingListJobOffer.candidateRespondedOn
                        ).format("LLL")}
                      </td>
                    </tr>
                  )}

                  {waitingListJobOffer.rejectionReason && (
                    <tr>
                      <th scope="row">RejectionReason</th>
                      <td>{waitingListJobOffer.rejectionReason}</td>
                    </tr>
                  )}
                  {waitingListJobOffer.revokedReason && (
                    <tr>
                      <th scope="row">
                        {waitingListJobOffer.jobOfferStatusId === 6
                          ? "SkippedReason"
                          : "RevokedReason"}{" "}
                      </th>
                      <td>{waitingListJobOffer.revokedReason}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          }
          title={<>View Job Offer of {waitingListJobOffer.names}</>}
          setOpenDialog={setOpenDialog}
        />
      )}
      {showCurrentProfile && (
        <UserResume
          showProfileDialog={showCurrentProfile}
          setShowProfileDialog={setShowCurrentProfile}
        />
      )}
      {confirmToEmploy && (
        <EmployCandidateDialog
          showEmployeeForm={confirmToEmploy}
          setShowEmployeeForm={setConfirmToEmploy}
          application={waitingListJobOffer}
          isFromWaitingList={true}
        />
      )}
    </>
  );
};
