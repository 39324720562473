import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { saveOralTopupScore } from "../../../store/recruitment/actions";
import { showError } from "../../toastify";
import PaperDraggableComponent from "../../common/components/PaperDraggableComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import PercentIcon from "@mui/icons-material/Percent";
const OralTopupScoreDialog = (props) => {
  const {
    loading,
    showTopupScore,
    setShowTopupScore,
    oralExamMark,
    saveOralTopupScore,
  } = props;

  const [formData, setFormData] = useState({
    oralExamMarkId: oralExamMark.oralExamMarkId,
    topupScore:
      oralExamMark.topupScore === 0 ? 0 : oralExamMark.topupScore,
    topupReason: oralExamMark.examinerTopupReason,
    examId: oralExamMark.examId,
    applicantExamSessionId: oralExamMark.id
  });

  const [errors, setErrors] = useState({
    topupScoreHasError: false,
    topupScore2HasError: false,
    topupScore3HasError: false,
    topupReasonHasError: false,
  });
  const [total, setTotal] = useState(
    oralExamMark.examinerTotalScore + oralExamMark.topupScore
  );

  const formValidator = () => {
    const error = {
      topupScoreHasError: false,
      topupScore2HasError: false,
      topupReasonHasError: false,
      hasError: false,
      topupScore3HasError: false,
    };

    if (!formData.topupScore || formData.topupScore === 0) {
      error.topupScoreHasError = true;
      error.hasError = true;
    }

    if (total && total < 0) {
      error.topupScore3HasError = true;
      error.hasError = true;
    }

    if (total && total > oralExamMark.totalMarks) {
      error.topupScore2HasError = true;
      error.hasError = true;
    }

    if (!formData.topupReason) {
      error.topupReasonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveOralTopupScore(formData, onClose);
    }
  };

  const onClose = () => {
    setShowTopupScore(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperDraggableComponent}
        onClose={onClose}
        open={showTopupScore}
        // fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          className="text-primary"
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Typography variant="overline" display="block">
            Topup Score / {oralExamMark.firstName} {oralExamMark.lastName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div className="row">
            <div className="col-lg-12 mt-2">
              <TextField
                // disabled={oralExamMark.topupScore !== 0}
                fullWidth
                size="small"
                name="topupScore"
                autoFocus
                label="Topup Score"
                variant="outlined"
                placeholder="Topup Score"
                type="number"
                value={formData.topupScore}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const topupScore = e.target.value;
                  const t = topupScore ? topupScore : 0;
                  const total = oralExamMark.examinerTotalScore + parseFloat(t);
                  setTotal(total);
                  setFormData({
                    ...formData,
                    topupScore,
                  });
                  let topupScore2HasError = false;
                  let topupScore3HasError = false;
                  if (total < 0) {
                    //topupScore2HasError
                    topupScore3HasError = true;
                  }
                  if (total > oralExamMark.totalMarks) {
                    //topupScore2HasError
                    topupScore2HasError = true;
                  }
                  const topupScoreHasError = false;
                  setErrors({
                    ...errors,
                    topupScoreHasError,
                    topupScore2HasError,
                    topupScore3HasError,
                  });
                }}
              />
              <ErrorMessage
                hasError={errors.topupScoreHasError}
                message="Topup score is required"
              />
              <ErrorMessage
                hasError={errors.topupScore3HasError}
                message="Topup score must be great or equal to zero"
              />
              <ErrorMessage
                hasError={errors.topupScore2HasError}
                message={`Topup score must be less or equal to ${oralExamMark.totalMarks}`}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <TextField
                // disabled={oralExamMark.topupScore !== 0}
                fullWidth
                multiline
                rows={2}
                size="small"
                name="topupReason"
                autoFocus
                label="Topup Reason"
                variant="outlined"
                placeholder="Topup Reason"
                value={formData.topupReason}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const topupReason = e.target.value;
                  setFormData({
                    ...formData,
                    topupReason,
                  });
                  const topupReasonHasError = false;
                  setErrors({ ...errors, topupReasonHasError });
                }}
              />

              <ErrorMessage
                hasError={errors.topupReasonHasError}
                message="Topup reason is required"
              />
            </div>

            {/* <div className="col-6">
              <div className="input-group input-group-sm mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Scored
                  </span>
                </div>
                <div className="form-control">
                  <div className="form-check form-check-inline">
                    <div
                      style={{
                        padding: "0px",
                        marginTop: "-10px",
                        width: "70px",
                        height: "auto",

                        borderRadius: "50px",
                        textAlign: "center",
                      }}
                    >
                      {oralExamMark.examinerTotalScore}
                      {oralExamMark.totalMarks === 50 ? (
                        <>/50</>
                      ) : (
                        <PercentIcon style={{ fontSize: "13px" }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="input-group input-group-sm mt-2">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Total Score
                  </span>
                </div>
                <div className="form-control">
                  <div className="form-check form-check-inline">
                    <div
                      style={{
                        padding: "0px",
                        marginTop: "-10px",
                        width: "70px",
                        height: "auto",

                        borderRadius: "50px",
                        textAlign: "center",
                      }}
                    >
                      {total}
                      {oralExamMark.totalMarks === 50 ? (
                        <>/50</>
                      ) : (
                        <PercentIcon style={{ fontSize: "13px" }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* {oralExamMark.topupScore === 0 && ( */}
              <div className="col-lg-12 mt-3">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase float-right"
                  disabled={loading}
                >
                  {loading ? "Wait..." : "Save"}
                </button>
              </div>
            {/* )} */}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  saveOralTopupScore,
})(OralTopupScoreDialog);
