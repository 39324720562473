import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Alert,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  approveNPSCReport,
  rejectNPSCReport,
  verifyNPSCReport,
  getNPSCReport,
} from "../../../store/recruitment/actions";
import { showError } from "../../toastify";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AdvertisementReport from "./AdvertisementReport";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";
import SubmitReportDialog from "./SubmitReportDialog";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ApproveNPSCSubmissionLetterDialog from "./ApproveNPSCSubmissionLetterDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ApproveNPSCRespondLetterDialog from "./ApproveNPSCRespondLetterDialog";
import ModifyNPSCReportRespondingLetterDialog from "./ModifyNPSCReportRespondingLetterDialog";
const ApproveNPSCReportDialog = (props) => {
  const {
    loading,
    approveRequestForm,
    setApproveRequestForm,
    npscReport,
    getRequestDocumentSignerDetails,
    requestDocumentSignerDetail,
    getNPSCReport,
    user,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showSubmissionReport, setShowSubmissionReport] = useState(false);
  const [showResponseReportLetter, setShowResponseReportLetter] =
    useState(false);
  const [npscReportDetail, setNPSCReportDetail] = useState(
    npscReport?.details || null
  );

  useEffect(() => {
    if (!requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  const [maxWidth, setMaxWidth] = useState("lg");
  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onViewDocument2, setOnViewDocument2] = useState(false);
  const [showApproveRequestForm, setShowApproveRequestForm] = useState(false);
  const [showNPSCApproveRequestForm, setShowNPSCApproveRequestForm] =
    useState(false);
  const onClose = () => {
    setApproveRequestForm(false);
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={approveRequestForm}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <Typography
            variant="overline"
            display="block"
            className="font-weight-bold"
          >
            <span>NPSC Report</span> /{" "}
            <span className="text-primary mr-2">
              {npscReportDetail?.positionName || ""}
            </span>
            <span
              style={{ fontSize: "12px" }}
              className={`${
                +npscReportDetail.statusId === 7
                  ? "badge badge-success w-100"
                  : [2, 4].includes(+npscReportDetail.statusId)
                  ? "badge badge-primary w-100"
                  : [5, 6].includes(+npscReportDetail.statusId)
                  ? "badge badge-danger w-100"
                  : [1, 3].includes(+npscReportDetail.statusId)
                  ? "badge badge-secondary w-100"
                  : "badge badge-info w-100"
              }`}
            >
              {npscReportDetail.statusName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="mt-0 mx-0" dividers>
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <AdvertisementReport
              details={{
                ...npscReportDetail,
                npscReportId: +npscReportDetail.id,
              }}
              canApproveCandidates={[3, 4].includes(+npscReportDetail.statusId)}
            />
          </Box>
        </DialogContent>
        <DialogActions className="py-2">
          <div className="row w-100">
            <div className="col-12 ">
              <Card
                className={`mb-2 w-100 rounded hoverable-card px-3`}
                elevation={0}
                variant="outlined"
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                }}
              >
                <CardHeader
                  avatar={
                    <span
                      className={
                        !!npscReportDetail?.docReferenceId
                          ? "font-weight-bold text-success"
                          : "font-weight-bold text-primary"
                      }
                    >
                      <TextSnippetIcon />
                    </span>
                  }
                  title={
                    <Typography>
                      <strong
                        className={
                          !!npscReportDetail?.docReferenceId
                            ? "text-success"
                            : ""
                        }
                      >
                        Submission of Recruitment Report
                      </strong>
                    </Typography>
                  }
                  subheader={
                    <>
                      {[5].includes(+npscReportDetail.statusId) && (
                        <>
                          <div className="d-flex justify-content-start py-2">
                            <Alert severity="error">
                              <strong>Reason for rejection:</strong>{" "}
                              {npscReportDetail.comments}
                            </Alert>
                          </div>
                        </>
                      )}
                    </>
                  }
                  action={
                    <>
                      <div
                        className="d-flex flex-row align-items-center pull-right"
                        style={{ borderRadius: "4px" }}
                      >
                        {!!npscReportDetail?.docReferenceId && (
                          <IconButton
                            // disableRipple
                            // className="ml-n2"
                            size="small"
                            onClick={() => {
                              setOnViewDocument(true);
                            }}
                          >
                            <FilePresentIcon
                              // fontSize="large"
                              style={{
                                color: !!npscReportDetail.docReferenceId
                                  ? "#fe4066"
                                  : "#c57c7cb8",
                              }}
                            />
                          </IconButton>
                        )}
                        {((!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                          +npscReportDetail?.statusId === 2) ||
                          (!!hasPermissions(["IS_HR"]) &&
                            +npscReportDetail?.statusId === 1)) && (
                          <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              getNPSCReport(
                                npscReportDetail.id,
                                setShowSubmissionReport,
                                () => {}
                              );
                            }}
                            className="btn btn-xs"
                          >
                            Edit Letter
                          </Button>
                        )}

                        {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                          +npscReportDetail?.statusId === 1 && (
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              disabled={loading}
                              onClick={() => {
                                setShowApproveRequestForm(true);
                              }}
                              className="btn btn-xs"
                            >
                              Verify
                            </Button>
                          )}
                        {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                          +npscReportDetail?.statusId === 2 && (
                            <Button
                              variant="contained"
                              color="primary"
                              type="button"
                              disabled={loading}
                              onClick={() => {
                                setShowApproveRequestForm(true);
                              }}
                              className="btn btn-xs"
                            >
                              Approve
                            </Button>
                          )}
                      </div>
                    </>
                  }
                />
              </Card>
            </div>
            {!!npscReportDetail?.docReferenceId && (
              <div className="col-12">
                <Divider />
                <Card
                  className={`mb-2 w-100 rounded hoverable-card px-3`}
                  elevation={0}
                  variant="outlined"
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                >
                  <CardHeader
                    avatar={
                      <span
                        className={
                          !!npscReportDetail?.npscDocReferenceId
                            ? "font-weight-bold text-success"
                            : "font-weight-bold text-primary"
                        }
                      >
                        <TextSnippetIcon />
                      </span>
                    }
                    title={
                      <Typography>
                        <strong
                          className={
                            !!npscReportDetail?.npscDocReferenceId
                              ? "text-success"
                              : ""
                          }
                        >
                          Response of Recruitment Report
                        </strong>
                      </Typography>
                    }
                    subheader={
                      <>
                        {[6].includes(+npscReportDetail.statusId) && (
                          <>
                            <div className="d-flex justify-content-start py-2">
                              <Alert severity="error">
                                <strong>Reason for rejection:</strong>{" "}
                                {npscReportDetail.comments}
                              </Alert>
                            </div>
                          </>
                        )}
                      </>
                    }
                    action={
                      <>
                        <div
                          className="d-flex flex-row align-items-center pull-right"
                          style={{ borderRadius: "4px" }}
                        >
                          {!!npscReportDetail?.npscDocReferenceId && (
                            <IconButton
                              // disableRipple
                              // className="ml-n2"
                              size="small"
                              onClick={() => {
                                setOnViewDocument2(true);
                              }}
                            >
                              <FilePresentIcon
                                // fontSize="large"
                                style={{
                                  color: !!npscReportDetail.npscDocReferenceId
                                    ? "#fe4066"
                                    : "#c57c7cb8",
                                }}
                              />
                            </IconButton>
                          )}
                          {!!hasPermissions(["IS_NPSC"]) &&
                            !hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                            +npscReportDetail?.statusId === 4 && (
                              <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={loading}
                                onClick={() => {
                                  getNPSCReport(
                                    npscReportDetail.id,
                                    setShowResponseReportLetter,
                                    () => {}
                                  );
                                }}
                                className="btn btn-xs"
                              >
                                Edit Letter
                              </Button>
                            )}

                          {!!hasPermissions(["IS_NPSC"]) &&
                            +npscReportDetail?.statusId === 3 && (
                              <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={loading}
                                onClick={() => {
                                  setShowNPSCApproveRequestForm(true);
                                }}
                                className="btn btn-xs"
                              >
                                Verify
                              </Button>
                            )}
                          {!!hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                            user?.selectedEntitySector?.id ===
                              "0302000000-060300000000000000-07" &&
                            +npscReportDetail?.statusId === 4 && (
                              <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={loading}
                                onClick={() => {
                                  setShowNPSCApproveRequestForm(true);
                                }}
                                className="btn btn-xs"
                              >
                                Approve
                              </Button>
                            )}
                        </div>
                      </>
                    }
                  />
                </Card>
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
      {showSubmissionReport && (
        <SubmitReportDialog
          showSubmissionReport={showSubmissionReport}
          setShowSubmissionReport={setShowSubmissionReport}
          advertisement={npscReportDetail}
          onCloseSubmitReport={onClose}
        />
      )}
      {showResponseReportLetter && (
        <ModifyNPSCReportRespondingLetterDialog
          showSubmissionReport={showResponseReportLetter}
          setShowSubmissionReport={setShowResponseReportLetter}
          advertisement={npscReportDetail}
          onCloseSubmitReport={onClose}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Submission of Recruitment Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              npscReportDetail?.docReferenceId,
          }}
        />
      )}
      {onViewDocument2 && (
        <PreviewPdfUrl
          showDocument={onViewDocument2}
          setShowDocument={() => setOnViewDocument2(false)}
          document={{
            name: `Response of Recruitment Letter`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              npscReportDetail?.npscDocReferenceId,
          }}
        />
      )}
      {showApproveRequestForm && (
        <ApproveNPSCSubmissionLetterDialog
          approveRequestForm={showApproveRequestForm}
          setApproveRequestForm={setShowApproveRequestForm}
          onCloseReport={onClose}
        />
      )}
      {showNPSCApproveRequestForm && (
        <ApproveNPSCRespondLetterDialog
          approveRequestForm={showNPSCApproveRequestForm}
          setApproveRequestForm={setShowNPSCApproveRequestForm}
          onCloseReport={onClose}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  npscReport,
  requestDocumentSignerDetail,
  user,
}) => {
  return {
    loading,
    npscReport,
    requestDocumentSignerDetail,
    user,
  };
};
export default connect(mapStateToProps, {
  approveNPSCReport,
  rejectNPSCReport,
  verifyNPSCReport,
  getRequestDocumentSignerDetails,
  getNPSCReport,
})(ApproveNPSCReportDialog);
