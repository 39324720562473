import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { connect, useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import {
  getOversightTrainingRequests,
  getSingleTrainingRequest,
} from "../../../store/training/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import ViewTrainingRequestDialog from "../components/ViewTrainingRequestDialog";

import RequestCard from "../components/RequestCard";
import Dashboard from "../components/Dashboard";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const OverSightRequests = (props) => {
  const {
    user,
    oversightTrainingRequests,
    getOversightTrainingRequests,
    getSingleTrainingRequest,
    env
  } = props;
  useEffect(() => {
    document.title = `IPPIS - Training(${totalRequests}) | Oversight Requests`;
  });
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [showTrainingRequestDetails, setShowTrainingRequestDetails] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentStatus, setCurrentStatus] = useState({
    id: 0,
    name: "In Progress",
  });

  const theme = useTheme();

  useEffect(() => {
    dispatch({
      type: "SET_OVERSIGHT_TRAINING_REQUESTS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: currentStatus?.id || 0,
    };

    getOversightTrainingRequests(query, setTotalRequests, setLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: currentStatus?.id || 0,
    };
    getOversightTrainingRequests(query, setTotalRequests, setLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: currentStatus?.id || 0,
      page: currentPage,
      size: itemPerPage,
    };
    getOversightTrainingRequests(query, setTotalRequests, setLoading1);
  };
  const [expanded, setExpanded] = useState("panel2");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-4">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>TRAINING</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    <span style={{ color: "rgb(7, 142, 206)" }}>DASHBOARD</span>
                  </span>
                </Typography>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <Dashboard />

            <CardContent
              className="py-5 px-5 mx-4"
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <div className="px-2 py-2 ">
                {/* <Typography>({totalRequests}) {currentStatus.name} Training Requests</Typography> */}
                <div className="d-flex justify-content-right  align-items-right">
                  <Autocomplete
                    sx={{ width: 300 }}
                    className="mr-2"
                    size="small"
                    disablePortal
                    defaultValue={null}
                    value={currentStatus}
                    options={[
                      { id: 0, name: "In Progress" },
                      { id: 1, name: "Approved" },
                      { id: 2, name: "Rejected" },
                      { id: 3, name: "Overdue" },
                      { id: 4, name: "All" },
                    ]}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, currentStatus) => {
                      const query = {
                        searchTerm: "",
                        currentStatus: currentStatus?.id || 0,
                        page: currentPage,
                        size: itemPerPage,
                      };
                      setCurrentStatus(currentStatus);
                      getOversightTrainingRequests(
                        query,
                        setTotalRequests,
                        setLoading1
                      );
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Status"
                        placeholder="Status"
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.name}
                      </Box>
                    )}
                  />

                  <SearchBox
                    onSearch={(search) => {
                      setSearchTerm(search);

                      if (!search) {
                        const currentPage = 1;
                        setCurrentPage(currentPage);
                        const query = {
                          searchTerm: "",
                          page: currentPage,
                          currentStatus: currentStatus?.id || 0,
                          size: itemPerPage,
                        };
                        getOversightTrainingRequests(
                          query,
                          setTotalRequests,
                          setLoading1
                        );
                      }
                    }}
                    placeholder="Search…"
                  />
                  <Button
                    disabled={!searchTerm}
                    onClick={() => onSearch()}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2 "
                    style={{
                      borderRadius: "8px",
                      height: "38px",
                      border: `1px solid rgb(7, 142, 206)`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "rgb(7, 142, 206)",
                      marginLeft: "5px",
                      "&:hover": {
                        backgroundColor: "#f0f2f5",
                      },
                    }}
                  >
                    <span className="material-icons">search</span>GO
                  </Button>
                </div>
              </div>
              {showTrainingRequestDetails && (
                <ViewTrainingRequestDialog
                  showTrainingRequestDetails={showTrainingRequestDetails}
                  setShowTrainingRequestDetails={setShowTrainingRequestDetails}
                />
              )}
              <table className="table-striped table-hover table-sm  fixTableHead mt-2">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      No
                    </th>
                    <th scope="col" className="text-left">
                      EmployeeName
                    </th>
                    {/* <th scope="col" className="text-left">
                      TrainingTitle
                    </th> */}

                    <th scope="col" className="text-left">
                      Institution
                    </th>

                    <th scope="col" className="text-left">
                      Position
                    </th>

                    <th scope="col" className="text-left">
                      RequestType
                    </th>
                    <th scope="col" className="text-right">
                      RequestedAt
                    </th>
                    <th scope="col" className="text-right">
                      DepartureDate
                    </th>

                    <th scope="col" className="text-right">
                      Status
                    </th>

                    <th scope="col" className="text-right">
                      <span className="mr-3">Actions</span>
                    </th>
                  </tr>
                </thead>

                {loading ? (
                  <TableSkeleton cols={9} />
                ) : (
                  <tbody>
                    {oversightTrainingRequests.map((request, index) => (
                      <RequestCard
                        request={request}
                        index={index}
                        key={index}
                        setLoading1={setLoading1}
                        loading1={loading1}
                        setShowTrainingRequestDetails={
                          setShowTrainingRequestDetails
                        }
                        env={env}
                        getSingleTrainingRequest={getSingleTrainingRequest}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              {!loading && (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ overflow: "hidden" }}
                >
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      // marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalRequests / itemPerPage)}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                      activeLinkClassName="active"
                    />
                  </div>

                  {!!oversightTrainingRequests.length && (
                    <select
                      className="custom-select custom-select-sm ml-2"
                      style={{ width: "100px" }}
                      value={itemPerPage}
                      onChange={(event) => {
                        const itemPerPage = +event.target.value;
                        window.localStorage.setItem(
                          "ITEM_PER_PAGE",
                          itemPerPage
                        );
                        setItemPerPage(itemPerPage);
                        const query = {
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                          currentStatus: currentStatus?.id || 0,
                        };
                        getOversightTrainingRequests(
                          query,
                          setTotalRequests,
                          setLoading
                        );
                      }}
                    >
                      {[10, 50, 100].map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                  )}
                </div>
              )}

              <NoRecordMessage
                isEmpty={!oversightTrainingRequests.length && !loading}
                title="No Results found"
              />
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, oversightTrainingRequests,env }) => {
  return { user, loading, oversightTrainingRequests,env };
};
export default connect(mapStateToProps, {
  getOversightTrainingRequests,
  getSingleTrainingRequest,
})(OverSightRequests);
