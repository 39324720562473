import {
  Tooltip,
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { deleteIndicator, approveIndicator } from "../../../store/rbm/actions";
import ActivityCard from "./ActivityCard";
import ActivityForm from "./ActivityForm";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const QuarterCard = (props) => {
  const location = useLocation();

  const {
    quarter,
    selectedExpectedResult,
    selectedIndicator,
    totalWeight,
    selectedFiscalYear,
    user,
    isForContract,
    setSelectedItem,
    isForMonitoring,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);

  const [totalProgress, setTotalProgress] = useState(0);
  const [totalQuarterTarget, setTotalQuarterTarget] = useState(0);
  const [achievedTarget, setAchievedTarget] = useState(0);

  useEffect(() => {
    let weight = 0,
      score = 0,
      achievedTarget = 0,
      quarterTarget = 0;
    quarter.activities.forEach((activity) => {
      weight += +(activity.weight || 0);
      quarterTarget += +(activity.targetShare || 0);
      achievedTarget += +activity.achievedTarget;
      score += (+(activity.score || 0) * +(activity.weight || 0)) / 100;
    });

    setTotalProgress((score * 100) / (weight || 1));
    setAchievedTarget(achievedTarget);
    setTotalQuarterTarget(quarterTarget.toFixed(2));
    paginate(quarter.activities);
  }, [quarter, quarter.activities]);

  //Filter
  const [searchTeam, setSearchTeam] = useState("");

  const [filteredActivities, setFilteredActivities] = useState([
    ...quarter.activities,
  ]);

  useEffect(() => {
    const filtered = [...quarter.activities].filter(
      ({ name, unitName, assignments }) =>
        (name || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (!!unitName &&
          (unitName || "").toLowerCase().includes(searchTeam.toLowerCase())) ||
        (assignments || []).findIndex(
          ({ firstName, lastName }) =>
            (!!firstName &&
              firstName.toLowerCase().includes(searchTeam.toLowerCase())) ||
            (lastName &&
              lastName.toLowerCase().includes(searchTeam.toLowerCase()))
        ) >= 0
    );

    setFilteredActivities(filtered);
    paginate(filtered);
  }, [searchTeam, quarter.activities]);

  const getNo = (idToSearch) => {
    return quarter.activities.findIndex(({ id }) => id === idToSearch);
  };

  const getRemainingTarget = (_quarter) => {
    if (!selectedIndicator) return 0;
    const totalIndicatorTargetShare =
      selectedIndicator.assignments.length > 0
        ? selectedIndicator.assignments
            .filter(({ id }) => id === user.id)
            .reduce(
              (sum, target) =>
                sum +
                (_quarter === 1
                  ? Math.round(target.q1TargetShare * 10)
                  : _quarter === 2
                  ? Math.round(target.q2TargetShare * 10)
                  : _quarter === 3
                  ? Math.round(target.q3TargetShare * 10)
                  : _quarter === 4
                  ? Math.round(target.q4TargetShare * 10)
                  : 0),
              0
            ) / 10
        : +quarter.target;
    const totalActivityTargetShare =
      quarter.activities
        .filter(
          ({ createdBy, quarterId }) =>
            createdBy === user.id && quarterId === _quarter
        )
        .reduce(
          (sum, activity) => sum + Math.round(activity.targetShare * 10),
          0
        ) / 10;

    return totalIndicatorTargetShare > 0 &&
      totalIndicatorTargetShare > totalActivityTargetShare + +totalQuarterTarget
      ? totalIndicatorTargetShare -
          (totalActivityTargetShare + +totalQuarterTarget)
      : +quarter.target;
  };

  //PAGINATING
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredActivities);
  }, [
    itemOffset,
    itemsPerPage,
    quarter,
    quarter.activities,
    filteredActivities,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredActivities.length || 0;

    setItemOffset(newOffset);
  };

  const position = user.employments.find(
    ({ entitySectorId, isActing }) =>
      entitySectorId === user.selectedEntitySector.id && isActing === false
  );

  const actingPosition = user.employments.find(
    ({ entitySectorId, isActing }) =>
      entitySectorId === user.selectedEntitySector.id && isActing === true
  );

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      {/* {(!!quarter.activities[0] || */}
      {/* (hasPermissions(["CAN_ADD_NEW_ACTIVITY"]))) && ( */}
      <Card
        variant="outlined"
        style={{
          border:
            quarter.activities.length > 0 || (quarter.target || 0) > 0
              ? "1px solid rgb(7, 142, 206)"
              : "",
        }}
        className={`mb-3 `}
      >
        <CardHeader
          style={{
            borderBottom:
              quarter.activities.length > 0 || (quarter.target || 0) > 0
                ? "1px solid rgb(7, 142, 206)"
                : "1px solid rgba(0, 0, 0, 0.12)",
            backgroundColor: "#eef1f3",
          }}
          avatar={
            <Tooltip title="Quarter Score">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    totalProgress < 50
                      ? "danger"
                      : totalProgress < 70
                      ? "warning"
                      : totalProgress < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={totalProgress}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      totalProgress < 50
                        ? "danger"
                        : totalProgress < 70
                        ? "warning"
                        : totalProgress < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${totalProgress.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          }
          action={
            <span className="d-flex align-items-center">
              <span style={{ minWidth: "105px" }}>
                <span style={{ color: "#088dce" }}>
                  {(quarter.activities || []).length} Activities
                </span>
              </span>
              {!isForContract &&
                hasPermissions(["CAN_ADD_NEW_ACTIVITY"]) &&
                !isForMonitoring && (
                  <Tooltip title="Add New Activity">
                    <IconButton
                      onClick={() => {
                        setShowActivityForm(true);
                      }}
                      color="info"
                    >
                      <span className="material-icons">add_box</span>
                    </IconButton>
                  </Tooltip>
                )}
            </span>
          }
          title={
            <span className="d-flex">
              <strong
                style={{
                  backgroundColor: `${
                    quarter.activities.length > 0 || quarter.target > 0
                      ? ""
                      : "transparent"
                  }`,
                }}
                className={`text-${
                  quarter.activities.length > 0 || quarter.target > 0
                    ? "primary"
                    : "alert alert-light"
                } mr-1`}
              >
                QUARTER {quarter.quarterId}
              </strong>
              {totalQuarterTarget !== (quarter.target || 0).toFixed(2) && (
                <Tooltip
                  title={`${quarter.target - +totalQuarterTarget} Target${
                    quarter.target - +totalQuarterTarget > 1 ? "s" : ""
                  } remaining`}
                >
                  <span
                    className="material-icons text-danger"
                    style={{ cursor: "default" }}
                  >
                    error
                  </span>
                </Tooltip>
              )}
            </span>
          }
          subheader={
            <span>
              <strong>Target: </strong>
              <>
                {quarter.target > 0 && (
                  <>
                    <span className={`badge badge-secondary `}>
                      {quarter.target} {selectedIndicator.measurementUnit}
                    </span>{" "}
                    <span className="d-none d-md-inline">
                      /{" "}
                      <span
                        className={`badge badge-${
                          achievedTarget < quarter.target
                            ? "info"
                            : achievedTarget === quarter.target
                            ? "success"
                            : "success"
                        } `}
                      >
                        {achievedTarget.toFixed(2)} Achieved
                      </span>
                    </span>
                  </>
                )}

                {quarter.target === 0 && (
                  <>
                    {quarter.target} {selectedIndicator.measurementUnit}
                  </>
                )}
              </>
            </span>
          }
        />

        <CardContent style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          {quarter?.activities?.length > 0 && (
            <div className="form-group mt-2 mb-2">
              <input
                type="text"
                value={searchTeam}
                className="form-control"
                id="filter"
                placeholder="Search..."
                onChange={(e) => setSearchTeam(e.target.value)}
              />
            </div>
          )}
          {filteredActivities.length === 0 && (
            <div className="container text-center">
              <p className="lead">No activity</p>
            </div>
          )}

          {paginatedItems &&
            paginatedItems.map((activity) => (
              <ActivityCard
                setIsEditing={setIsEditing}
                totalWeight={totalWeight}
                setShowActivityForm={setShowActivityForm}
                totalQuarterTarget={+totalQuarterTarget}
                quarter={quarter}
                activity={activity}
                index={getNo(activity.id)}
                key={activity.id}
                selectedIndicator={selectedIndicator}
                selectedExpectedResult={selectedExpectedResult}
                isForContract={isForContract}
                showReport={setSelectedItem}
                isForMonitoring={isForMonitoring}
              />
            ))}
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        </CardContent>

        {showActivityForm && (
          <ActivityForm
            totalWeight={totalWeight}
            totalQuarterTarget={+totalQuarterTarget}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showActivityForm={showActivityForm}
            setShowActivityForm={setShowActivityForm}
            quarter={quarter}
            personalRemainTargets={getRemainingTarget(quarter.quarterId)}
          />
        )}
      </Card>
      {/* )} */}
    </>
  );
};

const mapStateToProps = ({ selectedFiscalYear, user }) => {
  return { selectedFiscalYear, user };
};
export default connect(mapStateToProps, { deleteIndicator, approveIndicator })(
  QuarterCard
);
