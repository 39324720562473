import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const Breadcrumb = () => {
  const location = useLocation();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/e-appeal/in-progress-appeals"
          className={`  px-1 rounded  ${
            location.pathname.includes("/e-appeal/in-progress-appeals")
              ? "active"
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">In Progress</span>
        </Link>

        <HorizontalRuleIcon className="rotated" />
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/e-appeal/archived-appeals"
          className={`  px-1 rounded  ${
            location.pathname.includes("/e-appeal/archived-appeals")
              ? "active"
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Archives</span>
        </Link>

        {/* <HorizontalRuleIcon className="rotated" />
        <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/e-appeal/overdue-appeals"
          className={`  px-1 rounded  ${
            location.pathname.includes("/e-appeal/overdue-appeals")
              ? "active"
              : " "
          }`}
        >
          <span className="text-truncate  menu-item ">Overdue</span>
        </Link> */}
      </div>
    </>
  );
};

export default Breadcrumb;
