import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import attachment from "../../assets/icons/attachment.svg";
import { showError } from "../../toastify";
import ErrorMessage from "./ErrorMessage";
const WidgetUpload = styled("div")(({ theme }) => ({
  // padding: theme.spacing(0.6, 1.2),
  display: "flex",
  width: "100%",
  flexDirection: "column",
}));

const UploadAction = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  // marginRight: "10px",
  width: "100%",
  display: "flex",
}));

const FileUploads = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  overflow: "hidden",
  position: "relative",
  textAlign: "center",
  display: "inline-block",
  width: "100%",
}));

const UploadUi = styled("div")(({ theme }) => ({
  width: "100%",
}));

const UploadBtn = styled("div")(({ theme }) => ({
  background: "rgba(33,132,208,.23921568627450981)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "50px",
  borderRadius: "5px",
  padding: "10px 10px",
  cursor: "pointer!important",
}));

const Common = styled("div")(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  boxSizing: "border-box",
  fontWeight: 500,
  fontStyle: "normal",
  fontSize: "15px",
}));

const Label = styled("label")(({ theme }) => ({
  background: "#fff",
  opacity: 0,
  fontSize: "20em",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer!important",
}));
const Input = styled("input")(({ theme }) => ({
  background: "rgba(255,255,255,0)",
  overflow: "hidden",
  position: "fixed",
  width: "1px",
  height: "1px",
  zIndex: "-1",
  opacity: 0,
}));

const UploadsPreview = styled("div")(({ theme, direction, justify }) => ({
  // padding: theme.spacing(0.6, 1.2),

  justifyContent: justify ? justify : "space-between",
  display: "flex",
  width: "100%",
  flexDirection: direction,
}));

const UploadingItem = styled("div")(({ theme }) => ({
  height: "150px",
  width: "150px",
  borderRadius: "5px",
  border: "1px solid #ddd",
  padding: "9px",
  position: "relative",
  marginRight: "10px",
  marginBottom: "10px",
  display: "flex",
  flexDirection: "column",
}));

const fileMimeTypes = [
  { extension: "png", type: "image/png" },
  { extension: "jpg", type: "image/jpg" },
  { extension: "jpeg", type: "image/jpeg" },
  { extension: "pdf", type: "application/pdf" },
  {
    extension: "excel",
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  { extension: "word", type: "application/msword" },
  { extension: "excel", type: "vnd.ms-excel" },
  {
    extension: "word",
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { extension: "powerpoint", type: "application/vnd.ms-powerpoint" },
  {
    extension: "powerpoint",
    type: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  },
  {
    extension: "powerpoint",
    type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
];

const WidgetUploadComponent = (props) => {
  const {
    multiple,
    label,
    disabled,
    onSuccess,
    onErrors,
    acceptedFileTypes,
    acceptedFileSize,
  } = props;

  const [errors, setErrors] = useState({
    fileHasError: false,
    message: "",
  });
  const [addedFiles, setAddFiles] = useState([]);
  const [mimeTypes, setMimeTypes] = useState([]);

  useEffect(() => {
    const filtered = [...fileMimeTypes].filter(
      ({ extension, type }) =>
        extension.toLowerCase().includes(acceptedFileTypes.toLowerCase()) ||
        type.toLowerCase().includes(acceptedFileTypes.toLowerCase())
    );
    setMimeTypes(filtered);
  }, [acceptedFileTypes, fileMimeTypes]);

  const removeImage = (file) => {
    setErrors(null);
    const index = addedFiles.findIndex((row) => {
      return row === file;
    });
    addedFiles.splice(index, 1);

    const arr2 = [...addedFiles];
    setAddFiles(arr2);
    onSuccess(arr2);
  };

  const implodeJs = (array, separator, column) => {
    var temp = "";
    for (var i = 0; i < array.length; i++) {
      temp += column ? array[i][column] : array[i];
      if (i != array.length - 1) {
        temp += separator;
      }
    } //end of the for loop

    return temp;
  };

  const handleUploadedFile = (event) => {
    let file = event.target.files[0];
    setErrors(null);

    let hasError = false;
    if (file && file.size > parseInt(acceptedFileSize)) {
      hasError = true;
      const message = `File should not exceed ${acceptedFileSize}MB`;
      onErrors({ fileHasError: true, message: message });
      setErrors({ ...errors, fileHasError: true, message: message });
      showError(message);
    }

    if (
      file &&
      mimeTypes &&
      !mimeTypes.find(({ type }) => type === file.type)
    ) {
      hasError = true;
      const message = `Only ${implodeJs(
        mimeTypes,
        ",",
        "extension"
      )} format allowed!`;
      onErrors({ fileHasError: true, message: message });
      setErrors({ ...errors, fileHasError: true, message: message });
      showError(message);
    }

    if (!hasError) {
      let tmpFile = {
        file,
        name: file.name,
        canPreview: file.type.includes("image") ? true : false,
        src: URL.createObjectURL(file),
      };
      const arr2 = [...addedFiles, tmpFile];
      setAddFiles(arr2);
      onSuccess(arr2);
      setErrors({ ...errors, fileHasError: false, message: "" });
      onErrors(null);
    }
    // this.refs.file.value = "";
  };

  return (
    <WidgetUpload>
      {((!multiple && !addedFiles.length) || !!multiple) && (
        <UploadAction>
          <FileUploads>
            <UploadUi>
              <UploadBtn>
                <Common>{label ? label : "Attach or upload file"}</Common>
                <Common>
                  <img src={attachment} />
                </Common>
              </UploadBtn>
            </UploadUi>
            <Label htmlFor="attachment-upload"></Label>
            <Input
              disabled={disabled}
              type="file"
              name="file"
              accept={
                fileMimeTypes && fileMimeTypes !== "*" && mimeTypes
                  ? implodeJs(mimeTypes, ",", "type")
                  : "*"
              }
              placeholder={label}
              // ref="file"
              id="attachment-upload"
              multiple={multiple ? "multiple" : false}
              onChange={(e) => handleUploadedFile(e)}
            />
            <ErrorMessage
              hasError={errors && errors.fileHasError}
              message={errors && errors.message}
            />
          </FileUploads>
        </UploadAction>
      )}
      {!!addedFiles.length && (
        <div className="mt-3 border border-primary  p-2">
          <div className=" border-bottom border-primary pb-1">
            <strong>({addedFiles.length})Attached files</strong>
          </div>

          <div
            className="mt-3 pl-2 pr-2"
            style={{
              maxHeight: "100px",
              overflow: "auto",
            }}
          >
            <UploadsPreview direction="column">
              {addedFiles
                .filter(({ canPreview }) => !canPreview)
                .map((file, index) => (
                  <div key={index}>
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          // marginTop:'10px'
                        }}
                      >
                        <div
                          style={{
                            width: " 100%",
                            borderRadius: "5px",
                            border: "1px solid #ddd",
                            padding: "9px",
                            position: "relative",
                            minHeight: "40px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              className="text-truncate text-lowercase"
                              style={{
                                fontSize: "12px",
                                color: "#0d141d",
                                fontWeight: "600",
                                width: "calc(100% - 80px)",
                                overflow: "hidden",
                                textDecoration: "none",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <img
                                src={attachment}
                                style={{
                                  height: "18px",
                                }}
                              />{" "}
                              {file.name}
                            </div>
                            <div
                              style={{
                                width: "80px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                type="button"
                                onClick={() => removeImage(file)}
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "12px",
                                  color: "#dc3545",
                                  background: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                Remove{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                ))}
            </UploadsPreview>

            <div className="row">
              {addedFiles
                .filter(({ canPreview }) => !!canPreview)
                .map((file, index) => (
                  <div className="col-4" key={index}>
                    <UploadingItem>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          OObjectFit: "cover",
                          objectFit: "cover",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                        }}
                        src={file.src}
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(file)}
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          left: "10px",
                          padding: "4px",
                          background: "#dc3545",
                          color: "#fff",
                          border: "none",
                          fontSize: "12px",
                          borderRadius: "3px",
                        }}
                      >
                        {" "}
                        Remove{" "}
                      </button>
                    </UploadingItem>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </WidgetUpload>
  );
};

export default WidgetUploadComponent;
