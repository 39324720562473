import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../toastify";

import { doPayrollArchive } from "../../../store/payroll/actions";

const ArchivePayrollDialog = (props) => {
  const {
    loading,
    showDoPayrollArchiveForm,
    setShowDoPayrollArchiveForm,
    doPayrollArchive,
    selectedPayroll,
  } = props;

  const [formData, setFormData] = useState({
    opNumber: selectedPayroll.opNumber,
    password: "",
    payrollNumber: "",
  });
  const [errors, setErrors] = useState({
    opNumberHasError: false,
    passwordHasError: false,
  });

  const formValidator = () => {
    const error = {
      opNumberHasError: false,
      passwordHasError: false,
    };

    if (!formData.opNumber) {
      error.opNumberHasError = true;
      error.hasError = true;
    }

    if (!formData.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = (e) => {
    e.preventDefault();
    if (!formValidator()) {
      formData.payrollNumber = selectedPayroll.id;
      doPayrollArchive(formData, setShowDoPayrollArchiveForm);
    }
  };

  const onClose = () => {
    setShowDoPayrollArchiveForm(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDoPayrollArchiveForm}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">error</span> Confirm
          </span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <Typography className="font-weight-bold text-center">
            Are you sure you want to{" "}
            <span className="text-danger">archive</span> this payroll?
          </Typography>
          <span className="d-block text-center">
            ({selectedPayroll.payrollTypeName} {selectedPayroll.periodId})
          </span>

          <form onSubmit={onSave}>
            <div
              className="elevated rounded p-3 mt-2"
              style={{
                backgroundColor: "#fafafa",
                border: "1px solid #17a2b8",
              }}
            >
              <div className="row ">
                <div className="col-12">
                  <TextField
                    autoComplete="off"
                    fullWidth
                    size="small"
                    name="OPNumber"
                    autoFocus={!selectedPayroll.opNumber}
                    label="OP Number"
                    variant="outlined"
                    placeholder="OP Number"
                    value={formData.opNumber}
                    InputProps={{
                      readOnly: !!selectedPayroll.opNumber,
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        opNumber: e.target.value || "",
                      });
                      setErrors({ ...errors, opNumberHasError: false });
                    }}
                  />

                  {errors.opNumberHasError && (
                    <small className="text-danger">
                      OP Number is required{" "}
                    </small>
                  )}
                </div>

                <div className="col-12 mt-3">
                  <TextField
                    fullWidth
                    size="small"
                    name="password"
                    autoFocus={!!selectedPayroll.opNumber}
                    type="password"
                    label="Enter your password to confirm"
                    variant="outlined"
                    placeholder="Enter your password to confirm"
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        password: e.target.value || "",
                      });
                      setErrors({ ...errors, passwordHasError: false });
                    }}
                  />

                  {errors.passwordHasError && (
                    <small className="text-danger">Password is required </small>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
              <button
                type="submit"
                className="btn btn-primary text-uppercase  px-4"
                disabled={loading}
              >
                {loading ? "Archiving..." : "Confirm"}
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, { doPayrollArchive })(
  ArchivePayrollDialog
);
