import React from "react";
import rwandaLogo from "../../assets/rwanda-logo.png";
import moment from "moment";

const AuthorizationByMinistry = (props) => {
  const {
    minstryLetterReferenceNo,
    draftAuthorizationSubjectByMinistry,
    draftAuthorizationContentByMinistry,
    headOfInstitution,
    signContent,
    isMifotraEntitySector,
  } = props;

  return (
    <div className="letterMainContent">
      <div className="letter">
        <div className="letterhead d-flex flex-column justify-content-center w-100">
          <div
            className="letterhead-title w-100"
            style={{ fontWeight: "bold", fontFamily: "Times New Roman" }}
          >
            REPUBLIC OF RWANDA
          </div>
          <div className="w-100">
            <img
              style={{ width: "100px", height: "100px" }}
              src={rwandaLogo}
              alt="Logo"
            />
          </div>
          <div
            className="letterhead-subtitle w-100"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontFamily: "Times New Roman",
            }}
          >
            {headOfInstitution.institutionName.toUpperCase()}
          </div>
        </div>
        <div className="subletterhead">
          <table>
            <tr>
              <td style={{ width: "70%" }}></td>
              <td style={{ width: "30%" }}>
                <p
                  className="date"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                  }}
                >
                  Kigali, on {moment(new Date()).format("MMMM Do, YYYY")}
                </p>
                <p
                  className="reference"
                  style={{
                    fontWeight: "bold",
                    float: "left",
                    textAlign: "left",
                    position: "relative",
                    top: "-22px",
                  }}
                >
                  N<sup>o</sup>:{" "}
                  {minstryLetterReferenceNo
                    ? minstryLetterReferenceNo
                    : "........."}
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div className="recipient">
          {/* <!-- Recipient's name and address go here --> */}

          <p
            style={{
              fontSize: "16px",
              lineHeight: "1.5em",
            }}
          >
            {isMifotraEntitySector ? (
              <>
                Chief Skills Officer <br />
                <strong>Rwanda Development Board</strong>
              </>
            ) : (
              <>Honorable Minister of Public Service and Labour</>
            )}

            <br />
            <span
              style={{
                position: "relative",
                top: "6px",
                fontWeight: "bold",
              }}
            >
              KIGALI
            </span>
          </p>
        </div>
        <div className="content2">
          {/* <!-- Letter content goes here --> */}
          {isMifotraEntitySector ? (
            <>
              <p>Dear Chief Skills Officer,</p>
            </>
          ) : (
            <>
              <p>Honorable Minister,</p>
            </>
          )}

          <p style={{ fontWeight: "bold",fontSize: "15px", }}>
            Re:
            {draftAuthorizationSubjectByMinistry
              ? draftAuthorizationSubjectByMinistry
              : "Request the authorization to conduct a training"}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: draftAuthorizationContentByMinistry
                ? draftAuthorizationContentByMinistry
                : "",
            }}
          ></div>
          {/* 0864297531 */}
        </div>
        <div className="signature2">
          <p
            style={{
              position: "relative",
              paddingBottom: "15px",
              fontWeight: 800,
              color: "red",
            }}
          >
            {signContent ? (
              signContent
            ) : (
              <table>
                <tr>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "12px",
                      textAlign: "left",
                      paddingLeft: "76px",
                    }}
                  >
                    <span className="d-block text-danger">Not signed</span>
                  </td>
                  <td
                    style={{
                      width: "20%",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  ></td>
                </tr>
              </table>
            )}
          </p>
          <p className="mt-4" style={{ fontSize: "13px", fontWeight: 800 }}>
            {headOfInstitution.title ? headOfInstitution.title + "." : ""}{" "}
            {headOfInstitution?.firstName || ""}{" "}
            {headOfInstitution?.lastName || ""}
          </p>
          <p style={{ fontSize: "14px", fontWeight: 800 }}>
            {headOfInstitution.positionName}
          </p>
          {!!headOfInstitution?.canAddInstitutionName ? (
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 800,
                    }}
                  >
                    {headOfInstitution?.institutionName}
                  </p>
                ) : (
                  ""
                )}
        </div>
      </div>
    </div>
  );
};
export default AuthorizationByMinistry;
