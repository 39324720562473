import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
export const routers = [
  {
    name: "All positions",
    path: "/recruitment/waiting-list",
    to: "/recruitment/waiting-list/positions",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF","IS_RECRUITMENT_ADMIN"],
  },
  {
    name: "Requisitions",
    path: "/recruitment/waiting-list",
    to: "/recruitment/waiting-list/requisitions",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF"],
  },
  {
    name: "All candidates",
    path: "/recruitment/waiting-list",
    to: "/recruitment/waiting-list/candidates",
    permissions: ["IS_RECRUITMENT_ADMIN"],
  },
  {
    name: "Offers",
    to: "/recruitment/waiting-list/offers",
    permissions: ["IS_RECRUITMENT_ADMIN"],
  },
];

const WaitingListLeftSide = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <div className="list-group mt-4 ml-3">
        {routers.map((router, index) => (
          <React.Fragment key={index + 1}>
            {hasPermissions(router.permissions) && (
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to={router.to}
                className={`list-group-item list-group-item-action  ${
                  location.pathname.includes(router.to)
                    ? "active"
                    : "text-dark "
                }`}
              >
                <span className="text-truncate  menu-item">{router.name}</span>
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default WaitingListLeftSide;
