import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Typography, Toolbar, Button } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import { getAllOverdueAppeals } from "../../../store/recruitment/actions";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";

import BackdropLoader from "../../common/components/BackdropLoader";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AppealDialog from "./AppealDialog";
import AppealLeftSide from "../components/AppealLeftSide";
import moment from "moment";

const AllOverdueAppeals = (props) => {
  const { allOverdueAppeals, getAllOverdueAppeals } = props;

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { hasPermissions } = useRequiredAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 15
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_ARCHIVED_APPEALS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    getAllOverdueAppeals(query, setTotalRequests, setIsLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getAllOverdueAppeals(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getAllOverdueAppeals(query, setTotalRequests, setIsLoading1);
  };

  return (
    <>
      <div className="row mx-0">
        <div className="col-2">
          <AppealLeftSide />
        </div>
        <div className="col-10 mt-2">
          <div className="pr-3">
            <AppBar
              position="static"
              elevation={0}
              style={{ overflow: "hidden" }}
              className="app-bar w-100"
            >
              <Toolbar className="row">
                <div className="col-lg-7">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="text-uppercase"
                  >
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      {searchTerm
                        ? "Filtered Overdue appeals"
                        : "Overdue appeals"}
                      ({totalRequests})
                    </span>
                  </Typography>
                </div>

                <div className="col-lg-5">
                  <div className="d-flex justify-content-right  align-items-right">
                    <SearchBox
                      onSearch={(search) => {
                        setSearchTerm(search);

                        if (!search) {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: "",
                            page: currentPage,
                            size: itemPerPage,
                          };
                          getAllOverdueAppeals(
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                    <Button
                      disabled={!searchTerm}
                      onClick={() => onSearch()}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2 "
                      style={{
                        borderRadius: "8px",
                        height: "38px",
                        border: `1px solid rgb(7, 142, 206)`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                    >
                      <span className="material-icons">search</span>GO
                    </Button>
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left">
                    Title
                  </th>
                  {hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
                    <th scope="col" className="text-left">
                      Institution
                    </th>
                  )}

                  <th scope="col" className="text-center">
                    AllAppeals
                  </th>
                  <th scope="col" className="text-right">
                    Status
                  </th>
                  <th scope="col" className="text-center">
                    LastAppealedOn
                  </th>
                  <th scope="col" className="text-center"></th>
                </tr>
              </thead>

              {isLoading ? (
                <TableSkeleton cols={8} />
              ) : (
                <tbody>
                  <BackdropLoader isLoading={isLoading1} />
                  {allOverdueAppeals.map((allOverdueAppeal, index) => (
                    <AllOverdueAppealCard
                      allOverdueAppeal={allOverdueAppeal}
                      index={index}
                      key={allOverdueAppeal.id}
                    />
                  ))}
                </tbody>
              )}
            </table>
            <NoRecordMessage
              isEmpty={!allOverdueAppeals.length && !isLoading}
              title={"No record found"}
            />
            {!isLoading && (
              <div
                className="d-flex justify-content-center  align-items-center"
                style={{ overflow: "hidden" }}
              >
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    // marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRequests / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                {!!allOverdueAppeals.length && (
                  <select
                    className="custom-select custom-select-sm ml-2"
                    style={{ width: "100px" }}
                    value={itemPerPage}
                    onChange={(event) => {
                      const itemPerPage = +event.target.value;
                      window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                      setItemPerPage(itemPerPage);
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };
                      getAllOverdueAppeals(
                        query,
                        setTotalRequests,
                        setIsLoading
                      );
                    }}
                  >
                    {[10, 15, 20, 50, 100].map((item, index) => (
                      <option key={index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, allOverdueAppeals }) => {
  return { user, loading, allOverdueAppeals };
};
export default connect(mapStateToProps, {
  getAllOverdueAppeals,
})(AllOverdueAppeals);

export const AllOverdueAppealCard = (props) => {
  const { allOverdueAppeal, index,hrCanViewAppealedOnSecondLevel } = props;

  const { hasPermissions } = useRequiredAuth();
  const [showAppeal, setShowAppeal] = useState(false);
  return (
    <>
      <tr
        className="appeal-hovered"
        onClick={() => {
          setShowAppeal(true);
        }}
      >
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>

        <td className="text-left"> {allOverdueAppeal.positionName}</td>
        {hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
          <th className="text-left">{allOverdueAppeal.entityName}</th>
        )}
        {/* <td className="text-left"> {allOverdueAppeal.unitName}</td> */}

        <td className="text-center">
          <span className="p-2 badge badge-danger">
            {allOverdueAppeal.totalAppeals}
          </span>
        </td>
        <td className="text-right"> {allOverdueAppeal.statusName}</td>
        <td className="text-center">
          {moment(allOverdueAppeal.lastAppealedOn).format("DD/MM/YYYY hh:mm")}
        </td>

        <td className="text-center">
          <button
            type="button"
            className="btn btn-success m-2"
            onClick={() => {
              setShowAppeal(true);
            }}
          >
            <OpenInNewIcon /> Open
          </button>
        </td>
      </tr>
      {showAppeal && (
        <AppealDialog
          category="OverdueAppeals"
          showAppeal={showAppeal}
          setShowAppeal={setShowAppeal}
          selectedAdvertisement={allOverdueAppeal}
          hrCanViewAppealedOnSecondLevel={hrCanViewAppealedOnSecondLevel}
        />
      )}
    </>
  );
};
