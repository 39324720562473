import { CardContent } from "@mui/material";
import EmployeeCard from "./EmployeeCard";
import ReactPaginate from "react-paginate";
import React, { useState, useEffect } from "react";
const LoadEmployees = (props) => {
  const {
    skeletonLoader,
    showActionMenus,
    isEntitySectorLocked,
    showUnitName,
    employees,
    searchTerm,
    showInstitutionName,
  } = props;

  const [filteredEmployees, setFilteredEmployees] = useState([...employees]);

  //   console.log(employees);
  useEffect(() => {
    const filtered = [...employees].filter(
      ({
        id,
        lastName,
        firstName,
        idNumber,
        rssbNumber,
        levelName,
        scaleName,
        positionName,
        unitName,
        institutionName,
        statusName,
      }) =>
        (
          id +
          lastName +
          firstName +
          idNumber +
          rssbNumber +
          levelName +
          scaleName +
          positionName +
          unitName +
          institutionName +
          statusName
        )
          .toLowerCase()
          .replace(/ /g, "")
          .includes(searchTerm.toLowerCase().replace(/ /g, ""))
    );
    setFilteredEmployees(filtered);
    paginate(filtered);
  }, [searchTerm, employees]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 20;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(
    () => {
      paginate(filteredEmployees);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, employees, filteredEmployees]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredEmployees.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <>
      {!!paginatedItems.length && (
        <table className="table table-striped table-hover table-sm fixTableHead">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th scope="col">#</th>
              <th scope="col">EmployeeId</th>
              <th scope="col">Names</th>
              <th scope="col">National ID</th>
              <th scope="col">RSSBNo</th>
              <th scope="col">Position</th>
              {showUnitName && <th scope="col">Unity</th>}
              {showInstitutionName && <th scope="col">Institution</th>}

              <th scope="col" className="text-right">
                Level
              </th>
              <th scope="col" className="text-right">
                Index
              </th>
              <th scope="col" className="text-right">
                IndexValue
              </th>
              <th scope="col" className="text-right">
                Base Pay
              </th>

              <th scope="col" className="text-right">
                Status
              </th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>
            {paginatedItems.map((employee, index) => (
              <EmployeeCard
                showUnitName={showUnitName}
                showInstitutionName={showInstitutionName}
                showActionMenus={showActionMenus}
                isEntitySectorLocked={isEntitySectorLocked}
                employee={employee}
                employeeIndex={index}
                showBRDTotalRecovery={false}
                key={index}
              />
            ))}
          </tbody>
        </table>
      )}

      {!paginatedItems.length && !skeletonLoader && (
        <div className="jumbotron jumbotron-fluid text-center">
          <div className="container py-5">
            <p className="lead">No employees found</p>
          </div>
        </div>
      )}

      <div className="justify-content-center" style={{ overflow: "hidden" }}>
        {!!paginatedItems.length && employees.length >= itemsPerPage && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        )}
      </div>
    </>
  );
};

export default LoadEmployees;
