import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { connect } from "react-redux";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = (props) => {
  const location = useLocation();

  const { user } = props;

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/structure/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/structure/dashboard")
              ? " active  "
              : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link> */}

        {/* <HorizontalRuleIcon className="rotated"
 /> */}

        {hasPermissions(["IS_HR", "IS_CBM", "IS_DAF", "IS_TEST_USER"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/structure/units"
              className={` px-1 rounded   ${
                location.pathname.includes("/structure/units")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Units</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_HR", "IS_CBM", "IS_DAF", "IS_TEST_USER"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/structure/positions"
              className={`px-1 rounded ${
                location.pathname.includes("/structure/positions")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Positions</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {user?.selectedEntitySector?.id ===
          "1203000001-090000000000000001-11" && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/structure/rra-departments"
              className={`  px-1 rounded  ${
                location.pathname.includes("/structure/rra-departments")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">RRA Departments</span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_HR", "IS_CBM", "IS_DAF"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/structure/organigram"
              className={`  px-1 rounded  ${
                location.pathname.includes("/structure/organigram")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Organigram </span>
            </Link>

            <HorizontalRuleIcon className="rotated" />
          </>
        )}

        {hasPermissions(["IS_STRUCTURE_OVERSIGHT"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/structure/oversight"
              className={`  px-1 rounded  ${
                location.pathname.includes("/structure/oversight")
                  ? "active"
                  : " "
              }`}
            >
              <span className="text-truncate  menu-item">Oversight</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps)(Breadcrumb);
