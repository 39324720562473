import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  DialogActions,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ErrorMessage from "../../common/components/ErrorMessage";
import ActionDialog from "../../common/components/ActionDialog";
import { showError } from "../../toastify";

import { submitResolveAppeal } from "../../../store/e-appeal/actions";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
const AppealResolveDialog = (props) => {
  const {
    loading,
    resolveAppeal,
    setResolveAppeal,
    appealId,
    submitResolveAppeal,
  } = props;

  const onClose = () => {
    setResolveAppeal(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const maxLength = 250;

  const [formData, setFormData] = useState({
    appealId: appealId,
    message: "",
    attachedFiles: [],
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    messageHasError: false,
    hasError: false,
  });

  //console.log(entitySectors);
  const formValidator = () => {
    const error = {
      fileHasError: false,
      messageHasError: false,
      hasError: false,
    };

    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }

    if (!formData.attachedFiles.length) {
      error.fileHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!errors.fileHasError) {
      if (!formValidator()) {
        const payload = new FormData();
        const keys = Object.keys(formData);
        for (const key of keys) {
          if (formData.hasOwnProperty(key)) {
            if (
              key === "attachedFiles" &&
              formData[key] &&
              !!formData[key].length
            )
              formData[key].forEach((row, i) => {
                payload.append(key, row.file);
              });
            else payload.append(`${key}`, `${formData[key]}`);
          }
        }

        submitResolveAppeal(payload, setFormData, setIsSubmitting, onClose);
      }
    }
  };

  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={resolveAppeal}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-11">
              <h5
                style={{
                  // background: "rgba(0,99,207,.08)",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  margin: "0 25px 32px 0",
                  width: "-moz-fit-content",
                  width: "fit-content",
                  color: "#0063cf",
                }}
              >
                {" "}
                Resolve an appeal.
              </h5>
            </div>
            <div className="col-1">
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-12 mt-2">
              <TextField
                fullWidth
                multiline
                rows={4}
                size="small"
                name="message"
                label={`Write a message in ${maxLength} words`}
                variant="outlined"
                placeholder={`Write a message in ${maxLength} words`}
                value={formData.message}
                inputProps={{
                  maxLength: maxLength,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const message = e.target.value;
                  setFormData({
                    ...formData,
                    message,
                  });

                  setErrors({
                    ...errors,
                    messageHasError: false,
                  });
                }}
              />
              {!!formData.message.length && (
                <div className="float-right text-primary">
                  {formData.message.length}/{maxLength}
                </div>
              )}

              <ErrorMessage
                hasError={errors.messageHasError}
                message="message is required"
              />
            </div>
            <div className="col-lg-12 mt-3">
              <WidgetUploadComponent
                onSuccess={onUploadSuccess}
                onErrors={onUploadErrors}
                multiple={true}
                acceptedFileSize="1074176"
                acceptedFileTypes="pdf"
                label="Upload supporting document (pdf)"
              />
              <ErrorMessage
                hasError={errors.fileHasError && !formData.attachedFiles.length}
                message="Supporting document is required"
              />
            </div>{" "}
          </div>

          {isSubmitting && (
            <ActionDialog showAction={isSubmitting} action="Submitting" />
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary  px-4"
            disabled={loading || isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  submitResolveAppeal,
})(AppealResolveDialog);
