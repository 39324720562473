import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  DialogActions,
  Autocomplete,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { showError } from "../../toastify";

import {
  savePayrollSupportingDocument,
  removePayrollSupportingDocument,
} from "../../../store/payroll/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const PayrollSupportingDocsDialog = (props) => {
  const {
    loading,
    onWait,
    showSupportingDocsDialog,
    setShowSupportingDocsDialog,
    payroll,
    supportingDocumentTypes,
    payrollSupportingDocuments,
    savePayrollSupportingDocument,
    removePayrollSupportingDocument,
    viewType,
    type,
  } = props;

  const onClose = () => {
    setShowSupportingDocsDialog(false);
    setSupportingDocumentType(null);
    setDescription("");
    setFile(null);
  };

  const [
    showAddPayrollSupportingDocsDialog,
    setShowAddPayrollSupportingDocsDialog,
  ] = useState(false);

  const [supportingDocumentType, setSupportingDocumentType] = useState(null);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  const onSave = () => {
    if (!!supportingDocumentType && !!description && !!file) {
      const formData = new FormData();
      formData.append("payrollId", payroll.id);
      formData.append("supportingDocumentTypeId", supportingDocumentType?.id);
      formData.append("description", description);
      formData.append("file", file);
      savePayrollSupportingDocument(
        formData,
        setShowAddPayrollSupportingDocsDialog,
        setSupportingDocumentType,
        setDescription,
        setFile
      );
    } else showError("Please, fill all fields");
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="md"
        open={showSupportingDocsDialog}
      >
        <DialogTitle className="text-primary pb-0 mb-0">
          Payroll Supporting Documents
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="elevated rounded px-3 py-2 mt-0"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
              minHeight: "55vh",
            }}
          >
            {type === "Temp" && viewType !== "admin" && (
              <Button
                className="mb-2"
                onClick={() => {
                  setShowAddPayrollSupportingDocsDialog(true);
                }}
                style={{
                  float: "left",
                  borderRadius: "8px",
                  height: "32px",
                  border: `1px solid rgb(7, 142, 206)`,
                  display: "flex",
                  color: "#fff",
                  alignItems: "center",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "rgb(7, 142, 206)",
                  "&:hover": {
                    backgroundColor: "#f0f2f5",
                  },

                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="sm"
              >
                <span className="material-icons">add</span>
              </Button>
            )}

            <table className="table table-striped table-hover table-sm fixTableHead ">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Document Type</th>
                  <th>Description</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              {!!payrollSupportingDocuments.length && (
                <tbody>
                  {payrollSupportingDocuments.map(
                    (supportingDocument, index) => (
                      <SupportingDocumentItem
                        index={index}
                        key={supportingDocument.id}
                        supportingDocument={supportingDocument}
                        removePayrollSupportingDocument={
                          removePayrollSupportingDocument
                        }
                        loading={loading}
                        onWait={onWait}
                        type={type}
                        viewType={viewType}
                        payroll={payroll}
                      />
                    )
                  )}
                </tbody>
              )}
            </table>

            {!payrollSupportingDocuments.length && (
              <div className="jumbotron jumbotron-fluid text-center mt-n2">
                <div className="container  py-5">
                  <p className="lead">No supporting document added</p>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={() => {
          setShowAddPayrollSupportingDocsDialog(false);
        }}
        open={showAddPayrollSupportingDocsDialog}
      >
        <DialogTitle className={`text-primary`}>
          <small className="">Add Supporting Document</small>{" "}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowAddPayrollSupportingDocsDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="row">
            {" "}
            <div className="col-12">
              <Autocomplete
                fullWidth
                size="small"
                className="mt-3"
                id="supportingDocumentType"
                name="supportingDocumentType"
                defaultValue={null}
                value={supportingDocumentType || null}
                options={supportingDocumentTypes}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, supportingDocumentType) => {
                  setSupportingDocumentType(supportingDocumentType || null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, supportingDocumentType) => (
                  <Fragment>
                    <Box component="li" {...props}>
                      {supportingDocumentType.name}
                    </Box>
                    <Divider />
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    placeholder="Supporting Document Type"
                    {...params}
                    label="Supporting Document Type"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
            <div className="col-12 mt-3 ">
              <TextField
                fullWidth
                size="small"
                name="description"
                required={true}
                multiline
                rows={2}
                value={description}
                label="Description"
                placeholder="Description"
                onChange={(e) => {
                  setDescription(e.target.value || "");
                }}
              />
            </div>
            <div className="col-12 mt-2 text-left">
              <small>Attachement (PDF)</small>

              <Button
                fullWidth
                component="label"
                style={{
                  borderRadius: "0px",
                  height: "35px",
                  border: `1px solid #6c757d`,
                  color: "#fff",
                  fontSize: ".75rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "initial",
                  backgroundColor: "#6c757d",
                  "&:hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                size="md"
              >
                <input
                  required
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFile(file || null);
                  }}
                />
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <button
            onClick={onSave}
            type="button"
            disabled={loading}
            className="btn btn-primary text-uppercase  mr-3"
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  payrollSupportingDocuments,
  supportingDocumentTypes,
}) => {
  return {
    loading,
    onWait,
    payrollSupportingDocuments,
    supportingDocumentTypes,
  };
};
export default connect(mapStateToProps, {
  savePayrollSupportingDocument,
  removePayrollSupportingDocument,
})(PayrollSupportingDocsDialog);

const SupportingDocumentItem = (props) => {
  const {
    index,
    supportingDocument,
    removePayrollSupportingDocument,
    loading,
    onWait,
    type,
    viewType,
    payroll,
  } = props;

  const [confirmRemoveSupportingDocument, setConfirmRemoveSupportingDocument] =
    useState(false);

  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    link.click();
  }

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{supportingDocument.supportingDocumentTypeName}</td>
        <td>{supportingDocument.description}</td>
        <td className="text-right">
          <IconButton
            className="mr-2"
            size="small"
            color="primary"
            onClick={() => {
              downloadURI(supportingDocument.url, supportingDocument.filName);
            }}
          >
            <span className="material-icons">open_in_new</span>
          </IconButton>
          {type === "Temp" && viewType !== "admin" && (
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                setConfirmRemoveSupportingDocument(true);
              }}
            >
              <span className="material-icons">delete_outline</span>
            </IconButton>
          )}
        </td>
      </tr>

      {confirmRemoveSupportingDocument && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveSupportingDocument}
          message={`Are you sure you want to remove this supporting document?`}
          setConfirmationDialog={setConfirmRemoveSupportingDocument}
          onYes={() => {
            removePayrollSupportingDocument(
              supportingDocument.id,
              setConfirmRemoveSupportingDocument
            );
          }}
        />
      )}
    </>
  );
};
