import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const Breadcrumb = (props) => {
  const { ippisModules } = props;

  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  const getActiveModules = () => {
    return ippisModules.filter(
      ({ isActive, id }) => isActive === true && id !== "EMPLOYEES"
    );
  };

  return (
    <>
      <div className="d-flex bread-crumb align-items-center ">
        {/* <Link
          style={{ textDecoration: "none", color: "#fff" }}
          to="/admin/dashboard"
          className={` px-1 rounded   ${
            location.pathname.includes("/admin/dashboard") ? " active  " : " "
          }`}
        >
          <span className="text-truncate  menu-item">Dashboard</span>
        </Link> */}

        {getActiveModules().map(
          (mod, index) =>
            ((mod.id === "STRUCTURE" &&
              hasPermissions(["IS_ADMIN", "IS_STRUCTURE_OVERSIGHT"])) ||
              (mod.id === "PAYROLL" &&
                hasPermissions(["IS_ADMIN", "IS_PAYROLL_OVERSIGHT"])) ||
              (mod.id === "RECRUITMENT" &&
                hasPermissions(["IS_ADMIN", "IS_RECRUITMENT_ADMIN"]))) && (
              <Fragment key={index}>
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  to={`/admin${mod.to}`}
                  className={` px-1 rounded   ${
                    location.pathname.startsWith(`/admin${mod.to}`)
                      ? " active  "
                      : " "
                  }`}
                >
                  <span className="text-truncate  menu-item">
                    {mod.shortName}{" "}
                  </span>
                </Link>
                <HorizontalRuleIcon className="rotated" />
              </Fragment>
            )
        )}
        {hasPermissions(["IS_ADMIN"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/admin/users"
              className={` px-1 rounded   ${
                location.pathname.includes("/admin/users") ? " active  " : " "
              }`}
            >
              <span className="text-truncate  menu-item">Users</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/admin/common"
              className={` px-1 rounded   ${
                location.pathname.includes("/admin/common") ? " active  " : " "
              }`}
            >
              <span className="text-truncate  menu-item">Common</span>
            </Link>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user, ippisModules }) => {
  return {
    user,
    ippisModules,
  };
};
export default connect(mapStateToProps)(Breadcrumb);
